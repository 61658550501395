var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teacher-review" },
    [
      _c("div", { staticClass: "nav" }, [_vm._v("教师审核")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "scroll-content",
          style: {
            height:
              _vm.detail.checkStatus !== 2 &&
              _vm.detail.checkStatus !== 3 &&
              _vm.userOpenId &&
              _vm.isAdmin
                ? "calc(100vh - 44px - 56px)"
                : "calc(100vh - 44px )"
          }
        },
        [
          _vm.detail.certificateImage
            ? _c(
                "div",
                { staticClass: "common-view" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("在职教师证明文件")
                  ]),
                  _vm._v(" "),
                  _c("el-image", {
                    staticClass: "cover",
                    attrs: { src: _vm.getImgUrl(_vm.detail.certificateImage) },
                    on: {
                      click: function($event) {
                        _vm.showPreview = true
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "common-view" }, [
            _c("div", { staticClass: "common-item-flex" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("姓名")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm._f("beautifulTextFilter")(_vm.detail.name)))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "common-item-flex",
                on: { click: _vm.callPhoneNumber }
              },
              [
                _c("span", { staticClass: "label-name" }, [_vm._v("电话")]),
                _vm._v(" "),
                _c(
                  "el-link",
                  { attrs: { type: "primary", underline: false } },
                  [
                    _vm._v(
                      _vm._s(_vm._f("beautifulTextFilter")(_vm.detail.phone))
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "common-item-flex" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("学校")]),
              _vm._v(" "),
              _c("span", { staticClass: "school" }, [
                _vm._v(_vm._s(_vm._f("beautifulTextFilter")(_vm.detail.school)))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "common-item-flex" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("职务")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("beautifulTextFilter")(_vm.detail.teachZw))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "common-item-flex no-margin" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("任教学段")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("beautifulTextFilter")(_vm.detail.teachXd))
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-view" }, [
            _c("div", { staticClass: "common-item-flex" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("认证来源")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.detail.channel === "2"
                      ? "云零售-" + _vm.storeInfo.storeName
                      : "淘知学堂"
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "common-item-flex" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("认证时间")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detail.createdAt))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "common-item-flex no-margin" }, [
              _c("span", { staticClass: "label-name" }, [_vm._v("备注信息")]),
              _vm._v(" "),
              _c("span", { staticClass: "school" }, [
                _vm._v(
                  _vm._s(_vm._f("beautifulTextFilter")(_vm.detail.description))
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.detail.checkStatus === 2 || _vm.detail.checkStatus === 3
            ? _c("div", { staticClass: "common-view" }, [
                _c("div", { staticClass: "common-item-flex" }, [
                  _c("span", { staticClass: "label-name" }, [_vm._v("状态")]),
                  _vm._v(" "),
                  _vm.detail.checkStatus === 2
                    ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                        _vm._v("通过")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detail.checkStatus === 3
                    ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                        _vm._v("驳回")
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.detail.checkStatus == 3
                  ? _c("div", { staticClass: "common-item-flex" }, [
                      _c("span", { staticClass: "label-name" }, [
                        _vm._v("驳回原因")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "scroll" }, [
                        _vm._v(_vm._s(_vm.detail.checkRejectReason))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "common-item-flex" }, [
                  _c("span", { staticClass: "label-name" }, [_vm._v("审核人")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "school" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("beautifulTextFilter")(_vm.checkAuditorName)
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "common-item-flex no-margin" }, [
                  _c("span", { staticClass: "label-name" }, [
                    _vm._v(
                      _vm._s(_vm.detail.checkStatus === 3 ? "驳回" : "审核") +
                        "时间"
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "school" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("beautifulTextFilter")(_vm.detail.updatedAt)
                      )
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.detail.checkStatus !== 2 &&
      _vm.detail.checkStatus !== 3 &&
      _vm.detail.checkStatus &&
      _vm.userOpenId &&
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  staticStyle: { background: "#FF0000" },
                  attrs: { type: "danger", size: "small", round: "" },
                  on: { click: _vm.rejectBtn }
                },
                [_vm._v("驳回")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  attrs: {
                    type: "primary",
                    size: "small",
                    round: "",
                    loading: _vm.passLoading
                  },
                  on: { click: _vm.passBtn }
                },
                [_vm._v("通过")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPreview
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "2500" },
            attrs: { "on-close": _vm.closeViewer, "url-list": [_vm.img] }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "我是标题",
            visible: _vm.visible,
            "with-header": false,
            direction: "btt",
            "custom-class": "teacher-review-drawer",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "drawer-nav" }, [
            _c("span", [_vm._v("驳回原因")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "el-icon-close close-icon",
              on: { click: _vm.handleClose }
            })
          ]),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "drawer-textarea",
            attrs: {
              clearable: "",
              type: "textarea",
              autosize: { minRows: 4, maxRows: 4 },
              placeholder: "请输入驳回原因"
            },
            model: {
              value: _vm.reason,
              callback: function($$v) {
                _vm.reason = $$v
              },
              expression: "reason"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "drawer-btn",
                  attrs: {
                    type: "danger",
                    size: "small",
                    round: "",
                    loading: _vm.rejectLoading
                  },
                  on: { click: _vm.rejectFn }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }