var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "micro-detail" },
    [
      _c(
        "div",
        {
          staticClass: "classification",
          style: { "margin-right": _vm.isPlatform ? "20px" : 0 }
        },
        [
          !_vm.isPlatform
            ? _c("search-partner", {
                staticClass: "micro-partner",
                attrs: {
                  "is-return-obj": true,
                  "partner-open-id": _vm.newPartnerOpenId
                },
                on: { receivepartnerId: _vm.receivePartnerId }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("micro-classification", {
            ref: "classification",
            attrs: {
              "is-platform": _vm.isPlatform,
              "height-style": _vm.heightStyle,
              "partner-open-id": _vm.newPartnerOpenId
            },
            on: { refreshDetail: _vm.refreshDetail }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("detail-list", {
        ref: "detailList",
        style: {
          width: _vm.isPlatform ? "calc(100% - 260px)" : "calc(100% - 240px)"
        },
        attrs: {
          "partner-info": _vm.partnerInfo,
          "is-platform": _vm.isPlatform,
          "micro-page-classification-open-id": _vm.classOpenId,
          "partner-open-id": _vm.newPartnerOpenId,
          refresh: _vm.refresh
        },
        on: {
          "update:refresh": function($event) {
            _vm.refresh = $event
          },
          classListRefresh: _vm.classListRefresh
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }