var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-product-category",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.visible,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "600px",
        "before-close": _vm.cancel
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "categoryForm",
          attrs: {
            model: _vm.categoryForm,
            "label-width": "90px",
            rules: _vm.rules
          }
        },
        [
          _vm.categoryForm.parentId
            ? _c("el-form-item", { attrs: { label: "上级分类" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.categoryForm.parentName) + "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分类名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入分类名称" },
                model: {
                  value: _vm.categoryForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.categoryForm, "name", $$v)
                  },
                  expression: "categoryForm.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.categoryForm.parentId
            ? _c(
                "el-form-item",
                { attrs: { label: "分类图标" } },
                [
                  _c("coverupload", {
                    attrs: {
                      coverisshow: false,
                      covertext: _vm.covertextFeng,
                      coverarr: _vm.coverarr,
                      path: _vm.categoryManagePath,
                      texttips: _vm.texttipsDown,
                      type: "17"
                    },
                    on: {
                      deletecover: function($event) {
                        _vm.categoryForm.imageUrl = ""
                      }
                    },
                    model: {
                      value: _vm.categoryForm.imageUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.categoryForm, "imageUrl", $$v)
                      },
                      expression: "categoryForm.imageUrl"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示", prop: "isClientView" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.categoryForm.clientView,
                  callback: function($$v) {
                    _vm.$set(_vm.categoryForm, "clientView", $$v)
                  },
                  expression: "categoryForm.clientView"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "is-client-view-hint" }, [
                _vm._v("关闭后该分类将不再店铺中展示")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "volumeCodes" } },
            [
              _c("div", { staticClass: "dialogFoot" }, [
                _c(
                  "div",
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "120px" },
                      attrs: { "controls-position": "right" },
                      model: {
                        value: _vm.categoryForm.sequence,
                        callback: function($$v) {
                          _vm.$set(_vm.categoryForm, "sequence", $$v)
                        },
                        expression: "categoryForm.sequence"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "数字越大，排位越靠前",
                          placement: "top"
                        }
                      },
                      [
                        _c("span", { staticClass: "describeInfo" }, [
                          _c("i", {
                            staticClass: "el-icon-info",
                            staticStyle: { color: "#409EFF" }
                          })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取 消")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.editRegimentationInfo }
                      },
                      [_vm._v("确 定")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }