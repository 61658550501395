"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVocabularyPinyin = addVocabularyPinyin;
exports.updateVocabularyPinyin = updateVocabularyPinyin;
exports.deleteVocabularyPinyin = deleteVocabularyPinyin;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/vocabularyPinyin/"));
/* 创建词语拼音信息 */

function addVocabularyPinyin(params) {
  return request.post("addVocabularyPinyin/".concat(params.vocabularyOpenId), params);
}
/* 更新词语拼音信息 */


function updateVocabularyPinyin(id, params) {
  return request.put("updateVocabularyPinyin/".concat(id), params);
}
/* 删除词语拼音信息 */


function deleteVocabularyPinyin(id) {
  return request.delete("deleteVocabularyPinyin/".concat(id));
}