"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addTeacher = _interopRequireDefault(require("./addTeacher"));

//
//
//
//
//
//
var _default = {
  name: 'TeacherEdit',
  components: {
    addTeacher: _addTeacher.default
  },
  closeView: function closeView() {
    this.$store.dispatch('tagsView/updateCloseViewFormAnyWhere', 'AddTeacher');
  }
};
exports.default = _default;