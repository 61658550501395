"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DefaultSelector = _interopRequireDefault(require("../../../General/From/Selector/ProductGrouping/DefaultSelector"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CPM_P_ShopAllAddProduct_ProductCategoryBatchEdit',
  components: {
    ProductGroupingSelector: _DefaultSelector.default
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    middleOpenId: {
      type: String,
      default: undefined
    },
    shopOpenId: {
      type: String,
      default: undefined
    },
    useDefaultOptions: {
      type: Boolean,
      default: false
    },
    defaultOptions: {
      type: Array,
      default: undefined
    }
  },
  data: function data() {
    return {
      groupIds: undefined,
      secondGroupIds: undefined
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onConfirm: function onConfirm() {
      this.$emit('on-confirm', this.groupIds, this.secondGroupIds);
      this.valueProxy = false;
    }
  }
};
exports.default = _default;