"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileUploader = fileUploader;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.function.name");

var _aliOss = _interopRequireDefault(require("ali-oss"));

var _dictionary = require("./dictionary");

var _index = require("../../index");

var _fileUtil = require("./fileUtil");

var _uploadOSS = require("../../uploadOSS");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * Oss 配置
 *
 * @type {{banana: string, bucket: string, apple: string, region: string}}
 */
var ossConfig = {
  region: _uploadOSS.ossCfg.region,
  // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
  banana: _uploadOSS.ossCfg.banana,
  // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录 https://ram.console.aliyun.com 创建RAM账号。
  apple: _uploadOSS.ossCfg.apple,
  bucket: 'taozhi-download'
};
var ossConfigInvoice = {
  region: _uploadOSS.ossCfg.region,
  // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
  banana: _uploadOSS.ossCfg.banana,
  // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录 https://ram.console.aliyun.com 创建RAM账号。
  apple: _uploadOSS.ossCfg.apple,
  bucket: 'taozhi-open'
};

function handlePath() {
  var data = (0, _uploadOSS.getRadomNum)(3, true);
  var time = new Date().getTime();
  return "".concat(data).concat(time);
}
/**
 * 获取 oss 的文件名
 *
 * @param {File} file
 * @returns {string}
 */


function ossPath(file) {
  // 现在为时间 原来调用 createUniqueString() 生成随机数
  var time = new Date().getTime();
  var newTime = (0, _index.parseTime)(time, '{y}-{m}-{d}-{h}-{i}-{s}');
  var data = (0, _fileUtil.parseFileName)(file.name);
  return "".concat(file.path).concat(newTime, "/").concat(handlePath(), ".").concat(data[1]);
}
/**
 * oss 文件上传工具。调用该函数后立刻开始上传文件。
 *
 * @param {?File} file File 对象
 * @param {?function} onProgress 进度回调函数，通过该函数回调下载进度
 * @param {?function} onComplete 完成回调函数，通过该函数回调下载结果
 * @returns {object} client 对象，通过该对象可以停止下载
 */


function fileUploader(file) {
  var onProgress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var onComplete = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};

  try {
    var path = ossPath(file);
    var config;

    if (file.path && file.path === 'InvoiceimgList/') {
      config = ossConfigInvoice;
    }

    if (file.path && file.path === 'fileList/') {
      config = ossConfig;
    }

    var client = new _aliOss.default(_objectSpread(_objectSpread({}, config), {}, {
      accessKeyId: config.banana,
      accessKeySecret: config.apple
    }));
    client.multipartUpload("".concat(process.env.VUE_APP_OSS_PATH, "/").concat(path), file, {
      progress: onProgress
    }) // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
    .then(function (_ref) {
      var name = _ref.name;

      if (name === 'cancel') {
        // 取消上传
        onComplete(_dictionary.uploadStatus.CANCELED);
      } else {
        // 上传成功
        onComplete(_dictionary.uploadStatus.SUCCEEDED, path);
      }
    }).catch(function (e) {
      console.warn(e && e.message);
      onComplete(_dictionary.uploadStatus.FAILED);
    });
    return client;
  } catch (e) {
    console.warn(e && e.message);
    console.log('上传文件之前就发生了报错，此时认为文件上传失败');
    onComplete(_dictionary.uploadStatus.FAILED);
  }
}