"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _index = _interopRequireDefault(require("@/components/SearchOrdinaryuser/index.vue"));

var _common = require("../../../utils/common");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'ServiceOrderTop',
  components: {
    SearchUserByInput: _index.default
  },
  props: {
    tagsName: {
      type: String,
      default: 'awaitDeal'
    },
    searchClassList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      query: {
        workOrderOpenId: undefined,
        workorderDescribe: undefined,
        sortOrder: undefined,
        sortBy: undefined,
        priority: undefined,
        page: 1,
        size: 20
      },
      classificationId: [],
      popoverVisible: false,
      sortOrder: undefined,
      timer: [],
      priorityOptions: [{
        id: '0',
        value: '全部'
      }, {
        id: '1',
        value: '低'
      }, {
        id: '2',
        value: '中'
      }, {
        id: '3',
        value: '高'
      }, {
        id: '4',
        value: '紧急'
      }]
    };
  },
  computed: {
    searchOrderId: {
      get: function get() {
        return this.query.workOrderOpenId;
      },
      set: function set(val) {
        this.query.workOrderOpenId = val.trim();
      }
    },
    searchVal: {
      get: function get() {
        return this.query.workorderDescribe;
      },
      set: function set(val) {
        this.query.workorderDescribe = val.trim();
      }
    },
    searchPriority: {
      get: function get() {
        return this.query.priority;
      },
      set: function set(val) {
        this.query.priority = val;
      }
    }
  },
  methods: {
    // 处理选择
    handleSelect: function handleSelect(val) {
      if (val === 1) {
        this.query.sortOrder = 'desc';
        this.query.sortBy = 'priority_num';
      }

      if (val === 2) {
        this.query.sortOrder = 'asc';
        this.query.sortBy = 'priority_num';
      }

      if (val === 3) {
        this.query.sortOrder = 'desc';
        this.query.sortBy = 'created_at';
      }

      if (val === 4) {
        this.query.sortOrder = 'asc';
        this.query.sortBy = 'created_at';
      }

      if (!val) {
        this.query.sortOrder = undefined;
        this.query.sortBy = undefined;
      }

      this.$emit('sortSearch', this.query);
    },
    // 刷新
    handleRefresh: function handleRefresh() {
      this.$emit('handleRefresh');
    },
    categoryChange: function categoryChange(val) {
      if (val.length > 0) this.query.workorderClassificationId = val[val.length - 1];
      if (!val || val.length === 0) this.query.workorderClassificationId = undefined;
    },
    addOrder: function addOrder() {
      this.$emit('addOrder');
    },
    search: function search() {
      if (!this.timer) this.timer = [];
      this.query.createdStartTime = this.timer.length >= 1 ? this.timer[0] : undefined;
      this.query.createdEndTime = this.timer.length >= 1 ? this.timer[1] : undefined;

      var query = _objectSpread({}, this.query);

      if (query.priority === '全部') {
        query.priority = undefined;
      }

      this.$emit('search', query);
      this.popoverVisible = false;
    },
    // 获取选择用户中 partnerOpenId
    receiveUserId: (0, _common._debounce)(function (val) {
      this.query.selectUserOpenId = val;
    }, 500),
    handlePopover: function handlePopover() {
      this.popoverVisible = false;
      this.sortOrder = undefined;
      this.query.workOrderOpenId = undefined;
      this.query.workorderDescribe = undefined;
      this.query.sortOrder = undefined;
      this.query.sortBy = undefined;
      this.query.priority = undefined;
      this.query.workorderClassificationId = undefined;
      this.$refs.searchUserByInput.clear();
      this.timer = [];
      this.classificationId = [];
    }
  }
};
exports.default = _default2;