var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "word-terms-link" },
    [
      _c(
        "el-table",
        {
          staticClass: "word-terms-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.valueProxy.list,
            size: "small",
            "header-cell-style": { background: "#F5F7FA" },
            border: "",
            height: "440px"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.type, align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(scope.row.name))]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "iconfont icon-copy encode-copy-icon",
                        on: {
                          click: function($event) {
                            return _vm.copy(scope.row.name, $event)
                          }
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.type === "单词" ? "词性" : "拼音",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.type === "单词"
                      ? _c(
                          "div",
                          [
                            scope.row.wordNatureList &&
                            scope.row.wordNatureList.length > 1
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "word-pinyin-select",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择词性"
                                    },
                                    model: {
                                      value: scope.row.natureCode,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "natureCode", $$v)
                                      },
                                      expression: "scope.row.natureCode"
                                    }
                                  },
                                  _vm._l(scope.row.wordNatureList, function(
                                    item1,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: "word_" + index,
                                      attrs: {
                                        label: item1.name,
                                        value: item1.wordNatureCode
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("span", { staticClass: "one-word" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getWordNature(
                                        scope.row.wordNatureList
                                      )
                                    )
                                  )
                                ])
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            scope.row.pinyinList &&
                            scope.row.pinyinList.length > 1
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "word-pinyin-select",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择拼音"
                                    },
                                    model: {
                                      value: scope.row.pinyinId,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "pinyinId", $$v)
                                      },
                                      expression: "scope.row.pinyinId"
                                    }
                                  },
                                  _vm._l(scope.row.pinyinList, function(
                                    item1,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: "word_" + index,
                                      attrs: {
                                        label: item1.pinyin,
                                        value: item1.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("span", { staticClass: "one-word" }, [
                                  _vm._v(
                                    _vm._s(_vm.getPinyin(scope.row.pinyinList))
                                  )
                                ])
                          ],
                          1
                        )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "110", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticClass: "common-link edit-margin",
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.replaceRow(scope.row, scope.$index)
                          }
                        }
                      },
                      [_vm._v("替换")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "common-link",
                        attrs: { type: "danger", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.delRow(scope.$index)
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "div",
            {},
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "", size: "mini" },
                  on: { click: _vm.addBtn }
                },
                [_vm._v("添加" + _vm._s(_vm.type))]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "select",
                  attrs: { size: "mini", placeholder: "请选择" },
                  model: {
                    value: _vm.valueProxy.toolType,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "toolType", $$v)
                    },
                    expression: "valueProxy.toolType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "默认", value: null } }),
                  _vm._v(" "),
                  _vm.type === "单词" || _vm.type === "词语"
                    ? _c("el-option", {
                        attrs: { label: "听写", value: "dictation" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "单词"
                    ? _c("el-option", {
                        attrs: { label: "跟读", value: "followUp" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "单词"
                    ? _c("el-option", {
                        attrs: { label: "单词抓螃蟹", value: "crabGame" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "单词"
                    ? _c("el-option", {
                        attrs: {
                          label: "单词消消乐",
                          value: "eliminatePleasure"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "单词"
                    ? _c("el-option", {
                        attrs: { label: "单词速记", value: "wordShorthand" }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "danger", plain: "", size: "mini" },
              on: { click: _vm.deleteAll }
            },
            [_vm._v("全部移除")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.deskDialogShow
        ? _c("select-front-desk", {
            attrs: {
              "desk-dialog-show": _vm.deskDialogShow,
              "check-list": _vm.checkList,
              "show-type": _vm.showType,
              visible: _vm.deskDialogShow,
              "replace-map": _vm.replaceMap,
              placeholder: "搜索" + _vm.type,
              title: "选择" + _vm.type,
              type: _vm.wordType
            },
            on: {
              "update:visible": function($event) {
                _vm.deskDialogShow = $event
              },
              getSelectWord: _vm.getSelectWord,
              getReplace: _vm.getReplace,
              "change-show": _vm.parentEvent
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }