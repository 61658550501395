var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "playout-card", class: _vm.layoutClass },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", { staticClass: "header-text" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }