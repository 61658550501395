var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            disabled: _vm.allowEdit,
            multiple: "",
            placeholder: "请选择应用"
          },
          on: { change: _vm.changeProduct },
          model: {
            value: _vm.appProductOpenIds,
            callback: function($$v) {
              _vm.appProductOpenIds = $$v
            },
            expression: "appProductOpenIds"
          }
        },
        _vm._l(_vm.productOption, function(item) {
          return _c(
            "el-option",
            { key: item.id, attrs: { label: item.title, value: item.openId } },
            [_vm._v("\n      " + _vm._s(item.title) + "\n    ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }