var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "消息通知设置",
        "close-on-click-modal": false,
        "append-to-body": true,
        visible: _vm.valueProxy,
        width: "800px",
        "custom-class": "my-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialog_body" }, [
        _c(
          "div",
          { staticClass: "dialog-body-table-layout" },
          [
            _c(
              "el-table",
              {
                staticClass: "my-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.messageTmpList,
                  border: "",
                  "max-height": _vm.myTableMaxHeight
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "45" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "type", label: "分类", width: "180" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "title", label: "名称", "min-width": "180" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "70", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.have
                            ? _c("div", { staticClass: "my-configured" }, [
                                _vm._v("已配置")
                              ])
                            : _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading: scope.row.id === _vm.oneSetLoading,
                                    type: "text",
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onOneSet(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("配置")]
                              )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.valueProxy = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onAllSet } },
            [_vm._v("一键配置")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }