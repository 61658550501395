var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { disabled: "disabled" } },
    [
      _c("div", { staticClass: "add-title" }, [
        _c(
          "label",
          {
            staticClass: "title",
            class: [
              _vm.required ? _vm.required : "",
              _vm.type === "phoneticSignCodes" ? "default-title" : ""
            ],
            style: _vm.stateType ? "width: 36px;text-align: left;" : ""
          },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm._v(" "),
        !_vm.editorType
          ? _c(
              "div",
              {
                staticClass: "add-input",
                style:
                  _vm.type === "phoneticSignCodes" || _vm.stateType
                    ? "width: 88%;"
                    : ""
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: _vm.placeholder, size: "small" },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        slot: "append",
                        size: "mini",
                        icon: "el-icon-edit-outline"
                      },
                      on: { click: _vm.richText },
                      slot: "append"
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.tooltipType
                  ? _c(
                      "el-tooltip",
                      {
                        staticClass: "item-tooltip",
                        attrs: { effect: "dark", content: "", placement: "top" }
                      },
                      [_c("i", { staticClass: "icon el-icon-info" })]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.editorType
          ? _c(
              "div",
              { staticClass: "rich-box" },
              [
                _c("div", { staticClass: "editor-text-box" }, [
                  _c("div", {
                    style: _vm.editorContentStyle,
                    domProps: { innerHTML: _vm._s(_vm.value) }
                  })
                ]),
                _vm._v(" "),
                _c("el-button", {
                  staticClass: "rich-btn",
                  attrs: {
                    slot: "append",
                    size: "mini",
                    icon: "el-icon-edit-outline"
                  },
                  on: { click: _vm.richText },
                  slot: "append"
                }),
                _vm._v(" "),
                _vm.editorType && !_vm.tooltipType
                  ? _c(
                      "el-tooltip",
                      {
                        staticClass: "item-tooltip",
                        attrs: { effect: "dark", content: "", placement: "top" }
                      },
                      [_c("i", { staticClass: "icon el-icon-info" })]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.innerVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "rich-text-editor-box",
              attrs: {
                width: "70%",
                title: "编辑文本",
                visible: _vm.innerVisible,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.innerVisible = $event
                },
                close: _vm.closeDialog
              }
            },
            [
              _c("editor", {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.content,
                  callback: function($$v) {
                    _vm.content = $$v
                  },
                  expression: "content"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "button-box" },
                [
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.commit } },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }