var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "container-top" },
        [
          _c("search-user-by-input", {
            on: { receiveuserid: _vm.receiveUserId }
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
              "picker-options": _vm.pickerOptions,
              clearable: false
            },
            model: {
              value: _vm.time,
              callback: function($$v) {
                _vm.time = $$v
              },
              expression: "time"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "container-button",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.refreshConversation()
                }
              }
            },
            [_vm._v("搜索")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ]
        },
        [
          _c(
            "el-table",
            {
              staticClass: "container-table",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "conversationContent", label: "问题内容" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(scope.row.conversationContent)
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "contentNum", label: "内容量", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-user-info" },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "userCover",
                                attrs: {
                                  src: _vm.getImageUrl(
                                    scope.row.userInfo.cover
                                  ),
                                  alt: "",
                                  fit: "cover"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "userCover",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.userInfo.nickName) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createdAt", label: "创建时间" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "详情", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.showDetailDialog(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.getConversationParams.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.getConversationParams.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.totalElements
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            title: "问题详情",
            visible: _vm.dialogDetailVisible,
            width: "60%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDetailVisible = $event
            },
            close: function($event) {
              return _vm.hideDetailDialog()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialog-body" },
            [
              _c("conversation-detail", {
                attrs: {
                  "conversation-id": _vm.conversationId,
                  "search-params": _vm.getConversationParams
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.getQueryConversationId("last")
                    }
                  }
                },
                [_vm._v("上一个")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.getQueryConversationId("next")
                    }
                  }
                },
                [_vm._v("下一个")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }