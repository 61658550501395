var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "micro-page-add-class",
      attrs: {
        title: _vm.title,
        width: "450px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.cancelDialogue,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.valueProxy,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "父分类" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.valueProxy.parentName,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "parentName", $$v)
                  },
                  expression: "valueProxy.parentName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分类名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.valueProxy.name,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "name", $$v)
                  },
                  expression: "valueProxy.name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelDialogue } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }