"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileUpload = fileUpload;
exports.fileUploadExcelPost = fileUploadExcelPost;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var _uploadStatus = _interopRequireDefault(require("../store/modules/uploadStatus"));

function fileUpload(formData) {
  var config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 1000 * 60 * 10,
    // 添加上传进度监听事件
    onUploadProgress: function onUploadProgress(e) {
      var completeProgress = e.loaded / e.total * 100 | 0;
      _uploadStatus.default.state.percent = parseInt(completeProgress);

      if (_uploadStatus.default.state.percent >= 100) {
        _uploadStatus.default.state.showGeneratePageTip = true;
        _uploadStatus.default.state.message = '上传成功, 正在生成书页 ...';
        _uploadStatus.default.state.loading = true;
      }
    }
  };
  _uploadStatus.default.state.showDialog = true;
  var selfRequest = (0, _main.default)(process.env.VUE_APP_BASE_API, config);
  return selfRequest.post('/audiobook/fileUpload', formData);
}

function fileUploadExcelPost(formData) {
  var config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 1000 * 60 * 10
  };
  var selfRequest = (0, _main.default)(process.env.VUE_APP_BASE_API, config);
  return selfRequest.post('/order/admin/import/orderForm/csv', formData);
}