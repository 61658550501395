"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAlbumProductAttr = getAlbumProductAttr;
exports.setAlbumProductAttr = setAlbumProductAttr;
exports.getVideoProductAttr = getVideoProductAttr;
exports.setVideoProductAttr = setVideoProductAttr;
exports.getBookProductAttr = getBookProductAttr;
exports.setBookProductAttr = setBookProductAttr;
exports.setAudioProductAttr = setAudioProductAttr;
exports.getAudioProductAttr = getAudioProductAttr;
exports.setLiveProductAttr = setLiveProductAttr;
exports.getLiveProductAttr = getLiveProductAttr;
exports.setFileProductAttr = setFileProductAttr;
exports.getFileProductAttr = getFileProductAttr;
exports.setTextProductAttr = setTextProductAttr;
exports.getTextProductAttr = getTextProductAttr;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 缓存数据
 * @param {String} name
 * @param {*} data
 */
function setLocalStorage(name, data) {
  if (!name) {
    throw new Error('localStorage存储，name值不能为空');
  }

  if (!data) {
    throw new Error('localStorage存储，值不能为空');
  }

  var obj = JSON.stringify(data);
  window.localStorage.setItem(name, obj);
}
/**
 * 获取缓存的数据
 * @param name
 * @returns {null|Obj}
 */


function getLocalStorage(name) {
  if (!name) {
    throw new Error('localStorage获取，name值不能为空');
  }

  var data = window.localStorage.getItem(name);

  if (data) {
    return JSON.parse(data);
  }

  return null;
}
/**
 * 获取专辑缓存的商品属性值
 * @returns {Obj}
 */


function getAlbumProductAttr() {
  return getLocalStorage('albumProductAttr');
}
/**
 * 缓存专辑商品属性值
 * @param {Object} data
 */


function setAlbumProductAttr(data) {
  return setLocalStorage('albumProductAttr', _objectSpread({}, data));
}
/**
 * 获取视频的缓存商品属性值
 * @returns {Obj}
 */


function getVideoProductAttr() {
  return getLocalStorage('videoProductAttr');
}
/**
 * 缓存视频商品属性值
 * @param {Object} data
 */


function setVideoProductAttr(data) {
  return setLocalStorage('videoProductAttr', _objectSpread({}, data));
}
/**
 * 获取点学书的缓存商品属性值
 * @returns {Obj}
 */


function getBookProductAttr() {
  return getLocalStorage('bookProductAttr');
}
/**
 * 缓存点学书商品属性值
 * @param {Object} data
 */


function setBookProductAttr(data) {
  return setLocalStorage('bookProductAttr', _objectSpread({}, data));
}
/**
 * 缓存音频商品属性值
 * @param {Object} data
 */


function setAudioProductAttr(data) {
  return setLocalStorage('audioProductAttr', _objectSpread({}, data));
}
/**
 * 获取音频的缓存商品属性值
 * @returns {Obj}
 */


function getAudioProductAttr() {
  return getLocalStorage('audioProductAttr');
}
/**
 * 缓存音频商品属性值
 * @param {Object} data
 */


function setLiveProductAttr(data) {
  return setLocalStorage('liveProductAttr', _objectSpread({}, data));
}
/**
 * 获取音频的缓存商品属性值
 * @returns {Obj}
 */


function getLiveProductAttr() {
  return getLocalStorage('liveProductAttr');
}
/**
 * 缓存文件商品属性值
 * @param {Object} data
 */


function setFileProductAttr(data) {
  return setLocalStorage('fileProductAttr', _objectSpread({}, data));
}
/**
 * 获取文件的缓存商品属性值
 * @returns {Obj}
 */


function getFileProductAttr() {
  return getLocalStorage('fileProductAttr');
}
/**
 * 缓存图文商品属性值
 * @param {Object} data
 */


function setTextProductAttr(data) {
  return setLocalStorage('textProductAttr', _objectSpread({}, data));
}
/**
 * 获取图文的缓存商品属性值
 * @returns {Obj}
 */


function getTextProductAttr() {
  return getLocalStorage('textProductAttr');
}