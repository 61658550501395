"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _read = require("../../utils/cache/read");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Index',
  props: {
    isDisabled: {
      type: Boolean,
      default: true
    },
    typeName: {
      type: String,
      default: '点读书'
    },
    value: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      tagOptions: []
    };
  },
  computed: {
    tagValue: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  created: function created() {
    this.initTagOption();
  },
  methods: {
    // 初始化tag标签下拉列表
    initTagOption: function initTagOption() {
      var _this = this;

      var obj = null;

      if (this.typeName === '点读书') {
        obj = (0, _read.get)('bookProductAttr');
      } else if (this.typeName === '音频') {
        obj = (0, _read.get)('audioProductAttr');
      } else if (this.typeName === '视频') {
        obj = (0, _read.get)('videoProductAttr');
      } else if (this.typeName === '直播') {
        obj = (0, _read.get)('liveProductAttr');
      } else if (this.typeName === '文件包') {
        obj = (0, _read.get)('fileProductAttr');
      } else if (this.typeName === '专辑') {
        obj = (0, _read.get)('albumProductAttr');
      } else if (this.typeName === '图文') {
        obj = (0, _read.get)('textProductAttr');
      } else if (this.typeName === '文件') {
        obj = (0, _read.get)('singleFileProductAttr');
      }

      if (obj) {
        var tags = obj.tags ? obj.tags.split(',') : [];
        tags.forEach(function (tag) {
          var item = {
            value: tag,
            label: tag
          };

          _this.tagOptions.push(item);
        });
      }
    }
  }
};
exports.default = _default;