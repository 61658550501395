"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.to-string");

var _vue = _interopRequireDefault(require("vue"));

var _fileSaver = require("file-saver");

var _videoManagement = require("@/api/videoManagement");

var _common = require("@/utils/common");

var _DotImport = _interopRequireDefault(require("./DotImport"));

var _DotExport = _interopRequireDefault(require("./DotExport"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DotDialog',
  components: {
    DotExport: _DotExport.default,
    importDotData: _DotImport.default
  },
  props: {
    dotisshow: {
      type: Boolean,
      default: false
    },
    content: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    videourl: {
      type: String,
      default: undefined
    },
    resourceOpenId: {
      type: String,
      default: ''
    },
    resourceTitle: {
      // 导出lrc 用到的文件名
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      formatLongToTimeStr2: _common.formatLongToTimeStr2,
      hoverTime: 0,
      date: 0,
      previewLength: 0,
      player: null,
      description: '',
      // arraycatch: [],
      videoTime: '00:00',
      // 输入的打点的时间
      videoTimeSeconds: 0,
      // 打点的秒数
      pickerOptions: {
        start: '00:00',
        step: '00:01',
        end: '60:00'
      },
      currentDotIndex: undefined,
      isBatchData: false,
      // 批量导入数据弹窗是否显示
      templateTime: '',
      // 临时文本
      tempDetail: '',
      // 临时描述
      openExport: false
    };
  },
  computed: {
    arraycatch: {
      get: function get() {
        return this.content;
      },
      set: function set(value) {
        this.$emit('update:content', value);
      }
    }
  },
  watch: {
    videourl: {
      immediate: true,
      handler: function handler(val) {
        if (val.toString().length <= 0) return;
        this.videourl = val;
        var tant = this; // 创建点播播放器

        this.videopaly(); // 获取视频时长

        var audioElement = new Audio(tant.videourl);
        audioElement.addEventListener('loadedmetadata', function () {
          tant.previewLength = parseInt(audioElement.duration);
        });
      }
    },
    playLength: {
      immediate: true,
      handler: function handler(val) {
        this.previewLength = val;
      }
    },
    resourceOpenId: {
      immediate: true,
      handler: function handler(val) {
        this.resourceOpenId = val;
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    // 初始化打点信息
    initDotList: function initDotList(dotList) {
      var _this2 = this;

      this.arraycatch = dotList; // 将tableList滚动到底部

      this.$nextTick(function () {
        var container = _this2.$el.querySelector('.el-table__body-wrapper');

        container.scrollTop = container.scrollHeight;
      });
    },
    // 全部导入
    handlerImportData: function handlerImportData() {
      this.isBatchData = true;
    },
    // 导出lrc
    handleExport: function handleExport() {
      this.openExport = true;
    },
    // 确认改变
    handleConfirm: function handleConfirm(item) {
      item.detail = this.tempDetail;
      item.time = (0, _common.dateToTimestamp)(this.templateTime);

      _vue.default.set(item);

      this.currentDotIndex = undefined;
    },
    // 取消
    handleCancel: function handleCancel() {
      this.currentDotIndex = undefined;
    },
    // 编辑
    handleEditVideo: function handleEditVideo(index, item) {
      this.currentDotIndex = index;
      item.tempDetail = item.detail;
      item.templateTime = (0, _common.formatLongToTimeStr2)(item.time);
      this.templateTime = item.templateTime;
      this.tempDetail = item.tempDetail;

      _vue.default.set(item);
    },
    // 全部清除
    delAllDot: function delAllDot() {
      var _this3 = this;

      this.$confirm('确定全部移除？').then(function (_) {
        _this3.arraycatch = [];
      });
    },
    // 改变打点功能
    changeDotTime: function changeDotTime(dotObj) {
      console.log('dotOBj....');
      console.log(dotObj);
    },
    // 创建打点信息
    createDot: function createDot() {
      var _this4 = this;

      // 判断当前时间点是否有打点信息
      var dotTime = (0, _common.dateToTimestamp)(this.videoTime); // 判断是否已经存在打点信息

      if (dotTime <= 0) {
        this.open('请输入时间点');
        return;
      }

      var isadd = this.content.find(function (x) {
        return x.time.toString() === dotTime.toString();
      });

      if (typeof isadd !== 'undefined') {
        this.open('已存在当前时间点');
        return;
      }

      this.arraycatch.push({
        videoOpenId: this.resourceOpenId,
        detail: '',
        tempDetail: '',
        time: dotTime,
        templateTime: (0, _common.formatLongToTimeStr2)(dotTime)
      }); // 将tableList滚动到底部

      this.$nextTick(function () {
        var container = _this4.$el.querySelector('.el-table__body-wrapper');

        container.scrollTop = container.scrollHeight;
      });
      console.log(this.content);
    },
    // 当时间抽上的值发生改变时
    changeTimeTip: function changeTimeTip() {
      this.videoTime = (0, _common.formatLongToTimeStr2)(this.videoTimeSeconds * 1000);
    },
    // 减少时间
    reduceTime: function reduceTime() {
      var millisecond = (0, _common.dateToTimestamp)(this.videoTime) / 1000; // 转化成毫秒

      if (!millisecond) return;
      millisecond -= 1;
      this.videoTime = (0, _common.formatLongToTimeStr2)(millisecond * 1000);
    },
    // 添加时间
    addTime: function addTime() {
      var millisecond = (0, _common.dateToTimestamp)(this.videoTime) / 1000; // 转化成毫秒

      millisecond += 1;
      this.videoTime = (0, _common.formatLongToTimeStr2)(millisecond * 1000);
    },
    // 根据秒数显示时间
    formatTimetip: function formatTimetip(val) {
      var timeStr = (0, _common.formatLongToTimeStr2)(val * 1000);
      return timeStr;
    },
    // 鼠标悬浮列
    mouseoverFun: function mouseoverFun(time) {
      this.hoverTime = time;
    },
    mouseoutFun: function mouseoutFun() {
      this.hoverId = 0;
    },
    videopaly: function videopaly() {
      var _this = this;

      _this.player = new window.Aliplayer({
        id: 'videodot',
        width: '352px',
        height: '198px',
        autoplay: false,
        source: this.videourl
      }, function (player) {});
    },
    dotTime: function dotTime(time) {
      return (0, _common.formatLongToTimeStr2)(time);
    },
    open: function open(message) {
      this.$message({
        message: message,
        type: 'success'
      });
    },
    addDate: function addDate() {
      //  点击添加时间点按钮时，先暂停视频
      if (this.player !== null) {
        this.player.pause();
      }

      this.date = parseInt(this.player.getCurrentTime());
    },
    adddescription: function adddescription() {
      var _this5 = this;

      if (this.date.toString().length <= 0) {
        this.open('请输入时间点');
        return;
      }

      if (this.description.toString().length <= 0) {
        this.open('请输入打点描述');
        return;
      }

      var isadd = this.content.find(function (x) {
        return x.time.toString() === (_this5.date * 1000).toString();
      });

      if (typeof isadd !== 'undefined') {
        this.open('已存在当前时间点');
      } else {
        this.arraycatch.push({
          videoOpenId: this.resourceOpenId,
          detail: this.description,
          time: this.date * 1000
        });
        this.date = 0;
        this.description = '';
      }
    },
    // 关闭弹层
    dotbtnshow: function dotbtnshow() {
      this.currentDotIndex = undefined;
      this.$emit('dotbtnshow', false);

      if (this.player !== null) {
        this.player.pause();
      }
    },
    // 点击确定
    addlistdate: function addlistdate() {
      console.log('this.arraycatch.....');
      console.log(this.arraycatch);
      this.$emit('addlistdate', this.arraycatch);
      this.$emit('dotbtnshow', false);

      if (this.player !== null) {
        this.player.pause();
      }
    },
    // 移除当前时间点
    deletedate: function deletedate(item) {
      var _this6 = this;

      var indexcatch = this.arraycatch.findIndex(function (x) {
        return x.time === item.time;
      });

      if (indexcatch !== -1) {
        this.arraycatch.splice(indexcatch, 1);
      }

      if (item.id !== undefined) {
        (0, _videoManagement.deleteVideoMark)(item.id).then(function (res) {
          _this6.open('删除成功');
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.open('删除成功');
      } // this.content[index] = undefined

    }
  }
};
exports.default = _default2;