var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "el-card",
        {
          attrs: {
            "body-style": {
              padding: "0",
              height: "calc(100vh - 124px)",
              display: "flex"
            }
          }
        },
        [
          _c("div", { staticClass: "catalogue" }, [
            _c("div", { staticClass: "nav-text" }, [_vm._v("素材文件")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "catalogue-content" },
              _vm._l(_vm.classList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    class: [
                      "classification-item",
                      _vm.activeOpenId === item.materialClassificationOpenId
                        ? "active-classification-item"
                        : ""
                    ],
                    on: {
                      click: function($event) {
                        return _vm.changeOpenId(item)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "classification-title" }, [
                      _vm.activeOpenId === item.materialClassificationOpenId
                        ? _c("i", {
                            staticClass: "el-icon-folder-opened active-icon"
                          })
                        : _c("i", { staticClass: "el-icon-folder icon" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.classificationName))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.fileNum))])
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("material-content", {
            staticClass: "image-list",
            attrs: {
              "upload-function": _vm.uploadFunction,
              "upload-path": _vm.uploadPath,
              "partner-open-id": _vm.$route.query.partnerOpenId,
              "open-id": _vm.activeOpenId,
              "is-personal": true,
              "is-dialogue": false
            },
            on: { refreshCatalogue: _vm.getClassificationList }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }