var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "special-page" },
    [
      _vm.multipleCheck
        ? _c(
            "el-checkbox-group",
            {
              staticClass: "radio-group",
              model: {
                value: _vm.value.list,
                callback: function($$v) {
                  _vm.$set(_vm.value, "list", $$v)
                },
                expression: "value.list"
              }
            },
            [
              _c(
                "el-checkbox",
                { staticClass: "radio", attrs: { label: "口算APP" } },
                [_vm._v("口算APP")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                { staticClass: "radio", attrs: { label: "人气讲师" } },
                [_vm._v("人气讲师")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                { staticClass: "radio", attrs: { label: "客服" } },
                [_vm._v("客服")]
              )
            ],
            1
          )
        : _c(
            "el-radio-group",
            {
              staticClass: "radio-group",
              model: {
                value: _vm.value.specialPageLink,
                callback: function($$v) {
                  _vm.$set(_vm.value, "specialPageLink", $$v)
                },
                expression: "value.specialPageLink"
              }
            },
            [
              _c(
                "el-radio",
                {
                  staticClass: "radio",
                  attrs: { label: "口算APP" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickRadio("口算APP")
                    }
                  }
                },
                [_vm._v("口算APP")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticClass: "radio",
                  attrs: { label: "人气讲师" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickRadio("人气讲师")
                    }
                  }
                },
                [_vm._v("人气讲师")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticClass: "radio",
                  attrs: { label: "客服" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickRadio("客服")
                    }
                  }
                },
                [_vm._v("客服")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }