var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selectQuestion" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "select-son-question",
          attrs: {
            width: "1100px",
            title: "选择试题",
            visible: _vm.dialogTableVisible,
            "close-on-click-modal": false,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "添加试题", name: "addQuestion" } },
                [
                  _c("add-question", {
                    attrs: {
                      "paper-info": _vm.paperInfo,
                      "if-add-son-compose": _vm.ifSonCompose,
                      operationCode: _vm.operationCode,
                      type: _vm.type,
                      merchantOpenId: _vm.merchantOpenId,
                      ifCompose: _vm.ifCompose
                    },
                    on: { addSonDialogChange: _vm.addSonDialogChange }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "题库选择", name: "selectQuestion" } },
                [
                  _c("div", { staticClass: "list" }, [
                    _c("div", { staticClass: "listTop" }, [
                      _c(
                        "div",
                        { staticClass: "listTopLeft" },
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择分类",
                              options: _vm.questionClass,
                              props: _vm.classProps,
                              clearable: ""
                            },
                            on: { change: _vm.getQuestionList },
                            model: {
                              value: _vm.query.categoryOpenId,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "categoryOpenId", $$v)
                              },
                              expression: "query.categoryOpenId"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "type-code",
                              attrs: {
                                clearable: "",
                                placeholder: "请选择题型",
                                size: "medium"
                              },
                              on: { change: _vm.getQuestionList },
                              model: {
                                value: _vm.query.typeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "typeCode", $$v)
                                },
                                expression: "query.typeCode"
                              }
                            },
                            _vm._l(_vm.questionType, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "listTopRight" },
                        [
                          _c("el-input", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              clearable: "",
                              size: "medium",
                              placeholder: "试题名称搜索"
                            },
                            on: { change: _vm.getQuestionList },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchContent($event)
                              }
                            },
                            model: {
                              value: _vm.query.stem,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "stem", $$v)
                              },
                              expression: "query.stem"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "searchbtn",
                            {
                              staticClass: "searchBtn",
                              on: { searchContent: _vm.searchContent }
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  staticStyle: { width: "450px" },
                                  attrs: { "label-width": "80px" }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "同步信息" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "sync-info" },
                                        [
                                          _c("select-param-sync-info", {
                                            staticClass: "sync-select",
                                            attrs: {
                                              "query-option": "FindGradeList",
                                              placeholder: "年级"
                                            },
                                            model: {
                                              value: _vm.query.gradeCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.query,
                                                  "gradeCode",
                                                  $$v
                                                )
                                              },
                                              expression: "query.gradeCode"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("select-param-sync-info", {
                                            staticClass: "sync-select",
                                            attrs: {
                                              "query-option": "FindJDList",
                                              placeholder: "阶段"
                                            },
                                            model: {
                                              value: _vm.query.volumeCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.query,
                                                  "volumeCode",
                                                  $$v
                                                )
                                              },
                                              expression: "query.volumeCode"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("select-param-sync-info", {
                                            staticClass: "sync-select",
                                            attrs: {
                                              "query-option": "FindSubjectList",
                                              placeholder: "学科"
                                            },
                                            model: {
                                              value: _vm.query.subjectCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.query,
                                                  "subjectCode",
                                                  $$v
                                                )
                                              },
                                              expression: "query.subjectCode"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("select-param-sync-info", {
                                            staticClass: "sync-select",
                                            attrs: {
                                              "query-option": "FindBBList",
                                              placeholder: "版本"
                                            },
                                            model: {
                                              value: _vm.query.editionCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.query,
                                                  "editionCode",
                                                  $$v
                                                )
                                              },
                                              expression: "query.editionCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "版次" } },
                                    [
                                      _c("select-param-sync-info", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          "query-option": "FindAll",
                                          placeholder: "版次"
                                        },
                                        model: {
                                          value: _vm.query.periodCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.query,
                                              "periodCode",
                                              $$v
                                            )
                                          },
                                          expression: "query.periodCode"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "标签" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入标签" },
                                        model: {
                                          value: _vm.query.tags,
                                          callback: function($$v) {
                                            _vm.$set(_vm.query, "tags", $$v)
                                          },
                                          expression: "query.tags"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "难易" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "全部",
                                            size: "medium",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.query.difficultDegree,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.query,
                                                "difficultDegree",
                                                $$v
                                              )
                                            },
                                            expression: "query.difficultDegree"
                                          }
                                        },
                                        _vm._l(
                                          _vm.difficultDegreeList,
                                          function(item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.value
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "知识点" } },
                                    [
                                      _c("el-cascader", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          props: _vm.knowledgeProps,
                                          options: _vm.knowledgeList,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.query.knowledgeOpenId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.query,
                                              "knowledgeOpenId",
                                              $$v
                                            )
                                          },
                                          expression: "query.knowledgeOpenId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-left": "10px" },
                              on: { change: _vm.handleRemember },
                              model: {
                                value: _vm.remember,
                                callback: function($$v) {
                                  _vm.remember = $$v
                                },
                                expression: "remember"
                              }
                            },
                            [_vm._v("记住所选")]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "questionTable" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.tableLoading,
                                expression: "tableLoading"
                              }
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: { height: "400px", data: _vm.questionList },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                selectable: _vm.selectable
                              }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "试题",
                                prop: "stem",
                                width: "360"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "stem-type" },
                                        [
                                          _c(
                                            "el-tag",
                                            { attrs: { size: "mini" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.questionTypeName
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "questionStem",
                                            domProps: {
                                              innerHTML: _vm._s(scope.row.stem)
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "难易",
                                prop: "difficultDegree",
                                width: "80"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.difficultDegree === "0" ||
                                      !scope.row.difficultDegree
                                        ? _c("span", [_vm._v("暂无")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.difficultDegree === "1"
                                        ? _c("span", [_vm._v("很简单")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.difficultDegree === "2"
                                        ? _c("span", [_vm._v("简单")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.difficultDegree === "3"
                                        ? _c("span", [_vm._v("一般")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.difficultDegree === "4"
                                        ? _c("span", [_vm._v("困难")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.difficultDegree === "5"
                                        ? _c("span", [_vm._v("十分困难")])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "属性",
                                prop: "syncInfo",
                                width: "230"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "标签" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "3px",
                                            color: "#4da4ff"
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.tags))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tableBottom" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { indeterminate: _vm.indeterminate },
                          on: {
                            change: function($event) {
                              return _vm.toggleSelection(_vm.questionList)
                            }
                          },
                          model: {
                            value: _vm.checked,
                            callback: function($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked"
                          }
                        },
                        [_c("span", [_vm._v("全选")])]
                      ),
                      _vm._v(" "),
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.query.current,
                          "page-sizes": [10, 20, 30, 50],
                          "page-size": _vm.query.pageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.pageTotal
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.directoryDialogChange } },
                        [_vm._v("\n            取消\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.selectSubmit }
                        },
                        [_vm._v("\n            确定\n          ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }