"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryWordList = queryWordList;
exports.getWordDetail = getWordDetail;
exports.getWordNatureList = getWordNatureList;
exports.addWord = addWord;
exports.checkWord = checkWord;
exports.deleteWord = deleteWord;
exports.updateWord = updateWord;
exports.addWordNature = addWordNature;
exports.updateWordNature = updateWordNature;
exports.deleteWordNature = deleteWordNature;
exports.downLoadWordList = downLoadWordList;
exports.importGoods = importGoods;
exports.getDetail = getDetail;
exports.getMerchantWordGroup = getMerchantWordGroup;
exports.getClientWord = getClientWord;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/"));
var requestClient = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/client/"));
/* 获取单词列表 */

function queryWordList(params) {
  return request.get('word/findPage', {
    params: params
  });
}
/* 查询单词详情 */


function getWordDetail(params) {
  return request.get('word/findOne', {
    params: params
  });
}
/* 获取单词库词性列表 */


function getWordNatureList() {
  return request.get('wordNature/wordNatureList');
}
/* 创建单词 */


function addWord(data, params) {
  return request.post('word/addWord', data, {
    params: params
  });
}
/* 删除单词 */


function deleteWord(wordOpenId) {
  return request.delete("word/deleteWord/".concat(wordOpenId));
}
/* 更新单词 */


function updateWord(data, params) {
  return request.put("word/updateWord/".concat(data.wordOpenId), data, {
    params: params
  });
}
/* 添加单词的词性信息 */


function addWordNature(data) {
  return request.post("wordRelationNature/addWordRelationNature/".concat(data.wordOpenId), data);
}
/* 更新单词词性信息 */


function updateWordNature(data) {
  return request.put("wordRelationNature/updateWordRelationNature/".concat(data.id), data);
}
/* 删除单词词性信息 */


function deleteWordNature(id) {
  return request.delete("wordRelationNature/deleteWordRelationNature/".concat(id));
}
/* 校验单词是否存在 */


function checkWord(data) {
  return request.get('word/findOne', {
    params: data
  });
}
/* 导出单词列表 */


function downLoadWordList(params) {
  return request.get('word/downLoadWordList', {
    params: params
  });
}
/* 导入单词 */


function importGoods(data) {
  return request.post('word/importGoods', data);
}
/* 查询单词详情 */


function getDetail(wordOpenId) {
  return request.get('word/findOne', {
    params: {
      wordOpenId: wordOpenId
    }
  });
}
/* 查询商户单词分组 */


function getMerchantWordGroup(params) {
  return request.get('wordGroup/list', {
    params: params
  });
}

function getClientWord(params) {
  return requestClient.post('word/getClientWord', params);
}