var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 300px)", overflow: "auto" },
          attrs: { id: "scrollDrag" }
        },
        _vm._l(_vm.questionList, function(item, index) {
          return _c(
            "div",
            {
              key: item.id,
              ref: "scrollDragItem",
              refInFor: true,
              staticClass: "drag",
              style: {
                height: item.isShrink ? "auto" : "138px",
                border:
                  _vm.activeGroup === item.id.toString()
                    ? "2px solid #409EFF"
                    : ""
              },
              attrs: { id: item.id.toString() }
            },
            [
              _c("div", { staticClass: "dragTop" }, [
                _c(
                  "div",
                  { staticClass: "dragLeft" },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100%", "margin-bottom": "10px" },
                      attrs: {
                        disabled: _vm.isDisable,
                        size: "mini",
                        placeholder: "请输入试题组名称"
                      },
                      on: {
                        change: function($event) {
                          return _vm.groupChange(item, index)
                        }
                      },
                      model: {
                        value: item.name,
                        callback: function($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "100%", "margin-bottom": "10px" },
                      attrs: {
                        disabled: _vm.isDisable,
                        size: "mini",
                        placeholder: "请输入提示信息",
                        "prefix-icon": "el-icon-info"
                      },
                      on: {
                        change: function($event) {
                          return _vm.groupChange(item, index)
                        }
                      },
                      model: {
                        value: item.tips,
                        callback: function($$v) {
                          _vm.$set(item, "tips", $$v)
                        },
                        expression: "item.tips"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "select-question" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "calc(100% - 95px)" },
                            attrs: {
                              disabled: _vm.isDisable,
                              placeholder: "选择试题来源方式",
                              size: "mini"
                            },
                            on: {
                              change: function($event) {
                                return _vm.groupChange(item, index)
                              }
                            },
                            model: {
                              value: item.source,
                              callback: function($$v) {
                                _vm.$set(item, "source", $$v)
                              },
                              expression: "item.source"
                            }
                          },
                          _vm._l(_vm.questionSource, function(
                            source,
                            sourceIndex
                          ) {
                            return _c("el-option", {
                              key: sourceIndex,
                              attrs: { label: source.name, value: source.value }
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.isDisable,
                              type: "primary",
                              plain: "",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectQuestion(item, index)
                              }
                            }
                          },
                          [_vm._v("添加试题")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dragRight" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "收起大题、展开大题",
                          placement: "right-end"
                        }
                      },
                      [
                        _c("i", {
                          class: item.isShrink
                            ? "el-icon-s-unfold"
                            : "el-icon-s-fold",
                          on: {
                            click: function($event) {
                              return _vm.isShrink(index, !item.isShrink)
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "删除大题",
                          placement: "right-end"
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "paper-svg",
                          style: {
                            cursor: _vm.isDisable ? "not-allowed" : "pointer"
                          },
                          attrs: { "icon-class": "delete" },
                          on: {
                            click: function($event) {
                              return _vm.deletedGroupSubmit(item, index)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "所有试题显示全部、所有试题显示题干",
                          placement: "right-end"
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "paper-svg",
                          style: {
                            cursor: _vm.isDisable ? "not-allowed" : "pointer",
                            transform: item.groupShowOptions
                              ? "rotate(180deg)"
                              : ""
                          },
                          attrs: { "icon-class": "paperUp" },
                          on: {
                            click: function($event) {
                              return _vm.handleGroupSeeALL(
                                index,
                                item.groupShowOptions
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              item.examPaperQuestionsList &&
              item.examPaperQuestionsList.length > 0
                ? _c(
                    "div",
                    { staticClass: "dragBottom" },
                    _vm._l(item.examPaperQuestionsList, function(
                      question,
                      questionIndex
                    ) {
                      return _c(
                        "div",
                        {
                          key: questionIndex,
                          class: [
                            question.examQuestionOpenId === _vm.operationOpenId
                              ? "active-operation"
                              : "",
                            "questionlistTop"
                          ]
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "common-flex nav-question-list" },
                            [
                              _c("div", { staticClass: "nav-info" }, [
                                _c("span", [
                                  _vm._v("试题" + _vm._s(questionIndex + 1))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "question-type-name" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getQuestionTypeName(
                                          question.examQuestionTypeCode
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "knowledge" },
                                  _vm._l(question.knowledgeList, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: index,
                                        staticClass: "item-tag",
                                        attrs: { size: "mini" }
                                      },
                                      [_vm._v(_vm._s(item.knowledgeName))]
                                    )
                                  }),
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "operate-question" },
                                [
                                  _c("svg-icon", {
                                    class: [
                                      _vm.isDisable
                                        ? "disableSvgIcon"
                                        : "svgIcon"
                                    ],
                                    attrs: { "icon-class": "pencil" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editQuestion(question)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("svg-icon", {
                                    class: [
                                      _vm.isDisable
                                        ? "disableSvgIcon"
                                        : "svgIcon"
                                    ],
                                    staticStyle: { margin: "0 5px" },
                                    attrs: { "icon-class": "deleteLight" },
                                    on: {
                                      click: function($event) {
                                        return _vm.delQuestion(question)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  questionIndex > 0
                                    ? _c("svg-icon", {
                                        class: [
                                          _vm.isDisable
                                            ? "disableSvgIcon"
                                            : "svgIcon"
                                        ],
                                        staticStyle: {
                                          transform: "rotate(90deg)"
                                        },
                                        attrs: { "icon-class": "up" },
                                        on: {
                                          click: function($event) {
                                            return _vm.moverQuestion(
                                              question,
                                              0,
                                              index,
                                              questionIndex
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  questionIndex <
                                  item.examPaperQuestionsList.length - 1
                                    ? _c("svg-icon", {
                                        class: [
                                          _vm.isDisable
                                            ? "disableSvgIcon"
                                            : "svgIcon"
                                        ],
                                        staticStyle: {
                                          transform: "rotate(-90deg)"
                                        },
                                        attrs: { "icon-class": "up" },
                                        on: {
                                          click: function($event) {
                                            return _vm.moverQuestion(
                                              question,
                                              1,
                                              index,
                                              questionIndex
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "stem",
                            domProps: { innerHTML: _vm._s(question.stem) }
                          }),
                          _vm._v(" "),
                          question.showOptions
                            ? _c("div", { staticClass: "question-options" }, [
                                question.examQuestionTypeCode === 1001 ||
                                question.examQuestionTypeCode === 1002
                                  ? _c(
                                      "div",
                                      { staticClass: "optionBox" },
                                      [
                                        _vm._l(question.optionText[0], function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "item htmlItem"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "optionItem" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      index.toUpperCase()
                                                    ) + ": "
                                                  ),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(item)
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "answer" }, [
                                          _vm._v(
                                            "\n                正确答案: " +
                                              _vm._s(
                                                question.rightAnswerContent
                                                  ? question.rightAnswerContent.toUpperCase()
                                                  : "--"
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1003
                                  ? _c(
                                      "div",
                                      { staticClass: "optionBox" },
                                      [
                                        _vm._l(question.optionText[0], function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "item" },
                                            [
                                              _c("div", {
                                                staticClass: "optionItem",
                                                domProps: {
                                                  innerHTML: _vm._s(item)
                                                }
                                              })
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "answer judge-flex" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "judge-title" },
                                              [_vm._v("正确答案:")]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  question.rightAnswerContent ===
                                                    "no"
                                                    ? question.optionText[0].b
                                                    : question.optionText[0].a
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1006
                                  ? _c(
                                      "div",
                                      { staticClass: "optionBox" },
                                      [
                                        _vm._l(question.optionText[0], function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "item pxItem"
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(_vm._s(index) + ":")
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "px",
                                                domProps: {
                                                  innerHTML: _vm._s(item)
                                                }
                                              })
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "answer write" },
                                          [
                                            _vm._v(
                                              "\n                正确答案: " +
                                                _vm._s(
                                                  question.rightAnswerContent
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1007
                                  ? _c(
                                      "div",
                                      { staticClass: "optionBox" },
                                      [
                                        _vm._l(question.optionList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "item" },
                                            [
                                              _c("div", [
                                                _vm._v(_vm._s(index + 1) + ".")
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(item, function(
                                                secondItem,
                                                secondIndex
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: secondIndex,
                                                    staticClass: "optionItem"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          secondIndex.toUpperCase()
                                                        ) +
                                                        ":" +
                                                        _vm._s(secondItem) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "answer" },
                                          [
                                            _c("div", [_vm._v("正确答案: ")]),
                                            _vm._v(" "),
                                            _vm._l(
                                              question.rightAnswerContent[0],
                                              function(answer, answerIndex) {
                                                return _c(
                                                  "div",
                                                  { key: answerIndex },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(answerIndex) +
                                                        "." +
                                                        _vm._s(
                                                          answer.toUpperCase()
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1008
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "answer write" },
                                        [
                                          _vm._v(
                                            "\n                正确答案: "
                                          ),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                question.rightAnswerContent
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1005
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "answer write" },
                                        [
                                          _vm._v(
                                            "\n                正确答案:\n                "
                                          ),
                                          _vm._l(question.answerList, function(
                                            question,
                                            questionIndex
                                          ) {
                                            return _c(
                                              "span",
                                              { key: questionIndex },
                                              [_vm._v(_vm._s(question) + ", ")]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1004
                                  ? _c("div", { staticClass: "optionBox" }, [
                                      _c(
                                        "div",
                                        { staticClass: "connection-question" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "connection-options"
                                            },
                                            _vm._l(
                                              question.optionText[0],
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "item connectionOptions"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "optionIndex"
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              index.toUpperCase()
                                                            ) + "."
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          staticClass:
                                                            "htmlItem",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "connection-options"
                                            },
                                            _vm._l(
                                              question.optionText[1],
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "item connectionOptions"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "optionIndex"
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              index.toUpperCase()
                                                            ) + "."
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "answer" },
                                        [
                                          _c("div", [_vm._v("正确答案: ")]),
                                          _vm._v(" "),
                                          _vm._l(
                                            question.rightAnswerContent,
                                            function(answer, answerIndex) {
                                              return _c(
                                                "div",
                                                { key: answerIndex },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        margin: "5px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(answerIndex) +
                                                          " -- " +
                                                          _vm._s(answer) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                question.examQuestionTypeCode === 1009
                                  ? _c("div", { staticClass: "optionBox" }, [
                                      question.subQuestionList &&
                                      question.subQuestionList.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "combination" },
                                            [
                                              _c(
                                                "el-tabs",
                                                {
                                                  model: {
                                                    value: _vm.activeName,
                                                    callback: function($$v) {
                                                      _vm.activeName = $$v
                                                    },
                                                    expression: "activeName"
                                                  }
                                                },
                                                _vm._l(
                                                  question.subQuestionList,
                                                  function(item, index) {
                                                    return _c(
                                                      "el-tab-pane",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          label:
                                                            "试题" +
                                                            (index + 1),
                                                          name:
                                                            "试题" + (index + 1)
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "title"
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "stem",
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  item
                                                                    .examQuestion
                                                                    .stem
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                          1001 ||
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                          1002
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _vm._l(
                                                                  item
                                                                    .examQuestion
                                                                    .optionText[0],
                                                                  function(
                                                                    question,
                                                                    questionIndex
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: questionIndex,
                                                                        staticClass:
                                                                          "item"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              questionIndex
                                                                                ? questionIndex.toUpperCase()
                                                                                : ""
                                                                            ) +
                                                                            ": "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                question
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "answer"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        正确答案: " +
                                                                        _vm._s(
                                                                          item
                                                                            .examQuestion
                                                                            .rightAnswerContent
                                                                            ? item.examQuestion.rightAnswerContent.toUpperCase()
                                                                            : "--"
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                        1003
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _vm._l(
                                                                  item
                                                                    .examQuestion
                                                                    .optionText[0],
                                                                  function(
                                                                    question,
                                                                    questionIndex
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: questionIndex,
                                                                        staticClass:
                                                                          "item"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "optionItem",
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                question
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "answer judge-flex"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "judge-title"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "正确答案:"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", {
                                                                      domProps: {
                                                                        innerHTML: _vm._s(
                                                                          item
                                                                            .examQuestion
                                                                            .rightAnswerContent ===
                                                                            "no"
                                                                            ? item
                                                                                .examQuestion
                                                                                .optionText[0]
                                                                                .b
                                                                            : item
                                                                                .examQuestion
                                                                                .optionText[0]
                                                                                .a
                                                                        )
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                        1005
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "answer write"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        正确答案:\n                        "
                                                                  ),
                                                                  _vm._l(
                                                                    item
                                                                      .examQuestion
                                                                      .answerList,
                                                                    function(
                                                                      question,
                                                                      questionIndex
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key: questionIndex
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              question[0]
                                                                            ) +
                                                                              ","
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                        1004
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "connection-question"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "connection-options"
                                                                    },
                                                                    _vm._l(
                                                                      item
                                                                        .examQuestion
                                                                        .optionText[0],
                                                                      function(
                                                                        question,
                                                                        questionIndex
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: questionIndex,
                                                                            staticClass:
                                                                              "item connectionOptions"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "optionIndex"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        questionIndex
                                                                                          ? questionIndex.toUpperCase()
                                                                                          : ""
                                                                                      ) +
                                                                                        ":"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps: {
                                                                                      innerHTML: _vm._s(
                                                                                        question
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "connection-options"
                                                                    },
                                                                    _vm._l(
                                                                      item
                                                                        .examQuestion
                                                                        .optionList,
                                                                      function(
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: index,
                                                                            staticClass:
                                                                              "option"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle: {
                                                                                  display:
                                                                                    "flex",
                                                                                  margin:
                                                                                    "5px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        index +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "."
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps: {
                                                                                      innerHTML: _vm._s(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "answer"
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      "正确答案: "
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    item
                                                                      .examQuestion
                                                                      .rightAnswerContent,
                                                                    function(
                                                                      answer,
                                                                      answerIndex
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: answerIndex
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle: {
                                                                                margin:
                                                                                  "5px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    answerIndex
                                                                                  ) +
                                                                                  " -- " +
                                                                                  _vm._s(
                                                                                    answer
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                        1006
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _vm._l(
                                                                  item
                                                                    .examQuestion
                                                                    .optionText[0],
                                                                  function(
                                                                    optionItem,
                                                                    optionIndex
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: optionIndex,
                                                                        staticClass:
                                                                          "item"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                optionIndex
                                                                              ) +
                                                                                ": "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                optionItem
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "answer"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        正确答案: " +
                                                                        _vm._s(
                                                                          item
                                                                            .examQuestion
                                                                            .rightAnswerContent
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.examQuestion
                                                          .examQuestionTypeCode ===
                                                        1008
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "answer"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      正确答案:\n                      "
                                                                ),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "rightAnswer",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      item
                                                                        .examQuestion
                                                                        .rightAnswerContent
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "common-flex score",
                              style: {
                                cursor: _vm.isDisable
                                  ? "not-allowed"
                                  : "pointer"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "itemCounts" },
                                [
                                  _vm._v("\n              分数: "),
                                  _c("el-input", {
                                    staticStyle: { width: "30%" },
                                    attrs: {
                                      disabled: _vm.isDisable,
                                      size: "mini"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.upDateMark(question)
                                      }
                                    },
                                    model: {
                                      value: question.mark,
                                      callback: function($$v) {
                                        _vm.$set(question, "mark", $$v)
                                      },
                                      expression: "question.mark"
                                    }
                                  }),
                                  _vm._v(" 分\n            ")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "see-all",
                                  on: {
                                    click: function($event) {
                                      return _vm.seeAll(
                                        questionIndex,
                                        index,
                                        question
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      question.showOptions ? "收起" : "查看"
                                    ) + "全部"
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-caret-bottom see-icon",
                                    style: {
                                      transform: question.showOptions
                                        ? "rotate(180deg)"
                                        : "none"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.newQuestiondialogVisible
        ? _c("new-question", {
            ref: "newQuestion",
            attrs: {
              source: "paperEditQuestion",
              "preview-dialog": _vm.newQuestiondialogVisible,
              "merchant-open-id": _vm.partnerOpenId,
              type: "edit",
              "edit-open-id": _vm.editOpenId,
              operationCode: _vm.operationCode
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              "update:preview-dialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              directoryDialogChange: _vm.directoryDialogChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSelectDialog
        ? _c("select-question", {
            attrs: {
              paperAdd: true,
              type: _vm.operationType,
              source: _vm.source,
              partnerOpenId: _vm.partnerOpenId,
              "source-mode": _vm.sourceMode,
              "select-dialog": _vm.showSelectDialog,
              "paper-info": _vm.paperInfo
            },
            on: {
              "update:selectDialog": function($event) {
                _vm.showSelectDialog = $event
              },
              "update:select-dialog": function($event) {
                _vm.showSelectDialog = $event
              },
              dialogChange: _vm.dialogChange,
              addSuccess: _vm.addSuccess
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }