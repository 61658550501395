"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _common = require("../../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DownloadNav',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    valIndex: {
      type: Number,
      default: undefined
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    questionIndex: {
      type: Number,
      default: undefined
    }
  },
  methods: {
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-1000';

      if (!cover) {
        return cover;
      }

      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;