var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-cascader", {
        staticClass: "category-box",
        attrs: {
          options: _vm.options,
          props: _vm.props,
          clearable: "",
          disabled: _vm.isDisable
        },
        model: {
          value: _vm.valueProxy,
          callback: function($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }