"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ResourceAuthor',
  props: {
    resourceObj: {
      type: Object,
      default: null
    },
    // 直播对象
    saveResourceEncode: {
      type: Function,
      default: undefined
    }
  },
  data: function data() {
    return {
      ossUploadUrl: _common.ossUploadUrl
    };
  }
};
exports.default = _default;