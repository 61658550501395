var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("el-col", { attrs: { span: 5 } }, [
        _c("div", { staticClass: "priceGroup" }, [
          _c(
            "div",
            { staticClass: "priceGroupTop" },
            [
              _c("span", [_vm._v("奖项分组")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary", plain: "" },
                  on: { click: _vm.showDialog }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { overflow: "auto", height: "calc(100vh - 216px)" }
            },
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.priceGroupLoading,
                    expression: "priceGroupLoading"
                  }
                ],
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  props: _vm.priceGroupProps,
                  "default-expand-all": "",
                  data: _vm.priceGroup,
                  "node-key": "groupCode"
                },
                on: { "node-click": _vm.getHalfCheckEvent },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "div",
                        {
                          staticClass: "custom-tree-node",
                          staticStyle: { width: "100%" },
                          on: {
                            mouseenter: function($event) {
                              $event.stopPropagation()
                              return _vm.mouseoverFun(data)
                            },
                            mouseleave: function($event) {
                              $event.stopPropagation()
                              return _vm.mouseoutFun()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "treeName",
                              style:
                                data.id === _vm.mouseid ? "color:#409EFF;" : ""
                            },
                            [
                              _vm._v(
                                "\n        " + _vm._s(node.label) + "\n      "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: data.id === _vm.mouseDropId,
                                  expression: "data.id === mouseDropId"
                                }
                              ],
                              staticStyle: {
                                position: "absolute",
                                right: "5px",
                                "margin-top": "-15px"
                              }
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _c("i", { staticClass: "el-icon-more" })
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.editorPriceGroup(data)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removePriceGroup(
                                            node,
                                            data
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("el-col", { attrs: { span: 19 } }, [
        _c(
          "div",
          { staticClass: "price" },
          [
            _c(
              "div",
              { staticClass: "priceGroupTop noBorder" },
              [
                _c("span", [_vm._v("奖项")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary", plain: "" },
                    on: { click: _vm.showPrice }
                  },
                  [_vm._v("添加")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  height: "calc(100vh - 270px)",
                  data: _vm.priceList.records
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "sequence", label: "排序", "min-width": "10" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "awardName",
                    label: "奖项名称",
                    "min-width": "30"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "奖项图标", "min-width": "10" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-image", {
                            staticClass: "head_pic",
                            staticStyle: { width: "16px", height: "16px" },
                            attrs: {
                              src: _vm.getImgUrl(
                                scope.row.iconUrl,
                                _vm.smallthumbnailStyle
                              ),
                              fit: "cover"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "count",
                    label: "所含范文量",
                    "min-width": "20"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "updatedAt",
                    label: "更新时间",
                    "min-width": "50"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editorPrice(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.deletedAt,
                                  expression: "scope.row.deletedAt"
                                }
                              ],
                              staticClass: "removeBtn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.restorePrice(scope.row)
                                }
                              }
                            },
                            [_vm._v("恢复")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !scope.row.deletedAt,
                                  expression: "!scope.row.deletedAt"
                                }
                              ],
                              staticClass: "delBtn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.deletedPrice(scope.row)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  staticClass: "paginations",
                  attrs: {
                    background: "",
                    "current-page": _vm.query.page,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.query.size,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.priceList.total
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.priceTitle,
            visible: _vm.dialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "priceForm",
              attrs: {
                rules: _vm.priceGroupRules,
                model: _vm.priceForm,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖项分组名称", prop: "groupName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入奖项分组名称" },
                    model: {
                      value: _vm.priceForm.groupName,
                      callback: function($$v) {
                        _vm.$set(_vm.priceForm, "groupName", $$v)
                      },
                      expression: "priceForm.groupName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer footers",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-input-number", {
                staticStyle: { width: "30%" },
                attrs: { "controls-position": "right", min: 0 },
                model: {
                  value: _vm.priceForm.sequence,
                  callback: function($$v) {
                    _vm.$set(_vm.priceForm, "sequence", $$v)
                  },
                  expression: "priceForm.sequence"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("priceForm")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            title: _vm.pricesTitle,
            visible: _vm.priceDialogVisible,
            width: "650px"
          },
          on: {
            "update:visible": function($event) {
              _vm.priceDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "pricesForm",
              attrs: {
                rules: _vm.priceRules,
                model: _vm.pricesForm,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖项名称", prop: "awardName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入奖项名称" },
                    model: {
                      value: _vm.pricesForm.awardName,
                      callback: function($$v) {
                        _vm.$set(_vm.pricesForm, "awardName", $$v)
                      },
                      expression: "pricesForm.awardName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖项图标", prop: "iconUrl" } },
                [
                  _c("coverup-load", {
                    attrs: {
                      coverarr: _vm.coverarr,
                      covertext: _vm.covertext,
                      path: _vm.imagePath,
                      texttips: _vm.texttipsDown,
                      type: "88"
                    },
                    on: { deletecover: _vm.deletecover },
                    model: {
                      value: _vm.pricesForm.iconUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.pricesForm, "iconUrl", $$v)
                      },
                      expression: "pricesForm.iconUrl"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.pricesTitle === "编辑奖项"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "是否删除" } },
                    [
                      _c("el-switch", {
                        attrs: { disabled: "", "active-color": "#13ce66" },
                        model: {
                          value: _vm.isDelete,
                          callback: function($$v) {
                            _vm.isDelete = $$v
                          },
                          expression: "isDelete"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer footers",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-input-number", {
                staticStyle: { width: "30%", "margin-left": "25px" },
                attrs: { "controls-position": "right", min: 0 },
                model: {
                  value: _vm.pricesForm.sequence,
                  callback: function($$v) {
                    _vm.$set(_vm.pricesForm, "sequence", $$v)
                  },
                  expression: "pricesForm.sequence"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.priceDialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitPrice("pricesForm")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }