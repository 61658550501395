"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _index = _interopRequireDefault(require("../Header/index"));

var _index2 = _interopRequireDefault(require("../Content/index"));

var _index3 = _interopRequireDefault(require("../Footer/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Gr_ListView_index',
  components: {
    ProductHeader: _index.default,
    ProductContent: _index2.default,
    ProductFooter: _index3.default
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    query: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    total: {
      type: Number,
      default: 0
    },
    contentHeight: {
      type: String,
      default: undefined
    },
    select: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectType: {
      type: String,
      default: undefined
    },
    priceHidden: {
      type: Boolean,
      default: false
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showResourceType: {
      type: Boolean,
      default: false
    },
    showLowPrice: {
      type: Boolean,
      default: true
    },
    showWholeLowPrice: {
      type: Boolean,
      default: false
    },
    showStoreLowPrice: {
      type: Boolean,
      default: false
    },
    allInfoSelect: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    hasState: {
      type: Boolean,
      default: false
    },
    showInventory: {
      type: Boolean,
      default: true
    },
    showLocalInventory: {
      type: Boolean,
      default: false
    },
    onlyRead: {
      type: Boolean,
      default: false
    },
    useMerchantBrandSeries: {
      type: Boolean,
      default: false
    },
    partnerOpenId: {
      type: String,
      default: undefined
    },
    showQueryType: {
      type: Boolean,
      default: false
    },
    queryTypeExcept: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    presetPartnerOpenId: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    queryProxy: {
      get: function get() {
        return this.query;
      },
      set: function set(value) {
        this.$emit('update:query', value);
      }
    },
    selectProxy: {
      get: function get() {
        return this.select;
      },
      set: function set(value) {
        this.$emit('update:select', value);
      }
    },
    selectTypeProxy: {
      get: function get() {
        return this.selectType;
      },
      set: function set(value) {
        this.$emit('update:selectType', value);
      }
    },
    allInfoSelectProxy: {
      get: function get() {
        return this.allInfoSelect;
      },
      set: function set(value) {
        console.log(value);
        this.$emit('update:allInfoSelect', value);
      }
    }
  },
  methods: {
    doLayout: function doLayout() {
      this.$refs.table.doLayout();
    },
    toggleAllSelection: function toggleAllSelection(value) {
      this.$refs.table.toggleAllSelection(value);
    },
    initTableSelect: function initTableSelect() {
      this.$refs.table.initTableSelect();
    },
    updateData: function updateData() {
      this.$emit('update-data');
    },
    searchChanged: function searchChanged() {
      this.query.page = 1;
      this.$emit('update-data');
      this.$emit('search-changed');
    }
  }
};
exports.default = _default2;