"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    /* 标题 */
    title: {
      type: String,
      default: ''
    },
    dragData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    handle: {
      type: String,
      default: '选择汉字'
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showType: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      deskDialogShow: false,
      replaceMap: {},
      checkList: this.dragData,
      paramList: this.list,
      twoChildren: [],
      threeChildren: [],
      boxShowType: this.showType
    };
  },
  watch: {
    checkList: function checkList(newVal) {
      this.$emit('changeDrag', newVal);
    },
    showType: function showType(newVal) {
      this.boxShowType = newVal;
    },
    boxShowType: function boxShowType(newVal) {
      this.$emit('changeShowType', newVal);
    },
    dragData: function dragData(newVal) {
      if (newVal.length === 0) {
        this.paramList.forEach(function (item) {
          item.check = false;
        });
        this.twoChildren = [];
        this.threeChildren = [];
      }

      this.checkList = newVal;
    }
  },
  methods: {
    /* 拖拽 START */
    move: function move() {},
    start: function start() {},
    end: function end() {},

    /* 拖拽 END */

    /* 处理移除事件 */
    remove: function remove(i) {
      this.checkList.splice(i, 1);
    },
    openBox: function openBox() {
      this.deskDialogShow = true;
    },

    /* 获取选择的相近字 */
    getSelectWord: function getSelectWord(checkList) {
      this.checkList = checkList;
    },
    getReplace: function getReplace(map) {
      var i = map.index;
      this.checkList[i] = map.name;
    },

    /* 相近字弹窗显示处理 */
    parentEvent: function parentEvent(show) {
      this.deskDialogShow = show;
    },
    handleClick: function handleClick(i, map) {
      var list = this.paramList;
      list.forEach(function (item, index) {
        if (i === index) {
          item.check = true;
        } else {
          item.check = false;
        }

        item.label = item.label;
      });
      this.paramList = list;
      this.twoChildren = map.children;
      var twoChildren = this.twoChildren;
      twoChildren.forEach(function (item1) {
        item1.check = false;
      });
      this.threeChildren = [];
    },
    handleClickTwo: function handleClickTwo(i, map) {
      var list = this.twoChildren;
      list.forEach(function (item, index) {
        if (i === index) {
          item.check = true;
        } else {
          item.check = false;
        }

        item.label = item.label;
      });
      this.threeChildren = map.children;
    },
    handleClickThree: function handleClickThree(item) {
      this.checkList.push(item);
    }
  }
};
exports.default = _default2;