var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-group" },
    [
      _c("el-input", {
        staticClass: "header-search",
        attrs: {
          "prefix-icon": "el-icon-search",
          placeholder: _vm.placeholder,
          clearable: ""
        },
        nativeOn: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.searchChanged($event)
          }
        },
        model: {
          value: _vm.valueProxy.keyword,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "keyword", $$v)
          },
          expression: "valueProxy.keyword"
        }
      }),
      _vm._v(" "),
      _c(
        "search-button",
        { on: { "search-changed": _vm.searchChanged } },
        [
          _c("filter-options", {
            attrs: {
              "has-state": _vm.hasState,
              "show-inventory": _vm.showInventory,
              "show-local-inventory": _vm.showLocalInventory,
              "use-merchant-brand-series": _vm.useMerchantBrandSeries,
              "partner-open-id": _vm.partnerOpenId,
              "preset-partner-open-id": _vm.presetPartnerOpenId
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }