var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wraper" },
    [
      _c(
        "div",
        { staticClass: "pane-menu" },
        [
          _c("div", { staticClass: "menu-head" }, [
            _c(
              "div",
              { staticClass: "menu-search" },
              [
                _c("search", { on: { receivepartnerId: _vm.receivepartnerId } })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "icons" }, [
              _c("span", {
                staticClass: "iconfont icon-refresh",
                on: { click: _vm.refresh }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "iconfont icon-tianjiafenlei",
                on: { click: _vm.addOneBookcity }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "iconfont noWeight",
                class: _vm.expandAll
                  ? "icon-category-collapse"
                  : "icon-category-expand",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.isExpand()
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "city-search",
            attrs: { placeholder: "名称搜索..." },
            model: {
              value: _vm.citySearch,
              callback: function($$v) {
                _vm.citySearch = $$v
              },
              expression: "citySearch"
            }
          }),
          _vm._v(" "),
          _c("el-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading"
              }
            ],
            ref: "attrList",
            staticClass: "classification-tree",
            attrs: {
              data: _vm.treeList,
              "node-key": "id",
              "expand-on-click-node": false,
              draggable: "",
              "default-expand-all": _vm.expandAll,
              "filter-node-method": _vm.filterNode
            },
            on: { "node-drop": _vm.handleDrop },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c(
                    "div",
                    {
                      class: {
                        "custom-tree-node": true,
                        "custom-tree-node-active": _vm.activeCity.id == data.id
                      },
                      on: {
                        click: function($event) {
                          return _vm.cityClick(data)
                        }
                      }
                    },
                    [
                      _c("el-image", {
                        class: { cover: true, "cover-h": true },
                        attrs: {
                          src: _vm.handleCoverDisplay(
                            data,
                            undefined,
                            "coverDisplay"
                          ),
                          fit: "cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { class: { title: true, "title-h": true } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(data.name ? data.name : "--") +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown",
                        {
                          staticClass: "item",
                          on: { command: _vm.treeOperation }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "更多",
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-gengduohengxiang"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              !data.parentId
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: { type: "add", item: data }
                                      }
                                    },
                                    [_vm._v("添加二级书城")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: { type: "edit", item: data }
                                  }
                                },
                                [_vm._v("编辑书城")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: { type: "copy", item: data }
                                  }
                                },
                                [_vm._v("复制书城")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: { type: "delete", item: data }
                                  }
                                },
                                [_vm._v("删除书城")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: { type: "preview", item: data }
                                  }
                                },
                                [_vm._v("分享书城")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "city-wrap" },
        [
          _c(
            "div",
            { staticClass: "city-head" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.activeCity.parentId
                  },
                  on: { click: _vm.addSelectBookCase }
                },
                [_vm._v("添加书橱")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.activeCity.parentId
                  },
                  on: { click: _vm.addSelectBook }
                },
                [_vm._v("添加电子书")]
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.activeCity.parentId
            ? _c("div", { staticClass: "not-select" }, [
                _vm._v("\n      您还没有选中书城，请在左侧书城内操作\n    ")
              ])
            : _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticClass: "city-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "calc(100vh - 244px)",
                    data: _vm.cityContent,
                    "row-key": "openId",
                    "tooltip-effect": "dark"
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "45" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "类型", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(scope.row.type == 1 ? "电子书" : "书橱")
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "书橱/电子书名称",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { width: "100%" }
                              },
                              [_vm._v(_vm._s(scope.row.title))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      width: "150",
                      label: "简介",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { width: "100%" }
                              },
                              [_vm._v(_vm._s(scope.row.introduction))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数量", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "content" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.ebookNum) +
                                  " 本\n          "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == 2
                              ? _c(
                                  "div",
                                  { staticClass: "content status-active" },
                                  [_vm._v("\n            已上架\n          ")]
                                )
                              : _c("div", { staticClass: "content" }, [
                                  _vm._v("\n            未上架\n          ")
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "remove",
                                on: {
                                  click: function($event) {
                                    return _vm.removeTownContent(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
          _vm._v(" "),
          _vm.activeCity.parentId
            ? _c(
                "div",
                { staticClass: "city-foot" },
                [
                  _c("el-checkbox", {
                    staticClass: "pagination-check",
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.selectedAll,
                      callback: function($$v) {
                        _vm.selectedAll = $$v
                      },
                      expression: "selectedAll"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "" },
                      on: { click: _vm.deleteAll }
                    },
                    [_vm._v("移除")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ebookInfo",
              attrs: {
                model: _vm.ebookInfo,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _vm.parentInfo.parentId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上级书城", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: {
                          placeholder: "/",
                          disabled: "",
                          maxlength: "100",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.parentInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.parentInfo, "name", $$v)
                          },
                          expression: "parentInfo.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请填写书城名称",
                      disabled: _vm.allowEdit,
                      maxlength: "100",
                      "show-word-limit": ""
                    },
                    on: { change: _vm.titleChange },
                    model: {
                      value: _vm.ebookInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "name", $$v)
                      },
                      expression: "ebookInfo.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "书城封面" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ebookInfo.coverReferenceType,
                        callback: function($$v) {
                          _vm.$set(_vm.ebookInfo, "coverReferenceType", $$v)
                        },
                        expression: "ebookInfo.coverReferenceType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("引用电子书封面")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("自定义封面")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 1
                    ? _c("select-ebook-cover", {
                        attrs: { "partner-open-id": _vm.partnerOpenId },
                        model: {
                          value: _vm.ebookInfo,
                          callback: function($$v) {
                            _vm.ebookInfo = $$v
                          },
                          expression: "ebookInfo"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 3
                    ? _c(
                        "div",
                        { staticClass: "select-cover" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: {
                                change: function($event) {
                                  return _vm.selectCoverType("2")
                                }
                              },
                              model: {
                                value: _vm.ebookInfo.coverType,
                                callback: function($$v) {
                                  _vm.$set(_vm.ebookInfo, "coverType", $$v)
                                },
                                expression: "ebookInfo.coverType"
                              }
                            },
                            [_vm._v("纵向")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: {
                                change: function($event) {
                                  return _vm.selectCoverType("1")
                                }
                              },
                              model: {
                                value: _vm.ebookInfo.coverType,
                                callback: function($$v) {
                                  _vm.$set(_vm.ebookInfo, "coverType", $$v)
                                },
                                expression: "ebookInfo.coverType"
                              }
                            },
                            [_vm._v("横向")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 3
                    ? _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: _vm.E_BOOK_ID.toString(),
                          "disabled-edit": _vm.allowEdit
                        },
                        on: { deletecover: _vm.deletecoverFun },
                        model: {
                          value: _vm.ebookInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.ebookInfo, "cover", $$v)
                          },
                          expression: "ebookInfo.cover"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.parentInfo.parentId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "书城封面" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ebookInfo.coverScope,
                            callback: function($$v) {
                              _vm.$set(_vm.ebookInfo, "coverScope", $$v)
                            },
                            expression: "ebookInfo.coverScope"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("仅应用于当前书城")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("同时应用于当前书城下二级书城")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分享标题", prop: "shareTitle" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请填写分享标题",
                      disabled: _vm.allowEdit,
                      maxlength: "100",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.ebookInfo.shareTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "shareTitle", $$v)
                      },
                      expression: "ebookInfo.shareTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分享简介", prop: "shareProfile" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请输入分享简介",
                      disabled: _vm.allowEdit
                    },
                    model: {
                      value: _vm.ebookInfo.shareProfile,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "shareProfile", $$v)
                      },
                      expression: "ebookInfo.shareProfile"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分享图片" } },
                [
                  _c("cover-upload", {
                    attrs: {
                      path: _vm.path,
                      "partner-open-id": _vm.partnerOpenId,
                      coverarr: _vm.shareCoverArr,
                      covertext: _vm.coverText,
                      type: _vm.E_BOOK_ID.toString(),
                      disabledEdit: _vm.allowEdit
                    },
                    on: { deletecover: _vm.deletecoverFunB },
                    model: {
                      value: _vm.ebookInfo.shareImageAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "shareImageAddress", $$v)
                      },
                      expression: "ebookInfo.shareImageAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bottom-btns",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.onCancle } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-ebook-item-dialog", {
        ref: "selectEbookItem",
        attrs: {
          visible: _vm.dialogSelect,
          "partner-open-id": _vm.activeCity.partnerOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogSelect = $event
          },
          onSubmitSelect: _vm.onSubmitSelect
        }
      }),
      _vm._v(" "),
      _c("select-ebook-case-item-dialog", {
        ref: "selectEbookCaseItem",
        attrs: {
          visible: _vm.dialogSelectCase,
          "partner-open-id": _vm.activeCity.partnerOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogSelectCase = $event
          },
          onSubmitSelectCase: _vm.onSubmitSelectCase
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "delete-dialog",
          attrs: { title: "提示", visible: _vm.deleteClass, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.deleteClass = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除书城吗？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteClass = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteCity } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "delete-dialog",
          attrs: { title: "提示", visible: _vm.deleteTown, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.deleteTown = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除书城内容吗？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteTown = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteTownSbumit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }