"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ossUrl = ossUrl;
exports.getFileListossUrl = getFileListossUrl;

var _qs = _interopRequireDefault(require("qs"));

function ossUrl(path, defaultCover, style) {
  var query = _qs.default.stringify({
    'x-oss-process': style
  });

  var url = query ? "".concat(path, "?").concat(query) : path;
  return path ? "https://open.oss.taozhi.online/".concat(process.env.VUE_APP_OSS_PATH, "/").concat(url) : defaultCover;
}

function getFileListossUrl(path, defaultCover) {
  return path ? "http://download.oss.taozhi.online/".concat(process.env.VUE_APP_OSS_PATH, "/").concat(path) : defaultCover;
} // eslint-disable-line import/prefer-default-export