var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "the-underlying-column",
    [
      _c("el-form-item", { attrs: { label: "同步信息" } }, [
        _c(
          "div",
          { staticClass: "sync-info" },
          [
            _c("sync-info", {
              attrs: {
                size: "small",
                placeholder: "学段",
                "data-source": _vm.getSyncSection,
                filterable: true
              },
              model: {
                value: _vm.valueProxy.sectionCode,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "sectionCode", $$v)
                },
                expression: "valueProxy.sectionCode"
              }
            }),
            _vm._v(" "),
            _c("sync-info", {
              attrs: {
                size: "small",
                placeholder: "学科",
                "data-source": _vm.getSyncSubject,
                filterable: true
              },
              model: {
                value: _vm.valueProxy.subjectCode,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "subjectCode", $$v)
                },
                expression: "valueProxy.subjectCode"
              }
            }),
            _vm._v(" "),
            _c("sync-info", {
              attrs: {
                size: "small",
                placeholder: "版本",
                "data-source": _vm.getSyncEdition,
                filterable: true
              },
              model: {
                value: _vm.valueProxy.editionCode,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "editionCode", $$v)
                },
                expression: "valueProxy.editionCode"
              }
            }),
            _vm._v(" "),
            _c("sync-info", {
              attrs: {
                size: "small",
                placeholder: "年级",
                "data-source": _vm.getSyncGrade,
                filterable: true
              },
              model: {
                value: _vm.valueProxy.gradeCode,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "gradeCode", $$v)
                },
                expression: "valueProxy.gradeCode"
              }
            }),
            _vm._v(" "),
            _c("sync-info", {
              attrs: {
                size: "small",
                placeholder: "阶段",
                "data-source": _vm.getSyncVolume,
                filterable: true
              },
              model: {
                value: _vm.valueProxy.volumeCode,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "volumeCode", $$v)
                },
                expression: "valueProxy.volumeCode"
              }
            }),
            _vm._v(" "),
            _c("sync-info", {
              attrs: {
                size: "small",
                placeholder: "选必修",
                "data-source": _vm.getSyncElective,
                query: { size: 9999 },
                filterable: true
              },
              model: {
                value: _vm.valueProxy.electiveCode,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "electiveCode", $$v)
                },
                expression: "valueProxy.electiveCode"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "地区信息" } },
        [
          _c("sync-info", {
            staticClass: "single-line",
            attrs: {
              size: "small",
              placeholder: "地区",
              "data-source": _vm.getAllBookArea,
              filterable: true
            },
            model: {
              value: _vm.valueProxy.areaCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "areaCode", $$v)
              },
              expression: "valueProxy.areaCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "版次信息" } },
        [
          _c("sync-info", {
            staticClass: "single-line",
            attrs: {
              size: "small",
              placeholder: "版次",
              "data-source": _vm.getSyncPeriod,
              filterable: true
            },
            model: {
              value: _vm.valueProxy.periodCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "periodCode", $$v)
              },
              expression: "valueProxy.periodCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "商品标签" } },
        [
          _c("tag-select", {
            staticClass: "single-line",
            attrs: { "is-disabled": false },
            model: {
              value: _vm.valueProxy.tags,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "tags", $$v)
              },
              expression: "valueProxy.tags"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "类目信息" } },
        [
          _c("default-selector", {
            staticClass: "single-line",
            attrs: {
              "regimentation-ids": _vm.valueProxy.regimentationIds,
              "second-regimentation-ids": _vm.valueProxy.secondRegimentationIds
            },
            on: {
              "update:regimentationIds": function($event) {
                return _vm.$set(_vm.valueProxy, "regimentationIds", $event)
              },
              "update:regimentation-ids": function($event) {
                return _vm.$set(_vm.valueProxy, "regimentationIds", $event)
              },
              "update:secondRegimentationIds": function($event) {
                return _vm.$set(
                  _vm.valueProxy,
                  "secondRegimentationIds",
                  $event
                )
              },
              "update:second-regimentation-ids": function($event) {
                return _vm.$set(
                  _vm.valueProxy,
                  "secondRegimentationIds",
                  $event
                )
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "品牌" } },
        [
          _c("sync-info", {
            staticClass: "single-line",
            attrs: {
              size: "small",
              placeholder: "品牌",
              "data-source": _vm.getBrandList,
              "label-name": "title",
              "value-name": "brandOpenId",
              filterable: true,
              query: _vm.getBrandListSearchQuery
            },
            model: {
              value: _vm.valueProxy.brandOpenId,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "brandOpenId", $$v)
              },
              expression: "valueProxy.brandOpenId"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "图书系列" } },
        [
          _c("sync-info", {
            ref: "subjectList",
            staticClass: "single-line",
            attrs: {
              size: "small",
              placeholder: "图书系列",
              "data-source": _vm.getSeriesList,
              query: _vm.getSeriesListSearchQuery,
              "label-name": "title",
              "value-name": "seriesOpenId",
              filterable: true
            },
            model: {
              value: _vm.valueProxy.seriesCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "seriesCode", $$v)
              },
              expression: "valueProxy.seriesCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: _vm.inventoryLabel } }, [
        _c(
          "div",
          { staticClass: "local-inventory-layout" },
          [
            _c("el-input", {
              attrs: { placeholder: "最小库存", clearable: "", min: "0" },
              model: {
                value: _vm.valueProxy.minInventory,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "minInventory", $$v)
                },
                expression: "valueProxy.minInventory"
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "local-inventory-divider-line" }, [
              _vm._v("~")
            ]),
            _vm._v(" "),
            _c("el-input", {
              attrs: { placeholder: "最大库存", clearable: "", min: "0" },
              model: {
                value: _vm.valueProxy.maxInventory,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "maxInventory", $$v)
                },
                expression: "valueProxy.maxInventory"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }