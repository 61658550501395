var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extended-learning" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", disabled: _vm.disable },
              on: { click: _vm.addBtn }
            },
            [_vm._v("添加栏目")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.disable,
                plain: "",
                size: "small",
                type: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.addContent("paper")
                }
              }
            },
            [_vm._v("练一练")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.disable,
                plain: "",
                size: "small",
                type: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.addContent("word")
                }
              }
            },
            [_vm._v("单词听写")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.disable,
                plain: "",
                size: "small",
                type: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.addContent("vocabulary")
                }
              }
            },
            [_vm._v("词语听写")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "learning-table",
          staticStyle: { width: "100%" },
          attrs: {
            size: "small",
            "header-cell-style": { background: "#F5F7FA" },
            data: _vm.valueProxy,
            border: "",
            "row-key": "id"
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "图标", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticClass: "cover",
                      attrs: {
                        fit: "cover",
                        src: _vm.getImgUrl(scope.row.cover)
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "名称", prop: "name", width: "120" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      scope.row.link
                        ? _c(
                            "div",
                            _vm._l(scope.row.link, function(item, index) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.display.title)
                                ),
                                index !== scope.row.link.length - 1
                                  ? _c("span", [_vm._v("、")])
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        : _c("span", [_vm._v("--")])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.showSeat
            ? _c("el-table-column", {
                attrs: { align: "center", label: "位置", width: "120px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: scope.row.seat,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "seat", $$v)
                                },
                                expression: "scope.row.seat"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "top" } }, [
                                _vm._v("上")
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "bottom" } }, [
                                _vm._v("下")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2096870405
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "VIP可用", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { disabled: _vm.disable },
                      model: {
                        value: scope.row.isVip,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isVip", $$v)
                        },
                        expression: "scope.row.isVip"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#409EFF" },
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: _vm.disable
                        },
                        on: {
                          click: function($event) {
                            return _vm.editRow(scope.row, scope.$index)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "rgb(245, 108, 108)" },
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: _vm.disable
                        },
                        on: {
                          click: function($event) {
                            return _vm.delRow(scope.$index)
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.visible
        ? _c("add-extended-dialog", {
            attrs: { visible: _vm.visible, title: _vm.addTitle },
            on: {
              "update:visible": function($event) {
                _vm.visible = $event
              },
              submit: _vm.submit
            },
            model: {
              value: _vm.addData,
              callback: function($$v) {
                _vm.addData = $$v
              },
              expression: "addData"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }