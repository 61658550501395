var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量添加/修改角色",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialogBody-box" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading"
                    }
                  ],
                  staticStyle: {
                    margin: "15px 0",
                    "background-color": "#FFFFFF"
                  },
                  attrs: { data: _vm.imageList }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片", width: "50px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-image", {
                              staticClass: "item-image",
                              attrs: {
                                src: _vm.getImageUrl(scope.row.cover),
                                fit: "fit",
                                lazy: ""
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function(scope) {
                          return [
                            _vm._v("\n            角色名称"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  "margin-left": "5px"
                                }
                              },
                              [_vm._v("*")]
                            )
                          ]
                        }
                      },
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "角色名称", clearable: "" },
                              model: {
                                value: scope.row.roleName,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "roleName", $$v)
                                },
                                expression: "scope.row.roleName"
                              }
                            }),
                            _vm._v(" "),
                            scope.row.roleNameIsNull
                              ? _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n              角色名称不能为空\n            "
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-items": "center",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "item-change",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeName(scope, 1)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-refresh" })]
                                ),
                                _vm._v("\n              名称翻译\n            ")
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-items": "center",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "item-change2",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeName(scope, 0)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-refresh" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "padding-left": "10px" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "名称翻译",
                                        clearable: ""
                                      },
                                      model: {
                                        value: scope.row.roleTransName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "roleTransName",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.roleTransName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "标签" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-items": "center",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _vm._v("\n              标签\n              "),
                                _c(
                                  "div",
                                  {
                                    class: _vm.isALLLock
                                      ? "item-lock-on"
                                      : "item-lock",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeLock(scope, 1)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-link" })]
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-items": "center",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "labek-select",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请选择"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.tagsChange(scope.row)
                                      }
                                    },
                                    model: {
                                      value: scope.row.tags,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "tags", $$v)
                                      },
                                      expression: "scope.row.tags"
                                    }
                                  },
                                  _vm._l(_vm.labelList, function(item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: item }
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class: scope.row.isLock
                                      ? "item-lock2-on"
                                      : "item-lock2",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeLock(scope, 0)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-link" })]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序", width: "100px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: scope.row.sequence,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "sequence", $$v)
                                    },
                                    expression: "scope.row.sequence"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-footer" }, [
            _c("div", { staticClass: "footer-left" }, [
              _c("div", { staticClass: "step-flag step-on" }, [_vm._v("1")]),
              _vm._v(" "),
              _c("div", { staticClass: "step-content" }, [_vm._v("图片上传")]),
              _vm._v(" "),
              _c("div", { staticClass: "line-link" }),
              _vm._v(" "),
              _c("div", { staticClass: "step-flag step-on" }, [_vm._v("2")]),
              _vm._v(" "),
              _c("div", { staticClass: "step-content" }, [_vm._v("角色编辑")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消")
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.dialogLoading },
                    on: { click: _vm.submit }
                  },
                  [_vm._v("保 存")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }