var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-management" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.componentList, height: "calc(100vh - 164px)" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "title", label: "组件名称" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "defaultIcon", label: "组件图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticClass: "default-img",
                      attrs: { src: _vm.getImageUrl(scope.row.defaultIcon) }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "competentLibraryGroup", label: "所述分组" }
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "sequence", label: "排序" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row.competentOpenId)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }