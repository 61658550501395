"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.search");

var _oss = require("../../../../utils/oss");

var _common = require("../../../../utils/common");

var _resource = require("../../../../utils/resource");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogTableContent',
  props: {
    resourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dialogType: {
      type: String,
      default: 'add'
    }
  },
  data: function data() {
    return {
      ossUrl: _oss.ossUrl,
      showVal: _common.showVal,
      getResourceInfo: _resource.getResourceInfo,
      translationResourceType: _resource.translationResourceType,
      defaultCover: 'https://open.oss.taozhi.online/production/assets/mp/resource/select-resource/book_cover.png?x-oss-process=style%2Fthumbnail',
      fileIconList: [{
        icon: 'icon-xlsx',
        fileExtName: 'xlsx,xls'
      }, {
        icon: 'icon-tupian',
        fileExtName: 'jpg,jpeg,png,gif'
      }, {
        icon: 'icon-doc',
        fileExtName: 'doc,docx,rtf'
      }, {
        icon: 'icon-pdf',
        fileExtName: 'pdf'
      }, {
        icon: 'icon-ppt',
        fileExtName: 'ppt,pptx'
      }, {
        icon: 'icon-zip',
        fileExtName: 'zip'
      }, {
        icon: 'icon-rar',
        fileExtName: 'rar'
      }, {
        icon: 'icon-txt',
        fileExtName: 'txt'
      }, {
        icon: 'icon-shipin',
        fileExtName: 'wma,mp4,rm,rmvb,mkv'
      }, {
        icon: 'icon-yinpin',
        fileExtName: 'mp3,wav,aac'
      }, {
        icon: 'icon-qita',
        fileExtName: 'wps,js,json,css,sql,xml,java,cs'
      }]
    };
  },
  computed: {
    selection: function selection() {
      return this.$refs.table.selection;
    },
    clearSelection: function clearSelection() {
      return this.$refs.table.clearSelection;
    }
  },
  methods: {
    getResourceCoverClass: function getResourceCoverClass(type) {
      return parseInt(type, 10) === 3 ? 'book-cover' : 'resource-cover';
    },
    getResourceCover: function getResourceCover(extName) {
      // 获取封面图片
      var fileObj = this.fileIconList.filter(function (item) {
        return item.fileExtName.search(extName) != -1;
      });
      return fileObj[0].icon;
    },

    /**
     * 显示标题
     */
    displayTitle: function displayTitle(data) {
      if (data.resourceTitle && data.resourceTitle.trim().length > 0) {
        return "".concat(data.resourceTitle, ".").concat(data.fileExtName);
      }

      return "".concat(data.title, ".").concat(data.fileExtName);
    },
    selectable: function selectable(_ref) {
      var itemId = _ref.resourceOpenId;
      var except = this.excludeData.find(function (_ref2) {
        var resourceOpenId = _ref2.resourceOpenId;
        return resourceOpenId === itemId;
      });
      return !except;
    },
    tableRowClassName: function tableRowClassName(_ref3) {
      var row = _ref3.row;
      return this.selectable(row) ? '' : 'disabled-row';
    }
  }
};
exports.default = _default2;