"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var uploadStatus = {
  namespaced: true,
  state: {
    showDialog: undefined,
    showProgress: undefined,
    status: undefined,
    percent: undefined,
    message: undefined,
    showTip: undefined,
    showGeneratePageTip: undefined,
    loading: undefined
  },
  mutations: {
    SET_DIALOG: function SET_DIALOG(state, show) {
      state.showDialog = show;
    },
    SET_PROGRESS: function SET_PROGRESS(state, show) {
      state.showProgress = show;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_PERCENT: function SET_PERCENT(state, percent) {
      state.percent = percent;
    },
    SET_MSG: function SET_MSG(state, msg) {
      state.message = msg;
    },
    SET_TIP: function SET_TIP(state, show) {
      state.showTip = show;
    },
    SET_GENERATE_PAGE_TIP: function SET_GENERATE_PAGE_TIP(state, show) {
      state.showGeneratePageTip = show;
    },
    SET_LOADING: function SET_LOADING(state, value) {
      state.loading = value;
    },
    RESET_ALL: function RESET_ALL(state) {
      state.showDialog = undefined;
      state.showProgress = undefined;
      state.status = undefined;
      state.percent = undefined;
      state.message = undefined;
      state.showGeneratePageTip = undefined;
      state.loading = undefined;
    }
  },
  actions: {
    set_upload_dialog: function set_upload_dialog(_ref, show) {
      var commit = _ref.commit;
      commit('SET_DIALOG', show);
    },
    set_upload_progress: function set_upload_progress(_ref2, show) {
      var commit = _ref2.commit;
      commit('SET_PROGRESS', show);
    },
    set_upload_status: function set_upload_status(_ref3, status) {
      var commit = _ref3.commit;
      commit('SET_STATUS', status);
    },
    set_upload_percent: function set_upload_percent(_ref4, percent) {
      var commit = _ref4.commit;
      commit('SET_PERCENT', percent);
    },
    set_upload_msg: function set_upload_msg(_ref5, msg) {
      var commit = _ref5.commit;
      commit('SET_MSG', msg);
    },
    set_upload_tip: function set_upload_tip(_ref6, value) {
      var commit = _ref6.commit;
      commit('SET_TIP', value);
    },
    set_upload_pdf_tip: function set_upload_pdf_tip(_ref7, value) {
      var commit = _ref7.commit;
      commit('SET_GENERATE_PAGE_TIP', value);
    },
    set_upload_loading: function set_upload_loading(_ref8, value) {
      var commit = _ref8.commit;
      commit('SET_LOADING', value);
    },
    reset_upload_status: function reset_upload_status(_ref9) {
      var commit = _ref9.commit;
      commit('RESET_ALL');
    }
  }
};
var _default = uploadStatus;
exports.default = _default;