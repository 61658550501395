var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "product-attribute-dialog",
      attrs: {
        visible: _vm.visible,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
        width: "1000px",
        title: "批量设置属性"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "title-list" }, [
        _c("div", { staticClass: "common-title nav" }, [
          _c("div", { staticClass: "options common-txt-center" }, [
            _vm._v("序号")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "nav-space" }, [_vm._v("商品")]),
          _vm._v(" "),
          _c("div", { staticClass: "options common-txt-center" }, [
            _vm._v("操作")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list" },
          _vm._l(_vm.tableList, function(item, index) {
            return _c(
              "div",
              {
                key: item.productOpenId,
                staticClass: "list-item common-flex-space"
              },
              [
                _c("div", { staticClass: "options common-txt-center" }, [
                  _vm._v(_vm._s(index + 1))
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "nav-space",
                  attrs: {
                    minlength: "1",
                    maxlength: "100",
                    "show-word-limit": "",
                    placeholder: "请输入内容"
                  },
                  model: {
                    value: item.title,
                    callback: function($$v) {
                      _vm.$set(item, "title", $$v)
                    },
                    expression: "item.title"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "options common-txt-center" },
                  [
                    _c("el-link", {
                      attrs: { underline: false, icon: "el-icon-delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteBtn(item)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "attributes" },
        [
          _c("div", { staticClass: "common-title" }, [_vm._v("属性")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "attributes-form",
              attrs: { model: _vm.form, size: "small" }
            },
            [
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.form.isRegimentation,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isRegimentation", $$v)
                            },
                            expression: "form.isRegimentation"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("类目")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("default-selector", {
                      staticClass: "common-select-width",
                      attrs: {
                        "regimentation-ids": _vm.form.regimentationIds,
                        "second-regimentation-ids":
                          _vm.form.secondRegimentationIds,
                        "is-disable": !_vm.form.isRegimentation,
                        multiple: true
                      },
                      on: {
                        "update:regimentationIds": function($event) {
                          return _vm.$set(_vm.form, "regimentationIds", $event)
                        },
                        "update:regimentation-ids": function($event) {
                          return _vm.$set(_vm.form, "regimentationIds", $event)
                        },
                        "update:secondRegimentationIds": function($event) {
                          return _vm.$set(
                            _vm.form,
                            "secondRegimentationIds",
                            $event
                          )
                        },
                        "update:second-regimentation-ids": function($event) {
                          return _vm.$set(
                            _vm.form,
                            "secondRegimentationIds",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.form.isBrand,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isBrand", $$v)
                            },
                            expression: "form.isBrand"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("品牌")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("sync-info", {
                      staticClass: "common-select-width",
                      attrs: {
                        "key-prefix": "brandOpenId",
                        disabled: !_vm.form.isBrand,
                        size: "small",
                        placeholder: "请选择品牌方",
                        "data-source": _vm.getBrandListCached,
                        "label-name": "title",
                        "value-name": "brandOpenId",
                        filterable: true,
                        remote: true,
                        "remote-api": _vm.getBrandListSearch
                      },
                      model: {
                        value: _vm.form.brandOpenId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "brandOpenId", $$v)
                        },
                        expression: "form.brandOpenId"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c("div", { staticClass: "common-label" }, [
                      _c("div", { staticClass: "check-seize-a-seat" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "check-label" }, [
                        _vm._v("系列")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("sync-info", {
                      ref: "seriesCode",
                      staticClass: "common-select-width",
                      attrs: {
                        "key-prefix": "seriesCode",
                        disabled: _vm.isSeriesCanSelect,
                        size: "small",
                        placeholder: "请选择系列",
                        "data-source": _vm.getSeriesList,
                        "label-name": "title",
                        "value-name": "seriesOpenId",
                        filterable: true,
                        remote: true,
                        "remote-api": _vm.getSeriesListSearch,
                        query: _vm.getSeriesListSearchQuery
                      },
                      model: {
                        value: _vm.form.seriesCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "seriesCode", $$v)
                        },
                        expression: "form.seriesCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c("div", { staticClass: "common-flex-top" }, [
                  _c(
                    "div",
                    { staticClass: "common-label" },
                    [
                      _c("el-checkbox", {
                        attrs: { name: "type" },
                        model: {
                          value: _vm.isSync,
                          callback: function($$v) {
                            _vm.isSync = $$v
                          },
                          expression: "isSync"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "check-label" }, [
                        _vm._v("同步")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("sync-info", {
                        staticClass: "sync-select",
                        attrs: {
                          filterable: true,
                          placeholder: "学段",
                          disabled: !_vm.isSync,
                          "data-source": _vm.getSyncSection,
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.sectionCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sectionCode", $$v)
                          },
                          expression: "form.sectionCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("sync-info", {
                        staticClass: "sync-select",
                        attrs: {
                          filterable: true,
                          placeholder: "年级",
                          disabled: !_vm.isSync,
                          "data-source": _vm.getSyncGrade,
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.gradeCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "gradeCode", $$v)
                          },
                          expression: "form.gradeCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("sync-info", {
                        staticClass: "sync-select",
                        attrs: {
                          filterable: true,
                          placeholder: "阶段",
                          disabled: !_vm.isSync,
                          "data-source": _vm.getSyncVolume,
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.volumeCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "volumeCode", $$v)
                          },
                          expression: "form.volumeCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("sync-info", {
                        staticClass: "sync-select",
                        attrs: {
                          filterable: true,
                          placeholder: "学科",
                          disabled: !_vm.isSync,
                          "data-source": _vm.getSyncSubject,
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.subjectCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subjectCode", $$v)
                          },
                          expression: "form.subjectCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("sync-info", {
                        staticClass: "sync-select",
                        attrs: {
                          filterable: true,
                          placeholder: "版本",
                          disabled: !_vm.isSync,
                          "data-source": _vm.getSyncEdition,
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.editionCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "editionCode", $$v)
                          },
                          expression: "form.editionCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("sync-info", {
                        staticClass: "sync-select",
                        attrs: {
                          filterable: true,
                          placeholder: "选必修",
                          disabled: !_vm.isSync,
                          "data-source": _vm.getSyncElective,
                          query: { size: 9999 },
                          multiple: ""
                        },
                        model: {
                          value: _vm.form.electiveCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "electiveCode", $$v)
                          },
                          expression: "form.electiveCode"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.subLoading },
              on: { click: _vm.submit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }