"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default2 = {
  name: 'CG_From_Radio_BooleanRadio',
  props: {
    value: {
      type: String,
      default: null
    },
    option: {
      type: Array,
      default: function _default() {
        return [{
          key: '0',
          value: '全部'
        }, {
          key: '1',
          value: '是'
        }, {
          key: '2',
          value: '否'
        }];
      }
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default2;