var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "export-dialog-bg",
          attrs: {
            title: "批量导出",
            width: "520px",
            visible: _vm.visible,
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _vm.lrcData.length > 0
            ? _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.lrcData, function(item, index) {
                  return _c("div", { key: index }, [
                    _vm._v("\n        " + _vm._s(item) + "\n      ")
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "content no-data" }, [
                _vm._v("\n      暂无内容\n    ")
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "export-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.copyList($event)
                    }
                  }
                },
                [_vm._v("复制")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleExport }
                },
                [_vm._v("导出LRC")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }