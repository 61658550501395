var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-upload-bg" },
    [
      _vm.vid !== null && _vm.vid.length > 0
        ? _c("div", [
            _vm.videoCover !== null && _vm.videoCover.length > 0
              ? _c("img", {
                  staticClass: "video",
                  attrs: { src: _vm.getImageUrl(_vm.videoCover) }
                })
              : _c("img", {
                  staticClass: "video",
                  staticStyle: { background: "transparent" },
                  attrs: {
                    src:
                      "https://open.oss.taozhi.online/production/mp/defaultCoverVideo/" +
                      (_vm.isProduct
                        ? "new-product-default-cover"
                        : "default-cover") +
                      ".png"
                  }
                })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.vid
        ? _c("div", { staticClass: "video-hover-bg" }, [
            _c("div", { staticClass: "video-operation-bg" }, [
              _c("i", {
                staticClass: "el-icon-video-play",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.videoPreviewFun()
                  }
                }
              }),
              _vm._v(" "),
              !_vm.disabledEdit
                ? _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.deleteVideos()
                      }
                    }
                  })
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadDisabled
        ? _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", content: "取消上传", placement: "right" }
            },
            [
              _c("i", {
                staticClass: "iconfont icon-vidcancel",
                style: _vm.vidcancelhover ? "color:red" : "color:#909399",
                on: {
                  mouseover: function($event) {
                    _vm.vidcancelhover = true
                  },
                  mouseout: function($event) {
                    _vm.vidcancelhover = false
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.cancleUpload()
                  }
                }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "upload-video-box display-flex box" },
        [
          _vm.uploadDisabled
            ? _c("el-progress", {
                staticClass: "videoprogress",
                attrs: {
                  width: 80,
                  percentage: _vm.authProgress,
                  type: "circle"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            ref: "uploadControl",
            staticClass: "file-upload",
            attrs: {
              disabled: _vm.uploadDisabled,
              type: "file",
              accept: "video/*"
            },
            on: {
              change: function($event) {
                return _vm.fileChange($event, "audio")
              }
            }
          }),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoPreview,
            "before-close": _vm.videoPreviewClose,
            title: "视频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoPreview = $event
            }
          }
        },
        [
          _c("div", {
            staticClass: "video-start",
            attrs: { id: "videoBrowse" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-video-icon" }, [
      _c("i", { staticClass: "el-icon-upload" }),
      _vm._v(" "),
      _c("span", [_vm._v("视频上传")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }