var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      ref: "table",
      staticClass: "my-table",
      attrs: {
        data: _vm.data,
        height: _vm.height,
        "highlight-current-row": true,
        "cell-class-name": _vm.typeCellClassName,
        "empty-text": "--",
        "row-class-name": _vm.tableRowClassName
      },
      on: {
        "selection-change": _vm.handleSelectionChange,
        "row-click": _vm.selectCall
      }
    },
    [
      _vm.showSelection && !_vm.onlyRead
        ? _c("el-table-column", {
            attrs: {
              fixed: "",
              type: "selection",
              selectable: _vm.selectable,
              width: "50"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { fixed: "", width: "300", label: "商品" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_c("commodity-cell", { attrs: { commodity: scope.row } })]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "商品类型",
          prop: "resourceType",
          formatter: _vm.resourceTypeFormatter
        }
      }),
      _vm._v(" "),
      _vm._t("prefix"),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "250",
          label: "openid",
          prop: "productOpenId",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "ERP商品id",
          prop: "productId",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _vm.showSkuCode
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "sku编码",
              prop: "skuCode",
              formatter: _vm.emptyFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "120",
          label: "商品编码",
          prop: "productCode",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "主图视频",
          prop: "showVideo",
          formatter: _vm.hasFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "条码",
          prop: "barCode",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "类目",
          prop: "regimentationDisplay",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "品牌",
          prop: "brandText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "商品系列",
          prop: "seriesText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "学段",
          prop: "sectionText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "学科",
          prop: "subjectText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "版本",
          prop: "editionText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "年级",
          prop: "gradeText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "阶段",
          prop: "volumeText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "选必修",
          prop: "electiveText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "版次",
          prop: "periodText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "地区",
          prop: "areaText",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "标签",
          prop: "tags",
          formatter: _vm.emptyFormatter
        }
      }),
      _vm._v(" "),
      _vm.showIsSuit
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "是否套装",
              prop: "isSuit",
              formatter: _vm.booleanFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "教辅单品",
              prop: "isSingle",
              formatter: _vm.booleanFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "规格",
          prop: "specificType",
          formatter: _vm.specificTypeFormatter
        }
      }),
      _vm._v(" "),
      _vm.showInventory
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "库存",
              prop: "inventory",
              formatter: _vm.inventoryFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLocalInventory
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "本地库存",
              prop: "inventory",
              formatter: _vm.inventoryFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "限购",
          prop: "quota",
          formatter: _vm.quotaFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "仅认证教师可购",
          prop: "isTeacherBuy",
          formatter: _vm.booleanFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "原价",
          prop: "orginalPrice",
          formatter: _vm.priceFormatter
        }
      }),
      _vm._v(" "),
      _vm.showLowPrice
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "全网最低售价",
              prop: "lowPrice",
              formatter: _vm.discountFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showStoreLowPrice
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "店铺最低售价",
              prop: "lowPrice",
              formatter: _vm.discountFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showWholeLowPrice
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "全网最低售价",
              prop: "wholeLowPrice",
              formatter: _vm.discountFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.priceHidden
        ? _c("el-table-column", {
            attrs: {
              width: "150",
              label: "售价",
              prop: "price",
              formatter: _vm.discountFormatter
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("additional"),
      _vm._v(" "),
      _vm._t("action")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }