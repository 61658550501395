var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchUserByinput" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.openId != "" && _vm.openId != false,
            expression: "openId!=''&&openId!=false"
          }
        ],
        staticClass: "searchUserBot"
      },
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c("el-image", {
              staticStyle: {
                width: "24px",
                height: "24px",
                "border-radius": "50%"
              },
              attrs: { src: _vm.getImgUrl(_vm.cover), fit: "cover" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "submenu-name" }, [
              _vm._v(_vm._s(_vm.nickName))
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("i", {
          staticClass: "el-icon-error el-icon-errorSty",
          staticStyle: {
            padding: "0px 5px",
            color: "#c0c4cc",
            cursor: "pointer"
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.clear()
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _vm.openId == ""
      ? _c(
          "div",
          [
            _c(
              "el-select",
              {
                style: { width: _vm.width + "px" },
                attrs: {
                  "remote-method": _vm.remoteMethod,
                  loading: _vm.loading,
                  "popper-class": "hideUserSty",
                  filterable: "",
                  placeholder: _vm.placeHolderText,
                  remote: "",
                  "reserve-keyword": ""
                },
                on: { "visible-change": _vm.gettest, change: _vm.keyDown },
                model: {
                  value: _vm.openId,
                  callback: function($$v) {
                    _vm.openId = $$v
                  },
                  expression: "openId"
                }
              },
              [
                _vm.openId == ""
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "infinite-scroll",
                            rawName: "v-infinite-scroll",
                            value: _vm.loadScroll,
                            expression: "loadScroll"
                          }
                        ],
                        staticStyle: { height: "200px", overflow: "auto" },
                        attrs: { "infinite-scroll-disabled": "disabled" }
                      },
                      _vm._l(_vm.list, function(item, index) {
                        return _c(
                          "el-option",
                          {
                            key: index,
                            attrs: { label: item.nickName, value: item.openId }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "commonFlex commonAlign",
                                staticStyle: {
                                  height: "50px",
                                  padding: "5px",
                                  width: "100%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectItem(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "commonFlex commonAlign" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "commonFlex commonAlign",
                                        staticStyle: { width: "40px" }
                                      },
                                      [
                                        _c("el-image", {
                                          class: item.wxChatOpenId
                                            ? "weChatStyle"
                                            : "",
                                          staticStyle: {
                                            width: "24px",
                                            height: "24px",
                                            "border-radius": "50%"
                                          },
                                          attrs: {
                                            fit: "cover",
                                            src: _vm.getImgUrl(item.cover)
                                          }
                                        }),
                                        _vm._v(" "),
                                        item.wxChatOpenId
                                          ? _c("img", {
                                              staticClass: "weChatimg",
                                              staticStyle: {
                                                width: "15px",
                                                height: "15px",
                                                "border-radius": "50%"
                                              },
                                              attrs: {
                                                src: require("../../assets/images/wechart.png"),
                                                alt: ""
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "commonFlex commonAlign",
                                    staticStyle: {
                                      "flex-direction": "column",
                                      "align-items": "flex-start"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "commonText commonOver" },
                                      [_vm._v(_vm._s(item.nickName))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "commonText commonOver" },
                                      [_vm._v(_vm._s(item.openId))]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.scrollStaus
                  ? _c("p", { staticClass: "commonText commonPSty" }, [
                      _vm._v("\n        加载数据中...\n      ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.noMore
                  ? _c("p", { staticClass: "commonText commonPSty" }, [
                      _vm._v("\n        没有更多了\n      ")
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }