"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

//
//
//
//
var _default = {
  data: function data() {
    return {
      url: 'https://grafana.taozhi.cn/d/cF9C_OTmk/00-tao-zhi-xue-tang-da-shu-ju-hui-zong-biao?orgId=1'
    };
  },
  watch: {
    url: function url(val) {
      if (val) {
        this.$refs.iframe.contentWindow.location.replace(val);
      }
    }
  }
};
exports.default = _default;