"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Page404',
  data: function data() {
    return {
      name: localStorage.TAOZHI_LAST_ERROR_NAME,
      message: localStorage.TAOZHI_LAST_ERROR_MESSAGE
    };
  }
};
exports.default = _default;