var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-lz-content upload-video" }, [
    _c(
      "div",
      { staticClass: "works-display-flex" },
      [
        _c("el-cascader", {
          attrs: {
            disabled: _vm.isDisabled,
            options: _vm.treeData,
            props: { value: "openId", label: "title", multiple: true }
          },
          on: { change: _vm.cascaderChange },
          model: {
            value: _vm.valueProxy,
            callback: function($$v) {
              _vm.valueProxy = $$v
            },
            expression: "valueProxy"
          }
        }),
        _vm._v(" "),
        !_vm.editHide
          ? _c(
              "el-button",
              {
                staticClass: "synchronization",
                style: _vm.isDisabled ? "color:#909399" : "",
                attrs: { disabled: _vm.isDisabled, type: "text" },
                on: { click: _vm.addClassPageDiaFun }
              },
              [_vm._v("\n      编辑\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.addClassPageDialog, "append-to-body": "" },
            on: {
              "update:visible": function($event) {
                _vm.addClassPageDialog = $event
              }
            }
          },
          [
            _c("video-class", {
              ref: "videoclass",
              attrs: {
                type: _vm.resourceType,
                isebook: _vm.isebook,
                isbookcase: _vm.isbookcase,
                "is-edit": true,
                merchantId: _vm.merchantId
              },
              on: { "update-tree": _vm.updateTree }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }