var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bulkUpload" },
    [
      _c("div", { staticClass: "bulkUpload-content" }, [
        _c("div", { staticClass: "steps-view" }, [
          _c("div", { staticClass: "steps-number" }, [
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-line",
                class: _vm.uploadStep > 0 ? "finished" : ""
              },
              [_vm._m(0), _vm._v(" "), _vm._m(1)]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-line",
                class: _vm.uploadStep > 1 ? "finished" : ""
              },
              [
                _c("div", { staticClass: "steps-number-view" }, [
                  _c("span", { class: _vm.uploadStep > 0 ? "having" : "" }, [
                    _vm._v("2")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "steps-number-line" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "steps-text-item",
                    class: _vm.uploadStep > 0 ? "havingText" : ""
                  },
                  [
                    _c("span", [_vm._v("步骤 2")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("选择待上传的作品")])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-line",
                class: _vm.uploadStep > 2 ? "finished" : ""
              },
              [
                _c("div", { staticClass: "steps-number-view" }, [
                  _vm.uploadStep !== 2
                    ? _c(
                        "span",
                        { class: _vm.uploadStep > 2 ? "having" : "" },
                        [_vm._v("3")]
                      )
                    : _c("div", { staticClass: "uploadLoading" }, [_c("span")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "steps-number-line" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "steps-text-item",
                    class: _vm.uploadStep > 1 ? "havingText" : ""
                  },
                  [
                    _c("span", [_vm._v("步骤 3")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("批量上传作品")])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-end",
                class: _vm.uploadStep > 3 ? "finished" : ""
              },
              [
                _c("div", { staticClass: "steps-number-view" }, [
                  _c("span", { class: _vm.uploadStep > 2 ? "having" : "" }, [
                    _vm._v("4")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "steps-text-item",
                    class: _vm.uploadStep > 2 ? "havingText" : ""
                  },
                  [
                    _c("span", [_vm._v("步骤 4")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("完成批量上传")])
                  ]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.uploadStep === 0,
                expression: "uploadStep === 0"
              }
            ],
            staticClass: "animated content",
            class: _vm.nextLeft ? "fadeOutLeft" : "fadeOutRight",
            staticStyle: { height: "calc(100vh - 230px)" }
          },
          [
            _c("bulk-basic-info", {
              ref: "bulkBasicInfo",
              attrs: {
                "partner-open-id": _vm.partnerOpenId,
                "resource-type": _vm.resourceType
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.uploadStep > 0,
                expression: "uploadStep > 0"
              }
            ],
            staticClass: "animated content",
            class: _vm.nextRight ? "fadeInRight" : "fadeInLeft",
            staticStyle: {
              height: "calc(100vh - 230px)",
              "padding-top": "20px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "bulk-video" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "multipleTable",
                    class: _vm.videoArr.length <= 0 ? "video-upload-table" : "",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.videoArr, "tooltip-effect": "dark" },
                    on: {
                      "select-all": _vm.selectAll,
                      "selection-change": _vm.handleSelectionChange
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        selectable: _vm.selectable,
                        width: "50"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "作品" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              !scope.row.titleIpt
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "serial file-name",
                                      on: {
                                        click: function($event) {
                                          return _vm.showName(scope.row)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.name
                                            ? scope.row.name
                                            : "点击填写作品名称"
                                        )
                                      )
                                    ]
                                  )
                                : _c("div", { staticClass: "serial-content" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: scope.row.titleTemporary,
                                          expression: "scope.row.titleTemporary"
                                        }
                                      ],
                                      ref: "name" + scope.row.id,
                                      staticClass: "serial-ipt",
                                      attrs: {
                                        type: "text",
                                        placeholder: "请填写作品名称"
                                      },
                                      domProps: {
                                        value: scope.row.titleTemporary
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.confirmName(scope.row)
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            scope.row,
                                            "titleTemporary",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "编号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              !scope.row.serialIpt
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "serial",
                                      on: {
                                        click: function($event) {
                                          return _vm.showSerial(scope.row)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.serial
                                            ? scope.row.serial
                                            : "点击填写作品编号"
                                        )
                                      )
                                    ]
                                  )
                                : _c("div", { staticClass: "serial-content" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: scope.row.serialTemporary,
                                          expression:
                                            "scope.row.serialTemporary"
                                        }
                                      ],
                                      ref: "input" + scope.row.id,
                                      staticClass: "serial-ipt",
                                      attrs: {
                                        type: "text",
                                        placeholder: "请填写作品编号"
                                      },
                                      domProps: {
                                        value: scope.row.serialTemporary
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.confirm(scope.row)
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            scope.row,
                                            "serialTemporary",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "大小", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.size) +
                                  "M\n            "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "进度", width: "350" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.uploadState === "exception"
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: "网络中断,请尝试继续上传",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("el-progress", {
                                        attrs: {
                                          percentage: scope.row.progress,
                                          status:
                                            scope.row.uploadState ===
                                              "success" ||
                                            scope.row.uploadState ===
                                              "warning" ||
                                            scope.row.uploadState ===
                                              "exception"
                                              ? scope.row.uploadState
                                              : scope.row.status,
                                          format:
                                            scope.row.uploadState === "start" &&
                                            scope.row.progress === 0
                                              ? _vm.formatStart
                                              : _vm.format
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : scope.row.uploadState === "warning"
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: "保存失败,请联系开发人员",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("el-progress", {
                                        attrs: {
                                          percentage: scope.row.progress,
                                          status:
                                            scope.row.uploadState ===
                                              "success" ||
                                            scope.row.uploadState ===
                                              "warning" ||
                                            scope.row.uploadState ===
                                              "exception"
                                              ? scope.row.uploadState
                                              : scope.row.status,
                                          format:
                                            scope.row.uploadState === "start" &&
                                            scope.row.progress === 0
                                              ? _vm.formatStart
                                              : _vm.format
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("el-progress", {
                                    attrs: {
                                      percentage: scope.row.progress,
                                      status:
                                        scope.row.uploadState === "success" ||
                                        scope.row.uploadState === "warning" ||
                                        scope.row.uploadState === "exception"
                                          ? scope.row.uploadState
                                          : scope.row.status,
                                      format:
                                        scope.row.uploadState === "start" &&
                                        scope.row.progress === 0
                                          ? _vm.formatStart
                                          : _vm.format
                                    }
                                  })
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "操作",
                        width: "100",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.uploadIng &&
                                        scope.row.uploadState === "start",
                                      expression:
                                        "uploadIng && scope.row.uploadState === 'start'"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.isPauseUpload,
                                          expression: "scope.row.isPauseUpload"
                                        }
                                      ],
                                      staticStyle: { padding: "0" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.restoreItem(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("恢复")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !scope.row.isPauseUpload,
                                          expression: "!scope.row.isPauseUpload"
                                        }
                                      ],
                                      staticStyle: {
                                        padding: "0",
                                        margin: "0"
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.uploadPauseItem(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("暂停")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.uploadIng &&
                                        scope.row.uploadState === "exception",
                                      expression:
                                        "uploadIng && scope.row.uploadState === 'exception'"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        padding: "0",
                                        margin: "0"
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.restoreItem(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("继续")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              scope.row.uploadState !== "ready"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "#C0C4CC",
                                        padding: "0"
                                      },
                                      attrs: { type: "text" }
                                    },
                                    [_vm._v("移除")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        padding: "0",
                                        color: "#F56C6C"
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removerItemVideo(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.videoArr.length <= 0,
                        expression: "videoArr.length <= 0"
                      }
                    ],
                    staticClass: "file-upload-bg"
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        attrs: {
                          "http-request": _vm.fileChange,
                          "show-file-list": false,
                          action: "",
                          multiple: ""
                        }
                      },
                      [
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v("批量选择作品")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "batch-bottom box" }, [
          _c(
            "div",
            {
              staticClass: "batch-item-start",
              style: _vm.uploadStep > 0 ? "justify-content: space-between" : ""
            },
            [
              _vm.uploadStep > 0
                ? _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("el-checkbox", {
                        staticClass: "check-all",
                        attrs: {
                          indeterminate: _vm.isIndeterminate,
                          disabled: _vm.videoArr.length <= 0
                        },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: _vm.uploadStep >= 3,
                            plain: ""
                          },
                          on: { click: _vm.removeVideo }
                        },
                        [_vm._v("移除")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-btn",
                          attrs: {
                            "http-request": _vm.fileChange,
                            "show-file-list": false,
                            disabled: _vm.uploadStep >= 3 || _vm.uploadIng,
                            action: "",
                            multiple: ""
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.uploadStep >= 3 || _vm.uploadIng,
                                type: "primary"
                              }
                            },
                            [_vm._v("添加作品")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.videoArr.length !== 0
                        ? _c("span", { staticClass: "statistics" }, [
                            _vm._v(
                              "待上传：" +
                                _vm._s(
                                  _vm.videoArr.length -
                                    _vm.uploadComplete.length
                                ) +
                                "个，已上传：" +
                                _vm._s(_vm.uploadComplete.length) +
                                "个，共 " +
                                _vm._s(_vm.videoArr.length) +
                                " 个"
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.uploadStep <= 2
                    ? _c("el-button", { on: { click: _vm.uploadCancel } }, [
                        _vm._v("取消")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploadStep === 0
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.next } },
                        [_vm._v("下一步")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploadStep === 1 && !_vm.uploadIng
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.previous }
                        },
                        [_vm._v("上一步")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploadStep === 1 && !_vm.uploadIng
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            disabled: _vm.videoArr.length <= 0,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              _vm.uploadVisible = true
                            }
                          }
                        },
                        [_vm._v("开始上传")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.uploadStep === 2) & _vm.uploadIng
                    ? _c(
                        "span",
                        [
                          _vm.allStopUpload
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.restore }
                                },
                                [_vm._v("继续")]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.uploadPause }
                                },
                                [_vm._v("暂停")]
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploadStep > 2
                    ? _c("span", [
                        !_vm.showSubmit
                          ? _c(
                              "div",
                              {
                                staticClass: "loadingBtn",
                                attrs: { type: "primary", plain: "" }
                              },
                              [
                                _c("div", {
                                  staticClass: "uploadLoading",
                                  staticStyle: {
                                    width: "14px",
                                    height: "14px",
                                    "border-width": "1px",
                                    "border-color": "#1890ff",
                                    "border-bottom-color": "transparent"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v("作品正在上传中，请稍后...")]
                                )
                              ]
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      background: "#fff",
                                      "border-color": "#DCDFE6",
                                      color: "#606266"
                                    },
                                    attrs: { type: "primary" },
                                    on: { click: _vm.submitClosePage }
                                  },
                                  [_vm._v("完成")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onSubmit()
                                      }
                                    }
                                  },
                                  [_vm._v("批量提交审核")]
                                )
                              ],
                              1
                            )
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("upload-dialog", {
        ref: "dialog",
        attrs: {
          info: _vm.videoInfo,
          goods: _vm.goods,
          show: _vm.uploadVisible
        },
        on: {
          "update:show": function($event) {
            _vm.uploadVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-number-view" }, [
      _c("span", { staticClass: "having" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("div", { staticClass: "steps-number-line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-text-item havingText" }, [
      _c("span", [_vm._v("步骤 1")]),
      _vm._v(" "),
      _c("span", [_vm._v("设置作品的基本信息等")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }