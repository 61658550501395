var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-resource" },
    [
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c("dialog-filter-top-bar", {
            attrs: {
              "show-remember-select": false,
              "show-merchant": _vm.showMerchant,
              "expect-type": _vm.expectType
            },
            on: { search: _vm.search },
            model: {
              value: _vm.searchParams,
              callback: function($$v) {
                _vm.searchParams = $$v
              },
              expression: "searchParams"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("resource-content", {
        ref: "resourceContent",
        attrs: {
          loading: _vm.loading,
          refresh: _vm.refresh,
          "resource-list": _vm.resources.data,
          "resouce-type": _vm.searchParams.resourceType,
          value: _vm.resourceOpenId,
          "multiple-val": _vm.multipleVal,
          "multiple-check": _vm.multipleCheck
        },
        on: {
          refreshResource: _vm.refreshResource,
          handleResourceContent: _vm.handleResource
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("pagination", {
            attrs: {
              total: _vm.resources.totalElements,
              page: _vm.searchParams.page,
              limit: _vm.searchParams.size,
              "current-page": _vm.searchParams.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.searchParams, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.searchParams, "size", $event)
              },
              pagination: _vm.updateList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }