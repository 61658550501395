var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-block clearfix" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "tab-block"
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "tab-title",
                  attrs: { label: "专辑信息", name: "first" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "album-form-box" },
                    [
                      _c("div", { staticClass: "clearfix" }, [
                        _c("h3", { staticClass: "msg-title fl" }, [
                          _vm._v("基本信息")
                        ]),
                        _vm._v(" "),
                        _vm.albumId && _vm.albumInfo.state
                          ? _c(
                              "div",
                              { staticClass: "state-bg" },
                              [
                                _c("span", { staticClass: "state-date" }, [
                                  _vm._v(
                                    _vm._s(_vm.statusList[0].dateLabel) +
                                      ":" +
                                      _vm._s(
                                        _vm.getDateDiff(
                                          _vm.albumInfo[
                                            _vm.statusList[0].dateName
                                          ]
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "timer",
                                    attrs: { effect: "dark", placement: "top" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "line-height": "20px" },
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [
                                        _vm.albumInfo[
                                          _vm.statusList[0].dateName
                                        ]
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.statusList[0].dateLabel
                                                ) +
                                                  "：" +
                                                  _vm._s(
                                                    _vm._f("parseTime")(
                                                      _vm.albumInfo[
                                                        _vm.statusList[0]
                                                          .dateName
                                                      ],
                                                      "{y}-{m}-{d} {h}:{i}"
                                                    )
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" "),
                                        _vm.albumInfo.createdAt
                                          ? _c("span", [
                                              _vm._v(
                                                "创建时间：" +
                                                  _vm._s(
                                                    _vm._f("parseTime")(
                                                      _vm.albumInfo.createdAt,
                                                      "{y}-{m}-{d} {h}:{i}"
                                                    )
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tag",
                                      {
                                        staticClass: "state-tag",
                                        attrs: {
                                          type: _vm.statusList[0].typeName
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.statusList[0].btnName)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "albumInfo",
                          staticClass: "tab-form",
                          attrs: {
                            model: _vm.albumInfo,
                            rules: _vm.rules,
                            "label-width": "100px"
                          }
                        },
                        [
                          _vm.albumInfo.resourceOpenId
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "作品ID" } },
                                [
                                  _vm.albumInfo.resourceOpenId
                                    ? _c("span", { staticClass: "title-id" }, [
                                        _vm._v(
                                          _vm._s(_vm.albumInfo.resourceOpenId)
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "专辑名称", prop: "title" } },
                            [
                              _c("el-autocomplete", {
                                staticStyle: { width: "850px" },
                                attrs: {
                                  disabled: _vm.disabledEdit,
                                  "show-word-limit": "",
                                  maxlength: 100,
                                  debounce: 500,
                                  placeholder:
                                    "请输入专辑名称（建议字数在20以内，不超过45个字）",
                                  "fetch-suggestions": _vm.searchTitle
                                },
                                model: {
                                  value: _vm.albumInfo.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.albumInfo, "title", $$v)
                                  },
                                  expression: "albumInfo.title"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "作品编号" } },
                            [
                              _c("el-input", {
                                staticClass: "txt-width850",
                                attrs: {
                                  placeholder: "请输入作品编号",
                                  disabled: _vm.disabledEdit,
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.albumInfo.resourceEncode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.albumInfo,
                                      "resourceEncode",
                                      $$v
                                    )
                                  },
                                  expression: "albumInfo.resourceEncode"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-text",
                              attrs: { label: "专辑简介", prop: "introduction" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "850px" },
                                attrs: {
                                  disabled: _vm.disabledEdit,
                                  autosize: { minRows: 2, maxRows: 5 },
                                  type: "textarea",
                                  maxlength: "100",
                                  placeholder: "请输入专辑简介"
                                },
                                model: {
                                  value: _vm.albumInfo.introduction,
                                  callback: function($$v) {
                                    _vm.$set(_vm.albumInfo, "introduction", $$v)
                                  },
                                  expression: "albumInfo.introduction"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item", {
                            staticClass: "fl cover-img",
                            attrs: { label: "专辑封面", prop: "cover" }
                          }),
                          _vm._v(" "),
                          _c("cover-upload", {
                            staticClass: "album-cover",
                            attrs: {
                              path: _vm.path,
                              "partner-open-id": _vm.partnerOpenId,
                              disabledEdit: _vm.disabledEdit,
                              coverarr: _vm.coverarr,
                              covertext: _vm.covertext,
                              type: "4"
                            },
                            on: { deletecover: _vm.deletecoverFun },
                            model: {
                              value: _vm.albumInfo.cover,
                              callback: function($$v) {
                                _vm.$set(_vm.albumInfo, "cover", $$v)
                              },
                              expression: "albumInfo.cover"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "专辑介绍", prop: "region" } },
                            [
                              _c("editor", {
                                attrs: { readonly: _vm.disabledEdit },
                                model: {
                                  value: _vm.albumInfo.detail,
                                  callback: function($$v) {
                                    _vm.$set(_vm.albumInfo, "detail", $$v)
                                  },
                                  expression: "albumInfo.detail"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v("\n              资源附件\n            ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "资源栏目名" } },
                            [
                              _c("el-input", {
                                staticClass: "txt-width850",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入资源栏目名"
                                },
                                model: {
                                  value: _vm.albumInfo.columnTitle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.albumInfo, "columnTitle", $$v)
                                  },
                                  expression: "albumInfo.columnTitle"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item", { attrs: { label: "文件上传" } }, [
                            _c(
                              "div",
                              { staticClass: "txt-width850" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "header-row-style": { height: "30px" },
                                      "header-cell-style": {
                                        background: "#F5F7FA"
                                      },
                                      data: _vm.attachmentList,
                                      border: ""
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "name",
                                        label: "作品名",
                                        "min-width": "240"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    scope.row.display.title
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "作品编号",
                                        width: "300"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "resource-encode-flex"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "resource-encode"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.key
                                                            .resourceEncode
                                                            ? scope.row.key
                                                                .resourceEncode
                                                            : "--"
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont icon-copy encode-copy-icon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.copyResourceEncode(
                                                          scope.row.key
                                                            .resourceEncode,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "操作", width: "100" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    color: "#409EFF"
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.previewResource(
                                                        scope.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("预览")]
                                              ),
                                              _vm._v(" "),
                                              !_vm.disabledEdit
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "rgb(245, 108, 108)"
                                                      },
                                                      attrs: {
                                                        type: "text",
                                                        size: "small"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.delRow(
                                                            scope.$index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("移除")]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "btnBox" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: _vm.disabledEdit,
                                          size: "small",
                                          type: "primary",
                                          plain: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.outerVisible = true
                                          }
                                        }
                                      },
                                      [_vm._v("选择文件")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: _vm.disabledEdit,
                                          size: "small",
                                          type: "danger",
                                          plain: ""
                                        },
                                        on: { click: _vm.delAll }
                                      },
                                      [_vm._v("全部删除")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("h3", { staticClass: "msg-title" }, [
                                _vm._v("作品属性")
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "同步" } },
                                [
                                  _c("sync-view", {
                                    staticStyle: { width: "850px" },
                                    attrs: { isDisabled: _vm.disabledEdit },
                                    model: {
                                      value: _vm.albumInfo.tempCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.albumInfo, "tempCode", $$v)
                                      },
                                      expression: "albumInfo.tempCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "分类", prop: "types" } },
                                [
                                  _c("works", {
                                    ref: "worksView",
                                    staticStyle: { width: "850px" },
                                    attrs: { isDisabled: _vm.disabledEdit },
                                    on: { "add-classtion": _vm.addclasstion }
                                  }),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    attrs: { value: _vm.albumInfo.types }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "版次" } },
                                [
                                  _c("edition-box", {
                                    staticStyle: { width: "850px" },
                                    attrs: { isDisabled: _vm.disabledEdit },
                                    model: {
                                      value: _vm.albumInfo.periodCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.albumInfo,
                                          "periodCode",
                                          $$v
                                        )
                                      },
                                      expression: "albumInfo.periodCode"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    model: {
                                      value: _vm.albumInfo.periodCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.albumInfo,
                                          "periodCode",
                                          $$v
                                        )
                                      },
                                      expression: "albumInfo.periodCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "类目",
                                    prop: "secondRegimentationIds"
                                  }
                                },
                                [
                                  _c("category-view", {
                                    staticStyle: { width: "850px" },
                                    attrs: { "is-disabled": _vm.disabledEdit },
                                    on: {
                                      "category-change": _vm.categoryChange
                                    },
                                    model: {
                                      value:
                                        _vm.albumInfo.secondRegimentationIds,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.albumInfo,
                                          "secondRegimentationIds",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "albumInfo.secondRegimentationIds"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    model: {
                                      value:
                                        _vm.albumInfo.secondRegimentationIds,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.albumInfo,
                                          "secondRegimentationIds",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "albumInfo.secondRegimentationIds"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "property-box" },
                                [
                                  _c("el-collapse-transition", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isShowProperty,
                                            expression: "isShowProperty"
                                          }
                                        ],
                                        staticClass: "property-item-bg"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地区" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "850px" },
                                                attrs: {
                                                  disabled: _vm.disabledEdit,
                                                  multiple: "",
                                                  filterable: "",
                                                  "default-first-option": "",
                                                  placeholder:
                                                    "请输入或选择地区"
                                                },
                                                model: {
                                                  value: _vm.areaValue,
                                                  callback: function($$v) {
                                                    _vm.areaValue = $$v
                                                  },
                                                  expression: "areaValue"
                                                }
                                              },
                                              _vm._l(_vm.areaList, function(
                                                item
                                              ) {
                                                return _c("el-option", {
                                                  key: item.code,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.code
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "标签" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "850px" },
                                                attrs: {
                                                  disabled: _vm.disabledEdit,
                                                  multiple: "",
                                                  filterable: "",
                                                  "allow-create": "",
                                                  "default-first-option": "",
                                                  placeholder:
                                                    "请输入或选择商品标签"
                                                },
                                                model: {
                                                  value: _vm.tagValue,
                                                  callback: function($$v) {
                                                    _vm.tagValue = $$v
                                                  },
                                                  expression: "tagValue"
                                                }
                                              },
                                              _vm._l(_vm.tagList, function(
                                                item
                                              ) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    class: [
                                      "property-item-arrow",
                                      _vm.isShowProperty
                                        ? "el-icon-arrow-up"
                                        : "el-icon-arrow-down"
                                    ],
                                    on: {
                                      click: function($event) {
                                        _vm.isShowProperty = !_vm.isShowProperty
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("h3", { staticClass: "msg-title" }, [
                                _vm._v("商品设置")
                              ]),
                              _vm._v(" "),
                              _c("goods-set", {
                                ref: "commodity",
                                attrs: {
                                  disabledEdit: _vm.disabledEdit,
                                  partnerOpenId: _vm.partnerOpenId,
                                  "type-name": _vm.typeName,
                                  "independent-sale":
                                    _vm.albumInfo.independentSale,
                                  "resource-open-id": _vm.albumId
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h3", { staticClass: "msg-title" }, [
                            _vm._v("\n              专辑设置\n            ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "展示风格" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.disabledEdit,
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.albumInfo.displayType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.albumInfo,
                                        "displayType",
                                        $$v
                                      )
                                    },
                                    expression: "albumInfo.displayType"
                                  }
                                },
                                _vm._l(_vm.displayTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-isOver-bg",
                              attrs: { label: "" }
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: _vm.disabledEdit },
                                  model: {
                                    value: _vm.albumInfo.finish,
                                    callback: function($$v) {
                                      _vm.$set(_vm.albumInfo, "finish", $$v)
                                    },
                                    expression: "albumInfo.finish"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                已完结\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.albumInfo.finish
                                ? _c(
                                    "div",
                                    { staticClass: "total-set-bg" },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "内容总量" } },
                                        [
                                          _c("el-input-number", {
                                            staticClass: "txt-total-class",
                                            attrs: {
                                              disabled: _vm.disabledEdit,
                                              "controls-position": "right"
                                            },
                                            model: {
                                              value: _vm.albumInfo.totalClass,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.albumInfo,
                                                  "totalClass",
                                                  $$v
                                                )
                                              },
                                              expression: "albumInfo.totalClass"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-info icon-remark"
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "remark-bg" },
                                            [
                                              _vm._v(
                                                "若总量填0，则专辑状态为“持续更新”;"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "explain-box" }, [
                        _c("div", { staticClass: "explain-title" }, [
                          _vm._v("\n              作品发布说明\n            ")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "explain-con" }, [
                          _vm._v(
                            "\n              1. 未完结课程需要上传至少10节以上课程才能上线，上线后需保证更新进度，否则将视情况下线；"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              2. 除特殊情况外，不允许出现10元以下低价课及免费课；如有需要，需提前与平台沟通；"
                          ),
                          _c("br")
                        ])
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled: _vm.albumId.toString() <= 0,
                    label: _vm.albumContentTableName,
                    name: "second"
                  }
                },
                [
                  _vm.activeName == "second"
                    ? _c("work-content", {
                        attrs: {
                          "open-id": _vm.albumId,
                          "is-release": _vm.isRelease,
                          "partner-open-id": _vm.partnerOpenId,
                          "content-count": _vm.albumContentCount
                        },
                        on: {
                          "update:contentCount": function($event) {
                            _vm.albumContentCount = $event
                          },
                          "update:content-count": function($event) {
                            _vm.albumContentCount = $event
                          },
                          "upload-album-content-nums":
                            _vm.uploadAlbumContentNums,
                          "save-release": _vm.saveRelease,
                          "con-save": _vm.conSave
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("works-operation-buttton", {
            attrs: {
              openId: _vm.albumId,
              allowEdit: _vm.disabledEdit,
              operation: _vm.operation,
              status: _vm.albumInfo.state,
              passBtn: _vm.passBtn,
              refuseBtn: _vm.refuseBtn,
              nextLoad: _vm.nextBtn,
              restoreBtn: _vm.restoreBtn,
              publishBtn: _vm.publishBtn,
              saveBtn: _vm.saveBtn,
              "type-name": _vm.typeName
            },
            on: {
              closeView: _vm.closeView,
              updateEdit: _vm.updateEdit,
              passItem: _vm.batchReview,
              handelRefuse: _vm.handelRefuse,
              handelSaveAndReview: _vm.saveRelease,
              handleBookRestore: _vm.setRestoreAlbum,
              saveData: _vm.saveDrafts,
              saveDraft: _vm.saveDrafts,
              nextStep: _vm.saveInfo
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("refuse-resource", {
        on: { "refuse-obj": _vm.refuseObj },
        model: {
          value: _vm.dialogRefuse,
          callback: function($$v) {
            _vm.dialogRefuse = $$v
          },
          expression: "dialogRefuse"
        }
      }),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.outerVisible },
        on: {
          "update:visible": function($event) {
            _vm.outerVisible = $event
          }
        },
        model: {
          value: _vm.attachmentObj,
          callback: function($$v) {
            _vm.attachmentObj = $$v
          },
          expression: "attachmentObj"
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }