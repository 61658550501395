var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "directoryManagement" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "directoryRedialog",
          attrs: {
            width: "900px",
            title: _vm.catalogReTitle,
            visible: _vm.catalogReDialogVisible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.catalogReDialogVisible = $event
            }
          }
        },
        [
          this.catalogReTitle === "目录重命名"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "catalogId" }, [
                    _c("span", [_vm._v("目录名称")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("OpenId: " + _vm._s(_vm.addForm.bookMenuOpenId))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("Editor", {
                    ref: "ueditor",
                    staticStyle: { "margin-top": "10px" },
                    attrs: { config: { zIndex: 3000 } },
                    model: {
                      value: _vm.addForm.reNames,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "reNames", $$v)
                      },
                      expression: "addForm.reNames"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cancleAndSubmit" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.catalogReDialogVisible = false
                            }
                          }
                        },
                        [_vm._v("\n          取 消\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.reName()
                            }
                          }
                        },
                        [_vm._v("\n          保 存\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "directory-dialog",
          attrs: {
            width: "550px",
            title: _vm.catalogTitle,
            visible: _vm.catalogDialogVisible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.catalogDialogVisible = $event
            }
          }
        },
        [
          this.catalogTitle !== "将目录移动到..." &&
          this.catalogTitle !== "批量添加目录" &&
          this.catalogTitle !== "批量复制目录"
            ? _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    model: _vm.addForm,
                    "label-width": "80px",
                    rules: _vm.catalogRules
                  }
                },
                [
                  _vm.catalogTitle === "添加子目录"
                    ? _c("el-form-item", { attrs: { label: "父级名称" } }, [
                        _c("span", {
                          staticClass: "htmlImg",
                          attrs: { disabled: "" },
                          domProps: {
                            innerHTML: _vm._s(_vm.addForm.fatherName)
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.addForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "name", $$v)
                          },
                          expression: "addForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "50%" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.addForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "sequence", $$v)
                              },
                              expression: "addForm.sequence"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.catalogDialogVisible = false
                                }
                              }
                            },
                            [_vm._v("\n            取 消\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.menuLoading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submit("addForm")
                                }
                              }
                            },
                            [_vm._v("\n            确 定\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.catalogTitle === "将目录移动到..."
            ? _c("div", [
                _c(
                  "div",
                  { staticStyle: { height: "300px", "overflow-y": "auto" } },
                  [
                    _c("el-tree", {
                      ref: "catalogTree",
                      attrs: {
                        data: _vm.typeList,
                        props: _vm.defaultProps,
                        "default-expand-all": true,
                        "expand-on-click-node": false
                      },
                      on: { "node-click": _vm.transHandleNodeClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "div",
                                {
                                  staticClass: "custom-tree-node",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "treeName",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center"
                                      },
                                      style:
                                        data.id === _vm.checkMenuID ? "" : ""
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "treeName",
                                        staticStyle: { "min-height": "23px" },
                                        domProps: {
                                          innerHTML: _vm._s(node.label)
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        540518987
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cancleAndSubmit" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.catalogDialogVisible = false
                          }
                        }
                      },
                      [_vm._v("\n          取 消\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.menuLoading },
                        on: {
                          click: function($event) {
                            return _vm.submit()
                          }
                        }
                      },
                      [_vm._v("\n          确 定\n        ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          this.catalogTitle === "批量添加目录"
            ? _c("div", { staticClass: "diagBox" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.batchDirectory,
                      expression: "batchDirectory"
                    }
                  ],
                  staticClass: "batch-textarea",
                  attrs: { id: "batchDirectory", rows: "12" },
                  domProps: { value: _vm.batchDirectory },
                  on: {
                    focus: _vm.add_eventListener,
                    blur: _vm.remove_eventListener,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.batchDirectory = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cancleAndSubmit" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            _vm.catalogDialogVisible = false
                          }
                        }
                      },
                      [_vm._v("\n          取 消\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "primary",
                          loading: _vm.menuLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDefaultProps()
                          }
                        }
                      },
                      [_vm._v("\n          确 定\n        ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.catalogTitle === "批量复制目录",
                  expression: "this.catalogTitle === '批量复制目录'"
                }
              ],
              staticClass: "diagBox"
            },
            [
              _c("textarea", {
                staticClass: "batch-textarea",
                attrs: { id: "batchDirectory", rows: "12", readonly: "" },
                domProps: { innerHTML: _vm._s(_vm.batchDirectory) }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "rowBox" },
        [
          _c(
            "multipane",
            {
              staticClass: "container-box vertical-panes",
              attrs: { layout: "vertical" }
            },
            [
              _c(
                "div",
                { staticClass: "leftBox" },
                [
                  _c(
                    "div",
                    { staticClass: "recycleBin" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.isShowRecycleBin,
                            callback: function($$v) {
                              _vm.isShowRecycleBin = $$v
                            },
                            expression: "isShowRecycleBin"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _vm._v(
                                "\n              显示回收站目录\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tabs",
                    {
                      staticClass: "tabsCard",
                      attrs: { type: "card" },
                      model: {
                        value: _vm.treeName,
                        callback: function($$v) {
                          _vm.treeName = $$v
                        },
                        expression: "treeName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          attrs: { label: "资源目录", name: "userManagement" }
                        },
                        [
                          _c("div", { staticClass: "tabsCardAll" }, [
                            _c("div", { staticClass: "leftBoxTop" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.showAddCatalog }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus-outline"
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("添加目录")])
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c("i", {
                                  staticClass: "el-icon-refresh-right refresh",
                                  on: { click: _vm.refresh }
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "iconfont noWeight",
                                  class: _vm.expandAll
                                    ? "icon-category-collapse"
                                    : "icon-category-expand",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.isExpand }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "treeBox",
                                on: { click: _vm.clearTree }
                              },
                              [
                                _c("el-tree", {
                                  ref: "catalogTree",
                                  attrs: {
                                    data: _vm.ContentsList,
                                    props: _vm.defaultProps,
                                    "allow-drop": _vm.allowDrop,
                                    draggable: "",
                                    "node-key": "id",
                                    "default-expand-all": _vm.isShow,
                                    "default-expanded-keys": _vm.expanded,
                                    "expand-on-click-node": false
                                  },
                                  on: {
                                    "node-drop": _vm.nodeDragEnter,
                                    "node-click": _vm.handleNodeClick
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return _c(
                                          "div",
                                          {
                                            staticClass: "custom-tree-node",
                                            staticStyle: { width: "100%" },
                                            on: {
                                              mouseenter: function($event) {
                                                $event.stopPropagation()
                                                return _vm.mouseoverFun(data)
                                              },
                                              mouseleave: function($event) {
                                                $event.stopPropagation()
                                                return _vm.mouseoutFun()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  enterable: false,
                                                  placement: "top-start",
                                                  "open-delay": 1000,
                                                  effect: "light"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "directory-tooltip",
                                                    attrs: { slot: "content" },
                                                    slot: "content"
                                                  },
                                                  [
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          node.label
                                                        )
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "treeName",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center"
                                                    },
                                                    style:
                                                      data.id ===
                                                      _vm.checkMenuID
                                                        ? "color:#409EFF;font-weight: bold;"
                                                        : "",
                                                    attrs: { id: data.id }
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "treeName",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          node.label
                                                        )
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    data.deleted
                                                      ? _c("span", [
                                                          _vm._v("(已删除)")
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-dropdown",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              data.id ===
                                                              _vm.mouseid,
                                                            expression:
                                                              "data.id === mouseid"
                                                          }
                                                        ],
                                                        staticStyle: {
                                                          position: "absolute",
                                                          right: "14px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-dropdown-link"
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-more"
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dropdown-menu",
                                                          {
                                                            attrs: {
                                                              slot: "dropdown"
                                                            },
                                                            slot: "dropdown"
                                                          },
                                                          [
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addSoncatalog(
                                                                      node,
                                                                      data
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "添加子目录"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.batchAdd(
                                                                      data
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "批量添加子目录"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleCopy(
                                                                      node,
                                                                      data
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "复制目录名称"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.rename(
                                                                      node,
                                                                      data
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "目录重命名"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.transfer(
                                                                      node,
                                                                      data
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "转移到..."
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.isShowRecycleBin ===
                                                            false
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.remove(
                                                                          node,
                                                                          data
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "移除"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            data.deleted ===
                                                            true
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.recovery(
                                                                          node,
                                                                          data
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "恢复"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "directoryMan" },
                              [
                                _vm._v(
                                  "\n                目录批量管理\n                "
                                ),
                                _c(
                                  "el-dropdown",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [_c("i", { staticClass: "el-icon-more" })]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.batchAdd($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      批量添加目录\n                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.batchCopy($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      批量复制目录\n                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("multipane-resizer", {
                staticClass: "resize-split-bg iconfont icon-tuozhuaidaxiao"
              }),
              _vm._v(" "),
              _c("div", { style: { flexGrow: 1 } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.resourcesLoading,
                        expression: "resourcesLoading"
                      }
                    ],
                    staticClass: "rightBox"
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        attrs: { type: "card" },
                        on: { "tab-click": _vm.getQrcode },
                        model: {
                          value: _vm.resources,
                          callback: function($$v) {
                            _vm.resources = $$v
                          },
                          expression: "resources"
                        }
                      },
                      [
                        _c(
                          "el-tab-pane",
                          {
                            staticClass: "forbid-select",
                            attrs: { label: "关联资源", name: "resources" }
                          },
                          [
                            _c("div", { staticClass: "rightCon" }, [
                              _vm.showsResources
                                ? _c("div", { staticClass: "rightContainer" }, [
                                    _c(
                                      "div",
                                      { staticClass: "rightLeft" },
                                      [
                                        _vm.list.length <= 0
                                          ? _c(
                                              "div",
                                              { staticClass: "content-box" },
                                              [
                                                _vm._v(
                                                  "\n                      请从右侧面板点击选取\n                    "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(_vm.list, function(item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "resourceList",
                                              attrs: { id: item.extDataOpenId },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeHeaderBox(
                                                    item.extDataOpenId
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "resourceListTop"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center"
                                                      }
                                                    },
                                                    [
                                                      item.icon
                                                        ? _c("el-image", {
                                                            staticStyle: {
                                                              width: "18px",
                                                              height: "18px",
                                                              "margin-right":
                                                                "10px"
                                                            },
                                                            attrs: {
                                                              src: _vm.getImgUrl(
                                                                item.icon
                                                              ),
                                                              fit: "cover"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.required
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red"
                                                              }
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.labelName
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      item.toolType
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tool-type-box"
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "round"
                                                              }),
                                                              _vm._v(" "),
                                                              item.toolType ===
                                                              "dictation"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "听写"
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.toolType ===
                                                              "followUp"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "跟读"
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.toolType ===
                                                              "crabGame"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "单词抓螃蟹"
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.toolType ===
                                                              "eliminatePleasure"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "单词消消乐"
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              item.toolType ===
                                                              "wordShorthand"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "单词速记"
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              item.tipMessage,
                                                            placement: "right"
                                                          }
                                                        },
                                                        [
                                                          item.tipMessage !== ""
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-info",
                                                                staticStyle: {
                                                                  color:
                                                                    "#409EFF",
                                                                  position:
                                                                    "relative",
                                                                  "margin-left":
                                                                    "5px"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !item.required
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                            position:
                                                              "absolute",
                                                            right: "20px"
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-close",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.spliceList(
                                                                  item,
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: { width: "97%" }
                                                },
                                                [
                                                  _c("default-content", {
                                                    attrs: {
                                                      type: item.inputType,
                                                      "default-value":
                                                        item.defaultValue,
                                                      bookOpenId:
                                                        _vm.bookOpenId,
                                                      bookMenuOpenId:
                                                        _vm.qrImgList
                                                          .bookMenuOpenId,
                                                      extDataOpenId:
                                                        item.extDataOpenId,
                                                      index: index,
                                                      previewType: true,
                                                      "textbook-field":
                                                        item.textbookField
                                                    },
                                                    on: {
                                                      changeValue:
                                                        _vm.changeValue,
                                                      changeDefault:
                                                        _vm.changeDefault,
                                                      handleChangeDefault:
                                                        _vm.handleChangeDefault
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "rightRight" }, [
                                      _c(
                                        "div",
                                        { staticClass: "rightTop" },
                                        [
                                          _vm._v(
                                            "\n                      资源类型\n                      "
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "screen",
                                              attrs: { placeholder: "全部" },
                                              model: {
                                                value: _vm.screenValue,
                                                callback: function($$v) {
                                                  _vm.screenValue = $$v
                                                },
                                                expression: "screenValue"
                                              }
                                            },
                                            _vm._l(_vm.screenList, function(
                                              item,
                                              index
                                            ) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.changeScreen(
                                                      item
                                                    )
                                                  }
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "rightTable" }, [
                                        _vm.queryExtData.length > 0
                                          ? _c(
                                              "div",
                                              _vm._l(_vm.queryExtData, function(
                                                item,
                                                index
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "rightTableInfo"
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox-group",
                                                      {
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.showCheckList(
                                                              $event,
                                                              item
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: _vm.checkList,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.checkList = $$v
                                                          },
                                                          expression:
                                                            "checkList"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-checkbox",
                                                          {
                                                            attrs: {
                                                              label:
                                                                item.labelName,
                                                              disabled:
                                                                item.required
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "extdata-box"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      "margin-left":
                                                                        "10px"
                                                                    }
                                                                  },
                                                                  [
                                                                    item.required
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle: {
                                                                              color:
                                                                                "red"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "*"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.labelName
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                item.defaultNum >
                                                                0
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "badge"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.defaultNum
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "typeOfQueryData"
                                                      },
                                                      [
                                                        _vm.checkList.indexOf(
                                                          item.labelName
                                                        ) >= 0
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "anchor",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.toAnchor(
                                                                      item.extDataOpenId
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("el-image", {
                                                                  attrs: {
                                                                    src: require("../../../assets/images/fa-anchor.svg")
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              item.inputType
                                                            ) +
                                                            "\n                          "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              0
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "noQueryData" },
                                              [
                                                _vm._v(
                                                  "\n                        暂无数据\n                      "
                                                )
                                              ]
                                            )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "rightTableSet" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                indeterminate:
                                                  _vm.isIndeterminate
                                              },
                                              on: {
                                                change: _vm.handleCheckAllChange
                                              },
                                              model: {
                                                value: _vm.checkAll,
                                                callback: function($$v) {
                                                  _vm.checkAll = $$v
                                                },
                                                expression: "checkAll"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px"
                                                  }
                                                },
                                                [_vm._v("全选")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              on: { click: _vm.toResources }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-setting"
                                              }),
                                              _vm._v(
                                                "\n                        管理\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm.ContentsList.length === 0
                                ? _c("div", { staticClass: "noListData" }, [
                                    _vm._v(
                                      "\n                  暂无数据\n                "
                                    )
                                  ])
                                : _c("div", { staticClass: "chooseCatelog" }, [
                                    _vm._v(
                                      "\n                  请选择当前目录下的子目录\n                "
                                    )
                                  ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relation" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "primary",
                              plain: "",
                              size: "small",
                              disabled: _vm.disabled,
                              id: "save"
                            },
                            on: {
                              click: function($event) {
                                return _vm.saveAssociation()
                              }
                            }
                          },
                          [_vm._v("\n              保存关联\n            ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("catalog-extends", {
            attrs: {
              "book-mode-i-d": parseInt(_vm.bookModeID),
              "dialog-visible": _vm.dialogVisible
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.dialogVisible = $event
              },
              "update:dialog-visible": function($event) {
                _vm.dialogVisible = $event
              },
              directoryDialogChange: _vm.directoryDialogChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }