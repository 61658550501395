var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "baseInfo"
    },
    [
      _c("div", { staticClass: "baseTitle" }, [_vm._v("抽奖活动信息")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          staticStyle: { "margin-bottom": "100px" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动名称", prop: "luckyDrawName" } },
            [
              _c("el-input", {
                staticStyle: { width: "600px" },
                attrs: {
                  disabled: !_vm.allowEdit && _vm.isnew === "false",
                  maxlength: "45",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.luckyDrawName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "luckyDrawName", $$v)
                  },
                  expression: "ruleForm.luckyDrawName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动说明" } },
            [
              _c("editor-view", {
                staticStyle: { width: "600px" },
                attrs: { readonly: !_vm.allowEdit && _vm.isnew === "false" },
                model: {
                  value: _vm.ruleForm.luckyDrawExplain,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "luckyDrawExplain", $$v)
                  },
                  expression: "ruleForm.luckyDrawExplain"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动起止", prop: "beginTime", required: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "600px" },
                attrs: {
                  disabled: !_vm.allowEdit && _vm.isnew === "false",
                  "default-time": ["00:00:00", "23:59:59"],
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: {
                  change: function($event) {
                    return _vm.handleDate(_vm.dateValue)
                  }
                },
                model: {
                  value: _vm.dateValue,
                  callback: function($$v) {
                    _vm.dateValue = $$v
                  },
                  expression: "dateValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动封面", prop: "luckyDrawImage" } },
            [
              _c("cover-upload", {
                attrs: {
                  path: _vm.path,
                  texttips: _vm.texttipsDown,
                  coverarr: _vm.coverArr,
                  covertext: _vm.coverText,
                  type: "抽奖活动",
                  disabledEdit: !_vm.allowEdit && _vm.isnew === "false"
                },
                on: { deletecover: _vm.deleteCover },
                model: {
                  value: _vm.ruleForm.luckyDrawImage,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "luckyDrawImage", $$v)
                  },
                  expression: "ruleForm.luckyDrawImage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "抽奖机会" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.allowEdit && _vm.isnew === "false",
                  "controls-position": "right",
                  min: 0
                },
                model: {
                  value: _vm.ruleForm.drawNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "drawNumber", $$v)
                  },
                  expression: "ruleForm.drawNumber"
                }
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _c("i", {
                  staticClass: "iconInfo el-icon-info",
                  staticStyle: { color: "#409EFF" }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticStyle: { color: "#909399", "font-size": "14px" } },
                  [_vm._v("用户每日可参与抽奖次数")]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "仅VIP可参与" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.allowEdit && _vm.isnew === "false" },
                  model: {
                    value: _vm.ruleForm.ifVip,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "ifVip", $$v)
                    },
                    expression: "ruleForm.ifVip"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "活动背景" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("cover-upload", {
                  attrs: {
                    path: _vm.path,
                    coverisshow: false,
                    texttips: _vm.texttipsDown,
                    coverarr: _vm.activityBackcoverArr,
                    covertext: _vm.activityBackcoverText,
                    type: "抽奖活动",
                    disabledEdit: !_vm.allowEdit && _vm.isnew === "false"
                  },
                  on: { deletecover: _vm.deleteCoverBack },
                  model: {
                    value: _vm.ruleForm.activityTitle,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "activityTitle", $$v)
                    },
                    expression: "ruleForm.activityTitle"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "flex-start"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "iconInfo el-icon-info",
                      staticStyle: { color: "#409EFF", margin: "0 5px 0 15px" }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("div", { staticClass: "video-text" }, [
                          _vm._v(_vm._s(_vm.activityBackcoverText))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "video-text",
                            staticStyle: { margin: "10px 0" }
                          },
                          [_vm._v(_vm._s(_vm.activityBackcoverText2))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.downdemoImgHandle }
                          },
                          [_vm._v("点击下载")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "背景色", prop: "backgroundColor" } },
            [
              _c("el-color-picker", {
                attrs: { disabled: !_vm.allowEdit && _vm.isnew === "false" },
                model: {
                  value: _vm.ruleForm.backgroundColor,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "backgroundColor", $$v)
                  },
                  expression: "ruleForm.backgroundColor"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: { "margin-left": "5px", "vertical-align": "top" }
                },
                [
                  _c("i", {
                    staticClass: "iconInfo el-icon-info",
                    staticStyle: { color: "#409EFF" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticStyle: { color: "#909399", "font-size": "14px" } },
                    [_vm._v("默认色是#FF554F")]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "baseTitle" }, [_vm._v("分享设置")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分享描述" } },
            [
              _c("el-input", {
                staticStyle: { width: "600px" },
                attrs: {
                  type: "textarea",
                  disabled: !_vm.allowEdit && _vm.isnew === "false",
                  placeholder: "淘知学堂 - 5分钟，学会一个知识点"
                },
                model: {
                  value: _vm.ruleForm.shareDescription,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "shareDescription", $$v)
                  },
                  expression: "ruleForm.shareDescription"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "缩略图" } },
            [
              _c("cover-upload", {
                attrs: {
                  path: _vm.path,
                  texttips: _vm.texttipsDown,
                  coverarr: _vm.thumbnailcoverArr,
                  covertext: _vm.thumbnailcoverText,
                  type: "抽奖活动",
                  disabledEdit: !_vm.allowEdit && _vm.isnew === "false"
                },
                on: { deletecover: _vm.deleteThumbnailcover },
                model: {
                  value: _vm.ruleForm.shareIcon,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "shareIcon", $$v)
                  },
                  expression: "ruleForm.shareIcon"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _vm.isnew === "true"
          ? _c(
              "div",
              { staticClass: "t-block" },
              [
                _c("el-button", { on: { click: _vm.cancelBtn } }, [
                  _vm._v("取消")
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.submitForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v("下一步")]
                )
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "t-block" },
              [
                _vm.allowEdit
                  ? _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.allowEdit = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.allowEdit
                  ? _c(
                      "el-button",
                      { attrs: { plan: "" }, on: { click: _vm.editBaseInfo } },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.allowEdit
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.allowEdit },
                        on: {
                          click: function($event) {
                            return _vm.submitForm("ruleForm")
                          }
                        }
                      },
                      [_vm._v("保存")]
                    )
                  : _vm._e()
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }