var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-file-box" },
    [
      _vm.fileListData.queue.length <= 0
        ? _c(
            "div",
            { staticClass: "uploader-btn-box" },
            [
              _vm.componentsType === "question"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.uploadFile }
                    },
                    [_vm._v(_vm._s(_vm.upLoadTxt))]
                  )
                : _c("el-button", { on: { click: _vm.uploadFile } }, [
                    _vm._v(_vm._s(_vm.upLoadTxt))
                  ]),
              _vm._v(" "),
              _vm.componentsType !== "question"
                ? _c("el-button", { on: { click: _vm.uploadAndSyncFile } }, [
                    _vm._v("上传并同步名称")
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("file-list-uploader", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "fileUploader",
        attrs: {
          isEBook: _vm.isEBook,
          isAutoUpload: true,
          disabled: !_vm.canUpload
        },
        model: {
          value: _vm.fileListData,
          callback: function($$v) {
            _vm.fileListData = $$v
          },
          expression: "fileListData"
        }
      }),
      _vm._v(" "),
      _vm.fileListData.queue.length > 0
        ? _c("div", { staticClass: "result-bg" }, [
            _vm.uploadStatus.SUCCEEDED !==
            _vm.fileListData.queue[_vm.fileListData.queue.length - 1]
              .uploadState
              ? _c("div", [
                  _vm._v(
                    "\n      文件上传中 " +
                      _vm._s(
                        (
                          _vm.fileListData.queue[
                            _vm.fileListData.queue.length - 1
                          ].uploadProgress * 100
                        ).toFixed(2)
                      ) +
                      "%\n    "
                  )
                ])
              : _vm.uploadStatus.WAITING ===
                _vm.fileListData.queue[_vm.fileListData.queue.length - 1]
                  .uploadState
              ? _c("div", [_vm._v("\n      上传失败\n    ")])
              : _c("div", { staticClass: "upload-success-bg" }, [
                  _vm.componentsType === "question"
                    ? _c(
                        "div",
                        { staticClass: "question-file" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "dark", placement: "top" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fileListData.queue[
                                        _vm.fileListData.queue.length - 1
                                      ].fileTitle
                                    ) +
                                      "." +
                                      _vm._s(
                                        _vm.fileListData.queue[
                                          _vm.fileListData.queue.length - 1
                                        ].fileExtName
                                      )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "question-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.fileListData.queue[
                                      _vm.fileListData.queue.length - 1
                                    ].fileTitle
                                  ) +
                                    "." +
                                    _vm._s(
                                      _vm.fileListData.queue[
                                        _vm.fileListData.queue.length - 1
                                      ].fileExtName
                                    )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "仅替换文件",
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.uploadStatus.SUCCEEDED !==
                                      _vm.fileListData.queue[
                                        _vm.fileListData.queue.length - 1
                                      ].uploadState,
                                    "auto-upload": false,
                                    "on-change": _vm.fileChange,
                                    action: "",
                                    accept: "*"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-congxinshangchuan question-del"
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "iconfont icon-recycle question-del",
                            on: { click: _vm.delFile }
                          })
                        ],
                        1
                      )
                    : _c("div", { staticClass: "upload-content" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.fileListData.queue[
                                _vm.fileListData.queue.length - 1
                              ].fileTitle
                            ) +
                              "." +
                              _vm._s(
                                _vm.fileListData.queue[
                                  _vm.fileListData.queue.length - 1
                                ].fileExtName
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _vm.canUpload
                          ? _c(
                              "div",
                              { staticClass: "operation-box" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "仅替换文件",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.uploadStatus.SUCCEEDED !==
                                            _vm.fileListData.queue[
                                              _vm.fileListData.queue.length - 1
                                            ].uploadState,
                                          "auto-upload": false,
                                          "on-change": _vm.fileChange,
                                          action: "",
                                          accept: "*"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-congxinshangchuan btn-operation"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "替换并同步名称",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.uploadStatus.SUCCEEDED !==
                                            _vm.fileListData.queue[
                                              _vm.fileListData.queue.length - 1
                                            ].uploadState,
                                          "auto-upload": false,
                                          "on-change": _vm.setFileAndName,
                                          action: "",
                                          accept: "*"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-tihuanbingtongbumingcheng btn-operation"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "download-operation-btn",
                                    attrs: {
                                      disabled: _vm.isDownLoad,
                                      type: "text"
                                    },
                                    on: { click: _vm.handleDownLoadFile }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-download download-icon",
                                      style: {
                                        color: _vm.isDownLoad
                                          ? "#c0c4cc"
                                          : "#909399"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-recycle btn-operation",
                                  on: { click: _vm.delFile }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }