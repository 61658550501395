var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "browsingHisWrap" }, [
    _c(
      "div",
      { staticClass: "tableDataSty" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableloading,
                expression: "tableloading"
              }
            ],
            attrs: { data: _vm.tableData, height: "100%" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "商品" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "display_flex align_items" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "goodsCover",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    scope.row.middleShopProductVo.cover,
                                    _vm.smallthumbnailStyle64
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "goodsCover",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "marginLeft_10" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(scope.row.middleShopProductVo.title) +
                              "\n            "
                          )
                        ])
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "数量", prop: "buyQuantity", width: "100" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "价格", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "¥" +
                            _vm._s(
                              scope.row.specsData.price * scope.row.buyQuantity
                            )
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "150", label: "添加时间", prop: "createdAt" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }