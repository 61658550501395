"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("./index.vue"));

//
//
//
//
//
//
var _default = {
  name: 'index.vue',
  components: {
    modelDetail: _index.default
  }
};
exports.default = _default;