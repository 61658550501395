"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cashOutCheck = _interopRequireDefault(require("./cashOutCheck.vue"));

var _cashOutRecord = _interopRequireDefault(require("./cashOutRecord.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'cashOutIndex',
  components: {
    cashOutCheck: _cashOutCheck.default,
    cashOutRecord: _cashOutRecord.default
  },
  data: function data() {
    return {
      cashOutName: '提现审核'
    };
  },
  methods: {
    cashOutTabs: function cashOutTabs(tab) {
      this.cashOutName = tab.label;
    }
  }
};
exports.default = _default;