var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "promotion-box" }, [
    _c(
      "div",
      { staticClass: "tab-content-bg" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "promotion-tabs-bg",
            on: { "tab-click": _vm.promoterCheckTabs }
          },
          [
            _c("el-tab-pane", { attrs: { label: "推广员认证" } }, [
              _c(
                "div",
                { staticClass: "promotion-tab-content" },
                [
                  _vm.promoterCheckName === "推广员认证"
                    ? _c("promotion-user-check")
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "认证记录" } }, [
              _c(
                "div",
                { staticClass: "promotion-tab-content" },
                [
                  _vm.promoterCheckName === "认证记录"
                    ? _c("promotion-user-record")
                    : _vm._e()
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }