var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tz-price-input" },
    [
      _c(
        "el-radio",
        {
          staticClass: "m-radio",
          attrs: { label: _vm.radioLabel, disabled: !_vm.enable },
          model: {
            value: _vm.activeProxy,
            callback: function($$v) {
              _vm.activeProxy = $$v
            },
            expression: "activeProxy"
          }
        },
        [_vm._v("please-hide-me")]
      ),
      _vm._v(" "),
      _vm.max !== undefined
        ? _c("el-input-number", {
            staticClass: "m-input",
            attrs: {
              min: _vm.min,
              max: _vm.max,
              precision: _vm.precision,
              disabled: _vm.disabled,
              "controls-position": "right"
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        : _c("el-input-number", {
            staticClass: "m-input",
            attrs: {
              min: _vm.min,
              precision: _vm.precision,
              disabled: _vm.disabled,
              "controls-position": "right"
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          }),
      _vm._v(" "),
      _c("div", { staticClass: "m-label" }, [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }