var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "batch-upload-bg",
      style: "width:" + _vm.coverArr[0] + "px;height:" + _vm.coverArr[1] + "px"
    },
    [
      _c("input", {
        ref: "uploadImg",
        staticClass: "file-upload",
        attrs: {
          id: "uploadImg",
          multiple: _vm.isMultiple,
          disabled: _vm.disabledEdit,
          type: "file",
          accept: "image/*"
        },
        on: {
          change: function($event) {
            return _vm.uploadImg()
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "batch-upload-box" }, [
      _c("i", { staticClass: "el-icon-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }