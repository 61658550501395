"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _priceParser = require("@taozhi/price-parser");

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _index = _interopRequireDefault(require("@/components/General/From/Selector/RadioGroup/index"));

var _index2 = _interopRequireDefault(require("@/components/ProductMarketplace/PopUp/ProductSelectDialog/index"));

var _CardContent = _interopRequireDefault(require("../Base/CardContent"));

var _index3 = _interopRequireDefault(require("./ProductSpecificationEditing/index"));

var _PriceWithCheckBox = _interopRequireDefault(require("./PriceWithCheckBox"));

var _PriceGroup = _interopRequireDefault(require("./PriceGroup"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_index',
  components: {
    PriceGroup: _PriceGroup.default,
    PriceWithCheckBox: _PriceWithCheckBox.default,
    CardContent: _CardContent.default,
    MyRadioGroup: _index.default,
    ProductSpecificationEditing: _index3.default,
    ProductSelectDialog: _index2.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    singleLocked: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      checkData: [{
        label: true,
        title: '是'
      }, {
        label: false,
        title: '否'
      }],
      unlimitedPurchases: true,
      outerVisible: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    stIsSingleSpecification: function stIsSingleSpecification() {
      return this.valueProxy.specificType === 0;
    },
    // 规格类型：是否是单规格
    stIsMultipleSpecifications: function stIsMultipleSpecifications() {
      return !this.stIsSingleSpecification;
    },
    // 规格类型：是否是多规格
    orginalPrice: {
      get: function get() {
        return (0, _priceParser.priceToYuan)(this.valueProxy.orginalPrice);
      },
      set: function set(value) {
        this.$set(this.valueProxy, 'orginalPrice', (0, _priceParser.parsePriceFromYuan)(value));
      }
    },
    gifts: {
      get: function get() {
        if (!_main.default.isString(this.valueProxy.gifts)) {
          return [];
        }

        if (_main.default.isEmpty(this.valueProxy.gifts)) {
          return [];
        }

        return this.valueProxy.gifts.split(',');
      },
      set: function set(value) {
        this.$set(this.valueProxy, 'gifts', value.join(','));
      }
    },
    canAddGift: function canAddGift() {
      return this.gifts.length < 6;
    },
    canEditSpecific: function canEditSpecific() {
      return !this.valueProxy.isSingle;
    },
    hasEditSpecific: function hasEditSpecific() {
      return this.valueProxy.specificRequestList.length > 0;
    },
    showIsSingle: function showIsSingle() {
      return false;
    },
    // 是否显示教辅单品
    showSpecificJson: function showSpecificJson() {
      // 是否显示商品规格
      if (this.valueProxy.resourceType === 20) {
        if (this.stIsSingleSpecification) {
          return false;
        }
      } else {
        return false;
      }

      return true;
    },
    showInventory: function showInventory() {
      // 是否显示库存数量
      if (this.valueProxy.resourceType === 20) {
        if (this.stIsMultipleSpecifications) {
          return false;
        }
      } else {
        return false;
      }

      return true;
    },
    showOrginalPrice: function showOrginalPrice() {
      // 是否显示原价
      if (this.valueProxy.resourceType === 20) {
        if (this.stIsMultipleSpecifications) {
          return false;
        }
      } else {
        return false;
      }

      return true;
    },
    showLowPrice: function showLowPrice() {
      // 是否显示最低售价
      if (this.valueProxy.resourceType === 20) {
        if (this.stIsMultipleSpecifications) {
          return false;
        }
      }

      return true;
    },
    showPrice: function showPrice() {
      // 是否显示售价
      return false;
    },
    showOnlyDiscountsLowPrice: function showOnlyDiscountsLowPrice() {
      // 最低售价是否仅显示折扣
      return this.valueProxy.resourceType !== 20;
    },
    showOnlyDiscountsPrice: function showOnlyDiscountsPrice() {
      // 售价是否仅显示折扣
      return this.valueProxy.resourceType !== 20;
    },
    showGifts: function showGifts() {
      return false;
    } // 是否显示赠品

  },
  watch: {
    'valueProxy.quota': function valueProxyQuota() {
      this.unlimitedPurchases = !this.valueProxy.quota;
    }
  },
  methods: {
    addgiftevent: function addgiftevent() {
      for (var _len = arguments.length, p = new Array(_len), _key = 0; _key < _len; _key++) {
        p[_key] = arguments[_key];
      }

      console.log(p);
    }
  }
};
exports.default = _default2;