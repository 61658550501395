"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _common = require("../../../utils/common");

var _resource = require("../../../utils/resource");

var _oss = require("../../../utils/oss");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogTableContent',
  props: {
    resourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
      default: false
    },
    // 刷新数据
    multipleCheck: {
      type: Boolean,
      default: false
    },
    // 多选
    multipleVal: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      showVal: _common.showVal,
      getResourceInfo: _resource.getResourceInfo,
      translationResourceType: _resource.translationResourceType,
      defaultCover: 'https://open.oss.taozhi.online/production/assets/mp/resource/select-resource/book_cover.png?x-oss-process=style%2Fwidth-limit-128',
      resourceOpenId: undefined
    };
  },
  watch: {
    refresh: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.handleInitData();
        }
      }
    }
  },
  methods: {
    /** 此函数用于回显
     * 在次打开弹窗refresh刷新组件用
     * 监听refresh时 尚未获取到resourceList 需要在作品调用接口时在调
     */
    handleInitData: function handleInitData() {
      var _this = this;

      if (this.$refs.singleTable) {
        this.$refs.singleTable.setCurrentRow();
      }

      this.$emit('refreshResource', false);

      if (this.multipleCheck) {
        if (!this.$refs.singleTable) {
          return;
        }

        this.$refs.singleTable.clearSelection();
      }

      this.resourceOpenId = this.value;

      if (this.value && this.resourceList.length > 0) {
        this.resourceList.forEach(function (item) {
          if (_this.value === item.resourceOpenId) {
            _this.$refs.singleTable.setCurrentRow(item);
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      if (!this.multipleCheck) {
        return;
      }

      this.$emit('handleResourceContent', val);
    },
    // 为了再次单击取消
    clickRadio: function clickRadio(e) {
      if (this.resourceOpenId !== e.resourceOpenId) {
        this.resourceOpenId = e.resourceOpenId;
        this.$emit('handleResourceContent', e);
      } else {
        this.$emit('handleResourceContent', {
          resourceOpenId: ''
        });
        this.resourceOpenId = undefined;
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      if (this.multipleCheck) {
        return;
      }

      this.$emit('handleResourceContent', val);
    },
    getResourceCoverClass: function getResourceCoverClass(type) {
      if (parseInt(type, 10) === 3) {
        return 'book-cover';
      }

      return [2, 8, 10].includes(type) ? 'audio-cover' : 'resource-cover';
    },
    getResourceCover: function getResourceCover() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cover = _ref.cover,
          resourceType = _ref.resourceType;

      // 获取封面图片
      if ([5, 6].includes(resourceType)) {
        var name = resourceType === 5 ? 'jinxinbiCover' : 'vipCover';
        return "https://open.oss.taozhi.online/develop/mp/orderCover/".concat(name, ".png?x-oss-process=style%2Fthumbnail");
      }

      return (0, _oss.ossUrl)(cover, this.defaultCover);
    },
    // 显示标题
    displayTitle: function displayTitle(data) {
      if (data.resourceTitle && data.resourceTitle.trim().length > 0) {
        return data.resourceTitle;
      }

      return data.title;
    },
    selectable: function selectable(_ref2) {
      var itemId = _ref2.resourceOpenId;
      var except = this.excludeData.find(function (_ref3) {
        var resourceOpenId = _ref3.resourceOpenId;
        return resourceOpenId === itemId;
      });
      return !except;
    },
    tableRowClassName: function tableRowClassName(_ref4) {
      var row = _ref4.row;
      return this.selectable(row) ? '' : 'disabled-row';
    }
  }
};
exports.default = _default2;