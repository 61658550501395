var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content box" },
    [
      _c("publictop", {
        attrs: {
          value: _vm.form,
          "partner-open-id-echo": _vm.merchantId,
          typeName: _vm.routname,
          "show-json-data": true,
          "show-test-data": true
        },
        on: {
          "new-add": _vm.newaddvideo,
          searchbtnevent: _vm.searchBtnFun,
          "merchant-id": _vm.merchantswitch
        }
      }),
      _vm._v(" "),
      _c(
        "multipane",
        {
          staticClass: "video-content vertical-panes",
          attrs: { layout: "vertical" },
          on: { paneResize: _vm.changeMenuSize }
        },
        [
          _c(
            "div",
            {
              staticClass: "pane-menu",
              style: { minWidth: "130px", width: "240px" }
            },
            [
              _c(
                "div",
                { staticClass: "content-ulli" },
                [
                  _c("Labelpage", {
                    attrs: {
                      state: _vm.state,
                      isWidthChange: _vm.isChangeWidth,
                      state_current: _vm.state_current.toString()
                    },
                    on: { "state-currents": _vm.stateCurrents }
                  }),
                  _vm._v(" "),
                  _c("videoClass", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.merchantId,
                        expression: "merchantId"
                      }
                    ],
                    ref: "videoclass",
                    attrs: { routname: _vm.routname, type: _vm.resourceType },
                    on: { "check-event": _vm.changeClass }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("multipane-resizer", {
            staticClass: "resize-split-bg iconfont icon-tuozhuaidaxiao"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pane", style: { flexGrow: 1 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableloading,
                    expression: "tableloading"
                  }
                ],
                staticClass: "box-card"
              },
              [
                _c("table-list", {
                  ref: "tableList",
                  staticClass: "video-table-box",
                  attrs: {
                    list: _vm.videoList,
                    "copy-btn-disabled": _vm.copyBtnDisabled,
                    "resource-type-id": _vm.resourceType,
                    "status-val-name": "state",
                    handleDel: _vm.delVideo,
                    "list-query": _vm.page,
                    unPublish: _vm.batchUnpublish,
                    objectRestore: _vm.handleVideoRestore,
                    handleCancelHide: _vm.handleCancelVideoHide,
                    handleHide: _vm.handleVideoHide,
                    passObj: _vm.passObj,
                    refuseObj: _vm.refuseObj,
                    handleHaltSale: _vm.handleHaltSale,
                    handleSale: _vm.handleSale,
                    handleGetCover: _vm.updateVideoCover,
                    saveResourceEncode: _vm.updateVideoEncode,
                    setRecommend: _vm.setRecommend,
                    cancelRecommend: _vm.cancelRecommend
                  },
                  on: {
                    showPage: _vm.newaddvideo,
                    addObject: function($event) {
                      return _vm.newaddvideo()
                    },
                    handleCopy: _vm.handleVideoCopy,
                    selectObjs: _vm.selectObjs
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(video) {
                        return _c("div", { staticClass: "table-img" }, [
                          video.cover
                            ? _c("img", {
                                staticClass: "object-cover-img",
                                attrs: {
                                  onerror: _vm.errorUserPhoto,
                                  src: _vm.getImageUrl(video.cover),
                                  alt: ""
                                }
                              })
                            : _c("img", {
                                staticClass: "object-cover-img",
                                attrs: {
                                  src: require("../../../assets/book_cover.png"),
                                  alt: ""
                                }
                              })
                        ])
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-card-bottom display-flex" },
                  [
                    _c("review-btn", {
                      ref: "reviewBtn",
                      attrs: {
                        typename: _vm.typename,
                        merchantId: _vm.merchantId,
                        status: _vm.state_current.toString(),
                        "handle-batch-stop-sale": _vm.batchStopSale,
                        reviewFunction: _vm.videoReview,
                        copyFunction: _vm.handleVideoBatchCopy
                      },
                      on: {
                        batchUnpublish: _vm.batchUnpublish,
                        batchRefuse: function($event) {
                          return _vm.handlebatchRefuse(arguments)
                        },
                        batchSetAttribute: function($event) {
                          return _vm.batchSetAttributes(arguments)
                        },
                        batchRecovery: _vm.handleBatchRecovery,
                        batchExport: _vm.batchExportExcel,
                        batchUpdateCover: _vm.batchUpdateVideoCover,
                        batchSaveDraft: _vm.batchSaveVideoDraft,
                        batchSaveRecycle: _vm.batchSaveVideoRecycle,
                        batchHiddenOrStopSale: _vm.batchHiddenOrStopSale
                      }
                    }),
                    _vm._v(" "),
                    _vm.page.total > 0
                      ? _c("el-pagination", {
                          attrs: {
                            total: _vm.page.total,
                            "pager-count": 5,
                            "current-page": _vm.page.page,
                            "page-size": _vm.page.size,
                            "page-sizes": _vm.pageSizes,
                            layout: "total, sizes, prev, pager, next, jumper",
                            background: ""
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.currentchange
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "驳回申请",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": function() {
              _vm.dialogVisible = false
            }
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因：" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.reason,
                      callback: function($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.rejectBooks } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }