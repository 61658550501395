var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-container" },
    [
      _c("div", { staticClass: "header-bg" }, [
        _c(
          "div",
          { staticClass: "header-bg-l" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addBtn }
              },
              [_vm._v("新建")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header-bg-r" }, [
          _c(
            "div",
            { staticClass: "cash-search-bg" },
            [
              _c("el-input", {
                staticClass: "search-txt",
                attrs: { placeholder: "模板名称搜索", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchContent($event)
                  }
                },
                model: {
                  value: _vm.query.name,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "name", $$v)
                  },
                  expression: "query.name"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.searchContent }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "link-table-body"
        },
        [
          _c(
            "el-table",
            {
              ref: "schoolList",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "模板名称", "min-width": "8" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "code", label: "CODE", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createdAt",
                  label: "创建时间",
                  "min-width": "12"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("parseTime")(
                                scope.row.createdAt,
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注", "min-width": "15" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "140", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.showPreview(scope.row)
                                  }
                                }
                              },
                              [_vm._v("预览")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getTemplateList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "template-dialog",
          attrs: {
            title: _vm.dialogTitleText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "942"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "template-bg" }, [
            _c(
              "div",
              { staticClass: "template-form-bg" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "80px",
                      model: _vm.templateForm,
                      "label-position": "top",
                      rules: _vm.rules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "模板名称", prop: "name" } },
                      [
                        _c("el-input", {
                          staticClass: "txt-width-330",
                          attrs: {
                            placeholder: "请输入模板名称",
                            maxlength: "20",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.templateForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.templateForm, "name", $$v)
                            },
                            expression: "templateForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "CODE", prop: "code" } },
                      [
                        _c("el-input", {
                          staticClass: "txt-width-330",
                          attrs: {
                            disabled: _vm.dialogTitleText === "编辑海报模板",
                            placeholder: "请输入 code"
                          },
                          model: {
                            value: _vm.templateForm.code,
                            callback: function($$v) {
                              _vm.$set(_vm.templateForm, "code", $$v)
                            },
                            expression: "templateForm.code"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "代码", prop: "htmlString" } },
                      [
                        _c("el-input", {
                          staticClass: "txt-width-330",
                          attrs: {
                            type: "textarea",
                            rows: 6,
                            placeholder: "请输入代码"
                          },
                          model: {
                            value: _vm.templateForm.htmlString,
                            callback: function($$v) {
                              _vm.$set(_vm.templateForm, "htmlString", $$v)
                            },
                            expression: "templateForm.htmlString"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "example" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label-title-box",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _c("div", { staticClass: "label-title" }, [
                              _vm._v("示例json")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "label-btn" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "btn-run",
                                  on: { click: _vm.runGetView }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-yunhang"
                                  }),
                                  _vm._v("运行")
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "txt-width-330",
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入示例json代码"
                          },
                          model: {
                            value: _vm.templateForm.example,
                            callback: function($$v) {
                              _vm.$set(_vm.templateForm, "example", $$v)
                            },
                            expression: "templateForm.example"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticClass: "txt-width-330",
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入"
                          },
                          model: {
                            value: _vm.templateForm.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.templateForm, "remark", $$v)
                            },
                            expression: "templateForm.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btns-bg" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.editDialogVisible = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.submitSave($event)
                          }
                        }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "template-review-bg" }, [
              _c("div", { staticClass: "review-title" }, [_vm._v("预览")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "review-con" },
                [
                  _vm.viewImage
                    ? _c("el-image", {
                        staticClass: "review-img-bg",
                        attrs: { fit: "contain", src: _vm.viewImage, alt: "" }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "template-dialog",
          attrs: {
            title: "预览海报模板",
            visible: _vm.isShowPreview,
            "close-on-click-modal": false,
            width: "942"
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowPreview = $event
            }
          }
        },
        [
          _c("div", { staticClass: "template-preview-box" }, [
            _vm.previewObj
              ? _c(
                  "div",
                  { staticClass: "template-form-bg" },
                  [
                    _c("div", { staticClass: "review-title" }, [
                      _vm._v("示例json")
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "txt-width-330",
                      attrs: {
                        type: "textarea",
                        rows: 14,
                        disabled: true,
                        placeholder: "无示例json"
                      },
                      model: {
                        value: _vm.previewObj.example,
                        callback: function($$v) {
                          _vm.$set(_vm.previewObj, "example", $$v)
                        },
                        expression: "previewObj.example"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "footer-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              "data-clipboard-text": _vm.previewObj.example,
                              disabled: !_vm.previewObj.example
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.copyExample(
                                  _vm.previewObj.example,
                                  $event
                                )
                              }
                            }
                          },
                          [_vm._v("复制")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "template-review-bg" }, [
              _c("div", { staticClass: "review-title" }, [_vm._v("预览")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "review-con" },
                [
                  _vm.viewImage
                    ? _c("el-image", {
                        staticClass: "view-pic",
                        attrs: { fit: "contain", src: _vm.viewImage, alt: "" }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }