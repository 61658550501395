var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-tag-wrapper" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "group-list-bg",
          model: {
            value: _vm.query.tagType,
            callback: function($$v) {
              _vm.$set(_vm.query, "tagType", $$v)
            },
            expression: "query.tagType"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "1001" } }, [_vm._v("用户")]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "1002" } }, [_vm._v("题库")]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "1003" } }, [_vm._v("作品")]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "1004" } }, [
            _vm._v("知识点")
          ]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "1005" } }, [
            _vm._v("字词库")
          ]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "1006" } }, [
            _vm._v("古诗词")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "user-tag-con" }, [
        _c("div", { staticClass: "user-tag-container" }, [
          _c(
            "div",
            { staticClass: "header-bg" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addBtn }
                },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "search-txt",
                  attrs: { size: "small", placeholder: "标签名称搜索" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchTag($event)
                    }
                  },
                  model: {
                    value: _vm.query.tagName,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "tagName", $$v)
                    },
                    expression: "query.tagName"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "suffix", size: "small", type: "primary" },
                      on: { click: _vm.searchTag },
                      slot: "suffix"
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticClass: "tag-table-body"
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, height: "100%" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "13" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "标签", "min-width": "12" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", { class: ["btn-tag"] }, [
                              _vm._v(_vm._s(scope.row.tagName))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序", "min-width": "8" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                ref: "popover-" + scope.row.id,
                                attrs: {
                                  "popper-class": "preview-popover",
                                  placement: "top-start",
                                  trigger: "click"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "sequence-num-bg" },
                                  [
                                    _c("el-input", {
                                      staticClass: "sequence-num",
                                      model: {
                                        value: scope.row.temporarySequence,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "temporarySequence",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "scope.row.temporarySequence"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "iconfont icon-xuanzhong",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeSequence(
                                            scope.row.id,
                                            scope.row.temporarySequence
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sequence-txt",
                                    attrs: { slot: "reference" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.selectTag(scope.row.id)
                                      }
                                    },
                                    slot: "reference"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.sequence
                                          ? scope.row.sequence
                                          : 0
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "37", align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editBtn(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "removeBtn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeBtn(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("移除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-box-bg" },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalElements,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "current-page": _vm.query.page
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.getUserTags
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "480px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "80px",
                      model: _vm.tagForm,
                      rules: _vm.rules
                    }
                  },
                  [
                    _vm.dialogTitltText === "编辑用户标签"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "open id" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.tagForm.id,
                                callback: function($$v) {
                                  _vm.$set(_vm.tagForm, "id", $$v)
                                },
                                expression: "tagForm.id"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "标签名称", prop: "tagName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 90,
                            placeholder: "请输入标签名称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.tagForm.tagName,
                            callback: function($$v) {
                              _vm.$set(_vm.tagForm, "tagName", $$v)
                            },
                            expression: "tagForm.tagName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "排序" } }, [
                      _c(
                        "div",
                        { staticClass: "sequence-box" },
                        [
                          _c("el-input-number", {
                            staticClass: "select-sequence",
                            staticStyle: { width: "100px" },
                            attrs: {
                              precision: 0,
                              step: 1,
                              min: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.tagForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.tagForm, "sequence", $$v)
                              },
                              expression: "tagForm.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.editDialogVisible = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitSyncLoading
                                  },
                                  on: { click: _vm.submitSave }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }