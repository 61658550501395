var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-share-wrapper" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "activity-share-dialog",
          attrs: {
            title: "预览",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "lock-scroll": false,
            visible: _vm.visibleProxy,
            width: "722px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleProxy = $event
            },
            opened: _vm.initShareData
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadShareData,
                  expression: "loadShareData"
                }
              ],
              staticClass: "share-box"
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadPage,
                      expression: "loadPage"
                    }
                  ],
                  staticClass: "share-l",
                  attrs: { id: "iframeBox" }
                },
                [
                  _c("iframe", {
                    ref: "iframe",
                    staticClass: "share-iframe",
                    attrs: { id: "iframe", src: _vm.shareObj.resourceUrl }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "share-r" }, [
                _c("div", { staticClass: "share-item-bg" }, [
                  _c("div", { staticClass: "share-item-title" }, [
                    _vm._v("OpenID")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "share-item-con" },
                    [
                      _c("el-input", {
                        staticClass: "input-txt",
                        staticStyle: { width: "250px" },
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.shareObj.luckyDrawInfoOpenId,
                          callback: function($$v) {
                            _vm.$set(_vm.shareObj, "luckyDrawInfoOpenId", $$v)
                          },
                          expression: "shareObj.luckyDrawInfoOpenId"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-code-copy",
                          attrs: {
                            slot: "suffix",
                            type: "primary",
                            size: "medium"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.copy(
                                _vm.shareObj.luckyDrawInfoOpenId,
                                $event
                              )
                            }
                          },
                          slot: "suffix"
                        },
                        [_vm._v("\n              复制\n            ")]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "share-item-bg" }, [
                  _c("div", { staticClass: "share-item-title" }, [
                    _vm._v("预览链接")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "share-item-con" },
                    [
                      _c("el-input", {
                        staticClass: "input-txt",
                        staticStyle: { width: "250px" },
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.shareObj.resourceUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.shareObj, "resourceUrl", $$v)
                          },
                          expression: "shareObj.resourceUrl"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-code-copy",
                          attrs: {
                            slot: "suffix",
                            type: "primary",
                            size: "medium"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.copy(_vm.shareObj.resourceUrl, $event)
                            }
                          },
                          slot: "suffix"
                        },
                        [_vm._v("\n              复制\n            ")]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "share-item-bg" }, [
                  _c("div", { staticClass: "share-item-title" }, [
                    _vm._v("路由")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "share-item-con" },
                    [
                      _c("el-input", {
                        staticClass: "input-txt",
                        staticStyle: { width: "250px" },
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.shareObj.resourceRoute,
                          callback: function($$v) {
                            _vm.$set(_vm.shareObj, "resourceRoute", $$v)
                          },
                          expression: "shareObj.resourceRoute"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-code-copy",
                          attrs: {
                            slot: "suffix",
                            size: "medium",
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.copy(
                                _vm.shareObj.resourceRoute,
                                $event
                              )
                            }
                          },
                          slot: "suffix"
                        },
                        [_vm._v("\n              复制\n            ")]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "share-item-bg" }, [
                  _c("div", { staticClass: "share-item-title" }, [
                    _vm._v("扫描二维码，可在手机中预览")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "share-item-con" }, [
                    _c(
                      "div",
                      { staticClass: "share-code-bg" },
                      [
                        _c("qrcode-vue", {
                          staticClass: "share-code",
                          attrs: {
                            value: _vm.shareObj.resourceUrl,
                            size: 116,
                            level: "H"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }