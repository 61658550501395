var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      attrs: {
        data: [{}],
        "header-cell-class-name": "tz-change-price-table-header-cell",
        border: ""
      }
    },
    [
      _c("el-table-column", {
        attrs: { label: "价格" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("change-price-input", {
                  attrs: {
                    active: _vm.valueProxy.active,
                    "radio-label": 1,
                    enable: _vm.valueProxy.enable
                  },
                  on: {
                    "update:active": function($event) {
                      return _vm.$set(_vm.valueProxy, "active", $event)
                    }
                  },
                  model: {
                    value: _vm.valueProxy.price,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "price", $$v)
                    },
                    expression: "valueProxy.price"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "折扣" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("change-price-input", {
                  attrs: {
                    active: _vm.valueProxy.active,
                    "radio-label": 2,
                    max: 100,
                    label: "%",
                    precision: _vm.discountPrecision,
                    enable: _vm.valueProxy.enable
                  },
                  on: {
                    "update:active": function($event) {
                      return _vm.$set(_vm.valueProxy, "active", $event)
                    }
                  },
                  model: {
                    value: _vm.valueProxy.discount,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "discount", $$v)
                    },
                    expression: "valueProxy.discount"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "减价" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("change-price-input", {
                  attrs: {
                    active: _vm.valueProxy.active,
                    "radio-label": 3,
                    enable: _vm.valueProxy.enable
                  },
                  on: {
                    "update:active": function($event) {
                      return _vm.$set(_vm.valueProxy, "active", $event)
                    }
                  },
                  model: {
                    value: _vm.valueProxy.priceReduction,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "priceReduction", $$v)
                    },
                    expression: "valueProxy.priceReduction"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }