var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chinese-list" },
    [
      _c(
        "div",
        { staticClass: "content-card" },
        [
          _c("div", { staticClass: "chinese-header" }, [
            _c(
              "div",
              { staticClass: "chinese-button-group" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "create-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleCreate }
                  },
                  [_vm._v("添加")]
                ),
                _vm._v(" "),
                _c("el-button", {
                  staticClass: "refresh-btn",
                  attrs: { plain: "", icon: "el-icon-refresh-right" },
                  on: { click: _vm.getList }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "interval" }),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticStyle: { "margin-left": "12px", width: "100px" },
                    model: {
                      value: _vm.query.vocabularyNatureCode,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "vocabularyNatureCode", $$v)
                      },
                      expression: "query.vocabularyNatureCode"
                    }
                  },
                  _vm._l(_vm.natureList, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.name, value: item.natureCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chinese-search" },
              [
                _c("chinese-search", {
                  attrs: {
                    query: _vm.query,
                    type: "vocabulary",
                    placeholder: "搜索词语"
                  },
                  on: { getList: _vm.getList }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "word-table",
              staticStyle: { position: "relative" }
            },
            [
              _c(
                "div",
                { staticClass: "chinese-index" },
                _vm._l(_vm.indexList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: _vm.query.firstLetter == item ? "active" : "",
                      on: {
                        click: function($event) {
                          return _vm.conditionQuery(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item ? item : "*"))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.list.length > 0
                ? _c(
                    "div",
                    { staticClass: "content-box" },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "el-tooltip",
                        {
                          key: index,
                          staticClass: "word",
                          style: _vm.checkVoice(item) ? "" : "color:#E6A23C",
                          attrs: { enterable: false, placement: "right-start" }
                        },
                        [
                          item.id
                            ? _c(
                                "div",
                                {
                                  staticClass: "tooltip",
                                  attrs: { slot: "content" },
                                  slot: "content"
                                },
                                [
                                  _c("div", [
                                    _vm._v("索引：" + _vm._s(item.id))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "配音：" +
                                        _vm._s(
                                          _vm.checkVoice(item) ? "已配" : "未配"
                                        )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "description" }, [
                                    _vm._v(
                                      "备注：" +
                                        _vm._s(
                                          item.description
                                            ? item.description
                                            : "无"
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.getWordDetail(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "not-data" }, [
                    _vm._v("\n        暂无数据\n      ")
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                title: !_vm.wordParam.id ? "添加词语" : "编辑词语",
                visible: _vm.addDialogShow,
                "close-on-click-modal": false,
                width: "1000px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.addDialogShow = $event
                },
                close: _vm.closeDialog
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "bannerDialogBox",
                  on: {
                    click: function($event) {
                      _vm.pinyinShowType = false
                    }
                  }
                },
                [
                  _c(
                    "el-container",
                    [
                      _c(
                        "el-container",
                        { staticStyle: { width: "40%" } },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "tabs",
                              attrs: { type: "card" },
                              model: {
                                value: _vm.basic,
                                callback: function($$v) {
                                  _vm.basic = $$v
                                },
                                expression: "basic"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "基本信息", name: "basic" } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "form",
                                      staticClass: "add-dialog dialogBox",
                                      style:
                                        "max-height: " +
                                        (_vm.screeHeight - 350) +
                                        "px;"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "add-title",
                                          staticStyle: {
                                            "margin-bottom": "10px"
                                          },
                                          attrs: { prop: "name" }
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "required" },
                                            [_vm._v("词语")]
                                          ),
                                          _vm._v(" "),
                                          !_vm.wordParam.id
                                            ? _c("el-input", {
                                                staticClass: "add-input",
                                                attrs: {
                                                  placeholder: "请填写词语",
                                                  size: "small"
                                                },
                                                on: {
                                                  blur: _vm.checkVocabulary
                                                },
                                                model: {
                                                  value: _vm.wordParam.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "wordParam.name"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.wordParam.id
                                            ? _c("el-input", {
                                                staticClass: "add-input",
                                                attrs: {
                                                  disabled: "disabled",
                                                  placeholder: "请填写词语",
                                                  size: "small"
                                                },
                                                model: {
                                                  value: _vm.wordParam.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "wordParam.name"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "add-title" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "not-required" },
                                            [_vm._v("成语")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "75%" },
                                              attrs: {
                                                "value-key": "formatterList",
                                                disabled: !_vm.wordParam
                                                  .ifIdiom,
                                                placeholder: "请选择成语格式",
                                                size: "small"
                                              },
                                              model: {
                                                value: _vm.wordParam.formatter,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.wordParam,
                                                    "formatter",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "wordParam.formatter"
                                              }
                                            },
                                            _vm._l(_vm.formatterList, function(
                                              item,
                                              index
                                            ) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.formatterCode
                                                }
                                              })
                                            }),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            staticStyle: {
                                              "margin-left": "4%"
                                            },
                                            attrs: {
                                              "active-color": "#13ce66",
                                              "inactive-color": "#ededef"
                                            },
                                            on: { change: _vm.idIomHandle },
                                            model: {
                                              value: _vm.wordParam.ifIdiom,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.wordParam,
                                                  "ifIdiom",
                                                  $$v
                                                )
                                              },
                                              expression: "wordParam.ifIdiom"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("dragSort", {
                                        attrs: {
                                          title: "同义",
                                          placeholder:
                                            "请选择同义词，可拖拽排序",
                                          searchPlaceholder: "搜索词语",
                                          dragData: _vm.wordParam.synonymList,
                                          type: "vocabulary",
                                          screeHeight: _vm.screeHeight,
                                          handle: "选择词语"
                                        },
                                        on: {
                                          changeDrag: function($event) {
                                            return _vm.changeDrag(
                                              $event,
                                              "synonymList"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("dragSort", {
                                        attrs: {
                                          title: "近义",
                                          placeholder:
                                            "请选择近义词，可拖拽排序",
                                          searchPlaceholder: "搜索词语",
                                          dragData: _vm.wordParam.nearList,
                                          type: "vocabulary",
                                          screeHeight: _vm.screeHeight,
                                          handle: "选择词语"
                                        },
                                        on: {
                                          changeDrag: function($event) {
                                            return _vm.changeDrag(
                                              $event,
                                              "nearList"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("dragSort", {
                                        attrs: {
                                          title: "反义",
                                          placeholder:
                                            "请选择反义词，可拖拽排序",
                                          searchPlaceholder: "搜索词语",
                                          dragData: _vm.wordParam.antonymList,
                                          type: "vocabulary",
                                          screeHeight: _vm.screeHeight,
                                          handle: "选择词语"
                                        },
                                        on: {
                                          changeDrag: function($event) {
                                            return _vm.changeDrag(
                                              $event,
                                              "antonymList"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "add-title" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "not-required" },
                                            [_vm._v("备注")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticClass: "add-input",
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                              placeholder: "请填写备注"
                                            },
                                            model: {
                                              value: _vm.wordParam.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.wordParam,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "wordParam.description"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-container",
                        {
                          staticStyle: { width: "60%", "margin-left": "10px" }
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "tabs-right",
                              staticStyle: { width: "100%" },
                              attrs: { type: "card" },
                              model: {
                                value: _vm.rightInfo,
                                callback: function($$v) {
                                  _vm.rightInfo = $$v
                                },
                                expression: "rightInfo"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "拼音信息", name: "spell" } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "form",
                                      staticClass: "add-dialog",
                                      staticStyle: {
                                        padding: "5px 0px",
                                        "min-height": "50px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-tabs",
                                        {
                                          ref: "pinyin",
                                          staticStyle: { padding: "0 15px" },
                                          attrs: {
                                            closable:
                                              _vm.wordParam.pinyinList.length >
                                              1,
                                            addable: ""
                                          },
                                          on: { edit: _vm.handleTabsEdit },
                                          model: {
                                            value: _vm.editableTabsValue,
                                            callback: function($$v) {
                                              _vm.editableTabsValue = $$v
                                            },
                                            expression: "editableTabsValue"
                                          }
                                        },
                                        _vm._l(
                                          _vm.wordParam.pinyinList,
                                          function(item, index) {
                                            return _c(
                                              "el-tab-pane",
                                              {
                                                key: item.name,
                                                attrs: {
                                                  label: item.title,
                                                  name: item.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "dialogBox",
                                                    staticStyle: {
                                                      padding: "0 15px"
                                                    },
                                                    style:
                                                      "max-height: " +
                                                      (_vm.screeHeight - 350) +
                                                      "px;"
                                                  },
                                                  [
                                                    _c("repeat-cascader", {
                                                      attrs: {
                                                        title: "拼音",
                                                        showType:
                                                          _vm.pinyinShowType,
                                                        dragData:
                                                          item.syllableCodes,
                                                        list: _vm.syllableList,
                                                        placeholder:
                                                          "请选择拼音"
                                                      },
                                                      on: {
                                                        changeShowType:
                                                          _vm.changeShowType
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "add-title",
                                                        staticStyle: {
                                                          position: "relative"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "not-required",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          [_vm._v("儿化音")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("el-switch", {
                                                          staticStyle: {
                                                            "margin-left": "1em"
                                                          },
                                                          attrs: {
                                                            "active-color":
                                                              "#13ce66",
                                                            "inactive-color":
                                                              "#ededef"
                                                          },
                                                          model: {
                                                            value: item.ifErHua,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "ifErHua",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.ifErHua"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "add-title",
                                                        staticStyle: {
                                                          position: "relative"
                                                        }
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("词性")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "88%"
                                                            },
                                                            attrs: {
                                                              "value-key":
                                                                "vocabularyNatureList",
                                                              size: "medium",
                                                              placeholder:
                                                                "请选择词性",
                                                              multiple: ""
                                                            },
                                                            model: {
                                                              value:
                                                                item.vocabularyNatureCodes,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "vocabularyNatureCodes",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.vocabularyNatureCodes"
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.natureList,
                                                            function(
                                                              item,
                                                              index
                                                            ) {
                                                              return item.natureCode
                                                                ? _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.natureCode
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            staticClass:
                                                              "item-tooltip",
                                                            attrs: {
                                                              effect: "dark",
                                                              content: "",
                                                              placement: "top"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon el-icon-info"
                                                            })
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("rich_text", {
                                                      attrs: {
                                                        title: "释义",
                                                        stateType: true,
                                                        param:
                                                          item.interpretation,
                                                        index: index,
                                                        type: "interpretation",
                                                        placeholder:
                                                          "请填写释义"
                                                      },
                                                      on: {
                                                        changeRich: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeRich(
                                                            arguments
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("rich_text", {
                                                      attrs: {
                                                        title: "例句",
                                                        stateType: true,
                                                        param:
                                                          item.exampleSentence,
                                                        index: index,
                                                        type: "exampleSentence",
                                                        placeholder:
                                                          "请填写例句"
                                                      },
                                                      on: {
                                                        changeRich: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeRich(
                                                            arguments
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "add-title",
                                                        staticStyle: {
                                                          "align-items":
                                                            "flex-start",
                                                          "margin-right": "4px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "title",
                                                            staticStyle: {
                                                              width: "40px",
                                                              "margin-right":
                                                                "0"
                                                            }
                                                          },
                                                          [_vm._v("朗读")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("upload-audio", {
                                                          ref:
                                                            "uploadAudioControl",
                                                          refInFor: true,
                                                          staticClass:
                                                            "upload-box read-aloud ",
                                                          attrs: {
                                                            type: "audio",
                                                            cateId: _vm.cateId,
                                                            htmlType:
                                                              "wordLibrary",
                                                            "audio-id": item.readAloud
                                                              ? item.readAloud
                                                              : "",
                                                            clearType:
                                                              item.readAloud
                                                          },
                                                          on: {
                                                            getResult: function(
                                                              $event
                                                            ) {
                                                              return _vm.getAudio(
                                                                $event,
                                                                "readAloud",
                                                                index
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            staticClass:
                                                              "item-tooltip",
                                                            staticStyle: {
                                                              right: "-12px"
                                                            },
                                                            attrs: {
                                                              effect: "dark",
                                                              content: "",
                                                              placement: "top"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon el-icon-info"
                                                            })
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "add-title default",
                                                        staticStyle: {
                                                          position: "relative"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "not-required",
                                                            staticStyle: {
                                                              width: "5em"
                                                            }
                                                          },
                                                          [_vm._v("设为默认")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-color":
                                                              "#13ce66",
                                                            "inactive-color":
                                                              "#ededef"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeDefault(
                                                                $event,
                                                                index
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              item.ifDefault,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "ifDefault",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.ifDefault"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "扩展信息", name: "basic" } },
                                [
                                  _c(
                                    "el-form",
                                    { ref: "form", staticClass: "add-dialog" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "add-title form-audio-box"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                width: "62px",
                                                "font-size": "15px"
                                              }
                                            },
                                            [_vm._v("相关视频")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "2.8em"
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                plain: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  _vm.outerVisible = !_vm.outerVisible
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item-tooltip",
                                              attrs: {
                                                effect: "dark",
                                                content: "",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon el-icon-info"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-border video-border"
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              staticClass: "menu-list",
                                              attrs: {
                                                list: _vm.videoList,
                                                move: _vm.move,
                                                disabled: false
                                              },
                                              on: {
                                                start: function($event) {
                                                  return _vm.start()
                                                },
                                                end: _vm.end
                                              }
                                            },
                                            _vm._l(_vm.videoList, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "video-box"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex"
                                                      }
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        attrs: {
                                                          src: require("../../assets/images/md-ondemand_video.svg")
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "video-button",
                                                      staticStyle: {
                                                        display: "flex"
                                                      }
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass:
                                                          "video-check",
                                                        attrs: {
                                                          src: require("../../assets/images/md-find_in_page.svg")
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.previewVideo(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-image", {
                                                        staticClass:
                                                          "video-check",
                                                        attrs: {
                                                          src: require("../../assets/images/md-swap_horiz.svg")
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.replaceVideo(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-image", {
                                                        staticClass:
                                                          "video-check",
                                                        attrs: {
                                                          src: require("../../assets/images/md-close.svg")
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.handleRemove(
                                                              item.resourceOpenId
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-image", {
                                                        staticClass:
                                                          "video-not-check",
                                                        attrs: {
                                                          src: require("../../assets/images/md-check_circle.svg")
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "add-title form-audio-box"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                width: "90px",
                                                "font-size": "15px"
                                              }
                                            },
                                            [_vm._v("字形说写音频")]
                                          ),
                                          _vm._v(" "),
                                          _c("upload-audio", {
                                            ref: "uploadAudioControl",
                                            staticClass: "upload-box",
                                            staticStyle: {
                                              "padding-right": "10px",
                                              "padding-top": "3px"
                                            },
                                            attrs: {
                                              type: "audio",
                                              cateId: _vm.cateId,
                                              htmlType: "wordLibrary",
                                              "audio-id": _vm.wordParam
                                                .glyphSpeakWrite
                                                ? _vm.wordParam.glyphSpeakWrite
                                                : "",
                                              clearType:
                                                _vm.wordParam.glyphSpeakWrite
                                            },
                                            on: {
                                              getResult: function($event) {
                                                return _vm.getAudio(
                                                  $event,
                                                  "glyphSpeakWrite"
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item-tooltip",
                                              attrs: {
                                                effect: "dark",
                                                content: "",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon el-icon-info",
                                                staticStyle: { right: "-7px" }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("rich_text", {
                                        attrs: {
                                          title: "轶事",
                                          stateType: true,
                                          param: _vm.wordParam.anecdote,
                                          type: "anecdote",
                                          "content-max-height": 220,
                                          placeholder: "请输入轶事"
                                        },
                                        on: {
                                          changeRich: function($event) {
                                            return _vm.changeRich(arguments)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "add-title wider",
                                          staticStyle: {
                                            "align-items": "flex-start"
                                          }
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "title" },
                                            [_vm._v("词语图集")]
                                          ),
                                          _vm._v(" "),
                                          _c("vocabulary-album", {
                                            model: {
                                              value: _vm.wordParam.pictures,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.wordParam,
                                                  "pictures",
                                                  $$v
                                                )
                                              },
                                              expression: "wordParam.pictures"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("排序")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          attrs: { "controls-position": "right" },
                          model: {
                            value: _vm.wordParam.sequence,
                            callback: function($$v) {
                              _vm.$set(_vm.wordParam, "sequence", $$v)
                            },
                            expression: "wordParam.sequence"
                          }
                        }),
                        _vm._v(" "),
                        _vm.wordParam.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", plain: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteWord($event)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("el-button", { on: { click: _vm.cancel } }, [
                          _vm._v("取消")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "" },
                            on: { click: _vm.adhibition }
                          },
                          [_vm._v("应用")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.commitWord($event)
                              }
                            }
                          },
                          [_vm._v("全部保存")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "chinese-footer" },
            [
              _c("el-pagination", {
                staticClass: "footer-page",
                attrs: {
                  background: "",
                  "current-page": _vm.query.page,
                  "page-sizes": [100, 150, 200, 250],
                  "page-size": _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              expectType: "wordContent",
              visible: _vm.outerVisible,
              "expect-type": "commodity",
              "exclude-data": _vm.videoList,
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.onResourceSelected
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.replaceVisible
        ? _c("select-resource-dialog", {
            attrs: {
              replaceIndex: _vm.videoIndex,
              expectType: "wordContent",
              visible: _vm.replaceVisible,
              "expect-type": "commodity",
              "exclude-data": _vm.replaceVideoList,
              dialogType: "replace",
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.replaceVisible = $event
              },
              "add-select": _vm.onReplayVideo
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.videoPreview
        ? _c(
            "el-dialog",
            {
              staticClass: "video-preview",
              attrs: {
                visible: _vm.videoPreview,
                "before-close": _vm.videoPreviewClose,
                title: "视频预览"
              },
              on: {
                "update:visible": function($event) {
                  _vm.videoPreview = $event
                }
              }
            },
            [
              _c("div", {
                staticClass: "video-start",
                attrs: { id: "videoPreview" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }