"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    /* 标题 */
    title: {
      type: String,
      default: ''
    },
    dragData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    handle: {
      type: String,
      default: '选择汉字'
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showType: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      deskDialogShow: false,
      replaceMap: {},
      checkList: this.dragData,
      paramList: this.list,
      twoChildren: [],
      threeChildren: [],
      boxShowType: this.showType,
      showaaa: false
    };
  },
  watch: {
    list: function list(newVal) {
      this.paramList = newVal;
    },
    checkList: function checkList(newVal) {
      this.$emit('changeDrag', newVal);
    },
    showType: function showType(newVal) {
      this.boxShowType = newVal;
    },
    boxShowType: function boxShowType(newVal) {
      this.$emit('changeShowType', newVal);
    },
    dragData: function dragData(newVal) {
      if (newVal.length === 0) {
        this.twoChildren = [];
        this.threeChildren = [];
      }

      this.checkList = newVal;
    }
  },
  methods: {
    showBoxShowType: function showBoxShowType() {
      this.twoChildren = [];
      this.boxShowType = !this.boxShowType;
    },
    getImageUrl: function getImageUrl(url) {
      return (0, _common.getImageUrl)(url, 'x-oss-process=style/thumbnail');
    },

    /* 拖拽 START */
    move: function move() {},
    start: function start() {},
    end: function end() {},

    /* 拖拽 END */

    /* 处理移除事件 */
    remove: function remove(i) {
      this.checkList.splice(i, 1);
    },
    openBox: function openBox() {
      this.deskDialogShow = true;
    },

    /* 获取选择的相近字 */
    getSelectWord: function getSelectWord(checkList) {
      this.checkList = checkList;
    },
    getReplace: function getReplace(map) {
      var i = map.index;
      this.checkList[i] = map.name;
    },

    /* 相近字弹窗显示处理 */
    parentEvent: function parentEvent(show) {
      this.deskDialogShow = show;
    },
    handleClick: function handleClick(i, map) {
      var list = this.paramList;
      var idList = [];
      this.checkList.forEach(function (item) {
        idList.push(item.id);
      });
      map.children.forEach(function (item) {
        if (idList.indexOf(item.id) >= 0) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
      this.paramList = list;
      this.twoChildren = map.children;
      this.threeChildren = [];
    },
    handleClickTwo: function handleClickTwo(item, i) {
      this.twoChildren.forEach(function (item, index) {
        if (i === index) {
          item.check = !item.check;
        }
      });

      if (item.check) {
        this.checkList.push(item);
      }

      this.twoChildren.push(1);
      this.twoChildren.pop();
    }
  }
};
exports.default = _default2;