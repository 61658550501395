var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "playout" },
    [
      _vm.vDataInited
        ? _c("edit-product", {
            ref: "videoInfo",
            attrs: {
              loading: _vm.loading,
              "page-type": "EDIT",
              "p-data-inited": _vm.vDataInited
            },
            on: { cancel: _vm.cancel, save: _vm.save },
            model: {
              value: _vm.data,
              callback: function($$v) {
                _vm.data = $$v
              },
              expression: "data"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }