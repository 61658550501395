"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("../../../components/StatisticalTerms/index.vue"));

var _index2 = _interopRequireDefault(require("../../../components/IncomeRatio/index.vue"));

var _merchantIncome = _interopRequireDefault(require("../../../components/Merchantincome/merchantIncome.vue"));

var _index3 = _interopRequireDefault(require("../../../components/IncomeRecords/index.vue"));

var _salesConversionRate = _interopRequireDefault(require("./salesConversionRate.vue"));

var _paymentMethod = _interopRequireDefault(require("./paymentMethod.vue"));

var _productApplication = _interopRequireDefault(require("./productApplication.vue"));

var _sectionRecored = _interopRequireDefault(require("./sectionRecored.vue"));

var _gradeRecored = _interopRequireDefault(require("./gradeRecored.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Analysis',
  components: {
    MerchantIncome: _merchantIncome.default,
    statisticalTerms: _index.default,
    incomeRatio: _index2.default,
    incomeRecords: _index3.default,
    SalesconversionRate: _salesConversionRate.default,
    PaymentMethod: _paymentMethod.default,
    ProductApplication: _productApplication.default,
    sectionRecored: _sectionRecored.default,
    gradeRecored: _gradeRecored.default
  },
  data: function data() {
    var vm = this;
    return {
      timeRange: [],
      // 昨天、今天、过去7天、本周、过去30天、本月、过去3个月、去年、今年；
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var month = end.getMonth();
            var day = end.getDate();
            var start = new Date(fullYear, month, day - 1);
            end = new Date(fullYear, month, day - 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var month = end.getMonth();
            var day = end.getDate();
            var start = new Date(fullYear, month, day);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '过去7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '上周',
          onClick: function onClick(picker) {
            var start = vm.getTimelastWeek(7);
            var end = vm.getTimelastWeek(1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本周',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var month = end.getMonth();
            var day = end.getDate();
            var week = end.getDay();
            var start = new Date(fullYear, month, day - week + 1);
            end = new Date(fullYear, month, day + (7 - week));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '过去30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '上月',
          onClick: function onClick(picker) {
            var start = vm.getMonthStartAndEnd(-1)[0];
            var end = vm.getMonthStartAndEnd(-1)[1];
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本月',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var month = end.getMonth();
            var days = 30;

            if (month + 1 === 1 || month + 1 === 3 || month + 1 === 5 || month + 1 === 7 || month + 1 === 8 || month + 1 === 10 || month + 1 === 12) {
              days = 31;
            }

            var start = new Date(fullYear, month, 1);
            end = new Date(fullYear, month, days);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '过去三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var month = end.getMonth();
            var days = 30;

            if (month + 1 === 1 || month + 1 === 3 || month + 1 === 5 || month + 1 === 7 || month + 1 === 8 || month + 1 === 10 || month + 1 === 12) {
              days = 31;
            }

            var start = new Date(fullYear, month - 2, 1);
            end = new Date(fullYear, month, days);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '去年',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var start = new Date(fullYear - 1, 0, 1);
            end = new Date(fullYear - 1, 11, 31);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今年',
          onClick: function onClick(picker) {
            var end = new Date();
            var fullYear = end.getFullYear();
            var start = new Date(fullYear, 0, 1);
            end = new Date(fullYear, 11, 31);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 是否刷新
      isRefresh: 0
    };
  },
  created: function created() {
    var end = new Date();
    var fullYear = end.getFullYear();
    var month = end.getMonth();
    var start = new Date(fullYear, month, 1);
    this.timeRange = [this.dateFormat(start), this.dateFormat(end)];
    console.log(this.getMonthStartAndEnd(-1));
  },
  methods: {
    // 日期格式过滤
    dateFormat: function dateFormat(timer) {
      return "".concat(timer.getFullYear(), "-").concat(timer.getMonth() + 1, "-").concat(timer.getDate());
    },
    // 刷新当前页面
    refreshAllPage: function refreshAllPage() {
      this.isRefresh += 1;
    },
    getTimelastWeek: function getTimelastWeek(n) {
      var now = new Date();
      var year = now.getFullYear(); //因为月份是从0开始的,所以获取这个月的月份数要加1才行

      var month = now.getMonth() + 1;
      var date = now.getDate();
      var day = now.getDay(); //判断是否为周日,如果不是的话,就让今天的day-1(例如星期二就是2-1)

      if (day !== 0) {
        n = n + (day - 1);
      } else {
        n = n + day;
      }

      if (day) {
        //这个判断是为了解决跨年的问题
        if (month > 1) {
          month = month;
        } //这个判断是为了解决跨年的问题,月份是从0开始的
        else {
          year = year - 1;
          month = 12;
        }
      }

      now.setDate(now.getDate() - n);
      year = now.getFullYear();
      month = now.getMonth() + 1;
      date = now.getDate();
      var s = year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date);
      return s;
    },

    /**
     * 获得相对当月AddMonthCount个月的起止日期
     * AddMonthCount为0 代表当月 为-1代表上一个月 为1代表下一个月 以此类推
     * ***/
    getMonthStartAndEnd: function getMonthStartAndEnd(AddMonthCount) {
      //起止日期数组
      var startStop = new Array(); //获取当前时间

      var currentDate = new Date();
      var month = currentDate.getMonth() + AddMonthCount;

      if (month < 0) {
        var n = parseInt(-month / 12);
        month += n * 12;
        currentDate.setFullYear(currentDate.getFullYear() - n);
      }

      currentDate = new Date(currentDate.setMonth(month)); //获得当前月份0-11

      var currentMonth = currentDate.getMonth(); //获得当前年份4位年

      var currentYear = currentDate.getFullYear(); //获得上一个月的第一天

      var currentMonthFirstDay = new Date(currentYear, currentMonth, 1); //获得上一月的最后一天

      var currentMonthLastDay = new Date(currentYear, currentMonth + 1, 0); //添加至数组

      startStop.push(this.getDateStr3(currentMonthFirstDay));
      startStop.push(this.getDateStr3(currentMonthLastDay)); //返回

      return startStop;
    },
    //获取当前日期yy-mm-dd
    getDateStr3: function getDateStr3(date) {
      var year = "";
      var month = "";
      var day = "";
      var now = date;
      year = "" + now.getFullYear();

      if (now.getMonth() + 1 < 10) {
        month = "0" + (now.getMonth() + 1);
      } else {
        month = "" + (now.getMonth() + 1);
      }

      if (now.getDate() < 10) {
        day = "0" + now.getDate();
      } else {
        day = "" + now.getDate();
      }

      return year + "-" + month + "-" + day;
    }
  }
};
exports.default = _default;