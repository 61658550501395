var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.loadingAll,
          expression: "loadingAll",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "edit-case"
    },
    [
      _c("div", { staticClass: "edit-content" }, [
        _c("div", { staticStyle: { padding: "0 20px" } }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "edit-title" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "80%" } },
              [
                _c("el-input", {
                  staticStyle: { width: "75%" },
                  attrs: {
                    "show-word-limit": "",
                    maxlength: "45",
                    placeholder: "请输入活动名称"
                  },
                  model: {
                    value: _vm.activity_title,
                    callback: function($$v) {
                      _vm.activity_title = $$v
                    },
                    expression: "activity_title"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "normal-case excpt-align" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "goods-editor" },
              [
                _c("editor-view", {
                  ref: "editorview",
                  attrs: { type: "video" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "normal-case" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "picker-options": _vm.pickerOptions,
                    type: "datetimerange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    align: "right",
                    "default-time": ["12:00:00"],
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    format: "yyyy-MM-dd HH:mm:ss"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getlistApiinDate(_vm.time_array)
                    }
                  },
                  model: {
                    value: _vm.time_array,
                    callback: function($$v) {
                      _vm.time_array = $$v
                    },
                    expression: "time_array"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("coverupload", {
                attrs: {
                  coverarr: _vm.coverarr,
                  covertext: _vm.covertextFeng,
                  path: _vm.pathFeng,
                  texttips: _vm.texttipsDown,
                  type: "教师领书封面"
                },
                on: { deletecover: _vm.deletecoverFeng },
                model: {
                  value: _vm.bannerForm.coverFeng,
                  callback: function($$v) {
                    _vm.$set(_vm.bannerForm, "coverFeng", $$v)
                  },
                  expression: "bannerForm.coverFeng"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "normal-case",
              staticStyle: { "margin-bottom": "0", "align-items": "unset" }
            },
            [
              _vm._m(5),
              _vm._v(" "),
              _vm.dynamicValidateForm.length
                ? _c(
                    "div",
                    _vm._l(_vm.dynamicValidateForm, function(domain, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "normal-font active-noBottom",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "margin-bottom": "10px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row"
                              }
                            },
                            [
                              _c("ordinaryuser", {
                                attrs: { userOpenId: domain.adminOpenId },
                                on: {
                                  receiveuserid: function($event) {
                                    return _vm.receiveuserid($event, index)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择地区",
                                        disabled: _vm.chooseAddressDisable
                                      },
                                      model: {
                                        value: domain.address,
                                        callback: function($$v) {
                                          _vm.$set(domain, "address", $$v)
                                        },
                                        expression: "domain.address"
                                      }
                                    },
                                    _vm._l(_vm.options_address, function(item) {
                                      return _c("el-option", {
                                        key: item.adcode,
                                        attrs: {
                                          label: item.name,
                                          value: item.name
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { padding: "0 10px" },
                                  attrs: { type: "text" },
                                  on: { click: _vm.addDomain }
                                },
                                [_vm._v("添加")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  class: index !== 0 ? "" : "hidden",
                                  staticStyle: { color: "#ff4949" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeDomain(
                                        index,
                                        domain.adminOpenId
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm._m(6),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: {
                    change: function($event) {
                      return _vm.handleLimit()
                    }
                  },
                  model: {
                    value: _vm.choose_address,
                    callback: function($$v) {
                      _vm.choose_address = $$v
                    },
                    expression: "choose_address"
                  }
                },
                _vm._l(_vm.options_address, function(item) {
                  return _c("el-option", {
                    key: item.adcode,
                    attrs: { label: item.name, value: item.name }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(8),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.SyncSectionDataid,
                    callback: function($$v) {
                      _vm.SyncSectionDataid = $$v
                    },
                    expression: "SyncSectionDataid"
                  }
                },
                _vm._l(_vm.SyncSectionData, function(item) {
                  return _c("el-option", {
                    key: item.Id,
                    attrs: { label: item.Name, value: item.Id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(9),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.allSyncGradeid,
                    callback: function($$v) {
                      _vm.allSyncGradeid = $$v
                    },
                    expression: "allSyncGradeid"
                  }
                },
                _vm._l(_vm.allSyncGrade, function(item) {
                  return _c("el-option", {
                    key: item.Id,
                    attrs: { label: item.Name, value: item.Id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(10),
              _vm._v(" "),
              _c("el-switch", {
                attrs: { "active-color": "#13ce66" },
                model: {
                  value: _vm.certified,
                  callback: function($$v) {
                    _vm.certified = $$v
                  },
                  expression: "certified"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { margin: "0 10px", color: "#606266" } },
                [_vm._v("需要")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(11),
              _vm._v(" "),
              _c("el-switch", {
                attrs: { "active-color": "#13ce66" },
                model: {
                  value: _vm.mailToSchoolOnly,
                  callback: function($$v) {
                    _vm.mailToSchoolOnly = $$v
                  },
                  expression: "mailToSchoolOnly"
                }
              }),
              _c(
                "span",
                { staticStyle: { margin: "0 10px", color: "#606266" } },
                [_vm._v("仅限")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(12),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(13),
              _vm._v(" "),
              _c("coverupload", {
                attrs: {
                  coverarr: _vm.poster,
                  covertext: _vm.covertext,
                  path: _vm.pathHai,
                  texttips: _vm.texttipsDown,
                  type: "教师领书海报"
                },
                on: { deletecover: _vm.deletecoverHai },
                model: {
                  value: _vm.bannerForm.coverHai,
                  callback: function($$v) {
                    _vm.$set(_vm.bannerForm, "coverHai", $$v)
                  },
                  expression: "bannerForm.coverHai"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "normal-case" },
            [
              _vm._m(14),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  type: "textarea",
                  "show-word-limit": "",
                  maxlength: "200"
                },
                model: {
                  value: _vm.activityDesc,
                  callback: function($$v) {
                    _vm.activityDesc = $$v
                  },
                  expression: "activityDesc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "normal-case",
              staticStyle: { "padding-bottom": "15px" }
            },
            [
              _vm._m(15),
              _vm._v(" "),
              _c("coverupload", {
                attrs: {
                  coverarr: _vm.coverarrThumbnail,
                  covertext: _vm.covertextThumbnail,
                  path: _vm.pathThumbnail,
                  texttips: _vm.texttipsDown,
                  type: "教师领书缩略图"
                },
                on: { deletecover: _vm.deletecoverThumbnail },
                model: {
                  value: _vm.bannerForm.coverThumbnail,
                  callback: function($$v) {
                    _vm.$set(_vm.bannerForm, "coverThumbnail", $$v)
                  },
                  expression: "bannerForm.coverThumbnail"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.preview_flag
          ? _c("div", { staticClass: "edit-ewm" }, [
              _c("h3", [_vm._v("预览二维码")]),
              _vm._v(" "),
              _c("img", {
                staticStyle: {
                  width: "188px",
                  height: "188px",
                  border: "5px solid #ffffff"
                },
                attrs: { src: _vm.qr, alt: "" }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "el-button el-button--primary",
                  staticStyle: {
                    width: "60px",
                    height: "28px",
                    padding: "0px",
                    "margin-top": "20px",
                    "line-height": "28px"
                  },
                  attrs: {
                    href: _vm.qr,
                    download: _vm.activity_title,
                    type: "primary",
                    plain: ""
                  }
                },
                [_vm._v("下载")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "t-block1" }, [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    disabled: _vm.preview_flag
                  },
                  on: { click: _vm.preview }
                },
                [_vm._v("预览")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "t-block",
              staticStyle: { display: "flex", "align-items": "center" }
            },
            [
              _vm.newEdit
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveActivity }
                    },
                    [_vm._v("保存")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.patchActivity }
                    },
                    [_vm._v("保存")]
                  ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("取消")])
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("span", { staticClass: "commonSpan" }, [_vm._v("活动信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "normal-font commonSpan" }, [
      _c(
        "span",
        { staticStyle: { color: "#F56C6C", "padding-right": "5px" } },
        [_vm._v("*")]
      ),
      _c("span", [_vm._v("活动名称")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "normal-font commonSpan" }, [
      _c("span", [_vm._v("活动介绍")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [
        _c(
          "span",
          { staticStyle: { color: "#F56C6C", "padding-right": "5px" } },
          [_vm._v("*")]
        ),
        _vm._v(" "),
        _c("span", [_vm._v("活动起止")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [
        _c(
          "span",
          { staticStyle: { color: "#F56C6C", "padding-right": "5px" } },
          [_vm._v("*")]
        ),
        _c("span", [_vm._v("活动封面")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "normal-font commonSpan",
        staticStyle: {
          display: "flex",
          "margin-bottom": "0",
          "line-height": "40px"
        }
      },
      [_c("span", [_vm._v("负责人")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticStyle: { "margin-top": "0" } }, [
      _c("span", { staticClass: "commonSpan" }, [_vm._v("活动限定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("活动地区")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("学段")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("年级")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("教师证件")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("寄至学校")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticStyle: { "margin-top": "0" } }, [
      _c("span", { staticClass: "commonSpan" }, [_vm._v("分享设置")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("活动海报")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("分享描述")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "normal-font active-noBottom commonSpan" },
      [_c("span", [_vm._v("缩略图")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }