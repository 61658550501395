var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    JSON.stringify(_vm.userInfo) !== "{}"
      ? _c(
          "div",
          [
            _vm.isShowTooltip
              ? _c("el-tooltip", { attrs: { placement: "top" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { "line-height": "1.5" },
                      attrs: { slot: "content" },
                      slot: "content"
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "用户ID：" +
                            _vm._s(_vm.userInfo.openId) +
                            "\n          "
                        ),
                        _c("span", { staticStyle: { cursor: "pointer" } }, [
                          _c("i", {
                            staticClass: "el-icon-copy-document",
                            on: {
                              click: function($event) {
                                return _vm.copyOpenId(
                                  _vm.userInfo.openId,
                                  $event
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "user-info" },
                    [
                      _vm.userInfo && _vm.userInfo.cover
                        ? _c(
                            "el-image",
                            {
                              staticClass: "user-img",
                              style: _vm.newUserImg,
                              attrs: {
                                src: _vm.userInfo.cover,
                                alt: "",
                                fit: "cover"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error"
                                },
                                [
                                  _c("img", {
                                    staticClass: "user-img",
                                    style: _vm.newUserImg,
                                    attrs: {
                                      src: require("@/assets/book_cover.png")
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _c("img", {
                            staticClass: "user-img",
                            style: _vm.newUserImg,
                            attrs: { src: require("@/assets/book_cover.png") }
                          }),
                      _vm._v(" "),
                      _c("span", { staticClass: "user-name" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("beautifulTextFilter")(_vm.userInfo.name)
                          )
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _c(
                  "div",
                  { staticClass: "user-info" },
                  [
                    _vm.userInfo && _vm.userInfo.cover
                      ? _c(
                          "el-image",
                          {
                            staticClass: "user-img",
                            style: _vm.newUserImg,
                            attrs: {
                              src: _vm.userInfo.cover,
                              alt: "",
                              fit: "cover"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("img", {
                                  staticClass: "user-img",
                                  style: _vm.newUserImg,
                                  attrs: {
                                    src: require("@/assets/book_cover.png")
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      : _c("img", {
                          staticClass: "user-img",
                          style: _vm.newUserImg,
                          attrs: { src: require("@/assets/book_cover.png") }
                        }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "user-name",
                        class: _vm.active ? "common-active-color" : ""
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("beautifulTextFilter")(_vm.userInfo.name)
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
          ],
          1
        )
      : _c("div", [_vm._v("--")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }