"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CPM_P_ReasonForRejection_index',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    textarea: {
      type: String,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    textareaProxy: {
      get: function get() {
        return this.textarea;
      },
      set: function set(value) {
        this.$emit('update:textarea', value);
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.valueProxy = false;
      this.textareaProxy = '';
      this.$emit('cancel');
    },
    handleConfirm: function handleConfirm() {
      this.valueProxy = false;
      this.$emit('confirm');
    }
  }
};
exports.default = _default;