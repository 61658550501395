"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accMultiply = accMultiply;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.to-string");

// 精度浮点乘法
function accMultiply(arg1, arg2) {
  // eslint-disable-next-line one-var
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();

  try {
    if (s1.split('.')[1] === undefined) {
      m += 0;
    } else {
      m += s1.split('.')[1].length;
    }
  } catch (e) {
    console.log(e);
  }

  try {
    if (s2.split('.')[1] === undefined) {
      m += 0;
    } else {
      m += s2.split('.')[1].length;
    }
  } catch (e) {
    console.log(e);
  }

  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
} // eslint-disable-next-line import/prefer-default-export