"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createExtData = createExtData;
exports.queryExtDataList = queryExtDataList;
exports.bookModelList = bookModelList;
exports.bookAllModelList = bookAllModelList;
exports.addBookModel = addBookModel;
exports.editBookModel = editBookModel;
exports.delBookModel = delBookModel;
exports.groupManagement = groupManagement;
exports.delGroupManagement = delGroupManagement;
exports.addGroupManagement = addGroupManagement;
exports.editGroupManagement = editGroupManagement;
exports.bookList = bookList;
exports.editBookList = editBookList;
exports.addBookList = addBookList;
exports.delBook = delBook;
exports.getBookAreaList = getBookAreaList;
exports.getAllBookAreaList = getAllBookAreaList;
exports.addBookAreaList = addBookAreaList;
exports.delBookAreaList = delBookAreaList;
exports.getBookSeries = getBookSeries;
exports.addBookSeries = addBookSeries;
exports.editBookSeries = editBookSeries;
exports.delBookSeries = delBookSeries;
exports.getAllBookSeries = getAllBookSeries;
exports.updateBookStatus = updateBookStatus;
exports.copyBook = copyBook;
exports.saveRelation = saveRelation;
exports.bookCatalogue = bookCatalogue;
exports.addBookCatalogue = addBookCatalogue;
exports.delBookCatalogue = delBookCatalogue;
exports.editBookCatalogue = editBookCatalogue;
exports.getBookDetail = getBookDetail;
exports.updateBook = updateBook;
exports.getResourceList = getResourceList;
exports.getBookMenuChildren = getBookMenuChildren;
exports.directoryExtensionFields = directoryExtensionFields;
exports.restorationOfBooks = restorationOfBooks;
exports.isBookAreaUsed = isBookAreaUsed;
exports.downLoadQRCode = downLoadQRCode;
exports.getSystemExtDataList = getSystemExtDataList;
exports.getBookQRcode = getBookQRcode;
exports.getBookQRcodeRtlist = getBookQRcodeRtlist;
exports.getBookClassQRCode = getBookClassQRCode;
exports.getBookBatchQRCode = getBookBatchQRCode;
exports.getResourceQRCode = getResourceQRCode;
exports.getWeChatThirdPartyAuthList = getWeChatThirdPartyAuthList;
exports.getBookWxLink = getBookWxLink;
exports.exactResourceQRCodeDownload = exactResourceQRCodeDownload;
exports.rtQRCodeDownload = rtQRCodeDownload;
exports.classQRCodeDownload = classQRCodeDownload;
exports.getResourcePreviewUrl = getResourcePreviewUrl;
exports.bookMenuOrder = bookMenuOrder;
exports.allExactResourceQRCode = allExactResourceQRCode;
exports.exportBookData = exportBookData;
exports.copyExtDataModel = copyExtDataModel;
exports.downloadBookQRCode = downloadBookQRCode;
exports.publicitySave = publicitySave;
exports.getExtDataMenuResource = getExtDataMenuResource;
exports.replaceTBookResource = replaceTBookResource;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/value-added/admin"));
var weChatRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/weChat/mp/"));
var dictionaryRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/dictionary/admin/"));
/**
 * 保存扩展字段信息【商户端】
 * @param {Object} params
 * @returns {Promise<*>}
 */

function createExtData(id, params) {
  return request.post("/extDataModel/saveExtData", {
    partnerOpenId: null,
    id: id,
    extDataList: params
  });
}
/**
 * 查询图书模型目录扩展信息【商户端】
 * @param {Object} params
 * @returns {Promise<*>}
 */


function queryExtDataList(params) {
  return request.get("/extDataModel/listExtData", {
    params: params
  });
}
/**
 * 分页获取图书模型列表【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function bookModelList(params) {
  return request.get("/extDataModel", {
    params: params
  });
}
/**
 * 查询图书模型列表（不分页）【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function bookAllModelList(params) {
  return request.get("/extDataModel/list", {
    params: params
  });
}
/**
 * 添加图书模型【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function addBookModel(params) {
  return request.post("/extDataModel", params);
}
/**
 * 修改图书模型【商户端】
 * @param {Object}
 * @Code {Number}
 * @returns {Promise<*>}
 */


function editBookModel(code, params) {
  return request.put("/extDataModel/".concat(code), params);
}
/**
 * 删除图书模型【商户端】
 * @Code {Number}
 * @returns {Promise<*>}
 */


function delBookModel(code) {
  return request.delete("/extDataModel/".concat(code));
}
/**
 * 查询分组管理【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function groupManagement(params) {
  return request.get("/extDataGroup", {
    params: params
  });
}
/**
 * 删除分组管理【商户端】
 * @Code {Number}
 * @returns {Promise<*>}
 */


function delGroupManagement(code) {
  return request.delete("/extDataGroup/".concat(code));
}
/**
 * 添加分组管理【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function addGroupManagement(params) {
  return request.post("/extDataGroup", params);
}
/**
 * 编辑分组管理【商户端】
 * @Code {Number}
 * @param {Object}
 * @returns {Promise<*>}
 */


function editGroupManagement(code, params) {
  return request.put("/extDataGroup/".concat(code), params);
}
/**
 * 查询图书列表【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function bookList(params) {
  return request.get("/book/", {
    params: params
  });
}
/**
 * 修改图书【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function updateBook(id, params) {
  return request.put("/book/".concat(id), params);
}
/**
 * 查询图书基本信息【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function getBookDetail(params) {
  return request.get("/book/detail", {
    params: params
  });
}
/**
 * 批量修改图书发布状态【商户端】
 * @status {Number}
 * @returns {Promise<*>}
 */


function updateBookStatus(params) {
  return request.put('/book/status', params);
}
/**
 * 修改图书列表【商户端】
 * @code {Number}
 * @param {Object}
 * @returns {Promise<*>}
 */


function editBookList(code, params) {
  return request.put("/book/".concat(code), params);
}
/**
 * 添加图书列表【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function addBookList(params) {
  return request.post("/book", params);
}
/**
 * 删除图书列表【商户端】
 * @id {Number}
 * @returns {Promise<*>}
 */


function delBook(id, deleted) {
  return request.delete("/book/".concat(id, "/").concat(deleted));
}
/**
 * 查询图书目录的扩展内容及关联资源【商户端】
 * @param {Object}
 * @returns {Promise<*>}
 */


function getResourceList(bookOpenId) {
  return request.get("/book/resource/".concat(bookOpenId));
}

function getSystemExtDataList(params) {
  return request.get('/book/system/extDataList', {
    params: params
  });
}
/**
 * 查询所有图书地区（分页）
 * @Params {Object}
 * @returns {Promise<*>}
 */


function getBookAreaList(params) {
  return dictionaryRequest.get('/bookArea', {
    params: params
  });
}
/**
 * 查询所有图书地区(不分页)
 * @Params {Object}
 * @returns {Promise<*>}
 */


function getAllBookAreaList(params) {
  return dictionaryRequest.get('/bookArea/all', {
    params: params
  });
}
/**
 * 添加图书地区
 * @Params {Object}
 * @returns {Promise<*>}
 */


function addBookAreaList(params) {
  return dictionaryRequest.post('/bookArea', params);
}
/**
 * 删除图书地区
 * @Code {Number}
 * @returns {Promise<*>}
 */


function delBookAreaList(code) {
  return dictionaryRequest.delete("/bookArea/".concat(code));
}
/**
 * 查询图书系列
 * @Params {Object}
 * @returns {Promise<*>}
 */


function getBookSeries(params) {
  return request.get("/bookSeries/", {
    params: params
  });
}
/**
 * 查询全部图书系列
 * @Params {Object}
 * @returns {Promise<*>}
 */


function getAllBookSeries(params) {
  return request.get("/bookSeries/list", {
    params: params
  });
}
/**
 * 新增图书系列
 * @Params {Object}
 * @returns {Promise<*>}
 */


function addBookSeries(params) {
  return request.post("/bookSeries", params);
}
/**
 * 编辑图书系列
 * @Params {Object}
 * @code {Number}
 * @returns {Promise<*>}
 */


function editBookSeries(code, params) {
  return request.put("/bookSeries/".concat(code), params);
}
/**
 * 删除图书系列
 * @code {Number}
 * @returns {Promise<*>}
 */


function delBookSeries(code) {
  return request.delete("/bookSeries/".concat(code));
}
/**
 * 查询图书目录
 * @Params {Object}
 * @returns {Promise<*>}
 */


function bookCatalogue(params) {
  return request.get("/bookMenu", {
    params: params
  });
}
/**
 * 新增图书目录
 * @Params {Object}
 * @returns {Promise<*>}
 */


function addBookCatalogue(params) {
  return request.post("/bookMenu", params);
}
/**
 * 删除图书目录
 * @Code {Number}
 * @returns {Promise<*>}
 */


function delBookCatalogue(code) {
  return request.delete("/bookMenu/".concat(code));
}
/**
 * 修改图书目录
 * @Code {Number}
 * @Params {Object}
 * @returns {Promise<*>}
 */


function editBookCatalogue(code, params) {
  return request.put("/bookMenu/".concat(code), params);
}
/**
 * 复制图书
 * @param bookID
 * @param params
 * @returns {AxiosPromise<any>}
 */


function copyBook(bookID, params) {
  return request.put("book/copy/".concat(bookID), params);
}
/**
 * 查询图书子目录及扩展资源数 【商户端】
 * @param params
 * @returns {AxiosPromise<any>}
 */


function getBookMenuChildren(params) {
  return request.get("/bookMenu/children", {
    params: params
  });
}
/**
 * 保存关联 【商户端】
 * @param Object
 * @Code Number
 * @returns {AxiosPromise<any>}
 */


function saveRelation(code, params) {
  return request.put("/bookMenu/extDataValue/".concat(code), params);
}
/**
 * 查询图书目录扩展字段值 【商户端】
 * @Params Array
 * @returns {AxiosPromise<any>}
 */


function directoryExtensionFields(params) {
  return request.post("/bookMenu/extDataValue", params);
}
/**
 * 恢复图书 【商户端】
 * @id Number
 * @deleted Boolean
 * @returns {AxiosPromise<any>}
 */


function restorationOfBooks(id, deleted) {
  return request.delete("/bookMenu/".concat(id, "/").concat(deleted));
}
/**
 * 教材替换资源并同步增值图书（留码替换）
 * @id Number
 * @deleted Boolean
 * @returns {AxiosPromise<any>}
 */


function replaceTBookResource(data) {
  return request.put('/bookMenu/tBookResource/replace', data);
}
/**
 * 查询图书地区是否被使用 【商户端】
 * @Code Number
 * @returns {AxiosPromise<any>}
 */


function isBookAreaUsed(code) {
  return request.get("/book/bookAreaUsed/".concat(code));
}
/**
 * 下载资源二维码压缩文件
 */


function downLoadQRCode(params) {
  return request.post('/book/batchQRCode/download', params);
}
/**
 * 下载资源二维码压缩文件
 */


function allExactResourceQRCode(params) {
  return request.post('/book/allExactResourceQRCode/download', params);
}
/**
 * 生成图书总码
 * @param bookOpenId
 * @param QRCodeType
 * @returns {AxiosPromise<any>}
 */


function getBookQRcode(bookOpenId, QRCodeType) {
  return request.get("/book/QRCode/index/".concat(bookOpenId, "/").concat(QRCodeType));
}
/**
 * 生成资源分类码
 * @param bookOpenId
 * @returns {AxiosPromise<any>}
 */


function getBookQRcodeRtlist(bookOpenId) {
  return request.get("/book/QRCode/rtlist/".concat(bookOpenId));
}
/**
 * 生成图书课时码
 * @param bookOpenId
 * @returns {AxiosPromise<any>}
 */


function getBookClassQRCode(bookOpenId) {
  return request.get("/book/classQRCode/".concat(bookOpenId));
}
/**
 * 批量生成图书二维码
 * @param bookOpenId
 * @returns {AxiosPromise<any>}
 */


function getBookBatchQRCode(bookOpenId, bookMenuOpenId) {
  return request.get("/book/batchQRCode/".concat(bookOpenId, "/"), {
    params: {
      bookMenuOpenId: bookMenuOpenId
    }
  });
}
/**
 * 生成图书具体资源二维码
 * @param bookOpenId
 * @param bookMenuOpenId
 * @param extDataOpenId
 * @returns {AxiosPromise<any>}
 */


function getResourceQRCode(bookOpenId, bookMenuOpenId, extDataOpenId) {
  return request.get("/book/exactResourceQRCode/".concat(bookOpenId, "/").concat(bookMenuOpenId, "/").concat(extDataOpenId));
}
/**
 * 分页获取微信第三方平台绑定信息
 * @param params
 * @returns {AxiosPromise<any>}
 */


function getWeChatThirdPartyAuthList(params) {
  return weChatRequest.get("/bind/getWeChatThirdPartyAuthList", {
    params: params
  });
}
/**
 * 获取微信二维码
 * @param bookOpenId
 * @param shortLink
 * @returns {AxiosPromise<any>}
 */


function getBookWxLink(bookOpenId, shortLink) {
  return request.get("/book/wxLink/".concat(bookOpenId, "/").concat(shortLink));
}
/**
 * 下载具体资源二维码
 * @param params
 * @returns {AxiosPromise<any>}
 */


function exactResourceQRCodeDownload(params) {
  return request.post("/book/exactResourceQRCode/download", params);
}
/**
 * 下载资源分类码
 * @param params
 * @returns {AxiosPromise<any>}
 */


function rtQRCodeDownload(params) {
  return request.post("/book/rtQRCode/download", params);
}
/**
 * 下载课时码
 * @param params
 * @returns {AxiosPromise<any>}
 */


function classQRCodeDownload(params) {
  return request.post("/book/classQRCode/download", params);
}
/**
 * 获取资源预览地址
 * @param params
 * @returns {AxiosPromise<any>}
 */


function getResourcePreviewUrl(params) {
  return request.get("/book/getResourcePreviewUrl", {
    params: params
  });
}
/**
 * 图书目录排序
 * @param bookMenuList
 * @returns {AxiosPromise<any>}
 */


function bookMenuOrder(bookMenuList) {
  return request.put('bookMenu/order', bookMenuList);
}
/**
 * 导出数据
 * @param params
 */


function exportBookData(params) {
  return request.get('book/exportBookData', {
    params: params
  });
}
/**
 * 复制图书模型
 * @param id
 * @returns {AxiosPromise<any>}
 */


function copyExtDataModel(id) {
  return request.put("extDataModel/copy/".concat(id));
}
/**
 * 批量下载图书总码
 * @returns {AxiosPromise<any>}
 */


function downloadBookQRCode(params) {
  return request.post("/book/bookQRCode/download", params);
}
/**
 * 维护图书系列宣传材料
 * @returns {AxiosPromise<any>}
 */


function publicitySave(params) {
  return request.post("/bookSeries/publicity", params);
}
/**
 * 按资源查询图书目录
 */


function getExtDataMenuResource(params) {
  return request.get("/book/resource", {
    params: params
  });
}