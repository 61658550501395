"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BatchPricevue',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '批量设置售价'
    },
    footerVisible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    confirm: function confirm() {
      this.$emit('confirm');
    }
  }
};
exports.default = _default;