var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("brand-side-edit", {
    ref: "edit",
    attrs: { visible: _vm.valueSync, loading: _vm.loading },
    on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirm },
    model: {
      value: _vm.brand,
      callback: function($$v) {
        _vm.brand = $$v
      },
      expression: "brand"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }