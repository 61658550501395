"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/videoupload/index"));

var _bus = _interopRequireDefault(require("../../../../utils/bus"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CG_File_VideoUploader_index',
  components: {
    VideoUpload: _index.default
  },
  props: {
    value: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    cover: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      videourl: ''
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        if (typeof this.value !== 'string' || this.value.trim().length < 1) {
          return '';
        }

        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    valueProxy: function valueProxy() {
      this.reInitDataFormParent();
    },
    cover: function cover() {
      var cover = typeof this.cover === 'string' && this.cover.trim().length > 0 ? this.cover : null;

      _bus.default.$emit('video-cover', cover);
    }
  },
  mounted: function mounted() {
    this.reInitDataFormParent();
  },
  methods: {
    reInitDataFormParent: function reInitDataFormParent() {
      this.$refs.uploadVideo.vid = this.valueProxy;
    },

    /**
     * 视频上传完成
     * @param data
     */
    getVideo: function getVideo(data) {
      this.valueProxy = data.videoId;
      this.$notify.success({
        title: '上传成功',
        message: '视频上传成功'
      });
    },
    delectevent: function delectevent() {
      this.valueProxy = '';
    }
  }
};
exports.default = _default2;