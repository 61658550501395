"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.videoStateList = exports.resourceTypeList = exports.Vocabulary_Picture_ID = exports.Library_Cover_ID = exports.E_BOOK_ID = exports.BIG_ALBUM_ID = exports.FAMOUS = exports.FOLLOW_BOOK_ID = exports.BOOK_MODEL_ID = exports.MODEL_ESSAY = exports.REAL_COMMONDITY_ID = exports.SINGLE_FILE_ID = exports.IMAGE_TEXT_ID = exports.FILE_ID = exports.LIVE_ID = exports.AUDIO_ID = exports.ALBUM_ID = exports.VEDIO_ID = exports.AUDIOBOOK_ID = exports.RESOURCE_DELETED_STATE = exports.RESOURCE_REFUSED_STATE = exports.RESOURCE_PULISHED_STATE = exports.RESOURCE_REVIEW_STATE = exports.RESOURCE_DRAFT_STATE = exports.PARTNER_REFUSED_STATUS = exports.PARTNER_PASSED_STATUS = exports.PARTNER_REVIEW_STATUS = void 0;

/* eslint-disable no-multi-spaces,no-unused-vars */
var PARTNER_REVIEW_STATUS = 1; // 商户状态 审核中

exports.PARTNER_REVIEW_STATUS = PARTNER_REVIEW_STATUS;
var PARTNER_PASSED_STATUS = 2; // 商户状态 已通过

exports.PARTNER_PASSED_STATUS = PARTNER_PASSED_STATUS;
var PARTNER_REFUSED_STATUS = 3; // 商户状态 已拒绝

exports.PARTNER_REFUSED_STATUS = PARTNER_REFUSED_STATUS;
var RESOURCE_DRAFT_STATE = 1; // 资源状态 草稿箱

exports.RESOURCE_DRAFT_STATE = RESOURCE_DRAFT_STATE;
var RESOURCE_REVIEW_STATE = 2; // 资源状态 审核中

exports.RESOURCE_REVIEW_STATE = RESOURCE_REVIEW_STATE;
var RESOURCE_PULISHED_STATE = 3; // 资源状态 已发布

exports.RESOURCE_PULISHED_STATE = RESOURCE_PULISHED_STATE;
var RESOURCE_REFUSED_STATE = 4; // 资源状态 未通过

exports.RESOURCE_REFUSED_STATE = RESOURCE_REFUSED_STATE;
var RESOURCE_DELETED_STATE = 5; // 资源状态 回收站

exports.RESOURCE_DELETED_STATE = RESOURCE_DELETED_STATE;
var AUDIOBOOK_ID = 3;
exports.AUDIOBOOK_ID = AUDIOBOOK_ID;
var VEDIO_ID = 1;
exports.VEDIO_ID = VEDIO_ID;
var ALBUM_ID = 4;
exports.ALBUM_ID = ALBUM_ID;
var AUDIO_ID = 2; // 音频资源类型

exports.AUDIO_ID = AUDIO_ID;
var LIVE_ID = 11; // 直播typeId

exports.LIVE_ID = LIVE_ID;
var FILE_ID = 8; // 文件包typeId

exports.FILE_ID = FILE_ID;
var IMAGE_TEXT_ID = 9; // 图文typeId

exports.IMAGE_TEXT_ID = IMAGE_TEXT_ID;
var SINGLE_FILE_ID = 10; // 单个文件typeId

exports.SINGLE_FILE_ID = SINGLE_FILE_ID;
var REAL_COMMONDITY_ID = 15; // 实物商品typeId

exports.REAL_COMMONDITY_ID = REAL_COMMONDITY_ID;
var MODEL_ESSAY = 14; // 范文

exports.MODEL_ESSAY = MODEL_ESSAY;
var BOOK_MODEL_ID = 17; // 图书模型类型

exports.BOOK_MODEL_ID = BOOK_MODEL_ID;
var FOLLOW_BOOK_ID = 19;
exports.FOLLOW_BOOK_ID = FOLLOW_BOOK_ID;
var FAMOUS = '名师'; // 名师

exports.FAMOUS = FAMOUS;
var BIG_ALBUM_ID = 16; // 大专辑

exports.BIG_ALBUM_ID = BIG_ALBUM_ID;
var E_BOOK_ID = 20; // 电子书

exports.E_BOOK_ID = E_BOOK_ID;
var Library_Cover_ID = '字库封面'; // 汉字库

exports.Library_Cover_ID = Library_Cover_ID;
var Vocabulary_Picture_ID = '词库图集'; // 词库

exports.Vocabulary_Picture_ID = Vocabulary_Picture_ID;
var resourceTypeList = [{
  value: undefined,
  title: '不限'
}, {
  value: 4,
  title: '专辑'
}, {
  value: 1,
  title: '视频'
}, {
  value: 2,
  title: '音频'
}, {
  value: 8,
  title: '文件包'
}, {
  value: 10,
  title: '文件'
}, {
  value: 9,
  title: '图文'
}, {
  value: 3,
  title: '点学书'
}, {
  value: 7,
  title: '跟读书'
}, {
  value: 11,
  title: '直播'
}, {
  value: 5,
  title: '金星币'
}, {
  value: 15,
  title: '实物商品'
}, {
  value: 6,
  title: 'VIP'
}, {
  value: 16,
  title: '大专辑'
}, {
  value: 19,
  title: '英语会话'
}, {
  value: 20,
  title: '电子书'
}];
exports.resourceTypeList = resourceTypeList;
var videoStateList = [{
  value: 'Normal',
  title: '正常'
}, {
  value: 'Blocked',
  title: '屏蔽'
}, {
  value: 'TranscodeFail',
  title: '转码失败'
}, {
  value: 'Transcoding',
  title: '转码中'
}, {
  value: 'UploadSucc',
  title: '上传完成'
}, {
  value: 'UploadFail',
  title: '上传失败'
}, {
  value: 'Uploading',
  title: '上传中'
}];
exports.videoStateList = videoStateList;