var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("div", { staticClass: "header-bg" }, [
        _c(
          "div",
          { staticClass: "header-bg-l" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addBtn }
              },
              [_vm._v("添加")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "header-bg-r" },
          [
            _c(
              "el-select",
              {
                staticClass: "select-section-bg",
                attrs: {
                  clearable: "",
                  placeholder: "请选择学段",
                  size: "small"
                },
                model: {
                  value: _vm.query.sectionCode,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "sectionCode", $$v)
                  },
                  expression: "query.sectionCode"
                }
              },
              _vm._l(_vm.syncSectionInfo, function(item) {
                return _c("el-option", {
                  key: item.code,
                  attrs: { label: item.name, value: item.code }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-cascader", {
              staticClass: "cascader-city",
              attrs: {
                size: "small",
                clearable: "",
                placeholder: "请选择所属省市",
                props: _vm.cascaderProps,
                options: _vm.options,
                filterable: ""
              },
              model: {
                value: _vm.query.district,
                callback: function($$v) {
                  _vm.$set(_vm.query, "district", $$v)
                },
                expression: "query.district"
              }
            }),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "search-txt",
                attrs: {
                  size: "small",
                  placeholder: "搜索学校名",
                  clearable: ""
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSchool($event)
                  }
                },
                model: {
                  value: _vm.query.name,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "name", $$v)
                  },
                  expression: "query.name"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { slot: "suffix", size: "small", type: "primary" },
                    on: { click: _vm.searchSchool },
                    slot: "suffix"
                  },
                  [_vm._v("搜索")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "school-table-body"
        },
        [
          _c(
            "el-table",
            {
              ref: "schoolList",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sequence", label: "排序", "min-width": "8" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "schoolOpenId",
                  label: "Open ID",
                  "min-width": "13"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "学校名", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "section", label: "学段", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "area", label: "所属省市县", "min-width": "15" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "37", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getSchoolList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "480px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "80px",
                      model: _vm.schoolForm,
                      rules: _vm.rules
                    }
                  },
                  [
                    _vm.dialogTitltText === "编辑学校"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "open id" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.schoolForm.schoolOpenId,
                                callback: function($$v) {
                                  _vm.$set(_vm.schoolForm, "schoolOpenId", $$v)
                                },
                                expression: "schoolForm.schoolOpenId"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "学校名", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 90,
                            placeholder: "请填写学校名",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.schoolForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.schoolForm, "name", $$v)
                            },
                            expression: "schoolForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "学段", prop: "sectionCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form-box",
                            attrs: {
                              filterable: "",
                              "allow-create": "",
                              "default-first-option": "",
                              multiple: "",
                              placeholder: "请选择学段"
                            },
                            model: {
                              value: _vm.schoolForm.sectionCode,
                              callback: function($$v) {
                                _vm.$set(_vm.schoolForm, "sectionCode", $$v)
                              },
                              expression: "schoolForm.sectionCode"
                            }
                          },
                          _vm._l(_vm.syncSectionInfo, function(item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属省市", prop: "cityCodeArray" } },
                      [
                        _c("el-cascader", {
                          key: _vm.isResouceShow,
                          staticClass: "form-box",
                          attrs: {
                            placeholder: "请选择所属省市",
                            props: _vm.props,
                            options: _vm.options
                          },
                          model: {
                            value: _vm.schoolForm.cityCodeArray,
                            callback: function($$v) {
                              _vm.$set(_vm.schoolForm, "cityCodeArray", $$v)
                            },
                            expression: "schoolForm.cityCodeArray"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "排序" } }, [
                      _c(
                        "div",
                        { staticClass: "sequence-box" },
                        [
                          _c("el-input-number", {
                            staticClass: "select-sequence",
                            staticStyle: { width: "100px" },
                            attrs: {
                              precision: 0,
                              step: 1,
                              min: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.schoolForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.schoolForm, "sequence", $$v)
                              },
                              expression: "schoolForm.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.editDialogVisible = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitSyncLoading
                                  },
                                  on: { click: _vm.submitSave }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }