var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        placeholder: _vm.placeholder,
        clearable: "",
        disabled: _vm.disabled,
        size: _vm.size,
        multiple: _vm.multiple,
        filterable: _vm.filterable,
        remote: _vm.remote,
        "remote-method": _vm.activeRemoteMethod
      },
      on: { change: _vm.onChange },
      model: {
        value: _vm.valueProxy,
        callback: function($$v) {
          _vm.valueProxy = $$v
        },
        expression: "valueProxy"
      }
    },
    _vm._l(_vm.selectOptions, function(item) {
      return _c("el-option", {
        key: _vm.keyPrefix + "-" + item.id,
        attrs: { label: _vm.pLabelDisplay(item), value: item[_vm.valueName] }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }