var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bulk-video" },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          class: _vm.tableData.length <= 0 ? "video-upload-table" : "",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
          on: {
            "select-all": _vm.selectAll,
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              selectable: _vm.selectable,
              width: "50"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "作品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "进度", width: "350" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.$index <= _vm.uploadIndex &&
                      (_vm.openUpload || _vm.uploadIndex > 0)
                      ? [
                          _c("el-progress", {
                            attrs: {
                              percentage: scope.row.progress,
                              status: scope.row.status
                            }
                          })
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "address",
              label: "操作",
              width: "150",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.openUpload,
                            expression: "openUpload"
                          }
                        ]
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.isPauseUpload &&
                                  _vm.uploadIndex === scope.$index,
                                expression:
                                  "isPauseUpload && uploadIndex === scope.$index"
                              }
                            ],
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: { click: _vm.resumeUpload }
                          },
                          [_vm._v("恢复")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.isPauseUpload &&
                                  _vm.uploadIndex === scope.$index,
                                expression:
                                  "!isPauseUpload && uploadIndex === scope.$index"
                              }
                            ],
                            staticStyle: { padding: "0", margin: "0" },
                            attrs: { type: "text" },
                            on: { click: _vm.pauseUpload }
                          },
                          [_vm._v("暂停")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    (_vm.openUpload && _vm.uploadIndex === scope.$index) ||
                    scope.row.status
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#C0C4CC", padding: "0" },
                            attrs: { type: "text" }
                          },
                          [_vm._v("移除")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.removerItemVideo(scope.row)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("batch-resource", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.length <= 0,
            expression: "tableData.length <= 0"
          }
        ],
        ref: "UploadResource",
        attrs: { "resource-type": _vm.resourceType },
        on: {
          "onUpload-progress": _vm.onUploadProgress,
          startUpload: _vm.startVideoUpload
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }