"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var store = {
  namespaced: true,
  state: function state() {
    return {
      listNeedUpdateAt: new Date().getTime()
    };
  },
  mutations: {
    UPDATE_LIST_NEED_UPDATE_AT: function UPDATE_LIST_NEED_UPDATE_AT(state, listNeedUpdateAt) {
      state.listNeedUpdateAt = listNeedUpdateAt;
    }
  },
  actions: {
    updateListNeedUpdateAt: function updateListNeedUpdateAt(_ref, listNeedUpdateAt) {
      var commit = _ref.commit;
      commit('UPDATE_LIST_NEED_UPDATE_AT', listNeedUpdateAt);
    }
  },
  getters: {
    listNeedUpdateAt: function listNeedUpdateAt(state) {
      return state.listNeedUpdateAt;
    }
  }
};
var _default = store;
exports.default = _default;