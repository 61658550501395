var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-experients",
      attrs: {
        "append-to-body": "",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "before-close": _vm.close,
        width: "800px",
        title: "添加体验者微信号"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "rule-form",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "微信号", prop: "wx" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 10 },
                  placeholder: "请输入体验者微信号，每行一个账号"
                },
                model: {
                  value: _vm.ruleForm.wx,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "wx", $$v)
                  },
                  expression: "ruleForm.wx"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", loading: _vm.loading },
              on: { click: _vm.submit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }