var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-section-delete",
      style: _vm.pageSectionPoint,
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.onDelete($event)
        }
      }
    },
    [_c("svg-icon", { attrs: { "icon-class": "page-editor-delete" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }