var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "only-audio-upload" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "audio-dialog",
          attrs: { visible: _vm.dialogUploadAudioVisible, title: "音频上传" },
          on: {
            "update:visible": function($event) {
              _vm.dialogUploadAudioVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialog-body" },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "100px", "label-position": "right" }
                },
                [
                  _c("el-form-item", { attrs: { label: "音频文件*:" } }, [
                    _c(
                      "div",
                      { staticClass: "upload-audio-bg" },
                      [
                        _vm.dialogUploadAudioVisible
                          ? _c("upload-file", {
                              ref: "uploadAudio",
                              attrs: {
                                type: _vm.audioType,
                                "audio-id": _vm.audioAliVid
                              },
                              on: {
                                startUpload: _vm.startUpload,
                                getResult: _vm.getUploader
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "copy-btn",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.copyUrl(_vm.audioAliVid, $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelEdit } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.saveState, type: "primary" },
                  on: { click: _vm.saveAudioVid }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }