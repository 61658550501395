var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "delete-material-dialog",
      attrs: {
        visible: _vm.visible,
        "append-to-body": "",
        title: "移除分组",
        width: "670px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleCancel
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.data.classificationName) +
          "含有" +
          _vm._s(_vm.data.fileNum) +
          "个图片，确定要移除此分组吗？\n  "
      ),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.systemList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: {
                  label: item.classificationName,
                  value: item.materialClassificationOpenId
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handelDelete } },
                [_vm._v("移除")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }