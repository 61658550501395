"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CommentDetailItem',
  props: {
    detail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isReplyTop: {
      type: Boolean,
      default: false
    } // 是否是右侧回复

  },
  data: function data() {
    return {
      isPlay: false
    };
  },
  methods: {
    priviewCommon: function priviewCommon(val) {
      if (!this.isReplyTop) {
        return;
      }

      this.$emit('priviewCommon', val);
    },
    playAudio: function playAudio(uniqueindex) {
      var _this = this;

      var audios = document.getElementsByTagName('audio');

      for (var i = 0; i < audios.length; i++) {
        if (audios[i].id !== uniqueindex) {
          document.getElementById(audios[i].id).load();
        }
      }

      document.getElementById(uniqueindex).play();
      this.isPlay = true;
      var audio = document.getElementById(uniqueindex);
      audio.addEventListener('ended', function () {
        _this.isPlay = false;
      }, false);
    },
    getImgUrl: function getImgUrl(cover, style) {
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    },
    getImg: function getImg(cover, style) {
      var imgAllUrlArray = [];
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      imgAllUrlArray.push(imgAllUrl);
      return imgAllUrlArray;
    },
    getAudioUrl: function getAudioUrl(url) {
      return "".concat(process.env.VUE_APP_OSS_URL).concat(process.env.VUE_APP_OSS_PATH, "/").concat(url);
    }
  }
};
exports.default = _default2;