var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chinese-list" },
    [
      _c(
        "div",
        { staticClass: "content-card" },
        [
          _c("div", { staticClass: "chinese-header" }, [
            _c(
              "div",
              { staticClass: "chinese-button-group" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "create-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleCreate }
                  },
                  [_vm._v("添加")]
                ),
                _vm._v(" "),
                _c("el-button", {
                  staticClass: "refresh-btn",
                  attrs: { plain: "", icon: "el-icon-refresh-right" },
                  on: { click: _vm.getList }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "interval" })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chinese-search" },
              [
                _c("chinese-search", {
                  attrs: {
                    query: _vm.query,
                    type: "character",
                    placeholder: "搜索汉字"
                  },
                  on: { getList: _vm.getList }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "word-table",
              staticStyle: { position: "relative" }
            },
            [
              _c(
                "div",
                { staticClass: "chinese-index" },
                _vm._l(_vm.indexList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: _vm.query.firstLetter == item ? "active" : "",
                      on: {
                        click: function($event) {
                          return _vm.conditionQuery(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item ? item : "*"))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.list.length > 0
                ? _c(
                    "div",
                    { staticClass: "content-box" },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "el-tooltip",
                        {
                          key: index,
                          staticClass: "word",
                          attrs: { enterable: false, placement: "right-start" }
                        },
                        [
                          item.id
                            ? _c(
                                "div",
                                {
                                  staticClass: "tooltip",
                                  attrs: { slot: "content" },
                                  slot: "content"
                                },
                                [
                                  _c("div", [
                                    _vm._v("索引：" + _vm._s(item.id))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _vm._v("拼音："),
                                      _vm._l(item.pinyinList, function(
                                        item1,
                                        index
                                      ) {
                                        return _c("span", { key: index }, [
                                          _vm._v(_vm._s(item1.pinyin))
                                        ])
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "笔画：" + _vm._s(item.strokesNumber)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _vm._v("部首："),
                                      _vm._l(item.radicalsList, function(
                                        item1,
                                        index
                                      ) {
                                        return _c("span", { key: index }, [
                                          _vm._v(_vm._s(item1.name))
                                        ])
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "结构：" + _vm._s(item.structureName)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "造字：" + _vm._s(item.typographyName)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "description" }, [
                                    _vm._v(
                                      "备注：" +
                                        _vm._s(
                                          item.description
                                            ? item.description
                                            : "无"
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.getWordDetail(item.name)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "content-box not-data" }, [
                    _vm._v("\n        暂无数据\n      ")
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                title: !_vm.wordParam.id ? "添加汉字" : "编辑汉字",
                visible: _vm.addDialogShow,
                "close-on-click-modal": false,
                width: "1000px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.addDialogShow = $event
                },
                close: _vm.closeDialog
              }
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      _vm.pinyinShowType = false
                    }
                  }
                },
                [
                  _c(
                    "el-container",
                    [
                      _c(
                        "el-container",
                        { staticStyle: { width: "40%", position: "relative" } },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "tabs",
                              attrs: { type: "card" },
                              model: {
                                value: _vm.basic,
                                callback: function($$v) {
                                  _vm.basic = $$v
                                },
                                expression: "basic"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "基本信息", name: "basic" } },
                                [
                                  _c(
                                    "el-form",
                                    { ref: "form", staticClass: "add-dialog" },
                                    [
                                      !_vm.wordParam.id
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "add-title",
                                              staticStyle: {
                                                "margin-bottom": "10px"
                                              },
                                              attrs: { prop: "name" }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "required" },
                                                [_vm._v("汉字")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-input",
                                                {
                                                  directives: [
                                                    {
                                                      name: "loading",
                                                      rawName: "v-loading",
                                                      value: _vm.checkName,
                                                      expression: "checkName"
                                                    }
                                                  ],
                                                  staticClass: "add-input",
                                                  attrs: {
                                                    maxlength: "1",
                                                    placeholder: "检索汉字",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    input: _vm.changeNameHandle
                                                  },
                                                  model: {
                                                    value: _vm.wordParam.name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.wordParam,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "wordParam.name"
                                                  }
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      size: "mini",
                                                      icon: "el-icon-search"
                                                    },
                                                    on: {
                                                      click: _vm.checkWord
                                                    },
                                                    slot: "append"
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "add-title",
                                              staticStyle: {
                                                "margin-bottom": "10px"
                                              },
                                              attrs: { prop: "name" }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "required" },
                                                [_vm._v("汉字")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticClass: "add-input",
                                                attrs: {
                                                  disabled: "disabled",
                                                  maxlength: "1",
                                                  placeholder: "检索汉字",
                                                  size: "small"
                                                },
                                                model: {
                                                  value: _vm.wordParam.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "wordParam.name"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "add-title" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "required" },
                                            [_vm._v("结构")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "88%" },
                                              attrs: {
                                                multiple: "",
                                                placeholder: "请选择结构",
                                                size: "small"
                                              },
                                              model: {
                                                value:
                                                  _vm.wordParam.structureCodes,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.wordParam,
                                                    "structureCodes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "wordParam.structureCodes"
                                              }
                                            },
                                            _vm._l(_vm.structureList, function(
                                              item,
                                              index
                                            ) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.structureCode
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "add-title" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "required" },
                                            [_vm._v("笔画")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticClass: "add-input",
                                            attrs: {
                                              size: "small",
                                              placeholder: "请填写笔画数",
                                              type: "number"
                                            },
                                            model: {
                                              value:
                                                _vm.wordParam.strokesNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.wordParam,
                                                  "strokesNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "wordParam.strokesNumber"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("bushou-cascader", {
                                        attrs: {
                                          title: "部首",
                                          showType: _vm.pinyinShowType,
                                          dragData: _vm.wordParam.radicalsCodes,
                                          list: _vm.radicalList,
                                          placeholder: "请选择部首"
                                        },
                                        on: {
                                          "update:showType": function($event) {
                                            _vm.pinyinShowType = $event
                                          },
                                          "update:show-type": function($event) {
                                            _vm.pinyinShowType = $event
                                          },
                                          changeShowType: _vm.changeShowType,
                                          changeDrag: function($event) {
                                            return _vm.changeDrag(
                                              $event,
                                              "synonymList"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "add-title" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "required" },
                                            [_vm._v("造字")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "88%" },
                                              attrs: {
                                                multiple: "",
                                                placeholder: "请选择造字",
                                                size: "small"
                                              },
                                              model: {
                                                value:
                                                  _vm.wordParam.typographyCodes,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.wordParam,
                                                    "typographyCodes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "wordParam.typographyCodes"
                                              }
                                            },
                                            _vm._l(_vm.typographyList, function(
                                              item,
                                              index
                                            ) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.typographyCode
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "add-title not-required"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px"
                                              }
                                            },
                                            [_vm._v("配图")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticStyle: { width: "85%" } },
                                            [
                                              _c("cover-upload", {
                                                attrs: {
                                                  type: _vm.Library_Cover_ID.toString(),
                                                  coverarr: _vm.figureCoverArr,
                                                  texttips: "点击上传",
                                                  covertext: "",
                                                  path: "wordLibrary/figure/",
                                                  "preview-z-index": 3000
                                                },
                                                on: {
                                                  deletecover: function(
                                                    $event
                                                  ) {
                                                    _vm.wordParam.cover = undefined
                                                  }
                                                },
                                                model: {
                                                  value: _vm.wordParam.cover,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "cover",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "wordParam.cover"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "add-title not-required"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "margin-right": "5px"
                                              }
                                            },
                                            [_vm._v("备注")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticClass: "add-input",
                                            attrs: {
                                              type: "textarea",
                                              rows: 7,
                                              placeholder: "请填写备注"
                                            },
                                            model: {
                                              value: _vm.wordParam.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.wordParam,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "wordParam.description"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-container",
                        {
                          staticStyle: { width: "60%", "margin-left": "10px" }
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "tabs-right",
                              staticStyle: { width: "100%" },
                              attrs: { type: "card" },
                              model: {
                                value: _vm.rightInfo,
                                callback: function($$v) {
                                  _vm.rightInfo = $$v
                                },
                                expression: "rightInfo"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "拼音信息", name: "spell" } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "form",
                                      staticClass: "add-dialog",
                                      staticStyle: {
                                        padding: "5px 0px",
                                        "min-height": "50px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-tabs",
                                        {
                                          staticStyle: { padding: "0 15px" },
                                          attrs: {
                                            closable:
                                              _vm.wordParam.pinyinList.length >
                                              1,
                                            addable: ""
                                          },
                                          on: { edit: _vm.handleTabsEdit },
                                          model: {
                                            value: _vm.editableTabsValue,
                                            callback: function($$v) {
                                              _vm.editableTabsValue = $$v
                                            },
                                            expression: "editableTabsValue"
                                          }
                                        },
                                        _vm._l(
                                          _vm.wordParam.pinyinList,
                                          function(item, index) {
                                            return _c(
                                              "el-tab-pane",
                                              {
                                                key: item.name,
                                                attrs: {
                                                  label: item.title,
                                                  name: item.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "dialogBox",
                                                    style:
                                                      "max-height: " +
                                                      (_vm.screeHeight - 350) +
                                                      "px;"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          padding: "0 15px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "add-title",
                                                            staticStyle: {
                                                              position:
                                                                "relative"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "required"
                                                              },
                                                              [_vm._v("拼音")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("el-cascader", {
                                                              ref: "refSubCat",
                                                              refInFor: true,
                                                              staticStyle: {
                                                                width: "88%"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择拼音",
                                                                options:
                                                                  _vm.syllableList,
                                                                "show-all-levels": false,
                                                                size: "small"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleChange(
                                                                    $event,
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  item.pinyinName,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "pinyinName",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.pinyinName"
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item-tooltip",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content: "",
                                                                  placement:
                                                                    "top"
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon el-icon-info"
                                                                })
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("rich_text", {
                                                          staticStyle: {
                                                            "font-size": "15px"
                                                          },
                                                          attrs: {
                                                            title: "字义",
                                                            stateType: true,
                                                            required:
                                                              "required",
                                                            param:
                                                              item.wordMean,
                                                            index: index,
                                                            type: "wordMean",
                                                            placeholder:
                                                              "请填写该字的字义"
                                                          },
                                                          on: {
                                                            changeRich: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeRich(
                                                                arguments
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("rich_text", {
                                                          staticStyle: {
                                                            "font-size": "15px"
                                                          },
                                                          attrs: {
                                                            title: "组词",
                                                            stateType: true,
                                                            required:
                                                              "required",
                                                            param:
                                                              item.groupWords,
                                                            index: index,
                                                            type: "groupWords",
                                                            placeholder:
                                                              "请填写常用组词"
                                                          },
                                                          on: {
                                                            changeRich: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeRich(
                                                                arguments
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("rich_text", {
                                                          staticStyle: {
                                                            "font-size": "15px"
                                                          },
                                                          attrs: {
                                                            title: "造句",
                                                            stateType: true,
                                                            required:
                                                              "required",
                                                            param:
                                                              item.sentenceMake,
                                                            index: index,
                                                            type:
                                                              "sentenceMake",
                                                            placeholder:
                                                              "请填写常用造句"
                                                          },
                                                          on: {
                                                            changeRich: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeRich(
                                                                arguments
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "add-title default",
                                                            staticStyle: {
                                                              position:
                                                                "relative"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticStyle: {
                                                                  width: "4em"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "设为默认"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#13ce66",
                                                                "inactive-color":
                                                                  "#ededef"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeDefault(
                                                                    $event,
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  item.ifDefault,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "ifDefault",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.ifDefault"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "扩展信息", name: "basic" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "form",
                                          staticClass: "add-dialog dialogBox",
                                          style:
                                            "max-height: " +
                                            (_vm.screeHeight - 300) +
                                            "px;"
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "相关视频" } },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    plain: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.outerVisible = !_vm.outerVisible
                                                    }
                                                  }
                                                },
                                                [_vm._v("添加")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-item-border video-border"
                                            },
                                            [
                                              _c(
                                                "draggable",
                                                {
                                                  staticClass: "menu-list",
                                                  attrs: {
                                                    list: _vm.videoList,
                                                    move: _vm.move,
                                                    disabled: false
                                                  },
                                                  on: {
                                                    start: function($event) {
                                                      return _vm.start()
                                                    },
                                                    end: _vm.end
                                                  }
                                                },
                                                _vm._l(_vm.videoList, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass: "video-box"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "12px",
                                                              height: "12px"
                                                            },
                                                            attrs: {
                                                              src: require("../../assets/images/md-ondemand_video.svg")
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "video-button",
                                                          staticStyle: {
                                                            display: "flex"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content:
                                                                  "标签管理",
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "video-check iconfont icon-biaoqian",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "5px",
                                                                  "font-size":
                                                                    "12px",
                                                                  width: "12px",
                                                                  height: "12px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleOpenLabel(
                                                                      item,
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content: "预览",
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "video-check",
                                                                attrs: {
                                                                  src: require("../../assets/images/md-find_in_page.svg")
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewVideo(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content: "替换",
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "video-check",
                                                                attrs: {
                                                                  src: require("../../assets/images/md-swap_horiz.svg")
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.replaceVideo(
                                                                      item,
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content: "删除",
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "video-check",
                                                                attrs: {
                                                                  src: require("../../assets/images/md-close.svg")
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleRemove(
                                                                      item.resourceOpenId
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          item.label
                                                            ? _c(
                                                                "el-tag",
                                                                {
                                                                  staticClass:
                                                                    "video-tag-box"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("el-image", {
                                                            staticClass:
                                                              "video-not-check",
                                                            attrs: {
                                                              src: require("../../assets/images/md-check_circle.svg")
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "形近字" } },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "success",
                                                        size: "mini",
                                                        plain: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.deskDialogShow = !_vm.deskDialogShow
                                                          _vm.showType = "add"
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("添加汉字")]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.checkList.length > 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "danger",
                                                            size: "mini",
                                                            plain: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.removeAllChar
                                                          }
                                                        },
                                                        [_vm._v("全部移除")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-item-border near-char"
                                                },
                                                _vm._l(_vm.checkList, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass: "word-box"
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass:
                                                          "close-button",
                                                        attrs: {
                                                          src: require("../../assets/images/md-close-red.svg")
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeNearChar(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "modal"
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "5px",
                                                              cursor: "pointer"
                                                            },
                                                            attrs: {
                                                              src: require("../../assets/images/md-remove_red_eye.svg")
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.previewWord(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              cursor: "pointer"
                                                            },
                                                            attrs: {
                                                              src: require("../../assets/images/md-create.svg")
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.replaceNearChar(
                                                                  item,
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                }),
                                                0
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "add-title wider",
                                              staticStyle: {
                                                "align-items": "flex-start"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "title" },
                                                [_vm._v("笔顺拆分")]
                                              ),
                                              _vm._v(" "),
                                              _c("stroke-order-list", {
                                                attrs: {
                                                  "stroke-order-data":
                                                    _vm.wordParam
                                                      .chineseCharBishun
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("rich_text", {
                                            attrs: {
                                              title: "书写",
                                              param: _vm.wordParam.writeNotes,
                                              type: "writeNotes",
                                              placeholder: "请填写书写注意点"
                                            },
                                            on: {
                                              changeRich: function($event) {
                                                return _vm.changeRich(arguments)
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "add-title wider",
                                              staticStyle: {
                                                "align-items": "flex-start"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "title" },
                                                [_vm._v("字源")]
                                              ),
                                              _vm._v(" "),
                                              _c("word_up_load", {
                                                staticClass: "upload-box",
                                                attrs: {
                                                  form: "etymology",
                                                  path: "etymology/",
                                                  type: "etymology"
                                                },
                                                on: {
                                                  deletecover: function(
                                                    $event
                                                  ) {
                                                    return _vm.deleteCoverFun(
                                                      "etymology"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.wordParam.etymology,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "etymology",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "wordParam.etymology"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item-tooltip",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon el-icon-info"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("rich_text", {
                                            attrs: {
                                              title: "字族",
                                              param: _vm.wordParam.charFamily,
                                              type: "charFamily",
                                              placeholder: "请填写字族"
                                            },
                                            on: {
                                              changeRich: function($event) {
                                                return _vm.changeRich(arguments)
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("rich_text", {
                                            attrs: {
                                              title: "巧记",
                                              param:
                                                _vm.wordParam.ingeniousNotes,
                                              type: "ingeniousNotes",
                                              placeholder: "请填写巧记"
                                            },
                                            on: {
                                              changeRich: function($event) {
                                                return _vm.changeRich(arguments)
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("rich_text", {
                                            attrs: {
                                              title: "易错提示",
                                              param:
                                                _vm.wordParam.errorPronePrompt,
                                              type: "errorPronePrompt",
                                              placeholder: "请填写易错提示"
                                            },
                                            on: {
                                              changeRich: function($event) {
                                                return _vm.changeRich(arguments)
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "add-title wider",
                                              staticStyle: {
                                                "align-items": "flex-start"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "title" },
                                                [_vm._v("笔顺")]
                                              ),
                                              _vm._v(" "),
                                              _c("word_up_load", {
                                                staticClass: "upload-box",
                                                attrs: {
                                                  form: "strokeOrder",
                                                  path: "etymology/",
                                                  type: "strokeOrder"
                                                },
                                                on: {
                                                  deletecover: function(
                                                    $event
                                                  ) {
                                                    return _vm.deleteCoverFun(
                                                      "strokeOrder"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.wordParam.strokeOrder,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "strokeOrder",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "wordParam.strokeOrder"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item-tooltip",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon el-icon-info"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("rich_text", {
                                            attrs: {
                                              title: "字谜",
                                              param: _vm.wordParam.charRiddle,
                                              type: "charRiddle",
                                              placeholder: "请填写字谜"
                                            },
                                            on: {
                                              changeRich: function($event) {
                                                return _vm.changeRich(arguments)
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("rich_text", {
                                            attrs: {
                                              title: "字歌",
                                              param: _vm.wordParam.charSong,
                                              type: "charSong",
                                              placeholder: "请填写字歌"
                                            },
                                            on: {
                                              changeRich: function($event) {
                                                return _vm.changeRich(arguments)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("排序")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          attrs: { "controls-position": "right" },
                          model: {
                            value: _vm.wordParam.sequence,
                            callback: function($$v) {
                              _vm.$set(_vm.wordParam, "sequence", $$v)
                            },
                            expression: "wordParam.sequence"
                          }
                        }),
                        _vm._v(" "),
                        _vm.wordParam.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", plain: "" },
                                on: { click: _vm.deleteWord }
                              },
                              [_vm._v("移除")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("el-button", { on: { click: _vm.cancel } }, [
                          _vm._v("取消")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "" },
                            on: { click: _vm.adhibition }
                          },
                          [_vm._v("应用")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.commitWord }
                          },
                          [_vm._v("全部保存")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.deskDialogShow
            ? _c("selectFrontDesk", {
                attrs: {
                  deskDialogShow: _vm.deskDialogShow,
                  checkList: _vm.checkList,
                  showType: _vm.showType,
                  visible: _vm.deskDialogShow,
                  screeHeight: _vm.screeHeight,
                  replaceMap: _vm.replaceMap,
                  placeholder: "搜索汉字",
                  title: "选择汉字"
                },
                on: {
                  getSelectWord: _vm.getSelectWord,
                  getReplace: _vm.getReplace,
                  "change-show": _vm.parentEvent,
                  "update:visible": function($event) {
                    _vm.deskDialogShow = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "chinese-footer" },
            [
              _c("el-pagination", {
                staticClass: "footer-page",
                attrs: {
                  background: "",
                  "current-page": _vm.query.page,
                  "page-sizes": [100, 150, 200, 250],
                  "page-size": _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "形近字预览",
            visible: _vm.previewShow,
            "close-on-click-modal": false,
            width: "500px",
            height: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.previewShow = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                height: "100%",
                width: "100%"
              }
            },
            [
              _c("iframe", {
                staticStyle: { width: "100%", height: "500px" },
                attrs: {
                  src:
                    "https://www.taozhi.cn/phone_hz/" + _vm.previewID + ".htm"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              expectType: "wordContent",
              visible: _vm.outerVisible,
              "exclude-data": _vm.videoList,
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.onResourceSelected
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.replaceVisible
        ? _c("select-resource-dialog", {
            attrs: {
              replaceIndex: _vm.videoIndex,
              expectType: "wordContent",
              visible: _vm.replaceVisible,
              "exclude-data": _vm.replaceVideoList,
              dialogType: "replace",
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.replaceVisible = $event
              },
              "add-select": _vm.onReplayVideo
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.videoPreview
        ? _c(
            "el-dialog",
            {
              staticClass: "video-preview",
              attrs: {
                visible: _vm.videoPreview,
                "before-close": _vm.videoPreviewClose,
                title: "视频预览"
              },
              on: {
                "update:visible": function($event) {
                  _vm.videoPreview = $event
                }
              }
            },
            [
              _c("div", {
                staticClass: "video-start",
                attrs: { id: "videoPreview" }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("labelManage", {
        attrs: { innerVisible: _vm.tagDialogType, videoTag: _vm.videoTag },
        on: {
          closeLabelManage: function($event) {
            _vm.tagDialogType = false
          },
          handleSelectTag: _vm.handleSelectTag
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }