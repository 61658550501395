var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "videoInfo",
          staticClass: "demo-ruleForm",
          attrs: { "label-width": _vm.labelWidth }
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "sell-address",
                  attrs: { disabled: _vm.disabledEdit },
                  model: {
                    value: _vm.independent,
                    callback: function($$v) {
                      _vm.independent = $$v
                    },
                    expression: "independent"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.goodsType === "resource" ? "支持单独售卖" : "支持购买"
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _vm.independent
                ? _c(
                    "div",
                    { staticClass: "sellmode" },
                    [
                      _vm.goodsType !== "resource"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-goodschli",
                                  attrs: {
                                    label: "库存数量",
                                    "label-width": "80px"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.disabledEdit,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: _vm.commodityGoods.inventory,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.commodityGoods,
                                          "inventory",
                                          $$v
                                        )
                                      },
                                      expression: "commodityGoods.inventory"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-info",
                                    staticStyle: { color: "#409EFF" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text vip-text" },
                                    [_vm._v("库存数量为必填内容，最大值99999")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-goodschli",
                                  attrs: {
                                    label: "限购数量",
                                    "label-width": "80px"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled:
                                        _vm.disabledEdit ||
                                        _vm.commodityGoods.unlimitedNumber,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: _vm.commodityGoods.purchaseLimit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.commodityGoods,
                                          "purchaseLimit",
                                          $$v
                                        )
                                      },
                                      expression: "commodityGoods.purchaseLimit"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "check-unlimited",
                                      attrs: { disabled: _vm.disabledEdit },
                                      model: {
                                        value:
                                          _vm.commodityGoods.unlimitedNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.commodityGoods,
                                            "unlimitedNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "commodityGoods.unlimitedNumber"
                                      }
                                    },
                                    [_vm._v("不限")]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-info",
                                    staticStyle: { color: "#409EFF" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text vip-text" },
                                    [_vm._v("限制每人购买数量")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-goodschli",
                                  attrs: {
                                    label: "购买权限",
                                    "label-width": "80px"
                                  }
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: _vm.disabledEdit },
                                      model: {
                                        value: _vm.commodityGoods.vipBuy,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.commodityGoods,
                                            "vipBuy",
                                            $$v
                                          )
                                        },
                                        expression: "commodityGoods.vipBuy"
                                      }
                                    },
                                    [_vm._v("VIP可购")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: _vm.disabledEdit },
                                      model: {
                                        value: _vm.commodityGoods.teacherBuy,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.commodityGoods,
                                            "teacherBuy",
                                            $$v
                                          )
                                        },
                                        expression: "commodityGoods.teacherBuy"
                                      }
                                    },
                                    [_vm._v("教师可购")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.goodsType === "resource"
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "checkradio margibbottom",
                              attrs: {
                                label: "售卖方式",
                                "label-width": "80px"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.disabledEdit },
                                  on: { change: _vm.radiogroup },
                                  model: {
                                    value: _vm.radio,
                                    callback: function($$v) {
                                      _vm.radio = $$v
                                    },
                                    expression: "radio"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("付费")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("免费")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "3" } }, [
                                    _vm._v("仅VIP可用")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.radio == 1 || _vm.goodsType !== "resource",
                              expression:
                                "radio == 1 || goodsType !== 'resource'"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "el-form-goodschli",
                              attrs: {
                                label: "商品价格",
                                "label-width": "80px"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.disabledEdit,
                                  precision: 2,
                                  step: 1,
                                  min: _vm.pricemin,
                                  max: 1000000,
                                  "controls-position": "right"
                                },
                                on: { change: _vm.priceInputBlur },
                                model: {
                                  value: _vm.goods.price,
                                  callback: function($$v) {
                                    _vm.$set(_vm.goods, "price", $$v)
                                  },
                                  expression: "goods.price"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "video-text line" }, [
                                _vm._v("元")
                              ]),
                              _vm._v(" "),
                              _vm.goodsType === "resource"
                                ? _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "gold-star",
                                      attrs: { disabled: _vm.disabledEdit },
                                      on: { change: _vm.goldStarFun },
                                      model: {
                                        value: _vm.goldStar,
                                        callback: function($$v) {
                                          _vm.goldStar = $$v
                                        },
                                        expression: "goldStar"
                                      }
                                    },
                                    [_vm._v("可使用金星币购买")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.hideGoodsDiscount
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-goodschli",
                                  attrs: {
                                    label: "商品折扣",
                                    "label-width": "80px"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.disabledEdit,
                                      precision: 4,
                                      step: 5,
                                      max: 100,
                                      min: 0,
                                      "controls-position": "right"
                                    },
                                    on: { change: _vm.discountInputBlur },
                                    nativeOn: {
                                      keydown: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.discountInputBlur($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.goods.discount,
                                      callback: function($$v) {
                                        _vm.$set(_vm.goods, "discount", $$v)
                                      },
                                      expression: "goods.discount"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text line" },
                                    [_vm._v("％")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "price-label-title line" },
                                    [_vm._v("减价")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: 2,
                                      step: 1,
                                      min: -_vm.goods.price,
                                      max: 0,
                                      disabled: _vm.disabledEdit,
                                      "controls-position": "right"
                                    },
                                    on: { change: _vm.reducePriceBlur },
                                    nativeOn: {
                                      keydown: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.reducePriceBlur($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.reducePrice,
                                      callback: function($$v) {
                                        _vm.reducePrice = $$v
                                      },
                                      expression: "reducePrice"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text line" },
                                    [_vm._v("元")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "price-label-title line" },
                                    [_vm._v("折后价")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: 2,
                                      step: 1,
                                      min: 0,
                                      max: _vm.goods.price,
                                      disabled: _vm.disabledEdit,
                                      "controls-position": "right"
                                    },
                                    on: { change: _vm.discountPriceBlur },
                                    nativeOn: {
                                      keydown: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.discountPriceBlur($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.discountPrice,
                                      callback: function($$v) {
                                        _vm.discountPrice = $$v
                                      },
                                      expression: "discountPrice"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text line" },
                                    [_vm._v("元")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "el-form-goodschli",
                              attrs: { label: "VIP折扣", "label-width": "80px" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.disabledEdit,
                                  precision: 4,
                                  step: 5,
                                  max: 100,
                                  min: 0,
                                  "controls-position": "right"
                                },
                                on: { change: _vm.vipDiscountInputBlur },
                                nativeOn: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.vipDiscountInputBlur($event)
                                  }
                                },
                                model: {
                                  value: _vm.goods.vipDiscount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.goods, "vipDiscount", $$v)
                                  },
                                  expression: "goods.vipDiscount"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "video-text line" }, [
                                _vm._v("％")
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "price-label-title line" },
                                [_vm._v("VIP减价")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                staticClass: "reduce-price-input",
                                attrs: {
                                  precision: 2,
                                  step: 1,
                                  min: -_vm.goods.price,
                                  max: _vm.reducePrice,
                                  disabled: _vm.disabledEdit,
                                  "controls-position": "right"
                                },
                                on: { change: _vm.vipReducePriceBlur },
                                nativeOn: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.vipReducePriceBlur($event)
                                  }
                                },
                                model: {
                                  value: _vm.vipReducePrice,
                                  callback: function($$v) {
                                    _vm.vipReducePrice = $$v
                                  },
                                  expression: "vipReducePrice"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "video-text line" }, [
                                _vm._v("元")
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "price-label-title line" },
                                [_vm._v("VIP折后价")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                attrs: {
                                  precision: 2,
                                  step: 1,
                                  min: 0,
                                  max: _vm.discountPrice,
                                  disabled: _vm.disabledEdit,
                                  "controls-position": "right"
                                },
                                on: { change: _vm.vipDiscountPriceBlur },
                                nativeOn: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.vipDiscountPriceBlur($event)
                                  }
                                },
                                model: {
                                  value: _vm.vipDiscountPrice,
                                  callback: function($$v) {
                                    _vm.vipDiscountPrice = $$v
                                  },
                                  expression: "vipDiscountPrice"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "video-text line" }, [
                                _vm._v("元")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "vip-tip-bg",
                              staticStyle: { "margin-left": "85px" }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: { color: "#409EFF" }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "video-text vip-text" },
                                [_vm._v("VIP享受折上折优惠")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "el-form-goodschli",
                              attrs: { label: "有效期", "label-width": "85px" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "expiration" },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.disabledEdit,
                                      step: 1,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: _vm.goods.effectivePeriod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.goods,
                                          "effectivePeriod",
                                          $$v
                                        )
                                      },
                                      expression: "goods.effectivePeriod"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text line" },
                                    [_vm._v("天")]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-info",
                                    staticStyle: {
                                      color: "#409EFF",
                                      margin: "0 5px 0 15px"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "video-text vip-text" },
                                    [
                                      _vm._v(
                                        "默认为" +
                                          _vm._s(_vm.effectivePeriod) +
                                          "天，若填写0则为永久有效"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.bulkIsShow
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "el-form-goodschli",
                                  attrs: {
                                    label: "赠品",
                                    "label-width": "80px"
                                  }
                                },
                                [
                                  _vm.giftlist.length < 6
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              _vm.outerVisible = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "选择赠品(" +
                                              _vm._s(_vm.giftlist.length) +
                                              "/6)"
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.bulkIsShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "", "label-width": "80px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card-lz-row display-flex box addgift-row"
                                    },
                                    _vm._l(_vm.giftlist, function(item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.resourceOpenId,
                                          staticClass:
                                            "addgift-item display-flex box"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "addgift-item-img box"
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  onerror: _vm.errorUserPhoto,
                                                  src: _vm.getImageUrl(
                                                    item.resourceInfo
                                                      ? item.resourceInfo.cover
                                                      : item.cover
                                                  ),
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "addgift-item-textbox box"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "gift-title",
                                                  attrs: {
                                                    title: item.resourceTitle
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.title
                                                        ? item.title
                                                        : item.resourceTitle
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "addgift-textbox-btn display-flex"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.resourceTypetext(
                                                          item.resourceType
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "删除",
                                                    placement: "right"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.giftsdelete(
                                                          item.commodityOpenId
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.goodsType === "resource"
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "sell-address",
                      attrs: {
                        disabled: _vm.disabledEdit || _vm.goods.vipOnly
                      },
                      model: {
                        value: _vm.goods.needAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "needAddress", $$v)
                        },
                        expression: "goods.needAddress"
                      }
                    },
                    [_vm._v("\n        需要邮寄\n      ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.goodsType === "resource"
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "sell-address",
                      attrs: { disabled: _vm.disabledEdit },
                      model: {
                        value: _vm.goods.teacherFree,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "teacherFree", $$v)
                        },
                        expression: "goods.teacherFree"
                      }
                    },
                    [_vm._v("\n        认证教师免费\n      ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "sell-address",
                  attrs: { disabled: _vm.disabledEdit },
                  on: { change: _vm.distributionChange },
                  model: {
                    value: _vm.goods.distribution,
                    callback: function($$v) {
                      _vm.$set(_vm.goods, "distribution", $$v)
                    },
                    expression: "goods.distribution"
                  }
                },
                [_vm._v("\n        参与分销\n      ")]
              ),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-info",
                staticStyle: { color: "#409EFF" }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "video-text vip-text" },
                [
                  _vm._v("勾选此项，则资源参与平台推广，详见"),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          _vm.isPromotion = true
                        }
                      }
                    },
                    [_vm._v("《推广说明》")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.outerVisible,
              partnerOpenId: _vm.partnerOpenId,
              "expect-type": "commodity",
              "exclude-data": _vm.giftlist
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.addgiftevent
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("promotion-dialog", {
        attrs: { visible: _vm.isPromotion },
        on: {
          "update:visible": function($event) {
            _vm.isPromotion = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }