var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "the-underlying-column",
    { attrs: { label: "状态筛选" } },
    [
      _vm.hasState
        ? _c(
            "el-form-item",
            { attrs: { label: "在售状态" } },
            [
              _c("boolean-radio", {
                staticClass: "single-line",
                attrs: { option: _vm.state },
                model: {
                  value: _vm.valueProxy.state,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "state", $$v)
                  },
                  expression: "valueProxy.state"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "el-form-item",
            { attrs: { label: "是否套装" } },
            [
              _c("boolean-radio", {
                staticClass: "single-line",
                model: {
                  value: _vm.valueProxy.isSuit,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "isSuit", $$v)
                  },
                  expression: "valueProxy.isSuit"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "el-form-item",
            { attrs: { label: "教辅单品" } },
            [
              _c("boolean-radio", {
                staticClass: "single-line",
                model: {
                  value: _vm.valueProxy.single,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "single", $$v)
                  },
                  expression: "valueProxy.single"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "有无规格" } },
        [
          _c("boolean-radio", {
            staticClass: "single-line",
            attrs: { option: _vm.specificJson },
            model: {
              value: _vm.valueProxy.specificJson,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "specificJson", $$v)
              },
              expression: "valueProxy.specificJson"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "商品封面" } },
        [
          _c("boolean-radio", {
            staticClass: "single-line",
            attrs: { option: _vm.cover },
            model: {
              value: _vm.valueProxy.cover,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "cover", $$v)
              },
              expression: "valueProxy.cover"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "主图视频" } },
        [
          _c("boolean-radio", {
            staticClass: "single-line",
            attrs: { option: _vm.showVideo },
            model: {
              value: _vm.valueProxy.showVideo,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "showVideo", $$v)
              },
              expression: "valueProxy.showVideo"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "是否限购" } },
        [
          _c("boolean-radio", {
            staticClass: "single-line",
            attrs: { option: _vm.quota },
            model: {
              value: _vm.valueProxy.quota,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "quota", $$v)
              },
              expression: "valueProxy.quota"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "教师可购" } },
        [
          _c("boolean-radio", {
            staticClass: "single-line",
            attrs: { option: _vm.teacherBuy },
            model: {
              value: _vm.valueProxy.teacherBuy,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "teacherBuy", $$v)
              },
              expression: "valueProxy.teacherBuy"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }