var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-dialog",
      attrs: {
        width: "500px",
        visible: _vm.visible,
        title: _vm.title,
        "close-on-click-modal": false,
        "show-close": !_vm.loading
      },
      on: { close: _vm.onCancel }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.valueSync,
            rules: _vm.rules,
            disabled: _vm.loading,
            "label-width": "90px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "品牌名称", prop: "title" } },
            [
              _c("el-input", {
                staticClass: "add-input",
                attrs: { placeholder: "请输入品牌方名称", clearable: "" },
                model: {
                  value: _vm.valueSync.title,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "title", $$v)
                  },
                  expression: "valueSync.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sequence" } },
            [
              _c("el-input-number", {
                staticClass: "sequence",
                attrs: { precision: 0, "controls-position": "right" },
                model: {
                  value: _vm.valueSync.sequence,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "sequence", $$v)
                  },
                  expression: "valueSync.sequence"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { disabled: _vm.loading }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onConfirm }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }