"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _promotionSetting = _interopRequireDefault(require("./promotionSetting.vue"));

var _promotionSettingPlatform = _interopRequireDefault(require("./promotionSettingPlatform.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'promotionSettingIndex',
  components: {
    promotionSetting: _promotionSetting.default,
    promotionSettingPlatform: _promotionSettingPlatform.default
  },
  data: function data() {
    return {
      settingName: '推广设置'
    };
  },
  methods: {
    settingTabs: function settingTabs(tab) {
      this.settingName = tab.label;
    }
  }
};
exports.default = _default;