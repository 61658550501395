"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueAplayer = _interopRequireDefault(require("vue-aplayer"));

//
//
//
//
//
//
//
//
var _default = {
  name: 'Aplayer',
  components: {
    // 别忘了引入组件
    aplayer: _vueAplayer.default
  },
  props: {
    mp3url: {
      type: String,
      default: ''
    },
    mp3title: {
      type: String,
      default: ''
    },
    dialogVisibleaudio: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      musicList: {
        title: 'youkao100',
        author: 'youkao100',
        url: 'http://localhost:1122/static/ceshi.mp3',
        pic: ''
      }
    };
  },
  computed: {
    url: function url() {
      return this.mp3url;
    },
    title: function title() {
      return this.mp3title;
    }
  },
  mounted: function mounted() {
    this.musicList = {
      title: this.title,
      url: this.url,
      pic: '',
      author: 'Author'
    };
  }
};
exports.default = _default;