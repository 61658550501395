import { render, staticRenderFns } from "./Invoiceimage.vue?vue&type=template&id=dc39f9d8&scoped=true&"
import script from "./Invoiceimage.vue?vue&type=script&lang=js&"
export * from "./Invoiceimage.vue?vue&type=script&lang=js&"
import style0 from "./Invoiceimage.vue?vue&type=style&index=0&id=dc39f9d8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc39f9d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc39f9d8')) {
      api.createRecord('dc39f9d8', component.options)
    } else {
      api.reload('dc39f9d8', component.options)
    }
    module.hot.accept("./Invoiceimage.vue?vue&type=template&id=dc39f9d8&scoped=true&", function () {
      api.rerender('dc39f9d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Form/Invoiceimage/Invoiceimage.vue"
export default component.exports