var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      attrs: { "split-button": "", type: "primary" },
      on: { click: _vm.onDropdownClick, command: _vm.onDropdownCommand }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.activeOption.name) + "\n  "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.optionList, function(v, key) {
          return _c("el-dropdown-item", { key: key, attrs: { command: key } }, [
            _vm._v(_vm._s(v.name))
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }