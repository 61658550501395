var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("publictop", {
        attrs: {
          value: _vm.form,
          "partner-open-id-echo": _vm.merchantId,
          typeName: _vm.typename
        },
        on: {
          "new-add": _vm.newPage,
          searchbtnevent: _vm.searchBtnFun,
          "merchant-id": _vm.searchMerchant
        }
      }),
      _vm._v(" "),
      _c(
        "multipane",
        {
          staticClass: "container-box vertical-panes",
          attrs: { layout: "vertical" },
          on: { paneResize: _vm.changeMenuSize }
        },
        [
          _c(
            "div",
            {
              staticClass: "pane-menu",
              style: { minWidth: "130px", width: "240px" }
            },
            [
              _c(
                "div",
                { staticClass: "left-content" },
                [
                  _c("Labelpage", {
                    attrs: {
                      state: _vm.state,
                      state_current: _vm.state_current.toString(),
                      isWidthChange: _vm.isChangeWidth
                    },
                    on: { "state-currents": _vm.stateCurrents }
                  }),
                  _vm._v(" "),
                  _c("videoClass", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.merchantId,
                        expression: "merchantId"
                      }
                    ],
                    ref: "albumCatalog",
                    attrs: { type: _vm.resourceType, routname: _vm.typename },
                    on: { "check-event": _vm.classChange }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("multipane-resizer", {
            staticClass: "resize-split-bg iconfont icon-tuozhuaidaxiao"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pane", style: { flexGrow: 1 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                staticClass: "right-content"
              },
              [
                _c("table-list", {
                  ref: "tableList",
                  staticClass: "album-list-box",
                  attrs: {
                    list: _vm.lists,
                    "copy-btn-disabled": _vm.copyBtnDisabled,
                    reason: _vm.reason,
                    "status-val-name": "state",
                    "list-query": _vm.listQuery,
                    "resource-type-id": _vm.resourceType,
                    unPublish: _vm.batchUnpublish,
                    handleDel: _vm.deleteAlbum,
                    passObj: _vm.passObj,
                    refuseObj: _vm.refuseObj,
                    objectRestore: _vm.handleAlbumRestore,
                    handleCancelHide: _vm.handleCancelAlbumHide,
                    handleHide: _vm.handleAlbumHide,
                    handleHaltSale: _vm.handleHaltSale,
                    handleSale: _vm.handleSale,
                    saveResourceEncode: _vm.updateAlbumEncode,
                    setSequence: _vm.setSequence,
                    setRecommend: _vm.setRecommend,
                    cancelRecommend: _vm.cancelRecommend
                  },
                  on: {
                    showPage: _vm.newPage,
                    addObject: function($event) {
                      return _vm.newPage()
                    },
                    selectObjs: _vm.selectObjs
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(album) {
                        return _c("div", { staticClass: "table-img" }, [
                          album.cover
                            ? _c("img", {
                                staticClass: "object-cover-img",
                                attrs: {
                                  src: _vm.imageUrlFun(album.cover),
                                  alt: ""
                                }
                              })
                            : _c("img", {
                                staticClass: "object-cover-img",
                                attrs: {
                                  src: require("../../../assets/book_cover.png"),
                                  alt: ""
                                }
                              })
                        ])
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-card-bottom" },
                  [
                    _c("review-btn", {
                      ref: "reviewBtn",
                      attrs: {
                        status: _vm.state_current.toString(),
                        typename: _vm.typename,
                        merchantId: _vm.merchantId,
                        "handle-batch-stop-sale": _vm.batchStopSale,
                        reviewFunction: _vm.reviewAlbum
                      },
                      on: {
                        batchUnpublish: _vm.batchUnpublish,
                        batchRefuse: function($event) {
                          return _vm.handlebatchRefuse(arguments)
                        },
                        batchSetAttribute: function($event) {
                          return _vm.batchSetAttributes(arguments)
                        },
                        batchRecovery: _vm.handleBatchRecovery,
                        batchSaveDraft: _vm.batchSaveAlbumDraft,
                        batchSaveRecycle: _vm.batchSaveAlbumRecycle,
                        batchHiddenOrStopSale: _vm.batchHiddenOrStopSale
                      }
                    }),
                    _vm._v(" "),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total>0"
                        }
                      ],
                      attrs: {
                        total: _vm.total,
                        limit: _vm.listQuery.size,
                        "current-page": _vm.listQuery.page,
                        pageSizes: [10, 20, 30, 50, 100],
                        page: _vm.listQuery.page
                      },
                      on: {
                        "update:limit": function($event) {
                          return _vm.$set(_vm.listQuery, "size", $event)
                        },
                        "update:page": function($event) {
                          return _vm.$set(_vm.listQuery, "page", $event)
                        },
                        pagination: _vm.getList
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }