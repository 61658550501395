"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCacheApi = getCacheApi;
exports.getCacheProduct = getCacheProduct;

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _v = _interopRequireDefault(require("../../organizationLibs/powerCache/v2.0"));

var _user = _interopRequireDefault(require("../../store/modules/user"));

/* eslint-disable no-empty */

/**
 * 从 vuex 获取 user open id
 * @returns {string|undefined} user open id
 */
function getUserOpenId() {
  var uid; // 从 vuex 获取 user open id

  try {
    uid = _user.default.state.info.openId;
  } catch (e) {} // 清洗 user open id


  if (typeof uid !== 'string' || uid.trim().length < 1) {
    uid = undefined;
  } else {
    uid = uid.trim();
  }

  return uid;
}
/**
 * 生成缓存实例名称
 * @param {string} name 实例名称
 * @param {boolean} global 是否是全局缓存
 * @returns {string} 实例名称
 */


function generateCacheName(name) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$global = _ref.global,
      global = _ref$global === void 0 ? false : _ref$global;

  // 如果声明了全局缓存，则直接返回 name
  if (global) {
    return name;
  } // 获取 user open id


  var uid = getUserOpenId(); // 如果未取到 user open id，则使用全局缓存

  if (!uid) {
    return name;
  } // 返回缓存名


  return "".concat(uid, ":").concat(name);
}

var cacheApiInstance;

function getCacheApi() {
  if ((0, _typeof2.default)(cacheApiInstance) !== 'object' || cacheApiInstance === null || !(cacheApiInstance instanceof _v.default)) {
    cacheApiInstance = _v.default.createInstance({
      name: generateCacheName('cache-api')
    });
  }

  return cacheApiInstance;
}

var productEditingInstance;

function getCacheProduct() {
  if ((0, _typeof2.default)(productEditingInstance) !== 'object' || productEditingInstance === null || !(productEditingInstance instanceof _v.default)) {
    productEditingInstance = _v.default.createInstance({
      name: generateCacheName('product')
    });
  }

  return productEditingInstance;
}