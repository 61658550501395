"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'uploadExcel',
  props: ['uploadType'],
  data: function data() {
    return {
      fileList: [],
      file: null
    };
  },
  methods: {
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }

      return isJPG && isLt2M;
    },
    handleChange: function handleChange(file, fileList) {
      if (fileList && fileList.length >= 2) {
        fileList.shift();
      }

      fileList = [file];
      this.file = file;
    },
    handleClose: function handleClose() {
      this.$emit('closeUploadExcel');
    },
    submitUpload: function submitUpload() {
      this.$emit('handleUploadExcel', this.file);
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    }
  }
};
exports.default = _default;