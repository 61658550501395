"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLinkList = getLinkList;
exports.getLinkDetail = getLinkDetail;
exports.saveLink = saveLink;
exports.updateLink = updateLink;
exports.delLink = delLink;
exports.redirectLink = redirectLink;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API));
/**
 * 查询短连接列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getLinkList(params) {
  return request.get('/short-link/admin/link/list', {
    params: params
  });
}
/**
 * 获取短连接详情
 * @param {String} id
 * @returns {Promise<*>}
 */


function getLinkDetail(id) {
  return request.get("/short-link/".concat(id));
}
/**
 * 保存短连接(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveLink(data) {
  return request.post('/short-link/admin/link/save', data);
}
/**
 * 通过id修改短连接
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateLink(id, data) {
  return request.patch("/short-link/admin/link/update/".concat(id), data);
}
/**
 * 删除
 * @param {String} id
 * @returns {Promise<*>}
 */


function delLink(id) {
  return request.delete("/short-link/admin/link/delete/".concat(id));
}
/**
 * 删除
 * @param {String} id
 * @returns {Promise<*>}
 */


function redirectLink(data) {
  return request.get('/short-link/redirect/', {
    params: data
  });
}