"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'priceDiscountChangePricePrice',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 本价格选择结果
    min: {
      type: Number,
      default: 0
    },
    // 最小值
    label: {
      type: String,
      default: '元'
    },
    // 右侧对应的标签，例如元、%
    precision: {
      type: Number,
      default: 2
    } // 小数点位数

  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  }
};
exports.default = _default2;