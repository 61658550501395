var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-box" }, [
    _c(
      "div",
      {
        class: [
          "page-left",
          "page-sequence",
          _vm.selectedPageId === _vm.pageItem.id ? "selected-txt" : ""
        ]
      },
      [_vm._v(_vm._s(_vm.pageItem.sequence))]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "page-middle",
        class: { selected: _vm.selectedPageId === _vm.pageItem.id },
        on: {
          click: function($event) {
            return _vm.selectPage(_vm.pageItem, _vm.pageIndex)
          }
        }
      },
      [
        _c("el-image", {
          staticClass: "page-pic",
          attrs: { src: _vm.getThumbImg(_vm.pageItem.imgUrl), lazy: "" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "page-right" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedPageId === _vm.pageItem.id && _vm.canEdit,
              expression: "selectedPageId===pageItem.id&&canEdit"
            }
          ],
          staticClass: "page-operation"
        },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "显示或隐藏",
                placement: "right"
              }
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.hideShowPage(_vm.pageItem, _vm.pageIndex)
                    }
                  }
                },
                [
                  !_vm.pageItem.hidden
                    ? _c("i", { staticClass: "operation-icon el-icon-view" })
                    : _c("svg-icon", {
                        staticClass: "operation-icon",
                        attrs: { "icon-class": "view-off" }
                      })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", content: "插入书页", placement: "right" }
            },
            [
              _c(
                "el-popover",
                {
                  ref: "popover-" + _vm.pageItem.id,
                  attrs: { placement: "right", width: "100" },
                  model: {
                    value: _vm.pagePopoverVisible[_vm.pageIndex],
                    callback: function($$v) {
                      _vm.$set(_vm.pagePopoverVisible, _vm.pageIndex, $$v)
                    },
                    expression: "pagePopoverVisible[pageIndex]"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left", margin: "0" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            height: "25px",
                            "line-height": "25px",
                            color: "rgba(96,98,102,100)",
                            "font-size": "14px",
                            "text-align": "left",
                            cursor: "pointer"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.insertPage(_vm.pageItem, _vm.pageIndex)
                            }
                          }
                        },
                        [_vm._v("\n              图片\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            height: "25px",
                            "line-height": "25px",
                            color: "rgba(96,98,102,100)",
                            "font-size": "14px",
                            "text-align": "left",
                            cursor: "pointer"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.insertCommentPage(
                                _vm.pageItem,
                                _vm.pageIndex
                              )
                            }
                          }
                        },
                        [_vm._v("\n              独立评论页\n            ")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      attrs: { slot: "reference" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.hideShowInsertPopover(_vm.pageIndex)
                        }
                      },
                      slot: "reference"
                    },
                    [
                      _c("i", {
                        staticClass:
                          "operation-icon el-icon-circle-plus-outline"
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", content: "替换书页", placement: "right" }
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.uploadPictures($event)
                    }
                  }
                },
                [_c("i", { staticClass: "operation-icon el-icon-folder" })]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", content: "删除书页", placement: "right" }
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.deleteSelectedPage(_vm.pageItem)
                    }
                  }
                },
                [_c("i", { staticClass: "operation-icon el-icon-delete" })]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }