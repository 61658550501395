var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-wrapper" },
    [
      _c("div", { staticClass: "setting-title-bg" }, [_vm._v("基础设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-con-bg" }, [
        _c(
          "div",
          { staticClass: "setting-table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                staticClass: "setting-table-bg",
                attrs: { data: _vm.settingList }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "分销员级别" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.isAddLevel &&
                          _vm.promotionSettingCode ===
                            scope.row.promotionSettingCode
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.promotionSetting.promotionName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionSetting,
                                          "promotionName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionSetting.promotionName"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.promotionName) +
                                    "\n            "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "100", label: "升级规则" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.settingLevel === 1
                            ? _c("div", [
                                _vm._v(
                                  "\n              推广员默认等级\n            "
                                )
                              ])
                            : _c("div", [
                                _vm.isAddLevel &&
                                _vm.promotionSettingCode ===
                                  scope.row.promotionSettingCode
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            staticClass: "divide-check-group",
                                            model: {
                                              value: _vm.distributionList,
                                              callback: function($$v) {
                                                _vm.distributionList = $$v
                                              },
                                              expression: "distributionList"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "1" } },
                                              [
                                                _vm._v("累计直销客户"),
                                                _c("el-input", {
                                                  staticClass: "txt-input",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      _vm.promotionSetting
                                                        .distributionCustomer,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.promotionSetting,
                                                        "distributionCustomer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "promotionSetting.distributionCustomer"
                                                  }
                                                }),
                                                _vm._v("人")
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "2" } },
                                              [
                                                _vm._v("累计佣金金额"),
                                                _c("el-input", {
                                                  staticClass: "txt-input",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      _vm.promotionSetting
                                                        .distributionPrice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.promotionSetting,
                                                        "distributionPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "promotionSetting.distributionPrice"
                                                  }
                                                }),
                                                _vm._v("元")
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "3" } },
                                              [
                                                _vm._v("累计邀请分销员"),
                                                _c("el-input", {
                                                  staticClass: "txt-input",
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      _vm.promotionSetting
                                                        .distributionInvitation,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.promotionSetting,
                                                        "distributionInvitation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "promotionSetting.distributionInvitation"
                                                  }
                                                }),
                                                _vm._v("人")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "setting-info-bg" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "setting-info-client"
                                            },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  disabled: "",
                                                  checked:
                                                    scope.row
                                                      .distributionCustomer > 0
                                                }
                                              }),
                                              _vm._v(
                                                "\n                    累计直销客户"
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "setting-info-num"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .distributionCustomer
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v("人\n                  ")
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "setting-info-client"
                                            },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  disabled: "",
                                                  checked:
                                                    scope.row
                                                      .distributionPrice > 0
                                                }
                                              }),
                                              _vm._v(
                                                "\n                    累计佣金金额"
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "setting-info-num"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .distributionPrice
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" 元\n                  ")
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "setting-info-client"
                                            },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  disabled: "",
                                                  checked:
                                                    scope.row
                                                      .distributionInvitation >
                                                    0
                                                }
                                              }),
                                              _vm._v(
                                                "\n                    累计邀请分销员"
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "setting-info-num"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .distributionInvitation
                                                    ) + "人"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  {
                    attrs: { width: "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.isAddLevel &&
                            _vm.promotionSettingCode ===
                              scope.row.promotionSettingCode
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      staticClass: "txt-divide",
                                      attrs: {
                                        min: 1,
                                        max: 50,
                                        "controls-position": "right",
                                        size: "mini"
                                      },
                                      model: {
                                        value:
                                          _vm.promotionSetting.oneCommission,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promotionSetting,
                                            "oneCommission",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promotionSetting.oneCommission"
                                      }
                                    }),
                                    _vm._v("%\n            ")
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.oneCommission) +
                                      "%\n            "
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("span", [_vm._v("一级返佣")]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "title-tip",
                            attrs: {
                              effect: "dark",
                              content: "一级折扣最高不得高于50%",
                              placement: "top"
                            }
                          },
                          [_c("i", { staticClass: "el-icon-info" })]
                        )
                      ],
                      1
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v("二级返佣")]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "title-tip",
                              attrs: {
                                effect: "dark",
                                content: "二级折扣最高不得高于一级折扣比例",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-info" })]
                          )
                        ]
                      }
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.isAddLevel &&
                          _vm.promotionSettingCode ===
                            scope.row.promotionSettingCode
                            ? _c(
                                "div",
                                [
                                  _c("el-input-number", {
                                    staticClass: "txt-divide",
                                    attrs: {
                                      min: 1,
                                      max: 100,
                                      "controls-position": "right",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.promotionSetting.twoCommission,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionSetting,
                                          "twoCommission",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionSetting.twoCommission"
                                    }
                                  }),
                                  _vm._v("%\n            ")
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.twoCommission) +
                                    "%\n            "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v("三级返佣")]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "title-tip",
                              attrs: {
                                effect: "dark",
                                content: "三级折扣最高不得高于二级折扣比例",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-info" })]
                          )
                        ]
                      }
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.isAddLevel &&
                          _vm.promotionSettingCode ===
                            scope.row.promotionSettingCode
                            ? _c(
                                "div",
                                [
                                  _c("el-input-number", {
                                    staticClass: "txt-divide",
                                    attrs: {
                                      min: 1,
                                      max: 100,
                                      "controls-position": "right",
                                      size: "mini"
                                    },
                                    model: {
                                      value:
                                        _vm.promotionSetting.threeCommission,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionSetting,
                                          "threeCommission",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionSetting.threeCommission"
                                    }
                                  }),
                                  _vm._v("%\n            ")
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.threeCommission) +
                                    "%\n            "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v("推广折扣")]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "title-tip",
                              attrs: {
                                effect: "dark",
                                content: "该推广员推广商品时，客户所享受折扣",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-info" })]
                          )
                        ]
                      }
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.isAddLevel &&
                          _vm.promotionSettingCode ===
                            scope.row.promotionSettingCode
                            ? _c(
                                "div",
                                [
                                  _c("el-input-number", {
                                    staticClass: "txt-divide",
                                    attrs: {
                                      size: "mini",
                                      min: 0,
                                      precision: 1,
                                      step: 0.1,
                                      max: 10,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value:
                                        _vm.promotionSetting.promotionDiscount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promotionSetting,
                                          "promotionDiscount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "promotionSetting.promotionDiscount"
                                    }
                                  }),
                                  _vm._v("\n              折\n            ")
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.promotionDiscount / 10) +
                                    " 折\n            "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "100", label: "操作", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.isAddLevel &&
                          _vm.promotionSettingCode ===
                            scope.row.promotionSettingCode
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveLevel(
                                            scope.row.promotionSettingCode,
                                            scope.row,
                                            scope.$index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.cancelLevel }
                                    },
                                    [_vm._v("取消")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editLevel(
                                            scope.row.promotionSettingCode
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  scope.row.settingLevel !== 1
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-del",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.delLevel(
                                                scope.row.promotionSettingCode
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-add-bg" },
          [
            _c(
              "el-button",
              { attrs: { type: "text" }, on: { click: _vm.addLevel } },
              [_vm._v("添加级别")]
            ),
            _vm._v(" "),
            _vm._m(0)
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-title-bg" }, [_vm._v("客户关系")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-con-bg" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableRelationLoading,
                expression: "tableRelationLoading"
              }
            ],
            staticClass: "setting-relation-list"
          },
          _vm._l(_vm.settingRelationList, function(relation) {
            return _c("div", { staticClass: "relationship-bg" }, [
              _c(
                "div",
                {
                  class: [
                    "relation-box",
                    relation.isOpened ? "relation-checked" : ""
                  ],
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeRelation(relation.customerCode)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "relation-tip-bg" },
                    [
                      relation.isOpened
                        ? _c("i", { staticClass: "el-icon-success" })
                        : _c("i", { staticClass: "icon-placeholder" }),
                      _vm._v(" "),
                      _c(
                        "el-popover",
                        {
                          staticClass: "relation-title-tip",
                          attrs: {
                            placement: "right-start",
                            width: "360",
                            trigger: "hover"
                          }
                        },
                        [
                          _c("div", { staticClass: "tip-box" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tip-img-bg",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                relation.img === 1
                                  ? _c("img", {
                                      staticClass: "tip-img",
                                      attrs: {
                                        src: require("../../assets/images/relation-1.png")
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                relation.img === 2
                                  ? _c("img", {
                                      staticClass: "tip-img",
                                      attrs: {
                                        src: require("../../assets/images/relation-2.png")
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                relation.img === 3
                                  ? _c("img", {
                                      staticClass: "tip-img",
                                      attrs: {
                                        src: require("../../assets/images/relation-3.png")
                                      }
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "tip-txt" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(relation.tipTxt) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          relation.img
                            ? _c("i", {
                                staticClass: "el-icon-info",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              })
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "relationship-header" }, [
                    _vm._v(_vm._s(relation.relationshipName))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "relationship-con" },
                    [
                      relation.validityTime === -1
                        ? _c(
                            "div",
                            { staticClass: "relationship-validity_time" },
                            [_vm._v("客户永久有效")]
                          )
                        : _c(
                            "div",
                            { staticClass: "relationship-validity_time" },
                            [
                              _vm._v("客户"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(relation.validityTime) + "天内有效"
                                )
                              ])
                            ]
                          ),
                      _vm._v(" "),
                      relation.robCustomer === -1
                        ? _c("div", { staticClass: "rob-customer" }, [
                            _vm._v("随时抢客")
                          ])
                        : relation.robCustomer === -2
                        ? _c("div", { staticClass: "rob-customer" }, [
                            _vm._v("不允许抢客")
                          ])
                        : _c("div", { staticClass: "rob-customer" }, [
                            _vm._v(
                              "客户" +
                                _vm._s(relation.robCustomer) +
                                "天后会被抢走"
                            )
                          ]),
                      _vm._v(" "),
                      relation.customerCode === "guanxi0004"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handlerToSet(relation)
                                }
                              }
                            },
                            [_vm._v("去设置")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "customer-dialog-bg",
          attrs: { title: "客户绑定设置", visible: _vm.customerBoundVisible },
          on: {
            "update:visible": function($event) {
              _vm.customerBoundVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "customer-dialog-box" },
            [
              _c(
                "el-form",
                { ref: "customerBoundForm", attrs: { "label-width": "220px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广员与客户绑定有效期" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-group-bg",
                          model: {
                            value: _vm.validityTimeRadio,
                            callback: function($$v) {
                              _vm.validityTimeRadio = $$v
                            },
                            expression: "validityTimeRadio"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: -1 } }, [
                            _vm._v("永久有效")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 1 } },
                            [
                              _c("el-input-number", {
                                staticClass: "txt-number-bg",
                                attrs: { "controls-position": "right" },
                                on: {
                                  focus: function($event) {
                                    _vm.validityTimeRadio = 1
                                  }
                                },
                                model: {
                                  value: _vm.validityTimeNumber,
                                  callback: function($$v) {
                                    _vm.validityTimeNumber = $$v
                                  },
                                  expression: "validityTimeNumber"
                                }
                              }),
                              _vm._v("天有效\n            ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期内其他推广员是否可抢客" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-group-bg",
                          model: {
                            value: _vm.robCustomerRadio,
                            callback: function($$v) {
                              _vm.robCustomerRadio = $$v
                            },
                            expression: "robCustomerRadio"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: -1 } }, [
                            _vm._v("随时可抢客")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: -2 } }, [
                            _vm._v("不允许抢客")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 2 } },
                            [
                              _vm._v("保护期\n              "),
                              _c("el-input-number", {
                                staticClass: "txt-number-bg2",
                                attrs: { "controls-position": "right" },
                                on: {
                                  focus: function($event) {
                                    _vm.robCustomerRadio = 2
                                  }
                                },
                                model: {
                                  value: _vm.robCustomerNumber,
                                  callback: function($$v) {
                                    _vm.robCustomerNumber = $$v
                                  },
                                  expression: "robCustomerNumber"
                                }
                              }),
                              _vm._v("天内不可抢客\n            ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelCustomerBound } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.toSet } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-add-remark" }, [
      _c("i", { staticClass: "el-icon-info" }),
      _vm._v(" "),
      _c("span", [_vm._v("选择多个升级条件时，需要同时满足才能升级")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }