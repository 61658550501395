"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _tags = _interopRequireDefault(require("../components/tags"));

var _count = _interopRequireDefault(require("../components/count"));

var _until = _interopRequireDefault(require("../components/until"));

var _special = _interopRequireDefault(require("../components/special"));

var _price = _interopRequireDefault(require("../components/price"));

var _writerList = _interopRequireDefault(require("../components/writerList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'index.vue',
  components: {
    tags: _tags.default,
    count: _count.default,
    until: _until.default,
    special: _special.default,
    price: _price.default,
    writeList: _writerList.default
  },
  data: function data() {
    return {
      subjectCode: 1001,
      subjectName: 'Chinese',
      table_name: '专题',
      tagName: 'genre'
    };
  },
  watch: {},
  created: function created() {
    if (this.$route.query.names && this.$route.query.subjectName) {
      this.tagName = this.$route.query.names;
      this.table_name = '标签';
      this.subjectName = this.$route.query.subjectName;
    }
  },
  methods: {
    changeSubjectCode: function changeSubjectCode(e) {
      if (e.label === '英语') {
        this.subjectCode = 1003;
      } else {
        this.subjectCode = 1001;
      }
    },
    changeTabName: function changeTabName(tab) {
      this.table_name = tab.label;
    }
  }
};
exports.default = _default;