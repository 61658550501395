var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "wrap"
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "header" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.showAddRole }
                  },
                  [_vm._v("新建")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", plain: "" },
                    on: { click: _vm.showBatchAdd }
                  },
                  [_vm._v("批量上传")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-view" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "label-select",
                    attrs: {
                      multiple: "",
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "请选择"
                    },
                    model: {
                      value: _vm.label,
                      callback: function($$v) {
                        _vm.label = $$v
                      },
                      expression: "label"
                    }
                  },
                  _vm._l(_vm.labelList, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item, value: item }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-view" },
                  [
                    _c("el-input", {
                      staticClass: "role-name",
                      attrs: { clearable: "", placeholder: "角色名搜索" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchRole($event)
                        }
                      },
                      model: {
                        value: _vm.roleName,
                        callback: function($$v) {
                          _vm.roleName = $$v
                        },
                        expression: "roleName"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "search-btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.searchRole }
                      },
                      [_vm._v("搜索")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "line-h" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                _vm._l(_vm.roleList, function(item, index) {
                  return _c("el-col", { attrs: { span: "10%" } }, [
                    _c("div", { staticClass: "item-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "item-image-box",
                          on: {
                            mouseover: function($event) {
                              return _vm.itemMouseOver(index)
                            },
                            mouseleave: function($event) {
                              return _vm.itemMouseLeave(index)
                            }
                          }
                        },
                        [
                          _c("el-image", {
                            staticClass: "item-image",
                            attrs: {
                              src: _vm.getImageUrl(item.cover),
                              fit: "fit",
                              lazy: ""
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "item-select-box",
                              on: {
                                click: function($event) {
                                  return _vm.itemClick(index)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "item-select-border" }, [
                                item.isSelect
                                  ? _c("img", {
                                      staticClass: "item-select",
                                      attrs: {
                                        src: require("@/assets/ic_select_on.png")
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          item.isMouseOver
                            ? _c(
                                "div",
                                { staticClass: "item-mask-box" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        placement: "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content"
                                        },
                                        [
                                          _vm._v(
                                            "角色姓名：" +
                                              _vm._s(item.roleName) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                    角色翻译：" +
                                              _vm._s(
                                                item.roleTransName
                                                  ? item.roleTransName
                                                  : "--"
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                    标签：" +
                                              _vm._s(
                                                item.tags ? item.tags : ""
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "mask-info" }, [
                                        _vm._v("!")
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-search icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.showPreviewDialog(item)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-edit icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.editRole(item)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-delete icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteRole(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item-title",
                          attrs: { title: item.roleName }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.roleName) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line-h" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("el-checkbox", {
                staticClass: "footer-check",
                attrs: { indeterminate: _vm.immediateCheck },
                on: { change: _vm.checkChange },
                model: {
                  value: _vm.allCheck,
                  callback: function($$v) {
                    _vm.allCheck = $$v
                  },
                  expression: "allCheck"
                }
              }),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  attrs: { "split-button": "", type: "primary" },
                  on: { click: _vm.dropdownClick, command: _vm.dropdownCommand }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.dropdownTitle) + "\n        "
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "0" } }, [
                        _vm._v("批量修改角色")
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { command: "1" } }, [
                        _vm._v("批量删除角色")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("add-role-view", {
            attrs: {
              visible: _vm.addRoleShow,
              roleItem: _vm.roleItem,
              partnerOpenId: _vm.partnerId,
              "label-list": _vm.labelList
            },
            on: {
              "update:visible": function($event) {
                _vm.addRoleShow = $event
              },
              addRoleDialogClose: _vm.addRoleDialogClose
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("batch-add-role", {
        attrs: { "dialog-visible": _vm.batchAddVisible },
        on: {
          batchAddRoleDialogClose: _vm.batchAddRoleDialogClose,
          batchAddRoleChangeState: _vm.batchAddRoleChangeState
        }
      }),
      _vm._v(" "),
      _c("batch-add-role2", {
        attrs: {
          "dialog-visible": _vm.batchAddVisible2,
          "label-list": _vm.labelList,
          "image-list": _vm.imageList,
          type: _vm.addRoleType
        },
        on: { batchAddRoleDialogClose: _vm.batchAddRoleDialogClose }
      }),
      _vm._v(" "),
      _vm.showPreview && _vm.imageValue
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrl_preview(_vm.imageValue)],
              "z-index": "9999"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }