var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.operateUserOpenId === _vm.selectUserOpenId
      ? _c(
          "div",
          { staticClass: "select-user-wrapper" },
          [
            _c(
              "div",
              { staticClass: "common-flex" },
              [
                _c("user-info", {
                  attrs: { userInfo: _vm.userData, isShowTooltip: false }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "operate-time" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("parseTime")(_vm.createdAt, "{y}-{m}-{d}  {h}:{i}")
                    )
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "admin-user-wrapper" },
          [
            _c(
              "div",
              { staticClass: "common-flex admin-info" },
              [
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("beautifulTextFilter")(_vm.operateUserInfo.name)
                    )
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "operate-time" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("parseTime")(_vm.createdAt, "{y}-{m}-{d}  {h}:{i}")
                    )
                  )
                ]),
                _vm._v(" "),
                _vm.operateUserInfo && _vm.operateUserInfo.cover
                  ? _c(
                      "el-image",
                      {
                        staticClass: "operate-user-img",
                        attrs: {
                          src: _vm.operateUserInfo.cover,
                          alt: "",
                          fit: "cover"
                        }
                      },
                      [
                        _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                          _c("img", {
                            staticClass: "operate-user-img",
                            attrs: { src: require("@/assets/book_cover.png") }
                          })
                        ])
                      ]
                    )
                  : _c("img", {
                      staticClass: "operate-user-img",
                      attrs: { src: require("@/assets/book_cover.png") }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }