var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "grading-list-layout"
    },
    [
      _c("product-header", {
        attrs: { "show-resource-type": _vm.showResourceType },
        on: {
          "search-changed": _vm.updateData,
          "initiative-search-changed": _vm.searchChanged
        },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function() {
                return [_vm._t("header-left")]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.queryProxy,
          callback: function($$v) {
            _vm.queryProxy = $$v
          },
          expression: "queryProxy"
        }
      }),
      _vm._v(" "),
      _c("product-content", {
        ref: "table",
        attrs: {
          data: _vm.data,
          height: _vm.contentHeight,
          select: _vm.selectProxy,
          "select-type": _vm.selectTypeProxy,
          "price-hidden": _vm.priceHidden,
          "show-whole-low-price": _vm.showWholeLowPrice,
          "show-store-low-price": _vm.showStoreLowPrice
        },
        on: {
          "update:select": function($event) {
            _vm.selectProxy = $event
          },
          "update:selectType": function($event) {
            _vm.selectTypeProxy = $event
          },
          "update:select-type": function($event) {
            _vm.selectTypeProxy = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "additional",
              fn: function() {
                return [
                  _c("editable-table-column", {
                    attrs: {
                      fixed: "right",
                      width: "150",
                      label: "进货价",
                      prop: "stockPrice",
                      "key-name": "productOpenId",
                      "on-update": _vm.onUpdate,
                      "formatter-display": _vm.discountFormatter,
                      "formatter-get": _vm.discountFormatterGet,
                      "formatter-set": _vm.discountFormatterSet
                    },
                    on: { "update-data": _vm.updateData }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "action",
              fn: function() {
                return [_vm._t("content-action")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("product-footer", {
        attrs: { selectType: _vm.selectType, total: _vm.total },
        on: { "page-changed": _vm.updateData },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function() {
                return [_vm._t("footer-left")]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.queryProxy,
          callback: function($$v) {
            _vm.queryProxy = $$v
          },
          expression: "queryProxy"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }