"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var statusOptions = [{
  key: '',
  btn_name: '全部'
}, {
  key: '1',
  btn_name: '待审'
}, {
  key: '2',
  btn_name: '已通过'
}, {
  key: '3',
  btn_name: '未通过'
}];
var _default = {
  data: function data() {
    return {
      statusOptions: statusOptions,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      tableData: [],
      currentPage: 1
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875".concat(val));
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
    }
  }
};
exports.default = _default;