var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userListWrap" },
    [
      _c(
        "div",
        { staticClass: "teacher-btn justify_content_between" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.showAddDia }
            },
            [_vm._v("新建")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "display_flex align_items " },
            [
              _c("el-input", {
                staticClass: "searchText",
                attrs: { placeholder: "搜索用户昵称/手机号" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchContent($event)
                  }
                },
                model: {
                  value: _vm.query.keyword,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.query,
                      "keyword",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "query.keyword"
                }
              }),
              _vm._v(" "),
              _c(
                "search-btn",
                {
                  staticClass: "searchContent",
                  on: { searchContent: _vm.searchContent }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.query, "label-width": "100px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              size: "mini",
                              "picker-options": _vm.pickerOptions,
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              align: "right",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            on: {
                              change: function($event) {
                                return _vm.getRegisterDate(_vm.registerDate)
                              }
                            },
                            model: {
                              value: _vm.registerDate,
                              callback: function($$v) {
                                _vm.registerDate = $$v
                              },
                              expression: "registerDate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册渠道" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                size: "mini"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.getlistApiinPurchs(
                                    _vm.query.productOpenId
                                  )
                                }
                              },
                              model: {
                                value: _vm.query.productOpenId,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "productOpenId", $$v)
                                },
                                expression: "query.productOpenId"
                              }
                            },
                            _vm._l(_vm.registrationchannels, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.title, value: item.openId }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "登录时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              size: "mini",
                              "picker-options": _vm.pickerOptions,
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              align: "right",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            on: {
                              change: function($event) {
                                return _vm.getloginTime(_vm.loginTime)
                              }
                            },
                            model: {
                              value: _vm.loginTime,
                              callback: function($$v) {
                                _vm.loginTime = $$v
                              },
                              expression: "loginTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "VIP截止日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              size: "mini",
                              "picker-options": _vm.pickerOptions,
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              align: "right",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            on: {
                              change: function($event) {
                                return _vm.getvipTime(_vm.vipTime)
                              }
                            },
                            model: {
                              value: _vm.vipTime,
                              callback: function($$v) {
                                _vm.vipTime = $$v
                              },
                              expression: "vipTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "消费金额" } }, [
                        _c(
                          "div",
                          [
                            _c("el-input-number", {
                              attrs: {
                                size: "mini",
                                precision: 0,
                                placeholder: "金额下限",
                                min: 0,
                                "controls-position": "right"
                              },
                              model: {
                                value: _vm.minConsumeAmount,
                                callback: function($$v) {
                                  _vm.minConsumeAmount = $$v
                                },
                                expression: "minConsumeAmount"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticStyle: { padding: "0 5px" } }, [
                              _vm._v("至")
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: {
                                size: "mini",
                                precision: 0,
                                placeholder: "金额上限",
                                min: 0,
                                "controls-position": "right"
                              },
                              model: {
                                value: _vm.maxConsumeAmount,
                                callback: function($$v) {
                                  _vm.maxConsumeAmount = $$v
                                },
                                expression: "maxConsumeAmount"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户标签" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                size: "mini",
                                clearable: "",
                                placeholder: "请选择"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleBiaoqian(_vm.query.tagId)
                                }
                              },
                              model: {
                                value: _vm.query.tagId,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "tagId", $$v)
                                },
                                expression: "query.tagId"
                              }
                            },
                            _vm._l(_vm.UserTagList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.tagName, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户等级" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                size: "mini",
                                clearable: "",
                                placeholder: "请选择"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleDengji(_vm.query.level)
                                }
                              },
                              model: {
                                value: _vm.query.level,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "level", $$v)
                                },
                                expression: "query.level"
                              }
                            },
                            _vm._l(_vm.dengjiList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.title, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: { change: _vm.handleBtnCheck },
                              model: {
                                value: _vm.query.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "status", $$v)
                                },
                                expression: "query.status"
                              }
                            },
                            _vm._l(_vm.button_options, function(item) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    label: item.value
                                  }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "VIP用户" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: { change: _vm.viphandleBtnCheck },
                              model: {
                                value: _vm.query.vipstatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "vipstatus", $$v)
                                },
                                expression: "query.vipstatus"
                              }
                            },
                            _vm._l(_vm.button_optionsVip, function(item) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    label: item.value
                                  }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "teacher-case",
          attrs: { type: "border-card" }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "default-sort": { prop: "consumeAmount", order: "descending" }
              },
              on: { "sort-change": _vm.sortChange }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "用户", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { width: "fit-content" } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "right-start"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "1.5" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "用户ID：" +
                                          _vm._s(scope.row.openId) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { cursor: "pointer" } },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            on: {
                                              click: function($event) {
                                                return _vm.copyOpenId(
                                                  scope.row.openId,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "VIP截止期：" +
                                          _vm._s(
                                            _vm.isNull(scope.row.vipExpiredTime)
                                          )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("用户状态：\n                  "),
                                      !scope.row.deletedAt
                                        ? _c("span", [_vm._v("正常")])
                                        : _c("span", [_vm._v("已禁用")])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "commonFlex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "userCoverPo" },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "userCover",
                                          attrs: {
                                            src: scope.row.cover,
                                            alt: "",
                                            fit: "cover"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error"
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "userCover",
                                                attrs: {
                                                  src: require("@/assets/book_cover.png")
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.isVipUser(scope.row.vipExpiredTime)
                                        ? _c("img", {
                                            staticClass: "vipSty",
                                            attrs: {
                                              src: require("@/assets/images/VIP.png"),
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !scope.row.deletedAt
                                        ? _c("div", {
                                            staticClass: "normalSty"
                                          })
                                        : _c("div", {
                                            staticClass: "disableSty"
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.isNull(scope.row.nickName)
                                          ) +
                                          "\n                    "
                                      ),
                                      scope.row.level === 1
                                        ? _c("span", [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/user/lv1.png"),
                                                alt: ""
                                              }
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.level === 2
                                        ? _c("span", [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/user/lv2.png"),
                                                alt: ""
                                              }
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.level === 3
                                        ? _c("span", [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/user/lv3.png"),
                                                alt: ""
                                              }
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.level === 4
                                        ? _c("span", [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/user/lv4.png"),
                                                alt: ""
                                              }
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.identity
                                        ? _c(
                                            "span",
                                            [
                                              scope.row.identity === "教师"
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "success"
                                                      }
                                                    },
                                                    [_vm._v("认证教师")]
                                                  )
                                                : _c(
                                                    "el-tag",
                                                    { attrs: { size: "mini" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.identity
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "smallFontSize" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.showval(
                                                  scope.row.sectionCode,
                                                  _vm.SyncSectionData
                                                )
                                              )
                                            ) +
                                            "/\n                    " +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.showval(
                                                  scope.row.gradeCode,
                                                  _vm.allSyncGrade
                                                )
                                              )
                                            ) +
                                            "/\n                    " +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.showval(
                                                  scope.row.volumeCode,
                                                  _vm.volumeCodetion
                                                )
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "累计消费金额",
                  width: "300",
                  prop: "consumeAmount",
                  sortable: "custom"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.consumeAmount
                          ? _c("div", [
                              _vm._v(
                                "\n            ¥" +
                                  _vm._s(
                                    _vm.numFormat(
                                      (scope.row.consumeAmount / 100).toFixed(2)
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("div", [_vm._v("¥0")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户标签", width: "auto", "min-width": "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          scope.row.tagList && scope.row.tagList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "tagList",
                                  staticStyle: { display: "inline-flex" }
                                },
                                [
                                  scope.row.tagList.length < 6
                                    ? _c(
                                        "div",
                                        _vm._l(scope.row.tagList, function(
                                          tagItem,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticStyle: {
                                                display: "inline-flex",
                                                "margin-right": "5px"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-tag",
                                                {
                                                  class: _vm.showclassName(
                                                    scope.row.tagColorMap[
                                                      tagItem
                                                    ]
                                                  ),
                                                  attrs: {
                                                    size: "mini",
                                                    closable: false,
                                                    type: _vm.showType(
                                                      scope.row.tagColorMap[
                                                        tagItem
                                                      ]
                                                    )
                                                  }
                                                },
                                                [_vm._v(_vm._s(tagItem))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-collapse",
                                            {
                                              staticStyle: {
                                                display: "inline-flex"
                                              },
                                              attrs: { accordion: "" }
                                            },
                                            [
                                              _c("el-collapse-item", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex"
                                                    },
                                                    attrs: { slot: "title" },
                                                    slot: "title"
                                                  },
                                                  _vm._l(
                                                    scope.row.tagList.slice(
                                                      0,
                                                      5
                                                    ),
                                                    function(tagItem, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            "margin-right":
                                                              "5px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              class: _vm.showclassName(
                                                                scope.row
                                                                  .tagColorMap[
                                                                  tagItem
                                                                ]
                                                              ),
                                                              attrs: {
                                                                size: "mini",
                                                                closable: false,
                                                                type: _vm.showType(
                                                                  scope.row
                                                                    .tagColorMap[
                                                                    tagItem
                                                                  ]
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(tagItem)
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex"
                                                    }
                                                  },
                                                  _vm._l(
                                                    scope.row.tagList.slice(
                                                      5,
                                                      scope.row.tagList.length
                                                    ),
                                                    function(tagItem, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            "margin-right":
                                                              "5px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              class: _vm.showclassName(
                                                                scope.row
                                                                  .tagColorMap[
                                                                  tagItem
                                                                ]
                                                              ),
                                                              attrs: {
                                                                size: "mini",
                                                                closable: false,
                                                                type: _vm.showType(
                                                                  scope.row
                                                                    .tagColorMap[
                                                                    tagItem
                                                                  ]
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(tagItem)
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                        width: "210px"
                                      }
                                    },
                                    _vm._l(_vm.UserTagList, function(tagItem) {
                                      return _c(
                                        "div",
                                        {
                                          key: tagItem.id,
                                          staticStyle: {
                                            margin: "0 5px 5px 0",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addUserTagApi(
                                                tagItem,
                                                scope.row.openId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("tags", {
                                            attrs: {
                                              tagColor: tagItem.tagColor,
                                              tagName: tagItem.tagName,
                                              className: tagItem.className,
                                              closable: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "addTags",
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    [_vm._v("添加标签")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: "right",
                  "header-align": "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openDialogUserdetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block t-block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.query.page,
                  "page-sizes": [10, 20],
                  "page-size": _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.totalElements
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.browsingHistoryDia, width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.browsingHistoryDia = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { display: "flex", "align-items": "center" },
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c("div", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v("浏览记录")
              ]),
              _vm._v(" "),
              _vm.currentUserInfo
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-flex",
                        "align-items": "center"
                      }
                    },
                    [
                      _vm.currentUserInfo.cover
                        ? _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  "border-radius": "50%",
                                  width: "24px",
                                  height: "24px"
                                },
                                attrs: {
                                  src: _vm.currentUserInfo.cover,
                                  alt: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { color: "#606266", margin: "0 5px" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.isNull(_vm.currentUserInfo.displayName)
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { color: "#909399", "font-size": "12px" }
                        },
                        [
                          _vm._v(
                            "\n          用户ID: " +
                              _vm._s(_vm.isNull(_vm.currentUserInfo.openId)) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingbrowsingHistory,
                  expression: "loadingbrowsingHistory"
                }
              ],
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: { data: _vm.browsingHistoryData, height: "500px" }
            },
            [
              _c("el-table-column", {
                attrs: { property: "time", label: "日期", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.format(scope.row.time * 1000)) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "pageTitle", label: "标题", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.showVal(scope.row.httpUrl)))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    target: "_blank",
                                    href: scope.row.httpUrl
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.showVal(
                                          scope.row.pageTitle === "undefined"
                                            ? scope.row.systemName
                                            : scope.row.pageTitle
                                        )
                                      ) +
                                      "\n               "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "systemName",
                  label: "系统",
                  align: "right",
                  fixed: "right"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: {
              display: "flex",
              "justify-content": "flex-end",
              "padding-top": "20px"
            },
            attrs: {
              "current-page": _vm.browsingHistoryParams.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.browsingHistoryParams.size,
              total: _vm.totalElementshis,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.handleSizeChangehis,
              "current-change": _vm.handleCurrentChangehis
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("user-info", {
        attrs: {
          visible: _vm.dialogUserdetail,
          propActiveName: _vm.propActiveName,
          userOpenId: _vm.currentuserOpenId,
          userId: _vm.currentuserId
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogUserdetail = $event
          },
          clearuserOpenId: _vm.clearuserOpenId
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加用户",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.addVisible,
            width: "600px",
            "before-close": _vm.dismissAddDia
          },
          on: {
            "update:visible": function($event) {
              _vm.addVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.addLoading,
                  expression: "addLoading"
                }
              ]
            },
            [
              _c(
                "el-form",
                {
                  ref: "formAdd",
                  attrs: { model: _vm.addParams, "label-width": "60px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "姓名",
                        prop: "nickName",
                        rules: [{ required: true, message: "请输入姓名" }]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.addParams.nickName,
                          callback: function($$v) {
                            _vm.$set(_vm.addParams, "nickName", $$v)
                          },
                          expression: "addParams.nickName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "电话",
                        prop: "phone",
                        rules: [{ required: true, message: "请输入电话" }]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入电话" },
                        model: {
                          value: _vm.addParams.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.addParams, "phone", $$v)
                          },
                          expression: "addParams.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dismissAddDia } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addLoading },
                  on: { click: _vm.submitAdd }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }