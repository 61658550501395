var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "card-wrap"
    },
    [
      _c(
        "el-form",
        {
          ref: "ebookInfo",
          attrs: {
            model: _vm.ebookInfo,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      基本信息\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "电子书名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请填写电子书名称",
                      disabled: _vm.allowEdit,
                      maxlength: "100",
                      "show-word-limit": ""
                    },
                    on: { change: _vm.titleChange },
                    model: {
                      value: _vm.ebookInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "title", $$v)
                      },
                      expression: "ebookInfo.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作品编号" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请输入作品编号",
                      disabled: _vm.allowEdit
                    },
                    model: {
                      value: _vm.ebookInfo.resourceEncode,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "resourceEncode", $$v)
                      },
                      expression: "ebookInfo.resourceEncode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电子书介绍" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请输入电子书介绍",
                      disabled: _vm.allowEdit,
                      type: "textarea"
                    },
                    model: {
                      value: _vm.ebookInfo.introduction,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "introduction", $$v)
                      },
                      expression: "ebookInfo.introduction"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电子书封面", prop: "cover" } },
                [
                  _c("cover-upload", {
                    attrs: {
                      path: _vm.path,
                      "partner-open-id": _vm.partnerOpenId,
                      coverarr: _vm.coverArr,
                      covertext: _vm.coverText,
                      type: _vm.E_BOOK_ID.toString(),
                      disabledEdit: _vm.allowEdit
                    },
                    on: { deletecover: _vm.deletecoverFun },
                    model: {
                      value: _vm.ebookInfo.cover,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "cover", $$v)
                      },
                      expression: "ebookInfo.cover"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.ebookInfo.contentWithoutCover,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ebookInfo,
                                "contentWithoutCover",
                                $$v
                              )
                            },
                            expression: "ebookInfo.contentWithoutCover"
                          }
                        },
                        [_vm._v("内文不带封面")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "资源文件", prop: "filePath" } },
                [
                  _c(
                    "div",
                    { staticClass: "common-flex" },
                    [
                      _c("single-file-upload", {
                        class: [
                          _vm.ebookInfo.filePath ? "input" : "file-width"
                        ],
                        attrs: {
                          disabled: _vm.allowEdit,
                          isEBook: true,
                          "resource-open-id": _vm.ebookInfo.openId,
                          "resource-title": _vm.ebookInfo.title
                        },
                        on: {
                          fileUploading: _vm.fileUploading,
                          getResult: _vm.getUploader,
                          editFile: _vm.editFile
                        },
                        model: {
                          value: _vm.fileList,
                          callback: function($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "download-margin",
                          model: {
                            value: _vm.ebookInfo.downloadable,
                            callback: function($$v) {
                              _vm.$set(_vm.ebookInfo, "downloadable", $$v)
                            },
                            expression: "ebookInfo.downloadable"
                          }
                        },
                        [_vm._v("可下载")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    staticClass: "input",
                    model: {
                      value: _vm.ebookInfo.filePath,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "filePath", $$v)
                      },
                      expression: "ebookInfo.filePath"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      作品属性\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "同步" } },
                [
                  _c("sync-view", {
                    staticClass: "input",
                    attrs: { isDisabled: _vm.allowEdit },
                    model: {
                      value: _vm.ebookInfo.tempCode,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "tempCode", $$v)
                      },
                      expression: "ebookInfo.tempCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "types" } },
                [
                  _c("works", {
                    ref: "worksView",
                    staticClass: "input",
                    attrs: { isDisabled: _vm.allowEdit, isebook: true },
                    on: { "add-classtion": _vm.addClass }
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    staticClass: "input",
                    attrs: { value: _vm.ebookInfo.types }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "版次" } },
                [
                  _c("edition-box", {
                    staticClass: "input",
                    attrs: { isDisabled: _vm.allowEdit },
                    model: {
                      value: _vm.ebookInfo.periodCode,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "periodCode", $$v)
                      },
                      expression: "ebookInfo.periodCode"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    staticClass: "input",
                    model: {
                      value: _vm.ebookInfo.periodCode,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "periodCode", $$v)
                      },
                      expression: "ebookInfo.periodCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "property-box" },
                [
                  _c("el-collapse-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowProperty,
                            expression: "isShowProperty"
                          }
                        ]
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "地区" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "input",
                                attrs: {
                                  disabled: _vm.allowEdit,
                                  multiple: "",
                                  filterable: "",
                                  "default-first-option": "",
                                  placeholder: "请输入或选择地区"
                                },
                                model: {
                                  value: _vm.areaValue,
                                  callback: function($$v) {
                                    _vm.areaValue = $$v
                                  },
                                  expression: "areaValue"
                                }
                              },
                              _vm._l(_vm.areaList, function(item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "标签" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "input",
                                attrs: {
                                  disabled: _vm.allowEdit,
                                  multiple: "",
                                  filterable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "请输入或选择商品标签"
                                },
                                on: { change: _vm.changeTags },
                                model: {
                                  value: _vm.tagValue,
                                  callback: function($$v) {
                                    _vm.tagValue = $$v
                                  },
                                  expression: "tagValue"
                                }
                              },
                              _vm._l(_vm.tagList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    class: [
                      "property-item-arrow",
                      _vm.isShowProperty
                        ? "el-icon-arrow-up"
                        : "el-icon-arrow-down"
                    ],
                    on: {
                      click: function($event) {
                        _vm.isShowProperty = !_vm.isShowProperty
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      分享设置\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分享标题", prop: "shareTitle" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请填写电子书名称",
                      disabled: _vm.allowEdit,
                      maxlength: "100",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.ebookInfo.shareTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "shareTitle", $$v)
                      },
                      expression: "ebookInfo.shareTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分享简介", prop: "shareProfile" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "850px" },
                    attrs: {
                      disabled: _vm.allowEdit,
                      "popper-class": "my-autocomplete",
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.ebookInfo.shareProfile,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "shareProfile", $$v)
                      },
                      expression: "ebookInfo.shareProfile"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分享图片" } },
                [
                  _c("cover-upload", {
                    attrs: {
                      path: _vm.path,
                      "partner-open-id": _vm.partnerOpenId,
                      coverarr: _vm.shareCoverArr,
                      covertext: _vm.coverText,
                      type: _vm.E_BOOK_ID.toString(),
                      disabledEdit: _vm.allowEdit
                    },
                    on: { deletecover: _vm.deletecoverFunB },
                    model: {
                      value: _vm.ebookInfo.shareImageAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "shareImageAddress", $$v)
                      },
                      expression: "ebookInfo.shareImageAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-btns" },
            [
              _c("el-button", { on: { click: _vm.onCancle } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _vm.allowEdit
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onEdit } },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.allowEdit
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.subLoading },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit(undefined)
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ebookInfo.status !== 2 &&
              _vm.ebookInfo.status !== "2" &&
              !_vm.allowEdit
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.subLoading, type: "success" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit(2)
                        }
                      }
                    },
                    [_vm._v("保存并上架")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }