"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_EditBaseInfo_PriceInput',
  props: {
    value: {
      type: Number,
      default: 0
    },
    orginalPrice: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      amountAfterDiscount: undefined
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return (0, _priceParser.parseDiscount)(this.value);
      },
      set: function set(value) {
        this.$emit('input', (0, _priceParser.parseDiscount)(value));
      }
    },
    orginalPriceParsed: function orginalPriceParsed() {
      return (0, _priceParser.parsePrice)(this.orginalPrice);
    }
  },
  watch: {
    valueProxy: function valueProxy() {
      this.onDiscountUpdate();
    },
    orginalPriceParsed: function orginalPriceParsed() {
      this.onDiscountUpdate();
    }
  },
  mounted: function mounted() {
    this.onDiscountUpdate();
  },
  methods: {
    onDiscountUpdate: function onDiscountUpdate() {
      var price = (0, _priceParser.calcPriceByDiscount)(this.orginalPriceParsed, this.valueProxy);
      this.amountAfterDiscount = (0, _priceParser.priceToYuanFixed)(price);
    },
    onInputUpdate: function onInputUpdate() {
      var price = (0, _priceParser.parsePriceFromYuan)(this.amountAfterDiscount);
      this.valueProxy = (0, _priceParser.calcDiscountByPrice)(this.orginalPriceParsed, price);
    }
  }
};
exports.default = _default;