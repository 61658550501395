var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("original-sync-info", {
    ref: "subjectList",
    attrs: {
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      dataSource: _vm.dataSource,
      size: _vm.size,
      query: _vm.query,
      "label-name": _vm.labelName,
      "value-name": _vm.valueName,
      multiple: _vm.multiple,
      filterable: _vm.filterable,
      remote: _vm.remote,
      "remote-api": _vm.remoteApi,
      "key-prefix": _vm.keyPrefix,
      "label-display": _vm.labelDisplay
    },
    on: { change: _vm.onChange },
    model: {
      value: _vm.valueProxy,
      callback: function($$v) {
        _vm.valueProxy = $$v
      },
      expression: "valueProxy"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }