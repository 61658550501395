var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "outermostLayer navigation-box",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c("div", { staticClass: "navigation-contailer" }, [
        _c(
          "div",
          { staticClass: "navigation-header" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.addVersionBtn }
              },
              [_vm._v("添加")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nav-table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading"
                  }
                ],
                ref: "navigationList",
                staticStyle: { width: "100%" },
                attrs: { height: "100%", data: _vm.tableData }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "sequence", "min-width": "40", label: "序列" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "导航图标" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return scope.row.icon
                            ? [
                                scope.row.icon
                                  ? _c("el-image", {
                                      staticClass: "logo-img",
                                      attrs: {
                                        src: _vm.getImgUrl(scope.row.icon)
                                          ? _vm.getImgUrl(scope.row.icon)
                                          : "",
                                        fit: "cover"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : undefined
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "导航名称" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "subjectNames", label: "学科" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "跳转模式" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getNavigationType(scope.row.linkSetting)
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "系统" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.appProductName
                                  ? scope.row.appProductName
                                  : "--"
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "导航位置" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getNavigationLocation(scope.row.locations)
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "60", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.showPreview(scope.row.linkSetting)
                                }
                              }
                            },
                            [_vm._v("预览")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editVersionBtn(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "removeBtn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.removeAppVersion(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footPage" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.initData
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "navigation-dialog-bg",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.isDialog,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.isDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "versionForm",
              attrs: {
                rules: _vm.navigationFormRules,
                model: _vm.navigationObj,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "导航名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请填写导航名称",
                      maxlength: "45",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.navigationObj.name,
                      callback: function($$v) {
                        _vm.$set(_vm.navigationObj, "name", $$v)
                      },
                      expression: "navigationObj.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "导航图标", prop: "icon" } },
                [
                  _c("cover-upload", {
                    staticClass: "upload-logo",
                    attrs: {
                      path: _vm.path,
                      coverarr: _vm.coverArr,
                      covertext: _vm.coverText,
                      type: _vm.navigationID.toString()
                    },
                    on: { deletecover: _vm.deleteCover },
                    model: {
                      value: _vm.navigationObj.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.navigationObj, "icon", $$v)
                      },
                      expression: "navigationObj.icon"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.navigationObj.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.navigationObj, "icon", $$v)
                      },
                      expression: "navigationObj.icon"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转链接" } },
                [
                  !_vm.link
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              _vm.selectLinkShow = true
                            }
                          }
                        },
                        [_vm._v("选择连接")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.link
                    ? _c("div", { staticClass: "link-box" }, [
                        _vm.link
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.link.display
                                    ? _vm.link.display.title
                                    : "--"
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-sort change-link-icon",
                            on: {
                              click: function($event) {
                                _vm.selectLinkShow = true
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-delete link-icon",
                            on: { click: _vm.delLink }
                          })
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择系统" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sys-bg",
                      attrs: { size: "small", multiple: "" },
                      on: { change: _vm.choseSystem },
                      model: {
                        value: _vm.appProductOpenIds,
                        callback: function($$v) {
                          _vm.appProductOpenIds = $$v
                        },
                        expression: "appProductOpenIds"
                      }
                    },
                    _vm._l(_vm.productList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属页面" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sys-bg",
                      attrs: { size: "small", multiple: "" },
                      on: { change: _vm.choseLocation },
                      model: {
                        value: _vm.locationCodes,
                        callback: function($$v) {
                          _vm.locationCodes = $$v
                        },
                        expression: "locationCodes"
                      }
                    },
                    _vm._l(_vm.pageLinkList, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "学段", prop: "sectionCodes" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sys-bg",
                      attrs: { size: "small", multiple: "" },
                      model: {
                        value: _vm.navigationObj.sectionCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.navigationObj, "sectionCodes", $$v)
                        },
                        expression: "navigationObj.sectionCodes"
                      }
                    },
                    _vm._l(_vm.syncSectionInfo, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "年级", prop: "gradeCodes" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sys-bg",
                      attrs: { size: "small", multiple: "" },
                      model: {
                        value: _vm.navigationObj.gradeCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.navigationObj, "gradeCodes", $$v)
                        },
                        expression: "navigationObj.gradeCodes"
                      }
                    },
                    _vm._l(_vm.syncGrade, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "学科" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sys-bg",
                      attrs: { size: "small", clearable: "" },
                      model: {
                        value: _vm.navigationObj.subjectCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.navigationObj, "subjectCodes", $$v)
                        },
                        expression: "navigationObj.subjectCodes"
                      }
                    },
                    _vm._l(_vm.subjectList, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "阶段", prop: "volumeCodes" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sys-bg",
                      attrs: { size: "small", multiple: "" },
                      model: {
                        value: _vm.navigationObj.volumeCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.navigationObj, "volumeCodes", $$v)
                        },
                        expression: "navigationObj.volumeCodes"
                      }
                    },
                    _vm._l(_vm.syncVolume, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "sequence-wrapper", attrs: { label: "排序" } },
                [
                  _c(
                    "div",
                    { staticClass: "sequence-box" },
                    [
                      _c("el-input-number", {
                        staticClass: "select-sequence",
                        staticStyle: { width: "100px" },
                        attrs: {
                          precision: 0,
                          step: 1,
                          min: 0,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.navigationObj.sequence,
                          callback: function($$v) {
                            _vm.$set(_vm.navigationObj, "sequence", $$v)
                          },
                          expression: "navigationObj.sequence"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.isDialog = false
                                }
                              }
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.submitBtnLoading
                              },
                              on: { click: _vm.saveInfo }
                            },
                            [_vm._v("确 定")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.selectLinkShow },
        on: {
          "update:visible": function($event) {
            _vm.selectLinkShow = $event
          }
        },
        model: {
          value: _vm.link,
          callback: function($$v) {
            _vm.link = $$v
          },
          expression: "link"
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowResourcePreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowResourcePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }