"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPeriodList = getPeriodList;
exports.getItemList = getItemList;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var comRequest = (0, _main.default)(process.env.VUE_APP_TAOZHI_API);

function getPeriodList(str) {
  return comRequest({
    url: "/_Source/Comm_BCHandler.ashx?op=".concat(str),
    method: 'post',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  });
} // bujia


function getItemList(str) {
  return comRequest({
    url: "/_Source/BookHandler.ashx?op=".concat(str),
    method: 'post',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  });
}