var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stroke-order", attrs: { title: _vm.strokeOrder.bsText } },
    [
      _c("img", {
        staticClass: "stroke-order-image",
        attrs: { src: _vm.imageSrc, alt: _vm.strokeOrder.bsText }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "stroke-order-name" }, [
        _vm._v(_vm._s(_vm.strokeOrder.bsText))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }