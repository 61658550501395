var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "slider",
        {
          ref: "slider",
          staticClass: "subjectBox section-tabs",
          attrs: { options: _vm.sliderinit }
        },
        _vm._l(_vm.sectionList, function(item, index) {
          return _c(
            "slideritem",
            {
              key: item.code,
              style: {
                width: item.name.length * 12 + 20 + "px",
                "font-size": "12px"
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "active",
                  class:
                    _vm.query.sectionCode === item.code
                      ? "isActive"
                      : "noActive",
                  on: {
                    click: function($event) {
                      return _vm.handleSectionClick(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.name))]
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "slider",
        {
          ref: "slider",
          staticClass: "subjectBox",
          attrs: { options: _vm.sliderinit }
        },
        _vm._l(_vm.subjectList, function(item, index) {
          return _c(
            "slideritem",
            {
              key: item.code,
              style: {
                width: item.name.length * 12 + 20 + "px",
                "font-size": "12px"
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "active",
                  class:
                    _vm.query.subjectCode === item.code
                      ? "isActive"
                      : "noActive",
                  on: {
                    click: function($event) {
                      return _vm.selectStyle(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.name))]
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.type !== "material"
        ? _c(
            "div",
            { staticClass: "treeBox" },
            [
              _c("el-input", {
                staticStyle: {
                  width: "90%",
                  "margin-left": "5%",
                  "margin-top": "5px"
                },
                attrs: {
                  size: "mini",
                  placeholder: "请输入内容",
                  clearable: ""
                },
                model: {
                  value: _vm.filterText,
                  callback: function($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tree" },
                [
                  _c("el-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.treeLoading,
                        expression: "treeLoading"
                      }
                    ],
                    ref: "tree",
                    attrs: {
                      "node-key": "id",
                      "default-expand-all": _vm.isOpen,
                      data: _vm.pointsList,
                      props: _vm.dataProps,
                      "filter-node-method": _vm.filterNode
                    },
                    on: { "node-click": _vm.handleNodeClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "div",
                              {
                                staticClass: "custom-tree-node",
                                staticStyle: { width: "100%" },
                                on: {
                                  mouseenter: function($event) {
                                    $event.stopPropagation()
                                    return _vm.mouseoverFun(data)
                                  },
                                  mouseleave: function($event) {
                                    $event.stopPropagation()
                                    return _vm.mouseoutFun()
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "treeName",
                                    class:
                                      _vm.dataId === data.id ? "fontStyle" : ""
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(node.label) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      2998357923
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-click-modal": false,
                    title: _vm.treeTitle,
                    visible: _vm.treeVisible,
                    width: "802px"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.treeVisible = $event
                    }
                  }
                },
                [
                  _vm.treeTitle === "添加子知识点"
                    ? _c(
                        "div",
                        { staticClass: "classInput" },
                        [
                          _c("span", [_vm._v("根目录")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "nameInput",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.parentName,
                              callback: function($$v) {
                                _vm.parentName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "parentName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "classInput" },
                    [
                      _c("span", [_vm._v("知识点名称")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "nameInput",
                        model: {
                          value: _vm.className,
                          callback: function($$v) {
                            _vm.className =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "className"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.treeVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }