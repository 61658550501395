"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EndWorkOrder',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleEndClose: function handleEndClose() {
      this.$emit('update:visible', false);
    },
    detailsByPassed: function detailsByPassed() {
      this.$emit('handleResolved', '无需处理');
    },
    detailsResolved: function detailsResolved() {
      this.$emit('handleResolved', '已处理');
    }
  }
};
exports.default = _default;