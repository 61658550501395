var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "detail-material"
    },
    [
      _c("div", { staticClass: "nav" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addImage } },
              [_vm._v("添加图片")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: {
                  click: function($event) {
                    _vm.openBathAdd = true
                  }
                }
              },
              [_vm._v("批量添加")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("el-input", {
              staticClass: "search-input",
              attrs: { clearable: "", placeholder: "搜索关键词" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.search($event)
                }
              },
              model: {
                value: _vm.searchVal,
                callback: function($$v) {
                  _vm.searchVal = $$v
                },
                expression: "searchVal"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.materialList.length > 0
        ? _c(
            "div",
            { staticClass: "material-content" },
            [
              _c(
                "el-checkbox-group",
                {
                  staticClass: "check-flex",
                  on: { change: _vm.changeCheckList },
                  model: {
                    value: _vm.checkList,
                    callback: function($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList"
                  }
                },
                _vm._l(_vm.materialList, function(item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "content-image" },
                    [
                      _c("image-material-item", {
                        attrs: {
                          value: item,
                          "is-show-check": !_vm.isShowManage,
                          "name-loading": _vm.nameLoading,
                          "name-edit": _vm.nameEdit,
                          "delete-loading": _vm.deleteLoading,
                          "delete-visible": _vm.deleteVisible
                        },
                        on: {
                          handelDelete: _vm.handelDelete,
                          submitChangeName: _vm.submitChangeName
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isNoData
        ? _c(
            "div",
            { staticClass: "no-material-data" },
            [
              _c("el-image", {
                staticClass: "no-image",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(
                    "workOrder/no.png",
                    "style/width-limit-512"
                  )
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("暂无素材")])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.isShowManage
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-setting" },
                  on: {
                    click: function($event) {
                      _vm.isShowManage = false
                    }
                  }
                },
                [_vm._v("管理")]
              )
            : _c(
                "div",
                { staticClass: "delete-footer" },
                [
                  _c("el-checkbox", {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "footer-delete-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.bathDelete }
                    },
                    [_vm._v("删除")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "select-number" }, [
                    _vm._v("共" + _vm._s(_vm.checkList.length) + "个")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              ),
          _vm._v(" "),
          _c("pagination", {
            attrs: {
              "page-sizes": [50, 100, 150, 200],
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("add-img-material-dialog", {
        attrs: { visible: _vm.openAdd, "submit-loading": _vm.addLoading },
        on: {
          "update:visible": function($event) {
            _vm.openAdd = $event
          },
          submitImg: _vm.submitImg
        }
      }),
      _vm._v(" "),
      _c("batch-add-img", {
        attrs: {
          visible: _vm.openBathAdd,
          "material-classification-open-id":
            _vm.query.materialClassificationOpenId,
          "partner-open-id": "1"
        },
        on: {
          "update:visible": function($event) {
            _vm.openBathAdd = $event
          },
          refreshList: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }