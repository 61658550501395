var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            title: "服务单关闭原因",
            visible: _vm.visible,
            width: "40%",
            "append-to-body": true,
            "before-close": _vm.beforeClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialog_body" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-form",
                  { ref: "closeSaleAfterForm", attrs: { model: _vm.form } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "adminNotes",
                          rules: [{ required: true, message: "原因不能为空" }]
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请填写服务单关闭原因",
                            rows: 5
                          },
                          model: {
                            value: _vm.form.adminNotes,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "adminNotes", $$v)
                            },
                            expression: "form.adminNotes"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.beforeClose } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }