var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "header box" },
    [
      _c(
        "el-col",
        { staticClass: "header-left-item", attrs: { span: 4 } },
        [
          _c(
            "el-button",
            {
              staticClass: "newly",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function($event) {
                  return _vm.handleNewProduct()
                }
              }
            },
            [_vm._v("新建")]
          ),
          _vm._v(" "),
          _vm.uploadBtnIsShow
            ? _c(
                "el-button",
                {
                  staticClass: "bulk-upload",
                  attrs: { plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.bulkUpload()
                    }
                  }
                },
                [_vm._v("\n      批量上传\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "header-right", attrs: { span: 20 } },
        [
          _vm._t("eBook"),
          _vm._v(" "),
          _c("search", {
            attrs: { "partner-open-id": _vm.partnerOpenId },
            on: { receivepartnerId: _vm.receivepartnerId }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "header-search",
            attrs: {
              placeholder: "搜索作品名称、作品ID",
              "prefix-icon": "el-icon-search",
              clearable: ""
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchcontent($event)
              }
            },
            model: {
              value: _vm.form.searchKey,
              callback: function($$v) {
                _vm.$set(_vm.form, "searchKey", $$v)
              },
              expression: "form.searchKey"
            }
          }),
          _vm._v(" "),
          _c(
            "searchbtn",
            { on: { searchContent: _vm.searchcontent } },
            [
              _c("dialog-filter-panel", {
                attrs: {
                  "is-show-remember": true,
                  isebook: _vm.isebook,
                  isbookcase: _vm.isbookcase,
                  "type-name": _vm.typeName,
                  "show-vid-search": _vm.showVidSearch,
                  "show-test-data": _vm.showTestData,
                  "show-download": _vm.showDownload,
                  isShowArea: true,
                  "show-json-data": _vm.showJsonData
                },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.typeName === "英语会话" && _vm.partnerOpenId
            ? _c(
                "el-dropdown",
                [
                  _c("el-button", {
                    staticClass: "set-table-column",
                    attrs: { plain: "", icon: "el-icon-s-tools" }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.roleManagement($event)
                            }
                          }
                        },
                        [_vm._v("角色管理")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }