var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.multipleCheck
        ? _c("multiple-select-link", {
            attrs: {
              visible: _vm.open,
              "is-not-model": _vm.isNotModel,
              "props-active-name": _vm.propsActiveName,
              "multiple-check": _vm.multipleCheck,
              "is-replace": _vm.isReplace,
              "replace-index": _vm.replaceIndex,
              "show-word": _vm.showWord,
              tool: _vm.tool,
              "is-extend": _vm.isExtend
            },
            on: {
              "update:visible": function($event) {
                _vm.open = $event
              },
              submitLink: _vm.submitLink
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        : _c("radio-select-link", {
            attrs: {
              visible: _vm.open,
              "is-not-model": _vm.isNotModel,
              "audio-book-open-id": _vm.audioBookOpenId,
              "props-active-name": _vm.propsActiveName,
              "partner-open-id": _vm.partnerOpenId,
              "multiple-check": _vm.multipleCheck,
              "show-word": _vm.showWord,
              tool: _vm.tool,
              "is-extend": _vm.isExtend
            },
            on: {
              "update:visible": function($event) {
                _vm.open = $event
              },
              submitLink: _vm.submitLink
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }