var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-stem" },
    [
      _c("div", { staticClass: "nav-info" }, [
        _vm.questionIndex !== undefined
          ? _c("span", [_vm._v("（" + _vm._s(_vm.valIndex + 1) + "）")])
          : _vm._e(),
        _vm._v(" "),
        (_vm.options.showPeriod && _vm.value.periodCode) ||
        (_vm.options.showArea && _vm.value.bookAreaId)
          ? _c("span", { staticClass: "period-area" }, [
              _vm._v("\n      （"),
              _vm.options.showPeriod
                ? _c("span", [_vm._v(_vm._s(_vm.value.periodCode))])
                : _vm._e(),
              _vm.options.showArea
                ? _c("span", [_vm._v(_vm._s(_vm.value.bookAreaId))])
                : _vm._e(),
              _vm._v("）\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.value.examQuestionTypeCode === 1002
          ? _c("span", [_vm._v("（多选题）")])
          : _vm._e(),
        _vm._v(" "),
        _vm.options.showScore && _vm.value.mark
          ? _c("span", [_vm._v("（" + _vm._s(_vm.value.mark) + "分）")])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "stem",
        domProps: { innerHTML: _vm._s(_vm.value.stem) }
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { clear: "both" } }),
      _vm._v(" "),
      _vm.value.images
        ? _c("el-image", {
            staticClass: "stem-img",
            attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value.images) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }