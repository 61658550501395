var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icons" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "icons-dialog",
          attrs: { visible: _vm.dialogIconsVisible, title: "选择图片" },
          on: {
            "update:visible": function($event) {
              _vm.dialogIconsVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialog-tags" }, [
            _c(
              "div",
              {
                class: { selectedTag: _vm.areaId === 1 },
                on: {
                  click: function($event) {
                    return _vm.selectArea(1)
                  }
                }
              },
              [_vm._v("上传")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: { selectedTag: _vm.areaId === 2 },
                on: {
                  click: function($event) {
                    return _vm.selectArea(2)
                  }
                }
              },
              [_vm._v("图库")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: { selectedTag: _vm.areaId === 3 },
                on: {
                  click: function($event) {
                    return _vm.selectArea(3)
                  }
                }
              },
              [_vm._v("历史")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-body" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.areaId === 1 &&
                      _vm.$parent.curAction !== "addGifSection",
                    expression:
                      "areaId===1 && $parent.curAction !== 'addGifSection'"
                  }
                ],
                staticClass: "zone upload-zone",
                on: { click: _vm.uploadIcons }
              },
              [_vm._v("点击上传（支持JPEG/JPG/PNG格式，小于5M）")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.areaId === 1 &&
                      _vm.$parent.curAction === "addGifSection",
                    expression:
                      "areaId===1 && $parent.curAction === 'addGifSection'"
                  }
                ],
                staticClass: "zone upload-zone",
                on: { click: _vm.uploadIcons }
              },
              [_vm._v("点击上传（支持gif格式，小于5M）")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.areaId === 2,
                    expression: "areaId===2"
                  }
                ],
                staticClass: "zone sys-zone"
              },
              _vm._l(_vm.sysIcons, function(icon, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "sys-icon",
                    class: { "selected-icon": icon.id == _vm.SysIconId },
                    on: {
                      click: function($event) {
                        return _vm.selectIcon(icon)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "sys-img",
                      attrs: { src: _vm.getImageUrl(icon.imgUrl) }
                    })
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.areaId === 3,
                    expression: "areaId===3"
                  }
                ],
                staticClass: "zone user-zone"
              },
              _vm._l(_vm.userIcons, function(icon, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "user-icon",
                    on: {
                      click: function($event) {
                        return _vm.selectIcon(icon)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "user-img",
                      class: { "selected-icon": icon.id == _vm.UserIconId },
                      attrs: { src: _vm.getImageUrl(icon.imgUrl) }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "img-des" }, [
                      _vm._v(" " + _vm._s(icon.imgDescription))
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("input", {
              ref: "iconUpload",
              staticClass: "icon-upload",
              attrs: {
                id: "iconUpload",
                multiple: "",
                type: "file",
                accept: "image/*"
              },
              on: { change: _vm.Upload }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelEdit } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.confirmInsert()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }