"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Table_Inventory',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    isUseERP: {
      type: Boolean,
      default: false
    },
    disableCell: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    isUseERPProxy: {
      get: function get() {
        return this.isUseERP;
      },
      set: function set(value) {
        this.$emit('update:isUseERP', value);
      }
    },
    isInputDisable: function isInputDisable() {
      return this.isUseERPProxy || this.disableCell;
    }
  },
  watch: {
    isUseERP: function isUseERP() {
      if (this.isUseERP) {
        this.valueProxy = undefined;
      }
    }
  }
};
exports.default = _default;