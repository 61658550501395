var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading"
              }
            ],
            staticStyle: { width: "100%", overflow: "auto" },
            attrs: { height: "415", data: _vm.tableData, stripe: "" }
          },
          [
            _c("el-table-column", {
              attrs: { width: "auto" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "displayflex" },
                        [
                          _c("el-image", {
                            staticClass: "cover",
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                scope.row.cover,
                                "style/width-limit-128"
                              )
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c(
                                "div",
                                { staticClass: "class-resource-name" },
                                [
                                  _vm._v(
                                    "#" + _vm._s(scope.row.classResourceName)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "displayflex" },
                                [
                                  _c("el-image", {
                                    staticClass: "logo",
                                    attrs: {
                                      fit: "cover",
                                      src: _vm.getImgUrl(
                                        scope.row.partnerLogo,
                                        "style/width-limit-64"
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "resource-title" },
                                    [_vm._v(_vm._s(scope.row.partnerName))]
                                  ),
                                  _vm._v(" "),
                                  scope.row.resourceType === 4
                                    ? _c(
                                        "span",
                                        { staticClass: "resource-title" },
                                        [_vm._v(_vm._s(scope.row.title))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "300" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return scope.row.resourceType === 4
                        ? [
                            _c("div", [
                              _c("div", { staticClass: "small-size" }, [
                                _vm._v("已学习："),
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.resourceCompletedNum))
                                ]),
                                _vm._v("/"),
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.albumContentNums))
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "padding-top": "10px" } },
                                [
                                  _c("el-progress", {
                                    staticStyle: { width: "60%" },
                                    attrs: {
                                      "show-text": false,
                                      status: scope.row.progressStatus,
                                      percentage: scope.row.percentage
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        : undefined
                    }
                  }
                ],
                null,
                true
              )
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "right", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", [
                        _c("div", { staticClass: "study-time" }, [
                          _vm._v(
                            _vm._s(Math.ceil(scope.row.browseDuration / 60)) +
                              " 分钟"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "small-size" }, [
                          _vm._v("累计学习")
                        ])
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("pagination", {
          attrs: {
            total: _vm.totalElements,
            page: _vm.query.page,
            limit: _vm.query.size,
            "current-page": _vm.query.page
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.query, "page", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.query, "size", $event)
            },
            pagination: _vm.getTableData
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }