var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "table-list-wrapper",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c(
        "el-table",
        {
          ref: "multipleList",
          staticClass: "data-list-table",
          attrs: {
            "cell-style": { "vertical-align": "top" },
            data: _vm.listArray,
            "highlight-current-row": true,
            height: "100%",
            width: "100%"
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "row-click": _vm.selectCall
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "24" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "header-align": "left", label: _vm.resourceTitle },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "course-item-box" },
                      [
                        _c(
                          "div",
                          { staticClass: "course-content-bg" },
                          [
                            _c("resource-simple", {
                              attrs: {
                                resourceObj: scope.row,
                                saveResourceEncode: _vm.saveResourceEncode,
                                showPage: _vm.showPage,
                                handlerFinishClassFun: _vm.handlerFinishClass
                              }
                            }),
                            _vm._v(" "),
                            _c("resource-operation", {
                              attrs: {
                                resourceObj: scope.row,
                                passObjFun: _vm.passObjFun,
                                cancelRecommend: _vm.cancelRecommend,
                                showPage: _vm.showPage,
                                handleHideFun: _vm.handleHideFun,
                                handleCancelHide: _vm.handleCancelHide,
                                handleHaltSale: _vm.handleHaltSale,
                                handleCopyFun: _vm.handleCopy,
                                handleDelFun: _vm.handleDel,
                                objectRestoreFun: _vm.objectRestoreFun,
                                getUploader: _vm.getUploader,
                                getReplaceUploader: _vm.getReplaceUploader
                              },
                              on: {
                                handleSalePopup: _vm.handleSalePopup,
                                initData: _vm.initPopoverData,
                                handleRefuseObj: _vm.handleRefuseObj,
                                showSharePreview: _vm.showSharePreview,
                                handleSetSequence: _vm.handleSetSequence,
                                handleRecommend: _vm.handleRecommend,
                                handleLiveShare: _vm.handleLiveShare,
                                handleLiveStatistic: _vm.handleLiveStatistic,
                                showOperationPreview: _vm.showOperationPreview,
                                handleGetCover: _vm.handleGetCover,
                                unPublish: _vm.unPublish
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isRemarkFun(
                          scope.row.rejectReason,
                          scope.row.stopSoldByOfficialReason,
                          scope.row.stopSoldReason,
                          scope.row.hiddenByOfficialAt,
                          scope.row.hiddenAt
                        )
                          ? _c("resource-remark", {
                              attrs: { resourceObj: scope.row }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c(
              "div",
              { staticClass: "empty-box" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "a-add",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addObject()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          该商户未发布任何作品，点击可添加作品\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("resource-stop-sale", {
        attrs: {
          openId: _vm.openId,
          handleSale: _vm.handleSale,
          visible: _vm.dialogSale
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogSale = $event
          }
        }
      }),
      _vm._v(" "),
      _c("resource-refuse", {
        attrs: {
          openId: _vm.openId,
          refuseObj: _vm.refuseObj,
          visible: _vm.dialogRefuse
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogRefuse = $event
          }
        }
      }),
      _vm._v(" "),
      _c("live-finish-class", {
        attrs: { isLeaveClass: _vm.isFinishClass, liveObj: _vm.liveClassObj },
        on: {
          "update:isLeaveClass": function($event) {
            _vm.isFinishClass = $event
          },
          "update:is-leave-class": function($event) {
            _vm.isFinishClass = $event
          }
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      }),
      _vm._v(" "),
      _c("resource-set-sequence", {
        attrs: {
          resource: _vm.resourceObj,
          setSequence: _vm.setSequence,
          visible: _vm.sequenceDialog
        },
        on: {
          "update:visible": function($event) {
            _vm.sequenceDialog = $event
          }
        }
      }),
      _vm._v(" "),
      _c("resource-set-recommend", {
        attrs: {
          resource: _vm.resourceObj,
          visible: _vm.recommendDialog,
          setRecommend: _vm.setRecommend
        },
        on: {
          "update:visible": function($event) {
            _vm.recommendDialog = $event
          }
        }
      }),
      _vm._v(" "),
      _c("live-share", {
        attrs: { visible: _vm.liveShareDialog, liveObj: _vm.liveShareObj },
        on: {
          "update:visible": function($event) {
            _vm.liveShareDialog = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.liveStatisticDialog
        ? _c("live-statistics", {
            attrs: {
              visible: _vm.liveStatisticDialog,
              liveObj: _vm.liveObj,
              activeName: _vm.activeName
            },
            on: {
              "update:visible": function($event) {
                _vm.liveStatisticDialog = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowOperationRecord
        ? _c("resource-operation-record", {
            attrs: {
              visible: _vm.isShowOperationRecord,
              resourceObj: _vm.operatoinResource
            },
            on: {
              "update:visible": function($event) {
                _vm.isShowOperationRecord = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }