var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-cascader", {
        staticClass: "category-box",
        attrs: {
          options: _vm.categoryData,
          props: { value: "id", label: "name", multiple: false },
          clearable: ""
        },
        on: { change: _vm.categoryChange },
        model: {
          value: _vm.newValueCategory,
          callback: function($$v) {
            _vm.newValueCategory = $$v
          },
          expression: "newValueCategory"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }