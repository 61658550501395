var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-problem" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "details-dialog",
          attrs: {
            title: "问题详情",
            visible: _vm.visible,
            "before-close": _vm.detailsReset,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "970px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.initData
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailsLoading,
                  expression: "detailsLoading"
                }
              ],
              staticClass: "load-detail"
            },
            [
              _c("div", { staticClass: "details-title" }, [
                _vm._v(_vm._s(_vm.details.problemTitle))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details-apply-people" }, [
                _c("div", [_vm._v(_vm._s(_vm.details.newIntended))]),
                _vm._v(" "),
                _c("div", { staticClass: "details-time" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("parseTime")(
                        _vm.details.updatedAt,
                        "{y}-{m}-{d}  {h}:{i}"
                      )
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-divider", { staticClass: "details-divider" }),
              _vm._v(" "),
              _vm.details.linkAddress
                ? _c("div", {
                    staticClass: "video",
                    attrs: { id: "videoPreview" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "editor-display",
                domProps: { innerHTML: _vm._s(_vm.details.problemDetail) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "standing-position" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.detailsReset } },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }