var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "import-dialog-bg",
          attrs: {
            title: "批量导入",
            visible: _vm.visibleProxy,
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleProxy = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "lrc-txt",
                attrs: {
                  type: "textarea",
                  rows: 17,
                  placeholder: "请将字幕文本（lrc）粘贴至此。"
                },
                model: {
                  value: _vm.lrcData,
                  callback: function($$v) {
                    _vm.lrcData = $$v
                  },
                  expression: "lrcData"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.importCancel } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveDotData }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }