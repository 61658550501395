"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _timeFilter = _interopRequireDefault(require("../../../utils/filters/timeFilter"));

var _beautifulTextFilter = _interopRequireDefault(require("../../../utils/filters/beautifulTextFilter"));

var _UserInfo = _interopRequireDefault(require("../../../views/help/components/UserInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogueItem',
  components: {
    UserInfo: _UserInfo.default
  },
  filters: {
    parseTime: _timeFilter.default,
    beautifulTextFilter: _beautifulTextFilter.default
  },
  props: {
    createdAt: {
      type: String,
      default: ''
    },
    operateUserOpenId: {
      type: String,
      default: ''
    },
    operateUserInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    userData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectUserOpenId: {
      type: String,
      default: ''
    }
  }
};
exports.default = _default2;