var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dot-page" },
    [
      _c("div", { staticClass: "dot-header box" }, [
        _c("span", [_vm._v("多媒体打点")]),
        _vm._v(" "),
        _c("i", {
          staticClass: "el-icon-close",
          on: {
            click: function($event) {
              return _vm.dotbtnshow()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dot-content display-flex box" }, [
        _c("div", { staticClass: "video-wrapper" }, [
          _c("div", { staticClass: "video-start", attrs: { id: "videodot" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "video-slider-bg" },
            [
              _c("el-slider", {
                attrs: {
                  min: 0,
                  max: _vm.previewLength,
                  "format-tooltip": _vm.formatTimetip
                },
                on: { change: _vm.changeTimeTip },
                model: {
                  value: _vm.videoTimeSeconds,
                  callback: function($$v) {
                    _vm.videoTimeSeconds = $$v
                  },
                  expression: "videoTimeSeconds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "txt-time-bg" },
            [
              _c(
                "el-input",
                {
                  staticClass: "txt-time",
                  attrs: {
                    placeholder: "输入时间mm:ss或打点获取时间点",
                    size: "small"
                  },
                  model: {
                    value: _vm.videoTime,
                    callback: function($$v) {
                      _vm.videoTime = $$v
                    },
                    expression: "videoTime"
                  }
                },
                [
                  _c("i", {
                    staticClass:
                      "el-input__icon el-icon-minus btn-time-operation",
                    attrs: { slot: "prepend" },
                    on: { click: _vm.reduceTime },
                    slot: "prepend"
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass:
                      "el-input__icon el-icon-plus btn-time-operation",
                    attrs: { slot: "append" },
                    on: { click: _vm.addTime },
                    slot: "append"
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.createDot()
                    }
                  }
                },
                [_vm._v("打点")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "add-box" }, [
          _c(
            "div",
            { staticClass: "operation-bg" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "small" },
                  on: { click: _vm.handlerImportData }
                },
                [_vm._v("批量导入")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", plain: "", size: "small" },
                  on: { click: _vm.delAllDot }
                },
                [_vm._v("全部移除")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-list-bg" },
            [
              _c(
                "el-table",
                {
                  ref: "dotList",
                  staticClass: "table-list",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.arraycatch,
                    height: "226",
                    border: "",
                    "header-cell-class-name": "dot-table-header"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", width: "100", label: "时间点" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.currentDotIndex === scope.$index
                              ? _c(
                                  "div",
                                  { staticClass: "picker-bg" },
                                  [
                                    _c("el-time-select", {
                                      attrs: {
                                        size: "mini",
                                        "picker-options": _vm.pickerOptions
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeDotTime(scope.row)
                                        }
                                      },
                                      model: {
                                        value: scope.row.templateTime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "templateTime",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.templateTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "picker-info-bg" }, [
                                  _vm._v(_vm._s(scope.row.templateTime))
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "detail", label: "描述" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.currentDotIndex === scope.$index
                              ? _c(
                                  "div",
                                  { staticClass: "descript-bg" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入内容"
                                      },
                                      model: {
                                        value: scope.row.tempDetail,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "tempDetail", $$v)
                                        },
                                        expression: "scope.row.tempDetail"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(scope.row.detail))
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "address", width: "90", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.currentDotIndex === scope.$index
                              ? _c(
                                  "div",
                                  { staticClass: "operation-box" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-cancel",
                                        attrs: { type: "text", size: "mini" },
                                        on: { click: _vm.handleCancel }
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-affirm",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleConfirm(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("确认")]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "operation-box" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-edit",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEditVideo(
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-del",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deletedate(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("移除")]
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dot-bottom display-flex box" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.dotbtnshow()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.addlistdate()
                }
              }
            },
            [_vm._v("确认")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("import-dot-data", {
        attrs: { dataIsShow: _vm.isBatchData },
        on: {
          "update:dataIsShow": function($event) {
            _vm.isBatchData = $event
          },
          "update:data-is-show": function($event) {
            _vm.isBatchData = $event
          },
          getBatchDot: _vm.initDotList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }