var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-button", {
    staticClass: "icon-button",
    attrs: { type: _vm.type, icon: _vm.icon, circle: "" },
    on: { click: _vm.click }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }