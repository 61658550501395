"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RefuseResource',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    refuseBtn: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogRefuse: false,
      refuseForm: {
        reason: ''
      },
      refuseRules: {
        reason: [{
          required: true,
          trigger: 'blur',
          message: '请输入驳回原因'
        }, {
          min: 2,
          max: 20,
          message: '长度在 2 到 20 个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    isShow: function isShow() {
      return this.value;
    },
    dialogRefuseChange: function dialogRefuseChange() {
      return this.dialogRefuse;
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function handler(value) {
        this.dialogRefuse = value;
      }
    },
    dialogRefuseChange: {
      immediate: true,
      handler: function handler(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    refuseObj: function refuseObj() {
      var _this = this;

      this.$refs.refuseForm.validate(function (valid) {
        if (valid) {
          var data = {
            reason: _this.refuseForm.reason
          };

          _this.$emit('refuse-obj', data);
        }
      });
    }
  }
};
exports.default = _default;