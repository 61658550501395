var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-order" },
    [
      _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { name: "awaitDeal" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("待我处理" + _vm._s(_vm.awaitNumber))
                  ]),
                  _vm._v(" "),
                  _c("service-order-top", {
                    ref: "awaitSearch",
                    attrs: {
                      "tags-name": "awaitDeal",
                      "search-class-list": _vm.searchClassList
                    },
                    on: {
                      search: _vm.search,
                      addOrder: _vm.addOrder,
                      handleRefresh: _vm.handleRefresh
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { name: "createForMe" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("我创建的" + _vm._s(_vm.createForMeNumber))
                  ]),
                  _vm._v(" "),
                  _c("service-order-top", {
                    ref: "createForMeSearch",
                    attrs: {
                      "search-class-list": _vm.searchClassList,
                      "tags-name": "createForMe"
                    },
                    on: {
                      sortSearch: _vm.sortSearch,
                      search: _vm.search,
                      handleRefresh: _vm.handleRefresh
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { name: "processedOrder" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("已处理工单" + _vm._s(_vm.processedNumber))
                  ]),
                  _vm._v(" "),
                  _c("service-order-top", {
                    ref: "processedSearch",
                    attrs: {
                      "search-class-list": _vm.searchClassList,
                      "tags-name": "createForMe"
                    },
                    on: {
                      sortSearch: _vm.sortSearch,
                      search: _vm.search,
                      handleRefresh: _vm.handleRefresh
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.leftTabLoading,
              expression: "leftTabLoading"
            }
          ],
          staticClass: "service-tabs-left"
        },
        [
          _vm.activeName === "awaitDeal"
            ? _c("left-tabs-service-order", {
                attrs: {
                  list: _vm.awaitList,
                  "is-show-data": _vm.isAwaitShowData,
                  "no-more": _vm.noMore,
                  disabled: _vm.disabled,
                  "scroll-loading": _vm.scrollLoading,
                  "current-id": _vm.detailsIdVal
                },
                on: {
                  tabClick: _vm.tabClick,
                  handleForMe: _vm.handleAwaitForMe,
                  loadScroll: _vm.loadScroll
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "createForMe"
            ? _c("left-tabs-service-order", {
                attrs: {
                  list: _vm.createForMeList,
                  "is-show-data": _vm.isCreateShowData,
                  "no-more": _vm.noMore,
                  disabled: _vm.disabled,
                  "scroll-loading": _vm.scrollLoading,
                  "current-id": _vm.detailsIdVal
                },
                on: {
                  tabClick: _vm.tabClick,
                  handleForMe: _vm.handleForMe,
                  loadScroll: _vm.loadScroll
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "processedOrder"
            ? _c("left-tabs-service-order", {
                attrs: {
                  list: _vm.processedOrderList,
                  "is-show-data": _vm.isProcessedShowData,
                  "no-more": _vm.noMore,
                  disabled: _vm.disabled,
                  "scroll-loading": _vm.scrollLoading,
                  "current-id": _vm.detailsIdVal
                },
                on: {
                  tabClick: _vm.tabClick,
                  handleForMe: _vm.handleForMe,
                  loadScroll: _vm.loadScroll
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "service-tab-content" },
        [
          _vm.detailsIdVal
            ? _c("work-order-details", {
                ref: "workOrderDetails",
                attrs: {
                  "details-id": _vm.detailsIdVal,
                  "is-not-dialog": true,
                  "refresh-detail": _vm.refreshDetail,
                  "is-refresh-reply": _vm.isRefreshReply
                },
                on: {
                  handleEnd: _vm.handleEnd,
                  handleRefLeftList: _vm.handleRefLeftList,
                  getDetailData: _vm.getDetailData,
                  handleReplyManagement: _vm.handleCommonReply,
                  toUserDetails: _vm.toUserDetails
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.detailsShowData
            ? _c(
                "div",
                { staticClass: "no-data" },
                [
                  _c("el-image", {
                    staticClass: "no-data-image",
                    attrs: { fit: "cover", src: _vm.noDataImage }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "no-data-text" }, [
                    _vm._v("暂无工单内容")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("details-reply-drawer", {
            attrs: {
              visible: _vm.drawerDialog,
              "detail-data": _vm.detailData,
              "drawer-title": _vm.drawerTitle,
              "is-refresh-reply": _vm.isRefreshReply
            },
            on: {
              "update:visible": function($event) {
                _vm.drawerDialog = $event
              },
              "update:isRefreshReply": function($event) {
                _vm.isRefreshReply = $event
              },
              "update:is-refresh-reply": function($event) {
                _vm.isRefreshReply = $event
              },
              handleClose: _vm.handleDrawerClose,
              handleCommonReply: _vm.handleCommonReply,
              handleUpdateSubmit: _vm.handleUpdateSubmit
            }
          }),
          _vm._v(" "),
          _c("end-work-order", {
            attrs: { visible: _vm.endDialog },
            on: {
              "update:visible": function($event) {
                _vm.endDialog = $event
              },
              handleResolved: _vm.handleResolved
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("add-work-order", {
        ref: "addWorkDialog",
        attrs: { visible: _vm.addDialog },
        on: {
          "update:visible": function($event) {
            _vm.addDialog = $event
          },
          submitAddWork: _vm.submitAddWork
        }
      }),
      _vm._v(" "),
      _c("user-info", {
        attrs: {
          visible: _vm.dialogUserDetail,
          "user-open-id": _vm.currentUserOpenId,
          "prop-active-name": _vm.propActiveName
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogUserDetail = $event
          },
          clearuserOpenId: _vm.clearUserOpenId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }