var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑域名",
        "close-on-click-modal": false,
        visible: _vm.dialogVisible,
        width: "520px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ],
          staticClass: "container"
        },
        [
          _c(
            "div",
            [
              _vm._v("域名地址 "),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.addItem } },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "itemBox" },
            _vm._l(_vm.domainArr, function(item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("el-input", {
                      attrs: { clearable: "", placeholder: "请输入域名地址" },
                      model: {
                        value: _vm.domainArr[index],
                        callback: function($$v) {
                          _vm.$set(_vm.domainArr, index, $$v)
                        },
                        expression: "domainArr[index]"
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-delete icon",
                      class: _vm.domainArr.length === 1 ? "notIcon" : "icon",
                      on: {
                        click: function($event) {
                          return _vm.deleteDomain(index)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveDomain } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }