"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/utils/cachedApi/common/index");

var _dictionary = require("@/utils/cachedApi/common/dictionary");

var _DefaultSyncInfo = _interopRequireDefault(require("@/components/General/From/Selector/SyncInfo/DefaultSyncInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_Product_Product_EditProduct_EditProductAttributes_SyncInfoGroup',
  components: {
    SyncInfo: _DefaultSyncInfo.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      getSyncSection: _index.getSyncSection,
      getSyncSubject: _index.getSyncSubject,
      getSyncEdition: _index.getSyncEdition,
      getSyncGrade: _index.getSyncGrade,
      getSyncVolume: _index.getSyncVolume,
      getSyncElective: _dictionary.getSyncElective
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onChange: function onChange() {
      this.$emit('single-locked-need-update');
    }
  }
};
exports.default = _default2;