"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeUserInfo = changeUserInfo;
exports.findUser = findUser;
exports.getAllUsers = getAllUsers;
exports.getAllUsersAdmin = getAllUsersAdmin;
exports.getUser = getUser;
exports.passUser = passUser;
exports.userList = userList;
exports.editUserInfo = editUserInfo;
exports.bindPhone = bindPhone;
exports.editBindPhone = editBindPhone;
exports.changeGold = changeGold;
exports.clearFinanceWx = clearFinanceWx;
exports.clearPartnerWx = clearPartnerWx;
exports.unBindFinanceWx = unBindFinanceWx;
exports.unBindPartnerWx = unBindPartnerWx;
exports.bindFinanceWx = bindFinanceWx;
exports.bindPartnerWx = bindPartnerWx;
exports.getFinanceWxInfo = getFinanceWxInfo;
exports.getPartnerWxInfo = getPartnerWxInfo;
exports.getUserWxInfo = getUserWxInfo;
exports.deletedUser = deletedUser;
exports.restoreUser = restoreUser;
exports.getRoleListApi = getRoleListApi;
exports.getRoleListApiByproductOpenId = getRoleListApiByproductOpenId;
exports.getRoleListApiByRoleId = getRoleListApiByRoleId;
exports.deletedRoleByRoleId = deletedRoleByRoleId;
exports.getAuthMenuApi = getAuthMenuApi;
exports.getPrivilegeByOpenId = getPrivilegeByOpenId;
exports.getTreeListPrivilegeByOpenId = getTreeListPrivilegeByOpenId;
exports.saveAddRole = saveAddRole;
exports.getUserRole = getUserRole;
exports.deletedUserRole = deletedUserRole;
exports.getUserRoleById = getUserRoleById;
exports.getRoleNamesByUserOpenId = getRoleNamesByUserOpenId;
exports.getAuthorizationTreePrivilegeByRoleIds = getAuthorizationTreePrivilegeByRoleIds;
exports.getAllUserRole = getAllUserRole;
exports.isAdminManager = isAdminManager;
exports.saveUserRoleByBatch = saveUserRoleByBatch;
exports.saveRole = saveRole;
exports.updateRole = updateRole;
exports.addPrivilege = addPrivilege;
exports.delPrivilege = delPrivilege;
exports.getPrivilegeByPrivilegeOpenId = getPrivilegeByPrivilegeOpenId;
exports.updatePrivilegeByPrivilegeOpenId = updatePrivilegeByPrivilegeOpenId;
exports.getAllSubPrivilege = getAllSubPrivilege;
exports.roleRestore = roleRestore;
exports.restorePrivilege = restorePrivilege;
exports.userListKeyword = userListKeyword;
exports.getUserBalanceLogList = getUserBalanceLogList;
exports.getUserBalanceLogStatistics = getUserBalanceLogStatistics;
exports.getUserMpOpenId = getUserMpOpenId;
exports.getPrivilegeRoles = getPrivilegeRoles;
exports.getUsergoldChangeList = getUsergoldChangeList;
exports.getuserTextBook = getuserTextBook;
exports.addUserTag = addUserTag;
exports.deletedUserTag = deletedUserTag;
exports.getUserPortrait = getUserPortrait;
exports.getuserOperationLog = getuserOperationLog;
exports.getUserScanCategory = getUserScanCategory;
exports.getAuthorizationTreePrivilegeByUserOpenId = getAuthorizationTreePrivilegeByUserOpenId;
exports.userPortraitInfo = userPortraitInfo;
exports.getUserAddressList = getUserAddressList;
exports.patchUserAddress = patchUserAddress;
exports.addUserAddress = addUserAddress;
exports.getUserDeviceList = getUserDeviceList;
exports.delUserDeviceByuuid = delUserDeviceByuuid;
exports.deleteUserAddress = deleteUserAddress;
exports.getStudyDetailByUserOpenId = getStudyDetailByUserOpenId;
exports.getLearningTimeListByUserOpenId = getLearningTimeListByUserOpenId;
exports.getStudyplan = getStudyplan;
exports.userIsOwnPrivilege = userIsOwnPrivilege;
exports.checkUserDevice = checkUserDevice;
exports.addUserTaguserRole = addUserTaguserRole;
exports.deletedTaguserRole = deletedTaguserRole;
exports.importAdminApi = importAdminApi;
exports.getImportStatus = getImportStatus;
exports.userStore = userStore;
exports.getAuthorizationTreeByPartner = getAuthorizationTreeByPartner;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user/admin/"));
var commonRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user/common/"));
var requestOpenFeign = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user/openFeign/"));
var request_learn = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user-related/client/"));
var request_learn_admin = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user-related/admin/"));
var requestHiddenError = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user/admin/"), {}, {
  filled: true,
  errorHandling: false
});
var requestTime = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user/admin/"), {
  timeout: 120000
});
var mpRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/"));
/**
 * 同步用户信息
 * @param {String} params
 * @returns {Promise<*>}
 */

function userPortraitInfo(params) {
  return request.put('user/update/userPortraitInfo', undefined, {
    params: params
  });
}
/**
 * 获取用户最近浏览记录
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getUserScanCategory(params) {
  return request.get('/userScanCategory/getByUserOpenId', {
    params: params
  });
}
/**
 * 获取用户操作日志
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getuserOperationLog(params) {
  return request.get('/userOperationLog', {
    params: params
  });
}
/**
 * 获取用户画像
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getUserPortrait(params) {
  return request.get('/userPortrait/getByUserOpenId', {
    params: params
  });
}
/**
 * 新增用户标签
 * @param {String} params
 * @returns {Promise<*>}
 */


function addUserTag(params) {
  return request.put('/user/add/tag', undefined, {
    params: params
  });
}
/**
 * 删除用户标签
 * @param {String} params
 * @returns {Promise<*>}
 */


function deletedUserTag(params) {
  return request.put('/user/delete/tag', undefined, {
    params: params
  });
}
/**
 * 新增用户标签 addUserTaguserRole
 * @param {String} params
 * @returns {Promise<*>}
 */


function addUserTaguserRole(params) {
  return request.put('/userRole/add/tag', undefined, {
    params: params
  });
}
/**
 * 删除用户标签 deletedTaguserRole
 * @param {String} params
 * @returns {Promise<*>}
 */


function deletedTaguserRole(params) {
  return request.delete('/userRole/delete/tag', {
    params: params
  });
}
/**
 * 根据系统 open id 查询权限菜单对应角色列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getPrivilegeRoles(productOpenId) {
  return request.get('/privilege/getPrivilegeRoles/', {
    params: {
      productOpenId: productOpenId
    }
  });
}
/**
 * 获取单个用户的公众号 open id
 * @param {Object} userOpenId
 * @returns {Promise<*>}
 */


function getUserMpOpenId(userOpenId) {
  return requestOpenFeign.get('/user/getUserMpOpenId', {
    params: {
      userOpenId: userOpenId
    }
  });
}
/**
 * 总后台分页获取用户jinxingbi变动记录表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUsergoldChangeList(params) {
  return request.get('/userGoldLog', {
    params: params
  });
}
/**
 * 总后台分页获取用户余额变动记录表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserBalanceLogList(params) {
  return request.get('/userBalanceLog/getUserBalanceLogList', {
    params: params
  });
}
/**
 * 总后台获取用户余额变动记录统计
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserBalanceLogStatistics(params) {
  return request.get('/userBalanceLog/getUserBalanceLogStatistics', {
    params: params
  });
}
/**
 * 查询用户信息
 * @param {String} openIds
 * @param {Number} page
 * @param {Number} size
 * @param {String} keyword
 * @param isHiddenError 工单隐藏报错信息
 * @returns {Promise<*>}
 */


function getAllUsers(openIds, page, size, keyword) {
  var isHiddenError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

  if (isHiddenError) {
    return requestHiddenError.get('/user', {
      params: {
        openIds: openIds,
        page: page,
        size: size,
        keyword: keyword,
        sortBy: 'updatedAt',
        sortOrder: 'desc'
      }
    });
  }

  return request.get('/user', {
    params: {
      openIds: openIds,
      page: page,
      size: size,
      keyword: keyword,
      sortBy: 'updatedAt',
      sortOrder: 'desc'
    }
  });
}
/**
 * 查询用户信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllUsersAdmin(params) {
  return requestTime.get('/user/search', {
    params: params
  });
}
/**
 * 获取指定的用户信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getUser(openId) {
  return request.get("/user/".concat(openId));
}
/**
 * 获取用户教材版本信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getuserTextBook(params) {
  return request.get('/userTextBook/detail', {
    params: params
  });
}
/**
 * 替换指定的用户信息
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function passUser(openId, data) {
  return request.put("/user/".concat(openId), data);
}
/**
 * 更新用户金星币数量
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function changeGold(openId, params) {
  return request.patch("/user/change/gold/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 根据名称获取用户信息列表
 * @param {String} name
 * @returns {Promise<*>}
 */


function findUser(name) {
  return request.get('/user/getUserByName', {
    params: {
      name: name
    }
  });
}
/**
 * 获取用户信息列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function userList(params) {
  return request.get('/user/search', {
    params: params
  });
}
/**
 * 获取用户信息列表(key必填)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function userListKeyword(params) {
  return request.get('/user/search/forKeyword', {
    params: params
  });
}
/**
 * 更新指定的用户信息
 * @param {Number} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function changeUserInfo(openId, data) {
  return request.patch("/user/".concat(openId), data);
}
/**
 * 查询【用户】微信信息
 * @returns {Promise<*>}
 */


function getUserWxInfo() {
  return commonRequest.get('/wxInfo');
}
/**
 * 查询【商户】微信信息
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function getPartnerWxInfo(partnerOpenId) {
  return commonRequest.get('/wxInfo/partner', {
    params: {
      partnerOpenId: partnerOpenId
    }
  });
}
/**
 * 查询【财务】微信信息
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function getFinanceWxInfo(partnerOpenId) {
  return commonRequest.get('/wxInfo/finance', {
    params: {
      partnerOpenId: partnerOpenId
    }
  });
}
/**
 * 绑定【商户】创建者微信
 * @param {Object} params
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bindPartnerWx(params, data) {
  return commonRequest.patch('/wxInfo/partner/bindingWx', data, {
    params: params
  });
}
/**
 * 绑定【财务】创建者微信
 * @param {Object} params
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bindFinanceWx(params, data) {
  return commonRequest.patch('/wxInfo/finance/bindingWx', data, {
    params: params
  });
}
/**
 * 解绑【商户】创建者微信
 * @param {Object} params
 * @returns {Promise<*>}
 */


function unBindPartnerWx(params) {
  return commonRequest.patch('/wxInfo/partner/unbindWx', undefined, {
    params: params
  });
}
/**
 *解绑【财务】信息微信
 * @param {Object} params
 * @returns {Promise<*>}
 */


function unBindFinanceWx(params) {
  return commonRequest.patch('/wxInfo/finance/unbindWx', undefined, {
    params: params
  });
}
/**
 *清除【商户】微信信息
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function clearPartnerWx(partnerOpenId) {
  return commonRequest.delete('/wxInfo/partner', {
    params: {
      partnerOpenId: partnerOpenId
    }
  });
}
/**
 *清除【财务】微信信息
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function clearFinanceWx(partnerOpenId) {
  return commonRequest.delete('/wxInfo/finance', {
    params: {
      partnerOpenId: partnerOpenId
    }
  });
}
/**
 * 根据openId 修改用户信息
 * @param {String} openId
 * @param{Object} data
 * @returns {Promise<*>}
 */


function editUserInfo(openId, data) {
  return request.patch("/user/".concat(openId), data);
}
/**
 * 绑定手机号
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bindPhone(openId, params) {
  return request.patch("/user/band/telephone/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 更换绑定的手机号
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function editBindPhone(openId, params) {
  return request.patch("/user/change/telephone/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 更换绑定的手机号
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function deletedUser(openId, params) {
  return request.delete("/user/".concat(openId), {
    params: params
  });
}
/**
 * 恢复指定用户
 * @param {String} openId
 * @returns {Promise<*>}
 */


function restoreUser(openId) {
  return request.patch("/user/restore/".concat(openId));
}
/**
 * 查询角色列表
 * @param { Number } productOpenId
 *  @param { Number } size
 * @param { Number } page
 * @returns {Promise<*>}
 */


function getRoleListApi(productOpenId, page, size) {
  return request.get('/role', {
    params: {
      productOpenId: productOpenId,
      page: page,
      size: size
    }
  });
}
/**
 * 根据系统 open id 查询所有未删除的角色列表
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function getRoleListApiByproductOpenId(productOpenId) {
  return request.get('/role/getAllRoleByProductOpenId', {
    params: {
      productOpenId: productOpenId
    }
  });
}
/**
 * 查询指定的角色信息
 * @param {String} roleId
 * @returns {Promise<*>}
 */


function getRoleListApiByRoleId(roleId) {
  return request.get("/role/".concat(roleId));
}
/**
 * 查询指定的角色信息
 * @param {String} roleId
 * @returns {Promise<*>}
 */


function deletedRoleByRoleId(roleId) {
  return request.delete("/role/".concat(roleId));
}
/**
 * 查询权限菜单列表
 * @param {String} productOpenId
 * @param {String} page
 * @param {String} size
 * @returns {Promise<*>}
 */


function getAuthMenuApi(productOpenId, page, size) {
  return request.get('/privilege', {
    params: {
      productOpenId: productOpenId,
      page: page,
      size: size
    }
  });
}
/**
 * 查询指定的权限信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getPrivilegeByOpenId(openId) {
  return request.get("privilege/".concat(openId));
}
/**
 * 根据角色 id 和 系统 open id 查询所有未删除的权限列表 【树形结构，用于添加和编辑角色界面获取权限树形菜单】
 * @param {String} roleId
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function getTreeListPrivilegeByOpenId(roleId, productOpenId) {
  return request.get('/privilege/getAuthorizationTreePrivilegeByRoleId', {
    params: {
      roleId: roleId,
      productOpenId: productOpenId
    }
  });
}
/**
 * 保存角色
 * @param {Object} params
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveAddRole(params, data) {
  return request.post('/rolePrivilege/batch/', data, {
    params: params
  });
}
/**
 * 分页查询用户角色列表
 * @param {String} productOpenId
 * @param {String} page
 * @param {String} size
 * @returns {Promise<*>}
 */


function getUserRole(productOpenId, page, size, roleId, keyword) {
  return request.get('/userRole/', {
    params: {
      productOpenId: productOpenId,
      page: page,
      size: size,
      roleId: roleId,
      keyword: keyword
    }
  });
}
/**
 * 删除用户角色
 * @param {String} id
 * @returns {Promise<*>}
 */


function deletedUserRole(id) {
  return request.delete("/userRole/".concat(id));
}
/**
 * 查询指定的用户角色信息
 * @param {String} id
 * @returns {Promise<*>}
 */


function getUserRoleById(id) {
  return request.get("/userRole/".concat(id));
}
/**
 * 查询指定用户所属的角色名称
 * @param {String} userOpenId
 * @returns {Promise<*>}
 */


function getRoleNamesByUserOpenId(userOpenId, productOpenId) {
  return request.get('/userRole/getRolesByUserOpenId', {
    params: {
      userOpenId: userOpenId,
      productOpenId: productOpenId
    }
  });
}
/**
 * 根据多个角色 id 和 系统 open id 查询所有未删除的权限列表 【树形结构，用于添加和编辑管理员界面获取权限树形菜单】
 * @param {String} roleIds
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function getAuthorizationTreePrivilegeByRoleIds(roleIds, productOpenId) {
  return request.get('/privilege/getAuthorizationTreePrivilegeByRoleIds/', {
    params: {
      roleIds: roleIds,
      productOpenId: productOpenId
    }
  });
}
/**
 * 根据用户 open id 和 系统 open id 查询动态路由 【树形结构】
 * @param {String} userOpenId
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function getAuthorizationTreePrivilegeByUserOpenId(userOpenId, productOpenId) {
  return request.get('/privilege/getAuthorizationTreePrivilegeByUserOpenId', {
    params: {
      userOpenId: userOpenId,
      productOpenId: productOpenId
    }
  });
}
/**
 * 根据用户 open id 和 系统 open id 查询所有的用户角色信息
 * @param {String} userOpenId
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function getAllUserRole(userOpenId, productOpenId) {
  return request.get('/userRole/getAllUserRole/', {
    params: {
      userOpenId: userOpenId,
      productOpenId: productOpenId
    }
  });
}
/**
 * 批量添加用户角色
 * @param {Object} data
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function saveUserRoleByBatch(userOpenId, productOpenId, data) {
  return request.post('/userRole/batch', data, {
    params: {
      userOpenId: userOpenId,
      productOpenId: productOpenId
    }
  });
}
/**
 * 判断用户是否是总后台的管理员
 * @param {String} userOpenId
 * @returns {Promise<*>}
 */


function isAdminManager(userOpenId) {
  return request.get('/userRole/isAdminManager', {
    params: {
      userOpenId: userOpenId
    }
  });
}
/**
 * 保存角色
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveRole(data) {
  return request.post('/role/', data);
}
/**
 * 更新角色信息
 * @param {Object} data
 * @param {String} id
 * @returns {Promise<*>}
 */


function updateRole(id, data) {
  return request.patch("/role/".concat(id), data);
}
/**
 * 更新角色信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPrivilege(data) {
  return request.post('/privilege/', data);
}
/**
 * 删除权限
 * @param {Object} openId
 * @returns {Promise<*>}
 */


function delPrivilege(openId) {
  return request.delete("/privilege/".concat(openId));
}
/**
 * 查询权限
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getPrivilegeByPrivilegeOpenId(openId) {
  return request.get("/privilege/".concat(openId));
}
/**
 * 更新权限
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePrivilegeByPrivilegeOpenId(openId, data) {
  return request.patch("/privilege/".concat(openId), data);
}
/**
 * 根据父级权限 open id 和 系统 open id 查询所有的子权限列表
 * @param {String} parentOpenId
 * @param {String} productOpenId
 * @returns {Promise<*>}
 */


function getAllSubPrivilege(parentOpenId, productOpenId) {
  return request.get("/privilege/getAllSubPrivilege/".concat(parentOpenId), {
    params: {
      productOpenId: productOpenId
    }
  });
}
/**
 * 恢复指定的角色 * @param {String} parentOpenId
 * @param {String} id
 * @returns {Promise<*>}
 */


function roleRestore(id) {
  return request.patch("/role/restore/".concat(id));
}
/**
 * 恢复指定的权限
 * @param {String} id
 * @returns {Promise<*>}
 */


function restorePrivilege(openId) {
  return request.patch("/privilege/restore/".concat(openId));
}
/**
 * 指定用户地址管理
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserAddressList(params) {
  return request.get('/userAddress/getByOpenId/', {
    params: params
  });
}
/**
 * 更新用户地址
 * @param {String} id
 * @returns {Promise<*>}
 */


function patchUserAddress(id, data) {
  return request.patch("/userAddress/".concat(id), data);
}
/**
 * 更新用户地址
 * @param {String} id
 * @returns {Promise<*>}
 */


function addUserAddress(data) {
  return request.post('/userAddress', data);
}
/**
 * 删除用户地址
 * @param {String} id
 * @returns {Promise<*>}
 */


function deleteUserAddress(id) {
  return request.delete("/userAddress/".concat(id));
}
/**
 * 查询指定用户设备管理
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserDeviceList(params) {
  return request.get('/userDevice/', {
    params: params
  });
}
/**
 * 查询指定用户设备管理
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delUserDeviceByuuid(openid, params) {
  return request.delete("/userDevice/".concat(openid), {
    params: params
  });
}
/**
 * 根据用户id获取全部学习详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getStudyDetailByUserOpenId(params) {
  return request_learn.get('/studyRecord/getStudyDetailByUserOpenId/', {
    params: params
  });
}
/**
 * 获取学习时长
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getLearningTimeListByUserOpenId(params) {
  return request_learn.get('/studyRecord/getLearningTimeListByUserOpenId/', {
    params: params
  });
}
/**
 * 学习记录列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getStudyplan(params) {
  return request_learn_admin.get('/studyRecord/list/', {
    params: params
  });
}
/**
 * 判读用户是否拥有指定菜单的权限
 * @returns {AxiosPromise<any>}
 */


function userIsOwnPrivilege(params) {
  return request.get('/userRole/isOwnPrivilege/', {
    params: params
  });
}

function checkUserDevice(params) {
  var clientRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user/client/"));
  return clientRequest.get('userDevice/used', {
    params: params
  });
}
/**
 *查询批量导入用户角色状态
 * @returns {AxiosPromise<any>}
 */


function getImportStatus() {
  return request.get('/userRole/importStatus/');
}
/**
 * 批量导入用户角色
 * @param params
 * @param data
 * @returns {AxiosPromise<any>}
 */


function importAdminApi(params, data) {
  var config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 1000 * 60 * 10
  };
  var importAdmin = (0, _main.default)(process.env.VUE_APP_BASE_API, config);
  return importAdmin.post('/user/admin/userRole/importAdmin', data, {
    params: params
  });
}
/**
 * 创建新用户
 * @param data
 * @returns {AxiosPromise<any>}
 */


function userStore(data) {
  return request.post('user', data);
}
/**
 * 根据商户查询权限列表
 * @returns {AxiosPromise<any>}
 */


function getAuthorizationTreeByPartner(params) {
  return mpRequest.get('mp/partnerPrivilege/getAuthorizationAllPrivilegeByUserOpenId', {
    params: params
  });
}