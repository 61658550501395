var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-bach-category",
      attrs: {
        title: "批量设置属性",
        visible: _vm.visible,
        width: "450px",
        "append-to-body": "",
        "before-close": _vm.cancelDialogue,
        "close-on-click-modal": false
      },
      on: { open: _vm.initData }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "60px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "classList", label: "分类" } },
            [
              _c("class-category", {
                attrs: {
                  "partner-open-id": _vm.partnerOpenId,
                  "is-dialog": true,
                  refresh: _vm.refresh
                },
                on: {
                  "update:refresh": function($event) {
                    _vm.refresh = $event
                  }
                },
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelDialogue } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }