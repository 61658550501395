var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  },
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "module-box",
                      on: {
                        click: function($event) {
                          return _vm.showYuSheDialog()
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: require("@/assets/ic_logo_yushe.png") }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v("小淘预设")
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("el-col", {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                },
                attrs: { span: 8 }
              }),
              _vm._v(" "),
              _c("el-col", {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                },
                attrs: { span: 8 }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            title: "小淘预设",
            visible: _vm.dialogYuSheVisible,
            width: "45%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogYuSheVisible = $event
            },
            close: function($event) {
              return _vm.hideYuSheDialog()
            }
          }
        },
        [
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.YuSheLoading,
                  expression: "YuSheLoading"
                }
              ],
              staticClass: "content"
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 10,
                  placeholder: "请描述加入预设内容",
                  autosize: false
                },
                model: {
                  value: _vm.yuSheContent,
                  callback: function($$v) {
                    _vm.yuSheContent = $$v
                  },
                  expression: "yuSheContent"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.hideYuSheDialog()
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.yuSheSubmit()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }