var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auditem-remark-operation" },
    [
      _vm.audioAliVidType === "audioAliVidType"
        ? _c(
            "div",
            [
              _vm.item.sectionType === 4
                ? _c("svg-icon", {
                    staticClass: "con-operation-icon",
                    class: _vm.item.gifLoopPlay ? "highlight" : "",
                    attrs: { "icon-class": "loopPlay" },
                    on: {
                      click: function($event) {
                        return _vm.handleLoopPlay(_vm.item)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.sectionType === 4
                ? _c("svg-icon", {
                    staticClass: "con-operation-icon",
                    class: _vm.item.gifAutoPlay ? "highlight" : "",
                    attrs: { "icon-class": "autoPlay" },
                    on: {
                      click: function($event) {
                        return _vm.isGifAutoPlay(_vm.item)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "book-creat" },
                on: {
                  click: function($event) {
                    return _vm.handleUpdate(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: {
                  "icon-class": _vm.audioState ? "pause" : "play-circle-outline"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.audioPlay(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "file-download" },
                on: {
                  click: function($event) {
                    return _vm.fileDownHandle(_vm.item.audioAliVid)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.delItem(_vm.item, "audioAliVid")
                  }
                }
              }),
              _vm._v(" "),
              _c("video", {
                ref: "audio",
                attrs: {
                  id: "audio",
                  src: _vm.audioUrl,
                  controls: "controls",
                  hidden: "true"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.audioLrcUrlType === "audioLrcUrlType"
        ? _c(
            "div",
            [
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "book-creat" },
                on: {
                  click: function($event) {
                    return _vm.handleUpdate(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.delItem(_vm.item, "audioLrcUrl")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.videoAliVidType === "videoAliVidType"
        ? _c(
            "div",
            [
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "book-creat" },
                on: {
                  click: function($event) {
                    return _vm.handleUpdate(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "play-circle-outline" },
                on: {
                  click: function($event) {
                    return _vm.videoPlay(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "file-download" },
                on: {
                  click: function($event) {
                    return _vm.fileDownHandle(_vm.item.videoAliVid)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.delItem(_vm.item, "videoAliVid")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.linkUrlType === "linkUrlType"
        ? _c(
            "div",
            [
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "book-creat" },
                on: {
                  click: function($event) {
                    return _vm.handleUpdate(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.delItem(_vm.item, "linkUrl")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.linkUrlType === "sectionData"
        ? _c(
            "div",
            { staticClass: "section-data" },
            [
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "book-creat" },
                on: {
                  click: function($event) {
                    return _vm.handleUpdate(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.delItem(_vm.item, "sectionData")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.linkUrlType === "picturePath"
        ? _c(
            "div",
            [
              _c("svg-icon", {
                staticClass: "con-operation-icon",
                attrs: { "icon-class": "book-creat" },
                on: {
                  click: function($event) {
                    return _vm.handleUpdate(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-zoom-in con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.previewImg(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete con-operation-icon",
                on: {
                  click: function($event) {
                    return _vm.delItem(_vm.item, "picturePath")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoplaybg",
          attrs: {
            visible: _vm.videoVisible,
            "before-close": _vm.handleClose,
            title: "视频播放"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoVisible = $event
            }
          }
        },
        [
          _vm.videoVisible
            ? _c("player", {
                ref: "videoPlayer",
                attrs: { "video-id": _vm.playVideoId }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.imgUrl
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrl(_vm.imgUrl)]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }