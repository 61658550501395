"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PriceLine = _interopRequireDefault(require("./PriceLine.vue"));

//
//
//
//
//
//
var _default2 = {
  name: 'PriceLayoutvue',
  components: {
    ChangePriceLine: _PriceLine.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    } // 本价格选择结果

  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  }
};
exports.default = _default2;