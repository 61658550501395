var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-upload-bg" }, [
    _vm.id
      ? _c("div", { staticClass: "file-txt" }, [_vm._v(_vm._s(_vm.id))])
      : _c("div", { staticClass: "file-txt" }, [
          _vm._v(_vm._s(_vm.statusText))
        ]),
    _vm._v(" "),
    _c("input", {
      ref: "uploadControl",
      staticClass: "file-upload",
      attrs: { disabled: _vm.uploadDisabled, type: "file" },
      on: {
        change: function($event) {
          return _vm.fileChange($event, "audio")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }