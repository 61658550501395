"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCashOutList = getCashOutList;
exports.getCashOutDetail = getCashOutDetail;
exports.checkCashOut = checkCashOut;
exports.addPromotionSetting = addPromotionSetting;
exports.getSettingList = getSettingList;
exports.getPromotionRecordPage = getPromotionRecordPage;
exports.getPromotionRecordDetail = getPromotionRecordDetail;
exports.getPromoterList = getPromoterList;
exports.getMyCustomerPage = getMyCustomerPage;
exports.getPromoterDetail = getPromoterDetail;
exports.addPromoter = addPromoter;
exports.updatePromoter = updatePromoter;
exports.getPromoterArr = getPromoterArr;
exports.ifOpenPromoter = ifOpenPromoter;
exports.getPromoterCheckList = getPromoterCheckList;
exports.checkPassAuditPromoter = checkPassAuditPromoter;
exports.getCommodityList = getCommodityList;
exports.getCommodityInfo = getCommodityInfo;
exports.updatePromotionLevel = updatePromotionLevel;
exports.delPromotionSetting = delPromotionSetting;
exports.updateRelationship = updateRelationship;
exports.getRelationshipList = getRelationshipList;
exports.addRelationship = addRelationship;
exports.changeCustomerRelationship = changeCustomerRelationship;
exports.getAmountRecordPage = getAmountRecordPage;
exports.getAmountLevel = getAmountLevel;
exports.getMaxRecord = getMaxRecord;
exports.setRemittanceState = setRemittanceState;
exports.setTaxState = setTaxState;
exports.getCourseRecordPage = getCourseRecordPage;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/distribution/"));
/**
 * 获取提现管理列表
 * @param params
 * @returns {Promise<*>}
 */

function getCashOutList(params) {
  return request.get('/cashOut/findCashOutList', {
    params: params
  });
}
/**
 * 提现详情
 * @param withdrawOpenId
 * @returns {Promise<*>}
 */


function getCashOutDetail(withdrawOpenId) {
  return request.get("/cashOut/cashOutDetail/".concat(withdrawOpenId));
}
/**
 * 提现是否通过 （提现状态 1审批中 2通过 3拒绝 4成功 5 失败）
 * @param {Object} params
 * @returns {Promise<*>}
 */


function checkCashOut(params) {
  return request.post('/cashOut/ifPass', undefined, {
    params: params
  });
}
/**
 * 推广记录列表
 * @param params
 * @returns {Promise<*>}
 */


function getPromotionRecordPage(params) {
  return request.get('/promotionRecord/promotionRecordPage', {
    params: params
  });
}
/**
 * 推广记录详情
 * @param id
 * @returns {Promise<*>}
 */


function getPromotionRecordDetail(id) {
  return request.get('/promotionRecord/findOne', {
    params: {
      id: id
    }
  });
}
/**
 * 推广员管理列表
 * @param params
 * @returns {Promise<*>}
 */


function getPromoterList(params) {
  return request.get('/promoter/promoterManagerPage', {
    params: params
  });
}
/**
 * 我的客户列表
 * @param params
 * @returns {Promise<*>}
 */


function getMyCustomerPage(params) {
  return request.get('/promotionUserRecord/getMyCustomerPage', {
    params: params
  });
}
/**
 * 推广员详情页
 * @param String userOpenId
 * @returns {Promise<*>}
 */


function getPromoterDetail(userOpenId) {
  return request.get('/promoter/findPromoter', {
    params: {
      userOpenId: userOpenId
    }
  });
}
/**
 * 推广员添加
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addPromoter(data) {
  return request.post('/promoter/addPromoter', data);
}
/**
 * 推广员修改
 * @param {String} userOpenId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePromoter(userOpenId, data) {
  return request.put("/promoter/updatePromoter/".concat(userOpenId), data);
}
/**
 * 推广员级别搜索框
 * @param params
 * @returns {Promise<*>}
 */


function getPromoterArr(params) {
  return request.get('/promoter/getSetting', {
    params: params
  });
}
/**
 * 推广员启用禁用
 * @param {Object} params
 * @returns {Promise<*>}
 */


function ifOpenPromoter(params) {
  return request.post('/promoter/ifOpenPromoter', undefined, {
    params: params
  });
}
/**
 * 推广员审核列表
 * @param params
 * @returns {Promise<*>}
 */


function getPromoterCheckList(params) {
  return request.get('/promoter/auditPromoterPage', {
    params: params
  });
}
/**
 * 审核推广员( ifPass：1 审核中 2通过 3驳回)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function checkPassAuditPromoter(params) {
  return request.post('/promoter/ifPassAuditPromoter', undefined, {
    params: params
  });
}
/**
 * 推广商品列表
 * @param params
 * @returns {Promise<*>}
 */


function getCommodityList(params) {
  return request.get('/promotionRecord/getCommodityList', {
    params: params
  });
}
/**
 * 推广商品价格等信息
 * @param String commodityOpenId
 * @returns {Promise<*>}
 */


function getCommodityInfo(commodityOpenId) {
  return request.get('/promotionRecord/getOneCommodityInfo', {
    params: {
      commodityOpenId: commodityOpenId
    }
  });
}
/**
 * 添加级别设置
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPromotionSetting(data) {
  return request.post('/promotionSetting/addSetting', data);
}
/**
 * 级别设置列表
 * @param params
 * @returns {Promise<*>}
 */


function getSettingList(params) {
  return request.get('/promotionSetting/findSettingList', {
    params: params
  });
}
/**
 * 级别设置修改
 * @param String promotionSettingCode
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePromotionLevel(promotionSettingCode, data) {
  return request.put("/promotionSetting/updateSetting/".concat(promotionSettingCode), data);
}
/**
 * 级别设置删除
 * @param {String} promotionSettingCode
 * @returns {Promise<*>}
 */


function delPromotionSetting(promotionSettingCode) {
  return request.delete("/promotionSetting/deletePromotionSetting/".concat(promotionSettingCode));
}
/**
 * 客户关系列表
 * @param params
 * @returns {Promise<*>}
 */


function getRelationshipList(params) {
  return request.get('/customerRelationship/findRelationshipList', {
    params: params
  });
}
/**
 * 客户关系修改
 * @param String customerCode
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateRelationship(customerCode, data) {
  return request.put("/customerRelationship/updateRelationship/".concat(customerCode), data);
}
/**
 * 添加客户关系
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addRelationship(data) {
  return request.post('/customerRelationship/addRelationship', data);
}
/**
 * 客户关系开启关闭
 * @param {Object} params
 * @returns {Promise<*>}
 */


function changeCustomerRelationship(params) {
  return request.post('/customerRelationship/openCustomerRelationship', null, {
    params: params
  });
}
/**
 * 金额变动记录
 * @param params
 * @returns {Promise<*>}
 */


function getAmountRecordPage(params) {
  return request.get('/amountRecord/amountRecordPage', {
    params: params
  });
}
/**
 * 获取一二三级佣金额度
 * @param params
 * @returns {Promise<*>}
 */


function getAmountLevel(params) {
  return request.get('/amountRecord/getAmountLevel', {
    params: params
  });
}
/**
 * 获取佣金总额 余额
 * @param params
 * @returns {Promise<*>}
 */


function getMaxRecord(params) {
  return request.get('/amountRecord/getMaxRecord', {
    params: params
  });
}
/**
 * 提现是否汇款
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setRemittanceState(params) {
  return request.post('/cashOut/ifRemittance', null, {
    params: params
  });
}
/**
 * 提现是否汇款
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setTaxState(params) {
  return request.post('/cashOut/ifTax', null, {
    params: params
  });
}
/**
 * 课程分销记录列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCourseRecordPage(params) {
  return request.get('/promotionRecord/courseRecordPage', {
    params: params
  });
}