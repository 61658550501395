"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _common = require("../../utils/common");

var _UserInfo = _interopRequireDefault(require("../../views/help/components/UserInfo"));

var _timeFilter = _interopRequireDefault(require("../../utils/filters/timeFilter"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'WorkOrderTabItem',
  components: {
    UserInfo: _UserInfo.default
  },
  filters: {
    parseTime: _timeFilter.default
  },
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    activeName: {
      type: String,
      default: undefined
    },
    index: {
      type: Number,
      default: undefined
    }
  },
  data: function data() {
    return {
      isShowTooltip: false
    };
  },
  methods: {
    cancelPopover: function cancelPopover(item, index) {
      this.$emit('cancelPopover', item, index);
    },
    handleForMe: function handleForMe(item, index) {
      this.$emit('handleForMe', item, index);
    },
    orderTransfer: function orderTransfer(val) {
      this.$emit('orderTransfer', val);
    },
    orderCancel: function orderCancel(val) {
      this.$emit('orderCancel', val);
    },
    // 来自评论快审-添加工单 评论中带有表情包需要特殊处理
    getEmoji: function getEmoji(val) {
      return (0, _common.replaceEmoji)(val);
    },
    informationUnreadNum: function informationUnreadNum(val) {
      if (val <= 0) {
        return 'order-label-top-no';
      }

      if (val >= 1 && val <= 9) {
        return 'order-label-top-small';
      }

      if (val >= 10 && val <= 99) {
        return 'order-label-top';
      }

      if (val > 99) {
        return 'order-label-top-big';
      }
    }
  }
};
exports.default = _default2;