var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看物流",
            visible: _vm.visible,
            width: "50%",
            "append-to-body": true,
            "before-close": _vm.beforeClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "dialog_body"
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "content_top" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.expressName + ":" + _vm.expressCode) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.expressList, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "content_body" },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "rgba(96, 98, 102, 1)",
                              "font-size": "14px"
                            }
                          },
                          [_vm._v(_vm._s(item.time))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "rgba(136, 136, 136, 1)",
                              "font-size": "14px",
                              "margin-top": "10px",
                              "margin-bottom": "15px"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.status) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-divider")
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }