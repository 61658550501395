"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var adminManagement = {
  namespaced: true,
  state: {
    roleId: undefined
  },
  mutations: {
    SETROLEID: function SETROLEID(state, roleId) {
      state.roleId = roleId;
    }
  },
  actions: {
    set_roleId: function set_roleId(_ref, data) {
      var commit = _ref.commit;
      commit('SETROLEID', data);
    }
  }
};
var _default = adminManagement;
exports.default = _default;