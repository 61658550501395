var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "errorContainer" }, [
    _c(
      "div",
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.back } },
          [_vm._v("返回")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "desText" }, [_vm._v("啊哦！")]),
        _vm._v(" "),
        _c("div", { staticClass: "noRightvisit" }, [
          _vm._v("404：您所访问的页面不存在")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contactAdministrator" }, [
          _vm._v("请确认要访问的链接地址无误。")
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: "/dashboard" } },
          [_c("el-link", { attrs: { type: "primary" } }, [_vm._v("返回概览")])],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "errorImage" }, [
      _c("img", {
        attrs: { src: require("@/assets/404_images/404img.png"), alt: "404" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }