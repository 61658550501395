var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "playout" },
    [
      _c("edit-product", {
        ref: "videoInfo",
        attrs: { loading: _vm.loading, "p-data-inited": _vm.isDataInited },
        on: { cancel: _vm.cancel, save: _vm.save },
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }