"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _typeIs = _interopRequireDefault(require("@taozhi/type-is"));

var _index = require("@/utils/cachedApi/common/index");

var _dictionary = require("@/utils/cachedApi/common/dictionary");

var _valueAddedServices = require("@/utils/cachedApi/valueAddedServices");

var _DefaultSyncInfo = _interopRequireDefault(require("../../../General/From/Selector/SyncInfo/DefaultSyncInfo"));

var _DefaultTagSelect = _interopRequireDefault(require("@/components/General/From/Selector/TagSelect/DefaultTagSelect"));

var _DefaultSelector = _interopRequireDefault(require("@/components/General/From/Selector/CommoditySecondaryCategories/DefaultSelector"));

var _TheUnderlyingColumn = _interopRequireDefault(require("./TheUnderlyingColumn"));

var _dictionary2 = require("../../../../api/common/dictionary");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Ge_FilterOptions_ConditionalFiltering',
  components: {
    TheUnderlyingColumn: _TheUnderlyingColumn.default,
    SyncInfo: _DefaultSyncInfo.default,
    TagSelect: _DefaultTagSelect.default,
    DefaultSelector: _DefaultSelector.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    showInventory: {
      type: Boolean,
      default: true
    },
    showLocalInventory: {
      type: Boolean,
      default: false
    },
    useMerchantBrandSeries: {
      type: Boolean,
      default: false
    },
    partnerOpenId: {
      type: String,
      default: undefined
    },
    presetPartnerOpenId: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      getSyncSubject: _index.getSyncSubject,
      getSyncSection: _index.getSyncSection,
      getSyncEdition: _index.getSyncEdition,
      getSyncGrade: _index.getSyncGrade,
      getSyncVolume: _index.getSyncVolume,
      getSyncElective: _dictionary.getSyncElective,
      getAllBookArea: _dictionary.getAllBookArea,
      getSyncPeriod: _dictionary.getSyncPeriod,
      getBrandList: _dictionary2.getBrandList,
      getAllBookSeries: _valueAddedServices.getAllBookSeries,
      getSeriesList: _dictionary2.getSeriesList
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    activePartnerOpenId: function activePartnerOpenId() {
      if (_typeIs.default.isString(this.presetPartnerOpenId) && !_typeIs.default.stringIsBlank(this.presetPartnerOpenId)) {
        return this.presetPartnerOpenId;
      }

      return this.partnerOpenId;
    },
    getSeriesListSearchQuery: function getSeriesListSearchQuery() {
      var res = {
        orderBy: 'sequence',
        sortOrder: 'desc',
        size: 99999
      };

      if (typeof this.valueProxy.brandOpenId === 'string' && this.valueProxy.brandOpenId.trim().length > 0) {
        res.brandOpenId = this.valueProxy.brandOpenId;
      }

      if (this.useMerchantBrandSeries) {
        res.partnerOpenId = this.activePartnerOpenId;
      }

      return res;
    },
    getBrandListSearchQuery: function getBrandListSearchQuery() {
      var res = {
        orderBy: 'sequence',
        sortOrder: 'desc'
      };

      if (this.useMerchantBrandSeries) {
        res.partnerOpenId = this.activePartnerOpenId;
      }

      return res;
    },
    disableSeriesCode: function disableSeriesCode() {
      return typeof this.valueProxy.brandOpenId !== 'string' || this.valueProxy.brandOpenId.trim().length < 1;
    },
    inventoryLabel: function inventoryLabel() {
      return this.showInventory ? '库存' : '本地库存';
    }
  },
  watch: {
    'valueProxy.brandOpenId': {
      handler: function handler(nVal, oVal) {
        var _this = this;

        if (nVal === oVal) {
          return;
        } // 此时值未变


        this.$nextTick(function () {
          if ((0, _typeof2.default)(_this.$refs.subjectList) !== 'object' || _this.$refs.subjectList === null) {
            return;
          } // 有时 subjectList 组件可能未加载


          _this.valueProxy.seriesCode = undefined;

          _this.$refs.subjectList.updateData();
        });
      },
      deep: true
    }
  }
};
exports.default = _default2;