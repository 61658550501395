"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWordList = getWordList;
exports.checkIsThereWord = checkIsThereWord;
exports.addWord = addWord;
exports.updateWord = updateWord;
exports.deleteWord = deleteWord;
exports.addCharPinyin = addCharPinyin;
exports.updateCharPinyin = updateCharPinyin;
exports.deleteCharPinyin = deleteCharPinyin;
exports.getWordDetail = getWordDetail;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/chineseChars/"));
/* 获取字列表 */

function getWordList(params) {
  return request.get('findPage', {
    params: params
  });
}
/* 查询字是否存在 */


function getWordDetail(chars) {
  return request.get('findChar', {
    params: {
      chars: chars
    }
  });
}
/* 查询字是否存在 */


function checkIsThereWord(chars) {
  return request.get('checkChars', {
    params: {
      chars: chars
    }
  });
}
/* 添加字 */


function addWord(params) {
  return request.post('addChars', params);
}
/* 修改字 */


function updateWord(params, charOpenId) {
  return request.put("updateChars/".concat(charOpenId), params);
}
/* 删除字 */


function deleteWord(charOpenId) {
  return request.delete("deleteChars/".concat(charOpenId));
}
/* 添加拼音-多音字 */


function addCharPinyin(params) {
  return request.post("addCharPinyin/".concat(params.charOpenId), params);
}
/* 修改拼音 */


function updateCharPinyin(params, id) {
  return request.put("updateCharPinyin/".concat(id), params);
}
/* 删除拼音 */


function deleteCharPinyin(id) {
  return request.delete("deleteCharPinyin/".concat(id));
}