var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-management" },
    [
      _c("div", { staticClass: "statistics" }, [
        _c("div", { staticClass: "statistics-item" }, [
          _c("div", { staticClass: "title-view" }, [
            _c("p", { staticClass: "title" }, [_vm._v("工单总数")]),
            _vm._v(" "),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.statistics.currentDate))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _c("p", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.statistics.totalNumberOfWorkOrders))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statistics-item" }, [
          _c("div", { staticClass: "title-view" }, [
            _c("p", { staticClass: "title" }, [_vm._v("待处理工单数")]),
            _vm._v(" "),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.statistics.currentDate))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _c("p", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.statistics.totalNumberOfPendingWorkOrders))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statistics-item" }, [
          _c("div", { staticClass: "title-view" }, [
            _c("p", { staticClass: "title" }, [_vm._v("今日新增工单")]),
            _vm._v(" "),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.statistics.currentDate))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _c("p", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.statistics.numberOfWorkOrdersPendingToday))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statistics-item" }, [
          _c("div", { staticClass: "title-view" }, [
            _c("p", { staticClass: "title" }, [_vm._v("3天以上未处理")]),
            _vm._v(" "),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.statistics.currentDate))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _c("p", { staticClass: "number" }, [
              _vm._v(
                _vm._s(
                  _vm.statistics.numberOfUnprocessedWorkOrdersOverThreeDays
                )
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "middle-content" }, [
        _c(
          "div",
          { staticClass: "nav" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addWorkOrder } },
              [_vm._v("添加")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "nav-search" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "search-radio",
                    on: { change: _vm.searchContent },
                    model: {
                      value: _vm.searchStatus,
                      callback: function($$v) {
                        _vm.searchStatus = $$v
                      },
                      expression: "searchStatus"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "0" } }, [
                      _vm._v("全部")
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "1" } }, [
                      _vm._v("待处理")
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "3" } }, [
                      _vm._v("已处理")
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "2" } }, [
                      _vm._v("无需处理")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("search-user-by-input", {
                  on: { receiveuserid: _vm.receiveUserId }
                }),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "search-input",
                  attrs: { placeholder: "工单号搜索", clearable: "" },
                  model: {
                    value: _vm.searchVal,
                    callback: function($$v) {
                      _vm.searchVal = $$v
                    },
                    expression: "searchVal"
                  }
                }),
                _vm._v(" "),
                _c(
                  "search-btn",
                  { on: { searchContent: _vm.searchContent } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "search-form",
                        attrs: { model: _vm.query, "label-width": "100px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "工单分类" } },
                          [
                            _c("classification-cascade", {
                              on: { categoryChange: _vm.categoryChange }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "优先级别" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "select-common",
                                attrs: { placeholder: "全部", clearable: "" },
                                model: {
                                  value: _vm.searchPriority,
                                  callback: function($$v) {
                                    _vm.searchPriority = $$v
                                  },
                                  expression: "searchPriority"
                                }
                              },
                              _vm._l(_vm.priorityOptions, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "创建时间" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetimerange",
                                "default-time": ["00:00:00", "23:59:59"],
                                align: "right",
                                "unlink-panels": "",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd HH:mm:ss"
                              },
                              on: { change: _vm.handleSearchTimer },
                              model: {
                                value: _vm.timer,
                                callback: function($$v) {
                                  _vm.timer = $$v
                                },
                                expression: "timer"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      width: "110",
                      trigger: "manual",
                      "popper-class": "work-order-manage-popover"
                    },
                    model: {
                      value: _vm.showBigPopover,
                      callback: function($$v) {
                        _vm.showBigPopover = $$v
                      },
                      expression: "showBigPopover"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "work-order-popover-item",
                        on: { click: _vm.handleWorkClassification }
                      },
                      [_vm._v("工单分类")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "work-order-popover-item",
                        on: { click: _vm.handleAssignment }
                      },
                      [_vm._v("工单分配")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "work-order-popover-item",
                        on: { click: _vm.handleDefaultReply }
                      },
                      [_vm._v("预设回复")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          width: "330",
                          trigger: "click"
                        },
                        on: { show: _vm.show, hide: _vm.hide }
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.handleCheckedCitiesChange },
                            model: {
                              value: _vm.checkList,
                              callback: function($$v) {
                                _vm.checkList = $$v
                              },
                              expression: "checkList"
                            }
                          },
                          _vm._l(_vm.customList, function(item, index) {
                            return _c(
                              "el-checkbox",
                              {
                                key: index,
                                staticStyle: {
                                  margin: "5px 0 5px 10px",
                                  width: "90px"
                                },
                                attrs: {
                                  label: item,
                                  disabled: item === "工单号" || item === "操作"
                                }
                              },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "work-order-popover-item",
                            class: [
                              _vm.showPopover ? "active-popover-item" : ""
                            ],
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [_vm._v("自定义列表")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "type-button",
                        attrs: { slot: "reference" },
                        on: {
                          click: function($event) {
                            _vm.showBigPopover = !_vm.showBigPopover
                          }
                        },
                        slot: "reference"
                      },
                      [
                        _c("el-button", {
                          attrs: { plain: "", icon: "el-icon-s-tools" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: { height: "100%", data: _vm.workList }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "workOrderOpenId",
                    label: "工单号",
                    width: "230px",
                    fixed: "left"
                  }
                }),
                _vm._v(" "),
                !_vm.showDescribe
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "priority",
                        label: "工单描述",
                        width: "350px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.linkSetting
                                  ? _c("i", {
                                      staticClass:
                                        "iconfont icon-gongdanlianjie table-link-icon"
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.workorderImgUrls
                                  ? _c("i", { staticClass: "el-icon-picture" })
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.workorderDescribe
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEmoji(
                                            scope.row.workorderDescribe
                                          )
                                        )
                                      }
                                    })
                                  : _c("span", [_vm._v("--")])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1500766982
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showPriority
                  ? _c("el-table-column", {
                      attrs: { prop: "priority", label: "优先级" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.priority === "低"
                                  ? _c(
                                      "span",
                                      { staticClass: "priority-text-low" },
                                      [_vm._v("低")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.priority === "中"
                                  ? _c(
                                      "span",
                                      { staticClass: "priority-text-middle" },
                                      [_vm._v("中")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.priority === "高"
                                  ? _c(
                                      "span",
                                      { staticClass: "priority-text-height" },
                                      [_vm._v("高")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.priority === "急"
                                  ? _c(
                                      "span",
                                      { staticClass: "priority-text-urgent" },
                                      [_vm._v("急")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1880825705
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showClass
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "workorderClassificationName",
                        "show-overflow-tooltip": true,
                        label: "分类",
                        width: "300px"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showUser
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "userInfo",
                        label: "用户",
                        "show-overflow-tooltip": true,
                        width: "200px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("user-image-and-name", {
                                  attrs: {
                                    "user-info": scope.row.userInfo,
                                    "is-show-tooltip": false
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        940886804
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showCreateAt
                  ? _c("el-table-column", {
                      attrs: { prop: "updateTime", label: "创建时间" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm._f("parseTime")(
                                        scope.row.createdAt,
                                        "{y}-{m}-{d}"
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1532356395
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showStatus
                  ? _c("el-table-column", {
                      attrs: { prop: "status", label: "工单状态" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.status === 1
                                  ? _c("span", [_vm._v("待处理")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 3
                                  ? _c("span", [_vm._v("已处理")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 2
                                  ? _c("span", [_vm._v("无需处理")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2337585048
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showCreateUser
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "createUser",
                        label: "创建人",
                        "show-overflow-tooltip": true,
                        width: "200px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("user-image-and-name", {
                                  attrs: {
                                    "user-info": scope.row.createUser,
                                    "is-show-tooltip": false
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3961819646
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showAppProductId
                  ? _c("el-table-column", {
                      attrs: { label: "创建类型", prop: "appProductId" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.appOpenId === scope.row.appProductId
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "plain-tag",
                                        attrs: { size: "mini", effect: "dark" }
                                      },
                                      [_vm._v("平台")]
                                    )
                                  : _c(
                                      "el-tag",
                                      {
                                        staticClass: "plain-tag",
                                        attrs: {
                                          type: "warning",
                                          size: "mini",
                                          effect: "dark"
                                        }
                                      },
                                      [_vm._v("用户")]
                                    )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1037701254
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showTakeUser
                  ? _c("el-table-column", {
                      attrs: {
                        label: "处理人",
                        "show-overflow-tooltip": true,
                        width: "200px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.takeUserId
                                  ? _c("user-image-and-name", {
                                      attrs: {
                                        "user-info": scope.row.takeUser,
                                        "is-show-tooltip": false
                                      }
                                    })
                                  : _c("div", [_vm._v("--")])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2370208390
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    label: "操作",
                    width: "100px",
                    fixed: "right"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function($event) {
                                  return _vm.handleDetails(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("Pagination", {
              attrs: {
                total: _vm.workTotalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.getWorkOrderListData
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          ref: "classificationTable",
          staticClass: "classification-dialog",
          attrs: {
            title: "工单分类管理",
            width: "1000px",
            visible: _vm.classificationDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.classificationDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "classification-nav" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.addClassification }
                },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "classification-search" },
                [
                  _c("el-input", {
                    staticClass: "classification-input",
                    attrs: { placeholder: "分类名称搜索", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.classificationSearch($event)
                      }
                    },
                    model: {
                      value: _vm.searchClassificationVal,
                      callback: function($$v) {
                        _vm.searchClassificationVal = $$v
                      },
                      expression: "searchClassificationVal"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.classificationSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "classification-dialog-table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.classificationLoading,
                      expression: "classificationLoading"
                    }
                  ],
                  ref: "classificationTable",
                  staticClass: "classification-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "350px",
                    data: _vm.workClassificationList,
                    "row-key": "id",
                    "tooltip-effect": "dark",
                    "default-expand-all": "",
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "classificationName",
                      label: "分类名称",
                      width: "230",
                      "show-overflow-tooltip": true
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", width: "100" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分类图标", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.imgUrl
                              ? _c("el-image", {
                                  staticClass: "class-icon",
                                  attrs: {
                                    fit: "cover",
                                    src:
                                      scope.row.imgUrl.substring(0, 4) ===
                                      "http"
                                        ? scope.row.imgUrl
                                        : _vm.getImgUrl(scope.row.imgUrl)
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !scope.row.imgUrl
                              ? _c("span", [_vm._v("--")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "是否显示", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleSwitch(scope.row)
                                }
                              },
                              model: {
                                value: scope.row.clientDisplay,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "clientDisplay", $$v)
                                },
                                expression: "scope.row.clientDisplay"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "提示语", prop: "suggestiveLanguage" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  disabled: _vm.disabledSuggestive(
                                    scope.row.suggestiveLanguage
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "max-width": "252px!important",
                                      "overflow-wrap": "break-word",
                                      "word-break": "break-all"
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [_vm._v(_vm._s(scope.row.suggestiveLanguage))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "suggestive-language" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("beautifulTextFilter")(
                                          scope.row.suggestiveLanguage
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "right", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                staticClass: "edit-link",
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function($event) {
                                    return _vm.handleClassificationEdit(
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-link",
                              {
                                attrs: { type: "danger", underline: false },
                                on: {
                                  click: function($event) {
                                    return _vm.handleClassificationDelete(
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.classificationTotal,
                  page: _vm.classificationQuery.page,
                  limit: _vm.classificationQuery.size,
                  "current-page": _vm.classificationQuery.page
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.classificationQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.classificationQuery, "size", $event)
                  },
                  pagination: _vm.getWorkClassificationData
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add-classification-dialog",
          attrs: {
            title: _vm.addClassificationTitle,
            visible: _vm.addClassificationDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "530px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addClassificationDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addClassificationForm",
              attrs: {
                model: _vm.classificationForm,
                rules: _vm.addClassificationRules,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "classificationName" } },
                [
                  _c("el-input", {
                    staticClass: "classification-input",
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": "",
                      placeholder: "请输入分类名称"
                    },
                    model: {
                      value: _vm.classificationForm.classificationName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.classificationForm,
                          "classificationName",
                          $$v
                        )
                      },
                      expression: "classificationForm.classificationName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "选择所属分类", clearable: "" },
                      on: { change: _vm.handleNewParentId },
                      model: {
                        value: _vm.newParentId,
                        callback: function($$v) {
                          _vm.newParentId = $$v
                        },
                        expression: "newParentId"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "infinite-scroll",
                              rawName: "v-infinite-scroll",
                              value: _vm.loadScroll,
                              expression: "loadScroll"
                            }
                          ],
                          staticStyle: { height: "200px", overflow: "auto" },
                          attrs: {
                            "infinite-scroll-immediate": "false",
                            "infinite-scroll-disabled": "disabled"
                          }
                        },
                        _vm._l(_vm.addWorkClassificationList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.classificationName,
                              disabled: item.disabled,
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "分类图标" } }, [
                _c(
                  "div",
                  { staticClass: "cover-upload" },
                  [
                    _c("cover-upload", {
                      attrs: {
                        path: _vm.path,
                        coverarr: _vm.coverArr,
                        covertext: _vm.coverText,
                        type: _vm.type,
                        texttips: _vm.textTips
                      },
                      on: { deletecover: _vm.deleteCoverFun },
                      model: {
                        value: _vm.classificationForm.imgUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.classificationForm, "imgUrl", $$v)
                        },
                        expression: "classificationForm.imgUrl"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "提示语 " } },
                [
                  _c("el-input", {
                    staticClass: "textarea",
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      rows: 6,
                      placeholder: "请输入提示语",
                      maxlength: "200",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.classificationForm.suggestiveLanguage,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.classificationForm,
                          "suggestiveLanguage",
                          $$v
                        )
                      },
                      expression: "classificationForm.suggestiveLanguage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.showClassPriority
                ? _c(
                    "el-form-item",
                    { attrs: { label: "优先级" } },
                    [
                      _c("el-radio", {
                        attrs: { label: "低" },
                        model: {
                          value: _vm.classificationForm.priority,
                          callback: function($$v) {
                            _vm.$set(_vm.classificationForm, "priority", $$v)
                          },
                          expression: "classificationForm.priority"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-radio", {
                        attrs: { label: "中" },
                        model: {
                          value: _vm.classificationForm.priority,
                          callback: function($$v) {
                            _vm.$set(_vm.classificationForm, "priority", $$v)
                          },
                          expression: "classificationForm.priority"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-radio", {
                        attrs: { label: "高" },
                        model: {
                          value: _vm.classificationForm.priority,
                          callback: function($$v) {
                            _vm.$set(_vm.classificationForm, "priority", $$v)
                          },
                          expression: "classificationForm.priority"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-radio", {
                        attrs: { label: "急" },
                        model: {
                          value: _vm.classificationForm.priority,
                          callback: function($$v) {
                            _vm.$set(_vm.classificationForm, "priority", $$v)
                          },
                          expression: "classificationForm.priority"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showQuestion
                ? _c(
                    "el-form-item",
                    { attrs: { label: "问题类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "选择问题类型", clearable: "" },
                          model: {
                            value: _vm.classificationForm.questionType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.classificationForm,
                                "questionType",
                                $$v
                              )
                            },
                            expression: "classificationForm.questionType"
                          }
                        },
                        _vm._l(_vm.questionList, function(item) {
                          return _c("el-option", {
                            key: item.lable,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "是否显示" } }, [
                _c(
                  "div",
                  { staticClass: "client-display" },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949"
                      },
                      model: {
                        value: _vm.classificationForm.clientDisplay,
                        callback: function($$v) {
                          _vm.$set(_vm.classificationForm, "clientDisplay", $$v)
                        },
                        expression: "classificationForm.clientDisplay"
                      }
                    }),
                    _vm._v(" "),
                    _c("i", { staticClass: "icon el-icon-info" }),
                    _c("span", { staticClass: "icon-font-switch" }, [
                      _vm._v("开启后则在帮助中心出现")
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "排序" } }, [
                _c(
                  "div",
                  {
                    staticClass: "classification-foot",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("el-input-number", {
                          staticClass: "sequence",
                          staticStyle: { width: "200px" },
                          attrs: { precision: 0, "controls-position": "right" },
                          model: {
                            value: _vm.classificationForm.sequence,
                            callback: function($$v) {
                              _vm.$set(_vm.classificationForm, "sequence", $$v)
                            },
                            expression: "classificationForm.sequence"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "数字越大，排位越靠前",
                              placement: "top"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "icon el-icon-info",
                              staticStyle: {
                                "font-size": "14px",
                                color: "#409EFF",
                                "margin-left": "10px"
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.addClassificationDialog = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.submitClassificationLoading
                            },
                            on: { click: _vm.submitClassification }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add-details-dialog",
          attrs: {
            title: "工单详情",
            visible: _vm.detailsDialog,
            "before-close": _vm.cancelDetailsDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.detailsDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "details" },
            [
              _c("work-order-details", {
                attrs: {
                  "details-id": _vm.detailsId,
                  "refresh-detail": _vm.refreshDetail,
                  contentHeight: "500px"
                },
                on: {
                  toUserDetails: _vm.toUserDetails,
                  handleEnd: _vm.handleEnd,
                  getDetailData: _vm.getDetailData
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("default-reply", {
        attrs: { visible: _vm.defaultReply },
        on: {
          "update:visible": function($event) {
            _vm.defaultReply = $event
          }
        }
      }),
      _vm._v("replyDialog\n  "),
      _vm._v(" "),
      _c("work-order-assignment", {
        attrs: { visible: _vm.assignment },
        on: {
          "update:visible": function($event) {
            _vm.assignment = $event
          }
        }
      }),
      _vm._v(" "),
      _c("user-info", {
        attrs: {
          visible: _vm.dialogUserDetail,
          "user-open-id": _vm.currentUserOpenId,
          "prop-active-name": _vm.propActiveName
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogUserDetail = $event
          },
          clearuserOpenId: _vm.clearUserOpenId
        }
      }),
      _vm._v(" "),
      _c("add-work-order", {
        ref: "addWorkDialog",
        attrs: { visible: _vm.addDialog },
        on: {
          "update:visible": function($event) {
            _vm.addDialog = $event
          },
          submitAddWork: _vm.submitAddWork
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }