"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addEBook = addEBook;
exports.batchEbookAttribute = batchEbookAttribute;
exports.batchEbookCaseAttribute = batchEbookCaseAttribute;
exports.updateEbookFolder = updateEbookFolder;
exports.getEBookList = getEBookList;
exports.getEBookView = getEBookView;
exports.editEBook = editEBook;
exports.getOperationLog = getOperationLog;
exports.batchTakeDown = batchTakeDown;
exports.batchTakeUp = batchTakeUp;
exports.batchTakeDelete = batchTakeDelete;
exports.copyEbook = copyEbook;
exports.addEBookClass = addEBookClass;
exports.editEBookClass = editEBookClass;
exports.getEBookClass = getEBookClass;
exports.delEBookClass = delEBookClass;
exports.addBookCase = addBookCase;
exports.editBookCase = editBookCase;
exports.getBookCase = getBookCase;
exports.getBookCaseList = getBookCaseList;
exports.copyBookCaseList = copyBookCaseList;
exports.batchTakeDownByOpenIds = batchTakeDownByOpenIds;
exports.batchShelfByOpenIds = batchShelfByOpenIds;
exports.batchDeleteByOpenIds = batchDeleteByOpenIds;
exports.getSubEbookCase = getSubEbookCase;
exports.addSubBookCase = addSubBookCase;
exports.addSubBook = addSubBook;
exports.addEBookTown = addEBookTown;
exports.getEBookTown = getEBookTown;
exports.editEBookTown = editEBookTown;
exports.deleteEBookTown = deleteEBookTown;
exports.addEBookTownContent = addEBookTownContent;
exports.getEBookTownContent = getEBookTownContent;
exports.pdfToImg = pdfToImg;
exports.addEBookMenu = addEBookMenu;
exports.getEBookMenu = getEBookMenu;
exports.editEBookMenu = editEBookMenu;
exports.delEBookMenu = delEBookMenu;
exports.getParentInformation = getParentInformation;
exports.deleteTownContent = deleteTownContent;
exports.copyEbookTown = copyEbookTown;
exports.removeEBookClass = removeEBookClass;
exports.ebookBatch = ebookBatch;
exports.getDownloadPath = getDownloadPath;
exports.batchDeleteEbookCase = batchDeleteEbookCase;
exports.batchCopyEbookByOpenIds = batchCopyEbookByOpenIds;
exports.batchCopyEbookCaseByOpenIds = batchCopyEbookCaseByOpenIds;
exports.updateBatchBookCaseContent = updateBatchBookCaseContent;
exports.updateShowTitleByEbookCaseOpenId = updateShowTitleByEbookCaseOpenId;
exports.batchEbookTown = batchEbookTown;
exports.getEbooks = getEbooks;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/e-book/admin"));
var requestOpenFeign = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/e-book/openFeign"));
/**
 * 新增电子书
 * @param {Object} data
 */

function addEBook(data) {
  return request.post('/ebook/add', data);
}
/**
 * 编辑电子书
 * @param {Object} data
 */


function editEBook(data) {
  return request.post('/ebook/update', data);
}
/**
 * 分页获取电子书列表
 * @param {Object} data
 */


function getEBookList(params) {
  return request.get('/ebook/list', {
    params: params
  });
}
/**
 * 分页获取电子书列表
 * @param {Object} data
 */


function getEbooks(params) {
  return request.get('/ebook/getEbooks', {
    params: params
  });
}
/**
 * 查看电子书
 * @param {Object} data
 */


function getEBookView(data) {
  return request.post('/ebook/view', data);
}
/**
 * 分页获取电子书，电子书橱相关操作日志
 * @param {Object} params
 */


function getOperationLog(params) {
  return request.get('/ebook/queryOperationLog', {
    params: params
  });
}
/**
 * 批量下架
 * @param {Object} params
 */


function batchTakeDown(params) {
  return request.delete('/ebook/batchTakeDownByOpenIds', {
    params: params
  });
}
/**
 * 批量上架
 * @param {Object} params
 */


function batchTakeUp(params) {
  return request.delete('/ebook/batchShelfByOpenIds', {
    params: params
  });
}
/**
 * 批量删除
 * @param {Object} params
 */


function batchTakeDelete(params) {
  return request.delete('/ebook/batchDeleteByOpenIds', {
    params: params
  });
}
/**
 * 复制电子书
 * @param {Object} params
 */


function copyEbook(data) {
  return request.post('/ebook/copyEbook', data);
}
/**
 * 新增分类
 * @param {Object} data
 */


function addEBookClass(data) {
  return request.post('/ebookClassification/add', data);
}
/**
 * 编辑分类
 * @param {Object} data
 */


function editEBookClass(data) {
  return request.post('/ebookClassification/update', data);
}
/**
 * 移动分类
 * @param {Object} params
 */


function removeEBookClass(params) {
  return request.patch('/ebookClassification/moveEbookClassification', null, {
    params: params
  });
}
/**
 * 批量修改分类
 * @param {Object} params
 */


function ebookBatch(params) {
  return request.patch('/ebookClassification/batch', params);
}
/**
 * 分类列表
 * @param {Object} params
 */


function getEBookClass(params) {
  return request.get('/ebookClassification/list', {
    params: params
  });
}
/**
 * 删除分类
 * @param {Object} data
 */


function delEBookClass(data) {
  return request.post('/ebookClassification/deleteById', data);
}
/**
 * 新增电子书橱
 * @param {Object} data
 */


function addBookCase(data) {
  return request.post('/ebookCase/add', data);
}
/**
 * 修改电子书橱
 * @param {Object} data
 */


function editBookCase(data) {
  return request.post('/ebookCase/update', data);
}
/**
 * 查看电子书橱
 * @param {Object} data
 */


function getBookCase(data) {
  return request.post('/ebookCase/view', data);
}
/**
 * 查看电子书橱
 * @param {Object} params
 */


function getBookCaseList(params) {
  return request.get('/ebookCase/list', {
    params: params
  });
}
/**
 * 复制电子书橱
 * @param {Object} data
 */


function copyBookCaseList(data) {
  return request.post('/ebookCase/copyEbookCase', data);
}
/**
 * 书橱 批量下架
 * @param {Object} params
 */


function batchTakeDownByOpenIds(params) {
  return request.delete('/ebookCase/batchTakeDownByOpenIds', {
    params: params
  });
}
/**
 * 书橱 批量上架
 * @param {Object} params
 */


function batchShelfByOpenIds(params) {
  return request.delete('/ebookCase/batchShelfByOpenIds', {
    params: params
  });
}
/**
 * 书橱 批量删除
 * @param {Object} params
 */


function batchDeleteByOpenIds(params) {
  return request.delete('/ebookCase/batchDeleteByOpenIds', {
    params: params
  });
}
/**
 * 批量删除书橱内容
 * @param {Object} params
 */


function batchDeleteEbookCase(params) {
  return request.delete('/ebookCase/batchDeleteEbookCaseContentByOpenIds', {
    params: params
  });
}
/**
 * 根据电子书橱openId获取直属子集内容
 * @param {Object} params
 */


function getSubEbookCase(params) {
  return request.get('/ebookCase/getSubEbookCaseContentByEbookCaseOpenId', {
    params: params
  });
}
/**
 * 添加子书橱
 * @param {Object} data
 */


function addSubBookCase(data) {
  return request.post('/ebookCase/addSubBookCase', data);
}
/**
 * 添加电子书
 * @param {Object} data
 */


function addSubBook(data) {
  return request.post('/ebookCase/addSubBook', data);
}
/**
 * 新增书城
 * @param {Object} data
 */


function addEBookTown(data) {
  return request.post('/ebookTown/add', data);
}
/**
 * 修改书城
 * @param {Object} data
 */


function editEBookTown(data) {
  return request.post('/ebookTown/update', data);
}
/**
 * 获取书城列表
 * @param {Object} params
 */


function getEBookTown(params) {
  return request.get('/ebookTown/list', {
    params: params
  });
}
/**
 * 删除书城
 * @param {Object} data
 */


function deleteEBookTown(data) {
  return request.post('/ebookTown/deleteById', data);
}
/**
 * 新增电子书城内容
 * @param {Object} data
 */


function addEBookTownContent(data) {
  return request.post('/ebookTownContent/add', data);
}
/**
 * 获取电子书城内容列表
 * @param {Object} params
 */


function getEBookTownContent(params) {
  return request.get('ebookTownContent/list', {
    params: params
  });
}
/**
 * 电子书PDF转图片
 * @param {Object} data
 */


function pdfToImg(data) {
  return requestOpenFeign.post('ebook/pdfToImg', null, {
    params: data
  });
}
/**
 * 新增目录
 * @param {Object} data
 */


function addEBookMenu(data) {
  return request.post('ebookMenu/add', data);
}
/**
 * 修改目录
 * @param {Object} data
 */


function editEBookMenu(data) {
  return request.post('ebookMenu/update', data);
}
/**
 * 获取目录列表
 * @param {Object} params
 */


function getEBookMenu(params) {
  return request.get('ebookMenu/list', {
    params: params
  });
}
/**
 * 删除目录
 * @param {Object} data
 */


function delEBookMenu(data) {
  return request.post('ebookMenu/deleteById', data);
}
/**
 * 根据电子书橱openId获取父级信息（顺序递归）
 * @param {Object} params
 */


function getParentInformation(params) {
  return request.get('ebookCase/getParentInformationByEbookCaseOpenId', {
    params: params
  });
}
/**
 * 电子书城内容 批量删除
 * @param {Object} params
 */


function deleteTownContent(params) {
  return request.delete('ebookTownContent/batchDeleteByOpenIds', {
    params: params
  });
}
/**
 * 根据书城openId复制书城及其内容
 * @param {Object} data
 */


function copyEbookTown(data) {
  return request.post('ebookTownContent/copyEbookTown', data);
}
/**
 * 获取电子书文件下载地址
 * @param {Object} data
 */


function getDownloadPath(id) {
  return request.get("/ebook/request/download/".concat(id));
}
/**
 * 批量复制电子书
 * @param {Object} params
 */


function batchCopyEbookByOpenIds(params) {
  return request.post('/ebook/batchCopyEbookByOpenIds', null, {
    params: params
  });
}
/**
 * 批量复制书橱
 * @param {Object} params
 */


function batchCopyEbookCaseByOpenIds(params) {
  return request.post('/ebookCase/batchCopyEbookCaseByOpenIds', null, {
    params: params
  });
}
/**
 * 批量修改书橱内容排序
 * @param {Object} body
 */


function updateBatchBookCaseContent(body) {
  return request.patch('/ebookCase/updateBatchBookCaseContent', body);
}
/**
 * 根据父书橱openId修改标题在客户端显示状态
 * @param {Object} body
 */


function updateShowTitleByEbookCaseOpenId(body) {
  return request.post('/ebookCase/updateShowTitleByEbookCaseOpenId', body);
}
/**
 * 批量更新电子书城内容 (拖拽排序)
 * @param {Object} body
 */


function batchEbookTown(body) {
  return request.patch('ebookTownContent/batch', body);
}
/**
 * 批量更新电子书属性
 * @param {Object} body
 */


function batchEbookAttribute(params) {
  return request.patch('ebook/request/batch/attribute', undefined, {
    params: params
  });
}
/**
 * 批量更新书橱书属性
 * @param {Object} body
 */


function batchEbookCaseAttribute(params) {
  return request.patch('ebookCase/request/batch/attribute', undefined, {
    params: params
  });
}
/**
 * 电子书添加夹册子
 * @param {Object} body
 */


function updateEbookFolder(data) {
  return request.post('ebook/updateEbookFolder', data);
}