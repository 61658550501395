var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questionPreview" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "试题预览",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "previewBody" }, [
            _c("div", { staticClass: "questionInfo" }, [
              _c("div", { staticClass: "questionDetails" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n            查看试题详细信息\n          ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "details" },
                  [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          float: "left",
                          "margin-right": "5px",
                          "margin-top": "-3px"
                        },
                        attrs: { size: "small" }
                      },
                      [_vm._v(_vm._s(_vm.questionDetails.questionTypeName))]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "stemDetails",
                      domProps: { innerHTML: _vm._s(_vm.questionDetails.stem) }
                    }),
                    _vm._v(" "),
                    _vm.questionDetails.video !== "" &&
                    _vm.questionDetails.video
                      ? _c(
                          "div",
                          {
                            staticClass: "analysisVideo",
                            on: {
                              mouseleave: function($event) {
                                return _vm.hiddenPreview("video")
                              },
                              mouseenter: function($event) {
                                return _vm.showPreview("video")
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "info" }, [
                              _c("i", { staticClass: "el-icon-video-camera" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "videoTitle" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.questionDetails.videoName) +
                                    "\n                "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "operation" }, [
                              !_vm.showVideo
                                ? _c("i", { staticClass: "el-icon-success" })
                                : _c("i", {
                                    staticClass: "el-icon-search",
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#000000"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.seeVideo(
                                          _vm.questionDetails.video
                                        )
                                      }
                                    }
                                  })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1001 ||
                    _vm.questionDetails.examQuestionTypeCode === 1002
                      ? _c(
                          "div",
                          { staticClass: "option" },
                          [
                            _vm._l(_vm.questionDetails.optionText[0], function(
                              question,
                              questionIndex
                            ) {
                              return _c(
                                "div",
                                { key: questionIndex, staticClass: "item" },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "5px 0" } },
                                    [
                                      _vm._v(
                                        _vm._s(questionIndex.toUpperCase()) +
                                          ": "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(question)
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "answer" }, [
                              _vm._v(
                                "\n                正确答案: " +
                                  _vm._s(
                                    _vm.questionDetails.rightAnswerContent.toUpperCase()
                                  ) +
                                  "\n              "
                              )
                            ])
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1003
                      ? _c("div", { staticClass: "option" }, [
                          _c("div", { staticClass: "item" }, [
                            _vm._v("\n                正确\n              ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item" }, [
                            _vm._v("\n                错误\n              ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "answer" }, [
                            _vm._v(
                              "\n                正确答案: " +
                                _vm._s(
                                  _vm.questionDetails.rightAnswerContent ===
                                    "no"
                                    ? "错误"
                                    : "正确"
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1004
                      ? _c("div", { staticClass: "option" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                _vm._l(
                                  _vm.questionDetails.optionText[0],
                                  function(question, questionIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: questionIndex,
                                        staticClass: "item connectionOptions"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "optionIndex" },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  questionIndex.toUpperCase()
                                                ) + ":"
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(question)
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column"
                                  }
                                },
                                _vm._l(_vm.questionDetails.optionList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "option" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            margin: "5px"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(index + 1))
                                          ]),
                                          _vm._v("."),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(item)
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "answer" },
                            [
                              _c("div", [_vm._v("正确答案: ")]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.questionDetails.rightAnswerContent,
                                function(answer, answerIndex) {
                                  return _c("div", { key: answerIndex }, [
                                    _c(
                                      "div",
                                      { staticStyle: { margin: "5px" } },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(answerIndex) +
                                            " -- " +
                                            _vm._s(answer) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                }
                              )
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1005
                      ? _c("div", { staticClass: "option" }, [
                          _c(
                            "div",
                            { staticClass: "answer write" },
                            [
                              _vm._v(
                                "\n                正确答案:\n                "
                              ),
                              _vm._l(
                                _vm.questionDetails.rightAnswerContent,
                                function(question, questionIndex) {
                                  return _c("span", { key: questionIndex }, [
                                    _vm._v(_vm._s(question[0]) + ",")
                                  ])
                                }
                              )
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1006
                      ? _c(
                          "div",
                          { staticClass: "option" },
                          [
                            _vm._l(_vm.questionDetails.optionText[0], function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.letterList[index - 1]) +
                                      ": " +
                                      _vm._s(item) +
                                      "\n              "
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "answer" },
                              [
                                _vm._v(
                                  "\n                正确答案:\n                "
                                ),
                                _vm._l(
                                  _vm.questionDetails.rightAnswerContent,
                                  function(item, index) {
                                    return _c("span", { key: index }, [
                                      _vm._v(_vm._s(item) + ",")
                                    ])
                                  }
                                )
                              ],
                              2
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1007
                      ? _c(
                          "div",
                          { staticClass: "option" },
                          [
                            _vm._l(_vm.questionDetails.optionList, function(
                              option,
                              optionIndex
                            ) {
                              return _c(
                                "div",
                                { key: optionIndex, staticClass: "item" },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(optionIndex + 1) + ".")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(option, function(
                                    secondItem,
                                    secondIndex
                                  ) {
                                    return _c("div", { key: secondIndex }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "5px" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                secondIndex.toUpperCase()
                                              ) +
                                              ":" +
                                              _vm._s(secondItem) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ])
                                  })
                                ],
                                2
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "answer" },
                              [
                                _c("div", [_vm._v("正确答案: ")]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.questionDetails.rightAnswerContent[0],
                                  function(answer, answerIndex) {
                                    return _c("div", { key: answerIndex }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(answerIndex) +
                                          "." +
                                          _vm._s(answer.toUpperCase()) +
                                          "\n                "
                                      )
                                    ])
                                  }
                                )
                              ],
                              2
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1008
                      ? _c("div", { staticClass: "option" }, [
                          _c("div", { staticClass: "answer write" }, [
                            _c("div", [
                              _vm._v("\n                  正确答案: "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.questionDetails.rightAnswerContent)
                                )
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1009
                      ? _c("div", { staticClass: "option" }, [
                          _vm.questionDetails.subQuestionList &&
                          _vm.questionDetails.subQuestionList.length > 0
                            ? _c(
                                "div",
                                { staticClass: "combination" },
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      model: {
                                        value: _vm.activeName,
                                        callback: function($$v) {
                                          _vm.activeName = $$v
                                        },
                                        expression: "activeName"
                                      }
                                    },
                                    _vm._l(
                                      _vm.questionDetails.subQuestionList,
                                      function(item, index) {
                                        return _c(
                                          "el-tab-pane",
                                          {
                                            key: index,
                                            attrs: {
                                              label: "试题" + (index + 1),
                                              name: "试题" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c("div", {
                                                staticClass: "stem",
                                                domProps: {
                                                  innerHTML: _vm._s(item.stem)
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            item.examQuestionTypeCode ===
                                              1001 ||
                                            item.examQuestionTypeCode === 1002
                                              ? _c(
                                                  "div",
                                                  [
                                                    _vm._l(
                                                      item.optionText[0],
                                                      function(
                                                        question,
                                                        questionIndex
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: questionIndex,
                                                            staticClass: "item"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  questionIndex
                                                                ) +
                                                                ": "
                                                            ),
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  question
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "answer" },
                                                      [
                                                        _vm._v(
                                                          "\n                        正确答案: " +
                                                            _vm._s(
                                                              item.rightAnswerContent.toUpperCase()
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.examQuestionTypeCode === 1003
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    { staticClass: "item" },
                                                    [
                                                      _vm._v(
                                                        "\n                        正确\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "item" },
                                                    [
                                                      _vm._v(
                                                        "\n                        错误\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "answer" },
                                                    [
                                                      _vm._v(
                                                        "\n                        正确答案: " +
                                                          _vm._s(
                                                            item.rightAnswerContent ===
                                                              "no"
                                                              ? "错误"
                                                              : "正确"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.examQuestionTypeCode === 1005
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "answer write"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        正确答案:\n                        "
                                                      ),
                                                      _vm._l(
                                                        item
                                                          .rightAnswerContent[0],
                                                        function(
                                                          question,
                                                          questionIndex
                                                        ) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: questionIndex
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  question[0]
                                                                ) + ","
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.examQuestionTypeCode === 1004
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        _vm._l(
                                                          item.optionText[0],
                                                          function(
                                                            question,
                                                            questionIndex
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: questionIndex,
                                                                staticClass:
                                                                  "item connectionOptions"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "optionIndex"
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          questionIndex
                                                                        ) + ":"
                                                                      )
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("div", {
                                                                      domProps: {
                                                                        innerHTML: _vm._s(
                                                                          question
                                                                        )
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "flex-direction":
                                                              "column"
                                                          }
                                                        },
                                                        _vm._l(
                                                          item.optionList,
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "option"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle: {
                                                                      display:
                                                                        "flex",
                                                                      margin:
                                                                        "5px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _vm._v("."),
                                                                    _c("div", {
                                                                      domProps: {
                                                                        innerHTML: _vm._s(
                                                                          item
                                                                        )
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "answer" },
                                                    [
                                                      _c("div", [
                                                        _vm._v("正确答案: ")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        item.rightAnswerContent,
                                                        function(
                                                          answer,
                                                          answerIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: answerIndex
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        answerIndex
                                                                      ) +
                                                                      " -- " +
                                                                      _vm._s(
                                                                        answer
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.examQuestionTypeCode === 1006
                                              ? _c(
                                                  "div",
                                                  [
                                                    _vm._l(
                                                      item.optionText[0],
                                                      function(item, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass: "item"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "5px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .letterList[
                                                                      index - 1
                                                                    ]
                                                                  ) +
                                                                    ": " +
                                                                    _vm._s(item)
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "answer" },
                                                      [
                                                        _vm._v(
                                                          "\n                        正确答案:\n                        "
                                                        ),
                                                        _vm._l(
                                                          item.rightAnswerContent,
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              { key: index },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(item) +
                                                                    ","
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.examQuestionTypeCode === 1008
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "answer write"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                          正确答案: "
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.rightAnswerContent
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetails.examQuestionTypeCode === 1010
                      ? _c(
                          "div",
                          { staticClass: "option" },
                          _vm._l(_vm.questionDetails.optionText, function(
                            option,
                            optionIndex
                          ) {
                            return _c(
                              "div",
                              { key: optionIndex, staticClass: "item" },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(optionIndex + 1) +
                                      ".\n                  "
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [_vm._v("内容:" + _vm._s(option.content))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _vm._v(
                                        "内容翻译:" + _vm._s(option.translation)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _vm._v(
                                        "跟读vid:" +
                                          _vm._s(
                                            option.audio === ""
                                              ? "暂无"
                                              : option.audio
                                          )
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "analysis" }, [
                      _c("div", { staticClass: "analysisTitle" }, [
                        _vm._v("\n                题目解析:\n              ")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", {
                          staticStyle: { "margin-top": "5px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.questionDetails.questionAnalysis.analysis
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.questionDetails.questionAnalysis.analysisVideo !==
                          "" &&
                        _vm.questionDetails.questionAnalysis.analysisVideo
                          ? _c(
                              "div",
                              {
                                staticClass: "analysisVideo",
                                on: {
                                  mouseenter: function($event) {
                                    return _vm.showPreview("analysisVideo")
                                  },
                                  mouseleave: function($event) {
                                    return _vm.hiddenPreview("analysisVideo")
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "info" }, [
                                  _c("i", {
                                    staticClass: "el-icon-video-camera"
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "videoTitle" }, [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.questionDetails.questionAnalysis
                                            .analysisVideoName
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "operation" }, [
                                  !_vm.showAnalysisVideo
                                    ? _c("i", {
                                        staticClass: "el-icon-success"
                                      })
                                    : _c("i", {
                                        staticClass: "el-icon-search",
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#000000"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.seeVideo(
                                              _vm.questionDetails
                                                .questionAnalysis.analysisVideo
                                            )
                                          }
                                        }
                                      })
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "formTitle" }, [
                      _vm._v(
                        "\n              分类: " +
                          _vm._s(
                            _vm.questionDetails.categoryName
                              ? _vm.questionDetails.categoryName
                              : "默认分类"
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "formTitle" }, [
                      _vm._v(
                        "\n              难度: " +
                          _vm._s(_vm.questionDetails.difficultDegree) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "formTitle" }, [
                      _vm._v("\n              标签:\n              "),
                      _vm.questionDetails.tags !== "" &&
                      _vm.questionDetails.tags
                        ? _c(
                            "span",
                            _vm._l(_vm.questionDetails.tags, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-tag",
                                { key: index, staticClass: "tags" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            1
                          )
                        : _c("span", [_vm._v("暂无")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "formTitle" }, [
                      _vm._v(
                        "更新时间: " + _vm._s(_vm.questionDetails.updatedAt)
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function($event) {
                            return _vm.editQuestion(
                              _vm.questionDetails.examQuestionOpenId
                            )
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "questionAnalysis" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n            试题分析\n          ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "questionUse" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n          已使用(3)\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "header-cell-style": { background: "#F5F7FA" },
                      data: _vm.questionUse
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "名称", width: "180" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "类型", width: "180" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "作者" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "作答次数" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    _c("el-pagination", {
                      staticClass: "pagination",
                      attrs: {
                        background: "",
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 20, 30, 40],
                        "page-size": 100,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.videoPreview,
            "before-close": _vm.videoPreviewClose,
            title: "视频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoPreview = $event
            }
          }
        },
        [
          _c("div", {
            staticClass: "video-start",
            attrs: { id: "videoPreview" }
          })
        ]
      ),
      _vm._v(" "),
      _vm.newQuestiondialogVisible
        ? _c("new-question", {
            ref: "newQuestion",
            attrs: {
              "preview-dialog": _vm.newQuestiondialogVisible,
              type: "edit",
              "edit-open-id": _vm.editOpenId
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              "update:preview-dialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              directoryDialogChange: _vm.directoryDialogChange,
              upDateQuestion: _vm.upDateQuestion
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }