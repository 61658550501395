var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    attrs: {
      options: _vm.options,
      props: { value: "openId", label: "title", checkStrictly: true },
      lazy: true,
      placeholder: "分类",
      clearable: ""
    },
    model: {
      value: _vm.valueProxy,
      callback: function($$v) {
        _vm.valueProxy = $$v
      },
      expression: "valueProxy"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }