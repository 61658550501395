"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _index = _interopRequireDefault(require("./index.vue"));

//
//
//
//
//
//
var _default2 = {
  name: 'CV_WordLibrary_Character_StrokeOrder_List',
  components: {
    StrokeOrder: _index.default
  },
  props: {
    strokeOrderData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    strokeOrderList: function strokeOrderList() {
      if ((0, _typeof2.default)(this.strokeOrderData) !== 'object' || this.strokeOrderData === null) {
        return [];
      }

      if (typeof this.strokeOrderData.bishunJson !== 'string' || (0, _typeof2.default)(this.strokeOrderData.bishunJson.trim().length) < 1) {
        return [];
      }

      try {
        return JSON.parse(this.strokeOrderData.bishunJson);
      } catch (e) {
        console.warn(e);
        return [];
      }
    }
  }
};
exports.default = _default2;