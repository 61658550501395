"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEbookQuery = getEbookQuery;

function getEbookQuery(val, partnerOpenId) {
  return {
    areaId: val.areaId,
    // 地区ID
    page: val.page,
    // 当前页码
    size: val.size,
    // 每页的数量
    typeId: val.type,
    // 分类
    types: val.type,
    sectionId: val.xd,
    // 学段
    subjectId: val.subject,
    // 学科
    editionId: val.bb,
    // 版本
    gradeId: val.grade,
    // 年级
    volumeId: val.jd,
    periodId: val.bc,
    sortBy: val.orderBy,
    sortOrder: val.orderMode,
    createStartTime: val.createStartTime,
    createdEndTime: val.createdEndTime,
    publishStartTime: val.publishStartTime,
    // 发布时间 开始
    publishEndTime: val.publishEndTime,
    // 发布时间 结束
    ebookClassificationOpenId: val.ebookClassificationOpenId,
    regimentationIds: val.regimentationIds,
    secondRegimentationIds: val.secondRegimentationIds,
    tags: val.tags,
    recommendSequence: val.recommendSequence,
    partnerOpenId: partnerOpenId,
    keyword: val.keyword
  };
}

function fn() {}