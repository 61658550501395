"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CG_From_Selector_RadioGroup_ResourceTypeRadioGroup',
  props: {
    value: {
      default: undefined
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onChange: function onChange() {
      this.$emit('change');
    }
  }
};
exports.default = _default2;