var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDragging,
            expression: "isDragging"
          }
        ],
        staticClass: "page-box-top",
        class: { "is-dragging-top": _vm.isDragging }
      }),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "page-box-content",
          attrs: { list: _vm.PageList },
          on: { start: _vm.pageDragStart, end: _vm.pageDragEnd }
        },
        _vm._l(_vm.PageList, function(page, pageIndex) {
          return _c(
            "div",
            { key: pageIndex, attrs: { id: "pageId" + page.id } },
            [
              _c("page-item", {
                staticClass: "page-item",
                attrs: { pageItem: page, pageIndex: pageIndex }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDragging,
            expression: "isDragging"
          }
        ],
        staticClass: "page-box-bottom",
        class: { "is-dragging-bottom": _vm.isDragging }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }