"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  edition: [],
  // 班次列表
  uploadStep: [],
  // 上传的步骤
  tableData: [],
  // 上传视频的列表
  selectedArr: [],
  // 选中的视频列表
  openUpload: false,
  // 是否开始上传了
  stopUpload: false,
  // 暂停上传
  uploadIndex: 0,
  // 目前上传到第几个
  isPauseUpload: false // 上传是否暂停

};
var mutations = {
  SETEDITION: function SETEDITION(state, val) {
    state.edition = val;
  },
  setTableData: function setTableData(state, val) {
    state.tableData = val;
  },
  setSelectedArr: function setSelectedArr(state, val) {
    state.selectedArr = val;
  },
  setUploadStep: function setUploadStep(state, val) {
    state.uploadStep = val;
  },
  setOpenUpload: function setOpenUpload(state, val) {
    state.openUpload = val;
  },
  setUploadIndex: function setUploadIndex(state, val) {
    state.uploadIndex = val;
  },
  setStopUpload: function setStopUpload(state, val) {
    state.stopUpload = val;
  },
  setIsPauseUpload: function setIsPauseUpload(state, val) {
    state.isPauseUpload = val;
  }
};
var actions = {
  set_edition_arr: function set_edition_arr(_ref, arr) {
    var commit = _ref.commit;
    commit('SETEDITION', arr);
  },
  set_stopUpload: function set_stopUpload(_ref2, value) {
    var commit = _ref2.commit;
    commit('setStopUpload', value);
  },
  set_uploadStep: function set_uploadStep(_ref3, value) {
    var commit = _ref3.commit;
    commit('setUploadStep', value);
  },
  set_tableData: function set_tableData(_ref4, value) {
    var commit = _ref4.commit;
    commit('setTableData', value);
  },
  set_selectedArr: function set_selectedArr(_ref5, value) {
    var commit = _ref5.commit;
    commit('setSelectedArr', value);
  },
  set_uploadIndex: function set_uploadIndex(_ref6, value) {
    var commit = _ref6.commit;
    commit('setUploadIndex', value);
  },
  set_isPauseUpload: function set_isPauseUpload(_ref7, value) {
    var commit = _ref7.commit;
    commit('setIsPauseUpload', value);
  },
  set_openUpload: function set_openUpload(_ref8, value) {
    var commit = _ref8.commit;
    commit('setOpenUpload', value);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;