var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "test-question-dialog",
      attrs: {
        title: "选择试题",
        visible: _vm.dialogVisible,
        width: "1000px",
        "append-to-body": "",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "test-question-box" }, [
        _c("div", { staticClass: "box-select-conditions" }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("search", {
                staticClass: "fl",
                on: { receivepartnerId: _vm.receiveUserId }
              }),
              _vm._v(" "),
              _c("el-cascader", {
                staticStyle: { width: "120px", "margin-right": "9px" },
                attrs: {
                  size: "medium",
                  options: _vm.questionClass,
                  props: _vm.classProps,
                  placeholder: "请选择分类",
                  clearable: ""
                },
                model: {
                  value: _vm.query.categoryOpenId,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "categoryOpenId", $$v)
                  },
                  expression: "query.categoryOpenId"
                }
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: {
                    clearable: "",
                    size: "medium",
                    placeholder: "请选择类型"
                  },
                  model: {
                    value: _vm.query.typeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "typeCode", $$v)
                    },
                    expression: "query.typeCode"
                  }
                },
                _vm._l(_vm.questionTypeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-box" },
            [
              _c("el-input", {
                staticStyle: { "margin-right": "9px" },
                attrs: {
                  clearable: "",
                  placeholder: "试题名称搜索",
                  size: "medium"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleBtnSearch($event)
                  }
                },
                model: {
                  value: _vm.query.stem,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "stem", $$v)
                  },
                  expression: "query.stem"
                }
              }),
              _vm._v(" "),
              _c(
                "searchbtn",
                {
                  staticClass: "searchBtn",
                  on: { searchContent: _vm.searchContent }
                },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { width: "450px" },
                      attrs: { "label-width": "80px" }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "同步信息" } }, [
                        _c(
                          "div",
                          { staticClass: "sync-info" },
                          [
                            _c("select-param-sync-info", {
                              staticClass: "sync-select",
                              attrs: {
                                "query-option": "FindGradeList",
                                placeholder: "年级"
                              },
                              model: {
                                value: _vm.query.gradeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "gradeCode", $$v)
                                },
                                expression: "query.gradeCode"
                              }
                            }),
                            _vm._v(" "),
                            _c("select-param-sync-info", {
                              staticClass: "sync-select",
                              attrs: {
                                "query-option": "FindJDList",
                                placeholder: "阶段"
                              },
                              model: {
                                value: _vm.query.volumeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "volumeCode", $$v)
                                },
                                expression: "query.volumeCode"
                              }
                            }),
                            _vm._v(" "),
                            _c("select-param-sync-info", {
                              staticClass: "sync-select",
                              attrs: {
                                "query-option": "FindSubjectList",
                                placeholder: "学科"
                              },
                              model: {
                                value: _vm.query.subjectCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "subjectCode", $$v)
                                },
                                expression: "query.subjectCode"
                              }
                            }),
                            _vm._v(" "),
                            _c("select-param-sync-info", {
                              staticClass: "sync-select",
                              attrs: {
                                "query-option": "FindBBList",
                                placeholder: "版本"
                              },
                              model: {
                                value: _vm.query.editionCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "editionCode", $$v)
                                },
                                expression: "query.editionCode"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "版次" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "版次",
                                size: "medium",
                                clearable: ""
                              },
                              model: {
                                value: _vm.query.periodCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "periodCode", $$v)
                                },
                                expression: "query.periodCode"
                              }
                            },
                            _vm._l(_vm.periodList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "难易" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "全部",
                                size: "medium",
                                clearable: ""
                              },
                              model: {
                                value: _vm.query.difficultDegree,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "difficultDegree", $$v)
                                },
                                expression: "query.difficultDegree"
                              }
                            },
                            _vm._l(_vm.difficultDegreeList, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "知识点" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              props: _vm.knowledgeProps,
                              options: _vm.knowledgeList
                            },
                            on: { change: _vm.getKnowledgeOpenId },
                            model: {
                              value: _vm.query.knowledgeOpenId,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "knowledgeOpenId", $$v)
                              },
                              expression: "query.knowledgeOpenId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标签" },
                            model: {
                              value: _vm.query.tags,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "tags", $$v)
                              },
                              expression: "query.tags"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "remember-box" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "记住所选",
                        placement: "top"
                      }
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.rememberType,
                          callback: function($$v) {
                            _vm.rememberType = $$v
                          },
                          expression: "rememberType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "questionTable" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "show-header": false,
                    data: _vm.questionList,
                    height: "100%"
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "row-click": _vm.handleCheck
                  }
                },
                [
                  _vm.showType === "add"
                    ? _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          selectable: _vm.selectable,
                          width: "55"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stem",
                      label: "试题题干",
                      "min-width": "180",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(scope.row.stem) }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "difficultDegree",
                      label: "难易",
                      "min-width": "40"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.difficultDegree === "1"
                              ? _c("span", [_vm._v("很简单")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.difficultDegree === "2"
                              ? _c("span", [_vm._v("简单")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.difficultDegree === "3"
                              ? _c("span", [_vm._v("一般")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.difficultDegree === "4"
                              ? _c("span", [_vm._v("困难")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.difficultDegree === "5"
                              ? _c("span", [_vm._v("十分困难")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "questionTypeName", label: "题型" }
                  }),
                  _vm._v(" "),
                  _vm.showType === "replace"
                    ? _c("el-table-column", {
                        attrs: { "min-width": "40" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-add-album",
                                      attrs: {
                                        disabled: !_vm.selectable(scope.row),
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleReplace(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("替换")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          119307566
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableBottom" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("\n        取消\n      ")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.selectSubmit } },
                [_vm._v("\n        确定\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }