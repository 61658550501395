import { render, staticRenderFns } from "./selectTestPaper.vue?vue&type=template&id=13e05d5b&scoped=true&"
import script from "./selectTestPaper.vue?vue&type=script&lang=js&"
export * from "./selectTestPaper.vue?vue&type=script&lang=js&"
import style0 from "./selectTestPaper.vue?vue&type=style&index=0&id=13e05d5b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e05d5b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13e05d5b')) {
      api.createRecord('13e05d5b', component.options)
    } else {
      api.reload('13e05d5b', component.options)
    }
    module.hot.accept("./selectTestPaper.vue?vue&type=template&id=13e05d5b&scoped=true&", function () {
      api.rerender('13e05d5b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Book/Question/selectTestPaper.vue"
export default component.exports