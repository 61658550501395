"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _qrcode = _interopRequireDefault(require("qrcode.vue"));

var _qrCode = require("../../../../utils/qrCode");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'groupLink',
  components: {
    QrcodeVue: _qrcode.default
  },
  props: {
    groupLinkItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      inactiveValue: 1,
      activeVvalue: 0,
      showUpload: true,
      showDel: false
    };
  },
  watch: {
    'groupLinkItem.skipUrl': function groupLinkItemSkipUrl() {
      if (!this.groupLinkItem.skipUrl.trim()) {
        this.showUpload = true;
      } else {
        this.showUpload = false;
      }
    }
  },
  created: function created() {
    this.initData();
  },
  methods: {
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isJPG) {
        this.$message.error('只能上传 jpeg,png,jpg!');
      }

      return isJPG;
    },
    deleteLeadGroup: function deleteLeadGroup() {
      this.$emit('deleteLeadGroup', this.currentIndex);
    },
    initData: function initData() {
      if (this.groupLinkItem.skipUrl.trim() === '') {
        this.showUpload = true;
      } else {
        this.showUpload = false;
      }
    },
    handleRemove: function handleRemove() {
      this.groupLinkItem.skipUrl = '';
    },
    resolveQR: function resolveQR(event) {
      var _this = this;

      this.groupLinkItem.skipUrl = 'http';
      var result = (0, _qrCode.getQrUrl)(event.file);
      result.then(function (res) {
        if (res.data) {
          _this.$message.success('识别二维码成功!');

          _this.groupLinkItem.skipUrl = res.data;
        } else {
          _this.groupLinkItem.skipUrl = '';

          _this.$message.error('识别二维码失败, 请重新上传');
        }
      });
    }
  }
};
exports.default = _default2;