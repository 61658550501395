var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "select-resource-dialog resource",
          class: _vm.audioAliVid ? "resource-audioAliVid" : "",
          attrs: {
            "modal-append-to-body": false,
            width: "600px",
            visible: _vm.dialogShow,
            title: "添加/编辑英语会话内容"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            { staticClass: "dislog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "role-form",
                  attrs: {
                    model: _vm.contentForm,
                    rules: _vm.roles,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会话内容", prop: "content" } },
                    [
                      _c("el-input", {
                        staticClass: "content-textarea",
                        attrs: {
                          type: "textarea",
                          resize: "none",
                          rows: "4",
                          placeholder: "请输入会话内容"
                        },
                        model: {
                          value: _vm.contentForm.content,
                          callback: function($$v) {
                            _vm.$set(_vm.contentForm, "content", $$v)
                          },
                          expression: "contentForm.content"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容翻译" } },
                    [
                      _c("el-input", {
                        staticClass: "content-textarea",
                        attrs: {
                          type: "textarea",
                          resize: "none",
                          rows: "4",
                          placeholder: "请输入内容翻译"
                        },
                        model: {
                          value: _vm.contentForm.translateContent,
                          callback: function($$v) {
                            _vm.$set(_vm.contentForm, "translateContent", $$v)
                          },
                          expression: "contentForm.translateContent"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "labek-select",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.label,
                            callback: function($$v) {
                              _vm.label = $$v
                            },
                            expression: "label"
                          }
                        },
                        _vm._l(_vm.roleList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.roleName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "音频文件" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _vm.hackReset
                          ? _c("upload-file", {
                              ref: "uploadAudio",
                              attrs: {
                                type: _vm.audioType,
                                "audio-id": _vm.audioAliVid
                              },
                              on: {
                                startUpload: _vm.startUpload,
                                getFollowResult: _vm.getUploader
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.audioAliVid
                          ? _c("div", { staticClass: "audio-tip" }, [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: {
                                  color: "rgb(64, 158, 255)",
                                  "margin-left": "10px"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("仅限上传mp3、wma文件，且不超过10M。")
                              ])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-tools" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "margin-right": "22px"
                }
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取消")
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: !_vm.saveState },
                    on: {
                      click: function($event) {
                        return _vm.addContent()
                      }
                    }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }