"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));

var _selectFrontDesk = _interopRequireDefault(require("@/components/wordLibrary/selectFrontDesk"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'WordTermsLink',
  components: {
    SelectFrontDesk: _selectFrontDesk.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: undefined
    },
    wordType: {
      type: String,
      default: undefined
    } // character vocabulary word

  },
  data: function data() {
    return {
      options: [],
      replaceMap: undefined,
      deskDialogShow: false,
      showType: 'add',
      checkList: []
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    getSelectWord: function getSelectWord(checkList) {
      var _this = this;

      var result = [];

      if (this.wordType === 'word') {
        checkList.forEach(function (item) {
          if (item.isOld) {
            var newItem = JSON.parse(JSON.stringify(item));
            delete newItem.isOld;
            result.push(newItem);
            return;
          }

          var natureCode;
          var natureId;
          var wordNatureList = []; // 处理多余数据

          if (item.wordNatureList && Array.isArray(item.wordNatureList)) {
            var data = _this.handleWordData(item);

            natureCode = data.natureCode;
            wordNatureList = data.wordNatureList;
            natureId = data.natureId;
          }

          result.push({
            name: item.name,
            openId: item.openId,
            natureCode: natureCode,
            wordNatureList: wordNatureList,
            natureId: natureId
          });
        });
      }

      if (this.wordType === 'vocabulary' || this.wordType === 'character') {
        checkList.forEach(function (item) {
          if (item.isOld) {
            var newItem = JSON.parse(JSON.stringify(item));
            delete newItem.isOld;
            result.push(newItem);
            return;
          }

          var pinyinId;
          var newPinList = [];

          if (item.pinyinList && Array.isArray(item.pinyinList)) {
            var data = _this.handleCharacter(item);

            pinyinId = data.pinyinId;
            newPinList = data.newPinList;
          }

          result.push({
            name: item.name,
            openId: item.openId,
            pinyinId: pinyinId,
            pinyinList: newPinList
          });
        });
      }

      this.valueProxy.list = result;
    },
    // 处理单词
    handleWordData: function handleWordData(item) {
      var wordNatureList = [];
      var natureCode = item.wordNatureList[0].natureCode;
      var natureId = item.wordNatureList[0].id;
      item.wordNatureList.forEach(function (n) {
        wordNatureList.push({
          id: n.wordNature ? n.wordNature.id : undefined,
          // 跳转到跟读需要
          wordNatureCode: n.wordNature ? n.wordNature.wordNatureCode : undefined,
          name: n.wordNature ? n.wordNature.name : undefined
        });
      });
      return {
        natureCode: natureCode,
        wordNatureList: wordNatureList,
        natureId: natureId
      };
    },
    handleCharacter: function handleCharacter(item) {
      var newPinList = [];
      var pinyinId = item.pinyinList[0].id;
      item.pinyinList.forEach(function (n) {
        newPinList.push({
          pinyin: n.pinyin,
          id: n.id
        });
      });
      return {
        pinyinId: pinyinId,
        newPinList: newPinList
      };
    },
    getReplace: function getReplace(val) {
      var query = {};

      if (this.wordType === 'word') {
        var _this$handleWordData = this.handleWordData(val.name),
            natureCode = _this$handleWordData.natureCode,
            wordNatureList = _this$handleWordData.wordNatureList;

        query = {
          name: val.name.name,
          openId: val.name.openId,
          natureCode: natureCode,
          wordNatureList: wordNatureList
        };
      }

      if (this.wordType === 'vocabulary' || this.wordType === 'character') {
        var _this$handleCharacter = this.handleCharacter(val.name),
            pinyinId = _this$handleCharacter.pinyinId,
            newPinList = _this$handleCharacter.newPinList;

        query = {
          name: val.name.name,
          openId: val.name.openId,
          pinyinId: pinyinId,
          pinyinList: newPinList
        };
      }

      this.$set(this.valueProxy.list, val.index, query);
    },

    /* 字词库弹窗显示处理 */
    parentEvent: function parentEvent(show) {
      this.deskDialogShow = show;
    },
    addBtn: function addBtn() {
      var _this2 = this;

      this.showType = 'add';
      this.checkList = this.valueProxy.list.map(function (item) {
        if (_this2.wordType === 'word') {
          return _objectSpread(_objectSpread({}, item), {}, {
            wordOpenId: item.openId
          });
        }

        if (_this2.wordType === 'vocabulary') {
          return _objectSpread(_objectSpread({}, item), {}, {
            vocabularyOpenId: item.vocabularyOpenId
          });
        }

        if (_this2.wordType === 'character') {
          return _objectSpread(_objectSpread({}, item), {}, {
            charsOpenId: item.charsOpenId
          });
        }
      });
      this.deskDialogShow = true;
    },
    // 替换
    replaceRow: function replaceRow(val, index) {
      this.showType = 'replace';
      this.replaceMap = {
        name: val,
        index: index
      };
      this.deskDialogShow = true;
    },
    // 复制
    copy: function copy(url, event) {
      if (!url) {
        return;
      }

      (0, _clipboard.default)(url, event);
    },
    // 移除
    delRow: function delRow(index) {
      this.valueProxy.list.splice(index, 1);
    },
    deleteAll: function deleteAll() {
      this.valueProxy.list = [];
    },
    getWordNature: function getWordNature(wordNatureList) {
      if (!wordNatureList) {
        return '--';
      }

      var wordNature = '';
      wordNatureList.forEach(function (item) {
        wordNature = item.name;
      });
      return wordNature;
    },
    getPinyin: function getPinyin(pinyinList) {
      if (!pinyinList) {
        return '--';
      }

      if (pinyinList.length <= 0) {
        return '--';
      }

      return pinyinList[0].pinyin;
    }
  }
};
exports.default = _default2;