var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teacher-case" }, [
    _c(
      "div",
      { staticClass: "teacherInfo-case" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "100px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "真实姓名 ：", prop: "name_input" } },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: {
                    size: "mini",
                    maxlength: "10",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.ruleForm.name_input,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "name_input", $$v)
                    },
                    expression: "ruleForm.name_input"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "学校 ：", prop: "school_input" } },
              [
                _c("el-input", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    size: "mini",
                    type: "text",
                    placeholder: "请输入内容",
                    maxlength: "45",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.ruleForm.school_input,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "school_input", $$v)
                    },
                    expression: "ruleForm.school_input"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "职务 ：", prop: "job_select" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请选择职务", size: "mini" },
                    model: {
                      value: _vm.ruleForm.job_select,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "job_select", $$v)
                      },
                      expression: "ruleForm.job_select"
                    }
                  },
                  _vm._l(_vm.job_options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.label }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "任教学段 ：", prop: "teach_select" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请选择任教学段", size: "mini" },
                    model: {
                      value: _vm.ruleForm.teach_select,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "teach_select", $$v)
                      },
                      expression: "ruleForm.teach_select"
                    }
                  },
                  _vm._l(_vm.teach_options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.label }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "证件 ：", prop: "credential_select" } },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请选择证件类型", size: "mini" },
                        model: {
                          value: _vm.ruleForm.credential_select,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "credential_select", $$v)
                          },
                          expression: "ruleForm.credential_select"
                        }
                      },
                      _vm._l(_vm.credential_options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.label }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "companyLicense" } },
              [
                _c("coverupload", {
                  attrs: {
                    coverarr: _vm.coverarr,
                    covertext: _vm.covertextFeng,
                    path: _vm.pathTeacher,
                    texttips: _vm.texttipsDown,
                    type: "教师证件"
                  },
                  on: { deletecover: _vm.deletecoverFeng },
                  model: {
                    value: _vm.ruleForm.companyLicense,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "companyLicense", $$v)
                    },
                    expression: "ruleForm.companyLicense"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottomBtn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.resetForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.submitForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v("保存")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }