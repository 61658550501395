var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "resource-author-bg" },
      [
        _c(
          "el-image",
          {
            staticClass: "merchantCover",
            attrs: {
              src: _vm.ossUploadUrl(
                _vm.resourceObj.partnerLogo ? _vm.resourceObj.partnerLogo : "",
                "style/thumbnail"
              ),
              alt: ""
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "merchantCover",
                attrs: { slot: "error" },
                slot: "error"
              },
              [_c("i", { staticClass: "el-icon-picture-outline" })]
            )
          ]
        ),
        _vm._v(" "),
        _vm.resourceObj.partnerName
          ? _c(
              "span",
              {
                staticClass: "merchantName",
                attrs: { title: _vm.resourceObj.partnerName }
              },
              [_vm._v(_vm._s(_vm.resourceObj.partnerName))]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }