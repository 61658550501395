"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_WordLibrary_Character_StrokeOrder_index',
  props: {
    strokeOrder: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    imageSrc: function imageSrc() {
      if ((0, _typeof2.default)(this.strokeOrder) !== 'object' || this.strokeOrder === null) {
        return undefined;
      }

      if (typeof this.strokeOrder.biShun !== 'string' || this.strokeOrder.biShun.trim().length < 1) {
        return undefined;
      }

      var newBiShun = this.strokeOrder.biShun.replace(/^<svg>/, '<svg width="100px" height="100px" version="1.1" xmlns="http://www.w3.org/2000/svg">');
      return "data:image/svg+xml;base64,".concat(window.btoa(newBiShun));
    }
  }
};
exports.default = _default2;