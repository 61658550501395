var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("div", { staticClass: "cooperation-wrapper" }, [
        _c(
          "div",
          { staticClass: "header-bg" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.handlerAddChannelBtn()
                  }
                }
              },
              [_vm._v("添加")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "province-table-body" },
          [
            _vm.showTable
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    ref: "provincesList",
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: "100%",
                      "row-key": "cooperationChannelOpenId",
                      lazy: true,
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren"
                      },
                      load: _vm.load,
                      data: _vm.tableData
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "sequence",
                        label: "渠道",
                        "min-width": "50"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: scope.row.parentOpenId
                                      ? "paddingLetSty"
                                      : "paddingLet"
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.channelName))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1982323849
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cooperationChannelOpenId",
                        label: "渠道openID",
                        "min-width": "50"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { "min-width": "50", label: "渠道金额（元）" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("moneyTransform")(
                                        scope.row.payPrice
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1773462941
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { "min-width": "50", label: "订单笔数（笔）" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("qtyTransform")(scope.row.qty)
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1582050280
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "sequence", label: "排序", width: "80" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(" " + _vm._s(scope.row.sequence))
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1840587410
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "60",
                        align: "right"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        ref:
                                          "pop" +
                                          scope.row.cooperationChannelOpenId,
                                        attrs: {
                                          "popper-class": "preview-popover-bg",
                                          placement: "top",
                                          width: "386"
                                        },
                                        on: {
                                          show: function($event) {
                                            return _vm.generalize(
                                              scope.row,
                                              scope.$index
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.isPreview,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "isPreview",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.isPreview"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "code-title-bg" },
                                          [_vm._v("预览链接")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "code-bg" }, [
                                          _c(
                                            "div",
                                            { staticClass: "code-img" },
                                            [
                                              _c("vue-qr", {
                                                class: "codeImg" + scope.$index,
                                                attrs: {
                                                  text: scope.row.resourceUrl
                                                    ? scope.row.resourceUrl
                                                    : "",
                                                  size: 54,
                                                  margin: 0
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "code-btns-bg" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "code-btn-bg" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "code-btn-title"
                                                    },
                                                    [_vm._v("主页")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "code-btn" },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "code-input",
                                                        attrs: { size: "mini" },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .resourceUrl,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "resourceUrl",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.resourceUrl"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          ref: "previewCopyBtn",
                                                          staticClass:
                                                            "btn-code-copy",
                                                          attrs: {
                                                            size: "mini",
                                                            type: "primary",
                                                            plain: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.handleUrlCopy(
                                                                scope.row,
                                                                scope.$index,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("复制")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "code-title-bg" },
                                          [_vm._v("生成")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "code-btn" },
                                          [
                                            _c("el-input", {
                                              staticClass: "code-txt2",
                                              attrs: { size: "mini" },
                                              model: {
                                                value: _vm.inputUrl,
                                                callback: function($$v) {
                                                  _vm.inputUrl = $$v
                                                },
                                                expression: "inputUrl"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                ref: "previewCopyBtn",
                                                staticClass: "btn-code-copy",
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                  plain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.createNewUrl(
                                                      scope.row
                                                        .cooperationChannelOpenId,
                                                      _vm.inputUrl
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("生成")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "code-btn" },
                                          [
                                            _c("el-input", {
                                              staticClass: "code-txt2",
                                              attrs: {
                                                size: "mini",
                                                disabled: true
                                              },
                                              model: {
                                                value: _vm.newUrl,
                                                callback: function($$v) {
                                                  _vm.newUrl = $$v
                                                },
                                                expression: "newUrl"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                ref: "previewCopyBtn",
                                                staticClass: "btn-code-copy",
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                  plain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleNewUrlCopy(
                                                      scope.row,
                                                      scope.$index,
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("复制")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "btn-spread",
                                            attrs: {
                                              slot: "reference",
                                              type: "text"
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("生成链接")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    scope.row.level < 1
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addChildBtn(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.editBtn(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    scope.row.qty <= 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "removeBtn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeBtn(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("移除")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1289657471
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-box-bg" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.getChannelList
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "480px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "90px",
                      model: _vm.channelForm,
                      rules: _vm.rules
                    }
                  },
                  [
                    _vm.dialogTitltText === "编辑来源渠道"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "渠道openID" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.channelForm.cooperationChannelOpenId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.channelForm,
                                    "cooperationChannelOpenId",
                                    $$v
                                  )
                                },
                                expression:
                                  "channelForm.cooperationChannelOpenId"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.channelForm.level !== 0
                      ? _c("el-form-item", { attrs: { label: "父级渠道ID" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.channelForm.parentOpenId) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "渠道名称", prop: "channelName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 20,
                            placeholder: "请填写渠道名称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.channelForm.channelName,
                            callback: function($$v) {
                              _vm.$set(_vm.channelForm, "channelName", $$v)
                            },
                            expression: "channelForm.channelName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: 90,
                            placeholder: "请填写名称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.channelForm.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.channelForm, "remark", $$v)
                            },
                            expression: "channelForm.remark"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "排序" } }, [
                      _c(
                        "div",
                        { staticClass: "sequence-box" },
                        [
                          _c("el-input-number", {
                            staticClass: "select-sequence",
                            staticStyle: { width: "100px" },
                            attrs: {
                              precision: 0,
                              step: 1,
                              min: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.channelForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.channelForm, "sequence", $$v)
                              },
                              expression: "channelForm.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.editDialogVisible = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitSyncLoading
                                  },
                                  on: { click: _vm.submitSave }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }