"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRealCommodityList = getRealCommodityList;
exports.getRealCommodityDetail = getRealCommodityDetail;
exports.realCommodityToDraft = realCommodityToDraft;
exports.realCommodityToDraftById = realCommodityToDraftById;
exports.realCommodityToReview = realCommodityToReview;
exports.realCommodityToReviewById = realCommodityToReviewById;
exports.realCommodityHidden = realCommodityHidden;
exports.realCommodityCancelHidden = realCommodityCancelHidden;
exports.realCommodityStopSale = realCommodityStopSale;
exports.realCommodityCancelStopSale = realCommodityCancelStopSale;
exports.realCommodityReview = realCommodityReview;
exports.realCommodityBatchReview = realCommodityBatchReview;
exports.getRealCommodityStatusCount = getRealCommodityStatusCount;
exports.setRealCommoditySequence = setRealCommoditySequence;
exports.realCommodityCancelSequence = realCommodityCancelSequence;
exports.realCommodityRecovery = realCommodityRecovery;
exports.realCommodityBatchRecovery = realCommodityBatchRecovery;
exports.delRealCommodity = delRealCommodity;
exports.realCommodityBatchSetAttribute = realCommodityBatchSetAttribute;
exports.realCommodityBatchIndependentSale = realCommodityBatchIndependentSale;
exports.realCommodityReviewPass = realCommodityReviewPass;
exports.realCommodityReject = realCommodityReject;
exports.realCommodityBatchRefuse = realCommodityBatchRefuse;
exports.realCommoditySavePublish = realCommoditySavePublish;
exports.realCommoditySaveReview = realCommoditySaveReview;
exports.getRealCommodityResourceList = getRealCommodityResourceList;
exports.getRealCommodityTitleList = getRealCommodityTitleList;
exports.submitRealCommoditys = submitRealCommoditys;
exports.realCommodityToSaveById = realCommodityToSaveById;
exports.updateRealCommodityEncode = updateRealCommodityEncode;
exports.realCommodityBatchRecycle = realCommodityBatchRecycle;
exports.realCommodityCommodityList = realCommodityCommodityList;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/real-commodity/admin/realCommodity/"));
/**
 * 获取不同状态下的音频数量
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getRealCommodityStatusCount(params) {
  return request.get('/request/count', {
    params: params
  });
}
/**
 * 获取音频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRealCommodityList(params) {
  return request.get('/request/list', {
    params: params
  });
}
/**
 * 获取音频列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRealCommodityResourceList(params) {
  return request.get('/order/list', {
    params: params
  });
}
/**
 * 获取音频详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRealCommodityDetail(openId) {
  return request.get("/request/".concat(openId));
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function realCommodityToDraft(data) {
  return request.post('/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function realCommodityToDraftById(openId, data) {
  return request.patch("/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function realCommodityToSaveById(openId, data) {
  return request.patch("/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function realCommodityToReview(data) {
  return request.post('/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function realCommodityToReviewById(openId, data) {
  return request.patch("/request/".concat(openId, "/commit"), data);
}
/**
 * 音频隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityHidden(openId, params) {
  return request.post("/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 音频取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityCancelHidden(openId, params) {
  return request.post("/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 音频停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 音频取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityCancelStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 音频-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function realCommodityReview(openId) {
  return request.post("/request/".concat(openId, "/commit"));
}
/**
 * 音频-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function realCommodityBatchReview(openIds) {
  return request.post('/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setRealCommoditySequence(openId, params) {
  return request.patch("/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function realCommodityCancelSequence(openId) {
  return request.patch("/request/".concat(openId, "/cancelSequence"));
}
/**
 * 音频-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function realCommodityRecovery(openId) {
  return request.post("/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function realCommodityBatchRecovery(openIds) {
  return request.post('/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function realCommodityBatchRecycle(openIds) {
  return request.post('/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delRealCommodity(openId) {
  return request.delete("/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityBatchSetAttribute(params) {
  return request.patch('/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityBatchIndependentSale(params) {
  return request.patch('/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 音频-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function realCommodityReviewPass(openId) {
  return request.post("/request/".concat(openId, "/review"));
}
/**
 * 音频-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function realCommoditySavePublish(data) {
  return request.post('/request/review', data);
}
/**
 * 音频-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function realCommoditySaveReview(openId, data) {
  return request.patch("/request/".concat(openId, "/review"), data);
}
/**
 * 音频-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityReject(openId, params) {
  return request.post("/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 音频-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityBatchRefuse(params) {
  return request.post('/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRealCommodityTitleList(params) {
  return request.get('/request/repeat/title', {
    params: params
  });
}
/**
 * 批量提交审核
 * @param {Object} params
 * @returns {Promise<*>}
 */


function submitRealCommoditys(openIds) {
  return request.post('/request/commits', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateRealCommodityEncode(resourceOpenId, resourceEncode) {
  return request.patch("/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 分页查询商品正式信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function realCommodityCommodityList(params) {
  return request.get('/order/list', {
    params: params
  });
}