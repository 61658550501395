var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-problem" }, [
    _vm.classList && _vm.classList.length > 0
      ? _c("div", { staticClass: "nav" }, [
          _c(
            "div",
            { staticClass: "tab" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.classList, function(item) {
                  return _c("el-tab-pane", {
                    key: item.id,
                    attrs: {
                      label: item.classificationName,
                      name: item.id.toString()
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "search" }, [
            _c("i", {
              staticClass: "el-icon-search icon",
              on: {
                click: function($event) {
                  _vm.openSearch = true
                }
              }
            })
          ]),
          _vm._v(" "),
          _vm.openSearch
            ? _c(
                "div",
                { staticClass: "search-nav" },
                [
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: {
                      size: "small",
                      placeholder: "请输入关键字...",
                      clearable: ""
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.searchVal,
                      callback: function($$v) {
                        _vm.searchVal = $$v
                      },
                      expression: "searchVal"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: { click: _vm.closeSearch }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.problemList && _vm.problemList.length > 0
      ? _c("div", { staticClass: "problem" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadScroll,
                  expression: "loadScroll"
                }
              ],
              staticClass: "scroll-problem",
              attrs: {
                "infinite-scroll-immediate": "false",
                "infinite-scroll-disabled": _vm.disabled
              }
            },
            [
              _vm._l(_vm.problemList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "problem-item",
                    on: {
                      click: function($event) {
                        return _vm.clickProblem(item)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "problem-title" }, [
                      _vm._v(_vm._s(item.problemTitle))
                    ]),
                    _vm._v(" "),
                    item.problemDescribe || item.problemDetail
                      ? _c("div", { staticClass: "problem-content" }, [
                          _vm._v(
                            _vm._s(
                              item.problemDescribe
                                ? item.problemDescribe
                                : item.problemDetail
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                )
              }),
              _vm._v(" "),
              _vm.scrollStatue
                ? _c("p", { staticClass: "scroll-text" }, [_vm._v("加载中...")])
                : _vm._e(),
              _vm._v(" "),
              _vm.noMore
                ? _c("p", { staticClass: "scroll-text" }, [
                    _vm._v("没有更多了")
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.problemList || _vm.problemList.length === 0
      ? _c("div", { staticClass: "no-data" }, [_vm._v("\n    暂无内容\n  ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }