"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.is-nan");

require("core-js/modules/es6.number.constructor");

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _PhotoWall = _interopRequireDefault(require("./PhotoWall"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CG_Image_ImageWall_OriginalImageWall',
  components: {
    PhotoWall: _PhotoWall.default
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    coverArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    atlasArrText: {
      type: String,
      default: undefined
    },
    ossUploadFunction: {
      type: Function,
      default: undefined
    },
    path: {
      type: String,
      default: undefined
    },
    previewZIndex: {
      type: Number,
      default: 2000
    },
    limitNum: {
      type: Number,
      default: undefined
    }
  },
  data: function data() {
    return {
      imageList: []
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    isDisableUpload: function isDisableUpload() {
      if (typeof this.limitNum === 'number') {
        if (Number.isNaN(this.limitNum) || !Number.isFinite(this.limitNum)) {
          return false;
        }

        if (this.limitNum < 1) {
          return true;
        }

        if ((0, _typeof2.default)(this.imageList) === 'object' && this.imageList !== null && Array.isArray(this.imageList)) {
          return this.imageList.length >= this.limitNum;
        }

        return false;
      }

      return false;
    }
  },
  watch: {
    imageList: function imageList() {
      this.$emit('input', this.imageList);
    },
    value: function value() {
      this.reInitDataFormParent();
    }
  },
  mounted: function mounted() {
    this.reInitDataFormParent();
  },
  methods: {
    reInitDataFormParent: function reInitDataFormParent() {
      this.imageList = this.value;
    },
    // 删除上传图片
    deletedImage: function deletedImage(item) {
      this.imageList = this.imageList.filter(function (n) {
        return n !== item;
      });
    },
    // 替换上传图片
    replaceImage: function replaceImage(file, index) {
      this.imageList[index] = file;
    },
    getImage: function getImage(retArray) {
      if (!_main.default.isObject(retArray) || !_main.default.isArray(retArray)) {
        return;
      }

      if (!_main.default.isObject(this.imageList) || !_main.default.isArray(this.imageList)) {
        this.imageList = [];
      }

      this.imageList = [].concat((0, _toConsumableArray2.default)(retArray), (0, _toConsumableArray2.default)(this.imageList));
    }
  }
};
exports.default = _default2;