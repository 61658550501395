var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "classification-Box" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { width: "100%" },
          on: { "tab-click": _vm.changeSubjectCode },
          model: {
            value: _vm.subjectName,
            callback: function($$v) {
              _vm.subjectName = $$v
            },
            expression: "subjectName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "语文", name: "Chinese" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "英语", name: "English" } }),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              staticStyle: { height: "100vh" },
              attrs: { "tab-position": "left" },
              on: { "tab-click": _vm.changeTabName },
              model: {
                value: _vm.tagName,
                callback: function($$v) {
                  _vm.tagName = $$v
                },
                expression: "tagName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "专题", name: "genre" } },
                [
                  _vm.table_name === "专题"
                    ? _c("special", { attrs: { subjectCode: _vm.subjectCode } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "单元", name: "until" } },
                [
                  _vm.table_name === "单元"
                    ? _c("until", { attrs: { subjectCode: _vm.subjectCode } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "字数", name: "count" } },
                [
                  _vm.table_name === "字数"
                    ? _c("count", { attrs: { subjectCode: _vm.subjectCode } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "标签", name: "tags" } },
                [
                  _vm.table_name === "标签"
                    ? _c("tags", { attrs: { subjectCode: _vm.subjectCode } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "奖项", name: "price" } },
                [
                  _vm.table_name === "奖项"
                    ? _c("price", { attrs: { subjectCode: _vm.subjectCode } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "榜单", name: "writerList" } },
                [
                  _vm.table_name === "榜单"
                    ? _c("write-list", {
                        attrs: { subjectCode: _vm.subjectCode }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }