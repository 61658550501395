var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "update-announce"
    },
    [
      _c("edit-announcement", {
        attrs: { "submit-loading": _vm.submitLoading },
        on: {
          onResetClicked: _vm.onResetClicked,
          onSubmitClicked: _vm.onSubmitClicked,
          deleteLink: _vm.deleteLink
        },
        model: {
          value: _vm.announce,
          callback: function($$v) {
            _vm.announce = $$v
          },
          expression: "announce"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }