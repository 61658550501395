var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "general-dialog",
    {
      staticClass: "tz-product-information-dialog",
      attrs: { title: "商品信息", "footer-visible": false },
      model: {
        value: _vm.valueProxy,
        callback: function($$v) {
          _vm.valueProxy = $$v
        },
        expression: "valueProxy"
      }
    },
    [
      _c("div", { staticClass: "tz-product-information-content" }, [
        _c(
          "div",
          [
            _c("product-content", {
              ref: "table",
              attrs: {
                data: _vm.data,
                height: "calc(80vh - 130px)",
                "show-selection": false
              },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function() {
                    return [
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          align: "center",
                          width: "120",
                          label: "操作"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "actions" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-edit",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.toEdit(
                                              scope.row.productOpenId
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("详情")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("product-footer", {
              attrs: { total: _vm.total },
              on: { "page-changed": _vm.updateData },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dialogs" },
          [
            _c("real-goods-info", {
              attrs: {
                visible: _vm.goodsDialogVisible,
                "goods-id": _vm.goodsId
              },
              on: {
                "update:visible": function($event) {
                  _vm.goodsDialogVisible = $event
                },
                "update:goodsId": function($event) {
                  _vm.goodsId = $event
                },
                "update:goods-id": function($event) {
                  _vm.goodsId = $event
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }