"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _CardContent = _interopRequireDefault(require("../Base/CardContent.vue"));

var _CoverUpload = _interopRequireDefault(require("../../../../../General/Image/CoverUpload"));

var _index = _interopRequireDefault(require("../../../../../General/Image/ImageWall/index.vue"));

var _uploadOSS = require("../../../../../../utils/uploadOSS");

var _index2 = _interopRequireDefault(require("../../../../../General/File/VideoUploader/index.vue"));

var _main = require("../../../../../../organizationLibs/editor/main");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_Product_Product_EditProduct_EditPropaganda_index',
  components: {
    CardContent: _CardContent.default,
    CoverUpload: _CoverUpload.default,
    ImageWall: _index.default,
    VideoUploader: _index2.default,
    RichTextEditor: _main.Editor
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      path: "cover/".concat((0, _dayjs.default)().format('YYYY-MM-DD'), "/"),
      coverText: '建议尺寸750*750px，JPG、PNG格式， 图片小于5M。',
      coverArr: ['90', '90'],
      atlasArr: '你可以拖拽图片调整图片顺序',
      ossUploadFunction: _uploadOSS.productUploadBatchOSS,
      atlasImagePath: "atlas/".concat((0, _dayjs.default)().format('YYYY-MM-DD'), "/")
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    canImportFromSeries: function canImportFromSeries() {
      return !this.valueProxy.seriesCode;
    }
  },
  watch: {
    'valueProxy.showVideo': function valueProxyShowVideo(c, b) {
      if (!b) {
        return;
      }

      this.valueProxy.showVideoCover = null;
    }
  },
  mounted: function mounted() {
    this.reInitDataRtx();
  },
  methods: {
    deletecoverFun: function deletecoverFun() {
      this.valueProxy.cover = '';
    },
    reInitDataRtx: function reInitDataRtx() {
      var _this = this;

      setTimeout(function () {
        if (_this.$refs.rtx) {
          _this.$refs.rtx.setContent(_this.valueProxy.detail);
        }
      }, 382);
    },
    updateRtxData: function updateRtxData() {
      if (this.$refs.rtx) {
        this.valueProxy.detail = this.$refs.rtx.getContent();
      }
    }
  }
};
exports.default = _default2;