var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-book-box" },
    [
      _c(
        "div",
        { staticClass: "book-info-box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.bookInfo,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left"
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-box" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "info-form-item",
                      attrs: { label: "名称", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "info-input input-padding",
                        attrs: {
                          maxlength: "45",
                          "show-word-limit": "",
                          size: "mini",
                          placeholder: "请填写教材名称"
                        },
                        model: {
                          value: _vm.bookInfo.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.bookInfo,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "bookInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "info-form-item",
                      attrs: { label: "模型", prop: "bookMenuExtDataModelId" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "432px", height: "32px" },
                          attrs: {
                            size: "small",
                            filterable: "",
                            placeholder: "请选择教材模型",
                            value: ""
                          },
                          model: {
                            value: _vm.bookInfo.bookMenuExtDataModelId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.bookInfo,
                                "bookMenuExtDataModelId",
                                $$v
                              )
                            },
                            expression: "bookInfo.bookMenuExtDataModelId"
                          }
                        },
                        _vm._l(_vm.extDataList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "info-form-item",
                      attrs: { label: "教材封面" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.bookInfo.coverType,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "coverType", $$v)
                                },
                                expression: "bookInfo.coverType"
                              }
                            },
                            [_vm._v("纵向")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.bookInfo.coverType,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "coverType", $$v)
                                },
                                expression: "bookInfo.coverType"
                              }
                            },
                            [_vm._v("横向")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("cover-upload", {
                            staticClass: "cover-upload",
                            attrs: {
                              path: _vm.path,
                              type: "book",
                              coverarr: _vm.coverArr,
                              coverisshow: false,
                              covertext: ""
                            },
                            on: { deletecover: _vm.removeCover },
                            model: {
                              value: _vm.bookInfo.cover,
                              callback: function($$v) {
                                _vm.$set(_vm.bookInfo, "cover", $$v)
                              },
                              expression: "bookInfo.cover"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "info-form-item", attrs: { label: "ISBN" } },
                    [
                      _c("el-input", {
                        staticClass: "info-input",
                        attrs: {
                          maxlength: "13",
                          "show-word-limit": "",
                          size: "mini",
                          placeholder: "请输入"
                        },
                        model: {
                          value: _vm.bookInfo.isbn,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.bookInfo,
                              "isbn",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "bookInfo.isbn"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "info-form-item",
                      staticStyle: { width: "432px" },
                      attrs: { label: "备注" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "resizeNone",
                        attrs: {
                          maxlength: "200",
                          type: "textarea",
                          "show-word-limit": "",
                          rows: 3,
                          placeholder: "请填写教材备注"
                        },
                        model: {
                          value: _vm.bookInfo.description,
                          callback: function($$v) {
                            _vm.$set(_vm.bookInfo, "description", $$v)
                          },
                          expression: "bookInfo.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("教材属性")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-box" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "info-form-item sync-box",
                      staticStyle: { width: "656px" }
                    },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#F56C6C",
                              "margin-right": "4px"
                            }
                          },
                          [_vm._v("*")]
                        ),
                        _vm._v("同步")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "sectionCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择学段"
                              },
                              model: {
                                value: _vm.bookInfo.sectionCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "sectionCode", $$v)
                                },
                                expression: "bookInfo.sectionCode"
                              }
                            },
                            _vm._l(_vm.syncSectionInfo, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "subjectCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { margin: "0 16px" },
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: "请选择学科"
                              },
                              model: {
                                value: _vm.bookInfo.subjectCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "subjectCode", $$v)
                                },
                                expression: "bookInfo.subjectCode"
                              }
                            },
                            _vm._l(_vm.syncSubject, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "editionCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: "请选择版本"
                              },
                              model: {
                                value: _vm.bookInfo.editionCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "editionCode", $$v)
                                },
                                expression: "bookInfo.editionCode"
                              }
                            },
                            _vm._l(_vm.syncEdition, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "gradeCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "sync-select",
                              attrs: {
                                size: "small",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择年级"
                              },
                              model: {
                                value: _vm.bookInfo.gradeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "gradeCode", $$v)
                                },
                                expression: "bookInfo.gradeCode"
                              }
                            },
                            _vm._l(_vm.syncGrade, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "sync-select",
                              staticStyle: {
                                "margin-right": "auto",
                                "margin-left": "16px"
                              },
                              attrs: {
                                size: "small",
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择阶段"
                              },
                              model: {
                                value: _vm.bookInfo.volumeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.bookInfo, "volumeCode", $$v)
                                },
                                expression: "bookInfo.volumeCode"
                              }
                            },
                            _vm._l(_vm.syncVolume, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "info-form-item",
                      attrs: { label: "版次", prop: "periodCode" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            filterable: "",
                            placeholder: "请选择教材版次"
                          },
                          model: {
                            value: _vm.bookInfo.periodCode,
                            callback: function($$v) {
                              _vm.$set(_vm.bookInfo, "periodCode", $$v)
                            },
                            expression: "bookInfo.periodCode"
                          }
                        },
                        _vm._l(_vm.periodList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "add-button-box" }, [
        _c(
          "div",
          { staticClass: "sequence-box" },
          [
            _c("span", { staticClass: "sequence-title" }, [_vm._v("排序编号")]),
            _vm._v(" "),
            _c("el-input-number", {
              attrs: { "controls-position": "right" },
              model: {
                value: _vm.bookInfo.sequence,
                callback: function($$v) {
                  _vm.$set(_vm.bookInfo, "sequence", $$v)
                },
                expression: "bookInfo.sequence"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-button", { on: { click: _vm.cancel } }, [
              _vm._v("\n        取消\n      ")
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.checkParam } },
              [_vm._v("\n        保存\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.outerVisible,
              "expect-type": "isOnlyShowAudioBook",
              "exclude-data": _vm.checkList,
              dialogType: _vm.showType
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.getSelect
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }