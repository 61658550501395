"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ResourceRemark',
  props: {
    resourceObj: {
      type: Object,
      default: null
    } // 资源对象

  },
  methods: {
    foldRemark: function foldRemark(resource) {
      resource.isRemark = !resource.isRemark;
    }
  }
};
exports.default = _default;