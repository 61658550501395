var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "page", staticClass: "page", attrs: { id: "page" } },
    [
      _c(
        "div",
        {
          ref: "pageContent",
          staticClass: "page-content",
          style: _vm.editorPageStyle,
          attrs: { id: "pageContent", tabindex: "1", draggable: "false" },
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.quitEdit($event)
              },
              function($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 67) {
                  return null
                }
                if (!$event.ctrlKey) {
                  return null
                }
                return _vm.copySection($event)
              },
              function($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 86) {
                  return null
                }
                if (!$event.ctrlKey) {
                  return null
                }
                return _vm.pasteSection($event)
              }
            ],
            mousedown: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              $event.stopPropagation()
              return _vm.onMouseDown($event)
            },
            mousemove: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              $event.stopPropagation()
              return _vm.onMouseMove($event)
            },
            mouseup: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              $event.stopPropagation()
              return _vm.onMouseUp($event)
            },
            dragover: function($event) {
              $event.preventDefault()
              return _vm.throttleedDragOver($event)
            }
          }
        },
        [
          _vm._l(_vm.sections, function(section) {
            return _c("page-section", {
              key: section.localId,
              attrs: { "local-id": section.localId }
            })
          }),
          _vm._v(" "),
          _vm.localId
            ? _c("page-section", {
                attrs: {
                  "local-id": _vm.localId,
                  "temp-section": _vm.tempSection
                }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }