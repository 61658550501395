"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _promotionUserCheck = _interopRequireDefault(require("./promotionUserCheck.vue"));

var _promotionUserRecord = _interopRequireDefault(require("./promotionUserRecord.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'promotionUserIndex',
  components: {
    promotionUserCheck: _promotionUserCheck.default,
    promotionUserRecord: _promotionUserRecord.default
  },
  data: function data() {
    return {
      promoterCheckName: '推广员认证'
    };
  },
  methods: {
    promoterCheckTabs: function promoterCheckTabs(tab) {
      this.promoterCheckName = tab.label;
    }
  }
};
exports.default = _default;