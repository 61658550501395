var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-table" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            border: "",
            data: _vm.valueProxy,
            "row-key": "resourceOpenId",
            "header-cell-style": { background: "#F5F7FA" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", prop: "name", align: "left" }
          }),
          _vm._v(" "),
          _vm.showTag
            ? _c("el-table-column", {
                attrs: { label: "标签", align: "center", width: "150px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "tag-view" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("beautifulTextFilter")(
                                      scope.row.label
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "标签管理",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-biaoqian tag-icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleOpenLabel(
                                          scope.row,
                                          scope.$index
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3364384212
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "operator" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "播放",
                              placement: "top"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-video-play video-check",
                              on: {
                                click: function($event) {
                                  return _vm.openVideo(scope.row, scope.$index)
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "替换",
                              placement: "top"
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "el-icon-sort video-check replace-icon",
                              on: {
                                click: function($event) {
                                  return _vm.replaceVideo(
                                    scope.row,
                                    scope.$index
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "top"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete video-check",
                              on: {
                                click: function($event) {
                                  return _vm.handleRemove(
                                    scope.row.resourceOpenId,
                                    scope.$index
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }