var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "image-text-detail"
    },
    [
      _c(
        "div",
        { staticClass: "image-txt-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.textInfo,
                rules: _vm.rules,
                "label-width": "80px",
                "status-icon": ""
              }
            },
            [
              _c("div", { staticClass: "content-form" }, [
                _c(
                  "div",
                  { staticClass: "content-form-inner" },
                  [
                    _c("div", { staticClass: "title-text" }, [
                      _vm._v("\n              基本信息\n              "),
                      _vm.openId && _vm.textInfo.state
                        ? _c(
                            "div",
                            { staticClass: "state-bg" },
                            [
                              _c("span", { staticClass: "state-date" }, [
                                _vm._v(
                                  _vm._s(_vm.statusList[0].dateLabel) +
                                    ":" +
                                    _vm._s(
                                      _vm.getDateDiff(
                                        _vm.textInfo[_vm.statusList[0].dateName]
                                      )
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "timer",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "line-height": "20px" },
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.statusList[0].dateLabel) +
                                          "：" +
                                          _vm._s(
                                            _vm._f("parseTime")(
                                              _vm.textInfo[
                                                _vm.statusList[0].dateName
                                              ],
                                              "{y}-{m}-{d} {h}:{i}"
                                            )
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _vm.textInfo.createdAt
                                        ? _c("span", [
                                            _vm._v(
                                              "创建时间：" +
                                                _vm._s(
                                                  _vm._f("parseTime")(
                                                    _vm.textInfo.createdAt,
                                                    "{y}-{m}-{d} {h}:{i}"
                                                  )
                                                )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      staticClass: "state-tag",
                                      attrs: {
                                        type: _vm.statusList[0].typeName
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-information" },
                      [
                        _vm.textInfo.resourceOpenId
                          ? _c("el-form-item", { attrs: { label: "作品ID" } }, [
                              _vm.textInfo.resourceOpenId
                                ? _c("div", { staticClass: "title-id" }, [
                                    _vm._v(_vm._s(_vm.textInfo.resourceOpenId))
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "图文名称", prop: "title" } },
                          [
                            _c("el-autocomplete", {
                              staticClass: "title-input",
                              attrs: {
                                disabled: _vm.allowEdit,
                                "show-word-limit": "",
                                maxlength: 100,
                                debounce: 500,
                                placeholder: "请填写图文名称",
                                "fetch-suggestions": _vm.searchTitle
                              },
                              model: {
                                value: _vm.textInfo.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "title", $$v)
                                },
                                expression: "textInfo.title"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "作品编号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.allowEdit,
                                placeholder: "请输入作品编号",
                                maxlength: "50",
                                "show-word-limit": "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.textInfo.resourceEncode,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "resourceEncode", $$v)
                                },
                                expression: "textInfo.resourceEncode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "图文描述" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                disabled: _vm.allowEdit,
                                placeholder: "请填写图文描述"
                              },
                              model: {
                                value: _vm.textInfo.detail,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "detail", $$v)
                                },
                                expression: "textInfo.detail"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "图文封面", prop: "cover" } },
                          [
                            _c("cover-upload", {
                              attrs: {
                                disabledEdit: _vm.allowEdit,
                                path: _vm.path,
                                "partner-open-id": _vm.partnerOpenId,
                                type: _vm.IMAGE_TEXT_ID.toString(),
                                coverarr: _vm.coverArr,
                                covertext: _vm.coverText
                              },
                              on: { deletecover: _vm.deleteCoverFun },
                              model: {
                                value: _vm.textInfo.cover,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "cover", $$v)
                                },
                                expression: "textInfo.cover"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              model: {
                                value: _vm.textInfo.cover,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "cover", $$v)
                                },
                                expression: "textInfo.cover"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "图文详情", prop: "text" } },
                          [
                            _c("editor", {
                              attrs: {
                                readonly: _vm.allowEdit,
                                "is-show-fill-blank": true
                              },
                              model: {
                                value: _vm.textInfo.text,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "text", $$v)
                                },
                                expression: "textInfo.text"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "试看内容", prop: "previewText" } },
                          [
                            _c("div", { staticClass: "img-text" }, [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: { color: "#409EFF" }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text" }, [
                                _vm._v("若本图文内容需付费观看，请填写试看内容")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("editor", {
                              attrs: {
                                readonly: _vm.allowEdit,
                                "is-show-fill-blank": true
                              },
                              model: {
                                value: _vm.textInfo.previewText,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "previewText", $$v)
                                },
                                expression: "textInfo.previewText"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "title-text" }, [
                      _vm._v("\n            拓展学习栏目\n          ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "栏目内容" } },
                      [
                        _c("extended-learning", {
                          ref: "extendedLearning",
                          staticClass: "extended-learning",
                          attrs: {
                            "is-top-seat": true,
                            disable: _vm.allowEdit,
                            "show-seat": true
                          },
                          model: {
                            value: _vm.textInfo.testData,
                            callback: function($$v) {
                              _vm.$set(_vm.textInfo, "testData", $$v)
                            },
                            expression: "textInfo.testData"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "extended-tip" }, [
                          _c("i", { staticClass: "el-icon-warning info-icon" }),
                          _vm._v(
                            "\n              栏目位置上下，是指在正文上方或正文下方出现\n            "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-work" },
                      [
                        _c("div", { staticClass: "title-text" }, [
                          _vm._v("作品属性")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "同步" } },
                          [
                            _c("sync-view", {
                              attrs: { isDisabled: _vm.allowEdit },
                              model: {
                                value: _vm.textInfo.tempCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "tempCode", $$v)
                                },
                                expression: "textInfo.tempCode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "分类", prop: "types" } },
                          [
                            _c("works", {
                              ref: "worksView",
                              attrs: { isDisabled: _vm.allowEdit },
                              on: { "add-classtion": _vm.addClass }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              attrs: { value: _vm.textInfo.types }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "版次" } },
                          [
                            _c("edition-box", {
                              attrs: { isDisabled: _vm.allowEdit },
                              model: {
                                value: _vm.textInfo.periodCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "periodCode", $$v)
                                },
                                expression: "textInfo.periodCode"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              model: {
                                value: _vm.textInfo.periodCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.textInfo, "periodCode", $$v)
                                },
                                expression: "textInfo.periodCode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "类目",
                              prop: "secondRegimentationIds"
                            }
                          },
                          [
                            _c("category-view", {
                              attrs: { "is-disabled": _vm.allowEdit },
                              on: { "category-change": _vm.categoryChange },
                              model: {
                                value: _vm.textInfo.secondRegimentationIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.textInfo,
                                    "secondRegimentationIds",
                                    $$v
                                  )
                                },
                                expression: "textInfo.secondRegimentationIds"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              model: {
                                value: _vm.textInfo.secondRegimentationIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.textInfo,
                                    "secondRegimentationIds",
                                    $$v
                                  )
                                },
                                expression: "textInfo.secondRegimentationIds"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "property-box" },
                          [
                            _c("el-collapse-transition", [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isShowProperty,
                                      expression: "isShowProperty"
                                    }
                                  ],
                                  staticClass: "property-item-bg"
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "地区" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "850px" },
                                          attrs: {
                                            disabled: _vm.allowEdit,
                                            multiple: "",
                                            filterable: "",
                                            "default-first-option": "",
                                            placeholder: "请输入或选择地区"
                                          },
                                          model: {
                                            value: _vm.areaValue,
                                            callback: function($$v) {
                                              _vm.areaValue = $$v
                                            },
                                            expression: "areaValue"
                                          }
                                        },
                                        _vm._l(_vm.areaList, function(item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.name,
                                              value: item.code
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "标签" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "content-work-tags",
                                          attrs: {
                                            disabled: _vm.allowEdit,
                                            multiple: "",
                                            filterable: "",
                                            "allow-create": "",
                                            "default-first-option": "",
                                            placeholder: "请输入或选择商品标签"
                                          },
                                          on: { change: _vm.changeTags },
                                          model: {
                                            value: _vm.tagValue,
                                            callback: function($$v) {
                                              _vm.tagValue = $$v
                                            },
                                            expression: "tagValue"
                                          }
                                        },
                                        _vm._l(_vm.tagList, function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              class: [
                                "property-item-arrow",
                                _vm.isShowProperty
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down"
                              ],
                              on: {
                                click: function($event) {
                                  _vm.isShowProperty = !_vm.isShowProperty
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-commodity" },
                      [
                        _c("div", { staticClass: "title-text" }, [
                          _vm._v("商品设置")
                        ]),
                        _vm._v(" "),
                        _c("goods-set", {
                          ref: "commodity",
                          attrs: {
                            "type-name": _vm.typeName,
                            partnerOpenId: _vm.partnerOpenId,
                            disabledEdit: _vm.allowEdit,
                            "resource-open-id": _vm.openId,
                            "independent-sale": _vm.textInfo.independentSale
                          },
                          on: { "official-partner": _vm.getOfficialPartner }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content-footer" },
                [
                  _c("works-operation-button", {
                    attrs: {
                      openId: _vm.openId,
                      allowEdit: _vm.allowEdit,
                      operation: _vm.operation,
                      status: _vm.textInfo.state,
                      passBtn: _vm.passBtn,
                      refuseBtn: _vm.refuseBtn,
                      restoreBtn: _vm.restoreBtn,
                      publishBtn: _vm.publishBtn,
                      saveBtn: _vm.saveBtn,
                      typeName: _vm.typeName,
                      saveDraftBtn: _vm.saveDraftBtn
                    },
                    on: {
                      closeView: _vm.closeView,
                      updateEdit: _vm.updateEdit,
                      passItem: _vm.passItem,
                      handelRefuse: _vm.handelRefuse,
                      handelSaveAndReview: _vm.handelSaveAndReview,
                      handleBookRestore: _vm.handleBookRestore,
                      saveData: _vm.saveData,
                      saveDraft: _vm.saveDraft
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }