"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = readerFileCon;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)();

function readerFileCon(path) {
  return request.get("".concat(path));
}