var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "browsingHisWrap" }, [
    _c(
      "div",
      { staticClass: "tableDataSty" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableloading,
                expression: "tableloading"
              }
            ],
            attrs: {
              data: _vm.tableData,
              height: "100%",
              "header-cell-style": { background: "#F6F7FB", color: "#000000" }
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "商品" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-items": "center",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "goodsCover",
                                  staticStyle: { "margin-right": "8px" },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.conDataCover,
                                      _vm.thumbnailStyle
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "goodsCover",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(scope.row.pageTitle))]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { property: "time", label: "时间", align: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.format(scope.row.time * 1000)) +
                            "\n          "
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "t-block" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "current-page": _vm.query.page,
            "page-sizes": [10, 20, 30, 50],
            "page-size": _vm.query.size,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.totalElements
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }