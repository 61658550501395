"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.is-nan");

require("core-js/modules/es6.number.constructor");

var _main = require("@/organizationLibs/editor/main");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'EditImageText',
  components: {
    Editor: _main.Editor
  },
  props: {
    /* 标题 */
    title: {
      type: String,
      default: ''
    },

    /* 备注 */
    placeholder: {
      type: String,
      default: ''
    },

    /* v-modle绑定的参数 */
    param: {
      type: String,
      default: ''
    },

    /* 下标，用于list中的参数 */
    index: {
      type: Number,
      default: null
    },

    /* 类型，用于返回值是那个字段 */
    type: {
      type: String,
      default: ''
    },

    /* 是否为必填 */
    required: {
      type: String,
      default: ''
    },
    tooltipType: {
      type: String,
      default: ''
    },
    stateType: {
      type: Boolean,
      default: false
    },
    // 设置内容的最大高度
    contentMaxHeight: {
      type: Number,
      default: undefined
    }
  },
  data: function data() {
    return {
      label: this.title,
      innerVisible: false,
      content: '',
      editorType: false,
      allowEdit: false
    };
  },
  computed: {
    value: {
      get: function get() {
        // 判断参数是否为html格式
        if (/<[^>]+>/g.test(this.param) && this.param) {
          this.editorType = true;
        } else {
          this.editorType = false;
        }

        return this.param;
      },
      set: function set(msg) {
        this.$emit('changeRich', msg, this.index, this.type);
      }
    },
    editorContentStyle: function editorContentStyle() {
      // 如果设置了最大宽度
      var maxHeightStyle;

      if (typeof this.contentMaxHeight === 'number' && !Number.isNaN(this.contentMaxHeight) && Number.isFinite(this.contentMaxHeight) && this.contentMaxHeight > 0) {
        maxHeightStyle = {
          'max-height': "".concat(Math.ceil(this.contentMaxHeight), "px"),
          overflow: 'scroll'
        };
      }

      return _objectSpread({}, maxHeightStyle);
    }
  },
  watch: {
    param: function param(msg) {
      this.value = msg;
    }
  },
  methods: {
    /* 输入框后方的按钮点击处理 */
    richText: function richText() {
      // 弹出富文本编辑器
      this.innerVisible = true;
      var that = this; // 将内容赋值到富文本编辑器上，不加setTimeout的话赋不上值

      setTimeout(function () {
        that.content = that.value;
      }, 100);
    },

    /* 富文本弹窗取消按钮的处理 */
    cancel: function cancel() {
      this.innerVisible = false;
    },

    /* 富文本弹窗确定按钮的处理 */
    commit: function commit() {
      this.value = this.content;
      this.innerVisible = false; // 判断富文本内容是否为空，不为空则将editorType设为true，用于页面显示富文本内容

      if (this.content) {
        this.content = '';
        this.editorType = true;
      } else {
        this.editorType = false;
      }
    },

    /* 关闭富文本弹窗处理 */
    closeDialog: function closeDialog() {
      // 需清空当前富文本弹窗中的内容，不然下次赋值赋不上值
      this.content = '';
    }
  }
};
exports.default = _default;