"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _BooleanRadio = _interopRequireDefault(require("@/components/General/From/Radio/BooleanRadio"));

var _TheUnderlyingColumn = _interopRequireDefault(require("./TheUnderlyingColumn"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Ge_FilterOptions_StatusFiltering',
  components: {
    TheUnderlyingColumn: _TheUnderlyingColumn.default,
    BooleanRadio: _BooleanRadio.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hasState: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      state: [{
        key: '0',
        value: '全部'
      }, {
        key: '3',
        value: '在售'
      }, {
        key: '6',
        value: '未售'
      }],
      quota: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '限购'
      }, {
        key: '2',
        value: '不限'
      }],
      teacherBuy: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '仅教师可购'
      }, {
        key: '2',
        value: '不限'
      }],
      specificJson: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '有'
      }, {
        key: '2',
        value: '无'
      }],
      showVideo: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '有'
      }, {
        key: '2',
        value: '无'
      }],
      cover: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '有'
      }, {
        key: '2',
        value: '无'
      }]
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default2;