var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "小程序设置",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "dialog_body"
        },
        [
          _c(
            "div",
            { staticClass: "dialog_item display_flex justify_between" },
            [
              _c("div", [_vm._v("隐私协议")]),
              _vm._v(" "),
              _vm.privateRes
                ? _c("div", { staticClass: "display_flex" }, [
                    _c("img", {
                      staticClass: "cover_box",
                      attrs: { src: require("@/assets/ic_ok.png") }
                    }),
                    _vm._v("\n          设置成功\n        ")
                  ])
                : _c(
                    "div",
                    { staticClass: "display_flex" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.privateLoading,
                            disabled: _vm.allSetLoading
                          },
                          on: { click: _vm.setPrivacySetting }
                        },
                        [_vm._v("设置")]
                      )
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog_item display_flex justify_between" },
            [
              _c("div", [_vm._v("设置域名")]),
              _vm._v(" "),
              _vm.doMainRes
                ? _c("div", { staticClass: "display_flex" }, [
                    _c("img", {
                      staticClass: "cover_box",
                      attrs: { src: require("@/assets/ic_ok.png") }
                    }),
                    _vm._v("\n          设置成功\n        ")
                  ])
                : _c(
                    "div",
                    { staticClass: "display_flex" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.doMainLoading,
                            disabled: _vm.allSetLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.setModifyDomain("add")
                            }
                          }
                        },
                        [_vm._v("设置")]
                      )
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog_item display_flex justify_between" },
            [
              _c("div", [_vm._v("提交订单页信息")]),
              _vm._v(" "),
              _vm.orderRes
                ? _c("div", { staticClass: "display_flex" }, [
                    _c("img", {
                      staticClass: "cover_box",
                      attrs: { src: require("@/assets/ic_ok.png") }
                    }),
                    _vm._v("\n          设置成功\n        ")
                  ])
                : _c(
                    "div",
                    { staticClass: "display_flex" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.orderLoading,
                            disabled: _vm.allSetLoading
                          },
                          on: { click: _vm.setOrderInfo }
                        },
                        [_vm._v("设置")]
                      )
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog_item display_flex justify_between" },
            [
              _c("div", [_vm._v("开通云用户")]),
              _vm._v(" "),
              _vm.cloudRes
                ? _c("div", { staticClass: "display_flex" }, [
                    _c("img", {
                      staticClass: "cover_box",
                      attrs: { src: require("@/assets/ic_ok.png") }
                    }),
                    _vm._v("\n          设置成功\n        ")
                  ])
                : _c(
                    "div",
                    { staticClass: "display_flex" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.cloudLoading,
                            disabled: _vm.allSetLoading
                          },
                          on: { click: _vm.setCloudAccount }
                        },
                        [_vm._v("设置")]
                      )
                    ],
                    1
                  )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.allSetLoading },
              on: { click: _vm.allSet }
            },
            [_vm._v("一键配置")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }