"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var syncData = {
  namespaced: true,
  state: {
    syncObj: null,
    periodArr: null,
    category: null,
    areaArr: null
  },
  mutations: {
    SET_SYNC_OBJ: function SET_SYNC_OBJ(state, data) {
      state.syncObj = data;
    },
    SET_PERIOD_ARR: function SET_PERIOD_ARR(state, data) {
      state.periodArr = data;
    },
    SET_CATEGORY: function SET_CATEGORY(state, data) {
      state.category = data;
    },
    SET_AREA_ARR: function SET_AREA_ARR(state, data) {
      state.areaArr = data;
    }
  },
  actions: {
    set_sync_obj: function set_sync_obj(_ref, data) {
      var commit = _ref.commit;
      commit('SET_SYNC_OBJ', data);
    },
    set_period_arr: function set_period_arr(_ref2, data) {
      var commit = _ref2.commit;
      commit('SET_PERIOD_ARR', data);
    },
    set_category: function set_category(_ref3, data) {
      var commit = _ref3.commit;
      commit('SET_CATEGORY', data);
    },
    set_area_arr: function set_area_arr(_ref4, data) {
      var commit = _ref4.commit;
      commit('SET_AREA_ARR', data);
    }
  },
  getters: {
    syncObj: function syncObj(state) {
      return state.syncObj;
    },
    periodArr: function periodArr(state) {
      return state.periodArr;
    },
    category: function category(state) {
      return state.category;
    }
  }
};
var _default = syncData;
exports.default = _default;