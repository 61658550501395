var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resource-dot-bg" }, [
    _c("div", { staticClass: "resource-dot-box" }, [
      _c("div", [
        _vm.videoMarkEnable
          ? _c("div", { staticClass: "video-textarea box" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      ref: "dotList",
                      staticClass: "table-list",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.markList,
                        "empty-text": "请添加多媒体打点",
                        height: "226",
                        border: "",
                        size: "mini",
                        "row-class-name": "dot-row-bg",
                        "header-cell-class-name": "dot-table-header"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "date", width: "100", label: "时间点" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", { staticClass: "picker-info-bg" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatLongToTimeStr2(scope.row.time)
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2289603598
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "detail", label: "描述" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(" " + _vm._s(scope.row.detail))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3386774731
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "address", width: "90", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "operation-box" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-edit",
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            disabled: _vm.disabledEdit
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dotIsShow = true
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-del",
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            disabled: _vm.disabledEdit
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dotIsShow = true
                                            }
                                          }
                                        },
                                        [_vm._v("移除")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          83574467
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.videoMarkEnable
          ? _c(
              "div",
              { staticClass: "dot-btns-bg" },
              [
                _vm.videoMarkEnable && !_vm.disabledEdit
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          size: "mini",
                          disabled: _vm.disabledEdit
                        },
                        on: {
                          click: function($event) {
                            _vm.dotIsShow = true
                          }
                        }
                      },
                      [_vm._v("编辑打点")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.disabledEdit
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          plain: "",
                          size: "mini",
                          disabled: _vm.markList.length <= 0 || _vm.disabledEdit
                        },
                        on: { click: _vm.delAllMark }
                      },
                      [_vm._v("全部移除")]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dotIsShow,
            expression: "dotIsShow"
          }
        ],
        staticClass: "videodot"
      },
      [
        _c("Video-dot", {
          attrs: {
            dotisshow: _vm.dotIsShow,
            videourl: _vm.videourl,
            "resource-open-id": _vm.videoId.toString(),
            "resource-title": _vm.resourceTitle,
            content: _vm.markList
          },
          on: {
            "update:dotisshow": function($event) {
              _vm.dotIsShow = $event
            },
            "update:content": function($event) {
              _vm.markList = $event
            },
            dotbtnshow: _vm.isshow,
            addlistdate: _vm.addlistdate
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }