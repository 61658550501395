var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "groupLinkItem",
      class: _vm.showDel ? "bgSty" : "",
      on: {
        mouseenter: function($event) {
          _vm.showDel = true
        },
        mouseleave: function($event) {
          _vm.showDel = false
        }
      }
    },
    [
      _c("el-switch", {
        staticClass: "commonmarginRight",
        attrs: {
          "active-color": "#13ce66",
          "active-value": _vm.activeVvalue,
          "inactive-value": _vm.inactiveValue
        },
        model: {
          value: _vm.groupLinkItem.status,
          callback: function($$v) {
            _vm.$set(_vm.groupLinkItem, "status", $$v)
          },
          expression: "groupLinkItem.status"
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "commonmarginRight" }, [
        _vm._v("#" + _vm._s(_vm.currentIndex + 1))
      ]),
      _vm._v(" "),
      _vm.showUpload
        ? _c(
            "div",
            { staticClass: "commonmarginRight" },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    limit: 1,
                    "list-type": "picture-card",
                    action: "",
                    "http-request": _vm.resolveQR,
                    "before-upload": _vm.beforeAvatarUpload
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "commonmarginRight",
              staticStyle: { position: "relative" }
            },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: "150", placement: "right", trigger: "click" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("qrcode-vue", {
                        attrs: {
                          slot: "reference",
                          value: _vm.groupLinkItem.skipUrl,
                          size: 120,
                          level: "H"
                        },
                        slot: "reference"
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("qrcode-vue", {
                    staticStyle: { height: "32px" },
                    attrs: {
                      slot: "reference",
                      value: _vm.groupLinkItem.skipUrl,
                      size: 32,
                      level: "H"
                    },
                    slot: "reference"
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-error errorSty",
                on: {
                  click: [
                    _vm.handleRemove,
                    function($event) {
                      $event.stopPropagation()
                      return _vm.handleRemove(_vm.groupLinkItem.skipUrl)
                    }
                  ]
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commonmarginRight" },
        [
          _c("el-input", {
            staticStyle: { width: "128px" },
            attrs: { "show-word-limit": "", maxlength: "5" },
            model: {
              value: _vm.groupLinkItem.buttonName,
              callback: function($$v) {
                _vm.$set(_vm.groupLinkItem, "buttonName", $$v)
              },
              expression: "groupLinkItem.buttonName"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "commonmarginRight" },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入跳转链接" },
            model: {
              value: _vm.groupLinkItem.skipUrl,
              callback: function($$v) {
                _vm.$set(_vm.groupLinkItem, "skipUrl", $$v)
              },
              expression: "groupLinkItem.skipUrl"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        [
          _c(
            "span",
            {
              staticClass: "commonmarginRight",
              staticStyle: { color: "#606266", "font-size": "13px" }
            },
            [_vm._v("设置群人数")]
          ),
          _vm._v(" "),
          _c("el-input-number", {
            staticClass: "commonmarginRight",
            attrs: { size: "mini", "controls-position": "right", min: 1 },
            model: {
              value: _vm.groupLinkItem.groupCapacityNum,
              callback: function($$v) {
                _vm.$set(_vm.groupLinkItem, "groupCapacityNum", $$v)
              },
              expression: "groupLinkItem.groupCapacityNum"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-radio",
        {
          attrs: { label: 0 },
          model: {
            value: _vm.groupLinkItem.linkType,
            callback: function($$v) {
              _vm.$set(_vm.groupLinkItem, "linkType", $$v)
            },
            expression: "groupLinkItem.linkType"
          }
        },
        [_vm._v("二维码")]
      ),
      _vm._v(" "),
      _c(
        "el-radio",
        {
          attrs: { label: 1 },
          model: {
            value: _vm.groupLinkItem.linkType,
            callback: function($$v) {
              _vm.$set(_vm.groupLinkItem, "linkType", $$v)
            },
            expression: "groupLinkItem.linkType"
          }
        },
        [_vm._v("按钮")]
      ),
      _vm._v(" "),
      _vm.showDel
        ? _c("span", [
            _c("i", {
              staticClass: "el-icon-close",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.deleteLeadGroup }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }