var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-component" },
    [
      _c("div", { staticClass: "edit-title" }, [_vm._v("组件默认配置")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "basic-attributes" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组", prop: "competentLibraryGroup" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.form.competentLibraryGroup,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "competentLibraryGroup", $$v)
                        },
                        expression: "form.competentLibraryGroup"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "基础组件", value: "基础组件" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "课程组件", value: "课程组件" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "营销组件", value: "营销组件" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "其他", value: "其他" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    staticClass: "sequence",
                    attrs: { precision: 0, "controls-position": "right" },
                    model: {
                      value: _vm.form.sequence,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sequence", $$v)
                      },
                      expression: "form.sequence"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "数字越大，排位越靠前",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "icon el-icon-info",
                        staticStyle: {
                          "font-size": "14px",
                          color: "#409EFF",
                          "margin-left": "10px"
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "common-input",
                    attrs: { placeholder: "请输入名称", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图标", prop: "defaultIcon" } },
                [
                  _c(
                    "div",
                    { staticClass: "default-img" },
                    [
                      _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          coverarr: _vm.coverArr,
                          covertext: "默认展示图标",
                          texttips: _vm.textTips,
                          type: "material"
                        },
                        on: {
                          deletecover: function($event) {
                            return _vm.deleteCoverFun("defaultIcon")
                          }
                        },
                        model: {
                          value: _vm.form.defaultIcon,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "defaultIcon", $$v)
                          },
                          expression: "form.defaultIcon"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.form.defaultIcon,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "defaultIcon", $$v)
                          },
                          expression: "form.defaultIcon"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("cover-upload", {
                    attrs: {
                      path: _vm.path,
                      coverarr: _vm.coverArr,
                      covertext: "激活效果图标",
                      type: "material",
                      texttips: _vm.textTips
                    },
                    on: {
                      deletecover: function($event) {
                        return _vm.deleteCoverFun("activeIcon")
                      }
                    },
                    model: {
                      value: _vm.form.activeIcon,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "activeIcon", $$v)
                      },
                      expression: "form.activeIcon"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.form.defaultIcon,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "defaultIcon", $$v)
                      },
                      expression: "form.defaultIcon"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "competentBasics",
          staticClass: "default-config",
          attrs: {
            model: _vm.competentBasics,
            rules: _vm.baseRules,
            "label-width": "80px"
          }
        },
        [
          _c("div", { staticClass: "config-common-title" }, [
            _vm._v("基础设置")
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "组件外观", prop: "appearance" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.competentBasics.appearance,
                    callback: function($$v) {
                      _vm.$set(_vm.competentBasics, "appearance", $$v)
                    },
                    expression: "competentBasics.appearance"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "open" } }, [
                    _vm._v("展开")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "card" } }, [_vm._v("卡片")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "背景色", prop: "backgroundColor" } },
            [
              _c(
                "div",
                { staticClass: "common-color-border" },
                [
                  _c("el-color-picker", {
                    attrs: { size: "mini", "show-alpha": "" },
                    model: {
                      value: _vm.competentBasics.backgroundColor,
                      callback: function($$v) {
                        _vm.$set(_vm.competentBasics, "backgroundColor", $$v)
                      },
                      expression: "competentBasics.backgroundColor"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "common-color-text" }, [
                    _vm._v(_vm._s(_vm.competentBasics.backgroundColor))
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "字体主色", prop: "fontColor" } },
            [
              _c(
                "div",
                { staticClass: "common-color-border" },
                [
                  _c("el-color-picker", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.competentBasics.fontColor,
                      callback: function($$v) {
                        _vm.$set(_vm.competentBasics, "fontColor", $$v)
                      },
                      expression: "competentBasics.fontColor"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "common-color-text" }, [
                    _vm._v(_vm._s(_vm.competentBasics.fontColor))
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "内边距", prop: "contentPadding" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.competentBasics.contentPadding,
                    callback: function($$v) {
                      _vm.$set(_vm.competentBasics, "contentPadding", $$v)
                    },
                    expression: "competentBasics.contentPadding"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "margin" } }, [
                    _vm._v("留边")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "fill" } }, [_vm._v("填充")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "辅助边距", prop: "contentMarginList" } },
            [
              _c(
                "el-checkbox",
                { attrs: { label: "spacingTop", name: "spacingTop" } },
                [_vm._v("上边距")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                { attrs: { label: "spacingBottom", name: "spacingBottom" } },
                [_vm._v("下边距")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "set-title" },
            [
              _c("div", { staticClass: "set-title-absolute" }, [
                _vm._v("标题设置")
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "setTitle" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949"
                    },
                    on: { change: _vm.changeSetTitle },
                    model: {
                      value: _vm.competentBasics.setTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.competentBasics, "setTitle", $$v)
                      },
                      expression: "competentBasics.setTitle"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.competentBasics.setTitle
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题名称", prop: "titleName" } },
                    [
                      _c("el-input", {
                        staticClass: "common-input",
                        attrs: { placeholder: "请输入标题名称", clearable: "" },
                        model: {
                          value: _vm.competentBasics.titleName,
                          callback: function($$v) {
                            _vm.$set(_vm.competentBasics, "titleName", $$v)
                          },
                          expression: "competentBasics.titleName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "查看更多", prop: "seeMore" } },
                    [
                      _c("el-input", {
                        staticClass: "common-input",
                        attrs: { placeholder: "请输入名称", clearable: "" },
                        model: {
                          value: _vm.competentBasics.seeMore,
                          callback: function($$v) {
                            _vm.$set(_vm.competentBasics, "seeMore", $$v)
                          },
                          expression: "competentBasics.seeMore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer-btn" },
        [
          _c("el-button", { on: { click: _vm.handleReset } }, [_vm._v("返回")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-title" }, [
      _c("span", { staticClass: "common-title-icon" }),
      _vm._v("基础属性")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-title" }, [
      _c("span", { staticClass: "common-title-icon" }),
      _vm._v("默认配置")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }