"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.px2Proportion = px2Proportion;
exports.formatOffset = formatOffset;
exports.formatSize = formatSize;
exports.newSection = newSection;
exports.newIconSection = newIconSection;
exports.copyNewSection = copyNewSection;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.number.constructor");

var _md = _interopRequireDefault(require("md5"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function px2Proportion(px, total) {
  var ret = px * 100 / total;
  return parseFloat(Number(ret).toFixed(3));
}

function formatOffset(offset, unavailable) {
  var ret = offset;

  if (offset < 0) {
    ret = 0;
  }

  if (offset > 100 - unavailable) {
    ret = 100 - unavailable;
  }

  return parseFloat(Number(ret).toFixed(3));
}

function formatSize(offset, unavailable, min) {
  var ret = unavailable;

  if (unavailable < min) {
    ret = min;
  }

  if (unavailable > 100 - offset) {
    ret = 100 - offset;
  }

  return parseFloat(Number(ret).toFixed(3));
} // 1普通选区 2图片选区 3遮罩选区 4动画选区 5评论选区


function newSection(pageId, sequence, top, left, width, height, sectionType) {
  return {
    localId: (0, _md.default)("taoZhi".concat(new Date().getTime())),
    bookPageId: pageId,
    sequence: sequence,
    title: "\u65B0\u5EFA\u9009\u533A".concat(sequence),
    sectionType: sectionType >= 0 ? sectionType : 1,
    positionTop: top >= 0 ? top : 40,
    positionLeft: left >= 0 ? left : 30,
    positionWidth: width >= 0 ? width : 40,
    positionHeight: height >= 0 ? height : 20
  };
}

function newIconSection(pageId, sequence, url, sectionType) {
  return {
    localId: (0, _md.default)("taoZhi".concat(new Date().getTime())),
    bookPageId: pageId,
    sequence: sequence,
    title: "\u65B0\u5EFA\u9009\u533A".concat(sequence),
    sectionType: sectionType >= 0 ? sectionType : 2,
    // 默认2 图片选区（还有 4 动画选区）,
    iconUrl: url,
    positionTop: 40,
    positionLeft: 30,
    positionWidth: 40,
    positionHeight: 20
  };
}

function copyNewSection(copiedSection, sequence) {
  delete copiedSection.id;
  delete copiedSection.createdAt;
  return _objectSpread(_objectSpread({}, copiedSection), {}, {
    localId: (0, _md.default)("taoZhi".concat(new Date().getTime())),
    bookPageId: copiedSection.bookPageId,
    sequence: sequence,
    title: "\u590D\u5236\u9009\u533A".concat(sequence),
    sectionType: copiedSection.sectionType,
    iconUrl: copiedSection.iconUrl,
    positionTop: copiedSection.positionTop + 2,
    positionLeft: copiedSection.positionLeft + 2,
    positionWidth: copiedSection.positionWidth,
    positionHeight: copiedSection.positionHeight
  });
}