var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-lz-content upload-video" },
    [
      !_vm.disabledEdit
        ? _c("div", { staticClass: "common-flex" }, [
            _c("div", { staticClass: "multi-upload-bg" }, [
              _c(
                "div",
                {
                  staticClass: "upload-video-box common-flex",
                  style:
                    "width:" +
                    _vm.coverArr[0] +
                    "px;height:" +
                    _vm.coverArr[1] +
                    "px",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleClick($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "upload-video-icon common-flex" }, [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "el-icon-upload-title" }, [
                      _vm._v(_vm._s(_vm.textTips))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "myUpload",
                    staticClass: "file-upload",
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "myUpload",
                      multiple: _vm.multipleSelect,
                      type: "file",
                      accept: "image/*"
                    },
                    on: {
                      change: function($event) {
                        return _vm.Upload(undefined)
                      }
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _vm.coverIsShow
              ? _c("div", { staticClass: "upload-tip" }, [
                  _c("i", { staticClass: "el-icon-info" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "video-text" }, [
                    _vm._v(_vm._s(_vm.coverText))
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "image-list" },
        [
          _c(
            "draggable",
            { staticClass: "image-list", attrs: { list: _vm.valueProxy } },
            _vm._l(_vm.valueProxy, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "image-list-item",
                  style: "width:" + _vm.coverArr[0] + "px;"
                },
                [
                  _c("el-image", {
                    style:
                      "width:" +
                      _vm.coverArr[0] +
                      "px; height:" +
                      _vm.coverArr[1] +
                      "px; border-radius: 4px",
                    attrs: { fit: "cover", src: _vm.getImageUrl(item) }
                  }),
                  _vm._v(" "),
                  !_vm.disabledEdit && !_vm.isShowMask
                    ? _c(
                        "div",
                        { staticClass: "deleted-icon" },
                        [
                          _c("el-button", {
                            staticStyle: {
                              width: "18px",
                              height: "18px",
                              padding: "0"
                            },
                            attrs: {
                              icon: "el-icon-close",
                              type: "danger",
                              circle: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.deletedImage(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isShowMask
                    ? _c(
                        "div",
                        {
                          staticClass: "img-mask",
                          style:
                            "width:" +
                            _vm.coverArr[0] +
                            "px;height:" +
                            _vm.coverArr[1] +
                            "px"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mask",
                              style:
                                "width:" +
                                _vm.coverArr[0] +
                                "px;height:" +
                                _vm.coverArr[1] +
                                "px"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "iconfont icon-icon-test mask-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.onPreview(item)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "iconfont icon-qiehuandanchuang mask-icon change-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.replaceImg(item)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete mask-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.deletedImage(item)
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.imagePath
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "2030" },
            attrs: { "on-close": _vm.closeViewer, "url-list": [_vm.imagePath] }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }