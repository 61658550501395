var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingDetail,
          expression: "loadingDetail"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "addLeadGroupWrap" },
        [
          _c(
            "el-form",
            {
              ref: "addLeadGroupWrap",
              attrs: {
                rules: _vm.rules,
                model: _vm.LeadGroupForm,
                "label-position": "top",
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "引导加群名称", prop: "groupName" } },
                [
                  _c("div", { staticClass: "smallSize" }, [
                    _vm._v("仅显示在后台，用户区分各个引导加群。")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "432px" },
                    attrs: { "show-word-limit": "", maxlength: "45" },
                    model: {
                      value: _vm.LeadGroupForm.groupName,
                      callback: function($$v) {
                        _vm.$set(_vm.LeadGroupForm, "groupName", $$v)
                      },
                      expression: "LeadGroupForm.groupName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "引导方式" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#F5F7FA",
                      padding: "15px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "22px" },
                        attrs: { label: "引导标签内容设置", prop: "label" }
                      },
                      [
                        _c("div", { staticClass: "smallSize" }, [
                          _vm._v(
                            "显示在客户端，吸引用户加入社群，建议以入学年份命名，如：2021级学习群。"
                          )
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          staticStyle: { width: "432px" },
                          attrs: { "show-word-limit": "", maxlength: "16" },
                          model: {
                            value: _vm.LeadGroupForm.label,
                            callback: function($$v) {
                              _vm.$set(_vm.LeadGroupForm, "label", $$v)
                            },
                            expression: "LeadGroupForm.label"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "引导页面", prop: "pageCodes" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.LeadGroupForm.pageCodes,
                              callback: function($$v) {
                                _vm.$set(_vm.LeadGroupForm, "pageCodes", $$v)
                              },
                              expression: "LeadGroupForm.pageCodes"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "1" } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1001" } },
                                  [_vm._v("详情页引导加群")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right-start",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "540px",
                                          overflow: "auto",
                                          width: "300px"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "examplePng",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              "https://open.oss.taozhi.online/production/operation/operationManage/detailgroup.png"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("查看示例")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "1" } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1002" } },
                                  [_vm._v("购买成功页引导加群")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right-start",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "540px",
                                          overflow: "auto",
                                          width: "300px"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "examplePng",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              "https://open.oss.taozhi.online/production/operation/operationManage/paysuccess.png"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("查看示例")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "1" } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1003" } },
                                  [_vm._v("增值服务页引导加群")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right-start",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "540px",
                                          overflow: "auto",
                                          width: "300px"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "examplePng",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              "https://open.oss.taozhi.online/production/operation/operationManage/zzfw.png"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("查看示例")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "1" } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1004" } },
                                  [_vm._v("vip专区 ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right-start",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "540px",
                                          overflow: "auto",
                                          width: "300px"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "examplePng",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              "https://open.oss.taozhi.online/production/operation/operationManage/zhuanqu.png"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("查看示例")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "1" } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1005" } },
                                  [_vm._v("教师中心")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right-start",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "540px",
                                          overflow: "auto",
                                          width: "300px"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "examplePng",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              "https://open.oss.taozhi.online/production/operation/operationManage/teacher.png"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("查看示例")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "1" } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1006" } },
                                  [_vm._v("会员中心")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right-start",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "540px",
                                          overflow: "auto",
                                          width: "300px"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "examplePng",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              "https://open.oss.taozhi.online/production/operation/operationManage/member.png"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("查看示例")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "1",
                                  padding: "10px 0"
                                }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1007" } },
                                  [_vm._v("生字学习")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "1",
                                  padding: "10px 0"
                                }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "1008" } },
                                  [_vm._v("练字打卡")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "加群设置" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#F5F7FA",
                      padding: "15px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "引导加群描述" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "432px" },
                          attrs: { "show-word-limit": "", maxlength: "45" },
                          model: {
                            value: _vm.LeadGroupForm.description,
                            callback: function($$v) {
                              _vm.$set(_vm.LeadGroupForm, "description", $$v)
                            },
                            expression: "LeadGroupForm.description"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "群列表" } }, [
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v(
                          "支持多个跳转链接随机显示在客户端，若某用户已进入某一群，则直至此群链接手动删除前，仅显示此群链接。"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "15px",
                            "background-color": "#FFFFFF",
                            width: "fit-content"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._l(_vm.LeadGroupForm.groupLink, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c("group-link", {
                                      attrs: {
                                        groupLinkItem: item,
                                        currentIndex: index
                                      },
                                      on: {
                                        deleteLeadGroup: _vm.deleteLeadGroup
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "addQrItem",
                                  on: { click: _vm.addGroupLinkItem }
                                },
                                [_vm._v("添加")]
                              )
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用商户" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.LeadGroupForm.partenerType,
                        callback: function($$v) {
                          _vm.$set(_vm.LeadGroupForm, "partenerType", $$v)
                        },
                        expression: "LeadGroupForm.partenerType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "全部商户" } }, [
                        _vm._v("全部商户")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "指定商户" } }, [
                        _vm._v("指定商户")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.LeadGroupForm.partenerType === "指定商户"
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-bottom": "15px",
                                width: "560px",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("searchPartnerByInput", {
                                    ref: "searchPartner"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.addPartner($event)
                                        }
                                      }
                                    },
                                    [_vm._v("添加商户")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.LeadGroupForm.partnerOpenIdList &&
                              _vm.LeadGroupForm.partnerOpenIdList.length > 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        plain: "",
                                        size: "mini"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.delAllPartnerList($event)
                                        }
                                      }
                                    },
                                    [_vm._v("全部移除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.LeadGroupForm.partnerOpenIdList &&
                          _vm.LeadGroupForm.partnerOpenIdList.length > 0
                            ? _c(
                                "el-table",
                                {
                                  staticClass: "partner-list-bglead",
                                  attrs: {
                                    data: _vm.LeadGroupForm.partnerOpenIdList,
                                    border: "",
                                    stripe: "",
                                    "header-cell-class-name":
                                      "partner-row-header",
                                    "header-align": "center"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "商户",
                                      "min-width": "180"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "partner-info" },
                                                [
                                                  _c("el-image", {
                                                    staticClass:
                                                      "partner-cover-bg",
                                                    attrs: {
                                                      src: _vm.getImgUrl(
                                                        scope.row.partnerLogo,
                                                        _vm.thumbnailStyle
                                                      ),
                                                      fit: "cover"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "partner-info-bg"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "partner-info-name"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .partnerName
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "partner-info-id"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .partnerOpenId
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2352902914
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "60",
                                      align: "center",
                                      label: "操作"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    color: "#F56C6C"
                                                  },
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.delPartner(
                                                        scope.row.partnerOpenId
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("移除")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1177888510
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.LeadGroupForm.partenerType === "全部商户",
                          expression: "LeadGroupForm.partenerType==='全部商户'"
                        }
                      ],
                      staticStyle: {
                        "background-color": "#F5F7FA",
                        padding: "15px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用作品" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.LeadGroupForm.resourceType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.LeadGroupForm,
                                    "resourceType",
                                    $$v
                                  )
                                },
                                expression: "LeadGroupForm.resourceType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "全部作品" } }, [
                                _vm._v("全部作品")
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "指定作品" } }, [
                                _vm._v("指定作品")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.LeadGroupForm.resourceType === "指定作品"
                            ? _c("div", { staticClass: "goods-box" }, [
                                _c("div", { staticClass: "linegoods-box" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "btn-add-good",
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                _vm.outerVisible = true
                                              }
                                            }
                                          },
                                          [_vm._v("选择作品")]
                                        ),
                                        _vm._v(" "),
                                        _vm.LeadGroupForm.goodsList &&
                                        _vm.LeadGroupForm.goodsList.length > 0
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  plain: "",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delAllResourceList(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("全部移除")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.LeadGroupForm.goodsList &&
                                    _vm.LeadGroupForm.goodsList.length > 0
                                      ? _c(
                                          "el-table",
                                          {
                                            staticClass: "partner-list-bglead",
                                            attrs: {
                                              data: _vm.LeadGroupForm.goodsList,
                                              border: "",
                                              "header-cell-class-name":
                                                "goods-list-header",
                                              stripe: ""
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                type: "index",
                                                width: "50",
                                                label: "排序"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "商品",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(scope) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goods-con-bg"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .title
                                                                    ? scope.row
                                                                        .title
                                                                    : scope.row
                                                                        .resourceTitle
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1874600463
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                width: "60",
                                                align: "center",
                                                label: "操作"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(scope) {
                                                      return [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              color: "#F56C6C"
                                                            },
                                                            attrs: {
                                                              type: "text"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.delGoods(
                                                                  scope.row
                                                                    .resourceOpenId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("移除")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                387264356
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.LeadGroupForm.resourceType === "全部作品"
                            ? _c("el-form-item", { attrs: { label: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "line" }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#606266",
                                          margin: "0 10px"
                                        }
                                      },
                                      [_vm._v("所属学科")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "350px" },
                                        attrs: {
                                          clearable: "",
                                          multiple: "",
                                          placeholder: "请选择"
                                        },
                                        model: {
                                          value: _vm.LeadGroupForm.subjectCodes,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.LeadGroupForm,
                                              "subjectCodes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "LeadGroupForm.subjectCodes"
                                        }
                                      },
                                      _vm._l(_vm.allSyncSubject, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用用户", prop: "userTypes" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.LeadGroupForm.userTypes,
                        callback: function($$v) {
                          _vm.$set(_vm.LeadGroupForm, "userTypes", $$v)
                        },
                        expression: "LeadGroupForm.userTypes"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("普通用户")
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("VIP用户")
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("教师用户")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属年级", prop: "gradeCodes" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: {
                        clearable: "",
                        multiple: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.LeadGroupForm.gradeCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.LeadGroupForm, "gradeCodes", $$v)
                        },
                        expression: "LeadGroupForm.gradeCodes"
                      }
                    },
                    _vm._l(_vm.allSyncGrade, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用应用", prop: "appProductIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择",
                        multiple: ""
                      },
                      model: {
                        value: _vm.LeadGroupForm.appProductIds,
                        callback: function($$v) {
                          _vm.$set(_vm.LeadGroupForm, "appProductIds", $$v)
                        },
                        expression: "LeadGroupForm.appProductIds"
                      }
                    },
                    _vm._l(_vm.appoptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.title, value: item.openId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效时间" } },
                [
                  _c("div", { staticClass: "smallSize" }, [
                    _vm._v("空置时间，则视为永久有效。")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#606266" } }, [
                    _vm._v("开始时间")
                  ]),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择开始时间",
                      "default-time": "12:00:00"
                    },
                    model: {
                      value: _vm.LeadGroupForm.effectiveStartTime,
                      callback: function($$v) {
                        _vm.$set(_vm.LeadGroupForm, "effectiveStartTime", $$v)
                      },
                      expression: "LeadGroupForm.effectiveStartTime"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#606266", "margin-left": "15px" }
                    },
                    [_vm._v("结束时间")]
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择结束时间",
                      "default-time": "23:59:59"
                    },
                    model: {
                      value: _vm.LeadGroupForm.effectiveEndTime,
                      callback: function($$v) {
                        _vm.$set(_vm.LeadGroupForm, "effectiveEndTime", $$v)
                      },
                      expression: "LeadGroupForm.effectiveEndTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "t-block1" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c("span", { staticStyle: { color: "#303133" } }, [_vm._v("排序")]),
            _vm._v(" "),
            _c("el-input-number", {
              staticStyle: { width: "100px", margin: "0 10px" },
              attrs: { "controls-position": "right", size: "mini" },
              model: {
                value: _vm.LeadGroupForm.weight,
                callback: function($$v) {
                  _vm.$set(_vm.LeadGroupForm, "weight", $$v)
                },
                expression: "LeadGroupForm.weight"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "smallSize" }, [
              _vm._v("数字越大权重越高")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "t-block",
            staticStyle: { display: "flex", "align-items": "center" }
          },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function($event) {
                    _vm.dialogOperation = true
                  }
                }
              },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.btnLoading },
                on: {
                  click: function($event) {
                    return _vm.saveDate("addLeadGroupWrap")
                  }
                }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "即将离开",
            visible: _vm.dialogOperation,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogOperation = $event
            }
          }
        },
        [
          _c("span", [_vm._v("未保存/提交的内容即将丢失，是否保存后离开？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogOperation = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.goBack } },
                [_vm._v("不保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.saveDate("addLeadGroupWrap")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.outerVisible,
              "expect-type": "commodity",
              "exclude-data": _vm.LeadGroupForm.goodsList,
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.addGoods
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }