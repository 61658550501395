var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questionClassEdit" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "840px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "treeList" }, [
            _c("div", { staticClass: "listTop" }, [
              _c("span", [_vm._v("分类")]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "刷新",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-refresh-right",
                        on: { click: _vm.getTreeData }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "添加",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        on: { click: _vm.addPaperClass }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: !_vm.isOpen ? "展开" : "收起",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        class: _vm.isOpen
                          ? "el-icon-s-unfold"
                          : "el-icon-s-fold",
                        on: { click: _vm.isOpenTree }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "treeBox" },
              [
                _c("el-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeLoading,
                      expression: "treeLoading"
                    }
                  ],
                  ref: "tree",
                  attrs: {
                    "node-key": "id",
                    "default-expand-all": _vm.isOpen,
                    data: _vm.treeData,
                    props: _vm.treeDataProps,
                    "filter-node-method": _vm.filterNode
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c(
                          "div",
                          {
                            staticClass: "custom-tree-node",
                            staticStyle: { width: "100%" },
                            on: {
                              mouseenter: function($event) {
                                $event.stopPropagation()
                                return _vm.mouseoverFun(data)
                              },
                              mouseleave: function($event) {
                                $event.stopPropagation()
                                return _vm.mouseoutFun()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "treeName" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(node.label) +
                                  "\n                "
                              ),
                              _c(
                                "span",
                                [
                                  node.level > 0
                                    ? _c(
                                        "el-dropdown",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: data.id === _vm.mouseid,
                                              expression: "data.id === mouseid"
                                            }
                                          ],
                                          staticStyle: {
                                            position: "absolute",
                                            right: "15px"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-more"
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown"
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.nodeOperation(
                                                        data,
                                                        "add"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("添加子分类")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.nodeOperation(
                                                        data,
                                                        "reName"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("重命名")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.nodeOperation(
                                                        data,
                                                        "move"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("移动到")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.reDel(node)
                                                    }
                                                  }
                                                },
                                                [_vm._v("删除")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.paperClassTitle,
            visible: _vm.paperClassVisible,
            width: "700px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.paperClassVisible = $event
            }
          }
        },
        [
          _vm.paperClassTitle === "添加子分类"
            ? _c(
                "div",
                { staticClass: "addInput" },
                [
                  _c("span", [_vm._v("根目录")]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.parentName,
                      callback: function($$v) {
                        _vm.parentName = $$v
                      },
                      expression: "parentName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "addInput" },
            [
              _c("span", [_vm._v("分类名称")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "90%" },
                model: {
                  value: _vm.className,
                  callback: function($$v) {
                    _vm.className = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "className"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.paperClassVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dialogConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }