"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _index = _interopRequireDefault(require("@/components/SearchOrdinaryuser/index.vue"));

var _UserBaseInfo = _interopRequireDefault(require("@/components/UserDetail/UserBaseInfo.vue"));

var _MerchantsInfo = _interopRequireDefault(require("@/components/UserDetail/MerchantsInfo.vue"));

var _FinancialInfo = _interopRequireDefault(require("@/components/UserDetail/FinancialInfo.vue"));

var _TeacherInfo = _interopRequireDefault(require("@/components/UserDetail/TeacherInfo.vue"));

var _OperationLog = _interopRequireDefault(require("@/components/UserDetail/OperationLog.vue"));

var _BrowsingHistory = _interopRequireDefault(require("@/components/UserDetail/BrowsingHistory.vue"));

var _OrderRecord = _interopRequireDefault(require("@/components/UserDetail/OrderRecord.vue"));

var _commentMangent = _interopRequireDefault(require("@/components/UserDetail/commentMangent.vue"));

var _CollectionHistory = _interopRequireDefault(require("@/components/UserDetail/CollectionHistory.vue"));

var _LearningRecords = _interopRequireDefault(require("@/components/UserDetail/LearningRecords.vue"));

var _BalanceRecord = _interopRequireDefault(require("./BalanceRecord"));

var _UserAddress = _interopRequireDefault(require("./UserAddress"));

var _DownloadHistory = _interopRequireDefault(require("@/components/UserDetail/DownloadHistory.vue"));

var _DeviceManagement = _interopRequireDefault(require("@/components/UserDetail/DeviceManagement.vue"));

var _HistoricalWorkorder = _interopRequireDefault(require("@/components/UserDetail/HistoricalWorkorder.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    DeviceManagement: _DeviceManagement.default,
    searchUserByInput: _index.default,
    UserBaseInfo: _UserBaseInfo.default,
    TeacherInfo: _TeacherInfo.default,
    MerchantsInfo: _MerchantsInfo.default,
    OperationLog: _OperationLog.default,
    FinancialInfo: _FinancialInfo.default,
    BrowsingHistory: _BrowsingHistory.default,
    OrderRecord: _OrderRecord.default,
    BalanceRecord: _BalanceRecord.default,
    ManagementComment: _commentMangent.default,
    DownloadHistory: _DownloadHistory.default,
    CollectionHistory: _CollectionHistory.default,
    UserAddress: _UserAddress.default,
    LearningRecords: _LearningRecords.default,
    HistoricalWorkOrder: _HistoricalWorkorder.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    propActiveName: {
      type: String,
      default: '基本信息'
    },
    userOpenId: {
      type: String,
      default: ''
    },
    partnerOpenId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      currentuserOpenId_order: '',
      currentActiveName: '基本信息',
      showCommentBol: false,
      activeName: '基本信息',
      dialogUserdetail: false,
      currentuserOpenId: '',
      currentpartnerOpenId: ''
    };
  },
  watch: {
    userOpenId: {
      immediate: true,
      deep: true,
      handler: function handler(val) {
        if (val) {
          this.currentuserOpenId = val;
          this.currentuserOpenId_order = val;
        } else {
          this.currentuserOpenId = '';
          this.currentuserOpenId_order = '';
        }
      }
    },
    partnerOpenId: {
      immediate: true,
      deep: true,
      handler: function handler(val) {
        if (val) {
          this.currentpartnerOpenId = val;
        } else {
          this.currentpartnerOpenId = '';
        }
      }
    },
    visible: {
      immediate: true,
      deep: true,
      handler: function handler(val) {
        if (val) {
          this.activeName = this.propActiveName;
          this.currentActiveName = this.propActiveName;
        }
      }
    }
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.currentActiveName = tab.name;
      this.currentuserOpenId_order = JSON.parse(JSON.stringify(this.currentuserOpenId));

      if (tab.name === '订单记录' && this.currentpartnerOpenId) {
        this.currentuserOpenId_order = '';
      }
    },

    /* 获取选择用户中partnerOpenId */
    receiveuserid: function receiveuserid(val) {
      this.currentuserOpenId = val;
      this.currentuserOpenId_order = val;
      this.$store.dispatch('set_partnerOpenId', undefined);
    },
    closeDialogUserdetail: function closeDialogUserdetail(val) {
      this.dialogUserdetail = val;
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
      this.$emit('clearuserOpenId');
      this.$refs.searchUserByInput.openId = '';
      this.$store.dispatch('set_partnerOpenId', undefined);
    }
  }
};
exports.default = _default;