"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SpecialPage',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    multipleCheck: {
      type: Boolean,
      default: false
    },
    // 多选
    multipleVal: {
      type: Array,
      default: function _default() {
        return [];
      }
    } // 多选

  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    // 再次点击取消
    clickRadio: function clickRadio(val) {
      this.valueProxy.specialPageLink = val === this.valueProxy.specialPageLink ? undefined : val;
    }
  }
};
exports.default = _default2;