"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("../modelManagement/index"));

//
//
//
var _default = {
  name: 'ManuScriptManagement',
  components: {
    mainView: _index.default
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;