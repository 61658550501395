var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "haltSaleBook",
      attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
      on: {
        "update:visible": function($event) {
          _vm.dialogRefuse = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "refuseForm",
          attrs: {
            model: _vm.refuseForm,
            rules: _vm.refuseRules,
            "label-width": "110px",
            "label-position": "right"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "驳回原因", prop: "reason" } },
            [
              _c("el-input", {
                staticClass: "txt-reason",
                attrs: {
                  autosize: { minRows: 1, maxRows: 1 },
                  type: "textarea",
                  placeholder: "请简述驳回原因，不超过20个字"
                },
                model: {
                  value: _vm.refuseForm.reason,
                  callback: function($$v) {
                    _vm.$set(_vm.refuseForm, "reason", $$v)
                  },
                  expression: "refuseForm.reason"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogRefuse = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.refuseBtn },
              on: {
                click: function($event) {
                  return _vm.refuseObj()
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }