"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'AttributeQuestionItem',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      checkList: []
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.checkList = (0, _toConsumableArray2.default)(val);
      }
    }
  },
  methods: {
    changeGroup: function changeGroup(val) {
      this.$emit('changeGroup', val);
    }
  }
};
exports.default = _default2;