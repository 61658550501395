var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "management-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.commonTitle,
            visible: _vm.dialogVisibles,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibles = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "20" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", "show-word-limit": "" },
                    model: {
                      value: _vm.addReview,
                      callback: function($$v) {
                        _vm.addReview =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addReview"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitReview } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-container",
        [
          _vm.drawer
            ? _c("div", { staticClass: "leftContainer" }, [
                _c("div", { staticClass: "drawerTop" }, [
                  _c("span", [
                    _c("i", {
                      staticClass: "el-icon-arrow-left",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.drawer = false
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("快捷点评")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = true
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#606266", cursor: "pointer" }
                        },
                        [_vm._v("管理")]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-s-tools",
                        staticStyle: { cursor: "pointer" }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.commentLoading,
                        expression: "commentLoading"
                      }
                    ],
                    staticStyle: { "padding-top": "10px" }
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        attrs: { stretch: "" },
                        on: { "tab-click": _vm.checkCommonInfo },
                        model: {
                          value: _vm.commentClassName,
                          callback: function($$v) {
                            _vm.commentClassName = $$v
                          },
                          expression: "commentClassName"
                        }
                      },
                      _vm._l(_vm.commentClass, function(item) {
                        return _c(
                          "el-tab-pane",
                          {
                            key: item.id,
                            attrs: {
                              label: item.remarkClassName,
                              name: item.remarkCode
                            }
                          },
                          [
                            _vm.commentClass.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "commonListCon" },
                                  [
                                    _vm._l(_vm.commonInfo, function(
                                      info,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "commentList",
                                          on: {
                                            click: function($event) {
                                              return _vm.showContent(info)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(info.content))
                                          ]),
                                          _vm._v(" "),
                                          _c("el-divider")
                                        ],
                                        1
                                      )
                                    }),
                                    _vm._v(" "),
                                    _vm.commonInfo.length === 0
                                      ? _c("div", { staticClass: "noInfo" }, [
                                          _vm._v(
                                            "\n                暂无数据\n              "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-click-modal": false,
                    title: "管理点评",
                    visible: _vm.dialogVisible,
                    width: "700px",
                    "before-close": _vm.handleClose
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "managementReviewBox" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "boxLeft", attrs: { span: 7 } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "boxLeftTop",
                                  on: { click: _vm.addcomon }
                                },
                                [
                                  _c("strong", [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-plus-outline",
                                      staticStyle: { cursor: "pointer" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "padding-left": "10px",
                                        cursor: "pointer"
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { overflow: "auto" } },
                                [
                                  _c("el-tree", {
                                    attrs: {
                                      props: _vm.commonProps,
                                      data: _vm.commentClass,
                                      "node-key": "remarkCode",
                                      "expand-on-click-node": false
                                    },
                                    on: { "node-click": _vm.handleNodeClick },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var node = ref.node
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            {
                                              staticClass: "custom-tree-node",
                                              staticStyle: { width: "100%" },
                                              on: {
                                                mouseenter: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.mouseoverFun(data)
                                                },
                                                mouseleave: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.mouseoutFun()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "70%",
                                                    "white-space": "nowrap",
                                                    overflow: "hidden",
                                                    "text-overflow": "ellipsis"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "180px"
                                                      },
                                                      style:
                                                        data.id ===
                                                        _vm.checkMenuID
                                                          ? "color:#409EFF;"
                                                          : ""
                                                    },
                                                    [_vm._v(_vm._s(node.label))]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            data.id ===
                                                            _vm.mouseid,
                                                          expression:
                                                            "data.id === mouseid"
                                                        }
                                                      ],
                                                      staticStyle: {
                                                        position: "absolute",
                                                        right: "10px"
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-edit",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.editCommon(
                                                              data
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete-solid",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.delCommon(
                                                              data
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "boxRight", attrs: { span: 17 } },
                            [
                              _c(
                                "div",
                                { staticClass: "boxRightTop" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        placeholder: "搜索快捷点评",
                                        maxlength: "30"
                                      },
                                      model: {
                                        value: _vm.searchReview,
                                        callback: function($$v) {
                                          _vm.searchReview = $$v
                                        },
                                        expression: "searchReview"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { slot: "append" },
                                          on: { click: _vm.searchCommon },
                                          slot: "append"
                                        },
                                        [_vm._v("搜索")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "elTree" },
                                [
                                  _c("el-tree", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.commonListLoading,
                                        expression: "commonListLoading"
                                      }
                                    ],
                                    attrs: {
                                      props: _vm.defaultProps,
                                      data: _vm.commonInfos,
                                      "node-key": "id",
                                      "expand-on-click-node": false
                                    },
                                    on: { "node-click": _vm.handleNodeClicks },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var node = ref.node
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            {
                                              staticClass: "custom-tree-node",
                                              staticStyle: { width: "100%" },
                                              on: {
                                                mouseenter: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.mouseoverFuns(data)
                                                },
                                                mouseleave: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.mouseoutFuns()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "commentInfo" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "180px"
                                                      },
                                                      style:
                                                        data.id ===
                                                        _vm.checkRightMenuID
                                                          ? "color:#409EFF;"
                                                          : ""
                                                    },
                                                    [_vm._v(_vm._s(node.label))]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            data.id ===
                                                            _vm.mouseids,
                                                          expression:
                                                            "data.id === mouseids"
                                                        }
                                                      ],
                                                      staticStyle: {
                                                        position: "absolute",
                                                        right: "10px"
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete-solid",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.delCommonInfo(
                                                              data
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "boxRightBottom" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "show-word-limit": "",
                                      autosize: { minRows: 3 },
                                      type: "textarea",
                                      rows: 3,
                                      "show-password": "",
                                      placeholder: "添加/编辑快捷评论",
                                      maxlength: "191"
                                    },
                                    model: {
                                      value: _vm.addEditReview,
                                      callback: function($$v) {
                                        _vm.addEditReview = $$v
                                      },
                                      expression: "addEditReview"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dialogFooter",
                                      attrs: { slot: "footer" },
                                      slot: "footer"
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: _vm.addCommentClassInfos
                                          }
                                        },
                                        [_vm._v("保存")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-main", [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "mainLeft", attrs: { span: 18 } },
                      [
                        _c("div", { staticClass: "imgOperation" }, [
                          _c(
                            "div",
                            {
                              staticClass: "elCol",
                              staticStyle: { width: "15%" }
                            },
                            [
                              _c(
                                "strong",
                                {
                                  staticStyle: {
                                    width: "180px",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis"
                                  }
                                },
                                [
                                  _vm._v(
                                    "稿件 # " +
                                      _vm._s(this.manuScriptInfo.manuscriptName)
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "elCol",
                              staticStyle: { width: "40%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "imgLists" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-arrow-left",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.nextImg(-1)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.manuScriptInfo.manuscriptPictureList,
                                    function(item, index) {
                                      return _c("el-image", {
                                        key: index,
                                        staticClass: "imgListsInfo",
                                        class:
                                          _vm.imgkeys === index
                                            ? "imgBorder"
                                            : "",
                                        attrs: {
                                          fit: "cover",
                                          src: _vm.getImgUrl(item.imageUrl)
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeImg(item, index)
                                          }
                                        }
                                      })
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.nextImg(1)
                                      }
                                    }
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "operBtn" },
                                [
                                  _c("svg-icon", {
                                    class: this.imgSize === 100 ? "islo" : "",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "icon-class": "icon-narrow" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.imgSizeRed()
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        margin: "5px",
                                        "font-size": "12px"
                                      }
                                    },
                                    [_vm._v(_vm._s(this.imgSize) + "%")]
                                  ),
                                  _vm._v(" "),
                                  _c("svg-icon", {
                                    class: this.imgSize === 175 ? "islo" : "",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "icon-class": "icon-enlarge" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.imgSizeAdd()
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "operBtn" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { "icon-class": "icon-rotate-left" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.transForm(-90)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("svg-icon", {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      "icon-class": "icon-rotate-right"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.transForm(90)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "elCol",
                              staticStyle: { color: "#81848a", width: "45%" }
                            },
                            [
                              this.manuScriptInfo.status === 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                this.manuScriptInfo.updatedAt
                                              ) +
                                              "\n                    "
                                          ),
                                          _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px"
                                              },
                                              attrs: { type: "success" }
                                            },
                                            [_vm._v("合格")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.manuScriptInfo.status === -1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                this.manuScriptInfo.updatedAt
                                              ) +
                                              "\n                    "
                                          ),
                                          _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px"
                                              },
                                              attrs: { type: "danger" }
                                            },
                                            [_vm._v("不合格")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.manuScriptInfo.status === 0
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                this.manuScriptInfo.createdAt
                                              ) +
                                              "\n                    "
                                          ),
                                          _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px"
                                              }
                                            },
                                            [_vm._v("待审核")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "imgMain" }, [
                          _c(
                            "div",
                            {
                              style: "width: " + (_vm.imgWidth + "px"),
                              attrs: { id: "imgContainer" }
                            },
                            [
                              _c("img", {
                                style: "width: " + (_vm.imgWidth + "px"),
                                attrs: {
                                  id: "imgCon",
                                  src: _vm.getImgUrl(_vm.fristImg.imageUrl)
                                }
                              })
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticClass: "mainRight", attrs: { span: 6 } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%", overflow: "hidden" }
                          },
                          [
                            _c(
                              "el-collapse",
                              {
                                model: {
                                  value: _vm.foldNames,
                                  callback: function($$v) {
                                    _vm.foldNames = $$v
                                  },
                                  expression: "foldNames"
                                }
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        { staticClass: "block collapses" },
                                        [
                                          _vm.manuScriptInfo.cover !== null
                                            ? _c("el-avatar", {
                                                attrs: {
                                                  shape: "square",
                                                  size: "medium",
                                                  src: _vm.getImgUrl(
                                                    _vm.manuScriptInfo.cover
                                                  )
                                                }
                                              })
                                            : _c("el-avatar", {
                                                attrs: {
                                                  src: require("../../../assets/user-default.png"),
                                                  shape: "square",
                                                  size: "medium",
                                                  fit: "cover"
                                                }
                                              }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "5px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.manuScriptInfo
                                                        .nickName
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#909399",
                                                    width: "100%"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.manuScriptInfo
                                                        .userOpenId
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "authorInfo" }, [
                                      _c("div", [
                                        _vm._v(
                                          "姓名: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.authorName
                                                ? _vm.manuScriptInfo.authorName
                                                : "暂无"
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v("地址:\n                      "),
                                        _vm.manuScriptInfo
                                          .schoolProvinceName !== null
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.manuScriptInfo
                                                      .schoolProvinceName
                                                  ) +
                                                  "\n                      " +
                                                  _vm._s(
                                                    _vm.manuScriptInfo
                                                      .schoolCityName
                                                  ) +
                                                  "\n                      " +
                                                  _vm._s(
                                                    _vm.manuScriptInfo
                                                      .schoolDistrictName
                                                  ) +
                                                  "\n                      " +
                                                  _vm._s(
                                                    _vm.manuScriptInfo
                                                      .detailAddress
                                                  ) +
                                                  "\n                      "
                                              )
                                            ])
                                          : _c("span", [_vm._v("暂未填写")])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "学校: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.schoolName
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "手机: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.cellPhoneNumber
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "QQ: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.qq
                                                ? _vm.manuScriptInfo.qq
                                                : "--"
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "投稿: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.mstType === 1
                                                ? "全解杯"
                                                : "普通"
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "指导老师: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.adviser
                                                ? _vm.manuScriptInfo.adviser
                                                : "--"
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "指导老师手机号: " +
                                            _vm._s(
                                              _vm.manuScriptInfo.adviserPhone
                                                ? _vm.manuScriptInfo
                                                    .adviserPhone
                                                : "--"
                                            )
                                        )
                                      ])
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-tabs",
                              {
                                staticClass: "operateTabs",
                                attrs: { stretch: "" },
                                on: { "tab-click": _vm.showDrawer },
                                model: {
                                  value: _vm.manuscript,
                                  callback: function($$v) {
                                    _vm.manuscript = $$v
                                  },
                                  expression: "manuscript"
                                }
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "稿件属性",
                                      name: "properties"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          overflow: "auto",
                                          height: "calc(100vh - 300px)"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "propertiesForm",
                                            attrs: {
                                              model: _vm.propertiesForm,
                                              "label-width": "50px"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "类型" } },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticClass: "radioButton",
                                                    model: {
                                                      value:
                                                        _vm.manuscriptTypes,
                                                      callback: function($$v) {
                                                        _vm.manuscriptTypes = $$v
                                                      },
                                                      expression:
                                                        "manuscriptTypes"
                                                    }
                                                  },
                                                  _vm._l(_vm.types, function(
                                                    item,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "el-radio-button",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value
                                                        }
                                                      }
                                                    )
                                                  }),
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "学科" } },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticClass: "radioButton",
                                                    on: {
                                                      change: _vm.changeSubject
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.manuScriptInfo
                                                          .subjectName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.manuScriptInfo,
                                                          "subjectName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "manuScriptInfo.subjectName"
                                                    }
                                                  },
                                                  _vm._l(_vm.subject, function(
                                                    item
                                                  ) {
                                                    return _c(
                                                      "el-radio-button",
                                                      {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value
                                                        }
                                                      }
                                                    )
                                                  }),
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "体裁" } },
                                              [
                                                _c("el-cascader", {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    "expand-change": "getGenre",
                                                    options: _vm.genreList,
                                                    props: _vm.genreProps,
                                                    clearable: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.manuScriptInfo
                                                        .typeCodes,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.manuScriptInfo,
                                                        "typeCodes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "manuScriptInfo.typeCodes"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            this.manuscriptTypes === "同步"
                                              ? _c(
                                                  "el-form-item",
                                                  { attrs: { label: "版本" } },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%"
                                                        },
                                                        attrs: {
                                                          placeholder: "请选择"
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeUntil
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.manuScriptInfo
                                                              .sectionCode,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.manuScriptInfo,
                                                              "sectionCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "manuScriptInfo.sectionCode"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.editionLists,
                                                        function(item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.code
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.getUntil(
                                                                    $event
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "年级" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      placeholder: "请选择"
                                                    },
                                                    on: {
                                                      change: _vm.changeUntil
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.manuScriptInfo
                                                          .gradeCode,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.manuScriptInfo,
                                                          "gradeCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "manuScriptInfo.gradeCode"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.gradeLists,
                                                    function(item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.code
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.getUntil(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "阶段" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      placeholder: "请选择"
                                                    },
                                                    on: {
                                                      change: _vm.changeUntil
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.manuScriptInfo
                                                          .volumeCode,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.manuScriptInfo,
                                                          "volumeCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "manuScriptInfo.volumeCode"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.syncVolumeLists,
                                                    function(item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.code
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.getUntil(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            this.manuscriptTypes === "同步"
                                              ? _c(
                                                  "el-form-item",
                                                  { attrs: { label: "单元" } },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%"
                                                        },
                                                        attrs: {
                                                          placeholder: "请选择"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.getUntil(
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.manuScriptInfo
                                                              .unitCode,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.manuScriptInfo,
                                                              "unitCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "manuScriptInfo.unitCode"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.untilList,
                                                        function(item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.unitName,
                                                                value:
                                                                  item.unitCode
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticStyle: {
                                                "border-bottom":
                                                  "1px solid #d3d5d9"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "星级" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "block" },
                                                  [
                                                    _c("el-rate", {
                                                      staticStyle: {
                                                        "margin-top": "8px"
                                                      },
                                                      attrs: {
                                                        colors: _vm.colors
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.manuScriptInfo
                                                            .stars,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.manuScriptInfo,
                                                            "stars",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "manuScriptInfo.stars"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "奖项" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "block" },
                                                  [
                                                    _c("el-cascader", {
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择任意奖项",
                                                        options: _vm.prizeLists,
                                                        props: _vm.defaultProp,
                                                        "collapse-tags": "",
                                                        clearable: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.manuScriptInfo
                                                            .awardCode,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.manuScriptInfo,
                                                            "awardCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "manuScriptInfo.awardCode"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "标签" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "95%"
                                                        },
                                                        attrs: {
                                                          placeholder: "请选择",
                                                          multiple: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.manuScriptInfo
                                                              .tagCodes,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.manuScriptInfo,
                                                              "tagCodes",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "manuScriptInfo.tagCodes"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.tagsList,
                                                        function(item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                props:
                                                                  _vm.tagProps,
                                                                label:
                                                                  item.tagName,
                                                                value:
                                                                  item.tagCode
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content: "标签管理",
                                                          placement: "top"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            on: {
                                                              click:
                                                                _vm.jumpToTags
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-s-tools"
                                                            })
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "投稿" } },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.manuScriptInfo
                                                        .createdAt
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: { label: "投稿记录", name: "record" }
                                  },
                                  [
                                    _vm.contribution.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.loading,
                                                expression: "loading"
                                              }
                                            ],
                                            staticClass: "recordsBox"
                                          },
                                          _vm._l(_vm.contribution, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "contributionRecord",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.checkPicture(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    _vm._s(item.manuscriptName)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      color: "#aaaaaa",
                                                      display: "flex"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "typeName"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              item.typeName
                                                            ) +
                                                            "\n                            "
                                                        ),
                                                        _c("el-divider", {
                                                          attrs: {
                                                            direction:
                                                              "vertical"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              item.gradeName
                                                            ) +
                                                            "/" +
                                                            _vm._s(
                                                              item.volumeName
                                                            ) +
                                                            "\n                          "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                item.manuscriptPictureList
                                                  .length
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "imgBox" },
                                                      [
                                                        _vm._l(
                                                          item.manuscriptPictureList,
                                                          function(
                                                            img,
                                                            imgIndex
                                                          ) {
                                                            return _c(
                                                              "el-image",
                                                              {
                                                                key: imgIndex,
                                                                attrs: {
                                                                  fit: "cover",
                                                                  src: _vm.getImgUrl(
                                                                    img.imageUrl
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        item
                                                          .manuscriptPictureList
                                                          .length > 3
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "moreImg"
                                                              },
                                                              [_vm._v("3+")]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !item.manuscriptPictureList
                                                  .length
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "noRecords"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        暂无稿件图片\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.status === 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "20px",
                                                          color: "#409EFF",
                                                          "font-size": "15px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.createdAt
                                                            ) +
                                                            " 审核中\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.status === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "20px",
                                                          color: "#67C23A",
                                                          "font-size": "15px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.updatedAt
                                                            ) +
                                                            " 已通过\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.status === -1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "20px",
                                                          color: "#F56C6C",
                                                          "font-size": "15px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.updatedAt
                                                            ) +
                                                            " 不合格\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center"
                                            }
                                          },
                                          [_vm._v("暂无数据")]
                                        )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("el-button", { on: { click: _vm.controlDrawer } }, [
                _vm._v("快捷点评")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { margin: "0 10px" },
                attrs: {
                  placeholder: "点评下这篇稿件吧",
                  maxlength: "191",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.manuScriptInfo.unqualifiedReason,
                  callback: function($$v) {
                    _vm.$set(_vm.manuScriptInfo, "unqualifiedReason", $$v)
                  },
                  expression: "manuScriptInfo.unqualifiedReason"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.submitBtn, type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.qualified(-1)
                    }
                  }
                },
                [_vm._v("不合格")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.submitBtn, type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.qualified(1)
                    }
                  }
                },
                [_vm._v("合格")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }