var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input-number", {
        attrs: {
          disabled: _vm.isCanInput,
          min: 0,
          "controls-position": "right",
          precision: _vm.precision
        },
        model: {
          value: _vm.valueProxy,
          callback: function($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy"
        }
      }),
      _vm._v(" "),
      _c(
        "el-checkbox",
        {
          staticClass: "check-unlimited",
          attrs: { disabled: _vm.disabledEdit },
          model: {
            value: _vm.checkProxy,
            callback: function($$v) {
              _vm.checkProxy = $$v
            },
            expression: "checkProxy"
          }
        },
        [_vm._v(_vm._s(_vm.checkLabel))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }