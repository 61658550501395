var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-lz-content upload-video",
      staticStyle: { width: "20px" }
    },
    [
      _c("div", { staticClass: "display-flex" }, [
        _c(
          "div",
          { staticClass: "display-flex" },
          [
            !_vm.value
              ? _c("input", {
                  ref: "uploadImg",
                  staticClass: "file-upload",
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: {
                    id: _vm.type,
                    multiple: _vm.multipleSelect,
                    type: "file",
                    accept: "image/*"
                  },
                  on: { change: _vm.Upload }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("svg-icon", {
              staticStyle: { "font-size": "20px" },
              attrs: { "icon-class": "image" }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }