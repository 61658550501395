var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticClass: ".info_row_margin",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            border: "",
            "header-cell-style": { background: "#F6F7FB", color: "#000000" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "incomeType", label: "获得积分用户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "userCover_box" },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "userCover_box",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    scope.row.pointUserCover,
                                    _vm.smallthumbnailStyle
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "userCover_box",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "7px" } }, [
                          _vm._v(_vm._s(scope.row.pointUserNickName))
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "partnerName", label: "用户类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.getPointUserType(scope.row.pointUserType)
                          ) +
                          "\n        "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "incomeFormula", label: "积分类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.getPointType(scope.row.pointType)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "明细", prop: "remarks" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "积分", prop: "points" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }