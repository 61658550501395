"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'commonDropdown',
  props: {
    dropdownData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  data: function data() {
    return {
      dropdownTitle: ''
    };
  },
  created: function created() {
    this.dropdownTitle = "".concat(this.dropdownData[0]);
  },
  methods: {
    batchHandle: function batchHandle() {
      this.$emit('batchHandle', this.dropdownTitle);
    },
    handleCommand: function handleCommand(item) {
      this.dropdownTitle = "".concat(item);
    }
  }
};
exports.default = _default2;