"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _InputCloseable = _interopRequireDefault(require("./InputCloseable"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Group_Info_SpecificationValueCell',
  components: {
    InputCloseable: _InputCloseable.default
  },
  props: {
    index: {
      type: Number,
      default: undefined
    },
    data: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    } // 组件展示类型

  },
  methods: {
    change: function change(value) {
      this.$emit('change', this.index, value);
    },
    close: function close() {
      this.$emit('close', this.index);
    }
  }
};
exports.default = _default;