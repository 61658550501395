var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "batch-copy-wrapper" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "copy-dialog-bg",
          attrs: {
            title: "批量复制作品",
            width: "1000px",
            "close-on-click-modal": false,
            visible: _vm.showBatchCopyProxy
          },
          on: {
            "update:visible": function($event) {
              _vm.showBatchCopyProxy = $event
            },
            open: _vm.clearForm
          }
        },
        [
          _c("div", { staticClass: "resource" }, [
            _c("div", { staticClass: "common-nav" }, [_vm._v("作品")]),
            _vm._v(" "),
            _vm.copyArray.length > 0
              ? _c(
                  "div",
                  { staticClass: "resource-padding" },
                  _vm._l(_vm.copyArray, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "resource-list" },
                      [
                        _c("span", { staticClass: "sequence" }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "resource-title",
                          attrs: {
                            maxlength: 45,
                            placeholder: "请填写名称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: item.title,
                            callback: function($$v) {
                              _vm.$set(item, "title", $$v)
                            },
                            expression: "item.title"
                          }
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-delete delete",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.delCopyItem(item.resourceOpenId)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "copy-list-empty" }, [
                  _vm._v("\n        暂无数据\n      ")
                ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "attribute" },
            [
              _c("div", { staticClass: "common-nav" }, [_vm._v("属性")]),
              _vm._v(" "),
              _c("resource-attribute", {
                ref: "attributes",
                attrs: {
                  typename: _vm.typename,
                  "merchant-id": _vm.merchantId,
                  visible: _vm.showBatchCopyProxy
                },
                model: {
                  value: _vm.attributeForm,
                  callback: function($$v) {
                    _vm.attributeForm = $$v
                  },
                  expression: "attributeForm"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelCopy } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.copyArray.length <= 0,
                    loading: _vm.loading
                  },
                  on: { click: _vm.finishCopy }
                },
                [_vm._v("复制")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "copy-progress-dialog",
          attrs: {
            title: "复制作品",
            width: "400px",
            "close-on-click-modal": false,
            visible: _vm.showCopyProgress
          },
          on: {
            "update:visible": function($event) {
              _vm.showCopyProgress = $event
            }
          }
        },
        [
          _vm.auditStatus !== "success"
            ? _c("span", [
                _vm._v("正在复制" + _vm._s(_vm.copyTitle) + "，请稍候…")
              ])
            : _c("span", [_vm._v("复制完成，请在草稿箱里面查看")]),
          _vm._v(" "),
          _c("el-progress", {
            staticClass: "progress",
            attrs: {
              percentage: _vm.copyPercent,
              color: _vm.processColor,
              format: _vm.format
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress-footer-bg" },
            [
              _c("span", [
                _vm._v(
                  _vm._s(this.hasCopyCount) +
                    "/" +
                    _vm._s(this.copyArray.length)
                )
              ]),
              _vm._v(" "),
              _vm.auditStatus !== "success"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        loading: _vm.loading,
                        type: "primary"
                      }
                    },
                    [_vm._v("复制中...")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "success",
                        icon: "el-icon-success"
                      },
                      on: { click: _vm.cancelCopyProgress }
                    },
                    [_vm._v("完成")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }