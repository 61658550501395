"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DownLoadAnswer',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    valIndex: {
      type: Number,
      default: undefined
    },
    typeCode: {
      type: Number,
      default: undefined
    },
    questionIndex: {
      type: Number,
      default: undefined
    }
  }
};
exports.default = _default2;