var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量设置分类",
        visible: _vm.valueProxy,
        width: "30%",
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择分类" } },
                [
                  _c("product-grouping-selector", {
                    staticClass: "common-select-width",
                    attrs: {
                      "regimentation-ids": _vm.groupIds,
                      "second-regimentation-ids": _vm.secondGroupIds,
                      multiple: true,
                      "middle-open-id": _vm.middleOpenId,
                      "shop-open-id": _vm.shopOpenId,
                      "use-default-options": _vm.useDefaultOptions,
                      "default-options": _vm.defaultOptions
                    },
                    on: {
                      "update:regimentationIds": function($event) {
                        _vm.groupIds = $event
                      },
                      "update:regimentation-ids": function($event) {
                        _vm.groupIds = $event
                      },
                      "update:secondRegimentationIds": function($event) {
                        _vm.secondGroupIds = $event
                      },
                      "update:second-regimentation-ids": function($event) {
                        _vm.secondGroupIds = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.valueProxy = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }