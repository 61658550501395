var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-content",
    { attrs: { label: "商品属性" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "类目", prop: "secondRegimentationIds" } },
        [
          _c("default-selector", {
            staticClass: "common-select-width",
            attrs: {
              "regimentation-ids": _vm.valueProxy.regimentationIds,
              "second-regimentation-ids": _vm.valueProxy.secondRegimentationIds,
              "is-disabled": _vm.disabledEdit,
              multiple: true
            },
            on: {
              "update:regimentationIds": function($event) {
                return _vm.$set(_vm.valueProxy, "regimentationIds", $event)
              },
              "update:regimentation-ids": function($event) {
                return _vm.$set(_vm.valueProxy, "regimentationIds", $event)
              },
              "update:secondRegimentationIds": function($event) {
                return _vm.$set(
                  _vm.valueProxy,
                  "secondRegimentationIds",
                  $event
                )
              },
              "update:second-regimentation-ids": function($event) {
                return _vm.$set(
                  _vm.valueProxy,
                  "secondRegimentationIds",
                  $event
                )
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showBarCode
        ? _c(
            "el-form-item",
            { attrs: { label: "条码", prop: "barCode" } },
            [
              _c("el-input", {
                staticClass: "txt-width850",
                attrs: {
                  disabled: _vm.disabledEdit,
                  placeholder: "请输入条码",
                  maxlength: "50",
                  "show-word-limit": "",
                  clearable: ""
                },
                model: {
                  value: _vm.valueProxy.barCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "barCode", $$v)
                  },
                  expression: "valueProxy.barCode"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showBrandOpenId
        ? _c(
            "el-form-item",
            { attrs: { label: "品牌", prop: "brandOpenId" } },
            [
              _c("sync-info", {
                staticClass: "my-form-item",
                attrs: {
                  "key-prefix": "brandOpenId",
                  disabled: _vm.disabledEdit,
                  size: "small",
                  placeholder: "请选择品牌方",
                  "data-source": _vm.getBrandList,
                  "label-name": "title",
                  "value-name": "brandOpenId",
                  filterable: true,
                  remote: true,
                  "remote-api": _vm.getBrandListSearch
                },
                model: {
                  value: _vm.valueProxy.brandOpenId,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "brandOpenId", $$v)
                  },
                  expression: "valueProxy.brandOpenId"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSeriesCode
        ? _c(
            "el-form-item",
            { attrs: { label: "商品系列", prop: "seriesCode" } },
            [
              _c("sync-info", {
                ref: "subjectList",
                staticClass: "my-form-item",
                attrs: {
                  "key-prefix": "seriesCode",
                  disabled: _vm.disabledEdit,
                  size: "small",
                  placeholder: "请选择商品系列",
                  "data-source": _vm.getSeriesList,
                  "label-name": "title",
                  "value-name": "seriesOpenId",
                  filterable: true,
                  remote: true,
                  "remote-api": _vm.getSeriesListSearch,
                  query: _vm.getSeriesListSearchQuery,
                  "label-display": _vm.seriesLabelDisplay
                },
                model: {
                  value: _vm.valueProxy.seriesCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "seriesCode", $$v)
                  },
                  expression: "valueProxy.seriesCode"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "同步", prop: "resourceSyncId" } },
        [
          _c("sync-info-group", {
            on: { "single-locked-need-update": _vm.onSingleLockedNeedUpdate },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "版次", prop: "periodCode" } },
        [
          _c("sync-info", {
            staticClass: "my-form-item",
            attrs: {
              disabled: _vm.disabledEdit,
              size: "small",
              placeholder: "请选择版次",
              "data-source": _vm.getSyncPeriod
            },
            model: {
              value: _vm.valueProxy.periodCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "periodCode", $$v)
              },
              expression: "valueProxy.periodCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "property-box",
        [
          _c(
            "el-form-item",
            { attrs: { label: "地区", prop: "areaCode" } },
            [
              _c("sync-info", {
                staticClass: "my-form-item",
                attrs: {
                  disabled: _vm.disabledEdit,
                  size: "small",
                  placeholder: "请选择地区",
                  "data-source": _vm.getAllBookArea,
                  multiple: ""
                },
                on: { change: _vm.onSingleLockedNeedUpdate },
                model: {
                  value: _vm.valueProxy.areaCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "areaCode", $$v)
                  },
                  expression: "valueProxy.areaCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "标签", prop: "tags" } },
            [
              _c("tag-select", {
                staticClass: "my-form-item",
                attrs: {
                  "is-disabled": _vm.disabledEdit,
                  size: "small",
                  placeholder: "请选择标签",
                  multiple: true
                },
                model: {
                  value: _vm.valueProxy.tags,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "tags", $$v)
                  },
                  expression: "valueProxy.tags"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }