var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "div",
        { staticClass: "header-bg" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.addProvinceBtn("country")
                }
              }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "province-table-body" },
        [
          _vm.showTable
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "provincesList",
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "100%",
                    "row-key": "id",
                    lazy: "",
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren"
                    },
                    load: _vm.load,
                    data: _vm.tableData
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "", prop: "", width: 60 }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序", prop: "sequence", "min-width": "5" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "名称", "min-width": "15" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "code", label: "编号", "min-width": "10" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cityCode",
                      label: "区号",
                      "min-width": "35"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fullname",
                      label: "全称",
                      "min-width": "15"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "37", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                [
                                  scope.row.level !== "district"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addChildBtn(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("添加")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editBtn(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "removeBtn",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeBtn(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2554411514
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getAreaList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "480px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "80px",
                      model: _vm.areaForm,
                      rules: _vm.rules
                    }
                  },
                  [
                    _vm.dialogTitltText === "编辑省市县"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "open id" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.areaForm.id,
                                callback: function($$v) {
                                  _vm.$set(_vm.areaForm, "id", $$v)
                                },
                                expression: "areaForm.id"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "编号", prop: "code" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 6,
                            placeholder: "请填写编号",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.areaForm.code,
                            callback: function($$v) {
                              _vm.$set(_vm.areaForm, "code", $$v)
                            },
                            expression: "areaForm.code"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属", prop: "parentCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "form-box",
                            attrs: { placeholder: "请选择归属" },
                            model: {
                              value: _vm.areaForm.parentCode,
                              callback: function($$v) {
                                _vm.$set(_vm.areaForm, "parentCode", $$v)
                              },
                              expression: "areaForm.parentCode"
                            }
                          },
                          _vm._l(_vm.parentCodeList, function(item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 90,
                            placeholder: "请填写名称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.areaForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.areaForm, "name", $$v)
                            },
                            expression: "areaForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "区号", prop: "cityCode" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 6,
                            placeholder: "请填写城市区号",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.areaForm.cityCode,
                            callback: function($$v) {
                              _vm.$set(_vm.areaForm, "cityCode", $$v)
                            },
                            expression: "areaForm.cityCode"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "全称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 90,
                            placeholder: "请填写省市县全称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.areaForm.fullname,
                            callback: function($$v) {
                              _vm.$set(_vm.areaForm, "fullname", $$v)
                            },
                            expression: "areaForm.fullname"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "排序" } }, [
                      _c(
                        "div",
                        { staticClass: "sequence-box" },
                        [
                          _c("el-input-number", {
                            staticClass: "select-sequence",
                            staticStyle: { width: "100px" },
                            attrs: {
                              precision: 0,
                              step: 1,
                              min: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.areaForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.areaForm, "sequence", $$v)
                              },
                              expression: "areaForm.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.editDialogVisible = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitSyncLoading
                                  },
                                  on: { click: _vm.submitArea }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }