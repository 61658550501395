var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-recommend-wrapper",
          attrs: {
            visible: _vm.recommendDialog,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "设置推荐权重"
          },
          on: {
            "update:visible": function($event) {
              _vm.recommendDialog = $event
            },
            open: _vm.initData
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "recommendForm",
              attrs: {
                model: _vm.recommendForm,
                rules: _vm.recommendRules,
                "label-width": "60",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推荐权重", prop: "recommendSequence" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, max: 100, "controls-position": "right" },
                    model: {
                      value: _vm.recommendForm.recommendSequence,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.recommendForm,
                          "recommendSequence",
                          _vm._n($$v)
                        )
                      },
                      expression: "recommendForm.recommendSequence"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.recommendDialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.setRecommendFun()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }