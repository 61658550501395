var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "comment-case", attrs: { type: "border-card" } },
        [
          _c("div", { staticClass: "comment-btn" }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: _vm.size },
                    on: { change: _vm.handleBtnCheck },
                    model: {
                      value: _vm.query.status,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "status", $$v)
                      },
                      expression: "query.status"
                    }
                  },
                  _vm._l(_vm.button_options, function(item) {
                    return _c(
                      "el-radio-button",
                      {
                        key: item.value,
                        attrs: { value: item.value, label: item.value }
                      },
                      [_vm._v(_vm._s(item.label) + "\n          ")]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 10px" },
                    attrs: { size: _vm.size },
                    on: { click: _vm.openBlackListDialogHandle }
                  },
                  [_vm._v("黑名单")]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      visible: _vm.openBlackListDialog,
                      "close-on-click-modal": false,
                      "close-on-press-escape": false,
                      width: "950px",
                      "append-to-body": ""
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.openBlackListDialog = $event
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#303133",
                                  "font-size": "18px",
                                  "margin-right": "20px"
                                }
                              },
                              [_vm._v("黑名单")]
                            ),
                            _vm._v(" "),
                            _c("searchUserByInput", {
                              on: { receiveuserid: _vm.receiveuseridBlack }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingBlackList,
                            expression: "loadingBlackList"
                          }
                        ],
                        ref: "tablewrap",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          "tooltip-effect": "dark",
                          height: "500px"
                        },
                        on: { "selection-change": _vm.handleSelectionChange }
                      },
                      [
                        _c("el-table-column", { attrs: { type: "selection" } }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "用户", width: "400" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "24px",
                                          height: "24px",
                                          "border-radius": "50%"
                                        },
                                        attrs: {
                                          fit: "cover",
                                          src: _vm.getImgUrl(
                                            scope.row.userInfo.cover,
                                            _vm.thumbnailStyle
                                          ),
                                          alt: ""
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "userdisplayName",
                                          staticStyle: {
                                            color: "#606266",
                                            margin: "0 5px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isNull(
                                                scope.row.userInfo.nickName
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#909399",
                                            margin: "0 5px",
                                            "font-size": "12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "用户ID：" +
                                              _vm._s(
                                                _vm.isNull(
                                                  scope.row.userInfo.openId
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "原因", width: "350" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.isNull(scope.row.reason)) +
                                      "\n              "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "right",
                            fixed: "right",
                            width: "auto"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeBlacklist(
                                            scope.row.userInfo.openId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("恢复")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "padding-top": "20px"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "13px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "pagination-check",
                              attrs: {
                                disabled: _vm.tableData.length === 0,
                                indeterminate: _vm.isIndeterminateblack
                              },
                              on: { change: _vm.handleSelectedAll },
                              model: {
                                value: _vm.selectedAll,
                                callback: function($$v) {
                                  _vm.selectedAll = $$v
                                },
                                expression: "selectedAll"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  plain: "",
                                  loading: _vm.loadingbatchRecoveryBtn
                                },
                                on: { click: _vm.batchRecoveryUserBlack }
                              },
                              [_vm._v("恢复")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-pagination", {
                              attrs: {
                                background: "",
                                "current-page": _vm.queryBlack.page,
                                "page-sizes": [10, 20, 30, 50],
                                "page-size": _vm.queryBlack.size,
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.totalBlack
                              },
                              on: {
                                "size-change": _vm.handleSizeChangeBlack,
                                "current-change": _vm.handleCurrentChangeBlack
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _vm.showCommentBol
                  ? _c("searchUserByInput", {
                      attrs: { size: _vm.size },
                      on: { receiveuserid: _vm.receiveuserid }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "common-marign",
                  staticStyle: { width: "200px", "margin-left": "auto" },
                  attrs: { size: _vm.size, placeholder: "搜索关键字" },
                  on: { change: _vm.clearInput },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleBtnSearch($event)
                    }
                  },
                  model: {
                    value: _vm.query.comContent,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "comContent", $$v)
                    },
                    expression: "query.comContent"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "common-marign",
                    attrs: { type: "primary", size: _vm.size },
                    on: { click: _vm.handleBtnSearch }
                  },
                  [_vm._v("搜索")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.commentItemList.length > 0
            ? _c("div", { staticClass: "operation" }, [
                _c(
                  "div",
                  { staticClass: "operation_C" },
                  [
                    _c("el-checkbox", {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "commentFont" }, [_vm._v("评论")])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(0)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.commentItemList.length === 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    height: "512px",
                    "overflow-y": "auto",
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    padding: "10px 0px"
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.nodataStatusText) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.commentItemList.length > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    height: "470px",
                    "overflow-y": "auto",
                    "word-break": "break-all"
                  },
                  attrs: { id: "commentItemList" }
                },
                _vm._l(_vm.commentItemList, function(item, index) {
                  return _c("div", { key: index, staticClass: "commentItem" }, [
                    _c(
                      "div",
                      { staticClass: "commentItemIntroduce" },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            },
                            on: { change: _vm.handleCheckedCitiesChange },
                            model: {
                              value: _vm.checkedCities,
                              callback: function($$v) {
                                _vm.checkedCities = $$v
                              },
                              expression: "checkedCities"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c("el-checkbox", { attrs: { label: item } }, [
                                  _vm._v(_vm._s(item.id))
                                ]),
                                _vm._v(" "),
                                item.user
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { display: "inline-flex" }
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top-start",
                                              trigger: "hover"
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                    "margin-bottom": "10px"
                                                  }
                                                },
                                                [
                                                  item.user.cover
                                                    ? _c("el-image", {
                                                        staticStyle: {
                                                          "border-radius":
                                                            "50%",
                                                          "margin-right":
                                                            "10px",
                                                          width: "36px",
                                                          height: "36px"
                                                        },
                                                        attrs: {
                                                          fit: "cover",
                                                          src: _vm.getImgUrl(
                                                            item.user.cover,
                                                            _vm.thumbnailStyle
                                                          ),
                                                          alt: ""
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "center"
                                                        }
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.isNull(
                                                                item.user
                                                                  .nickName
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        item.user.vipUser
                                                          ? _c("img", {
                                                              staticStyle: {
                                                                width: "14px",
                                                                height: "18px",
                                                                "object-fit":
                                                                  "cover",
                                                                margin: "0 5px"
                                                              },
                                                              attrs: {
                                                                src: require("@/assets/images/VIP1.png"),
                                                                alt: ""
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          color: "#909399",
                                                          "font-size": "12px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "用户Id: " +
                                                            _vm._s(
                                                              _vm.isNull(
                                                                item.user.openId
                                                              )
                                                            )
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              margin: "0 5px"
                                                            }
                                                          },
                                                          [_vm._v("|")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "letter-spacing":
                                                                "2px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                           " +
                                                                _vm._s(
                                                                  _vm.isNull(
                                                                    item.user
                                                                      .sectionName
                                                                  )
                                                                ) +
                                                                "/" +
                                                                _vm._s(
                                                                  _vm.isNull(
                                                                    item.user
                                                                      .gradeName
                                                                  )
                                                                ) +
                                                                "/" +
                                                                _vm._s(
                                                                  _vm.isNull(
                                                                    item.user
                                                                      .volumeName
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    "margin-bottom": "5px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "手机号：" +
                                                      _vm._s(
                                                        _vm.isNull(
                                                          item.user.phone
                                                        )
                                                      )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              item.user.wxChatUnionId
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "font-size": "12px",
                                                        "align-items": "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "微信帐号：\n                       "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          item.user.cover
                                                            ? _c("el-image", {
                                                                staticStyle: {
                                                                  "border-radius":
                                                                    "50%",
                                                                  width: "20px",
                                                                  height: "20px"
                                                                },
                                                                attrs: {
                                                                  fit: "cover",
                                                                  src: _vm.getImgUrl(
                                                                    item.user
                                                                      .cover,
                                                                    _vm.thumbnailStyle
                                                                  )
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#606266",
                                                                margin: "0 5px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.isNull(
                                                                    item.user
                                                                      .displayName
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#909399",
                                                                "margin-right":
                                                                  "5px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.user
                                                                    .wxChatUnionId
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "#42B983"
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-success"
                                                              }),
                                                              _vm._v(" 已绑定")
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    "border-top":
                                                      "1px solid #EBEEF5",
                                                    "margin-top": "10px",
                                                    "padding-top": "10px"
                                                  }
                                                },
                                                [
                                                  _vm.showCommentBol
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            padding: "0"
                                                          },
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.jumpPersonalDetail(
                                                                item.user.openId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("查看个人详情")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !item.reason
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            color: "#F56C6C",
                                                            padding: "0"
                                                          },
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addBlacklist(
                                                                item.user.openId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("加入黑名单")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.reason
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            padding: "0"
                                                          },
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeBlacklist(
                                                                item.user.openId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("从黑名单移除")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "commentItemAuthor",
                                                attrs: { slot: "reference" },
                                                slot: "reference"
                                              },
                                              [
                                                item.user.cover
                                                  ? _c("el-image", {
                                                      staticStyle: {
                                                        width: "24px",
                                                        height: "24px",
                                                        "border-radius": "50%"
                                                      },
                                                      attrs: {
                                                        fit: "cover",
                                                        src: _vm.getImgUrl(
                                                          item.user.cover,
                                                          _vm.thumbnailStyle
                                                        )
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#409EFF",
                                                      padding: "0 5px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.isNull(
                                                          item.user.nickName
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                item.user.vipUser
                                                  ? _c("img", {
                                                      staticStyle: {
                                                        width: "14px",
                                                        height: "18px",
                                                        "object-fit": "cover",
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        src: require("@/assets/images/VIP1.png"),
                                                        alt: ""
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-flex",
                                              "align-items": "center"
                                            }
                                          },
                                          [
                                            item.reason
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content: item.reason,
                                                          placement: "top"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "content"
                                                            },
                                                            slot: "content"
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.blackoutTimeAt
                                                                ) +
                                                                  " 已被加入黑名单"
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("div", [
                                                              _vm._v(
                                                                "原因: " +
                                                                  _vm._s(
                                                                    item.reason
                                                                  )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "iconfont icon-jinzhi",
                                                          staticStyle: {
                                                            color: "#F56C6C",
                                                            "margin-right":
                                                              "5px"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "smallfont" },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#606266" } },
                                      [_vm._v("评论")]
                                    ),
                                    _vm._v(" "),
                                    item.resourceType
                                      ? _c(
                                          "span",
                                          { staticClass: "commonIconsty" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.resourcetypetoStr(
                                                    item.resourceType
                                                  )
                                                ) +
                                                " : "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(_vm.showVal(item.url))
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.priviewCommon(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.showVal(item.title)
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("resource-share", {
                                              attrs: {
                                                visible: _vm.isShowSharePreview,
                                                "share-obj": _vm.shareResource
                                              },
                                              on: {
                                                "update:visible": function(
                                                  $event
                                                ) {
                                                  _vm.isShowSharePreview = $event
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            item.targetCommentId === null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "smallfont",
                                    staticStyle: { margin: "0px 10px" }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "iconfont icon-ai45"
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(item.likes))]),
                                    _vm._v("个赞\n            ")
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "smallfont" }, [
                              _c("span", {
                                staticClass: "iconfont icon-pinglun",
                                staticStyle: { "margin-right": "5px" }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("评论时间："),
                                _c("span", [_vm._v(_vm._s(item.createdAt))])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "commentItemContent" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            color: "#303133",
                            "letter-spacing": "1px",
                            display: "flex",
                            "flex-direction": "column",
                            "line-height": "30px"
                          }
                        },
                        [
                          item.comContent
                            ? _c("div", [
                                _c("div", {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap"
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(item.comContent)
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.audioUrl
                            ? _c(
                                "div",
                                {
                                  staticClass: "bubble",
                                  on: {
                                    click: function($event) {
                                      return _vm.playAudio(item.uniqueindex)
                                    }
                                  }
                                },
                                [
                                  _c("audio", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    attrs: {
                                      src: _vm.getAudioUrl(item.audioUrl),
                                      id: item.uniqueindex
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "wifi-symbol" }, [
                                    _c("div", {
                                      staticClass: "wifi-circle first"
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "wifi-circle second",
                                      class:
                                        _vm.isPlay &&
                                        _vm.currentuniqueindex ===
                                          item.uniqueindex
                                          ? "wifi-circle second secondAnimation"
                                          : "wifi-circle second"
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "wifi-circle third",
                                      class:
                                        _vm.isPlay &&
                                        _vm.currentuniqueindex ===
                                          item.uniqueindex
                                          ? "wifi-circle third thirdAnimation"
                                          : "wifi-circle third"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "audioTime" }, [
                                    _vm._v(_vm._s(item.audioDuration) + "' ")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.commentImages && item.commentImages.length
                            ? _c(
                                "div",
                                _vm._l(item.commentImages, function(
                                  itemImg,
                                  indexC
                                ) {
                                  return _c("el-image", {
                                    key: indexC,
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      "margin-right": "10px"
                                    },
                                    attrs: {
                                      fit: "cover",
                                      src: _vm.getImgUrl(
                                        itemImg.imageUrl,
                                        _vm.thumbnailStyle
                                      ),
                                      "preview-src-list": _vm.getImg(
                                        itemImg.imageUrl,
                                        _vm.normalStyle
                                      )
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    item.count > 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "transition",
                              { attrs: { name: "slide-fade" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.packUp,
                                        expression: "item.packUp"
                                      }
                                    ],
                                    staticClass: "reply",
                                    attrs: { name: "slide-fade" }
                                  },
                                  [
                                    _vm.allReplayList.get(item.id) != undefined
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            _vm.allReplayList.get(item.id),
                                            function(itemc, indexA) {
                                              return _c(
                                                "div",
                                                {
                                                  key: indexA,
                                                  staticStyle: {
                                                    padding: "5px 0px",
                                                    display: "flex",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#409EFF"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              itemc.userDisplayName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" 回复 "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#409EFF"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              itemc.targetUserDisplayName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" ：")
                                                  ]),
                                                  _vm._v(" "),
                                                  itemc.comContent
                                                    ? _c("div", [
                                                        _c("div", {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "flex-wrap": "wrap"
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              itemc.comContent
                                                            )
                                                          }
                                                        })
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  itemc.commentImages &&
                                                  itemc.commentImages.length
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          itemc.commentImages,
                                                          function(
                                                            itemImg,
                                                            indexC
                                                          ) {
                                                            return _c(
                                                              "el-image",
                                                              {
                                                                key: indexC,
                                                                staticStyle: {
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px",
                                                                  "margin-right":
                                                                    "10px"
                                                                },
                                                                attrs: {
                                                                  fit: "cover",
                                                                  src: _vm.getImgUrl(
                                                                    itemImg.imageUrl,
                                                                    _vm.thumbnailStyle
                                                                  ),
                                                                  "preview-src-list": _vm.getImg(
                                                                    itemImg.imageUrl,
                                                                    _vm.normalStyle
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  itemc.audioUrl
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "bubble",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.playAudio(
                                                                itemc.uniqueindex
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("audio", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: false,
                                                                expression:
                                                                  "false"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "audio",
                                                            attrs: {
                                                              src: _vm.getAudioUrl(
                                                                itemc.audioUrl
                                                              ),
                                                              id:
                                                                itemc.uniqueindex
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "wifi-symbol"
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "wifi-circle first"
                                                              }),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "wifi-circle second",
                                                                class:
                                                                  _vm.isPlay &&
                                                                  _vm.currentuniqueindex ===
                                                                    itemc.uniqueindex
                                                                    ? "wifi-circle second secondAnimation"
                                                                    : "wifi-circle second"
                                                              }),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "wifi-circle third",
                                                                class:
                                                                  _vm.isPlay &&
                                                                  _vm.currentuniqueindex ===
                                                                    itemc.uniqueindex
                                                                    ? "wifi-circle third thirdAnimation"
                                                                    : "wifi-circle third"
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "audioTime"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  itemc.audioDuration
                                                                ) + "' "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "PackupAn" }, [
                      item.count > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "smallfont",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                cursor: "pointer"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading2 &&
                                        item.id === _vm.currentid,
                                      expression:
                                        "loading2&&item.id===currentid"
                                    }
                                  ],
                                  on: {
                                    click: function($event) {
                                      return _vm.changePackUp(
                                        item,
                                        index,
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    item.packUp
                                      ? _c("i", {
                                          staticClass: "el-icon-minus"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !item.packUp
                                      ? _c("i", { staticClass: "el-icon-plus" })
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.packUp ? " 收起" : "展开") +
                                        "用户的回复，共"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v(_vm._s(item.count))]),
                                  _vm._v("个\n            ")
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("div", [
                        item.targetCommentId === null
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value:
                                      _vm.loadingPopover &&
                                      _vm.currentId === item.id,
                                    expression:
                                      "loadingPopover&&currentId===item.id"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top",
                                      width: "auto",
                                      trigger: "click"
                                    },
                                    on: {
                                      show: function($event) {
                                        return _vm.showPreviewBox(item, index)
                                      }
                                    },
                                    model: {
                                      value: item.isShowPreviewBox,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShowPreviewBox", $$v)
                                      },
                                      expression: "item.isShowPreviewBox"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "240px",
                                            "margin-right": "10px"
                                          },
                                          attrs: {
                                            placeholder:
                                              "回复后，用户们将直接看到哦~"
                                          },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.reply(item, index)
                                            }
                                          },
                                          model: {
                                            value: _vm.comContent,
                                            callback: function($$v) {
                                              _vm.comContent = $$v
                                            },
                                            expression: "comContent"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.reply(item, index)
                                              }
                                            }
                                          },
                                          [_vm._v("回复")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("回复")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [
                          item.targetCommentId === null && item.topedAt === null
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        loading:
                                          _vm.roofplacementloading &&
                                          item.id === _vm.currentid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.roofplacement(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("设为置顶")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.targetCommentId === null && item.topedAt
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        loading:
                                          _vm.roofplacementloading &&
                                          item.id === _vm.currentid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelroofplacement(
                                            item.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("取消置顶")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          item.aduitedAt === null
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        loading:
                                          _vm.showCommentloading &&
                                          item.id === _vm.currentid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showComment(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("设为显示")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.aduitedAt != null
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        loading:
                                          _vm.showCommentloading &&
                                          item.id === _vm.currentid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.hideComment(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("取消显示")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#EBEEF5" } }, [
                          _vm._v("|")
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          item.deletedAt === null
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#F56C6C" },
                                      attrs: {
                                        type: "text",
                                        loading:
                                          _vm.deleteRecoverloading &&
                                          item.id === _vm.currentid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteCommon(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.deletedAt
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#67C23A" },
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        loading:
                                          _vm.deleteRecoverloading &&
                                          item.id === _vm.currentid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.recoverCommon(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("恢复")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "commentBottom" }, [
            _c(
              "div",
              [
                _c("el-checkbox", {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                }),
                _vm._v(" "),
                _c("common-dropdown", {
                  attrs: {
                    dropdownData: _vm.dropdownData,
                    dropdowntitle: _vm.dropdowntitle,
                    size: "mini"
                  },
                  on: { batchHandle: _vm.batchHandle }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block t-block" },
              [
                _c("el-pagination", {
                  attrs: {
                    size: _vm.size,
                    background: "",
                    "current-page": _vm.query.page,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _vm.query.size,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "加入黑名单原因",
            visible: _vm.blackDialog,
            width: "30%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.blackDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dynamicValidateForm",
              attrs: { model: _vm.dynamicValidateForm }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "blackListReason",
                    rules: [
                      {
                        required: true,
                        message: "请描述加入黑名单原因",
                        trigger: "blur"
                      }
                    ]
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      prop: "blackListReason",
                      placeholder: "请描述加入黑名单原因"
                    },
                    model: {
                      value: _vm.dynamicValidateForm.blackListReason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.dynamicValidateForm,
                          "blackListReason",
                          $$v
                        )
                      },
                      expression: "dynamicValidateForm.blackListReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.blackDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.addBlacklistHandle("dynamicValidateForm")
                    }
                  }
                },
                [_vm._v("加入黑名单")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "commentFont" }, [_vm._v("操作")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }