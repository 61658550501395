var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          background: "#FFFFFF",
          padding: "10px 20px"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              "margin-right": "auto",
              display: "flex",
              "flex-direction": "row"
            }
          },
          [
            _c(
              "el-button",
              {
                staticClass: "common-marign",
                attrs: { type: "primary" },
                on: { click: _vm.editActivity }
              },
              [_vm._v("新建")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "10px",
                  display: "flex",
                  "flex-direction": "row"
                }
              },
              [
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.handleBtnCheck },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  },
                  _vm._l(_vm.button_options, function(item) {
                    return _c(
                      "el-radio-button",
                      {
                        key: item.label,
                        attrs: { value: item.value, label: item.value }
                      },
                      [_vm._v(_vm._s(item.label) + "\n            ")]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "margin-left": "20px",
              display: "flex",
              "flex-direction": "row"
            }
          },
          [
            _c("el-input", {
              staticClass: "common-marign",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "搜索活动名称" },
              model: {
                value: _vm.input,
                callback: function($$v) {
                  _vm.input = $$v
                },
                expression: "input"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "common-marign",
                staticStyle: { "margin-left": "2%" },
                attrs: { type: "primary" },
                on: { click: _vm.getAllList }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "table-border"
      },
      [
        JSON.stringify(_vm.tableData) !== "[]"
          ? _c(
              "div",
              { staticClass: "active-case" },
              _vm._l(_vm.tableData, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "active-block",
                    attrs: { id: item.id },
                    on: {
                      click: function($event) {
                        return _vm.routerTo(item.id)
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      staticClass: "img",
                      attrs: {
                        src: _vm.getImgUrl(item.cover, _vm.normalStyle),
                        alt: "",
                        fit: "cover"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "active-title" }, [
                      _vm._v(
                        _vm._s(
                          item.title === null || item.title === ""
                            ? "--"
                            : item.title
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "active-bottom" }, [
                      _c("span", { staticClass: "active-time" }, [
                        _vm._v(_vm._s(_vm.getDateDiff(item.startedAt)))
                      ]),
                      _vm._v(" "),
                      item.status === "已结束"
                        ? _c(
                            "span",
                            { staticClass: "active-status status-over" },
                            [_vm._v(_vm._s(item.status))]
                          )
                        : item.status === "进行中"
                        ? _c(
                            "span",
                            { staticClass: "active-status status-doing" },
                            [_vm._v(_vm._s(item.status))]
                          )
                        : item.status === "未开始"
                        ? _c(
                            "span",
                            { staticClass: "active-status status-begin" },
                            [_vm._v(_vm._s(item.status))]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              {
                staticStyle: {
                  height: "300px",
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                  "font-size": "20px",
                  "font-weight": "700",
                  color: "#606266"
                }
              },
              [_c("span", [_vm._v("暂无活动")])]
            )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-pagination", {
          staticStyle: { float: "right" },
          attrs: {
            background: "",
            "current-page": _vm.page,
            "page-sizes": [10, 20, 30, 50],
            "page-size": _vm.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.totalSize
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }