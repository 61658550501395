var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticStyle: { "max-width": "1490px", margin: "0 auto" },
      attrs: {
        visible: _vm.visible,
        width: "80%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "span",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "span",
              { staticStyle: { "margin-right": "20px", "font-size": "18px" } },
              [_vm._v("用户信息")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.propActiveName,
                callback: function($$v) {
                  _vm.propActiveName = $$v
                },
                expression: "propActiveName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "基本信息", name: "基本信息" } },
                [
                  _c(
                    "div",
                    { staticClass: "tabs_body" },
                    [
                      _c("store-user-base-info", {
                        attrs: {
                          userOpenId: _vm.userOpenId,
                          "store-open-id": this.partnerOpenId
                        },
                        on: {
                          "update:userOpenId": function($event) {
                            _vm.userOpenId = $event
                          },
                          "update:user-open-id": function($event) {
                            _vm.userOpenId = $event
                          },
                          "update:storeOpenId": function($event) {
                            return _vm.$set(this, "partnerOpenId", $event)
                          },
                          "update:store-open-id": function($event) {
                            return _vm.$set(this, "partnerOpenId", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "订单记录", name: "订单记录" } },
                [
                  _c("div", { staticClass: "tabs_body" }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            height: "568px"
                          }
                        },
                        [
                          _c("product-order-table", {
                            attrs: {
                              "order-list": _vm.orderList,
                              loading: _vm.orderLoading
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "display_flex align_items flex_end",
                          staticStyle: {
                            padding: "10px 0px",
                            "background-color": "#FFFFFF"
                          }
                        },
                        [
                          _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.orderQuery.page,
                              "page-sizes": [10, 20, 50, 100],
                              "page-size": _vm.orderQuery.size,
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.orderTotalElements
                            },
                            on: {
                              "size-change": _vm.orderHandleSizeChange,
                              "current-change": _vm.orderHandleCurrentChange
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "购物车", name: "购物车" } },
                [
                  _c(
                    "div",
                    { staticClass: "tabs_body" },
                    [
                      _c("store-user-cart-info", {
                        staticStyle: { padding: "10px" },
                        attrs: {
                          userOpenId: _vm.userOpenId,
                          "partner-open-id": _vm.partnerOpenId
                        },
                        on: {
                          "update:userOpenId": function($event) {
                            _vm.userOpenId = $event
                          },
                          "update:user-open-id": function($event) {
                            _vm.userOpenId = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "收藏记录", name: "收藏记录" } },
                [
                  _c(
                    "div",
                    { staticClass: "tabs_body" },
                    [
                      _c("store-user-collection-history", {
                        staticStyle: { padding: "10px" },
                        attrs: {
                          userOpenId: _vm.userOpenId,
                          "partner-open-id": _vm.partnerOpenId
                        },
                        on: {
                          "update:userOpenId": function($event) {
                            _vm.userOpenId = $event
                          },
                          "update:user-open-id": function($event) {
                            _vm.userOpenId = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "浏览记录", name: "浏览记录" } },
                [
                  _c(
                    "div",
                    { staticClass: "tabs_body" },
                    [
                      _c("store-user-browsing-history", {
                        staticStyle: { padding: "10px" },
                        attrs: {
                          userOpenId: _vm.userOpenId,
                          "partner-open-id": _vm.partnerOpenId
                        },
                        on: {
                          "update:userOpenId": function($event) {
                            _vm.userOpenId = $event
                          },
                          "update:user-open-id": function($event) {
                            _vm.userOpenId = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }