var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-lz-content upload-video" },
    [
      _c("div", { staticClass: "display-flex" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "file-upload-bg"
          },
          [
            _vm.value
              ? _c("div", { staticClass: "img-operation-bg" }, [
                  _c("div", { staticClass: "img-operation" }, [
                    _c("i", {
                      staticClass: "el-icon-zoom-in icon-preview",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onPreview()
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.disabledEdit
                      ? _c("i", {
                          staticClass: "el-icon-delete icon-del",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.disabledEdit ? "" : _vm.deletecover()
                            }
                          }
                        })
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-video-box display-flex",
                style:
                  "width:" +
                  _vm.coverarr[0] +
                  "px;height:" +
                  _vm.coverarr[1] +
                  "px"
              },
              [
                _c("input", {
                  ref: "myUploadpc",
                  staticClass: "file-upload",
                  attrs: {
                    id: "myUploadpc",
                    multiple: _vm.multipleSelect,
                    disabled: _vm.disabledEdit,
                    type: "file",
                    accept: "image/*"
                  },
                  on: { change: _vm.Upload }
                }),
                _vm._v(" "),
                _vm.value
                  ? _c("img", {
                      staticClass: "cover",
                      attrs: {
                        onerror: _vm.errorUserPhoto,
                        src:
                          _vm.value.substring(0, 4) === "http"
                            ? _vm.value
                            : _vm.getImageUrl(_vm.value)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.value
                  ? _c("div")
                  : _c(
                      "div",
                      {
                        staticClass: "upload-video-icon",
                        staticStyle: { "align-items": "center" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "el-icon-upload-title",
                            staticStyle: { "line-height": "1.2" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.texttips) +
                                "\n              "
                            ),
                            _vm.texttips === "PC横幅"
                              ? _c(
                                  "div",
                                  { staticStyle: { "padding-top": "15px" } },
                                  [_vm._v("1920*460px")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.texttips === "APP/H5横幅"
                              ? _c(
                                  "div",
                                  { staticStyle: { "padding-top": "15px" } },
                                  [_vm._v("750*280px")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.texttips === "PC广告图"
                              ? _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [_vm._v("1200x100px")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.texttips === "APP/H5广告图"
                              ? _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [_vm._v("750x114px")]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.coverisshow
        ? _c(
            "div",
            {
              staticClass: "video-duration-text",
              staticStyle: { "margin-top": "20px" }
            },
            [
              _c("i", { staticClass: "el-icon-info" }),
              _vm._v(" "),
              _c("span", { staticClass: "video-text" }, [
                _vm._v(_vm._s(_vm.covertext))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPreview && _vm.value
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrl(_vm.value)]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }