var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("brand-side-edit", {
    ref: "edit",
    attrs: { visible: _vm.visible, loading: _vm.loading },
    on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirm },
    model: {
      value: _vm.valueSync,
      callback: function($$v) {
        _vm.valueSync = $$v
      },
      expression: "valueSync"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }