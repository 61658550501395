"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _bus = _interopRequireDefault(require("@/utils/bus"));

var _questionPoints = _interopRequireDefault(require("../../../components/Question/testQuestion/questionPoints"));

var _tableList = _interopRequireDefault(require("../../../components/Question/testQuestion/tableList"));

var _testPaperTop = _interopRequireDefault(require("../../../components/Question/testPaperTop"));

var _newQuestions = _interopRequireDefault(require("../../../components/Question/testQuestion/newQuestions"));

var _treeList = _interopRequireDefault(require("../../../components/Question/treeList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'testQuestions',
  components: {
    testTop: _testPaperTop.default,
    testQuestionTable: _tableList.default,
    newQuestion: _newQuestions.default,
    treeList: _treeList.default,
    questionPoints: _questionPoints.default
  },
  data: function data() {
    return {
      clickNodeOpenId: '',
      partnerOpenId: '',
      merchantOpenId: '',
      treeLoading: true,
      newQuestiondialogVisible: false,
      termIndex: null,
      period: undefined,
      subject: undefined,
      nodeId: undefined,
      parentId: undefined,
      dataId: -1,
      subjectList: [],
      singerItem: undefined,
      testClassVisible: false,
      activeName: 'class',
      testClassTitle: '添加分类',
      isChangeWidth: 1,
      // 模拟教材列表
      list: [],
      isTable: false
    };
  },
  watch: {
    testClassVisible: function testClassVisible(newVal) {
      if (!newVal) {
        this.className = '';
        this.nodeId = undefined;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _bus.default.$on('questionMerchantOpenId', function (view, data) {
      if (view) {
        _this.partnerOpenId = view;
      }

      _this.searchFun(data);
    });

    _bus.default.$on('questionEdit', function (e) {
      _this.newQuestiondialogVisible = false;

      if (_this.$refs.questionTable) {
        _this.$refs.questionTable.getQuestionList();
      }
    });

    _bus.default.$on('questionAdd', function (data) {
      _this.newQuestiondialogVisible = false;

      if (_this.$refs.questionTable) {
        _this.$refs.questionTable.getQuestionList();
      }
    });
  },
  methods: {
    /* dialog开关变化 */
    directoryDialogChange: function directoryDialogChange(dialogVisible) {
      this.newQuestiondialogVisible = dialogVisible;
    },
    updatequestionlist: function updatequestionlist(val) {
      this.newQuestiondialogVisible = false; // 添加试题成功后重新加载获取试题

      this.$refs.questionTable.getQuestionList();
    },
    // 调整窗口大小
    changeMenuSize: function changeMenuSize() {
      this.isChangeWidth = this.isChangeWidth + 1;
    },
    searchFun: function searchFun(data) {
      var _this2 = this;

      var arr = Object.keys(data);

      if (data.sortOrder === '倒序') {
        data.sortOrder = 'desc';
      } else {
        data.sortOrder = 'asc';
      }

      arr.forEach(function (item) {
        _this2.$refs.questionTable.query[item] = data[item];
      });
      this.$refs.questionTable.query.current = 1;
      this.$refs.questionTable.getQuestionList();
    },
    // 新增试题
    newAddQuestion: function newAddQuestion(e) {
      this.merchantOpenId = e;
      this.newQuestiondialogVisible = true;
    },
    tableStyle: function tableStyle(val) {
      this.isTable = val;

      if (!val) {
        this.$refs.questionTable.getMultipleSelection();
      }
    },
    handleClose: function handleClose(done) {
      done();
    },
    selectSubject: function selectSubject(code) {
      this.$refs.questionTable.query.subjectCode = code;
      this.$refs.questionTable.getQuestionList();
    },
    points: function points(id) {
      this.$refs.questionTable.query.knowledgeOpenId = id;
      this.$refs.questionTable.getQuestionList();
    },
    nodeClick: function nodeClick(data) {
      this.clickNodeOpenId = data;
      this.$refs.questionTable.query.categoryOpenId = data;
      this.$refs.questionTable.getQuestionList();
    },
    handleClick: function handleClick(tab) {
      // if (tab.label !== '教材') {
      //   this.isShowTerm = true
      // }
      if (tab.label === '分类') {
        this.$refs.questionTable.query.subjectCode = undefined;
        this.$refs.questionTable.query.knowledgeOpenId = undefined;
        this.$refs.questionTable.getQuestionList();
      }
    }
  }
};
exports.default = _default;