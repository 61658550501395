"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getImgUrl = getImgUrl;

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

function getImgUrl(path, style) {
  if (!_main.default.isString(path)) {
    return undefined;
  }

  if (_main.default.isEmpty(path)) {
    return undefined;
  } //


  var imgUrl = "".concat(process.env.VUE_APP_IMG_URL_PREFIX).concat(process.env.VUE_APP_OSS_PATH, "/").concat(path); //

  if (_main.default.isString(style) && !_main.default.isEmpty(style)) {
    imgUrl = "".concat(imgUrl, "?x-oss-process=style/").concat(style);
  }

  return imgUrl;
}