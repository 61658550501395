var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px 20px", width: "100%" } },
    [
      _c(
        "topBar",
        _vm._b(
          {
            ref: "topBar",
            attrs: {
              subjectCode: _vm.subjectCode,
              resourceType: _vm.resourceType
            },
            on: {
              "new-add": _vm.newAdd,
              changeTitle: _vm.changeTitle,
              filterName: _vm.filterName,
              searchbtnevent: _vm.searchbtnevent
            }
          },
          "topBar",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableList" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "tableItem",
              attrs: {
                "header-cell-style": { fontSize: "15px" },
                data:
                  _vm.resourceType === "model"
                    ? _vm.tableData.records
                    : _vm.scriptTable.records,
                height: "100%",
                "tooltip-effect": "dark"
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _vm.resourceType === "model"
                ? _c("el-table-column", {
                    attrs: { fixed: "", type: "selection", "min-width": "35" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                key: 1,
                attrs: { label: "序号", "min-width": "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.query.page - 1) * _vm.query.size +
                                scope.$index +
                                1
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              !_vm.isShowId && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 2,
                    attrs: {
                      prop: "compositionOpenId",
                      label: "openId",
                      "min-width": "270"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowId && _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 3,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "manuscriptOpenId",
                      label: "openId",
                      "min-width": "240"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowTitle && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 4,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "compositionName",
                      label: "标题",
                      "min-width": "240"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowTitle && _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 5,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "manuscriptName",
                      label: "标题",
                      "min-width": "150"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowAuthor && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 6,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "authorName",
                      label: "作者",
                      formatter: _vm.formatAuthor,
                      "min-width": "80"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowAuthor && _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 7,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "authorName",
                      label: "作者",
                      "min-width": "100"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowVIP && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 8,
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatBoolean,
                      prop: "vipOnly",
                      label: "仅限VIP",
                      "min-width": "100"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowAudio && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 9,
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatBoolean,
                      prop: "existAudio",
                      label: "是否含音频",
                      "min-width": "120"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowGenre
                ? _c("el-table-column", {
                    key: 10,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "typeName",
                      label: "体裁",
                      "min-width": "120"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowTags && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 30,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "tags",
                      label: "标签",
                      "min-width": "220"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.tags, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-tag",
                                { key: index, staticClass: "tableTags" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item) +
                                      "\n          "
                                  )
                                ]
                              )
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      1722086687
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowGrade
                ? _c("el-table-column", {
                    key: 11,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "gradeName",
                      label: "年级",
                      "min-width": "80"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowStage
                ? _c("el-table-column", {
                    key: 12,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "volumeName",
                      label: "阶段",
                      "min-width": "90"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAdviser && _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 13,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "adviser",
                      label: "指导老师",
                      "min-width": "90"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMstType && _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 14,
                    attrs: {
                      formatter: _vm.formatString,
                      "show-overflow-tooltip": true,
                      prop: "mstType",
                      label: "投稿",
                      "min-width": "90"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAdviserPhone && _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 15,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "adviserPhone",
                      label: "指导老师手机号",
                      "min-width": "150"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowUntil
                ? _c("el-table-column", {
                    key: 16,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "unitName",
                      label: "单元",
                      formatter: _vm.formatAuthor,
                      "min-width": "100"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowCount && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 17,
                    staticClass: "numTag",
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "countName",
                      label: "字数",
                      formatter: _vm.formatAuthor,
                      "min-width": "80"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowCreatTime
                ? _c("el-table-column", {
                    key: 18,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "createdAt",
                      label:
                        _vm.resourceType === "model" ? "创建时间" : "投稿时间",
                      formatter: _vm.formatAuthor,
                      "min-width": "150"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowUpTime && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 19,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "pubTime",
                      label: "发布时间",
                      formatter: _vm.formatAuthor,
                      "min-width": "150"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowIsUp && _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 20,
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatBoolean,
                      prop: "pubStatus",
                      label: "是否已发布",
                      "min-width": "100"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceType === "model"
                ? _c("el-table-column", {
                    key: 21,
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      width: "200",
                      align: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.preview(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("预览")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editBtn(
                                            scope.row.compositionOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !scope.row.pubStatus,
                                          expression: "!scope.row.pubStatus"
                                        }
                                      ],
                                      staticClass: "removeBtn",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.pubItem(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("发布")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.pubStatus,
                                          expression: "scope.row.pubStatus"
                                        }
                                      ],
                                      staticClass: "delBtn",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.backOutBtn(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("下架")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !scope.row.deletedAt,
                                          expression: "!scope.row.deletedAt"
                                        }
                                      ],
                                      staticClass: "delBtn",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeBtn(
                                            scope.row,
                                            scope.$index,
                                            _vm.tableData
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.deletedAt,
                                          expression: "scope.row.deletedAt"
                                        }
                                      ],
                                      staticClass: "removeBtn",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.restoreItem(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("恢复")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      792839848
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceType === "manuScript"
                ? _c("el-table-column", {
                    key: 22,
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      "min-width": "70",
                      align: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.seeCons(
                                        scope.row.manuscriptOpenId,
                                        scope.row.subjectCode
                                      )
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      177447247
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footerOperation" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.resourceType === "model",
                expression: "resourceType === 'model'"
              }
            ]
          },
          [
            _c(
              "el-checkbox",
              {
                attrs: { indeterminate: _vm.indeterminate },
                on: {
                  change: function($event) {
                    return _vm.toggleSelection(_vm.tableData.records)
                  }
                },
                model: {
                  value: _vm.checked,
                  callback: function($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked"
                }
              },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { "split-button": "", type: "primary" },
                    on: { click: _vm.submitChange }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.dropMenName) + "\n          "
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(_vm.dropMenuList, function(item, index) {
                        return _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function($event) {
                                return _vm.changeDropName(item, index)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _vm.resourceType === "model"
          ? _c(
              "div",
              [
                _c("Pagination", {
                  staticClass: "Pagination",
                  attrs: {
                    total: _vm.tableData.total,
                    page: _vm.query.page,
                    limit: _vm.query.size,
                    "current-page": _vm.query.page
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.query, "page", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.query, "size", $event)
                    },
                    pagination: _vm.getList
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.resourceType === "manuScript"
          ? _c(
              "div",
              { staticClass: "block" },
              [
                _c("Pagination", {
                  staticClass: "Pagination",
                  attrs: {
                    total: _vm.scriptTable.total,
                    page: _vm.query.page,
                    limit: _vm.query.size,
                    "current-page": _vm.query.page
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.query, "page", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.query, "size", $event)
                    },
                    pagination: _vm.getLists
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }