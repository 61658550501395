"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var workOrder = {
  namespaced: true,
  state: {
    workOrderNum: 0
  },
  mutations: {
    SET_WORK_ORDER_NUM: function SET_WORK_ORDER_NUM(state, val) {
      state.workOrderNum = val;
    }
  },
  actions: {
    setWorkOrderNum: function setWorkOrderNum(_ref, val) {
      var commit = _ref.commit;
      commit('SET_WORK_ORDER_NUM', val);
    }
  }
};
var _default = workOrder;
exports.default = _default;