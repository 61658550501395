"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fileDetail = _interopRequireDefault(require("./fileDetail.vue"));

//
//
//
//
//
//
var _default = {
  name: 'AddFile',
  components: {
    fileDetail: _fileDetail.default
  }
};
exports.default = _default;