var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dashboard_container"
    },
    [
      _c("div", { staticClass: "page_body" }, [
        _c(
          "div",
          { staticClass: "info_box" },
          [
            _c("div", { staticClass: "info_title" }, [
              _vm._v("\n        编辑门店信息\n      ")
            ]),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "info_content" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      "label-width": "80px",
                      rules: _vm.rules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "门店名称", prop: "name" } },
                      [
                        _c("el-input", {
                          staticClass: "input_width",
                          attrs: { placeholder: "请输入门店名称" },
                          model: {
                            value: _vm.form.name,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系电话", prop: "phone" } },
                      [
                        _c("el-input", {
                          staticClass: "input_width",
                          attrs: { placeholder: "请输入联系电话" },
                          model: {
                            value: _vm.form.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系地址", prop: "cityCodeArray" } },
                      [
                        _c("el-cascader", {
                          key: _vm.isResouceShow,
                          staticClass: "input_width",
                          attrs: {
                            placeholder: "请选择所属省市",
                            props: _vm.props,
                            options: _vm.options
                          },
                          model: {
                            value: _vm.form.cityCodeArray,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "cityCodeArray", $$v)
                            },
                            expression: "form.cityCodeArray"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "详细地址", prop: "address" } },
                      [
                        _c("el-input", {
                          staticClass: "input_width",
                          attrs: { placeholder: "请输入详细地址" },
                          model: {
                            value: _vm.form.address,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "address", $$v)
                            },
                            expression: "form.address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("map-view", { ref: "mapView" })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page_foot" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }