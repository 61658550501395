"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _dayjs = _interopRequireDefault(require("dayjs"));

var _uploadOSS = require("@/utils/uploadOSS");

var _index = _interopRequireDefault(require("./CoverUpload/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Table_ImageEditColumn',
  components: {
    CoverUpload: _index.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    disableCell: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      uploadFn: _uploadOSS.UploadBatchOSS,
      uploadPath: "product/cover/".concat((0, _dayjs.default)().format('YYYY-MM-DD-HH-mm'), "/")
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    replacButton: function replacButton() {
      return this.valueProxy ? '替换' : '上传';
    }
  },
  methods: {
    onReplace: function onReplace() {
      this.$refs.coverUpload.replace();
    }
  }
};
exports.default = _default;