var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "batchAllpriceWrap"
    },
    [
      _c(
        "div",
        { staticClass: "batchAllpriceTable", attrs: { type: "border-card" } },
        [
          _c(
            "div",
            { staticClass: "topBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.outerVisible = true
                    }
                  }
                },
                [_vm._v("添加作品")]
              ),
              _vm._v(" "),
              _c("select-resource-dialog", {
                attrs: {
                  partnerOpenId: _vm.partnerOpenId,
                  "expect-type": "resource",
                  visible: _vm.outerVisible,
                  "exclude-data": _vm.tableData
                },
                on: {
                  "update:visible": function($event) {
                    _vm.outerVisible = $event
                  },
                  "add-select": _vm.onAddAlbumContent
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "tablewrapCon" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading"
                        }
                      ],
                      ref: "tablewrap",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        height: "0"
                      },
                      on: { "selection-change": _vm.handleSelectionChange }
                    },
                    [
                      _c("el-table-column", { attrs: { type: "selection" } }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作品", width: "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        width: "200px",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [_vm._v(_vm._s(scope.row.resourceTitle))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1062702737
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "单独售卖" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm.changeIndependentSalestatus &&
                                  scope.row.resourceInfo
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-checkbox", {
                                            on: {
                                              change: function($event) {
                                                return _vm.changeIndependentSale(
                                                  scope.$index,
                                                  scope.row,
                                                  _vm.tableData
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                scope.row.resourceInfo
                                                  .independentSale,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row.resourceInfo,
                                                  "independentSale",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.resourceInfo.independentSale"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          845585328
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "售卖方式", prop: "selectedQuery" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale
                                  ? [
                                      _vm.showSwitch
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "110px"
                                                  },
                                                  attrs: {
                                                    placeholder: "请选择",
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeswitch()
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.selectedQuery,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "selectedQuery",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.selectedQuery"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.saleTypeOptions,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.lable,
                                                        value: item.id
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "原价(元)" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c("el-input-number", {
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          size: "mini",
                                          precision: 2,
                                          min: 0.01,
                                          "controls-position": "right"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getchangePrice(scope.row)
                                          }
                                        },
                                        model: {
                                          value: scope.row.price,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "price", $$v)
                                          },
                                          expression: "scope.row.price"
                                        }
                                      })
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "商品折扣(%)", width: "140" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c("el-input-number", {
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          precision: 4,
                                          max: 100,
                                          min: 0,
                                          "controls-position": "right",
                                          size: "mini"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getchangedisconut(
                                              scope.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.discount,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "discount", $$v)
                                          },
                                          expression: "scope.row.discount"
                                        }
                                      })
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "减价(元)", width: "140" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c(
                                        "div",
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceReduction",
                                            staticStyle: { width: "120px" },
                                            attrs: {
                                              size: "mini",
                                              precision: 2,
                                              max: 0,
                                              min: -Number(scope.row.price),
                                              "controls-position": "right"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.getchangeminusPrice(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.minusPrice,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "minusPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.minusPrice"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "折后价(元)", width: "140" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c("el-input-number", {
                                        staticClass: "discountPrice",
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          precision: 2,
                                          max: Number(scope.row.price),
                                          min: 0,
                                          "controls-position": "right",
                                          size: "mini"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getchangedisconPrice(
                                              scope.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.countPrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "countPrice",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.countPrice"
                                        }
                                      })
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "VIP折扣(%)" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c("el-input-number", {
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          precision: 4,
                                          max: 100,
                                          min: 0,
                                          "controls-position": "right",
                                          size: "mini"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getchangeVipdiscont(
                                              scope.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.vipDiscount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "vipDiscount",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.vipDiscount"
                                        }
                                      })
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "VIP减价(元)" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c("el-input-number", {
                                        staticClass: "priceReduction",
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          precision: 2,
                                          max: scope.row.minusPrice,
                                          min: -scope.row.price,
                                          "controls-position": "right",
                                          size: "mini"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getchangevipMinusPrice(
                                              scope.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.vipMinusPrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "vipMinusPrice",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.vipMinusPrice"
                                        }
                                      })
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "VIP折后价(元)" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return scope.row.resourceInfo &&
                                  scope.row.resourceInfo.independentSale &&
                                  scope.row.selectedQuery === 2
                                  ? [
                                      _c("el-input-number", {
                                        staticClass: "discountPrice",
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          precision: 2,
                                          max: scope.row.countPrice,
                                          min: 0,
                                          "controls-position": "right",
                                          size: "mini"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getchangevipCountPrice(
                                              scope.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.vipCountPrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "vipCountPrice",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.vipCountPrice"
                                        }
                                      })
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "right",
                          fixed: "right"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#F56C6C" },
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.deleteRow(
                                            scope.$index,
                                            _vm.tableData,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1289862781
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tableData.length
            ? _c("div", { staticClass: "btnBottom" }, [
                _c(
                  "div",
                  [
                    _c("el-checkbox", {
                      staticClass: "pagination-check",
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleSelectedAll },
                      model: {
                        value: _vm.selectedAll,
                        callback: function($$v) {
                          _vm.selectedAll = $$v
                        },
                        expression: "selectedAll"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "mini", type: "primary", plain: "" },
                        on: { click: _vm.allDiscount }
                      },
                      [_vm._v("批量设置折扣")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "mini", type: "primary", plain: "" },
                        on: { click: _vm.setSale }
                      },
                      [_vm._v("批量设置售卖")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", plain: "", size: "mini" },
                        on: { click: _vm.removeAllresource }
                      },
                      [_vm._v("批量移除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          visible: _vm.dialogFormVisibleDiscount,
                          "close-on-click-modal": false,
                          "close-on-press-escape": false,
                          title: "批量设置折扣",
                          width: "450px"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.dialogFormVisibleDiscount = $event
                          }
                        }
                      },
                      [
                        _c(
                          "el-form",
                          {
                            staticClass: "discountFormWrap",
                            attrs: { model: _vm.discountForm }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #EBEEF5",
                                  "padding-bottom": "10px"
                                },
                                model: {
                                  value: _vm.discountForm.radio1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.discountForm, "radio1", $$v)
                                  },
                                  expression: "discountForm.radio1"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "disCountRadio" },
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("商品折扣")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            precision: 4,
                                            max: 100,
                                            min: 0,
                                            disabled:
                                              _vm.discountForm.radio1 !== 3,
                                            "controls-position": "right"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getcommodityDiscount()
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.discountForm
                                                .commodityDiscount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.discountForm,
                                                "commodityDiscount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "discountForm.commodityDiscount"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { label: "%" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "disCountRadio" },
                                  [
                                    _c("el-radio", { attrs: { label: 6 } }, [
                                      _vm._v("减价")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            precision: 2,
                                            max: 0,
                                            disabled:
                                              _vm.discountForm.radio1 !== 6,
                                            "controls-position": "right"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getcommodityMinusPrice()
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.discountForm
                                                .commodityMinusPrice,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.discountForm,
                                                "commodityMinusPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "discountForm.commodityMinusPrice"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { label: "元" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "disCountRadio" },
                                  [
                                    _c("el-radio", { attrs: { label: 9 } }, [
                                      _vm._v("折后价")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            precision: 2,
                                            min: 0,
                                            disabled:
                                              _vm.discountForm.radio1 !== 9,
                                            "controls-position": "right"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getcommodityAfterPrice()
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.discountForm
                                                .commodityAfterPrice,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.discountForm,
                                                "commodityAfterPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "discountForm.commodityAfterPrice"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { label: "元" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { "padding-top": "10px" },
                                model: {
                                  value: _vm.discountForm.radioVip,
                                  callback: function($$v) {
                                    _vm.$set(_vm.discountForm, "radioVip", $$v)
                                  },
                                  expression: "discountForm.radioVip"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "disCountRadio" },
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("VIP折扣")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            precision: 4,
                                            min: 0,
                                            max: 100,
                                            disabled:
                                              _vm.discountForm.radioVip !== 3,
                                            "controls-position": "right"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getvipcommodityDiscount()
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.discountForm
                                                .vipcommodityDiscount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.discountForm,
                                                "vipcommodityDiscount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "discountForm.vipcommodityDiscount"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { label: "%" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "disCountRadio" },
                                  [
                                    _c("el-radio", { attrs: { label: 6 } }, [
                                      _vm._v("VIP减价")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            precision: 2,
                                            max: 0,
                                            disabled:
                                              _vm.discountForm.radioVip !== 6,
                                            "controls-position": "right"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getvipcommodityMinusPrice()
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.discountForm
                                                .vipcommodityMinusPrice,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.discountForm,
                                                "vipcommodityMinusPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "discountForm.vipcommodityMinusPrice"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { label: "元" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "disCountRadio" },
                                  [
                                    _c("el-radio", { attrs: { label: 9 } }, [
                                      _vm._v("VIP折后价")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            min: 0,
                                            precision: 2,
                                            disabled:
                                              _vm.discountForm.radioVip !== 9,
                                            "controls-position": "right"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getvipcommodityAfterPrice()
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.discountForm
                                                .vipcommodityAfterPrice,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.discountForm,
                                                "vipcommodityAfterPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "discountForm.vipcommodityAfterPrice"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { label: "元" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer"
                          },
                          [
                            _c("div", [_vm._v("VIP享受折上折优惠")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        _vm.dialogFormVisibleDiscount = false
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveVolumeDiscount()
                                      }
                                    }
                                  },
                                  [_vm._v("保存")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        staticClass: "independent-sale-dialog",
                        attrs: {
                          visible: _vm.dialogFormVisibleSetsale,
                          "close-on-click-modal": false,
                          "close-on-press-escape": false,
                          title: "批量设置售卖",
                          width: "350px"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.dialogFormVisibleSetsale = $event
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "independent-sale" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.setsaleSwitchCheck,
                                callback: function($$v) {
                                  _vm.setsaleSwitchCheck = $$v
                                },
                                expression: "setsaleSwitchCheck"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "sale-text" }, [
                              _vm._v("独立售卖")
                            ]),
                            _vm._v(" "),
                            _vm.setsaleSwitchCheck
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "independent-sale-select",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.setsaleModSelect,
                                      callback: function($$v) {
                                        _vm.setsaleModSelect = $$v
                                      },
                                      expression: "setsaleModSelect"
                                    }
                                  },
                                  _vm._l(_vm.saleTypeOptions, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.lable,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.dialogFormVisibleSetsale = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.saveSetSale()
                                  }
                                }
                              },
                              [_vm._v("保存")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.goBack } },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.saveSubmitAudit }
                      },
                      [_vm._v("保存")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }