var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["question-item", _vm.showOptions ? "question-item-active" : ""],
      on: {
        mouseenter: function($event) {
          return _vm.handleMouse(true)
        },
        mouseleave: function($event) {
          return _vm.handleMouse(false)
        }
      }
    },
    [
      _vm.questionIndex === undefined
        ? _c("div", { staticClass: "stem-num" }, [
            _vm._v(_vm._s(_vm.valIndex + 1) + ".")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("download-nav", {
            attrs: {
              value: _vm.value,
              options: _vm.options,
              "question-index": _vm.questionIndex,
              "val-index": _vm.valIndex
            }
          }),
          _vm._v(" "),
          _vm.value.examQuestionTypeCode === 1001 ||
          _vm.value.examQuestionTypeCode === 1002 ||
          _vm.value.examQuestionTypeCode === 1006
            ? _c("div", [
                _c(
                  "div",
                  {
                    style:
                      _vm.value.otherOptions === 1
                        ? ""
                        : "display:flex; flex-wrap: wrap"
                  },
                  _vm._l(_vm.value.optionText[0], function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "question-option-text common-flex",
                        style: _vm.choiceStyle
                      },
                      [
                        _vm.value.examQuestionTypeCode !== 1006
                          ? _c("div", [
                              _vm._v(
                                _vm._s(index ? index.toUpperCase() : index) +
                                  "."
                              )
                            ])
                          : _c("div", [_vm._v(_vm._s(index) + ":")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "analysis common-editor",
                          domProps: { innerHTML: _vm._s(item) }
                        })
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.options.answer === 1
                  ? _c("div", { staticClass: "answer" }, [
                      _c("div", { staticClass: "answer-right" }, [
                        _vm._v(
                          "【解析】" +
                            _vm._s(
                              _vm.value.rightAnswerContent
                                ? _vm.value.rightAnswerContent.toUpperCase()
                                : "--"
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _vm.value.analysis
                        ? _c("div", {
                            staticClass: "analysis common-editor",
                            domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                          })
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticStyle: { clear: "both" } })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.value.examQuestionTypeCode === 1003
            ? _c("div", [
                _vm.value.otherOptions === 1
                  ? _c(
                      "div",
                      _vm._l(_vm.value.optionText[0], function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "common-flex common-options"
                          },
                          [
                            _c("el-image", {
                              staticClass: "judge-options-img",
                              attrs: {
                                src: index === "a" ? _vm.right : _vm.error
                              }
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "common-editor",
                              domProps: { innerHTML: _vm._s(item) }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.options.answer === 1
                  ? _c("div", { staticClass: "answer" }, [
                      _c("div", [
                        _c("div", { staticClass: "common-float-left" }, [
                          _vm._v("【解析】")
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "common-editor",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.value.rightAnswerContent === "no"
                                ? "" + _vm.value.optionText[0].b
                                : "" + _vm.value.optionText[0].a
                            )
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { clear: "both" } }),
                      _vm._v(" "),
                      _vm.value.analysis
                        ? _c("div", {
                            staticClass: "common-editor",
                            domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                          })
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.value.examQuestionTypeCode === 1004
            ? _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm.value.otherOptions === 1
                  ? _c("table", { staticStyle: { width: "100%" } }, [
                      _c(
                        "tr",
                        { staticStyle: { width: "100%" } },
                        _vm._l(_vm.value.optionText[0], function(item, index) {
                          return _c(
                            "td",
                            {
                              key: index,
                              style: {
                                "vertical-align": "bottom",
                                width:
                                  _vm.value.otherOptions === 1
                                    ? 100 /
                                        Object.keys(_vm.value.optionText[0])
                                          .length +
                                      "%"
                                    : ""
                              }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(
                                  _vm._s(
                                    index ? index.toUpperCase() : undefined
                                  ) + "."
                                )
                              ]),
                              _c("span", {
                                staticClass: "common-editor",
                                domProps: { innerHTML: _vm._s(item) }
                              })
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tr", { staticStyle: { height: "40px" } }),
                      _vm._v(" "),
                      _c(
                        "tr",
                        { staticStyle: { width: "100%" } },
                        _vm._l(_vm.value.optionText[1], function(item, index) {
                          return _c(
                            "td",
                            {
                              key: index,
                              style: {
                                "vertical-align": "bottom",
                                width:
                                  _vm.value.otherOptions === 1
                                    ? 100 /
                                        Object.keys(_vm.value.optionText[0])
                                          .length +
                                      "%"
                                    : ""
                              }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(
                                  _vm._s(
                                    index ? index.toUpperCase() : undefined
                                  ) + "."
                                )
                              ]),
                              _c("span", {
                                staticClass: "common-editor",
                                domProps: { innerHTML: _vm._s(item) }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _c(
                      "table",
                      { staticStyle: { width: "100%" } },
                      _vm._l(_vm.value.optionText[0], function(item, index) {
                        return _c(
                          "tr",
                          { key: index, staticStyle: { width: "100%" } },
                          [
                            _c(
                              "td",
                              {
                                style: {
                                  width: "50%",
                                  "vertical-align": "middle",
                                  "padding-top":
                                    _vm.getConnectKey(index) === 1 ? 0 : "15px"
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(
                                    _vm._s(
                                      index ? index.toUpperCase() : undefined
                                    ) + "."
                                  )
                                ]),
                                _c("span", {
                                  staticClass: "common-editor",
                                  domProps: { innerHTML: _vm._s(item) }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                style: {
                                  width: "50%",
                                  "vertical-align": "middle",
                                  "padding-top":
                                    _vm.getConnectKey(index) === 1 ? 0 : "15px"
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getConnectKey(index)
                                        ? _vm.getConnectKey(index)
                                        : undefined
                                    ) + "."
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "common-editor",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.value.optionText[1][
                                        _vm.getConnectKey(index)
                                      ]
                                    )
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                _vm._v(" "),
                _vm.options.answer === 1
                  ? _c("div", { staticClass: "answer" }, [
                      _c("div", {
                        staticClass: "answer-right common-editor",
                        domProps: {
                          innerHTML: _vm._s(
                            "【解析】" + _vm.value.rightAnswerContent
                          )
                        }
                      }),
                      _vm._v(" "),
                      _vm.value.analysis
                        ? _c("div", {
                            staticClass: "analysis common-editor",
                            domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                          })
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticStyle: { clear: "both" } })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.value.examQuestionTypeCode === 1005 && _vm.options.answer === 1
            ? _c("div", { staticClass: "answer" }, [
                _c("div", {
                  staticClass: "answer-right common-editor",
                  domProps: {
                    innerHTML: _vm._s("【解析】" + _vm.value.rightAnswerContent)
                  }
                }),
                _vm._v(" "),
                _vm.value.analysis
                  ? _c("div", {
                      staticClass: "analysis common-editor",
                      domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticStyle: { clear: "both" } })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.value.examQuestionTypeCode === 1007
            ? _c(
                "div",
                [
                  _vm._l(_vm.value.optionList, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "common-options" },
                      [
                        _c("div", [_vm._v("(" + _vm._s(index + 1) + ")")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            style:
                              _vm.value.otherOptions === 1
                                ? ""
                                : "display:flex; flex-wrap: wrap"
                          },
                          _vm._l(item, function(secondItem, secondIndex) {
                            return _c(
                              "div",
                              {
                                key: secondIndex,
                                staticClass: "question-option-text",
                                style: _vm.choiceStyle
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(secondIndex.toUpperCase()) +
                                    ":" +
                                    _vm._s(secondItem) +
                                    "\n          "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.options.answer === 1
                    ? _c("div", { staticClass: "answer" }, [
                        _c("div", {
                          staticClass: "answer-right common-editor",
                          domProps: {
                            innerHTML: _vm._s(
                              "【解析】" + _vm.value.rightAnswerContent
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.value.analysis
                          ? _c("div", {
                              staticClass: "analysis common-editor",
                              domProps: {
                                innerHTML: _vm._s(_vm.value.analysis)
                              }
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticStyle: { clear: "both" } })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.value.examQuestionTypeCode === 1008
            ? _c("div", [
                _c("div", { staticClass: "subjective-question" }),
                _vm._v(" "),
                _vm.options.answer === 1
                  ? _c("div", { staticClass: "answer" }, [
                      _c("div", [
                        _c("div", { staticClass: "common-float-left" }, [
                          _vm._v("【解析】")
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "common-editor",
                          domProps: {
                            innerHTML: _vm._s(_vm.value.rightAnswerContent)
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { clear: "both" } }),
                      _vm._v(" "),
                      _vm.value.analysis
                        ? _c("div", {
                            staticClass: "common-editor",
                            domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                          })
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showOptions
        ? _c(
            "div",
            {
              staticClass: "set-options",
              style: {
                width:
                  _vm.value.examQuestionTypeCode !== 1003 &&
                  _vm.value.examQuestionTypeCode !== 1004
                    ? "70px"
                    : "50px"
              }
            },
            [
              _vm.value.examQuestionTypeCode === 1003
                ? _c("div", { staticClass: "icon-common-flex" }, [
                    _c("i", {
                      class: [
                        "iconfont icon-duicuogouxuan",
                        _vm.value.otherOptions === 1 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(1)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      class: [
                        "iconfont icon-xianshipanduandaan",
                        _vm.value.otherOptions === 2 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(2)
                        }
                      }
                    })
                  ])
                : _vm.value.examQuestionTypeCode === 1004
                ? _c("div", { staticClass: "icon-common-flex" }, [
                    _c("i", {
                      class: [
                        "iconfont icon-hengxiangpailie",
                        _vm.value.otherOptions === 1 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(1)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      class: [
                        "iconfont icon-shuxiangpailie",
                        _vm.value.otherOptions === 2 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(2)
                        }
                      }
                    })
                  ])
                : _c("div", { staticClass: "icon-common-flex" }, [
                    _c("i", {
                      class: [
                        "iconfont icon-yihangsigedaan",
                        _vm.value.otherOptions === 4 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(4)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      class: [
                        "iconfont icon-yihanglianggedaan",
                        _vm.value.otherOptions === 2 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(2)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      class: [
                        "iconfont icon-yihangyigedaan",
                        _vm.value.otherOptions === 1 ? "common-icon-color" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleOption(1)
                        }
                      }
                    })
                  ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }