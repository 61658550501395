"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.link");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _SpecialPage = _interopRequireDefault(require("./SpecialPage"));

var _ExternalLinks = _interopRequireDefault(require("./ExternalLinks"));

var _ApplicationPage = _interopRequireDefault(require("./ApplicationPage"));

var _CommonProblem = _interopRequireDefault(require("./CommonProblem"));

var _index = _interopRequireDefault(require("../../Help/SelectResource/index"));

var _Coupon = _interopRequireDefault(require("./Coupon"));

var _AudioBook = _interopRequireDefault(require("./AudioBook/AudioBook"));

var _MicroPageLink = _interopRequireDefault(require("./MicroPageLink"));

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

var _AppletLink = _interopRequireDefault(require("@/components/PublicReply/SelectLink/AppletLink"));

var _TestPaperLink = _interopRequireDefault(require("./TestPaperLink"));

var _WordTermsLink = _interopRequireDefault(require("./WordTermsLink"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'RadioSelectLink',
  components: {
    WordTermsLink: _WordTermsLink.default,
    TestPaperLink: _TestPaperLink.default,
    AppletLink: _AppletLink.default,
    MicroPageLink: _MicroPageLink.default,
    AudioBook: _AudioBook.default,
    Coupon: _Coupon.default,
    SelectResource: _index.default,
    CommonProblem: _CommonProblem.default,
    ApplicationPage: _ApplicationPage.default,
    ExternalLinks: _ExternalLinks.default,
    SpecialPage: _SpecialPage.default
  },
  props: {
    value: {
      type: [Object, Array],
      default: function _default() {
        return [{}, []];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    isOldLink: {
      type: Boolean,
      default: false
    },
    multipleCheck: {
      type: Boolean,
      default: false
    },
    // 多选
    partnerOpenId: {
      type: String,
      default: undefined
    },
    propsActiveName: {
      type: String,
      default: undefined
    },
    audioBookOpenId: {
      type: String,
      default: ''
    },
    // 指定某本点学书
    isNotModel: {
      type: Boolean,
      default: false
    },
    // 不要 v-model这种类型的
    isReplace: {
      type: Boolean,
      default: false
    },
    // 只有多选替换有
    replaceIndex: {
      type: Number,
      default: undefined
    },
    // 只有多选替换有
    disableTab: {
      type: Boolean,
      default: true
    },
    // 是否开启禁用 开启后只能选择同类型的
    showWord: {
      type: Boolean,
      default: false
    },
    tool: {
      type: String,
      default: ''
    },
    // 词语单词工具
    isExtend: {
      type: Boolean,
      default: false
    } // 是否是拓展学习

  },
  data: function data() {
    return {
      resourceOpenId: undefined,
      linkTitle: undefined,
      linkRouter: undefined,
      linkCommonProblem: undefined,
      specialPageLink: undefined,
      linkHref: undefined,
      pageLink: undefined,
      externalLinks: {
        linkTitle: '',
        linkHref: '',
        openInBrowser: false
      },
      activeName: 'resource',
      tabsList: [{
        label: '作品 ',
        name: 'resource'
      }, {
        label: '试卷 ',
        name: 'paper'
      }, {
        label: '字库',
        name: 'character'
      }, {
        label: '词库',
        name: 'terms'
      }, {
        label: '单词库',
        name: 'word'
      }],
      appPageVal: {
        pageCode: undefined,
        pageName: undefined
      },
      special: {
        specialPageLink: undefined,
        list: []
      },
      linkData: {},
      commonProblem: {
        id: undefined // 数字类型

      },
      resource: {
        resourceType: 1,
        resourceOpenId: undefined,
        linkCover: undefined
      },
      refreshResource: false,
      refreshProblem: false,
      refreshLink: false,
      refreshPage: false,
      refreshCoupon: false,
      refreshMicro: false,
      refreshPartnerMicro: false,
      refreshAudioBook: false,
      refreshPaper: false,
      coupon: {
        title: undefined,
        openId: undefined
      },
      multipleList: [],
      audioLink: {
        bookOpenId: undefined,
        sequence: undefined,
        id: undefined,
        bookTitle: undefined
      },
      microPage: {
        partnerOpenId: undefined,
        microPageOpenId: undefined
      },
      rememberCurrent: true,
      searchData: undefined,
      applet: {
        name: undefined,
        title: undefined,
        id: undefined,
        url: undefined,
        appSecret: undefined,
        appId: undefined
      },
      appletLink: {
        // 回显时用
        list: []
      },
      paper: {
        examPaperOpenId: undefined
      },
      tabsObj: {
        resource: 'first',
        paper: 'first',
        character: 'first',
        vocabulary: 'first',
        word: 'first',
        audioBook: 'second',
        microPage: 'second',
        partnerMicroPage: 'second',
        link: 'second',
        page: 'second',
        special: 'second',
        applet: 'second',
        problem: 'third'
      },
      activeTab: 'first',
      wordData: {
        list: [],
        toolType: this.tool
      },
      multipleVal: []
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    },
    showMerchant: function showMerchant() {
      return !this.partnerOpenId;
    },
    isDisable: function isDisable() {
      if (!this.isExtend) {
        return false;
      }

      if (!this.multipleVal) {
        return false;
      }

      if (!this.disableTab) {
        return false;
      }

      return this.multipleVal.length > 0;
    }
  },
  methods: {
    // 顶部tabs
    handleClick: function handleClick() {
      var isActiveName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (this.activeTab === 'first') {
        this.tabsList = this.showWord ? [{
          label: '作品 ',
          name: 'resource'
        }, {
          label: '试卷 ',
          name: 'paper'
        }, {
          label: '字库',
          name: 'character'
        }, {
          label: '词库',
          name: 'vocabulary'
        }, {
          label: '单词库',
          name: 'word'
        }] : [{
          label: '作品 ',
          name: 'resource'
        }, {
          label: '试卷 ',
          name: 'paper'
        }];

        if (!isActiveName) {
          this.activeName = 'resource';
        }
      }

      if (this.activeTab === 'second') {
        this.tabsList = [{
          label: '点学书页',
          name: 'audioBook'
        }, {
          label: '微页页面',
          name: 'partnerMicroPage'
        }, {
          label: '平台微页',
          name: 'microPage'
        }, {
          label: '应用页面',
          name: 'page'
        }, {
          label: '外部链接',
          name: 'link'
        }, {
          label: '特殊页面',
          name: 'special'
        }, {
          label: '小程序',
          name: 'applet'
        }];

        if (!isActiveName) {
          this.activeName = 'audioBook';
        }
      }

      if (this.activeTab === 'third') {
        this.tabsList = [{
          label: '常见问题',
          name: 'problem'
        }, {
          label: '优惠券',
          name: 'coupon'
        }];

        if (!isActiveName) {
          this.activeName = 'problem';
        }
      } // 处理多选回显


      if (this.multipleCheck && this.multipleObj && JSON.stringify(this.multipleObj) !== '{}' && !isActiveName) {
        this.multipleVal = this.multipleObj[this.activeName] || [];
      }
    },
    multipleInitData: function multipleInitData(data, storeData) {
      var _this = this;

      if (data.length <= 0) {
        this.multipleObj = {};
        this.multipleVal = [];
        this.handleNoData(storeData);
        return;
      }

      var result = {};
      this.activeName = undefined;
      data.forEach(function (item, index) {
        var isActive;

        if (_this.isReplace) {
          isActive = _this.replaceIndex === index;
        } else {
          isActive = index === 0;
        }

        result = _this.handleMultiple(item, storeData, result, isActive);
      });
      this.multipleVal = result[this.activeName];
      this.multipleObj = _objectSpread({}, result);
      this.activeTab = this.tabsObj[this.activeName];
      this.handleClick(true);

      if (this.rememberCurrent && storeData) {
        this.putStore(storeData[this.activeName]);
      }
    },
    handleMultiple: function handleMultiple(val, storeData, result, active, index) {
      try {
        // 作品
        if (val.type === 'OpenId' && val.key.keyType === 'resource') {
          // 点学书到具体页面
          if (val.key.resourceType === 3 && (this.propsActiveName === 'audioBook' || val.key.sequence || val.key.pageId)) {
            this.audioLink.bookOpenId = val.key.openId;
            this.audioLink.sequence = val.key.sequence;
            this.audioLink.bookTitle = val.display.title;
            this.audioLink.id = val.key.pageId;
            this.audioLink.resourceEncode = val.key.resourceEncode;
            this.activeName = 'audioBook';

            if (this.rememberCurrent && storeData) {
              this.putStore(storeData.audioBook);
            }

            return;
          }

          this.refreshResource = true;

          if (active) {
            this.activeName = 'resource';
          }

          result.resource = result.resource || [];
          result.resource.push(val.key.openId);
          this.resource.resourceType = val.key.resourceType;
        } // 常见问题


        if (val.type === 'Id' && val.key.keyType === 'problem') {
          if (active) {
            this.activeName = 'problem';
          }

          result.problem = result.problem || [];
          result.problem.push(val.key.id);
        } // 应用页面


        if (val.type === 'Page') {
          if (active) {
            this.activeName = 'page';
          }

          result.page = result.page || [];
          result.page.push(val.key.pageCode);
        } // 特殊链接


        if (val.type === 'Special') {
          this.activeName = 'special';

          if (active) {
            this.special.list = this.isReplace ? val.key.name : undefined;
          }

          result.special = result.special || [];
          result.special.push(val.key.name);
        } // 优惠券


        if (val.type === 'OpenId' && val.key.keyType === 'Coupon') {
          if (active) {
            this.activeName = 'coupon';
          }

          result.coupon = result.coupon || [];
          result.coupon.push(val.key.openId);
        } // 微页


        if (val.type === 'OpenId' && val.key.keyType === 'micropage') {
          var resultActive = val.key.partnerOpenId.toString() === '0' ? 'microPage' : 'partnerMicroPage';

          if (active) {
            this.activeName = val.key.partnerOpenId.toString() === '0' ? 'microPage' : 'partnerMicroPage';
          }

          result[resultActive] = result[this.activeName] || [];
          result[resultActive].push(val.key.microPageOpenId);

          if (this.activeName === 'partnerMicroPage') {
            this.microPage.partnerOpenId = val.key.partnerOpenId.toString();
          }
        } // 外部链接


        if (val.type === 'Link') {
          this.activeName = 'link';

          if (active) {
            this.externalLinks.linkHref = this.isReplace ? val.key.url : undefined;
            this.externalLinks.linkTitle = this.isReplace ? val.display.title : undefined;
            this.externalLinks.openInBrowser = this.isReplace ? val.key.openInBrowser : undefined;
          }

          result.link = result.link || [];
          result.link.push(val.key.url);
        } // 小程序


        if (val.type === 'Applet') {
          this.activeName = 'applet';

          if (active) {
            this.appletLink.list = this.isReplace ? val.key : [];
          }

          result.applet = result.applet || [];
          result.applet.push(val.key);
        } // 试卷


        if (val.type === 'OpenId' && val.key.keyType === 'paper') {
          if (active) {
            this.activeName = 'paper';
          }

          result.paper = result.paper || [];
          result.paper.push(val.key.openId);
        } // 单词


        if (val.type === 'Word' || val.type === 'Character' || val.type === 'Vocabulary') {
          var keyType = val.type.toLowerCase();
          result[keyType] = result[keyType] || [];

          if (active) {
            this.activeName = keyType;
            this.wordData = _objectSpread({}, val.key);
          }

          result[keyType].push(index);
        }

        return result;
      } catch (e) {
        console.log(e);
      }
    },
    // 切换选项卡
    handleChangeTab: function handleChangeTab() {
      try {
        if (this.activeName === 'audioBook') {
          this.refreshAudioBook = true;
        }

        if (this.activeName === 'microPage') {
          this.refreshMicro = true;
        }

        if (this.activeName === 'partnerMicroPage') {
          this.microPage.partnerOpenId = this.partnerOpenId;
          this.refreshPartnerMicro = true;
        }

        if (this.activeName === 'applet') {
          if (this.$refs.applet) {
            this.$refs.applet.$refs.appletForm.clearValidate();
          }
        }

        var list = ['link', 'special', 'applet', 'word', 'character', 'vocabulary'];

        if (this.rememberCurrent && list.includes(this.activeName)) {
          this.putStore();
        } // 处理多选回显


        if (this.multipleCheck && this.multipleObj && JSON.stringify(this.multipleObj) !== '{}') {
          this.multipleList = this.multipleObj[this.activeName] || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 处理 选项卡是点学书 并且 audioBookOpenId 有数据
    handleAudioBookOpenId: function handleAudioBookOpenId() {
      this.activeName = 'audioBook';
      this.audioLink.bookOpenId = this.audioBookOpenId;
      this.audioLink.sequence = undefined;
      this.audioLink.bookTitle = undefined;
      this.audioLink.id = undefined;
      this.audioLink.cover = undefined;
    },
    // 处理新建 无回显数据
    handleNoData: function handleNoData(storeData) {
      this.clearSelectLinkData();

      if (this.propsActiveName) {
        this.activeName = this.propsActiveName ? this.propsActiveName : 'resource';
        this.activeTab = this.tabsObj[this.activeName];

        if (this.propsActiveName === 'audioBook' && this.audioBookOpenId) {
          this.handleAudioBookOpenId();
        }

        this.resource.resourceType = 1;

        if (this.rememberCurrent && storeData) {
          this.putStore();
        }
      }

      if (!this.propsActiveName) {
        if (storeData && storeData.remember) {
          this.activeName = storeData.activeName ? storeData.activeName : 'resource';
          var noActive = ['vocabulary', 'word', 'character'];

          if (!this.isExtend && noActive.includes(storeData.activeName)) {
            this.activeName = 'resource';
          }

          if (!storeData[this.activeName]) {
            this.resource.resourceType = 1;
            this.activeTab = this.tabsObj[this.activeName];
            this.handleClick(true);
            this.putStore(undefined);
            return;
          }

          if (this.activeName === 'resource') {
            this.resource.resourceType = storeData.resource.resourceType;
          }

          this.putStore(storeData[this.activeName]);
        } else {
          this.activeName = 'resource';
        }
      }

      this.activeTab = this.tabsObj[this.activeName];
      this.handleClick(true);
    },
    initData: function initData() {
      this.clearSelectLinkData();
      var val = this.value;
      this.refreshResource = true;
      this.refreshProblem = true;
      this.refreshLink = true;
      this.refreshPage = true;
      this.refreshCoupon = true;
      this.refreshMicro = true;
      this.refreshPartnerMicro = true;
      this.refreshAudioBook = true;
      this.refreshPaper = true;

      var storeData = _main.default.get('admin_select_link_operation');

      if (storeData) {
        this.rememberCurrent = storeData.remember;
      }

      if (this.multipleCheck) {
        this.multipleInitData(val, storeData);
        return;
      }

      if (!val) {
        this.handleNoData(storeData);
        return;
      }

      if (!val.hasOwnProperty('type')) {
        this.handleNoData(storeData);
      } else {
        this.linkTitle = val.display.title; // 作品

        if (val.type === 'OpenId' && val.key.keyType === 'resource') {
          // 点学书到具体页面
          if (val.key.resourceType === 3 && (this.propsActiveName === 'audioBook' || val.key.sequence || val.key.pageId)) {
            // 再次判断是否是回显 存在openId是 不存在否
            if (!val.key.openId) {
              this.handleAudioBookOpenId();
              return;
            }

            this.audioLink.bookOpenId = val.key.openId;
            this.audioLink.sequence = val.key.sequence;
            this.audioLink.bookTitle = val.display.title;
            this.audioLink.id = val.key.pageId;
            this.audioLink.cover = val.display.cover;
            this.audioLink.resourceEncode = val.key.resourceEncode;
            this.activeName = 'audioBook';
            this.activeTab = this.tabsObj[this.activeName];

            if (this.rememberCurrent && storeData) {
              this.putStore(storeData.audioBook);
            }

            return;
          }

          this.refreshResource = true;
          this.activeName = 'resource';
          this.resource.resourceOpenId = val.key.openId;
          this.resource.resourceType = val.key.resourceType;
          this.resource.cover = val.display.cover;
          this.resource.resourceEncode = val.key.resourceEncode;
        } // 常见问题


        if (val.type === 'Id' && val.key.keyType === 'problem') {
          this.activeName = 'problem';
          this.commonProblem.id = val.key.id;
        } // 外部链接


        if (val.type === 'Link') {
          this.activeName = 'link';
          this.externalLinks.linkHref = val.key.url;
          this.externalLinks.linkTitle = val.display.title;
          this.externalLinks.openInBrowser = val.key.openInBrowser === true ? val.key.openInBrowser : false;
        } // 应用页面


        if (val.type === 'Page') {
          this.activeName = 'page';
          this.appPageVal.pageName = val.display.title;
          this.appPageVal.pageCode = val.key.pageCode;
        } // 特殊链接


        if (val.type === 'Special') {
          this.activeName = 'special';
          this.special.specialPageLink = val.key.name;
        } // 优惠券


        if (val.type === 'OpenId' && val.key.keyType === 'Coupon') {
          this.activeName = 'coupon';
          this.coupon.title = val.display.title;
          this.coupon.openId = val.key.openId;
        } // 微页


        if (val.type === 'OpenId' && val.key.keyType === 'micropage') {
          this.activeName = val.key.partnerOpenId.toString() === '0' ? 'microPage' : 'partnerMicroPage';
          this.microPage.partnerOpenId = val.key.partnerOpenId.toString();
          this.microPage.microPageOpenId = val.key.microPageOpenId;
        } // 小程序


        if (val.type === 'Applet') {
          this.activeName = 'applet';
          this.applet.id = val.key.id;
          this.applet.name = val.key.name;
          this.applet.url = val.key.url;
          this.applet.title = val.display.title;
          this.applet.appSecret = val.key.appSecret;
          this.applet.appId = val.key.appId;
          this.applet.query = val.key.query;
        } // 试卷


        if (val.type === 'OpenId' && val.key.keyType === 'paper') {
          this.activeName = 'paper';
          this.paper.title = val.display.title;
          this.paper.examPaperOpenId = val.key.openId;
          this.paper.answerMould = val.key.answerMould;
          this.paper.paperType = val.key.paperType;
          this.resource.resourceEncode = val.key.resourceEncode;
        }

        if (val.type === 'Word' || val.type === 'Vocabulary' || val.type === 'Character') {
          this.activeName = val.type.toLowerCase();
          console.log(this.activeName);
          this.wordData = {
            list: val.key.list,
            toolType: val.key.toolType
          };
        }

        this.activeTab = this.tabsObj[this.activeName];
        this.handleClick(true);

        if (this.rememberCurrent && storeData) {
          this.putStore(storeData[this.activeName]);
        }
      }
    },
    // 记住当前操作
    handleRemember: function handleRemember(val) {
      if (val) {
        this.putStore(this.searchData);
      } else {
        this.putStore();
      }
    },
    putStore: function putStore(searchData) {
      this.searchData = searchData;

      var storeData = _main.default.get('admin_select_link_operation');

      var query = {
        remember: this.rememberCurrent,
        activeName: !this.rememberCurrent ? 'resource' : this.activeName
      };

      if (this.rememberCurrent) {
        query = _objectSpread(_objectSpread({}, storeData), query);
        query[this.activeName] = searchData;
      }

      _main.default.put('admin_select_link_operation', query);
    },
    // 全部置为空
    clearSelectLinkData: function clearSelectLinkData() {
      this.linkTitle = undefined;
      this.special.specialPageLink = undefined;
      this.commonProblem.id = undefined;
      this.externalLinks.linkHref = undefined;
      this.externalLinks.linkTitle = undefined;
      this.externalLinks.openInBrowser = false;
      this.special.specialPageLink = undefined;
      this.appPageVal.pageCode = undefined;
      this.appPageVal.pageName = undefined;
      this.resource.resourceType = undefined;
      this.resource.resourceOpenId = undefined;
      this.resource.resourceEncode = undefined;
      this.resource.cover = undefined;
      this.coupon.title = undefined;
      this.coupon.openId = undefined;
      this.multipleList = [];
      this.audioLink.bookOpenId = undefined;
      this.audioLink.sequence = undefined;
      this.audioLink.bookTitle = undefined;
      this.audioLink.id = undefined;
      this.audioLink.cover = undefined;
      this.audioLink.resourceEncode = undefined;
      this.microPage.microPageOpenId = undefined;
      this.microPage.partnerOpenId = undefined;
      this.applet.id = undefined;
      this.applet.name = undefined;
      this.applet.title = undefined;
      this.applet.url = undefined;
      this.applet.appSecret = undefined;
      this.applet.appId = undefined;
      this.applet.query = undefined;
      this.paper.title = undefined;
      this.paper.examPaperOpenId = undefined;
      this.paper.answerMould = undefined;
      this.paper.paperType = undefined;
      this.paper.resourceEncode = undefined;
      this.multipleVal = [];
      this.multipleObj = {};

      if (this.$refs.selectResource) {
        this.$refs.selectResource.clearSelectResource();
      }
    },
    // 资源
    handleResource: function handleResource(val, type) {
      if (!val || JSON.stringify(val) === '{}') return;

      if (this.multipleCheck && val.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(val);
        return;
      }

      this.linkTitle = val.title;
      this.resource.resourceType = val.resourceType ? val.resourceType : Number(type);
      this.resource.resourceOpenId = val.resourceOpenId;
      this.resource.cover = val.cover;
      this.resource.resourceEncode = val.resourceEncode;
    },
    // 常见问题
    handleProblem: function handleProblem(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      if (this.multipleCheck && val.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(val);
        return;
      }

      this.commonProblem.id = val.id;
      this.linkTitle = val.problemTitle;
    },
    // 优惠券
    handleCoupon: function handleCoupon(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      if (this.multipleCheck && val.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(val);
        return;
      }

      this.coupon.title = val.title;
      this.coupon.openId = val.couponOpenId;
    },
    // 微页
    handleMicroPage: function handleMicroPage(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      if (this.multipleCheck && val.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(val);
        return;
      }

      this.linkTitle = val.title;
      this.microPage.partnerOpenId = val.partnerOpenId;
      this.microPage.microPageOpenId = val.microPageOpenId;
    },
    handleWarning: function handleWarning() {
      var isLink = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      // 外部链接
      if (isLink) {
        return this.$notify({
          title: '请填写完整的外部链接内容',
          message: '',
          type: 'warning'
        });
      } // 非外部链接


      this.$notify({
        title: '请选择链接',
        message: '',
        type: 'warning'
      });
    },
    // 应用页面
    handleAppPage: function handleAppPage(currentRow) {
      if (!currentRow || JSON.stringify(currentRow) === '{}') {
        return;
      }

      if (this.multipleCheck && currentRow.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(currentRow);
        return;
      }

      this.appPageVal.pageCode = currentRow.pageCode;
      this.appPageVal.pageName = currentRow.pageName;
      this.linkTitle = currentRow.pageName;
    },
    handleAudioBook: function handleAudioBook(val) {
      this.audioLink = _objectSpread({}, val);
    },
    // 试卷
    handlePaper: function handlePaper(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      if (this.multipleCheck && val.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(val);
        return;
      }

      this.paper.name = val.name;
      this.paper.examPaperOpenId = val.examPaperOpenId;
      this.paper.answerMould = val.answerMould;
      this.paper.paperType = val.paperType;
      this.paper.resourceEncode = val.resourceEncode;
    },
    // 确定
    handleSubmit: function handleSubmit() {
      var query = {}; // 处理小程序

      if (this.activeName === 'applet') {
        var validForm = false;
        this.$refs.applet.$refs.appletForm.validate(function (valid) {
          validForm = valid;
        });

        if (!validForm) {
          return;
        }
      }

      if (this.multipleCheck) {
        var multipleList = this.multipleList; // 替换只能有一个

        var replace = ['resource', 'problem', 'page', 'coupon', 'microPage', 'partnerMicroPage', 'paper'];

        if (this.isReplace && replace.includes(this.activeName)) {
          if (multipleList.length > 1) {
            return this.$notify({
              title: '提示',
              message: '替换操作最多只能选择一个',
              type: 'warning'
            });
          }
        }

        if (this.activeName === 'special') {
          multipleList = this.special.list;
        }

        if (this.activeName === 'audioBook') {
          multipleList = [];
          multipleList.push(this.audioLink);
        }

        if (this.activeName === 'link') {
          multipleList = [];
          multipleList.push(this.externalLinks);
        }

        if (this.activeName === 'applet') {
          multipleList = [];
          multipleList.push(this.applet);
        }

        if (this.activeName === 'word' || this.activeName === 'character' || this.activeName === 'vocabulary') {
          multipleList = this.wordData;
        }

        var multipleQuery = {
          activeName: this.activeName,
          multipleList: multipleList
        };
        this.$emit('submit', multipleQuery);
        return;
      }

      if (this.activeName === 'resource') {
        if (!this.resource.resourceOpenId) {
          this.handleWarning();
          return;
        }

        if (this.resource.resourceType === 3) {
          query = {
            type: 'OpenId',
            key: {
              keyType: 'resource',
              resourceType: this.resource.resourceType,
              openId: this.resource.resourceOpenId,
              sequence: '',
              pageId: '',
              resourceEncode: this.resource.resourceEncode // 作品附件需要显示

            },
            display: {
              title: this.linkTitle,
              cover: this.resource.cover
            }
          };
        } else {
          // 处理返回
          query = {
            type: 'OpenId',
            key: {
              keyType: 'resource',
              resourceType: this.resource.resourceType,
              openId: this.resource.resourceOpenId,
              resourceEncode: this.resource.resourceEncode // 作品附件需要显示

            },
            display: {
              title: this.linkTitle,
              cover: this.resource.cover
            }
          };
        }
      }

      if (this.activeName === 'problem') {
        if (!this.commonProblem.id) {
          this.handleWarning();
          return;
        }

        this.linkCommonProblem = {
          id: this.commonProblem.id
        };
        query = {
          type: 'Id',
          key: {
            keyType: 'problem',
            id: this.commonProblem.id
          },
          display: {
            title: this.linkTitle
          }
        };
      }

      if (this.activeName === 'link') {
        if (!this.externalLinks.linkHref || !this.externalLinks.linkTitle) {
          this.handleWarning(true);
          return;
        }

        this.linkHref = this.externalLinks.linkHref;
        query = {
          type: 'Link',
          key: {
            url: this.externalLinks.linkHref,
            openInBrowser: this.externalLinks.openInBrowser
          },
          display: {
            title: this.externalLinks.linkTitle
          }
        };
      }

      if (this.activeName === 'page') {
        if (!this.appPageVal.pageCode) {
          this.handleWarning();
          return;
        }

        this.pageLink = this.appPageVal;
        query = {
          type: 'Page',
          key: {
            pageCode: this.appPageVal.pageCode
          },
          display: {
            title: this.appPageVal.pageName
          }
        };
      }

      if (this.activeName === 'special') {
        if (!this.special.specialPageLink) {
          this.handleWarning();
          return;
        }

        this.specialPageLink = this.special.specialPageLink;
        query = {
          type: 'Special',
          key: {
            name: this.special.specialPageLink
          },
          display: {
            title: this.special.specialPageLink
          }
        };
      }

      if (this.activeName === 'coupon') {
        if (!this.coupon.openId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'Coupon',
            openId: this.coupon.openId
          },
          display: {
            title: this.coupon.title
          }
        };
      }

      if (this.activeName === 'microPage' || this.activeName === 'partnerMicroPage') {
        if (!this.microPage.microPageOpenId || !this.microPage.partnerOpenId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'micropage',
            partnerOpenId: this.microPage.partnerOpenId,
            microPageOpenId: this.microPage.microPageOpenId
          },
          display: {
            title: this.linkTitle
          }
        };
      }

      if (this.activeName === 'audioBook') {
        if (!this.audioLink.bookOpenId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'resource',
            resourceType: 3,
            openId: this.audioLink.bookOpenId,
            sequence: this.audioLink.sequence,
            pageId: this.audioLink.id,
            imgUrl: this.audioLink.imgUrl,
            resourceEncode: this.audioLink.resourceEncode // 作品附件需要显示

          },
          display: {
            title: this.audioLink.bookTitle,
            cover: this.audioLink.cover
          }
        };
      }

      if (this.activeName === 'applet') {
        query = {
          type: 'Applet',
          // 小程序
          key: {
            id: this.applet.id,
            // 原始id
            appId: this.applet.appId,
            // 小程序id
            appSecret: this.applet.appSecret,
            // appSecret
            name: this.applet.name,
            // 页面名称
            url: this.applet.url,
            // 小程序路径
            query: this.applet.query
          },
          display: {
            title: this.applet.title // 小程序名称

          }
        };
      }

      if (this.activeName === 'paper') {
        if (!this.paper.examPaperOpenId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'paper',
            openId: this.paper.examPaperOpenId,
            answerMould: this.paper.answerMould,
            // 1 是考题必刷
            paperType: this.paper.paperType,
            // 试卷类型
            resourceEncode: this.paper.resourceEncode // 作品附件需要显示

          },
          display: {
            title: this.paper.name
          }
        };
      }

      if (this.activeName === 'word' || this.activeName === 'character' || this.activeName === 'vocabulary') {
        var type;

        if (this.activeName === 'word') {
          type = 'Word';
        }

        if (this.activeName === 'character') {
          type = 'Character';
        }

        if (this.activeName === 'vocabulary') {
          type = 'Vocabulary';
        }

        var linkTitle = this.wordData.list.map(function (item) {
          return item.name;
        });
        query = {
          type: type,
          key: {
            toolType: this.wordData.toolType,
            list: this.wordData.list
          },
          display: {
            title: linkTitle.length > 0 ? linkTitle.join('、') : undefined
          }
        };
      }

      this.valueProxy = query;

      if (this.isNotModel) {
        this.$emit('submitLink', query);
      }

      this.$emit('update:visible', false);
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;