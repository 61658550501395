var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
      staticClass: "resource",
      attrs: {
        visible: _vm.dialogResourceVisible,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        width: "750px",
        title: "关联点学资源"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogResourceVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.section,
            "label-width": "95px",
            "label-position": "right"
          }
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "resource-tab",
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleChange },
              model: {
                value: _vm.resourceType,
                callback: function($$v) {
                  _vm.resourceType = $$v
                },
                expression: "resourceType"
              }
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "audio-pane",
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 1,
                    label: "音频",
                    name: "1"
                  }
                },
                [
                  _c("el-form-item", [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*")
                      ]),
                      _vm._v("音频文件：")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item-bg" }, [
                      _c(
                        "div",
                        { staticClass: "upload-bg" },
                        [
                          _vm.hackReset
                            ? _c("upload-file", {
                                ref: "uploadAudio",
                                style: _vm.section.audioAliVid
                                  ? "width:353px;"
                                  : "",
                                attrs: {
                                  type: _vm.audioType,
                                  "audio-id": _vm.audioAliVid
                                },
                                on: {
                                  startUpload: _vm.startUpload,
                                  getResult: _vm.getUploader
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.section.audioAliVid
                        ? _c("i", {
                            staticClass: "el-icon-copy-document copy-button",
                            on: {
                              click: function($event) {
                                return _vm.copyUrl(
                                  _vm.section.audioAliVid,
                                  $event
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "展示形式" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.handleRadioChange },
                          model: {
                            value: _vm.section.audioShowType,
                            callback: function($$v) {
                              _vm.$set(_vm.section, "audioShowType", $$v)
                            },
                            expression: "section.audioShowType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "dialogue" } }, [
                            _vm._v("对话字幕")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "pic" } }, [
                            _vm._v("音频配图")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "capt" } }, [
                            _vm._v("音频配字幕")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.section.audioShowType === "dialogue"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "95px",
                            "margin-bottom": "20px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "audioContent-trans",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 4 },
                              placeholder: "请输入对话字幕（原翻译）"
                            },
                            model: {
                              value: _vm.section.audioContentTrans,
                              callback: function($$v) {
                                _vm.$set(_vm.section, "audioContentTrans", $$v)
                              },
                              expression: "section.audioContentTrans"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.section.audioShowType === "capt"
                    ? _c(
                        "div",
                        {
                          staticClass: "audio-lrc",
                          staticStyle: {
                            "margin-left": "95px",
                            "margin-bottom": "20px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "audioContent-trans",
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入音频字幕",
                              autosize: { minRows: 4, maxRows: 4 },
                              clearable: ""
                            },
                            model: {
                              value: _vm.section.audioLrcUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.section, "audioLrcUrl", $$v)
                              },
                              expression: "section.audioLrcUrl"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "show-sub-title" },
                            [
                              _vm._v(
                                "\n              是否页面上显示字幕\n              "
                              ),
                              _c("el-switch", {
                                staticClass: "show-sub-title-switch",
                                attrs: { "active-color": "#13ce66" },
                                model: {
                                  value: _vm.section.showSubtitle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.section, "showSubtitle", $$v)
                                  },
                                  expression: "section.showSubtitle"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.section.audioShowType === "pic"
                    ? _c(
                        "div",
                        { staticClass: "audio-picture" },
                        [
                          _c("cover-upload", {
                            staticClass: "cover-upload",
                            attrs: {
                              path: _vm.path,
                              type: "3",
                              coverarr: _vm.coverArr,
                              coverisshow: false,
                              covertext: ""
                            },
                            on: { deletecover: _vm.removeCover },
                            model: {
                              value: _vm.section.picturePath,
                              callback: function($$v) {
                                _vm.$set(_vm.section, "picturePath", $$v)
                              },
                              expression: "section.picturePath"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "参与连读：" } }, [
                    _c(
                      "div",
                      { staticClass: "continuity-play" },
                      [
                        _c("el-switch", {
                          attrs: { "active-color": "#13ce66" },
                          model: {
                            value: _vm.section.continuityPlay,
                            callback: function($$v) {
                              _vm.$set(_vm.section, "continuityPlay", $$v)
                            },
                            expression: "section.continuityPlay"
                          }
                        }),
                        _vm._v(" "),
                        _vm.section.continuityPlay
                          ? _c("span", { staticClass: "end-reading" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.section.continuityPlay
                          ? _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.section.endReading,
                                  callback: function($$v) {
                                    _vm.$set(_vm.section, "endReading", $$v)
                                  },
                                  expression: "section.endReading"
                                }
                              },
                              [_vm._v("结束连读")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.section.continuityPlay
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "结束连读尽量设置在每课时最后一个可连读选区",
                                  placement: "right"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-partner info-icon"
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "仅VIP可用：" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.section.vipOnly,
                          callback: function($$v) {
                            _vm.$set(_vm.section, "vipOnly", $$v)
                          },
                          expression: "section.vipOnly"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 2,
                    label: "视频",
                    name: "2"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "video-box" },
                    [
                      _c(
                        "div",
                        { staticClass: "check-box" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "3" },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("关联视频")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("本地上传")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("VID上传")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isVidUpload === "1",
                              expression: "isVidUpload === '1'"
                            }
                          ],
                          staticClass: "upload-video"
                        },
                        [
                          _c("div", { staticClass: "display-flex" }, [
                            _c("div", { staticClass: "form-item-bg" }, [
                              _c(
                                "div",
                                { staticClass: "upload-bg" },
                                [
                                  _vm.hackReset
                                    ? _c("upload-file", {
                                        ref: "uploadVideo",
                                        style: _vm.videoAliVid
                                          ? "width:353px"
                                          : "",
                                        attrs: {
                                          type: _vm.videoType,
                                          "audio-id": _vm.videoAliVid
                                        },
                                        on: {
                                          startUpload: _vm.startVideoUpload,
                                          getResult: _vm.getVideo
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.videoAliVid
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-copy-document copy-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.copyUrl(
                                          _vm.section.videoAliVid,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "video-duration-text" }, [
                              _c("span", { staticClass: "video-text" }, [
                                _c("i", {
                                  staticClass: "el-icon-info",
                                  staticStyle: { color: "#409EFF" }
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                      视频名称限45字以内，格式支持MP4，大小不超过2G，建议视频分辨率为1280×720\n                    "
                                  )
                                ])
                              ])
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isVidUpload === "2"
                        ? _c("div", [
                            _c("div", { staticClass: "input-vid-bg" }, [
                              _c(
                                "div",
                                { staticClass: "input-vid-box" },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入视频vid" },
                                    model: {
                                      value: _vm.section.videoAliVid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.section,
                                          "videoAliVid",
                                          $$v
                                        )
                                      },
                                      expression: "section.videoAliVid"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn-copy",
                                  attrs: {
                                    "data-clipboard-text":
                                      _vm.section.videoAliVid
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.copyUrl(
                                        _vm.section.videoAliVid,
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isVidUpload === "3"
                        ? _c("div", { staticClass: "video-album-bg" }, [
                            _c("div", { staticClass: "input-vid-bg" }, [
                              _c(
                                "div",
                                { staticClass: "input-vid-box" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled: true,
                                        placeholder: "请选择视频"
                                      },
                                      model: {
                                        value: _vm.section.videoAliVid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.section,
                                            "videoAliVid",
                                            $$v
                                          )
                                        },
                                        expression: "section.videoAliVid"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "padding-right": "10px"
                                          },
                                          attrs: {
                                            slot: "suffix",
                                            type: "text"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openSelectVideo()
                                            }
                                          },
                                          slot: "suffix"
                                        },
                                        [_vm._v("选择")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn-copy",
                                  attrs: {
                                    "data-clipboard-text":
                                      _vm.section.videoAliVid
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.copyUrl(
                                        _vm.section.videoAliVid,
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "video-album-bg" },
                        [
                          _c("div", { staticClass: "video-album-title" }, [
                            _vm._v("相关专辑")
                          ]),
                          _vm._v(" "),
                          _c("select-album", {
                            attrs: {
                              commodityOpenId: _vm.section.relationAlbumOpenId
                            },
                            on: { getAlbumOpenId: _vm.getAlbumOpenId }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仅VIP可用：" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce66" },
                            model: {
                              value: _vm.section.vipOnly,
                              callback: function($$v) {
                                _vm.$set(_vm.section, "vipOnly", $$v)
                              },
                              expression: "section.vipOnly"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  staticClass: "audio-pane",
                  attrs: {
                    label: "图片",
                    name: "19",
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 19
                  }
                },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.section, "label-width": "90px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "展示形式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.imgType,
                                callback: function($$v) {
                                  _vm.imgType = $$v
                                },
                                expression: "imgType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("纵向排列")
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("横向轮播")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*")
                              ]),
                              _vm._v("图片")
                            ]
                          ),
                          _vm._v(" "),
                          _c("multi-image-upload", {
                            attrs: {
                              path: "image/",
                              "cover-arr": ["72", "72"],
                              "cover-text":
                                "图片小于5M，.jpg、.png、.gif格式。",
                              "text-tips": "图片上传",
                              "is-show-mask": true,
                              "limit-length": 18,
                              "is-disable-upload": _vm.imageList.length >= 18,
                              type: _vm.AUDIOBOOK_ID.toString()
                            },
                            model: {
                              value: _vm.imageList,
                              callback: function($$v) {
                                _vm.imageList = $$v
                              },
                              expression: "imageList"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仅VIP可用" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce66" },
                            model: {
                              value: _vm.section.vipOnly,
                              callback: function($$v) {
                                _vm.$set(_vm.section, "vipOnly", $$v)
                              },
                              expression: "section.vipOnly"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 3,
                    label: "链接",
                    name: "3"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "url-wrapper" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "url-title-bg",
                          attrs: { label: "1" },
                          on: { change: _vm.linkTypeChange },
                          model: {
                            value: _vm.choiceUrl,
                            callback: function($$v) {
                              _vm.choiceUrl = $$v
                            },
                            expression: "choiceUrl"
                          }
                        },
                        [_vm._v("跳转链接")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "url-content-bg" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "网站链接" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    id: "link-input",
                                    disabled: _vm.choiceUrl === "2",
                                    placeholder: "请输入网站链接"
                                  },
                                  model: {
                                    value: _vm.urlObj.url,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.urlObj,
                                        "url",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "urlObj.url"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "padding-right": "10px" },
                                      attrs: {
                                        slot: "suffix",
                                        disabled: _vm.choiceUrl === "2",
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectLinkClick()
                                        }
                                      },
                                      slot: "suffix"
                                    },
                                    [_vm._v("选择")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "APP路由" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: true,
                                    placeholder: "请输入APP路由"
                                  },
                                  model: {
                                    value: _vm.showRouterName,
                                    callback: function($$v) {
                                      _vm.showRouterName = $$v
                                    },
                                    expression: "showRouterName"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "padding-right": "10px" },
                                      attrs: {
                                        slot: "suffix",
                                        disabled: _vm.choiceUrl === "2",
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectRouterClick()
                                        }
                                      },
                                      slot: "suffix"
                                    },
                                    [_vm._v("选择")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "padding-right": "10px" },
                                      attrs: {
                                        slot: "suffix",
                                        disabled: _vm.choiceUrl === "2",
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteRouterClick()
                                        }
                                      },
                                      slot: "suffix"
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "book-page-wrapper" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: { change: _vm.linkTypeChange },
                              model: {
                                value: _vm.choiceUrl,
                                callback: function($$v) {
                                  _vm.choiceUrl = $$v
                                },
                                expression: "choiceUrl"
                              }
                            },
                            [_vm._v("图书页码")]
                          ),
                          _vm._v(" "),
                          _c("select-page", {
                            ref: "selectPageDialog",
                            attrs: {
                              linkItem: _vm.linkItem,
                              canClickSelectPageBtn: _vm.isClickSelectPageBtn()
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 12,
                    label: "内容",
                    name: "12"
                  }
                },
                [
                  _vm.pitchOn === "12"
                    ? _c("default-content", {
                        attrs: {
                          type: "内容",
                          "default-value": _vm.sectionData
                        },
                        on: { changeDefault: _vm.deleteAll }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 13,
                    label: "字库",
                    name: "13"
                  }
                },
                [
                  _vm.pitchOn === "13"
                    ? _c("default-content", {
                        attrs: {
                          type: "字库",
                          "default-value": _vm.sectionData,
                          toolType: _vm.sectionObj.toolType
                        },
                        on: {
                          changeToolType: _vm.changeToolType,
                          changeDefault: _vm.deleteAll
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 14,
                    label: "词库",
                    name: "14"
                  }
                },
                [
                  _vm.pitchOn === "14"
                    ? _c("default-content", {
                        attrs: {
                          type: "词库",
                          "default-value": _vm.sectionData,
                          toolType: _vm.sectionObj.toolType
                        },
                        on: {
                          changeToolType: _vm.changeToolType,
                          changeDefault: _vm.deleteAll
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 16,
                    label: "单词库",
                    name: "16"
                  }
                },
                [
                  _vm.pitchOn === "16"
                    ? _c("default-content", {
                        attrs: {
                          type: "单词库",
                          "default-value": _vm.sectionData,
                          toolType: _vm.sectionObj.toolType
                        },
                        on: {
                          changeToolType: _vm.changeToolType,
                          changeDefault: _vm.deleteAll
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 17,
                    label: "试题",
                    name: "17"
                  }
                },
                [
                  _vm.pitchOn === "17"
                    ? _c("default-content", {
                        attrs: {
                          type: "试题",
                          "from-type": "audioBook",
                          "tool-type": _vm.sectionObj.toolType,
                          "default-value": _vm.sectionData
                        },
                        on: {
                          changeToolType: _vm.changeToolType,
                          changeDefault: _vm.deleteAll
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    disabled:
                      !_vm.dialogStatus && _vm.section.resourceType !== 18,
                    label: "试卷",
                    name: "18"
                  }
                },
                [
                  _vm.pitchOn === "18"
                    ? _c("default-content", {
                        attrs: {
                          type: "试卷",
                          "from-type": "audioBook",
                          "tool-type": _vm.sectionObj.toolType,
                          "default-value": _vm.sectionData
                        },
                        on: {
                          changeToolType: _vm.changeToolType,
                          changeDefault: _vm.deleteAll
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.cancleOperation()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.saveState, type: "primary" },
              on: {
                click: function($event) {
                  return _vm.saveSection()
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.openSelectLink },
        on: {
          "update:visible": function($event) {
            _vm.openSelectLink = $event
          }
        },
        model: {
          value: _vm.selectInfo,
          callback: function($$v) {
            _vm.selectInfo = $$v
          },
          expression: "selectInfo"
        }
      }),
      _vm._v(" "),
      _vm.showSelectResDialog
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.showSelectResDialog,
              "expect-type": "analysisVideo"
            },
            on: {
              "update:visible": function($event) {
                _vm.showSelectResDialog = $event
              },
              "add-select": _vm.getSelect
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }