"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'PageSectionSequence',
  props: {
    activeStatus: {
      required: false,
      default: false,
      type: Boolean
    },
    linkSection: {
      required: true,
      type: Object
    }
  }
};
exports.default = _default;