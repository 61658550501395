"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _components = require("./components");

var _Subject = _interopRequireDefault(require("./components/Subject.vue"));

var _Grade = _interopRequireDefault(require("./components/Grade.vue"));

var _Edition = _interopRequireDefault(require("./components/Edition.vue"));

var _Volume = _interopRequireDefault(require("./components/Volume.vue"));

var _Period = _interopRequireDefault(require("./components/Period.vue"));

var _PostCompany = _interopRequireDefault(require("./components/PostCompany.vue"));

var _UserTag = _interopRequireDefault(require("./components/UserTag.vue"));

var _pageSetting = _interopRequireDefault(require("./components/pageSetting.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dictonary',
  components: {
    PostCompany: _PostCompany.default,
    AppBackend: _components.AppBackend,
    AppProduct: _components.AppProduct,
    AppSocial: _components.AppSocial,
    ResourceType: _components.ResourceType,
    LearningSection: _components.LearningSection,
    Subject: _Subject.default,
    Grade: _Grade.default,
    Edition: _Edition.default,
    Volume: _Volume.default,
    Period: _Period.default,
    School: _components.School,
    Provinces: _components.Provinces,
    BookArea: _components.BookArea,
    UserTag: _UserTag.default,
    PageSetting: _pageSetting.default,
    OptionalRequired: _components.OptionalRequired
  },
  data: function data() {
    return {
      table_name: '微服务应用表'
    };
  },
  methods: {
    selectTabs: function selectTabs(tab) {
      this.table_name = tab.label;
    }
  }
};
exports.default = _default;