"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'importDotData',
  props: {
    dataIsShow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      lrcData: ''
    };
  },
  computed: {
    visibleProxy: {
      get: function get() {
        return this.dataIsShow;
      },
      set: function set(value) {
        this.$emit('update:dataIsShow', value);
      }
    }
  },
  methods: {
    // 关闭弹窗之前先把数据清空
    handleClose: function handleClose() {
      this.lrcData = '';
      this.visibleProxy = false;
    },
    importCancel: function importCancel() {
      console.log('取消。。。。');
      this.visibleProxy = false;
      console.log(this.visibleProxy);
    },
    // 批量导入数据
    saveDotData: function saveDotData() {
      // 数据校验
      if (this.lrcData.length <= 0) {
        this.$notify({
          title: '提示',
          message: '请填写字幕文本',
          type: 'warning'
        });
        return;
      }

      console.log(this.lrcData);
      var dotList = []; // lrc数据格式第一模块听力训练#00:06|

      var lrcArr = this.lrcData.split('|');
      lrcArr.forEach(function (lrcObj) {
        var lrcItemArr = lrcObj.split('#');
        console.log('lrcItem');
        console.log(lrcItemArr);
        var dotObj = {
          videoOpenId: 'resaud202006050339jhbm71tw',
          detail: lrcItemArr[0],
          tempDetail: lrcItemArr[0],
          time: (0, _common.dateToTimestamp)(lrcItemArr[1]),
          templateTime: lrcItemArr[1]
        };
        dotList.push(dotObj);
      });
      this.$emit('getBatchDot', dotList);
      this.lrcData = '';
      this.visibleProxy = false;
    }
  }
};
exports.default = _default;