"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _Type = _interopRequireDefault(require("@/components/Resource/Selector/Type.vue"));

var _DialogFilterPanel = _interopRequireDefault(require("@/components/Resource/SelectResourceDialog/Header/DialogFilterPanel.vue"));

var _searchbtn = _interopRequireDefault(require("@/components/publictop/searchbtn.vue"));

var _search = _interopRequireDefault(require("@/components/SearchUser/search.vue"));

var _Category = _interopRequireDefault(require("@/components/Resource/Selector/Category.vue"));

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
var _default2 = {
  name: 'DialogFilterTopBar',
  components: {
    SelectParamType: _Type.default,
    SearchButton: _searchbtn.default,
    DialogFilterPanel: _DialogFilterPanel.default,
    SearchUser: _search.default,
    Category: _Category.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    expectType: {
      type: String,
      default: 'resource'
    },
    showMerchant: {
      type: Boolean,
      default: false
    },
    showRememberSelect: {
      type: Boolean,
      default: true
    },
    isOldSearch: {
      type: Boolean,
      default: false
    },
    // 是否显示原来长款搜索样式
    showTestData: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      resourceList: [{
        lable: '点读书',
        value: 3
      }, {
        lable: '视频',
        value: 1
      }, {
        lable: '音频',
        value: 2
      }, {
        lable: '专辑',
        value: 4
      }, {
        lable: '直播',
        value: 11
      }, {
        lable: '文件包',
        value: 8
      }, {
        lable: '图文',
        value: 9
      }, {
        lable: '文件',
        value: 10
      }, {
        lable: '大专辑',
        value: 16
      }, {
        lable: '英语会话',
        value: 19
      }],
      rememberSelect: true
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    typeName: function typeName() {
      var _this = this;

      var resourceObj = this.resourceList.find(function (resource) {
        return resource.value === _this.valueProxy.resourceType;
      });
      return resourceObj ? resourceObj.lable : '';
    }
  },
  watch: {
    'valueProxy.resourceType': function valueProxyResourceType() {
      this.valueProxy.type = '';
      this.$emit('search', this.rememberSelect);
    },
    'valueProxy.type': function valueProxyType() {
      this.$emit('search', this.rememberSelect);
    },
    'valueProxy.partnerOpenId': function valueProxyPartnerOpenId() {
      this.$emit('search', this.rememberSelect);
    }
  },
  methods: {
    handleRemember: function handleRemember(val) {
      _main.default.put('SELECT_RESOURCE_PARAM_CHECK', val);
    },

    /* 获取选择商户中partnerOpenId */
    receivePartnerId: function receivePartnerId(id) {
      this.$set(this.valueProxy, 'partnerOpenId', id);
    },
    searchResource: function searchResource() {
      this.valueProxy.page = 1;
      this.$emit('search', this.rememberSelect);
    }
  }
};
exports.default = _default2;