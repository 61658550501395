var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "tab-dialog-box",
      attrs: {
        width: "370px",
        title: "选择标签",
        "before-close": _vm.closeLabelManage,
        visible: _vm.innerVisible,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.innerVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "tag-box" },
        [
          _c(
            "el-radio",
            {
              attrs: { label: undefined },
              model: {
                value: _vm.tagName,
                callback: function($$v) {
                  _vm.tagName = $$v
                },
                expression: "tagName"
              }
            },
            [_vm._v("无标签")]
          ),
          _vm._v(" "),
          _vm._l(_vm.tagList, function(item) {
            return _c(
              "el-radio",
              {
                attrs: { label: item.tagName },
                model: {
                  value: _vm.tagName,
                  callback: function($$v) {
                    _vm.tagName = $$v
                  },
                  expression: "tagName"
                }
              },
              [_vm._v(_vm._s(item.tagName))]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "button-box" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.closeLabelManage } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleCommit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }