var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "errorContainer" }, [
    _c("div", [
      _c(
        "div",
        [
          _c(
            "router-link",
            { attrs: { to: "/dashboard" } },
            [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("返回")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "desText" }, [_vm._v("啊哦！")]),
      _vm._v(" "),
      _c("div", { staticClass: "noRightvisit" }, [
        _vm._v("401：当前页面暂时无权访问")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contactAdministrator" }, [
        _vm._v("请联系平台管理员进行相关授权。")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "backHome" },
        [
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.back } },
            [_vm._v("返回前页")]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "errorImage" }, [
      _c("img", {
        attrs: { src: require("@/assets/401_images/401.png"), alt: "401" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }