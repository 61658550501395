var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-specification-info" }, [
    _c("div", { staticClass: "info-line zebra-strips" }, [
      _c("div", { staticClass: "label" }, [_vm._v("规格名：")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "name-line" }, [
          _c(
            "div",
            [
              _c("input-closeable", {
                attrs: { data: _vm.data.name, width: "200px" },
                on: { change: _vm.updateName }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "el-icon-delete delete-action",
            on: { click: _vm.deleteItem }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-line" }, [
      _c("div", { staticClass: "label" }, [_vm._v("规格值：")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.valueChunked, function(i, k) {
          return _c(
            "div",
            { key: k, staticClass: "input-line" },
            _vm._l(i, function(j, l) {
              return _c("specification-value-cell", {
                key: l,
                attrs: { index: k * 4 + l, data: j.value, type: j.type },
                on: {
                  add: _vm.addValue,
                  change: _vm.updateValue,
                  close: _vm.deleteValue
                }
              })
            }),
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }