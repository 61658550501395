"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("../../../utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ResourceLiveState',
  filters: {
    parseTime: _utils.parseTime
  },
  props: {
    resourceObj: {
      type: Object,
      default: null
    },
    // 直播对象
    handlerFinishClass: {
      type: Function,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      liveStateList: [{
        label: '预告',
        value: 1,
        className: 'live-state-herald'
      }, {
        label: '即将开始',
        value: 2,
        className: 'live-state-herald'
      }, {
        label: '直播中',
        value: 3,
        className: 'live-ing'
      }, {
        label: '已结束',
        value: 4,
        className: 'live-end'
      }, {
        label: '回放',
        value: 5,
        className: 'live-replay'
      }]
    };
  },
  methods: {
    isShowFinishClassText: function isShowFinishClassText(liveObj) {
      var isShow = false;

      if (liveObj.liveStateNum === 3) {
        isShow = true;

        if (liveObj.longTerm === 1 && !liveObj.showInCenter) {
          isShow = false;
        } else if (liveObj.longTerm === 0 && liveObj.endDelayTime === 0) {
          isShow = false;
        } else {
          isShow = true;
        }
      }

      return isShow;
    },
    // 判断直播状态
    getLiveObj: function getLiveObj(liveObj) {
      var liveStateObj = this.liveStateList.filter(function (live) {
        return live.value === liveObj.liveStateNum;
      });
      return liveStateObj ? liveStateObj[0] : {};
    }
  }
};
exports.default = _default2;