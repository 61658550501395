var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commission-wrapper" },
    [
      _c("div", { staticClass: "user-info-box" }, [
        _c(
          "div",
          { staticClass: "user-info-top" },
          [
            _c("el-image", {
              staticClass: "user-cover",
              attrs: {
                onerror: _vm.errorUserPhoto,
                src: _vm.getImgUrl(_vm.userInfo.cover, _vm.thumbnailStyle),
                alt: ""
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "user-info-id" }, [
              _c("div", { staticClass: "user-info-name" }, [
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.userInfo.nickName))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-promotion-bg" }, [
                _vm.userInfo.userOpenId && _vm.userInfo.invitationCode
                  ? _c("span", { staticClass: "normal-font" }, [
                      _vm._v(
                        "\n             用户ID：" +
                          _vm._s(_vm.userInfo.userOpenId) +
                          " | 推广码：" +
                          _vm._s(_vm.userInfo.invitationCode) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "commission-record-box" }, [
          _c("div", { staticClass: "commission-record-bg" }, [
            _c("div", { staticClass: "commission-record-price" }, [
              _vm._v(
                _vm._s(
                  _vm.commissionInfo.total
                    ? _vm.numFormat((_vm.commissionInfo.total / 100).toFixed(2))
                    : "0.00"
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commission-record-title" }, [
              _vm._v("累计佣金（元）")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commission-record-bg" }, [
            _c("div", { staticClass: "commission-record-price" }, [
              _vm._v(_vm._s(_vm.numFormat((_vm.withdrawal / 100).toFixed(2))))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commission-record-title" }, [
              _vm._v("已提现佣金（元）")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commission-record-bg" }, [
            _c("div", { staticClass: "commission-record-price" }, [
              _vm._v(
                _vm._s(
                  _vm.commissionInfo.balance
                    ? _vm.numFormat(
                        (_vm.commissionInfo.balance / 100).toFixed(2)
                      )
                    : "0.00"
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commission-record-title" }, [
              _vm._v("可提现佣金（元）")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "record-info-title" }, [
        _c("div", { staticClass: "record-title" }, [_vm._v("佣金余额记录")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "record-operation" },
          [
            _c("el-date-picker", {
              staticClass: "pay-date-bg",
              attrs: {
                type: "daterange",
                "default-time": ["00:00:00", "23:59:59"],
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "value-format": "yyyy-MM-dd HH:mm:ss"
              },
              model: {
                value: _vm.createdTime,
                callback: function($$v) {
                  _vm.createdTime = $$v
                },
                expression: "createdTime"
              }
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "search-state-bg",
                model: {
                  value: _vm.searchQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.searchQuery, "status", $$v)
                  },
                  expression: "searchQuery.status"
                }
              },
              _vm._l(_vm.stateList, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.title, value: item.value }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "record-table-bg" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.promotionRecordLoading,
                  expression: "promotionRecordLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.recordList, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { width: "140", label: "时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.createdAt
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      scope.row.createdAt,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [_vm._v("--")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "40", label: "事项" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.remark) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "40", label: "入账 / 支出 (元)" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          ￥" +
                            _vm._s(
                              _vm.numFormat(
                                (scope.row.changePrice / 100).toFixed(2)
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "40", label: "变动前余额 (元)" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          ￥" +
                            _vm._s(
                              _vm.numFormat(
                                (
                                  (scope.row.balance - scope.row.changePrice) /
                                  100
                                ).toFixed(2)
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "40", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          { class: scope.row.status === 2 ? "refund" : "" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("getStateTitle")(
                                  scope.row.status,
                                  _vm.stateList
                                )
                              )
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "60", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toLookOrder(scope.row.orderId)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-page-box" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.searchQuery.page,
              limit: _vm.searchQuery.size,
              "current-page": _vm.searchQuery.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.searchQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.searchQuery, "size", $event)
              },
              pagination: _vm.getCommissionRecord
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("order-detail", {
        staticClass: "orderDetailWrap",
        attrs: { visible: _vm.orderDetailDialog, orderId: _vm.currentorderId },
        on: {
          "update:visible": function($event) {
            _vm.orderDetailDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }