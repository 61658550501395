import { render, staticRenderFns } from "./Subject.vue?vue&type=template&id=4332ab82&scoped=true&"
import script from "./Subject.vue?vue&type=script&lang=js&"
export * from "./Subject.vue?vue&type=script&lang=js&"
import style0 from "./Subject.vue?vue&type=style&index=0&id=4332ab82&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4332ab82",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4332ab82')) {
      api.createRecord('4332ab82', component.options)
    } else {
      api.reload('4332ab82', component.options)
    }
    module.hot.accept("./Subject.vue?vue&type=template&id=4332ab82&scoped=true&", function () {
      api.rerender('4332ab82', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dictionary/components/Subject.vue"
export default component.exports