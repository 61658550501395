var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "myUpload",
      staticClass: "file-upload",
      attrs: {
        id: "myUpload",
        multiple: _vm.multipleSelect,
        type: "file",
        accept: "image/*"
      },
      on: { change: _vm.Upload }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }