var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "historical-work-order" },
    [
      _c(
        "div",
        { staticClass: "searchBar-status" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              on: { change: _vm.handleBtnCheck },
              model: {
                value: _vm.query.status,
                callback: function($$v) {
                  _vm.$set(_vm.query, "status", $$v)
                },
                expression: "query.status"
              }
            },
            _vm._l(_vm.button_options, function(item) {
              return _c(
                "el-radio-button",
                {
                  key: item.value,
                  attrs: { value: item.value, label: item.value }
                },
                [_vm._v(_vm._s(item.label) + "\n      ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.tableData.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableloading,
                  expression: "tableloading"
                }
              ],
              staticClass: "work-order-content"
            },
            _vm._l(_vm.tableData, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "work-order-content-item" },
                [
                  _c("div", { staticClass: "item-header" }, [
                    _c("span", [
                      _vm._v("工单号：" + _vm._s(item.workOrderOpenId))
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("创建时间：" + _vm._s(item.createdAt))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("创建类型：\n          "),
                      _vm.appOpenId === item.appProductId
                        ? _c("span", [_vm._v("平台")])
                        : _c("span", [_vm._v("用户")])
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("优先级："),
                      _c("span", { staticStyle: { color: "#46a6ff" } }, [
                        _vm._v(_vm._s(item.priority))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("状态：\n          "),
                      item.status === 1
                        ? _c("span", [_vm._v("待处理")])
                        : _vm._e(),
                      _vm._v(" "),
                      item.status === 3
                        ? _c("span", [_vm._v("已处理")])
                        : _vm._e(),
                      _vm._v(" "),
                      item.status === 2
                        ? _c("span", [_vm._v("无需处理")])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.workorderClassificationName) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  item.workorderImgUrls
                    ? _c(
                        "div",
                        { staticClass: "item-describe" },
                        _vm._l(item.workorderImgUrls.split(","), function(
                          itemImg,
                          index
                        ) {
                          return _c("el-image", {
                            key: index,
                            staticStyle: {
                              width: "72px",
                              height: "72px",
                              "margin-right": "10px"
                            },
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                itemImg,
                                _vm.middlethumbnailStyle
                              )
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "item-describe",
                    domProps: {
                      innerHTML: _vm._s(_vm.getEmoji(item.workorderDescribe))
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-describe" }, [
                    _vm._v(
                      "\n        联系电话：" + _vm._s(item.phone) + "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-detail" }, [
                    _c(
                      "div",
                      [
                        _vm._v("\n          处理人：\n          "),
                        item.cover
                          ? _c("el-image", {
                              staticClass: "cover",
                              attrs: {
                                fit: "cover",
                                src: _vm.getImgUrl(
                                  item.cover,
                                  _vm.smallthumbnailStyle
                                ),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n          " + _vm._s(item.realName) + "\n        "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(item.id)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "noMore" }, [_vm._v("\n    暂无数据\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "t-block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalElements
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add-details-dialog",
          attrs: {
            title: "工单详情",
            "append-to-body": "",
            visible: _vm.detailsDialog,
            "before-close": _vm.cancelDetailsDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.detailsDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "details" },
            [
              _c("work-order-details", {
                attrs: {
                  "details-id": _vm.detailsId,
                  "refresh-detail": _vm.refreshDetail,
                  showMore: false,
                  contentHeight: "500px"
                },
                on: { getDetailData: _vm.getDetailData }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }