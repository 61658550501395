var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingUserinfo,
          expression: "loadingUserinfo"
        }
      ],
      staticClass: "userBaseInfo",
      staticStyle: { display: "flex", height: "100%" }
    },
    [
      _c("div", { staticClass: "leftSty" }, [
        _c("div", { staticClass: "commonBorder" }, [
          _c(
            "div",
            {
              staticClass: "commonMarginBottom",
              staticStyle: { display: "flex", "justify-content": "flex-end" }
            },
            [
              _vm.userData && !_vm.userData.deletedAt
                ? _c(
                    "span",
                    [
                      _c(
                        "el-tag",
                        { attrs: { type: "success", size: "mini" } },
                        [_vm._v("正常")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.userData && _vm.userData.deletedAt
                ? _c(
                    "span",
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "top-start" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(_vm._s(_vm.userData.deleteReason))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-tag",
                                { attrs: { type: "danger", size: "mini" } },
                                [_vm._v("已禁用")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "commonFleAc" }, [
            _c("div", { staticClass: "commonMarginBottom" }, [
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _vm.userData && _vm.userData.cover
                    ? _c(
                        "el-image",
                        {
                          staticClass: "userimg",
                          attrs: {
                            src: _vm.getImgUrl(
                              _vm.userData.cover,
                              _vm.thumbnailStyle
                            ),
                            alt: "",
                            fit: "cover"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("img", {
                                staticClass: "userimg",
                                attrs: {
                                  src: require("@/assets/book_cover.png")
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _c("img", {
                        staticClass: "userimg",
                        attrs: { src: require("@/assets/book_cover.png") }
                      }),
                  _vm._v(" "),
                  _vm.userData && _vm.userData.vipUser
                    ? _c("img", {
                        staticClass: "vipImg",
                        attrs: {
                          src: require("@/assets/images/VIP.png"),
                          alt: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commonMarginBottom" }, [
              _c("span", { staticClass: "nickName" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.isNull(_vm.userData && _vm.userData.nickName))
                  )
                ]),
                _vm._v(" "),
                _vm.userData && _vm.userData.level
                  ? _c(
                      "span",
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "right" }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", [
                                  _vm._v("用户层级"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("1级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("未消费；")
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("2级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("任意单次消费不满49元；")
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("3级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("任意单次消费超49元；")
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("4级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("购买/续费VIP的用户；")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm.userData.level === 1
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv1.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userData.level === 2
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv2.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userData.level === 3
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv3.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userData.level === 4
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv4.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.userData && _vm.userData.identity
              ? _c(
                  "div",
                  { staticClass: "commonMarginBottom" },
                  [
                    _vm.userData.identity === "教师"
                      ? _c(
                          "el-tag",
                          { attrs: { size: "mini", type: "success" } },
                          [_vm._v("认证教师")]
                        )
                      : _c("el-tag", { attrs: { size: "mini" } }, [
                          _vm._v(_vm._s(_vm.userData.identity))
                        ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "commonMarginBottom" }, [
              _c(
                "span",
                { staticStyle: { color: "#606266", "font-size": "14px" } },
                [
                  _vm._v("ID"),
                  _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                  _vm._v(
                    _vm._s(_vm.isNull(_vm.userData && _vm.userData.openId))
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "smallSize" }, [
                _vm._v("最近登录"),
                _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.isNull(_vm.userData && _vm.userData.lastLoginTimeAt)
                    )
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "commonBorder" }, [
          _c(
            "div",
            {
              staticClass: "commonMarginBottom baseTitle",
              staticStyle: { "margin-top": "20px" }
            },
            [_vm._v("联系方式")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _c("span", [
              _vm._v("手机号码"),
              _c("span", { staticClass: "colon" }, [_vm._v("：")]),
              _c("span", [
                _vm._v(_vm._s(_vm.isNull(_vm.userData && _vm.userData.phone)))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "flex-wrap": "wrap"
                }
              },
              [
                _vm._v("\n            微信帐号"),
                _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                _vm._v(" "),
                _vm.userData && _vm.userData.wxChatUnionId
                  ? _c(
                      "span",
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "top" }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.userData.wxChatUnionId) + " "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticClass: "userCover",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        _vm.userData.cover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "userCover",
                                          attrs: {
                                            src: require("@/assets/book_cover.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "displayName" }, [
                                  _vm._v(_vm._s(_vm.userData.displayName))
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-success bindWeChat"
                                  }),
                                  _vm._v("已绑定")
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _c("i", { staticClass: "el-icon-info notbind" }),
                      _vm._v(" 未绑定\n          ")
                    ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "commonMarginBottom",
                staticStyle: { "margin-top": "20px" }
              },
              [
                _c("span", { staticClass: "baseTitle" }, [_vm._v("超管备注")]),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "仅管理人员可见",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "iconInfo el-icon-info",
                        staticStyle: { color: "#409EFF" }
                      })
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "commonMarginBottom" },
              [
                _c("el-input", {
                  attrs: { type: "textarea", placeholder: "请输入超管备注" },
                  model: {
                    value: _vm.userData.adminRemarks,
                    callback: function($$v) {
                      _vm.$set(_vm.userData, "adminRemarks", $$v)
                    },
                    expression: "userData.adminRemarks"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: !_vm.userData || _vm.userOpenId === "",
                  type: "info",
                  plain: "",
                  size: "mini",
                  loading: _vm.loadingRemarksinfo
                },
                on: { click: _vm.remarksinfo }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rightSty" }, [
        _c("div", { staticClass: "commonMarginLR commonBorder" }, [
          _c(
            "div",
            {
              staticClass: "baseTitle commonMarginBottom",
              staticStyle: {
                "justify-content": "space-between",
                display: "flex"
              }
            },
            [
              _c("div", [_vm._v("基本资料")]),
              _vm._v(" "),
              _c("div", [
                _c("i", {
                  staticClass: "el-icon-refresh",
                  class:
                    _vm.rotateanimationBol === true ? "rotateanimation" : "",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.refreshUserInfo }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _vm._v("\n            真实姓名"),
            _c("span", { staticClass: "colon" }, [_vm._v("：")]),
            _vm._v(" "),
            !_vm.editRealNameBol
              ? _c(
                  "span",
                  {
                    staticStyle: { cursor: "pointer", margin: "0 3px" },
                    on: {
                      click: function($event) {
                        return _vm.editRealName(_vm.userData.realName)
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isNull(_vm.userData && _vm.userData.realName)
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-edit" })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editRealNameBol
              ? _c(
                  "span",
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        maxlength: "20",
                        "show-word-limit": "",
                        placeholder: "请输入真实姓名",
                        size: "mini"
                      },
                      model: {
                        value: _vm.copyRealName,
                        callback: function($$v) {
                          _vm.copyRealName = $$v
                        },
                        expression: "copyRealName"
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { color: "#ff4949", cursor: "pointer" },
                      on: { click: _vm.cancelEditName }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-check",
                      staticStyle: { color: "#67C23A", cursor: "pointer" },
                      on: { click: _vm.saveEditName }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.userData.realPeopleVerifiedAt
              ? _c("span", [
                  _c("i", { staticClass: "el-icon-success bindWeChat" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "smallSize" }, [
                    _vm._v("已通过实人认证")
                  ])
                ])
              : _c("span", [
                  _c("i", {
                    staticClass: "el-icon-info",
                    staticStyle: { color: "#409EFF" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "smallSize" }, [
                    _vm._v("未完成实人认证")
                  ])
                ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _c("span", { staticClass: "commonWidth" }, [
              _vm._v("\n            同步信息"),
              _c("span", { staticClass: "colon" }, [_vm._v("：")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.isNull(_vm.userData && _vm.userData.sectionName)) +
                    "/" +
                    _vm._s(_vm.isNull(_vm.userData && _vm.userData.gradeName)) +
                    "/" +
                    _vm._s(_vm.userData && _vm.userData.volumeName)
                )
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "smallSize",
                  staticStyle: { "margin-left": "5px" }
                },
                [
                  _vm._v("最后更新"),
                  _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                  _vm._v(_vm._s(_vm.userData && _vm.userData.updatedAt))
                ]
              )
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v("所在地区"),
              _c("span", { staticClass: "colon" }, [_vm._v("：")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.isNull(_vm.userData && _vm.userData.provinceName)
                  ) + _vm._s(_vm.userData && _vm.userData.cityName)
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _c("span", { staticClass: "commonWidth" }, [
              _vm._v("用户来源"),
              _c("span", { staticClass: "colon" }, [_vm._v("：")]),
              _vm._v(
                _vm._s(
                  _vm.isNull(_vm.userData && _vm.userData.registerFromName)
                )
              )
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v("注册时间"),
              _c("span", { staticClass: "colon" }, [_vm._v("：")]),
              _vm._v(_vm._s(_vm.isNull(_vm.userData && _vm.userData.createdAt)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _vm._v("\n          教材版本"),
            _c("span", { staticClass: "colon" }, [_vm._v("：")]),
            _vm._v(" "),
            _vm.userTextBookList && _vm.userTextBookList.length < 6
              ? _c(
                  "span",
                  _vm._l(_vm.userTextBookList, function(item, index) {
                    return _c(
                      "span",
                      { key: index, staticStyle: { "margin-right": "5px" } },
                      [_vm._v(_vm._s(item.displayName))]
                    )
                  }),
                  0
                )
              : _c(
                  "span",
                  [
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { display: "inline-flex" },
                        attrs: { accordion: "" }
                      },
                      [
                        _c("el-collapse-item", [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "inline-flex" },
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            _vm._l(_vm.userTextBookList.slice(0, 5), function(
                              item,
                              index
                            ) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticStyle: { "margin-right": "5px" }
                                },
                                [_vm._v(_vm._s(item.displayName))]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "inline-flex" } },
                            _vm._l(
                              _vm.userTextBookList.slice(
                                5,
                                _vm.userTextBookList.length
                              ),
                              function(item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "5px" }
                                  },
                                  [_vm._v(_vm._s(item.displayName))]
                                )
                              }
                            ),
                            0
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "span",
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "源于用户已扫的增值服务内容",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "iconInfo el-icon-info",
                        staticStyle: { color: "#409EFF" }
                      })
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", [
              _vm._v("\n           用户标签"),
              _c("span", { staticClass: "colon" }, [_vm._v("：")]),
              _vm._v(" "),
              _vm.userData && _vm.userOpenId
                ? _c(
                    "div",
                    { staticStyle: { display: "inline-flex" } },
                    [
                      _vm._l(_vm.userData.tagList, function(tagItem, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: { "margin-right": "5px" }
                          },
                          [
                            _c(
                              "el-tag",
                              {
                                class: _vm.showclassName(
                                  _vm.userData.tagColorMap[tagItem]
                                ),
                                attrs: {
                                  size: "mini",
                                  type: _vm.showType(
                                    _vm.userData.tagColorMap[tagItem]
                                  ),
                                  closable: true
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.deletedUserTag(tagItem)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tagItem))]
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "click" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                                width: "210px"
                              }
                            },
                            _vm._l(_vm.UserTagList, function(tagItem) {
                              return _c(
                                "div",
                                {
                                  key: tagItem.id,
                                  staticStyle: {
                                    margin: "0 5px 5px 0",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addUserTagApi(tagItem)
                                    }
                                  }
                                },
                                [
                                  _c("tags", {
                                    attrs: {
                                      tagColor: tagItem.tagColor,
                                      tagName: tagItem.tagName,
                                      className: tagItem.className,
                                      closable: false
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "addTags",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [_vm._v("添加标签")]
                          )
                        ]
                      )
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "commonMarginLR commonBorder" }, [
          _c(
            "div",
            { staticClass: "baseTitle", staticStyle: { "margin-top": "20px" } },
            [_vm._v("资产信息")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "commonWidth", staticStyle: { flex: "none" } },
              [
                _vm._v("VIP截止期"),
                _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.isNull(_vm.userData && _vm.userData.vipExpiredAt)
                    )
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: !_vm.userData || _vm.userOpenId === "",
                      type: "text"
                    },
                    on: {
                      click: function($event) {
                        _vm.setVipDialogVisible = true
                      }
                    }
                  },
                  [_vm._v("设置vip")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("span", [
                  _vm._v("金星币"),
                  _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                  _vm._v(_vm._s(_vm.userData && _vm.userData.goldCount))
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      disabled: !_vm.userData || _vm.userOpenId === ""
                    },
                    on: {
                      click: function($event) {
                        _vm.setCurrencyDialogVisible = true
                      }
                    }
                  },
                  [_vm._v("设置金星币")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "0" },
                    attrs: {
                      type: "text",
                      disabled: !_vm.userData || _vm.userOpenId === ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.togoldChangeRecord(_vm.userData.openId)
                      }
                    }
                  },
                  [_vm._v("金星币记录")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "commonWidth", staticStyle: { flex: "none" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      width: "65px",
                      "justify-content": "flex-end",
                      display: "inline-flex"
                    }
                  },
                  [_vm._v("\n              帐号余额")]
                ),
                _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                _vm.userData && _vm.userData.iosBalance
                  ? _c("span", [
                      _vm._v(
                        "¥" +
                          _vm._s(
                            _vm.numFormat(
                              (_vm.userData.iosBalance / 100).toFixed(2)
                            )
                          )
                      )
                    ])
                  : _c("span", [_vm._v("--")])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
              _vm._m(0),
              _vm._v(" "),
              _vm.userData && _vm.userData.consumeAmount
                ? _c(
                    "div",
                    {
                      staticStyle: { display: "flex", width: "100%" },
                      on: {
                        mouseenter: _vm.handleshowPrice,
                        mouseleave: _vm.handlehidePrice
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "Top Center 提示文字",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n                  ¥" +
                                  _vm._s(
                                    _vm.numFormat(
                                      (
                                        _vm.userData.consumeAmount / 100
                                      ).toFixed(2)
                                    )
                                  ) +
                                  "  ===  " +
                                  _vm._s(_vm.percentage) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "elProgress" },
                            [
                              _c("el-progress", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  percentage: _vm.percentage,
                                  "show-text": false
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "whitedot" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showPrice,
                                        expression: "showPrice"
                                      }
                                    ],
                                    staticClass: "bottomSize"
                                  },
                                  [_vm._v("0元")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "whitedot whitedot1" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showPrice,
                                        expression: "showPrice"
                                      }
                                    ],
                                    staticClass: "bottomSize"
                                  },
                                  [_vm._v("49元")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "whitedot whitedot2" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showPrice,
                                        expression: "showPrice"
                                      }
                                    ],
                                    staticClass: "bottomSize"
                                  },
                                  [_vm._v("258元/一学期VIP")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "whitedot whitedot3" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showPrice,
                                      expression: "showPrice"
                                    }
                                  ],
                                  staticClass: "bottomSize1"
                                },
                                [_vm._v("1000+元")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "¥" +
                            _vm._s(
                              _vm.numFormat(
                                (_vm.userData.consumeAmount / 100).toFixed(2)
                              )
                            )
                        )
                      ])
                    ],
                    1
                  )
                : _c("span", [_vm._v("--")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "commonMarginLR commonBorder" }, [
          _c(
            "div",
            {
              staticClass: "commonMarginBottom baseTitle",
              staticStyle: { "margin-top": "20px" }
            },
            [_vm._v("使用信息")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _vm._v("\n          购买渠道"),
            _c("span", { staticClass: "colon" }, [_vm._v("：")]),
            _vm.userPortraitList.length < 6
              ? _c(
                  "span",
                  _vm._l(_vm.userPortraitList, function(tag, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticStyle: { margin: "0 5px 5px 0" },
                        attrs: { type: "info", size: "mini" }
                      },
                      [_vm._v(_vm._s(tag))]
                    )
                  }),
                  1
                )
              : _c(
                  "span",
                  [
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { display: "inline-flex" },
                        attrs: { accordion: "" }
                      },
                      [
                        _c("el-collapse-item", [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "inline-flex" },
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            _vm._l(_vm.userPortraitList.slice(0, 5), function(
                              tag,
                              index
                            ) {
                              return _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticStyle: { margin: "0 5px 5px 0" },
                                  attrs: { size: "mini", type: "info" }
                                },
                                [_vm._v(_vm._s(tag))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "inline-flex" } },
                            _vm._l(
                              _vm.userPortraitList.slice(
                                5,
                                _vm.userPortraitList.length
                              ),
                              function(tag, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { margin: "0 5px 5px 0" },
                                    attrs: { type: "info", size: "mini" }
                                  },
                                  [_vm._v(_vm._s(tag))]
                                )
                              }
                            ),
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "span",
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "依最新渠道倒序排列，只增不减",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "iconInfo el-icon-info",
                        staticStyle: { color: "#409EFF" }
                      })
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonMarginBottom" }, [
            _vm._v("\n          最近浏览"),
            _c("span", { staticClass: "colon" }, [_vm._v("：")]),
            _vm.userScanCategoryList.length < 6
              ? _c(
                  "span",
                  _vm._l(_vm.userScanCategoryList, function(tag, index) {
                    return _c(
                      "el-tag",
                      { key: index, attrs: { type: "info", size: "mini" } },
                      [_vm._v(_vm._s(tag.displayName))]
                    )
                  }),
                  1
                )
              : _c(
                  "span",
                  [
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { display: "inline-flex" },
                        attrs: { accordion: "" }
                      },
                      [
                        _c("el-collapse-item", [
                          _c(
                            "div",
                            {
                              staticStyle: { display: "inline-flex" },
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            _vm._l(
                              _vm.userScanCategoryList.slice(0, 5),
                              function(tag, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { margin: "0 5px 5px 0" },
                                    attrs: { size: "mini", type: "info" }
                                  },
                                  [_vm._v(_vm._s(tag.displayName))]
                                )
                              }
                            ),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "inline-flex" } },
                            _vm._l(
                              _vm.userScanCategoryList.slice(
                                5,
                                _vm.userScanCategoryList.length
                              ),
                              function(tag, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { margin: "0 5px 5px 0" },
                                    attrs: { type: "info", size: "mini" }
                                  },
                                  [_vm._v(_vm._s(tag.displayName))]
                                )
                              }
                            ),
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
            _c(
              "span",
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "仅保留最近浏览课程的类目",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "iconInfo el-icon-info",
                        staticStyle: { color: "#409EFF" }
                      })
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottomBtn" }, [
          _c(
            "div",
            { staticStyle: { "margin-left": "16px" } },
            [
              _vm.userData && _vm.userData.deletedAt
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        disabled: !_vm.userData || _vm.userOpenId === "",
                        type: "success",
                        plain: "",
                        loading: _vm.loadingRestoreUser
                      },
                      on: { click: _vm.restoreUser }
                    },
                    [_vm._v("激活")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.userData || _vm.userOpenId === "",
                        size: "mini",
                        type: "danger",
                        plain: ""
                      },
                      on: { click: _vm.deleteUser }
                    },
                    [_vm._v("禁用")]
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottomBtnR" },
            [
              _c(
                "div",
                { staticStyle: { "margin-right": "16px" } },
                [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v("测试人员")
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      disabled: !_vm.userData || _vm.userOpenId === "",
                      "active-color": "#13ce66",
                      "inactive-color": "#d3d4d6"
                    },
                    on: { change: _vm.editUsertester },
                    model: {
                      value: _vm.testAdmin,
                      callback: function($$v) {
                        _vm.testAdmin = $$v
                      },
                      expression: "testAdmin"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: !_vm.userData || _vm.userOpenId === "",
                    type: "primary",
                    plain: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleClickEdit()
                    }
                  }
                },
                [_vm._v("编辑")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": "",
            "close-on-press-escape": false,
            title: "设置vip",
            visible: _vm.setVipDialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.setVipDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleFormVip",
                  attrs: {
                    model: _vm.ruleFormVip,
                    rules: _vm.rules,
                    "label-width": "105px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "VIP截止期" } }, [
                    _vm.userData && _vm.userData.vipExpiredAt
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.userData.vipExpiredAt) +
                              " (剩余:" +
                              _vm._s(
                                _vm.dateDiff(
                                  _vm.userData.vipExpiredAt,
                                  new Date()
                                )
                              ) +
                              "天)\n          "
                          )
                        ])
                      : _c("span", [_vm._v("--")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "增加VIP天数", prop: "vipValue" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleFormVip.vipValue,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleFormVip, "vipValue", $$v)
                            },
                            expression: "ruleFormVip.vipValue"
                          }
                        },
                        _vm._l(_vm.vipOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.resourceTitle,
                              value: item.commodityOpenId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注信息" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "备注信息" },
                        model: {
                          value: _vm.ruleFormVip.desc,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleFormVip, "desc", $$v)
                          },
                          expression: "ruleFormVip.desc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.setVipDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    loading: _vm.setVipDialogVisibleloading
                  },
                  on: {
                    click: function($event) {
                      return _vm.setVip("ruleFormVip")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": "",
            "close-on-press-escape": false,
            title: "设置金星币",
            visible: _vm.setCurrencyDialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.setCurrencyDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleFormGold",
                  attrs: {
                    model: _vm.ruleFormGold,
                    rules: _vm.goldRules,
                    "label-width": "90px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "当前金星币" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isNull(_vm.userData && _vm.userData.goldCount)
                        ) + " "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "增加/减少", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleFormGold.type,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleFormGold, "type", $$v)
                            },
                            expression: "ruleFormGold.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "增加" } }),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "减少" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "变更数量" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          type: "number",
                          "controls-position": "right",
                          min: 0
                        },
                        on: { blur: _vm.inputBlur },
                        model: {
                          value: _vm.ruleFormGold.number,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleFormGold, "number", $$v)
                          },
                          expression: "ruleFormGold.number"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "变更原因" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.ruleFormGold.reason,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleFormGold, "reason", $$v)
                          },
                          expression: "ruleFormGold.reason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.setCurrencyDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.setGold("ruleFormGold")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "设为禁用",
            "append-to-body": "",
            visible: _vm.dialogVisibledeleteUser,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibledeleteUser = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "reason",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.reason }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "reason",
                    rules: [{ required: true, message: "原因不能为空" }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请描述禁用原因",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.reason.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.reason, "reason", $$v)
                      },
                      expression: "reason.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisibledeleteUser = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.submitFormdeletedUser("reason")
                    }
                  }
                },
                [_vm._v("禁用")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("gold-record", {
        attrs: { visible: _vm.goldRecordDia, userOpenId: _vm.userOpenId },
        on: {
          "update:visible": function($event) {
            _vm.goldRecordDia = $event
          }
        }
      }),
      _vm._v(" "),
      _c("edit-user-info", {
        attrs: { visible: _vm.edituserinfoDia, userOpenId: _vm.userOpenId },
        on: {
          "update:visible": function($event) {
            _vm.edituserinfoDia = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "none" } }, [
      _vm._v(" 累计消费金额"),
      _c("span", { staticClass: "colon" }, [_vm._v("：")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }