var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.infoLoading,
          expression: "infoLoading"
        }
      ],
      staticClass: "dashboard_container"
    },
    [
      _c(
        "div",
        { staticClass: "item_box ", staticStyle: { padding: "30px" } },
        [
          _c("step-view", {
            attrs: {
              data: _vm.stepList,
              "current-state-index": _vm.currentStateIndex
            },
            on: { stepClick: _vm.stepClick }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item_box",
          staticStyle: { padding: "15px", "margin-top": "10px" }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _c("div", [
                  _c("div", { staticClass: "module_title" }, [
                    _vm._v("\n            服务单信息\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "module_content",
                      staticStyle: { padding: "10px" }
                    },
                    [
                      _c("div", { staticClass: "goods_box display_flex" }, [
                        _c(
                          "div",
                          { staticClass: "goodsCover" },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "goodsCover",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    _vm.afterInfo.productPic,
                                    _vm.thumbnailStyle
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "goodsCover",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "goodsTag" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.showVal(this.commodityLibrary)) +
                                  "/\n                  "
                              ),
                              _vm.afterInfo.commodityType === 1
                                ? _c("span", [_vm._v("实物")])
                                : _vm.afterInfo.commodityType === 2
                                ? _c("span", [_vm._v("数字")])
                                : _vm.afterInfo.commodityType === 3
                                ? _c("span", [_vm._v("组合")])
                                : _c("span", [_vm._v("--")])
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "100%"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "rgba(96, 98, 102, 1)",
                                  "font-size": "14px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.showVal(_vm.afterInfo.productName)
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "rgba(150, 163, 170, 1)",
                                  "font-size": "12px",
                                  "margin-top": "10px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.showVal(_vm.goodsInfo.specification)
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "display_flex align_items space_between",
                                staticStyle: {
                                  color: "rgba(16, 16, 16, 1)",
                                  "font-size": "14px",
                                  "margin-top": "10px"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "¥ " +
                                      _vm._s(
                                        _vm.showVal(
                                          Number(
                                            _vm.afterInfo.price / 100
                                          ).toFixed(2)
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "x" +
                                      _vm._s(
                                        _vm.showVal(_vm.afterInfo.buyCount)
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.afterInfo.commodityType === 2 ||
                      _vm.afterInfo.commodityType === 3
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "rgba(246, 247, 251, 1)",
                                "border-radius": "4px",
                                "margin-top": "15px",
                                "padding-top": "15px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "stepInfo_font_1",
                                  staticStyle: { "margin-left": "15px" }
                                },
                                [_vm._v("商品清单")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.goodsInfo.contentList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "goods_box display_flex"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "goodsCover" },
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "goodsCover",
                                            attrs: {
                                              fit: "contain",
                                              src: _vm.getGoodsCover(item)
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "error" },
                                                slot: "error"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "goodsCover",
                                                  attrs: {
                                                    src: require("@/assets/book_cover.png")
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.commodityType === 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "goodsDetailTag goodsTag_bg_1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    实物\n                  "
                                                )
                                              ]
                                            )
                                          : item.commodityType === 2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "goodsDetailTag goodsTag_bg_2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    数字\n                  "
                                                )
                                              ]
                                            )
                                          : item.commodityType === 3
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "goodsDetailTag goodsTag_bg_3"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    组合\n                  "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "goodsDetailTag goodsTag_bg_1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    --\n                  "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          width: "100%",
                                          display: "flex",
                                          "flex-direction": "column",
                                          "justify-content": "space-around"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "rgba(96, 98, 102, 1)",
                                              "font-size": "14px",
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              display: "-webkit-box",
                                              "-webkit-line-clamp": "2",
                                              "line-clamp": "2",
                                              "word-break": "break-all",
                                              "-webkit-box-orient": "vertical"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.showVal(item.title)
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "rgba(150, 163, 170, 1)",
                                              "font-size": "12px",
                                              "margin-top": "10px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.showVal(
                                                    item.specification
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content_font_default",
                          staticStyle: { "margin-top": "25px" }
                        },
                        [
                          _vm._v(
                            "\n              服务单号：" +
                              _vm._s(_vm.showVal(_vm.afterInfo.refundId)) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v("\n              申请数量："),
                          _c("span", { staticStyle: { color: "#E44646" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.showVal(_vm.afterInfo.returnCount))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v("\n              供货方式：\n              "),
                          _c(
                            "span",
                            { staticStyle: { color: "rgba(64, 158, 255, 1)" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getSupplyModeName(
                                    _vm.afterInfo.supplyMode
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v("\n              客户期望：\n              "),
                          _c("span", { staticStyle: { color: "#E44646" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getAfterSaleType()) +
                                "\n              "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v("\n              返回方式：\n              "),
                          _vm.afterInfo.returnType === 1
                            ? _c("span", [_vm._v("快递")])
                            : _c("span", [_vm._v("--")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v(
                            "\n              申请原因： " +
                              _vm._s(_vm.getApplyReason()) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "content_font_default margin_top_15 display_flex "
                        },
                        [
                          _c("div", { staticClass: "white_space_nowrap" }, [
                            _vm._v("问题描述：")
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.showVal(_vm.afterInfo.applyDesc)))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.picList.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "content_font_default margin_top_15 display_flex "
                            },
                            [_vm._v("\n              问题图片：\n            ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.picList.length > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "overflow-x": "auto"
                              }
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { width: "345px" },
                                  attrs: { gutter: 20 }
                                },
                                _vm._l(_vm.picList, function(item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 6 } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onPreview(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "el-image",
                                            {
                                              staticClass: "imageCover",
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  item,
                                                  _vm.thumbnailStyle
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "image-slot",
                                                  attrs: { slot: "error" },
                                                  slot: "error"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "imageCover",
                                                    attrs: {
                                                      src: require("@/assets/book_cover.png")
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "line_h",
                        staticStyle: { "margin-top": "10px" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "content_font_default margin_top_15 display_flex align_items"
                        },
                        [
                          _c("span", [_vm._v("订单编号：")]),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.orderDetailVisible = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.showVal(_vm.afterInfo.orderId)) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v(
                            "\n              订单商品数：" +
                              _vm._s(_vm.showVal(_vm.afterInfo.buyCount)) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v(
                            "\n              订单金额：¥ " +
                              _vm._s(
                                _vm.showVal(
                                  Number(_vm.afterInfo.payment / 100).toFixed(2)
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "content_font_default margin_top_15 display_flex align_items"
                        },
                        [
                          _c("div", [_vm._v("下单账号：")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "userCover" },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "userCover",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      _vm.afterInfo.buyerCover,
                                      _vm.thumbnailStyle32
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userCover",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "rgba(64, 158, 255, 1)",
                                "margin-left": "5px"
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.showVal(_vm.afterInfo.buyerNickName)
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v(
                            "\n              收货人： " +
                              _vm._s(_vm.showVal(_vm.afterInfo.buyerName)) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content_font_default margin_top_15" },
                        [
                          _vm._v(
                            "\n              收货人电话：" +
                              _vm._s(_vm.showVal(_vm.afterInfo.buyerPhone)) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "content_font_default margin_top_15 display_flex "
                        },
                        [
                          _c("div", { staticClass: "white_space_nowrap" }, [
                            _vm._v("收货地址：")
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.showVal(_vm.afterInfo.buyerAddress)
                                ) +
                                "\n              "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 17 } }, [
                _c("div", [
                  _c("div", { staticClass: "module_title" }, [
                    _vm._v("\n            服务单状态\n          ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "module_content" }, [
                    _vm.selectStatus === 2
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  买家期望\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "margin_top_12 stepInfo_font_2 display_flex align_items space_between"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "买家期望：" +
                                      _vm._s(_vm.getAfterSaleType())
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "申请原因：" + _vm._s(_vm.getApplyReason())
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "申请时间：" +
                                      _vm._s(
                                        _vm.showVal(_vm.afterInfo.createdAt)
                                      )
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 2
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  问题描述\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12 stepInfo_font_2 " },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.showVal(_vm.afterInfo.applyDesc)
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 2 && _vm.picList.length > 0
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  问题图片\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { width: "345px" },
                                    attrs: { gutter: 20 }
                                  },
                                  _vm._l(_vm.picList, function(item, index) {
                                    return _c(
                                      "el-col",
                                      { key: index, attrs: { span: 6 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "10px"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onPreview(item)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticClass: "imageCover",
                                                attrs: {
                                                  fit: "cover",
                                                  src: _vm.getImgUrl(
                                                    item,
                                                    _vm.thumbnailStyle
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: { slot: "error" },
                                                    slot: "error"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "imageCover",
                                                      attrs: {
                                                        src: require("@/assets/book_cover.png")
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 2 && _vm.afterInfo.status === 2
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  处理备注\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    placeholder: "请输入审核备注"
                                  },
                                  model: {
                                    value: _vm.auditText,
                                    callback: function($$v) {
                                      _vm.auditText = $$v
                                    },
                                    expression: "auditText"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 3 ||
                    _vm.selectStatus === 5 ||
                    _vm.selectStatus === 6 ||
                    _vm.selectStatus === 7 ||
                    _vm.selectStatus === 8
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  退货地址信息\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12 stepInfo_font_2 " },
                              [
                                _c(
                                  "div",
                                  { staticClass: "display_flex align_items" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "50%" } },
                                      [
                                        _vm._v(
                                          "收货人：" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.afterInfo.returnUser
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "50%" } },
                                      [
                                        _vm._v(
                                          "收货人电话：" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.afterInfo.returnPhone
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _vm._v(
                                      "\n                    收货地址：" +
                                        _vm._s(
                                          _vm.showVal(
                                            _vm.afterInfo.returnAddress
                                          )
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.selectStatus === 3 ||
                      _vm.selectStatus === 5 ||
                      _vm.selectStatus === 6 ||
                      _vm.selectStatus === 7 ||
                      _vm.selectStatus === 8) &&
                    _vm.expressList.length > 0
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "stepInfo_item" },
                            [
                              _c("div", { staticClass: "stepInfo_font_1" }, [
                                _vm._v(
                                  "\n                  物流信息\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.expressList, function(item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "stepInfo_font_1",
                                        staticStyle: { "margin-top": "15px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    物流" +
                                            _vm._s(index + 1) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-top": "15px"
                                        },
                                        attrs: { data: item, border: "" }
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "title",
                                            label: "商品名称"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "deliverQuantity",
                                            label: "数量",
                                            align: "center",
                                            width: "60"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "postCompanyName",
                                            label: "物流公司",
                                            align: "center",
                                            width: "100"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "trackingNumber",
                                            label: "物流运单号",
                                            width: "200"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "createdAt",
                                            label: "发货时间",
                                            width: "180"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.selectStatus === 3 ||
                      _vm.selectStatus === 5 ||
                      _vm.selectStatus === 6 ||
                      _vm.selectStatus === 7 ||
                      _vm.selectStatus === 8) &&
                    _vm.goodsInfo.isHaveEntityGoods
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  买家已发货/待买家发货\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12 stepInfo_font_2 " },
                              [
                                _c(
                                  "div",
                                  { staticClass: "display_flex align_items" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "50%" } },
                                      [
                                        _vm._v(
                                          "物流公司：" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.afterInfo
                                                  .returnExpressCompanyName
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "50%" } },
                                      [
                                        _vm._v(
                                          "物流运单号：" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.afterInfo.returnExpressCode
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "display_flex align_items",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "50%" } },
                                      [
                                        _vm._v(
                                          "发货时间：" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.afterInfo.buyerSendAt
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.afterInfo.status === 3
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "stepInfo_font_1 margin_top_12"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      修改物流信息\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "margin_top_12 stepInfo_font_2 display_flex align_items "
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "display_flex align_items",
                                              staticStyle: { width: "50%" }
                                            },
                                            [
                                              _vm._v(
                                                "物流公司：\n                        "
                                              ),
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass:
                                                    "search_input_width",
                                                  attrs: {
                                                    clearable: "",
                                                    placeholder: "物流公司"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.expForm
                                                        .returnExpressCompanyCode,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.expForm,
                                                        "returnExpressCompanyCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "expForm.returnExpressCompanyCode"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.activeOption,
                                                  function(item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.expName,
                                                        value: item.id
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "display_flex align_items",
                                              staticStyle: { width: "50%" }
                                            },
                                            [
                                              _vm._v(
                                                "物流单号：\n                        "
                                              ),
                                              _c("el-input", {
                                                staticClass:
                                                  "search_input_width",
                                                attrs: {
                                                  placeholder: "请输入单号",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.expForm
                                                      .returnExpressCode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.expForm,
                                                      "returnExpressCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "expForm.returnExpressCode"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "margin_top_12" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "small"
                                              },
                                              on: {
                                                click: _vm.submitReturnConfirm
                                              }
                                            },
                                            [_vm._v("确定")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 3 &&
                    _vm.afterInfo.supplyMode === 1 &&
                    _vm.goodsInfo.isHaveEntityGoods
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  ERP批销退货信息\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "margin_top_12 stepInfo_font_2 display_flex align_items"
                              },
                              [
                                _c("div", { staticStyle: { width: "50%" } }, [
                                  _vm._v("录入状态："),
                                  _vm.afterInfo.erpRefundId
                                    ? _c("span", [_vm._v("已完成")])
                                    : _c("span", [_vm._v("--")])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticStyle: { width: "50%" } }, [
                                  _vm._v("审核状态："),
                                  _vm.afterInfo.erpRefundStatus === 1
                                    ? _c("span", [_vm._v("已审核")])
                                    : _c("span", [_vm._v("未审核")])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.afterInfo.erpRefundId
                              ? _c(
                                  "div",
                                  { staticClass: "margin_top_12" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.pushERP()
                                          }
                                        }
                                      },
                                      [_vm._v("推送ERP批销退货信息")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 3 && _vm.afterInfo.status === 3
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  处理备注\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    placeholder:
                                      "请输入寄回包裹状态或其他备注信息，有利于辅助退款换货审核"
                                  },
                                  model: {
                                    value: _vm.receiptText,
                                    callback: function($$v) {
                                      _vm.receiptText = $$v
                                    },
                                    expression: "receiptText"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 4
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  验货处理备注\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12 stepInfo_font_2 " },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.showVal(_vm.afterInfo.yanhuoMemo)
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 4 ||
                    _vm.selectStatus === 5 ||
                    _vm.selectStatus === 6 ||
                    _vm.selectStatus === 7 ||
                    _vm.selectStatus === 8
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  退款信息\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "margin_top_12 stepInfo_font_2 display_flex align_items"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "display_flex align_items",
                                    staticStyle: { width: "50%" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    退款状态：\n                    "
                                    ),
                                    _vm.afterInfo.returnFeeStatus === 1
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#409EFF" } },
                                          [_vm._v("已退款")]
                                        )
                                      : _c(
                                          "span",
                                          { staticStyle: { color: "#409EFF" } },
                                          [_vm._v("未退款")]
                                        )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "display_flex align_items",
                                    staticStyle: { width: "50%" }
                                  },
                                  [
                                    _vm._v("退款金额："),
                                    _c(
                                      "span",
                                      { staticClass: "stepInfo_font_3" },
                                      [
                                        _vm._v(
                                          " ¥ " +
                                            _vm._s(
                                              _vm.showVal(
                                                Number(
                                                  _vm.afterInfo.returnFee / 100
                                                ).toFixed(2)
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "stepInfo_font_2 display_flex align_items",
                                staticStyle: { "margin-top": "10px" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "display_flex align_items",
                                    staticStyle: { width: "50%" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    退款时间：\n                    "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "stepInfo_font_3" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showVal(
                                              _vm.afterInfo.returnFeeAt
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 4 && _vm.afterInfo.status === 4
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  处理备注\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin_top_12" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    placeholder: "请输入退款备注信息"
                                  },
                                  model: {
                                    value: _vm.refundText,
                                    callback: function($$v) {
                                      _vm.refundText = $$v
                                    },
                                    expression: "refundText"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 5 ||
                    _vm.selectStatus === 6 ||
                    _vm.selectStatus === 7 ||
                    _vm.selectStatus === 8
                      ? _c("div", [
                          _c("div", { staticClass: "stepInfo_item" }, [
                            _c("div", { staticClass: "stepInfo_font_1" }, [
                              _vm._v(
                                "\n                  处理备注\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "margin_top_12 stepInfo_font_2 display_flex align_items"
                              },
                              [
                                _vm._v(
                                  "\n                  审核备注：" +
                                    _vm._s(
                                      _vm.showVal(_vm.afterInfo.shenheMemo)
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "stepInfo_font_2 display_flex align_items",
                                staticStyle: { "margin-top": "10px" }
                              },
                              [
                                _vm._v(
                                  "\n                  验货备注：" +
                                    _vm._s(
                                      _vm.showVal(_vm.afterInfo.yanhuoMemo)
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "stepInfo_font_2 display_flex align_items",
                                staticStyle: { "margin-top": "10px" }
                              },
                              [
                                _vm._v(
                                  "\n                  退款备注：" +
                                    _vm._s(
                                      _vm.showVal(_vm.afterInfo.tuikuanMemo)
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line_h" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 2 && _vm.afterInfo.status === 2
                      ? _c(
                          "div",
                          { staticStyle: { padding: "15px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.auditSubmit }
                              },
                              [_vm._v("通过")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "small" },
                                on: { click: _vm.showTurnDownDialog }
                              },
                              [_vm._v("驳回")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 3 && _vm.afterInfo.status === 3
                      ? _c(
                          "div",
                          { staticStyle: { padding: "15px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.submitReceiptConfirm }
                              },
                              [_vm._v("验货完成")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  size: "small"
                                },
                                on: { click: _vm.showCloseSaleAfterDialog }
                              },
                              [_vm._v("关闭服务单")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectStatus === 4 && _vm.afterInfo.status === 4
                      ? _c(
                          "div",
                          { staticStyle: { padding: "15px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.refundSubmit }
                              },
                              [_vm._v("确认退款")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  size: "small"
                                },
                                on: { click: _vm.showCloseSaleAfterDialog }
                              },
                              [_vm._v("关闭服务单")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _c("div", { staticClass: "module_title" }, [
                    _vm._v("\n            服务单日志\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "module_content",
                      staticStyle: { padding: "10px" }
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeName,
                            callback: function($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName"
                          }
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "服务单日志", name: "1" } },
                            _vm._l(_vm.saleAfterLogList, function(item, index) {
                              return _c("div", { key: index }, [
                                _c("div", { staticClass: "logItem_box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "display_flex align_items",
                                      staticStyle: { height: "16px" }
                                    },
                                    [
                                      item.adminOpenId
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "16px",
                                              height: "16px"
                                            },
                                            attrs: {
                                              src: require("@/assets/ic_admin_cover.png")
                                            }
                                          })
                                        : _c("img", {
                                            staticStyle: {
                                              width: "16px",
                                              height: "16px"
                                            },
                                            attrs: {
                                              src: require("@/assets/ic_user_cover.png")
                                            }
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "rgba(42, 83, 107, 1)",
                                            "font-size": "14px",
                                            "margin-left": "5px",
                                            "line-height": "20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                item.adminOpenId
                                                  ? _vm.showVal(
                                                      item.realName
                                                        ? item.realName
                                                        : item.nickName
                                                    )
                                                  : _vm.showVal(item.nickName)
                                              ) +
                                              "\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "rgba(150, 163, 170, 1)",
                                            "font-size": "12px",
                                            "margin-left": "25px",
                                            "line-height": "20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.showVal(item.createdAt)
                                              ) +
                                              "\n                      "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "rgba(42, 83, 107, 1)",
                                        "font-size": "14px",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.showVal(item.logTitle)) +
                                          "\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "rgba(102, 129, 144, 1)",
                                        "font-size": "12px",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            item.adminOpenId
                                              ? _vm.showVal(item.adminNotes)
                                              : _vm.showVal(item.buyerShowInfo)
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "line_h" })
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "同订单服务单", name: "2" } },
                            [
                              _c("sale-after-table", {
                                ref: "saleAfterTable",
                                attrs: {
                                  "show-checkbox": false,
                                  data: _vm.afterSaleList
                                },
                                on: {
                                  "update:data": function($event) {
                                    _vm.afterSaleList = $event
                                  },
                                  refresh: function($event) {
                                    return _vm.getAfterSaleData()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("turn-down-dialog", {
            ref: "turnDownDialog",
            attrs: {
              "before-close": _vm.disMissTurnDownDialog,
              visible: _vm.turnDownDialogVisible,
              "open-id": _vm.afterInfo.refundId
            },
            on: { turnDownOk: _vm.initData }
          }),
          _vm._v(" "),
          _c("close-sale-after-dialog", {
            ref: "closeSaleAfterDialog",
            attrs: {
              "before-close": _vm.disMissCloseSaleAfterDialog,
              visible: _vm.closeSaleAfterDialogVisible,
              "open-id": _vm.afterInfo.refundId
            },
            on: { closeOk: _vm.initData }
          }),
          _vm._v(" "),
          _vm.orderDetailVisible
            ? _c("product-order-detail", {
                attrs: {
                  visible: _vm.orderDetailVisible,
                  "order-id": _vm.afterInfo.orderId
                },
                on: {
                  "update:visible": function($event) {
                    _vm.orderDetailVisible = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.imagePath
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": [_vm.imagePath] }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }