var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-badge",
    {
      staticClass: "badge",
      attrs: {
        value: _vm.item.informationUnreadNum,
        type: _vm.item.takeUserId ? "danger" : "warning",
        max: 99,
        hidden: _vm.item.informationUnreadNum === 0
      }
    },
    [
      _c(
        "div",
        {
          class: _vm.informationUnreadNum(_vm.item.informationUnreadNum),
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "space-between"
          }
        },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.item.workOrderOpenId,
                placement: "bottom-start"
              }
            },
            [
              _c("div", { staticClass: "text-openId" }, [
                _vm._v("工单" + _vm._s(_vm.item.workOrderOpenId))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-label-left" },
            [
              _vm.item.takeUserId
                ? _c(
                    "div",
                    [
                      _vm.item.isShowRemove
                        ? _c(
                            "el-dropdown",
                            [
                              _c(
                                "span",
                                { staticClass: "el-dropdown-link" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "judge-button",
                                      attrs: {
                                        slot: "reference",
                                        type: "primary",
                                        size: "mini"
                                      },
                                      slot: "reference"
                                    },
                                    [
                                      _c("user-info", {
                                        attrs: {
                                          userInfo: _vm.item.adminUserInfo,
                                          isShowTooltip: _vm.isShowTooltip,
                                          newUserImg:
                                            "width: 14px; height: 14px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.orderTransfer(_vm.item)
                                        }
                                      }
                                    },
                                    [_vm._v("工单移交")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.orderCancel(_vm.item)
                                        }
                                      }
                                    },
                                    [_vm._v("取消处理")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "judge-button",
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                size: "mini"
                              },
                              slot: "reference"
                            },
                            [
                              _c("user-info", {
                                attrs: {
                                  userInfo: _vm.item.adminUserInfo,
                                  isShowTooltip: _vm.isShowTooltip,
                                  newUserImg: "width: 14px; height: 14px"
                                }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _c(
                    "el-popover",
                    {
                      attrs: {
                        trigger: "click",
                        placement: "bottom-start",
                        width: "220"
                      },
                      model: {
                        value: _vm.item.takeShow,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "takeShow", $$v)
                        },
                        expression: "item.takeShow"
                      }
                    },
                    [
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _vm._v("确定由我来处理吗？")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right", margin: "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.cancelPopover(_vm.item, _vm.index)
                                }
                              }
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.handleForMe(_vm.item)
                                }
                              }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "judge-button",
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "mini"
                          },
                          slot: "reference"
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-wolaichuli handle-icon"
                          }),
                          _vm._v("\n          我来处理")
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.item.priority === "低"
                ? _c("el-tag", { attrs: { size: "mini", type: "success" } }, [
                    _vm._v("低")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.priority === "中"
                ? _c("el-tag", { attrs: { size: "mini" } }, [_vm._v("中")])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.priority === "高"
                ? _c("el-tag", { attrs: { size: "mini", type: "warning" } }, [
                    _vm._v("高")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.priority === "急"
                ? _c("el-tag", { attrs: { size: "mini", type: "danger" } }, [
                    _vm._v("急")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "describe describe-padding",
        domProps: {
          innerHTML: _vm._s(_vm.getEmoji(_vm.item.workorderDescribe))
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-display" },
        [
          _c("user-info", {
            attrs: {
              "user-info": _vm.item.userInfo,
              "is-show-tooltip": _vm.isShowTooltip,
              "new-user-img": "width: 20px; height: 20px",
              active: _vm.activeName === _vm.index.toString()
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              class: [
                _vm.activeName === _vm.index.toString()
                  ? "common-active-color"
                  : "time"
              ]
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("parseTime")(_vm.item.createdAt, "{y}-{m}-{d} {h}:{i}")
                )
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }