var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs-left" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadScroll,
              expression: "loadScroll"
            }
          ],
          staticStyle: { height: "calc(100vh - 165px)", overflow: "auto" },
          attrs: {
            "infinite-scroll-immediate": "false",
            "infinite-scroll-disabled": "disabled"
          }
        },
        [
          _vm.list.length >= 1
            ? _c(
                "el-tabs",
                {
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "el-tab-pane",
                    { key: item.id, attrs: { name: item.id.toString() } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "order-label-item",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [
                          _c("work-order-tab-item", {
                            attrs: {
                              item: item,
                              index: index,
                              "active-name": _vm.activeName
                            },
                            on: {
                              cancelPopover: _vm.cancelPopover,
                              handleForMe: _vm.handleForMe,
                              orderTransfer: _vm.orderTransfer,
                              orderCancel: _vm.orderCancel
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isShowData
            ? _c("p", { staticClass: "common-scroll-text no-data" }, [
                _vm._v("暂无工单")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.scrollLoading
            ? _c("p", { staticClass: "common-scroll-text" }, [
                _vm._v("加载中...")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.noMore && _vm.list.length > 20
            ? _c("p", { staticClass: "common-scroll-text" }, [
                _vm._v("没有更多了")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "transfer-dialog",
          attrs: {
            title: "工单移交",
            visible: _vm.transferDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.transferDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "search-nav" },
            [
              _c("el-input", {
                staticClass: "search-input",
                attrs: { placeholder: "员工名称搜索", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.searchVal,
                  callback: function($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.transferLoad,
                      expression: "transferLoad"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { height: "100%", data: _vm.transferList }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "员工名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("user-info", {
                              attrs: {
                                userInfo: scope.row.adminUserInfo,
                                newUserImg: "width: 24px; height: 24px"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRemove(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移交")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalElements,
                  page: _vm.transferQuery.page,
                  limit: _vm.transferQuery.size,
                  "current-page": _vm.transferQuery.page
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.transferQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.transferQuery, "size", $event)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }