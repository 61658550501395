"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_Base_CardContent',
  props: {
    label: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    layoutClass: function layoutClass() {
      return {
        fullWidth: this.fullWidth
      };
    }
  }
};
exports.default = _default;