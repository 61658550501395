var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "album-work-content",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c(
        "el-row",
        { staticClass: "fill-height", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "fill-height", attrs: { span: 6 } }, [
            _c(
              "div",
              { staticClass: "directory-tree-block" },
              [
                _c("tree", {
                  attrs: {
                    disabled: _vm.isDisabled,
                    type: _vm.resourceType,
                    "open-id": _vm.openId,
                    "quantity-change": _vm.quantityChange
                  },
                  on: { "check-event": _vm.checkIdEvent }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              class: [
                "fill-height",
                _vm.isPageScroll ? "album-content-scroll" : ""
              ],
              attrs: { span: 18 }
            },
            [
              _c("div", { staticClass: "clearfix works-search-box" }, [
                _c(
                  "div",
                  { staticClass: "fl" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isDisabled,
                          size: "small"
                        },
                        on: { click: _vm.addWorks }
                      },
                      [_vm._v("添加作品")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "fr search-keywords" })
              ]),
              _vm._v(" "),
              _vm.tableData.length <= 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      staticClass: "add-works"
                    },
                    [
                      _c("ul", { staticClass: "table-item" }, [
                        _c("li", { staticClass: "fl" }, [_vm._v("作品")]),
                        _vm._v(" "),
                        _c("li", { staticClass: "fl" }, [_vm._v("类型")]),
                        _vm._v(" "),
                        _c("li", { staticClass: "fr" }, [_vm._v("操作")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "add-btn-block" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: { click: _vm.addGiftContent }
                            },
                            [_vm._v("点击此处添加作品")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      staticClass: "add-works"
                    },
                    [
                      !_vm.listLoading
                        ? _c(
                            "el-table",
                            {
                              ref: "dragTable",
                              staticClass: "resource-list-box",
                              attrs: {
                                data: _vm.tableData,
                                "row-key": _vm.getRowSkey
                              },
                              on: {
                                "row-click": _vm.selectContent,
                                "selection-change": _vm.handleSelectionChange
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "34" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "date", label: "作品" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("resource-simple", {
                                            attrs: {
                                              resourceObj: scope.row,
                                              isEdit: false
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  947519918
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  width: "80",
                                  label: "类型"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("typeFilter")(
                                                  scope.row.resourceType
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3559517877
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "address",
                                  width: "170",
                                  label: "操作"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn-action-free",
                                              attrs: {
                                                type: "text",
                                                disabled: _vm.isDisabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editResource(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn-action-free",
                                              attrs: {
                                                loading:
                                                  _vm.freeViewBtn[scope.row.id],
                                                disabled: _vm.isDisabled,
                                                type: "text"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.onFreeViewClicked(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.freeView
                                                    ? "取消试看"
                                                    : "设为试看"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "remove-btn btn-action-remove",
                                              attrs: {
                                                loading:
                                                  _vm.removeBtn[scope.row.id],
                                                disabled: _vm.isDisabled,
                                                type: "text"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("移除")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3908991884
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "batch-btn-box" },
                        [
                          _c("el-checkbox", {
                            staticClass: "check-content",
                            attrs: {
                              disabled: _vm.isDisabled,
                              indeterminate: _vm.notAll
                            },
                            on: { change: _vm.handleSelectedAll },
                            model: {
                              value: _vm.isSelectedAllContent,
                              callback: function($$v) {
                                _vm.isSelectedAllContent = $$v
                              },
                              expression: "isSelectedAllContent"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                plain: "",
                                size: "small",
                                disabled: _vm.isDisabled
                              },
                              on: { click: _vm.handlerBatchDelResource }
                            },
                            [_vm._v("批量移除")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "resource-count" }, [
                            _vm._v(" 共" + _vm._s(_vm.tableData.length) + "个")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-resource-dialog", {
        attrs: {
          visible: _vm.outerVisible,
          "exclude-data": _vm.tableData,
          "partner-open-id": _vm.partnerOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.outerVisible = $event
          },
          "add-select": _vm.onAddAlbumContent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }