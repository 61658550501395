"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_Base_PropertyBox',
  data: function data() {
    return {
      isShowProperty: false // 不必填的属性隐藏

    };
  },
  computed: {
    propertyItemArrowClass: function propertyItemArrowClass() {
      return ['property-item-arrow', this.isShowProperty ? 'el-icon-arrow-up' : 'el-icon-arrow-down'];
    }
  },
  methods: {
    propertyItemArrowClick: function propertyItemArrowClick() {
      this.isShowProperty = !this.isShowProperty;
    }
  }
};
exports.default = _default;