var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platform-micro" },
    [
      _c("micro-detail", {
        attrs: {
          "is-platform": true,
          "partner-open-id": "0",
          "height-style": "calc(100vh - 202px)"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }