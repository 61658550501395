var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outermostLayer" },
    [
      _c("div", { staticClass: "contailer" }, [
        _c("div", { staticClass: "header-bg" }, [
          _c(
            "div",
            { staticClass: "headerAddBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.addBannerBtn }
                },
                [_vm._v("添加横幅")]
              ),
              _c("i", { staticClass: "icon el-icon-info" }),
              _c("span", { staticClass: "headerIconFont" }, [
                _vm._v("相同学段年级下，前端最多可展示5个横幅。")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "searchBanner" },
            [
              _c("el-input", {
                staticClass: "searchInput",
                attrs: { placeholder: "搜索关键词", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchContent($event)
                  }
                },
                model: {
                  value: _vm.searchVal,
                  callback: function($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal"
                }
              }),
              _vm._v(" "),
              _c("search-btn", { on: { searchContent: _vm.searchContent } }, [
                _c(
                  "div",
                  { staticClass: "searchBannerBtn" },
                  [
                    _c(
                      "el-form",
                      { attrs: { "label-width": "60px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "系统" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "searchBannerSelect",
                                attrs: { size: "small", multiple: "" },
                                model: {
                                  value: _vm.appProductSearchList,
                                  callback: function($$v) {
                                    _vm.appProductSearchList = $$v
                                  },
                                  expression: "appProductSearchList"
                                }
                              },
                              _vm._l(_vm.dictionary, function(item) {
                                return _c("el-option", {
                                  key: item.openId,
                                  attrs: {
                                    label: item.title,
                                    value: item.openId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "学段" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "searchBannerSelect",
                                attrs: { size: "small", multiple: "" },
                                model: {
                                  value: _vm.searchSectionIdList,
                                  callback: function($$v) {
                                    _vm.searchSectionIdList = $$v
                                  },
                                  expression: "searchSectionIdList"
                                }
                              },
                              _vm._l(_vm.syncSectionInfo, function(item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "年级" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "searchBannerSelect",
                                attrs: { size: "small", multiple: "" },
                                model: {
                                  value: _vm.searchGradeIdList,
                                  callback: function($$v) {
                                    _vm.searchGradeIdList = $$v
                                  },
                                  expression: "searchGradeIdList"
                                }
                              },
                              _vm._l(_vm.syncGrade, function(item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "阶段" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "searchBannerSelect",
                                attrs: { size: "small", multiple: "" },
                                model: {
                                  value: _vm.searchVolumeIdList,
                                  callback: function($$v) {
                                    _vm.searchVolumeIdList = $$v
                                  },
                                  expression: "searchVolumeIdList"
                                }
                              },
                              _vm._l(_vm.syncVolume, function(item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bannerTableBox" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading"
                  }
                ],
                attrs: {
                  data: _vm.tableData,
                  "default-sort": { prop: "expiredAt", order: "descending" },
                  height: "100%"
                },
                on: { "sort-change": _vm.sortChange }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "sequence", label: "序列", "min-width": "5" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "缩略图", "min-width": "15" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.cover ||
                          scope.row.pcBannerImage ||
                          scope.row.pcAdsImage ||
                          scope.row.appAdsImage
                            ? _c("el-image", {
                                staticStyle: {
                                  width: "128px",
                                  height: "48px",
                                  "border-radius": "4px",
                                  overflow: "hidden"
                                },
                                attrs: {
                                  src: _vm.getImgUrl(
                                    scope.row.cover ||
                                      scope.row.pcBannerImage ||
                                      scope.row.pcAdsImage ||
                                      scope.row.appAdsImage
                                  ),
                                  fit: "cover"
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "横幅名称", "min-width": "15" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(scope.row.name ? scope.row.name : "--")
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "横幅展位", "min-width": "15" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.bannerCodes &&
                          scope.row.bannerCodes !== "全部"
                            ? _c(
                                "div",
                                { staticStyle: { width: "fit-content" } },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom-start",
                                        width: "200",
                                        trigger: "hover"
                                      }
                                    },
                                    [
                                      _c("div"),
                                      _vm._v(" "),
                                      _vm._l(
                                        scope.row.bannerCodes &&
                                          scope.row.bannerCodes.split(","),
                                        function(item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pageTitleSty"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.handlebannerCode(item)
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                scope.row.bannerCodes === "全部"
                                                  ? "全部页面"
                                                  : !scope.row.bannerCodes
                                                  ? "--"
                                                  : "部分页面"
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.bannerCodes === "全部"
                                        ? "全部页面"
                                        : !scope.row.bannerCodes
                                        ? "--"
                                        : "部分页面"
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "悬浮窗", "min-width": "15" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.pageCodes && scope.row.pageCodes !== "全部"
                            ? _c(
                                "div",
                                { staticStyle: { width: "fit-content" } },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom-start",
                                        width: "200",
                                        trigger: "hover"
                                      }
                                    },
                                    [
                                      _vm._l(
                                        scope.row.pageCodes &&
                                          scope.row.pageCodes.split(","),
                                        function(item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pageTitleSty"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.handlepageCode(item)
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                scope.row.pageCodes === "全部"
                                                  ? "全部页面"
                                                  : !scope.row.pageCodes
                                                  ? "--"
                                                  : "部分页面"
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.pageCodes === "全部"
                                        ? "全部页面"
                                        : !scope.row.pageCodes
                                        ? "--"
                                        : "部分页面"
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "显示模式",
                    "min-width": "10",
                    prop: "displayMode"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "跳转链接", "min-width": "30" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.linkRouter
                            ? _c("div", [_vm._v(_vm._s(scope.row.linkRouter))])
                            : scope.row.linkHref
                            ? _c("div", [_vm._v(_vm._s(scope.row.linkHref))])
                            : scope.row.pageLink
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(_vm.handlepageCode(scope.row.pageLink))
                                )
                              ])
                            : scope.row.specialPageLink
                            ? _c("div", [
                                _vm._v(_vm._s(scope.row.specialPageLink))
                              ])
                            : _c("div", [_vm._v("---")])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "点击量/展示量", "min-width": "10" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getShowQuantityAndClickQuantity(scope.row)
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "clickRate",
                    label: "点击率",
                    "min-width": "10"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.clickRate
                            ? _c("div", [
                                _vm._v(_vm._s(scope.row.clickRate) + "%")
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "expiredAt",
                    sortable: "custom",
                    label: "状态",
                    "min-width": "10"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          !scope.row.expiredAt
                            ? _c("div", { staticClass: "normal" }, [
                                _vm._v("正常")
                              ])
                            : _c("div", [
                                new Date().getTime() -
                                  new Date(scope.row.expiredAt).getTime() >
                                0
                                  ? _c("div", { staticClass: "invalid" }, [
                                      _vm._v("失效")
                                    ])
                                  : _c("div", { staticClass: "normal" }, [
                                      _vm._v("正常")
                                    ])
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "100", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editBannerBtn(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "removeBtn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.removeBanner(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footPage" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.initData
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.bannerDialogTitle,
            visible: _vm.bannerDialogShow,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.bannerDialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "bannerDialogBox" },
            [
              _c(
                "el-form",
                {
                  ref: "bannerForm",
                  attrs: {
                    rules: _vm.bannerFormRules,
                    model: _vm.bannerForm,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "横幅名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请填写横幅名称/上传图片后将自动获取"
                        },
                        model: {
                          value: _vm.bannerForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "name", $$v)
                          },
                          expression: "bannerForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "选择系统", prop: "appProductOpenIdList" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "addDialogSelect",
                          attrs: { multiple: "" },
                          on: { change: _vm.changeAppProduct },
                          model: {
                            value: _vm.bannerForm.appProductOpenIdList,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.bannerForm,
                                "appProductOpenIdList",
                                $$v
                              )
                            },
                            expression: "bannerForm.appProductOpenIdList"
                          }
                        },
                        _vm._l(_vm.dictionary, function(item) {
                          return _c("el-option", {
                            key: item.openId,
                            attrs: { label: item.title, value: item.openId }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "font-size": "12px" } }, [
                        _c("i", {
                          staticClass: "iconInfo el-icon-info",
                          staticStyle: { color: "#409EFF" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#909399" } }, [
                          _vm._v("选择横幅或广告所要显示在哪个系统")
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "Line", attrs: { label: "横幅展位" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.bannerboothSwitch,
                          callback: function($$v) {
                            _vm.bannerboothSwitch = $$v
                          },
                          expression: "bannerboothSwitch"
                        }
                      }),
                      _vm._v(" "),
                      _vm.bannerboothSwitch
                        ? _c("div", { staticClass: "lineSty" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.bannerboothSwitchradio,
                                      callback: function($$v) {
                                        _vm.bannerboothSwitchradio = $$v
                                      },
                                      expression: "bannerboothSwitchradio"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "全部" } },
                                      [_vm._v("全部")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "部分" } },
                                      [_vm._v("部分")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.bannerboothSwitchradio === "部分"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-cascader", {
                                      key: _vm.keyValue,
                                      ref: "cascader",
                                      attrs: {
                                        options: _vm.bannerboothOptions,
                                        props: {
                                          value: "code",
                                          label: "name",
                                          multiple: true
                                        },
                                        clearable: ""
                                      },
                                      on: { change: _vm.change },
                                      model: {
                                        value: _vm.bannerForm.bannerCodes,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.bannerForm,
                                            "bannerCodes",
                                            $$v
                                          )
                                        },
                                        expression: "bannerForm.bannerCodes"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "Line", attrs: { label: "悬浮窗" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.suspendedwindowswitch,
                          callback: function($$v) {
                            _vm.suspendedwindowswitch = $$v
                          },
                          expression: "suspendedwindowswitch"
                        }
                      }),
                      _vm._v(" "),
                      _vm.suspendedwindowswitch
                        ? _c("div", { staticClass: "lineSty" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.suspendedwindowswitchradio,
                                      callback: function($$v) {
                                        _vm.suspendedwindowswitchradio = $$v
                                      },
                                      expression: "suspendedwindowswitchradio"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "全部" } },
                                      [_vm._v("全部")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "部分" } },
                                      [_vm._v("部分")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.suspendedwindowswitchradio === "部分"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { clearable: "", multiple: "" },
                                        model: {
                                          value: _vm.bannerForm.pageCodes,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.bannerForm,
                                              "pageCodes",
                                              $$v
                                            )
                                          },
                                          expression: "bannerForm.pageCodes"
                                        }
                                      },
                                      _vm._l(_vm.pageLinkList, function(item) {
                                        return _c("el-option", {
                                          key: item.code,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#ff4949",
                              "margin-right": "4px"
                            }
                          },
                          [_vm._v("*")]
                        ),
                        _vm._v("图片尺寸")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { color: "#909399" } }, [
                        _vm._v("移动端(APP/H5)")
                      ]),
                      _vm._v(" "),
                      _c("coverupload", {
                        attrs: {
                          coverisshow: false,
                          coverarr: _vm.coverarr1,
                          covertext: _vm.covertext,
                          path: _vm.imagePath,
                          texttips: _vm.texttipsDown1,
                          type: "6"
                        },
                        on: { deletecover: _vm.deletecovercover },
                        model: {
                          value: _vm.bannerForm.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "cover", $$v)
                          },
                          expression: "bannerForm.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("coverupload", {
                        attrs: {
                          coverisshow: false,
                          coverarr: _vm.coverarr3,
                          covertext: _vm.covertext,
                          path: _vm.imagePath,
                          texttips: _vm.texttipsDown3,
                          type: "6"
                        },
                        on: { deletecover: _vm.deletecoverappAdsImage },
                        model: {
                          value: _vm.bannerForm.appAdsImage,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "appAdsImage", $$v)
                          },
                          expression: "bannerForm.appAdsImage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("div", { staticStyle: { color: "#909399" } }, [
                        _vm._v("电脑端(PC)")
                      ]),
                      _vm._v(" "),
                      _c("coverupload", {
                        attrs: {
                          coverisshow: false,
                          coverarr: _vm.coverarr,
                          covertext: _vm.covertext,
                          path: _vm.imagePath,
                          texttips: _vm.texttipsDown,
                          type: "6"
                        },
                        on: { deletecover: _vm.deletecover },
                        model: {
                          value: _vm.bannerForm.pcBannerImage,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "pcBannerImage", $$v)
                          },
                          expression: "bannerForm.pcBannerImage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("cover-pc", {
                        attrs: {
                          coverisshow: true,
                          coverarr: _vm.coverarr2,
                          covertext: _vm.covertext,
                          path: _vm.imagePath,
                          texttips: _vm.texttipsDown2,
                          type: "6"
                        },
                        on: { deletecover: _vm.deletecoverpcAdsImage },
                        model: {
                          value: _vm.bannerForm.pcAdsImage,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "pcAdsImage", $$v)
                          },
                          expression: "bannerForm.pcAdsImage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v("跳转模式")
                      ]),
                      _vm._v(" "),
                      !_vm.linkInfo
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.openSelectLink = true
                                }
                              }
                            },
                            [_vm._v("选择连接")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.linkInfo
                        ? _c("div", { staticClass: "link" }, [
                            _c("span", { staticClass: "link-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.linkInfo
                                    ? _vm.linkInfo.display.title
                                    : undefined
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("i", {
                                staticClass: "el-icon-sort change-link-icon",
                                on: {
                                  click: function($event) {
                                    _vm.openSelectLink = true
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete link-icon",
                                on: { click: _vm.handleDeleteLink }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("select-link", {
                        attrs: { visible: _vm.openSelectLink },
                        on: {
                          "update:visible": function($event) {
                            _vm.openSelectLink = $event
                          }
                        },
                        model: {
                          value: _vm.linkInfo,
                          callback: function($$v) {
                            _vm.linkInfo = $$v
                          },
                          expression: "linkInfo"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          staticClass: "jumpBox"
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "链接" },
                              model: {
                                value: _vm.jumpPattern,
                                callback: function($$v) {
                                  _vm.jumpPattern = $$v
                                },
                                expression: "jumpPattern"
                              }
                            },
                            [_vm._v("链接")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "路由" },
                              model: {
                                value: _vm.jumpPattern,
                                callback: function($$v) {
                                  _vm.jumpPattern = $$v
                                },
                                expression: "jumpPattern"
                              }
                            },
                            [_vm._v("路由")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "页面" },
                              model: {
                                value: _vm.jumpPattern,
                                callback: function($$v) {
                                  _vm.jumpPattern = $$v
                                },
                                expression: "jumpPattern"
                              }
                            },
                            [_vm._v("页面")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "特殊" },
                              model: {
                                value: _vm.jumpPattern,
                                callback: function($$v) {
                                  _vm.jumpPattern = $$v
                                },
                                expression: "jumpPattern"
                              }
                            },
                            [_vm._v("特殊")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { placeholder: "链接地址" },
                        model: {
                          value: _vm.bannerForm.linkHref,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "linkHref", $$v)
                          },
                          expression: "bannerForm.linkHref"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { placeholder: "(路由对象||路由地址)" },
                        model: {
                          value: _vm.bannerForm.linkRouter,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "linkRouter", $$v)
                          },
                          expression: "bannerForm.linkRouter"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          staticClass: "addDialogSelect",
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.bannerForm.pageLink,
                            callback: function($$v) {
                              _vm.$set(_vm.bannerForm, "pageLink", $$v)
                            },
                            expression: "bannerForm.pageLink"
                          }
                        },
                        _vm._l(_vm.pageLinkListJump, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          staticClass: "addDialogSelect",
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.bannerForm.specialPageLink,
                            callback: function($$v) {
                              _vm.$set(_vm.bannerForm, "specialPageLink", $$v)
                            },
                            expression: "bannerForm.specialPageLink"
                          }
                        },
                        _vm._l(_vm.specialPageLinkList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择商户" } },
                    [
                      _c("search", {
                        staticClass: "partnerInput",
                        attrs: { partnerOpenId: _vm.bannerForm.partnerOpenId },
                        on: { receivepartnerId: _vm.receivepartnerId }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属学段" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "addDialogSelect",
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.sectionIdList,
                            callback: function($$v) {
                              _vm.sectionIdList = $$v
                            },
                            expression: "sectionIdList"
                          }
                        },
                        _vm._l(_vm.syncSectionInfo, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属年级" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "addDialogSelect",
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.gradeIdList,
                            callback: function($$v) {
                              _vm.gradeIdList = $$v
                            },
                            expression: "gradeIdList"
                          }
                        },
                        _vm._l(_vm.syncGrade, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属阶段" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "addDialogSelect",
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.volumeIdList,
                            callback: function($$v) {
                              _vm.volumeIdList = $$v
                            },
                            expression: "volumeIdList"
                          }
                        },
                        _vm._l(_vm.syncVolume, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "显示模式" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "固定" },
                          model: {
                            value: _vm.displayMode,
                            callback: function($$v) {
                              _vm.displayMode = $$v
                            },
                            expression: "displayMode"
                          }
                        },
                        [_vm._v("固定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "随机" },
                          model: {
                            value: _vm.displayMode,
                            callback: function($$v) {
                              _vm.displayMode = $$v
                            },
                            expression: "displayMode"
                          }
                        },
                        [_vm._v("随机")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "addDialogSelect",
                        attrs: {
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetimerange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        on: { change: _vm.handleDate },
                        model: {
                          value: _vm.dateValue,
                          callback: function($$v) {
                            _vm.dateValue = $$v
                          },
                          expression: "dateValue"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "序列" } }, [
                    _c("div", { staticClass: "bannerDialogFoot" }, [
                      _c(
                        "div",
                        [
                          _c("el-input-number", {
                            staticClass: "bannerDialogSequence",
                            attrs: {
                              precision: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.bannerForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.bannerForm, "sequence", $$v)
                              },
                              expression: "bannerForm.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "数字越大，排位越靠前",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "icon el-icon-info" })]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bannerDialogFootBtns" },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.bannerDialogShow = false
                                }
                              }
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.submitBtnLoading
                              },
                              on: { click: _vm.submitBannerInfo }
                            },
                            [_vm._v("确 定")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }