var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isOldSearch
        ? _c("column-dialog-filter-panel", {
            attrs: {
              "type-name": _vm.typeName,
              "show-vid-search": _vm.showVidSearch,
              "is-show-area": true,
              "show-json-data": _vm.showJsonData,
              "show-download": _vm.showDownload
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        : _c("new-filter-panel", {
            attrs: {
              isbookcase: _vm.isbookcase,
              "type-name": _vm.typeName,
              isebook: _vm.isebook,
              "show-test-data": _vm.showTestData,
              "show-vid-search": _vm.showVidSearch,
              "is-show-area": true,
              "show-json-data": _vm.showJsonData,
              "show-download": _vm.showDownload
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          }),
      _vm._v(" "),
      _vm.isShowRemember
        ? _c(
            "div",
            { staticClass: "remember" },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.handleRemember },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                },
                [_vm._v("记住所选")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }