"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ProductList = _interopRequireDefault(require("../../../components/ProductMarketplace/View/ProductList"));

var _ProductListRecycleBin = _interopRequireDefault(require("../../../components/ProductMarketplace/View/ProductListRecycleBin"));

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ProductList',
  components: {
    ViewProductList: _ProductList.default,
    ViewProductListRecycleBin: _ProductListRecycleBin.default
  },
  props: {
    onlyRead: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      pageType: 'PRODUCT_LIBRARY'
    };
  },
  methods: {
    updatePageType: function updatePageType(pageType) {
      this.pageType = pageType;
    }
  }
};
exports.default = _default;