"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PAGE_DEFAULT_PARAM = void 0;
var PAGE_DEFAULT_PARAM = {
  deliveryStashFlag: 2,
  deliveryStashId: '',
  sectionCode: '',
  subjectCode: '',
  editionCode: '',
  gradeCode: '',
  volumeCode: '',
  electiveCode: '',
  areaCode: '',
  periodCode: '',
  tags: '',
  secondRegimentationIds: '',
  brandOpenId: '',
  seriesCode: '',
  suitStatus: 0,
  singleStatus: 0,
  specificJsonStatus: 0,
  showVideoStatus: 0,
  quotaStatus: 0,
  teacherBuyStatus: 0,
  buyType: '',
  payType: '',
  channel: '',
  cooperationChannelOpenId: '',
  deliveryMethod: 0,
  commodityType: 0,
  postCompanyId: '',
  userSectionCode: [],
  userGradeCode: [],
  userVolumeCode: [],
  resourceType: '',
  saleMethod: 0,
  orderCreated: [],
  payedAtCreated: [],
  inIosSandbox: false
};
exports.PAGE_DEFAULT_PARAM = PAGE_DEFAULT_PARAM;