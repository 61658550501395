"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

var _sortablejs = require("sortablejs");

var _AddExtendedDialog = _interopRequireDefault(require("@/components/Resource/ExtendedLearning/AddExtendedDialog"));

var _common = require("@/utils/common");

var _utils = require("@/utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'ExtendedLearning',
  components: {
    AddExtendedDialog: _AddExtendedDialog.default
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showSeat: {
      type: Boolean,
      default: false
    },
    isTopSeat: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      addData: {},
      addTitle: undefined
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.drag();
    });
  },
  methods: {
    initExtendedData: function initExtendedData(val) {
      if (!val) {
        return [];
      }

      val = JSON.parse(val);

      if (val.length > 0) {
        val.forEach(function (item) {
          item.id = (0, _utils.createUniqueString)();
        });
      }

      return val;
    },
    submitExtendedData: function submitExtendedData(val) {
      if (!val) {
        return null;
      }

      if (val.length <= 0) {
        return null;
      }

      if (val.length > 0) {
        val.forEach(function (item) {
          if (item) {
            delete item.id;
          }
        });
      }

      return val.length > 0 ? JSON.stringify(val) : null;
    },
    // 直接添加内容
    addContent: function addContent(type) {
      var val;

      switch (type) {
        case 'paper':
          {
            val = {
              name: '练一练',
              cover: 'material/imageList/2023-03-17/a98f144003e1d0485f6596088f5ceec3.png',
              list: [],
              isVip: false
            };
            break;
          }

        case 'vocabulary':
          {
            val = {
              name: '词语听写',
              cover: 'material/imageList/21_xx_zztb_164_【定稿】_词语听写_王鑫_2021.07.09.png',
              list: [],
              isVip: false
            };
            break;
          }

        case 'word':
          {
            val = {
              name: '单词听写',
              cover: 'material/imageList/2023-03-25/ad3f29dad153b7e055f9175d3c47da27.png',
              list: [],
              isVip: false
            };
            break;
          }

        default:
      }

      this.submit(val);
    },
    submit: function submit(val) {
      var result = JSON.parse(JSON.stringify(val));

      if (val.curIndex >= 0) {
        delete result.curIndex;
        this.$set(this.valueProxy, val.curIndex, _objectSpread({}, result));
        this.visible = false;
        return;
      }

      if (this.showSeat) {
        result = _objectSpread(_objectSpread({}, result), {}, {
          seat: this.isTopSeat ? 'top' : 'bottom'
        });
      }

      this.valueProxy.push(result);
      this.visible = false;
    },
    editRow: function editRow(val, index) {
      this.addTitle = '编辑';
      this.addData = _objectSpread(_objectSpread({}, val), {}, {
        curIndex: index
      });
      this.visible = true;
    },
    addBtn: function addBtn() {
      this.addTitle = '添加';
      this.visible = true;
      this.addData = {};
    },
    delRow: function delRow(index) {
      this.valueProxy.splice(index, 1);
    },
    drag: function drag() {
      var _this2 = this;

      var el = document.querySelectorAll('.learning-table tbody')[0];

      if (!el) {
        return;
      }

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          var arr = _this2.valueProxy;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);
          _this2.valueProxy = (0, _toConsumableArray2.default)(arr);
        }
      });
    },
    getImgUrl: function getImgUrl(url) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-256';
      return (0, _common.imageUrlFun)(url, style);
    }
  }
};
exports.default = _default2;