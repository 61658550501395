var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "general-dialog",
    {
      attrs: { title: "批量设置售价" },
      on: { confirm: _vm.confirm },
      model: {
        value: _vm.valueProxy,
        callback: function($$v) {
          _vm.valueProxy = $$v
        },
        expression: "valueProxy"
      }
    },
    [
      _c("change-price-layout", {
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }