"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rankDetails = rankDetails;
exports.pubCompositions = pubCompositions;
exports.pubDateCon = pubDateCon;
exports.backComposition = backComposition;
exports.offShelfCompositions = offShelfCompositions;
exports.tagsLists = tagsLists;
exports.genreList = genreList;
exports.specialList = specialList;
exports.prizeList = prizeList;
exports.compositionCount = compositionCount;
exports.CompositionQueryList = CompositionQueryList;
exports.addComposition = addComposition;
exports.addSpecialList = addSpecialList;
exports.updateSpecial = updateSpecial;
exports.delDateSpecial = delDateSpecial;
exports.reDateSpecial = reDateSpecial;
exports.unitList = unitList;
exports.reDateUntil = reDateUntil;
exports.delDateUntil = delDateUntil;
exports.updateUntil = updateUntil;
exports.addUntilList = addUntilList;
exports.countList = countList;
exports.reDateCount = reDateCount;
exports.delDateCount = delDateCount;
exports.updateCount = updateCount;
exports.addCountList = addCountList;
exports.tagsList = tagsList;
exports.reDateTags = reDateTags;
exports.delDateTags = delDateTags;
exports.updateTags = updateTags;
exports.addTagsList = addTagsList;
exports.delDateComposition = delDateComposition;
exports.deleteCompositions = deleteCompositions;
exports.reDateCon = reDateCon;
exports.addGenre = addGenre;
exports.upGenre = upGenre;
exports.getCon = getCon;
exports.delGenre = delGenre;
exports.addPrice = addPrice;
exports.upPrice = upPrice;
exports.delPrice = delPrice;
exports.upPrices = upPrices;
exports.addPrices = addPrices;
exports.priceList = priceList;
exports.priceLists = priceLists;
exports.delPrices = delPrices;
exports.rePrice = rePrice;
exports.rePrices = rePrices;
exports.upComposition = upComposition;
exports.moveGenre = moveGenre;
exports.pubCompositionCount = pubCompositionCount;
exports.notPubCompositionCount = notPubCompositionCount;
exports.editorCommentClassInfo = editorCommentClassInfo;
exports.queryManuscriptsNum = queryManuscriptsNum;
exports.ManuscriptsNum = ManuscriptsNum;
exports.ManuscriptsList = ManuscriptsList;
exports.manuscriptsInfo = manuscriptsInfo;
exports.contributionRecord = contributionRecord;
exports.queryTag = queryTag;
exports.commentClassification = commentClassification;
exports.addCommentClassification = addCommentClassification;
exports.delCommonClass = delCommonClass;
exports.upCommonClass = upCommonClass;
exports.delCommonInfo = delCommonInfo;
exports.getCommonInfo = getCommonInfo;
exports.recoverCompositions = recoverCompositions;
exports.addCommentClassInfo = addCommentClassInfo;
exports.upCommon = upCommon;
exports.batchExport = batchExport;
exports.countLists = countLists;
exports.specialLists = specialLists;
exports.unitLists = unitLists;
exports.rankList = rankList;
exports.addRank = addRank;
exports.upRank = upRank;
exports.upRankStatus = upRankStatus;
exports.modelExport = modelExport;
exports.modelNums = modelNums;
exports.manuscriptNums = manuscriptNums;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/composition/admin/"));
/**
 * 查询体裁列表
 * @param {Number} code
 * @returns {Promise<*>}
 */

function genreList(code) {
  return request.get("/type/?subjectCode=".concat(code));
}
/**
 * 查询专题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function specialList(params) {
  return request.get("/theme/all", {
    params: params
  });
}
/**
 * 查询专题列表(1)
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function specialLists(params) {
  return request.get("/theme/", {
    params: params
  });
}
/**
 * 查询奖项列表
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function priceList(params) {
  return request.get('/award/awardList', {
    params: params
  });
}
/**
 * 查询奖项分组列表
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function priceLists(params) {
  return request.get('/award/groupList', {
    params: params
  });
}
/**
 * 查询单元列表
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function unitList(code, params) {
  return request.get("/unit/?subjectCode=".concat(code), {
    params: params
  });
}
/**
 * 查询单元列表(1)
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function unitLists(params) {
  return request.get('/unit/list', {
    params: params
  });
}
/**
 * 添加专题列表
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addSpecialList(params) {
  return request.post('/theme/', params);
}
/**
 * 查询奖项列表
 * @param {Number} code
 * @returns {Promise<*>}
 */


function prizeList(code) {
  return request.get("/award/?subjectCode=".concat(code));
}
/**
 * 查询作文总数
 * @param {Number} code
 * @returns {Promise<*>}
 */


function compositionCount(code) {
  return request.get("/composition/count?subjectCode=".concat(code));
}
/**
 * 查询已发布作文总数
 * @param {Number} code
 * @returns {Promise<*>}
 */


function pubCompositionCount(code) {
  return request.get("/composition/count/pub?subjectCode=".concat(code));
}
/**
 * 查询未发布作文总数
 * @param {Number} code
 * @returns {Promise<*>}
 */


function notPubCompositionCount(code) {
  return request.get("/composition/count/notPub?subjectCode=".concat(code));
}
/**
 * 查询作文列表
 * @param {Object} CompositionQuery
 * @returns {Promise<*>}
 */


function CompositionQueryList(params) {
  return request.get('/composition/selectAttrs', {
    params: params
  });
}
/**
 * 添加作文
 * @param {Object} compositionInfo
 * @param {Number} code
 * @returns {Promise<*>}
 */


function addComposition(code, params) {
  return request.post("/composition/?pubStatus=".concat(code), params);
}
/**
 * 更新作文
 * @param {Object} compositionInfo
 * @param {Number} code
 * @returns {Promise<*>}
 */


function upComposition(code, codes, params) {
  return request.put("/composition/".concat(code, "?pubStatus=").concat(codes), params);
}
/**
 * 删除作文
 * @param {Object} code
 * @returns {Promise<*>}
 */


function delDateComposition(code) {
  return request.delete("/composition/".concat(code));
}
/**
 * 下架作文
 * @param {Object} code
 * @returns {Promise<*>}
 */


function backComposition(code) {
  return request.put("/composition/backout/".concat(code));
}
/**
 * 更新专题
 * @param {Object} form
 * @returns {Promise<*>}
 */


function updateSpecial(code, params) {
  return request.put("/theme/".concat(code), params);
}
/**
 * 删除专题
 * @param {Object} code
 * @returns {Promise<*>}
 */


function delDateSpecial(code) {
  return request.delete("/theme/".concat(code));
}
/**
 * 恢复专题
 * @param {Object} code
 * @returns {Promise<*>}
 */


function reDateSpecial(code) {
  return request.put("/theme/recover/".concat(code));
}
/**
 * 恢复作文
 * @param {Object} code
 * @returns {Promise<*>}
 */


function reDateCon(code) {
  return request.put("/composition/recover/".concat(code));
}
/**
 * 发布作文
 * @param {Object} code
 * @returns {Promise<*>}
 */


function pubDateCon(code) {
  return request.put("/composition/publish/".concat(code));
}
/**
 * 恢复单元
 * @param {Object} code
 * @returns {Promise<*>}
 */


function reDateUntil(code) {
  return request.put("/unit/recover/".concat(code));
}
/**
 * 删除单元
 * @param {Object} code
 * @returns {Promise<*>}
 */


function delDateUntil(code) {
  return request.delete("/unit/".concat(code));
}
/**
 * 更新单元
 * @param {Object} form
 * @returns {Promise<*>}
 */


function updateUntil(code, params) {
  return request.put("/unit/".concat(code), params);
}
/**
 * 添加单元列表
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addUntilList(params) {
  return request.post('/unit/', params);
}
/**
 * 查询字数标签
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function countList(code, params) {
  return request.get("/count/?subjectCode=".concat(code), {
    params: params
  });
}
/**
 * 查询字数标签(1)
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function countLists(code) {
  return request.get("/count/all/?subjectCode=".concat(code));
}
/**
 * 恢复字数标签
 * @param {Object} code
 * @returns {Promise<*>}
 */


function reDateCount(code) {
  return request.put("/count/recover/".concat(code));
}
/**
 * 删除字数标签
 * @param {Object} code
 * @returns {Promise<*>}
 */


function delDateCount(code) {
  return request.delete("/count/".concat(code));
}
/**
 * 更新字数标签
 * @param {Object} form
 * @returns {Promise<*>}
 */


function updateCount(code, params) {
  return request.put("/count/".concat(code), params);
}
/**
 * 添加字数标签
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addCountList(params) {
  return request.post('/count/', params);
}
/**
 * 查询标签
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function tagsList(code, params) {
  return request.get("/msRemarkTag/?subjectCode=".concat(code), {
    params: params
  });
}
/**
 * 查询所有标签
 * @param {Number} code
 * @param {Object} params
 * @returns {Promise<*>}
 */


function tagsLists(params) {
  return request.get("/msRemarkTag/list", {
    params: params
  });
}
/**
 * 恢复标签
 * @param {Object} code
 * @returns {Promise<*>}
 */


function reDateTags(code) {
  return request.put("/msRemarkTag/recover/".concat(code));
}
/**
 * 删除标签
 * @param {Object} code
 * @returns {Promise<*>}
 */


function delDateTags(code) {
  return request.delete("/msRemarkTag/".concat(code));
}
/**
 * 更新标签
 * @param {Object} form
 * @returns {Promise<*>}
 */


function updateTags(code, params) {
  return request.put("/msRemarkTag/".concat(code), params);
}
/**
 * 添加标签
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addTagsList(params) {
  return request.post('/msRemarkTag/', params);
}
/**
 * 批量删除作文
 * @param {Object} form
 * @returns {Promise<*>}composition
 */


function deleteCompositions(params) {
  return request.delete("/composition/batch?openIds=".concat(params));
}
/**
 * 批量恢复作文
 * @param {Object} form
 * @returns {Promise<*>}composition
 */


function recoverCompositions(params) {
  return request.put("/composition/recoverBatch?openIds=".concat(params));
}
/**
 * 批量发布作文
 * @param {Object} form
 * @returns {Promise<*>}composition
 */


function pubCompositions(params) {
  return request.put("/composition/pubBatch?openIds=".concat(params));
}
/**
 * 批量下架作文
 * @param {Object} form
 * @returns {Promise<*>}composition
 */


function offShelfCompositions(params) {
  return request.put("/composition/notPubBatch?openIds=".concat(params));
}
/**
 * 添加体裁
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addGenre(params) {
  return request.post('/type/', params);
}
/**
 * 移动体裁
 * @param {Number} code
 * @param {Number} code
 * @returns {Promise<*>}
 */


function moveGenre(targetCode, owncode) {
  return request.put("/type/move?targetCode=".concat(targetCode, "&ownCode=").concat(owncode));
}
/**
 * 体裁删除
 * @param {Object} form
 * @returns {Promise<*>}
 */


function delGenre(code) {
  return request.delete("/type/".concat(code));
}
/**
 * 更新体裁
 * @param {Object} form
 * @returns {Promise<*>}
 */


function upGenre(code, params) {
  return request.put("/type/".concat(code), params);
}
/**
 * 根据openId查询作文
 * @param {Object} code
 * @returns {Promise<*>}
 */


function getCon(code) {
  return request.get("/composition/".concat(code));
}
/**
 * 添加奖项
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addPrice(params) {
  return request.post('/award/', params);
}
/**
 * 更新奖项
 * @param {Object} form
 * @returns {Promise<*>}
 */


function upPrice(params) {
  return request.put('/award/', params);
}
/**
 * 更新奖项分组
 * @param {Object} form
 * @returns {Promise<*>}
 */


function upPrices(params) {
  return request.put('/award/group', params);
}
/**
 * 添加奖项分组
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addPrices(params) {
  return request.post('/award/group', params);
}
/**
 * 删除奖项分组
 * @param {Number} code
 * @returns {Promise<*>}
 */


function delPrices(code) {
  return request.delete("/award/group/".concat(code));
}
/**
 * 删除奖项
 * @param {Object} form
 * @returns {Promise<*>}
 */


function delPrice(code) {
  return request.delete("/award/".concat(code));
}
/**
 * 恢复奖项
 * @param {Object} form
 * @returns {Promise<*>}
 */


function rePrice(code) {
  return request.put("/award/".concat(code));
}
/**
 * 恢复奖项
 * @param {Object} form
 * @returns {Promise<*>}
 */


function rePrices(code) {
  return request.put("/award/group/".concat(code));
}
/**
 * 查询稿件待审核合格不合格数量
 * @param {Number} subjectCode
 * @returns {Promise<*>}
 */


function queryManuscriptsNum(code) {
  return request.get("/manuscript/counts?subjectCode=".concat(code));
}
/**
 * 查询各个学科稿件数量
 * @param {Number} subjectCode
 * @returns {Promise<*>}
 */


function ManuscriptsNum(code) {
  return request.get("/manuscript/subject/counts?subjectCode=".concat(code));
}
/**
 * 查询各个学科稿件
 * @param {Object} params
 * @returns {Promise<*>}
 */


function ManuscriptsList(params) {
  return request.get('/manuscript/manList', {
    params: params
  });
}
/**
 * 查询稿件详细内容
 * @param {Number} openId
 * @returns {Promise<*>}
 */


function manuscriptsInfo(code) {
  return request.get("/manuscript/".concat(code));
}
/**
 * 查询用户投稿记录
 * @param {Number} userOpenId
 * @returns {Promise<*>}
 */


function contributionRecord(code) {
  return request.get("/manuscript/records?userOpenId=".concat(code));
}
/**
 * 查询评论标签列表
 * @param {Object} Object
 * @returns {Promise<*>}
 */


function queryTag(params) {
  return request.get('/msRemarkTag/', {
    params: params
  });
}
/**
 * 查询点评分类列表
 * @param {code} subjectCode
 * @returns {Promise<*>}
 */


function commentClassification(code) {
  return request.get("/remark/classList?subjectCode=".concat(code));
}
/**
 * 添加点评分类
 * @param {Object} Object
 * @returns {Promise<*>}
 */


function addCommentClassification(params) {
  return request.post('remark/class', params);
}
/**
 * 删除点评分类
 * @param {Number} code
 * @returns {Promise<*>}
 */


function delCommonClass(code) {
  return request.delete("/remark/class/".concat(code));
}
/**
 * 更新点评分类
 * @param {String} reamrkClassName
 * @code {String} reamrkCode
 * @returns {Promise<*>}
 */


function upCommonClass(code, params) {
  return request.put("/remark/class/".concat(code), params);
}
/**
 * 更新点评分类详情
 * @code {Number} id
 * @returns {Promise<*>}
 */


function delCommonInfo(code) {
  return request.delete("/remark/content/".concat(code));
}
/**
 * 查询点评分类详情
 * @Object {Number} id
 * @returns {Promise<*>}
 */


function getCommonInfo(params) {
  return request.get('/remark/contentList/', {
    params: params
  });
}
/**
 * 添加点评分类详情
 * @param {Object} Object
 * @returns {Promise<*>}
 */


function addCommentClassInfo(params) {
  return request.post('remark/content', params);
}
/**
 * 修改点评分类详情
 * @param {Object} Object
 * @returns {Promise<*>}
 */


function editorCommentClassInfo(id, params) {
  return request.put("remark/content/".concat(id), params);
}
/**
 * 更新稿件
 * @param {Object} manuScript
 * @code {Object} Object
 * @returns {Promise<*>}manuscript/:manuscriptOpenId?status=0
 */


function upCommon(code, codes, params) {
  return request.put("/manuscript/".concat(code, "?status=").concat(codes), params);
}
/**
 * 稿件批量导出
 * @param {Object} params
 * @returns {Promise<*>}manuscript/:manuscriptOpenId?status=0
 */


function batchExport(params) {
  return request.get("/export/", {
    params: params
  });
}
/**
 * 范文批量导出
 * @param {Object} params
 * @returns {Promise<*>}manuscript/:manuscriptOpenId?status=0
 */


function modelExport(params) {
  return request.get("/com/export/", {
    params: params
  });
}
/**
 * 查询排行榜列表
 * @param {Object} Object
 * @returns {Promise<*>}
 */


function rankList(params) {
  return request.get('/rank/getRankLists/', {
    params: params
  });
}
/**
 * 添加排行榜
 * @param {Object} form
 * @returns {Promise<*>}
 */


function addRank(params) {
  return request.post('/rank/addRank/', params);
}
/**
 * 更新榜单
 * @param {Object} form
 * id {Number} id
 * @returns {Promise<*>}
 */


function upRank(id, params) {
  return request.put("/rank/updateRank?id=".concat(id), params);
}
/**
 * 查询榜单具体信息
 * params {params}
 * @returns {Promise<*>}
 */


function rankDetails(params) {
  return request.get("/rank/getRankInfo", {
    params: params
  });
}
/**
 * 更新榜单显示状态
 * @param {Object} form
 * @returns {Promise<*>}
 */


function upRankStatus(id, showStatus) {
  return request.put("/rank/updateStatus?id=".concat(id, "&showStatus=").concat(showStatus));
}
/**
 * 查询作文数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function modelNums(params) {
  return request.get('/composition/getCountAll', {
    params: params
  });
}
/**
 * 查询稿件数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function manuscriptNums(params) {
  return request.get('/manuscript/getCountAll', {
    params: params
  });
}