var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outerLayer" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.addAssistBtn }
            },
            [_vm._v("\n        添加协作者\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("el-input", {
                staticStyle: { "margin-right": "10px" },
                attrs: { placeholder: "搜索关键字" },
                model: {
                  value: _vm.keyword,
                  callback: function($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.getPartnerMessage }
                },
                [_vm._v("\n          搜索\n        ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "姓名", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.showVal(scope.row.name)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.showVal(scope.row.phone)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "帐号", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "userBox" },
                          [
                            scope.row.user && scope.row.user.cover
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "24px",
                                    height: "24px",
                                    "border-radius": "50%"
                                  },
                                  attrs: {
                                    src: _vm.getImgUrl(
                                      scope.row.user.cover,
                                      _vm.thumbnailStyle
                                    ),
                                    fit: "cover"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "username" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.showVal(
                                    scope.row.user && scope.row.user.displayName
                                  )
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "操作",
                  "min-width": "40",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !scope.row.founder
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "medium", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editAccoutnInfo(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n              编辑\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !scope.row.founder
                          ? _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { size: "medium", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeItem(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n              移除\n            ")]
                            )
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("\n              创建者\n            ")
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }