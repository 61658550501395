"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'AppletLink',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      rules: {
        name: [{
          required: true,
          message: '请填写页面名称',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '请填写小程序名',
          trigger: 'blur'
        }],
        appId: [{
          required: true,
          message: '请填写小程序id',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请填写小程序路径',
          trigger: 'blur'
        }],
        appSecret: [{
          required: true,
          message: '请填写小程序appSecret',
          trigger: 'blur'
        }],
        id: [{
          required: true,
          message: '请填写小程序原始id',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  }
};
exports.default = _default2;