var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input-number", {
    staticClass: "price-batch",
    attrs: { controls: false, min: 0 },
    on: { change: _vm.onInputUpdate },
    model: {
      value: _vm.amountAfterDiscount,
      callback: function($$v) {
        _vm.amountAfterDiscount = $$v
      },
      expression: "amountAfterDiscount"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }