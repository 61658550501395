"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.string.link");

var _sortablejs = require("sortablejs");

var _index = _interopRequireDefault(require("@/components/coverupload/index.vue"));

var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));

var _index2 = _interopRequireDefault(require("@/components/PublicReply/SelectLink/index"));

var _utils = require("@/utils");

var _ResourceShare = _interopRequireDefault(require("@/components/TableList/ResourceShare.vue"));

var _jumpToolLink = require("@/utils/jumpToolLink");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'AddExtendedDialog',
  components: {
    SelectLink: _index2.default,
    CoverUpload: _index.default,
    ResourceShare: _ResourceShare.default
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        link: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入栏目名称',
          trigger: 'blur'
        }],
        cover: [{
          required: true,
          message: '请选择图标',
          trigger: 'blur'
        }]
      },
      openSelect: false,
      linkData: [],
      isReplace: false,
      replaceIndex: undefined,
      isPreview: false,
      linkType: undefined,
      shareResource: {},
      isWord: false,
      tool: undefined,
      propsActiveName: undefined
    };
  },
  computed: {
    wordList: function wordList() {
      return this.form.link[0].key ? this.form.link[0].key.list : [];
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        var _this = this;

        this.isWord = false;
        this.form = JSON.parse(JSON.stringify(val));

        if (this.$refs.formData) {
          this.$refs.formData.clearValidate();
        } // 处理下link中添加id


        if (!this.form.link) {
          if (this.form.name === '练一练') {
            this.propsActiveName = 'paper';
            this.tool = '';
          }

          if (this.form.name === '词语听写') {
            this.propsActiveName = 'vocabulary';
            this.tool = 'dictation';
          }

          if (this.form.name === '单词听写') {
            this.propsActiveName = 'word';
            this.tool = 'dictation';
          }

          this.form.link = [];
          return;
        }

        if (this.form.link.length > 0) {
          this.form.link.forEach(function (item) {
            item.id = (0, _utils.createUniqueString)();
          });
          var noInclude = ['Word', 'Character', 'Vocabulary'];
          this.isWord = noInclude.includes(val.link[0].type);
          this.$nextTick(function () {
            _this.drag();
          });
        }
      }
    }
  },
  methods: {
    addSelect: function addSelect() {
      this.isReplace = false;
      this.linkData = this.form.link;
      this.replaceIndex = undefined;
      this.openSelect = true;
    },
    submitLink: function submitLink(val) {
      var _this2 = this;

      try {
        // 替换 字、词、单词直接val整体替换 其他只有一个取第一个
        if (this.isReplace && val.length > 0) {
          this.$set(this.form.link, this.replaceIndex, val[0]);
          return;
        } // 添加


        if (!Array.isArray(this.form.link)) {
          this.form.link = [];
        }

        var list = JSON.parse(JSON.stringify(this.form.link));

        if (val.length <= 0) {
          return;
        }

        var noInclude = ['Word', 'Character', 'Vocabulary'];

        if (noInclude.includes(val[0].type)) {
          this.isWord = true;
          this.form.link = val;
        } else {
          this.isWord = false; // 去重

          val.forEach(function (item) {
            var result = list.some(function (n) {
              return _this2.duplicateRemoval(n, item);
            });

            if (!result) {
              _this2.form.link.push(_objectSpread(_objectSpread({}, item), {}, {
                id: (0, _utils.createUniqueString)()
              }));
            }
          });
        }

        this.$nextTick(function () {
          _this2.drag();
        });
      } catch (e) {
        console.log(e);
      }
    },
    // 去重
    duplicateRemoval: function duplicateRemoval(val, item) {
      if (val.type === 'OpenId' && val.key.keyType === item.key.keyType) {
        return val.key.openId === item.key.openId;
      }

      if (val.type === 'Id' && val.key.keyType === item.key.keyType) {
        return val.key.id === item.key.id;
      } // 应用页面


      if (val.type === 'Page') {
        return val.key.pageCode === item.key.pageCode;
      } // 特殊链接


      if (val.type === 'Special') {
        return val.key.name === item.key.name;
      } // 外部链接


      if (val.type === 'Link') {
        return val.key.url === item.key.url && val.display.title === item.display.title;
      } // 小程序


      if (val.type === 'Applet') {
        return JSON.stringify(val.key) === JSON.stringify(item.key);
      }
    },
    showPreview: function showPreview(val) {
      if (!val) {
        return false;
      }

      var list = ['word', 'character', 'vocabulary'];
      return !(val.type === 'OpenId' && list.includes(val.key.keyType));
    },
    submit: function submit() {
      var _this3 = this;

      this.form.name = this.form.name ? this.form.name.trim() : undefined;
      this.$refs.formData.validate(function (valid) {
        if (!valid) {
          return;
        }

        try {
          _this3.form.id = _this3.form.id ? _this3.form.id : (0, _utils.createUniqueString)();

          if (_this3.form.link.length > 0) {
            _this3.form.link.forEach(function (item) {
              if (item) {
                delete item.id;
              }
            });
          }

          _this3.$emit('submit', _this3.form);
        } catch (e) {
          console.log(e);

          _this3.$notify.error({
            title: '失败',
            message: e
          });
        }
      });
    },
    // 复制
    copyResourceEncode: function copyResourceEncode(url, event) {
      if (!url) {
        return;
      }

      (0, _clipboard.default)(url, event);
    },
    previewResource: function previewResource(data) {
      var _toShareLink = (0, _jumpToolLink.toShareLink)(data),
          linkType = _toShareLink.linkType,
          shareData = _toShareLink.shareData;

      this.linkType = linkType;
      this.shareResource = shareData;
      this.isPreview = true;
    },
    drag: function drag() {
      var _this4 = this;

      var el = document.querySelectorAll('.link-table tbody')[0];

      if (!el) {
        return;
      }

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          if (_this4.isWord) {
            var arr = _this4.form.link[0].key.list;
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);
            _this4.form.link[0].key.list = arr;
          } else {
            var _arr = _this4.form.link;

            _arr.splice(e.newIndex, 0, _arr.splice(e.oldIndex, 1)[0]);

            _this4.form.link = _arr;
          }
        }
      });
    },
    // 替换
    replaceRow: function replaceRow(val, index) {
      this.isReplace = true;
      this.replaceIndex = index;
      this.linkData = this.form.link;
      this.openSelect = true;
    },
    deleteCoverFun: function deleteCoverFun() {
      this.form.cover = '';
    },
    delRow: function delRow(index) {
      var isWord = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (isWord) {
        if (this.form.link[0].key) {
          this.form.link[0].key.list.splice(index, 1);

          if (this.form.link[0].key.list.length <= 0) {
            this.form.link = [];
          }
        }

        return;
      }

      this.form.link.splice(index, 1);
    },
    cancel: function cancel() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;