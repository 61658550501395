var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "add-wrapper clearfix"
    },
    [
      _c(
        "div",
        { staticClass: "book-edit-block" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticClass: "tab-form-box",
              attrs: {
                model: _vm.book,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-container" },
                [
                  _c("h3", { staticClass: "tab-title" }, [
                    _vm._v("\n          基本信息\n          "),
                    _vm.openId && _vm.book[_vm.statusList[0].dateName]
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.book[_vm.statusList[0].dateName]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.book[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.book.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.book.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.book.resourceOpenId
                    ? _c("el-form-item", { attrs: { label: "作品ID" } }, [
                        _vm.book.resourceOpenId
                          ? _c("span", { staticClass: "title-id" }, [
                              _vm._v(_vm._s(_vm.book.resourceOpenId))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点学书名称", prop: "title" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "850px" },
                        attrs: {
                          disabled: _vm.allowEdit,
                          maxlength: 100,
                          debounce: 500,
                          "show-word-limit": "",
                          placeholder:
                            "请输入点学书名称（建议字数在30以内，不超过60个字）",
                          "fetch-suggestions": _vm.searchTitle
                        },
                        on: { select: _vm.titleSelect },
                        model: {
                          value: _vm.book.title,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "title", $$v)
                          },
                          expression: "book.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品编号" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          placeholder: "请输入作品编号",
                          disabled: _vm.allowEdit,
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.book.resourceEncode,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "resourceEncode", $$v)
                          },
                          expression: "book.resourceEncode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点学书封面", prop: "cover" } },
                    [
                      _c("coverupload", {
                        attrs: {
                          disabledEdit: _vm.allowEdit,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverarr,
                          covertext: _vm.covertext,
                          path: _vm.imagePath,
                          type: "3"
                        },
                        on: { deletecover: _vm.deletecover },
                        model: {
                          value: _vm.book.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "cover", $$v)
                          },
                          expression: "book.cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.book.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "cover", $$v)
                          },
                          expression: "book.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点学书详情" } },
                    [
                      _c("editor-view", {
                        attrs: { readonly: _vm.allowEdit },
                        model: {
                          value: _vm.book.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "detail", $$v)
                          },
                          expression: "book.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点学书介绍" } },
                    [
                      _c("upload-audio", {
                        ref: "uploadAudioControl",
                        attrs: {
                          type: "audio",
                          disabledEdit: _vm.allowEdit,
                          "audio-id": _vm.book.blankVid
                        },
                        on: { getResult: _vm.getAudio }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "可预览页数" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          disabled: _vm.allowEdit,
                          min: 0,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.book.previewPageCount,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "previewPageCount", _vm._n($$v))
                          },
                          expression: "book.previewPageCount"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "top-title" }, [_vm._v("菜单设置")]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "点学书菜单" } }, [
                    _c(
                      "div",
                      { staticClass: "menu-json" },
                      [
                        _c("div", { staticClass: "menu-item first-border" }, [
                          _c("div", { staticClass: "head" }, [_vm._v("菜单")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "menu-td" }, [
                            _vm._v("显示隐藏")
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.menuList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "menu-item" },
                            [
                              _c("div", { staticClass: "head" }, [
                                _vm._v(_vm._s(item))
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "menu-td" },
                                [
                                  _c("el-switch", {
                                    attrs: { disabled: _vm.allowEdit },
                                    model: {
                                      value: _vm.menuJson[index],
                                      callback: function($$v) {
                                        _vm.$set(_vm.menuJson, index, $$v)
                                      },
                                      expression: "menuJson[index]"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "tab-title" }, [_vm._v("作品属性")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同步" } },
                    [
                      _c("sync-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.book.tempCode,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "tempCode", $$v)
                          },
                          expression: "book.tempCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "types" } },
                    [
                      _c("works", {
                        ref: "worksView",
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        on: { "add-classtion": _vm.addclasstion }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.book.types,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "types", $$v)
                          },
                          expression: "book.types"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版次" } },
                    [
                      _c("editionbox", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.book.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "periodCode", $$v)
                          },
                          expression: "book.periodCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.book.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "periodCode", $$v)
                          },
                          expression: "book.periodCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "类目", prop: "secondRegimentationIds" }
                    },
                    [
                      _c("category-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        on: { "category-change": _vm.categoryChange },
                        model: {
                          value: _vm.book.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "secondRegimentationIds", $$v)
                          },
                          expression: "book.secondRegimentationIds"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.book.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "secondRegimentationIds", $$v)
                          },
                          expression: "book.secondRegimentationIds"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "property-box" },
                    [
                      _c("el-collapse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowProperty,
                                expression: "isShowProperty"
                              }
                            ],
                            staticClass: "property-item-bg"
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地区" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择地区"
                                    },
                                    model: {
                                      value: _vm.areaValue,
                                      callback: function($$v) {
                                        _vm.areaValue = $$v
                                      },
                                      expression: "areaValue"
                                    }
                                  },
                                  _vm._l(_vm.areaList, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择商品标签"
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.tagValue,
                                      callback: function($$v) {
                                        _vm.tagValue = $$v
                                      },
                                      expression: "tagValue"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        class: [
                          "property-item-arrow",
                          _vm.isShowProperty
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        ],
                        on: {
                          click: function($event) {
                            _vm.isShowProperty = !_vm.isShowProperty
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "tab-title" }, [_vm._v("商品设置")]),
                  _vm._v(" "),
                  _c("goodsset", {
                    ref: "commodity",
                    attrs: {
                      partnerOpenId: _vm.partnerOpenId,
                      disabledEdit: _vm.allowEdit,
                      "independent-sale": _vm.book.independentSale,
                      "resource-open-id": _vm.openId,
                      "type-name": _vm.typeName,
                      "effective-period": _vm.effectivePeriod
                    },
                    on: { "official-partner": _vm.getOfficialPartner }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("works-operation-buttton", {
            attrs: {
              openId: _vm.openId,
              allowEdit: _vm.allowEdit,
              operation: _vm.operation,
              status: _vm.book.status,
              passBtn: _vm.passBtn,
              refuseBtn: _vm.refuseBtn,
              nextLoad: _vm.nextBtn,
              restoreBtn: _vm.restoreBtn,
              publishBtn: _vm.publishBtn,
              saveBtn: _vm.saveBtn,
              saveDraftBtn: _vm.saveDraftBtn,
              "type-name": _vm.typeName
            },
            on: {
              closeView: _vm.closeView,
              updateEdit: _vm.updateEdit,
              passItem: _vm.passItem,
              handelRefuse: _vm.handelRefuse,
              handelSaveAndReview: _vm.handelSaveAndReview,
              handleBookRestore: _vm.handleBookRestore,
              saveData: _vm.saveData,
              saveDraft: _vm.saveDraft,
              nextStep: _vm.createData
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }