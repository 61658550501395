var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "live-dialog-body-wrapper" }, [
      _c("div", { staticClass: "live-body-box" }, [
        _c(
          "div",
          { staticClass: "live-search-box" },
          [
            _c(
              "el-select",
              {
                staticClass: "vip-option",
                attrs: { placeholder: "请选择", size: "small" },
                model: {
                  value: _vm.query.type,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "type", $$v)
                  },
                  expression: "query.type"
                }
              },
              _vm._l(_vm.userTypeOptions, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "btn-export",
                attrs: {
                  type: "primary",
                  size: "small",
                  disabled: _vm.total <= 0
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.batchExportUser()
                  }
                }
              },
              [_vm._v("导出用户名单")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "live-table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "live-table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.liveData,
                  height: "100%",
                  "header-cell-style": {
                    background: "#F5F7FA",
                    width: "1192px"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "用户", width: "360px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.user
                            ? _c(
                                "div",
                                { staticStyle: { width: "fit-content" } },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "user-tip-box",
                                      attrs: {
                                        effect: "dark",
                                        placement: "right-start"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "line-height": "1.5" },
                                          attrs: { slot: "content" },
                                          slot: "content"
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "用户ID：" +
                                                _vm._s(scope.row.user.openId) +
                                                "\n                      "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  cursor: "pointer"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-copy-document",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.copyOpenId(
                                                        scope.row.user.openId,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "VIP截止期：" +
                                                _vm._s(
                                                  _vm.isNull(
                                                    scope.row.user
                                                      .vipExpiredTime
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "用户状态：\n                      "
                                            ),
                                            !scope.row.user.deletedAt
                                              ? _c("span", [_vm._v("正常")])
                                              : _c("span", [_vm._v("已禁用")])
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "user-info-box" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "user-cover-bg" },
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass: "user-cover",
                                                  attrs: {
                                                    src: _vm.getImgUrl(
                                                      scope.row.user.cover,
                                                      "style/width-limit-128"
                                                    ),
                                                    alt: "",
                                                    fit: "cover"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "user-cover",
                                                        attrs: {
                                                          src: require("@/assets/book_cover.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.isVipUser(
                                                scope.row.user.vipExpiredTime
                                              )
                                                ? _c("img", {
                                                    staticClass: "vip-sty",
                                                    attrs: {
                                                      src: require("@/assets/images/VIP.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !scope.row.user.deletedAt
                                                ? _c("div", {
                                                    staticClass: "normal-sty"
                                                  })
                                                : _c("div", {
                                                    staticClass: "disable-sty"
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c("div", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.isNull(
                                                      scope.row.user.nickName
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                              scope.row.user.level === 1
                                                ? _c("span", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/user/lv1.png"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.user.level === 2
                                                ? _c("span", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/user/lv2.png"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.user.level === 3
                                                ? _c("span", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/user/lv3.png"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.user.level === 4
                                                ? _c("span", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/user/lv4.png"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.user.identity
                                                ? _c(
                                                    "span",
                                                    [
                                                      scope.row.user
                                                        .identity === "教师"
                                                        ? _c(
                                                            "el-tag",
                                                            {
                                                              attrs: {
                                                                size: "mini",
                                                                type: "success"
                                                              }
                                                            },
                                                            [_vm._v("认证教师")]
                                                          )
                                                        : _c(
                                                            "el-tag",
                                                            {
                                                              attrs: {
                                                                size: "mini"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row.user
                                                                    .identity
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "small-font-size"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.showVal(
                                                        _vm.showval(
                                                          scope.row.user
                                                            .sectionCode,
                                                          _vm.SyncSectionData
                                                        )
                                                      )
                                                    ) +
                                                    "/\n                        " +
                                                    _vm._s(
                                                      _vm.showVal(
                                                        _vm.showval(
                                                          scope.row.user
                                                            .gradeCode,
                                                          _vm.allSyncGrade
                                                        )
                                                      )
                                                    ) +
                                                    "/\n                        " +
                                                    _vm._s(
                                                      _vm.showVal(
                                                        _vm.showval(
                                                          scope.row.user
                                                            .volumeCode,
                                                          _vm.volumeCodetion
                                                        )
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticStyle: { width: "fit-content" } },
                                [
                                  _c("div", { staticClass: "user-info-box" }, [
                                    _c(
                                      "div",
                                      { staticClass: "user-cover-bg" },
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "user-cover",
                                            attrs: {
                                              src: _vm.getImgUrl(
                                                "",
                                                "style/width-limit-128"
                                              ),
                                              alt: "",
                                              fit: "cover"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "error" },
                                                slot: "error"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "user-cover",
                                                  attrs: {
                                                    src: require("@/assets/book_cover.png")
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("div", [
                                        _vm._v(
                                          "\n                      --\n                    "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "small-font-size" },
                                        [
                                          _vm._v(
                                            "\n                      --/--/--\n                    "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "手机号", width: "110" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.user
                            ? _c("span", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.isNull(scope.row.user.phone)) +
                                    "\n                "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                  --\n                "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "address", label: "实际听课时长" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.formatLongToTimeStr(
                                  scope.row.actualListenTime * 1000
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "140", label: "进入教室时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.parseTime(
                                  _vm.changeDateTime(scope.row.firstTime),
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "140", label: "离开教室时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.parseTime(
                                  _vm.changeDateTime(scope.row.lastTime),
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "80", label: "系统" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("checkClientType")(
                                  scope.row.clientType,
                                  _vm.clientTypeOption
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "城市" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.area))]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(scope.row.city))])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialog-footer-bg" },
        [
          _c("el-pagination", {
            attrs: {
              total: _vm.total,
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              "page-sizes": [10, 20, 30, 40, 50],
              layout: "total, sizes, prev, pager, next, jumper",
              background: ""
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              "size-change": _vm.handleSizeChangehis,
              "current-change": _vm.handleCurrentChangehis
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }