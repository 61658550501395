"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'tree.vue',
  props: {
    data: {
      type: Array
    },
    disabled: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      defaultProps: {
        label: 'themeName'
      },
      mouseid: ''
    };
  },
  created: function created() {},
  methods: {
    getHalfCheckEvent: function getHalfCheckEvent(node) {
      this.mouseid = node.id;
      this.$emit('getThemeCode', node.themeCode);
    }
  }
};
exports.default = _default;