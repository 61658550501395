var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["answer-item", _vm.questionIndex ? "" : "common-flex"] },
    [
      !_vm.questionIndex
        ? _c("div", { staticClass: "stem-num" }, [
            _vm._v(_vm._s(_vm.valIndex) + "、")
          ])
        : _c("div", { staticClass: "common-float stem-num" }, [
            _vm._v("（" + _vm._s(_vm.valIndex) + "）")
          ]),
      _vm._v(" "),
      _vm.typeCode === 1001 || _vm.typeCode === 1002 || _vm.typeCode === 1006
        ? _c("div", { staticClass: "answer" }, [
            _c("div", { staticClass: "answer-right" }, [
              _vm._v(
                "【解析】" +
                  _vm._s(
                    _vm.value.rightAnswerContent
                      ? _vm.value.rightAnswerContent.toUpperCase()
                      : "--"
                  )
              )
            ]),
            _vm._v(" "),
            _vm.value.analysis
              ? _c("div", {
                  staticClass: "common-editor",
                  domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.typeCode === 1003
        ? _c("div", { staticClass: "answer" }, [
            _c("div", [
              _c("div", { staticClass: "common-float" }, [_vm._v("【解析】")]),
              _vm._v(" "),
              _c("span", {
                staticClass: "common-editor",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.value.rightAnswerContent === "no"
                      ? _vm.value.optionText[0].b
                      : _vm.value.optionText[0].a
                  )
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } }),
            _vm._v(" "),
            _vm.value.analysis
              ? _c("div", {
                  staticClass: "common-editor",
                  domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.typeCode === 1004 || _vm.typeCode === 1007
        ? _c("div", { staticClass: "answer" }, [
            _c("div", { staticClass: "common-float" }, [
              _c("div", { staticClass: "common-float" }, [_vm._v("【解析】")]),
              _vm._v(" "),
              _c("div", {
                staticClass: "answer-right common-editor",
                domProps: { innerHTML: _vm._s(_vm.value.rightAnswerContent) }
              })
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "analysis common-editor",
              domProps: { innerHTML: _vm._s(_vm.value.analysis) }
            }),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.typeCode === 1005
        ? _c("div", { staticClass: "answer" }, [
            _c("div", {
              staticClass: "answer-right common-editor",
              domProps: {
                innerHTML: _vm._s("【解析】" + _vm.value.rightAnswerContent)
              }
            }),
            _vm._v(" "),
            _vm.value.analysis
              ? _c("div", {
                  staticClass: "analysis common-editor",
                  domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.typeCode === 1008
        ? _c("div", [
            _c("div", [
              _c("div", { staticClass: "common-float" }, [_vm._v("【解析】")]),
              _vm._v(" "),
              _c("div", {
                staticClass: "common-editor",
                domProps: { innerHTML: _vm._s(_vm.value.rightAnswerContent) }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } }),
            _vm._v(" "),
            _vm.value.analysis
              ? _c("div", {
                  staticClass: "common-editor",
                  domProps: { innerHTML: _vm._s(_vm.value.analysis) }
                })
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }