var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "select-ebook-dialog",
      attrs: {
        visible: _vm.visible,
        width: "1000px",
        title: "引用电子样书的封面",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancelDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "ebook-content" },
        [
          _c("div", { staticClass: "nav" }, [
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("search", {
                  attrs: { "partner-open-id": _vm.query.partnerOpenId },
                  on: { receivepartnerId: _vm.receivepartnerId }
                }),
                _vm._v(" "),
                _vm.query.partnerOpenId
                  ? _c("el-cascader", {
                      staticClass: "ebook-cascader",
                      attrs: {
                        clearable: "",
                        options: _vm.classList,
                        props: {
                          value: "openId",
                          label: "title",
                          checkStrictly: true
                        },
                        lazy: true,
                        placeholder: "分类"
                      },
                      on: { change: _vm.changeCascader },
                      model: {
                        value: _vm.classficiation,
                        callback: function($$v) {
                          _vm.classficiation = $$v
                        },
                        expression: "classficiation"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("el-input", {
                  staticClass: "nav-input",
                  attrs: { clearable: "", placeholder: "请输入电子书名称" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchResource($event)
                    }
                  },
                  model: {
                    value: _vm.query.keyword,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "keyword", $$v)
                    },
                    expression: "query.keyword"
                  }
                }),
                _vm._v(" "),
                _c(
                  "search-button",
                  { on: { searchContent: _vm.searchResource } },
                  [
                    _c("dialog-filter-panel", {
                      attrs: {
                        "type-name": "电子书",
                        isebook: true,
                        "show-download": true
                      },
                      model: {
                        value: _vm.query,
                        callback: function($$v) {
                          _vm.query = $$v
                        },
                        expression: "query"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select-list" },
            _vm._l(_vm.tableData, function(item) {
              return _c(
                "div",
                {
                  key: item.openId,
                  staticClass: "select-item",
                  on: {
                    click: function($event) {
                      return _vm.bookItem(item)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "select-cover",
                      style: {
                        height: item.coverType === 1 ? "56px" : "113px",
                        "margin-top": item.coverType === 1 ? "56px" : 0
                      }
                    },
                    [
                      _c("el-image", {
                        staticClass: "cover-image",
                        attrs: { src: _vm.getImgUrl(item), fit: "cover" }
                      }),
                      _vm._v(" "),
                      _vm.currentVal.openId === item.openId
                        ? _c("div", { staticClass: "mask" }, [
                            _c("img", {
                              staticClass: "mask-img",
                              attrs: {
                                src: require("../../../assets/images/dui.png")
                              }
                            })
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-title" }, [
                    _vm._v(_vm._s(item.title))
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "ebook-pagination",
            attrs: {
              "page-sizes": [50, 100, 150, 200],
              total: _vm.total,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getBookList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelDialog } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }