var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "copy-book-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "复制教材",
            visible: _vm.dialogVisible,
            width: "420px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ]
            },
            [
              _vm.bookInfo
                ? _c("div", { staticClass: "copy-book" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.bookInfo.name) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        { staticClass: "book-image-box" },
                        [
                          _c(
                            "div",
                            { staticClass: "book-angle" },
                            [
                              _c("div", { staticClass: "edition" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.bookInfo.periodName))
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  索引：" +
                                          _vm._s(_vm.bookInfo.id)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 名称：" + _vm._s(_vm.bookInfo.name)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 系列：" +
                                          _vm._s(_vm.bookInfo.bookSeriesName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 学段：" +
                                          _vm._s(_vm.bookInfo.sectionName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  学科：" +
                                          _vm._s(_vm.bookInfo.subjectName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 版本：" +
                                          _vm._s(_vm.bookInfo.editionName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 地区：" +
                                          _vm._s(_vm.bookInfo.bookAreaName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 年级：" +
                                          _vm._s(_vm.bookInfo.gradeName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  阶段：" +
                                          _vm._s(
                                            _vm.bookInfo.volumeName
                                              ? _vm.bookInfo.volumeName
                                              : "通用"
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 版次：" +
                                          _vm._s(_vm.bookInfo.periodName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " ISBN：" + _vm._s(_vm.bookInfo.isbn)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 发布：" +
                                          _vm._s(
                                            _vm.bookInfo.status === 0
                                              ? "否"
                                              : "是"
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v("\n                  浏览量：0"),
                                      _c("br"),
                                      _vm._v(
                                        " 教材模型：" +
                                          _vm._s(
                                            _vm.bookInfo
                                              .bookMenuExtDataModelName
                                          )
                                      ),
                                      _c("br")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-info prompt"
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.bookInfo.cover
                            ? _c("el-image", {
                                staticClass: "book-image",
                                attrs: {
                                  src: _vm.getImageUrl(_vm.bookInfo.cover),
                                  fit: "cover"
                                }
                              })
                            : _c("el-image", {
                                staticClass: "book-image",
                                attrs: {
                                  src: require("../../../assets/images/book-image.png"),
                                  fit: "cover"
                                }
                              })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "sub-content" }, [
                        _c(
                          "div",
                          { staticClass: "subtitle" },
                          [
                            _vm._v(
                              "\n              复制教材目录\n              "
                            ),
                            _c("el-switch", {
                              attrs: { "active-color": "#13ce66" },
                              on: { change: _vm.menuChange },
                              model: {
                                value: _vm.copy.copyBookMenu,
                                callback: function($$v) {
                                  _vm.$set(_vm.copy, "copyBookMenu", $$v)
                                },
                                expression: "copy.copyBookMenu"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "subtitle" },
                          [
                            _vm._v(
                              "\n              复制关联资源\n              "
                            ),
                            _c("el-switch", {
                              attrs: { "active-color": "#13ce66" },
                              on: { change: _vm.menuExtDataChange },
                              model: {
                                value: _vm.copy.copyBookMenuExtData,
                                callback: function($$v) {
                                  _vm.$set(_vm.copy, "copyBookMenuExtData", $$v)
                                },
                                expression: "copy.copyBookMenuExtData"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "subtitle" },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#F56C6C" } },
                                [_vm._v("*")]
                              ),
                              _vm._v("复制新教材的版次\n              ")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "period",
                                attrs: { placeholder: "版次", value: "" },
                                model: {
                                  value: _vm.copy.periodCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.copy, "periodCode", $$v)
                                  },
                                  expression: "copy.periodCode"
                                }
                              },
                              _vm._l(_vm.periodList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.closeDialog } }, [
                    _vm._v("取消")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.copyBookInfo }
                    },
                    [_vm._v("复制")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }