"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _detailAlbum = _interopRequireDefault(require("./detailAlbum.vue"));

//
//
//
var _default = {
  name: 'NewPage',
  components: {
    detailPage: _detailAlbum.default
  },
  methods: {}
};
exports.default = _default;