var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-reply",
      attrs: {
        "append-to-body": "",
        title: _vm.title,
        visible: _vm.visible,
        "before-close": _vm.cancelDialog,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "460px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.valueProxy, rules: _vm.linkRule } },
        [
          _vm.isEdit && _vm.businessType !== "comment"
            ? _c(
                "el-form-item",
                { attrs: { prop: "classification" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.type === 1 },
                      model: {
                        value: _vm.value.classification,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "classification", $$v)
                        },
                        expression: "value.classification"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.value, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === 1
            ? _c(
                "el-form-item",
                { attrs: { prop: "linkName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "添加链接名称", clearable: "" },
                    model: {
                      value: _vm.value.linkName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.value,
                          "linkName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "value.linkName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === 1
            ? _c(
                "el-form-item",
                { attrs: { prop: "linkSetting" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: "添加链接"
                    },
                    model: {
                      value: _vm.value.linkSetting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.value,
                          "linkSetting",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "value.linkSetting"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === 0
            ? _c(
                "el-form-item",
                { attrs: { prop: "reply" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "添加快捷常见回复",
                      autosize: { minRows: 5 },
                      clearable: ""
                    },
                    model: {
                      value: _vm.value.reply,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.value,
                          "reply",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "value.reply"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelDialog } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.detailsLoading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }