var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotion-detail-bg" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "extend-dialog-bg",
          attrs: { title: "推广详情", visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "promotion-detail-box" }, [
            _c(
              "div",
              { staticClass: "promotion-goods-box" },
              [
                _vm.detailInfo.commodityTypeId === 5
                  ? _c("el-image", {
                      staticClass: "goods-cover",
                      attrs: {
                        fit: "cover",
                        src: _vm.getImgUrl(
                          "mp/orderCover/jinxinbiCover.png",
                          _vm.thumbnailStyle
                        )
                      }
                    })
                  : _vm.detailInfo.commodityTypeId === 6
                  ? _c("el-image", {
                      staticClass: "goods-cover",
                      attrs: {
                        fit: "cover",
                        src: _vm.getImgUrl(
                          "mp/orderCover/vipCover.png",
                          _vm.thumbnailStyle
                        )
                      }
                    })
                  : _c("el-image", {
                      staticClass: "goods-cover",
                      attrs: {
                        onerror: _vm.errorUserPhoto,
                        src: _vm.getImgUrl(
                          _vm.detailInfo.commodityImage,
                          _vm.commodityImgStyle
                        ),
                        fit: "cover",
                        alt: ""
                      }
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "promotion-goods-info" }, [
                  _c("div", [
                    _c("div", { staticClass: "promotion-goods-title" }, [
                      _vm._v(_vm._s(_vm.detailInfo.commodityName))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "promotion-goods-class" }, [
                      _vm._v(_vm._s(_vm.detailInfo.commodityClassification))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-goods-remark" }, [
                    _c("span", { staticClass: "promotion-goods-tag" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getResourceTitle(_vm.detailInfo.commodityTypeId)
                        ) +
                          " | 商品ID：" +
                          _vm._s(_vm.detailInfo.commodityOpenId)
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "btn-look-detail",
                        on: {
                          click: function($event) {
                            return _vm.toLookOrder(_vm.detailInfo.orderId)
                          }
                        }
                      },
                      [_vm._v("订单详情")]
                    )
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-detail-title" }, [
              _vm._v("推广信息")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-detail-con" }, [
              _c("div", { staticClass: "promotion-info-box" }, [
                _vm._v(
                  "推广折扣：" +
                    _vm._s(_vm.detailInfo.promotionDiscount / 10) +
                    "折"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "promotion-info-box" }, [
                _vm._v(
                  "是否抢客：" +
                    _vm._s(_vm.detailInfo.ifRobCustomer === 1 ? "是" : "否")
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "promotion-info-box" }, [
                _vm._v("支付时间：" + _vm._s(_vm.detailInfo.payedAt))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-detail-title" }, [
              _vm._v("买家信息")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-detail-con" }, [
              _c("div", { staticClass: "promotion-info-box" }, [
                _c("div", { staticClass: "promotion-user-bg" }, [
                  _vm._v("\n            买家：\n            "),
                  _c(
                    "span",
                    { staticClass: "user-cover" },
                    [
                      _c("el-image", {
                        attrs: {
                          onerror: _vm.errorUserPhoto,
                          src: _vm.getImgUrl(
                            _vm.detailInfo.buyUserCover,
                            _vm.thumbnailStyle
                          ),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v("\n            " + _vm._s(_vm.detailInfo.buyUserName))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "promotion-info-box" }, [
                _vm._v("买家ID：" + _vm._s(_vm.detailInfo.buyUserOpenId))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "promotion-info-box" }, [
                _vm._v(
                  "购买方式：" + _vm._s(_vm.getBuyType(_vm.detailInfo.buyMode))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-detail-title" }, [
              _vm._v("推广佣金")
            ]),
            _vm._v(" "),
            _vm.onePromotion
              ? _c("div", { staticClass: "promotion-detail-con" }, [
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _c("div", { staticClass: "promotion-user-bg" }, [
                      _vm._v("直系推广人：\n            "),
                      _c(
                        "span",
                        { staticClass: "user-cover" },
                        [
                          _c("el-image", {
                            attrs: {
                              onerror: _vm.errorUserPhoto,
                              src: _vm.getImgUrl(
                                _vm.onePromotion.userCover,
                                _vm.thumbnailStyle
                              ),
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.onePromotion.userName) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _vm._v(
                      "佣金比例：" +
                        _vm._s(_vm.onePromotion.commissionRate) +
                        "%"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _vm._v(
                      "佣金：￥" + _vm._s(_vm.onePromotion.changePrice / 100)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.twoPromotion
              ? _c("div", { staticClass: "promotion-detail-con" }, [
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _c("div", { staticClass: "promotion-user-bg" }, [
                      _vm._v("二级推广人：\n            "),
                      _c(
                        "span",
                        { staticClass: "user-cover" },
                        [
                          _c("el-image", {
                            attrs: {
                              onerror: _vm.errorUserPhoto,
                              src: _vm.getImgUrl(
                                _vm.twoPromotion.userCover,
                                _vm.thumbnailStyle
                              ),
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.twoPromotion.userName) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _vm._v(
                      "佣金比例：" +
                        _vm._s(_vm.twoPromotion.commissionRate) +
                        "%"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _vm._v(
                      "佣金：￥" + _vm._s(_vm.twoPromotion.changePrice / 100)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.threePromotion
              ? _c("div", { staticClass: "promotion-detail-con" }, [
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _c("div", { staticClass: "promotion-user-bg" }, [
                      _vm._v("三级推广人：\n            "),
                      _c(
                        "span",
                        { staticClass: "user-cover" },
                        [
                          _c("el-image", {
                            attrs: {
                              onerror: _vm.errorUserPhoto,
                              src: _vm.getImgUrl(
                                _vm.threePromotion.userCover,
                                _vm.thumbnailStyle
                              ),
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.threePromotion.userName) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _vm._v(
                      "佣金比例：" +
                        _vm._s(_vm.threePromotion.commissionRate) +
                        "%"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "promotion-info-box" }, [
                    _vm._v(
                      "佣金：￥" + _vm._s(_vm.threePromotion.changePrice / 100)
                    )
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("order-detail", {
        staticClass: "orderDetailWrap",
        attrs: { visible: _vm.orderDetailDialog, orderId: _vm.currentorderId },
        on: {
          "update:visible": function($event) {
            _vm.orderDetailDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }