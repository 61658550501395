var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dashboard_container"
    },
    [
      _c(
        "div",
        { staticClass: "page_body" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "div",
                { staticClass: "body_box" },
                [
                  _c("div", { staticClass: "title_box" }, [
                    _vm._v("\n          基本信息\n        ")
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content_box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺名称", prop: "storeName" } },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请填写店铺名称" },
                            model: {
                              value: _vm.form.storeName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "storeName", $$v)
                              },
                              expression: "form.storeName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺logo", prop: "partnerLogo" } },
                        [
                          _c("cover-upload", {
                            attrs: {
                              path: _vm.path,
                              "partner-open-id": _vm.partnerOpenId,
                              coverarr: _vm.coverArr,
                              covertext: _vm.coverText,
                              type: "角色",
                              disabledEdit: false
                            },
                            on: { deletecover: _vm.deletecoverFun },
                            model: {
                              value: _vm.form.partnerLogo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "partnerLogo", $$v)
                              },
                              expression: "form.partnerLogo"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属省份" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input_width",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.belongProvince,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "belongProvince", $$v)
                                },
                                expression: "form.belongProvince"
                              }
                            },
                            _vm._l(_vm.optionsBelongProvince, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.fullname,
                                  value: item.code
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺简介", prop: "desc" } },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入店铺简介",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.form.desc,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "desc", $$v)
                              },
                              expression: "form.desc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "body_box",
                  staticStyle: { "margin-top": "10px" }
                },
                [
                  _c("div", { staticClass: "title_box" }, [
                    _vm._v("\n          联系我们\n        ")
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content_box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "operatorName" } },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入联系人姓名" },
                            model: {
                              value: _vm.form.operatorName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "operatorName", $$v)
                              },
                              expression: "form.operatorName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "operatorPhone" } },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入联系电话" },
                            model: {
                              value: _vm.form.operatorPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "operatorPhone", $$v)
                              },
                              expression: "form.operatorPhone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系地址", prop: "cityCodeArray" } },
                        [
                          _c("el-cascader", {
                            key: _vm.isResouceShow,
                            staticClass: "input_width",
                            attrs: {
                              placeholder: "请选择所属省市",
                              props: _vm.props,
                              options: _vm.options
                            },
                            model: {
                              value: _vm.form.cityCodeArray,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "cityCodeArray", $$v)
                              },
                              expression: "form.cityCodeArray"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "详细地址", prop: "address" } },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入详细地址" },
                            model: {
                              value: _vm.form.address,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("map-view", { ref: "mapView" })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page_foot" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }