var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "end-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "end",
          attrs: {
            title: "结束工单",
            visible: _vm.visible,
            "before-close": _vm.handleEndClose,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "common-title" }, [
            _vm._v("确定要结束此工单吗？")
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("若要结束此工单，请选择工单所属类型。")]),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.handleEndClose } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.detailsByPassed }
                },
                [_vm._v("无需处理")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.detailsResolved }
                },
                [_vm._v("已处理")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }