"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "updateConfig", {
  enumerable: true,
  get: function get() {
    return _config.updateConfig;
  }
});
Object.defineProperty(exports, "getConfig", {
  enumerable: true,
  get: function get() {
    return _config.getConfig;
  }
});
Object.defineProperty(exports, "CustomSsoException", {
  enumerable: true,
  get: function get() {
    return _CustomSsoException.default;
  }
});
Object.defineProperty(exports, "SsoException", {
  enumerable: true,
  get: function get() {
    return _SsoException.default;
  }
});
Object.defineProperty(exports, "UnauthorizedSsoException", {
  enumerable: true,
  get: function get() {
    return _UnauthorizedSsoException.default;
  }
});
Object.defineProperty(exports, "getCurrentUrl", {
  enumerable: true,
  get: function get() {
    return _host.getCurrentUrl;
  }
});
Object.defineProperty(exports, "getCurrentDomain", {
  enumerable: true,
  get: function get() {
    return _host.getCurrentDomain;
  }
});
Object.defineProperty(exports, "isEffectiveDomain", {
  enumerable: true,
  get: function get() {
    return _host.isEffectiveDomain;
  }
});
Object.defineProperty(exports, "getSsoUrl", {
  enumerable: true,
  get: function get() {
    return _host.getSsoUrl;
  }
});
Object.defineProperty(exports, "toLoginPage", {
  enumerable: true,
  get: function get() {
    return _host.toLoginPage;
  }
});
Object.defineProperty(exports, "routerIsProtect", {
  enumerable: true,
  get: function get() {
    return _router.routerIsProtect;
  }
});
Object.defineProperty(exports, "isAvailableToken", {
  enumerable: true,
  get: function get() {
    return _token.isAvailableToken;
  }
});
Object.defineProperty(exports, "getAccessToken", {
  enumerable: true,
  get: function get() {
    return _token.getAccessToken;
  }
});
Object.defineProperty(exports, "setAccessToken", {
  enumerable: true,
  get: function get() {
    return _token.setAccessToken;
  }
});
Object.defineProperty(exports, "removeAccessToken", {
  enumerable: true,
  get: function get() {
    return _token.removeAccessToken;
  }
});
Object.defineProperty(exports, "getRefreshToken", {
  enumerable: true,
  get: function get() {
    return _token.getRefreshToken;
  }
});
Object.defineProperty(exports, "setRefreshToken", {
  enumerable: true,
  get: function get() {
    return _token.setRefreshToken;
  }
});
Object.defineProperty(exports, "removeRefreshToken", {
  enumerable: true,
  get: function get() {
    return _token.removeRefreshToken;
  }
});
Object.defineProperty(exports, "isLoggedIn", {
  enumerable: true,
  get: function get() {
    return _auth.isLoggedIn;
  }
});
Object.defineProperty(exports, "isLoggedInStrict", {
  enumerable: true,
  get: function get() {
    return _auth.isLoggedInStrict;
  }
});
Object.defineProperty(exports, "attachAuthorization", {
  enumerable: true,
  get: function get() {
    return _auth.attachAuthorization;
  }
});
Object.defineProperty(exports, "onUnauthorizedError", {
  enumerable: true,
  get: function get() {
    return _auth.onUnauthorizedError;
  }
});
Object.defineProperty(exports, "clearLoginStatus", {
  enumerable: true,
  get: function get() {
    return _auth.clearLoginStatus;
  }
});
Object.defineProperty(exports, "isRefreshTokenExpired", {
  enumerable: true,
  get: function get() {
    return _util.isRefreshTokenExpired;
  }
});
Object.defineProperty(exports, "isAccessTokenExpired", {
  enumerable: true,
  get: function get() {
    return _util.isAccessTokenExpired;
  }
});

var _config = require("./config");

var _CustomSsoException = _interopRequireDefault(require("./util/errors/CustomSsoException"));

var _SsoException = _interopRequireDefault(require("./util/errors/SsoException"));

var _UnauthorizedSsoException = _interopRequireDefault(require("./util/errors/UnauthorizedSsoException"));

var _host = require("./util/host");

var _router = require("./util/router");

var _token = require("./util/token");

var _auth = require("./auth");

var _util = require("./util/util");