"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _sectionList = _interopRequireDefault(require("@/components/BookPageSection/sectionList.vue"));

var _pageAudio = _interopRequireDefault(require("@/components/BookPageSection/pageAudio.vue"));

//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
var _default = {
  name: 'Index',
  components: {
    sectionList: _sectionList.default,
    pageAudio: _pageAudio.default
  },
  data: function data() {
    return {};
  }
};
exports.default = _default;