var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container-mannger" }, [
    _c(
      "div",
      { staticClass: "dashboard-text" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function($event) {
                return _vm.addPermissionMenu("addClassiFication")
              }
            }
          },
          [_vm._v("添加权限")]
        ),
        _vm._v(" "),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择系统" },
            on: { change: _vm.changeSelectedSystem },
            model: {
              value: _vm.addClassiFicationForm.appProductOpenId,
              callback: function($$v) {
                _vm.$set(_vm.addClassiFicationForm, "appProductOpenId", $$v)
              },
              expression: "addClassiFicationForm.appProductOpenId"
            }
          },
          _vm._l(_vm.subordinateSystemOptions, function(item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.title, value: item.value }
            })
          }),
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tableData" },
      [
        _vm.showTable
          ? _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                ref: "multipleTable",
                staticStyle: { width: "100%", overflow: "auto" },
                attrs: {
                  data: _vm.tableData,
                  "tooltip-effect": "dark",
                  "row-key": "id",
                  lazy: "",
                  height: "100%",
                  load: _vm.load,
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "title", label: "名称", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                class:
                                  scope.row.parentOpenId && scope.row.hidden
                                    ? "paddingLetSty"
                                    : "paddingLet"
                              },
                              [_vm._v(" " + _vm._s(scope.row.title))]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1336412071
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "英文名", width: "200" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "sequence", label: "排序", width: "180" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.showEditFileSort &&
                            _vm.currentIndex === scope.$index
                              ? _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { margin: "5px 5px" },
                                      attrs: {
                                        min: 0,
                                        size: "mini",
                                        "controls-position": "right"
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.saveSort(scope.row)
                                        }
                                      },
                                      model: {
                                        value: scope.row.sequence,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "sequence", $$v)
                                        },
                                        expression: "scope.row.sequence"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-close",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "font-size": "24px",
                                        color: "#F56c6c",
                                        cursor: "pointer"
                                      },
                                      on: { click: _vm.cancelSort }
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-check",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "font-size": "24px",
                                        color: "#13ce66",
                                        cursor: "pointer"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveSort(scope.row)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "5px 10px",
                                      margin: "5px"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.sortEdit(
                                          scope.row,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.sequence))]
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4183535489
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "path", label: "页面路径", width: "250" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "component", label: "依赖组件", width: "300" }
                }),
                _vm._v(" "),
                _vm.addClassiFicationForm.appProductOpenId ===
                "sysdef2019062617494extnsr6"
                  ? _c("el-table-column", {
                      attrs: { prop: "protect", width: "150" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n            默认权限(书)\n            "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: " 默认保留知识店铺号权限",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconInfo el-icon-info"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: { "active-color": "#13ce66" },
                                  on: {
                                    change: function($event) {
                                      return _vm.setBookNetwork(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.bookNetwork,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bookNetwork", $$v)
                                    },
                                    expression: "scope.row.bookNetwork"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1298999554
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.addClassiFicationForm.appProductOpenId ===
                "sysdef2019062617494extnsr6"
                  ? _c("el-table-column", {
                      attrs: { prop: "protect", width: "150" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n            默认权限(知)\n            "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: " 默认保留书联网权限",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconInfo el-icon-info"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: { "active-color": "#13ce66" },
                                  on: {
                                    change: function($event) {
                                      return _vm.setKnowledgeShop(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.knowledgeShop,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "knowledgeShop", $$v)
                                    },
                                    expression: "scope.row.knowledgeShop"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3600570395
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.addClassiFicationForm.appProductOpenId ===
                "sysdef2019062617494extnsr6"
                  ? _c("el-table-column", {
                      attrs: { prop: "protect", width: "150" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n            默认权限(中盘)\n            "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: " 默认保留中盘端权限",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconInfo el-icon-info"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: { "active-color": "#13ce66" },
                                  on: {
                                    change: function($event) {
                                      return _vm.setKnowledgeShop(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.bookMidCap,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bookMidCap", $$v)
                                    },
                                    expression: "scope.row.bookMidCap"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2218762143
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.addClassiFicationForm.appProductOpenId ===
                "sysdef2019062617494extnsr6"
                  ? _c("el-table-column", {
                      attrs: { prop: "protect", width: "150" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n            默认权限(商户)\n            "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: " 默认保留商户端权限",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconInfo el-icon-info"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: { "active-color": "#13ce66" },
                                  on: {
                                    change: function($event) {
                                      return _vm.setKnowledgeShop(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.bookStore,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bookStore", $$v)
                                    },
                                    expression: "scope.row.bookStore"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1132763058
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "protect",
                    label: "是否验证登录",
                    width: "150"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-switch", {
                              attrs: { "active-color": "#13ce66" },
                              on: {
                                change: function($event) {
                                  return _vm.handleCheckLogin(scope.row)
                                }
                              },
                              model: {
                                value: scope.row.protect,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "protect", $$v)
                                },
                                expression: "scope.row.protect"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    476118941
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "hidden", label: "是否隐藏", width: "150" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-switch", {
                              attrs: { "active-color": "#13ce66" },
                              on: {
                                change: function($event) {
                                  return _vm.handleHidden(scope.row)
                                }
                              },
                              model: {
                                value: scope.row.hidden,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "hidden", $$v)
                                },
                                expression: "scope.row.hidden"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    99007251
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "privilegeOpenId",
                    label: "openId",
                    width: "220"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    fixed: "right",
                    prop: "address",
                    align: "right",
                    label: "操作",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEdit(
                                      scope.$index,
                                      scope.row,
                                      "addClassiFication"
                                    )
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            scope.row.deletedAt
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#e6a23c" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleRestore(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("恢复")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !scope.row.deletedAt
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#f56c6c" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDelete(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("移除")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3473560362
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.classdialogTitle,
              visible: _vm.classificationDialog,
              width: "600px",
              "close-on-click-modal": false,
              "close-on-press-escape": false
            },
            on: {
              "update:visible": function($event) {
                _vm.classificationDialog = $event
              }
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "addClassiFication",
                staticStyle: { height: "500px", overflow: "auto" },
                attrs: {
                  model: _vm.addClassiFicationForm,
                  "label-width": "125px"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "父级权限" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择父级菜单",
                          disabled:
                            !_vm.addClassiFicationForm.parentOpenId &&
                            _vm.classdialogTitle !== "添加权限"
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleParentOpenId()
                          }
                        },
                        model: {
                          value: _vm.addClassiFicationForm.parentOpenId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addClassiFicationForm,
                              "parentOpenId",
                              $$v
                            )
                          },
                          expression: "addClassiFicationForm.parentOpenId"
                        }
                      },
                      _vm._l(_vm.tableData1, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            disabled:
                              !item.privilegeOpenId &&
                              _vm.classdialogTitle !== "添加权限",
                            label: item.title,
                            value: item.privilegeOpenId
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "title",
                      label: "权限名称",
                      rules: [{ required: true, message: "请输入权限名称" }]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "input" },
                      model: {
                        value: _vm.addClassiFicationForm.title,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "title", $$v)
                        },
                        expression: "addClassiFicationForm.title"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "name",
                      label: "英文名",
                      rules: [{ required: true, message: "请输入英文名称" }]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "input" },
                      model: {
                        value: _vm.addClassiFicationForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "name", $$v)
                        },
                        expression: "addClassiFicationForm.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "path",
                      label: "页面路径",
                      rules: [{ required: false, message: "请输入页面路径" }]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "input" },
                      model: {
                        value: _vm.addClassiFicationForm.path,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "path", $$v)
                        },
                        expression: "addClassiFicationForm.path"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "component",
                      label: "依赖组件",
                      rules: [
                        { required: false, message: "请输入所依赖的组件" }
                      ]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "input" },
                      model: {
                        value: _vm.addClassiFicationForm.component,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "component", $$v)
                        },
                        expression: "addClassiFicationForm.component"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "redirect",
                      label: "重定向路径",
                      rules: [{ required: false, message: "请输入重定向路径" }]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "input" },
                      model: {
                        value: _vm.addClassiFicationForm.redirect,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "redirect", $$v)
                        },
                        expression: "addClassiFicationForm.redirect"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "protect",
                      label: "是否验证登录",
                      rules: [{ required: false }]
                    }
                  },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66" },
                      model: {
                        value: _vm.addClassiFicationForm.protect,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "protect", $$v)
                        },
                        expression: "addClassiFicationForm.protect"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "icon",
                      label: "菜单图标",
                      rules: [{ required: false, message: "请输入菜单图标" }]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "input" },
                      model: {
                        value: _vm.addClassiFicationForm.icon,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "icon", $$v)
                        },
                        expression: "addClassiFicationForm.icon"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.addClassiFicationForm.appProductOpenId ===
                "sysdef2019062617494extnsr6"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "keepAlive",
                          rules: [{ required: false }]
                        }
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "span",
                              [
                                _vm._v(
                                  "\n              默认权限(书)\n              "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: " 默认保留书联网权限",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconInfo el-icon-info"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: { "active-color": "#13ce66" },
                          model: {
                            value: _vm.addClassiFicationForm.bookNetwork,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addClassiFicationForm,
                                "bookNetwork",
                                $$v
                              )
                            },
                            expression: "addClassiFicationForm.bookNetwork"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addClassiFicationForm.appProductOpenId ===
                "sysdef2019062617494extnsr6"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "keepAlive",
                          rules: [{ required: false }]
                        }
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "span",
                              [
                                _vm._v(
                                  "\n              默认权限(知)\n              "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: " 默认保留知识店铺号权限",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconInfo el-icon-info"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: { "active-color": "#13ce66" },
                          model: {
                            value: _vm.addClassiFicationForm.knowledgeShop,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addClassiFicationForm,
                                "knowledgeShop",
                                $$v
                              )
                            },
                            expression: "addClassiFicationForm.knowledgeShop"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "keepAlive",
                      label: "页面是否缓存",
                      rules: [{ required: false }]
                    }
                  },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66" },
                      model: {
                        value: _vm.addClassiFicationForm.keepAlive,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "keepAlive", $$v)
                        },
                        expression: "addClassiFicationForm.keepAlive"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "hidden",
                      label: "是否隐藏",
                      rules: [{ required: false }]
                    }
                  },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66" },
                      model: {
                        value: _vm.addClassiFicationForm.hidden,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "hidden", $$v)
                        },
                        expression: "addClassiFicationForm.hidden"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "noCache",
                      label: "是否关闭路由缓存",
                      rules: [{ required: false }]
                    }
                  },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66" },
                      model: {
                        value: _vm.addClassiFicationForm.noCache,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "noCache", $$v)
                        },
                        expression: "addClassiFicationForm.noCache"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "breadcrumb",
                      label: "面包屑导航",
                      rules: [{ required: false }]
                    }
                  },
                  [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66" },
                      model: {
                        value: _vm.addClassiFicationForm.breadcrumb,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "breadcrumb", $$v)
                        },
                        expression: "addClassiFicationForm.breadcrumb"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "sequenceNum", attrs: { label: "排序" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "200px" },
                      attrs: { min: 0, "controls-position": "right" },
                      model: {
                        value: _vm.addClassiFicationForm.sequence,
                        callback: function($$v) {
                          _vm.$set(_vm.addClassiFicationForm, "sequence", $$v)
                        },
                        expression: "addClassiFicationForm.sequence"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.cancelEditclass("addClassiFication")
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      plain: "",
                      loading: _vm.loadingBtn
                    },
                    on: {
                      click: function($event) {
                        return _vm.addClassiFication("addClassiFication")
                      }
                    }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.tableData.length
          ? _c(
              "div",
              { staticClass: "elPagination" },
              [
                _c("el-pagination", {
                  staticStyle: { float: "right" },
                  attrs: {
                    background: "",
                    "current-page": _vm.query.page,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _vm.query.size,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.totalElements
                  },
                  on: {
                    "update:currentPage": function($event) {
                      return _vm.$set(_vm.query, "page", $event)
                    },
                    "update:current-page": function($event) {
                      return _vm.$set(_vm.query, "page", $event)
                    },
                    "update:pageSize": function($event) {
                      return _vm.$set(_vm.query, "size", $event)
                    },
                    "update:page-size": function($event) {
                      return _vm.$set(_vm.query, "size", $event)
                    },
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }