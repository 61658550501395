var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticClass: "body-bre" },
          [
            _vm.breadcrumb.length > 0
              ? _c(
                  "el-breadcrumb",
                  { attrs: { "separator-class": "el-icon-arrow-right" } },
                  _vm._l(_vm.breadcrumb, function(item, index) {
                    return _c(
                      "el-breadcrumb-item",
                      {
                        key: index,
                        attrs: {
                          to:
                            "/samplebook/content?openId=" +
                            item.openId +
                            "&title=" +
                            item.title
                        }
                      },
                      [_vm._v(_vm._s(item.title))]
                    )
                  }),
                  1
                )
              : _c(
                  "el-breadcrumb",
                  { attrs: { "separator-class": "el-icon-arrow-right" } },
                  [
                    _c("el-breadcrumb-item", [
                      _vm._v(_vm._s(_vm.parentInfo.title))
                    ])
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n        显示名称\n        "),
                _c("el-switch", {
                  staticStyle: { margin: "0 5px" },
                  on: { change: _vm.changeShowTitle },
                  model: {
                    value: _vm.isShowTitle,
                    callback: function($$v) {
                      _vm.isShowTitle = $$v
                    },
                    expression: "isShowTitle"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      width: "200",
                      trigger: "hover",
                      content:
                        "开启则用户能看到名称，关闭后用户则无法看到名称。此开关控制书橱下的所有內容（子书橱、电子书）。目前仅对用户端起作用，管理端无效"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-question",
                      staticStyle: { "font-size": "16px", color: "#409EFF" },
                      attrs: { slot: "reference" },
                      slot: "reference"
                    })
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.contentLoading,
                expression: "contentLoading"
              }
            ],
            staticClass: "content-list"
          },
          [
            _c(
              "draggable",
              {
                staticClass: "row",
                attrs: {
                  filter: ".forbid",
                  chosenClass: "chosen",
                  forceFallback: "true",
                  group: "people",
                  animation: "300"
                },
                on: { start: _vm.onStart, end: _vm.onEnd },
                model: {
                  value: _vm.bookList,
                  callback: function($$v) {
                    _vm.bookList = $$v
                  },
                  expression: "bookList"
                }
              },
              _vm._l(_vm.bookList, function(item, index) {
                return _c(
                  "div",
                  { key: item.openId, staticClass: "book-wrap" },
                  [
                    item.one
                      ? _c("div", { staticClass: "forbid" }, [
                          _c(
                            "div",
                            { staticClass: "book-cover book-cover-none" },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _vm._v("+")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "book-cover-show" }, [
                                _c("div", { staticClass: "book-cover-btns" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn btn1",
                                      on: {
                                        click: function($event) {
                                          return _vm.addBookcase(0)
                                        }
                                      }
                                    },
                                    [_vm._v("添加子书橱")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn btn2",
                                      on: {
                                        click: function($event) {
                                          return _vm.addEBook(0)
                                        }
                                      }
                                    },
                                    [_vm._v("添加电子书")]
                                  )
                                ])
                              ])
                            ]
                          )
                        ])
                      : item.type === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "1.5" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.syncInfoText) +
                                        "/"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(item.categoryInfoText) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("bookcase-content-item", {
                                  attrs: { item: item },
                                  on: {
                                    deleteBookcase: _vm.deleteBookcase,
                                    openBookCase: _vm.openBookCase,
                                    editBookcase: _vm.editBookcase
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c("bookcase-content-item", {
                          attrs: { item: item },
                          on: {
                            deleteBookcase: _vm.deleteBookcase,
                            openBookCase: _vm.openBookCase,
                            editBookcase: _vm.editBookcase
                          }
                        })
                  ],
                  1
                )
              }),
              0
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer-btn" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.backCase } },
              [_vm._v("退出书橱")]
            ),
            _vm._v(" "),
            _vm.breadcrumb.length >= 2
              ? _c(
                  "el-button",
                  {
                    staticClass: "back-btn",
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.backLast }
                  },
                  [_vm._v("返回上级书橱")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isEdit ? "编辑书橱" : "添加子书橱",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ebookInfo",
              attrs: {
                model: _vm.ebookInfo,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级书橱" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "/",
                      disabled: "",
                      maxlength: "100",
                      "show-word-limit": ""
                    },
                    on: { change: _vm.titleChange },
                    model: {
                      value: _vm.partnerTitle,
                      callback: function($$v) {
                        _vm.partnerTitle = $$v
                      },
                      expression: "partnerTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "书橱名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请填写书橱名称",
                      disabled: _vm.allowEdit,
                      maxlength: "100",
                      "show-word-limit": ""
                    },
                    on: { change: _vm.titleChange },
                    model: {
                      value: _vm.ebookInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "title", $$v)
                      },
                      expression: "ebookInfo.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "书橱介绍" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请输入书橱介绍",
                      disabled: _vm.allowEdit,
                      type: "textarea"
                    },
                    model: {
                      value: _vm.ebookInfo.introduction,
                      callback: function($$v) {
                        _vm.$set(_vm.ebookInfo, "introduction", $$v)
                      },
                      expression: "ebookInfo.introduction"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "书橱封面" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ebookInfo.coverReferenceType,
                        callback: function($$v) {
                          _vm.$set(_vm.ebookInfo, "coverReferenceType", $$v)
                        },
                        expression: "ebookInfo.coverReferenceType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("引用电子书封面")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("使用推荐封面")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("自定义封面")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 1
                    ? _c("select-ebook-cover", {
                        model: {
                          value: _vm.ebookInfo,
                          callback: function($$v) {
                            _vm.ebookInfo = $$v
                          },
                          expression: "ebookInfo"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 2
                    ? _c("div", { staticClass: "recommend-cover" }, [
                        _vm.ebookInfo.coverType === 1
                          ? _c("img", {
                              staticClass: "recommend-img",
                              attrs: {
                                src: require("../../../assets/ebook-case-default.png")
                              }
                            })
                          : _c("img", {
                              staticClass: "recommend-img",
                              attrs: {
                                src: require("../../../assets/ebook-default.png")
                              }
                            }),
                        _vm._v(" "),
                        _c("div", { staticClass: "recommend-title" }, [
                          _vm._v(_vm._s(_vm.ebookInfo.title))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 3
                    ? _c(
                        "div",
                        { staticClass: "select-cover" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: {
                                change: function($event) {
                                  return _vm.selectCoverType("2")
                                }
                              },
                              model: {
                                value: _vm.ebookInfo.coverType,
                                callback: function($$v) {
                                  _vm.$set(_vm.ebookInfo, "coverType", $$v)
                                },
                                expression: "ebookInfo.coverType"
                              }
                            },
                            [_vm._v("纵向")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: {
                                change: function($event) {
                                  return _vm.selectCoverType("1")
                                }
                              },
                              model: {
                                value: _vm.ebookInfo.coverType,
                                callback: function($$v) {
                                  _vm.$set(_vm.ebookInfo, "coverType", $$v)
                                },
                                expression: "ebookInfo.coverType"
                              }
                            },
                            [_vm._v("横向")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ebookInfo.coverReferenceType === 3
                    ? _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: _vm.E_BOOK_ID.toString(),
                          "disabled-edit": _vm.allowEdit
                        },
                        on: { deletecover: _vm.deletecoverFun },
                        model: {
                          value: _vm.ebookInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.ebookInfo, "cover", $$v)
                          },
                          expression: "ebookInfo.cover"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bottom-btns",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.onCancle } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveing },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-ebook-item-dialog", {
        ref: "selectEbookItem",
        attrs: {
          visible: _vm.dialogSelect,
          "partner-open-id": _vm.partnerOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogSelect = $event
          },
          onSubmitSelect: _vm.onSubmitSelect
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.deleteDialog, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialog = $event
            }
          }
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.deleteInfo.type == 2
                  ? "是否删除此书橱及书橱下的所有内容？"
                  : "是否删除此电子书的所有内容？"
              )
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteAllBook }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }