"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookHandler = bookHandler;
exports.commBCHandler = commBCHandler;
exports.getAllSyncSection = getAllSyncSection;
exports.getAllSyncGrade = getAllSyncGrade;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)(process.env.VUE_APP_TAOZHI_API);

function bookHandler(op) {
  return request.get('/_Source/BookHandler.ashx', {
    params: {
      op: op
    }
  });
}

function commBCHandler(op) {
  return request.get('/_Source/Comm_BCHandler.ashx', {
    params: {
      op: op
    }
  });
}
/* 学段接口 */


function getAllSyncSection(op) {
  return request.post('/_Source/BookHandler.ashx', undefined, {
    params: {
      op: op
    }
  });
}
/* 年级接口 */


function getAllSyncGrade(op) {
  return request.post('/_Source/BookHandler.ashx', undefined, {
    params: {
      op: op
    }
  });
}