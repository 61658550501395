var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cash-check-wrapper" },
    [
      _c("div", { staticClass: "cash-check-header" }, [
        _c(
          "div",
          { staticClass: "cash-search-bg" },
          [
            _c("promoter", {
              attrs: { partnerOpenId: _vm.searchQuery.promoterOpenId },
              on: { receiveUserOpenId: _vm.getPromoter }
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "search-txt",
              attrs: { placeholder: "订单号", clearable: "" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchContent($event)
                }
              },
              model: {
                value: _vm.searchQuery.orderId,
                callback: function($$v) {
                  _vm.$set(_vm.searchQuery, "orderId", $$v)
                },
                expression: "searchQuery.orderId"
              }
            }),
            _vm._v(" "),
            _c("search-btn", { on: { searchContent: _vm.searchContent } }, [
              _c(
                "div",
                { staticClass: "cash-search-btn" },
                [
                  _c(
                    "el-form",
                    { attrs: { "label-width": "80px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购买时间" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "pay-date-bg",
                            attrs: {
                              type: "daterange",
                              "default-time": ["00:00:00", "23:59:59"],
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.payTime,
                              callback: function($$v) {
                                _vm.payTime = $$v
                              },
                              expression: "payTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "search-type-bg",
                              attrs: { size: "small", multiple: "" },
                              model: {
                                value: _vm.commodityTypeIds,
                                callback: function($$v) {
                                  _vm.commodityTypeIds = $$v
                                },
                                expression: "commodityTypeIds"
                              }
                            },
                            _vm._l(_vm.resourceTypeList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.title, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "search-order-state",
                              model: {
                                value: _vm.searchQuery.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchQuery, "status", $$v)
                                },
                                expression: "searchQuery.status"
                              }
                            },
                            _vm._l(_vm.stateList, function(item) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: item.value,
                                  attrs: { label: item.value }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cash-check-content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: {
                data: _vm.promotionRecordList,
                width: "100%",
                height: "100%"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "交易时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.payedAt
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("parseTime")(
                                    scope.row.payedAt,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                )
                              )
                            ])
                          : _c("span", [_vm._v("--")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getResourceTitle(scope.row.commodityTypeId)
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.orderId) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "购买人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "user-info-bg" }, [
                          _c(
                            "div",
                            { staticClass: "user-cover-bg" },
                            [
                              _c("el-image", {
                                attrs: {
                                  onerror: _vm.errorUserPhoto,
                                  src: _vm.getImgUrl(
                                    scope.row.buyUserCover,
                                    _vm.thumbnailStyle
                                  ),
                                  fit: "cover"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "user-info-name" }, [
                            _vm._v(_vm._s(scope.row.buyUserName))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分销人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "user-info-bg" }, [
                          _c(
                            "div",
                            { staticClass: "user-cover-bg" },
                            [
                              _c("el-image", {
                                attrs: {
                                  onerror: _vm.errorUserPhoto,
                                  src: _vm.getImgUrl(
                                    scope.row.promoterCover,
                                    _vm.thumbnailStyle
                                  ),
                                  fit: "cover"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "user-info-name" }, [
                            _vm._v(_vm._s(scope.row.promoterName))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "80", label: "抢客订单" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.ifRobCustomer === 1 ? "是" : "否"
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分销商品" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.commodityName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "优惠折扣" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.promotionDiscount
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.promotionDiscount / 10) + "折"
                              )
                            ])
                          : _c("span", [_vm._v("无")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "交易金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          ￥" +
                            _vm._s(
                              _vm.numFormat(
                                (scope.row.commodityPrice / 100).toFixed(2)
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "佣金比例" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.commissionRate) +
                            "%\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          ￥" +
                            _vm._s(
                              _vm.numFormat(
                                (scope.row.commission / 100).toFixed(2)
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "80", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", [_vm._v("已完结")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2
                          ? _c("span", { staticClass: "txt-refund" }, [
                              _vm._v("已退款")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "50",
                  label: "操作",
                  fixed: "right",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-detail",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toLookDetail(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "cash-check-pages" }, [
        _c("div", { staticClass: "cash-pages-l" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cash-pages-r" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.searchQuery.page,
                limit: _vm.searchQuery.size,
                "current-page": _vm.searchQuery.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.searchQuery, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.searchQuery, "size", $event)
                },
                pagination: _vm.getPromotionRecordList
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("promotion-detail", {
        attrs: {
          isShowPromotionDetail: _vm.isShowPromotionDetail,
          promotionRecordId: _vm.promotionRecordId
        },
        on: {
          "update:isShowPromotionDetail": function($event) {
            _vm.isShowPromotionDetail = $event
          },
          "update:is-show-promotion-detail": function($event) {
            _vm.isShowPromotionDetail = $event
          },
          "update:promotionRecordId": function($event) {
            _vm.promotionRecordId = $event
          },
          "update:promotion-record-id": function($event) {
            _vm.promotionRecordId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }