var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material" },
    [
      _c(
        "div",
        {
          staticClass: "image-material-item",
          class: [_vm.isChecked ? "active-material-border" : ""],
          on: {
            mouseenter: function($event) {
              _vm.showInfoIcon = true
            },
            mouseleave: function($event) {
              _vm.showInfoIcon = false
            }
          }
        },
        [
          _vm.isShowCheck
            ? _c(
                "div",
                {
                  staticClass: "delete",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "delete-checkbox",
                      attrs: { label: _vm.valueProxy.materialOpenId }
                    },
                    [
                      _c("span", { staticClass: "check-hidden" }, [
                        _vm._v(_vm._s(_vm.valueProxy.materialOpenId))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-image", {
            staticClass: "image-cover",
            attrs: {
              fit: "cover",
              src: _vm.valueProxy.path
                ? _vm.getImgUrl(_vm.valueProxy.path)
                : undefined
            },
            on: { dblclick: _vm.onPreview }
          }),
          _vm._v(" "),
          !_vm.isSystem &&
          (_vm.popoverCopy ||
            _vm.popoverEdit ||
            _vm.visible ||
            _vm.showInfoIcon) &&
          !_vm.isShowCheck
            ? _c(
                "div",
                {
                  staticClass: "operation",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "300",
                        trigger: "manual"
                      },
                      model: {
                        value: _vm.popoverCopy,
                        callback: function($$v) {
                          _vm.popoverCopy = $$v
                        },
                        expression: "popoverCopy"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "common-flex" },
                        [
                          _c("el-input", {
                            staticClass: "prover-input",
                            attrs: { size: "small", clearable: "" },
                            model: {
                              value: _vm.imagePath,
                              callback: function($$v) {
                                _vm.imagePath = $$v
                              },
                              expression: "imagePath"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.copy(_vm.imagePath)
                                }
                              }
                            },
                            [_vm._v("复制")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("i", {
                        class: [
                          "iconfont icon-gongdanlianjie icon",
                          _vm.popoverCopy ? "active-icon" : ""
                        ],
                        attrs: { slot: "reference" },
                        on: { click: _vm.handelPopoverCopy },
                        slot: "reference"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "350",
                        trigger: "manual"
                      },
                      model: {
                        value: _vm.popoverEdit,
                        callback: function($$v) {
                          _vm.popoverEdit = $$v
                        },
                        expression: "popoverEdit"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "common-flex" },
                        [
                          _c("el-input", {
                            staticClass: "prover-input",
                            attrs: { size: "small", clearable: "" },
                            model: {
                              value: _vm.valueProxy.picName,
                              callback: function($$v) {
                                _vm.$set(_vm.valueProxy, "picName", $$v)
                              },
                              expression: "valueProxy.picName"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                loading: _vm.nameLoading
                              },
                              on: { click: _vm.cancelChangeName }
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                type: "primary",
                                loading: _vm.nameLoading
                              },
                              on: { click: _vm.submitChangeName }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("i", {
                        class: [
                          _vm.popoverEdit ? "active-icon" : "",
                          "el-icon-edit",
                          "icon"
                        ],
                        attrs: { slot: "reference" },
                        on: { click: _vm.handelPopoverEdit },
                        slot: "reference"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "250",
                        trigger: "manual"
                      },
                      model: {
                        value: _vm.visible,
                        callback: function($$v) {
                          _vm.visible = $$v
                        },
                        expression: "visible"
                      }
                    },
                    [
                      _c("div", { staticClass: "popover-delete" }, [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v("删除图片提醒")
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("确定要删除该图片吗？")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "delete-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    _vm.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  loading: _vm.deleteLoading
                                },
                                on: { click: _vm.handleDelete }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        class: [
                          "el-icon-delete-solid icon",
                          _vm.visible ? "active-icon" : ""
                        ],
                        attrs: { slot: "reference" },
                        on: { click: _vm.handelPopoverDelete },
                        slot: "reference"
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "image-title" }, [
        _vm._v(_vm._s(_vm.valueProxy.picName))
      ]),
      _vm._v(" "),
      _vm.showPreview && _vm.imagePath
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "2500" },
            attrs: {
              "on-close": _vm.closeViewer,
              "z-index": "50000",
              "url-list": [_vm.imagePath]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }