var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-ebook-cover" },
    [
      !_vm.valueProxy.cover
        ? _c(
            "div",
            {
              class: [
                "cover",
                _vm.valueProxy.coverType === 1 ? "row-cover" : "common-cover"
              ],
              on: { click: _vm.handleSelect }
            },
            [
              _c("i", { staticClass: "el-icon-picture-outline img-icon" }),
              _vm._v(" "),
              _c("span", [_vm._v("选择封面")])
            ]
          )
        : _c(
            "div",
            {
              class: [
                "cover-position",
                _vm.valueProxy.coverType === 1 ? "row-cover" : "common-cover"
              ]
            },
            [
              _c("el-image", {
                staticClass: "img-cover",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(_vm.valueProxy.cover)
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "mask",
                    _vm.valueProxy.coverType === 1
                      ? "row-cover"
                      : "common-cover"
                  ]
                },
                [
                  _c("div", { staticClass: "mask-icon" }, [
                    _c("i", {
                      staticClass: "iconfont icon-icon-test",
                      on: { click: _vm.onPreview }
                    }),
                    _vm._v(" "),
                    !_vm.disabled
                      ? _c("i", {
                          staticClass:
                            "iconfont icon-qiehuandanchuang change-icon",
                          on: {
                            click: function($event) {
                              _vm.visible = true
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.disabled
                      ? _c("i", {
                          staticClass: "el-icon-delete",
                          on: { click: _vm.deletedImage }
                        })
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("select-ebook-dialog", {
        attrs: { visible: _vm.visible, "partner-open-id": _vm.partnerOpenId },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          submit: _vm.submit
        }
      }),
      _vm._v(" "),
      _vm.showPreview && _vm.imagePath
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "2030" },
            attrs: { "on-close": _vm.closeViewer, "url-list": [_vm.imagePath] }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }