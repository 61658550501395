var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-table-column", {
    attrs: { label: _vm.label, prop: _vm.prop },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(scope) {
          return [
            !_vm.isOnEdit(scope.row)
              ? _c(
                  "el-button",
                  {
                    staticClass: "com-show",
                    on: {
                      click: function($event) {
                        return _vm.startEdit(scope.row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.currentVal(scope.row)))]
                )
              : _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "com-edit"
                  },
                  [
                    _c("el-input-number", {
                      staticClass: "com-input",
                      attrs: {
                        min: 0,
                        size: "mini",
                        "controls-position": "right"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.saveEdit(scope)
                        }
                      },
                      model: {
                        value: _vm.newVal,
                        callback: function($$v) {
                          _vm.newVal = $$v
                        },
                        expression: "newVal"
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-close com-action",
                      on: { click: _vm.cancelEdit }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-check com-action",
                      on: {
                        click: function($event) {
                          return _vm.saveEdit(scope)
                        }
                      }
                    })
                  ],
                  1
                )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }