var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "div",
        { staticClass: "header-bg" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addBtn } },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "section-table-body"
        },
        [
          _c(
            "el-table",
            {
              ref: "learningSectionList",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sequence", label: "排序", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "8" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "code", label: "CODE", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "logogram", label: "简称", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "全称", "min-width": "15" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "abbr", label: "字母简称", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "37", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getAllSync
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "480px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "70px",
                      model: _vm.learnForm,
                      rules: _vm.rules
                    }
                  },
                  [
                    _vm.dialogTitltText === "编辑字典"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "ID" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.learnForm.id,
                                callback: function($$v) {
                                  _vm.$set(_vm.learnForm, "id", $$v)
                                },
                                expression: "learnForm.id"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "CODE", prop: "code" } },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.dialogTitltText === "编辑字典",
                            maxlength: 40,
                            placeholder: "请填写CODE",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.learnForm.code,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "code", $$v)
                            },
                            expression: "learnForm.code"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "简称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 40,
                            placeholder: "请填写简称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.learnForm.logogram,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "logogram", $$v)
                            },
                            expression: "learnForm.logogram"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "全称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 40,
                            placeholder: "请填写全称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.learnForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "name", $$v)
                            },
                            expression: "learnForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "字母简称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 40,
                            placeholder: "请填写字母简称",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.learnForm.abbr,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "abbr", $$v)
                            },
                            expression: "learnForm.abbr"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "排序" } }, [
                      _c(
                        "div",
                        { staticClass: "sequence-box" },
                        [
                          _c("el-input-number", {
                            staticClass: "select-sequence",
                            staticStyle: { width: "100px" },
                            attrs: {
                              precision: 0,
                              step: 1,
                              min: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.learnForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.learnForm, "sequence", $$v)
                              },
                              expression: "learnForm.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.editDialogVisible = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitSyncLoading
                                  },
                                  on: { click: _vm.submitSync }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }