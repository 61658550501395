var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isSelectUser
        ? "select-user-background"
        : "admin-user-background"
    },
    [
      _c(
        "div",
        {
          class: _vm.isSelectUser ? "select-user-inner" : "admin-user-inner",
          on: { click: _vm.handleLinkJump }
        },
        [
          _vm.linkData.type === "OpenId" &&
          _vm.linkData.key.keyType === "resource"
            ? _c("div", [
                _vm.linkData.key.resourceType === 3
                  ? _c(
                      "div",
                      { staticClass: "common-flex" },
                      [
                        _c("el-image", {
                          staticClass: "book-cover",
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(_vm.linkData.display.cover)
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "common-link-title",
                              "book-link-title",
                              _vm.commonLinkTitle
                            ]
                          },
                          [_vm._v(_vm._s(_vm.linkData.display.title))]
                        )
                      ],
                      1
                    )
                  : _vm.isAudio
                  ? _c(
                      "div",
                      { staticClass: "common-flex" },
                      [
                        _c("el-image", {
                          staticClass: "audio-cover",
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(_vm.linkData.display.cover)
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "common-link-title",
                              "audio-link-title",
                              _vm.commonLinkTitle
                            ]
                          },
                          [_vm._v(_vm._s(_vm.linkData.display.title))]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "common-flex" },
                      [
                        _c("el-image", {
                          staticClass: "resource-cover",
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(_vm.linkData.display.cover)
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "resource-link-title",
                              "common-link-title",
                              _vm.commonLinkTitle
                            ]
                          },
                          [_vm._v(_vm._s(_vm.linkData.display.title))]
                        )
                      ],
                      1
                    )
              ])
            : _c("div", { staticClass: "common-flex" }, [
                _c("div", { staticClass: "default-cover" }, [
                  !_vm.isSelectUser
                    ? _c("i", {
                        staticClass: "iconfont icon-gongdanlianjie default-icon"
                      })
                    : _c("i", {
                        staticClass:
                          "iconfont icon-gongdanlianjie default-icon-select"
                      }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mask-default-cover" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "link-title",
                      "common-link-title",
                      _vm.commonLinkTitle
                    ]
                  },
                  [_vm._v(_vm._s(_vm.linkData.display.title))]
                )
              ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }