var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.linkSection.viewCount && _vm.linkSection.viewCount > 0
    ? _c(
        "div",
        {
          staticClass: "section-view-count",
          class: { "section-active-status": _vm.activeStatus }
        },
        [
          _c("i", { staticClass: "el-icon-view" }),
          _vm._v("  " + _vm._s(_vm.linkSection.viewCount) + "\n")
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }