var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticClass: "display_flex align_items justify_content_between" },
          [
            _c(
              "div",
              { staticClass: "display_flex align_items" },
              [
                _c(
                  "el-image",
                  {
                    staticClass: "userCover",
                    attrs: {
                      fit: "cover",
                      src: _vm.imageUrlFun(
                        _vm.agencyInfo.partnerLogo,
                        _vm.thumbnailStyle
                      )
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error"
                      },
                      [
                        _c("img", {
                          staticClass: "userCover",
                          attrs: { src: require("@/assets/book_cover.png") }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "margin_left_10" }, [
                  _c("div", { staticClass: "title_font" }, [
                    _vm._v(_vm._s(_vm.showVal(_vm.agencyInfo.storeName)))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "memo_font margin_top_5" }, [
                    _vm._v(
                      "id:" + _vm._s(_vm.showVal(_vm.agencyInfo.partnerOpenId))
                    )
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "display_flex align_items" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "margin_left_10",
                    attrs: { type: "primary" },
                    on: { click: _vm.showAddDia }
                  },
                  [_vm._v("编辑")]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "margin_left_10",
            staticStyle: { "margin-top": "30px" }
          },
          [
            _c("div", { staticClass: "default_font " }, [
              _vm._v(
                "联系人：" + _vm._s(_vm.showVal(_vm.agencyInfo.operatorName))
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "default_font margin_top_15" }, [
              _vm._v(
                "联系电话：" + _vm._s(_vm.showVal(_vm.agencyInfo.operatorPhone))
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "default_font margin_top_15" }, [
              _vm._v("简介：" + _vm._s(_vm.showVal(_vm.agencyInfo.desc)))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "num_box display_flex align_items justify_content_between",
            staticStyle: { "margin-top": "30px" }
          },
          [
            _c("div", { staticStyle: { width: "100%" } }, [
              _c("div", { staticClass: "num_font" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.showVal(_vm.agencyInfo.storeCount)) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "memo_font margin_top_5" }, [
                _vm._v("\n          商户数\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { width: "100%" } }, [
              _c("div", { staticClass: "num_font" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.showVal(_vm.agencyInfo.productCount)) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "memo_font margin_top_5" }, [
                _vm._v("\n          商品数\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { width: "100%" } }, [
              _c("div", { staticClass: "num_font" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.showVal(_vm.agencyInfo.orderCount)) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "memo_font margin_top_5" }, [
                _vm._v("\n          销售订单数\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { width: "100%" } }, [
              _c("div", { staticClass: "num_font" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.showVal(
                        Number(_vm.agencyInfo.totalSales / 100).toFixed(2)
                      )
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "memo_font margin_top_5" }, [
                _vm._v("\n          销售金额（元）\n        ")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "div",
            { staticClass: "display_flex align_items justify_content_between" },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "rgba(16, 16, 16, 1)",
                    "font-size": "14px",
                    "font-weight": "bold"
                  }
                },
                [_vm._v("仓库设置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.showStoreDia } },
                [_vm._v("编辑")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line_h margin_top_15" }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "margin_top_15",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.storeList }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "storeroomOpenId", label: "仓库ID" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "仓库名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "收货人" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userPhone", label: "联系电话" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "收货地址" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.addDialogVisible
        ? _c("zhong-pan-add-dialog", {
            attrs: {
              "dialog-visible": _vm.addDialogVisible,
              title: "中盘号",
              "is-add": false,
              midCapOpenId: _vm.partnerOpenId
            },
            on: { zhongPanAddRoleDialogClose: _vm.dismissAddDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.storeVisible
        ? _c("agency-select-store", {
            attrs: { data: _vm.storeList, "store-visible": _vm.storeVisible },
            on: {
              "update:storeVisible": function($event) {
                _vm.storeVisible = $event
              },
              "update:store-visible": function($event) {
                _vm.storeVisible = $event
              },
              selectStore: _vm.getSelectStore
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }