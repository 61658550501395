var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-detail" }, [
    _vm.detail.comContent
      ? _c("span", {
          staticClass: "comment-text",
          domProps: { innerHTML: _vm._s(_vm.detail.comContent) },
          on: {
            click: function($event) {
              return _vm.priviewCommon(_vm.detail.resourceId)
            }
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.commentImages && _vm.detail.commentImages.length
      ? _c(
          "div",
          { staticClass: "img-list" },
          _vm._l(_vm.detail.commentImages, function(itemImg, indexC) {
            return _c("el-image", {
              key: indexC,
              staticStyle: {
                width: "80px",
                height: "80px",
                "margin-right": "10px"
              },
              attrs: {
                fit: "cover",
                src: _vm.getImgUrl(itemImg.imageUrl, "style/width-limit-256"),
                "preview-src-list": _vm.getImg(
                  itemImg.imageUrl,
                  "style/width-limit-256"
                )
              }
            })
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.audioUrl
      ? _c(
          "div",
          {
            staticClass: "bubble",
            on: {
              click: function($event) {
                return _vm.playAudio(_vm.detail.uniqueindex)
              }
            }
          },
          [
            _c("audio", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: {
                src: _vm.getAudioUrl(_vm.detail.audioUrl),
                id: _vm.detail.uniqueindex
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "wifi-symbol" }, [
              _c("div", { staticClass: "wifi-circle first" }),
              _vm._v(" "),
              _c("div", {
                staticClass: "wifi-circle second",
                class: _vm.isPlay
                  ? "wifi-circle second secondAnimation"
                  : "wifi-circle second"
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "wifi-circle third",
                class: _vm.isPlay
                  ? "wifi-circle third thirdAnimation"
                  : "wifi-circle third"
              })
            ]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.detail.audioDuration) + "'")])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }