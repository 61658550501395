var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c("top-statistics"),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticStyle: { width: "80%" } },
            [_c("line-chart")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { width: "20%" } },
            [_c("balance-and-module")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("conversation-list", { staticStyle: { "margin-top": "20px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }