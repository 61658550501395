var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fileWrap upload-video" },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          class: [
            "table-list-bg",
            _vm.fileListData.queue.length <= 0 ? "video-upload-table" : ""
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.fileListData.queue, "tooltip-effect": "dark" },
          on: {
            "select-all": _vm.selectAll,
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              selectable: _vm.selectable,
              width: "50"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "作品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.fileTitle) +
                        "." +
                        _vm._s(scope.row.fileExtName) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "进度", width: "350" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm.isAutoUpload
                      ? [
                          _c("el-progress", {
                            attrs: {
                              percentage: _vm.getUploadProgress(
                                scope.row.uploadProgress
                              ),
                              color: _vm.processColor(scope.row.uploadState)
                            }
                          })
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "address",
              label: "操作",
              width: "150",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: _vm.isAutoUpload },
                        on: {
                          click: function($event) {
                            return _vm.deleteRow(scope.row, scope.$index)
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c(
              "div",
              { staticClass: "empty-box" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "a-add",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addObject()
                      }
                    }
                  },
                  [_vm._v("批量选择作品")]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("file-list-uploader", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "fileUploader",
        attrs: { isAutoUpload: false },
        model: {
          value: _vm.fileListData,
          callback: function($$v) {
            _vm.fileListData = $$v
          },
          expression: "fileListData"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }