"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _productList = _interopRequireDefault(require("./productList"));

var store = {
  namespaced: true,
  modules: {
    productList: _productList.default
  }
};
var _default = store;
exports.default = _default;