var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testPaperContainer" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tab-section",
          on: { "tab-click": _vm.sectionClick },
          model: {
            value: _vm.activeSection,
            callback: function($$v) {
              _vm.activeSection = $$v
            },
            expression: "activeSection"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "小学", name: "1001" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "初中", name: "1002" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "高中", name: "1003" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "subject-table" }, [
        _c(
          "div",
          { staticClass: "tabs" },
          [
            _c(
              "el-tabs",
              {
                attrs: { "tab-position": "left" },
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeSubjectIndex,
                  callback: function($$v) {
                    _vm.activeSubjectIndex = $$v
                  },
                  expression: "activeSubjectIndex"
                }
              },
              _vm._l(_vm.subjectList, function(item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.name, name: index.toString() }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "table" }, [
          _c("div", { staticClass: "tableCon" }, [
            _c(
              "div",
              { staticClass: "tableTop" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.add } },
                  [_vm._v("添加")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "nav-search" },
                  [
                    _c("el-input", {
                      staticClass: "search-input",
                      attrs: { clearable: "", placeholder: "请输入知识点名" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.search($event)
                        }
                      },
                      model: {
                        value: _vm.searchVal,
                        callback: function($$v) {
                          _vm.searchVal = $$v
                        },
                        expression: "searchVal"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "searchbtn",
                      { on: { searchContent: _vm.search } },
                      [
                        _c(
                          "el-form",
                          { attrs: { "label-width": "80px", size: "small" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "同步信息" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择年级"
                                    },
                                    model: {
                                      value: _vm.searchGrade,
                                      callback: function($$v) {
                                        _vm.searchGrade = $$v
                                      },
                                      expression: "searchGrade"
                                    }
                                  },
                                  _vm._l(_vm.gradeList, function(item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "难易" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "全部",
                                      size: "medium",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.difficultDegree,
                                      callback: function($$v) {
                                        _vm.difficultDegree = $$v
                                      },
                                      expression: "difficultDegree"
                                    }
                                  },
                                  _vm._l(_vm.difficultDegreeList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        staticClass: "popver",
                        attrs: {
                          placement: "bottom",
                          width: "330",
                          trigger: "click"
                        }
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.handleCheckedChange },
                            model: {
                              value: _vm.checkList,
                              callback: function($$v) {
                                _vm.checkList = $$v
                              },
                              expression: "checkList"
                            }
                          },
                          _vm._l(_vm.customList, function(item, index) {
                            return _c(
                              "el-checkbox",
                              {
                                key: index,
                                staticStyle: {
                                  margin: "5px 0 5px 10px",
                                  width: "90px"
                                },
                                attrs: {
                                  label: item,
                                  disabled: item === "知识点" || item === "操作"
                                }
                              },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-button", {
                          staticClass: "set-table-column",
                          attrs: {
                            slot: "reference",
                            plain: "",
                            icon: "el-icon-s-tools"
                          },
                          slot: "reference"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tableList" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    ref: "knowledgeTable",
                    attrs: {
                      height: "calc(100vh - 236px)",
                      data: _vm.tableList,
                      "row-key": "knowledgeOpenId",
                      "tree-props": {
                        children: "childList",
                        hasChildren: "hasChildren"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "knowledgeName", label: "知识点" }
                    }),
                    _vm._v(" "),
                    _vm.showSource
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "source",
                            label: "来源渠道",
                            width: "120"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.source
                                            ? scope.row.source
                                            : "平台"
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            615384608
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showUserName
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "userName",
                            label: "添加人",
                            width: "120"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.userCover
                                      ? _c("img", {
                                          staticClass: "userCover",
                                          attrs: {
                                            src: _vm.getImgUrl(
                                              scope.row.userCover
                                            ),
                                            alt: ""
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.userName))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3565682811
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showDifficulty
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "difficultyDegree",
                            label: "难易",
                            width: "100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.difficultyDegree === 0
                                      ? _c("span", [_vm._v("暂无")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.difficultyDegree === 1
                                      ? _c("span", [_vm._v("很简单")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.difficultyDegree === 2
                                      ? _c("span", [_vm._v("简单")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.difficultyDegree === 3
                                      ? _c("span", [_vm._v("一般")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.difficultyDegree === 4
                                      ? _c("span", [_vm._v("困难")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.difficultyDegree === 5
                                      ? _c("span", [_vm._v("十分困难")])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4194885008
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showGrade
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "gradeName",
                            label: "年级",
                            width: "150"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showSequence
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "sequence",
                            label: "排序",
                            width: "90"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-dropdown",
                                      [
                                        _c("div", { staticClass: "sequence" }, [
                                          _vm._v(_vm._s(scope.row.sequence))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            staticClass: "dropClass",
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  model: {
                                                    value: scope.row.sequence,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "sequence",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.sequence"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("svg-icon", {
                                                  staticStyle: {
                                                    "font-size": "29px",
                                                    color: "#67C23A"
                                                  },
                                                  attrs: {
                                                    "icon-class": "right"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.upDateSequence(
                                                        scope.row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("svg-icon", {
                                                  staticStyle: {
                                                    "font-size": "29px",
                                                    color: "#FC745E"
                                                  },
                                                  attrs: {
                                                    "icon-class": "del"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.delsequence(
                                                        scope.row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            874696337
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addSon(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("添加")]
                              ),
                              _vm._v(" "),
                              scope.row.level > 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.move(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("移动")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.edit(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#F56C6C" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.del(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formTitle,
            visible: _vm.dialogVisible,
            width: "983px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.addForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "知识点", prop: "knowledgeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入知识点名称" },
                    model: {
                      value: _vm.addForm.knowledgeName,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "knowledgeName", $$v)
                      },
                      expression: "addForm.knowledgeName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属知识点" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      props: _vm.props,
                      clearable: "",
                      placeholder: "搜索或选择知识点",
                      options: _vm.knowledgeList,
                      filterable: ""
                    },
                    model: {
                      value: _vm.addForm.parentOpenIds,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "parentOpenIds", $$v)
                      },
                      expression: "addForm.parentOpenIds"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "年级" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        multiple: "",
                        placeholder: "请选择年级"
                      },
                      model: {
                        value: _vm.addForm.gradeCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "gradeCodes", $$v)
                        },
                        expression: "addForm.gradeCodes"
                      }
                    },
                    _vm._l(_vm.gradeList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "试题难度" } },
                [
                  _c("el-rate", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: { texts: _vm.texts, "show-text": "" },
                    model: {
                      value: _vm.addForm.difficultyDegree,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "difficultyDegree", $$v)
                      },
                      expression: "addForm.difficultyDegree"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "解读" } },
                [
                  _c("editor", {
                    model: {
                      value: _vm.addForm.interpret,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "interpret", $$v)
                      },
                      expression: "addForm.interpret"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "DiaFooter" },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right" },
                model: {
                  value: _vm.addForm.sequence,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "sequence", $$v)
                  },
                  expression: "addForm.sequence"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submit("addForm")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移动知识点",
            visible: _vm.moveDialogVisible,
            width: "512px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.moveDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "90px" } },
            [
              _c("el-form-item", { attrs: { label: "知识点" } }, [
                _c("span", [_vm._v(_vm._s(_vm.moveObj.knowledgeName))])
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属知识点" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      props: _vm.props,
                      clearable: "",
                      placeholder: "搜索或选择知识点",
                      options: _vm.knowledgeList,
                      filterable: ""
                    },
                    on: { change: _vm.getMoveTo },
                    model: {
                      value: _vm.moveParentOpenId,
                      callback: function($$v) {
                        _vm.moveParentOpenId = $$v
                      },
                      expression: "moveParentOpenId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.moveDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.moveKnow } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }