var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": "",
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            width: "610px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                color: "#303133",
                "font-size": "18px",
                "margin-bottom": "20px"
              },
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v("教师资质")]
          ),
          _vm._v(" "),
          _c("edit-teacher-info", {
            attrs: { newOr: _vm.newOr, userOpenId: _vm.userOpenId },
            on: { closeDialog: _vm.closeDialog }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }