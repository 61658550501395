"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DialogueAudio',
  props: {
    operateUserOpenId: {
      type: String,
      default: ''
    },
    selectUserOpenId: {
      type: String,
      default: ''
    },
    currentUniqueIndex: {
      type: String,
      default: ''
    },
    isPlay: {
      type: Boolean,
      default: false
    },
    uniqueindex: {
      type: String,
      default: ''
    },
    audioDuration: {
      type: Number,
      default: undefined
    },
    audioUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    getAudioUrl: function getAudioUrl(url) {
      return "".concat(process.env.VUE_APP_OSS_URL).concat(process.env.VUE_APP_OSS_PATH, "/").concat(url);
    },
    playAudio: function playAudio(val) {
      this.$emit('playAudio', val);
    }
  }
};
exports.default = _default;