var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "table",
          attrs: {
            data: _vm.resourceList,
            "row-class-name": _vm.tableRowClassName,
            "tooltip-effect": "dark"
          }
        },
        [
          _vm.dialogType !== "replace"
            ? _c("el-table-column", {
                attrs: {
                  selectable: _vm.selectable,
                  type: "selection",
                  width: "42"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "文件名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "resource-title-bg" }, [
                      _c("i", {
                        class: [
                          "iconfont",
                          "resource-icon",
                          _vm.getResourceCover(scope.row.fileExtName)
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "resource-title-layout" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top-start" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "resource-item-box",
                                  staticStyle: { "line-height": "20px" },
                                  attrs: { slot: "content" },
                                  slot: "content"
                                },
                                [
                                  _c("div", [
                                    _vm._v("索引:" + _vm._s(scope.row.id))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v("标题:" + _vm._s(scope.row.title))
                                  ]),
                                  _vm._v(" "),
                                  scope.row.createdAt
                                    ? _c("div", [
                                        _vm._v(
                                          "创建时间:" +
                                            _vm._s(scope.row.createdAt)
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.publishedAt
                                    ? _c("div", [
                                        _vm._v(
                                          "发布时间:" +
                                            _vm._s(scope.row.publishedAt)
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.updatedAt
                                    ? _c("div", [
                                        _vm._v(
                                          "更新时间:" +
                                            _vm._s(scope.row.updatedAt)
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "resource-title" }, [
                                _vm._v(_vm._s(_vm.displayTitle(scope.row)))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "同步信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", { staticClass: "resource-info" }, [
                      _vm._v(_vm._s(scope.row.syncInfoText))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分类" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", { staticClass: "resource-class" }, [
                      _vm._v(_vm._s(scope.row.categoryInfoText))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            staticStyle: { "text-align": "center" },
            attrs: {
              prop: "data",
              width: "220",
              align: "center",
              label: "更新时间"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.showVal(scope.row.updatedAt)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.dialogType === "replace"
            ? _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.dialogType === "replace"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn-add-album",
                                  attrs: {
                                    disabled: !_vm.selectable(scope.row),
                                    type: "text"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("item-add", scope.row)
                                    }
                                  }
                                },
                                [_vm._v("替换")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1639811794
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }