"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getScaleRatio = getScaleRatio;

function sameRatio(base, target) {
  return base.width * target.height === target.width * base.height;
}

function aspectRatio(_ref) {
  var width = _ref.width,
      height = _ref.height;
  return width / height;
}

function getScaleRatio(base, target) {
  if (sameRatio(base, target)) {
    return 1;
  }

  if (aspectRatio(target) < aspectRatio(base)) {
    return base.height / target.height;
  }

  return base.width / target.width;
}