import { render, staticRenderFns } from "./GeneralDialog.vue?vue&type=template&id=61f5bafa&scoped=true&"
import script from "./GeneralDialog.vue?vue&type=script&lang=js&"
export * from "./GeneralDialog.vue?vue&type=script&lang=js&"
import style0 from "./GeneralDialog.vue?vue&type=style&index=0&id=61f5bafa&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f5bafa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61f5bafa')) {
      api.createRecord('61f5bafa', component.options)
    } else {
      api.reload('61f5bafa', component.options)
    }
    module.hot.accept("./GeneralDialog.vue?vue&type=template&id=61f5bafa&scoped=true&", function () {
      api.rerender('61f5bafa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProductMarketplace/General/Dialog/GeneralDialog.vue"
export default component.exports