var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isEdit
      ? _c("div", { staticClass: "resource-encode-bg" }, [
          _vm.showEditEncode &&
          _vm.currentOpenId === _vm.resourceObj.resourceOpenId
            ? _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "txt-encode",
                    attrs: {
                      size: "mini",
                      placeholder: _vm.getEncodePlace(
                        _vm.resourceObj.resourceType
                      )
                    },
                    on: {
                      blur: function($event) {
                        return _vm.saveDate(_vm.resourceObj)
                      }
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return $event.target.blur($event)
                      }
                    },
                    model: {
                      value: _vm.resourceObj.templateResourceEncode,
                      callback: function($$v) {
                        _vm.$set(_vm.resourceObj, "templateResourceEncode", $$v)
                      },
                      expression: "resourceObj.templateResourceEncode"
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "div-encode-bg",
                  on: {
                    click: function($event) {
                      return _vm.handleUpdateEncode(_vm.resourceObj)
                    }
                  }
                },
                [
                  _vm.resourceObj.resourceEncode
                    ? _c("div", { staticClass: "txt-resource" }, [
                        _vm._v(_vm._s(_vm.resourceObj.resourceEncode))
                      ])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.getEncodeTitle(_vm.resourceObj.resourceType)
                          )
                        )
                      ])
                ]
              )
        ])
      : _c("div", { staticClass: "resource-encode-bg" }, [
          _c("div", { staticClass: "resource-encode" }, [
            _vm._v(
              "作品编号:" +
                _vm._s(
                  _vm.resourceObj.resourceEncode
                    ? _vm.resourceObj.resourceEncode
                    : "--"
                )
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }