var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "book-catalog",
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogType,
            title: "目录扩展信息",
            "append-to-body": "",
            "modal-append-to-body": true,
            width: "970px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogType = $event
            }
          }
        },
        [
          _c("div", { staticClass: "content" }, [
            _vm.dragData.length > 0
              ? _c(
                  "div",
                  [
                    _c("drag-drop", {
                      attrs: {
                        "drag-data": _vm.dragData,
                        "book-mode-i-d": _vm.bookModeID
                      },
                      on: { closeDialog: _vm.closeDialog }
                    })
                  ],
                  1
                )
              : _c("div", { staticClass: "left-content tip-message" }, [
                  _vm._v("\n        请从右侧面板点击选取\n      ")
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-content" },
              [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "content-button my",
                      on: {
                        click: function($event) {
                          return _vm.handleClick("内容")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _c("span", [_vm._v("我的内容")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.menuList, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "right-title",
                        on: {
                          click: function($event) {
                            return _vm.handleMenuClick(item)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-arrow-up menu-up",
                          class: item.openType ? "menu-up" : "menu-down"
                        }),
                        _vm._v(_vm._s(item.title) + "\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "right-menu-content",
                        class: !item.openType ? "active" : "",
                        style:
                          "height: " +
                          Math.ceil(item.menu.length / 2) * 38 +
                          "px"
                      },
                      _vm._l(item.menu, function(item1, index1) {
                        return _c(
                          "div",
                          {
                            key: index1,
                            staticClass: "right-menu",
                            on: {
                              click: function($event) {
                                return _vm.handleClick(item1.name)
                              }
                            }
                          },
                          [
                            item1.icon
                              ? _c("i", {
                                  staticClass: "iconfont menu-icon",
                                  class: item1.icon
                                })
                              : _c(
                                  "span",
                                  { staticClass: "menu-icon icon-number" },
                                  [_vm._v(_vm._s(item1.number))]
                                ),
                            _vm._v(
                              "\n              " +
                                _vm._s(item1.name) +
                                "\n            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-button" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogType = false
                    }
                  }
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("\n        保存\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }