var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partner-micro-page" },
    [
      _c("micro-detail", {
        attrs: {
          "is-platform": false,
          "partner-open-id": _vm.$route.params.partnerOpenId,
          "height-style": "calc(100vh - 270px)"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }