var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticClass: "edition",
          attrs: {
            disabled: _vm.isDisabled,
            placeholder: "请选择",
            clearable: ""
          },
          model: {
            value: _vm.period,
            callback: function($$v) {
              _vm.period = $$v
            },
            expression: "period"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.code,
            attrs: { label: item.name, value: item.code }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }