"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllTemplateList = getAllTemplateList;
exports.getPushNotificationList = getPushNotificationList;
exports.pushNotification = pushNotification;
exports.sendTemplatePreview = sendTemplatePreview;
exports.pushNotificationPush = pushNotificationPush;
exports.pushNotificationAppPush = pushNotificationAppPush;
exports.pushNotificationwxPush = pushNotificationwxPush;
exports.removePushInfoApi = removePushInfoApi;
exports.getDownloadCenterListApi = getDownloadCenterListApi;
exports.removedownloadRecordApi = removedownloadRecordApi;
exports.pushNotificationPushByid = pushNotificationPushByid;
exports.setLiveBroad = setLiveBroad;
exports.getLiveBroad = getLiveBroad;
exports.downloadRecord = downloadRecord;
exports.getsubUsers = getsubUsers;
exports.exportSubs = exportSubs;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/notification/admin/"));
var subUsersrequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/live/admin/"));
/**
 * 导出订阅用户详细
 * @param {String} params
 * @returns {Promise<*>}
 */

function exportSubs(params) {
  return subUsersrequest.get('/subscription/exportSubs/', {
    params: params
  });
}
/**
 * 根据年级查询直播中心设置
 * @param {String} params
 * @returns {Promise<*>}
 */


function getsubUsers(params) {
  return subUsersrequest.get('/subscription/subUsers/', {
    params: params
  });
}
/**
 * 根据年级查询直播中心设置
 * @param {String} params
 * @returns {Promise<*>}
 */


function getLiveBroad(params) {
  return request.get('/live/', {
    params: params
  });
}
/**
 * 直播中心设置提醒
 * @param {String} params
 * @returns {Promise<*>}
 */


function setLiveBroad(gradeCode, data) {
  return request.post("/live/".concat(gradeCode), data);
}
/**
* 分页获取下载记录【商户端】
* @param {String} params
* @returns {Promise<*>}
*/


function getDownloadCenterListApi(params) {
  return request.get('/downloadRecord/getDownloadCenterList', {
    params: params
  });
}
/**
 * 删除
 * @param {String} id
 * @returns {Promise<*>}
 */


function removedownloadRecordApi(id) {
  return request.delete("/downloadRecord/".concat(id));
}
/**
 * 移除推送【总后台】
 * @param {String} id
 * @returns {Promise<*>}
 */


function removePushInfoApi(id) {
  return request.delete("/pushNotification/delete/".concat(id));
}
/**
 * 获取微信推送模板列表【总后台】
 * @returns {Promise<*>}
 */


function getAllTemplateList() {
  return request.get('/pushNotification/getAllTemplate/');
}
/**
 * 获取微信推送模板列表【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function getPushNotificationList(params) {
  return request.get('/pushNotification/getPushNotificationList', {
    params: params
  });
}
/**
 * 保存推送任务【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function pushNotification(params) {
  return request.post('/pushNotification/save', params);
}
/**
 * 发送微信短息通知预览功能【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function sendTemplatePreview(params) {
  return request.post('/pushNotification/sendTemplatePreview', undefined, {
    params: params
  });
}
/**
 * 推送接口【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function pushNotificationPush(params) {
  return request.post('/pushNotification/push', params);
}
/**
 * 推送接口(推送记录中推送)【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function pushNotificationPushByid(params) {
  return request.post("pushNotification/pushById/".concat(params.id));
}
/**
 * app推送接口【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function pushNotificationAppPush(params) {
  return request.post('/pushNotification/appPush', params);
}
/**
 * 微信推送接口【总后台】
 * @param {String} params
 * @returns {Promise<*>}
 */


function pushNotificationwxPush(params) {
  return request.post('/pushNotification/wxPush', params);
}
/**
 * 下载次数接口
 * @param {String} params
 * @returns {Promise<*>}
 */


function downloadRecord(data) {
  return request.post('/downloadRecord/addNum', data);
}