"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encrypt = encrypt;
exports.decrypt = decrypt;

require("core-js/modules/es6.regexp.to-string");

var _cryptoJs = _interopRequireDefault(require("crypto-js"));

var _main = _interopRequireDefault(require("../../typeIs/main"));

var _config = require("../config");

/**
 * 加密
 *
 * @param {*} data
 * @returns {string|undefined}
 */
function encrypt() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

  try {
    var plaintext = JSON.stringify({
      data: data
    });
    return _cryptoJs.default.AES.encrypt(plaintext, (0, _config.getConfig)().cipher).toString();
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
/**
 * 解密
 *
 * @param {string} ciphertext
 * @returns {*}
 */


function decrypt(ciphertext) {
  try {
    // 如果待解密字符串无效，则返回 undefined
    if (!_main.default.isString(ciphertext) || _main.default.isEmpty(ciphertext)) {
      return undefined;
    }

    var plaintext = _cryptoJs.default.AES.decrypt(ciphertext, (0, _config.getConfig)().cipher).toString(_cryptoJs.default.enc.Utf8);

    if (!_main.default.isString(plaintext) || _main.default.isEmpty(plaintext)) {
      return undefined;
    } // 如果解析出的密文无效，返回 undefined


    if (plaintext.trim().length < 1) {
      return undefined;
    } // 如果解析出的密文为空字符串，返回 undefined


    return JSON.parse(plaintext).data;
  } catch (e) {
    console.error(e);
    return undefined; // 如果解密失败，返回 undefined
  }
}