var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "bath-add",
      attrs: {
        title: "批量添加图片",
        width: "800px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        "close-on-press-escape": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c("div", { staticClass: "upload" }, [
        _c(
          "div",
          { staticClass: "upload-img" },
          [
            _c("input", {
              ref: "myUpload",
              staticClass: "file-upload",
              style: { display: _vm.submitLoading ? "none" : "block" },
              attrs: {
                id: "myUpload",
                multiple: "multiple",
                type: "file",
                accept: "image/*"
              },
              on: { change: _vm.handleUpload }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary", disabled: _vm.submitLoading } },
              [_vm._v("选择图片")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "upload-text", attrs: { slot: "tip" }, slot: "tip" },
          [_vm._v("支持jpg / png格式，小于2M（一次上传最多200张）")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          !_vm.successSubmit && !_vm.submitLoading
            ? _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")])
            : _vm._e(),
          _vm._v(" "),
          _vm.successSubmit
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitClose } },
                [_vm._v("确定")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submit }
                },
                [_vm._v("提交")]
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "img-list" },
        _vm._l(_vm.uploadList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "list-item",
                _vm.activeIndex === index ? "active-list-item" : ""
              ],
              on: {
                mouseenter: function($event) {
                  return _vm.mouseenter(index)
                },
                mouseleave: function($event) {
                  return _vm.mouseleave(index)
                }
              }
            },
            [
              !item.showInput
                ? _c(
                    "div",
                    {
                      staticClass: "item-title",
                      on: {
                        click: function($event) {
                          return _vm.handleShowInput(index)
                        }
                      }
                    },
                    [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              item.showInput
                ? _c("el-input", {
                    ref: "titleInput",
                    refInFor: true,
                    staticClass: "title-input",
                    attrs: { clearable: "", size: "mini" },
                    on: {
                      blur: function($event) {
                        return _vm.handleBlur(item, index)
                      }
                    },
                    model: {
                      value: item.title,
                      callback: function($$v) {
                        _vm.$set(item, "title", $$v)
                      },
                      expression: "item.title"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                (item.status === -2 || item.status === -1) &&
                _vm.showHoverIndex !== index && _vm.activeIndex !== index
                  ? _c("i", { staticClass: "el-icon-circle-close error-item" })
                  : _vm._e(),
                _vm._v(" "),
                item.status === 2
                  ? _c("i", {
                      staticClass: "el-icon-circle-check success-icon"
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.status === -2 &&
                (_vm.showHoverIndex === index || _vm.activeIndex === index)
                  ? _c("i", {
                      staticClass: "el-icon-upload hover-icon upload-error",
                      on: {
                        click: function($event) {
                          return _vm.handleMaterial(item, index)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.status === -1 &&
                (_vm.showHoverIndex === index || _vm.activeIndex === index)
                  ? _c("i", {
                      staticClass: "el-icon-upload hover-icon upload-error",
                      on: {
                        click: function($event) {
                          return _vm.handleUploadOss(item, index)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                (item.status === 0 ||
                  item.status === -2 ||
                  item.status === -1) &&
                (_vm.showHoverIndex === index || _vm.activeIndex === index)
                  ? _c("i", {
                      staticClass: "el-icon-close hover-icon",
                      on: {
                        click: function($event) {
                          return _vm.handleDelete(item, index)
                        }
                      }
                    })
                  : _vm._e()
              ])
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }