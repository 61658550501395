var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "custom-class": "pop-up-dialog",
        title: _vm.title,
        visible: _vm.valueProxy,
        width: "92%",
        "before-close": _vm.handleClose,
        "lock-scroll": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        }
      }
    },
    [
      _vm.valueProxy
        ? _c("product-list", {
            ref: "list",
            attrs: {
              "middle-open-id": _vm.middleOpenId,
              select: _vm.selectProxy
            },
            on: {
              "update:select": function($event) {
                _vm.selectProxy = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleClose } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }