"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _OriginalSyncInfo = _interopRequireDefault(require("./OriginalSyncInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CG_From_Selector_SyncInfo_DefaultSyncInfo',
  components: {
    OriginalSyncInfo: _OriginalSyncInfo.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    size: {
      type: String,
      default: undefined
    },
    query: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    labelName: {
      type: String,
      default: 'name'
    },
    valueName: {
      type: String,
      default: 'code'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Boolean,
      default: false
    },
    remoteApi: {
      type: Function,
      default: undefined
    },
    keyPrefix: {
      type: String,
      default: ''
    },
    labelDisplay: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        if (this.multiple) {
          if (!_main.default.isString(this.value)) {
            return [];
          }

          if (_main.default.isEmpty(this.value)) {
            return [];
          }

          return this.value.split(',');
        }

        return this.value;
      },
      set: function set(value) {
        if (this.multiple) {
          this.$emit('input', value.join(','));
        } else {
          this.$emit('input', value);
        }
      }
    }
  },
  methods: {
    onChange: function onChange() {
      this.$emit('change');
    },
    updateData: function updateData() {
      this.$refs.subjectList.updateData();
    }
  }
};
exports.default = _default2;