var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "小程序详情",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "780px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "dialog_body"
        },
        [
          _c("div", { staticClass: "bind_box" }, [
            _c("div", [
              _c("img", {
                staticClass: "cover_box",
                attrs: { src: require("@/assets/ic_wx.png") }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "19px" } },
              [
                _c(
                  "el-link",
                  { attrs: { href: _vm.bindUrl, target: "_blank" } },
                  [_vm._v("点击此处，绑定认证小程序")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }