var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "coupon-wrapper-bg",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c("div", { staticClass: "coupon-wrapper-box" }, [
        _c("div", { staticClass: "coupon-header" }, [
          _c(
            "div",
            { staticClass: "coupon-header-l" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-add",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.addCoupon }
                },
                [_vm._v("新建")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.query.status,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status"
                  }
                },
                _vm._l(_vm.stateList, function(item) {
                  return _c(
                    "el-radio-button",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "select-type",
                  attrs: { placeholder: "优惠券类型" },
                  model: {
                    value: _vm.query.discountType,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "discountType", $$v)
                    },
                    expression: "query.discountType"
                  }
                },
                _vm._l(_vm.discountTypeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "coupon-header-r" },
            [
              _c("el-input", {
                staticClass: "search-txt",
                attrs: { placeholder: "搜索关键词", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchCoupon($event)
                  }
                },
                model: {
                  value: _vm.query.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "keyword", $$v)
                  },
                  expression: "query.keyword"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.searchCoupon }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "coupon-table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading"
                  }
                ],
                ref: "couponList",
                staticStyle: { width: "100%" },
                attrs: { width: "100%", height: "100%", data: _vm.listArray }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "title", label: "名称" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "category",
                    filters: _vm.groupList,
                    "filter-method": _vm.filterGroup,
                    "filter-multiple": false,
                    label: "分组"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.category ? scope.row.category : "--"
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "80",
                    sortable: "",
                    prop: "discountTypeName",
                    label: "类型"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "offerContent", label: "优惠内容" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "已领取/优惠券总量" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "bottom",
                                content: "count-tooltip-box"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "count-content-bg" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "手动发放:" +
                                            _vm._s(
                                              scope.row.manuallyIssueQuantity
                                                ? scope.row
                                                    .manuallyIssueQuantity
                                                : "--"
                                            ) +
                                            "/" +
                                            _vm._s(
                                              scope.row.manuallyIssueQuantity
                                                ? scope.row
                                                    .manuallyIssueQuantity
                                                : "--"
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      scope.row.sendType === 3
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                    链接发放:" +
                                                _vm._s(
                                                  scope.row.receiveCount -
                                                    scope.row
                                                      .manuallyIssueQuantity
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  scope.row.quota === 0
                                                    ? "不限"
                                                    : scope.row.quota
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.sendType === 2
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                    手动领取:" +
                                                _vm._s(
                                                  scope.row.receiveCount -
                                                    scope.row
                                                      .manuallyIssueQuantity
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  scope.row.quota === 0
                                                    ? "不限"
                                                    : scope.row.quota
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.sendType === 1
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                    自动发放:" +
                                                _vm._s(
                                                  scope.row.receiveCount -
                                                    scope.row
                                                      .manuallyIssueQuantity
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  scope.row.quota === 0
                                                    ? "不限"
                                                    : scope.row.quota
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                { staticClass: "count-tip-box" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.receiveCount) +
                                      "/ " +
                                      _vm._s(
                                        scope.row.quota === 0
                                          ? "不限"
                                          : scope.row.quota +
                                              scope.row.manuallyIssueQuantity
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { sortable: "", prop: "usedCount", label: "已使用" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "100", sortable: "", label: "发放方式" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getSendState(scope.row.sendType).name)
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "80",
                    prop: "status",
                    sortable: "",
                    label: "状态"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: _vm.getCouponState(scope.row.status)
                                .calssName
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCouponState(scope.row.status).name
                                )
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "156",
                    sortable: "",
                    prop: "startTimeAt",
                    label: "发放时间"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.startTimeAt,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              ) +
                              " "
                          ),
                          scope.row.endTimeAt
                            ? _c("span", { staticStyle: { margin: "0 4px" } }, [
                                _vm._v("~")
                              ])
                            : _vm._e(),
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                scope.row.endTimeAt,
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            ) + "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "90",
                    prop: "displayName",
                    sortable: "",
                    label: "生成人"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.createUserInfo
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.createUserInfo.realName
                                      ? scope.row.createUserInfo.realName
                                      : "--"
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "110", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleManualSend(scope.row)
                                }
                              }
                            },
                            [_vm._v("手动发放")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.lookResult(
                                    scope.row.couponOpenId,
                                    scope.row.title
                                  )
                                }
                              }
                            },
                            [_vm._v("效果数据")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editVersionBtn(
                                    scope.row.couponOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          scope.row.status === 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.stopBtn(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("停止")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              ref: "pop" + scope.row.couponOpenId,
                              attrs: {
                                trigger: "click",
                                "popper-class": "preview-popover-wrapperbg",
                                placement: "top",
                                width: "402"
                              },
                              on: {
                                show: function($event) {
                                  return _vm.generalize(scope.row, scope.$index)
                                }
                              },
                              model: {
                                value: scope.row.isPreview,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isPreview", $$v)
                                },
                                expression: "scope.row.isPreview"
                              }
                            },
                            [
                              _c("div", { staticClass: "code-bg" }, [
                                _c(
                                  "div",
                                  { staticClass: "code-img" },
                                  [
                                    _c("vue-qr", {
                                      class: "codeImg" + scope.$index,
                                      attrs: {
                                        text: scope.row.resourceUrl,
                                        size: 90,
                                        margin: 0
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "code-btns-bg" }, [
                                  _c("div", { staticClass: "code-btn-bg" }, [
                                    _c(
                                      "div",
                                      { staticClass: "code-btn-title" },
                                      [_vm._v("推广链接")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "code-btn" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "code-input",
                                            attrs: { size: "mini" },
                                            model: {
                                              value: scope.row.resourceUrl,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "resourceUrl",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.resourceUrl"
                                            }
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    ref: "previewCopyBtn",
                                                    staticClass:
                                                      "btn-code-copy",
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                      plain: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleUrlCopy(
                                                          scope.row,
                                                          scope.$index,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("复制")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "code-btn-bg" }, [
                                    _c(
                                      "div",
                                      { staticClass: "code-btn-title" },
                                      [_vm._v("推广路由")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "code-btn" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "code-input",
                                            attrs: { size: "mini" },
                                            model: {
                                              value: scope.row.resourceRoute,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "resourceRoute",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.resourceRoute"
                                            }
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    ref: "previewCopyBtn",
                                                    staticClass:
                                                      "btn-code-copy",
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                      plain: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleRouteCopy(
                                                          scope.row,
                                                          scope.$index,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("复制")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "code-btn-bg" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          ref: "previewCopyBtn",
                                          attrs: {
                                            type: "primary",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.downCode(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("下载二维码")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.sendType === 3,
                                      expression: "scope.row.sendType===3"
                                    }
                                  ],
                                  staticClass: "btn-spread",
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference"
                                },
                                [_vm._v("推广")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.copyBtn(scope.row.couponOpenId)
                                }
                              }
                            },
                            [_vm._v("复制")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footPage" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.initData
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "coupon-send-bg",
          attrs: {
            title: "优惠券发放",
            visible: _vm.couponDialogVisible,
            width: "570px"
          },
          on: {
            "update:visible": function($event) {
              _vm.couponDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "header-box" },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: { model: _vm.couponForm, rules: _vm.couponFormRule }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指定用户",
                        "label-width": _vm.formLabelWidth,
                        prop: "openId"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "add-user-box" },
                        [
                          _c("searchUserByInput", {
                            ref: "searchUser",
                            attrs: {
                              userOpenId: _vm.query.buyUserOpenId,
                              isReturnObj: true
                            },
                            on: { receiveuserid: _vm.receiveuserid }
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: { value: _vm.couponForm.name }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", plain: "" },
                              on: { click: _vm.addUser }
                            },
                            [_vm._v("添加用户")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "coupon-content-bg" },
            [
              _c(
                "el-table",
                {
                  staticClass: "coupon-user-list",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.userList,
                    "header-cell-class-name": "coupon-user-header",
                    "header-align": "center",
                    border: "",
                    stripe: "",
                    height: "100%"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户", "min-width": "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "coupon-user-info" }, [
                              _c(
                                "div",
                                { staticClass: "user-cover-bg" },
                                [
                                  _c("el-image", {
                                    class: scope.row.wxChatOpenId
                                      ? "we-chat-style"
                                      : "",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        scope.row.cover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(" "),
                                  scope.row.wxChatOpenId
                                    ? _c("img", {
                                        staticClass: "we-chat-img",
                                        attrs: {
                                          src: require("../../assets/images/wechart.png"),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-info-bg" }, [
                                _c("span", { staticClass: "user-info-name" }, [
                                  _vm._v(_vm._s(scope.row.nickName))
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "user-info-id" }, [
                                  _vm._v(_vm._s(scope.row.openId))
                                ])
                              ])
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-remove-outline icon-del",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.delUser(scope.row.openId)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.couponDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.sendLoading },
                  on: {
                    click: function($event) {
                      return _vm.couponSend()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.sendLoading ? "发送中...." : "发送"))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }