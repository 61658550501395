var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.workOrderNum > 0
            ? _c(
                "el-badge",
                {
                  staticClass: "work-order",
                  attrs: { value: _vm.workOrderNum, max: 99 }
                },
                [
                  _vm.isShowWorkOrder
                    ? _c("i", {
                        staticClass: "iconfont icon-Image help-icon",
                        on: { click: _vm.handleWorkOrder }
                      })
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowWorkOrder && _vm.workOrderNum <= 0
            ? _c("i", {
                staticClass: "iconfont icon-Image help-icon",
                on: { click: _vm.handleWorkOrder }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            staticClass: "iconfont icon-zonghoutaibangzhu help-icon",
            on: {
              click: function($event) {
                _vm.drawerProblem = true
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-right": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.newestVersion))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.cover, fit: "cover" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          staticStyle: {
                            height: "100%",
                            "line-height": "40px",
                            "text-align": "center",
                            "background-color": "#e9e9e9"
                          },
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c("div", { staticClass: "user-info-bg" }, [
                    _c(
                      "div",
                      { staticClass: "user-cover-bg" },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "user-cover",
                            attrs: { src: _vm.cover, fit: "cover" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                staticStyle: {
                                  height: "100%",
                                  "line-height": "40px",
                                  "text-align": "center",
                                  "background-color": "#e9e9e9"
                                },
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline"
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-name" }, [
                          _vm._v(_vm._s(_vm.$store.state.user.info.displayName))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-id" }, [
                          _vm._v(
                            "ID：" +
                              _vm._s(_vm.$store.state.user.info.openId) +
                              "\n                "
                          ),
                          _c("span", {
                            staticClass: "tag-read iconfont icon-copy",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.copyUserId(
                                  _vm.$store.state.user.info.openId,
                                  $event
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "user-operation-bg" },
                      [
                        _c(
                          "router-link",
                          { staticClass: "btn-home", attrs: { to: "/" } },
                          [
                            _c("i", { staticClass: "iconfont icon-zhuye" }),
                            _vm._v("主  页")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "btn-exit",
                            on: { click: _vm.logout }
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-tuichu" }),
                            _vm._v("退  出")
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "464px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("更新日志")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { height: "350px", overflow: "auto" } },
            _vm._l(_vm.versionManagement, function(item, key) {
              return _c(
                "div",
                { key: key, staticClass: "versionManagement" },
                [
                  _c("span", { staticClass: "versionNumber" }, [
                    _vm._v(_vm._s(item.versionNumber) + " ")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "versionUpdateTime" }, [
                    _vm._v(_vm._s(item.versionUpdateTime))
                  ]),
                  _vm._v(" "),
                  _vm._l(item.versionUupdateContent, function(
                    version_content,
                    key
                  ) {
                    return _c(
                      "div",
                      { key: key, staticStyle: { "line-height": "2" } },
                      [
                        _c("span", { staticClass: "versionUupdateContent" }, [
                          _vm._v(_vm._s(version_content))
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: { display: "flex" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "常见问题",
            visible: _vm.drawerProblem,
            size: "380px",
            "with-header": false
          },
          on: {
            "update:visible": function($event) {
              _vm.drawerProblem = $event
            }
          }
        },
        [
          _c("drawer-problem", {
            on: { handleClickProblem: _vm.handleClickProblem }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("problem-detail", {
        attrs: {
          visible: _vm.detailProblemDialog,
          "problem-id": _vm.problemId
        },
        on: {
          "update:visible": function($event) {
            _vm.detailProblemDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }