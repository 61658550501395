var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cash-check-wrapper" },
    [
      _c(
        "div",
        { staticClass: "cash-check-header" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handerlAddPromoter }
            },
            [_vm._v("添加推广员")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cash-search-bg" },
            [
              _c(
                "el-select",
                {
                  staticClass: "search-type-bg",
                  model: {
                    value: _vm.searchQuery.promotionSettingCode,
                    callback: function($$v) {
                      _vm.$set(_vm.searchQuery, "promotionSettingCode", $$v)
                    },
                    expression: "searchQuery.promotionSettingCode"
                  }
                },
                _vm._l(_vm.levelSelectList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.title, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "search-txt",
                attrs: { placeholder: "搜索关键词", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchContent($event)
                  }
                },
                model: {
                  value: _vm.searchQuery.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.searchQuery, "keyword", $$v)
                  },
                  expression: "searchQuery.keyword"
                }
              }),
              _vm._v(" "),
              _c("search-btn", { on: { searchContent: _vm.searchContent } }, [
                _c(
                  "div",
                  { staticClass: "cash-search-btn" },
                  [
                    _c(
                      "el-form",
                      { attrs: { "label-width": "90px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "认证时间" } },
                          [
                            _c("el-date-picker", {
                              staticClass: "date-bg",
                              attrs: {
                                type: "daterange",
                                "default-time": ["00:00:00", "23:59:59"],
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd HH:mm:ss"
                              },
                              model: {
                                value: _vm.joinTime,
                                callback: function($$v) {
                                  _vm.joinTime = $$v
                                },
                                expression: "joinTime"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "申请方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search-type-bg",
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.searchQuery.applyType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchQuery, "applyType", $$v)
                                  },
                                  expression: "searchQuery.applyType"
                                }
                              },
                              _vm._l(_vm.applyTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.title,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "推广员状态" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "search-order-state",
                                model: {
                                  value: _vm.searchQuery.isDeleted,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchQuery, "isDeleted", $$v)
                                  },
                                  expression: "searchQuery.isDeleted"
                                }
                              },
                              _vm._l(_vm.stateList, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cash-check-content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              ref: "promotionUserTable",
              attrs: {
                data: _vm.promotionUserList,
                "default-sort": { prop: "updatedAt", order: "descending" },
                height: "100%"
              },
              on: {
                "selection-change": _vm.handleSelectChange,
                "sort-change": _vm.sortChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分销人员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "user-info-bg" },
                          [
                            _c("el-image", {
                              staticClass: "user-cover-bg",
                              attrs: {
                                onerror: _vm.errorUserPhoto,
                                src: _vm.getImgUrl(
                                  scope.row.cover,
                                  _vm.thumbnailStyle
                                ),
                                fit: "cover"
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "user-info-name" }, [
                              _vm._v(_vm._s(_vm.showVal(scope.row.nickName)))
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.promotionSettingName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "申请方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("getStateTitle")(
                                scope.row.applyType,
                                _vm.applyTypeList
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.showVal(scope.row.realName)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.showVal(scope.row.phone)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上级推广员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "user-info-bg" },
                          [
                            _c("el-image", {
                              staticClass: "user-cover-bg",
                              attrs: {
                                onerror: _vm.errorUserPhoto,
                                src: _vm.getImgUrl(
                                  scope.row.parentCover,
                                  _vm.thumbnailStyle
                                ),
                                fit: "cover"
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "user-info-name" }, [
                              _vm._v(_vm._s(_vm.showVal(scope.row.parentName)))
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { sortable: "customerNumber", label: "累计客户量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.customerNumber) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { sortable: "invitationNumber", label: "累计分享次数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.invitationNumber) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "累计佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          ￥" +
                            _vm._s(
                              _vm.numFormat((scope.row.total / 100).toFixed(2))
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "134", label: "认证时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.passedAt
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      scope.row.passedAt,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [_vm._v(" -- ")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "50", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.isDeleted
                          ? _c("span", { staticClass: "txt-disabled" }, [
                              _vm._v("禁用")
                            ])
                          : _c("span", [_vm._v("正常")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "90", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-edit",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toLookDetail(scope.row.userOpenId)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handleCommand } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-more",
                                attrs: { type: "text" }
                              },
                              [_vm._v("更多")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    staticClass: "btn-edit",
                                    attrs: {
                                      command: _vm.composeValue(
                                        "edit",
                                        scope.row
                                      )
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                scope.row.isDeleted === 0
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: _vm.composeValue(
                                            "disabled",
                                            scope.row.userOpenId
                                          )
                                        }
                                      },
                                      [_vm._v("禁用")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.isDeleted === 1
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: _vm.composeValue(
                                            "start",
                                            scope.row.userOpenId
                                          )
                                        }
                                      },
                                      [_vm._v("启用")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "cash-check-pages" }, [
        _c(
          "div",
          { staticClass: "cash-pages-l" },
          [
            _c("el-checkbox", {
              staticClass: "pagination-check",
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.handleSelectedAll },
              model: {
                value: _vm.selectedAll,
                callback: function($$v) {
                  _vm.selectedAll = $$v
                },
                expression: "selectedAll"
              }
            }),
            _vm._v(" "),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading.fullscreen.lock",
                    value: _vm.saveLoading,
                    expression: "saveLoading",
                    modifiers: { fullscreen: true, lock: true }
                  }
                ],
                staticClass: "batch-box",
                attrs: {
                  "element-loading-text": "数据保存中...",
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(255, 255, 255, 0.2)",
                  "split-button": "",
                  type: "primary"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handleBatchOperation($event)
                  },
                  command: _vm.handleBatchClick
                }
              },
              [
                _vm._v("\n        " + _vm._s(_vm.batchName) + "\n        "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.reviewList, function(item) {
                    return _c(
                      "el-dropdown-item",
                      { key: item.command, attrs: { command: item.command } },
                      [_vm._v(_vm._s(item.batch))]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cash-pages-r" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.searchQuery.page,
                limit: _vm.searchQuery.size,
                "current-page": _vm.searchQuery.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.searchQuery, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.searchQuery, "size", $event)
                },
                pagination: _vm.getPromotionUserList
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "promoter-dialog",
          attrs: {
            title: _vm.dialogTitle,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "promoterForm",
              attrs: {
                model: _vm.promoterForm,
                rules: _vm.promoterRules,
                "label-width": "90px",
                "label-position": "right"
              }
            },
            [
              _vm.dialogTitle === "添加推广员"
                ? _c(
                    "div",
                    { staticClass: "addPromoter" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户", prop: "userOpenId" } },
                        [
                          _c("searchUserByInput", {
                            ref: "searchUser",
                            staticClass: "with-330",
                            attrs: { isReturnObj: true },
                            on: { receiveuserid: _vm.getUserid }
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: { value: _vm.promoterForm.userOpenId }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "realName" } },
                        [
                          _c("el-input", {
                            staticClass: "with-330",
                            attrs: { placeholder: "请输入姓名", clearable: "" },
                            model: {
                              value: _vm.promoterForm.realName,
                              callback: function($$v) {
                                _vm.$set(_vm.promoterForm, "realName", $$v)
                              },
                              expression: "promoterForm.realName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c("el-input", {
                            staticClass: "with-330",
                            attrs: {
                              placeholder: "请输入手机号",
                              clearable: "",
                              maxlength: "11"
                            },
                            model: {
                              value: _vm.promoterForm.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.promoterForm, "phone", $$v)
                              },
                              expression: "promoterForm.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份证号", prop: "identityCard" } },
                        [
                          _c("el-input", {
                            staticClass: "with-330",
                            attrs: {
                              placeholder: "请输入身份证号",
                              clearable: ""
                            },
                            model: {
                              value: _vm.promoterForm.identityCard,
                              callback: function($$v) {
                                _vm.$set(_vm.promoterForm, "identityCard", $$v)
                              },
                              expression: "promoterForm.identityCard"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级推广员" } },
                        [
                          _c("promoter", {
                            staticClass: "with-330",
                            on: { receiveUserOpenId: _vm.getPromoter }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广员类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "with-330",
                              model: {
                                value: _vm.promoterForm.promotionSettingCode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoterForm,
                                    "promotionSettingCode",
                                    $$v
                                  )
                                },
                                expression: "promoterForm.promotionSettingCode"
                              }
                            },
                            _vm._l(_vm.levelList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.title, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "editPromoter" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广员姓名", prop: "reason" } },
                        [_vm._v(_vm._s(_vm.promoterForm.realName))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广员类别", prop: "reason" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "with-330",
                              model: {
                                value: _vm.promoterForm.promotionSettingCode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoterForm,
                                    "promotionSettingCode",
                                    $$v
                                  )
                                },
                                expression: "promoterForm.promotionSettingCode"
                              }
                            },
                            _vm._l(_vm.levelList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.title, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邀请人", prop: "reason" } },
                        [
                          _c("promoter", {
                            staticClass: "with-330",
                            attrs: {
                              partnerOpenId: _vm.promoterForm.parentOpenId
                            },
                            on: { receiveUserOpenId: _vm.getPromoter }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "修改原因", prop: "remarks" } },
                        [
                          _c("el-input", {
                            staticClass: "txt-reason",
                            attrs: {
                              autosize: { minRows: 2, maxRows: 2 },
                              type: "textarea",
                              placeholder: "请输入此次编辑修改内容及原因"
                            },
                            model: {
                              value: _vm.promoterForm.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.promoterForm, "remarks", $$v)
                              },
                              expression: "promoterForm.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSavePromoter }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }