"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _scale = require("@/utils/scale");

/* eslint-disable no-unused-vars */
var editor = {
  namespaced: true,
  state: {
    size: {
      width: undefined,
      height: undefined,
      scale: 1
    },
    drag: {
      id: undefined,
      time: undefined,
      x: undefined,
      y: undefined
    }
  },
  mutations: {
    IMAGE_SIZE_UPDATE: function IMAGE_SIZE_UPDATE(state, _ref) {
      var pageWidth = _ref.pageWidth,
          pageHeight = _ref.pageHeight,
          width = _ref.width,
          height = _ref.height;
      // eslint-disable-next-line no-unused-vars
      // getScaleRatio({ width: pageWidth, height: pageHeight }, { width, height })
      var ratio = parseFloat(Number(pageWidth / width).toFixed(3));
      state.size.width = width * ratio;
      state.size.height = height * ratio; // state.size.width = width
      // state.size.height = height

      state.size.scale = 1;
    },
    DRAG_RESET: function DRAG_RESET(state) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          id = _ref2.id,
          x = _ref2.x,
          y = _ref2.y;

      state.drag.id = id;
      state.drag.time = new Date().getTime();
      state.drag.x = x;
      state.drag.y = y;
    },
    DRAG_MOVE: function DRAG_MOVE(state) {
      var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          x = _ref3.x,
          y = _ref3.y;

      state.drag.time = new Date().getTime();
      state.drag.x = x;
      state.drag.y = y;
    },
    SIZE_SCALE_UP: function SIZE_SCALE_UP(state, height) {
      state.size.scale += 0.1;
    },
    SIZE_SCALE_DOWN: function SIZE_SCALE_DOWN(state, height) {
      state.size.scale -= 0.1;

      if (state.size.scale <= 0.1) {
        state.size.scale = 0.1;
      }
    }
  },
  actions: {
    imageSizeUpdate: function imageSizeUpdate(_ref4, info) {
      var commit = _ref4.commit;
      commit('IMAGE_SIZE_UPDATE', info);
    },
    dragReset: function dragReset(_ref5, info) {
      var commit = _ref5.commit;
      commit('DRAG_RESET', info);
    },
    dragMove: function dragMove(_ref6, point) {
      var commit = _ref6.commit;
      commit('DRAG_MOVE', point);
    },
    sizeScaleUp: function sizeScaleUp(_ref7) {
      var commit = _ref7.commit;
      commit('SIZE_SCALE_UP');
    },
    sizeScaleDown: function sizeScaleDown(_ref8) {
      var commit = _ref8.commit;
      commit('SIZE_SCALE_DOWN');
    }
  }
};
var _default = editor;
exports.default = _default;