"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllMp = getAllMp;
exports.getRequestMpByPartnerOpenId = getRequestMpByPartnerOpenId;
exports.getByPartnerOpenId = getByPartnerOpenId;
exports.adminChangePartnerInfo = adminChangePartnerInfo;
exports.getRequestFinance = getRequestFinance;
exports.getFinance = getFinance;
exports.passPartnerInfo = passPartnerInfo;
exports.passFinanceInfo = passFinanceInfo;
exports.noPassFinanceInfo = noPassFinanceInfo;
exports.noPassPartnerInfo = noPassPartnerInfo;
exports.getPartnerTitle = getPartnerTitle;
exports.mpCategory = mpCategory;
exports.getPartner = getPartner;
exports.categoryBatch = categoryBatch;
exports.getByResourcesId = getByResourcesId;
exports.saveCategoryInfo = saveCategoryInfo;
exports.editCategoryInfo = editCategoryInfo;
exports.deleteCategoryInfo = deleteCategoryInfo;
exports.addResourceSync = addResourceSync;
exports.deleteResourceSync = deleteResourceSync;
exports.editResourceSync = editResourceSync;
exports.selectResourceSync = selectResourceSync;
exports.setPreferred = setPreferred;
exports.editAccountAdmin = editAccountAdmin;
exports.partnerListForXiaoYan = partnerListForXiaoYan;
exports.getPartners = getPartners;
exports.getWithdrawalsRecord = getWithdrawalsRecord;
exports.getAuditPass = getAuditPass;
exports.getRejectPass = getRejectPass;
exports.getApprovalSuccess = getApprovalSuccess;
exports.getReject = getReject;
exports.getTransferSuccess = getTransferSuccess;
exports.getTransferFail = getTransferFail;
exports.getbatchMoneyTransferSuccess = getbatchMoneyTransferSuccess;
exports.getbatchMoneyTransferFail = getbatchMoneyTransferFail;
exports.getPartnerWithdrawByOpenId = getPartnerWithdrawByOpenId;
exports.getAssignmentProportion = getAssignmentProportion;
exports.getRequestMp = getRequestMp;
exports.moveCategory = moveCategory;
exports.getAuthPartnerList = getAuthPartnerList;
exports.getparternerListByuserid = getparternerListByuserid;
exports.getfindAllPartnerPrivilege = getfindAllPartnerPrivilege;
exports.savePartnerPrivilege = savePartnerPrivilege;
exports.addPartner = addPartner;
exports.passInitialCheck = passInitialCheck;
exports.rejectInitialCheck = rejectInitialCheck;
exports.softDeletePartner = softDeletePartner;
exports.replyPar = replyPar;
exports.inKindAssignmentProportionApi = inKindAssignmentProportionApi;
exports.upDatePartnerDomainInfo = upDatePartnerDomainInfo;
exports.uploadInvoiceApi = uploadInvoiceApi;
exports.getPartnerGetMapPartners = getPartnerGetMapPartners;
exports.getPrivilegeMenu = getPrivilegeMenu;
exports.getProductInfo = getProductInfo;
exports.getProductAmount = getProductAmount;
exports.getPartnerAdmin = getPartnerAdmin;
exports.getPartnerMessageByPartnerId = getPartnerMessageByPartnerId;
exports.deletePartnerAdminInfo = deletePartnerAdminInfo;
exports.getAllPartnerPrivilege = getAllPartnerPrivilege;
exports.setPartnerAdminPrivilege = setPartnerAdminPrivilege;
exports.addPartnerAdmin = addPartnerAdmin;
exports.getAllPartnerAdminPrivilege = getAllPartnerAdminPrivilege;
exports.editPartnerAdminInfo = editPartnerAdminInfo;
exports.getRecordItemOpenId = getRecordItemOpenId;
exports.getRecordItem = getRecordItem;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/admin/"));
var mpRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/mp/"));
/**
 * 根据商户的 open id 查询所有的商户管理员
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */

function getPartnerAdmin(partnerOpenId) {
  return request.get("partnerAdmin/partnerOpenId/".concat(partnerOpenId));
}
/**
 * 根据 partnerOpenId 恢复商户
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function replyPar(partnerOpenId) {
  return request.get("/partner/request/reply/".concat(partnerOpenId));
}
/**
 * 根据 partnerOpenId 软删除商户
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function softDeletePartner(partnerOpenId) {
  return request.delete("/partner/request/".concat(partnerOpenId));
}
/**
 * 根据 partner open id预审核驳回
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function rejectInitialCheck(openId) {
  return request.patch("/partner/request/turnDownInitialCheck/".concat(openId));
}
/**
 * 根据 partner open id预审核通过
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function passInitialCheck(openId) {
  return request.patch("/partner/request/passInitialCheck/".concat(openId));
}
/**
 * 保存商户权限
 * @param {Number} id
 * @returns {Promise<*>}
 */


function savePartnerPrivilege(params, data) {
  return request.post('/partnerPrivilege/store', data, {
    params: params
  });
}
/**
 * 以目录树的形式返回所有的权限菜单商户权限(新增)
 * @param {String} params
 * @returns {Promise<*>}
 */


function getfindAllPartnerPrivilege(params) {
  return request.get('partnerPrivilege/findAllPartnerPrivilege', {
    params: params
  });
}
/**
 * 根据userOpenId获取商户列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getparternerListByuserid(params) {
  return request.get('partner/request/getPartners', {
    params: params
  });
}
/**
 * 分页查询商户临时信息
 * @param {Number} params
 * @returns {Promise<*>}
 */


function getAllMp(params) {
  return request.get('partner/request', {
    params: params
  });
}
/**
 * 根据 openId 获取指定的商户临时信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRequestMpByPartnerOpenId(openId) {
  return request.get("/partner/request/".concat(openId));
}
/**
 * 通过用户的 openId 获取商户正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRequestMp(openId) {
  return request.get("/partner/getByUserOpenId/".concat(openId));
}
/**
 * 获取指定的商户正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getByPartnerOpenId(openId) {
  return request.get("/partner/".concat(openId));
}
/**
 * 更新商户临时信息，不需要审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function adminChangePartnerInfo(openId, data) {
  return request.patch('/partner/request/', data, {
    params: {
      openId: openId
    }
  });
}
/**
 * 新建商户
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPartner(data) {
  return request.post('/partner/request/', data);
}
/**
 * 获取指定的财务临时信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRequestFinance(openId) {
  return request.get("/finance/request/".concat(openId));
}
/**
 * 获取指定的商户财务正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getFinance(openId) {
  return request.get("/finance/".concat(openId));
}
/**
 * 审核商户临时信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function passPartnerInfo(openId) {
  return request.patch("/partner/request/verify/".concat(openId));
}
/**
 * 审核财务信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function passFinanceInfo(openId) {
  return request.patch("/finance/request/verify/".concat(openId));
}
/**
 * 驳回财务信息
 * @param {String} openId
 * @param {String} reason 驳回原因
 * @returns {Promise<*>}
 */


function noPassFinanceInfo(openId, reason) {
  return request.patch("/finance/request/refuse/".concat(openId), undefined, {
    params: {
      reason: reason
    }
  });
}
/**
 * 驳回商户临时信息
 * @param {String} openId
 * @param {String} reason 驳回原因
 * @returns {Promise<*>}
 */


function noPassPartnerInfo(openId, reason) {
  return request.patch("/partner/request/refuse/".concat(openId), undefined, {
    params: {
      reason: reason
    }
  });
}
/**
 * 根据多个用户的 open id 查询商户临时信息
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function getPartnerTitle(openIds) {
  return request.get('/partner/request/getByUserOpenIds', {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 根据资源类型和商户的 openId 获取分类列表
 * @param {Number} id
 * @param {Object} params
 * @returns {Promise<*>}
 */


function mpCategory(id, params) {
  return request.get("/category/resourceId/".concat(id), {
    params: params
  });
}
/**
 * 获取指定的商户正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getPartner(openId) {
  return request.get("/partner/".concat(openId));
}
/**
 * 批量修改分类信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function categoryBatch(data) {
  return request.patch('/category/batch', data);
}
/**
 * 根据资源类型和商户的 openId 获取分类列表
 * @param {Number} id
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getByResourcesId(id, params) {
  return request.get("/category/resourceId/".concat(id), {
    params: params
  });
}
/**
 * 保存分类信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveCategoryInfo(data) {
  return request.post('/category', data);
}
/**
 * 修改指定的分类
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editCategoryInfo(id, data) {
  return request.patch("/category/".concat(id), data);
}
/**
 * 删除指定的分类
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deleteCategoryInfo(id) {
  return request.delete("/category/".concat(id));
}
/**
 * 移动指定的分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function moveCategory(params) {
  return request.patch('/category/moveTypes', undefined, {
    params: params
  });
}
/**
 * 添加教辅同步信息
 * @param data
 * @returns {Promise<*>}
 */


function addResourceSync(data) {
  return request.post('/resourceSync', data);
}
/**
 * 删除教辅同步信息
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deleteResourceSync(id) {
  return request.delete("/resourceSync/".concat(id));
}
/**
 * 修改教辅同步信息
 * @param {Object} data
 * @param {Number} id
 * @returns {Promise<*>}
 */


function editResourceSync(data, id) {
  return request.patch("/resourceSync/".concat(id), data);
}
/**
 * 分页查询指定商户的 教辅同步信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function selectResourceSync(params) {
  return request.get('/resourceSync/partnerOpenId', {
    params: params
  });
}
/**
 * 设为默认同步信息
 * @param {Number} id
 * @returns {Promise<*>}
 */


function setPreferred(id) {
  return request.post('/resourceSync/setPreferred', undefined, {
    params: {
      id: id
    }
  });
}
/**
 * 更新财务信息，不需要审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editAccountAdmin(openId, data) {
  return request.patch("/finance/request/".concat(openId), data);
}
/**
 * 分页查询商户临时信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function partnerListForXiaoYan(params) {
  return request.get('/partner/request/forXiaoYan', {
    params: params
  });
}
/**
 * 商户正式
 * @param {String} params
 * @returns {Promise<*>}
 */


function getAuthPartnerList(params) {
  return request.get('/partner/', {
    params: params
  });
}
/**
 * 批量查询商户信息
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function getPartners(openIds) {
  return request.get('/partner/request/getPartnerByOpenIds/forMap', {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 分页获取提现记录表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWithdrawalsRecord(params) {
  return request.get('/withdraw/findAll', {
    params: params
  });
}
/**
 * 根据提现记录表openId 获取详情信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPartnerWithdrawByOpenId(params) {
  return request.get('/withdraw/findPartnerWithdrawByOpenId', {
    params: params
  });
}
/**
 * 审核通过接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAuditPass(params) {
  return request.patch('/withdraw/withdrawalApprovalSuccess', undefined, {
    params: params
  });
}
/**
 * 驳回通过接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRejectPass(params) {
  return request.patch('/withdraw/withdrawalApprovalFail', undefined, {
    params: params
  });
}
/**
 * 批量通过接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getApprovalSuccess(params) {
  return request.patch('/withdraw/batchWithdrawalApprovalSuccess', undefined, {
    params: params
  });
}
/**
 * 批量驳回接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getReject(params) {
  return request.patch('/withdraw/batchWithdrawalApprovalFail', undefined, {
    params: params
  });
}
/**
 * 汇款通过接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getTransferSuccess(params) {
  return request.patch('/withdraw/moneyTransferSuccess', undefined, {
    params: params
  });
}
/**
 * 汇款失败接口接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getTransferFail(params) {
  return request.patch('/withdraw/moneyTransferFail', undefined, {
    params: params
  });
}
/**
 * 批量汇款成功接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getbatchMoneyTransferSuccess(params) {
  return request.patch('/withdraw/batchMoneyTransferSuccess', undefined, {
    params: params
  });
}
/**
 * 批量汇款失败接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getbatchMoneyTransferFail(params) {
  return request.patch('/withdraw/batchMoneyTransferFail', undefined, {
    params: params
  });
}
/**
 * 根据 partner open id 修改商户分成比例【不需要审核】
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAssignmentProportion(params) {
  return request.patch('/partner/request/update/assignmentProportion', undefined, {
    params: params
  });
}
/**
 * 根据 partner open id 修改商户实物分成比例【不需要审核】
 * @param {Object} params
 * @returns {Promise<*>}
 */


function inKindAssignmentProportionApi(params) {
  return request.patch('/partner/request/update/inKindAssignmentProportion', undefined, {
    params: params
  });
}
/**
 * 修改商户域名信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function upDatePartnerDomainInfo(data) {
  return request.post('/partner/request/updatePartnerRequestAndPartnerDomainAddress', data);
}
/**
 * 上传发票
 * @param {String} params
 * @returns {Promise<*>}
 */


function uploadInvoiceApi(params) {
  return request.post('/withdraw/uploadInvoice', undefined, {
    params: params
  });
}
/**
 * 查询erp商品信息
 *
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getProductInfo(params) {
  return mpRequest.get('retail/erp/product/info', {
    params: params
  });
}
/**
 * 查询erp商品库存
 *
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getProductAmount(params) {
  return mpRequest.get('retail/erp/product/amount', {
    params: params
  });
}
/**
 * 根据多个 open id 查询商户信息
 * @param {string} openIds
 * @returns {Promise<*>}
 */


function getPartnerGetMapPartners(openIds) {
  return request.get('partner/getMapPartners', {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 根据商户的openID查询所有的商户管理员
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function getPartnerMessageByPartnerId(partnerOpenId, params) {
  return request.get("/partnerAdmin/partnerOpenId/".concat(partnerOpenId), {
    params: params
  });
}
/**
 * 根据商户管理员的主键 id 删除
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deletePartnerAdminInfo(id) {
  return mpRequest.delete("partnerAdmin/".concat(id));
}
/**
 * 以目录树的形式返回所有的权限菜单 (添加协作者)
 * @returns {Promise<*>}
 */


function getAllPartnerPrivilege(params) {
  return mpRequest.get('partnerPrivilege/getAllPrivilegeMenu', {
    params: params
  });
}
/**
 * 批量增加商户管理员权限 （先删除，后新增）
 * @param {Object} params
 * @param {Object} body
 * @returns {Promise<*>}
 */


function setPartnerAdminPrivilege(params, data) {
  return mpRequest.post('/partnerAdminPrivilege/batch', data, {
    params: params
  });
}
/**
 * 增加商户管理员
 * @param {Object} body
 * @returns {Promise<*>}
 */


function addPartnerAdmin(data) {
  return mpRequest.post('/partnerAdmin', data);
}
/**
 * 根据商户的openId和用户的openid查询所有的用户信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllPartnerAdminPrivilege(params) {
  return mpRequest.get('/partnerAdminPrivilege/getAll/forList', {
    params: params
  });
}
/**
 * 根据商户管理员的主键 id 更新管理员信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editPartnerAdminInfo(id, data) {
  return mpRequest.patch("partnerAdmin/".concat(id), data);
}
/**
 * 以目录树的形式返回所有的权限菜单 (添加协作者)
 * @returns {Promise<*>}
 */


function getPrivilegeMenu(params) {
  return mpRequest.get('/partnerPrivilege/getPrivilegeMenu', {
    params: params
  });
}
/**
 * 提现记录表 详情 (openid)
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRecordItemOpenId(openId) {
  return mpRequest.get('/withdraw/findPartnerWithdrawByOpenId', {
    params: {
      openId: openId
    }
  });
}
/**
 * 提现记录表 详情 (id)
 * @param {Number} id
 * @returns {Promise<*>}
 */


function getRecordItem(id) {
  return request.get('/withdraw/findPartnerWithdrawById', {
    params: {
      id: id
    }
  });
}