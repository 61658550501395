"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/web.dom.iterable");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _audiobook = require("../../../api/audiobook");

var _mp = require("../../../api/mp");

var _index = require("../../../api/common/index");

var _common = require("@/utils/common");

var _utils = require("@/utils");

var _const = require("@/utils/const");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var statusOptions = [{
  key: _const.RESOURCE_PULISHED_STATE,
  state_name: '已发布',
  class_name: 'icon-inbox',
  colorName: 'bg1'
}, {
  key: _const.RESOURCE_REVIEW_STATE,
  state_name: '审核中',
  class_name: 'icon-hourglass',
  colorName: 'bg2'
}, {
  key: _const.RESOURCE_DRAFT_STATE,
  state_name: '草稿箱',
  class_name: 'icon-envelope-open',
  colorName: 'bg3'
}, {
  key: _const.RESOURCE_REFUSED_STATE,
  state_name: '未通过',
  class_name: 'icon-block',
  colorName: 'bg4'
}, {
  key: _const.RESOURCE_DELETED_STATE,
  state_name: '回收站',
  class_name: 'icon-trash',
  colorName: 'bg5'
}];
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    stateFilter: function stateFilter(status) {
      switch (status) {
        case _const.RESOURCE_DRAFT_STATE:
          return '未通过';

        case _const.RESOURCE_REVIEW_STATE:
          return '待审';

        case _const.RESOURCE_PULISHED_STATE:
          return '已通过';

        case _const.RESOURCE_REFUSED_STATE:
          return '未通过';

        case _const.RESOURCE_DELETED_STATE:
          return '未通过';
      }
    },
    parseTime: _utils.parseTime
  },
  data: function data() {
    return {
      status: {},
      state_current: 3,
      statusOptions: statusOptions,
      total: 0,
      currentPage: 1,
      // 初始页
      pageSize: 10,
      // 每页显示的条数
      listLoading: true,
      lists: null,
      listQuery: {
        keyword: undefined,
        page: 1,
        size: 10
      },
      data: [],
      classList: [],
      resourceType: 4,
      isShowOperation: false,
      bookId: undefined,
      RESOURCE_DELETED_STATE: _const.RESOURCE_DELETED_STATE,
      RESOURCE_REVIEW_STATE: _const.RESOURCE_REVIEW_STATE,
      RESOURCE_REFUSED_STATE: _const.RESOURCE_REFUSED_STATE,
      RESOURCE_DRAFT_STATE: _const.RESOURCE_DRAFT_STATE,
      RESOURCE_PULISHED_STATE: _const.RESOURCE_PULISHED_STATE,
      stateRadio: '全部',
      multipleSelection: [],
      preview: false,
      // 控制预览弹窗是否显示
      previewUrl: '',
      selectedAll: false
    };
  },
  computed: {
    canClick: function canClick() {
      if (this.stateRadio === '全部') {
        return true;
      }

      if (this.stateRadio === '待审') {
        return false;
      }

      if (this.stateRadio === '已通过') {
        return true;
      }

      if (this.stateRadio === '未通过') {
        return true;
      }

      return true;
    }
  },
  watch: {
    stateRadio: function stateRadio(newVal) {
      if (newVal === '全部') {
        this.listQuery = {
          keyword: undefined,
          page: 1,
          size: 10
        };
        this.getList();
      } else if (newVal === '待审') {
        this.handleFilter(2);
      } else if (newVal === '已通过') {
        this.handleFilter(3);
      } else if (newVal === '未通过') {
        this.handleFilter(4);
      }
    },
    selectedAll: function selectedAll(newVal) {
      if (newVal) {
        this.toggleSelection(this.lists);
      } else {
        this.toggleSelection();
      }
    }
  },
  created: function created() {
    this.getList(); // 获取列表

    this.getListCount(); // 获取列表数

    this.getResourceInfo();
  },
  methods: {
    toggleSelection: function toggleSelection(rows) {
      var _this = this;

      if (rows) {
        rows.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 通过按钮点击事件
    passItem: function passItem() {
      var _this2 = this;

      this.multipleSelection.forEach(function (item, index, arr) {
        (0, _audiobook.passCheckItem)(item.resourceOpenId).then(function (res) {
          if (res.data.reviewed) {
            _this2.$message({
              message: '恭喜，审核通过！',
              type: 'success'
            });

            _this2.getList();
          } else {
            _this2.$message.error('审核失败！');
          }
        });
      });
    },
    // 驳回按钮的点击事件
    notPassItem: function notPassItem() {
      var _this3 = this;

      this.multipleSelection.forEach(function (item, index, arr) {
        (0, _audiobook.notPassCheckItem)(item.resourceOpenId).then(function (res) {
          if (res.data.success) {
            _this3.$message({
              message: '已驳回！',
              type: 'success'
            });

            _this3.getList();
          } else {
            _this3.$message.error('驳回失败！');
          }
        });
      });
    },
    // 预览
    previewFn: function previewFn(idbook) {
      this.previewUrl = "https://audiobook.dev.taozhi.cn/#/readBook?bookOpenId=".concat(idbook);
      this.preview = true;
    },
    previewClose: function previewClose() {
      this.previewUrl = '';
      this.preview = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getImgUrl: function getImgUrl(cover) {
      var imgAllUrl = (0, _common.getImageUrl)(cover, 'x-oss-process=style/book-cover');
      return imgAllUrl;
    },
    // 打开审核点学书页面
    openCheckBook: function openCheckBook() {
      this.$router.push({
        path: 'checkBook'
      });
    },
    // 进入详情
    showPage: function showPage(id) {
      this.$store.dispatch('book/updateBookOpenId', id);
      this.$router.push({
        path: 'audioBookpage',
        query: {
          openId: id
        }
      });
    },
    // 操作更新的时候，重新获取列表和每一个状态项的数量
    updateList: function updateList() {
      this.getList();
      this.getListCount();
    },
    search: function search() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this4 = this;

      // 查询列表
      this.listLoading = true;
      (0, _audiobook.bookList)(this.listQuery).then(function (res) {
        _this4.lists = res.data;
        _this4.total = res.totalElements; // 总条目数

        setTimeout(function () {
          _this4.listLoading = false;
        }, 1 * 1000); // 根据列表查询出来的 Id，查询对应的商户

        _this4.lists.forEach(function (item, index, arr) {
          // 获取商户信息 将获取的商户信息放置到对应的 lists数组项中
          (0, _mp.getPartner)(_this4.lists[index].partnerOpenId).then(function (res) {
            _this4.lists[index].partnerLists = res.data;
          });
        });

        console.log(_this4.lists);
      }).catch(function (error) {
        console.log(error);
      });
    },
    getListCount: function getListCount() {
      var _this5 = this;

      // 查询列表的各状态数量
      (0, _audiobook.getBookStateCount)().then(function (res) {
        _this5.status[1] = res.data.draftStatus;
        _this5.status[2] = res.data.reviewStatus;
        _this5.status[3] = res.data.pulishedStatus;
        _this5.status[4] = res.data.refusedStatus;
        _this5.status[5] = res.data.deletedStatus;
      });
    },
    handleFilter: function handleFilter(status) {
      // 过滤状态
      this.listQuery.status = status;
      this.getList();
    },
    getResourceInfo: function getResourceInfo() {
      var _this6 = this;

      // 查询资源类型
      (0, _index.getResourceType)().then(function (res) {
        var index = res.data.findIndex(function (x) {
          return x.title === '点读书' && !x.deletedAt;
        });
        _this6.resourceType = res.data[index].id;
      });
    },
    stateCurrents: function stateCurrents(val) {
      this.state_current = val;
      this.listQuery.status = val;
      this.getList();
    }
  }
};
exports.default = _default;