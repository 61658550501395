"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResourceInfo = getResourceInfo;
exports.translationResourceType = translationResourceType;
exports.handleToResourceFn = handleToResourceFn;
exports.getSyncInfoVal = getSyncInfoVal;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _router = _interopRequireDefault(require("../router"));

function getResourceInfo(_ref) {
  var syncInfoText = _ref.syncInfoText,
      categoryInfoText = _ref.categoryInfoText,
      periodName = _ref.periodName;
  return [syncInfoText, categoryInfoText, periodName].filter(function (i) {
    return !!i;
  }).join(' · ');
}

function translationResourceType(resourceTypeId) {
  var map = {
    1: '视频',
    2: '音频',
    3: '点学书',
    4: '专辑',
    5: '金星币',
    6: 'VIP',
    11: '直播',
    8: '文件包',
    9: '图文',
    10: '文件'
  };
  return Object.keys(map).includes("".concat(resourceTypeId)) ? map[resourceTypeId] : resourceTypeId;
} // 编辑内容资源


function handleToResourceFn(item, type, path) {
  var openId = item.openId;
  path = JSON.stringify(path); //  视频资源

  if (item.resourceType === 1) {
    var query = type === 'link' ? {
      videoId: openId
    } : {
      videoId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'Editvideo',
      query: query
    }); //  音频资源

  } else if (item.resourceType === 2) {
    // 音频
    var _query = type === 'link' ? {
      audioId: openId
    } : {
      audioId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'AudioDetail',
      query: _query
    });
  } else if (item.resourceType === 3) {
    // 点学书
    var _query2 = type === 'link' ? {
      openId: openId
    } : {
      openId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'ResourceAudioBookUpdate',
      query: _query2
    });
  } else if (item.resourceType === 4) {
    // 专辑
    var _query3 = type === 'link' ? {
      albumId: openId
    } : {
      albumId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'detailAlbum',
      query: _query3
    });
  } else if (item.resourceType === 8) {
    //  文件包资源
    var _query4 = type === 'link' ? {
      fileId: openId
    } : {
      fileId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'FileDetail',
      query: _query4
    });
  } else if (item.resourceType === 9) {
    //  图文资源
    var _query5 = type === 'link' ? {
      textId: openId
    } : {
      textId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'ImageTextDetail',
      params: _query5
    });
  } else if (item.resourceType === 10) {
    // 文件资源
    var _query6 = type === 'link' ? {
      fileId: openId
    } : {
      fileId: openId,
      backPathParam: path
    };

    _router.default.push({
      name: 'SingleFileDetail',
      query: _query6
    });
  } else if (item.resourceType === 19) {
    // 英语会话详情
    _router.default.push({
      name: 'DetailFollow',
      query: {
        followBookId: openId
      }
    });
  }
}

function getSyncInfoVal(key, data) {
  if (data.tempCode) {
    var list = Array.isArray(data.tempCode[key]) ? data.tempCode[key] : [];

    if (list.length > 0) {
      return data.tempCode[key].join(',');
    }
  }

  return '';
}