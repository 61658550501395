import { render, staticRenderFns } from "./addFollowBook.vue?vue&type=template&id=a3943ed8&scoped=true&"
import script from "./addFollowBook.vue?vue&type=script&lang=js&"
export * from "./addFollowBook.vue?vue&type=script&lang=js&"
import style0 from "./addFollowBook.vue?vue&type=style&index=0&id=a3943ed8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3943ed8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3943ed8')) {
      api.createRecord('a3943ed8', component.options)
    } else {
      api.reload('a3943ed8', component.options)
    }
    module.hot.accept("./addFollowBook.vue?vue&type=template&id=a3943ed8&scoped=true&", function () {
      api.rerender('a3943ed8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/resource/follow/addFollowBook.vue"
export default component.exports