var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-view-page" },
    [
      _c(
        "el-select",
        {
          staticClass: "sync-select",
          attrs: {
            multiple: "",
            placeholder: "学段",
            disabled: _vm.isDisabled
          },
          model: {
            value: _vm.temp.sectionCode,
            callback: function($$v) {
              _vm.$set(_vm.temp, "sectionCode", $$v)
            },
            expression: "temp.sectionCode"
          }
        },
        _vm._l(_vm.syncObj.section, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.code }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "sync-select",
          attrs: {
            multiple: "",
            placeholder: "学科",
            disabled: _vm.isDisabled
          },
          model: {
            value: _vm.temp.subjectCode,
            callback: function($$v) {
              _vm.$set(_vm.temp, "subjectCode", $$v)
            },
            expression: "temp.subjectCode"
          }
        },
        _vm._l(_vm.syncObj.subject, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.code }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "sync-select",
          staticStyle: { "margin-right": "0" },
          attrs: {
            multiple: "",
            placeholder: "版本",
            disabled: _vm.isDisabled
          },
          model: {
            value: _vm.temp.editionCode,
            callback: function($$v) {
              _vm.$set(_vm.temp, "editionCode", $$v)
            },
            expression: "temp.editionCode"
          }
        },
        _vm._l(_vm.syncObj.edition, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.code }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "sync-select",
          attrs: {
            multiple: "",
            placeholder: "年级",
            disabled: _vm.isDisabled
          },
          model: {
            value: _vm.temp.gradeCode,
            callback: function($$v) {
              _vm.$set(_vm.temp, "gradeCode", $$v)
            },
            expression: "temp.gradeCode"
          }
        },
        _vm._l(_vm.syncObj.grade, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.code }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "sync-select",
          staticStyle: { "margin-right": "auto", "margin-left": "2%" },
          attrs: {
            multiple: "",
            placeholder: "阶段",
            disabled: _vm.isDisabled
          },
          model: {
            value: _vm.temp.volumeCode,
            callback: function($$v) {
              _vm.$set(_vm.temp, "volumeCode", $$v)
            },
            expression: "temp.volumeCode"
          }
        },
        _vm._l(_vm.syncObj.volume, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.code }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }