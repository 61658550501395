"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStoreTypeName = getStoreTypeName;
exports.getSupplyModeName = getSupplyModeName;
exports.getCommodityLibraryName = getCommodityLibraryName;
exports.commodityLibraryOption = void 0;

function getStoreTypeName(val) {
  var name = val;

  if (name === '商户号') {
    name = '商城号';
  }

  return name;
} // 供货方式


function getSupplyModeName(val) {
  var name = '--';

  if (val === 1) {
    name = 'ERP供货';
  } else if (val === 2) {
    name = '店铺供货';
  } else if (val === 3) {
    name = '中盘供货';
  }

  return name;
} // 翻译中盘商品库


function getCommodityLibraryName(val) {
  var name = '--';

  if (val === 0) {
    name = '总盘';
  } else if (val === 1) {
    name = '自营';
  } else if (val === 2) {
    name = '中盘';
  }

  return name;
}

var commodityLibraryOption = [{
  label: '总盘'
}, {
  label: '中盘'
}, {
  label: '自营'
}];
exports.commodityLibraryOption = commodityLibraryOption;