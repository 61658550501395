"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.token = token;
exports.checkToken = checkToken;
exports.getInfo = getInfo;
exports.logout = logout;

var _qs = _interopRequireDefault(require("qs"));

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/security/"));
/**
 * 创建一个 OAuth 的 request
 * @param {string} url
 * @param {object} data
 * @returns {Promise<*>}
 */

function oAuthRequest(url, data) {
  return request.post(url, _qs.default.stringify(data), {
    auth: {
      username: 'taozhi',
      password: 'MEVl0Iggvx31UNnmPIDi'
    },
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  });
}
/**
 * @param {String} username
 * @param {String} password
 * @returns {Promise<*>}
 */


function login(username, password) {
  return oAuthRequest('/oauth/token', {
    grant_type: 'password',
    username: username,
    password: password
  });
}
/**
 * @param {Object} data
 * @returns {Promise<*>}
 */


function token(data) {
  return oAuthRequest('/oauth/token', data);
}
/**
 * @param {Object} data
 * @returns {Promise<*>}
 */


function checkToken(data) {
  return oAuthRequest('/oauth/check_token', data);
}
/**
 * @returns {Promise<*>}
 */


function getInfo() {
  return request.get('/auth/user/info');
}
/**
 * @returns {Promise<*>}
 */


function logout() {
  return request.post('/user/logout');
}