var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "micro-move-dialog",
      attrs: {
        title: "移动",
        width: "450px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.cancelDialogue,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c("el-tree", {
        attrs: {
          data: _vm.moveList,
          "default-expand-all": true,
          props: _vm.typeProps
        },
        on: { "node-click": _vm.handleClick }
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelDialogue } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }