var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "add-coupon-wrapper"
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.couponInfo,
            rules: _vm.rules,
            "status-icon": "",
            "label-width": "110px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "coupon-base-wrapper" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
              _vm._v(" "),
              _vm.couponId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "优惠券ID" } },
                    [
                      _vm.couponId
                        ? _c("span", [_vm._v(_vm._s(_vm.couponId))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.copyOpenId(_vm.couponId, $event)
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "input-txt",
                    attrs: {
                      "show-word-limit": "",
                      minlength: 0,
                      maxlength: 20,
                      placeholder: "请填写优惠券名称"
                    },
                    model: {
                      value: _vm.couponInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "title", $$v)
                      },
                      expression: "couponInfo.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券类型", prop: "discountType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.operation === "edit" },
                      model: {
                        value: _vm.couponInfo.discountType,
                        callback: function($$v) {
                          _vm.$set(_vm.couponInfo, "discountType", $$v)
                        },
                        expression: "couponInfo.discountType"
                      }
                    },
                    _vm._l(_vm.discountTypeList, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "required-bg",
                  attrs: { label: "使用门槛", prop: "thresholdAmountOptions" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.operation === "edit" },
                      model: {
                        value: _vm.thresholdAmountOption,
                        callback: function($$v) {
                          _vm.thresholdAmountOption = $$v
                        },
                        expression: "thresholdAmountOption"
                      }
                    },
                    [
                      _c("el-radio", { key: "001", attrs: { label: 0 } }, [
                        _vm._v("无门槛使用")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { key: "002", attrs: { label: 1 } },
                        [
                          _vm._v("\n            满"),
                          _c("el-input", {
                            staticClass: "txt-threshold",
                            attrs: { placeholder: "输入金额" },
                            on: {
                              focus: function($event) {
                                _vm.thresholdAmountOption = 1
                              }
                            },
                            model: {
                              value: _vm.couponInfo.thresholdAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.couponInfo, "thresholdAmount", $$v)
                              },
                              expression: "couponInfo.thresholdAmount"
                            }
                          }),
                          _vm._v("元使用\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.couponInfo.thresholdAmountOptions,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "thresholdAmountOptions", $$v)
                      },
                      expression: "couponInfo.thresholdAmountOptions"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "required-bg",
                  attrs: { label: "优惠内容", prop: "preferentialContent" }
                },
                [
                  _vm.couponInfo.discountType === 1
                    ? _c(
                        "div",
                        { staticClass: "threshold-bg" },
                        [
                          _vm._v("\n          减"),
                          _c("el-input", {
                            staticClass: "txt-amount",
                            attrs: {
                              disabled: _vm.operation === "edit",
                              placeholder: "请输入优惠券金额"
                            },
                            model: {
                              value: _vm.couponInfo.usedAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.couponInfo, "usedAmount", $$v)
                              },
                              expression: "couponInfo.usedAmount"
                            }
                          }),
                          _vm._v("元\n        ")
                        ],
                        1
                      )
                    : _c("div", { staticClass: "threshold-money-bg" }, [
                        _c(
                          "span",
                          { staticClass: "threshold-money-l" },
                          [
                            _vm._v("\n            打"),
                            _c("el-input-number", {
                              staticClass: "txt-amount",
                              attrs: {
                                disabled: _vm.operation === "edit",
                                precision: 1,
                                min: 0,
                                step: 0.1,
                                max: 10
                              },
                              model: {
                                value: _vm.showDiscount,
                                callback: function($$v) {
                                  _vm.showDiscount = $$v
                                },
                                expression: "showDiscount"
                              }
                            }),
                            _vm._v("折\n          ")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.isMaxAmount,
                                  callback: function($$v) {
                                    _vm.isMaxAmount = $$v
                                  },
                                  expression: "isMaxAmount"
                                }
                              },
                              [_vm._v("最多优惠")]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "txt-threshold",
                              attrs: {
                                disabled: _vm.operation === "edit",
                                placeholder: "输入金额"
                              },
                              on: {
                                focus: function($event) {
                                  _vm.isMaxAmount = true
                                }
                              },
                              model: {
                                value: _vm.couponInfo.maxAmount,
                                callback: function($$v) {
                                  _vm.$set(_vm.couponInfo, "maxAmount", $$v)
                                },
                                expression: "couponInfo.maxAmount"
                              }
                            }),
                            _vm._v("元\n          ")
                          ],
                          1
                        )
                      ]),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.couponInfo.preferentialContent,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "preferentialContent", $$v)
                      },
                      expression: "couponInfo.preferentialContent"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "required-bg",
                  attrs: { label: "适用商户", prop: "partner" }
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.couponInfo.partner,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "partner", $$v)
                      },
                      expression: "couponInfo.partner"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "isAllPartner-header" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.operation === "edit" },
                          model: {
                            value: _vm.couponInfo.isAllPartner,
                            callback: function($$v) {
                              _vm.$set(_vm.couponInfo, "isAllPartner", $$v)
                            },
                            expression: "couponInfo.isAllPartner"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("全部商户")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("指定商户")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.couponInfo.isAllPartner === 0
                        ? _c("searchPartnerByInput", {
                            ref: "searchPartner",
                            attrs: { isReturnObj: true },
                            on: { receivepartnerId: _vm.receivepartnerId }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.couponInfo.isAllPartner === 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn-add-good",
                              attrs: {
                                type: "primary",
                                plain: "",
                                disabled: _vm.operation === "edit"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.addPartner($event)
                                }
                              }
                            },
                            [_vm._v("添加商户")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.couponInfo.isAllPartner === 0 &&
                  _vm.partnerList.length > 0
                    ? _c(
                        "el-table",
                        {
                          staticClass: "partner-list-bg",
                          attrs: {
                            data: _vm.partnerList,
                            border: "",
                            stripe: "",
                            "max-height": "500px",
                            "header-cell-class-name": "partner-row-header",
                            "header-align": "center"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "商户", "min-width": "180" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "partner-info" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "partner-cover-bg" },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: _vm.getImgUrl(
                                                    scope.row.partnerLogo,
                                                    _vm.thumbnailStyle
                                                  ),
                                                  fit: "cover"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "partner-info-bg" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "partner-info-name"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.partnerName
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "partner-info-id"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.partnerOpenId
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3537036392
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { align: "center", label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-remove-outline icon-del",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.delPartner(
                                              scope.row.partnerOpenId
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1747689725
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.couponInfo.isAllPartner === 1
                    ? _c("div", { staticClass: "goods-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "goods-wrapper-bg" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "适用商品", prop: "goodsList" }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: {
                                      disabled: _vm.operation === "edit"
                                    },
                                    model: {
                                      value: _vm.couponInfo.isAllCommodity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.couponInfo,
                                          "isAllCommodity",
                                          $$v
                                        )
                                      },
                                      expression: "couponInfo.isAllCommodity"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("全部商品")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: 0 } },
                                      [
                                        _vm._v(
                                          "\n                  指定商品\n                  "
                                        ),
                                        _vm.couponInfo.isAllCommodity === 0
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "btn-add-good",
                                                attrs: {
                                                  type: "primary",
                                                  disabled:
                                                    _vm.operation === "edit",
                                                  plain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    _vm.outerVisible = true
                                                  }
                                                }
                                              },
                                              [_vm._v("选择商品")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.couponInfo.isAllCommodity == 0 &&
                        _vm.couponInfo.goodsList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "goods-box" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "goods-list-bg",
                                    attrs: {
                                      data: _vm.couponInfo.goodsList,
                                      border: "",
                                      "max-height": "500px",
                                      "header-cell-class-name":
                                        "goods-list-header",
                                      stripe: ""
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "商品",
                                        "min-width": "180"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "goods-con-bg"
                                                  },
                                                  [
                                                    _c(
                                                      "el-image",
                                                      {
                                                        staticClass:
                                                          "cover-img",
                                                        attrs: {
                                                          src: _vm.getResourceCover(
                                                            scope.row
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "image-slot",
                                                            attrs: {
                                                              slot: "error"
                                                            },
                                                            slot: "error"
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-picture-outline"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "goods-des-bg"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goods-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row.title
                                                                  ? scope.row
                                                                      .title
                                                                  : scope.row
                                                                      .resourceTitle
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goods-sync-txt"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .syncInfoText
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3608893970
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "60",
                                        align: "center",
                                        label: "操作"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline icon-del",
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.delGoods(
                                                        scope.row.resourceOpenId
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3178985159
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.couponInfo.isAllCommodity === 1
                          ? _c(
                              "div",
                              { staticClass: "goods-attribute-bg" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属学科" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled: _vm.operation === "edit",
                                          placeholder: "请选择",
                                          multiple: ""
                                        },
                                        model: {
                                          value: _vm.couponInfo.subjectCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.couponInfo,
                                              "subjectCode",
                                              $$v
                                            )
                                          },
                                          expression: "couponInfo.subjectCode"
                                        }
                                      },
                                      _vm._l(_vm.syncObj.subject, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "select-grade-bg",
                                    attrs: { label: "所属年级" }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: _vm.operation === "edit",
                                          multiple: ""
                                        },
                                        model: {
                                          value: _vm.couponInfo.gradeCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.couponInfo,
                                              "gradeCode",
                                              $$v
                                            )
                                          },
                                          expression: "couponInfo.gradeCode"
                                        }
                                      },
                                      _vm._l(_vm.syncObj.grade, function(item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转链接" } },
                [
                  !_vm.couponInfo.linkSetting
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.openSelect = true
                            }
                          }
                        },
                        [_vm._v("选择连接")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.couponInfo.linkSetting
                    ? _c("div", { staticClass: "link" }, [
                        _c("span", { staticClass: "link-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.couponInfo.linkSetting
                                ? _vm.couponInfo.linkSetting.display.title
                                : undefined
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-sort change-link-icon",
                            on: {
                              click: function($event) {
                                _vm.openSelect = true
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-delete link-icon",
                            on: {
                              click: function($event) {
                                this.couponInfo.linkSetting = undefined
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "适用应用", prop: "appProductOpenIdsArray" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-appProduct",
                      attrs: { placeholder: "请选择", multiple: "" },
                      model: {
                        value: _vm.couponInfo.appProductOpenIdsArray,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.couponInfo,
                            "appProductOpenIdsArray",
                            $$v
                          )
                        },
                        expression: "couponInfo.appProductOpenIdsArray"
                      }
                    },
                    _vm._l(_vm.dictionary, function(item) {
                      return _c("el-option", {
                        key: item.openId,
                        attrs: { label: item.title, value: item.openId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "required-bg",
                  attrs: { label: "有效时间", prop: "validTimes" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "validTime-box",
                      model: {
                        value: _vm.couponInfo.validType,
                        callback: function($$v) {
                          _vm.$set(_vm.couponInfo, "validType", $$v)
                        },
                        expression: "couponInfo.validType"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "default-time": ["00:00:00", "23:59:59"],
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            on: {
                              focus: function($event) {
                                _vm.couponInfo.validType = 1
                              }
                            },
                            model: {
                              value: _vm.validTime,
                              callback: function($$v) {
                                _vm.validTime = $$v
                              },
                              expression: "validTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _vm._v("\n            领取当日起"),
                          _c("el-input", {
                            staticClass: "txt-threshold",
                            attrs: { placeholder: "请输入天数" },
                            on: {
                              focus: function($event) {
                                _vm.couponInfo.validType = 2
                              }
                            },
                            model: {
                              value: _vm.couponInfo.validDays,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.couponInfo,
                                  "validDays",
                                  _vm._n($$v)
                                )
                              },
                              expression: "couponInfo.validDays"
                            }
                          }),
                          _vm._v(" 天内可用\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.couponInfo.validTimes,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "validTimes", $$v)
                      },
                      expression: "couponInfo.validTimes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券分组" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "417px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        "allow-create": "",
                        placeholder: "请输入或选择分组 (单选)"
                      },
                      on: { change: _vm.changeGroup },
                      model: {
                        value: _vm.couponInfo.category,
                        callback: function($$v) {
                          _vm.$set(_vm.couponInfo, "category", $$v)
                        },
                        expression: "couponInfo.category"
                      }
                    },
                    _vm._l(_vm.groupList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("领用规则")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取次数限制", prop: "frequencyLimit" } },
                [
                  _vm._v("\n        每人限领"),
                  _c("el-input", {
                    staticClass: "txt-threshold",
                    attrs: {
                      disabled: _vm.operation === "edit",
                      placeholder: "请输入领取次数限制"
                    },
                    model: {
                      value: _vm.couponInfo.frequencyLimit,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "frequencyLimit", _vm._n($$v))
                      },
                      expression: "couponInfo.frequencyLimit"
                    }
                  }),
                  _vm._v("次\n        "),
                  _c("span", { staticClass: "frequencyLimit-remark-bg" }, [
                    _c("i", { staticClass: "el-icon-info" }),
                    _vm._v("0代表不限制，用户需消耗已领券后，方可领取新券")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "frequencyLimit-remark" }, [
                    _c("i", { staticClass: "el-icon-info" }),
                    _vm._v(
                      "领取次数限制仅对用户在首页、商品详情页、领取优惠券页手动领取优惠券时生效。\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("发放规则")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用VIP", prop: "inVip" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.operation === "edit" },
                      model: {
                        value: _vm.couponInfo.inVip,
                        callback: function($$v) {
                          _vm.$set(_vm.couponInfo, "inVip", $$v)
                        },
                        expression: "couponInfo.inVip"
                      }
                    },
                    _vm._l(_vm.inVipOptions, function(obj) {
                      return _c(
                        "el-radio",
                        { key: obj.value, attrs: { label: obj.value } },
                        [_vm._v(_vm._s(obj.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发放方式", prop: "sendType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "send-type-bg",
                      attrs: { disabled: _vm.operation === "edit" },
                      model: {
                        value: _vm.couponInfo.sendType,
                        callback: function($$v) {
                          _vm.$set(_vm.couponInfo, "sendType", $$v)
                        },
                        expression: "couponInfo.sendType"
                      }
                    },
                    _vm._l(_vm.sendTypeOptions, function(obj) {
                      return _c(
                        "el-radio",
                        { key: obj.value, attrs: { label: obj.value } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(obj.label) +
                              "\n              "
                          ),
                          _c("span", { staticClass: "frequencyLimit-remark" }, [
                            _c("i", { staticClass: "el-icon-info" }),
                            _vm._v(_vm._s(obj.remark) + "\n              ")
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "required-bg",
                  attrs: { label: "发放时间", prop: "sendTimes" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "default-time": ["00:00:00", "23:59:59"],
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.sendTime,
                      callback: function($$v) {
                        _vm.sendTime = $$v
                      },
                      expression: "sendTime"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.couponInfo.sendTimes,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "sendTimes", $$v)
                      },
                      expression: "couponInfo.sendTimes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发行量", prop: "quota" } },
                [
                  _c("el-input", {
                    staticClass: "txt-quota",
                    attrs: {
                      disabled:
                        _vm.operation === "edit" && _vm.couponInfo.quota === 0,
                      placeholder: "请输入发行量"
                    },
                    model: {
                      value: _vm.couponInfo.quota,
                      callback: function($$v) {
                        _vm.$set(_vm.couponInfo, "quota", _vm._n($$v))
                      },
                      expression: "couponInfo.quota"
                    }
                  }),
                  _vm._v("张\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "explain-box" }, [
                _c("div", { staticClass: "explain-title" }, [
                  _vm._v("发放说明")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "explain-con" }, [
                  _vm._v("\n          1. 若0张，则代表无限发行量；"),
                  _c("br"),
                  _vm._v(
                    "\n          2. 若针对全部用户设置自动发放，且发行量不为0，则用户先登录先领取；"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          3. 发行量只可增加，不可减少，请谨慎设置；"
                  ),
                  _c("br")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "coupon-footer-bg" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleSaveCoupon($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.saveLoading ? "保存中..." : "保存"))]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.outerVisible,
              partnerOpenId: _vm.partnerOpenId,
              "expect-type": "commodity",
              "exclude-data": _vm.couponInfo.goodsList,
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.addGoods
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.openSelect },
        on: {
          "update:visible": function($event) {
            _vm.openSelect = $event
          }
        },
        model: {
          value: _vm.couponInfo.linkSetting,
          callback: function($$v) {
            _vm.$set(_vm.couponInfo, "linkSetting", $$v)
          },
          expression: "couponInfo.linkSetting"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }