var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "book-cover-box" }, [
    _c("div", { staticClass: "book-cover-top" }, [
      _c("img", {
        staticClass: "book-cover-img",
        attrs: { src: require("@/assets/book_cover.jpg"), alt: "" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stage-box-bg" },
        [
          _c(
            "span",
            { staticClass: "stage-box" },
            [
              _c("svg-icon", {
                staticClass: "stage-icon stage-icon-publish",
                attrs: { "icon-class": "book-tag" }
              }),
              _vm._v("\n        已发布\n      ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              attrs: { placement: "bottom-end", width: "147", trigger: "click" }
            },
            [
              _c(
                "ul",
                {
                  staticClass: "book-action-list",
                  staticStyle: {
                    "list-style": "none",
                    margin: "0 auto",
                    "padding-inline-start": "0px"
                  }
                },
                [
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "border-bottom": "1px solid #EBEEF5",
                        "line-height": "32px"
                      }
                    },
                    [_vm._v("设置")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "border-bottom": "1px solid #EBEEF5",
                        "line-height": "44px"
                      },
                      on: { click: _vm.bookCopy }
                    },
                    [_vm._v("复制")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticStyle: { "line-height": "32px", color: "#F56C6C" },
                      on: { click: _vm.bookDel }
                    },
                    [_vm._v("删除")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-more",
                attrs: { slot: "reference" },
                slot: "reference"
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "book-cover-option" },
        [
          _c(
            "router-link",
            { attrs: { to: "/books/addMenu" } },
            [
              _c("svg-icon", {
                staticClass: "option",
                attrs: { "icon-class": "book-creat" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "option",
            attrs: { "icon-class": "book-qrcode" }
          }),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "option",
            attrs: { "icon-class": "book-chart" }
          }),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "option",
            attrs: { "icon-class": "book-sliders" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "publish-date" }, [
        _vm._v("\n      2019-01-24 09:01\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "book-cover-shade" })
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "book-cover-bottom" }, [
      _c("h3", { staticClass: "book-cover-title" }, [
        _vm._v("小学语文人教版一年级上册")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "book-cover-tag" }, [
        _c("span", { staticClass: "book-cover-free" }, [_vm._v("免费")]),
        _vm._v(" "),
        _c("span", { staticClass: "book-cover-read" }, [_vm._v("1.8W+阅读量")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }