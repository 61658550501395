"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _menu = _interopRequireDefault(require("@/components/BookMenu/menu.vue"));

var _pageList = _interopRequireDefault(require("@/components/BookMenu/pageList.vue"));

var _upload = _interopRequireDefault(require("@/components/BookMenu/upload.vue"));

var _blankAudio = _interopRequireDefault(require("@/components/BookMenu/blankAudio.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
var _default = {
  components: {
    bookMenu: _menu.default,
    blankAudio: _blankAudio.default,
    pageList: _pageList.default,
    upload: _upload.default
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    uploadFile: function uploadFile() {
      console.log('ha ha ha ha ...');
    }
  }
};
exports.default = _default;