var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-wrap-body" },
    [
      _c("div", { staticClass: "card-wrap-list" }, [
        _c(
          "div",
          { staticClass: "pane-menu" },
          [
            _c("p", { staticClass: "b-title" }, [
              _vm._v("\n        电子书目录\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "b-head" }, [
              _c(
                "p",
                {
                  staticClass: "b-left",
                  on: {
                    click: function($event) {
                      return _vm.addCate(0)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "iconfont icon-tianjiamulu" }),
                  _vm._v("\n          添加目录\n        ")
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "b-right" }, [
                _c("span", {
                  staticClass: "iconfont icon-refresh",
                  on: { click: _vm.getEBookMenuList }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "iconfont noWeight",
                  class: _vm.expandAll
                    ? "icon-category-collapse"
                    : "icon-category-expand",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.isExpand()
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("el-tree", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cataLoading,
                  expression: "cataLoading"
                }
              ],
              ref: "attrList",
              attrs: {
                data: _vm.catalogueList,
                "node-key": "id",
                "default-expand-all": "",
                "expand-on-click-node": false,
                "default-expand-all": _vm.expandAll
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c(
                      "div",
                      { staticClass: "custom-tree-node" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            on: {
                              click: function($event) {
                                return _vm.toPageNum(data.pageNum)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "right-num" }, [
                          _vm._v(_vm._s(data.pageNum))
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          {
                            staticClass: "item",
                            on: { command: _vm.treeOperation }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: "更多",
                                  effect: "dark",
                                  placement: "top"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-gengduohengxiang"
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: { type: "edit", item: data }
                                    }
                                  },
                                  [_vm._v("编辑目录")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: { type: "add", item: data }
                                    }
                                  },
                                  [_vm._v("添加子目录")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: { type: "delete", item: data }
                                    }
                                  },
                                  [_vm._v("移除")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ebook-body" }, [
          _c("div", { staticClass: "preview-title" }, [
            _vm._v("\n        电子样书预览\n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.previewLoading,
                  expression: "previewLoading"
                }
              ],
              staticClass: "preview-body"
            },
            [
              _c("div", { staticClass: "preview-head" }),
              _vm._v(" "),
              _vm.imgPathList && _vm.imgPathList.length > 0
                ? _c("div", { staticClass: "preview-con" }, [
                    _c("i", {
                      staticClass: "icon el-icon-arrow-left",
                      on: { click: _vm.front }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cover-wrap" },
                      [
                        _c("el-image", {
                          staticClass: "cover",
                          attrs: {
                            src: _vm.imgPathList[_vm.activeImgIndex - 1],
                            fit: "cover"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "icon el-icon-arrow-right",
                      on: { click: _vm.after }
                    })
                  ])
                : _c("div", { staticClass: "preview-con" }, [
                    _c("div", { staticClass: "cover-wrap" }, [
                      _vm.openId
                        ? _c("div", { staticClass: "cover-wrap-view" }, [
                            _c("p", [_vm._v("格式转换中，请稍后再试...")]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "icon-wrap",
                                on: { click: _vm.getEBookPreview }
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont icon-refresh"
                                }),
                                _vm._v("刷新")
                              ]
                            )
                          ])
                        : _c("p", [
                            _vm._v("\n              暂无预览\n            ")
                          ])
                    ])
                  ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "preview-foot" },
                [
                  _c("i", {
                    staticClass: "icon el-icon-arrow-left",
                    on: { click: _vm.front }
                  }),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      step: 1,
                      "step-strictly": "",
                      controls: false,
                      min: 1,
                      max:
                        _vm.imgPathList && _vm.imgPathList.length
                          ? _vm.imgPathList.length
                          : 1
                    },
                    model: {
                      value: _vm.activeImgIndex,
                      callback: function($$v) {
                        _vm.activeImgIndex = $$v
                      },
                      expression: "activeImgIndex"
                    }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "icon el-icon-arrow-right",
                    on: { click: _vm.after }
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-btns" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onCancle } },
            [_vm._v("退出")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.cateTitle,
            visible: _vm.showCate,
            width: "30%",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.showCate = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.cateForm, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "父级目录" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.parentInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.parentInfo, "name", $$v)
                      },
                      expression: "parentInfo.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "目录名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.cateForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.cateForm, "name", $$v)
                      },
                      expression: "cateForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "页码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.cateForm.pageNum,
                      callback: function($$v) {
                        _vm.$set(_vm.cateForm, "pageNum", $$v)
                      },
                      expression: "cateForm.pageNum"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showCate = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitCate } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.deleteCata,
            width: "30%",
            "close-on-click-modal": false,
            "before-close": _vm.deleteCancle
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteCata = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定要移除该目录吗？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.deleteCancle } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteMenu } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }