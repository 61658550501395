var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("video-upload", {
    ref: "uploadVideo",
    attrs: {
      videoid: _vm.valueProxy,
      "disabled-edit": _vm.disabledEdit,
      videourl: _vm.videourl,
      types: "video",
      "is-product": true
    },
    on: { getResult: _vm.getVideo, "delect-event": _vm.delectevent }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }