"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.number.constructor");

var _RadioSelectLink = _interopRequireDefault(require("./RadioSelectLink"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'MultipleSelectLink',
  components: {
    RadioSelectLink: _RadioSelectLink.default
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    isNotModel: {
      type: Boolean,
      default: false
    },
    // 不要 v-model这种类型的
    isReplace: {
      type: Boolean,
      default: false
    },
    // 只有多选替换有
    replaceIndex: {
      type: Number,
      default: undefined
    },
    // 只有多选替换有
    showWord: {
      type: Boolean,
      default: false
    },
    propsActiveName: {
      type: String,
      default: undefined
    },
    tool: {
      type: String,
      default: ''
    },
    // 词语单词工具
    isExtend: {
      type: Boolean,
      default: false
    } // 是否是拓展学习

  },
  data: function data() {
    return {
      linkTitle: undefined,
      linkRouter: undefined,
      linkCommonProblem: undefined,
      specialPageLink: undefined,
      linkHref: undefined,
      pageLink: undefined,
      selectLink: [],
      open: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.open = val;
      }
    },
    open: function open(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    // 确定
    handleSubmit: function handleSubmit(data) {
      var list = [];
      var val = data.multipleList;
      var noLink = ['word', 'character', 'vocabulary'];

      if (val.length <= 0 && noLink.includes(data.activeName) && data.activeName !== 'link') {
        this.$notify({
          title: '请选择链接',
          message: '',
          type: 'warning'
        });
        return;
      }

      if (val.length <= 0 && data.activeName !== 'link') {
        this.$notify({
          title: '请选择链接',
          message: '',
          type: 'warning'
        });
        return;
      }

      if (data.activeName === 'link' && val) {
        if (!val[0].linkHref || !val[0].linkTitle) {
          this.$notify({
            title: '请填写完整的外部链接内容',
            message: '',
            type: 'warning'
          });
          return;
        }
      }

      if (noLink.includes(data.activeName)) {
        if (val.list.length <= 0) {
          this.$notify({
            title: '请选择链接',
            message: '',
            type: 'warning'
          });
          return;
        }
      }

      if (data.activeName === 'resource') {
        val.forEach(function (item) {
          var query = {
            type: 'OpenId',
            key: {
              keyType: 'resource',
              resourceType: item.resourceType,
              openId: item.resourceOpenId,
              resourceEncode: item.resourceEncode
            },
            display: {
              title: item.title,
              cover: item.cover
            }
          };
          list.push(query);
        });
      } // 常见问题


      if (data.activeName === 'problem') {
        val.forEach(function (item) {
          var query = {
            type: 'Id',
            key: {
              keyType: 'problem',
              id: item.id
            },
            display: {
              title: item.problemTitle
            }
          };
          list.push(query);
        });
      } // 应用页面


      if (data.activeName === 'page') {
        val.forEach(function (item) {
          var query = {
            type: 'Page',
            key: {
              pageCode: item.pageCode
            },
            display: {
              title: item.pageName
            }
          };
          list.push(query);
        });
      } // 特殊


      if (data.activeName === 'special') {
        val.forEach(function (item) {
          var query = {
            type: 'Special',
            key: {
              name: item
            },
            display: {
              title: item
            }
          };
          list.push(query);
        });
      } // 优惠券


      if (data.activeName === 'coupon') {
        val.forEach(function (item) {
          var query = {
            type: 'OpenId',
            key: {
              keyType: 'Coupon',
              openId: item.couponOpenId
            },
            display: {
              title: item.title
            }
          };
          list.push(query);
        });
      } // 外部链接


      if (data.activeName === 'link') {
        var query = {
          type: 'Link',
          key: {
            url: val[0].linkHref,
            openInBrowser: val[0].openInBrowser
          },
          display: {
            title: val[0].linkTitle
          }
        };
        list.push(query);
      } // 点学书


      if (data.activeName === 'audioBook') {
        val.forEach(function (item) {
          var query = {
            type: 'OpenId',
            key: {
              keyType: 'resource',
              resourceType: 3,
              openId: item.bookOpenId,
              sequence: item.sequence,
              pageId: item.id,
              imgUrl: item.imgUrl,
              resourceEncode: item.resourceEncode
            },
            display: {
              title: item.bookTitle,
              cover: item.cover
            }
          };
          list.push(query);
        });
      } // 微页


      if (data.activeName === 'microPage' || data.activeName === 'partnerMicroPage') {
        val.forEach(function (item) {
          var query = {
            type: 'OpenId',
            key: {
              keyType: 'micropage',
              partnerOpenId: item.partnerOpenId,
              microPageOpenId: item.microPageOpenId
            },
            display: {
              title: item.title
            }
          };
          list.push(query);
        });
      } // 小程序


      if (data.activeName === 'applet') {
        val.forEach(function (item) {
          var query = {
            type: 'Applet',
            // 小程序
            key: {
              id: item.id,
              // 原始id
              appId: item.appId,
              // 小程序id
              appSecret: item.appSecret,
              // appSecret
              name: item.name,
              // 页面名称
              url: item.url // 小程序路径

            },
            display: {
              title: item.title // 小程序名称

            }
          };
          list.push(query);
        });
      } // 试卷


      if (data.activeName === 'paper') {
        val.forEach(function (item) {
          var query = {
            type: 'OpenId',
            key: {
              keyType: 'paper',
              openId: item.examPaperOpenId,
              answerMould: item.answerMould,
              paperType: item.paperType,
              resourceEncode: item.resourceEncode
            },
            display: {
              title: item.name
            }
          };
          list.push(query);
        });
      }

      if (data.activeName === 'word' || data.activeName === 'character' || data.activeName === 'vocabulary') {
        var type;

        if (data.activeName === 'word') {
          type = 'Word';
        }

        if (data.activeName === 'character') {
          type = 'Character';
        }

        if (data.activeName === 'vocabulary') {
          type = 'Vocabulary';
        }

        var titleList = val.list.map(function (item) {
          return item.name;
        });
        var _query = {
          type: type,
          key: {
            toolType: val.toolType,
            list: val.list
          },
          display: {
            title: titleList.join('、')
          }
        };
        list.push(_query);
      }

      this.valueProxy = list;

      if (this.isNotModel) {
        this.$emit('submitLink', list);
      }

      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;