var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("page", { ref: "pageArea", staticClass: "editor-page" }),
      _vm._v(" "),
      _c("actions", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.canEdit,
            expression: "canEdit"
          }
        ],
        ref: "pageAction",
        staticClass: "actions"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }