var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-specification-group" },
    [
      _vm._l(_vm.data, function(v, k) {
        return _c("specification-info", {
          key: k,
          attrs: { index: k, data: v },
          on: {
            "add-value": _vm.addValue,
            "update-value": _vm.updateValue,
            "update-name": _vm.updateName,
            "delete-value": _vm.deleteValue,
            "delete-item": _vm.deleteItem
          }
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "zebra-strips" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("add-item")
                }
              }
            },
            [_vm._v("添加规格项")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }