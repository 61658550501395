var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "chinese-list word-list-box" },
        [
          _c(
            "div",
            { staticClass: "content-card" },
            [
              _c("div", { staticClass: "chinese-header" }, [
                _c(
                  "div",
                  { staticClass: "chinese-button-group" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "create-btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.handleCreate }
                      },
                      [_vm._v("添加")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "chinese-search" },
                  [
                    _c("search-bar", {
                      attrs: {
                        query: _vm.query,
                        type: "ancientPoetry",
                        placeholder: "古诗名称搜索"
                      },
                      on: {
                        getList: _vm.getList,
                        exportWord: _vm.exportWord,
                        importWord: _vm.importWord
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "word-table",
                  staticStyle: { position: "relative" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "chinese-index" },
                    _vm._l(_vm.indexList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: _vm.query.firstLetter == item ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.conditionQuery(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item ? item : "*"))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.list.length > 0
                    ? _c(
                        "div",
                        { staticClass: "content-box" },
                        _vm._l(_vm.list, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              attrs: {
                                enterable: false,
                                placement: "right-start"
                              }
                            },
                            [
                              _c(
                                "el-tooltip",
                                { attrs: { content: item.name } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "word-box",
                                      on: {
                                        click: function($event) {
                                          return _vm.getWordDetail(item.openId)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "word-name" }, [
                                        _vm._v(_vm._s(item.name))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "author-view" },
                                        [
                                          _c("span", [
                                            item.ancientPoetryDynastyName
                                              ? _c(
                                                  "span",
                                                  { staticClass: "author" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.ancientPoetryDynastyName
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  item.ancientPoetryPoetName
                                                ) +
                                                "\n                  "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            class: [
                                              "iconfont",
                                              "icon-shengyin-copy-copy",
                                              "audio-icon",
                                              item.relatedAudioOpenId
                                                ? "active-audio"
                                                : ""
                                            ]
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "not-data" }, [
                        _vm._v("\n            暂无数据\n          ")
                      ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chinese-footer" },
                [
                  _c("el-pagination", {
                    staticClass: "footer-page",
                    attrs: {
                      background: "",
                      "current-page": _vm.query.page,
                      "page-sizes": [50, 100, 150, 200, 250],
                      "page-size": _vm.query.size,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "add-ancient-poetry",
                  attrs: {
                    title: !_vm.wordParam.id ? "添加诗词" : "编辑诗词",
                    visible: _vm.addDialogShow,
                    "close-on-click-modal": false,
                    "before-close": _vm.cancel,
                    width: "1000px"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.addDialogShow = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bannerDialogBox",
                      on: {
                        click: function($event) {
                          _vm.pinyinShowType = false
                        }
                      }
                    },
                    [
                      _c(
                        "el-container",
                        [
                          _c(
                            "el-container",
                            {
                              staticClass: "common-bg",
                              staticStyle: { width: "40%" }
                            },
                            [
                              _c(
                                "el-tabs",
                                {
                                  staticClass: "tabs",
                                  attrs: { type: "card" },
                                  model: {
                                    value: _vm.basic,
                                    callback: function($$v) {
                                      _vm.basic = $$v
                                    },
                                    expression: "basic"
                                  }
                                },
                                [
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "基本信息",
                                        name: "basic"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "form",
                                          staticClass: "add-dialog"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title",
                                              staticStyle: {
                                                "margin-bottom": "3px"
                                              },
                                              attrs: { prop: "name" }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "required" },
                                                [_vm._v("诗词名")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticClass: "add-input",
                                                attrs: {
                                                  placeholder: "请输入诗词名",
                                                  size: "small"
                                                },
                                                model: {
                                                  value: _vm.wordParam.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "wordParam.name"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "not-required" },
                                                [_vm._v("作者")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: { width: "84%" },
                                                  attrs: {
                                                    "value-key": "openId",
                                                    clearable: "",
                                                    filterable: "",
                                                    remote: "",
                                                    "remote-method":
                                                      _vm.ancientPoetryPoetRemoteMethod,
                                                    loading:
                                                      _vm.ancientPoetryPoetLoading,
                                                    size: "medium",
                                                    placeholder: "请选择作者"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.wordParam
                                                        .ancientPoetryPoetOpenId,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.wordParam,
                                                        "ancientPoetryPoetOpenId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "wordParam.ancientPoetryPoetOpenId"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.ancientPoetryAuthorList,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.openId,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.openId
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "not-required" },
                                                [_vm._v("朝代")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: { width: "84%" },
                                                  attrs: {
                                                    "value-key": "code",
                                                    size: "medium",
                                                    placeholder: "请选择朝代"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.wordParam
                                                        .ancientPoetryDynastyCode,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.wordParam,
                                                        "ancientPoetryDynastyCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "wordParam.ancientPoetryDynastyCode"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.ancientPoetryDynastyList,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.code,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.code
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "not-required" },
                                                [_vm._v("题材")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: { width: "84%" },
                                                  attrs: {
                                                    "value-key": "code",
                                                    size: "medium",
                                                    placeholder: "请选择题材"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.wordParam
                                                        .ancientPoetryThemeCode,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.wordParam,
                                                        "ancientPoetryThemeCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "wordParam.ancientPoetryThemeCode"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.ancientPoetryThemeList,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.code,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.code
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "not-required" },
                                                [_vm._v("类型")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: { width: "84%" },
                                                  attrs: {
                                                    "value-key": "code",
                                                    multiple: "",
                                                    size: "medium",
                                                    placeholder: "请选择类型"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.wordParam
                                                        .ancientPoetryTypeCodes,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.wordParam,
                                                        "ancientPoetryTypeCodes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "wordParam.ancientPoetryTypeCodes"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.ancientPoetryTypeList,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.code,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.code
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title not-required"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "5px"
                                                  }
                                                },
                                                [_vm._v("备注")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticClass: "add-input",
                                                attrs: {
                                                  type: "textarea",
                                                  rows: 3,
                                                  placeholder: "请填写备注"
                                                },
                                                model: {
                                                  value: _vm.wordParam.remark,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.wordParam,
                                                      "remark",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "wordParam.remark"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-title ancient-poetry-title not-required"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "5px"
                                                  }
                                                },
                                                [_vm._v("参考链接")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-link",
                                                {
                                                  staticClass: "detail-href",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toLink(
                                                        _vm.wordParam.detailHref
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.wordParam.detailHref
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-container",
                            {
                              staticClass: "right-info",
                              staticStyle: {
                                width: "60%",
                                "margin-left": "10px"
                              }
                            },
                            [
                              _c(
                                "el-tabs",
                                {
                                  staticClass: "common-bg",
                                  staticStyle: { width: "100%" },
                                  attrs: { type: "card" },
                                  model: {
                                    value: _vm.rightInfo,
                                    callback: function($$v) {
                                      _vm.rightInfo = $$v
                                    },
                                    expression: "rightInfo"
                                  }
                                },
                                [
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "诗词信息",
                                        name: "poetry-information"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "form",
                                          staticClass: "add-dialog",
                                          staticStyle: { padding: "5px 0px" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "dialogBox" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "poetry-information-title"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "poetry-information-title-title"
                                                    },
                                                    [_vm._v("诗句、翻译、注释")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-link",
                                                    {
                                                      staticClass:
                                                        "poetry-information-title-batch-add",
                                                      attrs: {
                                                        underline: false,
                                                        type: "primary"
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openBatchVerse
                                                      }
                                                    },
                                                    [_vm._v("+ 批量添加诗句")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.wordParam.poetryDetailList,
                                                function(item, index) {
                                                  return _c(
                                                    "el-card",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "poetry-information-card",
                                                      attrs: { shadow: "never" }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "poetry-information-item"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "poetry-information-content"
                                                            },
                                                            [
                                                              _c("rich_text", {
                                                                attrs: {
                                                                  title: "句",
                                                                  required:
                                                                    "not-required",
                                                                  "state-type": true,
                                                                  param:
                                                                    item.verse,
                                                                  index: index,
                                                                  type: "verse",
                                                                  "tooltip-type":
                                                                    "false",
                                                                  placeholder:
                                                                    "请填写诗句"
                                                                },
                                                                on: {
                                                                  changeRich: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeRich(
                                                                      arguments
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("rich_text", {
                                                                attrs: {
                                                                  title: "译",
                                                                  required:
                                                                    "not-required",
                                                                  "state-type": true,
                                                                  param:
                                                                    item.translate,
                                                                  index: index,
                                                                  type:
                                                                    "translate",
                                                                  "tooltip-type":
                                                                    "false",
                                                                  placeholder:
                                                                    "请填写诗句翻译"
                                                                },
                                                                on: {
                                                                  changeRich: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeRich(
                                                                      arguments
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("rich_text", {
                                                                attrs: {
                                                                  title: "注",
                                                                  required:
                                                                    "not-required",
                                                                  "state-type": true,
                                                                  param:
                                                                    item.annotation,
                                                                  index: index,
                                                                  type:
                                                                    "annotation",
                                                                  "tooltip-type":
                                                                    "false",
                                                                  placeholder:
                                                                    "请填写诗句内的注释"
                                                                },
                                                                on: {
                                                                  changeRich: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeRich(
                                                                      arguments
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "poetry-information-actions"
                                                            },
                                                            [
                                                              _c("el-button", {
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  icon:
                                                                    "el-icon-minus",
                                                                  size: "mini",
                                                                  circle: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removePoetryDetail(
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("el-button", {
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  icon:
                                                                    "el-icon-plus",
                                                                  size: "mini",
                                                                  circle: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addPoetryDetail(
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "诗词赏析",
                                        name: "poetry-appreciation"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "add-dialog" },
                                        [
                                          _c("editor", {
                                            ref: "ueditor",
                                            staticStyle: {
                                              "margin-top": "5px"
                                            },
                                            attrs: {
                                              config: {
                                                zIndex: 3000,
                                                initialFrameWidth: 557,
                                                initialFrameHeight: 270
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.wordParam.appreciateAnalyse,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.wordParam,
                                                  "appreciateAnalyse",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "wordParam.appreciateAnalyse"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "扩展信息",
                                        name: "extended-information"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "add-dialog" },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              staticClass: "add-dialog-border"
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "相关视频" }
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                        plain: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.outerVisible = !_vm.outerVisible
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("添加")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.videoList.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-item-border"
                                                    },
                                                    [
                                                      _c("video-table", {
                                                        attrs: {
                                                          "show-tag": true
                                                        },
                                                        on: {
                                                          handleOpenLabel:
                                                            _vm.handleOpenLabel,
                                                          replaceVideo:
                                                            _vm.replaceVideo,
                                                          openVideo:
                                                            _vm.previewVideo
                                                        },
                                                        model: {
                                                          value: _vm.videoList,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.videoList = $$v
                                                          },
                                                          expression:
                                                            "videoList"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px"
                                                  },
                                                  attrs: { label: "诗词音频" }
                                                },
                                                [
                                                  _c("upload-audio", {
                                                    ref: "uploadAudioControl",
                                                    staticClass:
                                                      "ancient-poetry-update-cover",
                                                    attrs: {
                                                      type: "audio",
                                                      cateId: _vm.cateId,
                                                      "html-type":
                                                        "ancientPoetry",
                                                      "audio-id":
                                                        _vm.wordParam
                                                          .relatedAudioOpenId,
                                                      clearType:
                                                        _vm.wordParam
                                                          .relatedAudioOpenId
                                                    },
                                                    on: {
                                                      getResult: function(
                                                        $event
                                                      ) {
                                                        return _vm.getAudio(
                                                          $event,
                                                          "relatedAudioOpenId"
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px"
                                                  },
                                                  attrs: { label: "场景图" }
                                                },
                                                [
                                                  _c("cover-upload", {
                                                    staticClass:
                                                      "ancient-poetry-update-cover",
                                                    attrs: {
                                                      coverarr: ["84", "84"],
                                                      covertext:
                                                        "建议JPG、PNG格式， 图片小于3M。",
                                                      type: "4"
                                                    },
                                                    on: {
                                                      deletecover: function(
                                                        $event
                                                      ) {
                                                        _vm.wordParam.sceneGraphUrl = null
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.wordParam
                                                          .sceneGraphUrl,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.wordParam,
                                                          "sceneGraphUrl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "wordParam.sceneGraphUrl"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "拓展学习",
                                        name: "extended-study"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "add-dialog" },
                                        [
                                          _c("poetry-extend-study", {
                                            staticClass: "add-dialog-border",
                                            model: {
                                              value: _vm.wordParam,
                                              callback: function($$v) {
                                                _vm.wordParam = $$v
                                              },
                                              expression: "wordParam"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "bottom-button",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "footer" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#303133",
                                "font-size": "14px"
                              }
                            },
                            [_vm._v("排序")]
                          ),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "number-input",
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.wordParam.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.wordParam, "sequence", $$v)
                              },
                              expression: "wordParam.sequence"
                            }
                          }),
                          _vm._v(" "),
                          _vm.wordParam.id
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", plain: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteWord($event)
                                    }
                                  }
                                },
                                [_vm._v("移除")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("取消")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.commitWord($event)
                                }
                              }
                            },
                            [_vm._v("全部保存")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("uploadExcel", {
            attrs: { "upload-type": _vm.uploadType },
            on: {
              handleUploadExcel: _vm.handleUploadExcel,
              closeUploadExcel: _vm.closeUploadExcel
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              expectType: "wordContent",
              visible: _vm.outerVisible,
              "exclude-data": _vm.videoList,
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.onResourceSelected
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("label-manage", {
        attrs: {
          "tag-type": 1006,
          innerVisible: _vm.tagDialogType,
          videoTag: _vm.videoTag
        },
        on: {
          closeLabelManage: function($event) {
            _vm.tagDialogType = false
          },
          handleSelectTag: _vm.handleSelectTag
        }
      }),
      _vm._v(" "),
      _vm.videoPreview
        ? _c(
            "el-dialog",
            {
              staticClass: "video-preview",
              attrs: {
                visible: _vm.videoPreview,
                "before-close": _vm.videoPreviewClose,
                title: "视频预览"
              },
              on: {
                "update:visible": function($event) {
                  _vm.videoPreview = $event
                }
              }
            },
            [
              _c("div", {
                staticClass: "video-start",
                attrs: { id: "videoPreview" }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("batch-add-verse-dialog", {
        attrs: { visible: _vm.openBatchAddVerse },
        on: {
          "update:visible": function($event) {
            _vm.openBatchAddVerse = $event
          },
          submit: _vm.addBatchVerse
        }
      }),
      _vm._v(" "),
      _vm.replaceVisible
        ? _c("select-resource-dialog", {
            attrs: {
              replaceIndex: _vm.videoIndex,
              expectType: "wordContent",
              visible: _vm.replaceVisible,
              "exclude-data": _vm.replaceVideoList,
              dialogType: "replace",
              showMerchant: true
            },
            on: {
              "update:visible": function($event) {
                _vm.replaceVisible = $event
              },
              "add-select": _vm.onReplayVideo
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }