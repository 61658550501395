"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DialogFilterPanel = _interopRequireDefault(require("@/components/Resource/SelectFileDialog/Header/DialogFilterPanel.vue"));

var _searchbtn = _interopRequireDefault(require("@/components/publictop/searchbtn.vue"));

var _Category = _interopRequireDefault(require("@/components/Resource/Selector/Category.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
var _default2 = {
  name: 'DialogFilterTopBar',
  components: {
    SearchButton: _searchbtn.default,
    DialogFilterPanel: _DialogFilterPanel.default,
    Category: _Category.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    partnerOpenId: {
      type: String,
      default: ''
    } // 商户id

  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    'valueProxy.resourceType': function valueProxyResourceType() {
      this.valueProxy.type = '';
      this.$emit('search', this.rememberSelect);
    },
    'valueProxy.type': function valueProxyType() {
      this.$emit('search', this.rememberSelect);
    },
    'valueProxy.partnerOpenId': function valueProxyPartnerOpenId() {
      this.$emit('search', this.rememberSelect);
    }
  },
  data: function data() {
    return {
      rememberSelect: true
    };
  },
  methods: {
    // 添加新的文件
    addNewFile: function addNewFile() {
      // 添加新文件
      this.$emit('addNewSingleFile');
    },
    searchResource: function searchResource() {
      this.valueProxy.page = 1;
      this.$emit('search', this.rememberSelect);
    }
  }
};
exports.default = _default2;