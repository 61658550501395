"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteLuckyDrawInfo = deleteLuckyDrawInfo;
exports.findLuckyDrawInfoPage = findLuckyDrawInfoPage;
exports.findLuckyDrawInfoByOne = findLuckyDrawInfoByOne;
exports.addLuckyDrawInfo = addLuckyDrawInfo;
exports.updateLuckyDrawInfo = updateLuckyDrawInfo;
exports.addLuckyDrawPrize = addLuckyDrawPrize;
exports.updataDrawPrizeApi = updataDrawPrizeApi;
exports.deleteLuckyDrawPrizeApi = deleteLuckyDrawPrizeApi;
exports.findLuckyDrawPrizeList = findLuckyDrawPrizeList;
exports.findPrizeByOne = findPrizeByOne;
exports.findRecordPageApi = findRecordPageApi;
exports.operationRecordApi = operationRecordApi;
exports.getAllActivity = getAllActivity;
exports.getOneActivity = getOneActivity;
exports.getActivityApplication = getActivityApplication;
exports.patchActivityApplication = patchActivityApplication;
exports.batchReject = batchReject;
exports.batchPass = batchPass;
exports.saveNewActivity = saveNewActivity;
exports.saveActivityInfo = saveActivityInfo;
exports.patchOldActivity = patchOldActivity;
exports.addAdmin = addAdmin;
exports.getAdmin = getAdmin;
exports.excel = excel;
exports.getOpenScreenList = getOpenScreenList;
exports.addOpenScreen = addOpenScreen;
exports.removeOpenScreen = removeOpenScreen;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)(process.env.VUE_APP_TEACHER_API);
var teaRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/teacher-certification"));
/* 抽奖 获取活动list */

function deleteLuckyDrawInfo(luckyDrawOpenId) {
  return teaRequest.delete("/luckyDrawInfo/deleteLuckyDrawInfo/".concat(luckyDrawOpenId));
}
/* 抽奖 获取活动list */


function findLuckyDrawInfoPage(params) {
  return teaRequest.get('/luckyDrawInfo/findLuckyDrawInfoPage', {
    params: params
  });
}
/* 抽奖 dnage抽奖活动详情 */


function findLuckyDrawInfoByOne(params) {
  return teaRequest.get('/luckyDrawInfo/findLuckyDrawInfoByOne', {
    params: params
  });
}
/* 抽奖 添加基本信息 */


function addLuckyDrawInfo(data) {
  return teaRequest.post('/luckyDrawInfo/addLuckyDrawInfo', data);
}
/* 抽奖 更新基本信息 */


function updateLuckyDrawInfo(luckyDrawOpenId, data) {
  return teaRequest.put("/luckyDrawInfo/updateLuckyDrawInfo/".concat(luckyDrawOpenId), data);
}
/* 抽奖 添加奖品 */


function addLuckyDrawPrize(data) {
  return teaRequest.post('/luckyDrawPrize/addLuckyDrawPrize', data);
}
/* 抽奖 添加奖品 */


function updataDrawPrizeApi(luckyDrawPrizeOpenId, data) {
  return teaRequest.put("/luckyDrawPrize/updateLuckyDrawPrize/".concat(luckyDrawPrizeOpenId), data);
}
/* 抽奖 删除添加活动中 某一个奖品 */


function deleteLuckyDrawPrizeApi(luckyDrawPrizeOpenId) {
  return teaRequest.delete("/luckyDrawPrize/deleteLuckyDrawPrize/".concat(luckyDrawPrizeOpenId));
}
/* 抽奖 查看奖品列表 */


function findLuckyDrawPrizeList(params) {
  return teaRequest.get('/luckyDrawPrize/findLuckyDrawPrizeList', {
    params: params
  });
}
/* 抽奖 查看单个 */


function findPrizeByOne(params) {
  console.log('抽奖 查看单个', params);
  return teaRequest.get('/luckyDrawPrize/findPrizeByOne', {
    params: params
  });
}
/* 抽奖 活动记录表 */


function findRecordPageApi(params) {
  return teaRequest.get('/luckyDrawRecord/findRecordPage', {
    params: params
  });
}
/* 抽奖 记录表备注领取接口 */


function operationRecordApi(luckyDrawOpenId, params) {
  return teaRequest.put("/luckyDrawRecord/operationRecord/".concat(luckyDrawOpenId), undefined, {
    params: params
  });
}

function getAllActivity(input, size, page, status) {
  return request({
    url: "/activity?sortBy=createdAt&sortOrder=desc&page=".concat(page, "&size=").concat(size, "&keyword=").concat(input, "&checkStatus=").concat(status),
    method: 'get'
  });
}

function getOneActivity(activityId) {
  // return axios.get(request + '/activity/' + activityId)
  return request({
    url: "/activity/".concat(activityId),
    method: 'get'
  });
}

function getActivityApplication(activityId, params) {
  return request.get("/admin/applicationTeacherInfo/".concat(activityId), {
    params: params
  });
}

function patchActivityApplication(activityId, userOpenId, data) {
  return request({
    url: "/activity/".concat(activityId, "/application/").concat(userOpenId),
    method: 'patch',
    data: data
  });
}

function batchReject(activityId, list, value) {
  return request({
    url: "/activity/".concat(activityId, "/application/reject/").concat(list, "?rejectReason=").concat(value),
    method: 'patch'
  });
}

function batchPass(activityId, list) {
  console.log(list);
  return request({
    url: "/activity/".concat(activityId, "/application/pass/").concat(list),
    method: 'patch'
  });
}

function saveNewActivity(data) {
  return request({
    url: '/activity',
    method: 'post',
    data: data
  });
}

function saveActivityInfo(data) {
  return request({
    url: '/activity/batch/admin',
    method: 'post',
    data: data
  });
}

function patchOldActivity(activityId, data) {
  return request({
    url: "/activity/".concat(activityId),
    method: 'patch',
    data: data
  });
}

function addAdmin(activityId, data) {
  return request({
    url: "/frontend/activity/".concat(activityId, "/admin"),
    method: 'post',
    data: data
  });
}

function getAdmin(activityId) {
  return request({
    url: "/frontend/activity/".concat(activityId, "/admin"),
    method: 'get'
  });
}

function excel(activityId, page, size, checkStatus, province, city, county, street) {
  size = size === undefined ? '' : size;
  page = page === undefined ? 1 : page;
  checkStatus = checkStatus === undefined ? '' : checkStatus;
  province = province === undefined ? '' : province;
  city = city === undefined ? '' : city;
  county = county === undefined ? '' : county;
  street = street === undefined ? '' : street;
  return request({
    url: "/frontend/activity/".concat(activityId, "/application") + "/teacherInfo/excel?sortBy=createdAt&sortOrder=desc&page=".concat(page, "&size=").concat(size, "&checkStatus=").concat(checkStatus, "&addressProvince=").concat(province, "&addressCity=").concat(city, "&addressCounty=").concat(county, "&addressStreet").concat(street),
    method: 'get'
  });
}
/**
 * 获取开屏广告列表
 * @param params
 * @returns {*}
 */


function getOpenScreenList(params) {
  return teaRequest.get('admin/openscreen/getOpenScreenList', {
    params: params
  });
}
/**
 * 添加开屏广告
 * @returns {*}
 * @param data
 */


function addOpenScreen(data) {
  return teaRequest.post('admin/openscreen/add', data);
}
/**
 * 删除开屏广告
 * @param params
 * @returns {*}
 */


function removeOpenScreen(params) {
  return teaRequest.delete('admin/openscreen/removeById', {
    params: params
  });
}