var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon-result-wrapper" },
    [
      _c("div", { staticClass: "coupon-result-header" }, [
        _vm._v("近期优惠券明细")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "coupon-result-title" }, [
        _vm._v("优惠券名称：" + _vm._s(_vm.couponTitle) + " "),
        _c("span", { staticClass: "coupon-result-count" }, [
          _vm._v(
            "优惠券总量：" + _vm._s(_vm.quota === 0 ? "不限" : _vm.quota) + "张"
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "coupon-result-chart" },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.statisticsLoading,
                  expression: "statisticsLoading"
                }
              ],
              attrs: { gutter: 20 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "coupon-chart-box",
                      attrs: { shadow: "never" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "coupon-chart-title" },
                        [
                          _vm._v("费效比\n            "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "优惠总金额 / 用券总成交额",
                                placement: "top-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info icon-chart-tip"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "coupon-chart-num" }, [
                        _vm._v(_vm._s(_vm.feePercentage) + "%")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "chart-use-data" }, [
                        _vm._v("优惠总金额：\n            "),
                        _c(
                          "span",
                          { staticClass: "chart-count" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("moneyTransform")(
                                  _vm.actualDiscountAmount
                                )
                              ) + "元\n              "
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "使用该优惠券的订单付款总金额",
                                  placement: "top-start"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info icon-chart-tip"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "chart-use-num" },
                        [
                          _vm._v("用券总成交额：\n            "),
                          _c("span", { staticClass: "chart-count" }, [
                            _vm._v(
                              _vm._s(_vm._f("moneyTransform")(_vm.amount)) +
                                "元"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "使用该优惠券优惠的总金额",
                                placement: "bottom-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info icon-chart-tip"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "coupon-chart-box",
                      attrs: { shadow: "never" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "coupon-chart-title" },
                        [
                          _vm._v("客单价\n            "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "用券总成交额 / 使用该优惠券的付款订单总数",
                                placement: "top-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info icon-chart-tip"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "coupon-chart-num" }, [
                        _vm._v(_vm._s(_vm.unitPrice) + "元")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "chart-use-data" }, [
                        _vm._v("用券总成交额：\n            "),
                        _c(
                          "span",
                          { staticClass: "chart-count" },
                          [
                            _vm._v(
                              _vm._s(_vm._f("moneyTransform")(_vm.amount)) +
                                "元\n              "
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "使用该优惠券的订单付款总金额",
                                  placement: "top-start"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info icon-chart-tip"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "chart-use-num" },
                        [
                          _vm._v("付款订单总数：\n            "),
                          _c("span", { staticClass: "chart-count" }, [
                            _vm._v(_vm._s(_vm.orderCount))
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "使用该优惠券的付款订单总数",
                                placement: "bottom-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info icon-chart-tip"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "coupon-chart-box",
                      attrs: { shadow: "never" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "coupon-chart-title" },
                        [
                          _vm._v("使用率\n            "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "已用总量 / 领取总量",
                                placement: "top-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info icon-chart-tip"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "coupon-chart-num" }, [
                        _vm._v(_vm._s(_vm.usePercentage) + "%")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "chart-use-data" }, [
                        _vm._v("已用总量：\n            "),
                        _c("span", { staticClass: "chart-count" }, [
                          _vm._v(_vm._s(_vm.usedCount))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "chart-use-num" }, [
                        _vm._v("领取总量：\n            "),
                        _c("span", { staticClass: "chart-count" }, [
                          _vm._v(_vm._s(_vm.receiveCount))
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "coupon-chart-box",
                      attrs: { shadow: "never" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "coupon-chart-title" },
                        [
                          _vm._v("领取率\n            "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "领取总量 / 发放总量",
                                placement: "top-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info icon-chart-tip"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "coupon-chart-num" }, [
                        _vm._v(_vm._s(_vm.getPercentage) + "%")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "chart-use-data" }, [
                        _vm._v("领取总量：\n            "),
                        _c("span", { staticClass: "chart-count" }, [
                          _vm._v(_vm._s(_vm.receiveCount))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "chart-use-num" }, [
                        _vm._v("发放总量：\n            "),
                        _c("span", { staticClass: "chart-count" }, [
                          _vm._v(_vm._s(_vm.quota === 0 ? "不限" : _vm.quota))
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chart-data-bg" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.checkData },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "手动发放记录", name: "first" } },
                [
                  _vm.activeName === "first"
                    ? _c(
                        "div",
                        { staticClass: "coupon-table-box1" },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.tableDataLoading,
                                  expression: "tableDataLoading"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { height: "100%", data: _vm.manualList }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "createdAt",
                                  "min-width": "40",
                                  label: "发放时间"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "操作人" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.user
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      scope.row.user.nickName
                                                        ? scope.row.user
                                                            .nickName
                                                        : ""
                                                    ) +
                                                    "\n                "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3555538234
                                )
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "count", label: "发放数量" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { width: "50", label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.lookCoupon(
                                                    scope.row.couponUserOpenId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("查看")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2356640222
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-page-box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          total: _vm.manualTotal,
                          "pager-count": 5,
                          "current-page": _vm.manualQuery.page,
                          "page-size": _vm.manualQuery.size,
                          "page-sizes": _vm.pageSizes,
                          layout: "total, sizes, prev, pager, next, jumper",
                          background: ""
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.currentchange
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "用券商品", name: "second" } },
                [
                  _c("div", { staticClass: "coupon-table-box2" }, [
                    _c(
                      "div",
                      { staticClass: "coupon-table-top2" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.couponCommodityQuery.resoureType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.couponCommodityQuery,
                                  "resoureType",
                                  $$v
                                )
                              },
                              expression: "couponCommodityQuery.resoureType"
                            }
                          },
                          _vm._l(_vm.productList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "coupon-table-con2" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.couponCommodityLoading,
                                expression: "couponCommodityLoading"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { height: "100%", data: _vm.couponCommodity }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { "min-width": "40", label: "商品" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            scope.row.commodity.resourceTitle
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "类型" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("getResourceText")(
                                              scope.row.commodity.resourceType,
                                              _vm.productList
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "券前价格" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("moneyTransform")(
                                              scope.row.couponBeforeAmount
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "优惠金额" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("moneyTransform")(
                                              scope.row.actualDiscountAmount
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "实付金额" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("moneyTransform")(
                                              scope.row.amount
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.activeName === "second"
                    ? _c(
                        "div",
                        { staticClass: "table-page-box" },
                        [
                          _c("el-pagination", {
                            attrs: {
                              total: _vm.couponCommodityTotal,
                              "pager-count": 5,
                              "current-page": _vm.couponCommodityQuery.page,
                              "page-size": _vm.couponCommodityQuery.size,
                              "page-sizes": _vm.pageSizes,
                              layout: "total, sizes, prev, pager, next, jumper",
                              background: ""
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.currentchange
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "领/用券用户", name: "third" } },
                [
                  _c("div", { staticClass: "coupon-table-box2" }, [
                    _c(
                      "div",
                      { staticClass: "coupon-table-top" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "medium",
                              plain: ""
                            }
                          },
                          [_vm._v("导出用户")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.couponUserQuery.status,
                              callback: function($$v) {
                                _vm.$set(_vm.couponUserQuery, "status", $$v)
                              },
                              expression: "couponUserQuery.status"
                            }
                          },
                          _vm._l(_vm.couponUseState, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.activeName === "third"
                      ? _c(
                          "div",
                          { staticClass: "coupon-table-con2" },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.couponUserLoading,
                                    expression: "couponUserLoading"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { height: "100%", data: _vm.couponUser }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "用户昵称"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.user
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        scope.row.user.nickName
                                                          ? scope.row.user
                                                              .nickName
                                                          : ""
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2407955898
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "手机号码" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.user
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        scope.row.user.phone
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2696595676
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "receiveTimeAt",
                                    label: "领券时间"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "是否用券" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.usedTimeAt
                                                    ? "是"
                                                    : "否"
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4139625782
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "usedTimeAt",
                                    label: "用券时间"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-page-box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          total: _vm.couponUserTotal,
                          "pager-count": 5,
                          "current-page": _vm.couponUserQuery.page,
                          "page-size": _vm.couponUserQuery.size,
                          "page-sizes": _vm.pageSizes,
                          layout: "total, sizes, prev, pager, next, jumper",
                          background: ""
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.currentchange
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "coupon-send-bg",
          attrs: {
            title: "优惠券发放",
            visible: _vm.couponDialogVisible,
            width: "570px"
          },
          on: {
            "update:visible": function($event) {
              _vm.couponDialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "coupon-header" }, [_vm._v("指定用户")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "coupon-content-bg" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.userList,
                    "header-align": "center",
                    border: "",
                    stripe: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户", "min-width": "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.user
                              ? _c("div", { staticClass: "user-bg" }, [
                                  _c(
                                    "div",
                                    { staticClass: "user-cover-bg" },
                                    [
                                      _c("el-avatar", {
                                        attrs: {
                                          size: 24,
                                          src: _vm.imageUrl(
                                            scope.row.user.cover,
                                            "style/thumbnail"
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "user-info-bg" }, [
                                    _c("div", { staticClass: "user-phone" }, [
                                      _vm._v(_vm._s(scope.row.user.phone))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "user-openId" }, [
                                      _vm._v(_vm._s(scope.row.user.openId))
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", { attrs: { label: "操作" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-page-box" },
            [
              _c("el-pagination", {
                attrs: {
                  total: _vm.userTotal,
                  "current-page": _vm.userQuery.page,
                  "page-size": _vm.userQuery.size,
                  "page-sizes": _vm.pageSizes,
                  layout: "total, sizes, prev, pager, next, jumper",
                  background: ""
                },
                on: {
                  "size-change": _vm.handleUserSizeChange,
                  "current-change": _vm.currentUserChange
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }