var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "add"
    ? _c(
        "div",
        { staticClass: "add" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.$emit("add")
                }
              }
            },
            [_vm._v("添加规格值")]
          )
        ],
        1
      )
    : _vm.type === "empty"
    ? _c("div", { staticClass: "space" })
    : _c("input-closeable", {
        attrs: { data: _vm.data, "show-close": true },
        on: { change: _vm.change, close: _vm.close }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }