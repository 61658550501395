var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "left-content"
    },
    [
      _c(
        "draggable",
        {
          attrs: { list: _vm.dragData, move: _vm.move, disabled: false },
          on: {
            start: function($event) {
              return _vm.start()
            },
            end: _vm.end
          }
        },
        _vm._l(_vm.dragData, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "extension-box",
              attrs: { id: "extension_" + index },
              on: {
                click: function($event) {
                  return _vm.handleMouseenter(index)
                }
              }
            },
            [
              _c("div", { staticClass: "title-content" }, [
                _c(
                  "div",
                  { staticClass: "drag-box" },
                  [
                    _c("el-image", {
                      attrs: { src: require("../../assets/images/md-drag.svg") }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: item.imgLoading,
                        expression: "item.imgLoading"
                      }
                    ],
                    staticClass: "add-icon",
                    on: {
                      click: function($event) {
                        return _vm.uploadIcon(index)
                      }
                    }
                  },
                  [
                    !item.icon
                      ? _c("i", {
                          staticClass: "el-icon-plus",
                          staticStyle: { width: "28px" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.icon
                      ? _c("img", {
                          staticClass: "directory-icon",
                          attrs: {
                            onerror: _vm.errorUserPhoto,
                            src:
                              item.icon.substring(0, 4) === "http"
                                ? item.icon
                                : _vm.getImageUrl(item.icon)
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "calc(100% - 76px)" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "required-box",
                          staticStyle: { "margin-right": "10px" },
                          model: {
                            value: item.required,
                            callback: function($$v) {
                              _vm.$set(item, "required", $$v)
                            },
                            expression: "item.required"
                          }
                        },
                        [_vm._v("\n              必填\n            ")]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "name",
                        class: !!item.id ? "readonly-input" : "",
                        attrs: {
                          readonly: !!item.id,
                          size: "mini",
                          placeholder: "*必填：字段名称"
                        },
                        model: {
                          value: item.fieldName,
                          callback: function($$v) {
                            _vm.$set(item, "fieldName", $$v)
                          },
                          expression: "item.fieldName"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "group",
                          attrs: {
                            size: "mini",
                            placeholder: "请选择分组",
                            value: ""
                          },
                          model: {
                            value: item.extDataGroupId,
                            callback: function($$v) {
                              _vm.$set(item, "extDataGroupId", $$v)
                            },
                            expression: "item.extDataGroupId"
                          }
                        },
                        _vm._l(_vm.groupList, function(item1, index1) {
                          return _c("el-option", {
                            key: index1,
                            attrs: { label: item1.name, value: item1.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px", display: "flex" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "*必填：显示名称",
                            size: "mini"
                          },
                          model: {
                            value: item.labelName,
                            callback: function($$v) {
                              _vm.$set(item, "labelName", $$v)
                            },
                            expression: "item.labelName"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              class: !!item.id ? "readonly-select" : "",
                              staticStyle: { width: "120px" },
                              attrs: {
                                slot: "prepend",
                                disabled: !!item.id,
                                placeholder: "内容",
                                value: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChange(index)
                                }
                              },
                              slot: "prepend",
                              model: {
                                value: item.inputType,
                                callback: function($$v) {
                                  _vm.$set(item, "inputType", $$v)
                                },
                                expression: "item.inputType"
                              }
                            },
                            _vm._l(_vm.inputTypeList, function(item1, index2) {
                              return _c("el-option", {
                                key: index2,
                                attrs: { label: item1, value: item1 }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.inputType === "单词库" ||
                      item.inputType === "词库" ||
                      item.inputType === "字库"
                        ? _c(
                            "el-select",
                            {
                              staticClass: "tool-type-select",
                              attrs: { slot: "prepend", value: "" },
                              slot: "prepend",
                              model: {
                                value: item.toolType,
                                callback: function($$v) {
                                  _vm.$set(item, "toolType", $$v)
                                },
                                expression: "item.toolType"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "默认", value: null }
                              }),
                              _vm._v(" "),
                              item.inputType === "单词库" ||
                              item.inputType === "词库"
                                ? _c("el-option", {
                                    attrs: { label: "听写", value: "dictation" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.inputType === "单词库"
                                ? _c("el-option", {
                                    attrs: { label: "跟读", value: "followUp" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.inputType === "单词库"
                                ? _c("el-option", {
                                    attrs: {
                                      label: "单词抓螃蟹",
                                      value: "crabGame"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.inputType === "单词库"
                                ? _c("el-option", {
                                    attrs: {
                                      label: "单词消消乐",
                                      value: "eliminatePleasure"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.inputType === "单词库"
                                ? _c("el-option", {
                                    attrs: {
                                      label: "单词速记",
                                      value: "wordShorthand"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "close-box",
                    on: {
                      click: function($event) {
                        return _vm.remove(index)
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-close" })]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "prompt" },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入提示信息",
                      size: "mini",
                      "prefix-icon": "el-icon-info"
                    },
                    model: {
                      value: item.tipMessage,
                      callback: function($$v) {
                        _vm.$set(item, "tipMessage", $$v)
                      },
                      expression: "item.tipMessage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("default-content", {
                attrs: {
                  type: item.inputType,
                  index: index,
                  "default-value": item.defaultValue
                },
                on: {
                  changeValue: _vm.changeValue,
                  changeOpen: _vm.changeOpen,
                  changeDefault: _vm.changeDefault,
                  closeDialog: _vm.closeDialog
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("select-material-dialog", {
        attrs: {
          visible: _vm.openMaterial,
          "upload-path": _vm.uploadPath,
          "upload-function": _vm.uploadFunction
        },
        on: {
          submitMaterial: _vm.submitMaterial,
          "update:visible": function($event) {
            _vm.openMaterial = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }