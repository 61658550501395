"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/utils/index");

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ResourcePrice',
  filters: {
    parseTime: _index.parseTime,
    moneyTransform: function moneyTransform(value) {
      if (!value) return 0.00.toFixed(2);
      return isNaN(value) ? 0.00 : parseFloat((value / 100).toFixed(2));
    }
  },
  props: {
    resourceObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    } // 直播

  },
  data: function data() {
    return {
      getDateDiff: _common.getDateDiff
    };
  }
};
exports.default = _default2;