var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-content",
    { attrs: { label: "销售信息", "full-width": true } },
    [
      _c(
        "div",
        { staticClass: "show-content" },
        [
          _vm.showIsSingle
            ? _c(
                "el-form-item",
                {
                  staticClass: "checkradio margibbottom width-limit",
                  attrs: { label: "教辅单品" }
                },
                [
                  _c("my-radio-group", {
                    attrs: {
                      "disabled-edit": _vm.singleLocked,
                      data: _vm.checkData
                    },
                    model: {
                      value: _vm.valueProxy.isSingle,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "isSingle", $$v)
                      },
                      expression: "valueProxy.isSingle"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSpecificJson
            ? _c(
                "el-form-item",
                {
                  staticClass: "checkradio margibbottom",
                  attrs: { label: "商品规格" }
                },
                [
                  _c("product-specification-editing", {
                    attrs: { group: _vm.valueProxy.specificRequestList },
                    on: {
                      "update:group": function($event) {
                        return _vm.$set(
                          _vm.valueProxy,
                          "specificRequestList",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.valueProxy.specificJson,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "specificJson", $$v)
                      },
                      expression: "valueProxy.specificJson"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showInventory
            ? _c(
                "el-form-item",
                {
                  staticClass: "checkradio margibbottom width-limit",
                  attrs: { label: "库存数量" }
                },
                [
                  _c("price-with-check-box", {
                    attrs: {
                      check: _vm.valueProxy.isUseErp,
                      "disabled-edit": _vm.disabledEdit,
                      "check-label": "使用ERP库存",
                      precision: 0
                    },
                    on: {
                      "update:check": function($event) {
                        return _vm.$set(_vm.valueProxy, "isUseErp", $event)
                      }
                    },
                    model: {
                      value: _vm.valueProxy.inventory,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "inventory", $$v)
                      },
                      expression: "valueProxy.inventory"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "checkradio margibbottom width-limit",
              attrs: { label: "限购数量" }
            },
            [
              _c("price-with-check-box", {
                attrs: {
                  check: _vm.unlimitedPurchases,
                  "disabled-edit": _vm.disabledEdit,
                  "check-label": "不限",
                  "val-on-uncheck": 0,
                  precision: 0
                },
                on: {
                  "update:check": function($event) {
                    _vm.unlimitedPurchases = $event
                  }
                },
                model: {
                  value: _vm.valueProxy.quota,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "quota", $$v)
                  },
                  expression: "valueProxy.quota"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "el-form-goodschli width-limit",
              attrs: { label: "购买权限" }
            },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: _vm.disabledEdit },
                  model: {
                    value: _vm.valueProxy.isTeacherBuy,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "isTeacherBuy", $$v)
                    },
                    expression: "valueProxy.isTeacherBuy"
                  }
                },
                [_vm._v("仅认证教师可购")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showOrginalPrice
            ? _c(
                "el-form-item",
                {
                  staticClass: "el-form-goodschli width-limit",
                  attrs: { label: "原价" }
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      disabled: _vm.disabledEdit,
                      precision: 2,
                      step: 1,
                      min: 0,
                      max: 1000000,
                      "controls-position": "right"
                    },
                    model: {
                      value: _vm.orginalPrice,
                      callback: function($$v) {
                        _vm.orginalPrice = $$v
                      },
                      expression: "orginalPrice"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showLowPrice
            ? _c(
                "el-form-item",
                {
                  staticClass: "el-form-goodschli width-limit",
                  attrs: { label: "全网最低售价" }
                },
                [
                  _c("price-group", {
                    attrs: {
                      "disabled-edit": _vm.disabledEdit,
                      "orginal-price": _vm.valueProxy.orginalPrice,
                      "show-only-discounts": _vm.showOnlyDiscountsLowPrice
                    },
                    model: {
                      value: _vm.valueProxy.lowPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "lowPrice", $$v)
                      },
                      expression: "valueProxy.lowPrice"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showPrice
            ? _c(
                "el-form-item",
                {
                  staticClass: "el-form-goodschli width-limit",
                  attrs: { label: "售价" }
                },
                [
                  _c("price-group", {
                    attrs: {
                      "disabled-edit": _vm.disabledEdit,
                      "orginal-price": _vm.valueProxy.orginalPrice,
                      "show-only-discounts": _vm.showOnlyDiscountsPrice
                    },
                    model: {
                      value: _vm.valueProxy.price,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "price", $$v)
                      },
                      expression: "valueProxy.price"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showGifts
            ? _c(
                "el-form-item",
                {
                  staticClass: "el-form-goodschli width-limit",
                  attrs: { label: "赠品" }
                },
                [
                  _vm.canAddGift
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.outerVisible = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            "选择赠品 ( " + _vm._s(_vm.gifts.length) + " / 6 )"
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogs" },
        [
          _c("product-select-dialog", {
            attrs: { title: "选择赠品", select: _vm.gifts },
            on: {
              "add-select": _vm.addgiftevent,
              "update:select": function($event) {
                _vm.gifts = $event
              }
            },
            model: {
              value: _vm.outerVisible,
              callback: function($$v) {
                _vm.outerVisible = $$v
              },
              expression: "outerVisible"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }