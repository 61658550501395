"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../../../api/common");

var _DefaultSyncInfo = _interopRequireDefault(require("../../../../General/From/Selector/SyncInfo/DefaultSyncInfo.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'VolumeSelector',
  components: {
    SyncInfo: _DefaultSyncInfo.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      keyPrefix: 'VolumeSelector',
      placeholder: '选择阶段',
      dataSource: _common.getSyncVolume
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default;