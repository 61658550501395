var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wraper" }, [
    _c("h3", { staticClass: "ratioTitle" }, [_vm._v("收入比例")]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.statisticsLoading,
            expression: "statisticsLoading"
          }
        ],
        staticClass: "content"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tableContainerRatio" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "default-sort": { prop: "quota", order: "descending" },
                  data: _vm.tableData,
                  "show-summary": true,
                  "summary-method": _vm.getSummaries
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "收入类别", "min-width": "45" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "tableName" }, [
                            scope.row.color
                              ? _c("span", {
                                  staticClass: "titleIcon",
                                  style: { background: scope.row.color }
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.title) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "销售量",
                    sortable: "",
                    prop: "volume",
                    "header-align": "center",
                    align: "center",
                    "min-width": "25"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "tableVolume" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.volume) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "35",
                    prop: "quota",
                    sortable: "",
                    "header-align": "right",
                    align: "right",
                    label: "销售额（元）"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "tableQuota" }, [
                            _vm._v(
                              "\n              ¥" +
                                _vm._s(_vm.numFormat(scope.row.quota / 100)) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "salesVolume" } }, [
      _c("p", { staticClass: "chartP" }, [_vm._v("销售量比例")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "salesQuota" } }, [
      _c("p", { staticClass: "chartP" }, [_vm._v("销售额比例")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }