var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "tz-general-dialog",
        title: _vm.title,
        visible: _vm.valueProxy,
        "close-on-click-modal": false,
        "lock-scroll": "",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        }
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.footerVisible
        ? _c(
            "span",
            {
              staticClass: "tz-dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.valueProxy = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }