var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("radio-select-link", {
        attrs: {
          visible: _vm.open,
          "multiple-check": true,
          "is-old-link": true,
          "is-replace": _vm.isReplace,
          "replace-index": _vm.replaceIndex,
          "show-word": _vm.showWord,
          tool: _vm.tool,
          "props-active-name": _vm.propsActiveName,
          "is-extend": _vm.isExtend
        },
        on: {
          "update:visible": function($event) {
            _vm.open = $event
          },
          submit: _vm.handleSubmit
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }