var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotion-dialog-wrapper" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "promotion-dialog-bg",
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.visibleProxy,
            title: "推广说明"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleProxy = $event
            }
          }
        },
        [
          _c("div", { staticClass: "promotion-description-bg" }, [
            _c("div", { staticClass: "promotion-description-txt" }, [
              _vm._v(
                "\n        课程推广是平台推出的一款可帮助商户拓宽推广渠道的应用营销工具，商户在推广员成功推广课程后，给予其基于课程成交价格的分成金额奖励，以此给商户带来更多传播和促进销量提升。\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "promotion-level-bg" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "level-table-bg",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.settingList,
                      "header-row-class-name": "level-table-header"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "推广员级别" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.promotionName) +
                                  "\n            "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "一级推广比例" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(scope.row.oneCommission) +
                                    "%\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "二级推广比例" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(scope.row.twoCommission) +
                                    "%\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "三级推广比例" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(scope.row.threeCommission) +
                                    "%\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "推广折扣" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(scope.row.promotionDiscount / 10) +
                                    " 折\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-description-txt" }, [
              _vm._v("\n        以订单总额100为例：\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "promotion-level-bg" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "level-table-bg",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.settingList,
                      "header-row-class-name": "level-table-header"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "推广员级别" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.promotionName) +
                                  "\n            "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "一级推广收入" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getLevelPrice(
                                        scope.row.oneCommission,
                                        scope.row.promotionDiscount
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "二级推广收入" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getLevelPrice(
                                        scope.row.twoCommission,
                                        scope.row.promotionDiscount
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "三级推广收入" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getLevelPrice(
                                        scope.row.threeCommission,
                                        scope.row.promotionDiscount
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "最高推广成本" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getMaxCost(
                                        _vm.getLevelPrice(
                                          scope.row.oneCommission,
                                          scope.row.promotionDiscount
                                        ),
                                        _vm.getLevelPrice(
                                          scope.row.twoCommission,
                                          scope.row.promotionDiscount
                                        ),
                                        _vm.getLevelPrice(
                                          scope.row.threeCommission,
                                          scope.row.promotionDiscount
                                        )
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "商户最低收入" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.getMinPrice(100, scope.row)) +
                                    "\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-description-txt" }, [
              _vm._v("\n        此示例是按照55分成计算"),
              _c("br"),
              _vm._v(
                "\n        计算公式：商户最低收入  =（（100*推广员折扣）- 最高推广成本）*  商户分成"
              ),
              _c("br"),
              _vm._v(
                "\n        最高推广成本：三个级别推广收入的和，如果只有一级推广级别，则最高推广成本等于一级推广收入（推广成本按照商户与平台的分成比例共同承担）\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.visibleProxy = false
                    }
                  }
                },
                [_vm._v("我知道了")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }