var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canEdit,
              expression: "canEdit"
            }
          ],
          staticClass: "upload-box"
        },
        [
          _c(
            "div",
            { staticClass: "upload-button" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: {
                    id: "bookPageUploadDropDown",
                    size: "small",
                    "split-button": ""
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.uploadPictures("add")
                    },
                    command: _vm.handleCommand
                  }
                },
                [
                  _vm._v("\n        添加书页\n        "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "图片/PDF" } },
                        [_vm._v("图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "独立评论页" } },
                        [_vm._v("独立评论页")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("input", {
            ref: "myAudioBookUpload",
            staticClass: "my-upload",
            attrs: {
              id: "myAudioBookUpload",
              multiple: _vm.multipleSelect,
              type: "file",
              accept: "image/*"
            },
            on: { change: _vm.Upload }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.showUploadDialog,
            width: "600px",
            title: "上传提示"
          },
          on: {
            "update:visible": function($event) {
              _vm.showUploadDialog = $event
            }
          }
        },
        [
          _c("el-progress", {
            attrs: {
              "text-inside": true,
              "stroke-width": 18,
              percentage: _vm.uploadPercent,
              status: "success"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTip,
                  expression: "showTip"
                }
              ],
              staticStyle: {
                width: "100%",
                height: "30px",
                "margin-top": "5px",
                "line-height": "30px",
                "text-align": "right",
                "padding-right": "5px",
                "font-size": "16px"
              }
            },
            [_vm._v(_vm._s(_vm.tipMsg))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showGeneratePageTip,
                  expression: "showGeneratePageTip"
                }
              ],
              staticStyle: {
                "margin-top": "20px",
                "border-radius": "20px",
                width: "100%"
              },
              attrs: {
                loading: _vm.loading,
                type: "danger",
                size: "medium",
                plain: "",
                disabled: ""
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.tipMsg) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pdfUrl,
                      expression: "pdfUrl"
                    }
                  ],
                  attrs: { type: "warning" },
                  on: { click: _vm.generateBookPage }
                },
                [_vm._v("重新生成")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.isBatchUpload },
                  on: {
                    click: function($event) {
                      return _vm.closeUploadDialog()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.isBatchUpload ? "更新中" : "确定"))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }