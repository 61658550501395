"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProblemClassificationList = getProblemClassificationList;
exports.addProblemClassification = addProblemClassification;
exports.updateProblemClassification = updateProblemClassification;
exports.deleteProblemClassificationById = deleteProblemClassificationById;
exports.updateClickAmountById = updateClickAmountById;
exports.isQuoteProblemClassificationById = isQuoteProblemClassificationById;
exports.getProblemList = getProblemList;
exports.addProblem = addProblem;
exports.getProblemById = getProblemById;
exports.updateProblem = updateProblem;
exports.deleteProblemById = deleteProblemById;
exports.getWorkOrderList = getWorkOrderList;
exports.getWorkClassificationList = getWorkClassificationList;
exports.getWorkorderStatistics = getWorkorderStatistics;
exports.addWorkOrder = addWorkOrder;
exports.addWorkClassification = addWorkClassification;
exports.updateWorkClassification = updateWorkClassification;
exports.getClassificationTreeList = getClassificationTreeList;
exports.getDefaultReplyList = getDefaultReplyList;
exports.updateDefaultReply = updateDefaultReply;
exports.deleteDefaultReplyByID = deleteDefaultReplyByID;
exports.deleteWorkClassification = deleteWorkClassification;
exports.quoteWorkClassification = quoteWorkClassification;
exports.getAssignmentList = getAssignmentList;
exports.addAssignment = addAssignment;
exports.updateAssignment = updateAssignment;
exports.deleteAssignmentById = deleteAssignmentById;
exports.getWorkOperateById = getWorkOperateById;
exports.getWorkDetailsById = getWorkDetailsById;
exports.getAwaitOrderList = getAwaitOrderList;
exports.getServiceDescriptionList = getServiceDescriptionList;
exports.addServiceDescription = addServiceDescription;
exports.updateServiceDescription = updateServiceDescription;
exports.addOptions = addOptions;
exports.addDefaultReply = addDefaultReply;
exports.orderProcessingSave = orderProcessingSave;
exports.getTransferList = getTransferList;
exports.orderTransferSave = orderTransferSave;
exports.getWorkOrdersSomeCount = getWorkOrdersSomeCount;
exports.getWorkOrderDialogList = getWorkOrderDialogList;
exports.addWorkOrderDialogList = addWorkOrderDialogList;
exports.updateWorkIsRead = updateWorkIsRead;
exports.deleteServiceDescription = deleteServiceDescription;
exports.getServiceById = getServiceById;
exports.getStatusByUserOpenId = getStatusByUserOpenId;
exports.isQuoteByCode = isQuoteByCode;
exports.getHelpUser = getHelpUser;
exports.getHelpUserLists = getHelpUserLists;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/help-center/admin/"));
var errorHandlingRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/help-center/admin/"), {}, {
  filled: false,
  errorHandling: false
});
var noTokenRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/help-center/admin/"), {}, {
  filled: false,
  errorHandling: true
});
/**
 * 获取分类列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getProblemClassificationList(params) {
  return request.get('problemClassification/list', {
    params: params
  });
}
/**
 * 新增问题分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addProblemClassification(data) {
  return request.post('problemClassification/add', data);
}
/**
 * 修改问题分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateProblemClassification(data) {
  return request.post('problemClassification/update', data);
}
/**
 * 添加点击量
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateClickAmountById(data) {
  return request.post('problem/updateClickAmountById', data);
}
/**
 * 判断该分类是否被引用
 * @param {Object} params
 * @returns {Promise<*>}
 */


function isQuoteProblemClassificationById(params) {
  return request.post('problemClassification/isQuoteById', params);
}
/**
 * 删除问题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function deleteProblemClassificationById(params) {
  return request.post('problemClassification/deleteById', params);
}
/**
 * 获取常见问题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getProblemList(params) {
  return request.get('problem/list', {
    params: params
  });
}
/**
 * 新增常见问题列表
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addProblem(data) {
  return request.post('problem/add', data);
}
/**
 * 根据常见问题Id查找
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getProblemById(params) {
  return request.post('problem/view', params);
}
/**
 * 修改常见问题
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateProblem(data) {
  return request.post('problem/update', data);
}
/**
 * 删除根据常见问题Id
 * @param {Object} params
 * @returns {Promise<*>}
 */


function deleteProblemById(params) {
  return request.post('problem/deleteById', params);
}
/**
 * 获取工单列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWorkOrderList(params) {
  return errorHandlingRequest.get('workorder/list', {
    params: params
  });
}
/**
 * 获取工单分类列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWorkClassificationList(params) {
  return request.get('workorderClassification/list', {
    params: params
  });
}
/**
 * 获取工单统计数据
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWorkorderStatistics(params) {
  return request.get('workorder/getWorkorderStatistics', {
    params: params
  });
}
/**
 * 添加工单
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addWorkOrder(data) {
  return request.post('workorder/add', data);
}
/**
 * 根据Id获取工单详情
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getWorkDetailsById(data) {
  return noTokenRequest.post('workorder/findById', data);
}
/**
 * 添加工单分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addWorkClassification(data) {
  return request.post('workorderClassification/add', data);
}
/**
 * 添加工单分类树状列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getClassificationTreeList(params) {
  return request.get('workorderClassification/listWithChildNodes', {
    params: params
  });
}
/**
 * 修改工单分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateWorkClassification(data) {
  return request.post('workorderClassification/update', data);
}
/**
 * 删除工单分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function deleteWorkClassification(data) {
  return request.post('workorderClassification/deleteById', data);
}
/**
 * 校验删除工单分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function quoteWorkClassification(data) {
  return request.post('workorderClassification/isQuoteById', data);
}
/**
 * 获取预设回复列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getDefaultReplyList(params) {
  return request.get('defaultReply/list', {
    params: params
  });
}
/**
 * 修改预设回复
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateDefaultReply(data) {
  return request.post('defaultReply/update', data);
}
/**
 * 删除预设回复
 * @param {Object} data
 * @returns {Promise<*>}
 */


function deleteDefaultReplyByID(data) {
  return request.post('defaultReply/deleteById', data);
}
/**
 * 添加预设回复
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addDefaultReply(data) {
  return request.post('defaultReply/add', data);
}
/**
 * 获取工单分配列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAssignmentList(params) {
  return request.get('customerWorkorderClassification/list', {
    params: params
  });
}
/**
 * 添加工单分配
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addAssignment(data) {
  return request.post('customerWorkorderClassification/add', data);
}
/**
 * 修改工单分配
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateAssignment(data) {
  return request.post('customerWorkorderClassification/update', data);
}
/**
 * 删除工单分配
 * @param {Object} data
 * @returns {Promise<*>}
 */


function deleteAssignmentById(data) {
  return request.post('customerWorkorderClassification/deleteById', data);
}
/**
 * 获取工单操作列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWorkOperateById(params) {
  return request.get('customerWorkorderDetail/list', {
    params: params
  });
}
/**
 * 获取待我处理列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAwaitOrderList(params) {
  return errorHandlingRequest.get('customerWorkorderDetail/queryWorkordersWaitingForMeByUserOpenId', {
    params: params
  });
}
/**
 * 工单移交
 * @param {Object} data
 * @returns {Promise<*>}
 */


function orderTransferSave(data) {
  return request.post('workorder/orderTransferSave', data);
}
/**
 * 添加操作回复
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addOptions(data) {
  return noTokenRequest.post('customerWorkorderDetail/add', data);
}
/**
 * 保存 我来处理
 * @param {Object} data
 * @returns {Promise<*>}
 */


function orderProcessingSave(data) {
  return request.post('workorder/orderProcessingSave', data);
}
/**
 * 获取移交人列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getTransferList(params) {
  return request.get('customerWorkorderClassification/transferablePersonnelList', {
    params: params
  });
}
/**
 * 获取服务协议列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getServiceDescriptionList(params) {
  return request.get('serviceAgreement/list', {
    params: params
  });
}
/**
 * 根据id获取服务协议列表
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getServiceById(data) {
  return request.post('serviceAgreement/view', data);
}
/**
 * 添加服务协议
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addServiceDescription(data) {
  return request.post('serviceAgreement/add', data);
}
/**
 * 编辑服务协议
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateServiceDescription(data) {
  return request.post('serviceAgreement/update', data);
}
/**
 * 删除服务协议
 * @param {Object} data
 * @returns {Promise<*>}
 */


function deleteServiceDescription(data) {
  return request.post('serviceAgreement/deleteById', data);
}
/**
 * 获取待我处理 我创建  已处理 总数
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWorkOrdersSomeCount(params) {
  return request.get('customerWorkorderDetail/queryWorkordersSomeCount', {
    params: params
  });
}
/**
 * 获取工单对话列表根据工单 ID
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWorkOrderDialogList(params) {
  return noTokenRequest.get('customerWorkorderDetail/dialogList', {
    params: params
  });
}
/**
 * 工单对话保存
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addWorkOrderDialogList(data) {
  return noTokenRequest.post('customerWorkorderDetail/add', data);
}
/**
 * 工单已读保存
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateWorkIsRead(data) {
  return request.post('customerWorkorderDetail/updateReadFlagByCreateUserOpenIdAndWorkOrderId', data);
}
/**
 * 根据用户ID获取工单状态是否更新
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getStatusByUserOpenId(params) {
  return errorHandlingRequest.get('workorder/getAdminWorkorderUpdatedStatusByUserOpenId', {
    params: params
  });
}
/**
 * 根据code校验是否存在
 * @param {Object} data
 * @returns {Promise<*>}
 */


function isQuoteByCode(data) {
  return request.post('serviceAgreement/isQuoteByCode', data);
}
/**
 * 根据用户ID获取指定的用户信息(无鉴权 有缓存)
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getHelpUser(openId) {
  return errorHandlingRequest.get("customerWorkorderDetail/".concat(openId));
}
/**
 * 根据用户ID获取指定的用户信息(无鉴权 有缓存)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getHelpUserLists(params) {
  return errorHandlingRequest.get('customerWorkorderDetail/getUsers', {
    params: params
  });
}