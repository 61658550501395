var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c(
            "el-header",
            { staticClass: "header" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "elTab",
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.paperActiveName,
                    callback: function($$v) {
                      _vm.paperActiveName = $$v
                    },
                    expression: "paperActiveName"
                  }
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "试卷信息", name: "info" }
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "试卷试题",
                      name: "test",
                      disabled: _vm.isDisabled
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-main",
            { staticClass: "main" },
            [
              _vm.paperActiveName === "info"
                ? _c(
                    "div",
                    { staticClass: "form" },
                    [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("\n          基本信息\n        ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "addForm",
                          staticClass: "addForm",
                          attrs: {
                            disabled: _vm.isPaperDisabled,
                            model: _vm.form,
                            rules: _vm.formRules,
                            "label-width": "100px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "试卷名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "45",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请填写试卷名称 (字数建议20字以内，不超过45字)",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "编号" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写试卷编号",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.resourceEncode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "resourceEncode", $$v)
                                  },
                                  expression: "form.resourceEncode"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "试卷简介" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入内容",
                                  maxlength: "30",
                                  "show-word-limit": "",
                                  autosize: { minRows: 3 },
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.introduction,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "introduction", $$v)
                                  },
                                  expression: "form.introduction"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "试卷类型", prop: "radio" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.radioChange },
                                  model: {
                                    value: _vm.form.radio,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "radio", $$v)
                                    },
                                    expression: "form.radio"
                                  }
                                },
                                _vm._l(_vm.paperType, function(item, index) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: index,
                                      attrs: { label: item.value },
                                      on: {
                                        change: function($event) {
                                          return _vm.isShowCoverOrIntroduce(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "试卷封面" } },
                            [
                              _c("cover-upload", {
                                attrs: {
                                  path: _vm.path,
                                  type: _vm.type.toString(),
                                  coverarr: _vm.coverArr,
                                  covertext: _vm.coverText
                                },
                                on: { deletecover: _vm.deleteCoverFun },
                                model: {
                                  value: _vm.form.cover,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "cover", $$v)
                                  },
                                  expression: "form.cover"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "试卷介绍" } },
                            [
                              _c("editor", {
                                attrs: { readonly: _vm.isPaperDisabled },
                                model: {
                                  value: _vm.form.synopsis,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "synopsis", $$v)
                                  },
                                  expression: "form.synopsis"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "答题模板" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择答题模板" },
                                  model: {
                                    value: _vm.form.answerMould,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "answerMould", $$v)
                                    },
                                    expression: "form.answerMould"
                                  }
                                },
                                _vm._l(_vm.mouldList, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "title" }, [
                            _vm._v("\n            作品属性\n          ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "同步", prop: "subjectCode" } },
                            [
                              _c(
                                "div",
                                { staticClass: "synchronization-flex" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "gradeCodes" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            placeholder: "请选择年级",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.form.gradeCodes,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "gradeCodes",
                                                $$v
                                              )
                                            },
                                            expression: "form.gradeCodes"
                                          }
                                        },
                                        _vm._l(_vm.gradeList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "volumeCode" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            placeholder: "请选择阶段",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.form.volumeCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "volumeCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.volumeCode"
                                          }
                                        },
                                        _vm._l(_vm.volumeList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "subjectCode" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择学科",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.form.subjectCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "subjectCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.subjectCode"
                                          }
                                        },
                                        _vm._l(_vm.subjectList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "editionCode" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择版本",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.form.editionCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "editionCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.editionCode"
                                          }
                                        },
                                        _vm._l(_vm.editionList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.code
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "地区" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "inputLength",
                                  attrs: {
                                    placeholder: "请选择地区",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.form.bookAreaId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "bookAreaId", $$v)
                                    },
                                    expression: "form.bookAreaId"
                                  }
                                },
                                _vm._l(_vm.areaList, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分类", prop: "categoryOpenId" }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "93%" },
                                    attrs: {
                                      "show-all-levels": false,
                                      props: _vm.paperClassProps,
                                      options: _vm.paperClass,
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.categoryOpenId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "categoryOpenId",
                                          $$v
                                        )
                                      },
                                      expression: "form.categoryOpenId"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        color: "rgba(24, 144, 255, 1)",
                                        "font-size": "16px",
                                        cursor: "pointer"
                                      },
                                      on: { click: _vm.showTreeDialog }
                                    },
                                    [_vm._v("编辑")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "版次" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "inputLength",
                                  attrs: {
                                    placeholder: "请选择版次",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.form.periodCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "periodCode", $$v)
                                    },
                                    expression: "form.periodCode"
                                  }
                                },
                                _vm._l(_vm.periodList, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "难易" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "inputLength",
                                  attrs: {
                                    placeholder: "请选择难易",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.form.difficultDegree,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "difficultDegree", $$v)
                                    },
                                    expression: "form.difficultDegree"
                                  }
                                },
                                _vm._l(_vm.difficultDegreeList, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "inputLength",
                                  attrs: {
                                    multiple: "",
                                    filterable: "",
                                    "allow-create": "",
                                    placeholder: "请选择标签"
                                  },
                                  model: {
                                    value: _vm.form.tags,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "tags", $$v)
                                    },
                                    expression: "form.tags"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "title" }, [
                            _vm._v("\n            试卷设置\n          ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "答题时长" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.limitTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "limitTime", $$v)
                                    },
                                    expression: "form.limitTime"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v(
                                      "\n                整卷限制时长\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 6 } }, [
                                    _vm._v(
                                      "\n                不限时长\n              "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.form.limitTime === 3
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    staticClass: "timeInput",
                                    attrs: { placeholder: "例如: 1.5" },
                                    model: {
                                      value: _vm.form.examTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "examTime", $$v)
                                      },
                                      expression: "form.examTime"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("小时")])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.paperActiveName === "test"
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "elContainer", attrs: { span: 24 } },
                        [
                          _c("test-list", {
                            staticStyle: { height: "100%" },
                            attrs: {
                              partnerOpenId: _vm.editPartnerOpenId,
                              list: _vm.form,
                              "test-name": _vm.form.exerciseType,
                              "is-disable": _vm.isPaperDisabled
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.$route.query.openId
            ? _c(
                "el-footer",
                { staticClass: "footer" },
                [
                  _c("el-button", { on: { click: _vm.backRoute } }, [
                    _vm._v("取消")
                  ]),
                  _vm._v(" "),
                  _vm.paperActiveName === "info" && !_vm.isPaperDisabled
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", laoding: _vm.nextLoading },
                          on: { click: _vm.next }
                        },
                        [_vm._v("\n        下一步\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paperActiveName === "test"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            loading: _vm.draftsLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleRelease(0, "add")
                            }
                          }
                        },
                        [_vm._v("\n        保存至草稿箱\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paperActiveName === "test"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.releaseLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleRelease(2, "add")
                            }
                          }
                        },
                        [_vm._v("\n        保存并发布\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "el-footer",
                { staticClass: "footer" },
                [
                  _c("el-button", { on: { click: _vm.backRoute } }, [
                    _vm._v("取消")
                  ]),
                  _vm._v(" "),
                  _vm.isPaperDisabled
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.isPaperDisabled = false
                            }
                          }
                        },
                        [_vm._v("\n        编辑\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPaperDisabled
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            loading: _vm.draftsLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleRelease(0, "edit")
                            }
                          }
                        },
                        [_vm._v("\n        保存至草稿箱\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPaperDisabled
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.releaseLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleRelease(2, "edit")
                            }
                          }
                        },
                        [_vm._v("\n        保存并发布\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEditDialog
        ? _c("edit-dialog", {
            attrs: {
              "preview-dialog": _vm.showEditDialog,
              partnerOpenId: _vm.editPartnerOpenId
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.showEditDialog = $event
              },
              "update:preview-dialog": function($event) {
                _vm.showEditDialog = $event
              },
              dialogChange: _vm.dialogChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }