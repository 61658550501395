var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "extends-table"
        },
        [
          _vm.typeList.indexOf(_vm.type) >= 0
            ? _c("div", [
                _c(
                  "div",
                  [
                    _vm.type === "内容"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "35%" }
                            },
                            [_vm._v("内容")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "10%" }
                            },
                            [_vm._v("类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "15%" }
                            },
                            [_vm._v("售卖方式")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "15%" }
                            },
                            [_vm._v("页面")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "单词库"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "35%" }
                            },
                            [_vm._v("单词")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "23%" }
                            },
                            [_vm._v("默认词性")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "20%" }
                            },
                            [_vm._v("所属库")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "22%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "字库"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "50%" }
                            },
                            [_vm._v("汉字")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("拼音")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "词库"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "50%" }
                            },
                            [_vm._v("词语")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("拼音")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "教材"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "50%" }
                            },
                            [_vm._v("名称")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("版次")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "范文库"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "70%" }
                            },
                            [_vm._v("范文")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "30%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "试题"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "50%" }
                            },
                            [_vm._v("试题")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("题型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "试卷"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "50%" }
                            },
                            [_vm._v("试卷")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "链接"
                      ? _c("div", { staticClass: "default-table" }, [
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "50%" }
                            },
                            [_vm._v("名称")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "table-th",
                              staticStyle: { width: "25%" }
                            },
                            [_vm._v("操作")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        attrs: {
                          list: _vm.defaultValue,
                          move: _vm.move,
                          disabled: _vm.htmlShowType
                        },
                        on: {
                          start: function($event) {
                            return _vm.start()
                          },
                          end: _vm.end
                        }
                      },
                      _vm._l(_vm.defaultValue, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "default-table" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "table-td td-title",
                                style:
                                  _vm.type === "范文库"
                                    ? "width: 70%;"
                                    : _vm.type === "单词库"
                                    ? "width: 35%;"
                                    : _vm.type === "内容"
                                    ? "width: 35%"
                                    : "width: 50%;"
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "right",
                                      effect: "light",
                                      "open-delay": 1000
                                    }
                                  },
                                  [
                                    _vm.type !== "内容" &&
                                    _vm.type !== "范文库" &&
                                    _vm.type !== "链接" &&
                                    _vm.type !== "试题"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "directory-tooltip",
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(item.value)
                                              }
                                            })
                                          ]
                                        )
                                      : _vm.type === "试题"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "directory-tooltip",
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "openId：" + _vm._s(item.openId)
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-copy-document copy-name-but",
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.copyName(
                                                      item.openId,
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ])
                                          ]
                                        )
                                      : _vm.type === "链接"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "directory-tooltip",
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            item.display && item.display.title
                                              ? _c("div", [
                                                  _vm._v(
                                                    "名称：" +
                                                      _vm._s(item.display.title)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.key && item.key.openId
                                              ? _c("div", [
                                                  _vm._v(
                                                    "openId：" +
                                                      _vm._s(item.key.openId)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.key && item.key.id
                                              ? _c("div", [
                                                  _vm._v(
                                                    "id：" + _vm._s(item.key.id)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.key && item.key.url
                                              ? _c("div", [
                                                  _vm._v(
                                                    "链接：" +
                                                      _vm._s(item.key.url)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.linkType
                                              ? _c("div", [
                                                  _vm._v(_vm._s(item.value))
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.type === "OpenId" &&
                                            (item.key
                                              ? item.key.keyType === "resource"
                                              : false)
                                              ? _c("div", [
                                                  _vm._v(
                                                    "版次：" +
                                                      _vm._s(
                                                        _vm.getPeriodId(
                                                          item.key.openId
                                                        )
                                                      )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm.type === "范文库"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "directory-tooltip",
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "directory-tooltip-item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center"
                                                    }
                                                  },
                                                  [
                                                    _vm._v("名称："),
                                                    _c("div", [
                                                      _vm._v(_vm._s(item.value))
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center"
                                                    }
                                                  },
                                                  [
                                                    _vm._v("openId："),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(item.openId)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "directory-tooltip",
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _vm.albumList.length > 0 &&
                                            _vm.getAlbumData(item.openId)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "directory-tooltip-item"
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "openId：" +
                                                          _vm._s(
                                                            _vm.getAlbumData(
                                                              item.openId,
                                                              "openId"
                                                            )
                                                          )
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-copy-document copy-name-but",
                                                        staticStyle: {
                                                          "margin-left": "10px"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.copyName(
                                                              _vm.albumList[
                                                                index
                                                              ].openId,
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm.getAlbumData(
                                                      item.openId,
                                                      "resourceEncode"
                                                    )
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "编号：" +
                                                              _vm._s(
                                                                _vm.getAlbumData(
                                                                  item.openId,
                                                                  "resourceEncode"
                                                                )
                                                              )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "名称：" +
                                                          _vm._s(
                                                            _vm.getAlbumData(
                                                              item.openId,
                                                              "title"
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "分类：" +
                                                          _vm._s(
                                                            _vm.getAlbumData(
                                                              item.openId,
                                                              "categoryInfoText"
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "版次：" +
                                                          _vm._s(
                                                            _vm.getPeriodId(
                                                              item.openId
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                    正在加载中....\n                  "
                                                  )
                                                ])
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _vm.type !== "链接"
                                      ? _c(
                                          "div",
                                          { staticClass: "title-box" },
                                          [
                                            _vm.type === "试题"
                                              ? _c("div", {
                                                  staticClass:
                                                    "title-text max-title",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.value
                                                    )
                                                  }
                                                })
                                              : _c("span", {
                                                  staticClass: "title-text",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.value
                                                    )
                                                  }
                                                }),
                                            _vm._v(" "),
                                            _vm.type === "字库" ||
                                            _vm.type === "词库" ||
                                            _vm.type === "单词库"
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-copy-document copy-name-but",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.copyName(
                                                        item.value,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "title-box" },
                                          [
                                            item.linkType
                                              ? _c(
                                                  "span",
                                                  { staticClass: "title-text" },
                                                  [_vm._v(_vm._s(item.value))]
                                                )
                                              : item.display
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.display.title)
                                                  ),
                                                  item.key.resourceType === 3 &&
                                                  item.key.sequence
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " —— 第" +
                                                            _vm._s(
                                                              item.key.sequence
                                                            ) +
                                                            "页"
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.type === "链接"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "table-td td-title",
                                    staticStyle: { width: "25%" }
                                  },
                                  [
                                    item.type === "Id" &&
                                    item.key.keyType === "problem"
                                      ? _c("span", [_vm._v("常见问题")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "Link" || item.linkType
                                      ? _c("span", [_vm._v("外部链接")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "Special"
                                      ? _c("span", [_vm._v("特殊链接")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "OpenId" &&
                                    item.key.keyType === "paper"
                                      ? _c("span", [_vm._v("试卷")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "OpenId" &&
                                    item.key.keyType === "micropage"
                                      ? _c("span", [_vm._v("商户微页")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.type === "OpenId" &&
                                    item.key.keyType === "resource" &&
                                    item.key.resourceType === 3 &&
                                    (item.key.sequence || item.key.pageId)
                                      ? _c("span", [_vm._v("点学书")])
                                      : item.type === "OpenId" &&
                                        item.key.keyType === "resource"
                                      ? _c("span", [_vm._v("作品链接")])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type !== "范文库" &&
                            _vm.type !== "链接" &&
                            _vm.type !== "单词库"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: item.refreshLoading,
                                        expression: "item.refreshLoading"
                                      }
                                    ],
                                    staticClass: "table-td",
                                    style: [
                                      {
                                        "padding-right":
                                          _vm.type !== "单词库" ? 0 : ""
                                      },
                                      {
                                        width:
                                          _vm.type === "内容"
                                            ? "10%"
                                            : _vm.type === "单词库"
                                            ? "20%"
                                            : "25%"
                                      }
                                    ]
                                  },
                                  [
                                    _vm.type === "试题"
                                      ? _c(
                                          "span",
                                          { staticClass: "one-word" },
                                          [
                                            _vm._v(
                                              _vm._s(item.questionTypeName)
                                            )
                                          ]
                                        )
                                      : _vm.type === "试卷"
                                      ? _c(
                                          "span",
                                          { staticClass: "one-word" },
                                          [_vm._v(_vm._s(item.paperType))]
                                        )
                                      : _vm.type === "字库" &&
                                        item.pinyinList &&
                                        item.pinyinList.length > 1
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "word-pinyin-select",
                                            attrs: { placeholder: "请选择" },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  $event,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.pinyinId,
                                              callback: function($$v) {
                                                _vm.$set(item, "pinyinId", $$v)
                                              },
                                              expression: "item.pinyinId"
                                            }
                                          },
                                          _vm._l(item.pinyinList, function(
                                            item1,
                                            index
                                          ) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item1.pinyin,
                                                value: item1.id
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _vm.type === "词库" &&
                                        item.pinyinList &&
                                        item.pinyinList.length > 1
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "word-pinyin-select",
                                            attrs: { placeholder: "请选择" },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  $event,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.pinyinId,
                                              callback: function($$v) {
                                                _vm.$set(item, "pinyinId", $$v)
                                              },
                                              expression: "item.pinyinId"
                                            }
                                          },
                                          _vm._l(item.pinyinList, function(
                                            item1,
                                            index
                                          ) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item1.pinyin,
                                                value: item1.id
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _vm.type === "单词库" &&
                                        item.wordNatureList &&
                                        item.wordNatureList.length > 1 &&
                                        !_vm.htmlShowType
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "word-pinyin-select",
                                            attrs: { placeholder: "请选择" },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  $event,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.natureCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "natureCode",
                                                  $$v
                                                )
                                              },
                                              expression: "item.natureCode"
                                            }
                                          },
                                          _vm._l(item.wordNatureList, function(
                                            item1,
                                            index
                                          ) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item1.wordNature.name,
                                                value:
                                                  item1.wordNature
                                                    .wordNatureCode
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _vm.type !== "字库" &&
                                        _vm.type !== "词库" &&
                                        _vm.type !== "单词库"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getType(
                                                item.resourceType,
                                                item
                                              )
                                            )
                                          )
                                        ])
                                      : _vm.type !== "单词库"
                                      ? _c(
                                          "span",
                                          { staticClass: "one-word" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getPinyin(item.pinyinList)
                                              )
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "one-word" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getWordNature(
                                                  item.wordNatureList
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _vm.type === "单词库" && !_vm.htmlShowType
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              enterable: false,
                                              effect: "dark",
                                              content: "获取最新词性",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-refresh-right refresh-btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleRefresh(
                                                    item,
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "单词库"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: item.refreshLoading,
                                        expression: "item.refreshLoading"
                                      }
                                    ],
                                    staticClass: "table-td",
                                    staticStyle: { width: "23%" }
                                  },
                                  [
                                    item.wordNatureList &&
                                    item.wordNatureList.length > 1
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "word-pinyin-select",
                                            attrs: {
                                              multiple: "",
                                              placeholder: "请选择"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  $event,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.natureCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "natureCode",
                                                  $$v
                                                )
                                              },
                                              expression: "item.natureCode"
                                            }
                                          },
                                          _vm._l(item.wordNatureList, function(
                                            item1,
                                            index
                                          ) {
                                            return _c("el-option", {
                                              key: "word_" + index,
                                              attrs: {
                                                label: item1.wordNature.name,
                                                value:
                                                  item1.wordNature
                                                    .wordNatureCode
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "one-word" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getWordNature(
                                                  item.wordNatureList
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          enterable: false,
                                          effect: "dark",
                                          content: "获取最新词性",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-refresh-right refresh-btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleRefresh(
                                                item,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "单词库"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "table-td",
                                    staticStyle: { width: "20%" }
                                  },
                                  [
                                    item.wordNatureList
                                      ? _c(
                                          "span",
                                          { staticClass: "one-word" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.wordGroupName
                                                  ? item.wordGroupName
                                                  : "系统单词库"
                                              ) + " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "内容"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "table-td",
                                    staticStyle: {
                                      width: "15%",
                                      "padding-right": "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "sell-select",
                                        attrs: { placeholder: "请选择" },
                                        on: {
                                          change: _vm.handleChangeSellType
                                        },
                                        model: {
                                          value: item.sellType,
                                          callback: function($$v) {
                                            _vm.$set(item, "sellType", $$v)
                                          },
                                          expression: "item.sellType"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "免费", value: 0 }
                                        }),
                                        _vm._v(" "),
                                        item.resourceType === 1 ||
                                        item.resourceType === 2
                                          ? _c("el-option", {
                                              attrs: { label: "付费", value: 1 }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "内容"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "table-td",
                                    staticStyle: {
                                      width: "15%",
                                      "padding-right": "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "sell-select",
                                        attrs: { placeholder: "请选择" },
                                        on: {
                                          change: _vm.handleChangeSellType
                                        },
                                        model: {
                                          value: item.defaultPage,
                                          callback: function($$v) {
                                            _vm.$set(item, "defaultPage", $$v)
                                          },
                                          expression: "item.defaultPage"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "默认",
                                            value: undefined
                                          }
                                        }),
                                        _vm._v(" "),
                                        item.resourceType === 19
                                          ? _c("el-option", {
                                              attrs: { label: "朗读", value: 1 }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.resourceType === 19
                                          ? _c("el-option", {
                                              attrs: { label: "练习", value: 2 }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.resourceType === 19
                                          ? _c("el-option", {
                                              attrs: { label: "实战", value: 3 }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.htmlShowType
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "table-td",
                                    staticStyle: { "padding-right": "0" },
                                    style:
                                      _vm.type === "范文库"
                                        ? "width: 30%;"
                                        : _vm.type === "单词库"
                                        ? "width: 22%;"
                                        : "width: 25%;"
                                  },
                                  [
                                    _vm.type === "链接"
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              enterable: false,
                                              effect: "dark",
                                              content: "预览",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-view replace-button",
                                              on: {
                                                click: function($event) {
                                                  return _vm.preview(
                                                    item,
                                                    _vm.type
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.value && _vm.type === "内容"
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "编辑",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-edit replace-button",
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleEdit(item)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (item.value || _vm.type === "链接") &&
                                    _vm.type !== "范文库"
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "替换",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-sort replace-button sort-icon",
                                              on: {
                                                click: function($event) {
                                                  return _vm.replaceNearChar(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.type === "内容" ||
                                    _vm.type === "字库" ||
                                    _vm.type === "范文库"
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "保留原码替换资源",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-black_antOutline-qrcode replace-button",
                                              on: {
                                                click: function($event) {
                                                  return _vm.replaceNearChar(
                                                    index,
                                                    "retain",
                                                    item
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.value || _vm.type === "链接"
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "移除",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete delete-button",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteDefaultValue(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.htmlShowType
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "table-td",
                                    staticStyle: { "padding-right": "0" },
                                    style:
                                      _vm.type === "范文库"
                                        ? "width: 30%;"
                                        : "width: 25%;"
                                  },
                                  [
                                    item.value &&
                                    (_vm.type === "内容" ||
                                      _vm.type === "范文库" ||
                                      _vm.type === "试卷" ||
                                      _vm.type === "试题")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "replace-button",
                                            on: {
                                              click: function($event) {
                                                return _vm.resourcePreview(item)
                                              }
                                            }
                                          },
                                          [_vm._v("预览")]
                                        )
                                      : _c("div", [_vm._v("--")])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm._l(3, function(item, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.defaultValue.length - 1 < index,
                              expression: "defaultValue.length-1 < index"
                            }
                          ],
                          key: index,
                          staticClass: "default-table"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "table-td",
                              style:
                                _vm.type === "范文库"
                                  ? "width: 70%;"
                                  : _vm.type === "单词库"
                                  ? "width: 35%;"
                                  : _vm.type === "内容"
                                  ? "width: 35%"
                                  : "width: 50%;"
                            },
                            [_c("span")]
                          ),
                          _vm._v(" "),
                          _vm.type !== "范文库"
                            ? _c(
                                "div",
                                {
                                  staticClass: "table-td",
                                  style:
                                    _vm.type === "单词库"
                                      ? "width: 23%;"
                                      : _vm.type === "内容"
                                      ? "width: 10%"
                                      : "width: 25%;"
                                },
                                [_c("span")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type === "单词库"
                            ? _c(
                                "div",
                                {
                                  staticClass: "table-td",
                                  staticStyle: { width: "20%" }
                                },
                                [_c("span")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type === "内容"
                            ? _c(
                                "div",
                                {
                                  staticClass: "table-td",
                                  staticStyle: { width: "15%" }
                                },
                                [_c("span")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type === "内容"
                            ? _c(
                                "div",
                                {
                                  staticClass: "table-td",
                                  staticStyle: { width: "15%" }
                                },
                                [_c("span")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "table-td",
                            style:
                              _vm.type === "范文库"
                                ? "width: 30%;"
                                : _vm.type === "单词库"
                                ? "width: 22%;"
                                : "width: 25%;"
                          })
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.htmlShowType
                  ? _c("div", { staticClass: "extends-table-button" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "添加",
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    size: "mini",
                                    plain: ""
                                  },
                                  on: { click: _vm.openResource }
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "复制",
                                placement: "top"
                              }
                            },
                            [
                              _vm.type === "内容" ||
                              _vm.type === "范文库" ||
                              _vm.type === "试题" ||
                              _vm.type === "试卷"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyDefaultValue($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-document-copy"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "内容导入",
                                placement: "top"
                              }
                            },
                            [
                              _vm.type === "内容" ||
                              _vm.type === "范文库" ||
                              _vm.type === "试题" ||
                              _vm.type === "试卷"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: ""
                                      },
                                      on: { click: _vm.contentImport }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload2"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "复制",
                                placement: "top"
                              }
                            },
                            [
                              _vm.type === "单词库" ||
                              _vm.type === "字库" ||
                              _vm.type === "词库"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyContent($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-document-copy"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "全部移除",
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    plain: ""
                                  },
                                  on: { click: _vm.deleteAll }
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              showMerchant: true,
              visible: _vm.outerVisible,
              "expect-type": "commodity",
              "exclude-data": _vm.checkList,
              dialogType: _vm.showType
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.getSelect
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deskDialogShow
        ? _c("selectFrontDesk", {
            directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
            attrs: {
              "desk-dialog-show": _vm.deskDialogShow,
              "check-list": _vm.checkList,
              "show-type": _vm.showType,
              visible: _vm.deskDialogShow,
              "replace-map": _vm.replaceMap,
              placeholder: "搜索" + _vm.getName(),
              title: "选择" + _vm.getName(),
              type: _vm.wordType
            },
            on: {
              "update:visible": function($event) {
                _vm.deskDialogShow = $event
              },
              getSelectWord: _vm.getSelectWord,
              getReplace: _vm.getReplace,
              "change-show": _vm.parentEvent
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectBookShow
        ? _c("selectBookDialog", {
            directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
            attrs: {
              "dialog-visible": _vm.selectBookShow,
              "check-list": _vm.checkList,
              dialogType: _vm.showType
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.selectBookShow = $event
              },
              "update:dialog-visible": function($event) {
                _vm.selectBookShow = $event
              },
              cancelSelectBook: _vm.closeDialog,
              "item-add": _vm.handleBookAdd,
              "item-replace": _vm.handleBookReplace,
              "add-select": _vm.handleBookSelect
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.compositionDialog
        ? _c("composition-dialog", {
            attrs: {
              dialogVisible: _vm.compositionDialog,
              checkList: _vm.defaultValue,
              "is-replace": _vm.isCompositionReplace
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.compositionDialog = $event
              },
              "update:dialog-visible": function($event) {
                _vm.compositionDialog = $event
              },
              closeDialog: function($event) {
                _vm.compositionDialog = false
              },
              compositionChange: _vm.compositionChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.testQuestionDialog
        ? _c("select-test-questions", {
            attrs: {
              "check-list": _vm.checkList,
              "replace-index": _vm.replaceIndex,
              "dialog-visible": _vm.testQuestionDialog,
              "show-type": _vm.showType
            },
            on: {
              addSuccess: _vm.addSuccess,
              handleClose: function($event) {
                _vm.testQuestionDialog = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.testPaperDialog
        ? _c("select-test-paper", {
            attrs: {
              "check-list": _vm.checkList,
              "replace-index": _vm.replaceIndex,
              "dialog-visible": _vm.testPaperDialog,
              "show-type": _vm.showType
            },
            on: {
              addSuccess: _vm.handleTestPaperSuccess,
              handleClose: function($event) {
                _vm.testPaperDialog = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("select-link", {
        attrs: {
          "is-replace": _vm.linkReplace,
          "replace-index": 0,
          "multiple-check": true,
          "is-not-model": true,
          visible: _vm.selectLinkShow
        },
        on: {
          "update:visible": function($event) {
            _vm.selectLinkShow = $event
          },
          submitLink: _vm.updateLink
        },
        model: {
          value: _vm.link,
          callback: function($$v) {
            _vm.link = $$v
          },
          expression: "link"
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "内容导入",
            visible: _vm.contentImportDialog,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.contentImportDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 5, maxRows: 6 },
                  placeholder: "请粘贴需要导入的内容..."
                },
                model: {
                  value: _vm.importParam,
                  callback: function($$v) {
                    _vm.importParam = $$v
                  },
                  expression: "importParam"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.contentImportDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sumberImport } },
                [_vm._v("导入")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }