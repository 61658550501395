var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tz-change-price-layout" },
    _vm._l(Object.keys(_vm.valueProxy), function(i) {
      return _c("change-price-line", {
        key: i,
        model: {
          value: _vm.valueProxy[i],
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, i, $$v)
          },
          expression: "valueProxy[i]"
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }