var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vocabulary-album" },
    [
      _c(
        "el-button",
        {
          staticClass: "add-picture",
          attrs: { size: "mini" },
          on: { click: _vm.addPicture }
        },
        [_vm._v("添加")]
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: { group: "people" },
          on: {
            start: function($event) {
              _vm.drag = true
            },
            end: function($event) {
              _vm.drag = false
            }
          },
          model: {
            value: _vm.pictureList,
            callback: function($$v) {
              _vm.pictureList = $$v
            },
            expression: "pictureList"
          }
        },
        _vm._l(_vm.pictureList, function(p, i) {
          return _c("vocabulary-picture", {
            key: i,
            on: {
              remove: function($event) {
                return _vm.removePicture(i)
              }
            },
            model: {
              value: _vm.pictureList[i],
              callback: function($$v) {
                _vm.$set(_vm.pictureList, i, $$v)
              },
              expression: "pictureList[i]"
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }