var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-lz-content upload-video" },
    [
      _c("div", { staticClass: "display-flex" }, [
        _c("div", { staticClass: "file-upload-bg" }, [
          _vm.value
            ? _c("div", { staticClass: "img-operation-bg" }, [
                _c("div", { staticClass: "img-operation" }, [
                  _c("i", {
                    staticClass: "el-icon-zoom-in icon-preview",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.onPreview()
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.disabledEdit
                    ? _c("i", {
                        staticClass: "el-icon-delete icon-del",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.disabledEdit ? "" : _vm.deletecover()
                          }
                        }
                      })
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "upload-video-box display-flex",
              style:
                "width:" +
                _vm.coverarr[0] +
                "px;height:" +
                _vm.coverarr[1] +
                "px"
            },
            [
              _vm.value
                ? _c("img", {
                    staticClass: "cover",
                    attrs: {
                      onerror: _vm.errorUserPhoto,
                      src:
                        _vm.value.substring(0, 4) === "http"
                          ? _vm.value
                          : _vm.getImageUrl(_vm.value)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.value
                ? _c("div")
                : _c("div", { staticClass: "upload-video-icon display-flex" }, [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "el-icon-upload-title" }, [
                      _vm._v(_vm._s(_vm.texttips))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "upload-select-box" }, [
                      _c("div", { staticClass: "upload-select-bg" }, [
                        _c(
                          "div",
                          { staticClass: "upload-select-item upload-local-bg" },
                          [
                            !_vm.uploadHideIcon
                              ? _c("i", {
                                  staticClass:
                                    "upload-icon iconfont icon-tupianxian"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "upload-txt" }, [
                              _vm._v("本地")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              ref: "myUpload",
                              staticClass: "file-upload",
                              attrs: {
                                id: "myUpload",
                                multiple: _vm.multipleSelect,
                                disabled: _vm.disabledEdit,
                                type: "file",
                                accept: "image/*"
                              },
                              on: { change: _vm.Upload }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-select-item",
                            on: {
                              click: function($event) {
                                _vm.disabledEdit ? "" : _vm.handlerUploadCover()
                              }
                            }
                          },
                          [
                            !_vm.uploadHideIcon
                              ? _c("i", {
                                  staticClass:
                                    "upload-icon iconfont icon-sucaiku"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "upload-txt" }, [
                              _vm._v("素材库")
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm.coverisshow && _vm.covertext
          ? _c("div", [
              _c("div", { staticClass: "display-flex video-duration-text" }, [
                _c("i", { staticClass: "el-icon-info" }),
                _vm._v(" "),
                _c("span", { staticClass: "video-text" }, [
                  _vm._v(_vm._s(_vm.covertext))
                ])
              ]),
              _vm._v(" "),
              _vm.value && _vm.isShowUpdateCover
                ? _c(
                    "span",
                    {
                      staticClass: "update-cover-txt",
                      on: { click: _vm.handlerGetNewCover }
                    },
                    [_vm._v("更新封面")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.showPreview && _vm.value
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrlOriginal(_vm.value)],
              "z-index": _vm.previewZIndex
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("select-material-dialog", {
        attrs: {
          "partner-open-id": _vm.partnerOpenId,
          visible: _vm.openMaterial,
          "upload-path": _vm.uploadPath,
          "upload-function": _vm.uploadFunction
        },
        on: {
          "update:visible": function($event) {
            _vm.openMaterial = $event
          },
          submitMaterial: _vm.getImgUrl
        },
        model: {
          value: _vm.materialValue,
          callback: function($$v) {
            _vm.materialValue = $$v
          },
          expression: "materialValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }