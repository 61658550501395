import { render, staticRenderFns } from "./ResourceOperation.vue?vue&type=template&id=7fad48cc&scoped=true&"
import script from "./ResourceOperation.vue?vue&type=script&lang=js&"
export * from "./ResourceOperation.vue?vue&type=script&lang=js&"
import style0 from "./ResourceOperation.vue?vue&type=style&index=0&id=7fad48cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fad48cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7fad48cc')) {
      api.createRecord('7fad48cc', component.options)
    } else {
      api.reload('7fad48cc', component.options)
    }
    module.hot.accept("./ResourceOperation.vue?vue&type=template&id=7fad48cc&scoped=true&", function () {
      api.rerender('7fad48cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Resource/ResourceItem/ResourceOperation.vue"
export default component.exports