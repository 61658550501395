var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-info-box" },
    [
      _c("resource-title", {
        attrs: {
          resourceObj: _vm.resourceObj,
          showPage: _vm.showPage,
          isEdit: _vm.isEdit
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "resource-info-bottom" },
        [
          _c("resource-encode", {
            attrs: { resourceObj: _vm.resourceObj, isEdit: _vm.isEdit }
          }),
          _vm._v(" "),
          _vm.isEdit
            ? _c("resource-author", { attrs: { resourceObj: _vm.resourceObj } })
            : _vm._e(),
          _vm._v(" "),
          _vm.resourceObj.resourceType !== _vm.LIVE_ID && _vm.isEdit
            ? _c("resource-price", { attrs: { resourceObj: _vm.resourceObj } })
            : _vm.isEdit
            ? _c("resource-live-state", {
                attrs: {
                  resourceObj: _vm.resourceObj,
                  handlerFinishClass: _vm.handlerFinishClassFun
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }