var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-line sku-cover" },
    [
      _c("cover-upload", {
        ref: "coverUpload",
        attrs: {
          "disabled-edit": _vm.disableCell,
          type: "6",
          coverarr: [36, 36],
          coverisshow: false,
          covertext: "",
          "upload-fn": _vm.uploadFn,
          path: _vm.uploadPath
        },
        model: {
          value: _vm.valueProxy,
          callback: function($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy"
        }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "text", disabled: _vm.disableCell },
          on: { click: _vm.onReplace }
        },
        [_vm._v(_vm._s(_vm.replacButton))]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "text", disabled: _vm.disableCell },
          on: {
            click: function($event) {
              _vm.valueProxy = undefined
            }
          }
        },
        [_vm._v("移除")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }