"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _const = require("../../utils/const");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'index',
  props: {
    openId: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: 0
    },
    typeName: {
      type: String,
      default: '点读书'
    },
    operation: {
      type: String,
      default: 'update'
    },
    passBtn: {
      type: Boolean,
      default: false
    },
    refuseBtn: {
      type: Boolean,
      default: false
    },
    nextBtn: {
      type: Boolean,
      default: false
    },
    restoreBtn: {
      type: Boolean,
      default: false
    },
    publishBtn: {
      type: Boolean,
      default: false
    },
    saveBtn: {
      type: Boolean,
      default: false
    },
    saveDraftBtn: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVideo: function isVideo() {
      return this.typeName !== '专辑' && this.typeName !== '大专辑';
    },
    isUnderReview: function isUnderReview() {
      return this.status === _const.RESOURCE_REVIEW_STATE;
    },
    isRelease: function isRelease() {
      return this.status !== _const.RESOURCE_DELETED_STATE;
    },
    updateIsRelease: function updateIsRelease() {
      return this.status === _const.RESOURCE_DRAFT_STATE || this.status === _const.RESOURCE_REFUSED_STATE;
    },
    isEdit: function isEdit() {
      return this.status !== _const.RESOURCE_DELETED_STATE;
    },
    isResume: function isResume() {
      return this.status === _const.RESOURCE_DELETED_STATE;
    }
  },
  methods: {
    // 返回
    closeView: function closeView() {
      this.$emit('closeView');
    },
    // 编辑
    updateEdit: function updateEdit() {
      this.$emit('updateEdit');
    },
    // 通过
    passItem: function passItem() {
      this.$emit('passItem');
    },
    // 驳回
    handelRefuse: function handelRefuse() {
      this.$emit('handelRefuse');
    },
    // 发布
    handelSaveAndReview: function handelSaveAndReview() {
      this.$emit('handelSaveAndReview');
    },
    // 恢复
    handleBookRestore: function handleBookRestore() {
      this.$emit('handleBookRestore');
    },
    // 保存
    saveData: function saveData() {
      this.$emit('saveData');
    },
    // 保存至草稿箱
    saveDraft: function saveDraft() {
      this.$emit('saveDraft');
    },
    // 新建
    nextStep: function nextStep() {
      this.$emit('nextStep');
    }
  }
};
exports.default = _default;