var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-resource-dialog-layout" },
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "select-resource-dialog",
          attrs: {
            "append-to-body": "",
            visible: _vm.visibleProxy,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "选择作品"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleProxy = $event
            },
            opened: _vm.initSearchParams
          }
        },
        [
          _c(
            "div",
            { staticClass: "select-resource-dialog-body-layout" },
            [
              _c(
                "div",
                { staticClass: "select-resource-body-layout" },
                [
                  _c("dialog-filter-top-bar", {
                    attrs: {
                      showMerchant: _vm.showMerchant,
                      "expect-type": _vm.expectType
                    },
                    on: { search: _vm.handleSearch },
                    model: {
                      value: _vm.searchParams,
                      callback: function($$v) {
                        _vm.searchParams = $$v
                      },
                      expression: "searchParams"
                    }
                  }),
                  _vm._v(" "),
                  _c("dialog-table-content", {
                    ref: "resourceTable",
                    attrs: {
                      "resource-list": _vm.resources.data,
                      loading: _vm.loading,
                      "exclude-data": _vm.excludeData,
                      dialogType: _vm.dialogType
                    },
                    on: { "item-add": _vm.onAddItemClicked }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("dialog-footbar", {
                attrs: { total: _vm.resources.totalElements },
                on: {
                  cancel: _vm.cancelDialog,
                  submit: _vm.onAddButtonClicked
                },
                model: {
                  value: _vm.searchParams,
                  callback: function($$v) {
                    _vm.searchParams = $$v
                  },
                  expression: "searchParams"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }