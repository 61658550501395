var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-title", attrs: { prop: "name" } },
    [
      _c("label", { staticClass: "required" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "drag-box",
          staticStyle: { width: "88%", display: "flex", position: "relative" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.boxShowType = !_vm.boxShowType
            }
          }
        },
        [
          _c(
            "draggable",
            {
              staticClass: "menu-list rich",
              attrs: { list: _vm.checkList, move: _vm.move, disabled: false },
              on: {
                start: function($event) {
                  return _vm.start()
                },
                end: _vm.end
              }
            },
            _vm._l(_vm.checkList, function(item, index) {
              return _c("div", { key: index, staticClass: "select-word" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "4px 10px", color: "#909399" } },
                  [_c("span", [_vm._v(_vm._s(item.label))])]
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-error",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.remove(index)
                    }
                  }
                })
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.checkList.length <= 0
            ? _c("span", { staticClass: "placeholder" }, [
                _vm._v(_vm._s(_vm.placeholder))
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item-tooltip",
          attrs: { effect: "dark", content: "", placement: "top" }
        },
        [_c("i", { staticClass: "icon el-icon-info" })]
      ),
      _vm._v(" "),
      _vm.boxShowType
        ? _c("div", [
            _c("div", { staticClass: "box-letter" }, [
              _c(
                "ul",
                { staticClass: "letter-ul" },
                _vm._l(_vm.paramList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "letter-li",
                      style: item.check ? "color: #1890ff;" : "",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleClick(index, item)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "cascader-node__label" }, [
                        _vm._v(_vm._s(item.label))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass:
                          "el-icon-arrow-right el-cascader-node__postfix"
                      })
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "arrow" })
            ]),
            _vm._v(" "),
            _vm.twoChildren.length > 0
              ? _c(
                  "div",
                  { staticClass: "box-letter", staticStyle: { left: "162px" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "letter-ul" },
                      _vm._l(_vm.twoChildren, function(item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "letter-li",
                            style: item.check ? "color: #1890ff;" : "",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClickTwo(index, item)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "cascader-node__label" },
                              [_vm._v(_vm._s(item.label))]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass:
                                "el-icon-arrow-right el-cascader-node__postfix"
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.threeChildren.length > 0
              ? _c(
                  "div",
                  { staticClass: "box-letter", staticStyle: { left: "333px" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "letter-ul" },
                      _vm._l(_vm.threeChildren, function(item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "letter-li",
                            style: item.check ? "color: #1890ff;" : "",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClickThree(item)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "cascader-node__label" },
                              [_vm._v(_vm._s(item.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }