"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _GeneralDialog = _interopRequireDefault(require("../../General/Dialog/GeneralDialog.vue"));

var _PriceLayout = _interopRequireDefault(require("../../General/ChangePrice/PriceLayout.vue"));

var _batchSettingPrices = require("@/utils/views/product/batchSettingPrices");

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MidDiscShopBatchSetSellingPrice',
  components: {
    GeneralDialog: _GeneralDialog.default,
    ChangePriceLayout: _PriceLayout.default
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      data: {
        price: (0, _batchSettingPrices.createPriceConfig)({
          title: '售价'
        })
      }
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(val) {
      if (val !== false) {
        return;
      }

      this.data = {
        price: (0, _batchSettingPrices.createPriceConfig)({
          title: '售价'
        })
      };
    }
  },
  methods: {
    confirm: function confirm() {
      this.$emit('confirm', (0, _batchSettingPrices.parsePriceData)(this.data));
      this.valueProxy = false;
    }
  }
};
exports.default = _default;