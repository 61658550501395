"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _verificationHost = _interopRequireDefault(require("./guard/verificationHost"));

var _backupPrompt = _interopRequireDefault(require("./guard/backupPrompt"));

function guards(router) {
  router.beforeEach(_verificationHost.default);
  router.beforeEach(_backupPrompt.default);
}

var _default = guards;
exports.default = _default;