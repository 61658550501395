var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "replenishment" },
    [
      _c(
        "el-card",
        { staticClass: "balance box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("iOS 金星钻补单")])]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "guide" }, [
            _vm._v(
              '金星钻补单请在 "财务" -> "余额充值" 页面，通过用户 open id 查询到该用户未支付成功的订单，复制该订单的订单 open id，在该页面使用该 open id 进行补单。'
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "guide" }, [
            _vm._v("若有多个相同额度的充值订单，选择任意一个均可。")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.balanceFormLoading,
                  expression: "balanceFormLoading"
                }
              ],
              ref: "balanceForm",
              attrs: {
                model: _vm.balanceForm,
                rules: _vm.balanceRules,
                "label-width": "160px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "金星钻订单号", prop: "orderOpenId" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.balanceForm.orderOpenId,
                      callback: function($$v) {
                        _vm.$set(_vm.balanceForm, "orderOpenId", $$v)
                      },
                      expression: "balanceForm.orderOpenId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.balanceFormDisabled
                      },
                      on: { click: _vm.onBalanceClicked }
                    },
                    [_vm._v("补单")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "vip box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("iOS VIP 补单")])]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "guide" }, [
            _vm._v(
              'iOS 平台充值 VIP 掉单，请在 "财务" -> "订单查询" 页面，通过用户 open id 查询到该用户未支付成功的 vip 订单，复制该订单的订单 open id，在该页面使用该 open id 进行补单。'
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "guide" }, [
            _vm._v("若有多个相同额度的 vip 订单，选择任意一个均可。")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "guide" }, [
            _vm._v("VIP 价格填写以分为单位的价格。")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "warning" }, [
            _vm._v("此功能请谨慎使用！！！")
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.vipFormLoading,
                  expression: "vipFormLoading"
                }
              ],
              ref: "vipForm",
              attrs: {
                model: _vm.vipForm,
                rules: _vm.vipRules,
                "label-width": "160px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "VIP 订单号", prop: "orderOpenId" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.vipForm.orderOpenId,
                      callback: function($$v) {
                        _vm.$set(_vm.vipForm, "orderOpenId", $$v)
                      },
                      expression: "vipForm.orderOpenId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "VIP 价格", prop: "payPrice" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.vipForm.payPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.vipForm, "payPrice", $$v)
                      },
                      expression: "vipForm.payPrice"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.vipFormDisabled },
                      on: { click: _vm.onVipClicked }
                    },
                    [_vm._v("补单")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }