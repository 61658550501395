import { render, staticRenderFns } from "./BuyRecord.vue?vue&type=template&id=953b172a&scoped=true&"
import script from "./BuyRecord.vue?vue&type=script&lang=js&"
export * from "./BuyRecord.vue?vue&type=script&lang=js&"
import style0 from "./BuyRecord.vue?vue&type=style&index=0&id=953b172a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "953b172a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('953b172a')) {
      api.createRecord('953b172a', component.options)
    } else {
      api.reload('953b172a', component.options)
    }
    module.hot.accept("./BuyRecord.vue?vue&type=template&id=953b172a&scoped=true&", function () {
      api.rerender('953b172a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Promotion/BuyRecord.vue"
export default component.exports