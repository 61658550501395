var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fileWrap upload-video" },
    [
      _c("div", { staticClass: "display-flex" }, [
        _c(
          "div",
          { staticClass: "file-upload-bg", staticStyle: { width: "100%" } },
          [
            _c(
              "div",
              {
                staticClass: "upload-video-box display-flex",
                staticStyle: {
                  "margin-bottom": "10px",
                  "justify-content": "space-between"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", disabled: _vm.readonly },
                    on: { click: _vm.addFile }
                  },
                  [_vm._v("选择文件")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                ref: "tablewrap",
                staticClass: "tableX",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.fileList, border: "" },
                on: { "selection-change": _vm.handleSelectionChange }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "40", align: "center" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "sequence",
                    label: "排序",
                    width: "170",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.showEditFileSort &&
                          _vm.currentIndex === scope.$index
                            ? _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { margin: "5px 5px" },
                                    attrs: {
                                      min: 1,
                                      max: _vm.fileList.length,
                                      size: "mini",
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: _vm.sequence,
                                      callback: function($$v) {
                                        _vm.sequence = $$v
                                      },
                                      expression: "sequence"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "font-size": "24px",
                                      color: "#F56c6c",
                                      cursor: "pointer"
                                    },
                                    on: { click: _vm.cancelSort }
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-check",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "font-size": "24px",
                                      color: "#13ce66",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveSort(scope.row)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    padding: "5px 10px",
                                    margin: "5px"
                                  },
                                  attrs: { disabled: _vm.readonly },
                                  on: {
                                    click: function($event) {
                                      return _vm.sortEdit(
                                        scope.row,
                                        scope.$index
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.sequence))]
                              )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "fileTitle",
                    label: "文件",
                    width: "300",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.displayTitle(scope.row)) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "fileSize",
                    label: "大小",
                    align: "center",
                    width: "110"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                (scope.row.fileSize / 1024 / 1024).toFixed(4)
                              ) +
                              "M\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    align: "center",
                    fixed: "right",
                    width: "220"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", disabled: _vm.readonly },
                              on: {
                                click: function($event) {
                                  return _vm.preview(scope.row, scope.$index)
                                }
                              }
                            },
                            [_vm._v("预览")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", disabled: _vm.readonly },
                              on: {
                                click: function($event) {
                                  return _vm.toLookFileDetail(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", disabled: _vm.readonly },
                              on: {
                                click: function($event) {
                                  return _vm.handleReplaceFile(
                                    scope.row,
                                    scope.$index
                                  )
                                }
                              }
                            },
                            [_vm._v("替换")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "top",
                                disabled: !(
                                  _vm.fileContentOpenIdArr.indexOf(
                                    scope.row.fileContentOpenId
                                  ) > -1
                                )
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "line-height": "1.5" },
                                  attrs: { slot: "content" },
                                  slot: "content"
                                },
                                [
                                  _vm.fileContentOpenIdArr.indexOf(
                                    scope.row.fileContentOpenId
                                  ) > -1
                                    ? _c("div", [_vm._v("正在下载文件")])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          _vm.readonly ||
                                          _vm.fileContentOpenIdArr.indexOf(
                                            scope.row.fileContentOpenId
                                          ) > -1
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.downFile(
                                            scope.row,
                                            scope.$index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("下载")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "txt-del",
                              attrs: { type: "text", disabled: _vm.readonly },
                              on: {
                                click: function($event) {
                                  return _vm.deleteRow(scope.row, scope.$index)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.fileList.length > 0
              ? _c(
                  "div",
                  { staticClass: "pagination-check" },
                  [
                    _c("el-checkbox", {
                      staticClass: "pagination-check-all",
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleSelectedAll },
                      model: {
                        value: _vm.selectedAll,
                        callback: function($$v) {
                          _vm.selectedAll = $$v
                        },
                        expression: "selectedAll"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { "margin-left": "10px", padding: "5px 0" }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: _vm.readonly,
                              plain: ""
                            },
                            on: { click: _vm.emptyFileList }
                          },
                          [_vm._v("批量移除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top",
                              disabled: !_vm.isBatchDown
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { "line-height": "1.5" },
                                attrs: { slot: "content" },
                                slot: "content"
                              },
                              [
                                _vm.isBatchDown
                                  ? _c("div", [_vm._v("正在批量下载文件")])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      loading: _vm.isBatchDown,
                                      disabled: _vm.readonly || _vm.isBatchDown,
                                      plain: ""
                                    },
                                    on: { click: _vm.batchDownFileDia }
                                  },
                                  [_vm._v("批量下载")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              title: "批量下载文件",
                              visible: _vm.dialogVisiblebatchDownFile,
                              width: "400px"
                            },
                            on: {
                              "update:visible": function($event) {
                                _vm.dialogVisiblebatchDownFile = $event
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v("即将下载已选的文件，确认继续？")
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "footer" },
                                slot: "footer"
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        _vm.dialogVisiblebatchDownFile = false
                                      }
                                    }
                                  },
                                  [_vm._v("取 消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.batchDownFile }
                                  },
                                  [_vm._v("确 定")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("file-play", {
                attrs: {
                  mp4url: _vm.mp4url,
                  "dialog-visible": _vm.dialogVisible
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleaudio,
            "before-close": _vm.handleClose,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleaudio = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("file-audio-play", {
                attrs: {
                  mp3url: _vm.mp3url,
                  mp3title: _vm.mp3title,
                  "dialog-visible": _vm.dialogVisibleaudio
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showFileDialog
        ? _c("select-file-dialog", {
            attrs: {
              visible: _vm.showFileDialog,
              dialogType: _vm.operationType,
              replaceIndex: _vm.fileIndex,
              "exclude-data": _vm.fileList,
              partnerOpenId: _vm.partnerOpenId
            },
            on: {
              "update:visible": function($event) {
                _vm.showFileDialog = $event
              },
              "add-select": _vm.onAddFileContent
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }