var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.contentLoadng,
          expression: "loading.contentLoadng"
        }
      ],
      staticClass: "addAdmin"
    },
    [
      _c("div", { staticClass: "titleDes" }, [_vm._v("编辑管理员")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm, rules: _vm.rules }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#ff4949", "margin-right": "4px" }
                      },
                      [_vm._v("*")]
                    ),
                    _vm._v("用户\n        ")
                  ]),
                  _vm._v(" "),
                  _c("ordinaryuser", {
                    staticStyle: { width: "200px" },
                    attrs: { userOpenId: _vm.buyUserOpenId },
                    on: { receiveuserid: _vm.receiveuserid }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "管理员姓名", prop: "adminName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.ruleForm.adminName,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "adminName", $$v)
                      },
                      expression: "ruleForm.adminName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "adminTel", label: "管理员手机号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      oninput: "if(value.length>11)value=value.slice(0,11)",
                      type: "number"
                    },
                    nativeOn: {
                      mousewheel: function($event) {
                        $event.preventDefault()
                      }
                    },
                    model: {
                      value: _vm.ruleForm.adminTel,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "adminTel", _vm._n($$v))
                      },
                      expression: "ruleForm.adminTel"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "enterpriseWeChatOpenId",
                    label: "关联企业微信"
                  }
                },
                [
                  _c("el-cascader", {
                    staticClass: "cascader-wechat",
                    attrs: {
                      placeholder: "选择企业人员",
                      options: _vm.departmentList,
                      props: {
                        children: "children",
                        label: "name",
                        value: "id"
                      },
                      clearable: "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.ruleForm.enterpriseWeChatOpenId,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "enterpriseWeChatOpenId", $$v)
                      },
                      expression: "ruleForm.enterpriseWeChatOpenId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.roleList.length
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { label: "角色", prop: "role" }
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.ruleForm.role,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "role", $$v)
                            },
                            expression: "ruleForm.role"
                          }
                        },
                        _vm._l(_vm.roleList, function(role) {
                          return _c(
                            "el-checkbox",
                            {
                              key: role.id,
                              attrs: { label: role.title },
                              on: { change: _vm.roleListHandle }
                            },
                            [_vm._v(_vm._s(role.title))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm.treeList.length
        ? _c(
            "div",
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading.treeList,
                    expression: "loading.treeList"
                  }
                ],
                ref: "selectTree",
                staticClass: "treeClass",
                attrs: {
                  "expand-on-click-node": false,
                  data: _vm.treeList,
                  props: _vm.defaultProps,
                  "show-checkbox": "",
                  "node-key": "privilegeOpenId",
                  "default-expand-all": "",
                  "default-checked-keys": _vm.defaultCheckedKeys
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c(
                            "span",
                            {
                              class:
                                data.parentOpenId && data.hidden
                                  ? "paddingLetSty"
                                  : "",
                              staticStyle: { "font-size": "14px" }
                            },
                            [_vm._v(_vm._s(node.label))]
                          )
                        ])
                      }
                    }
                  ],
                  null,
                  false,
                  2807299429
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "saveInfo" },
        [
          _c(
            "el-button",
            { staticClass: "goBack", on: { click: _vm.goBack } },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "saveBtn",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseInfo commonFlex" }, [
      _c("div", { staticClass: "commonShuSty" }),
      _c(
        "span",
        {
          staticStyle: {
            height: "16px",
            "line-height": "16px",
            color: "#303133",
            "font-weight": "bold"
          }
        },
        [_vm._v("基本信息")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseInfo commonFlex" }, [
      _c("div", { staticClass: "commonShuSty" }),
      _c(
        "span",
        {
          staticStyle: {
            height: "16px",
            "line-height": "16px",
            color: "#303133",
            "font-weight": "bold"
          }
        },
        [_vm._v("权限预览")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }