var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "audio-wrapper"
    },
    [
      _c(
        "div",
        { staticClass: "audio-wrapper-bg" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.liveInfo,
                rules: _vm.rules,
                "status-icon": "",
                "label-width": "110px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-audio-wrapper" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          基本信息 "),
                    _vm.liveInfo.resourceOpenId
                      ? _c("span", { staticClass: "title-id" }, [
                          _vm._v(
                            "作品ID：" + _vm._s(_vm.liveInfo.resourceOpenId)
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.openId && _vm.liveInfo.state
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.liveInfo[_vm.statusList[0].dateName]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.liveInfo[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.liveInfo.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.liveInfo.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "直播类型" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0, disabled: _vm.allowEdit },
                          model: {
                            value: _vm.liveInfo.pushLive,
                            callback: function($$v) {
                              _vm.$set(_vm.liveInfo, "pushLive", $$v)
                            },
                            expression: "liveInfo.pushLive"
                          }
                        },
                        [_vm._v("常规直播")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1, disabled: _vm.allowEdit },
                          model: {
                            value: _vm.liveInfo.pushLive,
                            callback: function($$v) {
                              _vm.$set(_vm.liveInfo, "pushLive", $$v)
                            },
                            expression: "liveInfo.pushLive"
                          }
                        },
                        [_vm._v("推流直播")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2, disabled: _vm.allowEdit },
                          model: {
                            value: _vm.liveInfo.pushLive,
                            callback: function($$v) {
                              _vm.$set(_vm.liveInfo, "pushLive", $$v)
                            },
                            expression: "liveInfo.pushLive"
                          }
                        },
                        [_vm._v("伪直播")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "直播名称", prop: "title" } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "input-box",
                        attrs: {
                          disabled: _vm.allowEdit,
                          "show-word-limit": "",
                          maxlength: 100,
                          debounce: 500,
                          placeholder: "请填写或通过上传直播回放视频获取名称",
                          "fetch-suggestions": _vm.searchTitle
                        },
                        model: {
                          value: _vm.liveInfo.title,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "title", $$v)
                          },
                          expression: "liveInfo.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品编号" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          disabled: _vm.allowEdit,
                          placeholder: "请输入作品编号",
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.liveInfo.resourceEncode,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "resourceEncode", $$v)
                          },
                          expression: "liveInfo.resourceEncode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "直播封面", prop: "cover" } },
                    [
                      _c("cover-upload", {
                        staticClass: "live-cover-upload",
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: _vm.LIVE_ID.toString(),
                          disabledEdit: _vm.allowEdit
                        },
                        on: { deletecover: _vm.deletecoverFun },
                        model: {
                          value: _vm.liveInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "cover", $$v)
                          },
                          expression: "liveInfo.cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.liveInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "cover", $$v)
                          },
                          expression: "liveInfo.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "直播讲师", prop: "anchor" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          disabled: _vm.allowEdit,
                          maxlength: "60",
                          placeholder: "请填写主讲老师"
                        },
                        model: {
                          value: _vm.liveInfo.anchor,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "anchor", $$v)
                          },
                          expression: "liveInfo.anchor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "直播介绍", prop: "detail" } },
                    [
                      _c("editor-view", {
                        attrs: { readonly: _vm.allowEdit },
                        model: {
                          value: _vm.liveInfo.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "detail", $$v)
                          },
                          expression: "liveInfo.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "直播时间", prop: "startTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.allowEdit || _vm.isExpire,
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "请设置直播开始时间"
                        },
                        model: {
                          value: _vm.liveInfo.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "startTime", $$v)
                          },
                          expression: "liveInfo.startTime"
                        }
                      }),
                      _vm._v(" "),
                      _vm.liveInfo.pushLive === 2
                        ? _c("span", { staticClass: "tip-txt" }, [
                            _c("i", {
                              staticClass: "icon el-icon-info icon-tip"
                            }),
                            _vm._v(
                              "伪直播开始时间必须在创建时间一小时后,且直播开始后将不能修改直播时间"
                            )
                          ])
                        : _c("span", { staticClass: "tip-txt" }, [
                            _c("i", {
                              staticClass: "icon el-icon-info icon-tip"
                            }),
                            _vm._v("直播开始后将不能修改直播时间")
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.liveInfo.pushLive !== 2
                    ? _c("el-form-item", { attrs: { label: "直播时长" } }, [
                        _c(
                          "div",
                          { staticClass: "duration-bg" },
                          [
                            _c("el-input-number", {
                              attrs: {
                                disabled:
                                  _vm.allowEdit ||
                                  _vm.isExpire ||
                                  _vm.liveInfo.longTerm === 1,
                                precision: 0,
                                step: 1,
                                min: 0,
                                "controls-position": "right"
                              },
                              model: {
                                value: _vm.liveInfo.liveLength,
                                callback: function($$v) {
                                  _vm.$set(_vm.liveInfo, "liveLength", $$v)
                                },
                                expression: "liveInfo.liveLength"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "line" }, [
                              _vm._v("分钟")
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  disabled: _vm.openId.length > 0,
                                  "true-label": 1,
                                  "false-label": 0
                                },
                                model: {
                                  value: _vm.liveInfo.longTerm,
                                  callback: function($$v) {
                                    _vm.$set(_vm.liveInfo, "longTerm", $$v)
                                  },
                                  expression: "liveInfo.longTerm"
                                }
                              },
                              [_vm._v("长期房间")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "tip-txt" }, [
                              _c("i", {
                                staticClass: "icon el-icon-info icon-tip"
                              }),
                              _vm._v("直播开始后将不能修改直播时长")
                            ])
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提前进入时间", prop: "preEnterTime" } },
                    [
                      _c(
                        "div",
                        { staticClass: "duration-bg" },
                        [
                          _c("el-input-number", {
                            attrs: {
                              disabled:
                                _vm.allowEdit || _vm.isExpire || _vm.isAnytime,
                              precision: 0,
                              step: 1,
                              min: 0,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.liveInfo.preEnterTime,
                              callback: function($$v) {
                                _vm.$set(_vm.liveInfo, "preEnterTime", $$v)
                              },
                              expression: "liveInfo.preEnterTime"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "line" }, [_vm._v("分钟")]),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                disabled: _vm.allowEdit || _vm.isExpire
                              },
                              model: {
                                value: _vm.isAnytime,
                                callback: function($$v) {
                                  _vm.isAnytime = $$v
                                },
                                expression: "isAnytime"
                              }
                            },
                            [_vm._v("任意时间")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "tip-txt" }, [
                            _c("i", {
                              staticClass: "icon el-icon-info icon-tip"
                            }),
                            _vm._v("直播开始后将不能修改提前进入时间")
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否允许拖堂" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.allowEdit },
                          model: {
                            value: _vm.liveInfo.delayPlay,
                            callback: function($$v) {
                              _vm.$set(_vm.liveInfo, "delayPlay", $$v)
                            },
                            expression: "liveInfo.delayPlay"
                          }
                        },
                        [_vm._v("允许")]
                      ),
                      _vm._v(" "),
                      _vm.liveInfo.delayPlay
                        ? _c(
                            "div",
                            { staticClass: "duration-bg" },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.allowEdit,
                                  precision: 0,
                                  step: 1,
                                  min: 0,
                                  max: 120,
                                  "controls-position": "right"
                                },
                                model: {
                                  value: _vm.liveInfo.endDelayTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.liveInfo, "endDelayTime", $$v)
                                  },
                                  expression: "liveInfo.endDelayTime"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "line" }, [
                                _vm._v("分钟")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "直播地址" } }, [
                    _c("div", { staticClass: "live-address-box" }, [
                      _c(
                        "div",
                        { staticClass: "live-address-wrapper" },
                        [
                          _c("label", { staticClass: "live-addres-label" }, [
                            _vm._v("小程序地址")
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "live-addres-input",
                            staticStyle: { width: "730px" },
                            attrs: {
                              disabled: _vm.allowEdit,
                              placeholder: "请填写直播地址"
                            },
                            model: {
                              value: _vm.liveInfo.appletUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.liveInfo, "appletUrl", $$v)
                              },
                              expression: "liveInfo.appletUrl"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "live-address-wrapper" },
                        [
                          _c("label", { staticClass: "live-addres-label" }, [
                            _vm._v("网页地址")
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "live-addres-input",
                            staticStyle: { width: "730px" },
                            attrs: {
                              disabled: _vm.allowEdit,
                              placeholder: "请填写直播地址"
                            },
                            model: {
                              value: _vm.liveInfo.webUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.liveInfo, "webUrl", $$v)
                              },
                              expression: "liveInfo.webUrl"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.liveInfo.pushLive !== 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "直播自动录制" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              disabled: _vm.allowEdit,
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66"
                            },
                            model: {
                              value: _vm.liveInfo.liveRecord,
                              callback: function($$v) {
                                _vm.$set(_vm.liveInfo, "liveRecord", $$v)
                              },
                              expression: "liveInfo.liveRecord"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "在直播中心显示" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: _vm.allowEdit,
                          "active-color": "#13ce66"
                        },
                        model: {
                          value: _vm.liveInfo.showInCenter,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "showInCenter", $$v)
                          },
                          expression: "liveInfo.showInCenter"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "live-set-box" }, [
                    _c(
                      "div",
                      { staticClass: "live-set-wrapper" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: _vm.allowEdit },
                            model: {
                              value: _vm.liveInfo.vipView,
                              callback: function($$v) {
                                _vm.$set(_vm.liveInfo, "vipView", $$v)
                              },
                              expression: "liveInfo.vipView"
                            }
                          },
                          [_vm._v("仅会员可进直播间")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "live-set-wrapper" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: _vm.allowEdit },
                            model: {
                              value: _vm.liveInfo.owner,
                              callback: function($$v) {
                                _vm.$set(_vm.liveInfo, "owner", $$v)
                              },
                              expression: "liveInfo.owner"
                            }
                          },
                          [_vm._v("已购买本直播课或所属专辑的用户可进直播间")]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "教室容量" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true, disabled: _vm.allowEdit },
                          model: {
                            value: _vm.liveInfo.maxUser,
                            callback: function($$v) {
                              _vm.$set(_vm.liveInfo, "maxUser", $$v)
                            },
                            expression: "liveInfo.maxUser"
                          }
                        },
                        [_vm._v("设上限")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false, disabled: _vm.allowEdit },
                          model: {
                            value: _vm.liveInfo.maxUser,
                            callback: function($$v) {
                              _vm.$set(_vm.liveInfo, "maxUser", $$v)
                            },
                            expression: "liveInfo.maxUser"
                          }
                        },
                        [_vm._v("不设上限")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.liveInfo.maxUser
                    ? _c("div", { staticClass: "live-set-box" }, [
                        _c(
                          "div",
                          { staticClass: "live-set-wrapper" },
                          [
                            _c("el-input", {
                              staticClass: "txt-177",
                              attrs: { disabled: _vm.allowEdit },
                              model: {
                                value: _vm.liveInfo.maxUsers,
                                callback: function($$v) {
                                  _vm.$set(_vm.liveInfo, "maxUsers", $$v)
                                },
                                expression: "liveInfo.maxUsers"
                              }
                            }),
                            _c("i", {
                              staticClass: "icon el-icon-info icon-tip"
                            }),
                            _vm._v("设置教室最大容量\n          ")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "live-set-wrapper" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: _vm.allowEdit },
                                model: {
                                  value: _vm.liveInfo.vipInto,
                                  callback: function($$v) {
                                    _vm.$set(_vm.liveInfo, "vipInto", $$v)
                                  },
                                  expression: "liveInfo.vipInto"
                                }
                              },
                              [_vm._v("VIP可继续进入")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "video-box", attrs: { label: "回放上传" } },
                    [
                      _c(
                        "div",
                        { staticClass: "check-box" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1", disabled: _vm.allowEdit },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("本地上传")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2", disabled: _vm.allowEdit },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("VID上传")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isVidUpload === "1",
                              expression: "isVidUpload === '1'"
                            }
                          ],
                          staticClass: "card-lz-content upload-video"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "live-display-flex" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    attrs: { value: _vm.liveInfo.vid }
                                  }),
                                  _vm._v(" "),
                                  _c("upload-file", {
                                    ref: "uploadVideo",
                                    attrs: {
                                      disabledEdit: _vm.allowEdit,
                                      types: "video",
                                      videourl: _vm.videourl,
                                      videoid: _vm.openId
                                    },
                                    on: {
                                      startUpload: _vm.startVideoUpload,
                                      getResult: _vm.getVideo,
                                      "delect-event": _vm.delectevent
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "live-display-flex video-duration-wrapper"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "video-upload-text" },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-info",
                                        staticStyle: { color: "#409EFF" }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    格式支持MP4，大小不超过2G，建议视频分辨率为\n                    "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "     1280×720\n                  "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.liveInfo.vid
                                    ? _c(
                                        "span",
                                        { staticClass: "video-vid-preview" },
                                        [
                                          _vm._v(
                                            "\n                  VID: " +
                                              _vm._s(_vm.liveInfo.vid) +
                                              "\n                  "
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.videoPreviewFun(
                                                    _vm.videourl
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("预览")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isVidUpload === "2"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "input-vid-bg",
                                  attrs: { prop: "temporaryVid" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-box input-vid-box" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: _vm.allowEdit,
                                          placeholder: "请输入视频vid"
                                        },
                                        model: {
                                          value: _vm.liveInfo.temporaryVid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.liveInfo,
                                              "temporaryVid",
                                              $$v
                                            )
                                          },
                                          expression: "liveInfo.temporaryVid"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "copy-btn tag-read",
                                      attrs: {
                                        "data-clipboard-text":
                                          _vm.liveInfo.temporaryVid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.copy()
                                        }
                                      }
                                    },
                                    [_vm._v("复制")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.liveInfo.pushLive === 2,
                          expression: "liveInfo.pushLive === 2"
                        }
                      ],
                      staticClass: "video-box",
                      attrs: { label: "伪直播上传", prop: "bvid" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "check-box" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1", disabled: _vm.allowEdit },
                              model: {
                                value: _vm.isBvidUpload,
                                callback: function($$v) {
                                  _vm.isBvidUpload = $$v
                                },
                                expression: "isBvidUpload"
                              }
                            },
                            [_vm._v("本地上传")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2", disabled: _vm.allowEdit },
                              model: {
                                value: _vm.isBvidUpload,
                                callback: function($$v) {
                                  _vm.isBvidUpload = $$v
                                },
                                expression: "isBvidUpload"
                              }
                            },
                            [_vm._v("VID上传")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isBvidUpload === "1",
                              expression: "isBvidUpload === '1'"
                            }
                          ],
                          staticClass: "bjy-upload-box"
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "bvid" } },
                            [
                              _c("bjy-upload", {
                                ref: "uploadBjyVideo",
                                attrs: { disabledEdit: _vm.allowEdit },
                                on: {
                                  getBjyBid: _vm.getBjyBid,
                                  "del-bjy": _vm.delBVid
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bjy-upload-remark" }, [
                            _c("span", { staticClass: "video-upload-text" }, [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: { color: "#409EFF" }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                    格式支持MP4，大小不超过2G，建议视频分辨率为\n                    "
                                ),
                                _c("br"),
                                _vm._v("     1280×720\n                ")
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.liveInfo.bvid
                              ? _c(
                                  "span",
                                  { staticClass: "video-vid-preview" },
                                  [
                                    _vm._v(
                                      "\n                VID: " +
                                        _vm._s(_vm.liveInfo.bvid) +
                                        "\n                "
                                    ),
                                    _vm.bjyVideoUrl
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.videoPreviewFun(
                                                  _vm.bjyVideoUrl
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("预览")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isBvidUpload === "2"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "input-vid-bg",
                                  attrs: { prop: "temporaryBVid" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-box input-vid-box" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: _vm.allowEdit,
                                          placeholder: "请输入百家云vid"
                                        },
                                        model: {
                                          value: _vm.liveInfo.temporaryBVid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.liveInfo,
                                              "temporaryBVid",
                                              $$v
                                            )
                                          },
                                          expression: "liveInfo.temporaryBVid"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "copy-btn tag-read",
                                      attrs: {
                                        "data-clipboard-text":
                                          _vm.liveInfo.temporaryBVid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.copy()
                                        }
                                      }
                                    },
                                    [_vm._v("复制")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("作品属性")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同步" } },
                    [
                      _c("sync-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.liveInfo.tempCode,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "tempCode", $$v)
                          },
                          expression: "liveInfo.tempCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "types" } },
                    [
                      _c("works", {
                        ref: "worksView",
                        attrs: { isDisabled: _vm.allowEdit },
                        on: { "add-classtion": _vm.addClass }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { value: _vm.liveInfo.types }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版次" } },
                    [
                      _c("edition-box", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.liveInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "periodCode", $$v)
                          },
                          expression: "liveInfo.periodCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.liveInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.liveInfo, "periodCode", $$v)
                          },
                          expression: "liveInfo.periodCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "类目", prop: "secondRegimentationIds" }
                    },
                    [
                      _c("category-view", {
                        staticStyle: { width: "850px" },
                        attrs: { "is-disabled": _vm.allowEdit },
                        on: { "category-change": _vm.categoryChange },
                        model: {
                          value: _vm.liveInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.liveInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "liveInfo.secondRegimentationIds"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.liveInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.liveInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "liveInfo.secondRegimentationIds"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "property-box" },
                    [
                      _c("el-collapse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowProperty,
                                expression: "isShowProperty"
                              }
                            ],
                            staticClass: "property-item-bg"
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地区" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择地区"
                                    },
                                    model: {
                                      value: _vm.areaValue,
                                      callback: function($$v) {
                                        _vm.areaValue = $$v
                                      },
                                      expression: "areaValue"
                                    }
                                  },
                                  _vm._l(_vm.areaList, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择商品标签"
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.tagValue,
                                      callback: function($$v) {
                                        _vm.tagValue = $$v
                                      },
                                      expression: "tagValue"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        class: [
                          "property-item-arrow",
                          _vm.isShowProperty
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        ],
                        on: {
                          click: function($event) {
                            _vm.isShowProperty = !_vm.isShowProperty
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("商品设置")]),
                  _vm._v(" "),
                  _c("goods-set", {
                    ref: "commodity",
                    attrs: {
                      partnerOpenId: _vm.partnerOpenId,
                      "independent-sale": _vm.liveInfo.independentSale,
                      "type-name": _vm.typeName,
                      disabledEdit: _vm.allowEdit,
                      "resource-open-id": _vm.openId
                    },
                    on: { "official-partner": _vm.getOfficialPartner }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("works-operation-buttton", {
                attrs: {
                  openId: _vm.openId,
                  allowEdit: _vm.allowEdit,
                  operation: _vm.operation,
                  status: _vm.liveInfo.state,
                  passBtn: _vm.passBtn,
                  refuseBtn: _vm.refuseBtn,
                  restoreBtn: _vm.restoreBtn,
                  publishBtn: _vm.publishBtn,
                  saveBtn: _vm.saveBtn,
                  typeName: _vm.typeName,
                  saveDraftBtn: _vm.saveDraftBtn
                },
                on: {
                  closeView: _vm.closeView,
                  updateEdit: _vm.updateEdit,
                  passItem: _vm.passItem,
                  handelRefuse: _vm.handelRefuse,
                  handelSaveAndReview: _vm.handelSaveAndReview,
                  handleBookRestore: _vm.handleBookRestore,
                  saveData: _vm.saveData,
                  saveDraft: _vm.saveDraft
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoPreview,
            "before-close": _vm.videoPreviewClose,
            title: "视频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoPreview = $event
            }
          }
        },
        [
          _c("div", {
            staticClass: "video-start",
            attrs: { id: "videoPreview" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }