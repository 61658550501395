"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("../../../General/SearchButton/index"));

var _index2 = _interopRequireDefault(require("../../../General/FilterOptions/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Gr_Header_SearchGroup_index',
  components: {
    SearchButton: _index.default,
    FilterOptions: _index2.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: '商品名称、编号搜索'
    },
    hasState: {
      type: Boolean,
      default: false
    },
    showInventory: {
      type: Boolean,
      default: true
    },
    showLocalInventory: {
      type: Boolean,
      default: false
    },
    useMerchantBrandSeries: {
      type: Boolean,
      default: false
    },
    partnerOpenId: {
      type: String,
      default: undefined
    },
    presetPartnerOpenId: {
      type: String,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    searchChanged: function searchChanged() {
      this.$emit('search-changed');
    }
  }
};
exports.default = _default2;