"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var labesum = {
  namespaced: true,
  state: {
    sumstate: undefined,
    famousTeacherNum: undefined,
    couponNum: undefined,
    resource: []
  },
  mutations: {
    MODIFY: function MODIFY(state) {
      state.sumstate = new Date().getTime();
    },
    SETRESOURCE: function SETRESOURCE(state, arr) {
      state.resource = arr;
    },
    MODIFYFAMOUSTEACHERNUM: function MODIFYFAMOUSTEACHERNUM(state) {
      state.famousTeacherNum = new Date().getTime();
    },
    MODIFYCOUPONNUM: function MODIFYCOUPONNUM(state) {
      state.couponNum = new Date().getTime();
    }
  },
  actions: {
    set_state_sum: function set_state_sum(_ref) {
      var commit = _ref.commit;
      commit('MODIFY');
    },
    set_resource: function set_resource(_ref2, arr) {
      var commit = _ref2.commit;
      commit('SETRESOURCE', arr);
    },
    set_famous_teacher_num: function set_famous_teacher_num(_ref3) {
      var commit = _ref3.commit;
      commit('MODIFYFAMOUSTEACHERNUM');
    },
    set_coupon_num: function set_coupon_num(_ref4) {
      var commit = _ref4.commit;
      commit('MODIFYCOUPONNUM');
    }
  },
  getters: {
    sumstate: function sumstate(state) {
      return state.sumstate;
    },
    famousTeacherNum: function famousTeacherNum(state) {
      return state.famousTeacherNum;
    },
    couponNum: function couponNum(state) {
      return state.couponNum;
    }
  }
};
var _default = labesum;
exports.default = _default;