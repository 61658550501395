"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _imageTextDetail = _interopRequireDefault(require("./imageTextDetail.vue"));

//
//
//
//
//
//
var _default = {
  name: 'AddImageText',
  components: {
    EditImageText: _imageTextDetail.default
  },
  data: function data() {
    return {
      operation: 'add'
    };
  }
};
exports.default = _default;