var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-line" },
    [
      _c("el-input-number", {
        staticClass: "price-batch",
        attrs: { controls: false, max: 100, min: 0 },
        on: { change: _vm.onInputUpdate },
        model: {
          value: _vm.discount,
          callback: function($$v) {
            _vm.discount = $$v
          },
          expression: "discount"
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "zhe" }, [_vm._v("折")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }