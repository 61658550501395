var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tableListContainer" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          ref: "multipleTable",
          staticClass: "paperTable",
          attrs: {
            width: "100%",
            height: "calc(100vh - 236px)",
            data: _vm.paperList,
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "30" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "header-align": "left",
              "min-width": "180",
              label: "试卷",
              prop: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "worksSolt" },
                      [
                        _c("el-image", {
                          staticClass: "paperCover",
                          attrs: {
                            src: _vm.getImageUrl(scope.row.cover),
                            fit: "cover"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "soltRight" }, [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.name) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tags" }, [
                            scope.row.categoryName
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.categoryName) + " /")
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.paperType) +
                                "\n              "
                            ),
                            scope.row.paperMark
                              ? _c("span", [
                                  _vm._v(
                                    "\n                / 总分" +
                                      _vm._s(scope.row.paperMark) +
                                      "分\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v("/" + _vm._s(scope.row.syncText))
                            ]),
                            _vm._v(" "),
                            scope.row.tags && scope.row.tags !== ""
                              ? _c("span", [
                                  _vm._v("/" + _vm._s(scope.row.tags))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.limitTime
                              ? _c("span", [
                                  _vm._v(
                                    "\n                / 限制" +
                                      _vm._s(scope.row.examTime * 60) +
                                      "分钟\n              "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "labelInfo" }, [
                            !scope.row.isShowInput
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "serial file-name",
                                    on: {
                                      click: function($event) {
                                        return _vm.setInput(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("点击填写编号")]
                                )
                              : _c("div", [
                                  _vm.showEditEncode
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: scope.row.resourceEncode,
                                            expression:
                                              "scope.row.resourceEncode"
                                          }
                                        ],
                                        staticClass: "serial-ipt",
                                        attrs: {
                                          type: "text",
                                          placeholder: "请填写编号"
                                        },
                                        domProps: {
                                          value: scope.row.resourceEncode
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.confirmName(scope.row)
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              scope.row,
                                              "resourceEncode",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "div-encode-bg",
                                          on: {
                                            click: function($event) {
                                              _vm.showEditEncode = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-resource" },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.resourceEncode)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "merchantCover",
                              attrs: {
                                src: _vm.getImageUrl(scope.row.partnerCover),
                                alt: ""
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "merchantName" }, [
                              _vm._v(_vm._s(scope.row.partnerName))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "size" }, [
                              _vm._v(
                                "创建于: " +
                                  _vm._s(
                                    scope.row.createdAt
                                      ? scope.row.createdAt
                                      : "--"
                                  )
                              )
                            ])
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "obj-remark-bg" }, [
                      scope.row.ifOfficialHide === 1
                        ? _c("div", { staticClass: "object-reason-box" }, [
                            _c("label", { staticClass: "reason-label" }, [
                              _vm._v("官方隐藏")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "reason-txt" }, [
                              _c("span", { staticClass: "stop-sold-at" }, [
                                _vm._v(_vm._s(scope.row.officialHideTime))
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.ifHide === 1
                        ? _c("div", { staticClass: "object-reason-box" }, [
                            _c("label", { staticClass: "reason-label" }, [
                              _vm._v("商户隐藏")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "reason-txt" }, [
                              _c("span", { staticClass: "stop-sold-at" }, [
                                _vm._v(_vm._s(scope.row.hideTime))
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.ifOfficialSold === 1
                        ? _c("div", { staticClass: "object-reason-box" }, [
                            _c("label", { staticClass: "reason-label" }, [
                              _vm._v("官方停售")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "reason-txt" }, [
                              _c("span", { staticClass: "stop-sold-at" }, [
                                _vm._v(_vm._s(scope.row.officialStopSoldTime))
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "stop-sold-at",
                                  staticStyle: { "margin-left": "8px" }
                                },
                                [
                                  _vm._v(
                                    "原因: " +
                                      _vm._s(scope.row.officialStopSoldReason)
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.ifSold === 1
                        ? _c("div", { staticClass: "object-reason-box" }, [
                            _c("label", { staticClass: "reason-label" }, [
                              _vm._v("商户停售")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "reason-txt" }, [
                              _c("span", { staticClass: "stop-sold-at" }, [
                                _vm._v(_vm._s(scope.row.stopSoldTime))
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "stop-sold-at",
                                  staticStyle: { "margin-left": "8px" }
                                },
                                [
                                  _vm._v(
                                    "原因: " + _vm._s(scope.row.stopSoldReason)
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "header-align": "right", width: "240" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "operation" },
                      [
                        _vm.query.status === 1
                          ? _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [
                                _c("svg-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#67C23A",
                                    cursor: "pointer"
                                  },
                                  attrs: { "icon-class": "right" },
                                  on: {
                                    click: function($event) {
                                      return _vm.examine(scope.row, 2)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("svg-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#FC745E",
                                    cursor: "pointer"
                                  },
                                  attrs: { "icon-class": "del" },
                                  on: {
                                    click: function($event) {
                                      return _vm.examine(scope.row, 3)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.query.status === 0
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "发布",
                                  placement: "top"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-fabu operationName release-icon",
                                  on: {
                                    click: function($event) {
                                      return _vm.releasePaper(scope.row)
                                    }
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "预览",
                              placement: "top"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-yulan operationName",
                              on: {
                                click: function($event) {
                                  return _vm.previewPaper(scope.row)
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top"
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "iconfont icon-xiangqing operationName nameMargin",
                              on: {
                                click: function($event) {
                                  return _vm.details(scope.row)
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "更多",
                                  placement: "top"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-gengduo operationName"
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.copy(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                复制试卷\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleOperation(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                操作日志\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.download(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                下载试卷\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.move(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                移动分类\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showDel
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.delPaperItem(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("移除")]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.ifOfficialHide === 0
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { divided: "" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.hide(
                                              scope.row,
                                              1,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                官方隐藏\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.ifOfficialHide === 1
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { divided: "" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.hide(
                                              scope.row,
                                              0,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                官方显示\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.ifOfficialSold === 0
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { divided: "" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.sold(
                                              scope.row,
                                              1,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                官方停售\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.ifOfficialSold === 1
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { divided: "" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.sold(
                                              scope.row,
                                              0,
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                官方售卖\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableListBottom" },
        [
          _c(
            "el-checkbox",
            {
              attrs: { indeterminate: _vm.indeterminate },
              on: {
                change: function($event) {
                  return _vm.toggleSelection(_vm.paperList)
                }
              },
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { "split-button": "", type: "primary" },
                  on: { click: _vm.submitChange }
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.dropMenName) + "\n        "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.dropMenuList, function(item, index) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: index,
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeDropName(item, index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "pagination",
            attrs: {
              background: "",
              "current-page": _vm.query.currentPage,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.query.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "statistics",
          attrs: {
            width: "970px",
            "append-to-body": true,
            "close-on-click-modal": false,
            title: "统计分析",
            visible: _vm.statisticsdialogVisible,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.statisticsdialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-container",
            [
              _c(
                "el-header",
                { staticClass: "statisticsHeader" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "elTab",
                      on: { "tab-click": _vm.statisticsClick },
                      model: {
                        value: _vm.statisticsActiveName,
                        callback: function($$v) {
                          _vm.statisticsActiveName = $$v
                        },
                        expression: "statisticsActiveName"
                      }
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "基本分析", name: "basicAnalysis" }
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "试卷分析", name: "paperAnalysis" }
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "答题记录", name: "answerNotes" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                { staticClass: "statisticsMain" },
                [
                  _c(
                    "div",
                    { staticClass: "mainTopTime" },
                    [
                      _c("span", { staticClass: "time" }, [_vm._v("答题时间")]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.searchTime,
                          callback: function($$v) {
                            _vm.searchTime = $$v
                          },
                          expression: "searchTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.statisticsActiveName === "basicAnalysis"
                    ? _c("basic-analysis")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.statisticsActiveName === "answerNotes"
                    ? _c("answer-notes")
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.statisticsActiveName === "paperAnalysis"
                ? _c("analysis-paper")
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [_c("el-button", { on: { click: _vm.cancel } }, [_vm._v("关 闭")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "move-category-dialog",
          attrs: {
            title: "移动分类",
            visible: _vm.dialogVisible,
            width: "450px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.initMoveDialog
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "moveCategoryForm",
              attrs: {
                model: _vm.moveCategoryForm,
                rules: _vm.rules,
                "label-width": "60px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "moveToCategoryOpenId", label: "分类" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      props: {
                        value: "paperCategoryOpenId",
                        label: "name",
                        children: "childList"
                      },
                      options: _vm.treeData,
                      clearable: ""
                    },
                    model: {
                      value: _vm.moveCategoryForm.moveToCategoryOpenId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moveCategoryForm,
                          "moveToCategoryOpenId",
                          $$v
                        )
                      },
                      expression: "moveCategoryForm.moveToCategoryOpenId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.moveSubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "停售",
            visible: _vm.soldDialogVisible,
            width: "512px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.soldDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "停售原因" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入停售原因" },
                    model: {
                      value: _vm.stopSoldReason,
                      callback: function($$v) {
                        _vm.stopSoldReason = $$v
                      },
                      expression: "stopSoldReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.soldDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.soldSubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      }),
      _vm._v(" "),
      _c("batch-set-attribute", {
        attrs: {
          visible: _vm.openSetAttribute,
          list: _vm.multipleSelection,
          "partner-open-id": _vm.query.partnerOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.openSetAttribute = $event
          },
          refreshList: _vm.getPaperList
        }
      }),
      _vm._v(" "),
      _c("download-paper-dialog", {
        attrs: { visible: _vm.openDownload, value: _vm.currentPaper },
        on: {
          "update:visible": function($event) {
            _vm.openDownload = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.isShowOperationRecord
        ? _c("resource-operation-record", {
            attrs: {
              visible: _vm.isShowOperationRecord,
              type: "question",
              "resource-obj": _vm.operatoinResource
            },
            on: {
              "update:visible": function($event) {
                _vm.isShowOperationRecord = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }