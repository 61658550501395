"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _sequence = _interopRequireDefault(require("@/components/BookPageEditor/Page/Section/sequence.vue"));

var _area = _interopRequireDefault(require("@/components/BookPageEditor/Page/Section/area.vue"));

var _point = _interopRequireDefault(require("@/components/BookPageEditor/Page/Section/point.vue"));

var _delete = _interopRequireDefault(require("@/components/BookPageEditor/Page/Section/delete.vue"));

var _viewCount = _interopRequireDefault(require("@/components/BookPageEditor/Page/Section/viewCount.vue"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'PageSection',
  components: {
    PageSectionSequence: _sequence.default,
    PageSectionArea: _area.default,
    PageSectionPoint: _point.default,
    PageSectionDelete: _delete.default,
    PageSectionViewCount: _viewCount.default
  },
  props: {
    localId: {
      required: true,
      type: String
    },
    tempSection: {
      required: false,
      default: null,
      type: Object
    }
  },
  computed: {
    canEdit: function canEdit() {
      return this.$store.state.book.bookCanEdit;
    },
    section: {
      get: function get() {
        var section = this.$store.state.book.section.sections.value[this.localId] || this.tempSection;
        return section;
      },
      set: function set(value) {
        this.$store.dispatch('book/section/updateSection', value);
      }
    },
    top: function top() {
      return this.section.positionTop;
    },
    left: function left() {
      return this.section.positionLeft;
    },
    width: function width() {
      return this.section.positionWidth;
    },
    height: function height() {
      return this.section.positionHeight;
    },
    activeId: function activeId() {
      return this.$store.state.book.section.activeId;
    },
    active: function active() {
      return this.activeId === this.localId && this.canEdit;
    },
    pageSectionStyle: function pageSectionStyle() {
      var styleObject = {
        top: "".concat(this.top, "%"),
        left: "".concat(this.left, "%"),
        width: "".concat(this.width, "%"),
        height: "".concat(this.height, "%")
      };

      if (this.section.sectionType === 3) {
        return _objectSpread(_objectSpread({}, styleObject), {}, {
          'background-color': 'rgba(175,176,178,0.782)'
        });
      }

      return _objectSpread(_objectSpread({}, styleObject), {}, {
        'background-color': 'rgba(255,255,255,0.382)'
      });
    },
    pageSectionClass: function pageSectionClass() {
      return [this.active ? 'active' : undefined];
    },
    imageSection: function imageSection() {
      return (this.section.sectionType === 2 || this.section.sectionType === 4) && this.canEdit;
    },
    sectionCanPenetrate: function sectionCanPenetrate() {
      var action = this.$parent.$parent.$refs.pageAction && this.$parent.$parent.$refs.pageAction.curAction;

      if (action === 'addSection' || action === 'addMaskSection' || action === 'addCommentSection') {
        return true;
      }

      return false;
    }
  },
  methods: {
    onSectionClick: function onSectionClick() {
      if (!this.canEdit) return;
      this.$store.dispatch('book/section/selectSection', this.localId);
    },
    getImgUrl: function getImgUrl(_url) {
      return "".concat(process.env.VUE_APP_OSS_URL).concat(process.env.VUE_APP_OSS_PATH, "/").concat(_url);
    }
  }
};
exports.default = _default;