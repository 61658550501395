var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "external-link" },
    [
      _c("el-input", {
        staticClass: "common-bottom",
        attrs: { placeholder: "请输入链接名称...", clearable: "" },
        model: {
          value: _vm.valueProxy.linkTitle,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "linkTitle", $$v)
          },
          expression: "valueProxy.linkTitle"
        }
      }),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "common-bottom",
        attrs: {
          type: "textarea",
          placeholder: "请输入链接地址...",
          autosize: { minRows: 8, maxRows: 8 }
        },
        model: {
          value: _vm.valueProxy.linkHref,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "linkHref", $$v)
          },
          expression: "valueProxy.linkHref"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-flex" },
        [
          _c("el-checkbox", {
            model: {
              value: _vm.valueProxy.openInBrowser,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "openInBrowser", $$v)
              },
              expression: "valueProxy.openInBrowser"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "browser-text" }, [
            _vm._v("浏览器打开此链接（仅适用于APP中）")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }