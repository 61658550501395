var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard_container" },
    [
      _c("div", { staticClass: "zhongpan_box" }, [
        _c(
          "div",
          { staticClass: "top_box" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.showAddDialog(true)
                  }
                }
              },
              [_vm._v("新建中盘")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-input", {
                  staticStyle: { width: "184px" },
                  attrs: {
                    placeholder: "请输入中盘名称或手机号",
                    clearable: ""
                  },
                  model: {
                    value: _vm.params.name,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "name", $$v)
                    },
                    expression: "params.name"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.searchLoading },
                    on: { click: _vm.getData }
                  },
                  [_vm._v("搜索")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              height: "calc(100vh - 200px)",
              "margin-top": "15px",
              overflow: "auto"
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.searchLoading,
                    expression: "searchLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, loading: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "250", fixed: "", label: "中盘" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "userCover",
                                  staticStyle: { "margin-right": "8px" },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.partnerLogo,
                                      _vm.thumbnailStyle
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userCover",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "rgba(48, 49, 51, 1)",
                                    "font-size": "14px",
                                    "font-family": "SourceHanSansSC-regular"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(scope.row.storeName) +
                                      "\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "storeCount",
                    "min-width": "100",
                    align: "center",
                    label: "商户数"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productCount",
                    "min-width": "100",
                    align: "center",
                    label: "商品数"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "150",
                    align: "center",
                    prop: "orderCount",
                    label: "销售订单数"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "150",
                    align: "center",
                    label: "销售金额（元）"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.showVal(
                                    Number(scope.row.totalSales / 100).toFixed(
                                      2
                                    )
                                  )
                                )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "createdAt", label: "创建时间" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    width: "210",
                    fixed: "right",
                    label: "操作"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-edit",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jump(
                                    "OneClickStocking",
                                    scope.row.partnerOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("一键铺货")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-edit",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jump(
                                    "ProductManagement",
                                    scope.row.partnerOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("商品\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-edit",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jump(
                                    "StoreManagement",
                                    scope.row.partnerOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("店铺\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-edit",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jump(
                                    "AgencyDetail",
                                    scope.row.partnerOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("详情\n              ")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.addDialogVisible
        ? _c("zhong-pan-add-dialog", {
            attrs: {
              "dialog-visible": _vm.addDialogVisible,
              title: "中盘号",
              "is-add": _vm.isAdd,
              midCapOpenId: _vm.midCapOpenId
            },
            on: { zhongPanAddRoleDialogClose: _vm.dismissAddDialog }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }