"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/* eslint-disable no-unused-vars */
var partnerInfo = {
  state: {
    partnerOpenId: undefined
  },
  mutations: {
    SET_PARTNEROPENID: function SET_PARTNEROPENID(state, partnerOpenId) {
      state.partnerOpenId = partnerOpenId;
    }
  },
  actions: {
    set_partnerOpenId: function set_partnerOpenId(_ref, data) {
      var commit = _ref.commit;
      commit('SET_PARTNEROPENID', data);
    }
  }
};
var _default = partnerInfo;
exports.default = _default;