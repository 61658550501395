var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section-sequence-box",
      class: { "section-active-status": _vm.activeStatus }
    },
    [_c("div", [_vm._v(_vm._s(_vm.linkSection.SN))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }