"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _read = require("./read");

var _write = require("./write");

var _default = {
  get: _read.get,
  has: _read.has,
  increment: _read.increment,
  decrement: _read.decrement,
  remember: _read.remember,
  rememberForever: _read.rememberForever,
  rememberAsync: _read.rememberAsync,
  rememberForeverAsync: _read.rememberForeverAsync,
  pull: _read.pull,
  put: _write.put,
  add: _write.add,
  forever: _write.forever,
  forget: _write.forget,
  flush: _write.flush
};
exports.default = _default;