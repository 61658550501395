"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AnswerNotes',
  data: function data() {
    return {
      tableData: [{
        name: 789
      }, {
        name: 456
      }, {
        name: 789
      }, {
        name: 456
      }, {
        name: 789
      }, {
        name: 456
      }, {
        name: 789
      }, {
        name: 456
      }, {
        name: 789
      }, {
        name: 456
      }],
      currentPage4: 1
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange() {},
    handleCurrentChange: function handleCurrentChange() {}
  }
};
exports.default = _default;