"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _topStatistics = _interopRequireDefault(require("./topStatistics.vue"));

var _lineChart = _interopRequireDefault(require("./lineChart.vue"));

var _balanceAndModule = _interopRequireDefault(require("./balanceAndModule.vue"));

var _conversationList = _interopRequireDefault(require("./conversationList.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    TopStatistics: _topStatistics.default,
    LineChart: _lineChart.default,
    BalanceAndModule: _balanceAndModule.default,
    ConversationList: _conversationList.default
  },
  data: function data() {
    return {
      todayUserNum: 0,
      todayQuestionNum: 0,
      todayDate: 0
    };
  }
};
exports.default = _default;