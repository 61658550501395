var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-card" },
    [
      _c(
        "el-form",
        {
          ref: "announceForm",
          staticClass: "announce-form",
          attrs: {
            model: _vm.valueProxy,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _c("div", { staticClass: "edit-content" }, [
            _c("div", { staticClass: "announce-title" }, [_vm._v("公告信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-item-content" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "标题", prop: "title" } },
                  [
                    _c("el-input", {
                      staticClass: "title-input",
                      attrs: {
                        type: "text",
                        placeholder: "请填写公告标题",
                        maxlength: "45",
                        "show-word-limit": ""
                      },
                      model: {
                        value: _vm.value.title,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "title", $$v)
                        },
                        expression: "value.title"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "有效截至", prop: "expiredStartAt" } },
                  [
                    _c(
                      "div",
                      { staticClass: "announce-time" },
                      [
                        _c("el-date-picker", {
                          staticClass: "content-time",
                          attrs: {
                            type: "datetime",
                            placeholder: "选择公告开始日期时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "picker-options": _vm.myPickerOptions
                          },
                          model: {
                            value: _vm.value.expiredStartAt,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "expiredStartAt", $$v)
                            },
                            expression: "value.expiredStartAt"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "expired-text" }, [
                          _vm._v("至")
                        ]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticClass: "content-time",
                          attrs: {
                            type: "datetime",
                            placeholder: "选择公告失效日期时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "picker-options": _vm.myPickerOptions
                          },
                          model: {
                            value: _vm.value.expiredAt,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "expiredAt", $$v)
                            },
                            expression: "value.expiredAt"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-text" }, [
                          _c("i", { staticClass: "icon el-icon-info" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              "失效时间置为空则为永久有效，用户不会看到过期的公告"
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "摘要" } },
                  [
                    _c("el-input", {
                      staticClass: "title-input",
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 2, maxRows: 4 },
                        placeholder:
                          "请输入摘要内容，若空置，则将自动从正文中获取。",
                        maxlength: "200",
                        "show-word-limit": ""
                      },
                      model: {
                        value: _vm.value.summary,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "summary", $$v)
                        },
                        expression: "value.summary"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "公告正文", prop: "content" } },
                  [
                    _c("editor", {
                      model: {
                        value: _vm.value.content,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "content", $$v)
                        },
                        expression: "value.content"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "对应商户" } }, [
                  _c(
                    "div",
                    { staticClass: "partner" },
                    [
                      _c("search-user", {
                        attrs: { partnerOpenId: _vm.value.partnerOpenId },
                        on: { receivepartnerId: _vm.receivePartnerId }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "img-text" }, [
                        _c("i", { staticClass: "icon-partner el-icon-info" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text" }, [
                          _vm._v("默认为平台公告")
                        ])
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "公告平台" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "announce-product",
                        attrs: {
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "全部"
                        },
                        model: {
                          value: _vm.value.appProductOpenIds,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "appProductOpenIds", $$v)
                          },
                          expression: "value.appProductOpenIds"
                        }
                      },
                      _vm._l(_vm.platformOptions, function(item) {
                        return _c("el-option", {
                          key: item.openId,
                          attrs: { label: item.title, value: item.openId }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "跳转链接" } },
                  [
                    !_vm.value.linkSetting
                      ? _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.openSelect = true
                              }
                            }
                          },
                          [_vm._v("选择连接")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.value.linkSetting
                      ? _c("div", { staticClass: "link" }, [
                          _c("span", { staticClass: "link-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.value.linkSetting
                                  ? _vm.value.linkSetting.display.title
                                  : undefined
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", {
                              staticClass: "el-icon-sort change-link-icon",
                              on: {
                                click: function($event) {
                                  _vm.openSelect = true
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-delete link-icon",
                              on: { click: _vm.deleteLink }
                            })
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "曝光方式" } }, [
                  _c(
                    "div",
                    { staticClass: "common-border" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "common-padding",
                          attrs: {
                            label: "滚动通告栏",
                            "label-width": "100px",
                            prop: "scrollTimer"
                          }
                        },
                        [
                          _c("el-switch", {
                            staticClass: "scroll-switch",
                            attrs: { "active-color": "#13ce66" },
                            on: { change: _vm.handleScrollNotice },
                            model: {
                              value: _vm.value.scrollNotice,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "scrollNotice", $$v)
                              },
                              expression: "value.scrollNotice"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            attrs: {
                              disabled: !_vm.value.scrollNotice,
                              type: "datetimerange",
                              "default-time": ["00:00:00", "23:59:59"],
                              "range-separator": "至",
                              "validate-event": "",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.value.scrollTimer,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "scrollTimer", $$v)
                              },
                              expression: "value.scrollTimer"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "弹窗",
                            "label-width": "60px",
                            prop: "popUpTimer"
                          }
                        },
                        [
                          _c("el-switch", {
                            staticClass: "scroll-switch",
                            attrs: { "active-color": "#13ce66" },
                            on: { change: _vm.handlePopUps },
                            model: {
                              value: _vm.value.popUps,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "popUps", $$v)
                              },
                              expression: "value.popUps"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            attrs: {
                              disabled: !_vm.value.popUps,
                              type: "datetimerange",
                              "default-time": ["00:00:00", "23:59:59"],
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.value.popUpTimer,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "popUpTimer", $$v)
                              },
                              expression: "value.popUpTimer"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-footer" },
            [
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "success" },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("保存并发布")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.openSelect },
        on: {
          "update:visible": function($event) {
            _vm.openSelect = $event
          }
        },
        model: {
          value: _vm.value.linkSetting,
          callback: function($$v) {
            _vm.$set(_vm.value, "linkSetting", $$v)
          },
          expression: "value.linkSetting"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }