"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lotteryrecord = _interopRequireDefault(require("../lotteryrecord.vue"));

var _baseinfo = _interopRequireDefault(require("../baseinfo.vue"));

var _prizesetting = _interopRequireDefault(require("../prizesetting.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    LotteryRecord: _lotteryrecord.default,
    BaseInfo: _baseinfo.default,
    PrizeSetting: _prizesetting.default
  },
  data: function data() {
    return {
      luckyDrawOpenId: this.luckyDrawOpenId,
      isnew: true,
      activeName: '基础信息'
    };
  },
  created: function created() {
    if (this.$route.query.isnew) {
      this.isnew = this.$route.query.isnew;
    }

    if (this.$route.query.luckyDrawOpenId) {
      this.luckyDrawOpenId = this.$route.query.luckyDrawOpenId;
    }

    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
  },
  methods: {
    next: function next(luckyDrawOpenId) {
      this.$router.push({
        name: 'ActivityManage',
        query: {
          isnew: 'false',
          luckyDrawOpenId: luckyDrawOpenId,
          activeName: '奖品设置'
        }
      });
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
exports.default = _default;