var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticClass: "flows-static",
    attrs: {
      src:
        "https://grafana.taozhi.cn/d/cF9C_OTmk/00-tao-zhi-xue-tang-da-shu-ju-hui-zong-biao?orgId=1",
      scrolling: "auto",
      frameborder: "0"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }