"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.giftBookOrder = giftBookOrder;
exports.giftBookList = giftBookList;
exports.addGiftBookOrder = addGiftBookOrder;
exports.refreshExpress = refreshExpress;
exports.findGiftBookOrderByOne = findGiftBookOrderByOne;
exports.giftBookOrderlist = giftBookOrderlist;
exports.getWeChatDepartmentList = getWeChatDepartmentList;
exports.getWeChatUserListByDepartmentId = getWeChatUserListByDepartmentId;
exports.getExpressInfo = getExpressInfo;
exports.getAppletGetMessageTmpList = getAppletGetMessageTmpList;
exports.postAppletAddMessageTemplate = postAppletAddMessageTemplate;
exports.postAppletGetMessageTemplateList = postAppletGetMessageTemplateList;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/admin"));
var commonRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk"));
/**
 * 查询赠书list
 * @param {String} params
 * @returns {Promise<*>}
 */

function giftBookOrderlist(params) {
  return commonRequest.get('/giftBookOrder/bookList', {
    params: params
  });
}
/**
 * 查询赠书list
 * @param {String} params
 * @returns {Promise<*>}
 */


function giftBookOrder(params) {
  return commonRequest.get('/giftBookOrder/findGiftBookOrderPage', {
    params: params
  });
}
/**
 * 查询书籍list
 * @param {String} params
 * @returns {Promise<*>}
 */


function giftBookList(params) {
  return commonRequest.get('/giftBookList/giftBookList', {
    params: params
  });
}
/**
 * 查询单个赠书记录
 * @param {String} params
 * @returns {Promise<*>}
 */


function findGiftBookOrderByOne(params) {
  return commonRequest.get('/giftBookOrder/findGiftBookOrderByOne', {
    params: params
  });
}
/**
 * 添加赠书
 * @param {String} data
 * @returns {Promise<*>}
 */


function addGiftBookOrder(data) {
  return commonRequest.post('/giftBookOrder/addGiftBookOrder', data);
}
/**
 * 刷新赠书订单
 * @param {String} data
 * @returns {Promise<*>}
 */


function refreshExpress(params) {
  return commonRequest.post('/giftBookOrder/refreshExpress', undefined, {
    params: params
  });
}
/**
 * 获取企业微信部门树形列表
 * @returns {Promise<*>}
 */


function getWeChatDepartmentList() {
  return commonRequest.get('/enterpriseWeChat/getCorporateWeChatDepartmentTreeList');
}
/**
 * 根据部门ID获取部门成员列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWeChatUserListByDepartmentId(params) {
  return commonRequest.get('/enterpriseWeChat/getDepartmentMemberByDepartmentId', {
    params: params
  });
}
/**
 * 获取物流信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getExpressInfo(params) {
  return commonRequest.get('/admin/logistics/', {
    params: params
  });
}
/**
 * 获取消息模板
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAppletGetMessageTmpList(params) {
  return request.get('applet/getMessageTmpList', {
    params: params
  });
}
/**
 * 添加模板
 * @param {Object} params
 * @returns {Promise<*>}
 */


function postAppletAddMessageTemplate(params) {
  return request.post('applet/addMessageTemplate', undefined, {
    params: params
  });
}
/**
 * 获取个人模板
 * @param {Object} params
 * @returns {Promise<*>}
 */


function postAppletGetMessageTemplateList(params) {
  return request.post('applet/getMessageTemplateList', undefined, {
    params: params
  });
}