var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "operating-video" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "page-addvideo box"
      },
      [
        _c(
          "div",
          { staticClass: "form-static" },
          [
            _c(
              "el-form",
              {
                ref: "videoInfo",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.valueProxy,
                  rules: _vm.rules,
                  "status-icon": "",
                  "label-width": "100px"
                }
              },
              [
                _c("edit-base-info", {
                  ref: "ebi",
                  staticClass: "item",
                  attrs: {
                    "disabled-edit": _vm.disabledEdit,
                    "clear-validate": _vm.clearValidate,
                    "page-type": _vm.pageType
                  },
                  on: { "single-locked-need-update": _vm.updateSingle },
                  model: {
                    value: _vm.valueProxy,
                    callback: function($$v) {
                      _vm.valueProxy = $$v
                    },
                    expression: "valueProxy"
                  }
                }),
                _vm._v(" "),
                _c("edit-product-attributes", {
                  staticClass: "item",
                  attrs: {
                    "disabled-edit": _vm.disabledEdit,
                    "page-type": _vm.pageType,
                    "p-data-inited": _vm.isDataInited
                  },
                  on: { "single-locked-need-update": _vm.updateSingle },
                  model: {
                    value: _vm.valueProxy,
                    callback: function($$v) {
                      _vm.valueProxy = $$v
                    },
                    expression: "valueProxy"
                  }
                }),
                _vm._v(" "),
                _c("price-inventory", {
                  staticClass: "item",
                  attrs: {
                    "single-locked": _vm.singleLocked,
                    "disabled-edit": _vm.disabledEdit
                  },
                  model: {
                    value: _vm.valueProxy,
                    callback: function($$v) {
                      _vm.valueProxy = $$v
                    },
                    expression: "valueProxy"
                  }
                }),
                _vm._v(" "),
                _c("edit-propaganda", {
                  ref: "ppg",
                  staticClass: "item",
                  attrs: {
                    "single-locked": _vm.singleLocked,
                    "disabled-edit": _vm.disabledEdit
                  },
                  model: {
                    value: _vm.valueProxy,
                    callback: function($$v) {
                      _vm.valueProxy = $$v
                    },
                    expression: "valueProxy"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-fix" },
          [
            _c("el-button", { on: { click: _vm.oncancel } }, [_vm._v("取消")]),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "dialogs" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }