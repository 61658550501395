var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outerLayer" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "userForm",
              attrs: { model: _vm.userForm, "label-width": "80px" }
            },
            [
              _c("p", { staticClass: "smallTitle" }, [
                _c("span", { staticClass: "titleIcon" }),
                _vm._v("\n          基本信息\n        ")
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "formItem",
                  attrs: { label: "姓名", prop: "userName" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "请输入姓名",
                      maxlength: "15",
                      "show-word-limit": ""
                    },
                    on: { change: _vm.nameKeydown },
                    model: {
                      value: _vm.userForm.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "userName", $$v)
                      },
                      expression: "userForm.userName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "formItem",
                  attrs: { label: "手机号", prop: "phone" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "请输入手机号",
                      maxlength: "11",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.userForm.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.userForm, "phone", $$v)
                      },
                      expression: "userForm.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "formItem searchUser" },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("span", { staticStyle: { color: "#F56C6C" } }, [
                      _vm._v("*")
                    ]),
                    _vm._v("\n            帐号\n          ")
                  ]),
                  _vm._v(" "),
                  _c("searchUser", { on: { receiveuserid: _vm.receiveuserid } })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "smallTitle" }, [
                _c("span", { staticClass: "titleIcon" }),
                _vm._v("\n          权限设置\n        ")
              ]),
              _vm._v(" "),
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.treeData,
                  "expand-on-click-node": false,
                  props: _vm.defaultProps,
                  "default-expand-all": true,
                  "show-checkbox": "",
                  "node-key": "id"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c("span", { staticClass: "custom-tree-node" }, [
                        _c(
                          "span",
                          {
                            class:
                              data.parentOpenId && data.hidden
                                ? "paddingLetSty"
                                : "",
                            staticStyle: { "font-size": "14px" }
                          },
                          [_vm._v(_vm._s(node.label))]
                        )
                      ])
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "76px" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.goBack } },
            [_vm._v("\n        取消\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.submitForm }
            },
            [_vm._v("\n        保存\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h3", [_vm._v("添加协作者")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }