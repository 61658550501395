var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "teacher-resource-wrapper",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c(
        "div",
        { staticClass: "teacher-resource-header" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addResource } },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "resource-list-wrapper"
        },
        [
          _c(
            "div",
            { staticClass: "teacher-resource-list" },
            [
              _c(
                "el-table",
                {
                  ref: "dragTable",
                  attrs: { height: "100%", data: _vm.tableData }
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "80", label: "排序" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                ref: "popover-" + scope.row.resourceOpenId,
                                attrs: {
                                  "popper-class": "preview-popover",
                                  placement: "top-start",
                                  trigger: "click"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "sequence-num-bg" },
                                  [
                                    _c("el-input", {
                                      staticClass: "sequence-num",
                                      model: {
                                        value: scope.row.temporarySequence,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "temporarySequence",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "scope.row.temporarySequence"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "iconfont icon-xuanzhong",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeSequence(
                                            scope.row.famousTeacherOpenId,
                                            scope.row.resourceOpenId,
                                            scope.row.temporarySequence
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sequence-txt",
                                    attrs: { slot: "reference" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.selectTeacherResource(
                                          scope.row.id
                                        )
                                      }
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v(_vm._s(scope.row.sequence))]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作品" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "resource-title",
                                on: {
                                  click: function($event) {
                                    return _vm.getResourceDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.title))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "260", label: "类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("typeFilter")(scope.row.resourceTypeId)
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "130", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  loading:
                                    _vm.recommendBtn[scope.row.resourceOpenId]
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setRecommend(
                                      scope.row.famousTeacherOpenId,
                                      scope.row.resourceOpenId
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.recommend ? "取消推荐" : "推荐"
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "remove-btn",
                                attrs: {
                                  type: "text",
                                  loading:
                                    _vm.removeBtn[scope.row.resourceOpenId]
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.removeRow(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "page-wrapper",
            attrs: {
              total: _vm.total,
              limit: _vm.listQuery.size,
              "current-page": _vm.listQuery.page,
              page: _vm.listQuery.page
            },
            on: {
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "size", $event)
              },
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("select-resource-dialog", {
        attrs: {
          visible: _vm.resourceDialog,
          "exclude-data": _vm.tableData,
          showMerchant: true,
          expectType: "famousTeacherContent"
        },
        on: {
          "update:visible": function($event) {
            _vm.resourceDialog = $event
          },
          "add-select": _vm.onAddTeacherResource
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }