var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "browsingHisWrap" },
    [
      _c(
        "div",
        { staticClass: "tableDataSty" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableloading,
                  expression: "tableloading"
                }
              ],
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { property: "time", label: "日期", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.format(scope.row.time * 1000)) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "page_title", label: "标题", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.showVal(scope.row.http_url)))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    target: "_blank",
                                    href: scope.row.http_url
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.showVal(
                                          scope.row.page_title === "undefined"
                                            ? scope.row.system_name
                                            : scope.row.page_title
                                        )
                                      ) +
                                      "\n               "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "system_name",
                  label: "系统",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.showVal(scope.row.system_name)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "system_version_code",
                  label: "系统版本",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.showVal(scope.row.system_version_code)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "right", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.opendialogVisible(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "t-block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalElements
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "464px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("浏览记录详情")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { height: "350px", overflow: "auto" } },
            _vm._l(_vm.detailData, function(item, key) {
              return _c(
                "div",
                { key: key, staticStyle: { "line-height": "2" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(key) +
                      "：" +
                      _vm._s(item) +
                      "\n      "
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }