var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.resourceType === "question"
    ? _c("div", [
        _c("div", { staticClass: "file-upload-box" }, [
          _c(
            "div",
            { staticClass: "file-upload-bg" },
            [
              !_vm.uploadDisabled
                ? _c(
                    "svg-icon",
                    {
                      staticClass: "file-txt",
                      attrs: { "icon-class": "audio" }
                    },
                    [_vm._v("\n        点击上传\n      ")]
                  )
                : _c("el-progress", {
                    staticClass: "upload-progress",
                    attrs: {
                      percentage: _vm.authProgress,
                      width: 30,
                      type: "circle"
                    }
                  }),
              _vm._v(" "),
              _c("input", {
                ref: "uploadControl",
                staticClass: "file-upload",
                attrs: {
                  disabled: _vm.uploadDisabled,
                  accept: ".mp3,.wma",
                  type: "file"
                },
                on: {
                  change: function($event) {
                    return _vm.fileChange($event, "audio")
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    : _c("div", [
        _c("div", { staticClass: "file-upload-btn" }, [
          _c(
            "div",
            { staticClass: "file-upload-bg" },
            [
              !_vm.uploadDisabled
                ? _c(
                    "el-button",
                    {
                      staticClass: "file-txt",
                      attrs: { "icon-class": "audio" }
                    },
                    [_vm._v("\n        音频上传\n      ")]
                  )
                : _c("el-progress", {
                    staticClass: "upload-progress",
                    attrs: {
                      percentage: _vm.authProgress,
                      width: 30,
                      type: "circle"
                    }
                  }),
              _vm._v(" "),
              _c("input", {
                ref: "uploadControl",
                staticClass: "file-upload",
                attrs: {
                  disabled: _vm.uploadDisabled,
                  accept: ".mp3,.wma",
                  type: "file"
                },
                on: {
                  change: function($event) {
                    return _vm.fileChange($event, "audio")
                  }
                }
              })
            ],
            1
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }