var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "luckdrawactivityWrap" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            background: "#FFFFFF",
            padding: "10px 20px",
            "justify-content": "space-between"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "common-marign",
                  attrs: { type: "primary" },
                  on: { click: _vm.newAct }
                },
                [_vm._v("新建")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    display: "flex",
                    "flex-direction": "row"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.handleBtnCheck },
                      model: {
                        value: _vm.query.finish,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "finish", $$v)
                        },
                        expression: "query.finish"
                      }
                    },
                    _vm._l(_vm.button_options, function(item) {
                      return _c(
                        "el-radio-button",
                        {
                          key: item.label,
                          attrs: { value: item.value, label: item.value }
                        },
                        [_vm._v(_vm._s(item.label) + "\n            ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("el-input", {
                staticClass: "common-marign",
                staticStyle: { width: "200px" },
                attrs: { placeholder: "搜索活动名称" },
                model: {
                  value: _vm.query.luckyDrawName,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "luckyDrawName", $$v)
                  },
                  expression: "query.luckyDrawName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "common-marign",
                  staticStyle: { "margin-left": "2%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.getAllList }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "table-border"
        },
        [
          _vm.tableData.length
            ? _c(
                "div",
                { staticClass: "active-case" },
                _vm._l(_vm.tableData, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "active-block",
                      attrs: { id: item.id }
                    },
                    [
                      _c("el-image", {
                        staticClass: "img",
                        attrs: {
                          src: _vm.getImgUrl(
                            item.luckyDrawImage,
                            _vm.normalStyle
                          ),
                          alt: "",
                          fit: "cover"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.editAct(item.luckyDrawInfoOpenId)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "active-title" }, [
                        _vm._v(_vm._s(_vm.showVal(item.luckyDrawName)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "active-bottom" },
                        [
                          _c("span", { staticClass: "active-time" }, [
                            _vm._v(_vm._s(_vm.getDateDiff(item.createdAt)))
                          ]),
                          _vm._v(" "),
                          _vm.currentTime > item.endTi &&
                          item.beginTi < _vm.currentTime
                            ? _c(
                                "span",
                                { staticClass: "active-status status-over" },
                                [_vm._v("已结束")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.endTi > _vm.currentTime &&
                          item.beginTi < _vm.currentTime
                            ? _c(
                                "span",
                                { staticClass: "active-status status-doing" },
                                [_vm._v("进行中")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.beginTi > _vm.currentTime &&
                          item.endTi > _vm.currentTime
                            ? _c(
                                "span",
                                { staticClass: "active-status status-begin" },
                                [_vm._v("未开始")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                cursor: "pointer"
                              },
                              attrs: {
                                placement: "bottom-end",
                                width: "100",
                                trigger: "click"
                              }
                            },
                            [
                              _vm._l(_vm.strArr, function(itemc, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "pageTitleSty",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleJump(itemc, item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(itemc) +
                                        "\n                "
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              item.beginTi > _vm.currentTime &&
                              item.endTi > _vm.currentTime
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pageTitleSty",
                                      staticStyle: { color: "#FF4757" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleJump(
                                            "删除活动",
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  删除活动\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        "background-color": "#E9E9EB",
                                        "text-align": "center",
                                        "border-radius": "4px"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-more",
                                        staticStyle: {
                                          color: "#999999",
                                          "font-size": "12px"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _c("div", { staticClass: "nodata" }, [
                _c("span", [_vm._v("暂无活动")])
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { float: "right" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalSize
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }