"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _priceParser = require("@taozhi/price-parser");

var _PriceInput = _interopRequireDefault(require("./PriceInput.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @typedef {active: number, price: number, discount: number, priceReduction: number} TableData
 */
var _default2 = {
  name: 'ChangePriceTable',
  components: {
    ChangePriceInput: _PriceInput.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    } // 本价格选择结果

  },
  data: function data() {
    return {
      discountPrecision: _priceParser.config.discount.accuracy - 2
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  }
};
exports.default = _default2;