var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testPaperContainer" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              staticClass: "questionTree",
              attrs: { span: 4, "is-width-change": _vm.isChangeWidth }
            },
            [
              _c(
                "div",
                { staticClass: "elTabTop" },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { stretch: "" },
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "分类", name: "class" }
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "知识点", name: "points" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeName === "class"
                ? _c(
                    "div",
                    { staticClass: "classType" },
                    [_c("tree-list", { on: { nodeClick: _vm.nodeClick } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeName === "points"
                ? _c(
                    "div",
                    [
                      _c("question-points", {
                        attrs: { partnerOpenId: _vm.partnerOpenId },
                        on: {
                          points: _vm.points,
                          selectSubject: _vm.selectSubject
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("el-col", { staticClass: "table", attrs: { span: 20 } }, [
            _c(
              "div",
              { staticClass: "tableCon" },
              [
                _c("test-top", {
                  attrs: { "is-question": true, "need-function": "all" },
                  on: {
                    tableStyle: _vm.tableStyle,
                    newAddQuestion: _vm.newAddQuestion,
                    searchFun: _vm.searchFun
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tableList" },
                  [
                    _c("test-question-table", {
                      ref: "questionTable",
                      attrs: { "table-style": _vm.isTable }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.newQuestiondialogVisible
        ? _c("new-question", {
            ref: "newQuestion",
            attrs: {
              questionAdd: true,
              source: "questionAdd",
              clickNodeOpenId: _vm.clickNodeOpenId,
              merchantOpenId: _vm.merchantOpenId,
              "preview-dialog": _vm.newQuestiondialogVisible
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              "update:preview-dialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              directoryDialogChange: _vm.directoryDialogChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }