var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "demoruleForm",
          staticClass: "demo-ruleForm",
          staticStyle: { "margin-bottom": "100px" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c("div", { staticClass: "baseTitle" }, [_vm._v("基本选项")]),
          _vm._v(" "),
          _vm.ruleForm.luckyDrawPrizeOpenId
            ? _c("el-form-item", { attrs: { label: "奖项ID" } }, [
                _c("span", { staticStyle: { color: "#1f2d3d" } }, [
                  _vm._v(_vm._s(_vm.ruleForm.luckyDrawPrizeOpenId))
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { cursor: "pointer" } }, [
                  _c("i", {
                    staticClass: "el-icon-copy-document",
                    on: {
                      click: function($event) {
                        return _vm.copyOpenId(
                          _vm.ruleForm.luckyDrawPrizeOpenId,
                          $event
                        )
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖项名称", prop: "luckyDrawPrizeName" } },
            [
              _c("el-input", {
                staticStyle: { width: "600px" },
                attrs: { disabled: !_vm.ruleForm.allowEdit },
                model: {
                  value: _vm.ruleForm.luckyDrawPrizeName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "luckyDrawPrizeName", $$v)
                  },
                  expression: "ruleForm.luckyDrawPrizeName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品图片", prop: "prizeImage" } },
            [
              _c("cover-upload", {
                attrs: {
                  path: _vm.path,
                  texttips: _vm.texttipsDown,
                  coverarr: _vm.coverArr,
                  covertext: _vm.coverText,
                  type: "抽奖活动",
                  disabledEdit: !_vm.ruleForm.allowEdit
                },
                on: {
                  deletecover: function($event) {
                    return _vm.deleteCover(_vm.ruleForm)
                  }
                },
                model: {
                  value: _vm.ruleForm.prizeImage,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "prizeImage", $$v)
                  },
                  expression: "ruleForm.prizeImage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.ruleForm.allowEdit },
                  model: {
                    value: _vm.ruleForm.prizeType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "prizeType", $$v)
                    },
                    expression: "ruleForm.prizeType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "优惠券" } }, [
                    _vm._v("优惠券")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "实物" } }, [_vm._v("实物")])
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.prizeType === "优惠券"
                ? _c(
                    "div",
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          disabled: !_vm.ruleForm.allowEdit,
                          placeholder: "点击选择优惠券",
                          clearable: ""
                        },
                        on: { focus: _vm.opendiaprizeList },
                        model: {
                          value: _vm.ruleForm.couponName,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "couponName", $$v)
                          },
                          expression: "ruleForm.couponName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.prizeType === "实物"
                ? _c(
                    "div",
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          disabled: !_vm.ruleForm.allowEdit,
                          placeholder: "请输入实物名称",
                          clearable: ""
                        },
                        model: {
                          value: _vm.ruleForm.materialobjectName,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "materialobjectName", $$v)
                          },
                          expression: "ruleForm.materialobjectName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品介绍" } },
            [
              _c("editor-view", {
                ref: _vm.ruleForm.name,
                staticStyle: { width: "600px" },
                attrs: { readonly: !_vm.ruleForm.allowEdit },
                model: {
                  value: _vm.ruleForm.prizeIntroduce,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "prizeIntroduce", $$v)
                  },
                  expression: "ruleForm.prizeIntroduce"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品排序" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    disabled: !_vm.ruleForm.allowEdit,
                    size: "mini"
                  },
                  on: { change: _vm.changeSequence },
                  model: {
                    value: _vm.ruleForm.sequence,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "sequence", $$v)
                    },
                    expression: "ruleForm.sequence"
                  }
                },
                _vm._l(_vm.sequenceOptions, function(item) {
                  return _c("el-option", {
                    key: item,
                    attrs: {
                      disabled: _vm.handSenquence(item),
                      label: item,
                      value: item
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "0 5px" } }, [
                _c("i", {
                  staticClass: "iconInfo el-icon-info",
                  staticStyle: { color: "#409EFF" }
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#909399" } }, [
                  _vm._v("避免重复排序")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "baseTitle" }, [_vm._v("奖品设置")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "中奖概率", prop: "winProbability" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.ruleForm.allowEdit || _vm.winProbabilityDis,
                  precision: 0,
                  "controls-position": "right",
                  min: 0,
                  max: _vm.maxcurrntwinProbability,
                  size: "mini"
                },
                on: { change: _vm.changeWinProbability },
                model: {
                  value: _vm.ruleForm.winProbability,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "winProbability", $$v)
                  },
                  expression: "ruleForm.winProbability"
                }
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#909399" } }, [_vm._v("‱")]),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { color: "#909399", margin: "0 5px" } },
                [
                  _c("i", {
                    staticClass: "iconInfo el-icon-info",
                    staticStyle: { color: "#409EFF" }
                  }),
                  _vm._v("\n        总中奖概率不得大于10000 ‱\n      ")
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "首抽必中" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.ruleForm.allowEdit },
                  on: { change: _vm.handleMustPrize },
                  model: {
                    value: _vm.ruleForm.mustPrize,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "mustPrize", $$v)
                    },
                    expression: "ruleForm.mustPrize"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否安慰奖" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.ruleForm.allowEdit },
                  on: { change: _vm.handleConsolationPrize },
                  model: {
                    value: _vm.ruleForm.consolationPrize,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "consolationPrize", $$v)
                    },
                    expression: "ruleForm.consolationPrize"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖项数量" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.ruleForm.allowEdit },
                  model: {
                    value: _vm.ruleForm.prizeLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "prizeLimit", $$v)
                    },
                    expression: "ruleForm.prizeLimit"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        label: 1,
                        disabled: _vm.ruleForm.consolationPrize === 1
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          disabled:
                            !_vm.ruleForm.allowEdit ||
                            _vm.ruleForm.consolationPrize === 1,
                          size: "mini",
                          "controls-position": "right",
                          min: 1
                        },
                        model: {
                          value: _vm.ruleForm.prizeCount,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "prizeCount", $$v)
                          },
                          expression: "ruleForm.prizeCount"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不限量")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "每日中奖次数" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.ruleForm.allowEdit,
                  size: "mini",
                  "controls-position": "right",
                  min: 0
                },
                model: {
                  value: _vm.ruleForm.winDayCount,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "winDayCount", $$v)
                  },
                  expression: "ruleForm.winDayCount"
                }
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "0 5px" } }, [
                _c("i", {
                  staticClass: "iconInfo el-icon-info",
                  staticStyle: { color: "#409EFF" }
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#909399" } }, [
                  _vm._v("此活动中，同用户每日中将次数，0为不限制")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动中奖次数" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.ruleForm.allowEdit,
                  size: "mini",
                  "controls-position": "right",
                  min: 0
                },
                model: {
                  value: _vm.ruleForm.winActivityCount,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "winActivityCount", $$v)
                  },
                  expression: "ruleForm.winActivityCount"
                }
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "0 5px" } }, [
                _c("i", {
                  staticClass: "iconInfo el-icon-info",
                  staticStyle: { color: "#409EFF" }
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#909399" } }, [
                  _vm._v("此活动中，同用户总中奖次数，0为不限制")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "baseTitle" }, [_vm._v("奖品发放")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "发放类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.ruleForm.allowEdit },
                  model: {
                    value: _vm.ruleForm.prizeGrant,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "prizeGrant", $$v)
                    },
                    expression: "ruleForm.prizeGrant"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "线上发放" } }, [
                    _vm._v("线上发放")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "线下领取" } }, [
                    _vm._v("线下领取")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.prizeGrant === "线下领取"
            ? _c(
                "el-form-item",
                { attrs: { label: "领取二维码", prop: "customerServiceCode" } },
                [
                  _c("cover-upload", {
                    attrs: {
                      path: _vm.path,
                      disabledEdit: !_vm.ruleForm.allowEdit,
                      texttips: _vm.texttipsDowncustomer,
                      coverarr: _vm.coverArrcustomerServiceCode,
                      covertext: _vm.coverTextcustomerServiceCode,
                      type: "抽奖活动"
                    },
                    on: {
                      deletecover: function($event) {
                        return _vm.deleteCovercustomerServiceCode(_vm.ruleForm)
                      }
                    },
                    model: {
                      value: _vm.ruleForm.customerServiceCode,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "customerServiceCode", $$v)
                      },
                      expression: "ruleForm.customerServiceCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.ruleForm.luckyDrawPrizeOpenId
            ? _c(
                "div",
                { staticClass: "t-block" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm(_vm.ruleForm, "demoruleForm")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "t-block" },
                [
                  _vm.ruleForm.allowEdit
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.cacel(_vm.ruleForm)
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.ruleForm.allowEdit
                    ? _c(
                        "el-button",
                        {
                          attrs: { plan: "" },
                          on: {
                            click: function($event) {
                              return _vm.editBaseInfo(_vm.ruleForm)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm(
                                _vm.ruleForm,
                                "demoruleForm"
                              )
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "选择优惠券",
                visible: _vm.dialogVisible,
                width: "60%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-type",
                      attrs: { placeholder: "优惠券类型" },
                      on: { change: _vm.changegetCouponState },
                      model: {
                        value: _vm.query.discountType,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "discountType", $$v)
                        },
                        expression: "query.discountType"
                      }
                    },
                    _vm._l(_vm.discountTypeList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticClass: "search-txt",
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          placeholder: "请输入优惠券的名称或ID",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getCouponList($event)
                          }
                        },
                        model: {
                          value: _vm.query.keyword,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "keyword", $$v)
                          },
                          expression: "query.keyword"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.getCouponList }
                        },
                        [_vm._v("搜索")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableloading,
                          expression: "tableloading"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, height: "500px" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "优惠券名称",
                          width: "150"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "discountTypeName",
                          label: "类型",
                          width: "80"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "offerContent",
                          label: "优惠内容",
                          width: "auto"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "80", label: "状态" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: _vm.getCouponState(scope.row.status)
                                      .calssName
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCouponState(scope.row.status)
                                          .name
                                      )
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "300", label: "发放时间" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("parseTime")(
                                        scope.row.startTimeAt,
                                        "{y}-{m}-{d} {h}:{i}"
                                      )
                                    ) +
                                    " "
                                ),
                                scope.row.endTimeAt
                                  ? _c(
                                      "span",
                                      { staticStyle: { margin: "0 4px" } },
                                      [_vm._v("至")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      scope.row.endTimeAt,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) + "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "50",
                          fixed: "right",
                          align: "right"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addmustPrizeName(
                                          _vm.ruleForm,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50],
                      "page-size": _vm.query.size,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.totalElements
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }