"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQrUrl = getQrUrl;

var _qrcodeDecoder = _interopRequireDefault(require("qrcode-decoder"));

// 引入qrcode-decoder
// 获取临时路径
function getObjectURL(file) {
  var url = null;

  if (window.createObjectURL != undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }

  return url;
} // 传入file对象，返回promise


function getQrUrl(file) {
  // 获取临时路径
  var url = getObjectURL(file);
  console.log(url); // 初始化

  var qr = new _qrcodeDecoder.default(); // 解析二维码，返回promise

  return qr.decodeFromImage(url);
}