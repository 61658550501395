"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _amapJsapiLoader = _interopRequireDefault(require("@amap/amap-jsapi-loader"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
window._AMapSecurityConfig = {
  securityJsCode: '0b6b2b9e78ac0a210b851fc64799888e'
};
var _default = {
  name: 'MapView',
  props: {},
  data: function data() {
    return {
      address: '',
      addressList: [],
      location: ''
    };
  },
  watch: {},
  mounted: function mounted() {
    this.initMap();
  },
  methods: {
    initMap: function initMap() {
      var _this = this;

      this.suggest = new TMap.service.Suggestion({
        // 新建一个关键字输入提示类
        pageSize: 10 // 返回结果每页条目数

      }); // 定义map变量，调用 TMap.Map() 构造函数创建地图

      this.map = new TMap.Map(document.getElementById('container'), {
        zoom: 17.5,
        // 设置地图缩放级别
        pitch: 0,
        // 设置俯仰角
        rotation: 0 // 设置地图旋转角度

      }); // 根据当前ip获取当前定位

      var ipLocation = new TMap.service.IPLocation();
      ipLocation.locate({}).then(function (result) {
        _this.map.setCenter(new TMap.LatLng(result.result.location.lat, result.result.location.lng));
      });
      this.markers = new TMap.MultiMarker({
        map: this.map,
        geometries: []
      });
      this.map.on('click', function (evt) {
        var lat = evt.latLng.getLat().toFixed(6);
        var lng = evt.latLng.getLng().toFixed(6);

        _this.addMarker(lng, lat);
      });
    },
    searchAddress: function searchAddress(query) {
      var that = this;

      if (query) {
        this.suggest.getSuggestions({
          keyword: query,
          location: this.map.getCenter()
        }).then(function (result) {
          that.addressList = result.data;
        }).catch(function (error) {});
      }
    },
    selectItem: function selectItem(val) {
      var _this2 = this;

      this.addressList.forEach(function (item) {
        if (item.id === val) {
          _this2.addMarker(item.location.lng, item.location.lat);
        }
      });
    },
    addMarker: function addMarker(lng, lat) {
      if (lng || lat) {
        this.map.setCenter(new TMap.LatLng(lat, lng));
        this.markers.setGeometries([]);
        this.markers.updateGeometries([{
          id: 'marker',
          position: new TMap.LatLng(lat, lng)
        }]);
        this.location = "".concat(lng, ",").concat(lat);
      }
    },
    getLocation: function getLocation() {
      return this.location;
    }
  }
};
exports.default = _default;