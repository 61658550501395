var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "photo-wall-wrapper" },
    [
      _c("div", { staticClass: "photo-wall-tip" }, [
        _vm._v(_vm._s(_vm.atlasArrText))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "photo-wall-bg" },
        [
          _c(
            "div",
            { staticClass: "image-list-bg" },
            [
              _c(
                "draggable",
                {
                  staticClass: "image-draggable-bg",
                  attrs: { list: _vm.imageArr, disabled: _vm.disabledEdit },
                  on: { start: _vm.start, end: _vm.end }
                },
                _vm._l(_vm.imageArr, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "image-list-item",
                      style:
                        "width:" +
                        _vm.coverArr[0] +
                        "px;height:" +
                        _vm.coverArr[1] +
                        "px;"
                    },
                    [
                      _c("el-image", {
                        style:
                          "width:" +
                          _vm.coverArr[0] +
                          "px; height:" +
                          _vm.coverArr[1] +
                          "px; border-radius: 4px",
                        attrs: { fit: "cover", src: _vm.getImageUrl(item) }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "image-operation-box" }, [
                        _c("div", { staticClass: "operation-bg" }, [
                          _c("i", {
                            staticClass:
                              "btn-operation btn-preview el-icon-zoom-in",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.onPreview(item)
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.disabledEdit
                            ? _c("i", {
                                staticClass:
                                  "btn-operation iconfont icon-qiehuandanchuang",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.disabledEdit
                                      ? ""
                                      : _vm.replacePic(index)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.disabledEdit
                            ? _c("i", {
                                staticClass: "btn-operation el-icon-delete",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.disabledEdit ? "" : _vm.delPic(item)
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.disabledEdit
            ? _c("upload-image", {
                ref: "uploadFile",
                attrs: {
                  coverArr: _vm.coverArr,
                  disabledEdit: _vm.isDisableUpload,
                  resourceType: _vm.resourceType,
                  "oss-upload-function": _vm.ossUploadFunction,
                  path: _vm.path
                },
                on: {
                  handleGetImage: _vm.getImage,
                  handleGetReplaceImage: _vm.handleReplaceImage
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.previewUrl
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrl(_vm.previewUrl)]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }