var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "analysis" }, [
      _c("div", { staticClass: "analysisTop" }, [
        _c("span", { staticClass: "title" }, [_vm._v("单选题")]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "collesp",
            on: {
              click: function($event) {
                return _vm.isCollespIcon()
              }
            }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.isCollesp ? "收起" : "展开") + " "
            ),
            _c("i", {
              class: _vm.isCollesp ? "el-icon-arrow-up" : "el-icon-arrow-down"
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "onceTable",
          class: _vm.isCollesp ? "havaHeight" : "noHeight"
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { background: "#F5F7FA" },
                data: _vm.tableData,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "题号", width: "180" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "总作答次数", width: "180" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "错误次数" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "正确次数" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "错误率" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "正确率" }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "analysis" }, [
      _c("div", { staticClass: "analysisTop" }, [
        _c("span", { staticClass: "title" }, [_vm._v("组合题/完形填空")]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "collesp",
            on: {
              click: function($event) {
                return _vm.isCollespIcons()
              }
            }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.isCollesps ? "收起" : "展开") + " "
            ),
            _c("i", {
              class: _vm.isCollesps ? "el-icon-arrow-up" : "el-icon-arrow-down"
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "onceTable",
          class: _vm.isCollesps ? "havaHeight" : "noHeight"
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                "header-cell-style": { background: "#F5F7FA" },
                data: _vm.tableData,
                "span-method": _vm.objectSpanMethod,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "180" }
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "amount1", label: "数值 1（元）" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "amount2", label: "数值 2（元）" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "amount3", label: "数值 3（元）" }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }