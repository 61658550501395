var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "test-question-dialog",
      attrs: {
        title: "选择试卷",
        visible: _vm.dialogVisible,
        width: "1000px",
        "append-to-body": "",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "test-question-box" }, [
        _c("div", { staticClass: "box-select-conditions" }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("search", {
                staticClass: "fl",
                on: { receivepartnerId: _vm.receiveUserId }
              }),
              _vm._v(" "),
              _c("el-cascader", {
                staticStyle: { width: "120px", "margin-right": "9px" },
                attrs: {
                  size: "medium",
                  options: _vm.questionClass,
                  props: _vm.classProps,
                  placeholder: "请选择分类",
                  clearable: ""
                },
                model: {
                  value: _vm.query.categoryOpenId,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "categoryOpenId", $$v)
                  },
                  expression: "query.categoryOpenId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-box" },
            [
              _c("el-input", {
                staticStyle: { "margin-right": "9px" },
                attrs: { placeholder: "试卷名称搜索", size: "medium" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleBtnSearch($event)
                  }
                },
                model: {
                  value: _vm.query.name,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "name", $$v)
                  },
                  expression: "query.name"
                }
              }),
              _vm._v(" "),
              _c(
                "searchbtn",
                {
                  staticClass: "searchBtn",
                  on: { searchContent: _vm.searchContent }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "480px" } },
                    [
                      _c(
                        "el-form",
                        { attrs: { "label-width": "80px" } },
                        [
                          _c("el-form-item", { attrs: { label: "同步信息" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "127px" },
                                    attrs: {
                                      size: "small",
                                      clearable: "",
                                      placeholder: "年级"
                                    },
                                    model: {
                                      value: _vm.query.gradeCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.query, "gradeCode", $$v)
                                      },
                                      expression: "query.gradeCode"
                                    }
                                  },
                                  _vm._l(_vm.gradeList, function(item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "127px",
                                      "margin-left": "8px"
                                    },
                                    attrs: {
                                      size: "small",
                                      clearable: "",
                                      placeholder: "版本"
                                    },
                                    model: {
                                      value: _vm.query.editionCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.query, "editionCode", $$v)
                                      },
                                      expression: "query.editionCode"
                                    }
                                  },
                                  _vm._l(_vm.editionList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "127px",
                                      "margin-left": "8px"
                                    },
                                    attrs: {
                                      size: "small",
                                      clearable: "",
                                      placeholder: "学科"
                                    },
                                    model: {
                                      value: _vm.query.subjectCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.query, "subjectCode", $$v)
                                      },
                                      expression: "query.subjectCode"
                                    }
                                  },
                                  _vm._l(_vm.subjectList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "127px" },
                                    attrs: {
                                      size: "small",
                                      clearable: "",
                                      placeholder: "版次"
                                    },
                                    model: {
                                      value: _vm.query.periodCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.query, "periodCode", $$v)
                                      },
                                      expression: "query.periodCode"
                                    }
                                  },
                                  _vm._l(_vm.periodList, function(item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "sync-select",
                                        staticStyle: {
                                          width: "127px",
                                          "margin-left": "8px"
                                        },
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          placeholder: "阶段"
                                        },
                                        model: {
                                          value: _vm.query.volumeCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.query,
                                              "volumeCode",
                                              $$v
                                            )
                                          },
                                          expression: "query.volumeCode"
                                        }
                                      },
                                      _vm._l(_vm.syncVolume, function(item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "试卷类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择类型"
                                  },
                                  model: {
                                    value: _vm.query.paperType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "paperType", $$v)
                                    },
                                    expression: "query.paperType"
                                  }
                                },
                                _vm._l(_vm.paperTypeList, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "添加时间" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  align: "right",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "picker-options": _vm.pickerOptions,
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.query.searchTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "searchTime", $$v)
                                  },
                                  expression: "query.searchTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "remember-box" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "记住所选",
                        placement: "top"
                      }
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.rememberType,
                          callback: function($$v) {
                            _vm.rememberType = $$v
                          },
                          expression: "rememberType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "questionTable" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticClass: "select-paper-table",
                  attrs: {
                    width: "100%",
                    height: "100%",
                    data: _vm.paperList,
                    "tooltip-effect": "dark"
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "row-click": _vm.handleCheck
                  }
                },
                [
                  _vm.showType === "add"
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "30" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "left",
                      "min-width": "180",
                      label: "试卷",
                      prop: "name"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "worksSolt" },
                              [
                                _c("el-image", {
                                  staticClass: "paperCover",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImageUrl(scope.row.cover)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "soltRight" }, [
                                  _c("p", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(scope.row.name) +
                                        "\n                  "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "tags" }, [
                                    scope.row.categoryName
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.categoryName) +
                                              " /"
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(scope.row.paperType) +
                                        "\n                    "
                                    ),
                                    scope.row.paperMark
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      / 总分" +
                                              _vm._s(scope.row.paperMark) +
                                              "分\n                    "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.limitTime
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      / 限制" +
                                              _vm._s(scope.row.examTime * 60) +
                                              "分钟\n                    "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "paperType",
                      label: "类型",
                      "min-width": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "difficultDegree",
                      label: "创建时间",
                      "min-width": "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", { staticClass: "size" }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.createdAt
                                    ? scope.row.createdAt
                                    : "--"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm.showType === "replace"
                    ? _c("el-table-column", {
                        attrs: { label: "操作", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-add-album",
                                      attrs: {
                                        disabled: !_vm.selectable(scope.row),
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleReplace(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("替换")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          119307566
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableBottom" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("\n        取消\n      ")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.selectSubmit } },
                [_vm._v("\n        确定\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }