var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-lz-content upload-video",
      staticStyle: { width: "88%" }
    },
    [
      _c("div", { staticClass: "display-flex" }, [
        _c(
          "div",
          { staticClass: "display-flex" },
          [
            !_vm.value
              ? _c("input", {
                  ref: "uploadImg",
                  staticClass: "file-upload",
                  staticStyle: { width: "56px" },
                  attrs: {
                    id: _vm.type,
                    multiple: _vm.multipleSelect,
                    type: "file",
                    accept: "image/*"
                  },
                  on: { change: _vm.Upload }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.value
              ? _c(
                  "el-button",
                  { attrs: { type: "primary", size: "mini", plain: "" } },
                  [_vm._v("上传")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "file-upload-bg" },
              [
                _vm.hoverType ? _c("div", { staticClass: "modal" }) : _vm._e(),
                _vm._v(" "),
                _vm.hoverType
                  ? _c("el-image", {
                      staticClass: "image-have",
                      staticStyle: { width: "auto", height: "66px" },
                      attrs: {
                        src: require("../../assets/images/md-icon-test.svg")
                      },
                      on: { click: _vm.onPreview }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.hoverType
                  ? _c("i", {
                      staticClass: "el-icon-delete delete-have",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deletecover()
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "upload-video-box upload-image-box display-flex"
                  },
                  [
                    _vm.value
                      ? _c("img", {
                          staticClass: "cover",
                          attrs: {
                            onerror: _vm.errorUserPhoto,
                            src:
                              _vm.value.substring(0, 4) === "http"
                                ? _vm.value
                                : _vm.getImageUrl(_vm.value)
                          }
                        })
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            !_vm.hoverType && _vm.value
              ? _c(
                  "div",
                  { staticClass: "operation-box" },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { margin: "0 8px" },
                        attrs: { underline: false, type: "primary" },
                        on: { click: _vm.onPreview }
                      },
                      [_vm._v("查看")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-right": "8px" },
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.downloadImg(_vm.value)
                          }
                        }
                      },
                      [_vm._v("下载")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "danger" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.deletecover()
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.showPreview && _vm.value
        ? _c("el-image-viewer", {
            staticClass: "img-viewer",
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrl(_vm.value)]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }