"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calHight = calHight;

function calHight() {
  var winH = document.body.clientHeight;
  var data = {
    treeH: winH - 205 - 240,
    tabbleH: winH - 280
  }; // 表头高度48px

  return data;
}