"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ChangePricePrice',
  props: {
    active: {
      type: Number,
      default: 1
    },
    // 是否启用
    radioLabel: {
      type: Number,
      default: 1
    },
    // 本 radio 对应的 label
    value: {
      type: Number,
      default: 0
    },
    // 输入值
    min: {
      type: Number,
      default: 0
    },
    // 最小值
    max: {
      type: Number,
      default: undefined
    },
    // 最大值
    label: {
      type: String,
      default: '元'
    },
    // 右侧对应的标签，例如元、%
    precision: {
      type: Number,
      default: 2
    },
    // 小数点位数
    enable: {
      type: Boolean,
      default: true
    } // 是否已启用

  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    },
    activeProxy: {
      get: function get() {
        return this.active;
      },
      set: function set(val) {
        this.$emit('update:active', val);
      }
    },
    disabled: function disabled() {
      if (this.enable !== true) {
        return true;
      }

      return this.activeProxy !== this.radioLabel;
    }
  }
};
exports.default = _default;