var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "page-section-area",
    attrs: { draggable: "true" },
    on: { dragstart: _vm.onDragStart, dragend: _vm.onDragEnd }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }