var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-list-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "select-resource-dialog",
          attrs: {
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            width: "800px",
            visible: _vm.dialogShow,
            title: "角色库"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c("div", { staticClass: "dialog-flex" }, [
            _c("div", [
              _c("div", { staticClass: "dislog-content" }, [
                _c("div", { staticClass: "role-tools" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addRole }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-view" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "labek-select",
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.label,
                            callback: function($$v) {
                              _vm.label = $$v
                            },
                            expression: "label"
                          }
                        },
                        _vm._l(_vm.labelList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-view" },
                        [
                          _c("el-input", {
                            staticClass: "role-name",
                            attrs: { clearable: "", placeholder: "角色名搜索" },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchRole($event)
                              }
                            },
                            model: {
                              value: _vm.roleName,
                              callback: function($$v) {
                                _vm.roleName = $$v
                              },
                              expression: "roleName"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "search-btn",
                              attrs: { type: "primary" },
                              on: { click: _vm.searchRole }
                            },
                            [_vm._v("搜索")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.changeRemember },
                          model: {
                            value: _vm.searchRemeber,
                            callback: function($$v) {
                              _vm.searchRemeber = $$v
                            },
                            expression: "searchRemeber"
                          }
                        },
                        [_vm._v("记住")]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "role-content-table"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "role-content-title" },
                      [
                        _c(
                          "div",
                          { staticClass: "check-all" },
                          [
                            _vm.roleNumber > 1 && _vm.showCheckAll
                              ? _c("el-checkbox", {
                                  attrs: { indeterminate: _vm.isIndeterminate },
                                  on: { change: _vm.checkAllFun },
                                  model: {
                                    value: _vm.checkAll,
                                    callback: function($$v) {
                                      _vm.checkAll = $$v
                                    },
                                    expression: "checkAll"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.titleTab, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "title-tab" },
                            [_vm._v(_vm._s(item))]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "role-content",
                        attrs: { id: "roleContent" }
                      },
                      [
                        _vm.roleList.length === 0
                          ? _c("div", { staticClass: "content-null" }, [
                              _vm._v("暂无数据")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.roleList, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "role-item",
                              attrs: { id: "role" + index }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item-checkBox" },
                                [
                                  _c("el-checkbox", {
                                    attrs: { disabled: _vm.selectable(index) },
                                    on: {
                                      change: function($event) {
                                        return _vm.getCheckedState(
                                          $event,
                                          index
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.checked,
                                      callback: function($$v) {
                                        _vm.$set(item, "checked", $$v)
                                      },
                                      expression: "item.checked"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "item" }, [
                                item.cover
                                  ? _c("img", {
                                      staticClass: "role-cover",
                                      attrs: {
                                        src: _vm.getImageUrl(item.cover)
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.roleName) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.roleTransName) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.tags) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "remove-btn",
                                      staticStyle: { color: "#409EFF" },
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        disabled: item.checked
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeRole(item)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "remove-btn",
                                      staticStyle: {
                                        color: "rgb(245, 108, 108)"
                                      },
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        disabled: item.checked
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeRole(item.id, index)
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "flex-end",
                    "margin-top": "16px"
                  }
                },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.checkRoleFun }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "role-index" },
              _vm._l(_vm.letter, function(item) {
                return _c(
                  "div",
                  {
                    key: item,
                    class: _vm.letterActive === item ? "index active" : "index",
                    on: {
                      click: function($event) {
                        return _vm.letterSelect(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }