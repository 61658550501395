var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bjy-upload-bg" },
    [
      _vm.bvid !== 0 && _vm.isSetClass
        ? _c("div", { staticClass: "upload-img-bg" }, [
            _vm.videoCover !== null && _vm.videoCover.length > 0
              ? _c("img", {
                  staticClass: "video",
                  attrs: { src: _vm.getImageUrl(_vm.videoCover) }
                })
              : _c("img", {
                  staticClass: "video",
                  attrs: {
                    src:
                      "https://open.oss.taozhi.online/production/mp/defaultCoverVideo/default-cover.png"
                  }
                })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.bvid && _vm.isSetClass
        ? _c("div", { staticClass: "video-hover-bg" }, [
            _c("div", { staticClass: "video-operation-bg" }, [
              _c("i", {
                staticClass: "el-icon-video-play",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.videoPreviewFun()
                  }
                }
              }),
              _vm._v(" "),
              !_vm.disabledEdit
                ? _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.disabledEdit ? "" : _vm.deleteBjy()
                      }
                    }
                  })
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isUploading
        ? _c("div", { staticClass: "upload-video-bg" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.bvid === 0 && !_vm.isUploading
        ? _c("div", { staticClass: "upload-video-bg" }, [
            _c("input", {
              ref: "uploadControl",
              staticClass: "file-upload",
              attrs: {
                id: "uploadControl",
                disabled: _vm.uploadDisabled || _vm.disabledEdit,
                type: "file",
                accept: "video/*"
              },
              on: {
                change: function($event) {
                  return _vm.fileChange($event, "audio")
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "upload-video-icon" }, [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.uploadTxt))])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoPreview,
            "before-close": _vm.videoPreviewClose,
            title: "视频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoPreview = $event
            }
          }
        },
        [
          _c("div", {
            staticClass: "video-start",
            attrs: { id: "videoBrowse" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-result-bg" }, [
      _c("i", { staticClass: "el-icon-loading" }),
      _vm._v("\n      上传中...\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }