var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-info-group" },
    [
      _c("sync-info", {
        staticClass: "sync-select",
        attrs: {
          filterable: true,
          placeholder: "学段",
          disabled: _vm.disabledEdit,
          "data-source": _vm.getSyncSection,
          multiple: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.valueProxy.sectionCode,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "sectionCode", $$v)
          },
          expression: "valueProxy.sectionCode"
        }
      }),
      _vm._v(" "),
      _c("sync-info", {
        staticClass: "sync-select",
        attrs: {
          filterable: true,
          placeholder: "学科",
          disabled: _vm.disabledEdit,
          "data-source": _vm.getSyncSubject,
          multiple: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.valueProxy.subjectCode,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "subjectCode", $$v)
          },
          expression: "valueProxy.subjectCode"
        }
      }),
      _vm._v(" "),
      _c("sync-info", {
        staticClass: "sync-select",
        attrs: {
          filterable: true,
          placeholder: "版本",
          disabled: _vm.disabledEdit,
          "data-source": _vm.getSyncEdition,
          multiple: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.valueProxy.editionCode,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "editionCode", $$v)
          },
          expression: "valueProxy.editionCode"
        }
      }),
      _vm._v(" "),
      _c("sync-info", {
        staticClass: "sync-select",
        attrs: {
          filterable: true,
          placeholder: "年级",
          disabled: _vm.disabledEdit,
          "data-source": _vm.getSyncGrade,
          multiple: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.valueProxy.gradeCode,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "gradeCode", $$v)
          },
          expression: "valueProxy.gradeCode"
        }
      }),
      _vm._v(" "),
      _c("sync-info", {
        staticClass: "sync-select",
        attrs: {
          filterable: true,
          placeholder: "阶段",
          disabled: _vm.disabledEdit,
          "data-source": _vm.getSyncVolume,
          multiple: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.valueProxy.volumeCode,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "volumeCode", $$v)
          },
          expression: "valueProxy.volumeCode"
        }
      }),
      _vm._v(" "),
      _c("sync-info", {
        staticClass: "sync-select",
        attrs: {
          filterable: true,
          placeholder: "选必修",
          disabled: _vm.disabledEdit,
          "data-source": _vm.getSyncElective,
          query: { size: 9999 },
          multiple: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.valueProxy.electiveCode,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "electiveCode", $$v)
          },
          expression: "valueProxy.electiveCode"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }