var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "batch-add-verse-dialog",
      attrs: {
        title: "批量添加诗句",
        width: "500px",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          autosize: { minRows: 10 },
          placeholder:
            "请输入诗句，\n例如：\n鹅，鹅，鹅，曲项向天歌。\n白毛浮绿水，红掌拨清波。\n或\n鹅，鹅，鹅，\n曲项向天歌。\n白毛浮绿水，\n红掌拨清波。\n请根据需要选择成行形式\n"
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "tip" }, [
        _vm._v("注意：需要单独成行的诗句请用回车另起一行。")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }