"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _synchronize = require("../../utils/synchronize");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    value: {
      type: String,
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      options: []
    };
  },
  computed: {
    period: {
      get: function get() {
        if (this.value) {
          return this.value.toString();
        }

        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    (0, _synchronize.obtainSyncPeriod)(this).then(function (res) {
      _this.options = res;
    });
  }
};
exports.default = _default;