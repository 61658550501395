var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "line" },
    [
      _c(
        "el-checkbox",
        {
          staticClass: "line-enable",
          model: {
            value: _vm.valueProxy.enable,
            callback: function($$v) {
              _vm.$set(_vm.valueProxy, "enable", $$v)
            },
            expression: "valueProxy.enable"
          }
        },
        [_vm._v("\n    please hide me\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [
        _vm._v(_vm._s(_vm.valueProxy.title))
      ]),
      _vm._v(" "),
      _vm.valueProxy.type === 2
        ? _c("price-discount", {
            staticClass: "price-editor",
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        : _c("change-price-table", {
            staticClass: "price-editor",
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }