var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-content",
    { attrs: { label: "宣传材料", "full-width": true } },
    [
      _c(
        "el-form-item",
        {
          staticClass: "width-limit",
          attrs: { label: "商品封面", prop: "cover" }
        },
        [
          _c("cover-upload", {
            attrs: {
              path: _vm.path,
              disabledEdit: _vm.disabledEdit,
              coverarr: _vm.coverArr,
              covertext: _vm.coverText,
              type: "云零售商品",
              "view-style": "width-limit-128"
            },
            on: { deletecover: _vm.deletecoverFun },
            model: {
              value: _vm.valueProxy.cover,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "cover", $$v)
              },
              expression: "valueProxy.cover"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "width-limit",
          attrs: { label: "商品图集", prop: "imageList" }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.valueProxy.isSeriesAtlasJson,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "isSeriesAtlasJson", $$v)
                },
                expression: "valueProxy.isSeriesAtlasJson"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("新增内容")]),
              _vm._v(" "),
              _c(
                "el-radio",
                { attrs: { label: true, disabled: _vm.canImportFromSeries } },
                [_vm._v("引用系列内容")]
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.valueProxy.isSeriesAtlasJson
            ? _c("image-wall", {
                attrs: {
                  disabledEdit: _vm.disabledEdit,
                  coverArr: _vm.coverArr,
                  atlasArrText: _vm.atlasArr,
                  "oss-upload-function": _vm.ossUploadFunction,
                  path: _vm.atlasImagePath
                },
                model: {
                  value: _vm.valueProxy.atlasJson,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "atlasJson", $$v)
                  },
                  expression: "valueProxy.atlasJson"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("image-wall", {
                attrs: {
                  disabledEdit: true,
                  coverArr: _vm.coverArr,
                  atlasArrText: _vm.atlasArr,
                  "oss-upload-function": _vm.ossUploadFunction,
                  path: _vm.atlasImagePath
                },
                model: {
                  value: _vm.valueProxy.atlasJson,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "atlasJson", $$v)
                  },
                  expression: "valueProxy.atlasJson"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "width-limit",
          attrs: { label: "主图视频", prop: "showVideo" }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.valueProxy.isSeriesVideo,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "isSeriesVideo", $$v)
                },
                expression: "valueProxy.isSeriesVideo"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("新增内容")]),
              _vm._v(" "),
              _c(
                "el-radio",
                { attrs: { label: true, disabled: _vm.canImportFromSeries } },
                [_vm._v("引用系列内容")]
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.valueProxy.isSeriesVideo
            ? _c(
                "div",
                {
                  staticClass: "card-lz-content upload-video-main-image-video"
                },
                [
                  _c("video-uploader", {
                    ref: "uploadVideo",
                    attrs: {
                      disabledEdit: _vm.disabledEdit,
                      cover: _vm.valueProxy.showVideoCover
                    },
                    model: {
                      value: _vm.valueProxy.showVideo,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "showVideo", $$v)
                      },
                      expression: "valueProxy.showVideo"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "display-flex video-duration-text" },
                    [
                      _c("span", { staticClass: "video-text" }, [
                        _c("i", {
                          staticClass: "el-icon-info",
                          staticStyle: { color: "#409EFF" }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            建议时长 9-30 秒，建议视频宽高比 16:9\n          "
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("video-uploader", {
                ref: "uploadVideo",
                staticClass: "upload-video-main-image-video-only",
                attrs: {
                  disabledEdit: true,
                  cover: _vm.valueProxy.showVideoCover
                },
                model: {
                  value: _vm.valueProxy.showVideo,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "showVideo", $$v)
                  },
                  expression: "valueProxy.showVideo"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "width-limit",
          attrs: { label: "商品详情", prop: "detail" }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.valueProxy.isSeriesDetail,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "isSeriesDetail", $$v)
                },
                expression: "valueProxy.isSeriesDetail"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("新增内容")]),
              _vm._v(" "),
              _c(
                "el-radio",
                { attrs: { label: true, disabled: _vm.canImportFromSeries } },
                [_vm._v("引用系列内容")]
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.valueProxy.isSeriesDetail
            ? _c("rich-text-editor", {
                ref: "rtx",
                staticClass: "detial-editor",
                attrs: { readonly: _vm.disabledEdit },
                model: {
                  value: _vm.valueProxy.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "detail", $$v)
                  },
                  expression: "valueProxy.detail"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("rich-text-editor", {
                ref: "rtx",
                staticClass: "detial-editor",
                attrs: { readonly: true },
                model: {
                  value: _vm.valueProxy.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "detail", $$v)
                  },
                  expression: "valueProxy.detail"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }