var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "OperationlogWrap" },
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.handleBtnCheck },
                  model: {
                    value: _vm.query.ifCollect,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "ifCollect", $$v)
                    },
                    expression: "query.ifCollect"
                  }
                },
                _vm._l(_vm.button_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.label,
                      attrs: { value: item.value, label: item.value }
                    },
                    [_vm._v(_vm._s(item.label) + "\n        ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("searchUserByInput", {
                on: { receiveuserid: _vm.receiveuserid }
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "common-marign",
                staticStyle: { width: "200px", "margin-right": "15px" },
                attrs: { placeholder: "请输入中奖码或奖项ID" },
                model: {
                  value: _vm.query.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "keyword", $$v)
                  },
                  expression: "query.keyword"
                }
              }),
              _vm._v(" "),
              _c(
                "searchbtn",
                { on: { searchContent: _vm.searchContent } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.query, "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "中奖日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              type: "datetimerange",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              align: "right"
                            },
                            on: {
                              change: function($event) {
                                return _vm.handleDate(_vm.dateValue)
                              }
                            },
                            model: {
                              value: _vm.dateValue,
                              callback: function($$v) {
                                _vm.dateValue = $$v
                              },
                              expression: "dateValue"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否展示空奖" } },
                        [
                          _c("el-switch", {
                            staticStyle: { height: "36px", "line-height": "1" },
                            attrs: { clearable: "", "active-color": "#13ce66" },
                            on: { change: _vm.getifEmpty },
                            model: {
                              value: _vm.query.ifEmpty,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "ifEmpty", $$v)
                              },
                              expression: "query.ifEmpty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableDataSty" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableloading,
                  expression: "tableloading"
                }
              ],
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "createdAt", label: "中奖用户", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            scope.row.userCover
                              ? _c(
                                  "el-image",
                                  {
                                    staticClass: "userCover",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        scope.row.userCover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture-outline"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-width": "100px",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "-webkit-line-clamp": "1",
                                  "white-space": "nowrap"
                                }
                              },
                              [_vm._v(_vm._s(_vm.showVal(scope.row.userName)))]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "luckyDrawPrizeName",
                  label: "中奖商品",
                  width: "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "luckyDrawRecordOpenId",
                  label: "中奖码",
                  width: "250"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", width: "250" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createdAt", label: "中奖时间", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ifCollect", label: "领取状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.ifCollect === 1
                          ? _c("div", [_vm._v("已领取")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.ifCollect === 0
                          ? _c("div", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("未领取")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createdAt", label: "审核人", width: "auto" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            scope.row.reviewerCover
                              ? _c(
                                  "el-image",
                                  {
                                    staticClass: "userCover",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        scope.row.reviewerCover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture-outline"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.showVal(scope.row.reviewerName))
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "备注", width: "150" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "operationCategory",
                  label: "操作",
                  fixed: "right",
                  "header-align": "right",
                  align: "right",
                  width: "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openDialogUserdetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.beizhu(scope.row)
                              }
                            }
                          },
                          [_vm._v("备注")]
                        ),
                        _vm._v(" "),
                        scope.row.ifCollect === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  loading:
                                    _vm.setCollectloading &&
                                    _vm.luckyDrawRecordOpenId ===
                                      scope.row.luckyDrawRecordOpenId,
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setCollect(
                                      scope.row.luckyDrawRecordOpenId,
                                      1
                                    )
                                  }
                                }
                              },
                              [_vm._v("设为已领")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.ifCollect === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#F56C6C" },
                                attrs: {
                                  loading:
                                    _vm.setCollectloading &&
                                    _vm.luckyDrawRecordOpenId ===
                                      scope.row.luckyDrawRecordOpenId,
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setCollect(
                                      scope.row.luckyDrawRecordOpenId,
                                      0
                                    )
                                  }
                                }
                              },
                              [_vm._v("取消已领")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "备注",
                visible: _vm.dialogVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                { ref: "orderRecord", attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "remarks",
                        rules: [
                          {
                            required: true,
                            message: "备注不能为空",
                            trigger: "blur"
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请填写备注信息"
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "remarks",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.remarks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        loading: _vm.loadingdialogVisible
                      },
                      on: {
                        click: function($event) {
                          return _vm.remarksinfo("orderRecord")
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "t-block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalElements
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("user-info", {
        attrs: {
          visible: _vm.dialogUserdetail,
          propActiveName: _vm.propActiveName,
          userOpenId: _vm.currentuserOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogUserdetail = $event
          },
          clearuserOpenId: _vm.clearuserOpenId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }