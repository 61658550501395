"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAudioList = getAudioList;
exports.getAudioDetail = getAudioDetail;
exports.audioToDraft = audioToDraft;
exports.audioToDraftById = audioToDraftById;
exports.audioToReview = audioToReview;
exports.audioToReviewById = audioToReviewById;
exports.audioHidden = audioHidden;
exports.audioCancelHidden = audioCancelHidden;
exports.audioStopSale = audioStopSale;
exports.audioCancelStopSale = audioCancelStopSale;
exports.audioReview = audioReview;
exports.audioBatchReview = audioBatchReview;
exports.getAudioStatusCount = getAudioStatusCount;
exports.setAudioSequence = setAudioSequence;
exports.audioCancelSequence = audioCancelSequence;
exports.audioRecovery = audioRecovery;
exports.audioBatchRecovery = audioBatchRecovery;
exports.delAudio = delAudio;
exports.audioBatchSetAttribute = audioBatchSetAttribute;
exports.audioBatchIndependentSale = audioBatchIndependentSale;
exports.audioReviewPass = audioReviewPass;
exports.audioReject = audioReject;
exports.audioBatchRefuse = audioBatchRefuse;
exports.audioSavePublish = audioSavePublish;
exports.audioSaveReview = audioSaveReview;
exports.getAudioResourceList = getAudioResourceList;
exports.getAudioTitleList = getAudioTitleList;
exports.submitAudios = submitAudios;
exports.audioToSaveById = audioToSaveById;
exports.updateAudioEncode = updateAudioEncode;
exports.audioBatchRecycle = audioBatchRecycle;
exports.audioBatchRevoke = audioBatchRevoke;
exports.audioBatchHidden = audioBatchHidden;
exports.audioBatchCancelHidden = audioBatchCancelHidden;
exports.audioBatchStopSale = audioBatchStopSale;
exports.audioBatchCancelStopSale = audioBatchCancelStopSale;
exports.audioCopy = audioCopy;
exports.getAudioDownload = getAudioDownload;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audio/admin/"));
var requestClient = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audio/client/"));
var Request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/rich-text-management/admin/text"));
/**
 * 获取音频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getAudioList(params) {
  return request.get('/audio/request/list', {
    params: params
  });
}
/**
 * 获取音频列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioResourceList(params) {
  return request.get('/audio/order/list', {
    params: params
  });
}
/**
 * 获取音频详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getAudioDetail(openId) {
  return request.get("/audio/request/".concat(openId));
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioToDraft(data) {
  return request.post('/audio/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioToDraftById(openId, data) {
  return request.patch("/audio/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioToSaveById(openId, data) {
  return request.patch("/audio/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function audioToReview(data) {
  return request.post('/audio/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioToReviewById(openId, data) {
  return request.patch("/audio/request/".concat(openId, "/commit"), data);
}
/**
 * 音频隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioHidden(openId, params) {
  return request.post("/audio/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 音频取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioCancelHidden(openId, params) {
  return request.post("/audio/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 音频停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioStopSale(openId, params) {
  return request.post("/audio/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 音频取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioCancelStopSale(openId, params) {
  return request.post("/audio/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 音频-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function audioReview(openId) {
  return request.post("/audio/request/".concat(openId, "/commit"));
}
/**
 * 音频-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function audioBatchReview(openIds) {
  return request.post('/audio/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取不同状态下的音频数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioStatusCount(params) {
  return request.get('/audio/request/count', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setAudioSequence(openId, params) {
  return request.patch("/audio/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function audioCancelSequence(openId) {
  return request.patch("/audio/request/".concat(openId, "/cancelSequence"));
}
/**
 * 音频-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function audioRecovery(openId) {
  return request.post("/audio/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBatchRecovery(openIds) {
  return request.post('/audio/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBatchRecycle(openIds) {
  return request.post('/audio/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delAudio(openId) {
  return request.delete("/audio/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param data
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioBatchSetAttribute(data, params) {
  return request.patch('/audio/request/attribute', data, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioBatchIndependentSale(params) {
  return request.patch('/audio/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 音频-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function audioReviewPass(openId) {
  return request.post("/audio/request/".concat(openId, "/review"));
}
/**
 * 音频-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioSavePublish(data) {
  return request.post('/audio/request/review', data);
}
/**
 * 音频-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioSaveReview(openId, data) {
  return request.patch("/audio/request/".concat(openId, "/review"), data);
}
/**
 * 音频-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioReject(openId, params) {
  return request.post("/audio/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 音频-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioBatchRefuse(params) {
  return request.post('/audio/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioTitleList(params) {
  return request.get('/audio/request/repeat/title', {
    params: params
  });
}
/**
 * 批量提交审核
 * @param {Object} params
 * @returns {Promise<*>}
 */


function submitAudios(openIds) {
  return request.post('/audio/request/commits', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateAudioEncode(resourceOpenId, resourceEncode) {
  return request.patch("/audio/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBatchRevoke(openIds) {
  return request.post('audio/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBatchHidden(openIds) {
  return request.patch('/audio/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBatchCancelHidden(openIds) {
  return request.patch('/audio/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioBatchStopSale(params) {
  return request.patch('/audio/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBatchCancelStopSale(openIds) {
  return request.patch('/audio/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 复制资源
 * @param {Object} data
 * @returns {Promise<*>}
 */


function audioCopy(data) {
  return request.post('/audio/request/copy', data);
}
/**
 * 音频下载
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioDownload(params) {
  return requestClient.get('audio/url', {
    params: params
  });
}