"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addvideo = _interopRequireDefault(require("./addvideo"));

//
//
//
//
//
var _default = {
  name: 'Newvideo',
  components: {
    addvideo: _addvideo.default
  },
  mounted: function mounted() {},
  methods: {
    closeView: function closeView() {
      this.$store.dispatch('tagsView/updateCloseViewFormAnyWhere', 'ResourceVideo');
    }
  }
};
exports.default = _default;