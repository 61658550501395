var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-management-wrap" },
    [
      _vm.tableData
        ? _c(
            "div",
            { staticClass: "device-app-container app-container-list" },
            _vm._l(_vm.tableData, function(item, index) {
              return _c(
                "el-row",
                { key: index, attrs: { gutter: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "common-top margin-bottom" },
                    [
                      (item.productName && item.productName.includes("手机")) ||
                      (item.productName && item.productName.includes("APP"))
                        ? _c("svg-icon", {
                            staticStyle: { "margin-left": "3px" },
                            attrs: { "icon-class": "md-phone_iphone" }
                          })
                        : item.productName && item.productName.includes("Pad")
                        ? _c("svg-icon", {
                            staticStyle: { "margin-left": "3px" },
                            attrs: { "icon-class": "pad" }
                          })
                        : _c("svg-icon", {
                            staticStyle: { "margin-left": "3px" },
                            attrs: { "icon-class": "pc" }
                          }),
                      _vm._v(" "),
                      _c("span", { staticClass: "device-title" }, [
                        _vm._v(
                          _vm._s(
                            item.productName ? item.productName : "未知应用"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(item.deviceList, function(deviceItem, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticClass: "app-container-list-item",
                        attrs: { span: 6 }
                      },
                      [
                        _c("el-card", { attrs: { shadow: "hover" } }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "top" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(deviceItem.uuid) +
                                          "\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            cursor: "pointer",
                                            "margin-left": "15px"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            on: {
                                              click: function($event) {
                                                return _vm.copyOpenId(
                                                  deviceItem.uuid,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "device-management-name" },
                                    [
                                      deviceItem.model
                                        ? _c("span", [
                                            _vm._v(_vm._s(deviceItem.model))
                                          ])
                                        : deviceItem.osName
                                        ? _c("span", [
                                            _vm._v(_vm._s(deviceItem.osName))
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("filterLastLoginIp")(
                                                  deviceItem.lastLoginIp
                                                )
                                              )
                                            )
                                          ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "display" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      deviceItem.lastLoginTime.replace("T", " ")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.romoveDevice(deviceItem)
                                    }
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
            }),
            1
          )
        : _c("div", { staticClass: "noData" }, [_vm._v("\n    暂无数据\n  ")]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "deletedAddress",
          attrs: {
            "append-to-body": "",
            visible: _vm.deleteDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "移除用户设备",
            width: "352px"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialog = $event
            }
          }
        },
        [
          _c("span", [_vm._v("即将移除此设备。")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.deleteDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.deleteAddressHandle("ruleForm")
                    }
                  }
                },
                [_vm._v("移除")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }