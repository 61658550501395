var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "detailsofPresentationCon" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            title: "提现详情",
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "detailsofPresentationCon" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c("div", { staticClass: "commonSty" }, [
                    _c("div", { staticClass: "commonLineTitle" }, [
                      _c("div", { staticClass: "commonLine" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "commonTitle" }, [
                        _vm._v("基本信息")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "essentialInformationCon" }, [
                      _c("div", {}, [
                        _vm._v("流水编号：" + _vm._s(_vm.detailData.openId))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: " " }, [
                        _vm._v(
                          "提现时间：" +
                            _vm._s(_vm.detailData.withdrawRequestedAt)
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {}, [
                        _vm._v("提现人："),
                        _vm.detailData.user
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.detailData.user.nickName))
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: " " }, [
                        _vm._v("提现区间："),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.showVal(_vm.detailData.withdrawStartedAt)
                            ) + " "
                          ),
                          _c("span", { staticStyle: { margin: "0 10px" } }, [
                            _vm._v("~")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showVal(_vm.detailData.withdrawEndedAt)
                              )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.detailData.partner
                    ? _c("div", { staticClass: "commonSty commonWidth" }, [
                        _c("div", { staticClass: "commonLineTitle" }, [
                          _c("div", { staticClass: "commonLine" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "commonTitle" }, [
                            _vm._v("运营者信息")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "essentialInformationCon" }, [
                          _c("div", { staticClass: " " }, [
                            _vm._v(
                              "运营者姓名：" +
                                _vm._s(_vm.detailData.partner.operatorName)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: " " }, [
                            _vm._v(
                              "运营者手机号：" +
                                _vm._s(_vm.detailData.partner.operatorPhone)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", {}, [
                            _vm._v(
                              "运营者身份证号：" +
                                _vm._s(_vm.detailData.partner.operatorIdNumber)
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _vm.detailData.withdrawChannel === "微信"
                    ? _c("div", { staticClass: "commonSty" }, [
                        _c("div", { staticClass: "commonLineTitle" }, [
                          _c("div", { staticClass: "commonLine" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "commonTitle" }, [
                            _vm._v("提现账号")
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.detailData.finance
                          ? _c("div", { staticClass: "withdrawalsAccount" }, [
                              _c(
                                "div",
                                { staticClass: "withdrawalsAccountTit" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "提现渠道：" +
                                        _vm._s(_vm.detailData.withdrawChannel)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "commonFlex" },
                                    [
                                      _vm._v(
                                        "\n                  微信帐号：\n                  "
                                      ),
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "buyUserInfoStyChild",
                                          attrs: {
                                            src: _vm.getImgUrl(
                                              _vm.detailData.finance
                                                .withdrawWeChatCover,
                                              _vm.thumbnailStyle
                                            ),
                                            alt: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-picture-outline"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.detailData.finance
                                              .withdrawWeChatNickName
                                          ) +
                                          "\n                "
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.detailData.channelOrderId
                                    ? _c("div", [
                                        _vm._v(
                                          "微信交易流水编号：" +
                                            _vm._s(
                                              _vm.detailData.channelOrderId
                                            )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "姓名：" +
                                        _vm._s(
                                          _vm.detailData.finance.withdrawName
                                        )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "身份证号：" +
                                        _vm._s(
                                          _vm.detailData.finance
                                            .withdrawIdNumber
                                        )
                                    )
                                  ])
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailData.withdrawChannel === "银行卡"
                    ? _c("div", { staticClass: "commonSty" }, [
                        _c("div", { staticClass: "commonLineTitle" }, [
                          _c("div", { staticClass: "commonLine" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "commonTitle" }, [
                            _vm._v("提现账号")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "withdrawalsAccount" }, [
                          _c("div", { staticClass: "withdrawalsAccountTit" }, [
                            _c("div", [
                              _vm._v(
                                "提现渠道：" +
                                  _vm._s(_vm.detailData.withdrawChannel)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "commonFlex" }, [
                              _vm._v(
                                "银行卡号：" +
                                  _vm._s(_vm.detailData.withdrawBankCardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "银行户名：" +
                                  _vm._s(_vm.detailData.withdrawBankCardName)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "开户行：" +
                                  _vm._s(_vm.detailData.withdrawBankSubbranch)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.detailData.channelOrderId
                              ? _c("div", [
                                  _vm._v(
                                    "银行交易流水编号：" +
                                      _vm._s(_vm.detailData.channelOrderId)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "交易银行：" +
                                  _vm._s(_vm.detailData.withdrawBankName)
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "commonWidth" }, [
                    _vm.detailData.invoiceDeliveryMethod &&
                    _vm.detailData.partnerType === "企业"
                      ? _c("div", { staticClass: "commonSty" }, [
                          _c("div", { staticClass: "commonLineTitle" }, [
                            _c("div", { staticClass: "commonLine" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "commonTitle" }, [
                              _vm._v("发票信息")
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.detailData.invoiceDeliveryMethod == "1"
                            ? _c(
                                "div",
                                { staticClass: "invoiceInformation" },
                                [
                                  _c("div", {}, [_vm._v("提供发票：电子发票")]),
                                  _vm._v(" "),
                                  _vm._l(_vm.imgList, function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "invoiceDeliveryImg"
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticStyle: { color: "#909399" },
                                          attrs: {
                                            "icon-class": "md-insert_drive_file"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.substring(15)) +
                                            "\n                    "
                                        ),
                                        _c("svg-icon", {
                                          staticStyle: {
                                            color: "#909399",
                                            "font-size": "12px",
                                            cursor: "pointer"
                                          },
                                          attrs: {
                                            "icon-class": "downloadFile"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.downFile(item)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detailData.invoiceDeliveryMethod == "2"
                            ? _c("div", { staticClass: "invoiceInformation" }, [
                                _c("div", {}, [_vm._v("提供发票：邮寄发票")]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "快递公司：" +
                                        _vm._s(
                                          _vm.detailData.invoicePostCompanyName
                                        )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [_vm._v("快递单号：")]),
                                    _vm._v(
                                      _vm._s(
                                        _vm.detailData.invoiceTrackingNumber
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "commonLineTitle" }, [
                        _c("div", { staticClass: "commonLine" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "commonTitle" }, [
                          _vm._v("提现状态")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cashWithdrawalStatus" }, [
                        _c("div", [
                          _vm._v("提现状态：\n                  "),
                          _c(
                            "span",
                            {
                              class:
                                _vm.detailData.withdrawStatus === "10002" ||
                                _vm.detailData.withdrawStatus === "10005"
                                  ? "fontColor"
                                  : ""
                            },
                            [
                              _vm.detailData.withdrawStatus === "10002" ||
                              _vm.detailData.withdrawStatus === "10003"
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.detailData.checkedAt)
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10005" ||
                              _vm.detailData.withdrawStatus === "10005"
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.detailData.updatedAt)
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10001"
                                ? _c("span", [_vm._v("待审核")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10002"
                                ? _c("span", [_vm._v("审核驳回")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10003"
                                ? _c("span", [_vm._v("待汇款")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10004"
                                ? _c("span", [_vm._v("汇款成功")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10005"
                                ? _c("span", [_vm._v("汇款失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detailData.withdrawStatus === "10006"
                                ? _c("span", [_vm._v("待传发票")])
                                : _vm._e()
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.detailData.withdrawFailReason
                          ? _c("div", [
                              _vm._v(
                                "失败原因：" +
                                  _vm._s(_vm.detailData.withdrawFailReason)
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "commonSty amountMoney" }, [
                _c("div", [
                  _vm._v("实际提现金额：￥"),
                  _c("span", { staticClass: "amountMoneyNum" }, [
                    _vm._v(
                      _vm._s(
                        (_vm.detailData.withdrawBalance / 100).toFixed(2)
                      ) + "元"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "amountMoneyBottom" }, [
                  _vm._v(
                    "提现前余额：￥" +
                      _vm._s((_vm.detailData.balanceBefore / 100).toFixed(2)) +
                      "元 | 提现金额：￥" +
                      _vm._s(
                        (_vm.detailData.withdrawBalancePreTax / 100).toFixed(2)
                      ) +
                      "元 | 扣税金额：￥" +
                      _vm._s(
                        (_vm.detailData.personalIncomeTax / 100).toFixed(2)
                      ) +
                      "元"
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }