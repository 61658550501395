"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));

var _getters = _interopRequireDefault(require("./getters"));

var _labevue = _interopRequireDefault(require("./modules/labevue"));

var _syncData = _interopRequireDefault(require("./modules/syncData"));

var _videoBulkUpload = _interopRequireDefault(require("./modules/videoBulkUpload"));

var _dictionary = _interopRequireDefault(require("./modules/dictionary"));

var _partnerInfo = _interopRequireDefault(require("./modules/partnerInfo"));

var _adminManagement = _interopRequireDefault(require("./modules/adminManagement"));

var _workOrder = _interopRequireDefault(require("./modules/workOrder"));

var _bookLibrary = _interopRequireDefault(require("./modules/bookLibrary"));

var _product = _interopRequireDefault(require("./modules/product"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

_vue.default.use(_vuex.default); // https://webpack.js.org/guides/dependency-management/#requirecontext


var modulesFiles = require.context('./modules', false, /\.js$/); // you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file


var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  modules: _objectSpread(_objectSpread({}, modules), {}, {
    product: _product.default
  }),
  partnerInfo: _partnerInfo.default,
  getters: _getters.default,
  videoBulkUpload: _videoBulkUpload.default,
  labevue: _labevue.default,
  syncData: _syncData.default,
  dictionary: _dictionary.default,
  adminManagement: _adminManagement.default,
  workOrder: _workOrder.default,
  bookLibrary: _bookLibrary.default,
  plugins: [(0, _vuexPersistedstate.default)({
    storage: window.sessionStorage,
    reducer: function reducer(val) {
      return {
        labevue: val.labevue
      };
    }
  })]
});
var _default = store;
exports.default = _default;