var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detailData
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "wrapper"
        },
        [
          _c("div", { staticClass: "work-order-details" }, [
            _c(
              "div",
              { class: [_vm.isNotDialog ? "common-height" : "", "details"] },
              [
                _c("div", { staticClass: "nav" }, [
                  _c(
                    "div",
                    { staticClass: "common-padding-left divider-height" },
                    [
                      _c("span", { staticClass: "classification-name" }, [
                        _vm._v(
                          _vm._s(_vm.detailData.workorderClassificationName)
                        )
                      ]),
                      _vm._v(
                        "\n          工单：" +
                          _vm._s(_vm.detailData.workOrderOpenId) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "classification" }, [
                    _c("div", { staticClass: "common-padding-left version" }, [
                      _c("span", { staticClass: "app-product" }, [
                        _vm._v(
                          _vm._s(_vm.appProduction(_vm.detailData.appProductId))
                        )
                      ]),
                      _vm._v(" "),
                      _vm.detailData.versionName
                        ? _c("span", [
                            _vm._v("用户版本："),
                            _c(
                              "span",
                              {
                                style: {
                                  color:
                                    _vm.detailData.versionName !==
                                    _vm.detailData.latestVersionName
                                      ? "#F56C6C"
                                      : "#00a2ff",
                                  "margin-right": "10px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.detailData.versionName))]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailData.latestVersionName &&
                      _vm.detailData.appProductId !== _vm.webOpenId
                        ? _c("span", [
                            _vm._v("最新版本："),
                            _c("span", { staticStyle: { color: "#00a2ff" } }, [
                              _vm._v(_vm._s(_vm.detailData.latestVersionName))
                            ])
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tags" },
                      [
                        _vm.userOpenId === "usrdef20190927120546eowbfg"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "20px" },
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  loading: _vm.scrollLoading
                                },
                                on: { click: _vm.loadScroll }
                              },
                              [_vm._v("下一页")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailData.status !== 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "info" } },
                              [_vm._v("已处理")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "priority-tags" },
                          [
                            _vm.detailData.priority === "低"
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "success" } },
                                  [_vm._v("低")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detailData.priority === "中"
                              ? _c("el-tag", { attrs: { size: "mini" } }, [
                                  _vm._v("中")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detailData.priority === "高"
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "warning" } },
                                  [_vm._v("高")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detailData.priority === "急"
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "danger" } },
                                  [_vm._v("急")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-link", {
                          staticClass: "operation-icon",
                          attrs: { icon: "el-icon-tickets", underline: false },
                          on: { click: _vm.toOperate }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("el-divider", { staticClass: "divider" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.loadScroll,
                        expression: "loadScroll"
                      }
                    ],
                    staticClass: "details-content",
                    style: { height: _vm.styleContent },
                    attrs: {
                      "infinite-scroll-immediate": "false",
                      "infinite-scroll-disabled": "disabled",
                      id: "chatRecord"
                    }
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        { staticClass: "content-title" },
                        [
                          _c("user-image-and-name", {
                            attrs: {
                              userInfo: _vm.userData,
                              isShowTooltip: false
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-time" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("parseTime")(
                                    _vm.detailData.createdAt,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.detailData.workorderDescribe
                        ? _c("div", { staticClass: "content-problem" }, [
                            _c(
                              "div",
                              { staticClass: "common-background-color" },
                              [
                                _c("div", { staticClass: "common-status" }, [
                                  _vm._v("创建了工单")
                                ]),
                                _vm._v(" "),
                                _vm.detailData.linkSetting
                                  ? _c(
                                      "div",
                                      { staticClass: "describe-link" },
                                      [
                                        _vm._m(0),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "link-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detailData.linkSetting
                                                  ? _vm.detailData.linkSetting
                                                      .display.title
                                                  : "--"
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-copy copy-icon",
                                          on: {
                                            click: function($event) {
                                              return _vm.copy(
                                                _vm.detailData.linkSetting,
                                                $event
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-view copy-icon share-icon",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleLinkJump(
                                                _vm.detailData.linkSetting
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "detail-describe",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getEmoji(
                                        _vm.detailData.workorderDescribe
                                      )
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _vm.detailData.linkSetting
                                  ? _c("div", [
                                      _vm.getWordShorthand(
                                        _vm.detailData.linkSetting
                                      )
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "link-txt",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toBook(
                                                    _vm.detailData.linkSetting
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "bId：" +
                                                  _vm._s(
                                                    _vm.detailData.linkSetting
                                                      .query.bId
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.describeImages.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "image-list" },
                                      _vm._l(_vm.describeImages, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "images-item"
                                          },
                                          [
                                            _c("el-image", {
                                              staticClass: "describe-images",
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(item),
                                                "preview-src-list": _vm.getImg(
                                                  _vm.describeImages,
                                                  "style/width-limit-1000"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detailData.phone
                                  ? _c("div", { staticClass: "detail-phone" }, [
                                      _vm._v(
                                        "\n                联系电话: " +
                                          _vm._s(_vm.detailData.phone) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dialog-list" },
                      _vm._l(_vm.dialogList, function(item) {
                        return _c(
                          "div",
                          { key: item.id },
                          [
                            item.audioUrl
                              ? _c(
                                  "dialogue-item",
                                  {
                                    attrs: {
                                      "created-at": item.createdAt,
                                      "operate-user-info": item.operateUserInfo,
                                      "operate-user-open-id":
                                        item.operateUserOpenId,
                                      userData: _vm.userData,
                                      "select-user-open-id":
                                        _vm.detailData.selectUserOpenId
                                    }
                                  },
                                  [
                                    _c("dialogue-audio", {
                                      attrs: {
                                        "audio-url": item.audioUrl,
                                        uniqueindex: item.uniqueindex,
                                        "audio-duration": item.audioDuration,
                                        "operate-user-open-id":
                                          item.operateUserOpenId,
                                        "is-play": _vm.isPlay,
                                        "select-user-open-id":
                                          _vm.detailData.selectUserOpenId,
                                        "current-unique-index":
                                          _vm.currentUniqueIndex
                                      },
                                      on: { playAudio: _vm.playAudio }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.conversationContent ||
                            (item.imgUrls && item.imgUrls.length > 0)
                              ? _c(
                                  "dialogue-item",
                                  {
                                    attrs: {
                                      "created-at": item.createdAt,
                                      "operate-user-info": item.operateUserInfo,
                                      "operate-user-open-id":
                                        item.operateUserOpenId,
                                      userData: _vm.userData,
                                      "select-user-open-id":
                                        _vm.detailData.selectUserOpenId
                                    }
                                  },
                                  [
                                    _c("text-and-images", {
                                      attrs: {
                                        "operate-user-open-id":
                                          item.operateUserOpenId,
                                        "conversation-content":
                                          item.conversationContent,
                                        "img-urls": item.imgUrls,
                                        "select-user-open-id":
                                          _vm.detailData.selectUserOpenId
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.isShowLink
                              ? _c(
                                  "dialogue-item",
                                  {
                                    attrs: {
                                      "created-at": item.createdAt,
                                      "operate-user-info": item.operateUserInfo,
                                      "operate-user-open-id":
                                        item.operateUserOpenId,
                                      userData: _vm.userData,
                                      "select-user-open-id":
                                        _vm.detailData.selectUserOpenId
                                    }
                                  },
                                  [
                                    _c("dialogue-link", {
                                      attrs: {
                                        "link-data": item.linkSetting,
                                        "resource-info": item.resourceInfo,
                                        "is-select-user":
                                          _vm.detailData.selectUserOpenId ===
                                          item.operateUserOpenId
                                      },
                                      on: { handleLinkJump: _vm.handleLinkJump }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.isShowHandleBtn
                  ? _c("div", { staticClass: "details-passed" }, [
                      _vm.detailData.status === 1
                        ? _c(
                            "div",
                            { staticClass: "details-passed-reply" },
                            [
                              _c("public-reply", {
                                ref: "publicReply",
                                attrs: {
                                  "val-data": _vm.addData,
                                  "reply-list": _vm.replyList,
                                  "scroll-tatus": _vm.scrollStatusReply,
                                  "reply-total-elements":
                                    _vm.replyTotalElements,
                                  "submit-loading": _vm.resolvedLoading,
                                  "multiple-check": true,
                                  type: "工单"
                                },
                                on: {
                                  openLink: _vm.openLink,
                                  handleRefreshReply: _vm.handleRefreshReply,
                                  replyLoadScroll: _vm.replyLoadScroll,
                                  submit: _vm.submit
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", { staticClass: "end-order" }, [
                            _vm._v("工单已关闭")
                          ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: [_vm.isNotDialog ? "common-height" : "", "user"] },
              [
                _c(
                  "div",
                  { staticClass: "user-header" },
                  [
                    _c(
                      "div",
                      { staticClass: "user-padding-left divider-height" },
                      [_vm._v("用户信息")]
                    ),
                    _vm._v(" "),
                    _vm.userData
                      ? _c(
                          "div",
                          { staticClass: "user-info" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img",
                                on: {
                                  click: function($event) {
                                    return _vm.toUserDetails("基本信息")
                                  }
                                }
                              },
                              [
                                _vm.userData && _vm.userData.cover
                                  ? _c(
                                      "el-image",
                                      {
                                        staticClass: "user-img",
                                        attrs: {
                                          src: _vm.userData.cover,
                                          alt: "",
                                          fit: "cover"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "user-img",
                                              attrs: {
                                                src: require("@/assets/book_cover.png")
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : _c("img", {
                                      staticClass: "user-img",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    }),
                                _vm._v(" "),
                                _vm.userData && _vm.userData.vipUser
                                  ? _c("img", {
                                      staticClass: "vip-img",
                                      attrs: {
                                        src: require("@/assets/images/VIP.png"),
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.userData && _vm.userData.displayName
                              ? _c(
                                  "span",
                                  { staticClass: "user-name common-bottom" },
                                  [_vm._v(_vm._s(_vm.userData.displayName))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "text-id common-bottom" },
                              [_vm._v("ID: " + _vm._s(_vm.userData.openId))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "common-bottom",
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  round: "",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toUserDetails("基本信息")
                                  }
                                }
                              },
                              [_vm._v("查看更多")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userData
                      ? _c("el-divider", { staticClass: "divider" })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "user-scroll" },
                  [
                    _c(
                      "div",
                      { staticClass: "user-padding-left divider-height" },
                      [_vm._v("用户标签")]
                    ),
                    _vm._v(" "),
                    _vm.userData && _vm.userData.openId
                      ? _c(
                          "div",
                          { staticClass: "user-tags" },
                          [
                            _vm._l(_vm.userData.tagList, function(tagItem) {
                              return _c(
                                "div",
                                { key: tagItem, staticClass: "tags-item" },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      class: _vm.showClassName(
                                        _vm.userData.tagColorMap[tagItem]
                                      ),
                                      attrs: {
                                        size: "mini",
                                        type: _vm.showType(
                                          _vm.userData.tagColorMap[tagItem]
                                        ),
                                        closable: true
                                      },
                                      on: {
                                        close: function($event) {
                                          return _vm.deletedTag(tagItem)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(tagItem))]
                                  )
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                attrs: { placement: "bottom", trigger: "click" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                      width: "210px"
                                    }
                                  },
                                  _vm._l(_vm.userTagAllList, function(tagItem) {
                                    return _c(
                                      "div",
                                      {
                                        key: tagItem.id,
                                        staticStyle: {
                                          margin: "0 5px 5px 0",
                                          cursor: "pointer"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addUserTagApi(tagItem)
                                          }
                                        }
                                      },
                                      [
                                        _c("tags", {
                                          attrs: {
                                            tagColor: tagItem.tagColor,
                                            tagName: tagItem.tagName,
                                            className: tagItem.className,
                                            closable: false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "add-tags",
                                    attrs: { slot: "reference" },
                                    slot: "reference"
                                  },
                                  [_vm._v("添加标签")]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-divider", { staticClass: "divider" })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "history-order" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "user-padding-left divider-height history-flex"
                    },
                    [
                      _vm._v("历史工单\n          "),
                      _c("i", {
                        staticClass: "el-icon-tickets icon",
                        on: {
                          click: function($event) {
                            return _vm.toUserDetails("历史工单")
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.historyList.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.historyList, function(item) {
                          return _c("div", { key: item.id }, [
                            _c(
                              "div",
                              {
                                staticClass: "history-item",
                                on: {
                                  click: function($event) {
                                    return _vm.handleHistory(item)
                                  }
                                }
                              },
                              [
                                _c("div", {
                                  class: [
                                    "circular",
                                    item.status === 1 ? "active-circular" : ""
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "title",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getEmoji(item.workorderDescribe)
                                    )
                                  }
                                })
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.noData
                    ? _c("div", { staticClass: "no-history" }, [
                        _vm._v("暂无历史工单")
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.isShowHandleBtn
                  ? _c(
                      "div",
                      { staticClass: "user-footer" },
                      [
                        _vm.detailData.status === 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "footer-btn",
                                attrs: { type: "danger" },
                                on: { click: _vm.handleEndOrder }
                              },
                              [_vm._v("结束工单")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleRestart }
                              },
                              [_vm._v("重启工单")]
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _c("resource-share", {
            attrs: {
              "link-type": _vm.linkType,
              visible: _vm.openResourceShare,
              "share-obj": _vm.shareData
            },
            on: {
              "update:visible": function($event) {
                _vm.openResourceShare = $event
              }
            }
          }),
          _vm._v(" "),
          _c("operate", {
            attrs: {
              visible: _vm.operateDialog,
              "detail-data": _vm.detailsOperate
            },
            on: {
              "update:visible": function($event) {
                _vm.operateDialog = $event
              }
            }
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "history-details-dialog",
              attrs: {
                title: "工单详情",
                visible: _vm.openHistory,
                "append-to-body": "",
                "before-close": _vm.cancelDetailsDialog,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                width: "60%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.openHistory = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "history-details" },
                [
                  _c("work-order-details", {
                    attrs: {
                      "details-id": _vm.historyId,
                      contentHeight: "500px"
                    },
                    on: { toUserDetails: _vm.historyUserDetails }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("add-word-dialog", {
            attrs: {
              visible: _vm.openWord,
              "open-id": _vm.wordOpenId,
              disable: true
            },
            on: {
              "update:visible": function($event) {
                _vm.openWord = $event
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link-icon" }, [
      _c("i", { staticClass: "iconfont icon-gongdanlianjie icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }