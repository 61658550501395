var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c("div", { staticClass: "conversation" }, [
      _c("div", { staticClass: "title" }, [_vm._v("\n      问题内容\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "line-h" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.contentLoading,
              expression: "contentLoading"
            }
          ],
          staticClass: "conversation-box"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadDialogList,
                  expression: "loadDialogList"
                }
              ],
              staticClass: "conversation-content"
            },
            _vm._l(_vm.contentList, function(item, index) {
              return _c("div", { staticClass: "message" }, [
                item.operateUserOpenId === "1"
                  ? _c("div", { staticClass: "message-left" }, [
                      _c("div", { staticClass: "left-title" }, [
                        _c("img", {
                          staticClass: "user-cover",
                          attrs: { src: require("@/assets/robot.png") }
                        }),
                        _vm._v(" " + _vm._s(item.createdAt) + "\n            ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "left-content" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.conversationContent) +
                            "\n            "
                        )
                      ])
                    ])
                  : _c("div", { staticClass: "message-right" }, [
                      _c(
                        "div",
                        { staticClass: "right-title" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.createdAt) +
                              "\n              "
                          ),
                          _vm.userData && _vm.userData.cover
                            ? _c(
                                "el-image",
                                {
                                  staticClass: "user-cover",
                                  attrs: {
                                    src: _vm.getImgUrl(
                                      _vm.userData.cover,
                                      _vm.thumbnailStyle
                                    ),
                                    alt: "",
                                    fit: "cover"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "user-cover",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userimg",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "right-content",
                          on: {
                            click: function($event) {
                              return _vm.playAudio(item, index)
                            }
                          }
                        },
                        [
                          item.audioUrl
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-end",
                                      color: "#FFFFFF",
                                      "font-size": "14px",
                                      "align-items": "center",
                                      "margin-right": "-15px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.audioDuration) +
                                        "''\n                  "
                                    ),
                                    _c("div", { staticClass: "wifi-symbol" }, [
                                      _c("div", {
                                        staticClass: "wifi-circle first"
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "wifi-circle second",
                                        class: item.isPlay
                                          ? "wifi-circle second secondAnimation"
                                          : "wifi-circle second"
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "wifi-circle third",
                                        class: item.isPlay
                                          ? "wifi-circle third thirdAnimation"
                                          : "wifi-circle third"
                                      })
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("audio", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  attrs: {
                                    src: _vm.getAudioUrl(item.audioUrl),
                                    id: "audio" + item.id
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "line-h",
                                  staticStyle: {
                                    "margin-top": "5px",
                                    "margin-bottom": "5px"
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(item.conversationContent)
                            }
                          })
                        ]
                      )
                    ])
              ])
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "line-vertical" }),
    _vm._v(" "),
    _c("div", { staticClass: "user-info" }, [
      _c("div", { staticClass: "title" }, [_vm._v("\n      用户信息\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "line-h" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.userInfoLoading,
              expression: "userInfoLoading"
            }
          ],
          staticClass: "conversation-user"
        },
        [
          _c("div", { staticClass: "commonFleAc" }, [
            _c("div", { staticClass: "commonMarginBottom" }, [
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _vm.userData && _vm.userData.cover
                    ? _c(
                        "el-image",
                        {
                          staticClass: "userimg",
                          attrs: {
                            src: _vm.getImgUrl(
                              _vm.userData.cover,
                              _vm.thumbnailStyle
                            ),
                            alt: "",
                            fit: "cover"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("img", {
                                staticClass: "userimg",
                                attrs: {
                                  src: require("@/assets/book_cover.png")
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _c("img", {
                        staticClass: "userimg",
                        attrs: { src: require("@/assets/book_cover.png") }
                      }),
                  _vm._v(" "),
                  _vm.userData && _vm.userData.vipUser
                    ? _c("img", {
                        staticClass: "vipImg",
                        attrs: {
                          src: require("@/assets/images/VIP.png"),
                          alt: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commonMarginBottom" }, [
              _c("span", { staticClass: "nickName" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.isNull(_vm.userData && _vm.userData.nickName))
                  )
                ]),
                _vm._v(" "),
                _vm.userData && _vm.userData.level
                  ? _c(
                      "span",
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "right" }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", [
                                  _vm._v("用户层级"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("1级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("未消费；")
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("2级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("任意单次消费不满49元；")
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("3级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("任意单次消费超49元；")
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("4级用户"),
                                  _c("span", { staticClass: "colon" }, [
                                    _vm._v("：")
                                  ]),
                                  _vm._v("购买/续费VIP的用户；")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm.userData.level === 1
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv1.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userData.level === 2
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv2.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userData.level === 3
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv3.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userData.level === 4
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/user/lv4.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.userData.identity
              ? _c(
                  "div",
                  { staticClass: "commonMarginBottom" },
                  [
                    _vm.userData.identity === "教师"
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              size: "mini",
                              type: "success",
                              effect: "plain"
                            }
                          },
                          [_vm._v("认证教师")]
                        )
                      : _c(
                          "el-tag",
                          { attrs: { size: "mini", effect: "plain" } },
                          [_vm._v(_vm._s(_vm.userData.identity))]
                        )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.userData && _vm.userData.identity
              ? _c(
                  "div",
                  { staticClass: "commonMarginBottom" },
                  [
                    _vm.userData.identity === "教师"
                      ? _c(
                          "el-tag",
                          { attrs: { size: "mini", type: "success" } },
                          [_vm._v("认证教师")]
                        )
                      : _c("el-tag", { attrs: { size: "mini" } }, [
                          _vm._v(_vm._s(_vm.userData.identity))
                        ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "commonMarginBottom" }, [
              _c(
                "span",
                { staticStyle: { color: "#606266", "font-size": "14px" } },
                [
                  _vm._v("\n            ID\n            "),
                  _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.isNull(_vm.userData && _vm.userData.openId)) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "smallSize" }, [
                _vm._v("最近登录"),
                _c("span", { staticClass: "colon" }, [_vm._v("：")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.isNull(_vm.userData && _vm.userData.lastLoginTimeAt)
                    )
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }