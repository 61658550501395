var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container-mannger" }, [
    _c(
      "div",
      { staticClass: "dashboard-text" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function($event) {
                return _vm.addRoles()
              }
            }
          },
          [_vm._v("添加角色")]
        ),
        _vm._v(" "),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择系统" },
            on: { change: _vm.changeSelectedSystem },
            model: {
              value: _vm.subordinateSystemId,
              callback: function($$v) {
                _vm.subordinateSystemId = $$v
              },
              expression: "subordinateSystemId"
            }
          },
          _vm._l(_vm.subordinateSystemOptions, function(item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.title, value: item.value }
            })
          }),
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "multipleTable",
            staticClass: "tableData",
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, "tooltip-effect": "dark" }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "title", label: "角色", width: "120" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "name", label: "英文名", width: "120" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "appProductOpenId", label: "系统id", width: "300" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "sequence", label: "sequence", width: "120" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "address",
                align: "right",
                label: "操作",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.handleEdit(scope.$index, scope.row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      scope.row.deletedAt
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.restoreBtn,
                                  expression: "restoreBtn"
                                }
                              ],
                              staticStyle: { color: "#e6a23c" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleRestore(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            },
                            [_vm._v("恢复")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !scope.row.deletedAt
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { color: "#f56c6c" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.tableData.length
          ? _c(
              "div",
              { staticClass: "elPagination" },
              [
                _c("el-pagination", {
                  staticStyle: { float: "right" },
                  attrs: {
                    background: "",
                    "current-page": _vm.query.page,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _vm.query.size,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.totalElements
                  },
                  on: {
                    "update:currentPage": function($event) {
                      return _vm.$set(_vm.query, "page", $event)
                    },
                    "update:current-page": function($event) {
                      return _vm.$set(_vm.query, "page", $event)
                    },
                    "update:pageSize": function($event) {
                      return _vm.$set(_vm.query, "size", $event)
                    },
                    "update:page-size": function($event) {
                      return _vm.$set(_vm.query, "size", $event)
                    },
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }