import { render, staticRenderFns } from "./ModifyPricesInBatches.vue?vue&type=template&id=7c8ae038&scoped=true&"
import script from "./ModifyPricesInBatches.vue?vue&type=script&lang=js&"
export * from "./ModifyPricesInBatches.vue?vue&type=script&lang=js&"
import style0 from "./ModifyPricesInBatches.vue?vue&type=style&index=0&id=7c8ae038&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c8ae038",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c8ae038')) {
      api.createRecord('7c8ae038', component.options)
    } else {
      api.reload('7c8ae038', component.options)
    }
    module.hot.accept("./ModifyPricesInBatches.vue?vue&type=template&id=7c8ae038&scoped=true&", function () {
      api.rerender('7c8ae038', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProductMarketplace/General/BulkActionButton/ModifyPricesInBatches.vue"
export default component.exports