var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "common-problem" }, [
    _c("div", { staticClass: "nav" }, [
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              staticClass: "search-select",
              attrs: { placeholder: "全部", clearable: "" },
              on: { change: _vm.search },
              model: {
                value: _vm.searchSelect,
                callback: function($$v) {
                  _vm.searchSelect = $$v
                },
                expression: "searchSelect"
              }
            },
            _vm._l(_vm.searchCheckList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "search-select",
              attrs: { placeholder: "类型", clearable: "" },
              on: { change: _vm.search },
              model: {
                value: _vm.searchProblemClassificationId,
                callback: function($$v) {
                  _vm.searchProblemClassificationId = $$v
                },
                expression: "searchProblemClassificationId"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "infinite-scroll",
                      rawName: "v-infinite-scroll",
                      value: _vm.loadScroll,
                      expression: "loadScroll"
                    }
                  ],
                  staticStyle: { height: "200px", overflow: "auto" },
                  attrs: {
                    "infinite-scroll-immediate": "false",
                    "infinite-scroll-disabled": "disabled"
                  }
                },
                _vm._l(_vm.problemClassificationList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.classificationName, value: item.id }
                  })
                }),
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-search" },
        [
          _c("el-input", {
            staticClass: "search-input",
            attrs: { placeholder: "关键字搜索", clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.search($event)
              }
            },
            model: {
              value: _vm.searchProblemVal,
              callback: function($$v) {
                _vm.searchProblemVal = $$v
              },
              expression: "searchProblemVal"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.search } },
            [_vm._v("搜索")]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "problem-table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading"
              }
            ],
            ref: "singleTable",
            staticStyle: { width: "100%" },
            attrs: {
              height: "100%",
              data: _vm.problemList,
              "highlight-current-row": !_vm.multipleCheck,
              "row-class-name": _vm.tableRowClassName
            },
            on: { "selection-change": _vm.handleSelectionChange }
          },
          [
            _vm.multipleCheck
              ? _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "55",
                    selectable: _vm.selectable
                  }
                })
              : _c("el-table-column", {
                  attrs: { width: "30" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: scope.row.id },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.clickRadio(scope.row)
                                }
                              },
                              model: {
                                value: _vm.value.id,
                                callback: function($$v) {
                                  _vm.$set(_vm.value, "id", $$v)
                                },
                                expression: "value.id"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "radio-content-hidden" },
                                [_vm._v(_vm._s(scope.row.id))]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
            _vm._v(" "),
            _c("el-table-column", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "problem-title" }, [
                        _vm._v(_vm._s(scope.row.problemTitle))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "problem-detail" }, [
                        _vm._v(
                          _vm._s(
                            scope.row.problemDescribe
                              ? scope.row.problemDescribe
                              : scope.row.problemDetail
                          )
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("pagination", {
          attrs: {
            total: _vm.totalElements,
            page: _vm.query.page,
            limit: _vm.query.size,
            "current-page": _vm.query.page
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.query, "page", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.query, "size", $event)
            },
            pagination: _vm.getProblemListData
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }