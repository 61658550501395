var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "custom-class": "pop-up-dialog",
        title: "商品管理",
        visible: _vm.valueProxy,
        width: "92%",
        "before-close": _vm.handleClose,
        "lock-scroll": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "shop-all-product-manage-content" },
        [
          _c("product-category-tree", {
            ref: "videoclass",
            staticClass: "classification",
            attrs: {
              routname: _vm.routname,
              type: _vm.resourceType,
              "middle-open-id": _vm.middleOpenId,
              "shop-open-id": _vm.shopOpenId
            },
            on: { "check-event": _vm.checkidevent }
          }),
          _vm._v(" "),
          _vm.valueProxy
            ? _c("product-list", {
                ref: "list",
                staticClass: "product-list",
                attrs: {
                  "middle-open-id": _vm.middleOpenId,
                  "shop-open-id": _vm.shopOpenId,
                  "group-ids": _vm.groupIds,
                  "second-group-ids": _vm.secondGroupIds
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }