var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "baseInfo" }, [
    _c(
      "div",
      { staticStyle: { margin: "10px", padding: "10px 10px 0" } },
      [
        _c(
          "div",
          { staticStyle: { "background-color": "#F5F7FA", padding: "20px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  color: "#101010",
                  "margin-right": "10px",
                  "background-color": "#F5F7FA"
                }
              },
              [_vm._v("请设置活动需要派发的奖项")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  disabled: _vm.adddisabled
                },
                on: {
                  click: function($event) {
                    return _vm.addTab(_vm.editableTabsValue)
                  }
                }
              },
              [_vm._v("添加奖项")]
            ),
            _vm._v(" "),
            _c("span", { staticStyle: { color: "#909399" } }, [
              _c("i", {
                staticClass: "iconInfo el-icon-info",
                staticStyle: { color: "#409EFF" }
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("剩余概率：")
              ]),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#409EFF" } }, [
                _vm._v(_vm._s(10000 - _vm.currntwinProbability1))
              ]),
              _vm._v(" ‱\n     ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-tabs",
          {
            attrs: { closable: _vm.closableDis },
            on: { "tab-remove": _vm.removeTab, "tab-click": _vm.tabClick },
            model: {
              value: _vm.editableTabsValue,
              callback: function($$v) {
                _vm.editableTabsValue = $$v
              },
              expression: "editableTabsValue"
            }
          },
          _vm._l(_vm.forQuee, function(ruleForm) {
            return _c(
              "el-tab-pane",
              {
                key: ruleForm.name,
                staticStyle: { height: "561px", overflow: "auto" },
                attrs: {
                  label: ruleForm.luckyDrawPrizeName,
                  name: ruleForm.name
                }
              },
              [
                _vm.editableTabsValue === ruleForm.name
                  ? _c("form-data", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      ref: "tabValue",
                      refInFor: true,
                      attrs: {
                        currentSequence: _vm.currentSequence,
                        currntwinProbability: _vm.currntwinProbability,
                        luckyDrawPrizeOpenId: _vm.luckyDrawPrizeOpenId,
                        luckyDrawOpenId: _vm.luckyDrawOpenId,
                        ruleForm: ruleForm
                      },
                      on: {
                        changeSequence: _vm.changecurrentSequence,
                        changeWinProbability: _vm.changeWinProbability
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }