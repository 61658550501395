var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        disabled: _vm.isDisabled,
        filterable: "",
        clearable: "",
        "allow-create": "",
        "default-first-option": "",
        placeholder: _vm.placeholder,
        multiple: _vm.multiple
      },
      model: {
        value: _vm.valueProxy,
        callback: function($$v) {
          _vm.valueProxy = $$v
        },
        expression: "valueProxy"
      }
    },
    _vm._l(_vm.tagOptions, function(item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }