var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-filter-panel" },
    [
      _c(
        "el-form",
        {
          attrs: {
            id: "dialog-filter-panel-form",
            model: _vm.valueProxy,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "filter-sync-info", attrs: { label: "同步信息" } },
            [
              _c("select-param-sync-info", {
                attrs: { "query-option": "FindXDList", placeholder: "学段" },
                model: {
                  value: _vm.valueProxy.xd,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "xd", $$v)
                  },
                  expression: "valueProxy.xd"
                }
              }),
              _vm._v(" "),
              _c("select-param-sync-info", {
                attrs: {
                  "query-option": "FindSubjectList",
                  placeholder: "学科"
                },
                model: {
                  value: _vm.valueProxy.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "subject", $$v)
                  },
                  expression: "valueProxy.subject"
                }
              }),
              _vm._v(" "),
              _c("select-param-sync-info", {
                attrs: { "query-option": "FindBBList", placeholder: "版本" },
                model: {
                  value: _vm.valueProxy.bb,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "bb", $$v)
                  },
                  expression: "valueProxy.bb"
                }
              }),
              _vm._v(" "),
              _c("select-param-sync-info", {
                attrs: { "query-option": "FindGradeList", placeholder: "年级" },
                model: {
                  value: _vm.valueProxy.grade,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "grade", $$v)
                  },
                  expression: "valueProxy.grade"
                }
              }),
              _vm._v(" "),
              _c("select-param-sync-info", {
                attrs: { "query-option": "FindJDList", placeholder: "阶段" },
                model: {
                  value: _vm.valueProxy.jd,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "jd", $$v)
                  },
                  expression: "valueProxy.jd"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "版次信息" } },
            [
              _c("select-param-sync-info", {
                staticClass: "syn-order",
                attrs: { "query-option": "FindAll", placeholder: "版次" },
                model: {
                  value: _vm.valueProxy.bc,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "bc", $$v)
                  },
                  expression: "valueProxy.bc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "地区信息" } },
            [
              _c("area-option", {
                staticClass: "common-select-width",
                attrs: { placeholder: "地区" },
                model: {
                  value: _vm.valueProxy.areaId,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "areaId", $$v)
                  },
                  expression: "valueProxy.areaId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "类目" } },
            [
              _c("category-view", {
                staticClass: "syn-category",
                attrs: {
                  valueCategory: [
                    _vm.valueProxy.regimentationIds
                      ? _vm.valueProxy.regimentationIds * 1
                      : undefined,
                    _vm.valueProxy.secondRegimentationIds
                      ? _vm.valueProxy.secondRegimentationIds * 1
                      : undefined
                  ]
                },
                on: { "category-change": _vm.categoryChange },
                model: {
                  value: _vm.valueProxy.secondRegimentationIds,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "secondRegimentationIds", $$v)
                  },
                  expression: "valueProxy.secondRegimentationIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间" } },
            [
              _c("el-date-picker", {
                staticClass: "form-date",
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  align: "left",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.startDate,
                  callback: function($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "发布时间" } },
            [
              _c("el-date-picker", {
                staticClass: "form-date",
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  align: "left",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "显示状态" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.valueProxy.isShow,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "isShow", $$v)
                    },
                    expression: "valueProxy.isShow"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("全部")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("显示")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("隐藏")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "售卖状态" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.valueProxy.isSale,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "isSale", $$v)
                    },
                    expression: "valueProxy.isSale"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("全部")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("售卖")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("停售")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序条件" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "syn-order",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.valueProxy.orderBy,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "orderBy", $$v)
                    },
                    expression: "valueProxy.orderBy"
                  }
                },
                _vm._l(_vm.orderOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序方式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.valueProxy.orderMode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "orderMode", $$v)
                    },
                    expression: "valueProxy.orderMode"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "asc" } }, [
                    _vm._v("正序")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "desc" } }, [
                    _vm._v("倒序")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }