var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "live-state-box" }, [
      _vm.resourceObj.pushLive === 1
        ? _c("span", { staticClass: "tag-live tag-live-1" }, [
            _vm._v("推流直播")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.resourceObj.pushLive === 2
        ? _c("span", { staticClass: "tag-live tag-live-2" }, [_vm._v("伪直播")])
        : _vm._e(),
      _vm._v(" "),
      _vm.resourceObj.pushLive === 0
        ? _c("span", { staticClass: "tag-live" }, [_vm._v("常规直播")])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowFinishClassText(_vm.resourceObj)
        ? _c("span", [
            _vm._v("直播中: "),
            _c(
              "span",
              {
                staticClass: "btn-finish",
                on: {
                  click: function($event) {
                    return _vm.handlerFinishClass(_vm.resourceObj)
                  }
                }
              },
              [_vm._v("下课")]
            )
          ])
        : _vm.getLiveObj(_vm.resourceObj).value === 2 &&
          _vm.resourceObj.countDown
        ? _c("span", { staticClass: "live-timer" }, [
            _vm._v(_vm._s(_vm.resourceObj.countDown) + " ")
          ])
        : _c("span", { staticClass: "live-timer" }, [
            _vm._v(
              "直播时间：" +
                _vm._s(
                  _vm._f("parseTime")(
                    _vm.resourceObj.startTime,
                    "{y}-{m}-{d} {h}:{i}"
                  )
                ) +
                " "
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }