var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-detail-wrapper" },
    [
      _c("div", { staticClass: "promotion-user-box" }, [
        _c("div", { staticClass: "user-title-box" }, [
          _c("div", { staticClass: "user-title" }, [_vm._v("推广员信息")]),
          _vm._v(" "),
          _vm.userInfo.isDeleted === 0
            ? _c(
                "div",
                { staticClass: "user-title-con" },
                [
                  _c("span", { staticClass: "user-date" }, [
                    _vm._v(_vm._s(_vm.userInfo.passedAt))
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "success", plain: "", disabled: true } },
                    [_vm._v("正常")]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "user-title-con" },
                [
                  _c("span", { staticClass: "user-date" }, [
                    _vm._v(_vm._s(_vm.userInfo.updatedAt))
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "danger", plain: "", disabled: true } },
                    [_vm._v("已禁用")]
                  )
                ],
                1
              )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "user-info-top" },
          [
            _c("el-image", {
              staticClass: "user-cover",
              attrs: {
                onerror: _vm.errorUserPhoto,
                src: _vm.getImgUrl(_vm.userInfo.cover, _vm.thumbnailStyle),
                alt: ""
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "user-info-id" }, [
              _c("div", { staticClass: "user-info-name" }, [
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.userInfo.nickName))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-promotion-bg" }, [
                _vm.userInfo.userOpenId && _vm.userInfo.invitationCode
                  ? _c("span", { staticClass: "normal-font" }, [
                      _vm._v(
                        "\n           用户ID：" +
                          _vm._s(_vm.userInfo.userOpenId) +
                          " | 推广码：" +
                          _vm._s(_vm.userInfo.invitationCode) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-base-box" }, [
          _c("div", { staticClass: "user-base-l" }, [
            _c("div", { staticClass: "user-base-info" }, [
              _c(
                "div",
                { staticClass: "user-name-bg" },
                [
                  _c("span", { staticClass: "txt-name" }, [
                    _vm._v("真实姓名：" + _vm._s(_vm.userInfo.realName))
                  ]),
                  _vm._v(" "),
                  _vm.userInfo.applyType === 2
                    ? _c("el-tag", { attrs: { size: "small" } }, [
                        _vm._v("后台添加")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.userInfo.applyType === 1
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", size: "small" } },
                        [_vm._v("自主申请")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "user-txt-bg" }, [
                _vm._v(
                  "\n            电话：" +
                    _vm._s(_vm.showVal(_vm.userInfo.phone)) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-txt-bg" }, [
                _vm._v(
                  "\n            加入时间：" +
                    _vm._s(
                      _vm._f("parseTime")(
                        _vm.userInfo.createdAt ? _vm.userInfo.createdAt : "",
                        "{y}-{m}-{d} {h}:{i}"
                      )
                    ) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _vm.userInfo.parentOpenId
                ? _c(
                    "div",
                    { staticClass: "user-parent-bg" },
                    [
                      _vm._v("\n            上级推广人：\n            "),
                      _c("el-image", {
                        staticClass: "user-parent-cover",
                        attrs: {
                          onerror: _vm.errorUserPhoto,
                          src: _vm.getImgUrl(
                            _vm.userInfo.parentCover,
                            _vm.thumbnailStyle
                          ),
                          fit: "cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "txt-parent-name" }, [
                        _vm._v(_vm._s(_vm.userInfo.parentName))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "txt-parentOpenId" }, [
                        _vm._v(_vm._s(_vm.userInfo.parentOpenId))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "user-txt-bg" }, [
                _vm._v(
                  "\n            下级推广员：" +
                    _vm._s(
                      _vm.userInfo.lowerCount ? _vm.userInfo.lowerCount : 0
                    ) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-txt-bg" }, [
                _vm._v(
                  "\n            累计分享次数：" +
                    _vm._s(_vm.userInfo.invitationNumber) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-txt-bg" }, [
                _vm._v(
                  "\n            累计客户量：" +
                    _vm._s(_vm.userInfo.customerNumber) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-txt-bg" },
                [
                  _vm._v(
                    "\n            累计佣金：￥" +
                      _vm._s(
                        _vm.numFormat((_vm.userInfo.total / 100).toFixed(2))
                      ) +
                      "元\n            "
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-blue",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.toLookCommissionRecord(
                            _vm.userInfo.userOpenId
                          )
                        }
                      }
                    },
                    [_vm._v("佣金记录")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-txt-bg" },
                [
                  _vm._v(
                    "\n            佣金余额：￥" +
                      _vm._s(
                        _vm.numFormat((_vm.userInfo.balance / 100).toFixed(2))
                      ) +
                      "元\n            "
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-blue",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.toLookCommissionBalance(
                            _vm.userInfo.userOpenId
                          )
                        }
                      }
                    },
                    [_vm._v("余额记录")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "user-txt-bg" }, [
                _vm._v("\n            银行卡：\n            "),
                _vm.userInfo.bankCard
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.userInfo.bankName) +
                          " " +
                          _vm._s(_vm.userInfo.bankCard)
                      )
                    ])
                  : _c("span", [_vm._v("未绑定")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-base-r" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.handlerEditPromter }
                },
                [_vm._v("编辑")]
              ),
              _vm._v(" "),
              _vm.userInfo.isDeleted === 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", plain: "" },
                      on: { click: _vm.startPromoter }
                    },
                    [_vm._v("激活")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "" },
                      on: { click: _vm.disabledPromoter }
                    },
                    [_vm._v("禁用")]
                  )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promotion-user-box" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.checkData },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "客户列表", name: "first" } },
                [
                  _vm.activeName === "first"
                    ? _c("div", { staticClass: "list-table-box2" }, [
                        _c(
                          "div",
                          { staticClass: "coupon-table-top2" },
                          [
                            _c("el-input", {
                              staticClass: "search-txt",
                              attrs: { placeholder: "搜索昵称", clearable: "" },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.customerSearch($event)
                                }
                              },
                              model: {
                                value: _vm.customerQuery.buyUserName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.customerQuery,
                                    "buyUserName",
                                    $$v
                                  )
                                },
                                expression: "customerQuery.buyUserName"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.customerSearch }
                              },
                              [_vm._v("搜索")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "coupon-table-con2" },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.tableDataLoading,
                                    expression: "tableDataLoading"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.customerList }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "用户昵称"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "user-info-bg" },
                                              [
                                                _c("el-image", {
                                                  staticClass: "user-cover-bg",
                                                  attrs: {
                                                    onerror: _vm.errorUserPhoto,
                                                    src: _vm.getImgUrl(
                                                      scope.row.buyUserCover,
                                                      _vm.thumbnailStyle
                                                    ),
                                                    fit: "cover"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "user-info-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.buyUserName
                                                          ? scope.row
                                                              .buyUserName
                                                          : "--"
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3028146742
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.consumeNumber
                                                      ? scope.row.consumeNumber
                                                      : "--"
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      641977880
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "header" },
                                      [
                                        _c("span", [_vm._v("累计消费笔数")]),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "title-tip",
                                            attrs: {
                                              effect: "dark",
                                              content: "不含退款订单数",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                  ￥" +
                                                  _vm._s(
                                                    _vm.numFormat(
                                                      (
                                                        scope.row.consumeTotal /
                                                        100
                                                      ).toFixed(2)
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1083125357
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "header" },
                                      [
                                        _c("span", [_vm._v("累计消费金额")]),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "title-tip",
                                            attrs: {
                                              effect: "dark",
                                              content: "不含退款消费金额",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                  ￥" +
                                                  _vm._s(
                                                    _vm.numFormat(
                                                      (
                                                        scope.row
                                                          .commissionTotal / 100
                                                      ).toFixed(2)
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      862628844
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "header" },
                                      [
                                        _c("span", [_vm._v("赚取佣金")]),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "title-tip",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                "该客户累计给该推广员赚取的佣金",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "最新绑定时间" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("parseTime")(
                                                    scope.row.createdAt,
                                                    "{y}-{m}-{d} {h}:{i}"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    942162192
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "客户状态" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("getStateTitle")(
                                                    scope.row.status,
                                                    _vm.stateList
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1048270664
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "有效（天）数" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.validityTime === -1
                                                    ? "永久"
                                                    : scope.row.validityTime
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2087131460
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "100", label: "操作" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.lookUserRecord(
                                                      scope.row.buyUserOpenId
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("购买记录")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2332942138
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-page-box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          total: _vm.totalElements,
                          "pager-count": 5,
                          "current-page": _vm.customerQuery.page,
                          "page-size": _vm.customerQuery.size,
                          "page-sizes": _vm.pageSizes,
                          layout: "total, sizes, prev, pager, next, jumper",
                          background: ""
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.currentchange
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "邀请推广员列表", name: "second" } },
                [
                  _vm.activeName === "second"
                    ? _c("div", { staticClass: "list-table-box2" }, [
                        _c(
                          "div",
                          { staticClass: "coupon-table-top2" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.inviteQuery.promotionSettingCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.inviteQuery,
                                      "promotionSettingCode",
                                      $$v
                                    )
                                  },
                                  expression: "inviteQuery.promotionSettingCode"
                                }
                              },
                              _vm._l(_vm.levelSelectList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.title,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "search-txt",
                              attrs: {
                                placeholder: "搜索关键词",
                                clearable: ""
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.inviteSearch($event)
                                }
                              },
                              model: {
                                value: _vm.inviteQuery.keyword,
                                callback: function($$v) {
                                  _vm.$set(_vm.inviteQuery, "keyword", $$v)
                                },
                                expression: "inviteQuery.keyword"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.inviteSearch }
                              },
                              [_vm._v("搜索")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "coupon-table-con2" },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.inviteListLoading,
                                    expression: "inviteListLoading"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.inviteList }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "用户昵称"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "user-info-bg" },
                                              [
                                                _c("el-image", {
                                                  staticClass: "user-cover-bg",
                                                  attrs: {
                                                    onerror: _vm.errorUserPhoto,
                                                    src: _vm.getImgUrl(
                                                      scope.row.cover,
                                                      _vm.thumbnailStyle
                                                    ),
                                                    fit: "cover"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "user-info-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.nickName)
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2062612132
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "级别" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.promotionSettingName
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1412143071
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "姓名" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.row.realName) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    56657984
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "手机号" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.row.phone) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1986807265
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "累计客户量" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.customerNumber
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1273124826
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "累计邀请量" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.invitationNumber
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1509274383
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "赚取佣金" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  ￥" +
                                                _vm._s(
                                                  _vm.numFormat(
                                                    (
                                                      scope.row
                                                        .commissionTotal / 100
                                                    ).toFixed(2)
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    862628844
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "140", label: "加入时间" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("parseTime")(
                                                    scope.row.createdAt,
                                                    "{y}-{m}-{d} {h}:{i}"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    942162192
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "second"
                    ? _c(
                        "div",
                        { staticClass: "table-page-box" },
                        [
                          _c("el-pagination", {
                            attrs: {
                              total: _vm.totalElements,
                              "pager-count": 5,
                              "current-page": _vm.inviteQuery.page,
                              "page-size": _vm.inviteQuery.size,
                              "page-sizes": _vm.pageSizes,
                              layout: "total, sizes, prev, pager, next, jumper",
                              background: ""
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.currentchange
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "推广商品", name: "third" } },
                [
                  _c("div", { staticClass: "list-table-box2" }, [
                    _c(
                      "div",
                      { staticClass: "coupon-table-top" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "search-type-bg",
                            model: {
                              value: _vm.goodQuery.commodityTypeId,
                              callback: function($$v) {
                                _vm.$set(_vm.goodQuery, "commodityTypeId", $$v)
                              },
                              expression: "goodQuery.commodityTypeId"
                            }
                          },
                          _vm._l(_vm.resourceTypeList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.title, value: item.value }
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("searchPartnerByInput", {
                          ref: "searchPartner",
                          on: { receivepartnerId: _vm.receivePartnerId }
                        }),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "search-input",
                          attrs: { placeholder: "商品名搜索", clearable: "" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchGoods($event)
                            }
                          },
                          model: {
                            value: _vm.goodQuery.commodityName,
                            callback: function($$v) {
                              _vm.$set(_vm.goodQuery, "commodityName", $$v)
                            },
                            expression: "goodQuery.commodityName"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "medium" },
                            on: { click: _vm.searchGoods }
                          },
                          [_vm._v("搜索")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.activeName === "third"
                      ? _c(
                          "div",
                          { staticClass: "coupon-table-con2" },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.goodsListLoading,
                                    expression: "goodsListLoading"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.goodsList }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "商品名称"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  scope.row.commodityName
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4100295545
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { "min-width": "40", label: "类型" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("getStateTitle")(
                                                    scope.row.commodityTypeId,
                                                    _vm.resourceTypeList
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2950575887
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { "min-width": "40", label: "商户" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "user-info-bg" },
                                              [
                                                _c("el-image", {
                                                  staticClass: "user-cover-bg",
                                                  attrs: {
                                                    onerror: _vm.errorUserPhoto,
                                                    src: _vm.getImgUrl(
                                                      scope.row.partnerCover,
                                                      _vm.thumbnailStyle
                                                    ),
                                                    fit: "cover"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "user-info-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.partnerName
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    728548587
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "商品价格"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  ￥" +
                                                _vm._s(
                                                  _vm.numFormat(
                                                    (
                                                      scope.row.commodityPrice /
                                                      100
                                                    ).toFixed(2)
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    903055117
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "累计分销量"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.customerCount
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1630010266
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "40",
                                    label: "累计分销额"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  ￥" +
                                                _vm._s(
                                                  _vm.numFormat(
                                                    (
                                                      scope.row.priceTotal / 100
                                                    ).toFixed(2)
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1433206092
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "50", label: "操作" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.lookGoodDetail(
                                                      scope.row.commodityOpenId
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("详情")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1960837939
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-page-box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          total: _vm.totalElements,
                          "pager-count": 5,
                          "current-page": _vm.goodQuery.page,
                          "page-size": _vm.goodQuery.size,
                          "page-sizes": _vm.pageSizes,
                          layout: "total, sizes, prev, pager, next, jumper",
                          background: ""
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.currentchange
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "使用明细", name: "four" } },
                [
                  _c("div", { staticClass: "list-table-box2" }, [
                    _c(
                      "div",
                      { staticClass: "coupon-table-top" },
                      [
                        _c("el-input", {
                          staticClass: "search-txt",
                          attrs: { placeholder: "订单号", clearable: "" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchContent($event)
                            }
                          },
                          model: {
                            value: _vm.recordQuery.orderId,
                            callback: function($$v) {
                              _vm.$set(_vm.recordQuery, "orderId", $$v)
                            },
                            expression: "recordQuery.orderId"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "medium" },
                            on: { click: _vm.searchRecord }
                          },
                          [_vm._v("搜索")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.activeName === "four"
                      ? _c(
                          "div",
                          { staticClass: "coupon-table-con2" },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.codeListLoading,
                                    expression: "codeListLoading"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.promotionRecordList }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "使用人" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "user-info-bg" },
                                              [
                                                _c("el-image", {
                                                  staticClass: "user-cover-bg",
                                                  attrs: {
                                                    onerror: _vm.errorUserPhoto,
                                                    src: _vm.getImgUrl(
                                                      scope.row.buyUserCover,
                                                      _vm.thumbnailStyle
                                                    ),
                                                    fit: "cover"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "user-info-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.buyUserName
                                                          ? scope.row
                                                              .buyUserName
                                                          : "--"
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3028146742
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "折扣" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.promotionDiscount /
                                                    10
                                                ) +
                                                "折\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4098376837
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "是否抢客" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.ifRobCustomer === 1
                                                    ? "是"
                                                    : "否"
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2663822505
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "推广方式" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.buyMode === 1
                                                    ? "链接分享"
                                                    : "--"
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2356246767
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "购买商品" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.commodityName
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1973646713
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "140", label: "使用时间" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("parseTime")(
                                                    scope.row.createdAt,
                                                    "{y}-{m}-{d} {h}:{i}"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    942162192
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "50", label: "操作" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toLookPromoterDetail(
                                                      scope.row.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("详情")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    587572688
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-page-box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          total: _vm.totalElements,
                          "pager-count": 5,
                          "current-page": _vm.recordQuery.page,
                          "page-size": _vm.recordQuery.size,
                          "page-sizes": _vm.pageSizes,
                          layout: "total, sizes, prev, pager, next, jumper",
                          background: ""
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.currentchange
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "promoter-dialog",
          attrs: {
            title: "编辑推广员",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "promoterForm",
              attrs: {
                model: _vm.promoterForm,
                rules: _vm.promoterRules,
                "label-width": "90px",
                "label-position": "right"
              }
            },
            [
              _c(
                "div",
                { staticClass: "editPromoter" },
                [
                  _c("el-form-item", { attrs: { label: "推广员姓名" } }, [
                    _vm._v(_vm._s(_vm.promoterForm.realName))
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广员类别" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "search-type-bg with-330",
                          model: {
                            value: _vm.promoterForm.promotionSettingCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.promoterForm,
                                "promotionSettingCode",
                                $$v
                              )
                            },
                            expression: "promoterForm.promotionSettingCode"
                          }
                        },
                        _vm._l(_vm.levelList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.title, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邀请人" } },
                    [
                      _c("promoter", {
                        staticClass: "with-330",
                        attrs: { partnerOpenId: _vm.promoterForm.parentOpenId },
                        on: { receiveUserOpenId: _vm.getPromoter }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "修改原因", prop: "remarks" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-reason",
                        attrs: {
                          autosize: { minRows: 3, maxRows: 3 },
                          type: "textarea",
                          placeholder: "请输入此次编辑修改内容及原因"
                        },
                        model: {
                          value: _vm.promoterForm.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.promoterForm, "remarks", $$v)
                          },
                          expression: "promoterForm.remarks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editPromoter } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("buy-record", {
        attrs: { isShow: _vm.isShowBuy, buyUserOpenId: _vm.userOpenId },
        on: {
          "update:isShow": function($event) {
            _vm.isShowBuy = $event
          },
          "update:is-show": function($event) {
            _vm.isShowBuy = $event
          }
        }
      }),
      _vm._v(" "),
      _c("promotion-detail", {
        attrs: {
          isShowPromotionDetail: _vm.isShowPromotionDetail,
          promotionRecordId: _vm.promotionRecordId
        },
        on: {
          "update:isShowPromotionDetail": function($event) {
            _vm.isShowPromotionDetail = $event
          },
          "update:is-show-promotion-detail": function($event) {
            _vm.isShowPromotionDetail = $event
          },
          "update:promotionRecordId": function($event) {
            _vm.promotionRecordId = $event
          },
          "update:promotion-record-id": function($event) {
            _vm.promotionRecordId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }