var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pop-up-layout" },
    [
      _c("product-list", {
        ref: "myList",
        attrs: {
          loading: _vm.loading,
          query: _vm.query,
          data: _vm.data,
          total: _vm.total,
          "content-height": "calc(92vh - 202px)",
          select: _vm.select,
          "select-type": _vm.selectType,
          "show-resource-type": true,
          "price-hidden": true,
          "show-low-price": false,
          "show-whole-low-price": true,
          "has-state": true,
          "show-query-type": true,
          "query-type-except": [1],
          "use-merchant-brand-series": _vm.useMerchantBrandSeries,
          "preset-partner-open-id": _vm.middleOpenId
        },
        on: {
          "update:query": function($event) {
            _vm.query = $event
          },
          "update:select": function($event) {
            _vm.select = $event
          },
          "update:selectType": function($event) {
            _vm.selectType = $event
          },
          "update:select-type": function($event) {
            _vm.selectType = $event
          },
          "update-data": _vm.updateData
        },
        scopedSlots: _vm._u([
          {
            key: "header-left",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "medium" },
                    on: { click: _vm.onButtonClicked }
                  },
                  [_vm._v("添加商品")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "content-prefix",
            fn: function() {
              return [
                _c("el-table-column", {
                  attrs: {
                    fixed: "",
                    width: "50",
                    label: "状态",
                    prop: "state",
                    formatter: _vm.stateFormatter
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content-additional",
            fn: function() {
              return [
                _c("editable-table-column", {
                  attrs: {
                    fixed: "right",
                    width: "160",
                    label: "店铺进货价",
                    prop: "stockPrice",
                    "key-name": "productOpenId",
                    "on-update": _vm.onStockPriceUpdate,
                    "formatter-display": _vm.discountFormatter,
                    "formatter-get": _vm.discountFormatterGet,
                    "formatter-set": _vm.discountFormatterSet
                  },
                  on: { "update-data": _vm.updateData }
                }),
                _vm._v(" "),
                _c("editable-table-column", {
                  attrs: {
                    fixed: "right",
                    width: "160",
                    label: "店铺最低售价",
                    prop: "lowPrice",
                    "key-name": "productOpenId",
                    "on-update": _vm.onLowPriceUpdate,
                    "formatter-display": _vm.discountFormatter,
                    "formatter-get": _vm.discountFormatterGet,
                    "formatter-set": _vm.discountFormatterSet
                  },
                  on: { "update-data": _vm.updateData }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content-action",
            fn: function() {
              return [
                _c("list-actions", {
                  attrs: {
                    data: _vm.data,
                    "middle-open-id": _vm.middleOpenId,
                    "shop-open-id": _vm.shopOpenId
                  },
                  on: {
                    "show-info": _vm.onShowInfo,
                    "update-data": _vm.updateData
                  },
                  model: {
                    value: _vm.restockingPrice,
                    callback: function($$v) {
                      _vm.restockingPrice = $$v
                    },
                    expression: "restockingPrice"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer-left",
            fn: function() {
              return [
                _c("el-checkbox", {
                  staticClass: "pagination-check",
                  attrs: { indeterminate: _vm.selectType === "HALF" },
                  model: {
                    value: _vm.selectedAll,
                    callback: function($$v) {
                      _vm.selectedAll = $$v
                    },
                    expression: "selectedAll"
                  }
                }),
                _vm._v(" "),
                _c("universal-dropdown", {
                  attrs: {
                    "option-list": _vm.universalDropdownOptionList,
                    "cache-name": "zhongpan-store-distributionproductlibrary"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogs" },
        [
          _c("shop-add-product", {
            attrs: {
              "append-to-body": true,
              "middle-open-id": _vm.middleOpenId,
              "shop-open-id": _vm.shopOpenId
            },
            on: { "update-data": _vm.updateData },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          }),
          _vm._v(" "),
          _c("real-goods-info", {
            attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
            on: {
              "update:visible": function($event) {
                _vm.goodsDialogVisible = $event
              },
              "update:goodsId": function($event) {
                _vm.goodsId = $event
              },
              "update:goods-id": function($event) {
                _vm.goodsId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("mid-cap-batch-price-adjustment-dialog", {
            on: { confirm: _vm.batchPriceConfirm },
            model: {
              value: _vm.batchPriceDialogVisible,
              callback: function($$v) {
                _vm.batchPriceDialogVisible = $$v
              },
              expression: "batchPriceDialogVisible"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }