var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-menu no-select" },
    [
      _c(
        "el-col",
        { staticClass: "left-area", attrs: { span: 16 } },
        [
          _c("bookMenu", { ref: "book_menu", staticClass: "book_menu" }),
          _vm._v(" "),
          _c("blank-audio", { ref: "blank_audio", staticClass: "blank_audio" })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "right-area", attrs: { span: 8 } },
        [
          _c("upload", { ref: "upload", staticClass: "upload-box" }),
          _vm._v(" "),
          _c("pageList", { ref: "page_list", staticClass: "page-box" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }