var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "div",
        { staticClass: "header-bg" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addBtn } },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "post-table-body"
        },
        [
          _c(
            "el-table",
            {
              ref: "postCompanyList",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sequence", "min-width": "40", label: "排序" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", "min-width": "40", label: "ID" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "快递标识" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return scope.row.logo
                          ? [
                              scope.row.logo
                                ? _c("el-image", {
                                    staticClass: "logo-img",
                                    attrs: {
                                      src: _vm.getImgUrl(scope.row.logo)
                                        ? _vm.getImgUrl(scope.row.logo)
                                        : "",
                                      fit: "cover"
                                    }
                                  })
                                : _vm._e()
                            ]
                          : undefined
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "simpleName", label: "简称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "电话" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "expName", label: "名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "url", label: "查询地址" }
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "note", label: "备注" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "60", label: "可选择" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.active ? "是" : "否"))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "90", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getAllPostCompany
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "480px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formName",
                    attrs: {
                      "label-width": "70px",
                      model: _vm.learnForm,
                      rules: _vm.rules
                    }
                  },
                  [
                    _vm.dialogTitltText === "编辑物流渠道"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "ID" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.learnForm.id,
                                callback: function($$v) {
                                  _vm.$set(_vm.learnForm, "id", $$v)
                                },
                                expression: "learnForm.id"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递标识" } },
                      [
                        _c("cover-upload", {
                          staticClass: "upload-logo",
                          attrs: {
                            path: _vm.path,
                            coverarr: _vm.coverArr,
                            covertext: _vm.coverText,
                            type: _vm.postCompanyID.toString()
                          },
                          on: { deletecover: _vm.deletecoverFun },
                          model: {
                            value: _vm.learnForm.logo,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "logo", $$v)
                            },
                            expression: "learnForm.logo"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "简称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 40,
                            "show-word-limit": "",
                            placeholder: "请填写物流公司简称"
                          },
                          model: {
                            value: _vm.learnForm.simpleName,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "simpleName", $$v)
                            },
                            expression: "learnForm.simpleName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "电话" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写物流公司联系电话" },
                          model: {
                            value: _vm.learnForm.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "phone", $$v)
                            },
                            expression: "learnForm.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "expName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: 40,
                            "show-word-limit": "",
                            placeholder: "请填写物流公司全称"
                          },
                          model: {
                            value: _vm.learnForm.expName,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "expName", $$v)
                            },
                            expression: "learnForm.expName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "查询地址" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写物流公司查询订单地址" },
                          model: {
                            value: _vm.learnForm.url,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "url", $$v)
                            },
                            expression: "learnForm.url"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请填写相关备注，100字以内",
                            maxlength: "100"
                          },
                          model: {
                            value: _vm.learnForm.note,
                            callback: function($$v) {
                              _vm.$set(_vm.learnForm, "note", $$v)
                            },
                            expression: "learnForm.note"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "可选择" } }, [
                      _c(
                        "div",
                        { staticClass: "select-box" },
                        [
                          _c("el-switch", {
                            staticClass: "select-box-l",
                            model: {
                              value: _vm.learnForm.active,
                              callback: function($$v) {
                                _vm.$set(_vm.learnForm, "active", $$v)
                              },
                              expression: "learnForm.active"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "select-box-r" },
                            [
                              _vm._v("\n                排序"),
                              _c("el-input-number", {
                                staticClass: "select-sequence",
                                attrs: {
                                  precision: 0,
                                  step: 1,
                                  min: 0,
                                  "controls-position": "right"
                                },
                                model: {
                                  value: _vm.learnForm.sequence,
                                  callback: function($$v) {
                                    _vm.$set(_vm.learnForm, "sequence", $$v)
                                  },
                                  expression: "learnForm.sequence"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialogFoot" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.editDialogVisible = false
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.submitSyncLoading },
                    on: { click: _vm.submitSync }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }