var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-resource-dialog-layout" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "select-resource-dialog-layout",
          attrs: {
            title: "选择教材",
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.handleClose,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "select-resource-dialog-body-layout" },
            [
              _c("book-search", {
                attrs: { query: _vm.query, "show-type": false },
                on: { getList: _vm.getList }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "select-resource-body-layout content-table" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      ref: "table",
                      attrs: {
                        data: _vm.bookList,
                        "row-class-name": _vm.tableRowClassName,
                        "tooltip-effect": "dark"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          selectable: _vm.selectable,
                          type: "selection",
                          width: "65"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "教材" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", { staticClass: "resource-layout" }, [
                                  _c(
                                    "div",
                                    { staticClass: "cover-layout book-cover" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImageUrl(scope.row.cover),
                                          alt: "cover"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "resource-title-layout" },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "resource-title" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(scope.row.name) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "resource-info" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(scope.row.sectionName) +
                                              " /\n                    " +
                                              _vm._s(scope.row.subjectName) +
                                              " /\n                    " +
                                              _vm._s(scope.row.editionName) +
                                              " /\n                    " +
                                              _vm._s(scope.row.gradeName) +
                                              " /\n                    " +
                                              _vm._s(scope.row.volumeName) +
                                              " /\n                    " +
                                              _vm._s(scope.row.periodName) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          prop: "data",
                          width: "220",
                          align: "center",
                          label: "发布时间"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.showVal(scope.row.updatedAt))
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.dialogType === "add"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-add-album",
                                        attrs: {
                                          disabled: !_vm.selectable(scope.row),
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "item-add",
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                添加\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.dialogType === "replace"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-add-album",
                                        attrs: {
                                          disabled: !_vm.selectable(scope.row),
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "item-replace",
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                替换\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "select-resource-footer-layout" }, [
                _c(
                  "div",
                  [
                    _c("el-pagination", {
                      staticClass: "footer-page",
                      attrs: {
                        background: "",
                        "current-page": _vm.query.page,
                        "page-sizes": [10, 20, 40, 60],
                        "page-size": _vm.query.size,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("cancelSelectBook")
                          }
                        }
                      },
                      [_vm._v("\n            取消\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onAddButtonClicked }
                      },
                      [_vm._v("\n            确定\n          ")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }