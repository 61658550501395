"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _LiveUser = _interopRequireDefault(require("@/components/TableList/LiveUser.vue"));

var _LiveAward = _interopRequireDefault(require("@/components/TableList/LiveAward.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'LiveStatistics',
  components: {
    LiveUser: _LiveUser.default,
    LiveAward: _LiveAward.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 对话框是否显示
    liveObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 直播
    activeName: {
      type: String,
      default: 'user'
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    visibleProxy: {
      get: function get() {
        return this.visible;
      },
      set: function set(value) {
        this.$emit('update:visible', value);
      }
    },
    activeNameProxy: {
      get: function get() {
        return this.activeName;
      },
      set: function set(value) {
        this.$emit('update:activeName', value);
      }
    }
  },
  created: function created() {},
  methods: {
    jumpToexportData: function jumpToexportData() {
      // 关闭弹窗进行跳转
      this.visibleProxy = false;
      this.$router.push({
        name: 'ExportData'
      });
    }
  }
};
exports.default = _default2;