"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MicroDetail = _interopRequireDefault(require("../../../components/MicroPage/MicroDetail/MicroDetail"));

//
//
//
//
//
//
var _default = {
  name: 'platformMicroPage',
  components: {
    MicroDetail: _MicroDetail.default
  }
};
exports.default = _default;