var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderQuery" },
    [
      _c(
        "div",
        {
          staticClass: "exportOrder-case topButton display_flex",
          attrs: { type: "border-card" }
        },
        [
          _c(
            "div",
            { staticClass: "display_flex", staticStyle: { flex: "none" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "marginRight_10",
                  on: { change: _vm.handleBtnCheck },
                  model: {
                    value: _vm.query.status,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status"
                  }
                },
                _vm._l(_vm.button_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.value,
                      attrs: { value: item.value, label: item.value }
                    },
                    [_vm._v(_vm._s(item.label) + "\n          ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "marginRight_10",
                  on: { change: _vm.handleMaterialFlowstatusBtnCheck },
                  model: {
                    value: _vm.query.materialFlowstatus,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "materialFlowstatus", $$v)
                    },
                    expression: "query.materialFlowstatus"
                  }
                },
                _vm._l(_vm.button_logistics_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.value,
                      attrs: { value: item.value, label: item.value }
                    },
                    [_vm._v(_vm._s(item.label) + "\n          ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "marginRight_10",
                  on: { change: _vm.handleAfterSalestatusBtnCheck },
                  model: {
                    value: _vm.query.afterSalestatus,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "afterSalestatus", $$v)
                    },
                    expression: "query.afterSalestatus"
                  }
                },
                _vm._l(_vm.button_afterSale_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.value,
                      attrs: { value: item.value, label: item.value }
                    },
                    [_vm._v(_vm._s(item.label) + "\n          ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "marginRight_10",
                  on: { change: _vm.handleSupplyModeStatusBtnCheck },
                  model: {
                    value: _vm.query.supplyMode,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "supplyMode", $$v)
                    },
                    expression: "query.supplyMode"
                  }
                },
                _vm._l(_vm.button_supplyMode_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.value,
                      attrs: { value: item.value, label: item.value }
                    },
                    [_vm._v(_vm._s(item.label) + "\n          ")]
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "exportOrder-case topButton display_flex",
          attrs: { type: "border-card" }
        },
        [
          _c(
            "div",
            {
              staticClass: "display_flex marginLeft_10 flex_end",
              staticStyle: { flex: "auto" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "marginRight_10",
                  attrs: { placeholder: "选择中盘", clearable: "" },
                  on: { change: _vm.midCapChange },
                  model: {
                    value: _vm.query.centralOpenId,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "centralOpenId", $$v)
                    },
                    expression: "query.centralOpenId"
                  }
                },
                _vm._l(_vm.midCapOption, function(item) {
                  return _c("el-option", {
                    key: item.partnerOpenId,
                    attrs: { label: item.storeName, value: item.partnerOpenId }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("searchUserByInput", {
                attrs: { userOpenId: _vm.query.buyUserOpenId },
                on: { receiveuserid: _vm.receiveuserid }
              }),
              _vm._v(" "),
              _c("searchUserByInput", {
                attrs: {
                  "user-open-id": _vm.query.promoterOpenId,
                  "place-holder-text": "选择推广员"
                },
                on: { receiveuserid: _vm.promoterReceiveuserid }
              }),
              _vm._v(" "),
              _c("searchPartnerByInput", {
                attrs: { partnerOpenId: _vm.query.partnerOpenId },
                on: { receivepartnerId: _vm.receivepartnerId }
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "marginRight_10",
                staticStyle: { "max-width": "250px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "搜索订单号、商品名称、物流单号"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchContent($event)
                  }
                },
                model: {
                  value: _vm.query.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "keyword", $$v)
                  },
                  expression: "query.keyword"
                }
              }),
              _vm._v(" "),
              _c("product-order-search-button", {
                on: { searchBtn: _vm.searchContent },
                model: {
                  value: _vm.query,
                  callback: function($$v) {
                    _vm.query = $$v
                  },
                  expression: "query"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px", height: "36px" },
                  attrs: { type: "primary", loading: _vm.exportOrderLoading },
                  on: { click: _vm.exportOrder }
                },
                [_vm._v("导出订单")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "orderList" },
        [
          _c("product-order-table", {
            attrs: { loading: _vm.loading, "order-list": _vm.orderlist },
            on: {
              "update:loading": function($event) {
                _vm.loading = $event
              },
              "update:orderList": function($event) {
                _vm.orderlist = $event
              },
              "update:order-list": function($event) {
                _vm.orderlist = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "t-bag" },
        [
          _c("total-revenue", {
            ref: "totalRevenue",
            attrs: { query: _vm.query }
          }),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "t-block",
            attrs: {
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              total: _vm.totalElements,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "680px",
            "append-to-body": "",
            visible: _vm.exportOrderDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.exportOrderDialog = $event
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("导出订单")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "display_flex" }, [
            _c("div", { staticClass: "exportOrderDialog" }, [
              _vm._v(
                "订单状态：\n          " +
                  _vm._s(_vm.orderStatusStr(_vm.query.status)) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "exportOrderDialog" }, [
              _vm._v("关键词：" + _vm._s(_vm.showVal(_vm.query.keyword)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "line-height": "32px" } }, [
            _vm._v(
              "下单时间：" +
                _vm._s(_vm.showVal(_vm.query.createdStartTime)) +
                " ~\n        " +
                _vm._s(_vm.showVal(_vm.query.createdEndTime)) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("div", { staticClass: "exportOrderDialog" }, [
              _vm._v("购买方式：" + _vm._s(_vm.buyTypetoStr(_vm.query.buyType)))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "exportOrderDialog" }, [
              _vm._v("支付方式：" + _vm._s(_vm.showPayType(_vm.query.payType)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("div", { staticClass: "exportOrderDialog" }, [
              _vm._v("购买渠道：" + _vm._s(_vm.showChannel(_vm.query.channel)))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                width: "640px",
                height: "122px",
                "align-items": "center",
                margin: "10px 0"
              }
            },
            [
              _c("div", { staticClass: "line" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "auto",
                    "background-color": "#ECF8FF",
                    height: "122px",
                    padding: "15px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v("提示说明：")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-top": "10px" } }, [
                    _vm._v(
                      "1. 如需修改以上条件，请关闭本窗口，重选条件后，点击“导出订单”；"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("2. 您可在“应用-数据导出”中查看\n            "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.jumpToexportDataC }
                        },
                        [_vm._v("导出记录")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v("下单时间")
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  size: "mini",
                  "picker-options": _vm.pickerOptions,
                  type: "datetimerange",
                  "default-time": ["00:00:00", "23:59:59"],
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right"
                },
                on: {
                  change: function($event) {
                    return _vm.getlistApiinDateOrderexport(_vm.dateValue)
                  }
                },
                model: {
                  value: _vm.dateValue,
                  callback: function($$v) {
                    _vm.dateValue = $$v
                  },
                  expression: "dateValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.exportOrderDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.exportOrderHandle("导出订单")
                    }
                  }
                },
                [_vm._v("导出订单数据")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.postConfigloading,
              expression: "postConfigloading"
            }
          ],
          attrs: {
            title: "配置物流信息",
            visible: _vm.dialogPostConfig,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPostConfig = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "postCondialogFormVisible", attrs: { model: _vm.formPost } },
            [
              _c(
                "el-form-item",
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("span", { staticClass: "star" }, [_vm._v("*")]),
                    _vm._v("配置文件\n          ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "uploadExcel",
                      attrs: {
                        action:
                          "//api.cloud.dev.taozhi.online/order/admin/import/orderForm/csv",
                        limit: _vm.limitNum,
                        accept: ".csv",
                        "auto-upload": false,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeUploadFile,
                        "on-change": _vm.httpRequestNega,
                        "on-exceed": _vm.exceedFile,
                        "file-list": _vm.fileList
                      }
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("选择文件")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("只能上传csv文件，且不超过10M")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogPostConfig = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.uploadFile } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }