"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _decimal = _interopRequireDefault(require("decimal.js"));

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PriceGroup',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    orginalPrice: {
      type: Number,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    showOnlyDiscounts: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      price: 0,
      sale: 0
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return (0, _priceParser.isSafeNumber)(this.value) ? this.value : 0;
      },
      set: function set(value) {
        this.$emit('input', (0, _priceParser.isSafeNumber)(value) ? value : 0);
      }
    },
    valueProxyDisplay: {
      get: function get() {
        return (0, _priceParser.discountToPercent)(this.valueProxy);
      },
      set: function set(value) {
        this.valueProxy = (0, _priceParser.parseDiscountFromPercent)(value);
      }
    },
    priceDisplay: {
      get: function get() {
        return (0, _priceParser.priceToYuan)(this.price);
      },
      set: function set(value) {
        this.price = (0, _priceParser.parsePriceFromYuan)(value);
      }
    },
    saleDisplay: {
      get: function get() {
        return (0, _priceParser.priceToYuan)(this.sale);
      },
      set: function set(value) {
        this.sale = (0, _priceParser.parsePriceFromYuan)(value);
      }
    },
    orginalPriceDisplay: {
      get: function get() {
        return (0, _priceParser.priceToYuan)(this.orginalPrice);
      },
      set: function set(value) {
        this.orginalPrice = (0, _priceParser.parsePriceFromYuan)(value);
      }
    }
  },
  watch: {
    valueProxy: function valueProxy() {
      this.updatePrice();
    },
    orginalPrice: function orginalPrice() {
      this.updatePrice();
    }
  },
  mounted: function mounted() {
    this.updatePrice();
  },
  methods: {
    discountInputBlur: function discountInputBlur() {
      this.updatePrice();
    },
    reducePriceBlur: function reducePriceBlur() {
      if (new _decimal.default(this.orginalPrice).eq('0')) {
        return;
      }

      this.valueProxy = (0, _priceParser.calcDiscountByPrice)(this.orginalPrice, this.price);
      this.updatePrice();
    },
    discountPriceBlur: function discountPriceBlur() {
      if (new _decimal.default(this.orginalPrice).eq('0')) {
        return;
      }

      this.valueProxy = (0, _priceParser.calcDiscountByReduce)(this.orginalPrice, this.sale);
      this.updatePrice();
    },
    updatePrice: function updatePrice() {
      this.price = (0, _priceParser.calcPriceByDiscount)(this.orginalPrice, this.valueProxy);
      this.sale = (0, _priceParser.calcReduceByPrice)(this.orginalPrice, this.price);
    }
  }
};
exports.default = _default;