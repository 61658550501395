"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.forever = forever;
exports.flush = flush;
Object.defineProperty(exports, "put", {
  enumerable: true,
  get: function get() {
    return _storage.put;
  }
});
Object.defineProperty(exports, "forget", {
  enumerable: true,
  get: function get() {
    return _storage.forget;
  }
});

var _storage = require("./storage");

/**
 * 只存储缓存中不存在的数据。
 * 如果存储成功，将返回 true ，否则返回 false。
 *
 * @param {string} key
 * @param {*} value
 * @param {number|Date} expiredAt 过期时间
 */
function add(key, value, expiredAt) {
  if (!(0, _storage.has)(key)) {
    (0, _storage.put)(key, value, expiredAt);
    return true;
  }

  return false;
}
/**
 * 持久化将数据存储到缓存中。
 * 因为这些数据不会过期，所以必须通过 forget 方法从缓存中手动删除它们。
 *
 * @param {string} key
 * @param {*} value
 */


function forever(key, value) {
  (0, _storage.put)(key, value);
}
/**
 * 清空所有的缓存
 */


function flush() {
  localStorage.clear();
}