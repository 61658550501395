var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "download-dialog",
      attrs: {
        title: "下载试卷",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
        width: "1000px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c(
        "div",
        { staticClass: "paper-set" },
        [
          _c("div", { staticClass: "set-text" }, [_vm._v("主标题")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { placeholder: "请填写主标题" },
            model: {
              value: _vm.options.title,
              callback: function($$v) {
                _vm.$set(_vm.options, "title", $$v)
              },
              expression: "options.title"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "set-text" }, [_vm._v("副标题")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { placeholder: "请填写副标题" },
            model: {
              value: _vm.options.subTitle,
              callback: function($$v) {
                _vm.$set(_vm.options, "subTitle", $$v)
              },
              expression: "options.subTitle"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "set-text" }, [_vm._v("答案设置")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.options.answer,
                callback: function($$v) {
                  _vm.$set(_vm.options, "answer", $$v)
                },
                expression: "options.answer"
              }
            },
            [
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("无答案")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("试题后显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("试卷后显示")])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "set-text" }, [_vm._v("显示分数")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.options.showScore,
                callback: function($$v) {
                  _vm.$set(_vm.options, "showScore", $$v)
                },
                expression: "options.showScore"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("不显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: true } }, [_vm._v("显示")])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "set-text" }, [_vm._v("显示版次")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.options.showPeriod,
                callback: function($$v) {
                  _vm.$set(_vm.options, "showPeriod", $$v)
                },
                expression: "options.showPeriod"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("不显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: true } }, [_vm._v("显示")])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "set-text" }, [_vm._v("显示地区")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.options.showArea,
                callback: function($$v) {
                  _vm.$set(_vm.options, "showArea", $$v)
                },
                expression: "options.showArea"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("不显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: true } }, [_vm._v("显示")])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "set-text" }, [_vm._v("显示二维码")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.options.showQrCode,
                callback: function($$v) {
                  _vm.$set(_vm.options, "showQrCode", $$v)
                },
                expression: "options.showQrCode"
              }
            },
            [
              _c("el-radio", { attrs: { label: false } }, [_vm._v("不显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: true } }, [_vm._v("显示")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paper" },
        [
          _vm.options.title || _vm.options.subTitle
            ? _c("div", { staticClass: "paper-nav" }, [
                _vm.options.title
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.options.title))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.options.subTitle
                  ? _c("div", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(_vm.options.subTitle))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.options.showQrCode
                  ? _c(
                      "div",
                      { staticClass: "qrcode" },
                      [
                        _c("qrcode-vue", {
                          attrs: { value: _vm.qrPath, size: 80, level: "H" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("扫码答题")])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.paperGroup, function(item, index) {
            return _c(
              "div",
              { key: item.id },
              [
                _c("div", { staticClass: "group-name" }, [
                  _vm._v(_vm._s(item.groupName))
                ]),
                _vm._v(" "),
                _vm._l(item.examPaperQuestionsList, function(
                  question,
                  questionIndex
                ) {
                  return _c(
                    "div",
                    { key: questionIndex },
                    [
                      question.examQuestionTypeCode === 1009
                        ? _c("div", { staticClass: "son-question" }, [
                            _c("div", { staticClass: "stem-num" }, [
                              _vm._v(_vm._s(questionIndex + 1) + ".")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "son-item" },
                              [
                                _c("download-nav", {
                                  attrs: {
                                    value: question,
                                    "val-index": questionIndex,
                                    options: _vm.options
                                  }
                                }),
                                _vm._v(" "),
                                _vm._l(question.subQuestionList, function(
                                  sonQuestion,
                                  sonIndex
                                ) {
                                  return _c(
                                    "div",
                                    { key: sonIndex },
                                    [
                                      _c("download-question-item", {
                                        attrs: {
                                          value: sonQuestion.examQuestion,
                                          "val-index": sonIndex,
                                          "group-index": index,
                                          "question-index": questionIndex,
                                          options: _vm.options
                                        },
                                        on: { handleOption: _vm.handleOption }
                                      })
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        : _c("download-question-item", {
                            attrs: {
                              value: question,
                              "val-index": questionIndex,
                              "group-index": index,
                              options: _vm.options
                            },
                            on: { handleOption: _vm.handleOption }
                          })
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm.options.answer === 2
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "answer-name" }, [
                    _vm._v("答案及解析")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.paperGroup, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("div", { staticClass: "group-name" }, [
                          _vm._v(_vm._s(item.groupName))
                        ]),
                        _vm._v(" "),
                        _vm._l(item.examPaperQuestionsList, function(
                          question,
                          questionIndex
                        ) {
                          return _c(
                            "div",
                            { key: questionIndex },
                            [
                              question.examQuestionTypeCode === 1009
                                ? _c("div", { staticClass: "son-question" }, [
                                    _c("div", { staticClass: "stem-num" }, [
                                      _vm._v(_vm._s(questionIndex + 1) + "、")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      _vm._l(question.subQuestionList, function(
                                        sonQuestion,
                                        sonIndex
                                      ) {
                                        return _c(
                                          "div",
                                          { key: sonIndex },
                                          [
                                            _c("download-answer", {
                                              attrs: {
                                                value: sonQuestion.examQuestion,
                                                "val-index": sonIndex + 1,
                                                "type-code":
                                                  sonQuestion.examQuestion
                                                    .examQuestionTypeCode,
                                                "question-index": questionIndex
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  ])
                                : _c("download-answer", {
                                    attrs: {
                                      value: question,
                                      "val-index": questionIndex + 1,
                                      "type-code": question.examQuestionTypeCode
                                    }
                                  })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.pdfLoading },
              on: {
                click: function($event) {
                  return _vm.submit(true)
                }
              }
            },
            [_vm._v("下载pdf")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.wordLoading },
              on: {
                click: function($event) {
                  return _vm.submit(false)
                }
              }
            },
            [_vm._v("下载word")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }