"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/es6.regexp.split");

var _collect = _interopRequireDefault(require("collect.js"));

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _OriginalSelector = _interopRequireDefault(require("./OriginalSelector"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'CG_From_Selector_CommoditySecondaryCategories_DefaultSelector',
  components: {
    OriginalSelector: _OriginalSelector.default
  },
  props: {
    regimentationIds: {
      type: String,
      default: undefined
    },
    secondRegimentationIds: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data: {
      get: function get() {
        return this.multiple ? this.getMultipleData() : this.getSingleData();
      },
      set: function set(value) {
        if (this.multiple) {
          this.setMultipleData(value);
        } else {
          this.setSingleData(value);
        }
      }
    }
  },
  methods: {
    getSingleData: function getSingleData() {
      return [this.regimentationIds, this.secondRegimentationIds];
    },
    getMultipleData: function getMultipleData() {
      if (!_main.default.isString(this.regimentationIds)) {
        return [];
      }

      if (!_main.default.isString(this.secondRegimentationIds)) {
        return [];
      }

      return (0, _collect.default)(this.regimentationIds.split(',')).zip(this.secondRegimentationIds.split(',')).map(function (i) {
        return i.all();
      }).all();
    },
    setSingleData: function setSingleData(value) {
      var _value = (0, _slicedToArray2.default)(value, 2),
          regimentationIds = _value[0],
          secondRegimentationIdsProxy = _value[1];

      this.$emit('update:regimentationIds', regimentationIds);
      this.$emit('update:secondRegimentationIds', secondRegimentationIdsProxy);
    },
    setMultipleData: function setMultipleData(value) {
      var parsed = (0, _collect.default)(value).map(function (i) {
        return _objectSpread({}, i);
      });
      this.$emit('update:regimentationIds', parsed.pluck('0').join(','));
      this.$emit('update:secondRegimentationIds', parsed.pluck('1').join(','));
    }
  }
};
exports.default = _default;