"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _collect = _interopRequireDefault(require("collect.js"));

var _HeaderWithBatchSet = _interopRequireDefault(require("../../../General/From/Input/HeaderWithBatchSet"));

var _DiscountSettingColumn = _interopRequireDefault(require("../../../General/From/Input/DiscountSettingColumn"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_P_MiddleAddProduct_ListActions',
  components: {
    HeaderWithBatchSet: _HeaderWithBatchSet.default,
    DiscountSettingColumn: _DiscountSettingColumn.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    otherSupplyPrice: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    otherSupplyPriceProxy: {
      get: function get() {
        return this.otherSupplyPrice;
      },
      set: function set(value) {
        this.$emit('update:otherSupplyPrice', value);
      }
    }
  },
  methods: {
    rtIsDigitalGoods: function rtIsDigitalGoods(value) {
      return value === 21;
    },
    // 商品类型：是否是数字商品
    rtIsCombinationProducts: function rtIsCombinationProducts(value) {
      return value === 22;
    },
    // 商品类型：是否是组合商品
    rtIsPhysicalCommodity: function rtIsPhysicalCommodity(value) {
      return !this.rtIsDigitalGoods(value) && !this.rtIsCombinationProducts(value);
    },
    // 商品类型：是否是实物商品
    stIsSingleSpecification: function stIsSingleSpecification(value) {
      return value === 0;
    },
    // 规格类型：是否是单规格
    stIsMultipleSpecifications: function stIsMultipleSpecifications(value) {
      return !this.stIsSingleSpecification(value);
    },
    // 规格类型：是否是多规格
    showPrice: function showPrice(row) {
      if (this.rtIsPhysicalCommodity(row.resourceType)) {
        if (this.stIsSingleSpecification(row.specificType)) {
          return false;
        }
      }

      return true;
    },
    updatePrice: function updatePrice(value) {
      this.valueProxy = (0, _collect.default)(this.data).mapWithKeys(function (i) {
        return [i.productOpenId, value];
      }).all();
    },
    updatePriceOtherSupplyPrice: function updatePriceOtherSupplyPrice(value) {
      this.otherSupplyPriceProxy = (0, _collect.default)(this.data).mapWithKeys(function (i) {
        return [i.productOpenId, value];
      }).all();
    }
  }
};
exports.default = _default2;