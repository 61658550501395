var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-sequence-wrapper",
          attrs: {
            visible: _vm.sequenceDialog,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "设置排序"
          },
          on: {
            "update:visible": function($event) {
              _vm.sequenceDialog = $event
            },
            open: _vm.initSequenceData
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "recommendForm",
              attrs: {
                model: _vm.sequenceForm,
                rules: _vm.sequenceRules,
                "label-width": "30",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sequence" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, max: 10000, "controls-position": "right" },
                    model: {
                      value: _vm.sequenceForm.sequence,
                      callback: function($$v) {
                        _vm.$set(_vm.sequenceForm, "sequence", _vm._n($$v))
                      },
                      expression: "sequenceForm.sequence"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "sequence-remark-bg" }, [
                _c("i", { staticClass: "el-icon-info" }),
                _vm._v(" "),
                _c("p", { staticClass: "sequence-remark" }, [
                  _vm._v("\n          1. 排序范围：0~10000；"),
                  _c("br"),
                  _vm._v("\n          2. 排序越大，排位越靠前；"),
                  _c("br"),
                  _vm._v("\n          3. 排序仅对含二级类目的作品有效；"),
                  _c("br"),
                  _vm._v(
                    "\n          4. 未设置排序的作品，按照发布时间倒序排列；\n        "
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.sequenceDialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.saveSetSequence()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }