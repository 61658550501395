"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _Picture = _interopRequireDefault(require("./Picture"));

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_WordLibrary_Vocabulary_Album_index',
  components: {
    draggable: _vuedraggable.default,
    VocabularyPicture: _Picture.default
  },
  props: {
    value: {
      type: String,
      default: '[]'
    }
  },
  data: function data() {
    return {
      drag: undefined,
      // 可拖拽组件
      pictureList: [] // 图片列表数据

    };
  },
  computed: {
    vModelProxy: {
      get: function get() {
        if (typeof this.value === 'string' && this.value.trim().length > 0) {
          return this.value;
        } // value 不是字符串


        return '[]';
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    vModelProxy: function vModelProxy() {
      this.syncPictureList();
    },
    pictureList: {
      handler: function handler() {
        this.vModelProxy = JSON.stringify(this.pictureList);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.syncPictureList();
  },
  methods: {
    // 同步图片数据
    syncPictureList: function syncPictureList() {
      if (this.vModelProxy === JSON.stringify(this.pictureList)) {
        return;
      }

      try {
        this.pictureList = JSON.parse(this.vModelProxy);
      } catch (e) {
        console.warn(e);
      }
    },
    // 添加新图片
    addPicture: function addPicture() {
      this.pictureList.push({});
    },
    // 删除图片
    removePicture: function removePicture(index) {
      this.pictureList.splice(index, 1);
    }
  }
};
exports.default = _default;