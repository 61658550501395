var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      ref: "table",
      staticClass: "com-table",
      attrs: { data: _vm.valueSync, height: "calc(100vh - 242px)" }
    },
    [
      _c("el-table-column", {
        attrs: { label: "商品系列名称", prop: "title" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "所属品牌", prop: "brandText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "所属学段", prop: "sectionText" }
      }),
      _vm._v(" "),
      _c("el-table-column", { attrs: { label: "商品数量", prop: "count" } }),
      _vm._v(" "),
      !_vm.onlyRead
        ? _c("editable-table-column", {
            attrs: {
              label: "排序",
              prop: "sequence",
              "key-name": "seriesOpenId",
              "on-update": _vm.onUpdate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.onlyRead
        ? _c("el-table-column", { attrs: { label: "排序", prop: "sequence" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.onlyRead
        ? _c("el-table-column", {
            attrs: { label: "操作", align: "right" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          staticClass: "option",
                          attrs: {
                            type: "primary",
                            disabled: _vm.isLoading(scope.row.brandOpenId),
                            underline: false
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "onUpdateBrand",
                                Object.assign({}, scope.row)
                              )
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          staticClass: "option",
                          attrs: {
                            type: "danger",
                            disabled: _vm.isLoading(scope.row.brandOpenId),
                            underline: false
                          },
                          on: {
                            click: function($event) {
                              return _vm.onDelete(scope)
                            }
                          }
                        },
                        [_vm._v("移除")]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1674431421
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }