var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c("el-table-column", {
          attrs: { width: "160", fixed: "right" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "product-category-batch-edit",
                      on: { click: _vm.productCategoryBatchEditClick }
                    },
                    [
                      _c("span", { staticClass: "pcbe-title" }, [
                        _vm._v("商品分类")
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-edit" })
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function(scope) {
                return [
                  _c("product-grouping-selector", {
                    staticClass: "common-select-width",
                    attrs: {
                      "regimentation-ids": scope.row.groupIds,
                      "second-regimentation-ids": scope.row.secondGroupIds,
                      multiple: true,
                      "middle-open-id": _vm.middleOpenId,
                      "shop-open-id": _vm.shopOpenId,
                      "use-default-options": true,
                      "default-options": _vm.productGroupingSelectorOptionsData
                    },
                    on: {
                      "update:regimentationIds": function($event) {
                        return _vm.$set(scope.row, "groupIds", $event)
                      },
                      "update:regimentation-ids": function($event) {
                        return _vm.$set(scope.row, "groupIds", $event)
                      },
                      "update:secondRegimentationIds": function($event) {
                        return _vm.$set(scope.row, "secondGroupIds", $event)
                      },
                      "update:second-regimentation-ids": function($event) {
                        return _vm.$set(scope.row, "secondGroupIds", $event)
                      }
                    }
                  })
                ]
              }
            }
          ])
        }),
        _vm._v(" "),
        _c("el-table-column", {
          attrs: { width: "160", fixed: "right" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("header-with-batch-set", {
                    attrs: { label: "销售价" },
                    on: { update: _vm.updatePrice }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function(scope) {
                return [
                  _c("discount-setting-column", {
                    attrs: {
                      "orginal-price": scope.row.orginalPrice,
                      "no-price": _vm.showPrice(scope.row)
                    },
                    model: {
                      value: scope.row.pSalesPrice,
                      callback: function($$v) {
                        _vm.$set(scope.row, "pSalesPrice", $$v)
                      },
                      expression: "scope.row.pSalesPrice"
                    }
                  })
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialogs" },
      [
        _c("product-category-batch-edit", {
          attrs: {
            "middle-open-id": _vm.middleOpenId,
            "shop-open-id": _vm.shopOpenId,
            "use-default-options": true,
            "default-options": _vm.productGroupingSelectorOptionsData
          },
          on: { "on-confirm": _vm.onProductCategoryBatchEditConfirm },
          model: {
            value: _vm.productCategoryBatchEditVisible,
            callback: function($$v) {
              _vm.productCategoryBatchEditVisible = $$v
            },
            expression: "productCategoryBatchEditVisible"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }