"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addFinance = _interopRequireDefault(require("./addFinance"));

//
//
//
//
//
//
var _default = {
  name: 'FinanceCreate',
  components: {
    addFinance: _addFinance.default
  },
  methods: {
    closeView: function closeView() {
      this.$store.dispatch('tagsView/updateCloseViewFormAnyWhere', 'AddFinance');
    }
  }
};
exports.default = _default;