var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "select-array-ebook",
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "1000px",
        "before-close": _vm.onCancleSelect
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("span", [_vm._v("选择电子书")]),
            _vm._v(" "),
            _c("search", {
              staticClass: "partner",
              attrs: { "partner-open-id": _vm.listQuery.partnerOpenId },
              on: { receivepartnerId: _vm.receivepartnerId }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "select-wraper"
        },
        [
          _c("div", { staticClass: "select-wrap" }, [
            _c(
              "div",
              { staticClass: "select-head" },
              [
                _vm.listQuery.partnerOpenId
                  ? _c("el-cascader", {
                      staticClass: "search-cascader",
                      attrs: {
                        options: _vm.classList,
                        props: { checkStrictly: true },
                        clearable: "",
                        placeholder: "请选择分类"
                      },
                      on: { change: _vm.changeCascader }
                    })
                  : _c("div"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("el-input", {
                      staticClass: "nav-input",
                      attrs: { clearable: "", placeholder: "请输入电子书名称" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchResource($event)
                        }
                      },
                      model: {
                        value: _vm.listQuery.keyword,
                        callback: function($$v) {
                          _vm.$set(_vm.listQuery, "keyword", $$v)
                        },
                        expression: "listQuery.keyword"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "search-button",
                      { on: { searchContent: _vm.searchResource } },
                      [
                        _c("dialog-filter-panel", {
                          attrs: {
                            "type-name": "电子书",
                            isebook: true,
                            "show-download": true
                          },
                          model: {
                            value: _vm.listQuery,
                            callback: function($$v) {
                              _vm.listQuery = $$v
                            },
                            expression: "listQuery"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "记住所选",
                          placement: "top-start"
                        }
                      },
                      [
                        _c("el-checkbox", {
                          staticClass: "remember-check",
                          on: { change: _vm.handleRemember },
                          model: {
                            value: _vm.remember,
                            callback: function($$v) {
                              _vm.remember = $$v
                            },
                            expression: "remember"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-list" },
              _vm._l(_vm.tableData, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "select-item",
                    on: {
                      click: function($event) {
                        return _vm.bookItem(item)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "select-cover",
                          item.coverType === 1 ? "row-select-cover" : ""
                        ]
                      },
                      [
                        _c("el-image", {
                          staticClass: "image",
                          attrs: {
                            src: _vm.getImgUrl(item.coverDisplay),
                            fit: "cover"
                          }
                        }),
                        _vm._v(" "),
                        item.selected
                          ? _c(
                              "div",
                              {
                                staticClass: "select-cover-body",
                                style: {
                                  height: item.coverType === 1 ? "56px" : ""
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/dui.png")
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: item.title,
                          placement: "top"
                        }
                      },
                      [
                        _c("div", { staticClass: "select-title" }, [
                          _vm._v(_vm._s(item.title))
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-foot" },
              [
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("el-checkbox", {
                      staticClass: "all-check",
                      attrs: { indeterminate: _vm.indeterminate },
                      on: { change: _vm.handleCheckAll },
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    }),
                    _vm._v("全选")
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.page,
                    limit: _vm.listQuery.size,
                    "current-page": _vm.listQuery.page,
                    background: false,
                    layout: "prev, pager, next"
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.listQuery, "page", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.listQuery, "size", $event)
                    },
                    pagination: _vm.getBookList
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "select-span iconfont icon-shuangjiantou"
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select-wrap" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFolder,
                      expression: "!isFolder"
                    }
                  ],
                  staticClass: "select-head"
                },
                [
                  _c("span", { staticClass: "select-head-title" }, [
                    _vm._v("已选电子书")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFolder,
                      expression: "isFolder"
                    }
                  ],
                  staticClass: "select-head"
                },
                [
                  _c("span", { staticClass: "select-head-title" }, [
                    _vm._v("主书")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFolder,
                      expression: "isFolder"
                    }
                  ],
                  staticClass: "main-book"
                },
                [
                  _c("el-image", {
                    staticClass: "main-book-image",
                    style: {
                      height: _vm.bookData.coverType === 1 ? "56px" : ""
                    },
                    attrs: {
                      src: _vm.getImgUrl(_vm.bookData.coverDisplay),
                      fit: "cover"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "main-book-title" }, [
                    _vm._v(_vm._s(_vm.bookData.title))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFolder,
                      expression: "isFolder"
                    }
                  ],
                  staticClass: "folder"
                },
                [
                  _c("span", { staticClass: "select-head-title" }, [
                    _vm._v("夹带电子书样书")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "folder-clear" },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("已添加"),
                        _c("span", { staticClass: "total-num" }, [
                          _vm._v(_vm._s(_vm.selectedTableData.length))
                        ]),
                        _vm._v("本")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "danger", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.handleCheckAll(false)
                            }
                          }
                        },
                        [_vm._v("清空")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "draggable",
                {
                  staticClass: "select-list",
                  style: {
                    height: _vm.isFolder
                      ? _vm.bookData.coverType === 1
                        ? "321px"
                        : "264px"
                      : "440px"
                  },
                  model: {
                    value: _vm.selectedTableData,
                    callback: function($$v) {
                      _vm.selectedTableData = $$v
                    },
                    expression: "selectedTableData"
                  }
                },
                _vm._l(_vm.selectedTableData, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "select-item" },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "select-cover",
                            item.coverType === 1 ? "row-select-cover" : ""
                          ]
                        },
                        [
                          _c("el-image", {
                            staticClass: "image",
                            attrs: {
                              src: _vm.getImgUrl(item.coverDisplay),
                              fit: "cover"
                            }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "close",
                            attrs: { src: require("@/assets/images/cha.png") },
                            on: {
                              click: function($event) {
                                return _vm.centerSelected(item)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: item.title,
                            placement: "top"
                          }
                        },
                        [
                          _c("div", { staticClass: "select-title" }, [
                            _vm._v(_vm._s(item.title))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bottom-btns",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.onCancleSelect } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmitSelect }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }