var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testQuestion" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.tableStyle,
              expression: "!tableStyle"
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          ref: "multipleTable",
          class: [_vm.tableStyle ? "questionCard" : ""],
          staticStyle: { width: "100%" },
          attrs: {
            "header-cell-style": { background: "#F5F7FA" },
            height: "calc(100vh - 240px)",
            data: _vm.questionList,
            "tooltip-effect": "dark"
          },
          on: {
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", "min-width": "5" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "stem", label: "试题题干", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      staticClass: "questionStem",
                      domProps: { innerHTML: _vm._s(scope.row.stem) }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.showDifficult
            ? _c("el-table-column", {
                attrs: {
                  formatter: _vm.formatString,
                  prop: "difficultDegree",
                  label: "难易",
                  "min-width": "30"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.difficultDegree))]
                      }
                    }
                  ],
                  null,
                  false,
                  644651811
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showQuestionType
            ? _c("el-table-column", {
                attrs: {
                  prop: "questionTypeName",
                  label: "题型",
                  "min-width": "30"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.questionTypeName))]
                      }
                    }
                  ],
                  null,
                  false,
                  869449804
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showSubject
            ? _c("el-table-column", {
                attrs: {
                  prop: "subjectCode",
                  label: "学科",
                  "min-width": "30"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.subjectCode))]
                      }
                    }
                  ],
                  null,
                  false,
                  869075212
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showGrade
            ? _c("el-table-column", {
                attrs: { prop: "gradeCode", label: "年级", "min-width": "30" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.gradeName))]
                      }
                    }
                  ],
                  null,
                  false,
                  3043753103
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showVolume
            ? _c("el-table-column", {
                attrs: { prop: "volumeCode", label: "阶段", "min-width": "30" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.volumeName))]
                      }
                    }
                  ],
                  null,
                  false,
                  3129842994
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showArea
            ? _c("el-table-column", {
                attrs: { prop: "bookAreaId", label: "地区", "min-width": "30" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.bookAreaId))]
                      }
                    }
                  ],
                  null,
                  false,
                  1940038862
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showPeriod
            ? _c("el-table-column", {
                attrs: { prop: "periodCode", label: "版次", "min-width": "30" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.periodCode))]
                      }
                    }
                  ],
                  null,
                  false,
                  1740442069
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showKnowledge
            ? _c("el-table-column", {
                attrs: {
                  prop: "knowledge",
                  label: "知识点",
                  "min-width": "100"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(scope.row.knowledge))]
                      }
                    }
                  ],
                  null,
                  false,
                  1764698223
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showTags
            ? _c("el-table-column", {
                attrs: { prop: "tags", label: "标签", "min-width": "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.tags[0] !== ""
                            ? _c(
                                "div",
                                _vm._l(scope.row.tags, function(item, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "tableTags",
                                      attrs: { size: "mini" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  889040267
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.questionEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("\n            编辑\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link operationName" },
                          [_vm._v("\n            更多\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleOperation(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                操作日志\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.copy(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                复制试题\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.moveClass(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                转移分类\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                staticStyle: { color: "red" },
                                attrs: { divided: "" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.deleteQuestion(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                删除试题\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.tableStyle
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticClass: "questionCard"
            },
            _vm._l(_vm.questionList, function(item) {
              return _c(
                "div",
                { key: item.examQuestionOpenId },
                [
                  _vm.showCard
                    ? _c("question-card", {
                        ref: "questionCard",
                        refInFor: true,
                        attrs: {
                          list: item,
                          checked: _vm.checkList.includes(
                            item.examQuestionOpenId
                          )
                        },
                        on: {
                          copy: _vm.copy,
                          moveClass: _vm.moveClass,
                          deleteQuestion: _vm.deleteQuestion,
                          handleCheck: _vm.getCheck,
                          emitType: _vm.emitType
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableListBottom" },
        [
          _c(
            "el-checkbox",
            {
              attrs: { indeterminate: _vm.indeterminate },
              on: {
                change: function($event) {
                  return _vm.toggleSelection(_vm.checked)
                }
              },
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { "split-button": "", type: "primary" },
                  on: { click: _vm.handleDropBtn }
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.dropMenName) + "\n        "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.dropMenuList, function(item, index) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: index,
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeDropName(item, index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "pagination",
            attrs: {
              background: "",
              "current-page": _vm.query.current,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.query.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "move-category-dialog",
          attrs: {
            title: "移动分类",
            visible: _vm.dialogVisible,
            width: "450px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.initMoveDialog
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "moveCategoryForm",
              attrs: {
                model: _vm.moveCategoryForm,
                rules: _vm.rules,
                "label-width": "60px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "moveToCategoryOpenId", label: "分类" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      props: {
                        value: "questionCategoryOpenId",
                        label: "name",
                        children: "childList"
                      },
                      options: _vm.treeData,
                      clearable: ""
                    },
                    model: {
                      value: _vm.moveCategoryForm.moveToCategoryOpenId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.moveCategoryForm,
                          "moveToCategoryOpenId",
                          $$v
                        )
                      },
                      expression: "moveCategoryForm.moveToCategoryOpenId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.newQuestiondialogVisible
        ? _c("new-question", {
            ref: "newQuestion",
            attrs: {
              "preview-dialog": _vm.newQuestiondialogVisible,
              source: "questionEdit",
              type: "edit",
              "edit-open-id": _vm.editOpenId,
              merchantOpenId: _vm.merchantOpenId
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              "update:preview-dialog": function($event) {
                _vm.newQuestiondialogVisible = $event
              },
              directoryDialogChange: _vm.directoryDialogChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showQuestionPreviewDialog
        ? _c("question-preview", {
            ref: "questionPreview",
            attrs: {
              "preview-dialog": _vm.showQuestionPreviewDialog,
              "open-id": _vm.questionPreviewOpenId
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.showQuestionPreviewDialog = $event
              },
              "update:preview-dialog": function($event) {
                _vm.showQuestionPreviewDialog = $event
              },
              questionPreviewDialogChange: _vm.questionPreviewDialogChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.setAttribute
        ? _c("batch-set-question-attributes", {
            attrs: {
              visible: _vm.setAttribute,
              title: "批量修改属性（试题）",
              "partner-open-id": _vm.query.partnerOpenId,
              list: _vm.multipleSelection
            },
            on: {
              "update:visible": function($event) {
                _vm.setAttribute = $event
              },
              attributeSuccess: _vm.getQuestionList
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowOperationRecord
        ? _c("resource-operation-record", {
            attrs: {
              visible: _vm.isShowOperationRecord,
              type: "question",
              "resource-obj": _vm.operatoinResource
            },
            on: {
              "update:visible": function($event) {
                _vm.isShowOperationRecord = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }