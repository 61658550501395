"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BookCover',
  methods: {
    bookCopy: function bookCopy() {
      this.$confirm('确认关闭？').then(function (_) {
        console.log('ddd');
      }).catch(function (_) {});
    },
    bookDel: function bookDel() {
      this.$confirm('确定要删除吗？').then(function (_) {
        console.log('删除');
      }).catch(function (_) {});
    }
  }
};
exports.default = _default;