"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _section = require("@/utils/bookPageEditor/section");

//
//
//
//
var _default = {
  name: 'PageSectionArea',
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: function data() {
    return {
      moving: false,
      lastX: 0,
      lastY: 0
    };
  },
  computed: {
    associate: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    dragTime: function dragTime() {
      return this.$store.state.book.editor.drag.time;
    },
    dragId: function dragId() {
      return this.$store.state.book.editor.drag.id;
    },
    pageHeight: function pageHeight() {
      return this.$store.state.book.editor.size.height * this.$store.state.book.editor.size.scale;
    },
    pageWidth: function pageWidth() {
      return this.$store.state.book.editor.size.width * this.$store.state.book.editor.size.scale;
    },
    dragX: function dragX() {
      return this.$store.state.book.editor.drag.x;
    },
    dragY: function dragY() {
      return this.$store.state.book.editor.drag.y;
    }
  },
  watch: {
    dragTime: function dragTime() {
      if (this.moving === false) return;
      if (this.dragX === undefined) return;
      if (this.dragY === undefined) return;
      this.onMove();
    },
    dragId: function dragId() {
      if (this.dragId !== this.associate.id) {
        this.moving = false;
      }
    }
  },
  methods: {
    onDragStart: function onDragStart(event) {
      this.moving = true;
      this.lastX = event.x;
      this.lastY = event.y;
      this.$store.dispatch('book/editor/dragReset', {
        id: this.associate.id,
        x: this.lastX,
        y: this.lastY
      });
    },
    onDragEnd: function onDragEnd() {
      this.moving = false;
      this.$store.dispatch('book/editor/dragReset');
      this.associate.positionTop = (0, _section.formatOffset)(this.associate.positionTop, this.associate.positionHeight);
      this.associate.positionLeft = (0, _section.formatOffset)(this.associate.positionLeft, this.associate.positionWidth);
      var associate = JSON.parse(JSON.stringify(this.associate));

      if (associate.sectionData) {
        associate.sectionData = JSON.stringify(associate.sectionData);
      }

      this.$store.dispatch('book/section/updateSingleSection', associate);
    },
    onMove: function onMove() {
      this.associate.positionTop += (0, _section.px2Proportion)(this.dragY - this.lastY, this.pageHeight);
      this.associate.positionLeft += (0, _section.px2Proportion)(this.dragX - this.lastX, this.pageWidth);
      var _this$$store$state$bo = this.$store.state.book.editor.drag;
      this.lastX = _this$$store$state$bo.x;
      this.lastY = _this$$store$state$bo.y;
    }
  }
};
exports.default = _default;