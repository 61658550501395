var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.operateUserOpenId === _vm.selectUserOpenId
    ? _c("div", { staticClass: "select-user-background" }, [
        _c("div", { staticClass: "select-user-inner" }, [
          _c(
            "div",
            {
              staticClass: "bubble",
              style: { width: _vm.audioDuration * 2 + 50 + "px" },
              on: {
                click: function($event) {
                  return _vm.playAudio(_vm.uniqueindex)
                }
              }
            },
            [
              _c("audio", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: {
                  src: _vm.getAudioUrl(_vm.audioUrl),
                  id: _vm.uniqueindex
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "wifi-symbol" }, [
                _c("div", { staticClass: "wifi-circle first" }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "wifi-circle second",
                  class:
                    _vm.isPlay && _vm.currentUniqueIndex === _vm.uniqueindex
                      ? "wifi-circle second secondAnimation"
                      : "wifi-circle second"
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "wifi-circle third",
                  class:
                    _vm.isPlay && _vm.currentUniqueIndex === _vm.uniqueindex
                      ? "wifi-circle third thirdAnimation"
                      : "wifi-circle third"
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "audioTime" }, [
                _vm._v(_vm._s(_vm.audioDuration) + "' ")
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }