var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-cascader", {
        staticClass: "category-class",
        attrs: {
          options: _vm.categoryData,
          disabled: _vm.isDisabled,
          props: { value: "id", label: "name", multiple: true }
        },
        on: { change: _vm.categoryChange },
        model: {
          value: _vm.valueCategory,
          callback: function($$v) {
            _vm.valueCategory = $$v
          },
          expression: "valueCategory"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }