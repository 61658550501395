var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sync-info", {
    ref: "syncInfo",
    attrs: {
      "key-prefix": _vm.keyPrefix,
      size: "small",
      placeholder: _vm.placeholder,
      "data-source": _vm.dataSource,
      "label-name": "title",
      "value-name": "seriesOpenId",
      filterable: true,
      remote: true,
      "remote-api": _vm.remoteApi,
      query: _vm.searchQuery,
      disabled: _vm.isDisabled
    },
    on: {
      change: function($event) {
        return _vm.$emit("change")
      }
    },
    model: {
      value: _vm.valueProxy,
      callback: function($$v) {
        _vm.valueProxy = $$v
      },
      expression: "valueProxy"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }