"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      logo: 'https://open.oss.taozhi.online/production/assets/admin/layout/logo-expand.png',
      collapseLogo: 'https://open.oss.taozhi.online/production/assets/admin/layout/logo-collapse.png'
    };
  }
};
exports.default = _default;