"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.number.constructor");

var _jquery = _interopRequireDefault(require("jquery"));

var _const = require("@/utils/const");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var statusOptions = [{
  key: _const.RESOURCE_PULISHED_STATE,
  state_name: '已发布',
  class_name: 'iconfont icon-release',
  colorName: 'bg1'
}, {
  key: _const.RESOURCE_REVIEW_STATE,
  state_name: '审核中',
  class_name: 'iconfont icon-pending',
  colorName: 'bg2'
}, {
  key: _const.RESOURCE_DRAFT_STATE,
  state_name: '草稿箱',
  class_name: 'iconfont icon-draft',
  colorName: 'bg3'
}, {
  key: _const.RESOURCE_REFUSED_STATE,
  state_name: '未通过',
  class_name: 'iconfont icon-banned',
  colorName: 'bg4'
}, {
  key: _const.RESOURCE_DELETED_STATE,
  state_name: '回收站',
  class_name: 'iconfont icon-recycle',
  colorName: 'bg5'
}];
var _default = {
  name: 'Label',
  props: {
    isShowItem: {},
    state: {
      type: Object,
      required: true
    },
    state_current: {
      type: String,
      required: true
    },
    isWidthChange: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      hoverIndex: 0,
      state_currents: 0,
      state_index: 0,
      statusOptions: statusOptions,
      type: true
    };
  },
  watch: {
    state_current: {
      immediate: true,
      handler: function handler(val) {
        this.state_currents = val;
        this.state_index = statusOptions.findIndex(function (x) {
          return x.key.toString() === val.toString();
        });
      }
    },
    isWidthChange: function isWidthChange(val) {
      if (val) {
        var menuWidth = (0, _jquery.default)('#stateMenu').outerWidth();

        if (menuWidth < 140) {
          this.type = false;
        } else {
          this.type = true;
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.isShowItem === 'show') {
      this.statusOptions = [{
        key: _const.RESOURCE_PULISHED_STATE,
        state_name: '已发布',
        class_name: 'iconfont icon-release',
        colorName: 'bg1'
      }, {
        key: _const.RESOURCE_DRAFT_STATE,
        state_name: '草稿箱',
        class_name: 'iconfont icon-draft',
        colorName: 'bg3'
      }, {
        key: _const.RESOURCE_DELETED_STATE,
        state_name: '回收站',
        class_name: 'iconfont icon-recycle',
        colorName: 'bg5'
      }];
    }

    var that = this; // 切换状态

    (0, _jquery.default)('.card-item').click(function () {
      (0, _jquery.default)(this).find('div').eq(0).children('span').addClass('fontwe');
      (0, _jquery.default)(this).find('div').eq(0).children('option-icon').addClass('fontwe');
      (0, _jquery.default)(this).siblings('.card-item').children('div').children('option-icon').removeClass('fontwe');
      (0, _jquery.default)(this).siblings('.card-item').children('div').children('span').removeClass('fontwe');
      that.state_index = (0, _jquery.default)(this).index();
      that.state_currents = (0, _jquery.default)(this).attr('data-state');
      that.$emit('state-currents', that.state_currents);
    });
  },
  methods: {
    mouseoverFun: function mouseoverFun(index) {
      this.hoverIndex = index;
    },
    mouseoutFun: function mouseoutFun() {
      this.hoverIndex = null;
    }
  }
};
exports.default = _default;