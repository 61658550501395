import { render, staticRenderFns } from "./icons.vue?vue&type=template&id=049ffd60&scoped=true&"
import script from "./icons.vue?vue&type=script&lang=js&"
export * from "./icons.vue?vue&type=script&lang=js&"
import style0 from "./icons.vue?vue&type=style&index=0&id=049ffd60&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049ffd60",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('049ffd60')) {
      api.createRecord('049ffd60', component.options)
    } else {
      api.reload('049ffd60', component.options)
    }
    module.hot.accept("./icons.vue?vue&type=template&id=049ffd60&scoped=true&", function () {
      api.rerender('049ffd60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BookPageEditor/icons.vue"
export default component.exports