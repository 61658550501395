var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-resource-footer-layout" }, [
    _c(
      "div",
      { staticClass: "select-resource-bg" },
      [
        _vm.showPagination
          ? _c("el-pagination", {
              attrs: {
                total: _vm.total,
                "current-page": _vm.valueProxy.page,
                "page-sizes": [10, 20, 50, 100],
                "page-size": _vm.valueProxy.size,
                layout: "total, sizes, prev, pager, next, jumper",
                background: ""
              },
              on: {
                "update:currentPage": function($event) {
                  return _vm.$set(_vm.valueProxy, "page", $event)
                },
                "update:current-page": function($event) {
                  return _vm.$set(_vm.valueProxy, "page", $event)
                },
                "update:pageSize": function($event) {
                  return _vm.$set(_vm.valueProxy, "size", $event)
                },
                "update:page-size": function($event) {
                  return _vm.$set(_vm.valueProxy, "size", $event)
                }
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function($event) {
                return _vm.$emit("cancel")
              }
            }
          },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: {
              click: function($event) {
                return _vm.$emit("submit")
              }
            }
          },
          [_vm._v("选择")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }