var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dictionary-container" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "dictionary-tabs-bg",
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.selectTabs }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "微服务应用表" } },
            [_vm.table_name === "微服务应用表" ? _c("AppBackend") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "产品应用表" } },
            [_vm.table_name === "产品应用表" ? _c("AppProduct") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "社会化应用表" } },
            [_vm.table_name === "社会化应用表" ? _c("AppSocial") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "资源类型表" } },
            [_vm.table_name === "资源类型表" ? _c("ResourceType") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "物流渠道表" } },
            [_vm.table_name === "物流渠道表" ? _c("PostCompany") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "学段" } },
            [_vm.table_name === "学段" ? _c("LearningSection") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "学科" } },
            [_vm.table_name === "学科" ? _c("Subject") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "年级" } },
            [_vm.table_name === "年级" ? _c("Grade") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "版本" } },
            [_vm.table_name === "版本" ? _c("Edition") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "阶段" } },
            [_vm.table_name === "阶段" ? _c("Volume") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "版次" } },
            [_vm.table_name === "版次" ? _c("Period") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "地区" } },
            [_vm.table_name === "地区" ? _c("BookArea") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "选必修" } },
            [_vm.table_name === "选必修" ? _c("OptionalRequired") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "省市县" } },
            [_vm.table_name === "省市县" ? _c("Provinces") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "学校" } },
            [_vm.table_name === "学校" ? _c("School") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "标签管理" } },
            [_vm.table_name === "标签管理" ? _c("UserTag") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "页面配置" } },
            [_vm.table_name === "页面配置" ? _c("page-setting") : _vm._e()],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }