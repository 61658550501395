"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _RadioSelectLink = _interopRequireDefault(require("./RadioSelectLink"));

var _MultipleSelectLink = _interopRequireDefault(require("./MultipleSelectLink"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectLink',
  components: {
    MultipleSelectLink: _MultipleSelectLink.default,
    RadioSelectLink: _RadioSelectLink.default
  },
  props: {
    value: {
      type: [Object, Array],
      default: function _default() {
        return [{}, []];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    multipleCheck: {
      type: Boolean,
      default: false
    },
    // 多选
    partnerOpenId: {
      type: String,
      default: ''
    },
    propsActiveName: {
      type: String,
      default: undefined
    },
    // 选项卡
    audioBookOpenId: {
      type: String,
      default: ''
    },
    // 指定某本点学书
    isNotModel: {
      type: Boolean,
      default: false
    },
    // 不要 v-model这种类型的
    isReplace: {
      type: Boolean,
      default: false
    },
    // 只有多选替换有
    replaceIndex: {
      type: Number,
      default: undefined
    },
    // 只有多选替换有
    showWord: {
      type: Boolean,
      default: false
    },
    tool: {
      type: String,
      default: ''
    },
    // 词语单词工具
    isExtend: {
      type: Boolean,
      default: false
    } // 是否是拓展学习

  },
  data: function data() {
    return {
      open: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.open = val;
      }
    },
    open: function open(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    submitLink: function submitLink(val) {
      this.$emit('submitLink', val);
    }
  }
};
exports.default = _default2;