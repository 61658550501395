var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "poetry-extend-study" },
    [
      _c(
        "el-form",
        {
          staticClass: "poetry-extend-study-form",
          attrs: { model: _vm.valueProxy }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "学习链接" } },
            [
              !_vm.value.learningLink
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini", plain: "" },
                      on: {
                        click: function($event) {
                          _vm.openSelect = true
                        }
                      }
                    },
                    [_vm._v("选择连接")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.value.learningLink
                ? _c("div", { staticClass: "link" }, [
                    _c("span", { staticClass: "link-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.value.learningLink
                            ? _vm.value.learningLink.display.title
                            : undefined
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("i", {
                        staticClass: "el-icon-sort change-link-icon",
                        on: {
                          click: function($event) {
                            _vm.openSelect = true
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-delete link-icon",
                        on: { click: _vm.deleteLink }
                      })
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "诗词练习" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.toOpenPaper }
                },
                [_vm._v("添加试卷")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showPaper
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "paper-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        border: "",
                        data: _vm.valueProxy.examPaperOpenIds,
                        "row-key": "examPaperOpenId",
                        "header-cell-style": { background: "#F5F7FA" }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "名称", prop: "name", align: "left" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "80px",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "替换",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-sort video-check replace-icon",
                                        on: {
                                          click: function($event) {
                                            return _vm.replacePaper(
                                              scope.row,
                                              scope.$index
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "删除",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-delete video-check",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleRemove(
                                              scope.row.resourceOpenId,
                                              scope.$index
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2037750539
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.openSelect },
        on: {
          "update:visible": function($event) {
            _vm.openSelect = $event
          }
        },
        model: {
          value: _vm.value.learningLink,
          callback: function($$v) {
            _vm.$set(_vm.value, "learningLink", $$v)
          },
          expression: "value.learningLink"
        }
      }),
      _vm._v(" "),
      _vm.openPaper
        ? _c("select-test-paper", {
            attrs: {
              "dialog-visible": _vm.openPaper,
              "check-list": _vm.checkList,
              "replace-index": _vm.replaceIndex,
              "show-type": _vm.showType
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.openPaper = $event
              },
              "update:dialog-visible": function($event) {
                _vm.openPaper = $event
              },
              handleClose: function($event) {
                _vm.openPaper = false
              },
              addSuccess: _vm.addSuccess
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }