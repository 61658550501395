var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vocabulary-picture" },
    [
      _c("cover-upload", {
        staticClass: "picture",
        attrs: {
          type: _vm.Vocabulary_Picture_ID.toString(),
          coverarr: _vm.coverSize,
          texttips: "上传",
          covertext: "",
          path: "wordLibrary/vocabulary/album/",
          "upload-hide-icon": true,
          "preview-z-index": 3000
        },
        on: {
          deletecover: function($event) {
            _vm.vModelProxy.picture = undefined
          }
        },
        model: {
          value: _vm.vModelProxy.picture,
          callback: function($$v) {
            _vm.$set(_vm.vModelProxy, "picture", $$v)
          },
          expression: "vModelProxy.picture"
        }
      }),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "describe",
        attrs: { type: "textarea", rows: 2, placeholder: "请输入内容" },
        model: {
          value: _vm.vModelProxy.describe,
          callback: function($$v) {
            _vm.$set(_vm.vModelProxy, "describe", $$v)
          },
          expression: "vModelProxy.describe"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c("i", { staticClass: "el-icon-rank" }),
        _vm._v(" "),
        _c("i", {
          staticClass: "el-icon-remove-outline",
          on: {
            click: function($event) {
              return _vm.$emit("remove")
            }
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }