"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _selectFrontDesk = _interopRequireDefault(require("./selectFrontDesk.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    draggable: _vuedraggable.default,
    selectFrontDesk: _selectFrontDesk.default
  },
  props: {
    /* 标题 */
    title: {
      type: String,
      default: ''
    },
    dragData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    handle: {
      type: String,
      default: '选择汉字'
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    screeHeight: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      deskDialogShow: false,
      replaceMap: {},
      checkList: []
    };
  },
  watch: {
    checkList: function checkList(newVal) {
      this.$emit('changeDrag', newVal);
    },
    dragData: {
      handler: function handler(val) {
        this.checkList = val;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    /* 拖拽 START */
    move: function move() {},
    start: function start() {},
    end: function end() {},

    /* 拖拽 END */

    /* 处理移除事件 */
    remove: function remove(i) {
      this.dragData.splice(i, 1);
    },
    openBox: function openBox() {
      this.deskDialogShow = true;
    },

    /* 获取选择的相近字 */
    getSelectWord: function getSelectWord(checkList) {
      this.checkList = checkList;
    },
    getReplace: function getReplace(map) {
      var i = map.index;
      this.checkList[i] = map.name;
    },

    /* 相近字弹窗显示处理 */
    parentEvent: function parentEvent(show) {
      this.deskDialogShow = show;
    }
  }
};
exports.default = _default2;