"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'customList',
  props: {
    merchantTitleList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      checkList: []
    };
  },
  computed: {
    comData: {
      get: function get() {
        return this.area;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  created: function created() {
    this.checkList = this.merchantTitleList;
  },
  methods: {
    changeMerchantTitleList: function changeMerchantTitleList() {
      this.$emit('changeMerchantTitleList', this.checkList);
    }
  }
};
exports.default = _default2;