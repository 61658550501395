var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "attributeForm",
      staticClass: "resource-attribute-form",
      attrs: {
        rules: _vm.attributeRules,
        model: _vm.valueProxy,
        "label-width": "60",
        "label-position": "right"
      }
    },
    [
      _c("el-form-item", { attrs: { label: "" } }, [
        _c(
          "div",
          { staticClass: "form-check-bg" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "form-check",
                attrs: { label: "1", name: "type" },
                model: {
                  value: _vm.value.isChangeSync,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "isChangeSync", $$v)
                  },
                  expression: "value.isChangeSync"
                }
              },
              [_vm._v("同步")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-content-bg" },
          [
            _c("sync-view", {
              attrs: { "is-disabled": !_vm.value.isChangeSync },
              model: {
                value: _vm.value.tempCode,
                callback: function($$v) {
                  _vm.$set(_vm.value, "tempCode", $$v)
                },
                expression: "value.tempCode"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: "" } }, [
        _c(
          "div",
          { staticClass: "form-check-bg" },
          [
            _c(
              "el-checkbox",
              {
                attrs: { label: "2" },
                model: {
                  value: _vm.value.isChangeTypes,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "isChangeTypes", $$v)
                  },
                  expression: "value.isChangeTypes"
                }
              },
              [_vm._v("分类")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-content-bg" },
          [
            _c("works", {
              ref: "worksView",
              attrs: {
                "is-disabled": !_vm.value.isChangeTypes,
                "edit-hide": true
              },
              on: { "add-classtion": _vm.addClasstion }
            }),
            _vm._v(" "),
            _c("el-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: { value: _vm.value.types }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: "", prop: "periodId" } }, [
        _c(
          "div",
          { staticClass: "form-check-bg periodId" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "form-check",
                attrs: { label: "3", name: "type" },
                model: {
                  value: _vm.value.isChangePeriod,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "isChangePeriod", $$v)
                  },
                  expression: "value.isChangePeriod"
                }
              },
              [_c("span", { staticClass: "period-text" }, [_vm._v("版次")])]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-content-bg" },
          [
            _c("editionbox", {
              model: {
                value: _vm.value.periodId,
                callback: function($$v) {
                  _vm.$set(_vm.value, "periodId", $$v)
                },
                expression: "value.periodId"
              }
            }),
            _vm._v(" "),
            _c("el-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              model: {
                value: _vm.value.periodId,
                callback: function($$v) {
                  _vm.$set(_vm.value, "periodId", $$v)
                },
                expression: "value.periodId"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: "" } }, [
        _c(
          "div",
          { staticClass: "form-check-bg" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "form-check",
                attrs: { label: "4", name: "type" },
                model: {
                  value: _vm.value.isChangeRegimentation,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "isChangeRegimentation", $$v)
                  },
                  expression: "value.isChangeRegimentation"
                }
              },
              [_vm._v("类目")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-content-bg" },
          [
            _c("category-view", {
              ref: "category",
              attrs: { "is-disabled": !_vm.value.isChangeRegimentation },
              on: { "category-change": _vm.categoryChange },
              model: {
                value: _vm.value.secondRegimentationIds,
                callback: function($$v) {
                  _vm.$set(_vm.value, "secondRegimentationIds", $$v)
                },
                expression: "value.secondRegimentationIds"
              }
            }),
            _vm._v(" "),
            _c("el-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              model: {
                value: _vm.value.secondRegimentationIds,
                callback: function($$v) {
                  _vm.$set(_vm.value, "secondRegimentationIds", $$v)
                },
                expression: "value.secondRegimentationIds"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: "" } }, [
        _c(
          "div",
          { staticClass: "form-check-bg" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "form-check",
                attrs: { label: "4", name: "type" },
                model: {
                  value: _vm.value.isChangeTags,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "isChangeTags", $$v)
                  },
                  expression: "value.isChangeTags"
                }
              },
              [_vm._v("标签")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-content-bg" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: !_vm.value.isChangeTags,
                  multiple: "",
                  filterable: "",
                  "allow-create": "",
                  "default-first-option": "",
                  placeholder: "请输入或选择商品标签"
                },
                on: { change: _vm.changeTags },
                model: {
                  value: _vm.tagValue,
                  callback: function($$v) {
                    _vm.tagValue = $$v
                  },
                  expression: "tagValue"
                }
              },
              _vm._l(_vm.tagOptions, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              model: {
                value: _vm.value.tagValue,
                callback: function($$v) {
                  _vm.$set(_vm.value, "tagValue", $$v)
                },
                expression: "value.tagValue"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticStyle: { "margin-bottom": "0" }, attrs: { label: "" } },
        [
          _c(
            "div",
            { staticClass: "form-check-bg" },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "form-check",
                  attrs: { label: "4", name: "type" },
                  model: {
                    value: _vm.value.isChangeArea,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "isChangeArea", $$v)
                    },
                    expression: "value.isChangeArea"
                  }
                },
                [_vm._v("地区")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-content-bg" },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: !_vm.value.isChangeArea,
                    multiple: "",
                    filterable: "",
                    "allow-create": "",
                    "default-first-option": "",
                    placeholder: "请输入或选择地区"
                  },
                  on: { change: _vm.changeArea },
                  model: {
                    value: _vm.areaValue,
                    callback: function($$v) {
                      _vm.areaValue = $$v
                    },
                    expression: "areaValue"
                  }
                },
                _vm._l(_vm.areaList, function(item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                model: {
                  value: _vm.value.areaValue,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "areaValue", $$v)
                  },
                  expression: "value.areaValue"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }