var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-number-input" },
    [
      _vm.leftText
        ? _c("div", { staticClass: "text-label left-text" }, [
            _vm._v("\n    " + _vm._s(_vm.leftText) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPrecision
        ? _c("el-input-number", {
            attrs: {
              min: _vm.min,
              max: _vm.max,
              controls: false,
              size: "mini",
              precision: _vm.precision
            },
            on: { blur: _vm.blur, change: _vm.change },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        : _c("el-input-number", {
            attrs: {
              min: _vm.min,
              max: _vm.max,
              controls: false,
              size: "mini"
            },
            on: { blur: _vm.blur, change: _vm.change },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          }),
      _vm._v(" "),
      _vm.rightText
        ? _c("div", { staticClass: "text-label right-text" }, [
            _vm._v("\n    " + _vm._s(_vm.rightText) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }