var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("original-selector", {
    attrs: {
      multiple: _vm.multiple,
      "is-disabled": _vm.isDisabled,
      "middle-open-id": _vm.middleOpenId,
      "shop-open-id": _vm.shopOpenId,
      "use-default-options": _vm.useDefaultOptions,
      "default-options": _vm.defaultOptions
    },
    on: {
      change: function($event) {
        return _vm.$emit("change")
      }
    },
    model: {
      value: _vm.data,
      callback: function($$v) {
        _vm.data = $$v
      },
      expression: "data"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }