var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-book-content" },
    [
      _c("update", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.updatePageLoading,
            expression: "updatePageLoading"
          }
        ],
        attrs: { "open-id": _vm.openId, state: _vm.book.status }
      }),
      _vm._v(" "),
      _c("works-operation-buttton", {
        attrs: {
          openId: _vm.openId,
          allowEdit: _vm.allowEdit,
          operation: "update",
          status: _vm.book.status,
          passBtn: _vm.passBtn,
          refuseBtn: _vm.refuseBtn,
          restoreBtn: _vm.restoreBtn,
          publishBtn: _vm.publishBtn,
          saveBtn: _vm.saveBtn,
          saveDraftBtn: _vm.saveDraftBtn
        },
        on: {
          closeView: _vm.cancel,
          updateEdit: _vm.updateEdit,
          passItem: _vm.passItem,
          handelRefuse: _vm.handelRefuse,
          handelSaveAndReview: _vm.handelSaveAndReview,
          handleBookRestore: _vm.handleBookRestore,
          saveData: _vm.saveData,
          saveDraft: _vm.saveDraft
        }
      }),
      _vm._v(" "),
      _c("refuse-resource", {
        attrs: { value: _vm.dialogRefuse },
        on: {
          "update:value": function($event) {
            _vm.dialogRefuse = $event
          },
          "refuse-obj": _vm.refuseObj
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }