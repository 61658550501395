var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            disabled: _vm.isDisabled,
            filterable: "",
            clearable: "",
            "allow-create": "",
            "default-first-option": "",
            placeholder: "请输入或选择商品标签"
          },
          model: {
            value: _vm.tagValue,
            callback: function($$v) {
              _vm.tagValue = $$v
            },
            expression: "tagValue"
          }
        },
        _vm._l(_vm.tagOptions, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }