var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "obj-remark-box" }, [
    _c(
      "div",
      {
        class: [
          "obj-remark-bg",
          _vm.resourceObj && _vm.resourceObj.isRemark
            ? "obj-remark-bg1"
            : "obj-remark-bg2"
        ]
      },
      [
        _vm.resourceObj && _vm.resourceObj.rejectReason
          ? _c("div", { staticClass: "object-reason-box" }, [
              _c("label", { staticClass: "reason-label" }, [
                _vm._v("驳回原因")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "reason-txt" }, [
                _c("span", { staticClass: "stop-sold-at" }, [
                  _vm._v(_vm._s(_vm.resourceObj.rejectAt) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "stop-sold-reason",
                    attrs: { title: _vm.resourceObj.stopSoldReason }
                  },
                  [_vm._v("原因：" + _vm._s(_vm.resourceObj.rejectReason))]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.resourceObj && _vm.resourceObj.stopSoldByOfficialReason
          ? _c("div", { staticClass: "object-reason-box" }, [
              _c("label", { staticClass: "reason-label" }, [
                _vm._v("官方停售")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "reason-txt" }, [
                _c("span", { staticClass: "stop-sold-at" }, [
                  _vm._v(_vm._s(_vm.resourceObj.stopSoldByOfficialAt) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "stop-sold-reason",
                    attrs: { title: _vm.resourceObj.stopSoldByOfficialReason }
                  },
                  [
                    _vm._v(
                      "原因：" +
                        _vm._s(_vm.resourceObj.stopSoldByOfficialReason)
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.resourceObj && _vm.resourceObj.hiddenByOfficialAt
          ? _c("div", { staticClass: "object-reason-box" }, [
              _c("label", { staticClass: "reason-label" }, [
                _vm._v("官方隐藏")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "reason-txt" }, [
                _c("span", { staticClass: "stop-sold-at" }, [
                  _vm._v(_vm._s(_vm.resourceObj.hiddenByOfficialAt))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.resourceObj && _vm.resourceObj.stopSoldReason
          ? _c("div", { staticClass: "object-reason-box" }, [
              _c("label", { staticClass: "reason-label" }, [
                _vm._v("商户停售")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "reason-txt" }, [
                _c("span", { staticClass: "stop-sold-at" }, [
                  _vm._v(_vm._s(_vm.resourceObj.stopSoldAt) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "stop-sold-reason",
                    attrs: { title: _vm.resourceObj.stopSoldReason }
                  },
                  [_vm._v("原因：" + _vm._s(_vm.resourceObj.stopSoldReason))]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.resourceObj && _vm.resourceObj.hiddenAt
          ? _c("div", { staticClass: "object-reason-box" }, [
              _c("label", { staticClass: "reason-label" }, [
                _vm._v("商户隐藏")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "reason-txt" }, [
                _c("span", { staticClass: "stop-sold-at" }, [
                  _vm._v(_vm._s(_vm.resourceObj.hiddenAt))
                ])
              ])
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "remark-spand-bg" }, [
      _vm.resourceObj && _vm.resourceObj.remarkCount > 1
        ? _c("i", {
            class: [
              "obj-remark-spand",
              _vm.resourceObj && _vm.resourceObj.isRemark
                ? "el-icon-arrow-down"
                : "el-icon-arrow-up"
            ],
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.foldRemark(_vm.resourceObj)
              }
            }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }