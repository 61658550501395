"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'specialList.vue',
  props: {
    editionList: {
      type: Array
    }
  },
  data: function data() {
    return {
      mouseid: '',
      defaultProps: {
        label: 'abbr'
      },
      type: ''
    };
  },
  created: function created() {},
  methods: {
    getHalfCheckEvent: function getHalfCheckEvent(node) {
      this.mouseid = node.id;
      this.$emit('getEditionCode', node.code);
    }
  }
};
exports.default = _default;