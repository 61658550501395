"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _axios = _interopRequireDefault(require("axios"));

var _config = _interopRequireDefault(require("./config"));

var _onRequest = require("./onRequest");

var _onResponse = require("./onResponse");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 创建axios实例
var service = null;
/**
 * 创建一个 axios request
 *
 * @param {string} baseUrl
 * @param {object} config
 * @param {boolean} filled
 * @param {boolean} errorHandling 如果为 true，表示会在框架底层拦截系统错误。如果为 false，表示不在框架底层拦截错误。
 * @returns {AxiosInstance}
 */

function createRequest() {
  var baseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : process.env.BASE_API;
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$filled = _ref.filled,
      filled = _ref$filled === void 0 ? true : _ref$filled,
      _ref$errorHandling = _ref.errorHandling,
      errorHandling = _ref$errorHandling === void 0 ? true : _ref$errorHandling;

  service = _axios.default.create(_objectSpread(_objectSpread({}, _config.default), {}, {
    baseURL: baseUrl
  }, config)); // request拦截器

  if (filled) {
    service.interceptors.request.use(_onRequest.onRequestFulfilled, _onRequest.onRequestRejected);
  } else {
    service.interceptors.request.use(undefined, _onRequest.onRequestRejected);
  } // response 拦截器


  service.interceptors.response.use(_onResponse.onResponseFulfilled, function (e) {
    return (0, _onResponse.onResponseRejected)(e, {
      errorHandling: errorHandling
    });
  });
  return service;
}

var _default = createRequest;
exports.default = _default;