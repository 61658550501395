import { render, staticRenderFns } from "./wordAdministration.vue?vue&type=template&id=52296fc0&scoped=true&"
import script from "./wordAdministration.vue?vue&type=script&lang=js&"
export * from "./wordAdministration.vue?vue&type=script&lang=js&"
import style0 from "./wordAdministration.vue?vue&type=style&index=0&id=52296fc0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52296fc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52296fc0')) {
      api.createRecord('52296fc0', component.options)
    } else {
      api.reload('52296fc0', component.options)
    }
    module.hot.accept("./wordAdministration.vue?vue&type=template&id=52296fc0&scoped=true&", function () {
      api.rerender('52296fc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/wordLibrary/wordAdministration.vue"
export default component.exports