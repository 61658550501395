"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatFriendlyTime = formatFriendlyTime;

require("core-js/modules/es6.regexp.replace");

var _dayjs = _interopRequireDefault(require("dayjs"));

require("dayjs/locale/zh-cn");

var _relativeTime = _interopRequireDefault(require("dayjs/plugin/relativeTime"));

_dayjs.default.locale('zh-cn');

_dayjs.default.extend(_relativeTime.default);
/**
 * 格式化成友好时间
 * 参照 https://dayjs.gitee.io/docs/zh-CN/display/from-now
 */


function formatFriendlyTime(time) {
  // 不超过26天
  if ((0, _dayjs.default)().diff((0, _dayjs.default)(time), 'd') < 26) {
    return (0, _dayjs.default)(time).fromNow().replace(/\s*/g, '');
  }

  if ((0, _dayjs.default)().year() === (0, _dayjs.default)(time).year()) {
    // 超过26天并且是当年
    return (0, _dayjs.default)(time).format('M月D日');
  } // 不是当年


  return (0, _dayjs.default)(time).format('YYYY年M月D日');
}