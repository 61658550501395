"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDateDiff = getDateDiff;
exports.getCookie = getCookie;
exports.setCookie = setCookie;
exports.removeCookie = removeCookie;
exports.dotTime = dotTime;
exports.msgHandle = msgHandle;
exports.notifyHandle = notifyHandle;
exports.changeUnit = changeUnit;
exports.getImageUrl = getImageUrl;
exports.imageUrlFun = imageUrlFun;
exports.showVal = showVal;
exports.toPercent = toPercent;
exports._debounce = _debounce;
exports.errorUserPhoto = errorUserPhoto;
exports.toStringOrTrim = toStringOrTrim;
exports.ossUploadUrl = ossUploadUrl;
exports.numFormat = numFormat;
exports.successNotice = successNotice;
exports.operatorIDCardFront = operatorIDCardFront;
exports.operatorIDCardBackend = operatorIDCardBackend;
exports.resourcetypetoStr = resourcetypetoStr;
exports.formatRouteName = formatRouteName;
exports.buyTypetoStr = buyTypetoStr;
exports.formatLongToTimeStr = formatLongToTimeStr;
exports.showPayType = showPayType;
exports.showChannel = showChannel;
exports.sortKey = sortKey;
exports.dateFormatFun = dateFormatFun;
exports.replaceEmoji = replaceEmoji;
exports.dateToTimestamp = dateToTimestamp;
exports.formatLongToTimeStr2 = formatLongToTimeStr2;
exports.checkPhone = checkPhone;
exports.dateDiff = dateDiff;
exports.randumcolor = randumcolor;
exports.format_number = format_number;
exports.isJSON = isJSON;
exports.filterRich = filterRich;
exports.decryptData = decryptData;
exports.handleResourceListStore = handleResourceListStore;
exports.handleResourceListQuery = handleResourceListQuery;
exports.handleEchoResourceListQuery = handleEchoResourceListQuery;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es7.string.pad-start");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.to-string");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _qs = _interopRequireDefault(require("qs"));

var _elementUi = require("element-ui");

var _url = _interopRequireDefault(require("url"));

var _path = _interopRequireDefault(require("path"));

var _cryptoJs = _interopRequireDefault(require("crypto-js"));

var _payType = _interopRequireDefault(require("@/assets/json/payType"));

var _enjoyList = _interopRequireDefault(require("@/assets/json/enjoyList"));

var _main = _interopRequireDefault(require("../organizationLibs/typeIs/main"));

/* eslint-disable no-unreachable */
function getCookie(attr) {
  return _jsCookie.default.get(attr);
}

function setCookie(attr, value) {
  return _jsCookie.default.set(attr, value);
}

function removeCookie(attr) {
  return _jsCookie.default.remove(attr);
}

function dotTime(time) {
  var str = '';

  if (time > 60) {
    parseInt(time / 60).toString().length >= 2 ? str = "".concat(parseInt(time / 60).toString(), ":") : str = "0".concat(parseInt(time / 60).toString(), ":");
    time % 60 >= 10 ? str += (time % 60).toString() : str += "0".concat((time % 60).toString());
  } else {
    time >= 10 ? str = "00:".concat(time) : str = "00:0".concat(time);
  }

  return str;
}

function msgHandle(msg, level) {
  if (!msg) {
    msg = '获取数据失败';
  }

  (0, _elementUi.Message)({
    message: msg,
    type: level
  });
}

function notifyHandle(title, msg, level) {
  if (!msg) {
    msg = '获取数据失败';
  }

  (0, _elementUi.Notification)({
    title: title,
    message: msg,
    type: level
  });
}

function changeUnit(limit) {
  var size = '';

  if (limit < 0.1 * 1024) {
    // 小于0.1KB，则转化成B
    size = "".concat(limit.toFixed(2), "B");
  } else if (limit < 0.1 * 1024 * 1024) {
    // 小于0.1MB，则转化成KB
    size = "".concat((limit / 1024).toFixed(2), "KB");
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    // 小于0.1GB，则转化成MB
    size = "".concat((limit / (1024 * 1024)).toFixed(2), "MB");
  } else {
    // 其他转化成GB
    size = "".concat((limit / (1024 * 1024 * 1024)).toFixed(2), "GB");
  }

  var sizeStr = "".concat(size); // 转成字符串

  var index = sizeStr.indexOf('.'); // 获取小数点处的索引

  var dou = sizeStr.substr(index + 1, 2); // 获取小数点后两位的值

  if (dou === '00') {
    // 判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }

  return size;
}

function getImageUrl(url, style) {
  var imgUrl = "".concat(process.env.VUE_APP_IMG_URL_PREFIX + process.env.VUE_APP_OSS_PATH, "/").concat(url);

  if (style && style.length > 0) {
    imgUrl += "?".concat(style);
  }

  return imgUrl;
} // 图片路径拼接新方法


function imageUrlFun(url, style) {
  if (!url) {
    return undefined;
  }

  var isHtp = url.substring(0, 4) === 'http';

  if (isHtp) {
    return url;
  }

  var image = "".concat(process.env.VUE_APP_OSS_URL).concat(process.env.VUE_APP_OSS_PATH, "/").concat(url); // 判断是否是gif

  var path = url.split('.');

  if (path[path.length - 1] === 'gif' || path[path.length - 1] === 'svg' || !style) {
    return image;
  }

  var query = _qs.default.stringify({
    'x-oss-process': style
  });

  return query ? "".concat(image, "?").concat(query) : image;
}
/* 字段为空 默认值显示为 --*/


function showVal(val) {
  return val === undefined || val === null || val === '' ? '--' : val;
}

function toPercent(point) {
  var str = "".concat(Number(point * 100).toFixed(0), "%");
  return str;
} // 防抖


function _debounce(fn, delay) {
  var delays = delay || 200;
  var timer;
  return function () {
    var th = this;
    var args = arguments;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delays);
  };
}

function errorUserPhoto() {
  return "this.src=\"".concat(require("../assets/book_cover.png"), "\"");
}
/* 正面证件照 */


function operatorIDCardFront() {
  return "".concat(require("../assets/operatorIDCardFront.png"));
}
/* 背面证件照 */


function operatorIDCardBackend() {
  return "".concat(require("../assets/operatorIDCardBackend.png"));
}
/**
 * 将任意对象转换为 string
 *
 * @param {*} target
 * @param {string|undefined} defaultValue
 * @returns {string|undefined}
 */


function anyTypeToString(target, defaultValue) {
  try {
    return _main.default.isString(target) ? target : target.toString();
  } catch (e) {
    return defaultValue;
  }
}
/**
 * 将一个对象转换为 string 并且 trim
 * 主意，如果目标是空字符串，则会变为 undefined
 *
 * @param {*} target
 * @param {string|undefined} defaultValue
 * @returns {string|undefined}
 */


function toStringOrTrim(target) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  // 进行 trim
  var trimed;

  if (_main.default.isString(target)) {
    trimed = target.trim();
  } else {
    trimed = anyTypeToString(target, defaultValue);
  } // 如果 trim 结果是空字符串，则重置为 undefined


  return trimed || undefined;
} // 格式化金额


function numFormat(x) {
  // 强制保留两位小数
  // eslint-disable-next-line no-var
  var f = parseFloat(x); // eslint-disable-next-line no-restricted-globals

  if (isNaN(f)) return 0; // eslint-disable-next-line vars-on-top,no-redeclare,no-var

  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');

  if (rs < 0) {
    rs = s.length;
    s += '.';
  }

  while (s.length < rs + 1 + 2) {
    s += '0';
  } // 每三位用一个逗号隔开


  var leftNum = s.split('.')[0];
  var rightNum = ".".concat(s.split('.')[1]);
  var result; // 定义数组记录截取后的价格
  // eslint-disable-next-line no-array-constructor

  var resultArray = new Array();

  if (leftNum.length > 3) {
    // eslint-disable-next-line no-var,no-plusplus,no-redeclare,vars-on-top,block-scoped-var
    var i = true; // eslint-disable-next-line block-scoped-var

    while (i) {
      resultArray.push(leftNum.slice(-3));
      leftNum = leftNum.slice(0, leftNum.length - 3);

      if (leftNum.length < 4) {
        // eslint-disable-next-line block-scoped-var
        i = false;
      }
    } // 由于从后向前截取，所以从最后一个开始遍历并存到一个新的数组，顺序调换
    // eslint-disable-next-line no-array-constructor


    var sortArray = new Array(); // eslint-disable-next-line no-var,no-plusplus,no-redeclare,vars-on-top

    for (var i = resultArray.length - 1; i >= 0; i--) {
      sortArray.push(resultArray[i]);
    }

    result = "".concat(leftNum, ",").concat(sortArray.join(',')).concat(rightNum);
  } else {
    result = s;
  }

  return result;
}

function format_number(n) {
  var b = Math.ceil(n).toString();
  var len = b.length;

  if (len <= 3) {
    return b;
  }

  var r = len % 3; // b.slice(r,len).match(/\d{3}/g).join(",") 每三位数加一个逗号。

  return r > 0 ? "".concat(b.slice(0, r), ",").concat(b.slice(r, len).match(/\d{3}/g).join(',')) : b.slice(r, len).match(/\d{3}/g).join(',');
}
/**
 * 获取 oss 中 upload 方式的文件地址，如果是图片，则可以使用 style
 *
 * @param {string} url
 * @param {string} style
 * @returns {string}
 */


function ossUploadUrl(url, style) {
  if (!url) {
    return undefined;
  }

  try {
    // 拼接 url
    var imagePath = _path.default.resolve(process.env.VUE_APP_OSS_PATH, url);

    var image = _url.default.resolve(process.env.VUE_APP_OSS_URL, imagePath); // 格式化 query 参数


    var query = _qs.default.stringify({
      'x-oss-process': style
    }); // 如果有 query 参数，通过 ? 拼接


    return query ? "".concat(image, "?").concat(query) : image;
  } catch (e) {
    console.error(e); // 如果发生错误，回退使用简单模式

    return "".concat(process.env.VUE_APP_OSS_URL).concat(process.env.VUE_APP_ENVIRONMENT, "/").concat(url);
  }
}

function successNotice(_this, title, message) {
  _this.$notify({
    title: _this.$t("alert.".concat(title)),
    message: _this.$t("alert.".concat(message)),
    type: 'success'
  });
}
/* 资源类型转化成文本 */


function resourcetypetoStr(resourceType) {
  switch (Number(resourceType)) {
    case 1:
      return '视频';
      break;

    case 2:
      return '音频';
      break;

    case 3:
      return '点学书';
      break;

    case 4:
      return '专辑';
      break;

    case 5:
      return '金星币';
      break;

    case 6:
      return 'VIP';
      break;

    case 7:
      return '跟读书';
      break;

    case 8:
      return '文件包';
      break;

    case 9:
      return '图文';
      break;

    case 10:
      return '文件';
      break;

    case 11:
      return '直播';
      break;

    case 14:
      return '范文';
      break;

    case 15:
      return '实物商品';
      break;

    case 16:
      return '大专辑';
      break;

    case 19:
      return '英语会话';
      break;

    default:
      return resourceType || '--';
  }
}
/* 购买类型转化成文本 */


function buyTypetoStr(buyType) {
  switch (Number(buyType)) {
    case 1:
      return '现金';
      break;

    case 2:
      return '金星币';
      break;

    case 3:
      return '激活卡购买';
      break;

    case 4:
      return '赠品';
      break;

    case 5:
      return '后台添加';
      break;

    case 6:
      return '原系统导入';
      break;

    case 7:
      return 'IOS余额';
      break;

    case 8:
      return 'android余额购买';
      break;

    case 9:
      return '活动赠送';
      break;

    default:
      return '--';
  }
}
/* 格式化浏览记录跳转路由名字 */


function formatRouteName(resourceType, id, resourceCatalog) {
  if (resourceType == 1) {
    return 'pages/resource/video/index?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 2) {
    return 'pages/resource/audio/index?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 3) {
    return 'pages/resource/audiobook/index?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 4) {
    return 'pages/resource/album/index?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 8) {
    return 'pages/resource/file/file?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 10) {
    return 'pages/resource/fileDetails/fileDetails?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 9) {
    return 'pages/resource/imageAndText/imageAndText?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 14) {
    return 'pages/fanWenKu/modelText/index?' + 'openId=' + "".concat(id);
  }

  if (resourceType == 15) {
    return 'pages/resource/realCommodity/realCommodity?' + 'openId=' + "".concat(id);
  }

  if (resourceType == '名师') {
    return 'pages/index/famousTeacher/famousDetails?' + 'openId=' + "".concat(id);
  }

  if (resourceType == '公开课') {
    return 'pages/index/liveCenter/liveCenter?' + 'openId=' + "".concat(id);
  }

  if (resourceType === '直播中心') {
    return 'pages/index/liveCenter/liveCenter?' + 'openId=' + "".concat(id);
  }

  if (resourceType === '名师') {
    return 'pages/index/famousTeacher/famousDetails?' + 'openId=' + "".concat(id);
  }

  if (resourceType === '增值服务') {
    return 'pages/book?' + 'bId=' + "".concat(id);
  }

  if (resourceType === '增值服务-视频') {
    return 'pages/book/video?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-文件') {
    return 'pages/book/fileDetails?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-音频') {
    return 'pages/book/audio?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-文件包') {
    return 'pages/book/file?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-图文') {
    return 'pages/book/article?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-范文') {
    return 'pages/book/zw?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-目录') {
    return 'pages/book/menu?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }

  if (resourceType === '增值服务-目录') {
    return 'pages/book/menu?' + 'bId=' + "".concat(id).concat(resourceCatalog);
  }
} // 将毫秒转换成时分秒


function formatLongToTimeStr(time) {
  var t = '';
  var second = parseInt(time / 1000);

  if (second > -1) {
    var hour = Math.floor(second / 3600);
    var min = Math.floor(second / 60) % 60;
    var sec = second % 60;

    if (hour < 10) {
      t = "0".concat(hour, ":");
    } else {
      t = "".concat(hour, ":");
    }

    if (min < 10) {
      t += '0';
    }

    t += "".concat(min, ":");

    if (sec < 10) {
      t += '0';
    }

    t += sec;
  }

  return t;
} // 时间转化毫秒转化成分秒


function formatLongToTimeStr2(time) {
  var t = '';
  var second = parseInt(time / 1000);

  if (second > -1) {
    var hour = Math.floor(second / 3600);
    var min = Math.floor(second / 60) % 60;
    var sec = second % 60;

    if (hour > 0 && hour < 10) {
      t = "0".concat(hour, ":");
    }

    if (min < 10) {
      t += '0';
    }

    t += "".concat(min, ":");

    if (sec < 10) {
      t += '0';
    }

    t += sec;
  }

  return t;
} // 将分秒转化成毫秒(00:00)


function dateToTimestamp(time) {
  time = time.split(':');
  var mi = parseInt(time[0], 0);
  var ss = parseInt(time[1].substring(0, 2), 0);
  var ts = mi * 60 + ss;
  return ts * 1000;
}
/* 显示支付渠道 */


function showPayType(payType) {
  var channelData = _payType.default.payTypeData.find(function (_ref) {
    var ping_plus_plus_channel = _ref.ping_plus_plus_channel;
    return ping_plus_plus_channel === payType;
  });

  return showVal(channelData ? channelData.title : undefined);
}
/* 显示购买渠道 */


function showChannel(channel) {
  var channelData = this.$store.state.dictionary.service.find(function (_ref2) {
    var openId = _ref2.openId;
    return openId === channel;
  });
  return showVal(channelData ? channelData.title : undefined);
}
/* 对象数组排序   this.sortKey(this.tableData, 'number'); */


function sortKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });
}

function dateFormatFun(format, date) {
  var we = date.getDay(); // 星期

  var qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度

  var opt = {
    'Y+': date.getFullYear().toString(),
    // 年
    'm+': (date.getMonth() + 1).toString(),
    // 月(月份从0开始，要+1)
    'd+': date.getDate().toString(),
    // 日
    'H+': date.getHours().toString(),
    // 时
    'M+': date.getMinutes().toString(),
    // 分
    'S+': date.getSeconds().toString(),
    // 秒
    'q+': qut // 季度

  };
  var week = {
    // 中文数字 (星期)
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六'
  };
  var quarter = {
    // 中文数字（季度）
    1: '一',
    2: '二',
    3: '三',
    4: '四'
  };

  if (/(W+)/.test(format)) {
    format = format.replace(RegExp.$1, RegExp.$1.length > 1 ? RegExp.$1.length > 2 ? "\u661F\u671F".concat(week[we]) : "\u5468".concat(week[we]) : week[we]);
  }

  if (/(Q+)/.test(format)) {
    // 输入一个Q，只输出一个中文数字，输入4个Q，则拼接上字符串
    format = format.replace(RegExp.$1, RegExp.$1.length == 4 ? "\u7B2C".concat(quarter[qut], "\u5B63\u5EA6") : quarter[qut]);
  }

  for (var k in opt) {
    var r = new RegExp("(".concat(k, ")")).exec(format);

    if (r) {
      // 若输入的长度不为1，则前面补零
      format = format.replace(r[1], RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, '0'));
    }
  }

  return format;
} // 把时间转化为几天前，几周前形式


function getDateTimeStamp(dateStr) {
  if (dateStr) {
    return Date.parse(dateStr.replace(/-/gi, '/'));
  }

  return undefined;
}

function getDateDiff(dateStr) {
  var dateTimeStamp = getDateTimeStamp(dateStr);
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24; // const halfamonth = day * 15;

  var month = day * 30;
  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp;

  if (diffValue < 0) {
    return dateStr;
  }

  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  var result = '';

  if (monthC >= 1) {
    result = dateStr;
  } else if (weekC >= 1) {
    result = "".concat(parseInt(weekC, 10), "\u5468\u524D");
  } else if (dayC >= 1) {
    result = "".concat(parseInt(dayC, 10), "\u5929\u524D");
  } else if (hourC >= 1) {
    result = "".concat(parseInt(hourC, 10), "\u5C0F\u65F6\u524D");
  } else if (minC >= 1) {
    result = "".concat(parseInt(minC, 10), "\u5206\u949F\u524D");
  } else {
    result = '刚刚';
  }

  return result;
}

function replaceEmoji(str) {
  var replacedStr = str.replace(/\[([^(\]|\[)]*)\]/g, function (item, index) {
    var imgstr = '';

    for (var i = 0; i < _enjoyList.default.emojiList.length; i++) {
      var row = _enjoyList.default.emojiList[i];

      for (var j = 0; j < row.length; j++) {
        var EM = row[j];

        if (EM.alt == item) {
          imgstr = "<img src=\"".concat(imageUrlFun("assets/taozhi/app/static/emoji/".concat(EM.url)), "\" mode=\"\" style=\"width: 30px;height:30px;\" />");
          return imgstr;
        }
      }
    }

    return imgstr || item;
  });
  return replacedStr;
}

function dateDiff(firstDate, secondDate) {
  var diff = new Date(secondDate).getTime() - new Date(firstDate).getTime();
  return diff >= 0;
}

function checkPhone(rule, value, callback) {
  if (!value) {
    return callback(new Error('电话不能为空'));
  }

  if (!/^(?=\d{11}$)^1(?:3\d|4[57]|5[^4\D]|66|7[^249\D]|8\d|9[89])\d{8}$/.test(value)) {
    callback(new Error('格式不对哦'));
  } else {
    callback();
  }
}

function randumcolor() {
  return "#".concat(Math.random().toString(16).substr(2, 6).toUpperCase());
}

function isJSON(str) {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str);

      if ((0, _typeof2.default)(obj) === 'object' && obj) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  return false;
}

function filterRich(rich) {
  if (!rich) {
    return rich;
  }

  var result = '';
  var list = rich.split('</p>');
  list.forEach(function (item) {
    var data = filerRichData(item);

    if (data.includes('<span')) {
      var val = '';
      data = data.split('</span>');
      data.forEach(function (n) {
        n = n.replace(/<span style="(.*)">/gi, '');
        n = n.replace(/<\/span>/gi, '');
        val += n;
      });
      data = val;
    }

    if (data.includes('<img')) {
      var _val = '';
      data = data.split('<img');
      data.forEach(function (n) {
        n = "<img".concat(n);
        n = n.replace(/<img(.*)\/>/gi, '');
        n = n.replace(/<img/gi, '');
        _val += n;
      });
      data = _val;
    }

    data = data.replace(/<p style="(.*)">/gi, '');
    result += data;
  });
  return result;
}

function filerRichData(rich) {
  rich = rich.replace(/<p>/gi, '');
  rich = rich.replace(/<\/p>/gi, '');
  rich = rich.replace(/<strong>/gi, '');
  rich = rich.replace(/<\/strong>/gi, '');
  rich = rich.replace(/<em>/gi, '');
  rich = rich.replace(/<\/em>/gi, '');
  return rich;
} // aes解密


function decryptData(word) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _cryptoJs.default.enc.Utf8.parse(process.env.VUE_APP_NEW_DATA_MICRO);
  var iv = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _cryptoJs.default.enc.Utf8.parse(process.env.VUE_APP_NEW_DATA_TIME_MICRO);

  var encryptedHexStr = _cryptoJs.default.enc.Hex.parse(word);

  var srcs = _cryptoJs.default.enc.Base64.stringify(encryptedHexStr);

  var decrypt = _cryptoJs.default.AES.decrypt(srcs, key, {
    iv: iv,
    mode: _cryptoJs.default.mode.CBC,
    padding: _cryptoJs.default.pad.Pkcs7
  });

  var decryptedStr = decrypt.toString(_cryptoJs.default.enc.Utf8);
  return decryptedStr.toString();
}

function handleResourceListQuery(val) {
  var queryForm = {
    keyword: val.searchKey ? val.searchKey.trim() : '',
    createStartTime: val.createStartTime,
    createdEndTime: val.createdEndTime,
    publishStartTime: val.publishStartTime,
    publishEndTime: val.publishEndTime,
    isShow: val.isShow,
    isIndSale: val.isIndSale,
    // ?
    isSale: val.isSale,
    subjectId: val.subject,
    sectionId: val.xd,
    editionId: val.bb,
    areaId: val.areaId,
    gradeId: val.grade,
    volumeId: val.jd,
    periodId: val.bc,
    orderBy: val.orderBy,
    orderMode: val.orderMode,
    regimentationIds: val.regimentationIds,
    secondRegimentationIds: val.secondRegimentationIds,
    tags: val.tags,
    liveStartTime: val.liveStartTime,
    liveEndTime: val.liveEndTime,
    recommendSequence: val.recommendSequence,
    isVid: val.isVid,
    isClosure: val.isClosure,
    isHaveTest: val.isHaveTest,
    download: val.download,
    uploadCoverState: val.uploadCoverState
  };
  return queryForm;
}

function handleResourceListStore(data) {
  var searchQuery = localStorage.getItem('SELECT_RESOURCE_LIST');

  if (!searchQuery) {
    return data;
  }

  var val = JSON.parse(searchQuery);
  var result = handleResourceListQuery(val);
  result.state = val.state ? val.state : data.state;
  result.status = val.status ? val.status : data.status;
  return result;
}

function handleEchoResourceListQuery(val) {
  var queryForm = {
    searchKey: val.keyword,
    createStartTime: val.createStartTime,
    createdEndTime: val.createdEndTime,
    publishStartTime: val.publishStartTime,
    publishEndTime: val.publishEndTime,
    isShow: val.isShow,
    isIndSale: val.isIndSale,
    // ?
    isSale: val.isSale,
    subject: val.subjectId,
    xd: val.sectionId,
    bb: val.editionId,
    areaId: val.areaId,
    grade: val.gradeId,
    jd: val.volumeId,
    bc: val.periodId,
    orderBy: val.orderBy,
    orderMode: val.orderMode,
    regimentationIds: val.regimentationIds,
    secondRegimentationIds: val.secondRegimentationIds,
    tags: val.tags,
    liveStartTime: val.liveStartTime,
    liveEndTime: val.liveEndTime,
    recommendSequence: val.recommendSequence,
    isVid: val.isVid,
    isClosure: val.isClosure,
    isHaveTest: val.isHaveTest,
    download: val.download,
    uploadCoverState: val.uploadCoverState
  };
  return queryForm;
}