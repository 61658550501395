var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-assignment" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "工单分配",
            width: "900px",
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "nav" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "nav-search" },
                [
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { placeholder: "员工搜索", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.searchVal,
                      callback: function($$v) {
                        _vm.searchVal = $$v
                      },
                      expression: "searchVal"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.search } },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading"
                }
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: { height: "370px", data: _vm.list }
            },
            [
              _c("el-table-column", {
                attrs: { label: "员工", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("user-info", {
                          attrs: { "user-info": scope.row.userInfo }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分配工单类型", prop: "newClassificationNames" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticClass: "edit-link",
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "danger", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.handleAssignmentDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalElements,
                  page: _vm.query.page,
                  limit: _vm.query.size,
                  "current-page": _vm.query.page
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add-assigment-dialog",
          attrs: {
            title: _vm.title,
            width: "530px",
            visible: _vm.addDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.addDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-width": "80px", rules: _vm.rule }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "员工", prop: "customerServiceOpenId" } },
                [
                  _c("search-user-by-input", {
                    ref: "searchUserByInput",
                    attrs: { userOpenId: _vm.form.customerServiceOpenId },
                    on: { receiveuserid: _vm.receiveUserId }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择分类",
                    prop: "workorderClassificationIds"
                  }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.classificationOptions,
                        callback: function($$v) {
                          _vm.classificationOptions = $$v
                        },
                        expression: "classificationOptions"
                      }
                    },
                    _vm._l(_vm.workClassificationList, function(item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.id,
                          attrs: { label: item.id, name: "class" }
                        },
                        [_vm._v(_vm._s(item.classificationName))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }