var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "salesConversionRateContainer"
    },
    [
      _c(
        "h3",
        { staticClass: "ratioTitle" },
        [
          _vm._v("\n    销售转化率\n    "),
          _vm._v(" "),
          _c(
            "el-select",
            {
              on: {
                change: function($event) {
                  return _vm.appProductHandle(_vm.appProductOpenIds)
                }
              },
              model: {
                value: _vm.appProductOpenIds,
                callback: function($$v) {
                  _vm.appProductOpenIds = $$v
                },
                expression: "appProductOpenIds"
              }
            },
            _vm._l(_vm.appliedproductsOptions, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.title, value: item.openId }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "containerWrap" }, [
        _c("div", { attrs: { id: "container" } }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-table",
              { attrs: { data: _vm.tableData } },
              [
                _c("el-table-column", {
                  attrs: { prop: "action", label: "销售阶段", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.action === "用户量"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "commonLine viewsCounts"
                                  }),
                                  _vm._v(
                                    _vm._s(scope.row.action) +
                                      "\n               "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "全站用户量，含增值网站",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { padding: "0 10px" } },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconInfo el-icon-info",
                                            staticStyle: { color: "#409EFF" }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.action === "下单量"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "commonLine orderQuantity"
                                  }),
                                  _vm._v(_vm._s(scope.row.action))
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.action === "交易完成量"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "commonLine transactionVolume"
                                  }),
                                  _vm._v(_vm._s(scope.row.action))
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "pv", label: "数量", width: "100" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "pv",
                    label: "转化率",
                    width: "200",
                    align: "right",
                    "header-align": "right",
                    fixed: "right"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.action !== "用户量"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.viewsCounts > 0
                                      ? (
                                          (scope.row.pv / _vm.viewsCounts) *
                                          100
                                        ).toFixed(2)
                                      : 0
                                  ) + "%"
                                )
                              ])
                            : _c("span", [_vm._v("--")])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }