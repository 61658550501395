"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'prizeTree.vue',
  props: {
    dataList: {
      type: Array
    },
    subJectCode: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    var _ref;

    var data = this.dataList;
    return _ref = {
      data: JSON.parse(JSON.stringify(data))
    }, (0, _defineProperty2.default)(_ref, "data", JSON.parse(JSON.stringify(data))), (0, _defineProperty2.default)(_ref, "priceCode", undefined), (0, _defineProperty2.default)(_ref, "isShow", false), (0, _defineProperty2.default)(_ref, "groupCode", undefined), (0, _defineProperty2.default)(_ref, "awardCode", undefined), (0, _defineProperty2.default)(_ref, "defaultProps", {
      children: 'awardList',
      label: 'groupName'
    }), (0, _defineProperty2.default)(_ref, "expandAll", true), _ref;
  },
  created: function created() {},
  methods: {
    // 给父组件返回选中code
    getHalfCheckEvent: function getHalfCheckEvent(val) {
      var groupCode = [];
      var awardCode = [];
      var checkArr = [];
      var code = this.$refs.tree.getCheckedNodes();
      code.forEach(function (item) {
        checkArr.push(item.commonCode);

        if (item.awardList) {
          groupCode.push(item.groupCode);
        } else if (!item.awardList) {
          awardCode.push(item.awardCode);
        }
      });
      this.awardCode = awardCode.toString();
      this.groupCode = groupCode.toString();
      this.$emit('getGroupCode', this.awardCode, this.groupCode);
    },
    // 展开收缩
    isExpand: function isExpand() {
      this.isShow = !this.isShow;
      this.expandAll = !this.expandAll;
    },
    handleClose: function handleClose(done) {
      this.$confirm('确认关闭？').then(function (_) {
        done();
      }).catch(function (_) {});
    },
    // 删除子节点
    remove: function remove(node, data) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var parent = node.parent;
        var awardList = parent.data.awardList || parent.data;
        var index = awardList.findIndex(function (d) {
          return d.id === data.id;
        });
        awardList.splice(index, 1);
      }).catch(function () {});
    }
  }
};
exports.default = _default;