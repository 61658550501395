"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPoetryPoet = addPoetryPoet;
exports.updatePoetryPoet = updatePoetryPoet;
exports.getPoetryPoet = getPoetryPoet;
exports.deletePoetryPoet = deletePoetryPoet;
exports.getPoetryPoetList = getPoetryPoetList;
exports.getCharDetail = getCharDetail;
exports.getAdminChineseChars = getAdminChineseChars;
exports.getVocabularyDetail = getVocabularyDetail;
exports.getAdminVocabularyList = getAdminVocabularyList;
exports.getMerchantWordAndNum = getMerchantWordAndNum;
exports.getMerchantWordGroup = getMerchantWordGroup;
exports.copyWordGroup = copyWordGroup;
exports.delWordGroup = delWordGroup;
exports.upDateWordGroup = upDateWordGroup;
exports.queryWordDetail = queryWordDetail;
exports.getAdminWordList = getAdminWordList;
exports.getAncientPoetryDynastyList = getAncientPoetryDynastyList;
exports.getAncientPoetryThemeList = getAncientPoetryThemeList;
exports.getAncientPoetryTypeList = getAncientPoetryTypeList;
exports.getAncientPoetryList = getAncientPoetryList;
exports.postAncientPoetryAdd = postAncientPoetryAdd;
exports.postAncientPoetryDeleteByOpenId = postAncientPoetryDeleteByOpenId;
exports.postAncientPoetryView = postAncientPoetryView;
exports.postAncientPoetryUpdate = postAncientPoetryUpdate;
exports.getPoetryPoetListNoPage = getPoetryPoetListNoPage;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/"));
/* 获取单个字 */

function getCharDetail(params) {
  return request.get('chineseChars/findChar', {
    params: params
  });
}
/* 获取单个词 */


function getVocabularyDetail(params) {
  return request.get('vocabulary/checkVocabulary', {
    params: params
  });
}
/* 获取单个单词 */


function queryWordDetail(params) {
  return request.get('word/findOne', {
    params: params
  });
}
/* 查多个汉字 按顺序 */


function getAdminChineseChars(params) {
  return request.get('chineseChars/getChineseChars', {
    params: params
  });
}
/* 查询单词和词性（多个）按顺序 */


function getAdminWordList(params) {
  return request.get('word/getWord', {
    params: params
  });
}
/* 查询多个词语 按顺序 */


function getAdminVocabularyList(params) {
  return request.get('vocabulary/getVocabulary', {
    params: params
  });
}
/* 查询商户及单词数量 */


function getMerchantWordAndNum(params) {
  return request.get('word/partner', {
    params: params
  });
}
/* 查询商户单词分组 */


function getMerchantWordGroup(params) {
  return request.get('wordGroup/list', {
    params: params
  });
}
/* 复制单词分组 */


function copyWordGroup(openId) {
  return request.put("wordGroup/copy/".concat(openId));
}
/* 删除单词分组 */


function delWordGroup(openId) {
  return request.delete("wordGroup/".concat(openId));
}
/* 新增或编辑单词分组 */


function upDateWordGroup(data) {
  return request.post('wordGroup', data);
}
/* 获取诗词朝代列表 */


function getAncientPoetryDynastyList(params) {
  return request.get('ancientPoetryDynasty/list', {
    params: params
  });
}
/* 获取诗词题材列表 */


function getAncientPoetryThemeList(params) {
  return request.get('ancientPoetryTheme/list', {
    params: params
  });
}
/* 获取诗词类型列表 */


function getAncientPoetryTypeList(params) {
  return request.get('ancientPoetryType/list', {
    params: params
  });
}
/* 获取诗词列表（分页） */


function getAncientPoetryList(params) {
  return request.get('ancientPoetry/list', {
    params: params
  });
}
/* 新增诗词 */


function postAncientPoetryAdd(data) {
  return request.post('ancientPoetry/add', data);
}
/* 删除诗词 */


function postAncientPoetryDeleteByOpenId(data) {
  return request.post('ancientPoetry/deleteByOpenId', data);
}
/* 查看诗词 */


function postAncientPoetryView(data) {
  return request.post('ancientPoetry/view', data);
}
/* 修改诗词 */


function postAncientPoetryUpdate(data) {
  return request.post('ancientPoetry/update', data);
}
/* 新增诗人 */


function addPoetryPoet(data) {
  return request.post('ancientPoetryPoet/add', data);
}
/* 修改诗人 */


function updatePoetryPoet(data) {
  return request.post('ancientPoetryPoet/update', data);
}
/* 查看诗人 */


function getPoetryPoet(data) {
  return request.post('ancientPoetryPoet/view', data);
}
/* 删除诗人 */


function deletePoetryPoet(data) {
  return request.post('ancientPoetryPoet/deleteByOpenId', data);
}
/* 诗人列表 */


function getPoetryPoetList(params) {
  return request.get('ancientPoetryPoet/list', {
    params: params
  });
}
/* 诗人列表不分页 */


function getPoetryPoetListNoPage(params) {
  return request.get('ancientPoetryPoet/getAllList', {
    params: params
  });
}