var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "pop-up-dialog",
        title: "商品审核",
        visible: _vm.valueProxy,
        width: "92%",
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        },
        close: _vm.onDialogClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-content" },
        [
          _c("content-item", {
            attrs: {
              data: _vm.requestData,
              "diff-key": _vm.diffKey,
              title: "修改前"
            }
          }),
          _vm._v(" "),
          _c("content-item", {
            attrs: {
              data: _vm.prodData,
              "diff-key": _vm.diffKey,
              title: "修改后"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticClass: "cancel", on: { click: _vm.handleClose } },
            [_vm._v("驳回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "confirm",
              attrs: { type: "primary" },
              on: { click: _vm.handleConfirm }
            },
            [_vm._v("通过")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }