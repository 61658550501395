var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bulkUpload" }, [
    _c(
      "div",
      { staticClass: "bulkUpload-content" },
      [
        _c(
          "el-steps",
          { attrs: { active: _vm.uploadStep } },
          [
            _c("el-step", {
              attrs: {
                description:
                  "设置批量上传" + _vm.getResourceName + "的基本信息等",
                title: "步骤 1"
              }
            }),
            _vm._v(" "),
            _c("el-step", {
              attrs: {
                description: "选择待上传的" + _vm.getResourceName,
                title: "步骤 2"
              }
            }),
            _vm._v(" "),
            _c("el-step", {
              attrs: { description: _vm.descriptionText, title: "步骤 3" }
            }),
            _vm._v(" "),
            _c("el-step", {
              attrs: { title: "步骤 4", description: "完成批量上传" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("bulk-basic-info", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uploadStep <= 0,
              expression: "uploadStep <= 0"
            }
          ],
          ref: "bulkBasicInfo",
          attrs: {
            "partner-open-id": _vm.partnerOpenId,
            "resource-type": _vm.resourceType
          }
        }),
        _vm._v(" "),
        _c("bulk-video-upload", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uploadStep > 0,
              expression: "uploadStep > 0"
            }
          ],
          ref: "bulkVideoUpload",
          staticClass: "bulk-video-upload",
          attrs: { "resource-type": _vm.resourceType }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card-lz-bottom display-flex box",
            style: _vm.uploadStep > 0 ? "" : "justify-content: flex-end"
          },
          [
            _vm.uploadStep > 0
              ? _c(
                  "div",
                  {
                    staticClass: "display-flex",
                    staticStyle: { "align-items": "center" }
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "check-all",
                      attrs: {
                        indeterminate: _vm.isIndeterminate,
                        disabled: _vm.videoArr.length <= 0
                      },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.disabled || _vm.uploadStep >= 4,
                          type: "danger",
                          plain: ""
                        },
                        on: { click: _vm.removeVideo }
                      },
                      [_vm._v("移除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        attrs: {
                          "http-request": _vm.fileChange,
                          "show-file-list": false,
                          disabled: _vm.uploadStep >= 4 || _vm.openUpload,
                          action: "",
                          multiple: ""
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.uploadStep >= 4 || _vm.openUpload,
                              type: "primary"
                            }
                          },
                          [_vm._v("添加" + _vm._s(_vm.getResourceName))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.videoArr.length !== 0
                      ? _c("span", { staticClass: "statistics" }, [
                          _vm._v(
                            "待上传：" +
                              _vm._s(_vm.videoArr.length - _vm.uploadIndex) +
                              "个，已上传：" +
                              _vm._s(_vm.uploadIndex) +
                              "个，共 " +
                              _vm._s(_vm.videoArr.length) +
                              " 个"
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm.uploadStep < 4
                ? _c(
                    "span",
                    [
                      _c("el-button", { on: { click: _vm.cancelBulkVideo } }, [
                        _vm._v("取消")
                      ]),
                      _vm._v(" "),
                      _vm.uploadStep === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.getBasicInfo()
                                }
                              }
                            },
                            [_vm._v("下一步")]
                          )
                        : !_vm.openUpload
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.uploadStep = 0
                                }
                              }
                            },
                            [_vm._v("上一步")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.firstStepOver
                ? _c(
                    "span",
                    [
                      _vm.uploadIndex <= 0 && !_vm.openUpload
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.videoArr.length <= 0,
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.uploadVisible = true
                                }
                              }
                            },
                            [_vm._v("开始上传")]
                          )
                        : _vm.videoArr.length > _vm.uploadIndex
                        ? _c(
                            "span",
                            [
                              _vm.stopUpload
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.restoreUpload }
                                    },
                                    [_vm._v("继续")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.timeOutUpload }
                                    },
                                    [_vm._v("暂停")]
                                  )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploadStep >= 4
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit(2)
                                    }
                                  }
                                },
                                [_vm._v("批量提交审核")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.closeVide }
                                },
                                [_vm._v("完成")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c("upload-dialog", {
          ref: "dialog",
          attrs: {
            info: _vm.videoInfo,
            goods: _vm.goods,
            show: _vm.uploadVisible
          },
          on: {
            "update:show": function($event) {
              _vm.uploadVisible = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }