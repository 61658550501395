var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header", attrs: { type: "border-card" } },
    [
      _c(
        "div",
        { staticClass: "header-left-item" },
        [
          _vm.resourceType === "model"
            ? _c(
                "el-button",
                {
                  staticClass: "newly",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleNewProduct()
                    }
                  }
                },
                [_vm._v("新建")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.resourceType === "model"
            ? _c(
                "el-radio-group",
                {
                  staticStyle: { display: "flex", flex: "none" },
                  on: { change: _vm.filterByName },
                  model: {
                    value: _vm.query.status,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status"
                  }
                },
                _vm._l(_vm.button_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.value,
                      attrs: {
                        size: "small",
                        value: item.value,
                        label: item.value
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.showStat(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.label) + "\n      ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.resourceType === "manuScript"
            ? _c(
                "el-radio-group",
                {
                  staticStyle: {
                    "margin-left": "12px",
                    display: "flex",
                    flex: "none"
                  },
                  on: { change: _vm.filterByNames },
                  model: {
                    value: _vm.querys.status,
                    callback: function($$v) {
                      _vm.$set(_vm.querys, "status", $$v)
                    },
                    expression: "querys.status"
                  }
                },
                _vm._l(_vm.buttons_options, function(item) {
                  return _c(
                    "el-radio-button",
                    {
                      key: item.value,
                      attrs: { value: item.value, label: item.value },
                      nativeOn: {
                        click: function($event) {
                          return _vm.showStats(item)
                        }
                      }
                    },
                    [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header-right" },
        [
          _c("el-input", {
            staticClass: "header-search",
            attrs: {
              placeholder: "请输入标题或作者名",
              "prefix-icon": "el-icon-search",
              clearable: ""
            },
            on: { change: _vm.changeInput, clear: _vm.clearInput },
            model: {
              value: _vm.form.seachVal,
              callback: function($$v) {
                _vm.$set(_vm.form, "seachVal", $$v)
              },
              expression: "form.seachVal"
            }
          }),
          _vm._v(" "),
          _c(
            "search-btn",
            {
              staticClass: "searchContent searchContentInput",
              on: {
                searchContent: _vm.searchcontent,
                firstLoading: _vm.firstLoading
              }
            },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { "label-width": "90px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "年级" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择年级", clearable: "" },
                          model: {
                            value: _vm.form.grade,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "grade", $$v)
                            },
                            expression: "form.grade"
                          }
                        },
                        _vm._l(_vm.gradeLists, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "阶段" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            "collapse-tags": "",
                            placeholder: "请选择阶段"
                          },
                          model: {
                            value: _vm.form.stage,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "stage", $$v)
                            },
                            expression: "form.stage"
                          }
                        },
                        _vm._l(_vm.syncVolumeLists, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$attrs.table_name === "同步" &&
                  _vm.resourceType === "model" &&
                  _vm.$attrs.editionCode
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "单元" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择单元"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.getUntil($event)
                                }
                              },
                              model: {
                                value: _vm.form.unitCodes,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "unitCodes", $$v)
                                },
                                expression: "form.unitCodes"
                              }
                            },
                            _vm._l(_vm.unitLists, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.unitName,
                                  value: item.unitCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.resourceType === "model"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标签" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                "allow-create": "",
                                "default-first-option": "",
                                placeholder: "请选择或创建标签"
                              },
                              model: {
                                value: _vm.form.modelTags,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "modelTags", $$v)
                                },
                                expression: "form.modelTags"
                              }
                            },
                            _vm._l(_vm.tags, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$attrs.table_name !== "同步" ||
                  _vm.resourceType === "manuScript"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "地区" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              clearable: "",
                              placeholder: "选择或搜索地区",
                              options: _vm.areaList,
                              props: _vm.areaProps,
                              filterable: ""
                            },
                            on: { change: _vm.getAreaCode },
                            model: {
                              value: _vm.form.areaCodes,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "areaCodes", $$v)
                              },
                              expression: "form.areaCodes"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$attrs.table_name !== "同步" ||
                  _vm.resourceType === "manuScript"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "学校" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                remote: "",
                                "remote-method": _vm.remoteMethod,
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择或搜索学校",
                                disabled: _vm.isShowSchool
                              },
                              on: { change: _vm.getSchoolCode },
                              model: {
                                value: _vm.form.schoolCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "schoolCode", $$v)
                                },
                                expression: "form.schoolCode"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.schoolLoading,
                                      expression: "schoolLoading"
                                    }
                                  ],
                                  attrs: { "element-loading-text": "加载中" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "infinite-scroll",
                                          rawName: "v-infinite-scroll",
                                          value: _vm.loadScroll,
                                          expression: "loadScroll"
                                        }
                                      ],
                                      staticStyle: {
                                        overflow: "auto",
                                        height: "205px"
                                      },
                                      attrs: {
                                        "infinite-scroll-immediate": "false"
                                      }
                                    },
                                    _vm._l(_vm.schoolList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          props: _vm.schoolProps,
                                          label: item.name,
                                          value: item.schoolOpenId
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.resourceType === "manuScript"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "投稿" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择投稿类型"
                              },
                              model: {
                                value: _vm.form.modelCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "modelCode", $$v)
                                },
                                expression: "form.modelCode"
                              }
                            },
                            _vm._l(_vm.modelList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$attrs.table_name !== "奖项"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "奖项" } },
                        [
                          _c("el-cascader", {
                            ref: "awardList",
                            attrs: {
                              placeholder: "请选择奖项",
                              clearable: "",
                              options: _vm.priceList,
                              props: _vm.awardProps
                            },
                            on: { change: _vm.awardChange },
                            model: {
                              value: _vm.form.priceCode,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "priceCode", $$v)
                              },
                              expression: "form.priceCode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.resourceType === "model"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "字数" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择字数"
                              },
                              model: {
                                value: _vm.form.countCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "countCode", $$v)
                                },
                                expression: "form.countCode"
                              }
                            },
                            _vm._l(_vm.countLists, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.countName,
                                  value: item.countCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.resourceType === "manuScript"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "星级" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择星级"
                              },
                              model: {
                                value: _vm.form.starLevel,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "starLevel", $$v)
                                },
                                expression: "form.starLevel"
                              }
                            },
                            _vm._l(_vm.starList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否含音频" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.isHaveAudio,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isHaveAudio", $$v)
                            },
                            expression: "form.isHaveAudio"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "全部" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "是" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "否" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$attrs.table_name !== "同步" ||
                  _vm.resourceType === "manuScript"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时段" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              format: "yyyy 年 MM 月 dd 日",
                              "value-format": "yyyy-MM-dd",
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期"
                            },
                            on: {
                              change: function($event) {
                                return _vm.getTimeSearch(_vm.form.timeSearch)
                              }
                            },
                            model: {
                              value: _vm.form.timeSearch,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "timeSearch", $$v)
                              },
                              expression: "form.timeSearch"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "downListExport" },
            [
              _vm.resourceType === "manuScript"
                ? _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary", size: "mini" },
                      on: { click: _vm.showManuscriptDialog }
                    },
                    [_vm._v("导出稿件")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceType === "model"
                ? _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary", size: "mini" },
                      on: { click: _vm.batchExport }
                    },
                    [_vm._v("导出范文")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "el-dropdown-link",
                      staticStyle: {
                        "font-size": "16px",
                        "margin-left": "10px",
                        height: "37px"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-s-tools" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "dropMenu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.checkList,
                        callback: function($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList"
                      }
                    },
                    _vm._l(_vm.check, function(item) {
                      return _c("el-checkbox", {
                        key: item.value,
                        staticStyle: { "margin-top": "10px" },
                        attrs: { label: item.label },
                        on: {
                          change: function($event) {
                            return _vm.changeTitle(_vm.checkList)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.manuscriptVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "导出稿件",
                visible: _vm.manuscriptVisible,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "append-to-body": true,
                width: "25%",
                "before-close": _vm.dismissManuscriptDialog
              }
            },
            [
              _c("div", [
                _c("div", { staticClass: "line_h" }),
                _vm._v(" "),
                _c("div", { staticClass: "dialog_body_box" }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("div", [
                        _vm._v("\n            是否导出原文\n          ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "15px" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value:
                                  _vm.exportManuscriptParams.exportOriginalText,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.exportManuscriptParams,
                                    "exportOriginalText",
                                    $$v
                                  )
                                },
                                expression:
                                  "exportManuscriptParams.exportOriginalText"
                              }
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value:
                                  _vm.exportManuscriptParams.exportOriginalText,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.exportManuscriptParams,
                                    "exportOriginalText",
                                    $$v
                                  )
                                },
                                expression:
                                  "exportManuscriptParams.exportOriginalText"
                              }
                            },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _vm._v("即将导出当前筛选条件下的稿件")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "line_h" })
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { on: { click: _vm.dismissManuscriptDialog } },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.exportManuscriptLoading
                      },
                      on: { click: _vm.exportManuscript }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }