var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-with-button" },
    [
      _c("el-input", {
        staticClass: "input-box",
        attrs: {
          disabled: _vm.disabledEdit,
          value: _vm.valueProxy.productId,
          "show-word-limit": "",
          maxlength: 100,
          placeholder: _vm.placeholder,
          clearable: ""
        },
        on: { input: _vm.productIdInput }
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            loading: _vm.importLoading,
            disabled: _vm.canImport
          },
          on: { click: _vm.importFromErp }
        },
        [_vm._v(_vm._s(_vm.buttonLabel))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }