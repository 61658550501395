import { render, staticRenderFns } from "./editTeacher.vue?vue&type=template&id=28f3849a&scoped=true&"
import script from "./editTeacher.vue?vue&type=script&lang=js&"
export * from "./editTeacher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f3849a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28f3849a')) {
      api.createRecord('28f3849a', component.options)
    } else {
      api.reload('28f3849a', component.options)
    }
    module.hot.accept("./editTeacher.vue?vue&type=template&id=28f3849a&scoped=true&", function () {
      api.rerender('28f3849a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/teacher/edit/editTeacher.vue"
export default component.exports