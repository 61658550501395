"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _bus = _interopRequireDefault(require("@/utils/bus"));

var _questionDetails = _interopRequireDefault(require("./questionDetails"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'NewQuestions',
  components: {
    questionDetails: _questionDetails.default
  },
  props: {
    questionAdd: {},
    operationCode: {},
    clickNodeOpenId: {},
    previewDialog: {
      type: Boolean,
      default: false
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    merchantOpenId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'add'
    },
    source: {},
    editOpenId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      newQuestiondialogVisible: this.previewDialog
    };
  },
  watch: {
    previewDialog: function previewDialog(newVal) {
      this.newQuestiondialogVisible = newVal;
    },
    newQuestiondialogVisible: function newQuestiondialogVisible(newVal) {
      this.$emit('directoryDialogChange', newVal);
    },
    dialogVisible: function dialogVisible(newVal) {
      this.dialog = newVal;
    },
    dialog: function dialog(newVal) {
      this.$emit('directoryDialogChange', newVal);
    }
  },
  mounted: function mounted() {
    var _this = this;

    _bus.default.$on('updatequestionlist', function (e) {
      _this.newQuestiondialogVisible = false;
    });
  },
  methods: {
    directoryDialogChange: function directoryDialogChange(val) {
      this.$emit('directoryDialogChange', val);
    },
    handleClose: function handleClose(done) {
      done();
    }
  }
};
exports.default = _default;