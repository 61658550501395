var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dashboard_container"
    },
    [
      _c(
        "div",
        { staticClass: "info_box title_style" },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "space-between"
              }
            },
            [
              _c("div", [
                _c("span", { staticClass: "info_key" }, [_vm._v("店铺编号：")]),
                _vm._v(" "),
                _c("span", { staticClass: "info_value" }, [
                  _vm._v(_vm._s(_vm.storeInfo.partnerOpenId))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("div", [
                    _c("span", { staticClass: "info_key" }, [_vm._v("状态：")]),
                    _vm._v(" "),
                    _vm.storeInfo.extended.status === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "rgba(103, 194, 58, 1)",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("正常")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.storeInfo.extended.status === 0
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "rgba(245, 108, 108, 1)",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("已禁用")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.jumpStoreEdit }
                    },
                    [_vm._v("编辑")]
                  ),
                  _vm._v(" "),
                  _vm.storeInfo.extended.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", plain: "", size: "small" },
                          on: { click: _vm.showStopDialog }
                        },
                        [_vm._v("\n            禁用\n          ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.storeInfo.extended.status === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success", plain: "", size: "small" },
                          on: { click: _vm.changeStatus }
                        },
                        [_vm._v("\n            激活\n          ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "info_key" }, [_vm._v("店铺名称：")]),
                _vm._v(" "),
                _c("span", { staticClass: "info_value" }, [
                  _vm._v(_vm._s(_vm.storeInfo.storeName))
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "info_key" }, [_vm._v("联系电话：")]),
                _vm._v(" "),
                _c("span", { staticClass: "info_value" }, [
                  _vm._v(_vm._s(_vm.storeInfo.operatorPhone))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "25px" } },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 8 } },
                [
                  _c("span", { staticClass: "info_key" }, [
                    _vm._v("店铺logo：")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-image",
                    {
                      staticClass: "userCover",
                      staticStyle: { "margin-left": "12px" },
                      attrs: {
                        fit: "cover",
                        src: _vm.getImgUrl(
                          _vm.storeInfo.partnerLogo,
                          _vm.thumbnailStyle
                        )
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [
                          _c("img", {
                            staticClass: "userCover",
                            attrs: { src: require("@/assets/book_cover.png") }
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "info_key" }, [_vm._v("店铺地址：")]),
                _vm._v(" "),
                _c("span", { staticClass: "info_value" }, [
                  _vm._v(
                    _vm._s(_vm.storeInfo.extended.areaName) +
                      _vm._s(_vm.storeInfo.extended.address)
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-top": "25px" } }, [
            _c("span", { staticClass: "info_key" }, [_vm._v("店铺简介：")]),
            _vm._v(" "),
            _c("span", { staticClass: "info_value" }, [
              _vm._v(_vm._s(_vm.storeInfo.desc))
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info_box", staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.tabName,
                callback: function($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "客户", name: "kehu" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.userListLoading,
                          expression: "userListLoading"
                        }
                      ],
                      attrs: {
                        data: _vm.userTableData,
                        height: "470",
                        "default-sort": {
                          prop: "consumeAmount",
                          order: "descending"
                        }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "用户" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "display_flex align_items" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "userListCover" },
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "userListCover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                scope.row.userCover,
                                                _vm.thumbnailStyle
                                              )
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "error" },
                                                slot: "error"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "userListCover",
                                                  attrs: {
                                                    src: require("@/assets/book_cover.png")
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "marginLeft_10" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(scope.row.userNickName) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "消费次数",
                          prop: "orderCount",
                          align: "center",
                          width: "140"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "累计消费金额",
                          align: "center",
                          width: "140"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.totalSales
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                  ¥" +
                                          _vm._s(
                                            _vm.numFormat(
                                              (
                                                scope.row.totalSales / 100
                                              ).toFixed(2)
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _c("div", [_vm._v("¥0")])
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "用户标签", width: "auto" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  scope.row.tabList
                                    ? _c(
                                        "span",
                                        _vm._l(scope.row.tabList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: index,
                                              staticStyle: {
                                                "word-break": "break-all",
                                                margin: "5px"
                                              },
                                              attrs: { size: "mini" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          "\n                    --\n                  "
                                        )
                                      ])
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "right",
                          "header-align": "right",
                          width: "50"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDialogUserdetail(
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("详情")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "display_flex align_items flex_end",
                      staticStyle: { "margin-top": "15px" }
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.userParams.page,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": _vm.userParams.size,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.userTotal
                        },
                        on: {
                          "size-change": _vm.kehuHandleSizeChange,
                          "current-change": _vm.kehuHandleCurrentChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "订单", name: "dingdan" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "470px" } },
                  [
                    _c("product-order-table", {
                      attrs: {
                        loading: _vm.orderLoading,
                        "order-list": _vm.orderList
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "display_flex align_items flex_end",
                    staticStyle: { "margin-top": "15px" }
                  },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.orderQuery.page,
                        "page-sizes": [10, 20, 50, 100],
                        "page-size": _vm.orderQuery.size,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.orderTotalElements
                      },
                      on: {
                        "size-change": _vm.orderHandleSizeChange,
                        "current-change": _vm.orderHandleCurrentChange
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "商品销售", name: "shangpin" } },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.goodsTableLoading,
                              expression: "goodsTableLoading"
                            }
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.goodsTableData }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "date", label: "商品" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-items": "center",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                staticClass: "userCover",
                                                staticStyle: {
                                                  "margin-right": "8px"
                                                },
                                                attrs: {
                                                  fit: "cover",
                                                  src: _vm.getImgUrl(
                                                    scope.row.goodsCover,
                                                    _vm.thumbnailStyle
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: { slot: "error" },
                                                    slot: "error"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "userCover",
                                                      attrs: {
                                                        src: require("@/assets/book_cover.png")
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [_vm._v(_vm._s(scope.row.goodsName))]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              prop: "buyQuantity",
                              align: "center",
                              label: "销量"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              width: "200",
                              align: "center",
                              label: "累计销售金额"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            _vm.showVal(
                                              Number(
                                                scope.row.payPrice / 100
                                              ).toFixed(2)
                                            )
                                          )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { width: "100", label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: { "margin-right": "12px" },
                                        attrs: {
                                          type: "primary",
                                          underline: false
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toOrderdetail(
                                              scope.row.productOpenId
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("详情\n                  ")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "门店管理", name: "mendian" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.jumpShopEdit("")
                            }
                          }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.shopTableLoading,
                          expression: "shopTableLoading"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.shopTableData }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "门店名称" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "address", label: "门店地址" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(scope.row.areaName) +
                                    _vm._s(scope.row.address) +
                                    "\n              "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "联系电话" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "默认自提点",
                          width: "180",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-switch", {
                                  attrs: { "active-color": "#13ce66" },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeDefaultAddress(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.pickUpDefault,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "pickUpDefault", $$v)
                                    },
                                    expression: "scope.row.pickUpDefault"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          label: "操作",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.jumpShopEdit(
                                          scope.row.shopOpenId
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "12px" },
                                    attrs: { type: "danger", underline: false },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeShop(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  移除\n                "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "禁用" + _vm.storeInfo.storeName,
            visible: _vm.stopDialog,
            width: "30%",
            "before-close": _vm.dismissStopDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.stopDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "stopForm",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "禁用原因", prop: "disableDesc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.disableDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "disableDesc", $$v)
                      },
                      expression: "form.disableDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dismissStopDialog } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.checkStopForm()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("store-user-dialog", {
        attrs: {
          visible: _vm.dialogUserdetail,
          userOpenId: _vm.currentuserOpenId,
          "partner-open-id": _vm.partnerOpenId,
          "prop-active-name": _vm.propActiveName
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogUserdetail = $event
          },
          "update:userOpenId": function($event) {
            _vm.currentuserOpenId = $event
          },
          "update:user-open-id": function($event) {
            _vm.currentuserOpenId = $event
          },
          clearuserOpenId: _vm.clearuserOpenId
        }
      }),
      _vm._v(" "),
      _c("real-goods-info", {
        attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
        on: {
          "update:visible": function($event) {
            _vm.goodsDialogVisible = $event
          },
          "update:goodsId": function($event) {
            _vm.goodsId = $event
          },
          "update:goods-id": function($event) {
            _vm.goodsId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }