"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Group_Info_InputCloseable',
  props: {
    data: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: undefined
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      pData: undefined,
      changeDebounced: (0, _lodash.debounce)(this.change, 382)
    };
  },
  watch: {
    data: function data() {
      this.updateData();
    },
    pData: function pData() {
      this.changeDebounced();
    }
  },
  mounted: function mounted() {
    this.updateData();
  },
  methods: {
    updateData: function updateData() {
      this.pData = this.data;
    },
    change: function change() {
      this.$emit('change', this.pData);
    }
  }
};
exports.default = _default;