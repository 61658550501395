var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color_606266 income" }, [
    _c(
      "div",
      { staticClass: "income-item", staticStyle: { padding: "5px 0" } },
      [
        _c("div", { staticClass: "title" }, [_vm._v("订单收入")]),
        _vm._v(" "),
        _c("div", { staticClass: "amount commonSizeBold" }, [
          _vm._v(_vm._s(_vm.showAmount(_vm.cashData.orderFormPayPriceAmount)))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "showMoney" }, [
      _c("div", { staticClass: "income-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("平台收入")]),
        _vm._v(" "),
        _c("div", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.showAmount(_vm.cashData.platformIncomeAmount)))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "income-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("中盘收入")]),
        _vm._v(" "),
        _c("div", { staticClass: "amount" }, [
          _vm._v(
            _vm._s(_vm.showAmount(_vm.cashData.cloudRetailMiddleIncomeAmount))
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "income-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("云零售商户收入")]),
        _vm._v(" "),
        _c("div", { staticClass: "amount" }, [
          _vm._v(
            _vm._s(_vm.showAmount(_vm.cashData.cloudRetailPartnerIncomeAmount))
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "income-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("知识店铺商户收入")]),
        _vm._v(" "),
        _c("div", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.showAmount(_vm.cashData.merchantIncomeAmount)))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "income-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("手续费")]),
        _vm._v(" "),
        _c("div", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.showAmount(_vm.cashData.orderFormCommissionAmount)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }