"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDistributionOfGoodsData = createDistributionOfGoodsData;

/* eslint-disable import/prefer-default-export */

/**
 * 一键铺货商品 - 阶梯价格
 */
function createDistributionOfGoodsDataPreferential() {
  return {
    price: undefined,
    productCount: undefined
  };
}
/**
 * 一键铺货商品
 */


function createDistributionOfGoodsData() {
  var prIsSub = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    prIsSub: prIsSub,
    prCount: undefined,
    brandOpenId: undefined,
    seriesCode: undefined,
    groupIds: undefined,
    secondGroupIds: undefined,
    subjectCode: undefined,
    sectionCode: undefined,
    editionCode: undefined,
    gradeCode: undefined,
    volumeCode: undefined,
    areaCode: undefined,
    electiveCode: undefined,
    periodCode: undefined,
    middleStockPrice: undefined,
    otherSupplyPrice: undefined,
    shopStockPrice: undefined,
    shopLowPrice: undefined,
    shopPrice: undefined,
    productPreferentialList: [createDistributionOfGoodsDataPreferential(), createDistributionOfGoodsDataPreferential(), createDistributionOfGoodsDataPreferential()],
    splicingPreferentialList: [createDistributionOfGoodsDataPreferential(), createDistributionOfGoodsDataPreferential(), createDistributionOfGoodsDataPreferential()]
  };
}