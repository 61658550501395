var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ebook-case",
      attrs: {
        title: "选择书橱",
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "1000px",
        "before-close": _vm.onCancleSelectCase
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("span", [_vm._v("选择书橱")]),
            _vm._v(" "),
            _c("search", {
              staticClass: "partner",
              attrs: { "partner-open-id": _vm.listQueryCase.partnerOpenId },
              on: { receivepartnerId: _vm.receivepartnerId }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "select-wraper" }, [
        _c("div", { staticClass: "select-wrap" }, [
          _c(
            "div",
            { staticClass: "select-head" },
            [
              _c("el-cascader", {
                staticClass: "search-cascader",
                attrs: {
                  options: _vm.classListBookCase,
                  props: { checkStrictly: true },
                  clearable: "",
                  placeholder: "分类"
                },
                on: { change: _vm.changeCascaderBookCase }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "search" },
                [
                  _c("el-input", {
                    staticClass: "nav-input",
                    attrs: { clearable: "", placeholder: "请输入电子书名称" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchResource($event)
                      }
                    },
                    model: {
                      value: _vm.listQueryCase.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryCase, "keyword", $$v)
                      },
                      expression: "listQueryCase.keyword"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "search-button",
                    { on: { searchContent: _vm.searchResource } },
                    [
                      _c("dialog-filter-panel", {
                        attrs: {
                          "type-name": "电子书",
                          isebook: true,
                          "show-download": true
                        },
                        model: {
                          value: _vm.listQueryCase,
                          callback: function($$v) {
                            _vm.listQueryCase = $$v
                          },
                          expression: "listQueryCase"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "记住所选",
                        placement: "top-start"
                      }
                    },
                    [
                      _c("el-checkbox", {
                        staticClass: "remember-check",
                        on: { change: _vm.handleRemember },
                        model: {
                          value: _vm.remember,
                          callback: function($$v) {
                            _vm.remember = $$v
                          },
                          expression: "remember"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select-list" },
            _vm._l(_vm.tableDataCase, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "select-item",
                  on: {
                    click: function($event) {
                      return _vm.bookItemCase(item)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "select-cover",
                        item.coverType === 1 ? "row-select-cover" : ""
                      ]
                    },
                    [
                      _c("el-image", {
                        staticClass: "image",
                        attrs: {
                          src: _vm.handleCoverDisplay(item, undefined, "cover"),
                          fit: "cover"
                        }
                      }),
                      _vm._v(" "),
                      item.coverReferenceType === 2
                        ? _c("div", { staticClass: "recommend-title" }, [
                            _vm._v(_vm._s(item.title))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.selected
                        ? _c(
                            "div",
                            {
                              staticClass: "select-cover-body",
                              style: {
                                height: item.coverType === 1 ? "56px" : ""
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../../assets/images/dui.png")
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-title" }, [
                    _vm._v(_vm._s(item.title))
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select-foot" },
            [
              _c(
                "div",
                { staticClass: "search" },
                [
                  _c("el-checkbox", {
                    staticClass: "all-check",
                    attrs: { indeterminate: _vm.indeterminate },
                    on: { change: _vm.handleCheckAll },
                    model: {
                      value: _vm.checked,
                      callback: function($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked"
                    }
                  }),
                  _vm._v("全选")
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQueryCase.page,
                  limit: _vm.listQueryCase.size,
                  "current-page": _vm.listQueryCase.page,
                  background: false,
                  layout: "prev, pager, next"
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQueryCase, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQueryCase, "size", $event)
                  },
                  pagination: _vm.getBookListCase
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "select-span iconfont icon-shuangjiantou" }),
        _vm._v(" "),
        _c("div", { staticClass: "select-wrap" }, [
          _c("div", { staticClass: "select-head" }, [
            _c("span", { staticClass: "select-head-title" }, [
              _vm._v("已选书橱")
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select-list select-list-b" },
            _vm._l(_vm.selectedTableDataCase, function(item, index) {
              return _c("div", { key: index, staticClass: "select-item" }, [
                _c(
                  "div",
                  {
                    class: [
                      "select-cover",
                      item.coverType === 1 ? "row-select-cover" : ""
                    ]
                  },
                  [
                    _c("el-image", {
                      staticClass: "image",
                      attrs: {
                        src: _vm.handleCoverDisplay(item, undefined, "cover"),
                        fit: "cover"
                      }
                    }),
                    _vm._v(" "),
                    item.coverReferenceType === 2
                      ? _c("div", { staticClass: "recommend-title" }, [
                          _vm._v(_vm._s(item.title))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "close",
                      attrs: { src: require("../../../assets/images/cha.png") },
                      on: {
                        click: function($event) {
                          return _vm.centerSelectedCase(item)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "select-title" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bottom-btns",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.onCancleSelectCase } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmitSelectCase }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }