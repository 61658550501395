"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAppletDetailsInfo = getAppletDetailsInfo;
exports.getTemplateList = getTemplateList;
exports.getTemplatedRaftList = getTemplatedRaftList;
exports.addToTemplate = addToTemplate;
exports.commitCode = commitCode;
exports.submitAudit = submitAudit;
exports.releaseMiniApp = releaseMiniApp;
exports.getPrivacySetting = getPrivacySetting;
exports.setPrivacySetting = setPrivacySetting;
exports.modifyDomain = modifyDomain;
exports.getOrderPathInfo = getOrderPathInfo;
exports.applySetOrderPathInfo = applySetOrderPathInfo;
exports.createCloudUser = createCloudUser;
exports.getWechatPayList = getWechatPayList;
exports.wxpayopenauth = wxpayopenauth;
exports.undoAudit = undoAudit;
exports.revertCodeRelease = revertCodeRelease;
exports.modifyJumpDomain = modifyJumpDomain;
exports.getByPartnerOpenId = getByPartnerOpenId;
exports.setAppSecret = setAppSecret;
exports.applyReturnPlugin = applyReturnPlugin;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/admin/applet/"), {
  timeout: 120000
});
/**
 * 获取小程序详细信息
 */

function getAppletDetailsInfo(params) {
  return request.post('getAppletDetailsInfo', null, {
    params: params
  });
}
/**
 * 获取小程序代码模板列表
 */


function getTemplateList() {
  return request.get('getTemplateList');
}
/**
 * 获取小程序代码草稿箱代码
 */


function getTemplatedRaftList() {
  return request.get('getTemplatedRaftList');
}
/**
 * 将草稿添加到模板库
 */


function addToTemplate(params) {
  return request.post('addToTemplate', null, {
    params: params
  });
}
/**
 * 提交代码
 */


function commitCode(params) {
  return request.post('commit', null, {
    params: params
  });
}
/**
 * 提交审核
 */


function submitAudit(params) {
  return request.post('submitAudit', null, {
    params: params
  });
}
/**
 * 发布审核已通过的小程序
 */


function releaseMiniApp(params) {
  return request.post('release', null, {
    params: params
  });
}
/**
 * 获取隐私协议指引
 */


function getPrivacySetting(params) {
  return request.post('getPrivacySetting', null, {
    params: params
  });
}
/**
 * 设置隐私协议
 */


function setPrivacySetting(params) {
  return request.post('setPrivacySetting', null, {
    params: params
  });
}
/**
 * 设置域名
 * @param {String} data
 * @returns {Promise<*>}
 */


function modifyDomain(data, params) {
  return request.post('modifyDomain', data, {
    params: params
  });
}
/**
 * 获取订单页path信息
 * @param {String} data
 * @returns {Promise<*>}
 */


function getOrderPathInfo(params) {
  return request.post('getOrderPathInfo', null, {
    params: params
  });
}
/**
 * 设置订单页path信息
 * @param {String} data
 * @returns {Promise<*>}
 */


function applySetOrderPathInfo(params) {
  return request.post('applySetOrderPathInfo', null, {
    params: params
  });
}
/**
 * 开通云用户
 * @param {String} data
 * @returns {Promise<*>}
 */


function createCloudUser(params) {
  return request.post('createCloudUser', null, {
    params: params
  });
}
/**
 * 获取绑定支付商户号列表
 * @param {String} data
 * @returns {Promise<*>}
 */


function getWechatPayList(params) {
  return request.post('getWechatPayList', null, {
    params: params
  });
}
/**
 * 申请商户号授权
 * @param {String} data
 * @returns {Promise<*>}
 */


function wxpayopenauth(params) {
  return request.post('wxpayopenauth', null, {
    params: params
  });
}
/**
 * 撤回代码审核
 * @param {String} data
 * @returns {Promise<*>}
 */


function undoAudit(params) {
  return request.post('undoAudit', null, {
    params: params
  });
}
/**
 * 回退版本
 * @param {String} data
 * @returns {Promise<*>}
 */


function revertCodeRelease(params) {
  return request.post('revertCodeRelease', null, {
    params: params
  });
}
/**
 * 设置域名白名单
 * @param {String} data
 * @returns {Promise<*>}
 */


function modifyJumpDomain(body, params) {
  return request.post('modifyJumpDomain', body, {
    params: params
  });
}
/**
 * 获取小程序相关信息
 * @param {String} data
 * @returns {Promise<*>}
 */


function getByPartnerOpenId(params) {
  return request.get('getByPartnerOpenId', {
    params: params
  });
}
/**
 * 设置AppSecret
 * @param {String} data
 * @returns {Promise<*>}
 */


function setAppSecret(params) {
  return request.post('setAppSecret', null, {
    params: params
  });
}
/**
 * 设置AppSecret
 * @param {String} data
 * @returns {Promise<*>}
 */


function applyReturnPlugin(params) {
  return request.post('applyReturnPlugin', null, {
    params: params
  });
}