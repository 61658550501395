var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "material-dialogue",
      attrs: {
        title: "选择图片",
        width: "1000px",
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.cancelDialogue,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c("div", { staticClass: "flex-content" }, [
        _c(
          "div",
          { staticClass: "material-catalogue" },
          [
            _c("catalogue", {
              attrs: {
                open: _vm.open,
                "is-personal": _vm.isPersonal,
                refresh: _vm.refresh
              },
              on: {
                "update:refresh": function($event) {
                  _vm.refresh = $event
                },
                handleClick: _vm.handleClick
              },
              model: {
                value: _vm.partnerOpenId,
                callback: function($$v) {
                  _vm.partnerOpenId = $$v
                },
                expression: "partnerOpenId"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "material-content" },
          [
            _c("material-content", {
              attrs: {
                "close-dialog": _vm.visible,
                "partner-open-id": _vm.partnerOpenId,
                "open-id": _vm.openId,
                "hidden-add": false,
                "is-personal": _vm.personal,
                "upload-path": _vm.uploadPath,
                "upload-function": _vm.uploadFunction
              },
              on: {
                selectImgResult: _vm.selectImgResult,
                refreshCatalogue: _vm.refreshCatalogue
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelDialogue } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitMaterial } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }