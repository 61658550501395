var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dialogVisibleaudio
      ? _c(
          "div",
          [_c("aplayer", { attrs: { music: _vm.musicList, autoplay: "" } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }