var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderQuery" },
    [
      _c(
        "div",
        {
          staticClass: "exportOrder-case topButton",
          attrs: { type: "border-card" }
        },
        [
          _c("div", { staticClass: "exportOrder-btn" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", flex: "none" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    on: { change: _vm.handleBtnCheck },
                    model: {
                      value: _vm.query.status,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "status", $$v)
                      },
                      expression: "query.status"
                    }
                  },
                  _vm._l(_vm.button_options, function(item) {
                    return _c(
                      "el-radio-button",
                      {
                        key: item.value,
                        attrs: { value: item.value, label: item.value }
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  flex: "auto",
                  "justify-content": "flex-end",
                  "margin-left": "10px"
                }
              },
              [
                _c("searchPartnerByInput", {
                  attrs: { partnerOpenId: _vm.query.partnerOpenId },
                  on: { receivepartnerId: _vm.receivepartnerId }
                }),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { "max-width": "250px", "margin-right": "10px" },
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "搜索订单号、商品名称、用户ID"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchContent($event)
                    }
                  },
                  model: {
                    value: _vm.query.keyword,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "keyword", $$v)
                    },
                    expression: "query.keyword"
                  }
                }),
                _vm._v(" "),
                _c(
                  "searchbtn",
                  {
                    on: {
                      searchContent: _vm.searchContent,
                      firstLoading: _vm.firstLoading
                    }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          id: "elformC",
                          model: _vm.query,
                          "label-width": "100px"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "下单时间" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                size: "mini",
                                "picker-options": _vm.pickerOptions,
                                type: "datetimerange",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                align: "right"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.getlistApiinDate(_vm.dateValue)
                                }
                              },
                              model: {
                                value: _vm.dateValue,
                                callback: function($$v) {
                                  _vm.dateValue = $$v
                                },
                                expression: "dateValue"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否需要邮寄" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getIspostapi(
                                      _vm.query.needAddress
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.query.needAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "needAddress", $$v)
                                  },
                                  expression: "query.needAddress"
                                }
                              },
                              _vm._l(_vm.needAddressOptions, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.query.needAddress
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "邮寄状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleShip(_vm.query.ship)
                                      }
                                    },
                                    model: {
                                      value: _vm.query.ship,
                                      callback: function($$v) {
                                        _vm.$set(_vm.query, "ship", $$v)
                                      },
                                      expression: "query.ship"
                                    }
                                  },
                                  _vm._l(_vm.shipOptions, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "购买方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getlistApiinPay(
                                      _vm.query.buyType
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.query.buyType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "buyType", $$v)
                                  },
                                  expression: "query.buyType"
                                }
                              },
                              _vm._l(_vm.allForFront, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "支付渠道" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changePaymode(_vm.query.payType)
                                  }
                                },
                                model: {
                                  value: _vm.query.payType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "payType", $$v)
                                  },
                                  expression: "query.payType"
                                }
                              },
                              _vm._l(_vm.payTypeData.payTypeData, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.ping_plus_plus_channel
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "购买渠道" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getlistApiinPurchs(
                                      _vm.query.channel
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.query.channel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "channel", $$v)
                                  },
                                  expression: "query.channel"
                                }
                              },
                              _vm._l(_vm.Purchsoptions, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.openId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "来源渠道" } },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                size: "mini",
                                placeholder: "请选择",
                                options: _vm.sourceChannelOptions,
                                props: _vm.sourceProps
                              },
                              on: {
                                change: function($event) {
                                  return _vm.sourceChannelChange(
                                    _vm.query.cooperationChannelOpenId
                                  )
                                }
                              },
                              model: {
                                value: _vm.query.cooperationChannelOpenId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.query,
                                    "cooperationChannelOpenId",
                                    $$v
                                  )
                                },
                                expression: "query.cooperationChannelOpenId"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "资源类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getResourceTypeIdHandle(
                                      _vm.query.resourceType
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.query.resourceType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "resourceType", $$v)
                                  },
                                  expression: "query.resourceType"
                                }
                              },
                              _vm._l(_vm.resourceTypeOptions, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.title, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "沙盒模式" } },
                          [
                            _c("el-switch", {
                              staticStyle: {
                                height: "36px",
                                "line-height": "1"
                              },
                              attrs: { "active-color": "#13ce66" },
                              on: { change: _vm.getinIosSandboxhan },
                              model: {
                                value: _vm.query.inIosSandbox,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "inIosSandbox", $$v)
                                },
                                expression: "query.inIosSandbox"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "学段" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  size: "mini",
                                  multiple: "",
                                  placeholder: "请选择"
                                },
                                on: { change: _vm.handleChangesectionCode },
                                model: {
                                  value: _vm.sectionCode,
                                  callback: function($$v) {
                                    _vm.sectionCode = $$v
                                  },
                                  expression: "sectionCode"
                                }
                              },
                              _vm._l(_vm.SyncSectionData, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "年级" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  size: "mini",
                                  multiple: "",
                                  placeholder: "请选择"
                                },
                                on: { change: _vm.handleChangegradeCode },
                                model: {
                                  value: _vm.gradeCode,
                                  callback: function($$v) {
                                    _vm.gradeCode = $$v
                                  },
                                  expression: "gradeCode"
                                }
                              },
                              _vm._l(_vm.allSyncGrade, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "阶段" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  size: "mini",
                                  multiple: "",
                                  placeholder: "请选择"
                                },
                                on: { change: _vm.handleChangevolumeCode },
                                model: {
                                  value: _vm.volumeCode,
                                  callback: function($$v) {
                                    _vm.volumeCode = $$v
                                  },
                                  expression: "volumeCode"
                                }
                              },
                              _vm._l(_vm.volumeCodetion, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "btnExportOrder",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.exportOrder }
                  },
                  [_vm._v("导出订单")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "btnExportOrder",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.exportPostCon }
                  },
                  [_vm._v("配置物流")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableSty" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: { id: "test", data: _vm.orderlist, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "buyUserInfo commonFontColor",
                              staticStyle: { height: "36px" }
                            },
                            [
                              _vm._v(
                                "订单号：" +
                                  _vm._s(_vm.showVal(scope.row.orderId))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "orderbottomSty" }, [
                            _c("div", { staticClass: "orderbottomLeftTop" }, [
                              scope.row.resourceInfo
                                ? _c(
                                    "div",
                                    { staticClass: "orderbottomLeftTopimg" },
                                    [
                                      scope.row.resourceType === 3
                                        ? _c("el-image", {
                                            staticClass: "videoCover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                scope.row.resourceInfo.cover,
                                                _vm.thumbnailStyle
                                              ),
                                              alt: ""
                                            }
                                          })
                                        : scope.row.resourceType === 5
                                        ? _c("el-image", {
                                            staticClass: "audioBookcover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                "mp/orderCover/jinxinbiCover.png",
                                                _vm.thumbnailStyle
                                              ),
                                              alt: ""
                                            }
                                          })
                                        : scope.row.resourceType === 6
                                        ? _c("el-image", {
                                            staticClass: "audioBookcover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                "mp/orderCover/vipCover.png",
                                                _vm.thumbnailStyle
                                              ),
                                              alt: ""
                                            }
                                          })
                                        : _c("el-image", {
                                            staticClass: "audioBookcover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                scope.row.resourceInfo.cover,
                                                _vm.thumbnailStyle
                                              ),
                                              alt: ""
                                            }
                                          })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "orderbottomLeftTopimg" },
                                    [
                                      scope.row.resourceType !== 5 &&
                                      scope.row.resourceType !== 6
                                        ? _c("el-image", {
                                            staticClass: "audioBookcover",
                                            attrs: {
                                              fit: "cover",
                                              alt: "",
                                              src: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.resourceType === 5
                                        ? _c("el-image", {
                                            staticClass: "audioBookcover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                "mp/orderCover/jinxinbiCover.png",
                                                _vm.thumbnailStyle
                                              ),
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.resourceType === 6
                                        ? _c("el-image", {
                                            staticClass: "audioBookcover",
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                "mp/orderCover/vipCover.png",
                                                _vm.thumbnailStyle
                                              ),
                                              alt: ""
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "orderbottomLeftTopdetail" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "orderbottomLeftTopdetailSty"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(scope.row.resourceTitle) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "fontSty commonFontColor" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.resourcetypetoStr(
                                              scope.row.resourceType
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      scope.row.gifts
                                        ? _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "commonFontColor"
                                              },
                                              [_vm._v("| 含赠品")]
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "所属商户", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "buyUserInfo",
                              staticStyle: { height: "36px", color: "#909399" }
                            },
                            [
                              scope.row.postCompanyName
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.postCompanyName) + ":"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.trackingNumber
                                ? _c(
                                    "span",
                                    { staticStyle: { "margin-left": "5px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showVal(scope.row.trackingNumber)
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "orderbottomSty",
                              staticStyle: { height: "65px" }
                            },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "buyUserInfoStyChild",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.partnerLogo,
                                      _vm.smallthumbnailStyle
                                    ),
                                    alt: ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "buyUserInfoStyChild",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.showVal(scope.row.partnerName))
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "买家", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", { staticStyle: { height: "36px" } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "buyUser" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              scope.row.buyUserInfo
                                ? _c(
                                    "div",
                                    { staticClass: "buyUserInfoSty" },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "buyUserInfoStyChild",
                                          attrs: {
                                            fit: "cover",
                                            src: _vm.getImgUrl(
                                              scope.row.buyUserInfo.cover,
                                              _vm.smallthumbnailStyle
                                            ),
                                            alt: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error"
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "buyUserInfoStyChild",
                                                attrs: {
                                                  src: require("@/assets/book_cover.png")
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !scope.row.buyUserInfo
                                ? _c("div", { staticClass: "buyUserInfoSty" }, [
                                    _c("img", {
                                      staticClass: "buyUserInfoStyChild",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.buyUserInfo &&
                              scope.row.buyUserInfo.displayName
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "white-space": "normal",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                        padding: "0 5px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showVal(
                                            scope.row.buyUserInfo.displayName
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.buyUserInfo &&
                              scope.row.buyUserInfo.vipUser === true
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "14px",
                                          height: "18px"
                                        },
                                        attrs: {
                                          src: require("@/assets/images/VIP.png"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "fontSty commonFontColor" },
                              [
                                scope.row.sectionName
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.sectionName) + " / "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.gradeName
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.gradeName) + " / "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.volumeName
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.volumeName) + " ")
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "下单时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", { staticStyle: { height: "36px" } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "orderbottomSty" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.showVal(scope.row.createdAt)) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", { staticStyle: { height: "36px" } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "orderbottomSty" }, [
                            _c("div", [
                              _c("div", { staticClass: "payStatus" }, [
                                scope.row.status === 1
                                  ? _c("span", [_vm._v("未支付")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 2
                                  ? _c("span", [_vm._v("已支付")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 4
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#F56C6C" } },
                                      [_vm._v("已退款")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status === 5
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#F56C6C" } },
                                      [_vm._v("已失效")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单金额", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", { staticStyle: { height: "36px" } }),
                          _vm._v(" "),
                          _c("div", [
                            scope.row.buyType === 1 ||
                            scope.row.buyType === 7 ||
                            scope.row.buyType === 8
                              ? _c("div", { staticClass: "pay_number" }, [
                                  _vm._v(
                                    "\n                ¥" +
                                      _vm._s(
                                        _vm.showVal(
                                          Number(
                                            scope.row.payPrice / 100
                                          ).toFixed(2)
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.buyType === 3
                              ? _c("div", { staticClass: "pay_number" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.buyType == 3
                                          ? "激活卡购买"
                                          : "--"
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.buyType === 4
                              ? _c("div", { staticClass: "pay_number" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.buyType == 4 ? "赠品" : "--"
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.buyType === 5
                              ? _c("div", { staticClass: "pay_number" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.buyType == 5
                                          ? "后台添加"
                                          : "--"
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.buyType === 6
                              ? _c("div", { staticClass: "pay_number" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.buyType == 6
                                          ? "原系统导入"
                                          : "--"
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.buyType === 2
                              ? _c("div", { staticClass: "pay_number" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.buyType == 2 ? "金星币" : "--"
                                      ) +
                                      "\n                " +
                                      _vm._s(
                                        _vm.showVal(
                                          Math.ceil(scope.row.payPrice / 10)
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.buyType === 1 ||
                            scope.row.buyType === 7 ||
                            scope.row.buyType === 8
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "payFormulaSimple commonFontColor"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.payFormulaSimple) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "right",
                  fixed: "right",
                  width: "300"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "commonFontColor",
                              staticStyle: { height: "36px" }
                            },
                            [
                              _vm._v(
                                "\n              购买渠道：" +
                                  _vm._s(_vm.showChannel(scope.row.channel)) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "orderbottomSty",
                              staticStyle: {
                                "justify-content": "flex-end",
                                height: "65px"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ofMerchant" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.browsingHistory(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("浏览记录")]
                                  ),
                                  _vm._v(" "),
                                  scope.row.needAddress
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.updataAddress(
                                                scope.row.postCompanyId,
                                                scope.row.postCompanyName,
                                                scope.row.trackingNumber,
                                                scope.row.orderId
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("物流信息")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.toOrderdetail(
                                            scope.row.orderId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("详情")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.traOrder(scope.row.orderId)
                                        }
                                      }
                                    },
                                    [_vm._v("转移")]
                                  ),
                                  _vm._v(" "),
                                  scope.row.status !== 5 &&
                                  scope.row.status !== 4
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#F56C6C" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleinvalid(
                                                scope.row.orderId
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("失效")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status === 5
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.recovery(
                                                scope.row.orderId
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("恢复")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commonFlex" },
        [
          _c(
            "div",
            {
              staticClass: "t-block1",
              staticStyle: { color: "#606266", display: "flex" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { padding: "5px 0" },
                  style: _vm.showMoney ? "" : "margin-right:5px"
                },
                [
                  _c("div", { staticClass: "commonsmallSize" }, [
                    _vm._v("订单收入")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "commonSizeBold" }, [
                    _vm._v(
                      "¥" +
                        _vm._s(
                          _vm.numFormat(
                            _vm.showVal(_vm.cashData.sumIncome / 100).toFixed(2)
                          )
                        )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showMoney
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "background-color": "#F5F7FA",
                        padding: "5px",
                        margin: "0 5px"
                      }
                    },
                    [
                      _c("div", { staticClass: "commonsmallSize" }, [
                        _c("div", [_vm._v("平台收入")]),
                        _c("div", [
                          _vm._v(
                            "¥" +
                              _vm._s(
                                _vm.numFormat(
                                  _vm
                                    .showVal(_vm.cashData.officalIncome / 100)
                                    .toFixed(2)
                                )
                              )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "commonsmallSize",
                          staticStyle: { margin: "0 5px" }
                        },
                        [
                          _c("div", [_vm._v("商户收入")]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.numFormat(
                                    _vm
                                      .showVal(_vm.cashData.realIncome / 100)
                                      .toFixed(2)
                                  )
                                )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "commonsmallSize",
                          staticStyle: { "margin-right": "5px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "fontSize_12 color_909399" },
                            [_vm._v("分销金额")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "fontSize_14 color_606266" },
                            [
                              _vm._v(
                                "¥" +
                                  _vm._s(
                                    _vm.numFormat(
                                      _vm
                                        .showVal(
                                          _vm.cashData.distributionAmount / 100
                                        )
                                        .toFixed(2)
                                    )
                                  )
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "commonsmallSize" }, [
                        _c("div", [_vm._v("手续费")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "¥" +
                              _vm._s(
                                _vm.numFormat(
                                  _vm
                                    .showVal(_vm.cashData.sumCommission / 100)
                                    .toFixed(2)
                                )
                              )
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "#F5F7FA",
                    height: "50px",
                    display: "flex",
                    "align-items": "center",
                    cursor: "pointer",
                    width: "10px",
                    "justify-content": "center"
                  }
                },
                [
                  _vm.showMoney
                    ? _c("i", {
                        staticClass: "el-icon-arrow-left",
                        on: {
                          click: function($event) {
                            _vm.showMoney = false
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showMoney
                    ? _c("i", {
                        staticClass: "el-icon-arrow-right",
                        on: {
                          click: function($event) {
                            _vm.showMoney = true
                          }
                        }
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-left": "5px", padding: "5px 0" } },
                [
                  _c("div", { staticClass: "commonsmallSize" }, [
                    _vm._v(" 金星币")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "commonSizeBold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.showVal(Math.ceil(_vm.cashData.gold / 10)))
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "t-block",
            attrs: {
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              total: _vm.totalElements,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.browsingHistoryDia,
            width: "60%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.browsingHistoryDia = $event
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("span", { staticStyle: { "margin-right": "10px" } }, [
              _vm._v("浏览记录")
            ]),
            _vm._v(" "),
            _vm.currentUserInfo
              ? _c("span", [
                  _vm.currentUserInfo.cover
                    ? _c(
                        "span",
                        [
                          _c("el-image", {
                            staticClass: "buyUserInfoStyChild",
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                _vm.currentUserInfo.cover,
                                _vm.smallthumbnailStyle
                              ),
                              alt: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#606266" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.showVal(_vm.currentUserInfo.displayName)) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "commonsmallSize" }, [
                    _vm._v(
                      "\n          用户ID: " +
                        _vm._s(_vm.showVal(_vm.currentUserInfo.openId)) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { "margin-left": "20px" } },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "140px" },
                  attrs: {
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    type: "date",
                    placeholder: "开始时间"
                  },
                  on: {
                    change: function($event) {
                      return _vm.changeBrowsingHistoryStart(
                        _vm.browsingHistoryStart
                      )
                    }
                  },
                  model: {
                    value: _vm.browsingHistoryStart,
                    callback: function($$v) {
                      _vm.browsingHistoryStart = $$v
                    },
                    expression: "browsingHistoryStart"
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(" - ")]),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticStyle: { width: "140px" },
                  attrs: {
                    disabled: "",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    type: "date",
                    placeholder: "结束时间"
                  },
                  model: {
                    value: _vm.currentCreatedAt,
                    callback: function($$v) {
                      _vm.currentCreatedAt = $$v
                    },
                    expression: "currentCreatedAt"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingbrowsingHistory,
                  expression: "loadingbrowsingHistory"
                }
              ],
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: { data: _vm.browsingHistoryData, height: "500px" }
            },
            [
              _c("el-table-column", {
                attrs: { property: "time", label: "日期", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.format(scope.row.time * 1000)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "pageTitle", label: "标题", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.showVal(scope.row.httpUrl)))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    target: "_blank",
                                    href: scope.row.httpUrl
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(
                                        scope.row.pageTitle === "undefined"
                                          ? scope.row.systemName
                                          : scope.row.pageTitle
                                      ) +
                                      "\n               "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "systemName",
                  label: "系统",
                  align: "right",
                  fixed: "right"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: {
              display: "flex",
              "justify-content": "flex-end",
              "padding-top": "20px"
            },
            attrs: {
              "current-page": _vm.browsingHistoryParams.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.browsingHistoryParams.size,
              total: _vm.totalElementshis,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.handleSizeChangehis,
              "current-change": _vm.handleCurrentChangehis
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.postConfigloading,
              expression: "postConfigloading"
            }
          ],
          attrs: {
            "append-to-body": "",
            title: "配置物流信息",
            visible: _vm.dialogPostConfig,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPostConfig = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "postCondialogFormVisible", attrs: { model: _vm.formPost } },
            [
              _c(
                "el-form-item",
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("span", { staticClass: "star" }, [_vm._v("*")]),
                    _vm._v("配置文件\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "uploadExcel",
                      attrs: {
                        action:
                          "//api.cloud.dev.taozhi.online/order/admin/import/orderForm/csv",
                        limit: _vm.limitNum,
                        accept: ".csv",
                        "auto-upload": false,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeUploadFile,
                        "on-change": _vm.httpRequestNega,
                        "on-exceed": _vm.exceedFile,
                        "file-list": _vm.fileList
                      }
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("选择文件")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("只能上传csv文件，且不超过10M")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogPostConfig = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.uploadFile } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.addressloadingdialogFormVisible,
              expression: "addressloadingdialogFormVisible"
            }
          ],
          attrs: {
            "append-to-body": "",
            title: "设置物流信息",
            visible: _vm.addressdialogFormVisible,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.addressdialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addressdialogFormVisible",
              attrs: { model: _vm.trackingNumberForm }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流渠道",
                    "label-width": "80px",
                    prop: "postCompanyName",
                    rules: [{ required: true, message: "物流渠道不能为空" }]
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.postCompanyName,
                        callback: function($$v) {
                          _vm.postCompanyName = $$v
                        },
                        expression: "postCompanyName"
                      }
                    },
                    _vm._l(_vm.postOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.expName, value: item.expName }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "trackingNumber",
                    label: "运单号",
                    "label-width": "80px",
                    rules: [{ required: true, message: "运单号不能为空" }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "运单号" },
                    model: {
                      value: _vm.trackingNumberForm.trackingNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trackingNumberForm,
                          "trackingNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "trackingNumberForm.trackingNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addressdialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.updataAddressNumber("addressdialogFormVisible")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.invalidloadingdialogFormVisible,
              expression: "invalidloadingdialogFormVisible"
            }
          ],
          attrs: {
            "append-to-body": "",
            title: "失效",
            visible: _vm.invaliddialogFormVisible,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.invaliddialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "invalidformRecord", attrs: { model: _vm.invalidform } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "Reason",
                    label: "失效原因",
                    rules: [{ required: true, message: "失效原因不能为空" }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "失效原因" },
                    model: {
                      value: _vm.invalidform.Reason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.invalidform,
                          "Reason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "invalidform.Reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.invaliddialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.invalid("invalidformRecord")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingdialogFormVisible,
              expression: "loadingdialogFormVisible"
            }
          ],
          attrs: {
            "append-to-body": "",
            title: "转移商品",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "430px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "transferRecord", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "transferUser",
                  attrs: { "label-width": "135px" }
                },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("span", { staticClass: "star" }, [_vm._v("*")]),
                    _vm._v("将当前商品转移给\n        ")
                  ]),
                  _vm._v(" "),
                  _c("searchUserByInput", {
                    on: { receiveuserid: _vm.receiveuseridTransfer }
                  }),
                  _vm._v(" "),
                  _vm.showError
                    ? _c("p", { staticClass: "el-form-item__error" }, [
                        _vm._v("转移用户不能为空")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "transReason",
                    label: "转移备注",
                    rules: [{ required: true, message: "原因不能为空" }],
                    "label-width": "135px"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "转移商品备注信息"
                    },
                    model: {
                      value: _vm.form.transReason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "transReason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.transReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.transferHandle("transferRecord")
                    }
                  }
                },
                [_vm._v("转移")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("order-detail", {
        staticClass: "orderDetailWrap",
        attrs: { visible: _vm.orderDetailDia, orderId: _vm.currentorderId },
        on: {
          "update:visible": function($event) {
            _vm.orderDetailDia = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "680px",
            "append-to-body": "",
            visible: _vm.exportOrderDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.exportOrderDialog = $event
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("\n      导出订单\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "exportOrderDialogSty exportOrderCommonMargin" },
              [
                _vm._v(
                  "订单状态：\n        " +
                    _vm._s(_vm.orderStatusStr(_vm.query.status)) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "exportOrderDialogSty exportOrderCommonMargin" },
              [_vm._v("关键词：" + _vm._s(_vm.showVal(_vm.query.keyword)))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "exportOrderDialogSty" }, [
            _vm._v(
              "下单时间：" +
                _vm._s(_vm.showVal(_vm.query.createdStartTime)) +
                " ~ " +
                _vm._s(_vm.showVal(_vm.query.createdEndTime))
            )
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "exportOrderDialogSty exportOrderCommonMargin" },
              [
                _vm._v(
                  "需要邮寄：" +
                    _vm._s(_vm.needAddressStr(_vm.query.needAddress))
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "exportOrderDialogSty exportOrderCommonMargin" },
              [
                _vm._v(
                  "购买方式：" + _vm._s(_vm.buyTypetoStr(_vm.query.buyType))
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "exportOrderDialogSty" }, [
              _vm._v("支付方式：" + _vm._s(_vm.showPayType(_vm.query.payType)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "exportOrderDialogSty exportOrderCommonMargin" },
              [
                _vm._v(
                  "购买渠道：" + _vm._s(_vm.showChannel(_vm.query.channel))
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "exportOrderDialogSty exportOrderCommonMargin" },
              [
                _vm._v(
                  "资源类型：" +
                    _vm._s(
                      _vm.showVal(_vm.resourcetypetoStr(_vm.query.resourceType))
                    )
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                width: "640px",
                height: "122px",
                "align-items": "center",
                margin: "10px 0"
              }
            },
            [
              _c("div", {
                staticStyle: {
                  width: "5px",
                  height: "122px",
                  "background-color": "#50BFFF",
                  "border-radius": "4px 0px 0px 4px"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "auto",
                    "background-color": "#ECF8FF",
                    height: "122px",
                    padding: "15px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v("提示说明：")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-top": "10px" } }, [
                    _vm._v(
                      "1. 如需修改以上条件，请关闭本窗口，重选条件后，点击“导出订单”；"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("2. 您可在“应用-数据导出”中查看"),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.jumpToexportDataC }
                        },
                        [_vm._v("导出记录")]
                      ),
                      _vm._v("；")
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v("下单时间")
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  size: "mini",
                  "picker-options": _vm.pickerOptions,
                  type: "datetimerange",
                  "default-time": ["00:00:00", "23:59:59"],
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right"
                },
                on: {
                  change: function($event) {
                    return _vm.getlistApiinDateOrderexport(_vm.dateValue)
                  }
                },
                model: {
                  value: _vm.dateValue,
                  callback: function($$v) {
                    _vm.dateValue = $$v
                  },
                  expression: "dateValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.exportOrderDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.exportOrderHandle("导出分析")
                    }
                  }
                },
                [_vm._v("导出分析数据")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.exportOrderHandle("导出订单")
                    }
                  }
                },
                [_vm._v("导出订单数据")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }