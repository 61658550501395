var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.videoInfo
    ? _c(
        "div",
        { staticClass: "dislogBox" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.isShow,
                "before-close": _vm.close,
                title: "确认批量上传"
              },
              on: {
                "update:visible": function($event) {
                  _vm.isShow = $event
                }
              }
            },
            [
              _c("div", { staticClass: "dialog-box" }, [
                _c("dl", [
                  _c("dt", [_c("i"), _c("h3", [_vm._v("基本信息")])]),
                  _vm._v(" "),
                  _vm.resourceType !== "10"
                    ? _c("dd", [
                        _vm._v("预览时长："),
                        _c("span", [
                          _vm._v(_vm._s(_vm.videoInfo.previewLength) + "秒")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.videoInfo.cover
                    ? _c("dd", [
                        _vm._v("作品封面：\n          "),
                        _c(
                          "span",
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "merchantCover",
                                attrs: {
                                  src: _vm.imageUrl(
                                    _vm.videoInfo.cover
                                      ? _vm.videoInfo.cover
                                      : "",
                                    "style/thumbnail"
                                  ),
                                  alt: ""
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "merchantCover",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-picture-outline"
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _c("dd", [_vm._v("作品封面：无")]),
                  _vm._v(" "),
                  _vm.videoInfo.detail && _vm.resourceType === "10"
                    ? _c(
                        "dd",
                        { staticClass: "txt-detail" },
                        [
                          _c("span", [_vm._v("文件描述：")]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  _vm.showDetail = true
                                }
                              }
                            },
                            [_vm._v("查看")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("dl", { staticClass: "info-box-class" }, [
                  _c("dt", [_c("i"), _c("h3", [_vm._v("作品属性")])]),
                  _vm._v(" "),
                  _c("dd", [
                    _c("span", { staticClass: "attributes-text" }, [
                      _vm._v("同步：")
                    ]),
                    _c("span", { staticClass: "sync-text-class" }, [
                      _vm._v(
                        _vm._s(
                          _vm.videoInfo.displayName > 0
                            ? _vm.videoInfo.displayName
                            : "不限"
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _c("span", { staticClass: "attributes-text" }, [
                      _vm._v("分类：")
                    ]),
                    _c("span", { staticClass: "sync-text-class" }, [
                      _vm._v(_vm._s(_vm.videoInfo.typestext))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _c("span", [_vm._v("版次：")]),
                    _c("span", [_vm._v(_vm._s(_vm.videoInfo.periodName))])
                  ]),
                  _vm._v(" "),
                  _vm.videoInfo.categoryText
                    ? _c("dd", [
                        _c("span", [_vm._v("类目：")]),
                        _c("span", { staticStyle: { "max-width": "85%" } }, [
                          _vm._v(_vm._s(_vm.videoInfo.categoryText))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.videoInfo.areaList
                    ? _c("dd", [
                        _c("span", [_vm._v("地区：")]),
                        _c("span", { staticStyle: { "max-width": "85%" } }, [
                          _vm._v(_vm._s(_vm.videoInfo.areaList))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.videoInfo.tagList && _vm.videoInfo.tagList.length > 0
                    ? _c("dd", [
                        _c("span", [_vm._v("标签：")]),
                        _c("span", { staticStyle: { "max-width": "85%" } }, [
                          _vm._v(_vm._s(_vm.videoInfo.tagList.join(" / ")))
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_c("i"), _c("h3", [_vm._v("商品属性")])]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v("售卖方式："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.goods.independentSale
                            ? "支持单独售卖"
                            : "不支持单独售卖"
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.goods.independentSale
                    ? _c("span", [
                        _c("dd", [
                          _vm._v("商品价格："),
                          _c("span", [
                            _vm._v(
                              _vm._s(parseFloat(_vm.goods.price / 100)) +
                                "元" +
                                _vm._s(
                                  _vm.goods.goldStar ? "，可使用金星币购买" : ""
                                )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v("商品折扣："),
                          _c("span", [
                            _vm._v(_vm._s(_vm.goods.discount) + "%，")
                          ]),
                          _vm._v("减价："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                parseFloat(
                                  _vm.goods.price / 100 - _vm.discount
                                ).toFixed(2)
                              ) + "元，"
                            )
                          ]),
                          _vm._v("折后价："),
                          _c("span", [_vm._v(_vm._s(_vm.discount) + "元")])
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v("VIP折扣："),
                          _c("span", [
                            _vm._v(_vm._s(_vm.goods.vipDiscount) + "%，")
                          ]),
                          _vm._v("减价："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                parseFloat(
                                  _vm.goods.price / 100 - _vm.vipDiscount
                                ).toFixed(2)
                              ) + "元，"
                            )
                          ]),
                          _vm._v("折后价："),
                          _c("span", [_vm._v(_vm._s(_vm.vipDiscount) + "元")])
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v("有效期："),
                          _c("span", [
                            _vm._v(_vm._s(_vm.goods.validity) + "个月")
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v("认证教师免费："),
                    _c("span", [
                      _vm._v(_vm._s(_vm.goods.teacherFree ? "参加" : "不参加"))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.confirmFun()
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showDetail },
              on: {
                "update:visible": function($event) {
                  _vm.showDetail = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "detail-txt-wrapper" },
                [
                  _c("editor", {
                    attrs: { readonly: true },
                    model: {
                      value: _vm.videoInfo.detail,
                      callback: function($$v) {
                        _vm.$set(_vm.videoInfo, "detail", $$v)
                      },
                      expression: "videoInfo.detail"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }