var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-page-box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showImage,
              expression: "!showImage"
            }
          ],
          staticClass: "no-page-box",
          on: {
            click: function($event) {
              return _vm.openSelectPageDialog()
            }
          }
        },
        [_vm._m(0), _vm._v(" "), _c("div", [_vm._v("选择书页")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showImage,
              expression: "showImage"
            }
          ],
          staticClass: "page-box"
        },
        [
          _c("img", {
            staticClass: "book-image",
            attrs: { onerror: _vm.errorUserPhoto, src: _vm.thumbImageUrl }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "cover" }, [
            _c(
              "span",
              {
                staticClass: "hover-operation",
                on: {
                  click: function($event) {
                    return _vm.previewBookPage()
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-zoom-in" })]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canClickSelectPageBtn,
                    expression: "canClickSelectPageBtn"
                  }
                ],
                staticClass: "hover-operation",
                on: {
                  click: function($event) {
                    return _vm.replaceBookPage()
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-refresh" })]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canClickSelectPageBtn,
                    expression: "canClickSelectPageBtn"
                  }
                ],
                staticClass: "hover-operation",
                on: {
                  click: function($event) {
                    return _vm.deleteBookPage()
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-delete" })]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-tips" }, [
        _vm._v("点击选择所关联的书页")
      ]),
      _vm._v(" "),
      _c("select-link", {
        attrs: {
          visible: _vm.openSelectLink,
          "props-active-name": "audioBook",
          audioBookOpenId: _vm.linkItem.bookOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.openSelectLink = $event
          }
        },
        model: {
          value: _vm.selectInfo,
          callback: function($$v) {
            _vm.selectInfo = $$v
          },
          expression: "selectInfo"
        }
      }),
      _vm._v(" "),
      _vm.showPreview && _vm.previewUrl
        ? _c("el-image-viewer", {
            staticClass: "image-viewer",
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.getImageUrl(_vm.previewUrl)]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-picture-outline" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }