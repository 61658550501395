"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.has = has;
exports.put = put;
exports.forget = forget;

var _CacheExpiration = _interopRequireDefault(require("./CacheExpiration/CacheExpiration"));

/**
 * 从缓存中删除数据
 *
 * @param {string} key
 */
function forget(key) {
  if (typeof key !== 'string') {
    return;
  }

  if (!key) {
    return;
  } //


  localStorage.removeItem(key);
}
/**
 * 用于判断缓存项是否存在
 *
 * @param {string} key
 */


function has(key) {
  if (typeof key !== 'string') {
    return false;
  }

  if (!key) {
    return false;
  } //


  var value = localStorage.getItem(key);

  if (!value) {
    return false;
  } // 值无效


  try {
    var json = JSON.parse(value);

    if (json.data === undefined) {
      throw new Error("has \u65B9\u6CD5\u4ECE\u7F13\u5B58\u4E2D\u53D6\u5230\u4E86\u503C ".concat(value, " \uFF0C\u8BE5\u503C\u4E2D\u4E0D\u5305\u542B data \u5C5E\u6027"));
    } // 判断是否过期


    if (json.expiredAt) {
      if (new _CacheExpiration.default(new Date(json.expiredAt)).isExpired) {
        forget(key);
        return false;
      }
    }
  } catch (e) {
    console.warn(e && e.message);
    forget(key);
    return false;
  }

  return true;
}
/**
 * 将数据存储到缓存中。
 *
 * @param {string} key
 * @param {any} value
 * @param {Date|number} expiredAt 过期时间
 */


function put(key, value) {
  var expiredAt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

  if (typeof key !== 'string') {
    return undefined;
  }

  if (!key) {
    return undefined;
  } //


  var cacheExpiredAt = new _CacheExpiration.default(expiredAt);

  if (value === undefined || cacheExpiredAt.isExpired) {
    // value 为 undefined 或 expiredAt 已经过期时，应当清除缓存
    forget(key);
    return undefined;
  }

  var json;

  try {
    json = JSON.stringify({
      data: value,
      expiredAt: cacheExpiredAt.expiredAtUnix
    });
  } catch (e) {
    // 提供的 value 无法被保存
    console.warn(e && e.message);
    forget(key);
    return undefined;
  }

  localStorage.setItem(key, json);
  return value;
}