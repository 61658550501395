"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = require("lodash");

var _index = _interopRequireDefault(require("./Group/index"));

var _index2 = _interopRequireDefault(require("./Table/index"));

var _converter = require("@/utils/views/product/converter");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_index',
  components: {
    SpecificationGroup: _index.default,
    SpecificationTable: _index2.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    group: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      specification: [],
      onSpecificationUpdatedDebounced: (0, _lodash.debounce)(this.onSpecificationUpdated, 382),
      specificationName: {}
    };
  },
  computed: {
    specificationGroup: {
      get: function get() {
        return this.group;
      },
      set: function set(value) {
        this.$emit('update:group', value);
      }
    },
    isShowTable: function isShowTable() {
      return this.specificationGroup.length > 0;
    }
  },
  watch: {
    value: function value() {
      this.onValueUpdated();
    }
  },
  mounted: function mounted() {
    this.onValueUpdated();
  },
  methods: {
    onValueUpdated: function onValueUpdated() {
      try {
        this.specification = JSON.parse(this.value);
      } catch (e) {// eslint-disable-line no-empty
      }
    },
    addValue: function addValue(index) {
      this.specification[index].value.push('');
      this.onSpecificationUpdatedDebounced();
    },
    updateValue: function updateValue(item, index, value) {
      this.$set(this.specification[item].value, index, value);
      this.onSpecificationUpdatedDebounced();
    },
    updateName: function updateName(index, name) {
      this.specification[index].name = name;
      this.onSpecificationUpdatedDebounced();
    },
    deleteValue: function deleteValue(item, index) {
      this.specification[item].value = this.specification[item].value.filter(function (v, k) {
        return k !== index;
      });
      this.onSpecificationUpdatedDebounced();
    },
    addItem: function addItem() {
      this.specification.push({
        name: undefined,
        value: ['']
      });
      this.onSpecificationUpdatedDebounced();
    },
    deleteItem: function deleteItem(item) {
      this.specification = this.specification.filter(function (v, k) {
        return k !== item;
      });
      this.onSpecificationUpdatedDebounced();
    },
    onSpecificationUpdated: function onSpecificationUpdated() {
      this.$emit('input', JSON.stringify(this.specification));
      this.updateGroup();
    },
    updateGroup: function updateGroup() {
      var _crossSpecification = (0, _converter.crossSpecification)(this.specification),
          title = _crossSpecification.title,
          list = _crossSpecification.list;

      this.specificationName = title;
      this.specificationGroup = (0, _converter.updateSpecification)(this.specificationGroup, list);
    }
  }
};
exports.default = _default2;