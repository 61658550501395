"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
exports.parseParams = parseParams;

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.entries");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.match");

/**
 * 解析一个模板中的变量列表
 *
 * @param {string} template 待解析的模板
 * @returns {[]}
 */
function parseParams(template) {
  try {
    return template.match(/(?<=\{\{)\w*?(?=\.DATA\}\})/g);
  } catch (e) {
    console.warn(e.message);
    return [];
  }
}
/**
 * 替换模板中的指定参数
 *
 * @param {string} template 模板
 * @param {string} parameter 参数
 * @param {?string} value 值
 * @returns {string}
 */


function renderParameter(template, parameter, value) {
  try {
    if (!template) {
      throw new Error('template 不能为空！');
    }

    if (!parameter) {
      throw new Error('parameter 不能为空！');
    }

    return template.replace(new RegExp("\\{\\{".concat(parameter, ".DATA\\}\\}")), value);
  } catch (e) {
    console.warn(e.message);
    return template;
  }
}
/**
 * 使用给定的模板格式化字符串
 *
 * @param {string} template 模板
 * @param {?object} parameters 参数
 * @returns {?string}
 */


function render(template, parameters) {
  try {
    if (!template) {
      throw new Error('template 不能为空！');
    }

    if (!parameters) {
      throw new Error('parameters 不能为空！');
    }

    var res = template;
    var value;

    for (var _i = 0, _Object$entries = Object.entries(parameters); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
          parameter = _Object$entries$_i[0],
          parameterData = _Object$entries$_i[1];

      value = parameterData ? parameterData.value : undefined;
      var html = "<span style=\"color: ".concat(parameterData.color, "\">") + "".concat(value) + '</span>';

      if (res && parameter) {
        // 如果参数有值就对模板进行一次格式化
        res = renderParameter(res, parameter, html);
      }
    }

    return res;
  } catch (e) {
    console.warn(e.message);
    return template;
  }
}