var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-table-column", {
    attrs: { width: "100", fixed: "right" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(scope) {
          return [
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-edit",
                    attrs: {
                      type: "text",
                      disabled: _vm.isActionDisabled(scope.row.productOpenId)
                    },
                    on: {
                      click: function($event) {
                        return _vm.toEdit(scope.row.productOpenId)
                      }
                    }
                  },
                  [_vm._v("详情")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "btn-del",
                    attrs: {
                      type: "text",
                      disabled: _vm.isActionDisabled(scope.row.productOpenId)
                    },
                    on: {
                      click: function($event) {
                        return _vm.toDelete(scope.row.productOpenId)
                      }
                    }
                  },
                  [_vm._v("移除")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }