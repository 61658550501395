var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "appletForm",
          staticClass: "applet-form",
          attrs: {
            model: _vm.valueProxy,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "页面名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请填写页面名称..." },
                model: {
                  value: _vm.valueProxy.name,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "name", $$v)
                  },
                  expression: "valueProxy.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "小程序名", prop: "title" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请填写小程序名..." },
                model: {
                  value: _vm.valueProxy.title,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "title", $$v)
                  },
                  expression: "valueProxy.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "小程序id", prop: "appId" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请填写小程序id..." },
                model: {
                  value: _vm.valueProxy.appId,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "appId", $$v)
                  },
                  expression: "valueProxy.appId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "原始id", prop: "id" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请填原始id..." },
                model: {
                  value: _vm.valueProxy.id,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "id", $$v)
                  },
                  expression: "valueProxy.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "APPSecret", prop: "appSecret" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: "请填写小程序appSecret..."
                },
                model: {
                  value: _vm.valueProxy.appSecret,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "appSecret", $$v)
                  },
                  expression: "valueProxy.appSecret"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "小程序路径", prop: "url" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 4 },
                  placeholder: "请填写小程序页面路径（pages/index/index）...",
                  clearable: ""
                },
                model: {
                  value: _vm.valueProxy.url,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "url", $$v)
                  },
                  expression: "valueProxy.url"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "页面参数" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 2 },
                  placeholder: "请填写小程序页面传参（id=123）...",
                  clearable: ""
                },
                model: {
                  value: _vm.valueProxy.query,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "query", $$v)
                  },
                  expression: "valueProxy.query"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }