var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-checkbox-group",
    {
      attrs: { size: "small" },
      on: { change: _vm.changeGroup },
      model: {
        value: _vm.checkList,
        callback: function($$v) {
          _vm.checkList = $$v
        },
        expression: "checkList"
      }
    },
    _vm._l(_vm.list, function(item, index) {
      return _c(
        "div",
        { key: item.examQuestionOpenId, staticClass: "group-item" },
        [
          _c(
            "el-checkbox",
            {
              staticClass: "checkbox",
              attrs: { label: item.examQuestionOpenId }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ]
                },
                [_vm._v(_vm._s(item.examQuestionOpenId))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "sequence" }, [
                  _vm._v(_vm._s(index + 1))
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "stem",
                  style: { width: _vm.isPaper ? "415px" : "435px" },
                  domProps: { innerHTML: _vm._s(item.stem) }
                })
              ])
            ]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }