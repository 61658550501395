var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fileListUploaderLayout",
      on: { click: _vm.onFileUploadClicked }
    },
    [
      _vm._t("default", [
        _c(
          "el-button",
          {
            attrs: { disabled: !_vm.canUpload, type: "primary", size: "small" }
          },
          [_vm._v("\n      上传发票\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "fileInput",
        attrs: { disabled: !_vm.canUpload, type: "file", multiple: "multiple" },
        on: { change: _vm.onFileInputChanged }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }