"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * versionNumber :版本号
 * versionUpdateTime : 版本更新时间
 * versionUupdateContent : 版本更新内容
 */
var _default = {
  versionManagement: [{
    versionNumber: 'v5.6.5',
    versionUpdateTime: '2021-11-4',
    versionUupdateContent: ['1.选择链接弹窗中点学书书页添加搜索']
  }, {
    versionNumber: 'v5.6.4',
    versionUpdateTime: '2021-11-3',
    versionUupdateContent: ['1.添加微页']
  }, {
    versionNumber: 'v5.6.3',
    versionUpdateTime: '2021-10-25',
    versionUupdateContent: ['1.修复作品批量上传中的确认批量上传弹窗 同步和分类不显示']
  }, {
    versionNumber: 'v5.6.2',
    versionUpdateTime: '2021-10-22',
    versionUupdateContent: ['1.无admin的接口添加admin']
  }, {
    versionNumber: 'v5.6.1',
    versionUpdateTime: '2021-10-21',
    versionUupdateContent: ['1.去掉接口请求 添加不需要token接口的处理', '2.getAllUsers 接口添加工单调用时不提示报错信息']
  }, {
    versionNumber: 'v5.6.0',
    versionUpdateTime: '2021-10-20',
    versionUupdateContent: ['1.富文本<o:p>替换成<p>', '2.接口请求 添加不需要token接口的处理']
  }, {
    versionNumber: 'v5.5.9',
    versionUpdateTime: '2021-10-20',
    versionUupdateContent: ['1.修复xss漏洞 工单和评论回复转义特殊符号']
  }, {
    versionNumber: 'v5.5.8',
    versionUpdateTime: '2021-10-18',
    versionUupdateContent: ['1.修复音频打点批量上传100以上的数没转换成对应时分秒格式']
  }, {
    versionNumber: 'v5.5.7',
    versionUpdateTime: '2021-10-14',
    versionUupdateContent: ['1.富文本图片上传去掉插入图片']
  }, {
    versionNumber: 'v5.5.6',
    versionUpdateTime: '2021-10-13',
    versionUupdateContent: ['1.修复第二次文件替换打不开选择文件弹窗']
  }, {
    versionNumber: 'v5.5.5',
    versionUpdateTime: '2021-10-09',
    versionUupdateContent: ['1.作品批量上传添加全部分类', '2.批量修改属性添加全部分类']
  }, {
    versionNumber: 'v5.5.4',
    versionUpdateTime: '2021-10-08',
    versionUupdateContent: ['1.给所有作品添加全部分类', '2.选择作品弹窗搜索分类变成可选父级']
  }, {
    versionNumber: 'v5.5.3',
    versionUpdateTime: '2021-09-14',
    versionUupdateContent: ['1.修复学习中心字段使用不正确和优化样式', '2.优化工单详情弹窗样式']
  }, {
    versionNumber: 'v5.5.2',
    versionUpdateTime: '2021-09-07',
    versionUupdateContent: ['1.修复工单链接其他浏览器触发错误', '2.优化工单链接作品链接的资源分类']
  }, {
    versionNumber: 'v5.5.1',
    versionUpdateTime: '2021-09-01',
    versionUupdateContent: ['1.修复素材库图片预览变双击']
  }, {
    versionNumber: 'v5.5.0',
    versionUpdateTime: '2021-08-28',
    versionUupdateContent: ['1.修复素材库编辑和删除分类']
  }, {
    versionNumber: 'v5.4.9',
    versionUpdateTime: '2021-08-25',
    versionUupdateContent: ['1.修复选择链接组件作品分类查询错误']
  }, {
    versionNumber: 'v5.4.8',
    versionUpdateTime: '2021-08-20',
    versionUupdateContent: ['1.工单管理添加自定义列表', '2.后台选择链接共用“选择链接组件”', '3.增加流量统计', '4.管理员添加标签管理', '5.添加素材库', '6.公告跳转链接变成新json格式', '7.工单链接变成新json格式', '8.工单管理添加自定义列表', '9.管理、点学书中资源增加直接跳转游戏、跟读、听写的选项', '10.教材管理中选择图片改为通过素材库选择', '11.中“关联资源”弹窗：视频->选择专辑组件优化', '12.作品批量设置属性新增地区选项。', '13.文件：列表根据自身文件类型显示相应的文件图标封面', '14.导航：选择链接共用工单中的“选择链接组件”', '15.作品封面：选择图片时使用素材库功能', '16.赠品选择窗-增加实物商品']
  }, {
    versionNumber: 'v5.4.7',
    versionUpdateTime: '2021-08-10',
    versionUupdateContent: ['1.工单管理添加自定义列表']
  }, {
    versionNumber: 'v5.4.6',
    versionUpdateTime: '2021-07-07',
    versionUupdateContent: ['1.优化工单对话组件', '2.修复二次点击常见问题详情不调接口']
  }, {
    versionNumber: 'v5.4.5',
    versionUpdateTime: '2021-07-02',
    versionUupdateContent: ['1.文件资源的替换按钮，改为 2 个，可以替换文件并更新文件名', '2.名师增加：显示和隐藏']
  }, {
    versionNumber: 'v5.4.4',
    versionUpdateTime: '2021-07-01',
    versionUupdateContent: ['1.修复由于轮询导致结束工单不刷新详情页面', '2.去掉3个工单轮询接口的token']
  }, {
    versionNumber: 'v5.4.3',
    versionUpdateTime: '2021-06-25',
    versionUupdateContent: ['1.资源中“是否参与分销”去掉“是否”', '2.作品列表中的每页数量，增加 100条/页', '3.所有的资源列表-优化：记录下上次选的每页数量', '4.视频替换vid后封面修改-优化', '5.增加用户个人信息的展示', '6.各资源-新增：批量保存到草稿箱', '7.版本管理-优化：优化版本管理相关相关操作', '8.总后台中增加一个图片生成功能的管理，可以管理图片生成的模板', '9.点学书关联资源增加付费免费选择']
  }, {
    versionNumber: 'v5.4.2',
    versionUpdateTime: '2021-05-24',
    versionUupdateContent: ['1.增加教材管理', '2.点学书增加内容、字词单词库的资源关联', '3.增加学习统计']
  }, {
    versionNumber: 'v5.4.1',
    versionUpdateTime: '2021-05-21',
    versionUupdateContent: ['1.作品列表：新增浏览量、销售量文字提示', '2.各资源:新增“推荐”筛选', '3.各资源商品设置中，商品折扣隐藏', '4.各资源:新增查看操作日志功能', '5.视频资源列表显示视频vid', '6.文件列表:新增批量文件下载功能', '7.音频资源:新增字幕下载', '8.新增短链接管理功能']
  }, {
    versionNumber: 'v5.4.0',
    versionUpdateTime: '2021-05-19',
    versionUupdateContent: ['1.优化工单管理根据状态搜索', '2.修复工单回复中选择链接,应用页面只显示允许跳转']
  }, {
    versionNumber: 'v5.3.9',
    versionUpdateTime: '2021-04-22',
    versionUupdateContent: ['1.优化工单回复组件', '2.常见问题新增可以添加视频', '3.修改服务工单code']
  }, {
    versionNumber: 'v5.3.8',
    versionUpdateTime: '2021-04-17',
    versionUupdateContent: ['1.工单删除不能上线的代码;']
  }, {
    versionNumber: 'v5.3.7',
    versionUpdateTime: '2021-04-8',
    versionUupdateContent: ['1.选择作品弹窗：同步作品列表的属性;']
  }, {
    versionNumber: 'v5.3.6',
    versionUpdateTime: '2021-03-25',
    versionUupdateContent: ['1.修复富文本不识别特殊符号&rsquo;']
  }, {
    versionNumber: 'v5.3.5',
    versionUpdateTime: '2021-03-24',
    versionUupdateContent: ['1.修复结束工单时出现多条结束语', '2.修复工单内容为空提示语']
  }, {
    versionNumber: 'v5.3.4',
    versionUpdateTime: '2021-03-10',
    versionUupdateContent: ['1.修复工单无法查看用户信息', '2.优化文件包下载', '3.字词将拼音信息和基本信息的保存按钮合成一个', '4.优化专辑拖动']
  }, {
    versionNumber: 'v5.3.3',
    versionUpdateTime: '2021-03-03',
    versionUupdateContent: ['1.添加用户地址', '2.优化用户基本信息', '3.数据导出添加轮询', '4.重构激活码页面']
  }, {
    versionNumber: 'v5.3.2',
    versionUpdateTime: '2021-03-03',
    versionUupdateContent: ['1.优化帮助中心样式和错误提示', '2.优化工单轮询', '3.常见问题分类添加排序']
  }, {
    versionNumber: 'v5.3.1',
    versionUpdateTime: '2021-03-03',
    versionUupdateContent: ['1.优化文件包批量下载']
  }, {
    versionNumber: 'v5.3.0',
    versionUpdateTime: '2021-02-25',
    versionUupdateContent: ['1.多媒体打点功能调整', '2.专辑内容：新增批量移除功能', '3.资源列表中：分类显示作品数量']
  }, {
    versionNumber: 'v5.2.9',
    versionUpdateTime: '2021-02-25',
    versionUupdateContent: ['1.评论表情同步、兼容直播中心评论']
  }, {
    versionNumber: 'v5.2.8',
    versionUpdateTime: '2021-02-24',
    versionUupdateContent: ['1.调整字词库相关视频预览的比例', '2.去除字词单词库前台二维码']
  }, {
    versionNumber: 'v5.2.7',
    versionUpdateTime: '2021-02-23',
    versionUupdateContent: ['1.修复字词相关视频预览关闭后视频未停止的问题']
  }, {
    versionNumber: 'v5.2.6',
    versionUpdateTime: '2021-02-23',
    versionUupdateContent: ['1.收入统计新增实物商品']
  }, {
    versionNumber: 'v5.2.5',
    versionUpdateTime: '2021-02-20',
    versionUupdateContent: ['1.单词库将词性保存和单词保存合为一个', '2.单词库修改单词时，单词改为可修改']
  }, {
    versionNumber: 'v5.2.4',
    versionUpdateTime: '2021-02-6',
    versionUupdateContent: ['1.专辑内容：添加作品编码']
  }, {
    versionNumber: 'v5.2.3',
    versionUpdateTime: '2021-02-5',
    versionUupdateContent: ['1.选择作品弹窗：添加作品编码', '2.视频vid校验']
  }, {
    versionNumber: 'v5.2.2',
    versionUpdateTime: '2021-02-5',
    versionUupdateContent: ['1.资源分类拖拽bug修复']
  }, {
    versionNumber: 'v5.2.1',
    versionUpdateTime: '2021-02-5',
    versionUupdateContent: ['1.优化字词库检索字词是否重复时loading的位置']
  }, {
    versionNumber: 'v5.2.0',
    versionUpdateTime: '2021-02-3',
    versionUupdateContent: ['1.工单的常见回复添加非空限制', '2.修复编辑常见问题适用用户非空判断', '3.优化范文库代码', '4.文件包添加批量下载', '5.优化类目管理']
  }, {
    versionNumber: 'v5.1.9',
    versionUpdateTime: '2021-02-1',
    versionUupdateContent: ['1.点学评论选区优化']
  }, {
    versionNumber: 'v5.1.8',
    versionUpdateTime: '2021-02-1',
    versionUupdateContent: ['1.优化文件排序', '2.优化收入统计资源类型']
  }, {
    versionNumber: 'v5.1.7',
    versionUpdateTime: '2021-02-1',
    versionUupdateContent: ['1.新增帮助中心', '2.优化范文库代码', '3.优化稿件管理图片放大', '4.书页列表按钮添加hover文字提示']
  }, {
    versionNumber: 'v5.1.6',
    versionUpdateTime: '2021-01-30',
    versionUupdateContent: ['1.点学书书页列表功能优化']
  }, {
    versionNumber: 'v5.1.5',
    versionUpdateTime: '2021-01-29',
    versionUupdateContent: ['1. 优化资源分类组件']
  }, {
    versionNumber: 'v5.1.4',
    versionUpdateTime: '2021-01-29',
    versionUupdateContent: ['1. 作品资源详情：更改作品编码的位置', '2. 音频详情：新建的时候允许打点']
  }, {
    versionNumber: 'v5.1.3',
    versionUpdateTime: '2021-01-27',
    versionUupdateContent: ['1. 范文专题、奖项设置缩略图', '2. 优化日志管理、订单详情弹窗']
  }, {
    versionNumber: 'v5.1.2',
    versionUpdateTime: '2021-01-26',
    versionUupdateContent: ['1. 修复增值服务图书目录转移到根目录时报错的问题', '2. 直播分享：添加直播录制']
  }, {
    versionNumber: 'v5.1.1',
    versionUpdateTime: '2021-01-26',
    versionUupdateContent: ['1. 作品资源列表页：添加作品编号搜索、修改作品编号功能。列表更新时间调整', '2. 直播列表：添加按直播时间搜索', '3. 作品资源详情：添加作品编号', '4. 直播列表：添加中奖记录功能，添加下课功能，添加直播状态', '5. 点学书详情：添加点学书详情、点学书介绍', '6. 修复增值服务图书目录转移到根目录时报错的问题', '7. 直播分享：添加直播录制']
  }]
};
exports.default = _default;