"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * 格式化文字，如果文字为空，则返回 '--'
 *
 * @param {*} text
 * @returns {*}
 */
var _default = function _default(text) {
  try {
    if (text === undefined || text === null || text instanceof Array || text instanceof Object || text.trim() === '' || text.trim() === '""') {
      return '--';
    }

    return text;
  } catch (e) {
    console.error(e.message);
    return '--';
  }
};

exports.default = _default;