var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.tableLoading,
          expression: "tableLoading"
        }
      ],
      ref: "viewBox",
      staticClass: "newQuestion"
    },
    [
      _c(
        "el-form",
        {
          ref: "questionForm",
          attrs: {
            "label-width": "80px",
            rules: _vm.rules,
            model: _vm.questionForm
          }
        },
        [
          _c("div", { staticClass: "selectType" }, [
            _c(
              "div",
              { staticClass: "selectTop" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "试题分类", prop: "categoryOpenId" } },
                  [
                    _c("el-cascader", {
                      attrs: {
                        placeholder: "请选择分类",
                        options: _vm.questionType,
                        props: _vm.classProps
                      },
                      model: {
                        value: _vm.questionForm.categoryOpenId,
                        callback: function($$v) {
                          _vm.$set(_vm.questionForm, "categoryOpenId", $$v)
                        },
                        expression: "questionForm.categoryOpenId"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-left": "15px" },
                    attrs: {
                      label: "试题模板",
                      prop: _vm.isDisabled ? "" : "examQuestionTypeCode"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          disabled: _vm.isDisabled,
                          placeholder: "请选择试题模板"
                        },
                        on: { change: _vm.questionTypeChange },
                        model: {
                          value: _vm.questionForm.examQuestionTypeCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.questionForm,
                              "examQuestionTypeCode",
                              $$v
                            )
                          },
                          expression: "questionForm.examQuestionTypeCode"
                        }
                      },
                      _vm._l(_vm.questionTypeList, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.code }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-left": "15px" },
                    attrs: {
                      label: "题型标签",
                      prop: _vm.isDisabled ? "" : "questionTypeTagCode"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          disabled: _vm.isDisabled,
                          placeholder: "请选择题型标签"
                        },
                        model: {
                          value: _vm.questionForm.questionTypeTagCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.questionForm,
                              "questionTypeTagCode",
                              $$v
                            )
                          },
                          expression: "questionForm.questionTypeTagCode"
                        }
                      },
                      _vm._l(_vm.questionTagList, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.code }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "selectTop" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "试题难度" } },
                  [
                    _c("el-rate", {
                      staticClass: "rateStar",
                      attrs: { texts: this.texts, "show-text": "" },
                      model: {
                        value: _vm.questionForm.difficultDegree,
                        callback: function($$v) {
                          _vm.$set(_vm.questionForm, "difficultDegree", $$v)
                        },
                        expression: "questionForm.difficultDegree"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "tags", attrs: { label: "试题标签" } },
                  [
                    _vm._l(_vm.dynamicTags, function(tag) {
                      return _c(
                        "el-tag",
                        {
                          key: tag,
                          staticClass: "tag",
                          attrs: { closable: "", "disable-transitions": false },
                          on: {
                            close: function($event) {
                              return _vm.handleCloseTag(tag)
                            }
                          },
                          model: {
                            value: _vm.dynamicTags,
                            callback: function($$v) {
                              _vm.dynamicTags = $$v
                            },
                            expression: "dynamicTags"
                          }
                        },
                        [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                      )
                    }),
                    _vm._v(" "),
                    _vm.inputVisible
                      ? _c("el-input", {
                          ref: "saveTagInput",
                          staticClass: "input-new-tag",
                          attrs: { size: "small" },
                          on: { blur: _vm.handleInputConfirm },
                          model: {
                            value: _vm.inputValue,
                            callback: function($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue"
                          }
                        })
                      : _c(
                          "span",
                          {
                            staticClass: "newTag",
                            on: { click: _vm.showInput }
                          },
                          [_vm._v("添加")]
                        )
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "questionInfo" }, [
            _c("p", { staticClass: "title" }, [_vm._v("题目信息")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "formBox" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "试题题干", prop: "stem" } },
                  [
                    _c("span", { staticClass: "vice" }, [
                      _vm._v("这里填写题目描述")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "formTextArea" },
                      [
                        !_vm.stemType
                          ? _c("el-input", {
                              ref: "questionFormStem",
                              staticClass: "textArea",
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 6 }
                              },
                              model: {
                                value: _vm.questionForm.stem,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionForm, "stem", $$v)
                                },
                                expression: "questionForm.stem"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.stemType
                          ? _c("div", {
                              staticClass: "beforeInput",
                              domProps: {
                                innerHTML: _vm._s(_vm.questionForm.stem)
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "textAreaRight",
                            on: {
                              click: function($event) {
                                return _vm.openEditor(
                                  -1,
                                  _vm.questionForm.stem,
                                  "stem"
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-edit-outline" })]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("up-load-img", {
                          staticClass: "upLoadFile",
                          attrs: { type: "img", path: "stemCover/" },
                          on: { input: _vm.addInput }
                        }),
                        _vm._v(" "),
                        _c("up-load-stem-audio", {
                          staticClass: "upLoadFile",
                          attrs: { type: "audio", path: "questionAudio/" },
                          on: { input: _vm.addInput1 }
                        }),
                        _vm._v(" "),
                        _c("svg-icon", {
                          staticStyle: {
                            "font-size": "24px",
                            cursor: "pointer",
                            position: "relative",
                            "z-index": "999",
                            "margin-top": "3px"
                          },
                          attrs: { "icon-class": "videocam" },
                          on: { click: _vm.selectStemVideo }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "addText" }, [
                          _vm._v(
                            "\n            添加图片、音频、视频\n          "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showPreview
                      ? _c("el-image-viewer", {
                          attrs: {
                            "z-index": 2300,
                            "on-close": _vm.closeViewer,
                            "url-list": [_vm.getImgUrl(_vm.previewImg)]
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionForm.images !== ""
                      ? _c("div", { staticClass: "upLoadBox" }, [
                          _c(
                            "div",
                            { staticClass: "info" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "image" }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "rescourceTitle" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.questionForm.imagesName) +
                                    "\n            "
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "operation" }, [
                            _c("i", {
                              staticClass: "el-icon-search common-icon",
                              staticStyle: { "margin-right": "10px" },
                              on: { click: _vm.seeBigImg }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-delete common-icon",
                              on: { click: _vm.delStemImg }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionForm.audio && _vm.questionForm.audio !== ""
                      ? _c("div", { staticClass: "upLoadBox" }, [
                          _c(
                            "div",
                            { staticClass: "info" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "audio" }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "rescourceTitle" }, [
                                _vm._v(_vm._s(_vm.stemAudioName))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "operation" }, [
                            _c("i", {
                              staticClass: "el-icon-video-play common-icon",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function($event) {
                                  _vm.audioPreview = true
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-delete common-icon",
                              on: { click: _vm.delStemAudio }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionForm.video !== ""
                      ? _c("div", { staticClass: "upLoadBox" }, [
                          _c(
                            "div",
                            { staticClass: "info" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "videocam" }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "rescourceTitle" }, [
                                _vm._v(_vm._s(_vm.stemVideoName))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "operation" }, [
                            _c("i", {
                              staticClass: "el-icon-video-play common-icon",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function($event) {
                                  return _vm.seeStemVideo(
                                    _vm.questionForm.video
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-delete common-icon",
                              on: {
                                click: function($event) {
                                  return _vm.delVideo("stemVideo")
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.questionName === "主观题"
                  ? _c("el-form-item", { attrs: { label: "参考答案" } }, [
                      _c("span", { staticClass: "vice" }, [
                        _vm._v("用户根据参考答案自主打分")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "formTextArea" },
                        [
                          !_vm.subjectiveType
                            ? _c("el-input", {
                                staticClass: "textArea",
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 3, maxRows: 6 }
                                },
                                model: {
                                  value: _vm.questionForm.rightAnswerContent,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.questionForm,
                                      "rightAnswerContent",
                                      $$v
                                    )
                                  },
                                  expression: "questionForm.rightAnswerContent"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.subjectiveType
                            ? _c("div", {
                                staticClass: "beforeInput",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.questionForm.rightAnswerContent
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "textAreaRight",
                              on: {
                                click: function($event) {
                                  return _vm.openEditor(
                                    -1,
                                    _vm.questionForm.rightAnswerContent,
                                    "subjective"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-edit-outline" })]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.questionName === "单选题" || _vm.questionName === "多选题"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#F56C6C",
                                  "margin-right": "2px"
                                }
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("答案选项")
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "vice" }, [
                          _vm._v("单/多选题的选项范围从 2 到 20 ")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("span", [_vm._v("答案乱序")]),
                            _vm._v(" "),
                            _c("el-switch", {
                              attrs: { "active-color": "#13ce66" },
                              model: {
                                value: _vm.questionForm.random,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionForm, "random", $$v)
                                },
                                expression: "questionForm.random"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.optionList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "optionItem" },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "block",
                                    width: "20px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "(" + _vm._s(_vm.letterList[index]) + ")"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !item.optionType
                                ? _c(
                                    "el-input",
                                    {
                                      ref: "danxuan",
                                      refInFor: true,
                                      staticClass:
                                        "input-with-select inputLength",
                                      attrs: { placeholder: "请输入内容" },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-edit-outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditor(
                                              index,
                                              item.value,
                                              "commonValue"
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  item.optionType
                                    ? _c("div", {
                                        staticClass: "optionInput",
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.optionType
                                    ? _c("el-button", {
                                        staticClass: "appendBtn",
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-edit-outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditor(
                                              index,
                                              item.value,
                                              "commonValue"
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "inputRightIcon" },
                                [
                                  _c(
                                    "el-dropdown",
                                    [
                                      _c("svg-icon", {
                                        staticClass: "moreIcon",
                                        attrs: { "icon-class": "moreOpertion" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          index !== 0
                                            ? _c("el-dropdown-item", {
                                                attrs: {
                                                  icon: "el-icon-upload2"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.upItem(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index >= 0 && index < 19
                                            ? _c("el-dropdown-item", {
                                                attrs: {
                                                  icon: "el-icon-download"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.downItem(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteItem(item, index)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "addNewOption",
                            attrs: { icon: "el-icon-plus" },
                            on: { click: _vm.pushOption }
                          },
                          [_vm._v("\n          添加新选项\n        ")]
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.questionName === "判断题"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#F56C6C",
                                  "margin-right": "2px"
                                }
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("选项文字")
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm._l(_vm.optionList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "optionItem" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _vm._v(" "),
                              !item.optionType
                                ? _c(
                                    "el-input",
                                    {
                                      staticClass:
                                        "input-with-select inputLengthTrueFalse",
                                      attrs: { placeholder: "请输入内容" },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-edit-outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditor(
                                              index,
                                              item.value,
                                              "trueFalse"
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  item.optionType
                                    ? _c("div", {
                                        staticClass: "optionInput1",
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.optionType
                                    ? _c("el-button", {
                                        staticClass: "appendBtn",
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-edit-outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditor(
                                              index,
                                              item.value,
                                              "trueFalse"
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.questionName !== ""
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _vm.questionName !== "判断题" &&
                        _vm.questionName !== "单选题" &&
                        _vm.questionName !== "主观题" &&
                        _vm.questionName !== "多选题"
                          ? _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#F56C6C",
                                      "margin-right": "2px"
                                    }
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v(_vm._s(_vm.correctTitle))
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName !== "组合题"
                          ? _c("span", { staticClass: "vice" }, [
                              _vm._v(_vm._s(_vm.viceTitle))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "组合题"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "addSonQuestion",
                                attrs: { icon: "el-icon-plus" },
                                on: {
                                  click: function($event) {
                                    return _vm.addSonQuestion(_vm.questionForm)
                                  }
                                }
                              },
                              [_vm._v("\n          添加子试题\n        ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "单选题"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.questionForm.rightAnswerContent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.questionForm,
                                          "rightAnswerContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "questionForm.rightAnswerContent"
                                    }
                                  },
                                  _vm._l(_vm.optionList, function(item, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        staticClass: "choose-radio",
                                        attrs: {
                                          label: _vm.letterList[index],
                                          border: ""
                                        },
                                        model: {
                                          value:
                                            _vm.questionForm.rightAnswerContent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.questionForm,
                                              "rightAnswerContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "questionForm.rightAnswerContent"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.letterList[index]) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "多选题"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value:
                                        _vm.questionForm.rightAnswerContent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.questionForm,
                                          "rightAnswerContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "questionForm.rightAnswerContent"
                                    }
                                  },
                                  _vm._l(_vm.optionList, function(item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        staticClass: "choose-radio",
                                        attrs: {
                                          label: _vm.letterList[index],
                                          border: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.letterList[index]) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "判断题"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { display: "flex" },
                                    model: {
                                      value:
                                        _vm.questionForm.rightAnswerContent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.questionForm,
                                          "rightAnswerContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "questionForm.rightAnswerContent"
                                    }
                                  },
                                  _vm._l(_vm.optionList, function(item, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: {
                                          label: _vm.letterList[index],
                                          border: ""
                                        },
                                        model: {
                                          value:
                                            _vm.questionForm.rightAnswerContent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.questionForm,
                                              "rightAnswerContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "questionForm.rightAnswerContent"
                                        }
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(item.value)
                                          }
                                        })
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "填空题"
                          ? _c(
                              "div",
                              [
                                _vm._l(_vm.optionList, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "optionItem" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "block",
                                            width: "20px"
                                          }
                                        },
                                        [_vm._v(_vm._s(index + 1) + ".")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        ref: "tiankongti",
                                        refInFor: true,
                                        staticClass:
                                          "input-with-select inputLength",
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "inputRightIcon" },
                                        [
                                          _c(
                                            "el-dropdown",
                                            [
                                              _c("svg-icon", {
                                                staticClass: "moreIcon",
                                                attrs: {
                                                  "icon-class": "moreOpertion"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown"
                                                },
                                                [
                                                  index !== 0
                                                    ? _c("el-dropdown-item", {
                                                        attrs: {
                                                          icon:
                                                            "el-icon-upload2"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.upItem(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  index >= 0 && index < 19
                                                    ? _c("el-dropdown-item", {
                                                        attrs: {
                                                          icon:
                                                            "el-icon-download"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.downItem(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteItem(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "addNewOption",
                                    attrs: { icon: "el-icon-plus" },
                                    on: { click: _vm.pushOption }
                                  },
                                  [
                                    _vm._v(
                                      "\n            添加新选项\n          "
                                    )
                                  ]
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "排序题"
                          ? _c(
                              "div",
                              [
                                _vm._l(_vm.optionList, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "optionItem" },
                                    [
                                      !item.optionType
                                        ? _c(
                                            "el-input",
                                            {
                                              ref: "paixuti",
                                              refInFor: true,
                                              staticClass:
                                                "input-with-select inputLengthTrueFalse",
                                              attrs: {
                                                placeholder: "请输入内容"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.getSortLabel(
                                                    index,
                                                    item.value
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                {
                                                  staticClass: "prependText",
                                                  slot: "prepend"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.questionForm
                                                          .sortType === "2"
                                                          ? _vm.getLetterByNumber(
                                                              index + 1
                                                            )
                                                          : index + 1
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("el-button", {
                                                attrs: {
                                                  slot: "append",
                                                  icon: "el-icon-edit-outline"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openEditor(
                                                      index,
                                                      item.value,
                                                      "sort"
                                                    )
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          item.optionType
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass: "appendBtnIndex",
                                                  attrs: { slot: "append" },
                                                  slot: "append"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.questionForm
                                                          .sortType === "2"
                                                          ? _vm.getLetterByNumber(
                                                              index + 1
                                                            )
                                                          : index + 1
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.optionType
                                            ? _c("div", {
                                                staticClass: "optionInput2",
                                                domProps: {
                                                  innerHTML: _vm._s(item.value)
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.optionType
                                            ? _c("el-button", {
                                                staticClass: "appendBtn",
                                                attrs: {
                                                  slot: "append",
                                                  icon: "el-icon-edit-outline"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openEditor(
                                                      index,
                                                      item.value,
                                                      "sort"
                                                    )
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "inputRightIcon" },
                                        [
                                          _c(
                                            "el-dropdown",
                                            [
                                              _c("svg-icon", {
                                                staticClass: "moreIcon",
                                                attrs: {
                                                  "icon-class": "moreOpertion"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown"
                                                },
                                                [
                                                  index !== 0
                                                    ? _c("el-dropdown-item", {
                                                        attrs: {
                                                          icon:
                                                            "el-icon-upload2"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.upItem(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  index >= 0 && index < 19
                                                    ? _c("el-dropdown-item", {
                                                        attrs: {
                                                          icon:
                                                            "el-icon-download"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.downItem(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteItem(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "addNewOption1",
                                    attrs: { icon: "el-icon-plus" },
                                    on: { click: _vm.pushOption }
                                  },
                                  [
                                    _vm._v(
                                      "\n            添加新选项\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "vice" }, [
                                  _vm._v(
                                    "\n            答案设置: 请把下列的语序拖动至正确的排序\n          "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      list: _vm.sortArr,
                                      animation: "300"
                                    }
                                  },
                                  _vm._l(_vm.sortArr, function(item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "dragBox" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dragIcon" },
                                          [
                                            _c("svg-icon", {
                                              attrs: { "icon-class": "drag" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "sortInput",
                                          domProps: {
                                            innerHTML: _vm._s(item.value)
                                          }
                                        })
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "连线题"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-switch", {
                                      attrs: { "active-color": "#13ce66" },
                                      model: {
                                        value: _vm.questionForm.random,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.questionForm,
                                            "random",
                                            $$v
                                          )
                                        },
                                        expression: "questionForm.random"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "vice" }, [
                                      _vm._v("答案乱序")
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "connection" }, [
                                  _c(
                                    "div",
                                    { staticClass: "connectionLeftOption" },
                                    _vm._l(_vm.optionList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "optionItem"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "block",
                                                width: "20px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.letterList[index]
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !item.optionType
                                            ? _c(
                                                "el-input",
                                                {
                                                  ref: "lianxianti",
                                                  refInFor: true,
                                                  staticClass:
                                                    "input-with-select inputConLength",
                                                  attrs: {
                                                    placeholder: "请输入内容"
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.getInputValue(
                                                        index,
                                                        item.value
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: item.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.value"
                                                  }
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      icon:
                                                        "el-icon-edit-outline"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEditor(
                                                          index,
                                                          item.value,
                                                          "conValue"
                                                        )
                                                      }
                                                    },
                                                    slot: "append"
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.optionType
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex"
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "optionInput3",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      )
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-button", {
                                                    staticClass: "appendBtnCon",
                                                    attrs: {
                                                      slot: "append",
                                                      icon:
                                                        "el-icon-edit-outline"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEditor(
                                                          index,
                                                          item.value,
                                                          "conValue"
                                                        )
                                                      }
                                                    },
                                                    slot: "append"
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "inputRightIcon" },
                                            [
                                              _c(
                                                "el-dropdown",
                                                [
                                                  _c("svg-icon", {
                                                    staticClass: "moreIcon",
                                                    attrs: {
                                                      "icon-class":
                                                        "moreOpertion"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-dropdown-menu",
                                                    {
                                                      attrs: {
                                                        slot: "dropdown"
                                                      },
                                                      slot: "dropdown"
                                                    },
                                                    [
                                                      index !== 0
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              attrs: {
                                                                icon:
                                                                  "el-icon-upload2"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.upItem(
                                                                    item,
                                                                    index,
                                                                    "conValue"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      index >= 0 && index < 19
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              attrs: {
                                                                icon:
                                                                  "el-icon-download"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.downItem(
                                                                    item,
                                                                    index,
                                                                    "conValue"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteItem(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "connectionRightOption" },
                                    _vm._l(_vm.optionList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "optionItem"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "block",
                                                width: "20px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "(" + _vm._s(index + 1) + ")"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !item.optionValueType
                                            ? _c(
                                                "el-input",
                                                {
                                                  staticClass:
                                                    "input-with-select inputConLength",
                                                  attrs: {
                                                    placeholder: "请输入内容"
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.getInputLabel(
                                                        index,
                                                        item.label
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: item.label,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "label",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.label"
                                                  }
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      icon:
                                                        "el-icon-edit-outline"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEditor(
                                                          index,
                                                          item.label,
                                                          "conLabel"
                                                        )
                                                      }
                                                    },
                                                    slot: "append"
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" }
                                            },
                                            [
                                              item.optionValueType
                                                ? _c("div", {
                                                    staticClass: "optionInput3",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.label
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.optionValueType
                                                ? _c("el-button", {
                                                    staticClass: "appendBtnCon",
                                                    attrs: {
                                                      slot: "append",
                                                      icon:
                                                        "el-icon-edit-outline"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEditor(
                                                          index,
                                                          item.label,
                                                          "conLabel"
                                                        )
                                                      }
                                                    },
                                                    slot: "append"
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "inputRightIcon" },
                                            [
                                              _c(
                                                "el-dropdown",
                                                [
                                                  _c("svg-icon", {
                                                    staticClass: "moreIcon",
                                                    attrs: {
                                                      "icon-class":
                                                        "moreOpertion"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-dropdown-menu",
                                                    {
                                                      attrs: {
                                                        slot: "dropdown"
                                                      },
                                                      slot: "dropdown"
                                                    },
                                                    [
                                                      index !== 0
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              attrs: {
                                                                icon:
                                                                  "el-icon-upload2"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.upItem(
                                                                    item,
                                                                    index,
                                                                    "conLabel"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      index >= 0 && index < 19
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              attrs: {
                                                                icon:
                                                                  "el-icon-download"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.downItem(
                                                                    item,
                                                                    index,
                                                                    "conLabel"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteItem(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "addNewOption1",
                                    attrs: { icon: "el-icon-plus" },
                                    on: { click: _vm.pushOption }
                                  },
                                  [
                                    _vm._v(
                                      "\n            添加新选项\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "跟读题"
                          ? _c(
                              "div",
                              { staticClass: "gdtTable" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    key: _vm.itemkey,
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.optionList, border: "" }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "content",
                                        label: "题号",
                                        "min-width": "10"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.$index + 1)
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4178052834
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "content",
                                        label: "内容",
                                        "min-width": "40"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入跟读内容"
                                                  },
                                                  model: {
                                                    value: scope.row.content,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.content"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3541634288
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "translation",
                                        label: "内容翻译",
                                        "min-width": "40"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入跟读内容翻译"
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.translation,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "translation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.translation"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4223522040
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      staticStyle: { display: "inline-block" },
                                      attrs: {
                                        prop: "audio",
                                        label: "音频文件",
                                        width: "220"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.saveScope(
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("single-file-upload", {
                                                      attrs: {
                                                        "components-type":
                                                          "question",
                                                        upLoadTxt: "音频上传"
                                                      },
                                                      on: {
                                                        fileUploading:
                                                          _vm.fileUploading,
                                                        getResult:
                                                          _vm.getUploader,
                                                        delFile: function(
                                                          $event
                                                        ) {
                                                          return _vm.delFile(
                                                            scope.$index
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.fileList[
                                                            scope.$index
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.fileList,
                                                            scope.$index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "fileList[scope.$index]"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1090932471
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { "min-width": "10" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "inputRightIcon"
                                                  },
                                                  [
                                                    _c(
                                                      "el-dropdown",
                                                      [
                                                        _c("svg-icon", {
                                                          staticClass:
                                                            "moreIcon",
                                                          attrs: {
                                                            "icon-class":
                                                              "moreOpertion"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dropdown-menu",
                                                          {
                                                            attrs: {
                                                              slot: "dropdown"
                                                            },
                                                            slot: "dropdown"
                                                          },
                                                          [
                                                            scope.$index !== 0
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "el-icon-upload2"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.upItem(
                                                                          scope.row,
                                                                          scope.$index,
                                                                          "readWith"
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            scope.$index >= 0 &&
                                                            scope.$index < 19
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "el-icon-download"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downItem(
                                                                          scope.row,
                                                                          scope.$index,
                                                                          "readWith"
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteItem(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4176183659
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "addNewOption2",
                                    attrs: { icon: "el-icon-plus" },
                                    on: { click: _vm.pushOption }
                                  },
                                  [_vm._v("\n            新增跟读\n          ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "完形填空"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    key: _vm.itemkey,
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.optionList, border: "" }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "content",
                                        label: "题号",
                                        "min-width": "10"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.$index + 1)
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4178052834
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "content",
                                        label: "选项",
                                        "min-width": "40"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _vm._l(
                                                  _vm.optionList[scope.$index]
                                                    .optionChild,
                                                  function(item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "optionItem"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              display: "block",
                                                              width: "20px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.letterList[
                                                                  index
                                                                ]
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("el-input", {
                                                          ref:
                                                            "wanxingtiankongleft",
                                                          refInFor: true,
                                                          staticClass:
                                                            "input-with-select inputLength",
                                                          attrs: {
                                                            wanxingtiankongleft:
                                                              "",
                                                            placeholder:
                                                              "请输入内容"
                                                          },
                                                          model: {
                                                            value: item.value,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.value"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "inputRightIcon"
                                                          },
                                                          [
                                                            _c(
                                                              "el-dropdown",
                                                              [
                                                                _c("svg-icon", {
                                                                  staticClass:
                                                                    "moreIcon",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "moreOpertion"
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-dropdown-menu",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "dropdown"
                                                                    },
                                                                    slot:
                                                                      "dropdown"
                                                                  },
                                                                  [
                                                                    index !== 0
                                                                      ? _c(
                                                                          "el-dropdown-item",
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "el-icon-upload2"
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.upItem(
                                                                                  item,
                                                                                  index,
                                                                                  "optionItem",
                                                                                  scope.$index
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    index >=
                                                                      0 &&
                                                                    index < 19
                                                                      ? _c(
                                                                          "el-dropdown-item",
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "el-icon-download"
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.downItem(
                                                                                  item,
                                                                                  index,
                                                                                  "optionItem",
                                                                                  scope.$index
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteItem(
                                                                    item,
                                                                    index,
                                                                    "optionItem",
                                                                    scope.$index
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "addNewOption1",
                                                    attrs: {
                                                      icon: "el-icon-plus"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.pushOption(
                                                          "optionItem",
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  添加新选项\n                "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        989983636
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "translation",
                                        label: "正确答案",
                                        width: "90"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticClass: "elRadioCon",
                                                    model: {
                                                      value:
                                                        _vm.optionList[
                                                          scope.$index
                                                        ].rightAnswerContent,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.optionList[
                                                            scope.$index
                                                          ],
                                                          "rightAnswerContent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "optionList[scope.$index].rightAnswerContent"
                                                    }
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.optionList[
                                                        scope.$index
                                                      ].optionChild,
                                                      function(item, index) {
                                                        return _c(
                                                          "el-radio",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              label: _vm.letterList[
                                                                index
                                                              ].toLowerCase()
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm
                                                                    .letterList[
                                                                    index
                                                                  ]
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br")
                                                  ],
                                                  2
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2706164909
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "inputRightIcon"
                                                  },
                                                  [
                                                    _c(
                                                      "el-dropdown",
                                                      [
                                                        _c("svg-icon", {
                                                          staticClass:
                                                            "moreIcon",
                                                          attrs: {
                                                            "icon-class":
                                                              "moreOpertion"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dropdown-menu",
                                                          {
                                                            attrs: {
                                                              slot: "dropdown"
                                                            },
                                                            slot: "dropdown"
                                                          },
                                                          [
                                                            scope.$index !== 0
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "el-icon-upload2"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.upItem(
                                                                          scope.row,
                                                                          scope.$index,
                                                                          "readWith"
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            scope.$index >= 0 &&
                                                            scope.$index < 19
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "el-icon-download"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downItem(
                                                                          scope.row,
                                                                          scope.$index,
                                                                          "readWith"
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteItem(
                                                            scope.row,
                                                            scope.$index,
                                                            "optionItem1",
                                                            scope.$index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3897257062
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "addNewOption2",
                                    attrs: { icon: "el-icon-plus" },
                                    on: { click: _vm.pushOption }
                                  },
                                  [_vm._v("\n            新增填空\n          ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questionName === "组合题"
                          ? _c("div", [
                              _vm.questionForm.subQuestionList &&
                              _vm.questionForm.subQuestionList.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "combination" },
                                    [
                                      _c(
                                        "el-tabs",
                                        {
                                          model: {
                                            value: _vm.activeName,
                                            callback: function($$v) {
                                              _vm.activeName = $$v
                                            },
                                            expression: "activeName"
                                          }
                                        },
                                        _vm._l(
                                          _vm.questionForm.subQuestionList,
                                          function(item, index) {
                                            return _c(
                                              "el-tab-pane",
                                              {
                                                key: index,
                                                attrs: {
                                                  label: "试题" + (index + 1),
                                                  name: "试题" + (index + 1)
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "justify-content":
                                                        "space-between"
                                                    }
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "title"
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass: "stem",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.stem
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      item.examQuestionTypeCode ===
                                                        1001 ||
                                                      item.examQuestionTypeCode ===
                                                        1002
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _vm._l(
                                                                item
                                                                  .optionText[0],
                                                                function(
                                                                  question,
                                                                  questionIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: questionIndex,
                                                                      staticClass:
                                                                        "item"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            questionIndex.toUpperCase()
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps: {
                                                                            innerHTML: _vm._s(
                                                                              question
                                                                            )
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "answer"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        正确答案: " +
                                                                      _vm._s(
                                                                        item.rightAnswerContent
                                                                          ? item.rightAnswerContent.toUpperCase()
                                                                          : undefined
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.examQuestionTypeCode ===
                                                      1003
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _vm._l(
                                                                item
                                                                  .optionText[0],
                                                                function(
                                                                  question,
                                                                  questionIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: questionIndex,
                                                                      staticClass:
                                                                        "item connectionOptions"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          domProps: {
                                                                            innerHTML: _vm._s(
                                                                              question
                                                                            )
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "answer judge-flex"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "judge-title"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "正确答案:"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "judge-answercontent",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        item.rightAnswerContent ===
                                                                          "no"
                                                                          ? item
                                                                              .optionText[0]
                                                                              .b
                                                                          : item
                                                                              .optionText[0]
                                                                              .a
                                                                      )
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.examQuestionTypeCode ===
                                                      1005
                                                        ? _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "answer write"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        正确答案:\n                        "
                                                                ),
                                                                _vm._l(
                                                                  item.rightAnswerContent,
                                                                  function(
                                                                    question,
                                                                    questionIndex
                                                                  ) {
                                                                    return _c(
                                                                      "span",
                                                                      {
                                                                        key: questionIndex
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            question[0]
                                                                          ) +
                                                                            ","
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.examQuestionTypeCode ===
                                                      1004
                                                        ? _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  _vm._l(
                                                                    item
                                                                      .optionText[0],
                                                                    function(
                                                                      question,
                                                                      questionIndex
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: questionIndex,
                                                                          staticClass:
                                                                            "item connectionOptions"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "optionIndex"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      questionIndex
                                                                                    ) +
                                                                                      ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  domProps: {
                                                                                    innerHTML: _vm._s(
                                                                                      question
                                                                                    )
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle: {
                                                                      display:
                                                                        "flex",
                                                                      "flex-direction":
                                                                        "column"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    item.optionList,
                                                                    function(
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "option"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle: {
                                                                                display:
                                                                                  "flex",
                                                                                margin:
                                                                                  "5px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      index +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                "."
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  domProps: {
                                                                                    innerHTML: _vm._s(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "answer"
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "正确答案: "
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  item.rightAnswerContent,
                                                                  function(
                                                                    answer,
                                                                    answerIndex
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: answerIndex
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle: {
                                                                              margin:
                                                                                "5px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            " +
                                                                                _vm._s(
                                                                                  answerIndex
                                                                                ) +
                                                                                " -- " +
                                                                                _vm._s(
                                                                                  answer
                                                                                ) +
                                                                                "\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.examQuestionTypeCode ===
                                                      1006
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _vm._l(
                                                                item
                                                                  .optionText[0],
                                                                function(
                                                                  option,
                                                                  optionIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: optionIndex,
                                                                      staticClass:
                                                                        "item"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              optionIndex
                                                                            ) +
                                                                              ": "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps: {
                                                                            innerHTML: _vm._s(
                                                                              option
                                                                            )
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "answer"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        正确答案: " +
                                                                      _vm._s(
                                                                        item.rightAnswerContent
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.examQuestionTypeCode ===
                                                      1008
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "answer"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      正确答案:"
                                                              ),
                                                              _c("div", {
                                                                staticClass:
                                                                  "rightAnswer",
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    item.rightAnswerContent
                                                                  )
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          flex: "none"
                                                        }
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("排序：")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "80px"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.subQuestionSort(
                                                                item
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              item.sequence,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "sequence",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.sequence"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      "border-top":
                                                        "1px solid #DCDFE6",
                                                      "padding-top": "10px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          plain: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.editSonQuestion(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("编辑")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          plain: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.tihuan(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("替换")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                          plain: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeItem(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    移除\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.questionName === "连线题"
                  ? _c("el-form-item", { attrs: { label: "正确答案" } }, [
                      _c("span", { staticClass: "vice" }, [
                        _vm._v(
                          "点击选项对应的黄块与蓝块完成配对，点击中间链接标志可断开按钮可重新配对"
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "connectionCon" }, [
                        _c(
                          "div",
                          { staticClass: "connectionItem" },
                          _vm._l(_vm.connectionArr, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "connectionBox" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "5px" } },
                                  [
                                    _vm._v(
                                      "(" + _vm._s(_vm.letterList[index]) + ")"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "marginInput",
                                  class:
                                    _vm.leftBallIndex === index
                                      ? "ballBorder"
                                      : "",
                                  domProps: { innerHTML: _vm._s(item.value) }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  class: {
                                    ballLight: true,
                                    ballConLight: item.isCon
                                  }
                                }),
                                _vm._v(" "),
                                !item.isCon
                                  ? _c("div", {
                                      staticClass: "leftBall",
                                      on: {
                                        click: function($event) {
                                          return _vm.leftBallClick(index, item)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticStyle: { width: "5px" } }),
                                _vm._v(" "),
                                item.isCon
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "connectionIcon",
                                        on: {
                                          click: function($event) {
                                            return _vm.breakLink(index, item)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-connection"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !item.isCon
                                  ? _c("div", {
                                      staticClass: "rightBall",
                                      on: {
                                        click: function($event) {
                                          return _vm.rightBallClick(index, item)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", {
                                  class: {
                                    ballLight: true,
                                    ballConLight: item.isCon
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "marginInput dragInput",
                                  class:
                                    _vm.rightBallIndex === index
                                      ? "ballBorder"
                                      : "",
                                  domProps: { innerHTML: _vm._s(item.label) }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.questionName === "排序题"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "排序方式" } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.questionForm.sortType,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "sortType", $$v)
                              },
                              expression: "questionForm.sortType"
                            }
                          },
                          [_vm._v("数字")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value: _vm.questionForm.sortType,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "sortType", $$v)
                              },
                              expression: "questionForm.sortType"
                            }
                          },
                          [_vm._v("英文字母")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "top-start" }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v("用户排序区按钮内呈现方式"),
                                _c("br"),
                                _vm._v("数字 ：阿拉伯数字1~9"),
                                _c("br"),
                                _vm._v("英文字母：大写字母A~Z")
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-warning",
                              staticStyle: { color: "#409EFF" }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "题目解析" } },
                  [
                    _c("span", { staticClass: "vice" }, [
                      _vm._v("这里填写该问题对应的答案解释")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "formTextArea" },
                      [
                        !_vm.analysisType
                          ? _c("el-input", {
                              staticClass: "textArea",
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 6 }
                              },
                              model: {
                                value:
                                  _vm.questionForm.questionAnalysis.analysis,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.questionForm.questionAnalysis,
                                    "analysis",
                                    $$v
                                  )
                                },
                                expression:
                                  "questionForm.questionAnalysis.analysis"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.analysisType
                          ? _c("div", {
                              staticClass: "beforeInput",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.questionForm.questionAnalysis.analysis
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "textAreaRight",
                            on: {
                              click: function($event) {
                                return _vm.openEditor(
                                  -2,
                                  _vm.questionForm.questionAnalysis.analysis,
                                  "analysis"
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-edit-outline" })]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("svg-icon", {
                      staticStyle: {
                        "font-size": "24px",
                        cursor: "pointer",
                        position: "relative",
                        "z-index": "999",
                        "margin-top": "3px"
                      },
                      attrs: { "icon-class": "videocam" },
                      on: { click: _vm.selectanalysisVideo }
                    }),
                    _vm._v(" "),
                    _vm.questionForm.questionAnalysis.analysisVideo !== ""
                      ? _c("div", { staticClass: "upLoadBox" }, [
                          _c(
                            "div",
                            { staticClass: "info" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "videocam" }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "rescourceTitle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.questionForm.questionAnalysis
                                      .analysisVideoName
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "operation" }, [
                            _c("i", {
                              staticClass: "el-icon-search",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.seeStemVideo(
                                    _vm.questionForm.questionAnalysis
                                      .analysisVideo
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-delete",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.delVideo("analysisVideo")
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "border" }),
                _vm._v(" "),
                _c("p", { staticClass: "other" }, [
                  _vm._v("\n        其他\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "others" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "学科", prop: "subjectCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "请选择学科" },
                            on: { change: _vm.getKnowledge },
                            model: {
                              value: _vm.questionForm.subjectCode,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "subjectCode", $$v)
                              },
                              expression: "questionForm.subjectCode"
                            }
                          },
                          _vm._l(_vm.subjectList, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "版本" } },
                      [
                        _c("select-param-sync-info", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "query-option": "FindBBList",
                            placeholder: "版本"
                          },
                          model: {
                            value: _vm.questionForm.editionCode,
                            callback: function($$v) {
                              _vm.$set(_vm.questionForm, "editionCode", $$v)
                            },
                            expression: "questionForm.editionCode"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "知识点" } },
                      [
                        _c("el-cascader", {
                          key: _vm.knowledgeCascader,
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择知识点",
                            options: _vm.knowledgeList,
                            props: _vm.knowledgeProps,
                            clearable: ""
                          },
                          on: { "visible-change": _vm.checkSubject },
                          model: {
                            value: _vm.questionForm.knowledgeList,
                            callback: function($$v) {
                              _vm.$set(_vm.questionForm, "knowledgeList", $$v)
                            },
                            expression: "questionForm.knowledgeList"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "年级" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              multiple: "",
                              placeholder: "请选择年级"
                            },
                            model: {
                              value: _vm.questionForm.gradeCode,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "gradeCode", $$v)
                              },
                              expression: "questionForm.gradeCode"
                            }
                          },
                          _vm._l(_vm.gradeList, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "阶段" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              multiple: "",
                              placeholder: "请选择阶段"
                            },
                            model: {
                              value: _vm.questionForm.volumeCode,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "volumeCode", $$v)
                              },
                              expression: "questionForm.volumeCode"
                            }
                          },
                          _vm._l(_vm.volumeList, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "地区" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "请选择地区" },
                            model: {
                              value: _vm.questionForm.bookAreaId,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "bookAreaId", $$v)
                              },
                              expression: "questionForm.bookAreaId"
                            }
                          },
                          _vm._l(_vm.areaList, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "版次" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "请选择版次" },
                            model: {
                              value: _vm.questionForm.periodCode,
                              callback: function($$v) {
                                _vm.$set(_vm.questionForm, "periodCode", $$v)
                              },
                              expression: "questionForm.periodCode"
                            }
                          },
                          _vm._l(_vm.periodList, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogFooter" },
        [
          _c("el-button", { on: { click: _vm.cancelDialog } }, [
            _vm._v("取 消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit("questionForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "900px",
            title: "编辑文本",
            visible: _vm.innerVisible,
            "append-to-body": "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.innerVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _c("editor", {
            ref: "ueditor",
            attrs: { config: _vm.ueditorConfig },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-box" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.commit } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.videoPreview,
            "before-close": _vm.videoPreviewClose,
            title: "视频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoPreview = $event
            }
          }
        },
        [
          _c("div", {
            staticClass: "video-start",
            attrs: { id: "videoPreview" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.audioPreview,
            "before-close": _vm.audioPreviewClose,
            title: "音频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.audioPreview = $event
            }
          }
        },
        [
          _vm.questionForm.audio.audio
            ? _c("audio", {
                ref: "audio",
                staticClass: "audio-play",
                attrs: {
                  id: "audio",
                  src: _vm.getAudioUrl(_vm.questionForm.audio.audio),
                  controls: "controls"
                }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              "expect-type": _vm.expectType,
              visible: _vm.outerVisible,
              "exclude-data": _vm.videoList,
              "show-merchant": true
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.onResourceSelected
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSelectDialog
        ? _c("select-son-question", {
            attrs: {
              "if-son-compose": _vm.ifSonCompose,
              operationCode: _vm.operationCode,
              type: _vm.selectType,
              merchantOpenId: _vm.merchantOpenId,
              "select-dialog": _vm.showSelectDialog,
              "paper-info": _vm.combinationQuestion,
              "disable-open-id": _vm.disableOpenId
            },
            on: {
              "update:selectDialog": function($event) {
                _vm.showSelectDialog = $event
              },
              "update:select-dialog": function($event) {
                _vm.showSelectDialog = $event
              },
              dialogChange: _vm.dialogChange,
              addSuccess: _vm.addSuccess
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "history-details-dialog",
          attrs: {
            title: "编辑试题",
            visible: _vm.openSonDetail,
            "append-to-body": "",
            "before-close": _vm.cancelSonDetailsDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "1035px"
          },
          on: {
            "update:visible": function($event) {
              _vm.openSonDetail = $event
            }
          }
        },
        [
          _vm.openSonDetail
            ? _c("question-details", {
                attrs: {
                  "edit-open-id": _vm.editSonOpenId,
                  merchantOpenId: _vm.merchantOpenId,
                  "colse-level": "two",
                  source: "updateSonQuestion",
                  type: "edit"
                },
                on: {
                  updateSonQuestion: _vm.addSuccess,
                  directoryDialogChange: _vm.cancelSonDetailsDialog
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }