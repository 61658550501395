var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-upload-bg" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            "http-request": _vm.fileChange,
            "show-file-list": false,
            drag: "",
            action: "",
            multiple: ""
          }
        },
        [
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("选择或批量拖拽" + _vm._s(_vm.getResourceName))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }