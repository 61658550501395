var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tag",
        {
          class: _vm.className,
          attrs: {
            tagId: _vm.tagId,
            type: _vm.tagType,
            size: "mini",
            closable: _vm.closable
          },
          on: {
            close: function($event) {
              return _vm.handleClose(_vm.tagId)
            }
          }
        },
        [_vm._v(_vm._s(_vm.tagName))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }