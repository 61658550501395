"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  payTypeData: [{
    id: '1',
    title: '支付宝 App 支付',
    ping_plus_plus_channel: 'alipay',
    brokerage: '0.008',
    created_at: '2019-08-06 20:04:05',
    updated_at: '2019-08-09 09:27:45',
    deleted_at: null
  }, {
    id: '2',
    title: '支付宝手机网站支付',
    ping_plus_plus_channel: 'alipay_wap',
    brokerage: '0.008',
    created_at: '2019-08-06 20:04:23',
    updated_at: '2019-08-09 09:27:18',
    deleted_at: null
  }, {
    id: '3',
    title: '支付宝扫码支付',
    ping_plus_plus_channel: 'alipay_qr',
    brokerage: '0.008',
    created_at: '2019-08-06 20:04:32',
    updated_at: '2019-11-08 17:15:57',
    deleted_at: '2019-11-08 17:15:57'
  }, {
    id: '4',
    title: '支付宝条码支付',
    ping_plus_plus_channel: 'alipay_scan',
    brokerage: '0.008',
    created_at: '2019-08-09 09:29:56',
    updated_at: '2019-08-09 09:29:56',
    deleted_at: null
  }, {
    id: '5',
    title: '支付宝小程序支付',
    ping_plus_plus_channel: 'alipay_lite',
    brokerage: '0.008',
    created_at: '2019-08-09 09:30:18',
    updated_at: '2019-08-09 09:30:18',
    deleted_at: null
  }, {
    id: '6',
    title: '支付宝电脑网站支付',
    ping_plus_plus_channel: 'alipay_pc_direct',
    brokerage: '0.008',
    created_at: '2019-08-09 09:30:32',
    updated_at: '2019-08-09 09:30:32',
    deleted_at: null
  }, {
    id: '7',
    title: '微信 App 支付',
    ping_plus_plus_channel: 'wx',
    brokerage: '0.006',
    created_at: '2019-08-09 09:31:15',
    updated_at: '2019-08-09 09:31:15',
    deleted_at: null
  }, {
    id: '8',
    title: '微信浏览器支付',
    ping_plus_plus_channel: 'wx_pub',
    brokerage: '0.006',
    created_at: '2019-08-09 09:31:49',
    updated_at: '2019-08-09 09:31:49',
    deleted_at: null
  }, {
    id: '9',
    title: '微信扫码支付',
    ping_plus_plus_channel: 'wx_pub_qr',
    brokerage: '0.006',
    created_at: '2019-08-09 09:32:09',
    updated_at: '2019-08-09 09:32:09',
    deleted_at: null
  }, {
    id: '10',
    title: '微信付款码支付',
    ping_plus_plus_channel: 'wx_pub_scan',
    brokerage: '0.006',
    created_at: '2019-08-09 09:32:52',
    updated_at: '2019-08-09 09:32:52',
    deleted_at: null
  }, {
    id: '11',
    title: '微信 H5 支付',
    ping_plus_plus_channel: 'wx_wap',
    brokerage: '0.006',
    created_at: '2019-08-09 09:33:08',
    updated_at: '2019-08-09 09:33:08',
    deleted_at: null
  }, {
    id: '12',
    title: '微信小程序支付',
    ping_plus_plus_channel: 'wx_lite',
    brokerage: '0.006',
    created_at: '2019-08-09 09:34:19',
    updated_at: '2019-08-09 09:34:19',
    deleted_at: null
  }, {
    id: '13',
    title: '苹果支付',
    ping_plus_plus_channel: 'applepay_upacp',
    brokerage: '0.3',
    created_at: '2019-08-09 09:34:48',
    updated_at: '2019-08-09 09:34:48',
    deleted_at: null
  }, {
    id: '14',
    title: '苹果内购',
    ping_plus_plus_channel: 'applepay_iap',
    brokerage: '0.32',
    created_at: '2019-11-08 17:15:56',
    updated_at: '2019-11-08 17:15:56',
    deleted_at: null
  }, {
    id: '15',
    title: 'IOS余额购买',
    ping_plus_plus_channel: 'ios_balance',
    brokerage: '0.32',
    created_at: '2019-12-28 11:34:28',
    updated_at: '2019-12-28 11:34:32',
    deleted_at: null
  }, {
    id: '16',
    title: 'Android余额购买',
    ping_plus_plus_channel: 'android_balance',
    brokerage: '0.008',
    created_at: '2019-12-28 11:35:17',
    updated_at: '2019-12-28 11:35:19',
    deleted_at: null
  }]
};
exports.default = _default;