var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-number-input", {
    attrs: {
      min: 0,
      "left-text": _vm.leftText,
      "right-text": _vm.rightText,
      precision: 0
    },
    on: { blur: _vm.blur, change: _vm.change },
    model: {
      value: _vm.valueProxy,
      callback: function($$v) {
        _vm.valueProxy = $$v
      },
      expression: "valueProxy"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }