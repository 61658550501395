var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "1192px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "commodityDetailTitle" }, [_vm._v("商品详情")])
      ]),
      _vm._v(" "),
      _vm.commoditydetailData
        ? _c(
            "div",
            {
              staticClass: "commodityDetail",
              attrs: { type: "bcommodity-card" }
            },
            [
              _c("div", { staticClass: "commodityDetailTop flexItem" }, [
                _c("div", { staticClass: "commodityDetailTopLeft" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _vm.commoditydetailData.resourceInfo &&
                    _vm.commoditydetailData.resourceInfo.cover
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "0px 10px 0px 0px",
                              "align-items": "center"
                            }
                          },
                          [
                            _vm.commoditydetailData.resourceType === 3
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "90px",
                                    height: "126px"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      _vm.commoditydetailData.resourceInfo
                                        .cover,
                                      _vm.thumbnailStyle
                                    )
                                  }
                                })
                              : _c("el-image", {
                                  staticStyle: {
                                    width: "160px",
                                    height: "90px"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      _vm.commoditydetailData.resourceInfo
                                        .cover,
                                      _vm.thumbnailStyle
                                    )
                                  }
                                })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "0px 10px 0px 0px",
                          "align-items": "center"
                        }
                      },
                      [
                        _vm.commoditydetailData.resourceType === 6
                          ? _c("el-image", {
                              staticStyle: {
                                width: "160px",
                                "object-fit": "cover",
                                height: "90px"
                              },
                              attrs: {
                                fit: "cover",
                                src: _vm.getImgUrl(
                                  "mp/orderCover/vipCover.png",
                                  _vm.thumbnailStyle
                                ),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.commoditydetailData.resourceType === 5
                          ? _c("el-image", {
                              staticStyle: {
                                width: "160px",
                                "object-fit": "cover",
                                height: "90px"
                              },
                              attrs: {
                                fit: "cover",
                                src: _vm.getImgUrl(
                                  "mp/orderCover/jinxinbiCover.png",
                                  _vm.thumbnailStyle
                                ),
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "570px",
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              color: "#303133"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.showVal(
                                  _vm.commoditydetailData.resourceTitle
                                )
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "rgba(64,158,255,1)"
                            }
                          },
                          [
                            _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.handleClose }
                                  },
                                  [_vm._v("返回订单详情")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "commodityDetailbottom flexItem" }, [
                _c(
                  "div",
                  {
                    staticClass: "flexItem",
                    staticStyle: { display: "flex", "flex-direction": "column" }
                  },
                  [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticClass: "line" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "listOfGiftsTitle" }, [
                        _vm._v("商品介绍")
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.commoditydetailData.resourceInfo &&
                    _vm.commoditydetailData.resourceInfo.cover
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#FAFAFA",
                              padding: "10px"
                            }
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "360px", height: "100%" },
                              attrs: {
                                fit: "cover",
                                src: _vm.getImgUrl(
                                  _vm.commoditydetailData.resourceInfo.cover
                                ),
                                alt: ""
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.commoditydetailData.resourceInfo.cover ||
                    !_vm.commoditydetailData.resourceInfo
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#FAFAFA",
                              padding: "10px"
                            }
                          },
                          [
                            _vm.commoditydetailData.resourceType === 5
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "360px",
                                    height: "100%"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      "mp/orderCover/jinxinbiCover.png"
                                    ),
                                    alt: ""
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.commoditydetailData.resourceType === 6
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "360px",
                                    height: "100%"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      "mp/orderCover/vipCover.png"
                                    ),
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "padding-left": "30px", width: "100%" } },
                  [
                    _c("div", { staticClass: "orderInfo flexItem" }, [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticClass: "line" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "listOfGiftsTitle" }, [
                          _vm._v("商品属性")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column"
                          }
                        },
                        [
                          _c("div", { staticStyle: { padding: "5px 0px" } }, [
                            _vm._v(
                              "同步：" +
                                _vm._s(
                                  _vm.showVal(
                                    _vm.commoditydetailData.resourceInfo
                                      .syncInfoText
                                  )
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { padding: "5px 0px" } }, [
                            _vm._v(
                              "分类：" +
                                _vm._s(
                                  _vm.showVal(
                                    _vm.commoditydetailData.resourceInfo
                                      .categoryInfoText
                                  )
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { padding: "5px 0px" } }, [
                            _vm._v(
                              "专辑：" +
                                _vm._s(
                                  _vm.showVal(
                                    _vm.commoditydetailData.resourceInfo
                                      .introduction
                                  )
                                )
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "orderInfo flexItem" }, [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticClass: "line" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "listOfGiftsTitle" }, [
                          _vm._v("专辑简介")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "line-height": "27px"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.showVal(
                                  _vm.commoditydetailData.resourceInfo
                                    .introduction
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.commoditydetailData.gifts
                      ? _c("div", { staticClass: "listOfGifts flexItem" }, [
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c("div", { staticClass: "line" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "listOfGiftsTitle" }, [
                              _vm._v("赠品清单")
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.gifts.data
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap"
                                  }
                                },
                                _vm._l(_vm.gifts.data, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: { display: "flex" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            padding: "0px 10px 10px 0px",
                                            "padding-right": "30px"
                                          }
                                        },
                                        [
                                          item.resourceInfo
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "0px 10px 0px 0px",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  item.resourceType === 1 ||
                                                  item.resourceType === 4 ||
                                                  item.resourceType === 2
                                                    ? _c("el-image", {
                                                        staticStyle: {
                                                          width: "123px",
                                                          height: "69px"
                                                        },
                                                        attrs: {
                                                          fit: "cover",
                                                          src: _vm.getImgUrl(
                                                            item.resourceInfo
                                                              .cover,
                                                            _vm.normalStyle
                                                          ),
                                                          alt: ""
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.resourceType === 3
                                                    ? _c("el-image", {
                                                        staticStyle: {
                                                          width: "69px",
                                                          height: "97px"
                                                        },
                                                        attrs: {
                                                          fit: "cover",
                                                          src: _vm.getImgUrl(
                                                            item.resourceInfo
                                                              .cover,
                                                            _vm.normalStyle
                                                          ),
                                                          alt: ""
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !item.resourceInfo
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "0px 10px 0px 0px",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  item.resourceType === 5
                                                    ? _c("el-image", {
                                                        staticClass: "imgStyJV",
                                                        attrs: {
                                                          fit: "cover",
                                                          src: _vm.getImgUrl(
                                                            "mp/orderCover/jinxinbiCover.png",
                                                            _vm.thumbnailStyle
                                                          )
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.resourceType === 6
                                                    ? _c("el-image", {
                                                        staticClass: "imgStyJV",
                                                        attrs: {
                                                          fit: "cover",
                                                          src: _vm.getImgUrl(
                                                            "mp/orderCover/vipCover.png",
                                                            _vm.thumbnailStyle
                                                          )
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                                "justify-content":
                                                  "space-between"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "152px",
                                                    "font-size": "16px",
                                                    color: "#606266"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.resourceTitle)
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    color: "rgba(144,147,153,1)"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.resourcetypetoStr(
                                                          item.resourceType
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "orderInfo flexItem dottedLine" },
                      [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "line" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "listOfGiftsTitle" }, [
                            _vm._v("销售信息")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "priceSty" }, [
                          _c("div", { staticClass: "originalPrice" }, [
                            _vm._v(
                              "\n              原价：¥" +
                                _vm._s(
                                  Number(_vm.snapshotData.price / 100).toFixed(
                                    2
                                  )
                                ) +
                                "元\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "discount" }, [
                            _c("div", { staticClass: "discountLf" }, [
                              _c("div", { staticClass: "commPadding" }, [
                                _vm._v(
                                  "普通折扣：" +
                                    _vm._s(
                                      _vm.toPercent(_vm.snapshotData.discount)
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "vip折扣：" +
                                    _vm._s(
                                      _vm.toPercent(
                                        _vm.snapshotData.vipDiscount
                                      )
                                    )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "discountLf" }, [
                              _c(
                                "div",
                                { staticClass: "conmmonFlexje fontBlack" },
                                [
                                  _c("div", {}, [_vm._v("现价：")]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "presentPrice" }, [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "16px" } },
                                      [_vm._v("¥")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "-9px" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (
                                              (_vm.snapshotData.discount *
                                                _vm.snapshotData.price) /
                                              100
                                            ).toFixed(2)
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "vipPrice conmmonFlexje" },
                                [
                                  _vm._v(
                                    "\n                  VIP享折上折：¥" +
                                      _vm._s(
                                        (
                                          ((_vm.snapshotData.discount *
                                            _vm.snapshotData.price) /
                                            100) *
                                          _vm.snapshotData.vipDiscount
                                        ).toFixed(2)
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }