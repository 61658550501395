var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.getTitle(),
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "800px",
            "before-close": _vm.dismissAddDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "600px", overflow: "auto" } },
            [
              _c(
                "el-form",
                {
                  ref: "addFrom",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    model: _vm.addFrom,
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "addDialog_info_box" },
                    [
                      _c("div", { staticClass: "addDialog_title" }, [
                        _vm._v("\n            基础信息\n          ")
                      ]),
                      _vm._v(" "),
                      this.title === "商户号"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "所属中盘", prop: "" } },
                            [
                              _c("searchPartnerByInput", {
                                attrs: {
                                  partnerOpenId:
                                    _vm.addFrom.extended.midCapOpenId,
                                  "partner-model": "中盘号",
                                  "show-clear": _vm.showClear
                                },
                                on: { receivepartnerId: _vm.receivepartnerId }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商户名称", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入商户名称" },
                            model: {
                              value: _vm.addFrom.storeName,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "storeName", $$v)
                              },
                              expression: "addFrom.storeName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商户Logo", prop: "partnerLogo" } },
                        [
                          _c("cover-upload", {
                            attrs: {
                              path: _vm.path,
                              "partner-open-id": _vm.partnerOpenId,
                              coverarr: _vm.coverArr,
                              covertext: _vm.coverText,
                              type: "角色",
                              disabledEdit: false
                            },
                            on: { deletecover: _vm.deletecoverFun },
                            model: {
                              value: _vm.addFrom.partnerLogo,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "partnerLogo", $$v)
                              },
                              expression: "addFrom.partnerLogo"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.title === "商户号" || _vm.title === "中盘号"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "所属省份" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.addFrom.extended.belongProvince,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addFrom.extended,
                                        "belongProvince",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "addFrom.extended.belongProvince"
                                  }
                                },
                                _vm._l(_vm.optionsBelongProvince, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.fullname,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "operatorName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系人姓名" },
                            model: {
                              value: _vm.addFrom.operatorName,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "operatorName", $$v)
                              },
                              expression: "addFrom.operatorName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "operatorPhone" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系电话" },
                            model: {
                              value: _vm.addFrom.operatorPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "operatorPhone", $$v)
                              },
                              expression: "addFrom.operatorPhone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "省市区", prop: "cityCodeArray" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择所属省市",
                              props: _vm.propsAddress,
                              options: _vm.optionsAddress
                            },
                            model: {
                              value: _vm.addFrom.cityCodeArray,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "cityCodeArray", $$v)
                              },
                              expression: "addFrom.cityCodeArray"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "详细地址", prop: "address" } },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入详细地址" },
                            model: {
                              value: _vm.addFrom.address,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "address", $$v)
                              },
                              expression: "addFrom.address"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "简介", prop: "desc" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: "",
                              placeholder: "请输入简介信息"
                            },
                            model: {
                              value: _vm.addFrom.desc,
                              callback: function($$v) {
                                _vm.$set(_vm.addFrom, "desc", $$v)
                              },
                              expression: "addFrom.desc"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.title === "商户号"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "ERP供货", prop: "erpSupported" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display_flex align_items" },
                                [
                                  _c("el-switch", {
                                    attrs: { "active-color": "#13ce66" },
                                    model: {
                                      value: _vm.addFrom.erpSupported,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addFrom,
                                          "erpSupported",
                                          $$v
                                        )
                                      },
                                      expression: "addFrom.erpSupported"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "margin_left_10" }, [
                                    _vm._v(
                                      "开启后，所属库为总盘商品库的商品可以选择由ERP选择供货"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.addFrom.erpSupported
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "display_flex align_items margin_top_10",
                                      staticStyle: {
                                        "background-color":
                                          "rgba(246, 247, 251, 1)",
                                        padding: "10px 15px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              自动推送ERP\n              "
                                      ),
                                      _c("el-switch", {
                                        staticClass: "margin_left_10",
                                        attrs: { "active-color": "#13ce66" },
                                        model: {
                                          value: _vm.addFrom.erpAuto,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addFrom,
                                              "erpAuto",
                                              $$v
                                            )
                                          },
                                          expression: "addFrom.erpAuto"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "margin_left_10" },
                                        [_vm._v("开启后，订单自动推送到ERP")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.title === "中盘号"
                    ? _c(
                        "div",
                        {
                          staticClass: "addDialog_info_box",
                          staticStyle: { "margin-top": "10px" }
                        },
                        [
                          _c("div", { staticClass: "addDialog_title" }, [
                            _vm._v("\n            仓库信息\n          ")
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { attrs: { label: "仓库" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  { on: { click: _vm.showStoreDia } },
                                  [_vm._v("选择仓库")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.storeList }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "storeroomOpenId",
                                        label: "仓库ID"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { prop: "name", label: "仓库名称" }
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "userName",
                                        label: "收货人"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "userPhone",
                                        label: "联系电话"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "address",
                                        label: "收货地址"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "操作", width: "80" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: { type: "danger" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeStore(
                                                          scope.row.index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("移除")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3825849527
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "addDialog_info_box",
                      staticStyle: { "margin-top": "10px" }
                    },
                    [
                      _c("div", { staticClass: "addDialog_title" }, [
                        _vm._v("\n            协作者信息\n          ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择用户" } },
                        [
                          _c("search-user-by-input", {
                            ref: "searchUserByInput",
                            attrs: { "is-return-obj": true },
                            on: { receiveuserid: _vm.receiveUserObj }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "协作者", prop: "adminList" } },
                        [
                          _c(
                            "el-row",
                            _vm._l(_vm.userList, function(item, index) {
                              return _c(
                                "el-col",
                                { key: index, attrs: { span: 8 } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "teamworker_box" },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "userCover",
                                          staticStyle: { "margin-left": "8px" },
                                          attrs: {
                                            fit: "cover",
                                            src: _vm.getImgUrl(
                                              item.cover,
                                              _vm.thumbnailStyle
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error"
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "userCover",
                                                attrs: {
                                                  src: require("@/assets/book_cover.png")
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis",
                                            overflow: "hidden",
                                            "word-break": "break-all",
                                            "-webkit-box-orient": "vertical"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.nickName))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "teamworker_bt",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeUser(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dismissAddDialog } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.searchLoading },
                  on: { click: _vm.submit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.storeVisible
        ? _c("agency-select-store", {
            attrs: { data: _vm.storeList, "store-visible": _vm.storeVisible },
            on: {
              "update:storeVisible": function($event) {
                _vm.storeVisible = $event
              },
              "update:store-visible": function($event) {
                _vm.storeVisible = $event
              },
              selectStore: _vm.getSelectStore
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }