var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "management-box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "book-list"
        },
        [
          _c("book-search", {
            attrs: { query: _vm.query },
            on: { getList: _vm.getList }
          }),
          _vm._v(" "),
          _vm.bookList.length > 0
            ? _c(
                "div",
                { staticClass: "book-box" },
                _vm._l(_vm.bookList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "book-content" },
                    [
                      _c(
                        "div",
                        { staticClass: "book-image-box" },
                        [
                          _c(
                            "div",
                            { staticClass: "book-angle" },
                            [
                              _c("div", { staticClass: "edition" }, [
                                _c("span", [_vm._v(_vm._s(item.periodName))])
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        "\n                索引：" +
                                          _vm._s(item.id)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " openId：" + _vm._s(item.bookOpenId)
                                      ),
                                      _c("br"),
                                      _vm._v("名称：" + _vm._s(item.name)),
                                      _c("br"),
                                      _vm._v(
                                        " 学段：" + _vm._s(item.sectionName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                学科：" +
                                          _vm._s(item.subjectName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 版本：" + _vm._s(item.editionName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 年级：" + _vm._s(item.gradeName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                阶段：" +
                                          _vm._s(
                                            item.volumeName
                                              ? item.volumeName
                                              : "通用"
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 版次：" + _vm._s(item.periodName)
                                      ),
                                      _c("br"),
                                      _vm._v(" ISBN：" + _vm._s(item.isbn)),
                                      _c("br"),
                                      _vm._v(
                                        " 发布：" +
                                          _vm._s(
                                            item.status === 0 ? "否" : "是"
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v("\n                浏览量：0"),
                                      _c("br"),
                                      _vm._v(
                                        " 教材模型：" +
                                          _vm._s(item.bookMenuExtDataModelName)
                                      ),
                                      _c("br")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-info prompt"
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          item.cover
                            ? _c("el-image", {
                                staticClass: "book-image",
                                class: item.status === 0 ? "" : "active",
                                attrs: {
                                  src: _vm.getImageUrl(item.cover),
                                  fit: "cover"
                                }
                              })
                            : _c("el-image", {
                                staticClass: "book-image",
                                class: item.status === 0 ? "" : "active",
                                attrs: {
                                  src: require("../../../assets/images/book-image.png"),
                                  fit: "cover"
                                }
                              }),
                          _vm._v(" "),
                          _c("div", { staticClass: "image-mode" }, [
                            _c("div", { staticClass: "mode-menu-box" }, [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mode-menu",
                                    on: {
                                      click: function($event) {
                                        return _vm.showStatistics(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-operation"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mode-menu",
                                    on: {
                                      click: function($event) {
                                        return _vm.toBookDetail(
                                          item.id,
                                          item.bookOpenId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-black_md-create"
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mode-menu",
                                    on: {
                                      click: function($event) {
                                        return _vm.toDirectory(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-black_antOutline-unordered-list"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticStyle: { width: "49px" } })
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "book-angle angle-bottom" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "check-box",
                                  style: item.checked ? "display:inherit" : ""
                                },
                                [
                                  _c("el-checkbox", {
                                    on: { change: _vm.handleCheckedChange },
                                    model: {
                                      value: item.checked,
                                      callback: function($$v) {
                                        _vm.$set(item, "checked", $$v)
                                      },
                                      expression: "item.checked"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                {
                                  staticClass: "prompt-box",
                                  attrs: {
                                    trigger: "hover",
                                    placement: "bottom"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-more prompt more"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.toBookDetail(
                                                item.id,
                                                item.bookOpenId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  编辑教材\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.handleCopyBook(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  复制教材\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !item.deleted
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.updateStatus(
                                                    item.status,
                                                    item.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    item.status === 0
                                                      ? "发布"
                                                      : "下架"
                                                  ) +
                                                  "教材\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.deleted
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.deleteBook(
                                                    item.id,
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  移除教材\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.deleted
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.deleteBook(
                                                    item.id,
                                                    false
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  恢复教材\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "book-title",
                          attrs: { title: item.name }
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.name) + "\n        "
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "book-box not-list" }, [
                _vm._v("\n      暂无数据\n    ")
              ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fotter" }, [
        _c(
          "div",
          [
            _c("el-checkbox", {
              staticStyle: { "margin-right": "10px" },
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.handleCheckAllChange },
              model: {
                value: _vm.checkAll,
                callback: function($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "success",
                  loading: _vm.batchLoading,
                  plain: ""
                },
                on: {
                  click: function($event) {
                    return _vm.batchUpdateStatus(1)
                  }
                }
              },
              [_vm._v("\n        批量发布\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "danger",
                  loading: _vm.batchUnLoading,
                  plain: ""
                },
                on: {
                  click: function($event) {
                    return _vm.batchUpdateStatus(0)
                  }
                }
              },
              [_vm._v("\n        批量下架\n      ")]
            ),
            _vm._v(" "),
            _vm.checkLength
              ? _c("span", { staticClass: "select-number" }, [
                  _vm._v("已选："),
                  _c("span", [_vm._v(_vm._s(_vm.checkLength))])
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer-box" },
          [
            _c("el-pagination", {
              staticClass: "footer-page",
              attrs: {
                background: "",
                "current-page": _vm.query.page,
                "page-sizes": [20, 40, 60, 80, 100, 200],
                "page-size": _vm.query.size,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("copy-book", {
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              "book-info": _vm.bookInfo
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.dialogVisible = $event
              },
              "update:dialog-visible": function($event) {
                _vm.dialogVisible = $event
              },
              getList: _vm.getList,
              closeCopyDialog: _vm.closeCopyDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "book-statistics-box",
          attrs: {
            title: "教材资源统计",
            visible: _vm.bookStatistics,
            width: "1000px"
          },
          on: {
            "update:visible": function($event) {
              _vm.bookStatistics = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("bookStatistics", {
                attrs: {
                  bookStatistics: _vm.bookStatistics,
                  bookStatisticsInfo: _vm.bookStatisticsInfo
                },
                on: {
                  toDirectory: _vm.toDirectory,
                  closeDialog: function($event) {
                    _vm.bookStatistics = false
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }