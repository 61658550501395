"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'OrderScoreTable',
  props: {
    data: {
      type: Array,
      default: [],
      required: true
    }
  },
  data: function data() {
    return {
      smallthumbnailStyle: 'style/width-limit-64'
    };
  },
  methods: {
    getPointType: function getPointType(val) {
      if (val === 1) {
        return '推广商品';
      }

      if (val === 2) {
        return '积分兑换';
      }

      if (val === 3) {
        return '商品购买';
      }

      if (val === 4) {
        return '手动销分';
      }

      if (val === 5) {
        return '下级推广返佣';
      }

      if (val === 6) {
        return '退款消分';
      }

      if (val === 7) {
        return '二级返佣退款消分';
      }

      return '--';
    },
    getPointUserType: function getPointUserType(val) {
      if (val === 1) {
        return '普通推广员';
      }

      if (val === 2) {
        return '高级推广员';
      }

      if (val === 3) {
        return '购买用户';
      }

      return '--';
    },

    /* 获取tupian */
    getImgUrl: function getImgUrl(cover, style) {
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    }
  }
};
exports.default = _default;