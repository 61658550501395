"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TextAndImages',
  props: {
    selectUserOpenId: {
      type: String,
      default: ''
    },
    operateUserOpenId: {
      type: String,
      default: ''
    },
    conversationContent: {
      type: String,
      default: ''
    },
    imgUrls: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    /* 获取图片数组 */
    getImg: function getImg(cover, style) {
      var imgAllUrlArray = [];

      if (cover && cover.length > 0) {
        cover.forEach(function (item) {
          var imgAllUrl = (0, _common.imageUrlFun)(item, style);
          imgAllUrlArray.push(imgAllUrl);
        });
      }

      return imgAllUrlArray;
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      if (cover.substring(0, 4) === 'http' || cover.substring(0, 5) === 'https') return cover;
      var imgAllUrl = (0, _common.imageUrlFun)(cover, 'style/width-limit-80');
      return imgAllUrl;
    }
  }
};
exports.default = _default2;