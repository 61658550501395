"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _common = require("../../../../utils/common");

var _DownloadNav = _interopRequireDefault(require("./DownloadNav"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DownloadQuestionItem',
  components: {
    DownloadNav: _DownloadNav.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    valIndex: {
      type: Number,
      default: undefined
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    groupIndex: {
      type: Number,
      default: undefined
    },
    questionIndex: {
      type: Number,
      default: undefined
    }
  },
  data: function data() {
    return {
      showOptions: false,
      right: 'http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/NSelectRight.png',
      error: 'http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/NSelectError.png'
    };
  },
  computed: {
    choiceStyle: function choiceStyle() {
      if (this.value.otherOptions === 2) {
        return {
          width: '50%',
          'padding-right': '10px',
          flex: 'none'
        };
      }

      if (this.value.otherOptions === 4) {
        return {
          width: '25%',
          'padding-right': '10px',
          flex: 'none'
        };
      }

      return {
        width: '100%'
      };
    }
  },
  methods: {
    getConnectKey: function getConnectKey(val) {
      return val ? val.toLowerCase().charCodeAt() - 96 : undefined;
    },
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-768';

      if (!cover) {
        return cover;
      }

      return (0, _common.imageUrlFun)(cover, style);
    },
    handleMouse: function handleMouse(val) {
      if (this.value.examQuestionTypeCode === 1009 || this.value.examQuestionTypeCode === 1005 || this.value.examQuestionTypeCode === 1008) {
        return;
      }

      this.showOptions = val;
    },
    handleOption: function handleOption(val) {
      this.$emit('handleOption', val, this.valIndex, this.groupIndex, this.questionIndex);
    }
  }
};
exports.default = _default2;