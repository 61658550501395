"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../index");

/**
 * 格式化时间
 *
 * @param {*} time
 * @param {string} format
 * @returns {string}
 */
var _default = function _default(time, format) {
  try {
    // 如果时间有值，则格式化时间
    if (time) {
      return (0, _index.parseTime)(time, format);
    }

    return '--';
  } catch (e) {
    console.error(e.message);
    return '--';
  }
};

exports.default = _default;