var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard_container" },
    [
      _c("div", { staticClass: "zhongpan_box" }, [
        _c(
          "div",
          { staticClass: "top_box" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.showAddDialog } },
              [_vm._v("新建店铺")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-input", {
                  staticStyle: { width: "184px" },
                  attrs: {
                    placeholder: "请输入店铺名称或手机号",
                    clearable: ""
                  },
                  model: {
                    value: _vm.params.name,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "name", $$v)
                    },
                    expression: "params.name"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.searchLoading },
                    on: {
                      click: function($event) {
                        return _vm.getData(true)
                      }
                    }
                  },
                  [_vm._v("搜索")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table_box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.searchLoading,
                    expression: "searchLoading"
                  }
                ],
                attrs: {
                  data: _vm.tableData,
                  width: "100%",
                  height: "100%",
                  loading: ""
                }
              },
              [
                _c("el-table-column", {
                  attrs: { fixed: "", "min-width": "150", label: "商户" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "userCover",
                                  staticStyle: { "margin-right": "8px" },
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.partnerLogo,
                                      _vm.thumbnailStyle
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userCover",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "rgba(48, 49, 51, 1)",
                                    "font-size": "14px",
                                    "font-family": "SourceHanSansSC-regular"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(scope.row.storeName) +
                                      "\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "100", label: "在售" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "editableColumns",
                              on: {
                                click: function($event) {
                                  return _vm.openShopAllProductManage(scope.row)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.allProductOnCount))
                              ]),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-edit" })
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "100", label: "分销商品" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "editableColumns",
                              on: {
                                click: function($event) {
                                  return _vm.openShopProductManage(scope.row)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.distributionOnCount) +
                                    " / " +
                                    _vm._s(scope.row.distributionProductCount)
                                )
                              ]),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-edit" })
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "userCount",
                    align: "center",
                    "min-width": "100",
                    label: "用户数"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "orderCount",
                    "min-width": "100",
                    align: "center",
                    label: "总订单数"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "100", label: "总销售额" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.showVal(
                                    Number(scope.row.totalSales / 100).toFixed(
                                      2
                                    )
                                  )
                                )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "100", label: "可提现" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  _vm.showVal(
                                    Number(scope.row.balance / 100).toFixed(2)
                                  )
                                )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "80", label: "附近书店" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#13ce66" },
                                on: {
                                  change: function($event) {
                                    return _vm.nearbyStoreOpenChange(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.extended.showNearby,
                                  callback: function($$v) {
                                    _vm.$set(
                                      scope.row.extended,
                                      "showNearby",
                                      $$v
                                    )
                                  },
                                  expression: "scope.row.extended.showNearby"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "80", label: "ERP供货" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#13ce66" },
                                on: {
                                  change: function($event) {
                                    return _vm.changeErpSupported(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.extended.erpSupported,
                                  callback: function($$v) {
                                    _vm.$set(
                                      scope.row.extended,
                                      "erpSupported",
                                      $$v
                                    )
                                  },
                                  expression: "scope.row.extended.erpSupported"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "80", label: "自动推送ERP" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "请先开启ERP供货设置，再设置自动推送",
                                placement: "top"
                              }
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  disabled: !scope.row.extended.erpSupported,
                                  "active-color": "#13ce66"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeErpAuto(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.extended.erpAuto,
                                  callback: function($$v) {
                                    _vm.$set(scope.row.extended, "erpAuto", $$v)
                                  },
                                  expression: "scope.row.extended.erpAuto"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "100",
                    prop: "createdAt",
                    label: "创建时间"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "right",
                    "min-width": "100",
                    label: "操作"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.jump(scope.row.partnerOpenId)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          ),
                          _vm._v(" "),
                          scope.row.extended.status === 1
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { underline: false, type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showStopDialog(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("禁用\n              ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.extended.status === 0
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { underline: false, type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeStatus(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("激活\n              ")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page_foot" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.params.page,
                "page-sizes": [10, 20, 30, 50],
                "page-size": _vm.params.size,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.totalElements
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.addDialogVisible
        ? _c("zhong-pan-add-dialog", {
            attrs: {
              "dialog-visible": _vm.addDialogVisible,
              title: "商户号",
              midCapOpenId: _vm.params.midCapOpenId
            },
            on: {
              zhongPanAddRoleDialogClose: _vm.dismissAddDialog,
              "update:midCapOpenId": function($event) {
                return _vm.$set(_vm.params, "midCapOpenId", $event)
              },
              "update:mid-cap-open-id": function($event) {
                return _vm.$set(_vm.params, "midCapOpenId", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "禁用" + _vm.item.storeName,
            visible: _vm.stopDialog,
            width: "30%",
            "before-close": _vm.dismissStopDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.stopDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "stopForm",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "禁用原因", prop: "disableDesc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.disableDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "disableDesc", $$v)
                      },
                      expression: "form.disableDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dismissStopDialog } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.checkStopForm()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogs" },
        [
          _c("shop-product-manage", {
            attrs: {
              "middle-open-id": _vm.params.midCapOpenId,
              "shop-open-id": _vm.shopProductManageShopOpenId
            },
            on: {
              "update-data": function($event) {
                return _vm.getData(false)
              }
            },
            model: {
              value: _vm.shopProductManageVisible,
              callback: function($$v) {
                _vm.shopProductManageVisible = $$v
              },
              expression: "shopProductManageVisible"
            }
          }),
          _vm._v(" "),
          _c("shop-all-product-manage", {
            attrs: {
              "middle-open-id": _vm.params.midCapOpenId,
              "shop-open-id": _vm.shopAllProductManageShopOpenId
            },
            on: {
              "update-data": function($event) {
                return _vm.getData(false)
              }
            },
            model: {
              value: _vm.shopAllProductManageVisible,
              callback: function($$v) {
                _vm.shopAllProductManageVisible = $$v
              },
              expression: "shopAllProductManageVisible"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.settingNearbyVisible
        ? _c("store-near-setting", {
            attrs: { visible: _vm.settingNearbyVisible },
            on: {
              "update:visible": function($event) {
                _vm.settingNearbyVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }