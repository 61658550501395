var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-tag-container" },
    [
      _c("div", { staticClass: "header-bg" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addBtn } },
              [_vm._v("添加")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "flex", "padding-bottom": "10px" } },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "250px", "margin-right": "10px" },
                attrs: { clearable: "", placeholder: "请选择产品应用" },
                on: {
                  change: function($event) {
                    return _vm.getlistApiinPurchs(_vm.query.appProductOpenId)
                  }
                },
                model: {
                  value: _vm.query.appProductOpenId,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "appProductOpenId", $$v)
                  },
                  expression: "query.appProductOpenId"
                }
              },
              _vm._l(_vm.appProducts, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.title, value: item.openId }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticStyle: { width: "250px", "margin-right": "10px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "页面名称/页面路径搜索"
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getList($event)
                }
              },
              model: {
                value: _vm.query.keyword,
                callback: function($$v) {
                  _vm.$set(_vm.query, "keyword", $$v)
                },
                expression: "query.keyword"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.getList } },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "tag-table-body"
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "pageName", label: "页面名称", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "appProductOpenIds",
                  label: "系统",
                  width: "300"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.showSysHandle(scope.row.appProductOpenIds)
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sequence", label: "排序", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.showEditFileSort &&
                        _vm.currentIndex === scope.$index
                          ? _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { margin: "5px 5px" },
                                  attrs: {
                                    min: 1,
                                    size: "mini",
                                    "controls-position": "right"
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.saveSort(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.sequence,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "sequence", $$v)
                                    },
                                    expression: "scope.row.sequence"
                                  }
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-close",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "font-size": "24px",
                                    color: "#F56c6c",
                                    cursor: "pointer"
                                  },
                                  on: { click: _vm.cancelSort }
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-check",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "font-size": "24px",
                                    color: "#13ce66",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveSort(scope.row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: {
                                  padding: "5px 10px",
                                  margin: "5px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortEdit(scope.row, scope.$index)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.sequence))]
                            )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "jump", label: "允许跳转", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: { "active-color": "#13ce66" },
                          on: {
                            change: function($event) {
                              return _vm.jumpAllowed(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.jump,
                            callback: function($$v) {
                              _vm.$set(scope.row, "jump", $$v)
                            },
                            expression: "scope.row.jump"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "is_enable", label: "启用横幅", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: { "active-color": "#13ce66" },
                          on: {
                            change: function($event) {
                              return _vm.enableBanner(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.enable,
                            callback: function($$v) {
                              _vm.$set(scope.row, "enable", $$v)
                            },
                            expression: "scope.row.enable"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "openid", label: "横幅位", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v(_vm._s(scope.row.boothQty) + "个")]
                            ),
                            _vm._v(" "),
                            _c("el-button", {
                              attrs: { icon: "el-icon-edit", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.editBanner(scope.row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  width: "auto",
                  "min-width": "100",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeBtn(
                                      scope.row,
                                      scope.$index
                                    )
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "530px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialogBody" },
            [
              _c(
                "el-form",
                {
                  ref: "formName",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.channelForm,
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "页面名称",
                        prop: "pageName",
                        rules: [
                          {
                            required: true,
                            message: "请输入页面名称",
                            trigger: "change"
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请填写页面名称" },
                        model: {
                          value: _vm.channelForm.pageName,
                          callback: function($$v) {
                            _vm.$set(_vm.channelForm, "pageName", $$v)
                          },
                          expression: "channelForm.pageName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择系统",
                        prop: "appProductOpenIds",
                        rules: [
                          {
                            required: true,
                            message: "请选择系统",
                            trigger: "change"
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择系统",
                            multiple: ""
                          },
                          on: { change: _vm.tagChange },
                          model: {
                            value: _vm.channelForm.appProductOpenIds,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.channelForm,
                                "appProductOpenIds",
                                $$v
                              )
                            },
                            expression: "channelForm.appProductOpenIds"
                          }
                        },
                        _vm._l(_vm.appProducts, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.openId }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.channelForm.pageUrlList.length
                    ? _c("el-form-item", { attrs: { prop: "pageName" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#ff4949",
                                  "margin-right": "4px"
                                }
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("页面路径")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "pagePathSty" },
                          _vm._l(_vm.channelForm.pageUrlList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showChannel(item.appProductOpenId)
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: item.verify,
                                          callback: function($$v) {
                                            _vm.$set(item, "verify", $$v)
                                          },
                                          expression: "item.verify"
                                        }
                                      },
                                      [_vm._v("登录验证")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  attrs: { placeholder: "请填写页面名称" },
                                  model: {
                                    value: item.pageUrl,
                                    callback: function($$v) {
                                      _vm.$set(item, "pageUrl", $$v)
                                    },
                                    expression: "item.pageUrl"
                                  }
                                }),
                                _vm._v(" "),
                                !item.pageUrl
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#ff4949",
                                          display: "flex",
                                          "line-height": "1",
                                          "padding-top": "4px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("请填写页面名称")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", {
                    staticStyle: {
                      "border-top": "1px solid #F5F5F5",
                      "margin-bottom": "0"
                    },
                    attrs: { label: "页面配置" }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "允许跳转" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.channelForm.jump,
                          callback: function($$v) {
                            _vm.$set(_vm.channelForm, "jump", $$v)
                          },
                          expression: "channelForm.jump"
                        }
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "iconInfo el-icon-info",
                        staticStyle: { color: "#409EFF" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("关闭时，此页面不允许被跳转")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用横幅" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.channelForm.enable,
                          callback: function($$v) {
                            _vm.$set(_vm.channelForm, "enable", $$v)
                          },
                          expression: "channelForm.enable"
                        }
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "iconInfo el-icon-info",
                        staticStyle: { color: "#409EFF" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("关闭时，此页面没有横幅位")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                display: "flex",
                "border-top": "1px solid #F5F5F5",
                "padding-top": "20px",
                "justify-content": "space-between"
              },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                [
                  _c("span", { staticStyle: { color: "#606266" } }, [
                    _vm._v("排序：")
                  ]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      size: "mini",
                      "controls-position": "right"
                    },
                    model: {
                      value: _vm.channelForm.sequence,
                      callback: function($$v) {
                        _vm.$set(_vm.channelForm, "sequence", $$v)
                      },
                      expression: "channelForm.sequence"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.editDialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.submitSyncLoading,
                        type: "primary",
                        plain: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.addEditPage("formName")
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "横幅位管理",
            visible: _vm.editDialogVisibleBan,
            "close-on-click-modal": false,
            width: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisibleBan = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialogBody" },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.addBanner }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "tablewrap",
                  staticStyle: { width: "100%", overflow: "auto" },
                  attrs: { data: _vm.tableDataBan, height: "400px" },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", { attrs: { type: "selection" } }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "boothCode", label: "code", width: "250" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "boothName",
                      label: "横幅位名称",
                      width: "180"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "启用" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-switch", {
                              attrs: { "active-color": "#13ce66" },
                              on: {
                                change: function($event) {
                                  return _vm.changeEnable(scope.row)
                                }
                              },
                              model: {
                                value: scope.row.enable,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "enable", $$v)
                                },
                                expression: "scope.row.enable"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: "right",
                      width: "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editBtnBan(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "removeBtn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeBtnBan(
                                          scope.row,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("移除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "padding-left": "13px"
                  }
                },
                [
                  _c("div", [
                    _vm.tableDataBan.length
                      ? _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              staticClass: "pagination-check",
                              staticStyle: { "margin-right": "10px" },
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.handleSelectedAll },
                              model: {
                                value: _vm.selectedAll,
                                callback: function($$v) {
                                  _vm.selectedAll = $$v
                                },
                                expression: "selectedAll"
                              }
                            }),
                            _vm._v(" "),
                            _c("common-dropdown", {
                              attrs: {
                                dropdownData: _vm.dropdownData,
                                dropdowntitle: _vm.dropdowntitle
                              },
                              on: { batchHandle: _vm.batchHandle }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.editDialogVisibleBan = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                title: _vm.dialogTitltTextBan,
                visible: _vm.dialogAddedit,
                "close-on-click-modal": false,
                width: "480px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogAddedit = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "dialogBody" },
                [
                  _c(
                    "el-form",
                    { ref: "formNamebooth", attrs: { model: _vm.boothForm } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "boothCode",
                            rules: [
                              {
                                required: true,
                                message: "请填写横幅位code",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请填写横幅位code" },
                            model: {
                              value: _vm.boothForm.boothCode,
                              callback: function($$v) {
                                _vm.$set(_vm.boothForm, "boothCode", $$v)
                              },
                              expression: "boothForm.boothCode"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "boothName",
                            rules: [
                              {
                                required: true,
                                message: "请填写横幅位名称",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请填写横幅位名称" },
                            model: {
                              value: _vm.boothForm.boothName,
                              callback: function($$v) {
                                _vm.$set(_vm.boothForm, "boothName", $$v)
                              },
                              expression: "boothForm.boothName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogAddedit = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.submitSyncLoadingBan,
                        type: "primary",
                        plain: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.addEditbooth("formNamebooth")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }