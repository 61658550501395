var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.visible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "附近书店设置",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "append-to-body": true,
                visible: _vm.visible,
                "before-close": _vm.dismissSettingNearbyDialog,
                width: "35%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.visible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "nearby_dialog_body" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, "label-width": "80px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "中盘店铺",
                            prop: "belongMidCap",
                            rules: [
                              {
                                required: true,
                                message: "是否中盘店铺不能为空",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.form.belongMidCap,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "belongMidCap", $$v)
                                },
                                expression: "form.belongMidCap"
                              }
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.form.belongMidCap,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "belongMidCap", $$v)
                                },
                                expression: "form.belongMidCap"
                              }
                            },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "负责区域",
                            prop: "areaCode",
                            rules: [
                              {
                                required: true,
                                message: "负责区域不能为空",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-button", [_vm._v("选择区域")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "area_box" },
                                _vm._l(_vm.area_tags, function(tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticStyle: { margin: "0px 10px" },
                                      attrs: { closable: "", type: "info" },
                                      on: {
                                        close: function($event) {
                                          return _vm.removeAreaList(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(tag) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { on: { click: _vm.dismissSettingNearbyDialog } },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }