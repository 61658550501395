"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BatchAddVerseDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      value: undefined
    };
  },
  methods: {
    cancel: function cancel() {
      this.$emit('update:visible', false);
    },
    submit: function submit() {
      if (!this.value) {
        return this.$notify.info({
          title: '提示',
          message: '请填写诗句'
        });
      }

      if (!this.value.trim()) {
        return this.$notify.info({
          title: '提示',
          message: '请填写诗句'
        });
      }

      this.$emit('submit', this.value);
    }
  }
};
exports.default = _default;