var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      staticClass: "composition-dialog",
      attrs: {
        title: "添加范文",
        visible: _vm.dialogType,
        width: "1000px",
        "before-close": _vm.handleClose,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogType = $event
        }
      }
    },
    [
      _c(
        "span",
        { staticClass: "title-box", attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", { staticClass: "title" }, [_vm._v("添加范文")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              on: { change: _vm.handleChangeGroup },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("分类")
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [_vm._v("同步")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-resource-dialog-body-layout" }, [
        _c(
          "div",
          { staticClass: "composition-switch" },
          [
            _c("el-cascader", {
              staticClass: "composition-select",
              attrs: {
                placeholder: "体裁",
                options: _vm.genreLists,
                props: _vm.defaultProps,
                "collapse-tags": "",
                multiple: "",
                size: "mini",
                clearable: ""
              },
              model: {
                value: _vm.query.typeCodes,
                callback: function($$v) {
                  _vm.$set(_vm.query, "typeCodes", $$v)
                },
                expression: "query.typeCodes"
              }
            }),
            _vm._v(" "),
            _vm.type === "2"
              ? _c(
                  "el-select",
                  {
                    staticClass: "composition-select",
                    attrs: { placeholder: "版本", size: "mini" },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.query.editionCode,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "editionCode", $$v)
                      },
                      expression: "query.editionCode"
                    }
                  },
                  _vm._l(_vm.syncEdition, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "composition-select",
                attrs: { placeholder: "年级", size: "mini" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.query.gradeCodes,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "gradeCodes", $$v)
                  },
                  expression: "query.gradeCodes"
                }
              },
              _vm._l(_vm.syncGrade, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.name, value: item.code }
                })
              }),
              1
            ),
            _vm._v(" "),
            _vm.type === "2"
              ? _c(
                  "el-select",
                  {
                    staticClass: "composition-select",
                    attrs: {
                      disabled: !(
                        _vm.query.editionCode && _vm.query.gradeCodes
                      ),
                      "collapse-tags": "",
                      multiple: "",
                      placeholder: "单元",
                      size: "mini"
                    },
                    on: { change: _vm.search },
                    model: {
                      value: _vm.query.unitCodes,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "unitCodes", $$v)
                      },
                      expression: "query.unitCodes"
                    }
                  },
                  _vm._l(_vm.unitList, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "composition-select",
                attrs: {
                  "collapse-tags": "",
                  multiple: "",
                  placeholder: "字数",
                  size: "mini"
                },
                on: { change: _vm.search },
                model: {
                  value: _vm.query.countCodes,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "countCodes", $$v)
                  },
                  expression: "query.countCodes"
                }
              },
              _vm._l(_vm.countList, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.name, value: item.code }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "composition-input",
              attrs: { clearable: "", size: "mini", placeholder: "搜索范文" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.search($event)
                }
              },
              model: {
                value: _vm.query.condition,
                callback: function($$v) {
                  _vm.$set(_vm.query, "condition", $$v)
                },
                expression: "query.condition"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "composition-button",
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-resource-body-layout content-table" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type === "1",
                    expression: "type === '1'"
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                ref: "table",
                attrs: {
                  data: _vm.list,
                  "row-class-name": _vm.tableRowClassName,
                  "tooltip-effect": "dark"
                },
                on: { "selection-change": _vm.selRoleChangeHand }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    selectable: _vm.selectable,
                    align: "left",
                    width: "26",
                    type: "selection"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "60", label: "序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.query.page - 1) * _vm.query.size +
                                  scope.$index +
                                  1
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    prop: "data",
                    "min-width": "450",
                    align: "left",
                    label: "范文"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.compositionName))
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "150", label: "体载" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.typeName))])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "年级", "min-width": "108" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.gradeName))])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "字数" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.countName))])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type === "2",
                    expression: "type === '2'"
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                ref: "table1",
                attrs: {
                  data: _vm.list,
                  "row-class-name": _vm.tableRowClassName,
                  "tooltip-effect": "dark"
                },
                on: { "selection-change": _vm.selRoleChangeHand }
              },
              [
                _c("el-table-column", {
                  staticClass: "abcedasd",
                  attrs: {
                    selectable: _vm.selectable,
                    align: "left",
                    width: "26",
                    type: "selection"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "60", label: "序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.query.page - 1) * _vm.query.size +
                                  scope.$index +
                                  1
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    prop: "data",
                    "min-width": "220",
                    align: "left",
                    label: "范文"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.compositionName))
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "150", label: "版本" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.editionName))])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "年级", width: "108" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.gradeName))])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "200", label: "单元" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.unitName))])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "字数" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.countName))])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select-resource-footer-layout" }, [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("el-checkbox", {
                attrs: { indeterminate: _vm.isIndeterminate },
                on: { change: _vm.handleCheckAllChange },
                model: {
                  value: _vm.checkAll,
                  callback: function($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll"
                }
              }),
              _vm._v(" "),
              _c("el-pagination", {
                staticClass: "footer-page",
                attrs: {
                  background: "",
                  "current-page": _vm.query.page,
                  "page-sizes": [10, 20, 40, 60],
                  "page-size": _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleCheckAdd }
                },
                [_vm._v("\n          确认添加\n        ")]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }