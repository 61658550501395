"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DefaultSelector = _interopRequireDefault(require("../../../../General/From/Selector/ProductGrouping/DefaultSelector.vue"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'GroupSelector',
  components: {
    ProductGroupingSelector: _DefaultSelector.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    secondGroupIds: {
      type: String,
      default: undefined
    },
    middleOpenId: {
      type: String,
      default: undefined
    },
    partnerOpenId: {
      type: String,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    secondGroupIdsProxy: {
      get: function get() {
        return this.secondGroupIds;
      },
      set: function set(value) {
        this.$emit('update:secondGroupIds', value);
      }
    }
  }
};
exports.default = _default;