"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 小学的 学段和学科对应
var primaryData = [{
  name: '语文',
  code: '1001'
}, {
  name: '数学',
  code: '1002'
}, {
  name: '英语',
  code: '1003'
}, {
  name: '科学',
  code: '1004'
}, {
  name: '道德与法治',
  code: '1012'
}];
var juniorHeightSchool = [{
  name: '语文',
  code: '1001'
}, {
  name: '数学',
  code: '1002'
}, {
  name: '英语',
  code: '1003'
}, {
  name: '生物',
  code: '1008'
}, {
  name: '化学',
  code: '1007'
}, {
  name: '物理',
  code: '1006'
}, {
  name: '道德与法治',
  code: '1012'
}, {
  name: '地理',
  code: '1011'
}, {
  name: '历史',
  code: '1010'
}];
var middleData = [{
  name: '语文',
  code: '1001'
}, {
  name: '数学',
  code: '1002'
}, {
  name: '英语',
  code: '1003'
}, {
  name: '生物',
  code: '1008'
}, {
  name: '化学',
  code: '1007'
}, {
  name: '物理',
  code: '1006'
}, {
  name: '政治',
  code: '1012'
}, {
  name: '地理',
  code: '1011'
}, {
  name: '历史',
  code: '1010'
}];
var _default = {
  1001: primaryData,
  1002: juniorHeightSchool,
  1003: middleData
};
exports.default = _default;