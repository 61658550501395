var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stroke-order-list" },
    _vm._l(_vm.strokeOrderList, function(strokeOrder, key) {
      return _c("stroke-order", {
        key: key,
        attrs: { "stroke-order": strokeOrder }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }