var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bulkUpload" }, [
    _c(
      "div",
      { staticClass: "bulkUpload-content" },
      [
        _c("div", { staticClass: "steps-view" }, [
          _c("div", { staticClass: "steps-number" }, [
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-line",
                class: _vm.uploadStep > 0 ? "finished" : ""
              },
              [_vm._m(0), _vm._v(" "), _vm._m(1)]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-line",
                class: _vm.uploadStep > 1 ? "finished" : ""
              },
              [
                _c("div", { staticClass: "steps-number-view" }, [
                  _c("span", { class: _vm.uploadStep > 0 ? "having" : "" }, [
                    _vm._v("2")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "steps-number-line" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "steps-text-item",
                    class: _vm.uploadStep > 0 ? "havingText" : ""
                  },
                  [
                    _c("span", [_vm._v("步骤 2")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("选择待上传的作品")])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-line",
                class: _vm.uploadStep > 2 ? "finished" : ""
              },
              [
                _c("div", { staticClass: "steps-number-view" }, [
                  _vm.uploadStep !== 2
                    ? _c(
                        "span",
                        { class: _vm.uploadStep > 2 ? "having" : "" },
                        [_vm._v("3")]
                      )
                    : _c("div", { staticClass: "uploadLoading" }, [_c("span")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "steps-number-line" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "steps-text-item",
                    class: _vm.uploadStep > 1 ? "havingText" : ""
                  },
                  [
                    _c("span", [_vm._v("步骤 3")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("批量上传作品")])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "steps-number-item steps-end",
                class: _vm.uploadStep > 3 ? "finished" : ""
              },
              [
                _c("div", { staticClass: "steps-number-view" }, [
                  _c("span", { class: _vm.uploadStep > 2 ? "having" : "" }, [
                    _vm._v("4")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "steps-text-item",
                    class: _vm.uploadStep > 2 ? "havingText" : ""
                  },
                  [
                    _c("span", [_vm._v("步骤 4")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("完成批量上传")])
                  ]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.uploaderShow,
                expression: "uploaderShow"
              }
            ],
            staticClass: "animated content",
            class: _vm.nextLeft ? "fadeOutLeft" : "fadeOutRight",
            staticStyle: { height: "calc(100vh - 230px)" }
          },
          [
            _c("bulk-basic-info", {
              ref: "bulkBasicInfo",
              attrs: {
                "partner-open-id": _vm.partnerOpenId,
                "resource-type": _vm.resourceType
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.uploaderShow,
                expression: "!uploaderShow"
              }
            ],
            staticClass: "animated content box",
            class: _vm.nextRight ? "fadeInRight" : "fadeInLeft",
            staticStyle: {
              height: "calc(100vh - 230px)",
              "padding-top": "20px"
            }
          },
          [
            _vm.resourceType === "10"
              ? _c("batch-upload-file", {
                  ref: "batchFileUploader",
                  staticClass: "bulk-video-upload",
                  attrs: {
                    isAutoUpload: _vm.openUpload,
                    "resource-type": _vm.resourceType
                  },
                  on: { fileUploading: _vm.fileUploading },
                  model: {
                    value: _vm.videoArr,
                    callback: function($$v) {
                      _vm.videoArr = $$v
                    },
                    expression: "videoArr"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "batch-bottom box",
            style: _vm.uploadStep > 0 ? "" : "justify-content: flex-end"
          },
          [
            _vm.uploadStep > 0
              ? _c(
                  "div",
                  {
                    staticClass: "display-flex",
                    staticStyle: { "align-items": "center" }
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "check-all",
                      attrs: {
                        indeterminate: _vm.isIndeterminate,
                        disabled:
                          _vm.videoArr.length <= 0 || _vm.fileUploadingBol
                      },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.fileUploadingBol || _vm.uploadStep >= 4,
                          type: "danger",
                          plain: ""
                        },
                        on: { click: _vm.removeFile }
                      },
                      [_vm._v("移除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.uploadStep >= 4 || _vm.fileUploadingBol,
                          type: "primary"
                        },
                        on: { click: _vm.selectProduct }
                      },
                      [_vm._v("添加作品")]
                    ),
                    _vm._v(" "),
                    _vm.videoArr.length !== 0
                      ? _c("span", { staticClass: "statistics" }, [
                          _vm._v(
                            "待上传：" +
                              _vm._s(_vm.videoArr.length - _vm.uploadIndex) +
                              "个，已上传：" +
                              _vm._s(_vm.uploadIndex) +
                              "个，共 " +
                              _vm._s(_vm.videoArr.length) +
                              " 个"
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm.uploadStep < 4
                ? _c(
                    "span",
                    [
                      !_vm.uploadStep < 4
                        ? _c(
                            "el-button",
                            {
                              attrs: { disabled: _vm.fileUploadingBol },
                              on: { click: _vm.cancelBulkVideo }
                            },
                            [_vm._v("取消")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploadStep === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.next }
                            },
                            [_vm._v("下一步")]
                          )
                        : !_vm.fileUploadingBol && _vm.uploadStep < 4
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.previous }
                            },
                            [_vm._v("上一步")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uploadStep > 0
                ? _c(
                    "span",
                    [
                      _vm.uploadIndex <= 0 && !_vm.fileUploadingBol
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                disabled: _vm.videoArr.length <= 0,
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.uploadVisible = true
                                }
                              }
                            },
                            [_vm._v("开始上传")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploadStep >= 4
                        ? _c("span", [
                            !_vm.showSubmit
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "loadingBtn",
                                    attrs: { type: "primary", plain: "" }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "uploadLoading",
                                      staticStyle: {
                                        width: "14px",
                                        height: "14px",
                                        "border-width": "1px",
                                        "border-color": "#1890ff",
                                        "border-bottom-color": "transparent"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v("作品正在上传中，请稍后...")]
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary", plain: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onSubmit()
                                          }
                                        }
                                      },
                                      [_vm._v("批量提交审核")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.submitClosePage }
                                      },
                                      [_vm._v("完成")]
                                    )
                                  ],
                                  1
                                )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c("upload-dialog", {
          ref: "dialog",
          attrs: {
            info: _vm.videoInfo,
            resourceType: _vm.resourceType,
            goods: _vm.goods,
            show: _vm.uploadVisible
          },
          on: {
            "update:show": function($event) {
              _vm.uploadVisible = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-number-view" }, [
      _c("span", { staticClass: "having" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("div", { staticClass: "steps-number-line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-text-item havingText" }, [
      _c("span", [_vm._v("步骤 1")]),
      _vm._v(" "),
      _c("span", [_vm._v("设置作品的基本信息等")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }