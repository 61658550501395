var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-section" },
    [
      _c("div", { staticClass: "section-title" }, [_vm._v("点学区")]),
      _vm._v(" "),
      _c("sectionList", { staticClass: "section_list" }),
      _vm._v(" "),
      _c("page-audio", { ref: "page_audio", staticClass: "page_audio" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }