var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "are-table-container" },
    [
      _c(
        "div",
        { staticClass: "header-bg" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addBtn } },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c("search-user", {
            staticClass: "merchant-bg",
            on: { receivepartnerId: _vm.receivePartnerId }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "area-table-body"
        },
        [
          _c(
            "el-table",
            {
              ref: "bookAreaList",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sequence", label: "排序", "min-width": "3" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "code", label: "Code", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "地区", "min-width": "12" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "partnerName", label: "商户", "min-width": "15" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "37", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "removeBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeBtn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-box-bg" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getBookAreaList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
            width: "526px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialogBody" },
            [
              _c(
                "el-form",
                {
                  ref: "formName",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.bookAreaForm,
                    rules: _vm.rules
                  }
                },
                [
                  _vm.dialogTitltText === "编辑地区"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Code" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.bookAreaForm.code,
                              callback: function($$v) {
                                _vm.$set(_vm.bookAreaForm, "code", $$v)
                              },
                              expression: "bookAreaForm.code"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: 90,
                          placeholder: "请填写地区",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.bookAreaForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.bookAreaForm, "name", $$v)
                          },
                          expression: "bookAreaForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "商户" } }, [
                    _c(
                      "div",
                      { staticClass: "merchant-box" },
                      [
                        _c("search-user", {
                          staticClass: "merchant-bg2",
                          attrs: {
                            placeholder: "选择商户",
                            partnerOpenId: _vm.bookAreaForm.partnerOpenId
                          },
                          on: { receivepartnerId: _vm.selectPartnerId }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "merchant-tip" }, [
                          _c("i", { staticClass: "el-icon-info" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("空置商户则视为系统地区。")])
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "排序" } }, [
                    _c(
                      "div",
                      { staticClass: "sequence-box" },
                      [
                        _c("el-input-number", {
                          staticClass: "select-sequence",
                          staticStyle: { width: "100px" },
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 0,
                            "controls-position": "right"
                          },
                          model: {
                            value: _vm.bookAreaForm.sequence,
                            callback: function($$v) {
                              _vm.$set(_vm.bookAreaForm, "sequence", $$v)
                            },
                            expression: "bookAreaForm.sequence"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.editDialogVisible = false
                                  }
                                }
                              },
                              [_vm._v("取 消")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.submitSyncLoading
                                },
                                on: { click: _vm.submitSave }
                              },
                              [_vm._v("确 定")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }