"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _priceParser = require("@taozhi/price-parser");

var _splinter = require("@/utils/splinter");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_P_QuickReview_ContentItem',
  props: {
    title: {
      type: String,
      default: undefined
    },
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    diffKey: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      priceToYuan: _priceParser.priceToYuan
    };
  },
  computed: {
    imageList: function imageList() {
      try {
        return JSON.parse(this.data.atlasJson);
      } catch (e) {
        return [];
      }
    },
    isSuitDisplay: function isSuitDisplay() {
      return this.data.isSuit ? '是' : '否';
    },
    quotaDisplay: function quotaDisplay() {
      return this.data.quota > 0 ? this.data.quota : '不限购';
    },
    paymentMethodDisplay: function paymentMethodDisplay() {
      var pm = [];

      if (this.data.isExpress) {
        pm.push('快递发货');
      }

      if (this.data.isPickup) {
        pm.push('到店自提');
      }

      return pm.join('、');
    }
  },
  methods: {
    getImgUrlWl64: function getImgUrlWl64(img) {
      return (0, _splinter.getImgUrl)(img, 'width-limit-64');
    },
    getItemClass: function getItemClass(key) {
      return {
        active: this.diffKey.includes(key)
      };
    },
    textParser: function textParser(test) {
      if (typeof test !== 'string') {
        return '　';
      }

      if (test.trim().length < 1) {
        return '　';
      }

      return test;
    }
  }
};
exports.default = _default2;