var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addressWrap" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "tableDataList"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 12 } },
            [
              _vm._l(_vm.tableData, function(item, index) {
                return _c(
                  "el-col",
                  { key: index, attrs: { span: 6 } },
                  [
                    _c("address-detail", {
                      attrs: { item: item },
                      on: {
                        editAddress: _vm.editAddress,
                        deleteAddress: _vm.deleteAddress
                      }
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-card", { attrs: { shadow: "hover" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "addUserAddress",
                        on: { click: _vm.addUserAddress }
                      },
                      [
                        _c("span", [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v("新建")
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "deletedAddress",
          attrs: {
            "append-to-body": "",
            visible: _vm.deleteAddressDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "移除用户地址",
            width: "352px"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteAddressDialog = $event
            }
          }
        },
        [
          _c("span", [_vm._v("即将移除此地址。")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.deleteAddressDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.deleteAddressHandle("ruleForm")
                    }
                  }
                },
                [_vm._v("移除")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("user-address", {
        attrs: {
          visible: _vm.dialogFormVisible,
          ruleForm: _vm.ruleForm,
          isEditAddress: _vm.isEditAddress,
          userOpenId: _vm.userOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogFormVisible = $event
          },
          refreshList: _vm.refreshList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }