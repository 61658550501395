"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPriceConfig = createPriceConfig;
exports.parsePriceData = parsePriceData;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _collect = _interopRequireDefault(require("collect.js"));

var _priceParser = require("@taozhi/price-parser");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function createPriceConfig(c) {
  return _objectSpread({
    title: '',
    // 显示在左侧的标题
    enable: false,
    // 是否启用
    type: 1,
    // 输入类型。1 折扣 2 价格。
    active: 1,
    // 选中的类型
    price: 0,
    // 价格
    discount: 1,
    // 折扣
    priceReduction: 0
  }, c);
}

function parsePriceData(d) {
  return (0, _collect.default)(d).map(function (i) {
    return {
      title: i.title,
      enable: i.enable,
      type: i.type,
      active: i.active,
      price: (0, _priceParser.parsePriceFromYuan)(i.price),
      discount: (0, _priceParser.parseDiscountFromPercent)(i.discount),
      priceReduction: (0, _priceParser.parsePriceFromYuan)(i.priceReduction)
    };
  }).all();
}