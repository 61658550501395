var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量添加角色",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody-box" }, [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c("div", { staticClass: "upload-box " }, [
                  _c(
                    "div",
                    { staticClass: "upload-img" },
                    [
                      _c("input", {
                        ref: "myUpload",
                        staticClass: "file-upload",
                        attrs: {
                          id: "myUpload",
                          multiple: "multiple",
                          type: "file",
                          accept: "image/*",
                          disabled: _vm.dialogLoading
                        },
                        on: { change: _vm.handleChange }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.dialogLoading }
                        },
                        [_vm._v("选择图片")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-title",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("支持jpg / png格式，小于2M（一次上传最多200张）")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.imageList }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "图片" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "状态", align: "center", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.status === 0
                                ? _c("i", [_vm._v("--")])
                                : scope.row.status === -1
                                ? _c("img", {
                                    staticClass: "iconClass",
                                    attrs: {
                                      src: require("@/assets/ic_error.png")
                                    }
                                  })
                                : scope.row.status === 1
                                ? _c("img", {
                                    staticClass: "iconClass",
                                    attrs: {
                                      src: require("@/assets/ic_ok.png")
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { width: "100", align: "center", label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.status === -1
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-upload2 el-icon-cursor",
                                    staticStyle: { "margin-right": "10px" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete el-icon-cursor",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteItem(scope)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-footer" }, [
            _c("div", { staticClass: "footer-left" }, [
              _c("div", { staticClass: "step-flag step-on" }, [_vm._v("1")]),
              _vm._v(" "),
              _c("div", { staticClass: "step-content" }, [_vm._v("图片上传")]),
              _vm._v(" "),
              _c("div", { staticClass: "line-link" }),
              _vm._v(" "),
              _c("div", { staticClass: "step-flag step-off" }, [_vm._v("2")]),
              _vm._v(" "),
              _c("div", { staticClass: "step-content" }, [_vm._v("角色编辑")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消")
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.dialogLoading },
                    on: { click: _vm.submitUpload }
                  },
                  [
                    _vm.dialogState === 0
                      ? _c("span", [_vm._v("上 传")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dialogState === 1
                      ? _c("span", [_vm._v("下一步")])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }