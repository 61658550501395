"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.erpStoreSave = erpStoreSave;
exports.erpStoreRoomList = erpStoreRoomList;
exports.storeroomNotUsed = storeroomNotUsed;
exports.erpStoreRoomDelete = erpStoreRoomDelete;
exports.storeroomList = storeroomList;
exports.storeroomSave = storeroomSave;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/admin/retail/"));
var requestMP = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/mp/retail/"));
/* 新增修改erp仓库 */

function erpStoreSave(data) {
  return request.put('erpStoreRoom/save', data);
}
/* 查询全部erp仓库 */


function erpStoreRoomList() {
  return request.get('erpStoreRoom/list');
}
/* 未使用的仓库列表 */


function storeroomNotUsed() {
  return requestMP.get('partner/storeroom/notUsed');
}
/* 删除erp仓库 */


function erpStoreRoomDelete(data) {
  return request.put('erpStoreRoom/delete', data);
}
/* 查询商户仓库列表 */


function storeroomList(params) {
  return requestMP.get('partner/storeroom/list', {
    params: params
  });
}
/* 维护商户仓库 */


function storeroomSave(data) {
  return requestMP.post('partner/storeroom/save', data);
}