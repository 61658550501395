var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "gxContailer",
      attrs: { visible: _vm.dialogDetails, title: "提现详情", width: "80%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogDetails = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          staticClass: "contailer"
        },
        [
          _vm.cashInfo
            ? _c("div", [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "titleBox" }, [
                      _c("span", { staticClass: "icon" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "titleText" }, [
                        _vm._v("基本信息")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("流水编号：" + _vm._s(_vm.cashInfo.openId))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "提现时间：" +
                                _vm._s(
                                  _vm._f("parseTime")(
                                    _vm.cashInfo.withdrawRequestedAt,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "提现人：" +
                                _vm._s(
                                  _vm.cashInfo.user.displayName
                                    ? _vm.cashInfo.user.displayName
                                    : _vm.cashInfo.user.nickName
                                )
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.cashInfo.withdrawChannel === "银行卡"
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "titleBox" }, [
                          _c("span", { staticClass: "icon" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "titleText" }, [
                            _vm._v("提现账号")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v("提现渠道：银行卡")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "银行交易流水编号：" +
                                    _vm._s(
                                      _vm.cashInfo.channelOrderId
                                        ? _vm.cashInfo.channelOrderId
                                        : "--"
                                    )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "交易银行：" +
                                    _vm._s(_vm.cashInfo.withdrawBankName)
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "银行卡号：" +
                                    _vm._s(
                                      _vm._f("bankCardNumberFilters")(
                                        _vm.cashInfo.withdrawBankCardNumber
                                      )
                                    )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "银行户名：" +
                                    _vm._s(_vm.cashInfo.withdrawBankCardName)
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "开户行：" +
                                    _vm._s(_vm.cashInfo.withdrawBankSubbranch)
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "titleBox" }, [
                          _c("span", { staticClass: "icon" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "titleText" }, [
                            _vm._v("发票信息")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "提供发票：" +
                              _vm._s(
                                _vm.cashInfo.invoiceDeliveryMethod
                                  ? _vm.cashInfo.invoiceDeliveryMethod
                                  : "--"
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _vm.cashInfo.invoiceDeliveryMethod === "电子发票"
                          ? _c(
                              "div",
                              { staticClass: "imageBox" },
                              [
                                _vm.srcList[0]
                                  ? _c("el-image", {
                                      staticClass: "invoiceImage",
                                      attrs: {
                                        "preview-src-list": _vm.srcListOne,
                                        src: _vm.getImgUrl(_vm.srcList[0]),
                                        fit: "cover"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.srcList[1]
                                  ? _c("el-image", {
                                      staticClass: "invoiceImage",
                                      attrs: {
                                        "preview-src-list": _vm.srcListTwo,
                                        src: _vm.getImgUrl(_vm.srcList[1]),
                                        fit: "cover"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "titleBox" }, [
                          _c("span", { staticClass: "icon" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "titleText" }, [
                            _vm._v("提现账号")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v("提现渠道：微信")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "微信交易流水编号：" +
                                    _vm._s(
                                      _vm.cashInfo.channelOrderId
                                        ? _vm.cashInfo.channelOrderId
                                        : "--"
                                    )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" })
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "text flexText" },
                                [
                                  _c("span", [_vm._v("微信帐号：")]),
                                  _vm._v(" "),
                                  _c("el-image", {
                                    staticClass: "coverImage",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        _vm.cashInfo.finance.withdrawWeChatCover
                                      ),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cashInfo.finance
                                          .withdrawWeChatNickName
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "姓名：" +
                                    _vm._s(_vm.cashInfo.finance.withdrawName)
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "身份证号：" +
                                    _vm._s(
                                      _vm.cashInfo.finance.withdrawIdNumber
                                    )
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                _vm._v(" "),
                _vm.partnerInfo
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "titleBox" }, [
                          _c("span", { staticClass: "icon" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "titleText" }, [
                            _vm._v("运营者信息")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "运营者姓名：" +
                                    _vm._s(_vm.partnerInfo.operatorName)
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "运营者手机号：" +
                                    _vm._s(_vm.partnerInfo.operatorPhone)
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "运营者身份证号：" +
                                    _vm._s(_vm.partnerInfo.operatorIdNumber)
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "titleBox" }, [
                    _c("span", { staticClass: "icon" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "titleText" }, [
                      _vm._v("提现状态")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "提现状态：" +
                        _vm._s(
                          _vm._f("parseTime")(
                            _vm.cashInfo.updatedAt,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        ) +
                        " " +
                        _vm._s(
                          _vm._f("filterStatus")(_vm.cashInfo.withdrawStatus)
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.cashInfo.withdrawFailReason
                    ? _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "失败原因：" + _vm._s(_vm.cashInfo.withdrawFailReason)
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "footText" }, [
                  _c("div", { staticClass: "text moneyText" }, [
                    _vm._v("实际提现金额：￥"),
                    _c("span", { staticClass: "moneyNumber" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("financeFilters")(
                            (_vm.cashInfo.withdrawBalance / 100).toFixed(2)
                          )
                        ) + "元"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "提现前余额：￥" +
                        _vm._s(
                          _vm._f("financeFilters")(
                            (_vm.cashInfo.balanceBefore / 100).toFixed(2)
                          )
                        ) +
                        "元\n        |\n        提现金额：￥" +
                        _vm._s(
                          _vm._f("financeFilters")(
                            (_vm.cashInfo.withdrawBalancePreTax / 100).toFixed(
                              2
                            )
                          )
                        ) +
                        "元\n        |\n        扣税金额：￥" +
                        _vm._s(
                          _vm._f("financeFilters")(
                            (
                              _vm.cashInfo.withdrawBalancePreTax / 100 -
                              _vm.cashInfo.withdrawBalance / 100
                            ).toFixed(2)
                          )
                        ) +
                        "元"
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }