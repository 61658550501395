var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择仓库",
        visible: _vm.storeVisible,
        width: "800px",
        "before-close": _vm.storeClose
      },
      on: {
        "update:visible": function($event) {
          _vm.storeVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "dia_body" },
        [
          _c(
            "el-table",
            {
              ref: "storeTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.storeList },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "storeroomOpenId", label: "仓库id" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "仓库名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "收货人" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userPhone", label: "联系电话" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "收货地址" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.storeClose } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }