var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-title", attrs: { prop: "name" } },
    [
      _c("label", { staticClass: "not-required" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "drag-box",
          staticStyle: { width: "86%", display: "flex", position: "relative" }
        },
        [
          _c(
            "draggable",
            {
              staticClass: "menu-list rich",
              attrs: { list: _vm.dragData, move: _vm.move, disabled: false },
              on: {
                start: function($event) {
                  return _vm.start()
                },
                end: _vm.end
              }
            },
            _vm._l(_vm.dragData, function(item, index) {
              return _c("div", { key: index, staticClass: "select-word" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "4px 10px", color: "#909399" } },
                  [_c("span", [_vm._v(_vm._s(item.name))])]
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-error",
                  on: {
                    click: function($event) {
                      return _vm.remove(index)
                    }
                  }
                })
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.dragData.length <= 0
            ? _c("span", { staticClass: "placeholder" }, [
                _vm._v(_vm._s(_vm.placeholder))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-btn", on: { click: _vm.openBox } },
            [
              _c("el-image", {
                attrs: {
                  src: require("../../assets/images/md-add_to_photos.svg")
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.deskDialogShow
        ? _c("selectFrontDesk", {
            attrs: {
              type: _vm.type,
              visible: _vm.deskDialogShow,
              placeholder: _vm.searchPlaceholder,
              deskDialogShow: _vm.deskDialogShow,
              replaceMap: _vm.replaceMap,
              screeHeight: _vm.screeHeight,
              checkList: _vm.checkList,
              title: _vm.handle,
              showType: "add"
            },
            on: {
              getSelectWord: _vm.getSelectWord,
              getReplace: _vm.getReplace,
              "change-show": _vm.parentEvent,
              "update:visible": function($event) {
                _vm.deskDialogShow = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }