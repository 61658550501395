var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            staticClass: "selectProductButton",
            on: {
              click: function($event) {
                _vm.outerVisible = true
              }
            }
          },
          [_vm._v("选择数字商品")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "selectProductButton",
            on: {
              click: function($event) {
                _vm.outerPhysicalObjectVisible = true
              }
            }
          },
          [_vm._v("选择实物商品")]
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticClass: "tz-pcc-table combination-products-table",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.valueProxy,
              "row-key": "openId",
              "header-cell-style": _vm.myTableHeaderCellStyle,
              border: "",
              "cell-class-name": _vm.elTableCellClassName
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("div", { staticClass: "tz-pcc-table-total" }, [
                      _vm._v(
                        "\n          合计：" +
                          _vm._s(_vm.sumPrice) +
                          "\n        "
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _c("el-table-column", {
              attrs: { prop: "title", label: "商品名称", "min-width": "180" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "productType",
                label: "类型",
                width: "180",
                formatter: _vm.productTypeFormatter,
                align: "center"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "isHasSpecific",
                label: "规格",
                width: "180",
                formatter: _vm.isHasSpecificFormatter,
                align: "center"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "orginalPrice",
                label: "售价",
                width: "180",
                formatter: _vm.priceFormatter,
                align: "center"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "price",
                label: "折扣",
                width: "180",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("price-input-discount", {
                        model: {
                          value: scope.row.price,
                          callback: function($$v) {
                            _vm.$set(scope.row, "price", $$v)
                          },
                          expression: "scope.row.price"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "price",
                label: "折后金额",
                width: "180",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.productType === 1 && scope.row.isHasSpecific
                        ? _c("span", [_vm._v("--")])
                        : _c("price-input", {
                            attrs: { "orginal-price": scope.row.orginalPrice },
                            model: {
                              value: scope.row.price,
                              callback: function($$v) {
                                _vm.$set(scope.row, "price", $$v)
                              },
                              expression: "scope.row.price"
                            }
                          })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", width: "180", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var $index = ref.$index
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-edit",
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.toRemove($index)
                            }
                          }
                        },
                        [_vm._v("移除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialogs" },
      [
        _vm.outerVisible
          ? _c("select-resource-dialog", {
              attrs: {
                visible: _vm.outerVisible,
                "exclude-data": _vm.excludeData,
                showMerchant: true,
                "expect-type": "productProductContent"
              },
              on: {
                "update:visible": function($event) {
                  _vm.outerVisible = $event
                },
                "add-select": _vm.addgiftevent
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.outerPhysicalObjectVisible
          ? _c("combo-add-physical-items", {
              attrs: { "exclude-data": _vm.excludeData },
              on: { "on-select": _vm.addComboGiftEvent },
              model: {
                value: _vm.outerPhysicalObjectVisible,
                callback: function($$v) {
                  _vm.outerPhysicalObjectVisible = $$v
                },
                expression: "outerPhysicalObjectVisible"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }