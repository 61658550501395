var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-dialog",
      attrs: {
        "custom-class": "ppop-up-dialog",
        width: "980px",
        visible: _vm.visible,
        title: _vm.title,
        "close-on-click-modal": false,
        "show-close": !_vm.loading
      },
      on: { close: _vm.onCancel }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.valueSync,
            rules: _vm.rules,
            disabled: _vm.loading,
            "label-width": "90px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "系列名称", prop: "title" } },
            [
              _c("el-input", {
                staticClass: "add-input",
                attrs: { placeholder: "请输入系列名称", clearable: "" },
                model: {
                  value: _vm.valueSync.title,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "title", $$v)
                  },
                  expression: "valueSync.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属品牌", prop: "brandOpenId" } },
            [
              _c("sync-info", {
                staticClass: "add-input",
                attrs: {
                  placeholder: "请选择品牌方",
                  "data-source": _vm.getBrandListCached,
                  "label-name": "title",
                  "value-name": "brandOpenId",
                  filterable: true,
                  remote: true,
                  "remote-api": _vm.getBrandListSearch
                },
                model: {
                  value: _vm.valueSync.brandOpenId,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "brandOpenId", $$v)
                  },
                  expression: "valueSync.brandOpenId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属学段", prop: "sectionCode" } },
            [
              _c("sync-info", {
                staticClass: "add-input",
                attrs: {
                  filterable: true,
                  placeholder: "学段",
                  disabled: false,
                  "data-source": _vm.getSyncSection
                },
                model: {
                  value: _vm.valueSync.sectionCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "sectionCode", $$v)
                  },
                  expression: "valueSync.sectionCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品图集", prop: "atlasJson" } },
            [
              _c("image-wall", {
                attrs: {
                  disabledEdit: false,
                  coverArr: _vm.coverArr,
                  atlasArrText: _vm.atlasArr,
                  "oss-upload-function": _vm.ossUploadFunction,
                  path: _vm.atlasImagePath,
                  "preview-z-index": 3000
                },
                model: {
                  value: _vm.valueSync.atlasJson,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "atlasJson", $$v)
                  },
                  expression: "valueSync.atlasJson"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "主图视频", prop: "showVideo" } },
            [
              _c(
                "div",
                {
                  staticClass: "card-lz-content upload-video-main-image-video"
                },
                [
                  _c("video-uploader", {
                    ref: "uploadVideo",
                    attrs: {
                      disabledEdit: false,
                      cover: _vm.valueSync.showVideoCover
                    },
                    model: {
                      value: _vm.valueSync.showVideo,
                      callback: function($$v) {
                        _vm.$set(_vm.valueSync, "showVideo", $$v)
                      },
                      expression: "valueSync.showVideo"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "display-flex video-duration-text" },
                    [
                      _c("span", { staticClass: "video-text" }, [
                        _c("i", {
                          staticClass: "el-icon-info",
                          staticStyle: { color: "#409EFF" }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            建议时长 9-30 秒，建议视频宽高比 16:9\n          "
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品详情", prop: "detail" } },
            [
              _c("rich-text-editor", {
                ref: "rtx",
                staticClass: "detial-editor",
                attrs: { readonly: false },
                model: {
                  value: _vm.valueSync.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "detail", $$v)
                  },
                  expression: "valueSync.detail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sequence" } },
            [
              _c("el-input-number", {
                staticClass: "sequence",
                attrs: { precision: 0, "controls-position": "right" },
                model: {
                  value: _vm.valueSync.sequence,
                  callback: function($$v) {
                    _vm.$set(_vm.valueSync, "sequence", $$v)
                  },
                  expression: "valueSync.sequence"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { disabled: _vm.loading }, on: { click: _vm.onCancel } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onConfirm }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }