var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-line" },
    [
      _c("el-input-number", {
        attrs: {
          "controls-position": "right",
          disabled: _vm.disableCell,
          min: 0,
          precision: _vm.precision
        },
        model: {
          value: _vm.valueProxy,
          callback: function($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }