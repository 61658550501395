var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "giveBooks" },
    [
      _c(
        "div",
        { staticClass: "giveBooksTable", attrs: { type: "border-card" } },
        [
          _c("div", { staticClass: "topBtn" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.addBookOrder }
                  },
                  [_vm._v("添加")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("search-user", { on: { receiveuserid: _vm.receiveuserid } }),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "searchInput",
                  staticStyle: { "margin-right": "10px" },
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "订单编号或者手机号"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchContent($event)
                    }
                  },
                  model: {
                    value: _vm.query.keyWord,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "keyWord", $$v)
                    },
                    expression: "query.keyWord"
                  }
                }),
                _vm._v(" "),
                _c(
                  "search-btn",
                  { on: { searchContent: _vm.searchContent } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.query, "label-width": "100px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "下单时间" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.pickerOptions,
                                type: "datetimerange",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                align: "right"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.getlistApiinDate(_vm.dateValue)
                                }
                              },
                              model: {
                                value: _vm.dateValue,
                                callback: function($$v) {
                                  _vm.dateValue = $$v
                                },
                                expression: "dateValue"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "赠书类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    return _vm.giveBooksTypeChange(
                                      _vm.query.bookType
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.query.bookType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "bookType", $$v)
                                  },
                                  expression: "query.bookType"
                                }
                              },
                              _vm._l(_vm.bookTypes, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "350px" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    return _vm.orderStatusChange(
                                      _vm.query.status
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.query.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "status", $$v)
                                  },
                                  expression: "query.status"
                                }
                              },
                              _vm._l(_vm.orderStatusArr, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "tablewrapCon"
            },
            [
              _c(
                "el-table",
                {
                  ref: "tablewrap",
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    height: "0"
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", { attrs: { type: "selection" } }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单编号",
                      prop: "giftBookOpenId",
                      width: "250"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "赠书类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.giftBookType == ""
                              ? _c("div", [_vm._v("全部")])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.showVal(scope.row.giftBookType))
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "checkAuditor",
                      label: "用户",
                      width: "200"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticClass: "userCover",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        scope.row.userCover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover",
                                      alt: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture-outline"
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.userName) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "收货人", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.showVal(scope.row.receiverName))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "手机号", width: "110" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.showVal(scope.row.receiverPhone))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status === 0
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#F56C6C" } },
                                  [_vm._v("待发货")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status === 1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#0298FA" } },
                                  [_vm._v("已发货")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "物流单号", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.showVal(scope.row.expressOrder))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "备注信息", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.remarks,
                                  placement: "top"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "white-space": "nowrap",
                                      width: "200px",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.showVal(scope.row.remarks))
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "150",
                      fixed: "right",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#67C23A" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.refreshOrder(scope.row)
                                  }
                                }
                              },
                              [_vm._v("刷新")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.supplementOrder(scope.row)
                                  }
                                }
                              },
                              [_vm._v("补单")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "btnBottom" }, [
            _c(
              "div",
              [
                _c("el-checkbox", {
                  staticClass: "pagination-check",
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleSelectedAll },
                  model: {
                    value: _vm.selectedAll,
                    callback: function($$v) {
                      _vm.selectedAll = $$v
                    },
                    expression: "selectedAll"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 0 0 10px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.batchrefresh }
                  },
                  [_vm._v("批量刷新")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.query.page,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _vm.query.size,
                    total: _vm.totalElements,
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper"
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.addgiftBookload,
              expression: "addgiftBookload"
            }
          ],
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogVisible,
            width: "70%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("赠书订单")
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              staticStyle: { height: "600px", overflow: "auto" },
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "commonTitle" }, [
                    _vm._v("赠书信息")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加图书", prop: "goodsId" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "600px" },
                              attrs: {
                                clearable: "",
                                "popper-class": "hideUserSty",
                                filterable: "",
                                placeholder: "请输入图书名称",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loadingfenye
                              },
                              on: { "visible-change": _vm.gettest },
                              model: {
                                value: _vm.goodsId,
                                callback: function($$v) {
                                  _vm.goodsId = $$v
                                },
                                expression: "goodsId"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "infinite-scroll",
                                      rawName: "v-infinite-scroll",
                                      value: _vm.loadScroll,
                                      expression: "loadScroll"
                                    }
                                  ],
                                  staticStyle: {
                                    height: "200px",
                                    overflow: "auto"
                                  },
                                  attrs: {
                                    "infinite-scroll-disabled": "disabled"
                                  }
                                },
                                _vm._l(_vm.list, function(item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.goodsName,
                                        value: item.goodsId
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { width: "100%" },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                   " +
                                              _vm._s(item.goodsName) +
                                              "\n                 "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _vm.scrollStaus
                                ? _c("p", { staticClass: "commonPSty" }, [
                                    _vm._v("加载数据中...")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.noMore
                                ? _c("p", { staticClass: "commonPSty" }, [
                                    _vm._v("没有更多了")
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.bookDetailList.length
                        ? _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "15px"
                              },
                              attrs: {
                                data: _vm.bookDetailList,
                                "max-height": "200",
                                "header-cell-style": { background: "#E5F5FF" },
                                border: ""
                              }
                            },
                            [
                              _c(
                                "el-table-column",
                                {
                                  attrs: { width: "80px", align: "center" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("svg-icon", {
                                              staticClass: "iconSty",
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                "icon-class": "removeBook"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeBook(
                                                    scope.$index,
                                                    _vm.bookDetailList
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1641831223
                                  )
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "header" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "iconSty",
                                        attrs: { "icon-class": "removeBook" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "bookName",
                                  label: "书本名称",
                                  width: "auto"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "commonTitle" }, [_vm._v("基础信息")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "选择用户", prop: "userOpenId" } },
                      [
                        _c("search-user", {
                          ref: "searchUserCom",
                          attrs: { userOpenId: _vm.ruleForm.userOpenId },
                          on: { receiveuserid: _vm.receiveuseridAddbook }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "赠书类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.ruleForm.giftBookType,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "giftBookType", $$v)
                              },
                              expression: "ruleForm.giftBookType"
                            }
                          },
                          _vm._l(_vm.bookTypes, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.title, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "commonTitle" }, [
                    _vm._v("地址信息")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货人", prop: "receiverName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.ruleForm.receiverName,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "receiverName", $$v)
                              },
                              expression: "ruleForm.receiverName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "receiverPhone" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { maxlength: "11", "show-word-limit": "" },
                            model: {
                              value: _vm.ruleForm.receiverPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "receiverPhone", $$v)
                              },
                              expression: "ruleForm.receiverPhone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择地区", prop: "county" } },
                    [
                      _c("address-area-select", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.ruleForm,
                          callback: function($$v) {
                            _vm.ruleForm = $$v
                          },
                          expression: "ruleForm"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址", prop: "detailAddr" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入填写地址"
                        },
                        model: {
                          value: _vm.ruleForm.detailAddr,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "detailAddr", $$v)
                          },
                          expression: "ruleForm.detailAddr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "commonTitle" }, [
                    _vm._v("备注信息")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remarks" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入备注" },
                        model: {
                          value: _vm.ruleForm.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "remarks", $$v)
                          },
                          expression: "ruleForm.remarks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisibleOrderDetail, width: "55%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleOrderDetail = $event
            }
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("订单详情")
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "flex" } }, [
            _vm.orderDetail && _vm.orderDetail.userCover
              ? _c(
                  "div",
                  [
                    _c(
                      "el-image",
                      {
                        staticClass: "userimg",
                        attrs: {
                          src: _vm.getImgUrl(
                            _vm.orderDetail.userCover,
                            _vm.thumbnailStyle
                          ),
                          fit: "cover"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [
                            _c("img", {
                              staticClass: "userimg",
                              attrs: { src: require("@/assets/book_cover.png") }
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-around"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("span", { staticStyle: { "font-size": "18px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.showVal(
                            _vm.orderDetail && _vm.orderDetail.userName
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "vipImg",
                      attrs: {
                        src: require("@/assets/images/VIP.png"),
                        alt: ""
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("span", [
                    _vm._v(
                      "用户ID：" +
                        _vm._s(
                          _vm.showVal(
                            _vm.orderDetail && _vm.orderDetail.userOpenId
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "commonFlex" }, [
              _c("div", { staticClass: "line" }),
              _vm._v(" "),
              _c("span", { staticClass: "secondTitle" }, [_vm._v("订单信息")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commonFlexSty commonLineh" }, [
              _c("div", { staticClass: "commonWidth" }, [
                _vm._v(
                  "姓名：" + _vm._s(_vm.showVal(_vm.orderDetail.receiverName))
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "物流公司：" +
                    _vm._s(_vm.showVal(_vm.orderDetail.expressCompany))
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "commonFlexSty commonLineh" }, [
              _c("div", { staticClass: "commonWidth" }, [
                _vm._v(
                  "手机号码：" +
                    _vm._s(_vm.showVal(_vm.orderDetail.receiverPhone))
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "物流编号：" +
                    _vm._s(_vm.showVal(_vm.orderDetail.expressOrder))
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonFlexSty commonLineh" }, [
            _c("div", { staticClass: "commonWidth" }, [
              _vm._v(
                "详细地址：" + _vm._s(_vm.showVal(_vm.orderDetail.detailAddr))
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "发货时间：" + _vm._s(_vm.showVal(_vm.orderDetail.deliverAt))
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonFlexSty commonLineh" }, [
            _c("div", { staticClass: "commonWidth" }, [
              _vm._v(
                "订单编号：" +
                  _vm._s(_vm.showVal(_vm.orderDetail.giftBookOpenId))
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("备注：" + _vm._s(_vm.showVal(_vm.orderDetail.remarks)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonFlexSty commonLineh" }, [
            _c("div", [
              _vm._v(
                "\n        电商订单编号：" +
                  _vm._s(_vm.showVal(_vm.orderDetail.dsOrderOpenId)) +
                  "\n        "
              ),
              _c("span", {
                staticClass: "copy-btn",
                on: {
                  click: function($event) {
                    return _vm.copyOpenId(_vm.orderDetail.dsOrderOpenId, $event)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commonFlex" }, [
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _c("span", { staticClass: "secondTitle" }, [_vm._v("所赠图书")])
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
                overflow: "hidden",
                "border-bottom": "1px solid #dfe6ec"
              },
              attrs: {
                data: _vm.orderDetail.bookDetailList,
                height: "200",
                "header-cell-style": { background: "#E5F5FF" },
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "bookName", label: "书本名称", width: "auto" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "quantity", label: "数量", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.showVal(scope.row.quantity)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleOrderDetail = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }