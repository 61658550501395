"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _list = _interopRequireDefault(require("@/views/product/product/list.vue"));

var _brandSide = _interopRequireDefault(require("@/views/product/brandSide.vue"));

var _productSetManagement = _interopRequireDefault(require("@/views/marketplaceSettings/productSetManagement.vue"));

var _default = {
  name: 'searchGoods',
  components: {
    PMS_productSetManagement: _productSetManagement.default,
    P_brandSide: _brandSide.default,
    ProductList: _list.default
  },
  data: function data() {
    return {
      activeName: '1'
    };
  },
  methods: {
    handleClick: function handleClick() {}
  }
};
exports.default = _default;