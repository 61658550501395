var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "lock-scroll": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": "",
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            width: "740px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                color: "#303133",
                "font-size": "18px",
                "margin-bottom": "20px"
              },
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v("商户资质")]
          ),
          _vm._v(" "),
          _c("edit-mearchant-info", {
            attrs: {
              partnerOpenId: _vm.partnerOpenId,
              userOpenId: _vm.userOpenId,
              newOr: _vm.newOr
            },
            on: {
              closeDialog: _vm.closeDialog,
              closeDialogrefreshMerchantInfo: _vm.closeDialogrefreshMerchantInfo
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }