var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "emoji", staticClass: "emoji" },
    [
      _c(
        "el-carousel",
        {
          attrs: {
            height: "110px",
            autoplay: false,
            arrow: "never",
            loop: false,
            trigger: "click",
            "indicator-position": "outside"
          }
        },
        _vm._l(_vm.newEmojiList, function(item, index) {
          return _c(
            "el-carousel-item",
            { key: index, staticClass: "emoji-list" },
            _vm._l(item, function(img, imgIndex) {
              return _c("div", { key: imgIndex, staticClass: "emoji-item" }, [
                _c("img", {
                  staticStyle: { width: "30px", height: "30px" },
                  attrs: { title: img.alt, alt: img.alt, src: img.src },
                  on: {
                    click: function($event) {
                      return _vm.selectEmoji(img.alt)
                    }
                  }
                })
              ])
            }),
            0
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }