var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("product-list", {
    ref: "myList",
    attrs: {
      loading: _vm.loading,
      query: _vm.query,
      data: _vm.data,
      total: _vm.total,
      "content-height": "calc(92vh - 268px)",
      select: _vm.selectProxy
    },
    on: {
      "update:query": function($event) {
        _vm.query = $event
      },
      "update:select": function($event) {
        _vm.selectProxy = $event
      },
      "update-data": _vm.updateData
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }