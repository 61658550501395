"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var modelEssay = {
  state: {
    isHaveAudio: undefined,
    subjectName: undefined,
    modelTabName: undefined,
    modelTabLabel: undefined,
    modelTreeCode: undefined,
    manuScriptTreeCode: undefined,
    editionCode: undefined,
    manuScriptEditionCode: undefined,
    manuScriptEditionIndex: undefined,
    editionIndex: undefined,
    themeCode: undefined,
    themeIndex: undefined,
    priceCheck: undefined,
    awardTreeGroupCode: undefined,
    awardTreeAwardCode: undefined,
    filterModelName: undefined,
    filterManuScriptName: undefined,
    searchText: undefined,
    manuScriptSearchText: undefined,
    gradeCode: undefined,
    manuScriptGradeCode: undefined,
    volumeCode: undefined,
    manuScriptVolumeCode: undefined,
    unitCode: undefined,
    manuScriptUnitCode: undefined,
    relationProvinceCode: undefined,
    manuScriptRelationProvinceCode: undefined,
    relationCityCode: undefined,
    manuScriptRelationCityCode: undefined,
    relationDistrictCode: undefined,
    manuScriptRelationDistrictCode: undefined,
    schoolCode: undefined,
    manuScriptSchoolCode: undefined,
    schoolName: undefined,
    manuScriptSchoolName: undefined,
    manuScriptType: undefined,
    manuScriptTypes: undefined,
    awardCode: undefined,
    manuScriptAwardCode: undefined,
    manuScriptGroupCode: undefined,
    groupCode: undefined,
    countCode: undefined,
    star: undefined,
    searchTime: undefined,
    manuScriptSearchTime: undefined,
    modelQuery: undefined,
    manuScriptQuery: undefined,
    modelTags: ''
  },
  mutations: {
    SET_SUBJECT_NAME: function SET_SUBJECT_NAME(state, data) {
      state.subjectName = data;
    },
    SET_MODEL_TAB_NAME: function SET_MODEL_TAB_NAME(state, data) {
      state.modelTabName = data;
    },
    SET_MODEL_TAB_LABEL: function SET_MODEL_TAB_LABEL(state, data) {
      state.modelTabLabel = data;
    },
    SET_MODEL_TREE_CODE: function SET_MODEL_TREE_CODE(state, data) {
      state.modelTreeCode = data;
    },
    SET_MANUSCRIPT_TREE_CODE: function SET_MANUSCRIPT_TREE_CODE(state, data) {
      state.manuScriptTreeCode = data;
    },
    SET_EDITION_CODE: function SET_EDITION_CODE(state, data) {
      state.editionCode = data;
    },
    SET_MANUSCRIPT_EDITION_CODE: function SET_MANUSCRIPT_EDITION_CODE(state, data) {
      state.manuScriptEditionCode = data;
    },
    SET_MANUSCRIPT_EDITION_INDEX: function SET_MANUSCRIPT_EDITION_INDEX(state, data) {
      state.manuScriptEditionIndex = data;
    },
    SET_EDITION_INDEX: function SET_EDITION_INDEX(state, data) {
      state.editionIndex = data;
    },
    SET_THEME_CODE: function SET_THEME_CODE(state, data) {
      state.themeCode = data;
    },
    SET_THEME_INDEX: function SET_THEME_INDEX(state, data) {
      state.themeIndex = data;
    },
    SET_PRICE_CHECK: function SET_PRICE_CHECK(state, data) {
      state.priceCheck = data;
    },
    SET_AWARD_TREE_GROUP_CODE: function SET_AWARD_TREE_GROUP_CODE(state, data) {
      state.awardTreeGroupCode = data;
    },
    SET_AWARD_TREE_AWARD_CODE: function SET_AWARD_TREE_AWARD_CODE(state, data) {
      state.awardTreeAwardCode = data;
    },
    SET_FILTER_MODEL_NAME: function SET_FILTER_MODEL_NAME(state, data) {
      state.filterModelName = data;
    },
    SET_FILTER_MANUSCRIPT_NAME: function SET_FILTER_MANUSCRIPT_NAME(state, data) {
      state.filterManuScriptName = data;
    },
    SET_SEARCH_TEXT: function SET_SEARCH_TEXT(state, data) {
      state.searchText = data;
    },
    SET_MANUSCRIPT_SEARCH_TEXT: function SET_MANUSCRIPT_SEARCH_TEXT(state, data) {
      state.manuScriptSearchText = data;
    },
    SET_GRADE_CODE: function SET_GRADE_CODE(state, data) {
      state.gradeCode = data;
    },
    SET_MANUSCRIPT_GRADE_CODE: function SET_MANUSCRIPT_GRADE_CODE(state, data) {
      state.manuScriptGradeCode = data;
    },
    SET_VOLUME_CODE: function SET_VOLUME_CODE(state, data) {
      state.volumeCode = data;
    },
    SET_MANUSCRIPT_VOLUME_CODE: function SET_MANUSCRIPT_VOLUME_CODE(state, data) {
      state.manuScriptVolumeCode = data;
    },
    SET_UNIT_CODE: function SET_UNIT_CODE(state, data) {
      state.unitCode = data;
    },
    SET_MANUSCRIPT_UNIT_CODE: function SET_MANUSCRIPT_UNIT_CODE(state, data) {
      state.manuScriptUnitCode = data;
    },
    SET_RELATION_PROVINCE_CODE: function SET_RELATION_PROVINCE_CODE(state, data) {
      state.relationProvinceCode = data;
    },
    SET_MANUSCRIPT_RELATION_PROVINCE_CODE: function SET_MANUSCRIPT_RELATION_PROVINCE_CODE(state, data) {
      state.manuScriptRelationProvinceCode = data;
    },
    SET_RELATION_CITY_CODE: function SET_RELATION_CITY_CODE(state, data) {
      state.relationCityCode = data;
    },
    SET_MANUSCRIPT_RELATION_CITY_CODE: function SET_MANUSCRIPT_RELATION_CITY_CODE(state, data) {
      state.manuScriptRelationCityCode = data;
    },
    SET_RELATION_DISTRICT_CODE: function SET_RELATION_DISTRICT_CODE(state, data) {
      state.relationDistrictCode = data;
    },
    SET_MANUSCRIPT_RELATION_DISTRICT_CODE: function SET_MANUSCRIPT_RELATION_DISTRICT_CODE(state, data) {
      state.manuScriptRelationDistrictCode = data;
    },
    SET_SCHOOL_CODE: function SET_SCHOOL_CODE(state, data) {
      state.schoolCode = data;
    },
    SET_MANUSCRIPT_SCHOOL_CODE: function SET_MANUSCRIPT_SCHOOL_CODE(state, data) {
      state.manuScriptSchoolCode = data;
    },
    SET_SCHOOL_NAME: function SET_SCHOOL_NAME(state, data) {
      state.schoolName = data;
    },
    SET_MANUSCRIPT_SCHOOL_NAME: function SET_MANUSCRIPT_SCHOOL_NAME(state, data) {
      state.manuScriptSchoolName = data;
    },
    SET_MANUSCRIPT_TYPE: function SET_MANUSCRIPT_TYPE(state, data) {
      state.manuScriptType = data;
    },
    SET_MANUSCRIPT_TYPES: function SET_MANUSCRIPT_TYPES(state, data) {
      state.manuScriptTypes = data;
    },
    SET_AWARD_CODE: function SET_AWARD_CODE(state, data) {
      state.awardCode = data;
    },
    SET_MANUSCRIPT_AWARD_CODE: function SET_MANUSCRIPT_AWARD_CODE(state, data) {
      state.manuScriptAwardCode = data;
    },
    SET_MANUSCRIPT_GROUP_CODE: function SET_MANUSCRIPT_GROUP_CODE(state, data) {
      state.manuScriptGroupCode = data;
    },
    SET_GROUP_CODE: function SET_GROUP_CODE(state, data) {
      state.groupCode = data;
    },
    SET_COUNT_CODE: function SET_COUNT_CODE(state, data) {
      state.countCode = data;
    },
    SET_IS_HAVE_AUDIO: function SET_IS_HAVE_AUDIO(state, data) {
      state.isHaveAudio = data;
    },
    SET_STAR: function SET_STAR(state, data) {
      state.star = data;
    },
    SET_SEARCH_TIME: function SET_SEARCH_TIME(state, data) {
      state.searchTime = data;
    },
    SET_MANUSCRIPT_SEARCH_TIME: function SET_MANUSCRIPT_SEARCH_TIME(state, data) {
      state.manuScriptSearchTime = data;
    },
    SET_MODEL_QUERY: function SET_MODEL_QUERY(state, data) {
      state.modelQuery = data;
    },
    SET_MANUSCRIPT_QUERY: function SET_MANUSCRIPT_QUERY(state, data) {
      state.manuScriptQuery = data;
    },
    SET_MODEL_TAGS: function SET_MODEL_TAGS(state, data) {
      state.modelTags = data;
    }
  },
  actions: {
    setSubjectName: function setSubjectName(_ref, data) {
      var commit = _ref.commit;
      commit('SET_SUBJECT_NAME', data);
    },
    setModelTabName: function setModelTabName(_ref2, data) {
      var commit = _ref2.commit;
      commit('SET_MODEL_TAB_NAME', data);
    },
    setModelTabLabel: function setModelTabLabel(_ref3, data) {
      var commit = _ref3.commit;
      commit('SET_MODEL_TAB_LABEL', data);
    },
    setEditionCode: function setEditionCode(_ref4, data) {
      var commit = _ref4.commit;
      commit('SET_EDITION_CODE', data);
    },
    setManuScriptEditionCode: function setManuScriptEditionCode(_ref5, data) {
      var commit = _ref5.commit;
      commit('SET_MANUSCRIPT_EDITION_CODE', data);
    },
    setManuScriptEditionIndex: function setManuScriptEditionIndex(_ref6, data) {
      var commit = _ref6.commit;
      commit('SET_MANUSCRIPT_EDITION_INDEX', data);
    },
    setModelTreeCode: function setModelTreeCode(_ref7, data) {
      var commit = _ref7.commit;
      commit('SET_MODEL_TREE_CODE', data);
    },
    setManuScriptTreeCode: function setManuScriptTreeCode(_ref8, data) {
      var commit = _ref8.commit;
      commit('SET_MANUSCRIPT_TREE_CODE', data);
    },
    setEditionIndex: function setEditionIndex(_ref9, data) {
      var commit = _ref9.commit;
      commit('SET_EDITION_INDEX', data);
    },
    setThemeCode: function setThemeCode(_ref10, data) {
      var commit = _ref10.commit;
      commit('SET_THEME_CODE', data);
    },
    setThemeIndex: function setThemeIndex(_ref11, data) {
      var commit = _ref11.commit;
      commit('SET_THEME_INDEX', data);
    },
    setPriceCheck: function setPriceCheck(_ref12, data) {
      var commit = _ref12.commit;
      commit('SET_PRICE_CHECK', data);
    },
    setAwardTreeGroupCode: function setAwardTreeGroupCode(_ref13, data) {
      var commit = _ref13.commit;
      commit('SET_AWARD_TREE_GROUP_CODE', data);
    },
    setAwardTreeAwardCode: function setAwardTreeAwardCode(_ref14, data) {
      var commit = _ref14.commit;
      commit('SET_AWARD_TREE_AWARD_CODE', data);
    },
    setFilterModelName: function setFilterModelName(_ref15, data) {
      var commit = _ref15.commit;
      commit('SET_FILTER_MODEL_NAME', data);
    },
    setFilterManuScriptName: function setFilterManuScriptName(_ref16, data) {
      var commit = _ref16.commit;
      commit('SET_FILTER_MANUSCRIPT_NAME', data);
    },
    setSearchText: function setSearchText(_ref17, data) {
      var commit = _ref17.commit;
      commit('SET_SEARCH_TEXT', data);
    },
    setManuScriptSearchText: function setManuScriptSearchText(_ref18, data) {
      var commit = _ref18.commit;
      commit('SET_MANUSCRIPT_SEARCH_TEXT', data);
    },
    setGradeCode: function setGradeCode(_ref19, data) {
      var commit = _ref19.commit;
      commit('SET_GRADE_CODE', data);
    },
    setManuScriptGradeCode: function setManuScriptGradeCode(_ref20, data) {
      var commit = _ref20.commit;
      commit('SET_MANUSCRIPT_GRADE_CODE', data);
    },
    setVolumeCode: function setVolumeCode(_ref21, data) {
      var commit = _ref21.commit;
      commit('SET_VOLUME_CODE', data);
    },
    setManuScriptVolumeCode: function setManuScriptVolumeCode(_ref22, data) {
      var commit = _ref22.commit;
      commit('SET_MANUSCRIPT_VOLUME_CODE', data);
    },
    setUnitCode: function setUnitCode(_ref23, data) {
      var commit = _ref23.commit;
      commit('SET_UNIT_CODE', data);
    },
    setManuScriptUnitCode: function setManuScriptUnitCode(_ref24, data) {
      var commit = _ref24.commit;
      commit('SET_MANUSCRIPT_UNIT_CODE', data);
    },
    setRelationProvinceCode: function setRelationProvinceCode(_ref25, data) {
      var commit = _ref25.commit;
      commit('SET_RELATION_PROVINCE_CODE', data);
    },
    setManuScriptRelationProvinceCode: function setManuScriptRelationProvinceCode(_ref26, data) {
      var commit = _ref26.commit;
      commit('SET_MANUSCRIPT_RELATION_PROVINCE_CODE', data);
    },
    setRelationCityCode: function setRelationCityCode(_ref27, data) {
      var commit = _ref27.commit;
      commit('SET_RELATION_CITY_CODE', data);
    },
    setManuScriptRelationCityCode: function setManuScriptRelationCityCode(_ref28, data) {
      var commit = _ref28.commit;
      commit('SET_MANUSCRIPT_RELATION_CITY_CODE', data);
    },
    setRelationDistrictCode: function setRelationDistrictCode(_ref29, data) {
      var commit = _ref29.commit;
      commit('SET_RELATION_DISTRICT_CODE', data);
    },
    setManuScriptRelationDistrictCode: function setManuScriptRelationDistrictCode(_ref30, data) {
      var commit = _ref30.commit;
      commit('SET_MANUSCRIPT_RELATION_DISTRICT_CODE', data);
    },
    setSchoolCode: function setSchoolCode(_ref31, data) {
      var commit = _ref31.commit;
      commit('SET_SCHOOL_CODE', data);
    },
    setManuScriptSchoolCode: function setManuScriptSchoolCode(_ref32, data) {
      var commit = _ref32.commit;
      commit('SET_MANUSCRIPT_SCHOOL_CODE', data);
    },
    setSchoolName: function setSchoolName(_ref33, data) {
      var commit = _ref33.commit;
      commit('SET_SCHOOL_NAME', data);
    },
    setManuScriptSchoolName: function setManuScriptSchoolName(_ref34, data) {
      var commit = _ref34.commit;
      commit('SET_MANUSCRIPT_SCHOOL_NAME', data);
    },
    setAwardCode: function setAwardCode(_ref35, data) {
      var commit = _ref35.commit;
      commit('SET_AWARD_CODE', data);
    },
    setManuScriptAwardCode: function setManuScriptAwardCode(_ref36, data) {
      var commit = _ref36.commit;
      commit('SET_MANUSCRIPT_AWARD_CODE', data);
    },
    setManuScriptGroupCode: function setManuScriptGroupCode(_ref37, data) {
      var commit = _ref37.commit;
      commit('SET_MANUSCRIPT_GROUP_CODE', data);
    },
    setManuScriptType: function setManuScriptType(_ref38, data) {
      var commit = _ref38.commit;
      commit('SET_MANUSCRIPT_TYPE', data);
    },
    setManuScriptTypes: function setManuScriptTypes(_ref39, data) {
      var commit = _ref39.commit;
      commit('SET_MANUSCRIPT_TYPES', data);
    },
    setGroupCode: function setGroupCode(_ref40, data) {
      var commit = _ref40.commit;
      commit('SET_GROUP_CODE', data);
    },
    setCountCode: function setCountCode(_ref41, data) {
      var commit = _ref41.commit;
      commit('SET_COUNT_CODE', data);
    },
    setIsHaveAudio: function setIsHaveAudio(_ref42, data) {
      var commit = _ref42.commit;
      commit('SET_IS_HAVE_AUDIO', data);
    },
    setStar: function setStar(_ref43, data) {
      var commit = _ref43.commit;
      commit('SET_STAR', data);
    },
    setSearchTime: function setSearchTime(_ref44, data) {
      var commit = _ref44.commit;
      commit('SET_SEARCH_TIME', data);
    },
    setManuScriptSearchTime: function setManuScriptSearchTime(_ref45, data) {
      var commit = _ref45.commit;
      commit('SET_MANUSCRIPT_SEARCH_TIME', data);
    },
    setModelQuery: function setModelQuery(_ref46, data) {
      var commit = _ref46.commit;
      commit('SET_MODEL_QUERY', data);
    },
    setManuScriptQuery: function setManuScriptQuery(_ref47, data) {
      var commit = _ref47.commit;
      commit('SET_MANUSCRIPT_QUERY', data);
    },
    setModelTags: function setModelTags(_ref48, data) {
      var commit = _ref48.commit;
      commit('SET_MODEL_TAGS', data);
    }
  },
  getters: {
    getSubJectName: function getSubJectName(state) {
      return state.subjectName;
    },
    getModelTabName: function getModelTabName(state) {
      return state.modelTabName;
    },
    getModelTabLabel: function getModelTabLabel(state) {
      return state.modelTabLabel;
    },
    getModelTreeCode: function getModelTreeCode(state) {
      return state.modelTreeCode;
    },
    getManuScriptTreeCode: function getManuScriptTreeCode(state) {
      return state.manuScriptTreeCode;
    },
    getEditionIndex: function getEditionIndex(state) {
      return state.editionIndex;
    },
    getEditionCode: function getEditionCode(state) {
      return state.editionCode;
    },
    getManuScriptEditionCode: function getManuScriptEditionCode(state) {
      return state.manuScriptEditionCode;
    },
    getManuScriptEditionIndex: function getManuScriptEditionIndex(state) {
      return state.manuScriptEditionIndex;
    },
    getThemeCode: function getThemeCode(state) {
      return state.themeCode;
    },
    getThemeIndex: function getThemeIndex(state) {
      return state.themeIndex;
    },
    getPriceCheck: function getPriceCheck(state) {
      return state.priceCheck;
    },
    getAwardTreeGroupCode: function getAwardTreeGroupCode(state) {
      return state.awardTreeGroupCode;
    },
    getAwardTreeAwardCode: function getAwardTreeAwardCode(state) {
      return state.awardTreeAwardCode;
    },
    getFilterModelName: function getFilterModelName(state) {
      return state.filterModelName;
    },
    getFilterManuScriptName: function getFilterManuScriptName(state) {
      return state.filterManuScriptName;
    },
    getSearchText: function getSearchText(state) {
      return state.searchText;
    },
    getManuScriptSearchText: function getManuScriptSearchText(state) {
      return state.manuScriptSearchText;
    },
    getGradeCode: function getGradeCode(state) {
      return state.gradeCode;
    },
    getManuScriptGradeCode: function getManuScriptGradeCode(state) {
      return state.manuScriptGradeCode;
    },
    getVolumeCode: function getVolumeCode(state) {
      return state.volumeCode;
    },
    getManuScriptVolumeCode: function getManuScriptVolumeCode(state) {
      return state.manuScriptVolumeCode;
    },
    getUnitCode: function getUnitCode(state) {
      return state.unitCode;
    },
    getManuScriptUnitCode: function getManuScriptUnitCode(state) {
      return state.manuScriptUnitCode;
    },
    getRelationProvinceCode: function getRelationProvinceCode(state) {
      return state.relationProvinceCode;
    },
    getManuScriptRelationProvinceCode: function getManuScriptRelationProvinceCode(state) {
      return state.manuScriptRelationProvinceCode;
    },
    getRelationCityCode: function getRelationCityCode(state) {
      return state.relationCityCode;
    },
    getManuScriptRelationCityCode: function getManuScriptRelationCityCode(state) {
      return state.manuScriptRelationCityCode;
    },
    getRelationDistrictCode: function getRelationDistrictCode(state) {
      return state.relationDistrictCode;
    },
    getManuScriptRelationDistrictCode: function getManuScriptRelationDistrictCode(state) {
      return state.manuScriptRelationDistrictCode;
    },
    getSchoolCode: function getSchoolCode(state) {
      return state.schoolCode;
    },
    getManuScriptSchoolCode: function getManuScriptSchoolCode(state) {
      return state.manuScriptSchoolCode;
    },
    getSchoolName: function getSchoolName(state) {
      manuScriptSchoolName;
      return state.schoolName;
    },
    getManuScriptSchoolName: function getManuScriptSchoolName(state) {
      return state.manuScriptSchoolName;
    },
    getManuScriptType: function getManuScriptType(state) {
      return state.manuScriptType;
    },
    getManuScriptTypes: function getManuScriptTypes(state) {
      return state.manuScriptTypes;
    },
    getAwardCode: function getAwardCode(state) {
      return state.awardCode;
    },
    getManuScriptAwardCode: function getManuScriptAwardCode(state) {
      return state.manuScriptAwardCode;
    },
    getManuScriptGroupCode: function getManuScriptGroupCode(state) {
      return state.manuScriptGroupCode;
    },
    getGroupCode: function getGroupCode(state) {
      return state.groupCode;
    },
    getCountCode: function getCountCode(state) {
      return state.countCode;
    },
    getStar: function getStar(state) {
      return state.star;
    },
    getSearchTime: function getSearchTime(state) {
      return state.searchTime;
    },
    getManuScriptSearchTime: function getManuScriptSearchTime(state) {
      return state.manuScriptSearchTime;
    },
    getModelQuery: function getModelQuery(state) {
      return state.modelQuery;
    },
    getManuScriptQuery: function getManuScriptQuery(state) {
      return state.manuScriptQuery;
    },
    getModelTags: function getModelTags(state) {
      return state.modelTags;
    },
    getIsHaveAudio: function getIsHaveAudio(state) {
      return state.isHaveAudio;
    }
  }
};
var _default = modelEssay;
exports.default = _default;