"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'importDotData',
  props: {
    dataIsShow: {
      type: Boolean,
      default: false
    },
    resourceOpenId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      lrcData: ''
    };
  },
  computed: {
    visibleProxy: {
      get: function get() {
        return this.dataIsShow;
      },
      set: function set(value) {
        this.$emit('update:dataIsShow', value);
      }
    }
  },
  methods: {
    // 关闭弹窗之前先把数据清空
    handleClose: function handleClose() {
      this.lrcData = '';
      this.visibleProxy = false;
    },
    importCancel: function importCancel() {
      this.lrcData = '';
      this.visibleProxy = false;
    },
    // 批量导入数据
    saveDotData: function saveDotData() {
      var _this = this;

      try {
        // 数据校验
        if (this.lrcData.length <= 0) {
          this.$notify({
            title: '提示',
            message: '请填写字幕文本',
            type: 'warning'
          });
          return;
        }

        var dotList = []; // lrc数据格式[00:06:00]第一模块听力训练

        var lrcArr = this.lrcData.split('\n');
        lrcArr.forEach(function (lrcObj) {
          var dateTime = lrcObj.substring(lrcObj.lastIndexOf('[') + 1, lrcObj.lastIndexOf(']'));
          var detailTxt = lrcObj.substring(lrcObj.lastIndexOf(']') + 1, lrcObj.length);
          var dotObj = {
            videoOpenId: _this.resourceOpenId,
            detail: detailTxt,
            tempDetail: detailTxt,
            time: (0, _common.dateToTimestamp)(dateTime),
            templateTime: dateTime
          };
          dotList.push(dotObj);
        });
        this.$emit('getBatchDot', dotList);
        this.lrcData = '';
        this.visibleProxy = false;
      } catch (e) {
        this.$notify({
          title: '提示',
          message: e.message,
          type: 'warning'
        });
      }
    }
  }
};
exports.default = _default;