"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _md = _interopRequireDefault(require("md5"));

var _lodash = _interopRequireDefault(require("lodash"));

var _section = _interopRequireDefault(require("@/components/BookPageEditor/Page/section.vue"));

var _section2 = require("@/utils/bookPageEditor/section");

var _bus = _interopRequireDefault(require("@/utils/bus"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
var _default = {
  name: 'Page',
  components: {
    PageSection: _section.default
  },
  data: function data() {
    return {
      startX: 0,
      startY: 0,
      lastX: 0,
      lastY: 0,
      isDrag: false,
      localId: undefined,
      tempSection: {
        positionTop: 0,
        positionLeft: 0,
        positionWidth: 0,
        positionHeight: 0
      },
      copiedSection: undefined
    };
  },
  computed: {
    pageId: function pageId() {
      return this.$store.state.book.page.page.id;
    },
    page: function page() {
      return this.$store.state.book.page.page;
    },
    width: function width() {
      return this.$store.state.book.editor.size.width * this.$store.state.book.editor.size.scale;
    },
    height: function height() {
      return this.$store.state.book.editor.size.height * this.$store.state.book.editor.size.scale;
    },
    sections: function sections() {
      return this.$store.getters['book/section/visibleSections'];
    },
    activeId: function activeId() {
      return this.$store.state.book.section.activeId;
    },
    section: function section() {
      return this.$store.state.book.section.sections.value[this.activeId];
    },
    getImageUrl: function getImageUrl() {
      var url = '';

      if (this.page && this.page.imgUrl) {
        url = "".concat(process.env.VUE_APP_IMG_URL_PREFIX).concat(process.env.VUE_APP_OSS_PATH, "/").concat(this.page.imgUrl, "?x-oss-process=style/normal");
      }

      return url;
    },
    editorPageStyle: function editorPageStyle() {
      return {
        height: "".concat(this.height, "px"),
        width: "".concat(this.width, "px"),
        backgroundImage: "url(".concat(this.getImageUrl, ")")
      };
    },
    canEdit: function canEdit() {
      return this.$store.state.book.bookCanEdit;
    }
  },
  watch: {
    getImageUrl: function getImageUrl(val, oldVal) {
      var _this = this;

      if (val !== oldVal) {
        var pageWidth = this.$refs.page.offsetWidth;
        var pageHeight = this.$refs.page.offsetHeight;
        var img = new Image();
        img.src = val;

        img.onload = function () {
          var width = img.width,
              height = img.height;

          _this.$store.dispatch('book/editor/imageSizeUpdate', {
            pageWidth: pageWidth,
            pageHeight: pageHeight,
            width: width,
            height: height
          });
        };
      }
    }
  },
  mounted: function mounted() {
    document.addEventListener('keydown', this.altKeyDown);
    document.addEventListener('keyup', this.altKeyUp);
  },
  created: function created() {
    var _this2 = this;

    this.throttleedDragOver = this.onDragOver;

    _bus.default.$on('updatePageEditorArea', function () {
      _this2.updateEditorArea();
    });
  },
  destroyed: function destroyed() {
    document.removeEventListener('keydown', this.altKeyDown);
    document.removeEventListener('keyup', this.altKeyUp);
  },
  methods: {
    onMouseDown: function onMouseDown(event) {
      if (this.canEdit) {
        if (this.$parent.$refs.pageAction.curAction === 'addSection' || this.$parent.$refs.pageAction.curAction === 'addMaskSection' || this.$parent.$refs.pageAction.curAction === 'addCommentSection') {
          if (this.localId) {
            this.onMouseUp();
          } else {
            this.startX = event.offsetX;
            this.startY = event.offsetY;
            this.lastX = event.x;
            this.lastY = event.y;
            this.isDrag = true;
            var top = (0, _section2.px2Proportion)(this.startY, this.height);
            var left = (0, _section2.px2Proportion)(this.startX, this.width);
            this.localId = (0, _md.default)("taoZhi".concat(new Date().getTime()));
            this.tempSection.positionTop = top;
            this.tempSection.positionLeft = left;
          }
        }
      }
    },
    onDragOver: function onDragOver(event) {
      var x = event.x,
          y = event.y;
      this.$store.dispatch('book/editor/dragMove', {
        x: x,
        y: y
      });
    },
    onMouseMove: function onMouseMove(event) {
      if (this.canEdit) {
        if ((this.$parent.$refs.pageAction.curAction === 'addSection' || this.$parent.$refs.pageAction.curAction === 'addMaskSection' || this.$parent.$refs.pageAction.curAction === 'addCommentSection') && this.isDrag) {
          var rect = this.$refs.pageContent.getBoundingClientRect(); // console.log(rect)
          // console.log(event.x + ' ' + event.y)

          var x = event.x;
          var y = event.y;
          var diffX = x - this.lastX;
          var diffY = y - this.lastY;
          this.tempSection.positionWidth = (0, _section2.px2Proportion)(diffX, this.width);
          this.tempSection.positionHeight = (0, _section2.px2Proportion)(diffY, this.height);
          var pageRect = this.$refs.page.getBoundingClientRect(); // console.log(pageRect)

          if (x >= pageRect.right - 20) {
            this.$refs.page.scrollLeft = this.$refs.page.scrollLeft + diffX;
          }

          if (y >= pageRect.bottom - 20) {
            this.$refs.page.scrollTop = this.$refs.page.scrollTop + diffY;
          }

          if (rect.width > pageRect.width) {
            var moveDiffX = this.pageVerticalScroller() + 5;

            if (x >= pageRect.right - moveDiffX) {
              this.onMouseUp();
              return;
            }
          } else {
            var _moveDiffX = 5;

            if (x >= rect.right - _moveDiffX) {
              this.onMouseUp();
              return;
            }
          }

          if (rect.height > pageRect.height) {
            var moveDiffY = this.pageHorizontalScroller() + 5;

            if (y >= pageRect.bottom - moveDiffY) {
              this.onMouseUp();
            }
          } else {
            var _moveDiffX2 = 5;

            if (y >= rect.bottom - _moveDiffX2) {
              this.onMouseUp();
            }
          }
        }
      }
    },
    onMouseUp: function onMouseUp() {
      if (this.canEdit) {
        if (!this.localId) return;
        this.isDrag = false;

        if (this.tempSection.positionWidth > 2 && this.tempSection.positionHeight > 2) {
          var sequence = this.$store.getters['book/section/visibleSections'].length + 1;

          if (this.pageId) {
            var sectionType = 1; // 默认选区

            if (this.$parent.$refs.pageAction.curAction === 'addMaskSection') {
              sectionType = 3; // 遮罩选区
            }

            if (this.$parent.$refs.pageAction.curAction === 'addCommentSection') {
              sectionType = 5; // 评论选区
            }

            var section = (0, _section2.newSection)(this.pageId, sequence, this.tempSection.positionTop, this.tempSection.positionLeft, this.tempSection.positionWidth, this.tempSection.positionHeight, sectionType);
            this.$store.dispatch('book/section/updateSingleSection', section);
          }
        }

        this.localId = undefined;
        this.tempSection.positionTop = 0;
        this.tempSection.positionLeft = 0;
        this.tempSection.positionWidth = 0;
        this.tempSection.positionHeight = 0;

        if (this.$parent.$refs.pageAction.curAction === 'addSection' || this.$parent.$refs.pageAction.curAction === 'addMaskSection' || this.$parent.$refs.pageAction.curAction === 'addCommentSection') {
          this.$parent.$refs.pageAction.onDrag();
        } else {
          this.$store.dispatch('book/section/selectSection', undefined);
        }
      }
    },
    altKeyDown: function altKeyDown(e) {
      var that = this;

      if (e.keyCode === 17) {
        that.$parent.$refs.pageAction.onAddSection();
      }
    },
    altKeyUp: function altKeyUp(e) {
      var that = this;

      if (e.keyCode === 17) {
        that.$parent.$refs.pageAction.onDrag();
      }
    },
    updateEditorArea: function updateEditorArea() {
      var _this3 = this;

      this.$nextTick(function () {
        if (_this3.$refs.page) {
          var pageWidth = _this3.$refs.page.offsetWidth;
          var pageHeight = _this3.$refs.page.offsetHeight;

          if (_this3.$store.state.book.editor.size.scale === 1 && pageWidth !== _this3.width) {
            var width = _this3.width;
            var height = _this3.height;

            _this3.$store.dispatch('book/editor/imageSizeUpdate', {
              pageWidth: pageWidth,
              pageHeight: pageHeight,
              width: width,
              height: height
            });
          }
        }
      });
    },
    // 返回垂直滚动条的宽度
    pageVerticalScroller: function pageVerticalScroller() {
      var el = this.$refs.page;

      if (el.scrollHeight > el.clientHeight) {
        return 4;
      }

      return 0;
    },
    // 返回水平滚动条的高度
    pageHorizontalScroller: function pageHorizontalScroller() {
      var el = this.$refs.page;

      if (el.scrollWidth > el.clientWidth) {
        return 8;
      }

      return 0;
    },
    resetTempSection: function resetTempSection() {
      this.localId = undefined;
      this.tempSection.positionTop = 0;
      this.tempSection.positionLeft = 0;
      this.tempSection.positionWidth = 0;
      this.tempSection.positionHeight = 0;
    },
    quitEdit: function quitEdit() {
      this.resetTempSection();
    },
    copySection: function copySection() {
      console.log('拷贝选区 ......');

      if (this.section) {
        console.log(this.section);
        this.copiedSection = _lodash.default.cloneDeep(this.section);
      }
    },
    pasteSection: function pasteSection() {
      console.log('粘贴选区 ......');

      if (this.copiedSection) {
        var sequence = this.$store.getters['book/section/visibleSections'].length + 1;
        this.$store.dispatch('book/section/updateSingleSection', (0, _section2.copyNewSection)(this.copiedSection, sequence));
      }
    }
  }
};
exports.default = _default;