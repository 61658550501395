"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("./Info/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Group_index',
  components: {
    SpecificationInfo: _index.default
  },
  props: {
    data: {
      type: Array,
      default: undefined
    }
  },
  methods: {
    addValue: function addValue(item) {
      this.$emit('add-value', item);
    },
    updateValue: function updateValue(item, index, value) {
      this.$emit('update-value', item, index, value);
    },
    updateName: function updateName(index, name) {
      this.$emit('update-name', index, name);
    },
    deleteValue: function deleteValue(item, index) {
      this.$emit('delete-value', item, index);
    },
    deleteItem: function deleteItem(item) {
      this.$emit('delete-item', item);
    }
  }
};
exports.default = _default;