"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _index = _interopRequireDefault(require("./liveSetting/index.vue"));

var _index2 = _interopRequireDefault(require("./guideJoinGroup/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    LiveBrodSet: _index.default,
    LeadingPlusGroup: _index2.default
  },
  data: function data() {
    return {
      activeName: '直播中心',
      tabPosition: 'left',
      currentActiveName: '直播中心'
    };
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.currentActiveName = tab.name;
    }
  }
};
exports.default = _default;