"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function waitTimeout(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

var _default = waitTimeout;
exports.default = _default;