"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _helper = require("./helper");

var _Collection = _interopRequireDefault(require("./Collection"));

/**
 * 创建 Collection 集合的快捷方法
 *
 * @param {*} data
 * @returns { Collection }
 */
function collect(data) {
  if ((0, _helper.isCollection)(data)) {
    return data;
  }

  return new _Collection.default(data);
}

var _default = collect;
exports.default = _default;