"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHtmlTemplate = getHtmlTemplate;
exports.addHtmlTemplate = addHtmlTemplate;
exports.updateHtmlTemplate = updateHtmlTemplate;
exports.delHtmlTemplate = delHtmlTemplate;
exports.getHtmlTemplateByCode = getHtmlTemplateByCode;
exports.isQuoteByTemplateCode = isQuoteByTemplateCode;
exports.getTemplatePreview = getTemplatePreview;
exports.getTemplateImageGeneration = getTemplateImageGeneration;
exports.html2word = html2word;
exports.html2pdf = html2pdf;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/image-processing/admin/htmlTemplate/"));
var otherRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/image-processing/admin/"));
/**
 * 获取网页模板列表（分页）
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getHtmlTemplate(params) {
  return request.get('/list', {
    params: params
  });
}
/**
 * 新增网页模板
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addHtmlTemplate(data) {
  return request.post('/add', data);
}
/**
 * 通过id修改网页模板
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateHtmlTemplate(data) {
  return request.post('/update', data);
}
/**
 * 删除网页模板
 * @param {Object} data
 * @returns {Promise<*>}
 */


function delHtmlTemplate(data) {
  return request.post('/deleteById', data);
}
/**
 * 根据code获取网页模板
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getHtmlTemplateByCode(data) {
  return request.post('/getHtmlTemplateByCode', data);
}
/**
 * 根据code校验是否存在
 * @param {Object} data
 * @returns {Promise<*>}
 */


function isQuoteByTemplateCode(data) {
  return request.post('/isQuoteByCode', data);
}
/**
 * 模板设置通用接口（编译页预览）
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getTemplatePreview(data) {
  return request.post('/templatePreview', data);
}
/**
 * 模板图片生成
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getTemplateImageGeneration(data) {
  return request.post('/templateImageGeneration', data);
}
/**
 * html 转 word
 * @param {Object} data
 * @returns {Promise<*>}
 */


function html2word(data) {
  return otherRequest.post('/htmlToFile/html2word', data);
}
/**
 * html 转 pdf
 * @param {Object} data
 * @returns {Promise<*>}
 */


function html2pdf(data) {
  return otherRequest.post('/htmlToFile/html2pdf', data);
}