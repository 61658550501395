var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchUserByinput" },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "230px" },
          attrs: {
            multiple: "",
            clearable: "",
            "collapse-tags": "",
            filterable: "",
            placeholder: "请搜索商户昵称/商户ID号",
            remote: "",
            "reserve-keyword": "",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading
          },
          on: { "visible-change": _vm.gettest },
          model: {
            value: _vm.partnerOpenIdList,
            callback: function($$v) {
              _vm.partnerOpenIdList = $$v
            },
            expression: "partnerOpenIdList"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadScroll,
                  expression: "loadScroll"
                }
              ],
              staticStyle: { height: "200px", overflow: "auto" },
              attrs: {
                "infinite-scroll-immediate": "false",
                "infinite-scroll-disabled": "disabled"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "el-option",
                {
                  key: index,
                  attrs: { label: item.partnerName, value: item.partnerOpenId }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "commonFlex commonAlign",
                      staticStyle: {
                        height: "50px",
                        padding: "5px",
                        width: "100%"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectItem(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "commonFlex commonAlign" }, [
                        _c(
                          "div",
                          {
                            staticClass: "commonFlex commonAlign",
                            staticStyle: { width: "40px" }
                          },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "userCover",
                                class: item.wxChatOpenId ? "weChatStyle" : "",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    item.partnerLogo,
                                    _vm.thumbnailStyle
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "userCover",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            item.wxChatOpenId
                              ? _c("img", {
                                  staticClass: "weChatimg",
                                  staticStyle: {
                                    width: "15px",
                                    height: "15px",
                                    "border-radius": "50%"
                                  },
                                  attrs: {
                                    src: require("../../assets/images/wechart.png"),
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "commonFlex commonAlign",
                          staticStyle: {
                            "flex-direction": "column",
                            "align-items": "flex-start"
                          }
                        },
                        [
                          _c("span", { staticClass: "commonText commonOver" }, [
                            _vm._v(_vm._s(item.partnerName))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "commonText commonOver" }, [
                            _vm._v(_vm._s(item.partnerOpenId))
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.scrollStaus
            ? _c("p", { staticClass: "commonText commonPSty" }, [
                _vm._v("加载数据中...")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.noMore
            ? _c("p", { staticClass: "commonText commonPSty" }, [
                _vm._v("没有更多了")
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }