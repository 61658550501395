var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("product-list", {
        ref: "myList",
        attrs: {
          loading: _vm.loading,
          query: _vm.query,
          data: _vm.data,
          total: _vm.total,
          "content-height": "calc(92vh - 268px)",
          select: _vm.select,
          "show-resource-type": true,
          "price-hidden": true,
          "show-low-price": false,
          "show-whole-low-price": false,
          "show-store-low-price": true
        },
        on: {
          "update:query": function($event) {
            _vm.query = $event
          },
          "update:select": function($event) {
            _vm.select = $event
          },
          "update-data": _vm.updateData,
          "search-changed": _vm.searchChanged
        },
        scopedSlots: _vm._u([
          {
            key: "content-action",
            fn: function() {
              return [
                _c("list-actions", {
                  attrs: {
                    data: _vm.data,
                    "middle-open-id": _vm.middleOpenId,
                    "shop-open-id": _vm.shopOpenId
                  },
                  on: {
                    "show-info": _vm.onShowInfo,
                    "on-product-category-batch-edit-confirm":
                      _vm.onProductCategoryBatchEditConfirm
                  },
                  model: {
                    value: _vm.restockingPrice,
                    callback: function($$v) {
                      _vm.restockingPrice = $$v
                    },
                    expression: "restockingPrice"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("real-goods-info", {
            attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
            on: {
              "update:visible": function($event) {
                _vm.goodsDialogVisible = $event
              },
              "update:goodsId": function($event) {
                _vm.goodsId = $event
              },
              "update:goods-id": function($event) {
                _vm.goodsId = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }