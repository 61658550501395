var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticStyle: { "max-width": "1490px", margin: "0 auto" },
          attrs: {
            visible: _vm.visible,
            width: "80%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _c(
              "span",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "margin-right": "20px", "font-size": "18px" }
                  },
                  [_vm._v("用户信息")]
                ),
                _vm._v(" "),
                !_vm.currentpartnerOpenId ||
                _vm.currentActiveName !== "订单记录"
                  ? _c("searchUserByInput", {
                      ref: "searchUserByInput",
                      on: { receiveuserid: _vm.receiveuserid }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "基本信息", name: "基本信息" }
                    },
                    [
                      _vm.currentActiveName === "基本信息"
                        ? _c("UserBaseInfo", {
                            attrs: { userOpenId: _vm.currentuserOpenId },
                            on: {
                              closeDialogUserdetail: _vm.closeDialogUserdetail
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "教师资质", name: "教师资质" }
                    },
                    [
                      _vm.currentActiveName === "教师资质"
                        ? _c("TeacherInfo", {
                            attrs: { userOpenId: _vm.currentuserOpenId },
                            on: {
                              closeDialogUserdetail: _vm.closeDialogUserdetail
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "商户资质", name: "商户资质" }
                    },
                    [
                      _vm.currentActiveName === "商户资质"
                        ? _c("MerchantsInfo", {
                            attrs: { userOpenId: _vm.currentuserOpenId },
                            on: {
                              closeDialogUserdetail: _vm.closeDialogUserdetail
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "财务信息", name: "财务信息" }
                    },
                    [
                      _vm.currentActiveName === "财务信息"
                        ? _c("financial-info", {
                            attrs: { userOpenId: _vm.currentuserOpenId },
                            on: {
                              closeDialogUserdetail: _vm.closeDialogUserdetail
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "操作日志", name: "操作日志" }
                    },
                    [
                      _vm.currentActiveName === "操作日志"
                        ? _c("OperationLog", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "订单记录", name: "订单记录" }
                    },
                    [
                      _vm.currentActiveName === "订单记录"
                        ? _c("order-record", {
                            attrs: {
                              userOpenId: _vm.currentuserOpenId_order,
                              partnerOpenId: _vm.currentpartnerOpenId
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "余额记录", name: "余额记录" }
                    },
                    [
                      _vm.currentActiveName === "余额记录"
                        ? _c("balance-record", {
                            attrs: {
                              userOpenId: _vm.currentuserOpenId,
                              partnerOpenId: _vm.currentpartnerOpenId
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "浏览记录", name: "浏览记录" }
                    },
                    [
                      _vm.currentActiveName === "浏览记录"
                        ? _c("browsing-history", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "下载记录", name: "下载记录" }
                    },
                    [
                      _vm.currentActiveName === "下载记录"
                        ? _c("download-history", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "收藏记录", name: "收藏记录" }
                    },
                    [
                      _vm.currentActiveName === "收藏记录"
                        ? _c("collection-history", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "留言记录", name: "留言记录" }
                    },
                    [
                      _vm.currentActiveName === "留言记录"
                        ? _c("management-comment", {
                            attrs: {
                              size: "mini",
                              userOpenId: _vm.currentuserOpenId,
                              showCommentBol: _vm.showCommentBol
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "用户地址", name: "用户地址" }
                    },
                    [
                      _vm.currentActiveName === "用户地址"
                        ? _c("user-address", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "设备管理", name: "设备管理" } },
                    [
                      _vm.currentActiveName === "设备管理"
                        ? _c("device-management", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "学习记录", name: "学习记录" } },
                    [
                      _vm.currentActiveName === "学习记录"
                        ? _c("learning-records", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { border: "1px solid #EBEEF5" },
                      attrs: { label: "历史工单", name: "历史工单" }
                    },
                    [
                      _vm.currentActiveName === "历史工单"
                        ? _c("historical-work-order", {
                            attrs: { userOpenId: _vm.currentuserOpenId }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }