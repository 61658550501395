"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addTeacher = _interopRequireDefault(require("../../views/user/teacher/edit/addTeacher.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    editTeacherInfo: _addTeacher.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    newOr: {
      type: Boolean,
      default: false
    },
    userOpenId: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    closeDialog: function closeDialog() {
      this.$emit('update:visible', false);
      this.$emit('refreshTeacherInfo');
    }
  }
};
exports.default = _default;