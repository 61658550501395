"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllList = getAllList;
exports.teacherStatus = teacherStatus;
exports.getTeacherInfo = getTeacherInfo;
exports.patchTeacher = patchTeacher;
exports.getTeacherInfos = getTeacherInfos;
exports.saveTeacher = saveTeacher;
exports.cancelQualification = cancelQualification;
exports.importTeacherExcel = importTeacherExcel;
exports.batchTeacherStatus = batchTeacherStatus;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_TEACHER_API, "/admin"));

function getAllList(params) {
  return request.get('/teacher', {
    params: params
  });
}

function teacherStatus(value, keyword) {
  return request({
    url: "/teacher?openIds&&checkStatus=".concat(value, "&&keyword=").concat(keyword),
    method: 'get'
  });
}

function getTeacherInfo(userOpenId) {
  return request({
    url: "/teacher/".concat(userOpenId),
    method: 'get'
  });
}

function patchTeacher(userOpenId, data) {
  return request({
    url: "/teacher/".concat(userOpenId),
    method: 'patch',
    data: data
  });
}

function getTeacherInfos(activityId, userOpenId) {
  return request({
    url: "/teacherInfo/".concat(activityId, "/").concat(userOpenId),
    method: 'get'
  });
}

function saveTeacher(data) {
  return request({
    url: '/teacher',
    method: 'post',
    data: data
  });
}
/* 取消教师资格 */


function cancelQualification(userOpenId) {
  return request({
    url: "/teacherInfo/delete/".concat(userOpenId),
    method: 'post'
  });
}
/**
 * 导入
 * @returns {Promise<*>}
 */


function importTeacherExcel(body) {
  return request.post('teacher/import', body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 1000 * 60 * 10
  });
}
/**
 * 批零通过、驳回
 * @param userOpenId
 * @param data
 * @param params
 * @returns {*}
 */


function batchTeacherStatus(data, params) {
  return request.patch('/batch/teacher', data, {
    params: params
  });
}