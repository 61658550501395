"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ConditionalFiltering = _interopRequireDefault(require("./ConditionalFiltering"));

var _StatusFiltering = _interopRequireDefault(require("./StatusFiltering"));

var _TimeAndSorting = _interopRequireDefault(require("./TimeAndSorting"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Ge_FilterOptions_index',
  components: {
    ConditionalFiltering: _ConditionalFiltering.default,
    StatusFiltering: _StatusFiltering.default,
    TimeAndSorting: _TimeAndSorting.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hasState: {
      type: Boolean,
      default: false
    },
    showInventory: {
      type: Boolean,
      default: true
    },
    showLocalInventory: {
      type: Boolean,
      default: false
    },
    useMerchantBrandSeries: {
      type: Boolean,
      default: false
    },
    partnerOpenId: {
      type: String,
      default: undefined
    },
    presetPartnerOpenId: {
      type: String,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default2;