var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "teacherActivity",
      staticStyle: {
        padding: "20px",
        "background-color": "white",
        overflow: "auto",
        height: "calc(100vh - 84px)"
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "活动信息", name: "first" } }, [
            _c(
              "div",
              { staticClass: "detail-case", attrs: { model: _vm.tableData } },
              [
                _c(
                  "div",
                  {
                    staticClass: "detail-title",
                    staticStyle: { display: "flex", "align-items": "center" }
                  },
                  [
                    _c("div", [_c("span", [_vm._v("活动信息")])]),
                    _vm._v(" "),
                    _c("div", { staticClass: "left-mg" }, [
                      _c(
                        "div",
                        { staticClass: "active-bottom" },
                        [
                          _vm.tableData.status === "已结束"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    plain: "",
                                    disabled: "",
                                    size: "small"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.tableData.status))]
                              )
                            : _vm.tableData.status === "进行中"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    plain: "",
                                    disabled: "",
                                    size: "small"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.tableData.status) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm.tableData.status === "未开始"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "warning",
                                    plain: "",
                                    disabled: "",
                                    size: "small"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.tableData.status) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "common-marign",
                              staticStyle: {
                                "margin-left": "10px",
                                "margin-right": "20px"
                              },
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.editActivity }
                            },
                            [_vm._v("编辑\n              ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "detail-ds" }, [
                  _vm.tableData.cover
                    ? _c(
                        "div",
                        [
                          _c("el-image", {
                            staticClass: "detail-cover",
                            attrs: {
                              src: _vm.getImgUrl(
                                _vm.tableData.cover,
                                _vm.thumbnailStyle
                              ),
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-nr" }, [
                    _c("div", { staticClass: "detail-tl" }, [
                      _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.isNull(_vm.tableData.title)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "smallSize",
                          staticStyle: { "margin-bottom": "5px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-right": "30px",
                                flex: "none"
                              }
                            },
                            [
                              _vm._v(
                                "\n                    活动创建人：\n                    "
                              ),
                              _vm.tableData.admin_cover
                                ? _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticClass: "userCover",
                                        attrs: {
                                          src: _vm.getImgUrl(
                                            _vm.tableData.admin_cover,
                                            _vm.thumbnailStyle
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.isNull(_vm.tableData.admin_name)) +
                                  "\n                  "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _vm._v("\n                    活动负责人："),
                              _c("span", { staticStyle: { width: "16px" } }),
                              _vm._v(" "),
                              _vm.adminData.length
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "flex-wrap": "wrap",
                                        "margin-left": "16px"
                                      }
                                    },
                                    _vm._l(_vm.adminData, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c(
                                            "el-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm.isNull(item.name)
                                                      ) +
                                                      "\n                          "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              item.cover
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "-16px"
                                                      }
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass:
                                                          "userCover",
                                                        attrs: {
                                                          src: _vm.getImgUrl(
                                                            item.cover,
                                                            _vm.thumbnailStyle
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", { staticClass: "adminLength" }, [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.adminData.length) +
                                    "\n                    "
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-ta" }, [
                        _c("div", { staticClass: "detail-time" }, [
                          _c("div", { staticClass: "smallSize" }, [
                            _vm._v(
                              "活动起止：" +
                                _vm._s(_vm.tableData.startedAt) +
                                " ~ " +
                                _vm._s(_vm.tableData.endedAt)
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("div", [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", {
                        staticStyle: {
                          width: "4px",
                          height: "16px",
                          "background-color": "#409EFF",
                          "border-radius": "2px",
                          "margin-right": "10px"
                        }
                      }),
                      _vm._v("活动介绍")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "320px",
                          height: "435px",
                          padding: "10px",
                          overflow: "scroll"
                        }
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.tableData.description)
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { margin: "0 100px" } }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", {
                        staticStyle: {
                          width: "4px",
                          height: "16px",
                          "background-color": "#409EFF",
                          "border-radius": "2px",
                          "margin-right": "10px"
                        }
                      }),
                      _vm._v("活动属性")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { padding: "10px" } }, [
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v("活动链接：www.taozhi.cn")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v(
                          "活动地区：" +
                            _vm._s(
                              _vm.tableData.area === null
                                ? "全国"
                                : _vm.tableData.area
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v(
                          "学段：" +
                            _vm._s(
                              _vm.showval(
                                _vm.tableData.sectionIds,
                                _vm.SyncSectionData
                              )
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v(
                          "年级：" +
                            _vm._s(
                              _vm.showval(
                                _vm.tableData.gradeIds,
                                _vm.allSyncGrade
                              )
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v(
                          "教师资格：" +
                            _vm._s(
                              _vm.tableData.certifiedOnly === true
                                ? "需要"
                                : "不需要"
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "smallSize" }, [
                        _vm._v(
                          "寄至学校：" +
                            _vm._s(
                              _vm.tableData.mailToSchoolOnly === true
                                ? "是，仅限"
                                : "不是"
                            )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", {
                          staticStyle: {
                            width: "4px",
                            height: "16px",
                            "background-color": "#409EFF",
                            "border-radius": "2px",
                            "margin-right": "10px"
                          }
                        }),
                        _vm._v("活动分享")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "257px",
                            padding: "10px",
                            border: "1px solid #EBEEF5",
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "space-between",
                            "margin-top": "10px"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.isNull(_vm.tableData.title)))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                height: "40px",
                                "align-items": "center",
                                "margin-top": "10px",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "tryitItem-title",
                                  staticStyle: { "font-size": "12px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isNull(_vm.tableData.shareDescription)
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.tableData.shareImage
                                ? _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "50px",
                                          height: "40px"
                                        },
                                        attrs: {
                                          src: _vm.getImgUrl(
                                            _vm.tableData.shareImage,
                                            _vm.thumbnailStyle
                                          ),
                                          fit: "cover"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", {
                        staticStyle: {
                          width: "4px",
                          height: "16px",
                          "background-color": "#409EFF",
                          "border-radius": "2px",
                          "margin-right": "10px"
                        }
                      }),
                      _vm._v("活动海报")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { padding: "10px" } }, [
                      _vm.tableData.poster
                        ? _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "150px",
                                  height: "270px",
                                  "border-radius": "5px"
                                },
                                attrs: {
                                  src: _vm.getImgUrl(
                                    _vm.tableData.poster,
                                    _vm.thumbnailStyle
                                  ),
                                  fit: "cover"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "参与用户", name: "second" } }, [
            _c("div", { staticClass: "detail-case" }, [
              _c("div", { staticClass: "user-case" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "align-items": "center",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "div",
                      _vm._l(_vm.button_options, function(item, index) {
                        return _c(
                          "button",
                          {
                            key: index,
                            staticClass: "btn",
                            class: { " active-btn": item.show === true },
                            staticStyle: { margin: "0" },
                            attrs: { disabled: item.show },
                            on: {
                              click: function($event) {
                                return _vm.handleBtnCheck(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.title) + "\n              ")]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.fullscreenLoading,
                            expression: "fullscreenLoading"
                          }
                        ],
                        staticClass: "topButton",
                        attrs: { type: "border-card" }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-input", {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                "prefix-icon": "el-icon-search",
                                placeholder: "搜索关键字"
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchContent($event)
                                }
                              },
                              model: {
                                value: _vm.keyword,
                                callback: function($$v) {
                                  _vm.keyword = $$v
                                },
                                expression: "keyword"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "searchbtn",
                              {
                                staticClass: "searchContent",
                                on: { searchContent: _vm.searchContent }
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "form",
                                    attrs: {
                                      model: _vm.query,
                                      "label-width": "100px"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "审核日期" } },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            type: "datetimerange",
                                            "range-separator": "至",
                                            "start-placeholder": "开始日期",
                                            "end-placeholder": "结束日期",
                                            align: "right",
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.applyDate(
                                                _vm.applyDateValue
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.applyDateValue,
                                            callback: function($$v) {
                                              _vm.applyDateValue = $$v
                                            },
                                            expression: "applyDateValue"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "选择地区" } },
                                      [
                                        _c("address-select", {
                                          model: {
                                            value: _vm.queryArea,
                                            callback: function($$v) {
                                              _vm.queryArea = $$v
                                            },
                                            expression: "queryArea"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btnExportOrder",
                                staticStyle: {
                                  "margin-left": "10px",
                                  "margin-right": "0"
                                },
                                attrs: { type: "primary" },
                                on: { click: _vm.outExe }
                              },
                              [_vm._v("导出")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tableDataSty" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.load,
                            expression: "load"
                          }
                        ],
                        ref: "tablewrap",
                        staticStyle: { width: "100%", overflow: "auto" },
                        attrs: {
                          data: _vm.teacherData,
                          height: "100%",
                          "default-sort": {
                            prop: "withdrawRequestedAt",
                            order: "descending"
                          }
                        },
                        on: { "selection-change": _vm.handleSelectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            selectable: _vm.checkboxT
                          }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { type: "expand" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form",
                                    {
                                      staticClass: "demo-table-expand",
                                      attrs: {
                                        "label-position": "left",
                                        inline: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "expendCommon" },
                                        [
                                          _vm._v(
                                            "\n                      用户ID： "
                                          ),
                                          scope.row.userOpenId
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.userOpenId)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "expendCommon" },
                                        [
                                          _vm._v(
                                            "\n                      申请IP： "
                                          ),
                                          scope.row.activityApplication
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.isNull(
                                                      scope.row
                                                        .activityApplication
                                                        .applicationIp
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "expendCommon" },
                                        [
                                          _vm._v(
                                            "\n                      参与活动： "
                                          ),
                                          scope.row.joinNumber
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.joinNumber) +
                                                    "次"
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "expendCommon" },
                                        [
                                          _vm._v(
                                            "\n                      任教信息：\n                      "
                                          ),
                                          scope.row.teacherTeachingInfoList
                                            ? _c(
                                                "span",
                                                _vm._l(
                                                  scope.row
                                                    .teacherTeachingInfoList,
                                                  function(index) {
                                                    return _c(
                                                      "span",
                                                      { key: index.id },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              index.subject +
                                                                " / " +
                                                                index.grade +
                                                                " / " +
                                                                index.edition
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "expendCommon" },
                                        [
                                          _vm._v(
                                            "\n                      邮寄地址：\n                      "
                                          ),
                                          scope.row.activityApplication
                                            ? _c("span", [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showNull(
                                                          scope.row
                                                            .activityApplication
                                                            .addressProvince
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showNull(
                                                          scope.row
                                                            .activityApplication
                                                            .addressCity
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showNull(
                                                          scope.row
                                                            .activityApplication
                                                            .addressCounty
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showNull(
                                                          scope.row
                                                            .activityApplication
                                                            .addressStreet
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showNull(
                                                          scope.row
                                                            .activityApplication
                                                            .addressDetail
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "参与用户", width: "400" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "commomFlex" },
                                    [
                                      scope.row.userCover
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-image", {
                                                staticClass: "userCover",
                                                attrs: {
                                                  src: _vm.getImgUrl(
                                                    scope.row.userCover
                                                  ),
                                                  alt: "",
                                                  fit: "cover"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px"
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(scope.row.name))]
                                      ),
                                      _vm._v(" "),
                                      scope.row.checkedAt
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "success" } },
                                            [
                                              _vm._v(
                                                "认证教师：" +
                                                  _vm._s(scope.row.checkedAt)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "运单号",
                            align: "left",
                            width: "300"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.activityApplication
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _vm.input_this !==
                                          scope.row.userOpenId
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#409EFF"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.isNull(
                                                          scope.row
                                                            .activityApplication
                                                            .trackingNumber
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.input_this == scope.row.userOpenId
                                            ? _c("el-input", {
                                                staticStyle: {
                                                  color: "#409eff"
                                                },
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "请输入运货单号"
                                                },
                                                model: {
                                                  value:
                                                    scope.row
                                                      .activityApplication
                                                      .trackingNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row
                                                        .activityApplication,
                                                      "trackingNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.activityApplication.trackingNumber"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "0 10px" }
                                            },
                                            [
                                              _vm.input_this !==
                                              scope.row.userOpenId
                                                ? _c("i", {
                                                    staticClass: "el-icon-edit",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.inputFreight(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "0 10px" }
                                            },
                                            [
                                              _vm.showSaveIcon &&
                                              _vm.input_this ==
                                                scope.row.userOpenId
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-check",
                                                    staticStyle: {
                                                      color: "#409EFF"
                                                    },
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.patchFreight(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "审核人", width: "150" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", { staticClass: "commomFlex" }, [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isNull(scope.row.checkAuditor)
                                          )
                                      )
                                    ])
                                  ])
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "状态",
                            width: "150",
                            prop: "checkStatus",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.checkStatus
                                    ? _c("div", [
                                        scope.row.checkStatus === "已通过"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "passedFontStyle"
                                              },
                                              [_vm._v("已通过")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.checkStatus === "审核中"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "auditedFontStyle"
                                              },
                                              [_vm._v("审核中")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.checkStatus === "已驳回"
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      placement: "top"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "content"
                                                        },
                                                        slot: "content"
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            "驳回原因：" +
                                                              _vm._s(
                                                                scope.row
                                                                  .checkRejectReason
                                                              )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rejectFontStyle"
                                                      },
                                                      [_vm._v("已驳回")]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "证件",
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "47px",
                                          height: "32px",
                                          "border-radius": "4px"
                                        },
                                        attrs: {
                                          fit: "cover",
                                          src: _vm.getImgUrl(
                                            scope.row.certificateImage
                                          ),
                                          "preview-src-list": _vm.getImgUrlArr(
                                            scope.row.certificateImage
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            fixed: "right",
                            "header-align": "right",
                            align: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToDetail(
                                            scope.row.userOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("详情")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _vm.teacherData.length
              ? _c(
                  "div",
                  [
                    _vm.checkStatus === "" || _vm.checkStatus === 1
                      ? _c(
                          "div",
                          {
                            staticClass: "t-block1",
                            staticStyle: { display: "flex" }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  staticClass: "pagination-check",
                                  staticStyle: { margin: "0 14px" },
                                  attrs: { indeterminate: _vm.isIndeterminate },
                                  on: { change: _vm.handleSelectedAll },
                                  model: {
                                    value: _vm.selectedAll,
                                    callback: function($$v) {
                                      _vm.selectedAll = $$v
                                    },
                                    expression: "selectedAll"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      disabled: _vm.checkList.length === 0
                                    },
                                    on: { click: _vm.updateToPass }
                                  },
                                  [_vm._v("通过")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      disabled: _vm.checkList.length === 0
                                    },
                                    on: { click: _vm.updateToReject }
                                  },
                                  [_vm._v("驳回")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-pagination", {
                      staticClass: "t-block",
                      attrs: {
                        background: "",
                        "current-page": _vm.page,
                        "page-sizes": [10, 20, 30, 50],
                        "page-size": _vm.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          id: "dialogBodyeReject",
                          visible: _vm.dialogFormVisibleRefund,
                          title: "驳回申请",
                          width: "610px",
                          "close-on-click-modal": false,
                          "close-on-press-escape": false
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.dialogFormVisibleRefund = $event
                          }
                        }
                      },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ruleFormReject",
                            staticClass: "demo-ruleForm",
                            attrs: {
                              model: _vm.ruleFormReject,
                              rules: _vm.rules,
                              "status-icon": ""
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { display: "flex" },
                                attrs: { label: "驳回原因", prop: "reject" }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "480px" },
                                  attrs: {
                                    type: "textarea",
                                    placeholder: "请简述驳回原因，不超过20个字"
                                  },
                                  model: {
                                    value: _vm.ruleFormReject.reject,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleFormReject,
                                        "reject",
                                        $$v
                                      )
                                    },
                                    expression: "ruleFormReject.reject"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer"
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitFormReject(
                                      "ruleFormReject"
                                    )
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.dialogFormVisibleRefund = false
                                  }
                                }
                              },
                              [_vm._v("取 消")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("UserInfo", {
        attrs: {
          visible: _vm.dialogUserdetail,
          userOpenId: _vm.currentuserOpenId,
          propActiveName: _vm.propActiveName
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogUserdetail = $event
          },
          clearuserOpenId: _vm.clearuserOpenId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }