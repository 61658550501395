var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "e-wrap" },
    [
      _c(
        "div",
        { class: { "pane-menu": true, "pane-menu-left": _vm.merchantId } },
        [
          _c("videoClass", {
            ref: "albumCatalog",
            attrs: {
              isebook: true,
              isbookcase: true,
              type: 20,
              routname: _vm.typename
            },
            on: { "update-tree": _vm.getList, "check-event": _vm.classChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: { bookcase: true, "bookcase-right": _vm.merchantId } },
        [
          _c("publictop", {
            attrs: { typeName: _vm.typename, isebook: true, isbookcase: true },
            on: {
              "new-add": _vm.newPage,
              searchbtnevent: _vm.searchBtnFun,
              "merchant-id": _vm.searchMerchant
            },
            scopedSlots: _vm._u([
              {
                key: "eBook",
                fn: function() {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "screen-botton",
                        model: {
                          value: _vm.screenVal,
                          callback: function($$v) {
                            _vm.screenVal = $$v
                          },
                          expression: "screenVal"
                        }
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "全部" } }),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "已上架" } }),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "未上架" } })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              ref: "multipleTable",
              staticClass: "table-wrap",
              staticStyle: { width: "100%" },
              attrs: {
                height: "calc(100% - 128px)",
                data: _vm.tableData,
                "empty-text": "暂无书橱",
                "tooltip-effect": "dark"
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "45" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "书橱", width: "320" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "info",
                            on: {
                              click: function($event) {
                                return _vm.editBookcase(scope.row)
                              }
                            }
                          },
                          [
                            _c("el-image", {
                              class:
                                scope.row.coverType == 1
                                  ? "cover-transverse"
                                  : "cover-vertical",
                              attrs: {
                                src: _vm.handleCoverDisplay(scope.row),
                                fit: "cover"
                              }
                            }),
                            _vm._v(" "),
                            scope.row.coverReferenceType === 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cover-vertical recommend-title"
                                  },
                                  [_vm._v(_vm._s(scope.row.title))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                class:
                                  scope.row.coverType == 1
                                    ? "title-transverse"
                                    : "title-vertical"
                              },
                              [_vm._v(_vm._s(scope.row.title))]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "简介" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.introduction
                                  ? scope.row.introduction
                                  : "--"
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商户", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "merchant" },
                          [
                            _c("el-image", {
                              staticClass: "mer-cover",
                              attrs: {
                                src: _vm.getImgUrl(scope.row.partnerLogo),
                                fit: "cover"
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.partnerName) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.ebookNum) +
                              "本\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          scope.row.status && scope.row.status == 1
                            ? _c(
                                "span",
                                { staticStyle: { color: "#606266" } },
                                [_vm._v("未上架")]
                              )
                            : !scope.row.status || scope.row.status == 2
                            ? _c(
                                "span",
                                { staticStyle: { color: "#409EFF" } },
                                [_vm._v("已上架")]
                              )
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "更新时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.updatedAt) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "right", label: "操作", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "operation" }, [
                          _c(
                            "div",
                            { staticClass: "icons" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "预览",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "iconfont icon-yulan",
                                    on: {
                                      click: function($event) {
                                        return _vm.showSharePreview(scope.row)
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "编辑",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "iconfont icon-xiangqing",
                                    on: {
                                      click: function($event) {
                                        return _vm.editBookcase(scope.row)
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "书橱",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "iconfont icon-shuchu",
                                    on: {
                                      click: function($event) {
                                        return _vm.openContent(scope.row)
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "操作日志",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "iconfont icon-caozuojilu",
                                    on: {
                                      click: function($event) {
                                        return _vm.showOperationPreview(
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { on: { command: _vm.itemOperation } },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        content: "更多",
                                        effect: "dark",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-gengduo"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: {
                                              type: "copy",
                                              item: scope.row
                                            }
                                          }
                                        },
                                        [_vm._v("复制")]
                                      ),
                                      _vm._v(" "),
                                      scope.row.status && scope.row.status == 1
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: {
                                                  type: "up",
                                                  item: scope.row
                                                }
                                              }
                                            },
                                            [_vm._v("上架")]
                                          )
                                        : !scope.row.status ||
                                          scope.row.status == 2
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: {
                                                  type: "down",
                                                  item: scope.row
                                                }
                                              }
                                            },
                                            [_vm._v("下架")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: {
                                              type: "delete",
                                              item: scope.row
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-bottom-box" },
            [
              _c(
                "div",
                [
                  _c("el-checkbox", {
                    staticClass: "pagination-check",
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.selectedAll,
                      callback: function($$v) {
                        _vm.selectedAll = $$v
                      },
                      expression: "selectedAll"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-dropdown",
                    {
                      attrs: { "split-button": "", type: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.listOperation($event)
                        },
                        command: _vm.handleCommand
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.batchName) + "\n          "
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", { attrs: { command: "up" } }, [
                            _vm._v("批量上架书橱")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "down" } },
                            [_vm._v("批量下架书橱")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "delete" } },
                            [_vm._v("批量删除书橱")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "copy" } },
                            [_vm._v("批量复制书橱")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "attribute" } },
                            [_vm._v("批量设置属性")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.total > 0
                ? _c("el-pagination", {
                    attrs: {
                      total: _vm.total,
                      "pager-count": 5,
                      "current-page": _vm.listQuery.page,
                      "page-size": _vm.listQuery.size,
                      "page-sizes": _vm.pageSizes,
                      layout: "total, sizes, prev, pager, next, jumper",
                      background: ""
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.currentchange
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowOperationRecord
        ? _c("resource-operation-record", {
            attrs: {
              visible: _vm.isShowOperationRecord,
              isebook: true,
              resourceObj: _vm.operatoinResource
            },
            on: {
              "update:visible": function($event) {
                _vm.isShowOperationRecord = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("是否删除此书橱及书橱下的所有内容？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteABook } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogCopyAll, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogCopyAll = $event
            }
          }
        },
        [
          _c("span", [
            _vm._v("确定要复制此" + _vm._s(_vm.copyNumber) + "个作品吗？")
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogCopyAll = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.copyAllBook } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisibleAll, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleAll = $event
            }
          }
        },
        [
          _c("span", [_vm._v("是否删除选中的书橱及书橱下的所有内容？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleAll = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteAllBook }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      }),
      _vm._v(" "),
      _c("ebook-attribute-dialog", {
        ref: "attribute",
        attrs: {
          visible: _vm.attributeDialog,
          "operation-list": _vm.selectedOpenIds,
          "partner-open-id": _vm.merchantId,
          "type-name": "书橱"
        },
        on: {
          "update:visible": function($event) {
            _vm.attributeDialog = $event
          },
          batchSetAttribute: _vm.batchSetAttribute
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }