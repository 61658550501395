var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "input-box",
          style: !_vm.commodityOpenId ? "width: 85px" : "",
          on: { click: _vm.handleSelectAlbum }
        },
        [
          _c("div", { staticClass: "placeholder" }, [
            _vm._v(_vm._s(_vm.detail ? _vm.detail.title : "请选择专辑"))
          ]),
          _vm._v(" "),
          _vm.detail
            ? _c("div", { staticClass: "icon-box" }, [
                _c("i", {
                  staticClass: "iconfont icon-black_red_eye",
                  on: { click: _vm.preview }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-sort",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.outerVisible = true
                    }
                  }
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-delete",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.deleteAlbum($event)
                    }
                  }
                })
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.outerVisible,
              "expect-type": "isOnlyShowAlbum",
              "exclude-data": _vm.giftlist
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.getSelect
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }