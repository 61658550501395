var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "custom-class": "pop-up-dialog",
        title: "店铺-分销商品库",
        visible: _vm.valueProxy,
        width: "92%",
        "before-close": _vm.handleClose,
        "lock-scroll": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.valueProxy = $event
        }
      }
    },
    [
      _vm.valueProxy
        ? _c("product-list", {
            ref: "list",
            attrs: {
              "middle-open-id": _vm.middleOpenId,
              "shop-open-id": _vm.shopOpenId
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }