"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendMessage = sendMessage;

require("core-js/modules/es6.function.name");

var _index = require("../api/common/index");

function sendMessage(temp, params) {
  var data = {};

  switch (temp) {
    // 通过商户信息
    case 0:
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174814506',
        templateParam: {
          partnerName: params.name
        }
      };
      break;
    // 驳回商户信息

    case 1:
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174809421',
        templateParam: {
          partnerName: params.name,
          Reason: params.reason
        }
      };
      break;
    // 通过财务信息

    case 2:
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174814507',
        templateParam: {
          partnerName: params.name
        }
      };
      break;
    // 驳回财务信息

    case 3:
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174814508',
        templateParam: {
          partnerName: params.name,
          Reason: params.reason
        }
      };
      break;

    case 4:
      // 通过作品
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174809420',
        templateParam: {
          partnerName: params.name,
          sourceName: params.resourceOpenId
        }
      };
      break;

    case 5:
      // 驳回作品
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174814505',
        templateParam: {
          partnerName: params.name,
          sourceName: params.resourceOpenId,
          Reason: params.reason
        }
      };
      break;
    // 官方停售

    case 6:
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174814503',
        templateParam: {
          partnerName: params.name,
          sourceName: params.resourceOpenId,
          Reason: params.reason
        }
      };
      break;
    // 官方取消停售

    case 7:
      data = {
        phoneNumber: params.phone,
        templateCode: 'SMS_174809418',
        templateParam: {
          partnerName: params.name,
          sourceName: params.resourceOpenId
        }
      };
      break;
  }

  (0, _index.sendMsgTemp)(data).then(function (res) {
    console.log("\u53D1\u9001\u6D88\u606F\u6210\u529F\uFF1A".concat(res));
  }).catch(function (err) {
    console.log(err);
  });
}