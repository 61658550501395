"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.number.constructor");

var _collect = _interopRequireDefault(require("collect.js"));

var _InputCloseable = _interopRequireDefault(require("./InputCloseable"));

var _SpecificationValueCell = _interopRequireDefault(require("./SpecificationValueCell"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Group_Info_index',
  components: {
    InputCloseable: _InputCloseable.default,
    SpecificationValueCell: _SpecificationValueCell.default
  },
  props: {
    index: {
      type: Number,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    valueChunked: function valueChunked() {
      return (0, _collect.default)((0, _toConsumableArray2.default)(this.data.value)).map(function (value) {
        return {
          value: value
        };
      }).push({
        type: 'add'
      }).chunk(4).map(function (s) {
        return s.pad(4, {
          type: 'empty'
        });
      }).all();
    }
  },
  methods: {
    updateName: function updateName(value) {
      this.$emit('update-name', this.index, value);
    },
    deleteItem: function deleteItem() {
      this.$emit('delete-item', this.index);
    },
    addValue: function addValue() {
      this.$emit('add-value', this.index);
    },
    updateValue: function updateValue(index, value) {
      this.$emit('update-value', this.index, index, value);
    },
    deleteValue: function deleteValue(index) {
      this.$emit('delete-value', this.index, index);
    }
  }
};
exports.default = _default;