"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _fileSaver = _interopRequireDefault(require("file-saver"));

var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DotExport',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    resourceTitle: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      lrcData: []
    };
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        console.log(val, 'visible');
        this.initData();
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    initData: function initData() {
      var _this = this;

      var arrayCatch = (0, _toConsumableArray2.default)(this.list);
      var list = [];
      arrayCatch.forEach(function (item) {
        var time;
        var templateTime = item.time / 1000;

        if (templateTime < 60) {
          time = "00:".concat(templateTime);
        }

        if (templateTime >= 60 && templateTime < 3600) {
          var second = _this.getNewTime(templateTime / 60, true);

          var millisecond = _this.getNewTime(templateTime % 60);

          time = "".concat(second, ":").concat(millisecond);
        }

        if (templateTime >= 3600) {
          var minute = _this.getNewTime(templateTime / 3600, true);

          var val = _this.getNewTime(templateTime % 3600);

          var _second = _this.getNewTime(val / 60, true);

          var _millisecond = _this.getNewTime(val % 60);

          time = "".concat(minute, ":").concat(_second, ":").concat(_millisecond);
        }

        list.push("[".concat(time, "]").concat(item.detail, "\n"));
      });
      this.lrcData = [].concat(list);
    },
    getNewTime: function getNewTime(val) {
      var isParseInt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      val = isParseInt ? parseInt(val, 0) : val;
      return val > 10 ? val : "0".concat(val);
    },
    // 导出lrc
    handleExport: function handleExport() {
      try {
        if (this.lrcData.length <= 0) {
          return this.$message({
            message: 'lrc不能为空',
            type: 'warning'
          });
        }

        var str = new Blob(this.lrcData, {
          type: 'text/plain;charset=utf-8',
          endings: 'native'
        });
        (0, _fileSaver.default)(str, "".concat(this.resourceTitle, ".lrc"));
      } catch (e) {
        console.log(e);
        this.$notify({
          title: '失败',
          message: e.message,
          type: 'error'
        });
      }
    },
    copyList: function copyList(event) {
      if (this.lrcData.length <= 0) {
        return this.$message({
          message: 'lrc不能为空',
          type: 'warning'
        });
      }

      var data = '';
      this.lrcData.map(function (item) {
        data += item;
      });
      (0, _clipboard.default)(data, event);
    }
  }
};
exports.default = _default2;