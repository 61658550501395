"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CG_From_Input_DiscountSettingColumn',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    orginalPrice: {
      type: Number,
      default: undefined
    },
    disableCell: {
      type: Boolean,
      default: false
    },
    defaultProportion: {
      type: Number,
      default: 1
    },
    noPrice: {
      type: Boolean,
      default: false
    },
    inputWidth: {
      type: String,
      default: '50px'
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return (0, _priceParser.isSafeNumber)(this.value) ? (0, _priceParser.parseDiscount)(this.value) : (0, _priceParser.parseDiscount)(this.defaultProportion);
      },
      set: function set(value) {
        this.$emit('input', (0, _priceParser.parseDiscount)(value));
      }
    },
    valueProxyDisplay: {
      get: function get() {
        return (0, _priceParser.discountToPercent)(this.valueProxy);
      },
      set: function set(value) {
        this.valueProxy = (0, _priceParser.parseDiscountFromPercent)(value);
      }
    },
    orgPrice: function orgPrice() {
      return (0, _priceParser.parsePrice)(this.orginalPrice);
    },
    curPrice: function curPrice() {
      return (0, _priceParser.calcPriceYuanFixedByDiscount)(this.orgPrice, this.valueProxy);
    },
    batchSetStyle: function batchSetStyle() {
      return {
        width: this.inputWidth
      };
    }
  }
};
exports.default = _default;