var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.showAddDia("添加")
            }
          }
        },
        [_vm._v("添加")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.searchLoading,
              expression: "searchLoading"
            }
          ],
          staticClass: "table_box"
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "header-cell-style": { background: "#F6F7FB" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "storeroomOpenId",
                  label: "仓库ID",
                  "min-width": "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  label: "ERP库房编码",
                  "min-width": "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "仓库名称", "min-width": "150" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "收货人", "min-width": "150" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userPhone",
                  label: "联系电话",
                  "min-width": "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "收货地址",
                  "min-width": "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "排序",
                  align: "center",
                  "min-width": "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.showEditFileSort &&
                        _vm.currentIndex === scope.$index
                          ? _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { margin: "5px 5px" },
                                  attrs: {
                                    min: 0,
                                    size: "mini",
                                    "controls-position": "right",
                                    precision: 0
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.saveSort(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.sequence,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "sequence", $$v)
                                    },
                                    expression: "scope.row.sequence"
                                  }
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-close",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "font-size": "24px",
                                    color: "#F56c6c",
                                    cursor: "pointer"
                                  },
                                  on: { click: _vm.cancelSort }
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-check",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "font-size": "24px",
                                    color: "#13ce66",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveSort(scope.row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: {
                                  padding: "5px 10px",
                                  margin: "5px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortEdit(scope.row, scope.$index)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.sequence))]
                            )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "是否启用", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66"
                          },
                          on: {
                            change: function($event) {
                              return _vm.saveStatus(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.status,
                            callback: function($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "display_flex" },
                          [
                            _c(
                              "el-link",
                              {
                                staticClass: "margin_right_10",
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.showAddDia("编辑", scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-link",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteStore(scope.row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.diaType + "发货仓",
                visible: _vm.addVisible,
                width: "800px",
                "before-close": _vm.addClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.addLoading,
                      expression: "addLoading"
                    }
                  ],
                  staticClass: "dia_body"
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.query, "label-width": "80px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "仓库编号",
                            prop: "code",
                            rules: [
                              { required: true, message: "仓库编号不能为空" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入仓库编号" },
                            model: {
                              value: _vm.query.code,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "code", $$v)
                              },
                              expression: "query.code"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "仓库名称",
                            prop: "name",
                            rules: [
                              { required: true, message: "仓库名称不能为空" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入仓库名称" },
                            model: {
                              value: _vm.query.name,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "name", $$v)
                              },
                              expression: "query.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货人",
                            prop: "userName",
                            rules: [
                              { required: true, message: "收货人不能为空" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入收货人姓名" },
                            model: {
                              value: _vm.query.userName,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "userName", $$v)
                              },
                              expression: "query.userName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联系电话",
                            prop: "userPhone",
                            rules: [
                              { required: true, message: "联系电话不能为空" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入联系电话" },
                            model: {
                              value: _vm.query.userPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "userPhone", $$v)
                              },
                              expression: "query.userPhone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货地址",
                            prop: "address",
                            rules: [
                              { required: true, message: "收货地址不能为空" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input_width",
                            attrs: { placeholder: "请输入收货地址" },
                            model: {
                              value: _vm.query.address,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "address", $$v)
                              },
                              expression: "query.address"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "行政区划",
                            prop: "regionList",
                            rules: [
                              { required: true, message: "行政区划不能为空" }
                            ]
                          }
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "input_width",
                            attrs: {
                              props: _vm.props,
                              placeholder: "请选择所属省市",
                              options: _vm.options
                            },
                            model: {
                              value: _vm.query.regionList,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "regionList", $$v)
                              },
                              expression: "query.regionList"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否启用", prop: "status" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66"
                            },
                            model: {
                              value: _vm.query.status,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "status", $$v)
                              },
                              expression: "query.status"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序", prop: "sequence" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 0,
                              "controls-position": "right",
                              min: 0,
                              size: "small"
                            },
                            model: {
                              value: _vm.query.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "sequence", $$v)
                              },
                              expression: "query.sequence"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.addClose } },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.addLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }