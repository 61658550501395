var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("original-tag-select", {
    attrs: {
      "is-disabled": _vm.isDisabled,
      placeholder: _vm.placeholder,
      multiple: _vm.multiple
    },
    model: {
      value: _vm.valueProxy,
      callback: function($$v) {
        _vm.valueProxy = $$v
      },
      expression: "valueProxy"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }