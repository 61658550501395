var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("photo-wall", {
    attrs: {
      "disabled-edit": _vm.disabledEdit,
      "image-arr": _vm.imageList,
      "cover-arr": _vm.coverArr,
      "atlas-arr-text": _vm.atlasArrText,
      isDisableUpload: _vm.isDisableUpload,
      "oss-upload-function": _vm.ossUploadFunction,
      path: _vm.path,
      resourceType: "15",
      "preview-z-index": _vm.previewZIndex,
      "limit-num": _vm.limitNum
    },
    on: {
      deletedImage: _vm.deletedImage,
      replaceImage: _vm.replaceImage,
      getImage: _vm.getImage
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }