"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../api/common/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 调用这个组件的时候，通过 @phoneCode 来实时获取输入的手机验证码
 */
var _default = {
  name: 'VerifyVue',
  data: function data() {
    return {
      userInfo: null,
      code: '',
      sendText: '获取验证码',
      sendDisabled: false
    };
  },
  watch: {
    code: function code(newVal) {
      this.$emit('phone-code', newVal);
    }
  },
  created: function created() {
    this.initDate();
  },
  methods: {
    initDate: function initDate() {
      this.userInfo = this.$store.getters.userInfo;
    },
    keyupEnterHandle: function keyupEnterHandle() {
      this.$emit('keyupEnter');
    },
    // 获取验证码
    getCodeBtn: function getCodeBtn() {
      var _this2 = this;

      this.sendDisabled = true;
      clearInterval(timer);
      var timeDown = 60;
      this.sendText = "".concat(timeDown, "\u79D2\u540E\u91CD\u53D1");

      var _this = this;

      var timer = setInterval(function () {
        timeDown--;
        _this.sendText = "".concat(timeDown, "\u79D2\u540E\u91CD\u53D1");

        if (timeDown <= 0) {
          clearInterval(timer);
          _this.sendDisabled = false;
          _this.sendText = '获取验证码';
        }
      }, 1000);
      (0, _index.getCode)({
        phoneNumber: this.userInfo.phone
      }).then(function (res) {
        if (res.success) {
          _this2.$message({
            message: '验证码已发送',
            type: 'success'
          });
        }
      });
    },
    // 绑定手机号
    bingPhone: function bingPhone() {
      this.$router.push({
        path: '/account/infoBase'
      });
    }
  }
};
exports.default = _default;