var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "batch-question-attributes",
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "lock-scroll": false,
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        title: _vm.title,
        "append-to-body": "",
        width: "1000px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "question" },
        [
          _c("div", { staticClass: "common-title question-nav" }, [
            _c("span", [
              _vm._v("已选"),
              _c("span", { staticStyle: { color: "#409EFF" } }, [
                _vm._v(
                  _vm._s(!_vm.isPaper ? _vm.checkList.length : _vm.totalVal)
                )
              ]),
              _vm._v("题")
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "nav-check" },
              [
                _c("el-checkbox", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPaper,
                      expression: "isPaper"
                    }
                  ],
                  staticClass: "checkbox",
                  on: { change: _vm.changeDefaultAll },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPaper,
                        expression: "isPaper"
                      }
                    ]
                  },
                  [_vm._v("默认全选")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          !_vm.isPaper
            ? _c("attribute-question-item", {
                staticClass: "question-list",
                attrs: { list: _vm.list },
                on: { changeGroup: _vm.changeGroup },
                model: {
                  value: _vm.checkList,
                  callback: function($$v) {
                    _vm.checkList = $$v
                  },
                  expression: "checkList"
                }
              })
            : _c(
                "div",
                { staticClass: "paper" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: [
                        _vm.getShowCollapse(item.examPaperQuestionsList)
                          ? "paper-item"
                          : ""
                      ]
                    },
                    [
                      _vm.getShowCollapse(item.examPaperQuestionsList)
                        ? _c(
                            "el-collapse",
                            {
                              model: {
                                value: _vm.activeNames,
                                callback: function($$v) {
                                  _vm.activeNames = $$v
                                },
                                expression: "activeNames"
                              }
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: item.id } },
                                [
                                  _c(
                                    "template",
                                    { slot: "title" },
                                    [
                                      _c("el-checkbox", {
                                        staticClass: "check-name",
                                        on: {
                                          change: function($event) {
                                            return _vm.handlePaperCheck(
                                              $event,
                                              index
                                            )
                                          }
                                        },
                                        model: {
                                          value: item.check,
                                          callback: function($$v) {
                                            _vm.$set(item, "check", $$v)
                                          },
                                          expression: "item.check"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.name
                                              ? item.name
                                              : "题组" + (index + 1)
                                          ) +
                                          "\n              "
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("attribute-question-item", {
                                    staticClass: "group-list",
                                    attrs: {
                                      "is-paper": true,
                                      list: item.examPaperQuestionsList
                                    },
                                    on: {
                                      changeGroup: function($event) {
                                        return _vm.changeGroup($event, index)
                                      }
                                    },
                                    model: {
                                      value: item.checkList,
                                      callback: function($$v) {
                                        _vm.$set(item, "checkList", $$v)
                                      },
                                      expression: "item.checkList"
                                    }
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "attributes" },
        [
          _c("div", { staticClass: "common-title" }, [_vm._v("属性")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "attributes-form",
              attrs: { model: _vm.form, size: "small" }
            },
            [
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isSubject,
                            callback: function($$v) {
                              _vm.isSubject = $$v
                            },
                            expression: "isSubject"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("学科")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("select-param-sync-info", {
                      staticClass: "sync-width",
                      attrs: {
                        disabled: !_vm.isSubject,
                        "query-option": "FindSubjectList",
                        placeholder: "学科"
                      },
                      model: {
                        value: _vm.form.subjectCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "subjectCode", $$v)
                        },
                        expression: "form.subjectCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isGrade,
                            callback: function($$v) {
                              _vm.isGrade = $$v
                            },
                            expression: "isGrade"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("年级")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "sync-width",
                        attrs: {
                          disabled: !_vm.isGrade,
                          multiple: "",
                          clearable: "",
                          placeholder: "年级"
                        },
                        model: {
                          value: _vm.form.gradeCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "gradeCode", $$v)
                          },
                          expression: "form.gradeCode"
                        }
                      },
                      _vm._l(_vm.gradeList, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.code }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isVolume,
                            callback: function($$v) {
                              _vm.isVolume = $$v
                            },
                            expression: "isVolume"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("阶段")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "sync-width",
                        attrs: {
                          disabled: !_vm.isVolume,
                          multiple: "",
                          clearable: "",
                          placeholder: "阶段"
                        },
                        model: {
                          value: _vm.form.volumeCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "volumeCode", $$v)
                          },
                          expression: "form.volumeCode"
                        }
                      },
                      _vm._l(_vm.volumeList, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.code }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isKnowledge,
                            callback: function($$v) {
                              _vm.isKnowledge = $$v
                            },
                            expression: "isKnowledge"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("知识点")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-cascader", {
                      staticClass: "sync-width",
                      attrs: {
                        disabled: !_vm.isKnowledge,
                        placeholder: "知识点",
                        options: _vm.knowledgeList,
                        props: _vm.knowledgeProps,
                        clearable: ""
                      },
                      on: { "visible-change": _vm.checkSubject },
                      model: {
                        value: _vm.form.knowledgeOpenIdList,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "knowledgeOpenIdList", $$v)
                        },
                        expression: "form.knowledgeOpenIdList"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isAreaId,
                            callback: function($$v) {
                              _vm.isAreaId = $$v
                            },
                            expression: "isAreaId"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("地区")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("select-param-sync-info", {
                      staticClass: "sync-width",
                      attrs: {
                        disabled: !_vm.isAreaId,
                        "query-option": "FindArea",
                        placeholder: "地区"
                      },
                      model: {
                        value: _vm.form.bookAreaId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bookAreaId", $$v)
                        },
                        expression: "form.bookAreaId"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isPeriod,
                            callback: function($$v) {
                              _vm.isPeriod = $$v
                            },
                            expression: "isPeriod"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("版次")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("select-param-sync-info", {
                      staticClass: "sync-width",
                      attrs: {
                        disabled: !_vm.isPeriod,
                        "query-option": "FindAll",
                        placeholder: "版次"
                      },
                      model: {
                        value: _vm.form.periodCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "periodCode", $$v)
                        },
                        expression: "form.periodCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "common-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "common-label" },
                      [
                        _c("el-checkbox", {
                          attrs: { name: "type" },
                          model: {
                            value: _vm.isCategory,
                            callback: function($$v) {
                              _vm.isCategory = $$v
                            },
                            expression: "isCategory"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check-label" }, [
                          _vm._v("分类")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-cascader", {
                      staticClass: "sync-width",
                      attrs: {
                        disabled: !_vm.isCategory,
                        placeholder: "请选择分类",
                        options: _vm.questionType,
                        props: _vm.classProps
                      },
                      model: {
                        value: _vm.form.categoryOpenId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "categoryOpenId", $$v)
                        },
                        expression: "form.categoryOpenId"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }