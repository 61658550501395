var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "operating-video"
    },
    [
      _c(
        "div",
        { staticClass: "page-addvideo box" },
        [
          _c(
            "el-form",
            {
              ref: "videoInfo",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.videoInfo,
                rules: _vm.rules,
                "status-icon": "",
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-addvideo-page" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          基本信息\n          "),
                    _vm.videoId && _vm.videoInfo.state
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.videoInfo[_vm.statusList[0].dateName]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.videoInfo[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.videoInfo.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.videoInfo.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.videoInfo.resourceOpenId
                    ? _c("el-form-item", { attrs: { label: "作品ID" } }, [
                        _vm.videoInfo.resourceOpenId
                          ? _c("span", { staticClass: "title-id" }, [
                              _vm._v(_vm._s(_vm.videoInfo.resourceOpenId))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "视频名称", prop: "title" } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "input-box",
                        attrs: {
                          disabled: _vm.disabledEdit,
                          "show-word-limit": "",
                          maxlength: 100,
                          debounce: 500,
                          placeholder:
                            "请输入视频名称（建议字数在30以内，不超过60个字）",
                          "fetch-suggestions": _vm.searchTitle
                        },
                        model: {
                          value: _vm.videoInfo.title,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "title", $$v)
                          },
                          expression: "videoInfo.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品编号" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          disabled: _vm.disabledEdit,
                          placeholder: "请输入作品编号",
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.videoInfo.resourceEncode,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "resourceEncode", $$v)
                          },
                          expression: "videoInfo.resourceEncode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "视频上传", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "checkradio" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("本地上传")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.isVidUpload,
                                callback: function($$v) {
                                  _vm.isVidUpload = $$v
                                },
                                expression: "isVidUpload"
                              }
                            },
                            [_vm._v("VID上传")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isVidUpload === "1",
                              expression: "isVidUpload === '1'"
                            }
                          ],
                          staticClass: "card-lz-content upload-video"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "display-flex" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "vid" } },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    attrs: { value: _vm.videoInfo.vid }
                                  }),
                                  _vm._v(" "),
                                  _c("upload-file", {
                                    ref: "uploadVideo",
                                    attrs: {
                                      disabledEdit: _vm.disabledEdit,
                                      types: "video",
                                      videourl: _vm.videourl,
                                      videoid: _vm.videoInfo.vid
                                    },
                                    on: {
                                      startUpload: _vm.startVideoUpload,
                                      getResult: _vm.getVideo,
                                      "delect-event": _vm.delectevent
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "display-flex video-duration-text"
                                },
                                [
                                  _c("span", { staticClass: "video-text" }, [
                                    _c("i", {
                                      staticClass: "el-icon-info",
                                      staticStyle: { color: "#409EFF" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "\n                    格式支持MP4，大小不超过2G，建议视频分辨率为\n                    "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "     1280×720\n                  "
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm.videoInfo.vid
                                    ? _c(
                                        "span",
                                        { staticClass: "video-vid-preview" },
                                        [
                                          _vm._v(
                                            "\n                  VID: " +
                                              _vm._s(_vm.videoInfo.vid) +
                                              "\n                  "
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "tag-read",
                                              attrs: {
                                                type: "text",
                                                "data-clipboard-text":
                                                  _vm.videoInfo.vid
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.copy()
                                                }
                                              }
                                            },
                                            [_vm._v("复制")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: { click: _vm.videoPreviewFun }
                                            },
                                            [_vm._v("预览")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isVidUpload === "2"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "temporaryVid" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "temp-vid-box" },
                                    [
                                      _c("el-input", {
                                        staticClass: "temp-vid-txt",
                                        attrs: {
                                          disabled: _vm.disabledEdit,
                                          placeholder: "请输入视频vid"
                                        },
                                        model: {
                                          value: _vm.videoInfo.temporaryVid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.videoInfo,
                                              "temporaryVid",
                                              $$v
                                            )
                                          },
                                          expression: "videoInfo.temporaryVid"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "btn-verify",
                                          attrs: {
                                            disabledEdit: _vm.disabledEdit
                                          },
                                          on: { click: _vm.checkTempVid }
                                        },
                                        [_vm._v("校验")]
                                      ),
                                      _vm._v(" "),
                                      _vm.videourl
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "btn-preview",
                                              attrs: {
                                                disabledEdit: _vm.disabledEdit
                                              },
                                              on: { click: _vm.videoPreviewFun }
                                            },
                                            [_vm._v("预览")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.videoInfo.temporaryVid
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "tag-read",
                                              attrs: {
                                                "data-clipboard-text":
                                                  _vm.videoInfo.temporaryVid,
                                                disabledEdit: _vm.disabledEdit
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.copy()
                                                }
                                              }
                                            },
                                            [_vm._v("复制")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.videoInfo.temporaryVid
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "btn-del",
                                              attrs: {
                                                disabledEdit: _vm.disabledEdit
                                              },
                                              on: { click: _vm.delTempVid }
                                            },
                                            [_vm._v("移除")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "预览时长" } }, [
                    _c(
                      "div",
                      { staticClass: "display-flex video-duration" },
                      [
                        _c("el-input-number", {
                          attrs: {
                            precision: 0,
                            step: 1,
                            max: _vm.maxPreviewLength,
                            min: 0,
                            disabled:
                              _vm.disabledEdit ||
                              _vm.videourl.toString().length <= 0,
                            "controls-position": "right"
                          },
                          model: {
                            value: _vm.previewLength,
                            callback: function($$v) {
                              _vm.previewLength = $$v
                            },
                            expression: "previewLength"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "line" }, [_vm._v("秒")])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "视频打点" } }, [
                    _c("div", { staticClass: "dot" }, [
                      _c(
                        "div",
                        { staticClass: "dot-title" },
                        [
                          _c("el-switch", {
                            attrs: {
                              disabled: _vm.disabledEdit,
                              "active-color": "#13ce66",
                              "inactive-color": "#DEDEE4"
                            },
                            model: {
                              value: _vm.videoInfo.videoMarkEnable,
                              callback: function($$v) {
                                _vm.$set(_vm.videoInfo, "videoMarkEnable", $$v)
                              },
                              expression: "videoInfo.videoMarkEnable"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("video-dot", {
                            attrs: {
                              videoMarkEnable: _vm.videoInfo.videoMarkEnable,
                              disabledEdit: _vm.disabledEdit,
                              videourl: _vm.videourl,
                              "resource-title": _vm.videoInfo.title,
                              markData: _vm.markData.content,
                              videoId: _vm.videoId
                            },
                            on: {
                              getMarkList: _vm.getMarkList,
                              "update:markData": function($event) {
                                return _vm.$set(_vm.markData, "content", $event)
                              },
                              "update:mark-data": function($event) {
                                return _vm.$set(_vm.markData, "content", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "视频封面" } },
                    [
                      _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          disabledEdit: _vm.disabledEdit,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          isShowUpdateCover: _vm.isShowUpdateCover,
                          handlerGetNewCover: _vm.updateVideoCover,
                          type: "1"
                        },
                        on: { deletecover: _vm.deletecoverFun },
                        model: {
                          value: _vm.videoInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "cover", $$v)
                          },
                          expression: "videoInfo.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-text" }, [
                    _vm._v("拓展学习栏目")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "栏目内容" } },
                    [
                      _c("extended-learning", {
                        ref: "extendedLearning",
                        staticClass: "extended-learning",
                        attrs: { disable: _vm.disabledEdit },
                        model: {
                          value: _vm.videoInfo.testData,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "testData", $$v)
                          },
                          expression: "videoInfo.testData"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("资源附件")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "资源栏目名" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          clearable: "",
                          placeholder: "请输入资源栏目名"
                        },
                        model: {
                          value: _vm.videoInfo.columnTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "columnTitle", $$v)
                          },
                          expression: "videoInfo.columnTitle"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "文件上传" } }, [
                    _c(
                      "div",
                      { staticClass: "txt-width850 attachment-tab" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "header-row-style": { height: "30px" },
                              "header-cell-style": { background: "#F5F7FA" },
                              data: _vm.attachmentList,
                              border: ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "作品名",
                                "min-width": "240"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(scope.row.display.title) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "作品编号", width: "300" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "resource-encode-flex" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "resource-encode" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.key.resourceEncode
                                                    ? scope.row.key
                                                        .resourceEncode
                                                    : "--"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-copy encode-copy-icon",
                                            on: {
                                              click: function($event) {
                                                return _vm.copyResourceEncode(
                                                  scope.row.key.resourceEncode,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "100" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#409EFF" },
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.previewResource(
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("预览")]
                                      ),
                                      _vm._v(" "),
                                      !_vm.disabledEdit
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                color: "rgb(245, 108, 108)"
                                              },
                                              attrs: {
                                                type: "text",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("移除")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "btnBox" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.disabledEdit,
                                  size: "small",
                                  type: "primary",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.outerVisible = true
                                  }
                                }
                              },
                              [_vm._v("选择文件")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.disabledEdit,
                                  size: "small",
                                  type: "danger",
                                  plain: ""
                                },
                                on: { click: _vm.delAll }
                              },
                              [_vm._v("全部删除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "视频文稿", prop: "detail" } },
                    [
                      _c("editor", {
                        attrs: { readonly: _vm.disabledEdit },
                        model: {
                          value: _vm.videoInfo.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "detail", $$v)
                          },
                          expression: "videoInfo.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("作品属性")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同步" } },
                    [
                      _c("sync-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.disabledEdit },
                        model: {
                          value: _vm.videoInfo.tempCode,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "tempCode", $$v)
                          },
                          expression: "videoInfo.tempCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "types" } },
                    [
                      _c("works", {
                        ref: "worksView",
                        attrs: { isDisabled: _vm.disabledEdit },
                        on: { "add-classtion": _vm.addclasstion }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { value: _vm.videoInfo.types }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版次" } },
                    [
                      _c("edition-box", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.disabledEdit },
                        model: {
                          value: _vm.videoInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "periodCode", $$v)
                          },
                          expression: "videoInfo.periodCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.videoInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "periodCode", $$v)
                          },
                          expression: "videoInfo.periodCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "类目", prop: "secondRegimentationIds" }
                    },
                    [
                      _c("category-view", {
                        staticStyle: { width: "850px" },
                        attrs: { "is-disabled": _vm.disabledEdit },
                        on: { "category-change": _vm.categoryChange },
                        model: {
                          value: _vm.videoInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.videoInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "videoInfo.secondRegimentationIds"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.videoInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.videoInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "videoInfo.secondRegimentationIds"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "property-box" },
                    [
                      _c("el-collapse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowProperty,
                                expression: "isShowProperty"
                              }
                            ],
                            staticClass: "property-item-bg"
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地区" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.disabledEdit,
                                      multiple: "",
                                      filterable: "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择地区"
                                    },
                                    model: {
                                      value: _vm.areaValue,
                                      callback: function($$v) {
                                        _vm.areaValue = $$v
                                      },
                                      expression: "areaValue"
                                    }
                                  },
                                  _vm._l(_vm.areaList, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.disabledEdit,
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择商品标签"
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.tagValue,
                                      callback: function($$v) {
                                        _vm.tagValue = $$v
                                      },
                                      expression: "tagValue"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        class: [
                          "property-item-arrow",
                          _vm.isShowProperty
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        ],
                        on: {
                          click: function($event) {
                            _vm.isShowProperty = !_vm.isShowProperty
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("商品设置")]),
                  _vm._v(" "),
                  _c("goods-set", {
                    ref: "commodity",
                    attrs: {
                      disabledEdit: _vm.disabledEdit,
                      "type-name": _vm.typeName,
                      partnerOpenId: _vm.partnerOpenId,
                      userOpenId: _vm.videoInfo.userOpenId,
                      "independent-sale": _vm.videoInfo.independentSale,
                      "resource-open-id": _vm.videoId
                    },
                    on: { "official-partner": _vm.getOfficialPartner }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.disabledEdit
                ? _c(
                    "el-form-item",
                    { staticClass: "card-lz-bottom display-flex box" },
                    [
                      _c("el-button", { on: { click: _vm.closeView } }, [
                        _vm._v("返回")
                      ]),
                      _vm._v(" "),
                      !_vm.showRestore
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.disabledEdit = false
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isUnderReview
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.batchReview }
                            },
                            [_vm._v("通过")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isUnderReview
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  _vm.dialogRefuse = true
                                }
                              }
                            },
                            [_vm._v("驳回")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isRelease
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                loading: _vm.submittow,
                                type: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveRelease("videoInfo")
                                }
                              }
                            },
                            [_vm._v("发布")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showRestore
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.setRestoreVideo }
                            },
                            [_vm._v("恢复")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { staticClass: "card-lz-bottom display-flex box" },
                    [
                      _c("el-button", { on: { click: _vm.oncancel } }, [
                        _vm._v("取消")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.submitone, type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit("videoInfo")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.onSubmitText))]
                      ),
                      _vm._v(" "),
                      this.closepath === "Newvideo"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                loading: _vm.submittow,
                                type: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveRelease("videoInfo")
                                }
                              }
                            },
                            [_vm._v("保存并发布")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                loading: _vm.submittow,
                                type: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveRelease("videoInfo")
                                }
                              }
                            },
                            [_vm._v("发布")]
                          )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.classification,
                title: "提示",
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.classification = $event
                }
              }
            },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.data,
                  props: _vm.defaultProps,
                  "check-strictly": true,
                  "highlight-current": true,
                  "expand-on-click-node": false,
                  "default-expand-all": "",
                  "show-checkbox": "",
                  "node-key": "id"
                },
                on: {
                  "check-change": _vm.checkChange,
                  "node-click": _vm.nodeClick
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.classification = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.classification = false
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("refuse-resource", {
        on: { "refuse-obj": _vm.refuseObj },
        model: {
          value: _vm.dialogRefuse,
          callback: function($$v) {
            _vm.dialogRefuse = $$v
          },
          expression: "dialogRefuse"
        }
      }),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.outerVisible },
        on: {
          "update:visible": function($event) {
            _vm.outerVisible = $event
          }
        },
        model: {
          value: _vm.attachmentObj,
          callback: function($$v) {
            _vm.attachmentObj = $$v
          },
          expression: "attachmentObj"
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.openPaper
        ? _c("select-test-paper", {
            attrs: { dialogVisible: _vm.openPaper, "show-type": "add" },
            on: {
              "update:dialogVisible": function($event) {
                _vm.openPaper = $event
              },
              "update:dialog-visible": function($event) {
                _vm.openPaper = $event
              },
              handleClose: function($event) {
                _vm.openPaper = false
              },
              addSuccess: _vm.addSuccess
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }