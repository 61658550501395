var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "my-menu-title" }, [
        _c("div", { staticClass: "title-left" }, [
          _vm._v("\n      目录\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title-right" }, [
          _c("i", { staticClass: "el-icon-plus", on: { click: _vm.add_menu } }),
          _vm._v(" "),
          _c("i", { staticClass: "el-icon-s-unfold" })
        ])
      ]),
      _vm._v(" "),
      _vm.MenuList.length > 0
        ? _c(
            "div",
            { staticClass: "menu-box" },
            [
              _c(
                "draggable",
                {
                  staticClass: "menu-list",
                  attrs: {
                    list: _vm.MenuList,
                    move: _vm.move,
                    disabled: !_vm.canEdit
                  },
                  on: {
                    start: function($event) {
                      return _vm.start()
                    },
                    end: _vm.end
                  }
                },
                _vm._l(_vm.MenuList, function(item, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "first-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-main",
                          class: {
                            "selected-item": _vm.selectedMenuIndex === item.id,
                            active: _vm.hoverMenuIndex === item.id
                          },
                          on: {
                            mouseover: function($event) {
                              _vm.hoverMenuIndex = item.id
                            },
                            mouseout: function($event) {
                              _vm.hoverMenuIndex = -1
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item-title",
                              on: {
                                click: function($event) {
                                  return _vm.selectItem(
                                    item,
                                    1,
                                    item.pageSequence
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.hoverMenuIndex !== item.id &&
                                    item.pageId,
                                  expression:
                                    "hoverMenuIndex!==item.id && item.pageId"
                                }
                              ],
                              staticClass: "item-ps"
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.hoverMenuIndex === item.id,
                                  expression: "hoverMenuIndex===item.id"
                                }
                              ],
                              staticClass: "item-operation"
                            },
                            [
                              _c("el-button", {
                                staticClass: "operation-icon",
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  icon: "el-icon-plus",
                                  disabled: !_vm.canEdit
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.add_menu(item.id, item.title)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "operation-icon",
                                  attrs: {
                                    type: "text",
                                    size: "medium",
                                    disabled: !_vm.canEdit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.edit_menu(item, key)
                                    }
                                  }
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "option",
                                    attrs: { "icon-class": "edit" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-button", {
                                staticClass: "operation-icon",
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  disabled: !_vm.canEdit,
                                  icon: "el-icon-delete"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.delete_menu(item, key)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.SubMenuList[item.id]
                        ? _c(
                            "draggable",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.expandMenuIndex[item.id],
                                  expression: "expandMenuIndex[item.id]"
                                }
                              ],
                              attrs: { list: _vm.SubMenuList[item.id] },
                              on: {
                                start: function($event) {
                                  return _vm.start(item.id)
                                },
                                end: _vm.end
                              }
                            },
                            _vm._l(_vm.SubMenuList[item.id], function(
                              second_item,
                              second_key
                            ) {
                              return _c(
                                "div",
                                { key: second_key, staticClass: "second-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-main",
                                      class: {
                                        "selected-item":
                                          _vm.selectedMenuIndex ===
                                          second_item.id,
                                        active:
                                          _vm.hoverMenuIndex === second_item.id
                                      },
                                      on: {
                                        mouseover: function($event) {
                                          _vm.hoverMenuIndex = second_item.id
                                        },
                                        mouseout: function($event) {
                                          _vm.hoverMenuIndex = -1
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item-title second-title",
                                          on: {
                                            click: function($event) {
                                              return _vm.selectItem(
                                                second_item,
                                                2,
                                                second_item.pageSequence
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(second_item.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.hoverMenuIndex !==
                                                  second_item.id &&
                                                second_item.pageId,
                                              expression:
                                                "hoverMenuIndex!==second_item.id && second_item.pageId"
                                            }
                                          ],
                                          staticClass: "item-ps"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.hoverMenuIndex ===
                                                second_item.id,
                                              expression:
                                                "hoverMenuIndex===second_item.id"
                                            }
                                          ],
                                          staticClass: "item-operation"
                                        },
                                        [
                                          _c("el-button", {
                                            staticClass: "operation-icon",
                                            attrs: {
                                              type: "text",
                                              size: "medium",
                                              icon: "el-icon-plus"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.add_menu(
                                                  second_item.id,
                                                  second_item.title
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "operation-icon",
                                              attrs: {
                                                type: "text",
                                                size: "medium"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.edit_menu(
                                                    second_item,
                                                    second_key
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "option",
                                                attrs: { "icon-class": "edit" }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-button", {
                                            staticClass: "operation-icon",
                                            attrs: {
                                              type: "text",
                                              size: "medium",
                                              icon: "el-icon-delete"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.delete_menu(
                                                  second_item,
                                                  second_key
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.SubMenuList[second_item.id]
                                    ? _c(
                                        "draggable",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.expandMenuIndex[
                                                  second_item.id
                                                ],
                                              expression:
                                                "expandMenuIndex[second_item.id]"
                                            }
                                          ],
                                          attrs: {
                                            list:
                                              _vm.SubMenuList[second_item.id]
                                          },
                                          on: {
                                            start: function($event) {
                                              return _vm.start(second_item.id)
                                            },
                                            end: _vm.end
                                          }
                                        },
                                        _vm._l(
                                          _vm.SubMenuList[second_item.id],
                                          function(third_item, third_key) {
                                            return _c(
                                              "div",
                                              {
                                                key: third_key,
                                                staticClass: "second-item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "item-main",
                                                    class: {
                                                      "selected-item":
                                                        _vm.selectedMenuIndex ===
                                                        third_item.id,
                                                      active:
                                                        _vm.hoverMenuIndex ===
                                                        third_item.id
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        _vm.hoverMenuIndex =
                                                          third_item.id
                                                      },
                                                      mouseout: function(
                                                        $event
                                                      ) {
                                                        _vm.hoverMenuIndex = -1
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-title third-title",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectItem(
                                                              third_item,
                                                              3,
                                                              third_item.pageSequence
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            third_item.title
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.hoverMenuIndex !==
                                                                third_item.id &&
                                                              third_item.pageId,
                                                            expression:
                                                              "hoverMenuIndex!==third_item.id && third_item.pageId"
                                                          }
                                                        ],
                                                        staticClass: "item-ps"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-picture-outline"
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.hoverMenuIndex ===
                                                              third_item.id,
                                                            expression:
                                                              "hoverMenuIndex===third_item.id"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "item-operation"
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "operation-icon",
                                                            attrs: {
                                                              type: "text",
                                                              size: "medium"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.edit_menu(
                                                                  third_item,
                                                                  third_key
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              staticClass:
                                                                "option",
                                                              attrs: {
                                                                "icon-class":
                                                                  "edit"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("el-button", {
                                                          staticClass:
                                                            "operation-icon",
                                                          attrs: {
                                                            type: "text",
                                                            size: "medium",
                                                            icon:
                                                              "el-icon-delete"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.delete_menu(
                                                                third_item,
                                                                third_key
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "menu-box empty-menu", on: { click: _vm.add_menu } },
            [_c("div", [_vm._v("\n      请添加目录\n    ")])]
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            visible: _vm.dialogMenuVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "append-to-body": true,
            width: "450px",
            title: "编辑目录"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogMenuVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.tempMenu } },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": _vm.labelWidth, label: "父目录" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.parentMenuName,
                      callback: function($$v) {
                        _vm.parentMenuName = $$v
                      },
                      expression: "parentMenuName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "label-width": _vm.labelWidth, label: "目录名称" } },
                [
                  _c("el-input", {
                    ref: "titleInput",
                    model: {
                      value: _vm.tempMenu.title,
                      callback: function($$v) {
                        _vm.$set(_vm.tempMenu, "title", $$v)
                      },
                      expression: "tempMenu.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "label-width": _vm.labelWidth, label: "目录书页" } },
                [
                  _vm.dialogMenuVisible
                    ? _c("select-page", {
                        ref: "selectPageDialog",
                        attrs: { linkItem: _vm.tempMenu }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelEdit } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveMenu } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }