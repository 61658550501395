"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _search = _interopRequireDefault(require("@/components/SearchUser/search.vue"));

var _DetailList = _interopRequireDefault(require("./DetailList/DetailList"));

var _MicroClassification = _interopRequireDefault(require("./MicroClassification"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MicroDetail',
  components: {
    MicroClassification: _MicroClassification.default,
    DetailList: _DetailList.default,
    SearchPartner: _search.default
  },
  props: {
    partnerOpenId: {
      type: String,
      default: undefined
    },
    isPlatform: {
      type: Boolean,
      default: false
    },
    heightStyle: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      classOpenId: undefined,
      refresh: false,
      newPartnerOpenId: undefined,
      partnerInfo: {
        partnerLogo: undefined,
        partnerName: undefined,
        partnerModel: undefined
      }
    };
  },
  watch: {
    partnerOpenId: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.newPartnerOpenId = val;
        }
      }
    }
  },
  methods: {
    // 获取选择商户中 partnerOpenId
    receivePartnerId: function receivePartnerId(val) {
      this.newPartnerOpenId = val.partnerOpenId;
      this.partnerInfo.partnerLogo = val.partnerLogo;
      this.partnerInfo.partnerName = val.partnerName;
      this.partnerInfo.partnerModel = val.partnerModel;
    },
    classListRefresh: function classListRefresh() {
      this.$refs.classification.modifyRefresh();
    },
    refreshDetail: function refreshDetail(val, isPartner) {
      if (!isPartner) {
        if (!val && this.classOpenId === '-1') {
          return;
        }

        if (val && this.classOpenId === val) {
          return;
        }
      }

      this.refresh = true;
      this.classOpenId = val || '-1';
    }
  }
};
exports.default = _default;