var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "shop-home"
    },
    [
      _c("div", { staticClass: "nav" }, [_vm._v("应用首页")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.path
          ? _c("iframe", {
              ref: "iframe",
              staticClass: "iframe",
              attrs: { id: "iframe", src: _vm.path }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "detail" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.homeData.title))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "number" }, [
            _vm._v("模块数量："),
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.homeData.componentNum) + "个")
            ])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "description" }, [
            _vm._v("此处搭建为应用首页，可根据实际需求结合已有组件搭建首页"),
            _c("br"),
            _vm._v("也可根据当前营销活动，添加相应活动板块")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-button",
                {
                  staticClass: "home-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toMicroPage("app")
                    }
                  }
                },
                [_vm._v("搭建app主页")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.editLoading,
                    type: "primary",
                    plain: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.previewBtn("app")
                    }
                  }
                },
                [_vm._v("预览")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group btn-margin" },
            [
              _c(
                "el-button",
                {
                  staticClass: "home-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toMicroPage("web")
                    }
                  }
                },
                [_vm._v("搭建web主页")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.editLoading,
                    type: "primary",
                    plain: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.previewBtn("web")
                    }
                  }
                },
                [_vm._v("预览")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          "link-type": "microPage",
          visible: _vm.openPreview,
          "share-obj": _vm.shareObj
        },
        on: {
          "update:visible": function($event) {
            _vm.openPreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }