var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboardContainer" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.createLoading,
              expression: "createLoading"
            }
          ],
          staticClass: "codeWraper"
        },
        [
          _c("h3", { staticClass: "wrapTtile" }, [_vm._v("生成激活码")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "createContent" },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.codeForm,
                    "label-width": "100px",
                    "label-position": "top"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批次名称", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "432px" },
                        attrs: { placeholder: "请输入批次备注信息" },
                        model: {
                          value: _vm.codeForm.title,
                          callback: function($$v) {
                            _vm.$set(_vm.codeForm, "title", $$v)
                          },
                          expression: "codeForm.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "激活码数量", prop: "count" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "12px", color: "#909399" }
                        },
                        [_vm._v("数量上限：500,00")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入激活码数量（不大于50000）",
                          "controls-position": "right",
                          min: 1,
                          max: 50000
                        },
                        model: {
                          value: _vm.codeForm.count,
                          callback: function($$v) {
                            _vm.$set(_vm.codeForm, "count", $$v)
                          },
                          expression: "codeForm.count"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "picker-options": _vm.pickerOptions,
                          type: "datetimerange",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          align: "right"
                        },
                        on: {
                          change: function($event) {
                            return _vm.getlistApiinDate(_vm.codeForm.dateValue)
                          }
                        },
                        model: {
                          value: _vm.codeForm.dateValue,
                          callback: function($$v) {
                            _vm.$set(_vm.codeForm, "dateValue", $$v)
                          },
                          expression: "codeForm.dateValue"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "12px", color: "#909399" }
                        },
                        [
                          _c("div", [_vm._v("未设置：即可生效；")]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("设置开始时间：则自此时间起，永久有效；")
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("设置结束时间：则自此时间前，永久有效。")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "激活码限定" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.codeForm.duplicateUse,
                          callback: function($$v) {
                            _vm.$set(_vm.codeForm, "duplicateUse", $$v)
                          },
                          expression: "codeForm.duplicateUse"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "textInfo" }, [
                        _c("i", {
                          staticClass: "iconInfo el-icon-info",
                          staticStyle: { color: "#409EFF" }
                        }),
                        _vm._v(" "),
                        _vm.codeForm.duplicateUse
                          ? _c("span", [_vm._v("同账号同批次可叠加使用")])
                          : _c("span", [
                              _vm._v("同一账号同批次激活卡仅限使用一次")
                            ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "激活码标签", prop: "mothTitle" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "12px", color: "#909399" }
                        },
                        [
                          _vm._v(
                            "本标签用于标记使用本激活码的用户，可在用户基本信息中查看。"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请输入或选择标签"
                          },
                          model: {
                            value: _vm.codeForm.tags,
                            callback: function($$v) {
                              _vm.$set(_vm.codeForm, "tags", $$v)
                            },
                            expression: "codeForm.tags"
                          }
                        },
                        _vm._l(_vm.tagOptions, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.tagName, value: item.tagName }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "激活码名称", prop: "mothTitle" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入激活码名称" },
                        model: {
                          value: _vm.codeForm.mothTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.codeForm, "mothTitle", $$v)
                          },
                          expression: "codeForm.mothTitle"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用须知" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入激活码的使用规则"
                        },
                        model: {
                          value: _vm.codeForm.mothDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.codeForm, "mothDescription", $$v)
                          },
                          expression: "codeForm.mothDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "msgList" }, [
            _c("div", { staticClass: "msgHead" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", { staticClass: "icon" }),
                  _vm._v("激活清单\n          "),
                  _vm.tableData.length <= 5
                    ? _c("span", { staticClass: "countTips" }, [
                        _vm._v(_vm._s(_vm.tableData.length) + "/5")
                      ])
                    : _c("span", { staticClass: "countTips countTipsError" }, [
                        _vm._v(
                          _vm._s(_vm.tableData.length) +
                            "/5 仅限激活5个商品，请确认后生成激活码"
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "mini", plain: "", type: "primary" },
                      on: { click: _vm.addCommodityList }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-num" },
              [
                _c("span", [_vm._v("激活内容数")]),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    staticClass: "radio",
                    attrs: { label: true },
                    on: { change: _vm.changeRadio },
                    model: {
                      value: _vm.radio,
                      callback: function($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio"
                    }
                  },
                  [_vm._v("全部")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    staticClass: "radio",
                    attrs: { label: false },
                    model: {
                      value: _vm.radio,
                      callback: function($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio"
                    }
                  },
                  [_vm._v("部分")]
                ),
                _vm._v(" "),
                _c("el-input-number", {
                  staticClass: "num",
                  attrs: {
                    disabled: _vm.radio,
                    "controls-position": "right",
                    min: 1,
                    max: _vm.tableData.length
                  },
                  model: {
                    value: _vm.allowCount,
                    callback: function($$v) {
                      _vm.allowCount = $$v
                    },
                    expression: "allowCount"
                  }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-warning tooltip-icon" }),
                _vm._v(" "),
                _c("span", { staticClass: "tip" }, [
                  _vm._v(
                    "此激活码可以选择以下几项内容激活，默认为添加商品数（全部激活）"
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "msgContent" },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      "header-cell-style": {
                        background: "#F6F7FB",
                        color: "#606266"
                      }
                    },
                    on: { "selection-change": _vm.handleSelectionChange }
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c(
                        "div",
                        {
                          staticClass: "notHave",
                          on: { click: _vm.addCommodityList }
                        },
                        [
                          _vm._v(
                            "\n              点击此处以添加激活的商品\n            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "36" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "激活的商品", "min-width": "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm.commodityType(scope.row.resourceOpenId) === 2
                                ? _c(
                                    "div",
                                    { staticClass: "item" },
                                    [
                                      _c("el-image", {
                                        style: _vm.getResourceCoverStyle(
                                          scope.row.resourceType
                                        ),
                                        attrs: {
                                          src: _vm.getImgUrl(scope.row.cover),
                                          fit: "cover"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text" }, [
                                        _c("p", { staticClass: "title" }, [
                                          _vm._v(
                                            _vm._s(scope.row.resourceTitle)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "info" }, [
                                          _vm._v(_vm._s(scope.row.syncInfoText))
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "page" })
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.commodityType(scope.row.resourceOpenId) === 1
                                ? _c(
                                    "div",
                                    { staticClass: "item" },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "56px",
                                          flex: "none",
                                          "border-radius": "2px",
                                          overflow: "hidden"
                                        },
                                        attrs: {
                                          src:
                                            "https://open.oss.taozhi.online/develop/assets/taozhi/md-admin/vip.png",
                                          fit: "cover"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text" }, [
                                        _c("p", { staticClass: "title" }, [
                                          _vm._v(
                                            _vm._s(scope.row.resourceTitle)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "info" }, [
                                          _vm._v("VIP")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.commodityType(scope.row.resourceOpenId) === 0
                                ? _c(
                                    "div",
                                    { staticClass: "item" },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "56px",
                                          flex: "none",
                                          "border-radius": "2px",
                                          overflow: "hidden"
                                        },
                                        attrs: {
                                          src:
                                            "https://open.oss.taozhi.online/develop/assets/taozhi/md-admin/money.png",
                                          fit: "cover"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text" }, [
                                        _c("p", { staticClass: "title" }, [
                                          _vm._v(
                                            _vm._s(scope.row.resourceTitle)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "info" }, [
                                          _vm._v("金星币")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "商户", "min-width": "20" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm.partnerMap !== null &&
                              _vm.commodityType(scope.row.resourceOpenId) === 2
                                ? _c(
                                    "div",
                                    { staticClass: "user" },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "24px",
                                          height: "24px",
                                          "border-radius": "50%",
                                          overflow: "hidden"
                                        },
                                        attrs: {
                                          src: _vm.getPartnerImgUrl(scope.row),
                                          fit: "cover"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(_vm.getPartnerName(scope.row))
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "价格",
                        "min-width": "40",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", { staticClass: "price" }, [
                                _vm._v(
                                  "\n                ￥ " +
                                    _vm._s(scope.row.price / 100) +
                                    "\n              "
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "30",
                        "header-align": "right",
                        "show-overflow-tooltip": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "btns" },
                                [
                                  _vm.commodityType(
                                    scope.row.resourceOpenId
                                  ) === 2
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "btn",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.preview(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("预览")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.commodityType(
                                    scope.row.resourceOpenId
                                  ) === 2
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "btn",
                                          staticStyle: { margin: "0 10px" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.details(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn",
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeItem(
                                            scope.$index,
                                            _vm.tableData
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "removeBox" },
              [
                _c("el-checkbox", {
                  staticStyle: { margin: "0 15px 0 10px" },
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.removeCheckBoxBtn },
                  model: {
                    value: _vm.removeCheckBox,
                    callback: function($$v) {
                      _vm.removeCheckBox = $$v
                    },
                    expression: "removeCheckBox"
                  }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", type: "danger", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.removeMoreItems()
                      }
                    }
                  },
                  [_vm._v("全部移除")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "createFoot" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        path: "/application/activationCode"
                      })
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary", disabled: _vm.createLoading },
                  on: { click: _vm.createCodeBtn }
                },
                [_vm._v("生成")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.outerVisible
        ? _c("select-resource-dialog", {
            attrs: {
              visible: _vm.outerVisible,
              showMerchant: true,
              "expect-type": "activeCode",
              "exclude-data": _vm.tableData
            },
            on: {
              "update:visible": function($event) {
                _vm.outerVisible = $event
              },
              "add-select": _vm.onResourceSelected
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("resource-share", {
        attrs: { visible: _vm.openPreview, "share-obj": _vm.shareObj },
        on: {
          "update:visible": function($event) {
            _vm.openPreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }