"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _addCoupon = _interopRequireDefault(require("./addCoupon.vue"));

//
//
//
//
//
//
//
var _default = {
  name: 'editCoupon',
  components: {
    addCoupon: _addCoupon.default
  }
};
exports.default = _default;