var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "bookModel"
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.bookModelTitle,
            visible: _vm.bookModelDialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.bookModelDialogVisible = $event
            }
          }
        },
        [
          this.bookModelTitle !== "分组管理"
            ? _c(
                "el-form",
                {
                  ref: "bookModelRef",
                  attrs: {
                    model: _vm.bookModelForm,
                    "label-width": "60px",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.bookModelForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.bookModelForm, "name", $$v)
                          },
                          expression: "bookModelForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "70%" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.bookModelForm.sequence,
                              callback: function($$v) {
                                _vm.$set(_vm.bookModelForm, "sequence", $$v)
                              },
                              expression: "bookModelForm.sequence"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("\n            取 消\n          ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.submit("bookModelRef")
                                }
                              }
                            },
                            [_vm._v("\n            确 定\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.bookModelTitle === "分组管理"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                  },
                  attrs: { data: _vm.groupManagementArr, "max-height": "250" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "sequence",
                      label: "排序",
                      "min-width": "60"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "分组名称",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  scope.row.icon !== null
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "24px",
                                          height: "24px",
                                          "margin-right": "10px"
                                        },
                                        attrs: {
                                          src: _vm.getImgUrl(scope.row.icon),
                                          fit: "cover"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.name) +
                                      "\n          "
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1425327845
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      fixed: "right",
                      label: "操作",
                      width: "120"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.delGroupManagement(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("\n            移除\n          ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.editGroupManagement(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("\n            编辑\n          ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2000261753
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.bookModelTitle === "分组管理"
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "15px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addGroup }
                },
                [_vm._v("\n      添加分组\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.bookGroupTitle,
            visible: _vm.bookGroupDialogVisible,
            width: "500px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.bookGroupDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "bookModelRef",
              attrs: {
                model: _vm.bookModelForm,
                "label-width": "60px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.bookModelForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.bookModelForm, "name", $$v)
                      },
                      expression: "bookModelForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "图标" } }, [
                _c(
                  "div",
                  {
                    staticClass: "add-icon",
                    on: {
                      click: function($event) {
                        return _vm.uploadIcon(_vm.bookModelForm.icon)
                      }
                    }
                  },
                  [
                    !_vm.bookModelForm.icon
                      ? _c("i", {
                          staticClass: "el-icon-plus",
                          staticStyle: { width: "28px" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.bookModelForm.icon
                      ? _c("img", {
                          staticClass: "directory-icon",
                          attrs: {
                            onerror: _vm.errorUserPhoto,
                            src:
                              _vm.bookModelForm.icon.substring(0, 4) === "http"
                                ? _vm.bookModelForm.icon
                                : _vm.getImgUrl(_vm.bookModelForm.icon)
                          }
                        })
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "70%" },
                        attrs: { "controls-position": "right" },
                        model: {
                          value: _vm.bookModelForm.sequence,
                          callback: function($$v) {
                            _vm.$set(_vm.bookModelForm, "sequence", $$v)
                          },
                          expression: "bookModelForm.sequence"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-button", { on: { click: _vm.cancel } }, [
                        _vm._v("\n            取 消\n          ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submit("bookModelRef")
                            }
                          }
                        },
                        [_vm._v("\n            确 定\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "add-search" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addBookModel } },
            [_vm._v("\n      添加\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-box" },
            [
              _c("el-input", {
                staticClass: "search-input",
                attrs: { placeholder: "搜索关键词" },
                on: { input: _vm.handleSearch },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleBtnSearch($event)
                  }
                },
                model: {
                  value: _vm.query.name,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "name", $$v)
                  },
                  expression: "query.name"
                }
              }),
              _vm._v(" "),
              _c(
                "searchbtn",
                {
                  staticClass: "book-search",
                  on: { searchContent: _vm.searchContent }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "search-item-wrapper",
                      attrs: {
                        id: "elformBook",
                        "label-width": "70px",
                        size: "mini"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "check-item-box",
                              model: {
                                value: _vm.query.sortBy,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "sortBy", $$v)
                                },
                                expression: "query.sortBy"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "createdAt" } },
                                [
                                  _vm._v(
                                    "\n                默认排序\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "sequence" } },
                                [
                                  _vm._v(
                                    "\n                排序编号\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "升序降序" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "check-item-box",
                              model: {
                                value: _vm.query.sortOrder,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "sortOrder", $$v)
                                },
                                expression: "query.sortOrder"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "asc" } },
                                [
                                  _vm._v(
                                    "\n                升序\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "desc" } },
                                [
                                  _vm._v(
                                    "\n                降序\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableList" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: "100%" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "索引", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.query.page - 1) * _vm.query.size +
                                scope.$index +
                                1
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sequence", label: "排序", width: "90" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "名称", width: "180" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  "min-width": "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.handleDirectory(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("\n            目录扩展信息\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.groupManagement(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            分组管理\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.copyModel(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            复制\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.editRow(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.delRow(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            移除\n          ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.query.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalElements
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("catalog-extends", {
            attrs: {
              "book-mode-i-d": _vm.bookModeID,
              "dialog-visible": _vm.dialogVisible
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.dialogVisible = $event
              },
              "update:dialog-visible": function($event) {
                _vm.dialogVisible = $event
              },
              directoryDialogChange: _vm.directoryDialogChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("select-material-dialog", {
        attrs: {
          visible: _vm.openMaterial,
          "upload-path": _vm.uploadPath,
          "upload-function": _vm.uploadFunction
        },
        on: {
          submitMaterial: _vm.submitMaterial,
          "update:visible": function($event) {
            _vm.openMaterial = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }