var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.contentloading,
          expression: "loading.contentloading"
        }
      ],
      staticClass: "addAdmin"
    },
    [
      _c("div", { staticClass: "titleDes" }, [_vm._v("添加角色")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commonFlex", staticStyle: { "align-items": "center" } },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "14px",
                color: "#606266",
                "margin-right": "10px",
                width: "60px",
                "text-align": "right"
              }
            },
            [_vm._v("角色名称")]
          ),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请填写批次名称",
              maxlength: "50",
              "show-word-limit": ""
            },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = $$v
              },
              expression: "title"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "commonFlex",
          staticStyle: { "align-items": "center", margin: "20px 0" }
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "14px",
                color: "#606266",
                "margin-right": "10px",
                width: "60px",
                "text-align": "right"
              }
            },
            [_vm._v("英文名称")]
          ),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请填写英文名称",
              maxlength: "50",
              "show-word-limit": ""
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "commonFlex",
          staticStyle: { "align-items": "center", margin: "20px 0" }
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "14px",
                color: "#606266",
                "margin-right": "10px",
                width: "60px",
                "text-align": "right"
              }
            },
            [_vm._v("排序")]
          ),
          _vm._v(" "),
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.sequence,
              callback: function($$v) {
                _vm.sequence = $$v
              },
              expression: "sequence"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm.treeList.length
        ? _c(
            "div",
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading.treeList,
                    expression: "loading.treeList"
                  }
                ],
                ref: "selectTree",
                staticClass: "treeClass",
                attrs: {
                  "expand-on-click-node": false,
                  data: _vm.treeList,
                  props: _vm.defaultProps,
                  "show-checkbox": "",
                  "node-key": "privilegeOpenId",
                  "default-expand-all": "",
                  "default-checked-keys": _vm.defaultCheckedKeys
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c(
                            "span",
                            {
                              class:
                                data.parentOpenId && data.hidden
                                  ? "paddingLetSty"
                                  : "",
                              staticStyle: { "font-size": "14px" }
                            },
                            [_vm._v(_vm._s(node.label))]
                          )
                        ])
                      }
                    }
                  ],
                  null,
                  false,
                  2807299429
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "saveInfo" },
        [
          _c(
            "el-button",
            {
              staticClass: "goBack",
              on: {
                click: function($event) {
                  return _vm.pillarFlowBack()
                }
              }
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "saveBtn",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm()
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseInfo commonFlex" }, [
      _c("div", { staticClass: "commonShuSty" }),
      _c(
        "span",
        {
          staticStyle: {
            height: "16px",
            "line-height": "16px",
            color: "#303133",
            "font-weight": "bold"
          }
        },
        [_vm._v("基本信息")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseInfo commonFlex" }, [
      _c("div", { staticClass: "commonShuSty" }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticStyle: {
            height: "16px",
            "line-height": "16px",
            color: "#303133",
            "font-weight": "bold"
          }
        },
        [_vm._v("权限设置")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }