"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAfterSaleList = getAfterSaleList;
exports.getAfterSaleInfo = getAfterSaleInfo;
exports.getAfterSaleLog = getAfterSaleLog;
exports.afterSaleRefuse = afterSaleRefuse;
exports.returnConfirm = returnConfirm;
exports.receiptConfirm = receiptConfirm;
exports.auditConfirm = auditConfirm;
exports.refundConfirm = refundConfirm;
exports.batchAfterSale = batchAfterSale;
exports.getLogistic = getLogistic;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/admin/"), {
  timeout: 120000
});
/**
 * 获取售后列表
 * @returns {Promise<*>}
 */

function getAfterSaleList(params) {
  return request.get('afterSale/page', {
    params: params
  });
}
/**
 * 获取订单物流信息
 * @returns {Promise<*>}
 */


function getLogistic(params) {
  return request.get('afterSale/order/logistic', {
    params: params
  });
}
/**
 * 获取售后详情
 * @returns {Promise<*>}
 */


function getAfterSaleInfo(params) {
  return request.get('afterSale/info', {
    params: params
  });
}
/**
 * 获取售后详情操作日志
 * @returns {Promise<*>}
 */


function getAfterSaleLog(params) {
  return request.get('afterSale/log/list', {
    params: params
  });
}
/**
 * 获取售后详情操作日志
 * @returns {Promise<*>}
 */


function afterSaleRefuse(params) {
  return request.post('afterSale/refuse', params);
}
/**
 * 管理员确认退回信息，处理订单 mp
 * @returns {Promise<*>}
 */


function returnConfirm(params) {
  return request.post('afterSale/return/confirm', params);
}
/**
 * 管理员确认收货，进入待处理状态 mp
 * @returns {Promise<*>}
 */


function receiptConfirm(params) {
  return request.post('afterSale/receipt/confirm', params);
}
/**
 * 管理员审核售后单 mp
 * @returns {Promise<*>}
 */


function auditConfirm(params) {
  return request.post('afterSale/audit', params);
}
/**
 * 管理员退款售后单 mp
 * @returns {Promise<*>}
 */


function refundConfirm(params) {
  return request.post('afterSale/refund/confirm', params);
}
/**
 * 批量操作售后单 mp
 * @returns {Promise<*>}
 */


function batchAfterSale(params) {
  return request.post('afterSale/operate/batch', params);
}