var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teacher-wrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "tab-block"
      },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "border-card" },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              {
                staticClass: "tab-detail-bg",
                attrs: { label: "教师信息", name: "first" }
              },
              [
                _c(
                  "div",
                  { staticClass: "teacher-form-box" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "teacherDataForm",
                        staticClass: "tab-form",
                        attrs: {
                          model: _vm.teacherInfo,
                          rules: _vm.teacherRules,
                          "label-width": "80px"
                        }
                      },
                      [
                        _c("h3", { staticClass: "msg-title" }, [
                          _vm._v("基本信息")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "教师姓名", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "show-word-limit": "",
                                minlength: 2,
                                maxlength: 60,
                                placeholder: "请填写教师姓名"
                              },
                              model: {
                                value: _vm.teacherInfo.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "name", $$v)
                                },
                                expression: "teacherInfo.name"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "教师昵称", prop: "nickName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "show-word-limit": "",
                                maxlength: 60,
                                placeholder: "请填写教师昵称"
                              },
                              model: {
                                value: _vm.teacherInfo.nickName,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "nickName", $$v)
                                },
                                expression: "teacherInfo.nickName"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "教师形象", prop: "cover" } },
                          [
                            _c("cover-upload", {
                              attrs: {
                                path: _vm.coverPath,
                                coverarr: _vm.coverArr,
                                covertext: _vm.coverText,
                                type: _vm.Teacher_ID.toString()
                              },
                              on: { deletecover: _vm.delCoverFun },
                              model: {
                                value: _vm.teacherInfo.cover,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "cover", $$v)
                                },
                                expression: "teacherInfo.cover"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              model: {
                                value: _vm.teacherInfo.cover,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "cover", $$v)
                                },
                                expression: "teacherInfo.cover"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "教师背景", prop: "backgroundPic" }
                          },
                          [
                            _c("cover-upload", {
                              attrs: {
                                path: _vm.backgroundPath,
                                coverarr: _vm.backgroundArr,
                                covertext: _vm.backgroundText,
                                type: _vm.Teacher_ID.toString()
                              },
                              on: { deletecover: _vm.delBackgroundFun },
                              model: {
                                value: _vm.teacherInfo.backgroundPic,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.teacherInfo,
                                    "backgroundPic",
                                    $$v
                                  )
                                },
                                expression: "teacherInfo.backgroundPic"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              model: {
                                value: _vm.teacherInfo.backgroundPic,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.teacherInfo,
                                    "backgroundPic",
                                    $$v
                                  )
                                },
                                expression: "teacherInfo.backgroundPic"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-text",
                            attrs: { label: "教师简介", prop: "introduction" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "850px" },
                              attrs: {
                                "show-word-limit": "",
                                autosize: { minRows: 2, maxRows: 5 },
                                type: "textarea",
                                maxlength: "200",
                                placeholder: "请填写教师简介"
                              },
                              model: {
                                value: _vm.teacherInfo.introduction,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "introduction", $$v)
                                },
                                expression: "teacherInfo.introduction"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-text",
                            attrs: { label: "教师详情", prop: "detail" }
                          },
                          [
                            _c("editor-view", {
                              model: {
                                value: _vm.teacherInfo.detail,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "detail", $$v)
                                },
                                expression: "teacherInfo.detail"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              model: {
                                value: _vm.teacherInfo.detail,
                                callback: function($$v) {
                                  _vm.$set(_vm.teacherInfo, "detail", $$v)
                                },
                                expression: "teacherInfo.detail"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-text",
                            attrs: { label: "所属学段" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "sync-select",
                                attrs: { multiple: "", placeholder: "学段" },
                                model: {
                                  value: _vm.sectionCodes,
                                  callback: function($$v) {
                                    _vm.sectionCodes = $$v
                                  },
                                  expression: "sectionCodes"
                                }
                              },
                              _vm._l(_vm.syncObj.section, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-text",
                            attrs: { label: "所属年级" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "sync-select",
                                attrs: { multiple: "", placeholder: "年级" },
                                model: {
                                  value: _vm.gradeCodes,
                                  callback: function($$v) {
                                    _vm.gradeCodes = $$v
                                  },
                                  expression: "gradeCodes"
                                }
                              },
                              _vm._l(_vm.syncObj.grade, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-text",
                            attrs: { label: "所属阶段" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "sync-select",
                                attrs: { multiple: "", placeholder: "阶段" },
                                model: {
                                  value: _vm.volumeCodes,
                                  callback: function($$v) {
                                    _vm.volumeCodes = $$v
                                  },
                                  expression: "volumeCodes"
                                }
                              },
                              _vm._l(_vm.syncObj.volume, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-text",
                            attrs: { label: "所教学科" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "sync-select",
                                attrs: { multiple: "", placeholder: "学科" },
                                model: {
                                  value: _vm.subjectCodes,
                                  callback: function($$v) {
                                    _vm.subjectCodes = $$v
                                  },
                                  expression: "subjectCodes"
                                }
                              },
                              _vm._l(_vm.syncObj.subject, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footer-bg" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.backBtn()
                          }
                        }
                      },
                      [_vm._v("返回")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.saveLoad },
                        on: {
                          click: function($event) {
                            return _vm.saveTeacherInfo()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.saveLoad ? "保存..." : "保存"))]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  disabled: _vm.teacherId.toString() <= 0,
                  label: _vm.teacherContentTableName,
                  name: "second"
                }
              },
              [
                _c("teacher-resource", {
                  attrs: { teacherId: _vm.teacherId },
                  on: { uploadReourceCount: _vm.getReourceCount }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }