var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingUserinfo,
          expression: "loadingUserinfo"
        }
      ],
      staticStyle: { display: "flex" }
    },
    [
      _c("div", { staticClass: "left_box default_box" }, [
        _c(
          "div",
          { staticClass: "cover_box" },
          [
            _c(
              "el-image",
              {
                staticClass: "userCover",
                attrs: {
                  src: _vm.getImgUrl(
                    _vm.userData.userCover,
                    _vm.thumbnailStyle
                  ),
                  alt: "",
                  fit: "cover"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error"
                  },
                  [
                    _c("img", {
                      staticClass: "userCover",
                      attrs: { src: require("@/assets/book_cover.png") }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "info_name marginTop_8" }, [
              _vm._v(
                _vm._s(_vm.isNull(_vm.userData && _vm.userData.userNickName))
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "info_value marginTop_8" }, [
              _vm._v(
                "ID:" +
                  _vm._s(_vm.isNull(_vm.userData && _vm.userData.userOpenId))
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "info_memo marginTop_5" }, [
              _vm._v(
                "最近登录：" +
                  _vm._s(
                    _vm.isNull(_vm.userData && _vm.userData.lastLoginTimeAt)
                  )
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "memo_box" },
          [
            _c("el-input", {
              attrs: { type: "textarea", placeholder: "请输入备注" },
              model: {
                value: _vm.userData.adminRemarks,
                callback: function($$v) {
                  _vm.$set(_vm.userData, "adminRemarks", $$v)
                },
                expression: "userData.adminRemarks"
              }
            }),
            _vm._v(" "),
            _vm._m(0)
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right_box default_box" },
        [
          _c("div", { staticClass: "info_title" }, [
            _vm._v("\n        基本资料\n      ")
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "displayFlex align_items_center" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "item_marginTop" }, [
                  _c("span", { staticClass: "info_key" }, [
                    _vm._v("真实姓名：")
                  ]),
                  _vm._v(" "),
                  !_vm.editRealName
                    ? _c(
                        "span",
                        {
                          staticClass: "info_value",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.editName(_vm.userData.realName)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.isNull(_vm.userData && _vm.userData.realName)
                            ) + "\n              "
                          ),
                          _c("i", { staticClass: "el-icon-edit" })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editRealName
                    ? _c(
                        "span",
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              maxlength: "20",
                              "show-word-limit": "",
                              placeholder: "请输入真实姓名",
                              size: "mini"
                            },
                            model: {
                              value: _vm.newRealName,
                              callback: function($$v) {
                                _vm.newRealName = $$v
                              },
                              expression: "newRealName"
                            }
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-close",
                            staticStyle: {
                              color: "#ff4949",
                              cursor: "pointer"
                            },
                            on: { click: _vm.cancelEditName }
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-check",
                            staticStyle: {
                              color: "#67C23A",
                              cursor: "pointer"
                            },
                            on: { click: _vm.saveEditName }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "item_marginTop" }, [
                  _c("span", { staticClass: "info_key" }, [
                    _vm._v("所在地区：")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "info_value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.isNull(_vm.userData && _vm.userData.provinceName)
                      ) + _vm._s(_vm.userData && _vm.userData.cityName)
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "displayFlex align_items_center" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "item_marginTop" }, [
                  _c("span", { staticClass: "info_key" }, [
                    _vm._v("手机号码：")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "info_value" }, [
                    _vm._v(
                      _vm._s(_vm.isNull(_vm.userData && _vm.userData.userPhone))
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "item_marginTop" }, [
                  _c("span", { staticClass: "info_key" }, [
                    _vm._v("注册时间：")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "info_value" }, [
                    _vm._v(
                      _vm._s(_vm.isNull(_vm.userData && _vm.userData.createdAt))
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "item_marginTop displayFlex" },
                  [
                    _c("span", { staticClass: "info_key" }, [
                      _vm._v("用户标签：")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.userData.tagList, function(tagItem, index) {
                      return _c(
                        "div",
                        { key: index, staticStyle: { "margin-right": "5px" } },
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: { size: "mini", closable: true },
                              on: {
                                close: function($event) {
                                  return _vm.deletedUserTag(tagItem)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tagItem))]
                          )
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      { attrs: { placement: "bottom", trigger: "click" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                              width: "210px"
                            }
                          },
                          _vm._l(_vm.UserTagList, function(tagItem) {
                            return _c(
                              "div",
                              {
                                key: tagItem.id,
                                staticStyle: {
                                  margin: "0 5px 5px 0",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addUserTagApi(tagItem)
                                  }
                                }
                              },
                              [
                                _c("tags", {
                                  attrs: {
                                    tagName: tagItem.tagName,
                                    className: tagItem.className,
                                    closable: false
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "addTags",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [_vm._v("添加标签")]
                        )
                      ]
                    )
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "info_title",
              staticStyle: { "margin-top": "22px" }
            },
            [_vm._v("\n        资产信息\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "displayFlex align_items_center" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "item_marginTop" }, [
                  _c("span", { staticClass: "info_key" }, [
                    _vm._v("累计消费金额：")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "info_value" }, [
                    _vm._v(
                      "¥" +
                        _vm._s(
                          _vm.numFormat(
                            (_vm.userData.totalSales / 100).toFixed(2)
                          )
                        )
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          cursor: "pointer",
          "margin-top": "7px",
          color: "rgba(48, 49, 51, 1)",
          "font-size": "14px"
        }
      },
      [
        _c("i", { staticClass: "el-icon-edit" }),
        _vm._v(" "),
        _c("span", [_vm._v("编辑")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }