var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leadingGroup", staticStyle: { height: "100%" } },
    [
      _c(
        "div",
        { staticClass: "commonFlex headerCon" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.jumpAddLeadGroup } },
            [_vm._v("新建")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commonFlex" },
            [
              _c("el-input", {
                staticStyle: { width: "200px", "margin-right": "10px" },
                attrs: { placeholder: "搜索关键字" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleBtnSearch($event)
                  }
                },
                model: {
                  value: _vm.query.groupName,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "groupName", $$v)
                  },
                  expression: "query.groupName"
                }
              }),
              _vm._v(" "),
              _c(
                "search-btn",
                { on: { searchContent: _vm.searchContent } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.query, "label-width": "auto" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "年级" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                multiple: "",
                                placeholder: "请选择"
                              },
                              on: { change: _vm.handleChangegradeCode },
                              model: {
                                value: _vm.gradeCode,
                                callback: function($$v) {
                                  _vm.gradeCode = $$v
                                },
                                expression: "gradeCode"
                              }
                            },
                            _vm._l(_vm.allSyncGrade, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                multiple: "",
                                placeholder: "请选择"
                              },
                              on: { change: _vm.handleChanuserType },
                              model: {
                                value: _vm.userTypes,
                                callback: function($$v) {
                                  _vm.userTypes = $$v
                                },
                                expression: "userTypes"
                              }
                            },
                            _vm._l(_vm.userTypeOptions, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTable,
                  expression: "loadingTable"
                }
              ],
              ref: "tablewrap",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                height: "calc(100vh - 218px)",
                "default-sort": { prop: "createdAt", order: "descending" }
              },
              on: {
                "sort-change": _vm.sortChange,
                "selection-change": _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", { attrs: { type: "selection" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "引导加群名称",
                  width: "200",
                  prop: "groupName"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "所属年级", width: "240", prop: "gradeCodes" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.showval(
                                scope.row.gradeCodes,
                                _vm.allSyncGrade
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "适用角色", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.showTypes(scope.row.userTypes)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "有效时间", width: "240" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.effectiveEndTime
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.effectiveEndTime) + " 后失效"
                              )
                            ])
                          : _c("span", [_vm._v("永久")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  width: "150",
                  prop: "createdAt",
                  sortable: "custom"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getDateDiff(scope.row.createdAt)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  width: "80",
                  prop: "status",
                  sortable: "custom"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("已关闭")
                            ])
                          : scope.row.status === 0
                          ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                              _vm._v("已开启")
                            ])
                          : _c("span", [_vm._v("--")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数据概况", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: { effect: "dark", placement: "bottom" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "共" +
                                          _vm._s(
                                            scope.row.groupLinkSettings.length
                                          ) +
                                          "个群"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      scope.row.groupLinkSettings,
                                      function(item, index) {
                                        return _c("div", { key: index }, [
                                          _vm._v(
                                            "\n                    群" +
                                              _vm._s(index + 1) +
                                              " [" +
                                              _vm._s(
                                                item.status === 0
                                                  ? "开启"
                                                  : "关闭"
                                              ) +
                                              "]：已加入" +
                                              _vm._s(item.entryGroupNum) +
                                              "人，可容纳" +
                                              _vm._s(item.groupCapacityNum) +
                                              "人\n                "
                                          )
                                        ])
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-progress", {
                                      staticStyle: { width: "60%" },
                                      attrs: {
                                        "show-text": false,
                                        status: scope.row.progressStatus,
                                        percentage: scope.row.percentage
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "margin-left": "10px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.entryGroupTotalNum) +
                                            "/" +
                                            _vm._s(
                                              scope.row.groupCapacityTotalNum
                                            )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "right",
                  "header-align": "right",
                  fixed: "right",
                  "min-width": "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.jumpEditLeadGroup(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        scope.row.status === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#67C23A" },
                                attrs: {
                                  type: "text",
                                  loading:
                                    _vm.openHandleLoading &&
                                    _vm.currendex === scope.$index
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openHandle(
                                      scope.row,
                                      0,
                                      scope.$index
                                    )
                                  }
                                }
                              },
                              [_vm._v("开启")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#F56C6C" },
                                attrs: {
                                  type: "text",
                                  loading:
                                    _vm.openHandleLoading &&
                                    _vm.currendex === scope.$index
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openHandle(
                                      scope.row,
                                      1,
                                      scope.$index
                                    )
                                  }
                                }
                              },
                              [_vm._v("关闭")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.tableData.length
        ? _c(
            "div",
            { staticClass: "elPagination" },
            [
              _c(
                "div",
                { staticClass: "bottomCheckBox" },
                [
                  _c("el-checkbox", {
                    staticClass: "pagination-check",
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleSelectedAll },
                    model: {
                      value: _vm.selectedAll,
                      callback: function($$v) {
                        _vm.selectedAll = $$v
                      },
                      expression: "selectedAll"
                    }
                  }),
                  _vm._v(" "),
                  _c("common-drop-down", {
                    attrs: {
                      dropdownData: _vm.dropdownData,
                      dropdowntitle: _vm.dropdowntitle
                    },
                    on: { batchHandle: _vm.batchHandle }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { float: "right" },
                attrs: {
                  background: "",
                  "current-page": _vm.query.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.totalElements
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "setGradeDialog",
          attrs: {
            visible: _vm.setGradeDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "批量设置年级",
            width: "450px"
          },
          on: {
            "update:visible": function($event) {
              _vm.setGradeDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checkSetGrade,
                            callback: function($$v) {
                              _vm.checkSetGrade = $$v
                            },
                            expression: "checkSetGrade"
                          }
                        },
                        [_vm._v("所属年级")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: {
                        clearable: "",
                        multiple: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.setGradeCode,
                        callback: function($$v) {
                          _vm.setGradeCode = $$v
                        },
                        expression: "setGradeCode"
                      }
                    },
                    _vm._l(_vm.allSyncGrade, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", [_vm._v("仅保存激活项")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function($event) {
                          _vm.setGradeDialog = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.setGradeLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveSetGrade()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }