var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      class: {
        "card-box": true,
        "index-tree-card": true,
        "index-tree-card-ebook": _vm.isebook
      },
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c(
        "div",
        { staticClass: "card-header card-header-class fontwe display-flex" },
        [
          _c("span", [_vm._v("分类")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "刷新分类列表",
                    placement: "top"
                  }
                },
                [
                  _c("i", {
                    staticClass:
                      "el-icon-refresh-right el-icon-refresh-header el-icon-tree",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.modifyrefresh()
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-plus el-icon-plus-header el-icon-tree",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.modifyadd()
                  }
                }
              }),
              _vm._v(" "),
              _vm.classlist.length > 0
                ? _c("i", {
                    staticClass: "iconfont noWeight",
                    class: _vm.expandAll
                      ? "icon-category-collapse"
                      : "icon-category-expand",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.isExpand()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.classlist.length > 0
        ? _c(
            "div",
            { staticClass: "search-bg" },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "分类搜索...",
                  size: "mini",
                  clearable: ""
                },
                model: {
                  value: _vm.filterClass,
                  callback: function($$v) {
                    _vm.filterClass = $$v
                  },
                  expression: "filterClass"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.partnerOpenId && _vm.isebook
        ? _c(
            "div",
            {
              class: {
                "display-flex": true,
                "el-tree-air": true,
                "el-tree-air-a": true
              }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-add-class",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.showMessage($event)
                    }
                  }
                },
                [_vm._v("\n      选择商户后展示分类\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.partnerOpenId,
              expression: "partnerOpenId"
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          class: { "card-body": true, "card-body-a": _vm.isebook },
          style: {
            height:
              _vm.classlist.length > 0
                ? "calc(100vh - 502px)"
                : "calc(100vh - 466px)"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass: "tree-lz-box box"
            },
            [
              _vm.classlist.length > 0
                ? _c("el-tree", {
                    ref: "attrList",
                    attrs: {
                      data: _vm.classlist,
                      "allow-drop": _vm.allowDrop,
                      "default-expand-all": _vm.expandAll,
                      "default-expanded-keys": _vm.expandedKeys,
                      draggable: "",
                      "node-key": "id",
                      "filter-node-method": _vm.filterNode,
                      "empty-text": "暂无此分类"
                    },
                    on: {
                      "node-drop": _vm.handleDrop,
                      "node-drag-start": _vm.handleDragStart,
                      "node-drag-enter": _vm.handleDragEnter,
                      "node-drag-leave": _vm.handleDragLeave,
                      "node-drag-over": _vm.handleDragOver,
                      "node-drag-end": _vm.handleDragEnd
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "div",
                              {
                                staticClass: "custom-tree-node",
                                on: {
                                  mouseenter: function($event) {
                                    $event.stopPropagation()
                                    return _vm.mouseoverFun(data)
                                  },
                                  mouseleave: function($event) {
                                    $event.stopPropagation()
                                    return _vm.mouseoutFun()
                                  },
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.checkidFun(data)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.checkid === data.openId
                                        ? "color-check custom-tree-node-text"
                                        : "custom-tree-node-text",
                                    attrs: {
                                      title:
                                        data.title +
                                        _vm.getClassCount(data.albumNumbers) +
                                        "\n" +
                                        data.openId
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(data.title) +
                                        "\n            "
                                    ),
                                    !_vm.isebook
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                data.albumNumbers
                                                  ? data.albumNumbers
                                                  : 0
                                              ) +
                                              ")"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                data.ebookNumber
                                                  ? data.ebookNumber
                                                  : 0
                                              ) +
                                              ")"
                                          )
                                        ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: data.id === _vm.mouseid,
                                        expression: "data.id === mouseid"
                                      }
                                    ],
                                    staticClass: "custom-tree-node-btn"
                                  },
                                  [
                                    _c(
                                      "el-dropdown",
                                      {
                                        attrs: { "hide-timeout": 500 },
                                        on: {
                                          command: _vm.handleOperationClass
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "el-dropdown-link" },
                                          [
                                            _c("i", {
                                              staticClass: "iconfont icon-More"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown"
                                          },
                                          [
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: _vm.composeValue(
                                                    "add",
                                                    data
                                                  )
                                                }
                                              },
                                              [_vm._v("添加子分类")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: _vm.composeValue(
                                                    "edit",
                                                    data
                                                  )
                                                }
                                              },
                                              [_vm._v("重命名")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: _vm.composeValue(
                                                    "remove",
                                                    data
                                                  )
                                                }
                                              },
                                              [_vm._v("移动到...")]
                                            ),
                                            _vm._v(" "),
                                            !data.children
                                              ? _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      command: _vm.composeValue(
                                                        "del",
                                                        data
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "删除\n                "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      2510736443
                    )
                  })
                : _c(
                    "div",
                    { staticClass: "display-flex el-tree-air" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-add-class",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.modifyadd()
                            }
                          }
                        },
                        [_vm._v("\n          添加分类\n        ")]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.addmunlu
        ? _c("div", { staticClass: "mask-class addmunlu" }, [
            _c("div", { staticClass: "mask-class-box" }, [
              _c(
                "div",
                { staticClass: "el-dialog", staticStyle: { width: "450px" } },
                [
                  _c("div", { staticClass: "el-dialog__header" }, [
                    _c("span", { staticClass: "el-dialog__title" }, [
                      _vm._v("编辑分类")
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "el-dialog__headerbtn",
                        attrs: { type: "button", "aria-label": "Close" },
                        on: {
                          click: function($event) {
                            return _vm.closedit()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-dialog__close el-icon el-icon-close"
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-dialog__body" }, [
                    _c("form", { staticClass: "el-form" }, [
                      _c("div", { staticClass: "el-form-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "el-form-item__label",
                            staticStyle: { width: "80px" }
                          },
                          [_vm._v("父分类")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "el-form-item__content",
                            staticStyle: { "margin-left": "80px" }
                          },
                          [
                            _c("div", { staticClass: "el-input is-disabled" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.parentname,
                                    expression: "parentname"
                                  }
                                ],
                                staticClass: "el-input__inner",
                                attrs: {
                                  type: "text",
                                  disabled: "disabled",
                                  autocomplete: "off"
                                },
                                domProps: { value: _vm.parentname },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.parentname = $event.target.value
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-form-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "el-form-item__label",
                            staticStyle: { width: "80px" }
                          },
                          [_vm._v("分类名称")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "el-form-item__content",
                            staticStyle: { "margin-left": "80px" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "el-input selection-name" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectionName,
                                      expression: "selectionName"
                                    }
                                  ],
                                  staticClass: "el-input__inner",
                                  class: _vm.isSelectionName ? "rules" : "",
                                  attrs: { maxlength: "100", type: "text" },
                                  domProps: { value: _vm.selectionName },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectionNameChange()
                                    },
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.confirmEvent()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.selectionName = $event.target.value
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.isSelectionName
                                  ? _c(
                                      "span",
                                      { staticClass: "selection-name-title" },
                                      [_vm._v("请输入分类名称")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-dialog__footer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { "data-v-284ef026": "" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "el-button el-button--default",
                            attrs: { "data-v-284ef026": "", type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.closedit()
                              }
                            }
                          },
                          [_c("span", [_vm._v("取消")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "el-button el-button--primary",
                            attrs: { loading: _vm.confirmbtn },
                            on: {
                              click: function($event) {
                                return _vm.confirmEvent()
                              }
                            }
                          },
                          [_vm._v("保存\n            ")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tips
        ? _c("div", { staticClass: "mask-class tips" }, [
            _c("div", { staticClass: "mask-class-box" }, [
              _c("div", { staticClass: "el-message-box" }, [
                _c("div", { staticClass: "el-message-box__header" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "el-message-box__headerbtn",
                      attrs: { type: "button", "aria-label": "Close" }
                    },
                    [
                      _c("i", {
                        staticClass: "el-message-box__close el-icon-close",
                        on: {
                          click: function($event) {
                            return _vm.closedit()
                          }
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "el-message-box__btns" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "el-button el-button--default el-button--small",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.closedit()
                        }
                      }
                    },
                    [_c("span", [_vm._v("取消")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "el-button el-button--default el-button--small el-button--primary",
                      attrs: { loading: _vm.delLoad, type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.delectclick()
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.delLoad ? "删除中..." : "确定"))
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.removeDialog
        ? _c("div", { staticClass: "mask-class remove-class" }, [
            _c("div", { staticClass: "mask-class-box" }, [
              _c(
                "div",
                { staticClass: "el-dialog", staticStyle: { width: "420px" } },
                [
                  _c("div", { staticClass: "el-dialog__header" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "el-dialog__headerbtn",
                        attrs: { type: "button", "aria-label": "Close" },
                        on: {
                          click: function($event) {
                            _vm.removeDialog = false
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-dialog__close el-icon el-icon-close"
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "el-dialog__body" },
                    [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.typeList,
                          props: _vm.typeProps,
                          "default-expand-all": true,
                          accordion: ""
                        },
                        on: { "node-click": _vm.handleTypeClick }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-dialog__footer" }, [
                    _c("div", { staticClass: "dialog-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "el-button el-button--default el-button--small",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.removeDialog = false
                            }
                          }
                        },
                        [_c("span", [_vm._v("取消")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "el-button el-button--default el-button--small el-button--primary",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.moveTypes()
                            }
                          }
                        },
                        [_c("span", [_vm._v("确定")])]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-message-box__title" }, [
      _c("span", [_vm._v("提示")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-message-box__content" }, [
      _c("i", { staticClass: "el-message-box__status el-icon-warning" }),
      _vm._v(" "),
      _c("div", { staticClass: "el-message-box__message" }, [
        _c("p", [_vm._v("此操作将永久删除该分类, 是否继续？")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "el-message-box__input" }, [
        _c("i", {
          staticClass: "el-message-box__errormsg",
          staticStyle: { visibility: "hidden" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-dialog__title" }, [
      _c("span", [_vm._v("移动到")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }