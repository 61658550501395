var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "live-dialog-layout" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "live-statistic-dialog",
          attrs: {
            visible: _vm.visibleProxy,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "lock-scroll": false,
            width: "1192px",
            title: "直播统计"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleProxy = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeNameProxy,
                callback: function($$v) {
                  _vm.activeNameProxy = $$v
                },
                expression: "activeNameProxy"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "用户名单", name: "user" } },
                [
                  _c("live-user", {
                    attrs: { liveObj: _vm.liveObj },
                    on: { closeDialog: _vm.jumpToexportData }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "中奖名单", name: "award" } },
                [
                  _c("live-award", {
                    attrs: { liveObj: _vm.liveObj },
                    on: { closeDialog: _vm.jumpToexportData }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }