var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "upload-box-dialog",
      attrs: {
        title: "导入单词",
        visible: _vm.uploadType,
        width: "352px",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.uploadType = $event
        }
      }
    },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-word-box",
          attrs: {
            action: "#",
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
            "on-change": _vm.handleChange,
            "before-upload": _vm.beforeAvatarUpload,
            accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
            "auto-upload": false
          }
        },
        [
          _c(
            "div",
            { staticClass: "word-button-box" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("更新单词上传")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "word-upload-button",
                  attrs: { slot: "trigger", size: "small", plain: "" },
                  slot: "trigger"
                },
                [_vm._v("点击上传")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [_vm._v("仅限上传Excel,小于2M。")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "botton-box" },
        [
          _c(
            "el-button",
            { attrs: { plain: "" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitUpload } },
            [_vm._v("完成")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }