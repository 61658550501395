"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ckeditor5BuildDecoupledDocument = _interopRequireDefault(require("@ckeditor/ckeditor5-build-decoupled-document"));

var _editor = require("@/utils/editor");

require("@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn");

//
//
//
//
//
//
//
var _default = {
  props: {
    type: {
      type: String,
      default: null
    },
    disabledEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      editor: null,
      editorDomId: "editor".concat(new Date().getTime() + (Math.floor(Math.random() * (100 - 1)) + 1)),
      toolbarContainer: "toolbar-container-".concat(new Date().getTime() + (Math.floor(Math.random() * (100 - 1)) + 1))
    };
  },
  watch: {
    disabledEdit: function disabledEdit(val) {
      if (this.editor) {
        this.editor.isReadOnly = val;
      }
    }
  },
  mounted: function mounted() {
    this.initCKEditor('');
  },
  methods: {
    // 富文本编辑器
    // eslint-disable-next-line no-unused-vars
    initCKEditor: function initCKEditor(content) {
      var _this = this;

      var tant = this;

      _ckeditor5BuildDecoupledDocument.default.create(document.querySelector("#".concat(tant.editorDomId)), {
        language: 'zh-cn',
        toolbar: [// 'heading',
        // '|', // 分隔
        'fontSize', // 'FontFamily',
        '|', // 分隔
        'bold', 'italic', 'underline', 'Strikethrough', 'Highlight', '|', // 分隔
        'alignment', // '|', // 分隔
        // 'NumberedList',
        // 'BulletedList',
        '|', // 分隔
        'imageUpload', 'link', 'blockquote', 'InsertTable', '|', // 分隔
        'undo', // 撤销
        'redo' // 重做
        ]
      }).then(function (editor) {
        var toolbarContainer = document.querySelector("#".concat(tant.toolbarContainer));
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        _this.editor = editor; // eslint-disable-next-line no-param-reassign

        _this.editor.isReadOnly = _this.disabledEdit;

        _this.editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
          return new _editor.UploadAdapter(loader, tant.type);
        };
      }).catch(function (error) {
        console.error(error);
      });
    },
    setcontent: function setcontent(content) {
      this.editor.setData(content);
    },
    getcontent: function getcontent() {
      return this.editor.getData();
    }
  }
};
exports.default = _default;