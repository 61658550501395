"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    // 编辑地址
    editAddress: function editAddress(item) {
      this.$emit('editAddress', item);
    },
    // 删除地址
    deleteAddress: function deleteAddress(item) {
      this.$emit('deleteAddress', item);
    }
  }
};
exports.default = _default2;