var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNoAuthTip,
            expression: "showNoAuthTip"
          }
        ],
        staticClass: "noAuthTip"
      },
      [
        _c("div", { staticClass: "tip-header" }, [_vm._v("提示")]),
        _vm._v(" "),
        _c(
          "el-form",
          {
            staticClass: "tip-form",
            attrs: { "label-width": "60px", size: "mini", model: _vm.userInfo }
          },
          [
            _c(
              "el-form-item",
              { staticClass: "tip-item", attrs: { label: "昵称" } },
              [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.userInfo.nickName))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "tip-item", attrs: { label: "OpenId" } },
              [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v("OpenID:" + _vm._s(_vm.userInfo.openId))
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "copy-btn",
                  on: {
                    click: function($event) {
                      return _vm.copyOpenId(_vm.userInfo.openId, $event)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "tip-item", attrs: { label: "手机号" } },
              [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.userInfo.phone || "--"))
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tip-text" }, [
          _vm._v("您当前不是总后台的管理员，若要开通请联系以下负责人！")
        ]),
        _vm._v(" "),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact-item" }, [
      _c("span", [_vm._v("张久瑞    17600223682")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }