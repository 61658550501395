var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "table",
          attrs: {
            data: _vm.resourceList,
            "row-class-name": _vm.tableRowClassName,
            "tooltip-effect": "dark"
          }
        },
        [
          _vm.dialogType !== "replace"
            ? _c("el-table-column", {
                attrs: {
                  selectable: _vm.selectable,
                  type: "selection",
                  width: "65"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("resource-simple", {
                      attrs: { resourceObj: scope.row, isEdit: false }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            staticStyle: { "text-align": "center" },
            attrs: {
              prop: "data",
              width: "220",
              align: "center",
              label: "发布时间"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.showVal(scope.row.publishedAt)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.dialogType === "replace"
            ? _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.dialogType === "replace"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn-add-album",
                                  attrs: {
                                    disabled: !_vm.selectable(scope.row),
                                    type: "text"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("item-add", scope.row)
                                    }
                                  }
                                },
                                [_vm._v("替换")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1639811794
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }