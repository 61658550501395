var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-announce" },
    [
      _c("edit-announcement", {
        attrs: { "submit-loading": _vm.submitLoading },
        on: {
          onSubmitClicked: _vm.onAddSubmitClicked,
          onResetClicked: _vm.onAddResetClicked,
          deleteLink: _vm.deleteLink
        },
        model: {
          value: _vm.announce,
          callback: function($$v) {
            _vm.announce = $$v
          },
          expression: "announce"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }