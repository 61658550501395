"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

var _section = require("@/utils/bookPageEditor/section");

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PageSectionPoint',
  props: {
    value: {
      required: true,
      type: Object
    },
    location: {
      required: true,
      type: Array
    }
  },
  data: function data() {
    return {
      resizing: false,
      lastX: 0,
      lastY: 0
    };
  },
  computed: {
    associate: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    pageSectionPoint: function pageSectionPoint() {
      var _ref;

      var _this$location = (0, _slicedToArray2.default)(this.location, 2),
          first = _this$location[0],
          second = _this$location[1];

      return _ref = {}, (0, _defineProperty2.default)(_ref, first, '-6px'), (0, _defineProperty2.default)(_ref, second, '-6px'), _ref;
    },
    pageHeight: function pageHeight() {
      return this.$store.state.book.editor.size.height * this.$store.state.book.editor.size.scale;
    },
    pageWidth: function pageWidth() {
      return this.$store.state.book.editor.size.width * this.$store.state.book.editor.size.scale;
    },
    dragX: function dragX() {
      return this.$store.state.book.editor.drag.x;
    },
    dragY: function dragY() {
      return this.$store.state.book.editor.drag.y;
    },
    minHeight: function minHeight() {
      return (0, _section.px2Proportion)(28, this.pageHeight);
    },
    minWidth: function minWidth() {
      return (0, _section.px2Proportion)(28, this.pageWidth);
    },
    dragTime: function dragTime() {
      return this.$store.state.book.editor.drag.time;
    },
    dragId: function dragId() {
      return this.$store.state.book.editor.drag.id;
    }
  },
  watch: {
    dragTime: function dragTime() {
      if (this.resizing === false) return;
      if (this.dragX === undefined) return;
      if (this.dragY === undefined) return;
      this.onResize();
    },
    dragId: function dragId() {
      if (this.dragId !== this.associate.id) {
        this.resizing = false;
      }
    }
  },
  methods: {
    onDragStart: function onDragStart(event) {
      this.resizing = true;
      this.lastX = event.x;
      this.lastY = event.y;
      this.$store.dispatch('book/editor/dragReset', {
        id: this.associate.id,
        x: this.lastX,
        y: this.lastY
      });
    },
    onDragEnd: function onDragEnd() {
      this.resizing = false;
      this.$store.dispatch('book/editor/dragReset');
      this.associate.positionWidth = (0, _section.formatSize)(this.associate.positionLeft, this.associate.positionWidth, this.minWidth);
      this.associate.positionHeight = (0, _section.formatSize)(this.associate.positionTop, this.associate.positionHeight, this.minHeight);
      var associate = JSON.parse(JSON.stringify(this.associate));

      if (associate.sectionData) {
        associate.sectionData = JSON.stringify(associate.sectionData);
      }

      this.$store.dispatch('book/section/updateSingleSection', this.associate);
    },
    onResize: function onResize() {
      this.associate.positionWidth += (0, _section.px2Proportion)(this.dragX - this.lastX, this.pageWidth);
      this.associate.positionHeight += (0, _section.px2Proportion)(this.dragY - this.lastY, this.pageHeight); //

      var _this$$store$state$bo = this.$store.state.book.editor.drag;
      this.lastX = _this$$store$state$bo.x;
      this.lastY = _this$$store$state$bo.y;
    }
  }
};
exports.default = _default;