"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AddExperientsWxDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      ruleForm: {
        wx: undefined
      },
      rules: {
        wx: [{
          required: true,
          message: '请填写活动形式',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    close: function close() {
      this.$emit('update:visible', false);
    },
    submit: function submit() {
      var result = true;
      this.$refs.ruleForm.validate(function (valid) {
        result = valid;
      });

      if (!result) {
        return this.$notify.info({
          title: '提示',
          message: '请填写微信号'
        });
      }

      try {
        this.loading = true;
        this.loading = false;
        this.$emit('success');
        this.close();
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: '绑定失败',
          message: e.message
        });
      }
    }
  }
};
exports.default = _default;