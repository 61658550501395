var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-specification-editing" },
    [
      _c("specification-group", {
        staticClass: "specification-group",
        attrs: { data: _vm.specification },
        on: {
          "add-value": _vm.addValue,
          "update-value": _vm.updateValue,
          "update-name": _vm.updateName,
          "delete-value": _vm.deleteValue,
          "add-item": _vm.addItem,
          "delete-item": _vm.deleteItem
        }
      }),
      _vm._v(" "),
      _vm.isShowTable
        ? _c("specification-table", {
            attrs: {
              name: _vm.specificationName,
              specification: _vm.specification
            },
            model: {
              value: _vm.specificationGroup,
              callback: function($$v) {
                _vm.specificationGroup = $$v
              },
              expression: "specificationGroup"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }