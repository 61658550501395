var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-layout" },
    [
      _c(
        "el-card",
        { staticClass: "view-layout-card" },
        [
          _c("product-list", {
            ref: "list",
            attrs: {
              loading: _vm.loading,
              query: _vm.query,
              data: _vm.data,
              total: _vm.total,
              "content-height": "calc(100vh - 244px)",
              select: _vm.select,
              "select-type": _vm.selectType
            },
            on: {
              "update:query": function($event) {
                _vm.query = $event
              },
              "update:select": function($event) {
                _vm.select = $event
              },
              "update:selectType": function($event) {
                _vm.selectType = $event
              },
              "update:select-type": function($event) {
                _vm.selectType = $event
              },
              "update-data": _vm.updateData
            },
            scopedSlots: _vm._u([
              {
                key: "header-left",
                fn: function() {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "medium" },
                        on: { click: _vm.onButtonClicked }
                      },
                      [_vm._v("快速审核")]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "content-action",
                fn: function() {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        align: "center",
                        width: "160",
                        label: "操作"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "actions" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-edit",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isActionDisabled(
                                          scope.row.productOpenId
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toEdit(
                                            scope.row.productOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("详情")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-edit",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isActionDisabled(
                                          scope.row.productOpenId
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onExaminationPassed(
                                            scope.row.productOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("通过")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-remove",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isActionDisabled(
                                          scope.row.productOpenId
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onReviewRejected(
                                            scope.row.productOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("驳回")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "footer-left",
                fn: function() {
                  return [
                    _c("bulk-action-button", {
                      ref: "reviewBtn",
                      attrs: {
                        selectType: _vm.selectType,
                        status: "1",
                        "handle-batch-stop-sale": _vm.batchStopSale,
                        reviewFunction: _vm.audioReviewPass,
                        "copy-function": _vm.batchCopy
                      },
                      on: {
                        "check-all": _vm.checkAll,
                        batchUnpublish: _vm.batchUnpublish,
                        batchRefuse: function($event) {
                          return _vm.handlebatchRefuse(arguments)
                        },
                        batchSetAttribute: function($event) {
                          return _vm.batchSetAttributes(arguments)
                        },
                        batchRecovery: _vm.handleBatchRecovery,
                        batchSaveDraft: _vm.batchSaveAudioDraft,
                        batchSaveRecycle: _vm.batchSaveAudioRecycle,
                        batchHiddenOrStopSale: _vm.batchHiddenOrStopSale
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogs" },
        [
          _c("real-goods-info", {
            attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
            on: {
              "update:visible": function($event) {
                _vm.goodsDialogVisible = $event
              },
              "update:goodsId": function($event) {
                _vm.goodsId = $event
              },
              "update:goods-id": function($event) {
                _vm.goodsId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("reason-for-rejection", {
            attrs: { textarea: _vm.reasonForRejectionTextarea },
            on: {
              "update:textarea": function($event) {
                _vm.reasonForRejectionTextarea = $event
              },
              cancel: _vm.reasonForRejectionCancel,
              confirm: _vm.reasonForRejectionConfirm
            },
            model: {
              value: _vm.reasonForRejectionDialogVisible,
              callback: function($$v) {
                _vm.reasonForRejectionDialogVisible = $$v
              },
              expression: "reasonForRejectionDialogVisible"
            }
          }),
          _vm._v(" "),
          _c("quick-review", {
            attrs: { reason: _vm.getReasonForRejection },
            on: { "on-close": _vm.updateData },
            model: {
              value: _vm.quickReviewDialogVisible,
              callback: function($$v) {
                _vm.quickReviewDialogVisible = $$v
              },
              expression: "quickReviewDialogVisible"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }