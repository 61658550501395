"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _index = _interopRequireDefault(require("@/components/coverupload/index.vue"));

var _const = require("@/utils/const");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_WordLibrary_Vocabulary_Album_Picture',
  components: {
    CoverUpload: _index.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      Vocabulary_Picture_ID: _const.Vocabulary_Picture_ID,
      // 词库图片的 id
      coverSize: ['60', '60'] // 图集尺寸大小

    };
  },
  computed: {
    vModelProxy: {
      get: function get() {
        if ((0, _typeof2.default)(this.value) === 'object' && this.value !== null) {
          return this.value;
        }

        return {};
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default2;