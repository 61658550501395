var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fast-track" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "fast-dialog",
          attrs: {
            width: "1000px",
            title: "评论审核",
            visible: _vm.visible,
            "before-close": _vm.cancelDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          !_vm.isNoData
            ? _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "comment-content" },
                  [
                    _c(
                      "div",
                      { staticClass: "detail-info" },
                      [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(_vm._s(_vm.showVal(_vm.detail.url)))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-title" }, [
                            _vm.detail.resourceType
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.resourcetypetoStr(
                                        _vm.detail.resourceType
                                      )
                                    ) + " : "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "title",
                              domProps: {
                                innerHTML: _vm._s(_vm.showVal(_vm.detail.title))
                              },
                              on: {
                                click: function($event) {
                                  return _vm.priviewCommon(_vm.detail)
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "timer" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("parseTime")(
                                  _vm.detail.createdAt,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.detail.appTitle))])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "detail-view" },
                          [
                            _c("comment-detail-item", {
                              attrs: { detail: _vm.detail }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("public-reply", {
                      ref: "publicReply",
                      staticClass: "comment-reply",
                      attrs: {
                        "val-data": _vm.addData,
                        "reply-list": _vm.replyList,
                        "scroll-tatus": _vm.scrollStatusReply,
                        "reply-total-elements": _vm.replyTotalElements,
                        "submit-loading": _vm.resolvedLoading,
                        "multiple-check": true,
                        "show-link": false,
                        "business-type": "comment"
                      },
                      on: {
                        handleRefreshReply: _vm.handleRefreshReply,
                        replyLoadScroll: _vm.replyLoadScroll,
                        submit: _vm.submit
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "user-content" }, [
                  _vm.userData
                    ? _c(
                        "div",
                        { staticClass: "user-info" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "img",
                              on: {
                                click: function($event) {
                                  return _vm.toUserDetails(_vm.userData.openId)
                                }
                              }
                            },
                            [
                              _vm.userData && _vm.userData.cover
                                ? _c(
                                    "el-image",
                                    {
                                      staticClass: "user-img",
                                      attrs: {
                                        src: _vm.getImgUrl(
                                          _vm.userData.cover,
                                          "style/width-limit-80"
                                        ),
                                        alt: "",
                                        fit: "cover"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "user-img",
                                            attrs: {
                                              src: require("@/assets/book_cover.png")
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _c("img", {
                                    staticClass: "user-img",
                                    attrs: {
                                      src: require("@/assets/book_cover.png")
                                    }
                                  }),
                              _vm._v(" "),
                              _vm.userData && _vm.userData.vipUser
                                ? _c("img", {
                                    staticClass: "user-vip-img",
                                    attrs: {
                                      src: require("@/assets/images/VIP.png"),
                                      alt: ""
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.userData && _vm.userData.displayName
                            ? _c(
                                "span",
                                { staticClass: "user-name common-bottom" },
                                [_vm._v(_vm._s(_vm.userData.displayName))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userData.identity
                            ? _c(
                                "div",
                                { staticClass: "identity common-bottom" },
                                [
                                  _vm.userData.identity === "教师"
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "success"
                                          }
                                        },
                                        [_vm._v("认证教师")]
                                      )
                                    : _c(
                                        "el-tag",
                                        { attrs: { size: "mini" } },
                                        [_vm._v(_vm._s(_vm.userData.identity))]
                                      )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-id common-bottom" }, [
                            _vm._v("ID: " + _vm._s(_vm.userData.openId))
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "text-time common-bottom" },
                            [
                              _vm._v(
                                "最近登录: " +
                                  _vm._s(_vm.userData.lastLoginTimeAt)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { round: "", size: "small" },
                              on: {
                                click: function($event) {
                                  _vm.openWorkOrder = true
                                }
                              }
                            },
                            [_vm._v("添加工单")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "comment-list" }, [
                    _vm.detail.targetReplyCommentId && _vm.commentObj
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "reply-title" }, [
                              _vm._v("回复的评论")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "reply-info",
                                on: {
                                  click: function($event) {
                                    return _vm.priviewCommon(_vm.commentObj)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "reply-user" },
                                  [
                                    _c("el-image", {
                                      staticClass: "cover",
                                      attrs: {
                                        src: _vm.getImgUrl(
                                          _vm.commentObj.userCover,
                                          "style/width-limit-80"
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.commentObj.userIsVip
                                      ? _c("img", {
                                          staticClass: "vip-img",
                                          attrs: {
                                            src: require("@/assets/images/VIP.png"),
                                            alt: ""
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "reply-user-title" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.commentObj.userDisplayName)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatFriendlyTime")(
                                        _vm.commentObj.createdAt
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("comment-detail-item", {
                              attrs: {
                                detail: _vm.commentObj,
                                "is-reply-top": true
                              },
                              on: { priviewCommon: _vm.priviewCommon }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "no-data" },
                          [
                            _c("el-image", {
                              staticClass: "no-data-img",
                              attrs: { src: _vm.noDataImg }
                            }),
                            _vm._v("\n            暂无回复的评论\n          ")
                          ],
                          1
                        )
                  ])
                ])
              ])
            : _c(
                "div",
                { staticClass: "content comment-no-data" },
                [
                  _c("el-image", {
                    staticClass: "no-data-img",
                    attrs: { src: _vm.noDataImg }
                  }),
                  _vm._v("\n      暂无需要审核的评论\n    ")
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  attrs: { type: "danger", round: "", disabled: _vm.isNoData },
                  on: {
                    click: function($event) {
                      _vm.blackDialog = true
                    }
                  }
                },
                [_vm._v("拉黑并删除")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  attrs: {
                    type: "danger",
                    round: "",
                    loading: _vm.deleteLoading,
                    disabled: _vm.isNoData
                  },
                  on: { click: _vm.handleDelete }
                },
                [_vm._v("删除")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.showLoading,
                    disabled: _vm.isNoData
                  },
                  on: { click: _vm.handleShow }
                },
                [_vm._v("显示")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footer-btn",
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.showTopLoading,
                    disabled: _vm.isNoData
                  },
                  on: { click: _vm.handleShowAndTop }
                },
                [_vm._v("显示并置顶")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("add-blacklist-dialog", {
        attrs: {
          visible: _vm.blackDialog,
          "back-user-open-id": _vm.userData.openId
        },
        on: {
          "update:visible": function($event) {
            _vm.blackDialog = $event
          },
          addBlacklist: _vm.addBlacklistHandle
        }
      }),
      _vm._v(" "),
      _c("add-work-order", {
        attrs: { visible: _vm.openWorkOrder, "value-data": _vm.detail },
        on: {
          "update:visible": function($event) {
            _vm.openWorkOrder = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }