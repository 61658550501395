"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.oneClickStockingDataValidate = oneClickStockingDataValidate;
exports.oneClickStockingDataToApiData = oneClickStockingDataToApiData;
exports.oneClickStockingSingleDataValidate = oneClickStockingSingleDataValidate;
exports.oneClickStockingSingleDataToApiData = oneClickStockingSingleDataToApiData;
exports.oneClickStockingSingleDataValidate2 = oneClickStockingSingleDataValidate2;

var _collect = _interopRequireDefault(require("collect.js"));

var _typeIs = _interopRequireDefault(require("@taozhi/type-is"));

var _priceParser = require("@taozhi/price-parser");

/* eslint-disable no-restricted-syntax, guard-for-in, no-continue */
function arrayIndexToNum(i) {
  return parseInt(i, 10) + 1;
}

function oneClickStockingDataValidateHint(i, name) {
  return "\u7B2C ".concat(arrayIndexToNum(i), " \u6761\u672A\u9009\u62E9").concat(name);
}

function oneClickStockingDataValidate(data) {
  for (var i in data) {
    if (data[i].prIsSub === true) {
      continue;
    }

    if (!_typeIs.default.isString(data[i].brandOpenId)) {
      throw new Error(oneClickStockingDataValidateHint(i, '品牌'));
    }

    if (_typeIs.default.stringIsBlank(data[i].brandOpenId)) {
      throw new Error(oneClickStockingDataValidateHint(i, '品牌'));
    }

    if (!_typeIs.default.isString(data[i].seriesCode)) {
      throw new Error(oneClickStockingDataValidateHint(i, '系列'));
    }

    if (_typeIs.default.stringIsBlank(data[i].seriesCode)) {
      throw new Error(oneClickStockingDataValidateHint(i, '系列'));
    }

    if (!_typeIs.default.isString(data[i].groupIds)) {
      throw new Error(oneClickStockingDataValidateHint(i, '商品分组'));
    }

    if (_typeIs.default.stringIsBlank(data[i].groupIds)) {
      throw new Error(oneClickStockingDataValidateHint(i, '商品分组'));
    }
  }
}

function oneClickStockingSingleDataValidate(data) {
  if (!_typeIs.default.isString(data.brandOpenId)) {
    return false;
  }

  if (_typeIs.default.stringIsBlank(data.brandOpenId)) {
    return false;
  }

  if (!_typeIs.default.isString(data.seriesCode)) {
    return false;
  }

  if (_typeIs.default.stringIsBlank(data.seriesCode)) {
    return false;
  }

  if (!_typeIs.default.isString(data.groupIds)) {
    return false;
  }

  return !_typeIs.default.stringIsBlank(data.groupIds);
}

function oneClickStockingSingleDataValidate2(data) {
  if (!_typeIs.default.isString(data.brandOpenId)) {
    return false;
  }

  if (_typeIs.default.stringIsBlank(data.brandOpenId)) {
    return false;
  }

  if (!_typeIs.default.isString(data.seriesCode)) {
    return false;
  }

  return !_typeIs.default.stringIsBlank(data.seriesCode);
}

function oneClickStockingDataToApiDataParsePrice(i) {
  var parseDiscountH = function parseDiscountH(key) {
    if ((0, _priceParser.isSafeNumber)(i[key])) {
      i[key] = (0, _priceParser.parseDiscountFromRebate)(i[key]);
    }
  };

  parseDiscountH('middleStockPrice');
  parseDiscountH('otherSupplyPrice');
  parseDiscountH('shopStockPrice');
  parseDiscountH('shopLowPrice');
  parseDiscountH('shopPrice');
  return i;
}

function oneClickStockingDataToApiDataParseList(i) {
  var parseDiscountLSH = function parseDiscountLSH(key) {
    var r = {
      productCount: key.productCount
    };

    if ((0, _priceParser.isSafeNumber)(key.price)) {
      r.price = (0, _priceParser.parseDiscountFromRebate)(key.price);
    }

    return r;
  };

  var parseDiscountLH = function parseDiscountLH(key) {
    i[key] = [parseDiscountLSH(i[key][0]), parseDiscountLSH(i[key][1]), parseDiscountLSH(i[key][2])];
  };

  parseDiscountLH('productPreferentialList');
  parseDiscountLH('splicingPreferentialList');
  return i;
}

function oneClickStockingDataToApiDataParseListPrice(i) {
  var parseListHv = function parseListHv(j) {
    if ((0, _priceParser.isSafeNumber)(j.price)) {
      return true;
    }

    return (0, _priceParser.isSafeNumber)(j.productCount);
  };

  var parseList = function parseList(key) {
    var nx = [];
    var hv = false;

    for (var j = i[key].length - 1; j >= 0; j -= 1) {
      if (hv) {
        nx[j] = i[key][j];
      } else if (parseListHv(i[key][j])) {
        nx[j] = i[key][j];
        hv = true;
      }
    }

    if (nx.length > 0) {
      i[key] = nx;
    } else {
      i[key] = undefined;
    }
  }; // 如果活动无有效内容，删除


  parseList('productPreferentialList');
  parseList('splicingPreferentialList');
  return i;
}

function oneClickStockingDataToApiData(data) {
  var pId;

  for (var i in data) {
    if (data[i].prIsSub === true) {
      data[i].brandOpenId = data[pId].brandOpenId;
      data[i].seriesCode = data[pId].seriesCode;
      data[i].groupIds = data[pId].groupIds;
      data[i].secondGroupIds = data[pId].secondGroupIds;
    } else {
      pId = i;
    }
  }

  return (0, _collect.default)(data).map(function (i) {
    return (0, _collect.default)(i).except('prIsSub', 'prCount').all();
  }).map(oneClickStockingDataToApiDataParsePrice).map(oneClickStockingDataToApiDataParseList).map(oneClickStockingDataToApiDataParseListPrice).all();
}

function oneClickStockingSingleDataToApiData(list, index) {
  var res = list[index]; // 如果是子项，向前查找到父项，将父项的值复制过来

  if (res.prIsSub === true) {
    for (var i = index - 1; i > -1; i -= 1) {
      if (list[i].prIsSub !== true) {
        res.brandOpenId = list[i].brandOpenId;
        res.seriesCode = list[i].seriesCode;
        res.groupIds = list[i].groupIds;
        res.secondGroupIds = list[i].secondGroupIds;
        break;
      }
    }
  }

  return (0, _collect.default)(res).except('prIsSub', 'prCount', 'middleStockPrice', 'otherSupplyPrice', 'shopStockPrice', 'shopLowPrice', 'shopPrice', 'productPreferentialList', 'splicingPreferentialList').all();
}