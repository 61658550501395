"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSingleFileList = getSingleFileList;
exports.getSingleFileDetail = getSingleFileDetail;
exports.singleFileToDraft = singleFileToDraft;
exports.singleFileToDraftById = singleFileToDraftById;
exports.fileToReview = fileToReview;
exports.fileToReviewById = fileToReviewById;
exports.singleFileHidden = singleFileHidden;
exports.singleFileCancelHidden = singleFileCancelHidden;
exports.singleFileStopSale = singleFileStopSale;
exports.singleFileCancelStopSale = singleFileCancelStopSale;
exports.fileReview = fileReview;
exports.fileBatchReview = fileBatchReview;
exports.getSingleFileStatusCount = getSingleFileStatusCount;
exports.setSingleFileSequence = setSingleFileSequence;
exports.singleFileCancelSequence = singleFileCancelSequence;
exports.singleFileRecovery = singleFileRecovery;
exports.singleFileBatchRecovery = singleFileBatchRecovery;
exports.delSingleFile = delSingleFile;
exports.singleFileBatchSetAttribute = singleFileBatchSetAttribute;
exports.singleFileBatchIndependentSale = singleFileBatchIndependentSale;
exports.singleFileReviewPass = singleFileReviewPass;
exports.singleFileReject = singleFileReject;
exports.singleFileBatchRefuse = singleFileBatchRefuse;
exports.singleFileSavePublish = singleFileSavePublish;
exports.singleFileSaveReview = singleFileSaveReview;
exports.getAlbumSingleFileList = getAlbumSingleFileList;
exports.addSingleFileList = addSingleFileList;
exports.fileDelete = fileDelete;
exports.getCommoditySingleFileList = getCommoditySingleFileList;
exports.getFileTitleList = getFileTitleList;
exports.getUserdownLogList = getUserdownLogList;
exports.singleFileReplace = singleFileReplace;
exports.singleFileToSaveById = singleFileToSaveById;
exports.setAudit = setAudit;
exports.updateSingleFileEncode = updateSingleFileEncode;
exports.getResourceOperationList = getResourceOperationList;
exports.getSingleFileZip = getSingleFileZip;
exports.singleFileBatchRecycle = singleFileBatchRecycle;
exports.singleFileBatchRevoke = singleFileBatchRevoke;
exports.getFileDownLoadUrl = getFileDownLoadUrl;
exports.singleFileBatchHidden = singleFileBatchHidden;
exports.singleFileBatchCancelHidden = singleFileBatchCancelHidden;
exports.singleFileBatchStopSale = singleFileBatchStopSale;
exports.singleFileBatchCancelStopSale = singleFileBatchCancelStopSale;
exports.singleFileCopy = singleFileCopy;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _main = _interopRequireDefault(require("../utils/request/main"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/file/"));
var requestFile = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/fileContent/"));
var commonRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/"));
/**
 * 用户下载记录
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getUserdownLogList(params) {
  return commonRequest.get('/downLog', {
    params: params
  });
}
/**
 * 查询文件列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSingleFileList(params) {
  return request.get('/request/list', {
    params: params
  });
}
/**
 * 批量修改文件(新建 更新)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addSingleFileList(openId, data) {
  return requestFile.patch("/request/list/".concat(openId), data);
}
/**
 * 获取直播详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getSingleFileDetail(openId) {
  return request.get("/request/".concat(openId));
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function singleFileToDraft(data) {
  return request.post('/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function singleFileToDraftById(openId, data) {
  return request.patch("/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function singleFileToSaveById(openId, data) {
  return request.patch("/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function fileToReview(data) {
  return request.post('/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function fileToReviewById(openId, data) {
  return request.patch("/request/".concat(openId, "/commit"), data);
}
/**
 * 直播隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileHidden(openId, params) {
  return request.post("/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 直播取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileCancelHidden(openId, params) {
  return request.post("/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 直播停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 直播取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileCancelStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 直播-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function fileReview(openId) {
  return request.post("/request/".concat(openId, "/commit"));
}
/**
 * 直播-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function fileBatchReview(openIds) {
  return request.post('/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取各状态数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSingleFileStatusCount(params) {
  return request.get('/request/count', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setSingleFileSequence(openId, params) {
  return request.patch("/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function singleFileCancelSequence(openId) {
  return request.patch("/request/".concat(openId, "/cancelSequence"));
}
/**
 * 直播-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function singleFileRecovery(openId) {
  return request.post("/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function singleFileBatchRecovery(openIds) {
  return request.post('/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function singleFileBatchRecycle(openIds) {
  return request.post('/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delSingleFile(openId) {
  return request.delete("/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileBatchSetAttribute(params) {
  return request.patch('/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileBatchIndependentSale(params) {
  return request.patch('/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 直播-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function singleFileReviewPass(openId) {
  return request.post("/request/".concat(openId, "/review"));
}
/**
 * 直播-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function singleFileSavePublish(data) {
  return request.post('/request/review', data);
}
/**
 * 直播-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function singleFileSaveReview(openId, data) {
  return request.patch("/request/".concat(openId, "/review"), data);
}
/**
 * 直播-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileReject(openId, params) {
  return request.post("/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 直播-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileBatchRefuse(params) {
  return request.post('/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取文件列表(专辑专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumSingleFileList(params) {
  return request.get('/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取文件列表(赠品专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommoditySingleFileList(params) {
  return request.get('/order/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 删除文件
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileDelete(fileContentOpenId) {
  return requestFile.delete("/request/".concat(fileContentOpenId));
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFileTitleList(params) {
  return request.get('/request/repeat/title', {
    params: params
  });
}
/**
 * 替换文件
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileReplace(params) {
  return request.patch('/request/replace', undefined, {
    params: params
  });
}
/**
 * 批量提交审核
 * @param {Object} openIds
 * @returns {Promise<*>}
 */


function setAudit(openIds) {
  return request.patch('/request/batch/submit', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateSingleFileEncode(resourceOpenId, resourceEncode) {
  return request.patch("/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 查询操作日志列表
 * @param {String} resourceOpenId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getResourceOperationList(resourceOpenId, params) {
  return request.get("/request/operationLog/".concat(resourceOpenId), {
    params: params
  });
}
/**
 * 下载文件列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSingleFileZip(params) {
  return request.get('/zip', {
    params: params
  });
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function singleFileBatchRevoke(openIds) {
  return request.post('/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取文件下载地址
 * @param {String} resourceOpenId
 * @returns {Promise<*>}
 */


function getFileDownLoadUrl(resourceOpenId) {
  return request.get("/request/download/".concat(resourceOpenId));
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function singleFileBatchHidden(openIds) {
  return request.patch('/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function singleFileBatchCancelHidden(openIds) {
  return request.patch('/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function singleFileBatchStopSale(params) {
  return request.patch('/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function singleFileBatchCancelStopSale(openIds) {
  return request.patch('/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 复制资源
 * @param {Object} data
 * @returns {Promise<*>}
 */


function singleFileCopy(data) {
  return request.post('/request/copy', data);
}