var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("product-list", {
    ref: "myList",
    attrs: {
      loading: _vm.loading,
      query: _vm.query,
      data: _vm.data,
      total: _vm.total,
      "content-height": "calc(92vh - 268px)",
      select: _vm.select,
      "show-resource-type": true,
      "price-hidden": true
    },
    on: {
      "update:query": function($event) {
        _vm.query = $event
      },
      "update:select": function($event) {
        _vm.select = $event
      },
      "update-data": _vm.updateData,
      "search-changed": _vm.searchChanged
    },
    scopedSlots: _vm._u([
      {
        key: "content-action",
        fn: function() {
          return [
            _c("list-actions", {
              attrs: {
                "other-supply-price": _vm.restockingPriceOtherSupplyPrice,
                data: _vm.data
              },
              on: {
                "update:otherSupplyPrice": function($event) {
                  _vm.restockingPriceOtherSupplyPrice = $event
                },
                "update:other-supply-price": function($event) {
                  _vm.restockingPriceOtherSupplyPrice = $event
                }
              },
              model: {
                value: _vm.restockingPrice,
                callback: function($$v) {
                  _vm.restockingPrice = $$v
                },
                expression: "restockingPrice"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }