"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBalance = getBalance;
exports.getAccountList = getAccountList;
exports.querybalanceAccts = querybalanceAccts;
exports.getOutUserIdByPartnerOpenId = getOutUserIdByPartnerOpenId;
exports.getRecordList = getRecordList;
exports.getWithdrawalLogList = getWithdrawalLogList;
exports.getPingPublicDataList = getPingPublicDataList;
exports.queryMchIndividualAuthenticationApplicationByPartnerOpenId = queryMchIndividualAuthenticationApplicationByPartnerOpenId;
exports.withdrawalCreate = withdrawalCreate;
exports.getPartnerBalanceVoList = getPartnerBalanceVoList;
exports.findAllHistoricalRecord = findAllHistoricalRecord;
exports.businessSideElectronicReceipts = businessSideElectronicReceipts;
exports.downloadPingStatementAllListByDate = downloadPingStatementAllListByDate;
exports.findAllAndDownload = findAllAndDownload;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _main = _interopRequireDefault(require("../utils/request/main"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/mp/"));
var request2 = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/order/openFeign/automaticaccountdistribution/"));
var request3 = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/admin/"));
/**
 * 商户账户表
 * @returns {Promise<*>}
 */

function getBalance(openid) {
  return request.get("/partnerBalance/".concat(openid));
}
/**
 * 账户列表 (商户账户记录表、资产管理-我的账户)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAccountList(params) {
  return request.get('/partnerBalanceLog', {
    params: params
  });
}
/**
 * 根据商户openId获取用户openId
 * @returns {Promise<*>}
 * @param params
 */


function getOutUserIdByPartnerOpenId(params) {
  return request2.get('getOutUserIdByPartnerOpenId', {
    params: params
  });
}
/**
 * 查询场景账户
 * @returns {Promise<*>}
 * @param params
 */


function querybalanceAccts(params) {
  return request2.get('querybalanceAccts', {
    params: params
  });
}
/**
 * 提现记录列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRecordList(params) {
  return request.get('/withdraw/findAll', {
    params: _objectSpread({
      partnerOpenId: '1'
    }, params)
  });
}
/**
 * 提现记录列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function findAllHistoricalRecord(params) {
  return request3.get('/withdraw/findAllHistoricalRecord', {
    params: _objectSpread({
      partnerOpenId: '1'
    }, params)
  });
}
/**
 * 获取商户提现日志列表（分页）
 * @returns {Promise<*>}
 * @param params
 */


function getWithdrawalLogList(params) {
  return request2.get('getWithdrawalLogList', {
    params: params
  });
}
/**
 * 获取银行列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getPingPublicDataList(params) {
  return request2.get('getPingPublicDataList', {
    params: params
  });
}
/**
 * 企业认证申请状态查询
 * @param {String} params
 * @returns {Promise<*>}
 */


function queryMchIndividualAuthenticationApplicationByPartnerOpenId(params) {
  return request2.get('queryMchIndividualAuthenticationApplicationByPartnerOpenId', {
    params: params
  });
}
/**
 * 下载对账单
 * @param {String} params
 * @returns {Promise<*>}
 */


function downloadPingStatementAllListByDate(params) {
  return request2.get('downloadPingStatementAllListByDate', {
    params: params
  });
}
/**
 * 提现创建
 * @returns {Promise<*>}
 * @param body
 */


function withdrawalCreate(body) {
  return request.post('withdrawalCreate', body);
}
/**
 * 获取自定义商户余额列表（分页）
 * @returns {Promise<*>}
 * @param params
 */


function getPartnerBalanceVoList(params) {
  return request3.get('partnerBalance/getPartnerBalanceVoList', {
    params: params
  });
}
/**
 * 电子回单生成
 * @returns {Promise<*>}
 * @param body
 */


function businessSideElectronicReceipts(body) {
  return request2.post('businessSideElectronicReceipts', body);
}
/**
 * 获取自定义商户余额列表（分页）
 * @returns {Promise<*>}
 * @param params
 */


function findAllAndDownload(params) {
  return request3.get('withdraw/findAllAndDownload', {
    params: params
  });
}