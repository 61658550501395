"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
var _default = {
  name: 'index',
  props: {
    tagId: {
      type: Number,
      default: 10001
    },
    tagColor: {
      type: String,
      default: '#409EFF'
    },
    tagName: {
      type: String,
      default: '运营'
    },
    className: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      tagType: 'info'
    };
  },
  created: function created() {
    if (this.tagColor === '#409EFF') {
      this.tagType = 'primary';
    } else if (this.tagColor === '#67C23A') {
      this.tagType = 'success';
    } else if (this.tagColor === '#909399') {
      this.tagType = 'info';
    } else if (this.tagColor === '#E6A23C') {
      this.tagType = 'warning';
    } else if (this.tagColor === '#F56C6C') {
      this.tagType = 'danger';
    } else {
      this.tagType = '';
    }
  },
  methods: {
    handleClose: function handleClose(tagId) {
      this.$emit('deletedUserTag', tagId);
    }
  }
};
exports.default = _default;