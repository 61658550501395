"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _detailGoods = _interopRequireDefault(require("./detailGoods.vue"));

//
//
//
//
//
//
var _default = {
  name: 'AddGoods',
  components: {
    goodsDetail: _detailGoods.default
  },
  data: function data() {
    return {
      operation: 'add'
    };
  }
};
exports.default = _default;