var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teacherWrap" }, [
    _vm.teacherInfoData
      ? _c(
          "div",
          [
            _c("div", { staticStyle: { display: "flex", margin: "0 16px" } }, [
              _c("div", { staticClass: "commonFlex" }, [
                _c(
                  "div",
                  { staticClass: "certificateSlot" },
                  [
                    _vm.certificateImage
                      ? _c("el-image", {
                          staticClass: "teacherCover",
                          attrs: {
                            fit: "cover",
                            src: _vm.getOssImage(_vm.certificateImage, 1),
                            "preview-src-list": _vm.certificateImageList
                          }
                        })
                      : _c("div", { staticClass: "teacherCover no-cover" }, [
                          _vm._v("无图片")
                        ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "#606266",
                      "margin-top": "10px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.teacherInfoData.certificateDescription))]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#606266",
                    "font-size": "14px",
                    width: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                        width: "100%"
                      }
                    },
                    [
                      _c("div", { staticClass: "teacherTitle" }, [
                        _vm._v("教师资质")
                      ]),
                      _vm._v(" "),
                      _vm.teacherInfoData
                        ? _c("div", [
                            _vm.teacherInfoData.checkStatus === 1
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                          plain: "",
                                          disabled: ""
                                        }
                                      },
                                      [_vm._v("待审核")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.teacherInfoData.checkStatus === 2
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "commonMArginR" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.teacherInfoData.checkedAt
                                          ) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                          plain: "",
                                          disabled: ""
                                        }
                                      },
                                      [_vm._v("已通过")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.teacherInfoData.checkStatus === 3
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          color: "#606266"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.teacherInfoData.checkedAt)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _vm._v(
                                              "驳回原因：" +
                                                _vm._s(
                                                  _vm.teacherInfoData
                                                    .checkRejectReason
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-flex"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  plain: "",
                                                  size: "mini",
                                                  disabled: ""
                                                }
                                              },
                                              [_vm._v("已驳回")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.teacherInfoData.checkStatus === 4
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                          plain: "",
                                          disabled: ""
                                        }
                                      },
                                      [_vm._v("待认证")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v("真实姓名：")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.isNull(_vm.teacherInfoData.name)))
                    ]),
                    _vm._v(" "),
                    _vm.teacherInfoData.realPeopleVerifiedAt
                      ? _c("span", [
                          _c("i", {
                            staticClass: "el-icon-success bindWeChat"
                          }),
                          _c("span", { staticClass: "smallSize" }, [
                            _vm._v("已实人认证")
                          ])
                        ])
                      : _c("span", [
                          _c("i", { staticClass: "el-icon-success notbind" }),
                          _c("span", { staticClass: "smallSize" }, [
                            _vm._v("未实人认证")
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v("身份：")
                    ]),
                    _vm._v(" 教师\n          "),
                    _vm.teacherInfoData.checkStatus === 2
                      ? _c("span", [
                          _c("i", {
                            staticClass: "el-icon-success bindWeChat"
                          }),
                          _c("span", { staticClass: "smallSize" }, [
                            _vm._v("已认证")
                          ])
                        ])
                      : _c("span", [
                          _c("i", { staticClass: "el-icon-success notbind" }),
                          _c("span", { staticClass: "smallSize" }, [
                            _vm._v("未认证")
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v("学校：")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.schoolNameIsNull(
                            _vm.teacherInfoData.schoolProvince
                          )
                        ) +
                          _vm._s(
                            _vm.schoolNameIsNull(_vm.teacherInfoData.schoolCity)
                          ) +
                          _vm._s(
                            _vm.schoolNameIsNull(
                              _vm.teacherInfoData.schoolCounty
                            )
                          ) +
                          _vm._s(
                            _vm.isNull(
                              _vm.teacherInfoData.schoolName ||
                                _vm.teacherInfoData.schoolCustomAddress
                            )
                          )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v(" 职务：")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.isNull(_vm.teacherInfoData.teachZw)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v(" 任教学段：")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.isNull(_vm.teacherInfoData.teachXd)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v(" 认证来源：")
                    ]),
                    _vm._v(" "),
                    _vm.teacherInfoData.channel === "2"
                      ? _c("span", [
                          _vm._v("云零售-" + _vm._s(_vm.storeInfo.storeName))
                        ])
                      : _c("span", [_vm._v("淘知学堂")])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "commonWidth" }, [
                      _vm._v(" 备注信息：")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.isNull(_vm.teacherInfoData.description))
                      )
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottomBtn" }, [
              _c(
                "div",
                { staticStyle: { "margin-left": "16px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "danger", plain: "" },
                      on: { click: _vm.cancelQualification }
                    },
                    [_vm._v("取消资质")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-right": "16px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleClickEdit(false)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _vm._v(" "),
                  _vm.teacherInfoData.checkStatus !== 2
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            size: "mini",
                            type: "success",
                            plain: "",
                            loading: _vm.passLoading
                          },
                          on: { click: _vm.pass }
                        },
                        [_vm._v("通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.teacherInfoData.checkStatus !== 3
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "danger", plain: "" },
                          on: { click: _vm.open }
                        },
                        [_vm._v("驳回")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "teacher-reject-dialog",
                attrs: {
                  "close-on-click-modal": false,
                  "append-to-body": "",
                  "close-on-press-escape": false,
                  title: "驳回申请",
                  visible: _vm.dialogVisibleTeacherotPass,
                  width: "30%"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogVisibleTeacherotPass = $event
                  }
                }
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "reasonText",
                    staticClass: "demo-ruleForm",
                    attrs: { model: _vm.reasonTeacher }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "reason",
                          rules: [{ required: true, message: "原因不能为空" }]
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            autosize: { minRows: 4, maxRows: 4 },
                            type: "textarea",
                            placeholder: "请描述驳回原因",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.reasonTeacher.reason,
                            callback: function($$v) {
                              _vm.$set(_vm.reasonTeacher, "reason", $$v)
                            },
                            expression: "reasonTeacher.reason"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.dialogVisibleTeacherotPass = false
                          }
                        }
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.rejectTeacher("reasonText")
                          }
                        }
                      },
                      [_vm._v("确 定")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "教师资质",
                  "append-to-body": "",
                  visible: _vm.cancelQualificationDialog,
                  width: "30%"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.cancelQualificationDialog = $event
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(
                    "确认取消此教师的教师资质？取消资质的教师，不可再次重复认证。"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.cancelQualificationDialog = false
                          }
                        }
                      },
                      [_vm._v("放 弃")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: { click: _vm.cancelQualificationHandle }
                      },
                      [_vm._v("确 认")]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("edit-teacher-info", {
              attrs: {
                newOr: _vm.newOr,
                visible: _vm.editTeacherinfoDia,
                userOpenId: _vm.userOpenId
              },
              on: {
                "update:visible": function($event) {
                  _vm.editTeacherinfoDia = $event
                },
                refreshTeacherInfo: _vm.refreshTeacherInfo
              }
            })
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "commonCard",
            class: _vm.userOpenId == "" ? "pointerEvents" : "",
            on: {
              click: function($event) {
                return _vm.handleClickEdit(true)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "avatar-uploader" },
              [
                _c("svg-icon", {
                  staticClass: "iconStyable",
                  attrs: { "icon-class": "md-perm_contact_calendar" }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      margin: "10px 0",
                      color: "#303133"
                    }
                  },
                  [_vm._v("尚无教师资质")]
                ),
                _vm._v(" "),
                _c("div", [_vm._v("点击此处，填写教师资质")])
              ],
              1
            ),
            _vm._v(" "),
            _c("edit-teacher-info", {
              attrs: {
                newOr: _vm.newOr,
                visible: _vm.editTeacherinfoDia,
                userOpenId: _vm.userOpenId
              },
              on: {
                "update:visible": function($event) {
                  _vm.editTeacherinfoDia = $event
                },
                refreshTeacherInfo: _vm.refreshTeacherInfo
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }