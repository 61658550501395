var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "open-screen-advertising"
    },
    [
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addAdvert } },
            [_vm._v("新建")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-select",
                {
                  staticClass: "search-select",
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    clearable: "",
                    placeholder: "请选择系统"
                  },
                  on: { change: _vm.getAdvertList },
                  model: {
                    value: _vm.appProductSearchList,
                    callback: function($$v) {
                      _vm.appProductSearchList = $$v
                    },
                    expression: "appProductSearchList"
                  }
                },
                _vm._l(_vm.dictionary, function(item) {
                  return _c("el-option", {
                    key: item.openId,
                    attrs: { label: item.title, value: item.openId }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "search-input",
                attrs: { clearable: "", placeholder: "请输入广告名称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getAdvertList($event)
                  }
                },
                model: {
                  value: _vm.searchVal,
                  callback: function($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.getAdvertList }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "advert-table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, height: "calc(100vh - 225px)" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "广告图" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticClass: "cover",
                      attrs: {
                        fit: "cover",
                        src: _vm.getImgUrl(scope.row.cover)
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleCover(scope.row.cover)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "广告名称", prop: "name" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "应用", prop: "appProductOpenId" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "点击量/展示量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.clickQuantity) +
                        "/" +
                        _vm._s(scope.row.showQuantity) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "点击率", prop: "clickRate" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    !scope.row.expiredAt
                      ? _c("div", { staticClass: "normal" }, [_vm._v("正常")])
                      : _c("div", [
                          new Date().getTime() -
                            new Date(scope.row.expiredAt).getTime() >
                          0
                            ? _c("div", { staticClass: "invalid" }, [
                                _vm._v("失效")
                              ])
                            : _c("div", { staticClass: "normal" }, [
                                _vm._v("正常")
                              ])
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "invalid",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleRemove(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        staticClass: "foot-pagination",
        attrs: {
          total: _vm.totalElements,
          page: _vm.query.page,
          limit: _vm.query.size,
          "current-page": _vm.query.page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.query, "size", $event)
          },
          pagination: _vm.getAdvertList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "添加开屏广告",
            visible: _vm.dialogVisible,
            width: "530",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, rules: _vm.rule, "label-width": "80px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "广告名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请填写广告名称/上传图片后将自动获取"
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择系统", prop: "appProductOpenIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "common-width",
                      attrs: {
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择系统"
                      },
                      model: {
                        value: _vm.form.appProductOpenIds,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "appProductOpenIds", $$v)
                        },
                        expression: "form.appProductOpenIds"
                      }
                    },
                    _vm._l(_vm.dictionary, function(item) {
                      return _c("el-option", {
                        key: item.openId,
                        attrs: { label: item.title, value: item.openId }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "font-size": "14px" } }, [
                    _c("i", {
                      staticClass: "iconInfo el-icon-info",
                      staticStyle: { color: "#409EFF" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#909399" } }, [
                      _vm._v("选择广告所要显示在哪个系统")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "广告图片", prop: "cover" } },
                [
                  _c(
                    "div",
                    { staticClass: "advert-upload-cover" },
                    [
                      _c("coverupload", {
                        attrs: {
                          coverisshow: false,
                          coverarr: [100, 178],
                          covertext: "",
                          texttips: "点击上传",
                          type: "6",
                          "upload-fn": _vm.openScreenAdvertisingUploadBatch
                        },
                        on: { deletecover: _vm.deleteCover },
                        model: {
                          value: _vm.form.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cover", $$v)
                          },
                          expression: "form.cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "cover-text" }, [
                        _c("i", {
                          staticClass: "iconInfo el-icon-info tip-icon",
                          staticStyle: { color: "#409EFF" }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cover-tip" },
                          [
                            _c("span", { staticStyle: { color: "#909399" } }, [
                              _vm._v(
                                "建议尺寸750*1334px，JPG、PNG格式， 图片小于1M。可以点击下方“下载”按钮下载广告图psd源文件作为参考。"
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "download-btn",
                                attrs: {
                                  type: "primary",
                                  loading: _vm.downloading
                                },
                                on: { click: _vm.download }
                              },
                              [_vm._v("下载")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转链接", prop: "linkInfo" } },
                [
                  !_vm.form.linkInfo
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.openSelectLink = true
                            }
                          }
                        },
                        [_vm._v("选择连接")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.linkInfo
                    ? _c("div", { staticClass: "link" }, [
                        _c("span", { staticClass: "link-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.form.linkInfo
                                ? _vm.form.linkInfo.display.title
                                : undefined
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("i", {
                            staticClass: "el-icon-sort change-link-icon",
                            on: {
                              click: function($event) {
                                _vm.openSelectLink = true
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-delete link-icon",
                            on: { click: _vm.handleDeleteLink }
                          })
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "失效时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择失效日期时间"
                    },
                    model: {
                      value: _vm.form.expiredAt,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "expiredAt", $$v)
                      },
                      expression: "form.expiredAt"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submit }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.imagePath
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "2010" },
            attrs: { "on-close": _vm.close, "url-list": [_vm.imagePath] }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.openSelectLink },
        on: {
          "update:visible": function($event) {
            _vm.openSelectLink = $event
          }
        },
        model: {
          value: _vm.form.linkInfo,
          callback: function($$v) {
            _vm.$set(_vm.form, "linkInfo", $$v)
          },
          expression: "form.linkInfo"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }