var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-resource-table" }, [
    _c(
      "div",
      { staticClass: "content-table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "singleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.resourceList,
              height: "100%",
              "tooltip-effect": "dark",
              "highlight-current-row": !_vm.multipleCheck,
              "row-class-name": _vm.tableRowClassName
            },
            on: { "selection-change": _vm.handleSelectionChange }
          },
          [
            _vm.multipleCheck
              ? _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "55",
                    selectable: _vm.selectable
                  }
                })
              : _c("el-table-column", {
                  attrs: { width: "30" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: scope.row.resourceOpenId },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.clickRadio(scope.row)
                                }
                              },
                              model: {
                                value: _vm.resourceOpenId,
                                callback: function($$v) {
                                  _vm.resourceOpenId = $$v
                                },
                                expression: "resourceOpenId"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "radio-content-hidden" },
                                [_vm._v(_vm._s(scope.row.resourceOpenId))]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "作品" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "resource-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cover-layout",
                            class: _vm.getResourceCoverClass(
                              scope.row.resourceType
                            )
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.getResourceCover(scope.row),
                                alt: "cover"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "resource-title-layout" }, [
                          _c("h3", { staticClass: "resource-title" }, [
                            _vm._v(_vm._s(_vm.displayTitle(scope.row)))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "resource-info" }, [
                            _vm._v(_vm._s(_vm.getResourceInfo(scope.row)))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "resource-type" }, [
                            _vm._v(
                              _vm._s(
                                _vm.translationResourceType(
                                  scope.row.resourceType
                                )
                              )
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "resource-remark-bg" }, [
                        _c("div", { staticClass: "resource-id" }, [
                          _vm._v(
                            "作品编号:" +
                              _vm._s(
                                scope.row.resourceEncode
                                  ? scope.row.resourceEncode
                                  : "--"
                              )
                          )
                        ])
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "data",
                width: "220",
                align: "center",
                label: "发布时间"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.showVal(scope.row.publishedAt)))
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }