"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

var _common = require("@/utils/common");

//
//
//
//
//
//
var _default = {
  name: 'PageSectionDelete',
  props: {
    value: {
      required: true,
      type: Object
    },
    location: {
      required: true,
      type: Array
    }
  },
  computed: {
    associate: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    pageSectionPoint: function pageSectionPoint() {
      var _ref;

      var _this$location = (0, _slicedToArray2.default)(this.location, 2),
          first = _this$location[0],
          second = _this$location[1];

      return _ref = {}, (0, _defineProperty2.default)(_ref, first, '-12px'), (0, _defineProperty2.default)(_ref, second, '-12px'), _ref;
    }
  },
  methods: {
    onDelete: function onDelete() {
      var _this = this;

      this.$confirm('确定要删除吗？').then(function () {
        _this.$store.dispatch('book/section/delSingleSection', _this.associate.localId);

        _this.$store.dispatch('book/section/initSections', _this.$store.state.book.page.page.id);

        (0, _common.msgHandle)('删除成功', 'success');
      });
    }
  }
};
exports.default = _default;