var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userContailer" },
    [
      _vm.userInfo
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.userInfoLoading,
                  expression: "userInfoLoading"
                }
              ],
              staticClass: "contailer"
            },
            [
              _c("div", { staticClass: "userItem" }, [
                _c("p", { staticClass: "itemTitle" }, [_vm._v("用户ID")]),
                _vm._v(" "),
                _c("div", { staticClass: "itemBox" }, [
                  _c("p", { staticClass: "itemBoxText" }, [
                    _vm._v(
                      _vm._s(_vm.userInfo.openId ? _vm.userInfo.openId : "--")
                    )
                  ]),
                  _vm._v(" "),
                  _c("span")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userItem" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "itemBox" },
                  [
                    _c("p", { staticClass: "itemBoxText" }, [
                      _vm._v(
                        _vm._s(
                          _vm.userInfo.displayName
                            ? _vm.userInfo.displayName
                            : "--"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "itemBoxBtn",
                        attrs: { type: "text" },
                        on: { click: _vm.setUserName }
                      },
                      [_vm._v("设置")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userItem" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "itemBox" },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "48px",
                        height: "48px",
                        "border-radius": "4px"
                      },
                      attrs: {
                        src: _vm.getImgUrl(
                          _vm.userInfo.cover,
                          _vm.thumbnailStyle
                        ),
                        fit: "cover"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "itemBoxBtn",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.fileBtn.click()
                          }
                        }
                      },
                      [_vm._v("设置")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      ref: "fileBtn",
                      attrs: { id: "fileBtn", accept: "image/*", type: "file" },
                      on: { change: _vm.Upload }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userItem" }, [
                _c("p", { staticClass: "itemTitle" }, [_vm._v("同步")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "itemBox" },
                  [
                    _c("p", { staticClass: "itemBoxText" }, [
                      _vm._v(
                        _vm._s(_vm.showVal(_vm.userInfo.sectionName)) +
                          "/" +
                          _vm._s(_vm.showVal(_vm.userInfo.gradeName)) +
                          "/" +
                          _vm._s(_vm.showVal(_vm.userInfo.volumeName))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "itemBoxBtn",
                        attrs: { type: "text" },
                        on: { click: _vm.setUserInfoasyc }
                      },
                      [_vm._v("设置")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userItem" }, [
                _c("p", { staticClass: "itemTitle" }, [_vm._v("微信")]),
                _vm._v(" "),
                _c("div", { staticClass: "itemBox" }, [
                  _c(
                    "p",
                    {
                      staticClass: "itemBoxText",
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "el-image",
                        {
                          staticClass: "userCover",
                          attrs: {
                            src: _vm.getImgUrl(
                              _vm.userInfo.cover,
                              _vm.thumbnailStyle
                            ),
                            alt: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline"
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#606266",
                            "padding-right": "5px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.userInfo.displayName
                                ? _vm.userInfo.displayName
                                : "--"
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.userInfo.wxChatUnionId
                              ? _vm.userInfo.wxChatUnionId
                              : "--"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm.userInfo.wxChatUnionId
                        ? _c("span", [
                            _c("i", { staticClass: "el-icon-success binded" }),
                            _vm._v(" 已绑定")
                          ])
                        : _c("span", { staticClass: "notbind" }, [
                            _c("i", { staticClass: "el-icon-info" }),
                            _vm._v("\n            未绑定\n          ")
                          ])
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userItem" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "itemBox" },
                  [
                    _c("p", { staticClass: "itemBoxText" }, [
                      _vm._v(
                        _vm._s(_vm.userInfo.phone ? _vm.userInfo.phone : "--") +
                          "\n          "
                      ),
                      _vm.userInfo.phone
                        ? _c("span", [
                            _c("i", { staticClass: "el-icon-success binded" }),
                            _vm._v(" 已绑定")
                          ])
                        : _c("span", { staticClass: "notbind" }, [
                            _c("i", { staticClass: "el-icon-info" }),
                            _vm._v(" 未绑定 ")
                          ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "itemBoxBtn",
                        attrs: { type: "text" },
                        on: { click: _vm.setUserPhone }
                      },
                      [_vm._v("设置")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userItem" }, [
                _c("p", { staticClass: "itemTitle" }, [_vm._v("超管备注")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "itemBox" },
                  [
                    _c("p", { staticClass: "itemBoxText" }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.showVal(_vm.userInfo.adminRemarks))
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#909399", "font-size": "12px" }
                        },
                        [_vm._v("仅总后台管理人员可见")]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "itemBoxBtn",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            _vm.dialogRemarksinformationVisible = true
                          }
                        }
                      },
                      [_vm._v("设置")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingdialogFormVisible,
                            expression: "loadingdialogFormVisible"
                          }
                        ],
                        attrs: {
                          "append-to-body": "",
                          title: "设置备注信息",
                          visible: _vm.dialogRemarksinformationVisible,
                          width: "30%",
                          "close-on-click-modal": false,
                          "close-on-press-escape": false
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.dialogRemarksinformationVisible = $event
                          }
                        }
                      },
                      [
                        _c(
                          "el-form",
                          { ref: "orderRecord" },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    placeholder: "请描述备注信息"
                                  },
                                  model: {
                                    value: _vm.adminRemarks,
                                    callback: function($$v) {
                                      _vm.adminRemarks = $$v
                                    },
                                    expression: "adminRemarks"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer"
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.dialogRemarksinformationVisible = false
                                  }
                                }
                              },
                              [_vm._v("取 消")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.remarksinfo }
                              },
                              [_vm._v("保存")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "设置昵称",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogVisibleName,
            width: "610px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleName = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "80px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "15", "show-word-limit": "" },
                        model: {
                          value: _vm.userName,
                          callback: function($$v) {
                            _vm.userName = $$v
                          },
                          expression: "userName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "dialogInfoText" }, [
                _c("i", { staticClass: "el-icon-info" }),
                _vm._v(" 支持中英文、数字、下划线_和连接符-")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleName = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.editUserNameDisabled
                  },
                  on: { click: _vm.editUserName }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "设置手机号",
            visible: _vm.dialogVisiblePhone,
            width: "610px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisiblePhone = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "80px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号：" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "11" },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function($$v) {
                            _vm.phoneNumber = $$v
                          },
                          expression: "phoneNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisiblePhone = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.confirmEditUserPhone
                  },
                  on: { click: _vm.setUserPhoneBtn }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "设置同步",
            visible: _vm.dialogVisibleasyc,
            width: "610px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleasyc = $event
            }
          }
        },
        [
          _vm.dialogVisibleasyc
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "asycUserForm",
                      attrs: {
                        "label-width": "80px",
                        model: _vm.asycUserForm,
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "学段", prop: "sectionCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.asycUserForm.sectionCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.asycUserForm, "sectionCode", $$v)
                                },
                                expression: "asycUserForm.sectionCode"
                              }
                            },
                            _vm._l(_vm.SyncSectionData, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "年级", prop: "gradeCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.asycUserForm.gradeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.asycUserForm, "gradeCode", $$v)
                                },
                                expression: "asycUserForm.gradeCode"
                              }
                            },
                            _vm._l(_vm.allSyncGrade, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "阶段", prop: "volumeCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.asycUserForm.volumeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.asycUserForm, "volumeCode", $$v)
                                },
                                expression: "asycUserForm.volumeCode"
                              }
                            },
                            _vm._l(_vm.volumeCodetion, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleasyc = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.asycUserFormLoading },
                  on: {
                    click: function($event) {
                      return _vm.setUserasyc("asycUserForm")
                    }
                  }
                },
                [_vm._v(" 确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("dislog", {
        attrs: {
          "append-to-body": "",
          "set-weixin": _vm.setWeixin,
          "user-info": _vm.userInfo
        },
        on: { iSetWeixin: _vm.iSetWeixin }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "itemTitle" }, [
      _c("span", { staticClass: "notEmpty" }, [_vm._v("*")]),
      _vm._v("昵称")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "itemTitle" }, [
      _c("span", { staticClass: "notEmpty" }, [_vm._v("*")]),
      _vm._v("头像")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "itemTitle" }, [
      _c("span", { staticClass: "notEmpty" }, [_vm._v("*")]),
      _vm._v("手机号")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { padding: "0 5px" } }, [
      _c("i", {
        staticClass: "iconInfo el-icon-info",
        staticStyle: { color: "#409EFF" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }