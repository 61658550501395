var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c("work-statistics", { attrs: { isRefresh: _vm.isRefresh } }),
      _vm._v(" "),
      _c("div", { staticClass: "timeRange" }, [
        _c("h3", [_vm._v("时间范围")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "picker-options": _vm.pickerOptions,
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "value-format": "yyyy-MM-dd",
                format: "yyyy-MM-dd",
                align: "right",
                clearable: false
              },
              model: {
                value: _vm.timeRange,
                callback: function($$v) {
                  _vm.timeRange = $$v
                },
                expression: "timeRange"
              }
            }),
            _vm._v(" "),
            _c("i", {
              staticClass: "iconfont icon-refresh refreshBtn",
              on: { click: _vm.refreshAllPage }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [
          _c("product-application", {
            attrs: { timeRange: _vm.timeRange, isRefresh: _vm.isRefresh }
          }),
          _vm._v(" "),
          _c("section-recored", {
            attrs: { timeRange: _vm.timeRange, isRefresh: _vm.isRefresh }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [
          _c("resources", {
            attrs: { timeRange: _vm.timeRange, isRefresh: _vm.isRefresh }
          }),
          _vm._v(" "),
          _c("merchant", {
            attrs: { timeRange: _vm.timeRange, isRefresh: _vm.isRefresh }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("learning-records", {
        attrs: { isRefresh: _vm.isRefresh, timeRange: _vm.timeRange }
      }),
      _vm._v(" "),
      _c("proportion-resource", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: { timeRange: _vm.timeRange, isRefresh: _vm.isRefresh }
      }),
      _vm._v(" "),
      _c("proportion-merchants", {
        attrs: { timeRange: _vm.timeRange, isRefresh: _vm.isRefresh }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }