"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _oss = require("../../../../utils/oss");

var _common = require("../../../../utils/common");

var _resource = require("../../../../utils/resource");

var _ResourceSimple = _interopRequireDefault(require("@/components/Resource/ResourceItem/ResourceSimple.vue"));

var _const = require("../../../../utils/const");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogTableContent',
  components: {
    resourceSimple: _ResourceSimple.default
  },
  props: {
    resourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dialogType: {
      type: String,
      default: 'add'
    }
  },
  data: function data() {
    return {
      LIVE_ID: _const.LIVE_ID,
      ossUrl: _oss.ossUrl,
      showVal: _common.showVal,
      getResourceInfo: _resource.getResourceInfo,
      translationResourceType: _resource.translationResourceType,
      defaultCover: 'https://open.oss.taozhi.online/production/assets/mp/resource/select-resource/book_cover.png?x-oss-process=style%2Fthumbnail'
    };
  },
  computed: {
    selection: function selection() {
      return this.$refs.table.selection;
    },
    clearSelection: function clearSelection() {
      return this.$refs.table.clearSelection;
    }
  },
  methods: {
    getResourceCoverClass: function getResourceCoverClass(type) {
      return parseInt(type, 10) === 3 ? 'book-cover' : 'resource-cover';
    },
    getResourceCover: function getResourceCover() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cover = _ref.cover,
          resourceType = _ref.resourceType;

      // 获取封面图片
      if ([5, 6].includes(resourceType)) {
        var name = resourceType === 5 ? 'jinxinbiCover' : 'vipCover';
        return "https://open.oss.taozhi.online/develop/mp/orderCover/".concat(name, ".png?x-oss-process=style%2Fthumbnail");
      }

      return this.ossUrl(cover, this.defaultCover);
    },

    /**
     * 显示标题
     */
    displayTitle: function displayTitle(data) {
      if (data.resourceTitle && data.resourceTitle.trim().length > 0) {
        return data.resourceTitle;
      }

      return data.title;
    },
    selectable: function selectable(_ref2) {
      var itemId = _ref2.resourceOpenId;
      var except = this.excludeData.find(function (_ref3) {
        var resourceOpenId = _ref3.resourceOpenId;
        return resourceOpenId === itemId;
      });
      return !except;
    },
    tableRowClassName: function tableRowClassName(_ref4) {
      var row = _ref4.row;
      return this.selectable(row) ? '' : 'disabled-row';
    },
    // 滚动到顶部
    handleScrollTop: function handleScrollTop() {
      if (!this.$refs.table) {
        return;
      }

      this.$refs.table.bodyWrapper.scrollTop = 0;
    }
  }
};
exports.default = _default2;