"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PriceWithCheckBox',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    precision: {
      type: Number,
      default: 0
    },
    check: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    checkLabel: {
      type: String,
      default: undefined
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    valOnUncheck: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    checkProxy: {
      get: function get() {
        return this.check;
      },
      set: function set(value) {
        this.$emit('update:check', value);
        this.valueProxy = this.valOnUncheck;
      }
    },
    isCanInput: function isCanInput() {
      return this.disabledEdit || this.checkProxy;
    }
  }
};
exports.default = _default;