var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "teacher-list-wrapper",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c("div", { staticClass: "teacher-contailer" }, [
        _c(
          "div",
          { staticClass: "teacher-header" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: {
                  click: function($event) {
                    return _vm.addTeacher()
                  }
                }
              },
              [_vm._v("添加")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search-box" },
              [
                _c("el-input", {
                  staticClass: "search-txt",
                  attrs: { placeholder: "搜索关键词", clearable: "" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchTeacher($event)
                    }
                  },
                  model: {
                    value: _vm.query.keyword,
                    callback: function($$v) {
                      _vm.$set(_vm.query, "keyword", $$v)
                    },
                    expression: "query.keyword"
                  }
                }),
                _vm._v(" "),
                _c(
                  "search-btn",
                  {
                    staticClass: "search-con-bg",
                    on: { searchContent: _vm.searchTeacher }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "search-teacher-con" },
                      [
                        _c(
                          "el-form",
                          { attrs: { "label-width": "60px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "学科" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "select-sync",
                                    attrs: { size: "small", multiple: "" },
                                    model: {
                                      value: _vm.subjectCodes,
                                      callback: function($$v) {
                                        _vm.subjectCodes = $$v
                                      },
                                      expression: "subjectCodes"
                                    }
                                  },
                                  _vm._l(_vm.syncObj.subject, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "年级" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "select-sync",
                                    attrs: { size: "small", multiple: "" },
                                    model: {
                                      value: _vm.gradeCodes,
                                      callback: function($$v) {
                                        _vm.gradeCodes = $$v
                                      },
                                      expression: "gradeCodes"
                                    }
                                  },
                                  _vm._l(_vm.syncObj.grade, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "阶段" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "select-sync",
                                    attrs: { size: "small", multiple: "" },
                                    model: {
                                      value: _vm.volumeCodes,
                                      callback: function($$v) {
                                        _vm.volumeCodes = $$v
                                      },
                                      expression: "volumeCodes"
                                    }
                                  },
                                  _vm._l(_vm.syncObj.volume, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teacher-table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading"
                  }
                ],
                ref: "famousTeacherList",
                staticStyle: { width: "100%" },
                attrs: { height: "100%", data: _vm.tableData }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "80", label: "排序" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              ref: "popover-" + scope.row.famousTeacherOpenId,
                              attrs: {
                                "popper-class": "preview-popover",
                                placement: "top-start",
                                trigger: "click"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sequence-num-bg" },
                                [
                                  _c("el-input", {
                                    staticClass: "sequence-num",
                                    model: {
                                      value: scope.row.temporarySequence,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "temporarySequence",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "scope.row.temporarySequence"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "iconfont icon-xuanzhong",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeSequence(
                                          scope.row.famousTeacherOpenId,
                                          scope.row.temporarySequence
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "sequence-txt",
                                  attrs: { slot: "reference" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.selectTeacher(
                                        scope.row.famousTeacherOpenId
                                      )
                                    }
                                  },
                                  slot: "reference"
                                },
                                [_vm._v(_vm._s(scope.row.sequence))]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "教师形象" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return scope.row.cover
                            ? [
                                scope.row.cover
                                  ? _c("el-image", {
                                      staticClass: "cover-img",
                                      attrs: {
                                        src: _vm.getImgUrl(scope.row.cover)
                                          ? _vm.getImgUrl(scope.row.cover)
                                          : "",
                                        fit: "cover"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : undefined
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "姓名" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "nickName", label: "昵称" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "subject", label: "任教" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "teach", label: "学科" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "resourceCount", label: "作品数量" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "是否展示" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce66" },
                            on: {
                              change: function($event) {
                                return _vm.isChangeShow(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.showed,
                              callback: function($$v) {
                                _vm.$set(scope.row, "showed", $$v)
                              },
                              expression: "scope.row.showed"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "60", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.addTeacher(
                                    scope.row.famousTeacherOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "remove-btn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.delTeacher(
                                    scope.row.famousTeacherOpenId
                                  )
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footPage" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.initData
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }