"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogFootbar',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    total: {
      type: Number,
      default: 0
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectCount: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      selectedAll: false,
      isIndeterminate: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    showPagination: function showPagination() {
      return this.total > 1;
    }
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange() {
      this.$emit('handleCheckAllChange', this.selectedAll);
    }
  }
};
exports.default = _default2;