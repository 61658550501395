"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CG_From_Input_HeaderWithBatchSet',
  props: {
    label: {
      type: String,
      default: undefined
    },
    defVal: {
      type: Number,
      default: undefined
    },
    inputWidth: {
      type: String,
      default: '50px'
    },
    hasSuffix: {
      type: Boolean,
      default: true
    },
    inputMax: {
      type: Number,
      default: 100
    },
    inputMin: {
      type: Number,
      default: 0
    },
    parserGet: {
      type: Function,
      default: _priceParser.discountToPercent
    },
    parserSet: {
      type: Function,
      default: _priceParser.parseDiscountFromPercent
    }
  },
  data: function data() {
    return {
      input: undefined
    };
  },
  computed: {
    inputProxy: {
      get: function get() {
        return this.parserGet(this.input);
      },
      set: function set(value) {
        this.input = this.parserSet(value);
      }
    },
    batchSetStyle: function batchSetStyle() {
      return {
        width: this.inputWidth
      };
    }
  },
  mounted: function mounted() {
    this.input = this.defVal;
  },
  methods: {
    change: function change() {
      this.$emit('update', this.input);
    }
  }
};
exports.default = _default;