var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "testList" }, [
        _c("div", { staticClass: "paperName" }, [
          _vm._v("\n      " + _vm._s(_vm.list.name) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.testList.length === 0
          ? _c("div", { staticClass: "noData" }, [
              _c(
                "div",
                { staticClass: "noDataTitle" },
                [
                  _c("svg-icon", { attrs: { "icon-class": "paper" } }),
                  _vm._v("\n        暂未创建题组\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "creatTest" }, [
                _vm._v("\n        点击右侧创建题组\n      ")
              ])
            ])
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ]
              },
              [
                _c("test-drag", {
                  ref: "testDrag",
                  attrs: {
                    "paper-info": _vm.list,
                    "active-group": _vm.activeName,
                    "partner-open-id": _vm.partnerOpenId,
                    "question-list": _vm.testList,
                    "is-disable": _vm.isDisable
                  },
                  on: {
                    isShrink: _vm.isShrink,
                    changeTab: _vm.changeTab,
                    upDateMark: _vm.upDateMark
                  }
                })
              ],
              1
            )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "questionScore" },
        [
          _c(
            "div",
            { staticClass: "addBtn" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: _vm.isDisable, type: "primary" },
                  on: { click: _vm.getTestList }
                },
                [_vm._v("添加题组")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              staticClass: "group-tab",
              attrs: { "tab-position": "right" },
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.testList, function(item, index) {
              return _c(
                "el-tab-pane",
                {
                  key: item.id,
                  staticClass: "scoreBox",
                  attrs: { name: item.id.toString() }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "group-tab-label",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "questionInfo",
                          style: {
                            color:
                              _vm.activeName === item.id.toString()
                                ? "#409EFF"
                                : ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.name ? item.name : "题组" + (index + 1)
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "num" }, [
                        _c("div", [
                          _vm._v("\n              共 "),
                          _c("span", { staticClass: "counts" }, [
                            _vm._v(
                              _vm._s(
                                item.examPaperQuestionsList
                                  ? item.examPaperQuestionsList.length > 0
                                    ? item.examPaperQuestionsList.length
                                    : 0
                                  : 0
                              )
                            )
                          ]),
                          _vm._v(" 题，共 "),
                          _c("span", { staticClass: "counts" }, [
                            _vm._v(
                              _vm._s(item.allMark ? item.allMark : 0) + " "
                            )
                          ]),
                          _vm._v("分数\n            ")
                        ]),
                        _vm._v(" "),
                        item.name !== "练习题"
                          ? _c("i", {
                              staticClass: "el-icon-delete",
                              style: {
                                cursor: _vm.isDisable
                                  ? "not-allowed"
                                  : "pointer"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deletedGroup(item, index)
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "batch-attribute",
              style: { cursor: _vm.isDisable ? "not-allowed" : "pointer" },
              on: { click: _vm.handleAttribute }
            },
            [
              _c("i", { staticClass: "el-icon-setting set-icon" }),
              _vm._v("批量修改试题属性")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.openAttribute
        ? _c("batch-set-question-attributes", {
            attrs: {
              visible: _vm.openAttribute,
              title: "批量设置属性（试卷）",
              "is-paper": true,
              list: _vm.testList
            },
            on: {
              "update:visible": function($event) {
                _vm.openAttribute = $event
              },
              attributeSuccess: _vm.getPaperQuestionGroup
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }