var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addressDetail" },
    [
      _c(
        "el-card",
        { staticClass: "positionRelative", attrs: { shadow: "hover" } },
        [
          _c("div", { staticClass: "addressItem commonColor" }, [
            _c("div", [
              _c("span", { staticClass: "recipient" }, [
                _vm._v(_vm._s(_vm.item.recipient))
              ]),
              _vm._v(_vm._s(_vm.item.phone))
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                _vm._s(_vm.item.province) +
                  _vm._s(_vm.item.city) +
                  _vm._s(_vm.item.county) +
                  _vm._s(_vm.item.detail)
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "iconManagement" }, [
              _c("i", {
                staticClass: "el-icon-edit",
                on: {
                  click: function($event) {
                    return _vm.editAddress(_vm.item)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete",
                on: {
                  click: function($event) {
                    return _vm.deleteAddress(_vm.item)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _vm.item.preferred
            ? _c(
                "div",
                { staticClass: "preferred" },
                [_c("el-tag", { attrs: { size: "mini" } }, [_vm._v("默认")])],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }