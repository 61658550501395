"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _editorTopOperation = _interopRequireDefault(require("@/components/BookPageEditor/editorTopOperation.vue"));

var _editor = _interopRequireDefault(require("@/components/BookPageEditor/editor.vue"));

var _index = _interopRequireDefault(require("@/components/BookPageSection/index.vue"));

var _index2 = _interopRequireDefault(require("@/components/BookMenu/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Update',
  components: {
    BookMenu: _index2.default,
    PageSection: _index.default,
    EditorTopOperation: _editorTopOperation.default,
    Editor: _editor.default
  },
  props: {
    openId: {
      type: String,
      default: ''
    },
    state: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    sections: function sections() {
      return this.$store.state.book.section.sections;
    }
  },
  watch: {},
  mounted: function mounted() {
    if (this.openId) {
      this.$store.dispatch('book/selectBook', this.openId);
    }

    this.$store.dispatch('sysIcon/get_sys_icons');
    this.$store.dispatch('userIcon/get_user_icons');
  },
  methods: {}
};
exports.default = _default;