var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-problem" },
    [
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addCommonProblem } },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "nav-search" },
            [
              _c(
                "el-select",
                {
                  staticClass: "search-select",
                  attrs: { placeholder: "全部", clearable: "" },
                  on: { change: _vm.search },
                  model: {
                    value: _vm.searchSelect,
                    callback: function($$v) {
                      _vm.searchSelect = $$v
                    },
                    expression: "searchSelect"
                  }
                },
                _vm._l(_vm.searchCheckList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "search-select",
                  attrs: { placeholder: "类型", clearable: "" },
                  on: { change: _vm.search },
                  model: {
                    value: _vm.searchProblemClassificationId,
                    callback: function($$v) {
                      _vm.searchProblemClassificationId = $$v
                    },
                    expression: "searchProblemClassificationId"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "infinite-scroll",
                          rawName: "v-infinite-scroll",
                          value: _vm.loadScroll,
                          expression: "loadScroll"
                        }
                      ],
                      staticStyle: { height: "200px", overflow: "auto" },
                      attrs: {
                        "infinite-scroll-immediate": "false",
                        "infinite-scroll-disabled": "disabled"
                      }
                    },
                    _vm._l(_vm.problemClassificationList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.classificationName,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "search-input",
                attrs: { placeholder: "关键字搜索", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.searchProblemVal,
                  callback: function($$v) {
                    _vm.searchProblemVal = $$v
                  },
                  expression: "searchProblemVal"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { "hide-on-click": false } },
                [
                  _c(
                    "div",
                    { staticClass: "type-button" },
                    [
                      _c("el-button", {
                        attrs: { plain: "", icon: "el-icon-s-tools" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.handleClassification($event)
                            }
                          }
                        },
                        [_vm._v("分类管理")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { height: "100%", data: _vm.problemList }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "problemTitle",
                  label: "常见问题",
                  width: "230",
                  "show-overflow-tooltip": true
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "classificationName",
                  label: "类型",
                  width: "180"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "newIntended", label: "适用人群" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "clickAmount", label: "点击量" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "updatedAt", label: "更新时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("parseTime")(
                                scope.row.updatedAt,
                                "{y}-{m}-{d}"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { "hide-on-click": false } },
                          [
                            _c(
                              "el-link",
                              {
                                staticClass: "more",
                                attrs: { underline: false, type: "primary" }
                              },
                              [_vm._v("更多")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleEdit(scope.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleDelete(scope.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.getProblemListData
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          ref: "addProblemDialog",
          staticClass: "add-dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.addShow,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "970px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.addFormLoading,
                  expression: "addFormLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "add-event",
                  attrs: { label: "问题标题", prop: "problemTitle" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "45",
                      clearable: "",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.problemTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "problemTitle", $$v)
                      },
                      expression: "form.problemTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "问题描述" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      clearable: "",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: "请输入内容",
                      maxlength: "200",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.problemDescribe,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "problemDescribe", $$v)
                      },
                      expression: "form.problemDescribe"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "问题分类", prop: "problemClassificationId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "classification",
                      attrs: { placeholder: "分类" },
                      on: { change: _vm.changeClassificationName },
                      model: {
                        value: _vm.form.problemClassificationId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "problemClassificationId", $$v)
                        },
                        expression: "form.problemClassificationId"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "infinite-scroll",
                              rawName: "v-infinite-scroll",
                              value: _vm.loadScroll,
                              expression: "loadScroll"
                            }
                          ],
                          staticStyle: { height: "200px", overflow: "auto" },
                          attrs: {
                            "infinite-scroll-immediate": "false",
                            "infinite-scroll-disabled": _vm.disabled
                          }
                        },
                        _vm._l(_vm.problemClassificationList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.classificationName,
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用人群", prop: "intended" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "type-check",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-right": "30px" },
                          attrs: { disabled: _vm.checkAllDisabled },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.checkAll,
                            callback: function($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll"
                          }
                        },
                        [_vm._v("全部")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.handleCheckedChange },
                          model: {
                            value: _vm.addCheckedOptions,
                            callback: function($$v) {
                              _vm.addCheckedOptions = $$v
                            },
                            expression: "addCheckedOptions"
                          }
                        },
                        _vm._l(_vm.addCheckList, function(item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.id,
                              attrs: { label: item.id, disabled: item.disabled }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "问题详情", prop: "problemDetail" } },
                [
                  _c("editor", {
                    attrs: { config: _vm.editorConfig },
                    model: {
                      value: _vm.form.problemDetail,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "problemDetail", $$v)
                      },
                      expression: "form.problemDetail"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "视频" } }, [
                !_vm.videoInfo || !_vm.videoInfo.resourceOpenId
                  ? _c(
                      "div",
                      [
                        _c("div", [
                          _vm._v("从已上传的视频中选择可以解决此问题的视频")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleSelectResource }
                          },
                          [_vm._v("选择")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.videoInfo && _vm.videoInfo.resourceOpenId
                  ? _c(
                      "div",
                      { staticClass: "video" },
                      [
                        _c("el-image", {
                          staticClass: "video-cover",
                          attrs: {
                            fit: "cover",
                            src: _vm.videoInfo.cover
                              ? _vm.getImageUrl(_vm.videoInfo.cover)
                              : _vm.defaultCover
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "video-title-option" }, [
                          _c("span", { staticClass: "video-title" }, [
                            _vm._v(_vm._s(_vm.videoInfo.title))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "delete-and-change" }, [
                            _c("i", {
                              staticClass: "el-icon-sort change-icon icon",
                              on: { click: _vm.changeCurrentVideo }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-delete icon",
                              on: { click: _vm.deleteCurrentVideo }
                            })
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addLoading },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("problem-detail", {
        attrs: { visible: _vm.detailsDialog, "problem-id": _vm.problemId },
        on: {
          "update:visible": function($event) {
            _vm.detailsDialog = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "type-dialog",
          attrs: {
            title: "常见问题分类",
            visible: _vm.classificationDialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "650px"
          },
          on: {
            "update:visible": function($event) {
              _vm.classificationDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "nav" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.addClassification }
                },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "search" },
                [
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { clearable: "", placeholder: "关键字搜索" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.classificationSearch($event)
                      }
                    },
                    model: {
                      value: _vm.searchClassificationVal,
                      callback: function($$v) {
                        _vm.searchClassificationVal = $$v
                      },
                      expression: "searchClassificationVal"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.classificationSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.classificationLoading,
                  expression: "classificationLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { height: "265px", data: _vm.classificationList }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "classificationName",
                  label: "分类名称",
                  "show-overflow-tooltip": true
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "newIntended", label: "适用人群", width: "150" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "updatedAt", label: "更新时间", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("parseTime")(
                                scope.row.updatedAt,
                                "{y}-{m}-{d}"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "right", label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "type-dialog-link" },
                          [
                            _c("el-link", {
                              staticClass: "type-edit-link",
                              attrs: { icon: "el-icon-edit", underline: false },
                              on: {
                                click: function($event) {
                                  return _vm.handleClassificationEdit(scope.row)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("el-link", {
                              staticClass: "type-detail-link",
                              attrs: {
                                icon: "el-icon-delete",
                                underline: false
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleClassificationDelete(
                                    scope.row.id
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-footer" },
            [
              _c("el-pagination", {
                attrs: {
                  layout: "prev, pager, next",
                  "page-size": _vm.classificationQuery.size,
                  "current-page": _vm.classificationQuery.page,
                  total: _vm.classificationTotal
                },
                on: { "current-change": _vm.classificationCurrentChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "inner-dialog-view",
          attrs: {
            title: _vm.addClassificationDialogTitle,
            width: "460px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.addClassificationDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.addClassificationDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "classificationForm",
              attrs: { model: _vm.classificationData }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": "",
                      placeholder: "输入分类名称"
                    },
                    model: {
                      value: _vm.classificationData.classificationName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.classificationData,
                          "classificationName",
                          $$v
                        )
                      },
                      expression: "classificationData.classificationName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { prop: "intended" } }, [
                _c(
                  "div",
                  { staticClass: "type-check" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "30px" },
                        on: { change: _vm.classificationCheckAll },
                        model: {
                          value: _vm.checkClassificationAll,
                          callback: function($$v) {
                            _vm.checkClassificationAll = $$v
                          },
                          expression: "checkClassificationAll"
                        }
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.classificationChecked },
                        model: {
                          value: _vm.classificationCheckOptions,
                          callback: function($$v) {
                            _vm.classificationCheckOptions = $$v
                          },
                          expression: "classificationCheckOptions"
                        }
                      },
                      _vm._l(_vm.checkList, function(item) {
                        return _c(
                          "el-checkbox",
                          { key: item.id, attrs: { label: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "排序" } }, [
                _c("div", { staticClass: "classification-foot" }, [
                  _c(
                    "div",
                    [
                      _c("el-input-number", {
                        staticClass: "sequence",
                        attrs: { precision: 0, "controls-position": "right" },
                        model: {
                          value: _vm.classificationData.sequence,
                          callback: function($$v) {
                            _vm.$set(_vm.classificationData, "sequence", $$v)
                          },
                          expression: "classificationData.sequence"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "数字越大，排位越靠前",
                            placement: "top"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "icon el-icon-info",
                            staticStyle: {
                              "font-size": "14px",
                              color: "#409EFF",
                              "margin-left": "10px"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.classificationReset } },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.submitLoading
                          },
                          on: { click: _vm.classificationSubmit }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "select-video",
          attrs: {
            title: "选择视频",
            visible: _vm.isOpen,
            width: "1000px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.isOpen = $event
            }
          }
        },
        [
          _c("select-resource", {
            ref: "selectResource",
            attrs: {
              "expect-type": "wordContent",
              "resource-type": 1,
              "resource-open-id": _vm.resourceOpenId
            },
            on: { handleResource: _vm.handleResource }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "select-video-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.resetVideo } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmitVideo }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }