"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));

var _SelectEbookDialog = _interopRequireDefault(require("@/components/Samplebook/BookCity/SelectEbookDialog.vue"));

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectEbookCover',
  components: {
    SelectEbookDialog: _SelectEbookDialog.default,
    ElImageViewer: _imageViewer.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    partnerOpenId: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      showPreview: false,
      imagePath: undefined
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleSelect: function handleSelect() {
      if (this.disabled) {
        return;
      }

      this.visible = true;
    },
    // 关闭预览图
    closeViewer: function closeViewer() {
      this.showPreview = false;
    },
    // 预览图片
    onPreview: function onPreview() {
      this.imagePath = (0, _common.getImageUrl)(this.valueProxy.cover, 'style/width-limit-1000');
      this.showPreview = true;
    },
    deletedImage: function deletedImage() {
      this.valueProxy.cover = undefined;
    },
    submit: function submit(val) {
      this.valueProxy.cover = val.coverDisplay;
      this.valueProxy.coverType = val.coverType;
    },
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-256';

      if (!cover) {
        return undefined;
      }

      if (cover.indexOf('http') !== -1) {
        return cover;
      }

      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;