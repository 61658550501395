"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSelectAttrs = getSelectAttrs;
exports.getCount = getCount;
exports.getType = getType;
exports.getUnit = getUnit;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/composition/mp/"));
/**
 * 作文属性列表分页查询
 * @param params
 * @returns {*}
 */

function getSelectAttrs(params) {
  return request.get('composition/selectAttrs', {
    params: params
  });
}
/**
 * 查询字数标签列表
 * @returns {*}
 */


function getCount(params) {
  return request.get('count/', {
    params: params
  });
}
/**
 * 查询体裁列表
 * @returns {*}
 */


function getType(params) {
  return request.get('type/', {
    params: params
  });
}

function getUnit(params) {
  return request.get('unit/', {
    params: params
  });
}