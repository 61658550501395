"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cashOutCheck = _interopRequireDefault(require("./cashOutCheck.vue"));

var _cashOutRecord = _interopRequireDefault(require("./cashOutRecord.vue"));

var _promotionUserCheck = _interopRequireDefault(require("./promotionUserCheck.vue"));

var _promotionUserRecord = _interopRequireDefault(require("./promotionUserRecord.vue"));

var _promotionUserManage = _interopRequireDefault(require("./promotionUserManage.vue"));

var _promotionRecord = _interopRequireDefault(require("./promotionRecord.vue"));

var _promotionCommodity = _interopRequireDefault(require("./promotionCommodity.vue"));

var _promotionSetting = _interopRequireDefault(require("./promotionSetting.vue"));

var _promotionSettingPlatform = _interopRequireDefault(require("./promotionSettingPlatform.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PromotionManage',
  components: {
    cashOutCheck: _cashOutCheck.default,
    cashOutRecord: _cashOutRecord.default,
    promotionUserCheck: _promotionUserCheck.default,
    promotionUserRecord: _promotionUserRecord.default,
    promotionUserManage: _promotionUserManage.default,
    promotionRecord: _promotionRecord.default,
    promotionCommodity: _promotionCommodity.default,
    promotionSetting: _promotionSetting.default,
    promotionSettingPlatform: _promotionSettingPlatform.default
  },
  data: function data() {
    return {
      tableName: '提现审核',
      cashOutName: '提现审核',
      settingName: '推广设置',
      promoterCheckName: '推广员审核'
    };
  },
  methods: {
    selectTabs: function selectTabs(tab) {
      this.tableName = tab.label;
    },
    cashOutTabs: function cashOutTabs(tab) {
      this.cashOutName = tab.label;
    },
    settingTabs: function settingTabs(tab) {
      this.settingName = tab.label;
    },
    promoterCheckTabs: function promoterCheckTabs(tab) {
      this.promoterCheckName = tab.label;
    }
  }
};
exports.default = _default;