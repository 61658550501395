var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c("el-input-number", {
        staticClass: "batch-set",
        style: _vm.batchSetStyle,
        attrs: { max: _vm.inputMax, min: _vm.inputMin, controls: false },
        on: { change: _vm.change },
        model: {
          value: _vm.inputProxy,
          callback: function($$v) {
            _vm.inputProxy = $$v
          },
          expression: "inputProxy"
        }
      }),
      _vm._v(" "),
      _vm.hasSuffix ? _c("span", [_vm._v("%")]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }