var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wraper" }, [
    _c("div", { staticClass: "titleBox" }, [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("h3", { staticClass: "recordsTitle" }, [_vm._v("学习记录")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              staticClass: "radioBox",
              attrs: { size: "medium" },
              model: {
                value: _vm.moldStep,
                callback: function($$v) {
                  _vm.moldStep = $$v
                },
                expression: "moldStep"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "lesson" } }, [
                _vm._v("课程")
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "consumer" } }, [
                _vm._v("用户")
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "timeer" } }, [
                _vm._v("时长")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btnGroup" },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "radioBox",
              attrs: { size: "medium" },
              model: {
                value: _vm.timeStep,
                callback: function($$v) {
                  _vm.timeStep = $$v
                },
                expression: "timeStep"
              }
            },
            [
              _c(
                "el-radio-button",
                { attrs: { label: "hour", disabled: _vm.disHour } },
                [_vm._v("小时")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { attrs: { label: "day", disabled: _vm.disDay } },
                [_vm._v("天")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { attrs: { label: "month", disabled: _vm.disMonth } },
                [_vm._v("月")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { attrs: { label: "quarter", disabled: _vm.disQuarter } },
                [_vm._v("季度")]
              ),
              _vm._v(" "),
              _c(
                "el-radio-button",
                { attrs: { label: "year", disabled: _vm.disYear } },
                [_vm._v("年")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "10px", height: "36px" },
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.exportReport }
            },
            [_vm._v("导出报表")]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.dataLoading,
            expression: "dataLoading"
          }
        ],
        staticClass: "chartContainer"
      },
      [_c("div", { ref: "recordChart", attrs: { id: "recordChart" } })]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tableContainerRatio" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              "default-sort": { prop: "date", order: "descending" },
              data: _vm.tableData
            },
            on: { "sort-change": _vm.sortChange }
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "日期",
                "min-width": "20",
                sortable: "custom",
                prop: "date"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(scope.row.date))
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                sortable: "custom",
                "header-align": "right",
                align: "right",
                "min-width": "20",
                property: "courseNum",
                label: "累计观看课时数"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "textRight" }, [
                        _vm._v(_vm._s(scope.row.courseNum))
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                sortable: "custom",
                "header-align": "right",
                align: "right",
                "min-width": "20",
                property: "courseFinishedNum",
                label: "结课数"
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function(scope) {
                    return [
                      _c(
                        "span",
                        [
                          _vm._v("结课数\n              "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "用户学习完成后的课程数",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "iconInfo el-icon-info" })]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "textRight" }, [
                        _vm._v(" " + _vm._s(scope.row.courseFinishedNum))
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                sortable: "custom",
                "header-align": "right",
                align: "right",
                "min-width": "20",
                property: "courseFinishedRate",
                label: "结课率"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "textRight" }, [
                        _vm._v(_vm._s(scope.row.courseFinishedRate))
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                sortable: "custom",
                align: "right",
                "header-align": "right",
                "min-width": "20",
                property: "userNum",
                label: "观看用户数"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(scope.row.userNum))
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                sortable: "custom",
                align: "right",
                "header-align": "right",
                "min-width": "20",
                property: "grandBrowseDuration",
                label: "累计学习时长（分钟）"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(scope.row.grandBrowseDuration))
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                sortable: "custom",
                align: "right",
                "header-align": "right",
                "min-width": "20",
                property: "perBrowseDuration",
                label: "人均学习时长（分钟）"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(scope.row.perBrowseDuration))
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer footerPage" },
      [
        _c("Pagination", {
          attrs: {
            total: _vm.totalElements,
            page: _vm.query.page,
            limit: _vm.query.size,
            "current-page": _vm.query.page
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.query, "page", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.query, "size", $event)
            },
            pagination: _vm.initData
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }