var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-section no-select",
      class: {
        "page-section-active": _vm.active,
        "section-penetrating": _vm.sectionCanPenetrate
      },
      style: _vm.pageSectionStyle,
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.onSectionClick($event)
        }
      }
    },
    [
      _c("page-section-sequence", {
        attrs: { "link-section": _vm.section, "active-status": _vm.active }
      }),
      _vm._v(" "),
      _vm.active
        ? _c("page-section-area", {
            model: {
              value: _vm.section,
              callback: function($$v) {
                _vm.section = $$v
              },
              expression: "section"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.active
        ? _c("page-section-point", {
            attrs: { location: ["right", "bottom"] },
            model: {
              value: _vm.section,
              callback: function($$v) {
                _vm.section = $$v
              },
              expression: "section"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.active
        ? _c("page-section-delete", {
            attrs: { location: ["right", "top"] },
            model: {
              value: _vm.section,
              callback: function($$v) {
                _vm.section = $$v
              },
              expression: "section"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("page-section-view-count", {
        attrs: { "link-section": _vm.section, "active-status": _vm.active }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content", attrs: { draggable: "false" } }, [
        _vm.imageSection
          ? _c("img", {
              attrs: {
                src: _vm.getImgUrl(_vm.section.iconUrl),
                draggable: "false",
                alt: ""
              }
            })
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }