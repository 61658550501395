"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQuestionTypeList = getQuestionTypeList;
exports.addQuestion = addQuestion;
exports.getQuestionList = getQuestionList;
exports.addPaperClass = addPaperClass;
exports.getPaperClass = getPaperClass;
exports.modifyPaperClass = modifyPaperClass;
exports.delPaperClass = delPaperClass;
exports.addQuestionClass = addQuestionClass;
exports.getQuestionClass = getQuestionClass;
exports.getQuestionPoints = getQuestionPoints;
exports.delQuestionClass = delQuestionClass;
exports.upDataQuestionClass = upDataQuestionClass;
exports.addKnowledge = addKnowledge;
exports.upDataQuestionPoints = upDataQuestionPoints;
exports.delQuestionPoints = delQuestionPoints;
exports.getQuestionDetailsByOpenId = getQuestionDetailsByOpenId;
exports.upDateQuestion = upDateQuestion;
exports.addPaper = addPaper;
exports.getPaperList = getPaperList;
exports.getPaperDetails = getPaperDetails;
exports.upDatePaper = upDatePaper;
exports.addQuestionGroup = addQuestionGroup;
exports.getQuestionGroup = getQuestionGroup;
exports.delQuestionGroup = delQuestionGroup;
exports.screenQuestion = screenQuestion;
exports.questionGroupAddQuestion = questionGroupAddQuestion;
exports.delQuestionGroupQuestion = delQuestionGroupQuestion;
exports.moveQuestionGroupQuestion = moveQuestionGroupQuestion;
exports.moveQuestionClassNode = moveQuestionClassNode;
exports.upDateQuestionGroup = upDateQuestionGroup;
exports.upDateQuestionGroupQuestion = upDateQuestionGroupQuestion;
exports.getPaperStatusNumber = getPaperStatusNumber;
exports.deleteSubQuestionById = deleteSubQuestionById;
exports.subQuestionAdd = subQuestionAdd;
exports.delQuestion = delQuestion;
exports.copyQuestion = copyQuestion;
exports.questionMoveClass = questionMoveClass;
exports.editPaperStatus = editPaperStatus;
exports.getKnowledgeList = getKnowledgeList;
exports.getNextLevelKnowledge = getNextLevelKnowledge;
exports.moveKnowledge = moveKnowledge;
exports.sequenceKnowledge = sequenceKnowledge;
exports.copyPaper = copyPaper;
exports.paperMoveClass = paperMoveClass;
exports.delPaper = delPaper;
exports.isHidePaper = isHidePaper;
exports.isSoldPaper = isSoldPaper;
exports.batchPublicPaper = batchPublicPaper;
exports.replaceQuestions = replaceQuestions;
exports.moveQuestionGroup = moveQuestionGroup;
exports.sonQuestionSort = sonQuestionSort;
exports.batchMovePaperCategory = batchMovePaperCategory;
exports.batchMoveQuestionCategory = batchMoveQuestionCategory;
exports.batchSetAttribute = batchSetAttribute;
exports.getQuestionTypeTag = getQuestionTypeTag;
exports.addQuestionTag = addQuestionTag;
exports.updateQuestionTag = updateQuestionTag;
exports.deleteQuestionTag = deleteQuestionTag;
exports.batchQuestionAttribute = batchQuestionAttribute;
exports.operationQuestionLog = operationQuestionLog;
exports.getPaperByOpenIds = getPaperByOpenIds;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/question/admin"));
var mpRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/question/mp"));
/**
 * 获取题型列表
 */

function getQuestionTypeList(data) {
  return request.get('/questionType/findQuestionTypeList', {
    params: data
  });
}
/**
 * 获取试题列表
 */


function getQuestionList(params) {
  return request.get('/examQuestion/findQuestionPage', {
    params: params
  });
}
/**
 * 根据试题openId查询试题详情
 */


function getQuestionDetailsByOpenId(params) {
  return request.get('/examQuestion/getQuestion', {
    params: params
  });
}
/**
 * 添加试题
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addQuestion(params) {
  return request.post('/examQuestion/addQuestion', params);
}
/**
 * 添加试卷分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addPaperClass(params) {
  return request.post('/paperCategory/addPaperCategory', params);
}
/**
 * 查询试卷分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPaperClass(params) {
  return request.get('/paperCategory/getPaperCategory', {
    params: params
  });
}
/**
 * 修改试卷分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function modifyPaperClass(id, params) {
  return request.put("/paperCategory/updatePaperCategory/".concat(id), params);
}
/**
 * 删除试卷分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delPaperClass(id) {
  return request.delete("/paperCategory/deletePaperCategory/".concat(id));
}
/**
 * 删除试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delQuestionClass(id) {
  return request.delete("/questionCategory/deleteQuestionCategory/".concat(id));
}
/**
 * 删除试题知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delQuestionPoints(id) {
  return request.delete("/knowledge/deleteKnowledge/".concat(id));
}
/**
 * 新增试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addQuestionClass(data) {
  return request.post('/questionCategory/addQuestionCategory', data);
}
/**
 * 新增试题知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addKnowledge(data) {
  return request.post('/knowledge/addKnowledge', data);
}
/**
 * 移动试题知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function moveKnowledge(data) {
  return request.post('/knowledge/moveKnowledge', null, {
    params: data
  });
}
/**
 * 查询试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getQuestionClass(params) {
  return request.get('/questionCategory/getQuestionCategory', {
    params: params
  });
}
/**
 * 修改试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function upDataQuestionClass(id, params) {
  return request.put("/questionCategory/updateQuestionCategory/".concat(id), params);
}
/**
 * 修改试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function upDataQuestionPoints(id, params) {
  return request.put("/knowledge/updateKnowledge/".concat(id), params);
}
/**
 * 修改试题
 * @param {Object} params
 * @returns {Promise<*>}
 */


function upDateQuestion(openId, params) {
  return request.put("/examQuestion/updateQuestion/".concat(openId), params);
}
/**
 * 查询试题知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getQuestionPoints(params) {
  return request.get('/knowledge/findKnowledgeList', {
    params: params
  });
}
/**
 * 新增试卷
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addPaper(data) {
  return request.post('/examPaper/addExamPaper', data);
}
/**
 * 查询试卷列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPaperList(params) {
  return request.get('/examPaper/findExamPaperPage', {
    params: params
  });
}
/**
 * 查询试卷详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPaperDetails(params) {
  return request.get('/examPaper/getExamPaper', {
    params: params
  });
}
/**
 * 修改试题
 * @param {Object} params
 * @returns {Promise<*>}
 */


function upDatePaper(openId, params) {
  return request.put("/examPaper/updateExamPaper/".concat(openId), params);
}
/**
 * 添加试题组
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addQuestionGroup(data) {
  return request.post('/examPaperGroup/addExamPaperGroup', data);
}
/**
 * 查询试题组
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getQuestionGroup(data) {
  return request.get('/examPaperGroup/getPaperQuestions', {
    params: data
  });
}
/**
 * 删除试题组
 * @id {Number} id
 * @returns {Promise<*>}
 */


function delQuestionGroup(id) {
  return request.delete("/examPaperGroup/deletePaperGroup/".concat(id));
}
/**
 * 筛选试题
 * @data {Object} data
 * @returns {Promise<*>}
 */


function screenQuestion(data) {
  return request.get('/examQuestion/screenQuestion', {
    params: data
  });
}
/**
 * 试题组添加试题
 * @data {Object} data
 * @returns {Promise<*>}
 */


function questionGroupAddQuestion(data) {
  return request.post('/examPaperQuestions/addExamPaperQuestions', data);
}
/**
 * 删除试题组下的试题
 * @id {Number} id
 * @returns {Promise<*>}
 */


function delQuestionGroupQuestion(id) {
  return request.delete("/examPaperQuestions/deleteExamPaperQuestions/".concat(id));
}
/**
 * 试题组试题上下移动
 * @id {Number} id
 * @returns {Promise<*>}
 */


function moveQuestionGroupQuestion(id, data) {
  return request.put("examPaperQuestions/changeQuestionPosition/".concat(id), undefined, {
    params: data
  });
}
/**
 * 试题分类移动
 * @id {Number} id
 * @returns {Promise<*>}
 */


function moveQuestionClassNode(data) {
  return request.post('questionCategory/moveQuestionCategory', undefined, {
    params: data
  });
}
/**
 * 试题组修改
 * @id {Number} id
 * @returns {Promise<*>}
 */


function upDateQuestionGroup(id, data) {
  return request.put("examPaperGroup/updatePaperGroup/".concat(id), data);
}
/**
 * 试题组试题修改
 * @id {Number} id
 * @returns {Promise<*>}
 */


function upDateQuestionGroupQuestion(id, data) {
  return request.put("examPaperQuestions/updateExamPaperQuestions/".concat(id), data);
}
/**
 * 获取试卷各个状态数量
 * @id {Number} id
 * @returns {Promise<*>}
 */


function getPaperStatusNumber(data) {
  return request.get('examPaper/statusNumber', {
    params: data
  });
}
/**
 * 删除组合题子题
 * @id {Number} id
 * @returns {Promise<*>}
 */


function deleteSubQuestionById(id) {
  return request.delete("subQuestion/deleteSubQuestion/".concat(id));
}
/**
 * 组合添加题子题
 * @id {Number} id
 * @returns {Promise<*>}
 */


function subQuestionAdd(data) {
  return request.post('subQuestion/addSubQuestion', data);
}
/**
 * 删除试题
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delQuestion(id) {
  return request.delete("/examQuestion/deleteQuestion?examQuestionOpenIds=".concat(id));
}
/**
 * 复制试题
 * @param {Object} params
 * @returns {Promise<*>}
 */


function copyQuestion(openId, data) {
  return request.put("/examQuestion/copyQuestion/".concat(openId), data);
}
/**
 * 复制试卷
 * @param {Object} params
 * @returns {Promise<*>}
 */


function copyPaper(openId) {
  return request.put("/examPaper/copyPaper/".concat(openId));
}
/**
 * 试题转移分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function questionMoveClass(openId, data) {
  return request.put("/examQuestion/moveQuestionCategory/".concat(openId), null, {
    params: data
  });
}
/**
 * 修改试卷状态
 * @param {Object} params
 * @returns {Promise<*>}
 */


function editPaperStatus(openId, data) {
  return request.put("/examPaper/publishExamPaper/".concat(openId), null, {
    params: data
  });
}
/**
 * 分页查询知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getKnowledgeList(params) {
  return request.get('/knowledge/findKnowledgePage', {
    params: params
  });
}
/**
 * 查询下级知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getNextLevelKnowledge(params) {
  return request.get('/knowledge/findLowLevelList', {
    params: params
  });
}
/**
 * 知识点排序
 * @param {Object} params
 * @returns {Promise<*>}
 */


function sequenceKnowledge(openId, data) {
  return request.put("/knowledge/sequenceKnowledge/".concat(openId), null, {
    params: data
  });
}
/**
 * 试卷转移分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function paperMoveClass(openId, data) {
  return request.put("/examPaper/movePaperCategory/".concat(openId), null, {
    params: data
  });
}
/**
 * 删除试卷
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delPaper(openIds) {
  return request.delete("/examPaper/deleteExamPaper?examPaperOpenIds=".concat(openIds));
}
/**
 * 官方隐藏
 * @param {Object} params
 * @returns {Promise<*>}
 */


function isHidePaper(openId, data) {
  return request.put("/examPaper/hideOrShowPaper/".concat(openId), null, {
    params: data
  });
}
/**
 * 官方售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function isSoldPaper(openId, data) {
  return request.put("/examPaper/stopOrStartPaper/".concat(openId), null, {
    params: data
  });
}
/**
 * 批量发布试卷
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchPublicPaper(data) {
  return request.put('/examPaper/batchPublishExamPaper', null, {
    params: data
  });
}
/**
 * 组合题替换试题
 * @param {Object} params
 * @returns {Promise<*>}
 */


function replaceQuestions(id, subQuestionOpenId) {
  return request.put("/subQuestion/replaceSubQuestion/".concat(id, "?subQuestionOpenId=").concat(subQuestionOpenId));
}
/**
 * 试题组拖拽
 * @param {Object} params
 * @returns {Promise<*>}
 */


function moveQuestionGroup(data) {
  return request.post('/examPaperGroup/changeGroupPosition', null, {
    params: data
  });
}
/**
 * 子题排序
 * @param {Object} params
 * @returns {Promise<*>}
 */


function sonQuestionSort(id, data) {
  return request.put("/subQuestion/sortSubQuestion/".concat(id), null, {
    params: data
  });
}
/**
 * 批量移动试卷分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchMovePaperCategory(data) {
  return request.put('/examPaper/category', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
/**
 * 批量移动试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchMoveQuestionCategory(data) {
  return request.put('examQuestion/category', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
/**
 * 批量设置试卷属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchSetAttribute(data) {
  return request.put('examPaper/syncInfo', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
/**
 * 查询题型标签列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getQuestionTypeTag(params) {
  return request.get('/questionTypeTag/list', {
    params: params
  });
}
/**
 * 添加题型标签
 * @returns {Promise<*>}
 * @param data
 */


function addQuestionTag(data) {
  return request.post('/questionTypeTag/add', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
/**
 * 修改题型标签
 * @returns {Promise<*>}
 * @param data
 */


function updateQuestionTag(data) {
  return request.put('/questionTypeTag/update', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
/**
 * 删除题型标签
 * @returns {Promise<*>}
 * @param data
 */


function deleteQuestionTag(data) {
  return request.delete('/questionTypeTag/delete', {
    data: data
  });
}
/**
 * 批量修改试题属性
 * @returns {Promise<*>}
 * @param data
 */


function batchQuestionAttribute(data) {
  return request.put('/examQuestion/property', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
/**
 * 查询题型标签列表
 * @param resourceOpenId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function operationQuestionLog(resourceOpenId, params) {
  return request.get("/examQuestion/request/operationLog/".concat(resourceOpenId), {
    params: params
  });
}
/**
 * 多个openIds获取试卷
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPaperByOpenIds(params) {
  return mpRequest.get('/examPaper/getClientExamPaper', {
    params: params
  });
}