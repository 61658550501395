"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.videoRequestList = videoRequestList;
exports.getVideoCount = getVideoCount;
exports.deleteVideo = deleteVideo;
exports.getVideoInfo = getVideoInfo;
exports.refuseVideoBy = refuseVideoBy;
exports.batchRefuseVideoBy = batchRefuseVideoBy;
exports.setVideoHide = setVideoHide;
exports.addVideo = addVideo;
exports.videoDiscontinued = videoDiscontinued;
exports.videoCancelStopSell = videoCancelStopSell;
exports.videoSetRecommendSequence = videoSetRecommendSequence;
exports.setVideoShow = setVideoShow;
exports.restoreVideo = restoreVideo;
exports.addVideoMark = addVideoMark;
exports.gerVideoMark = gerVideoMark;
exports.deleteVideoMark = deleteVideoMark;
exports.editVideoInfo = editVideoInfo;
exports.videoBatchSetAttribute = videoBatchSetAttribute;
exports.videoBatchRecovery = videoBatchRecovery;
exports.videoBatchIndependentSale = videoBatchIndependentSale;
exports.videoBatchReview = videoBatchReview;
exports.videoReview = videoReview;
exports.batchSubmitVideo = batchSubmitVideo;
exports.saveAndReview = saveAndReview;
exports.getVideoTitleList = getVideoTitleList;
exports.videoCopy = videoCopy;
exports.getVideoOutExcel = getVideoOutExcel;
exports.updateVideoEncode = updateVideoEncode;
exports.getCommodityVideoList = getCommodityVideoList;
exports.getVideoCover = getVideoCover;
exports.getSingleVideoCover = getSingleVideoCover;
exports.videoBatchRecycle = videoBatchRecycle;
exports.videoBatchRevoke = videoBatchRevoke;
exports.videoBatchCancelStopSale = videoBatchCancelStopSale;
exports.videoBatchStopSale = videoBatchStopSale;
exports.videoBatchCancelHidden = videoBatchCancelHidden;
exports.videoBatchHidden = videoBatchHidden;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/video-management/admin/"));
/**
 * 查询视频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function videoRequestList(params) {
  return request.get('/video/request/list', {
    params: params
  });
}
/**
 * 获取视频详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getVideoInfo(openId) {
  return request.get("/video/request/".concat(openId));
}
/**
 * 新建视频
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addVideo(data) {
  return request.post('/video/request', data);
}
/**
 * 保存并通过审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveAndReview(data) {
  return request.post('/video/request/saveAndReview', data);
}
/**
 * 编辑/修改视频详情
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editVideoInfo(openId, data) {
  return request.patch("/video/request/".concat(openId), data);
}
/**
 * 获取视频数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getVideoCount(params) {
  return request.get('/video/request/count', {
    params: params
  });
}
/**
 * 删除视频
 * @param {String} openId
 * @returns {Promise<*>}
 */


function deleteVideo(openId) {
  return request.delete("/video/request/".concat(openId));
}
/**
 * 恢复视频
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function restoreVideo(openIds) {
  return request.post('/video/request/batch/recovery', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 驳回视频
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function refuseVideoBy(openId, params) {
  return request.post("/video/request/refuse/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 批量驳回视频
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchRefuseVideoBy(params) {
  return request.post('video/request/batch/refuse', undefined, {
    params: params
  });
}
/**
 * 隐藏视频
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setVideoHide(openId, params) {
  return request.post("/video/request/hide/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 显示视频
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setVideoShow(openId, params) {
  return request.post("/video/request/hide/cancel/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 设置视频停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function videoDiscontinued(openId, params) {
  return request.post("/video/request/stopSell/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 开始售卖
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function videoCancelStopSell(openId, params) {
  return request.post("/video/request/stopSell/cancel/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 视频-设置推荐排序
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function videoSetRecommendSequence(openId, params) {
  return request.patch("/video/request/change/recommendSequence/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 查询视频打点
 * @param {String} openId
 * @returns {Promise<*>}
 */


function gerVideoMark(openId) {
  return request.get("/mark/request/".concat(openId));
}
/**
 * 增加视频打点
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addVideoMark(openId, data) {
  return request.post("/mark/request/batch/add/".concat(openId), data);
}
/**
 * 删除视频打点
 * @param {String} openId
 * @returns {Promise<*>}
 */


function deleteVideoMark(openId) {
  return request.delete("/mark/request/".concat(openId));
}
/**
 * 批量设置独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function videoBatchIndependentSale(params) {
  return request.patch('/video/request/batch/independentSale', undefined, {
    params: params
  });
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function videoBatchSetAttribute(params) {
  return request.patch('/video/request/batch/attribute', undefined, {
    params: params
  });
}
/**
 * 批量恢复
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchRecovery(openIds) {
  return request.post('/video/request/batch/recovery', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量恢复
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchRecycle(openIds) {
  return request.post('/video/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量审核视频
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchReview(openIds) {
  return request.post('/video/request/batch/review', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 审核视频
 * @returns {Promise<*>}
 */


function videoReview(openId) {
  return request.post("/video/request/".concat(openId, "/review"));
}
/**
 * 批量提交视频
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchSubmitVideo(params) {
  return request.patch('/video/request/batch/submit', undefined, {
    params: params
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getVideoTitleList(params) {
  return request.get('/video/request/repeat/title', {
    params: params
  });
}
/**
 * 复制视频
 * @param {Object} data
 * @returns {Promise<*>}
 */


function videoCopy(data) {
  return request.post('/video/request/copy', data);
}
/**
 * 批量导出数据
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getVideoOutExcel(params) {
  return request.get('/video/outExcel', {
    params: params
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateVideoEncode(resourceOpenId, resourceEncode) {
  return request.patch("/video/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 查询视频商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityVideoList(params) {
  return request.get('/video/admin', {
    params: params
  });
}
/**
 * 获取视频封面
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getVideoCover(openId) {
  return request.patch('/video/request/getCover', undefined, {
    params: {
      openId: openId
    }
  });
}
/**
 * 获取单个视频封面
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getSingleVideoCover(resourceOpenId) {
  return request.patch("/video/request/getVideoCover/".concat(resourceOpenId));
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchRevoke(openIds) {
  return request.post('video/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchHidden(openIds) {
  return request.patch('video/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchCancelHidden(openIds) {
  return request.patch('video/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function videoBatchStopSale(params) {
  return request.patch('video/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function videoBatchCancelStopSale(openIds) {
  return request.patch('video/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}