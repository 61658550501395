var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-platform-wrapper" },
    [
      _c("div", { staticClass: "setting-title-bg" }, [_vm._v("基础设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-con-bg" }, [
        _c(
          "div",
          { staticClass: "setting-table-wrapper" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                staticClass: "setting-table-bg",
                attrs: { data: _vm.settingList, height: "100%" }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "promotionName", label: "推广员名称" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "一级返佣" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.oneCommission) +
                              "%\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "二级返佣" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.twoCommission) +
                              "%\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "三级返佣" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.threeCommission) +
                              "%\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "推广折扣" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.promotionDiscount / 10) +
                              "折\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "客户关系" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "relationship-con" }, [
                            scope.row.validityTime === -1
                              ? _c(
                                  "span",
                                  { staticClass: "relationship-validity_time" },
                                  [_vm._v("客户永久有效,")]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "relationship-validity_time" },
                                  [
                                    _vm._v("客户"),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.validityTime
                                            ? scope.row.validityTime
                                            : 0
                                        ) + "天内有效,"
                                      )
                                    ])
                                  ]
                                ),
                            _vm._v(" "),
                            scope.row.robCustomer === -1
                              ? _c("span", { staticClass: "rob-customer" }, [
                                  _vm._v("随时抢客")
                                ])
                              : scope.row.robCustomer === -2
                              ? _c("span", { staticClass: "rob-customer" }, [
                                  _vm._v("不允许抢客")
                                ])
                              : _c("span", { staticClass: "rob-customer" }, [
                                  _vm._v(
                                    "客户" +
                                      _vm._s(
                                        scope.row.robCustomer
                                          ? scope.row.robCustomer
                                          : 0
                                      ) +
                                      "天后会被抢走"
                                  )
                                ])
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "70px", label: "操作", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handlerEditSettingPlatform(
                                    scope.row
                                  )
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-add-bg" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: { click: _vm.handlerAddPromotionType }
              },
              [_vm._v("添加类型")]
            ),
            _vm._v(" "),
            _vm._m(0)
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "setting-dialog-bg",
          attrs: {
            title: "推广员设置",
            "close-on-click-modal": false,
            visible: _vm.settingPlatformVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.settingPlatformVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "customer-dialog-box" },
            [
              _c("div", { staticClass: "setting-header-bg" }, [
                _vm._v("基础设置")
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "settingPlatformForm",
                  attrs: {
                    model: _vm.settingPlatForm,
                    rules: _vm.settingPlatRules,
                    "label-width": "220px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "推广员类别名称设置",
                        prop: "promotionName"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.settingPlatForm.promotionName,
                          callback: function($$v) {
                            _vm.$set(_vm.settingPlatForm, "promotionName", $$v)
                          },
                          expression: "settingPlatForm.promotionName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广比例设置", prop: "commission" } },
                    [
                      _c("div", { staticClass: "commission-setting-bg" }, [
                        _c(
                          "div",
                          { staticClass: "commission-setting" },
                          [
                            _vm._v("\n              一级返佣"),
                            _c("el-input-number", {
                              staticClass: "txt-number-bg2",
                              attrs: {
                                min: 0,
                                precision: 2,
                                step: 0.1,
                                max: 100,
                                "controls-position": "right"
                              },
                              model: {
                                value: _vm.settingPlatForm.oneCommission,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.settingPlatForm,
                                    "oneCommission",
                                    $$v
                                  )
                                },
                                expression: "settingPlatForm.oneCommission"
                              }
                            }),
                            _vm._v(" %\n            ")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "commission-setting" },
                          [
                            _vm._v("\n              二级返佣"),
                            _c("el-input-number", {
                              staticClass: "txt-number-bg2",
                              attrs: {
                                min: 0,
                                precision: 2,
                                step: 0.1,
                                "controls-position": "right"
                              },
                              model: {
                                value: _vm.settingPlatForm.twoCommission,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.settingPlatForm,
                                    "twoCommission",
                                    $$v
                                  )
                                },
                                expression: "settingPlatForm.twoCommission"
                              }
                            }),
                            _vm._v(" %\n            ")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "commission-setting" },
                          [
                            _vm._v("\n              三级返佣"),
                            _c("el-input-number", {
                              staticClass: "txt-number-bg2",
                              attrs: {
                                min: 0,
                                precision: 2,
                                step: 0.1,
                                "controls-position": "right"
                              },
                              model: {
                                value: _vm.settingPlatForm.threeCommission,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.settingPlatForm,
                                    "threeCommission",
                                    $$v
                                  )
                                },
                                expression: "settingPlatForm.threeCommission"
                              }
                            }),
                            _vm._v(" %\n            ")
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.settingPlatForm.commission,
                          callback: function($$v) {
                            _vm.$set(_vm.settingPlatForm, "commission", $$v)
                          },
                          expression: "settingPlatForm.commission"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广折扣" } },
                    [
                      _c("el-input-number", {
                        staticClass: "txt-number-bg",
                        attrs: {
                          min: 0,
                          precision: 1,
                          step: 0.1,
                          max: 10,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.promotionDiscount,
                          callback: function($$v) {
                            _vm.promotionDiscount = $$v
                          },
                          expression: "promotionDiscount"
                        }
                      }),
                      _vm._v("折\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "setting-header-bg" }, [
                    _vm._v("客户关系设置")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广员与客户绑定有效期" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-group-bg",
                          model: {
                            value: _vm.validityTimeRadio,
                            callback: function($$v) {
                              _vm.validityTimeRadio = $$v
                            },
                            expression: "validityTimeRadio"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: -1 } }, [
                            _vm._v("永久有效")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 1 } },
                            [
                              _c("el-input-number", {
                                staticClass: "txt-number-bg",
                                attrs: { min: 0, "controls-position": "right" },
                                on: { focus: _vm.validOnfocus },
                                model: {
                                  value: _vm.validityTimeNumber,
                                  callback: function($$v) {
                                    _vm.validityTimeNumber = $$v
                                  },
                                  expression: "validityTimeNumber"
                                }
                              }),
                              _vm._v("天有效")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期内其他推广员是否可抢客" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-group-bg",
                          model: {
                            value: _vm.robCustomerRadio,
                            callback: function($$v) {
                              _vm.robCustomerRadio = $$v
                            },
                            expression: "robCustomerRadio"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: -1 } }, [
                            _vm._v("随时可抢客")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: -2 } }, [
                            _vm._v("不允许抢客")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 2 } },
                            [
                              _vm._v("保护期"),
                              _c("el-input-number", {
                                staticClass: "txt-number-bg2",
                                attrs: { min: 0, "controls-position": "right" },
                                on: { focus: _vm.robOnfocus },
                                model: {
                                  value: _vm.robCustomerNumber,
                                  callback: function($$v) {
                                    _vm.robCustomerNumber = $$v
                                  },
                                  expression: "robCustomerNumber"
                                }
                              }),
                              _vm._v("天内不可抢客")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveSettingPlatform }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-add-remark" }, [
      _c("i", { staticClass: "el-icon-info" }),
      _vm._v(" "),
      _c("span", [_vm._v("特殊推广员不能升级；所有修改内容将在次日凌晨生效。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }