var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "app-version-box",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c("div", { staticClass: "contailer" }, [
        _c(
          "div",
          { staticClass: "version-header" },
          [
            _c(
              "div",
              { staticClass: "version-header-l" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "header-select",
                    attrs: { placeholder: "请选择应用", clearable: true },
                    on: { change: _vm.handleQueryList },
                    model: {
                      value: _vm.query.productOpenId,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "productOpenId", $$v)
                      },
                      expression: "query.productOpenId"
                    }
                  },
                  _vm._l(_vm.productOption, function(item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.title, value: item.openId }
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.title) + "\n          "
                        )
                      ]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "medium" },
                    on: { click: _vm.addVersionBtn }
                  },
                  [_vm._v("添加版本")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-radio-group",
              {
                on: { change: _vm.handlePlusChange },
                model: {
                  value: _vm.query.plus,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "plus", $$v)
                  },
                  expression: "query.plus"
                }
              },
              _vm._l(_vm.button_options, function(item) {
                return _c(
                  "el-radio-button",
                  {
                    key: item.value,
                    attrs: { value: item.value, label: item.value }
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tableBox" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading"
                  }
                ],
                ref: "appVersionList",
                staticStyle: { width: "100%" },
                attrs: { height: "100%", data: _vm.tableData },
                on: { "filter-change": _vm.filterHandler }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "versionCode",
                    "min-width": "40",
                    label: "版本号"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "versionName", label: "版本名" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "product-list-bg",
                  attrs: { prop: "productName", label: "应用" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "平台" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.platformOptions[scope.row.platform])
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "更新数量" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.installedCount
                                  ? scope.row.installedCount
                                  : 0
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "160", prop: "createdAt", label: "日期" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "测试模式" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66"
                            },
                            on: {
                              change: function($event) {
                                return _vm.isTestVersion(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.isTester,
                              callback: function($$v) {
                                _vm.$set(scope.row, "isTester", $$v)
                              },
                              expression: "scope.row.isTester"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "210", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.onCopyDownloadLinkClicked(
                                    $event,
                                    scope.row
                                  )
                                }
                              }
                            },
                            [_vm._v("复制链接")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editVersionBtn(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              class: [scope.row.active ? "removeBtn" : ""],
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.isActiveVersion(scope.row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(scope.row.active ? "关闭" : "开启"))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "removeBtn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.removeAppVersion(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footPage" },
          [
            _c("Pagination", {
              attrs: {
                total: _vm.totalElements,
                page: _vm.query.page,
                limit: _vm.query.size,
                "current-page": _vm.query.page
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.initData
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "version-dialog-bg",
          attrs: {
            title: _vm.versionDialogTitle,
            visible: _vm.versionDialogShow,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.versionDialogShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "versionDialogBox" },
            [
              _c(
                "el-form",
                {
                  ref: "versionForm",
                  attrs: {
                    rules: _vm.versionFormRules,
                    model: _vm.versionForm,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "应用", prop: "productOpenId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择应用", disabled: true },
                          model: {
                            value: _vm.versionForm.productOpenId,
                            callback: function($$v) {
                              _vm.$set(_vm.versionForm, "productOpenId", $$v)
                            },
                            expression: "versionForm.productOpenId"
                          }
                        },
                        _vm._l(_vm.productOption, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.title, value: item.openId }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台", prop: "platform" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.versionForm.platform,
                            callback: function($$v) {
                              _vm.$set(_vm.versionForm, "platform", _vm._n($$v))
                            },
                            expression: "versionForm.platform"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("Android")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("iOS")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本号", prop: "versionCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请填写版本号",
                          maxlength: 10,
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.versionForm.versionCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.versionForm,
                              "versionCode",
                              _vm._n($$v)
                            )
                          },
                          expression: "versionForm.versionCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本名", prop: "versionName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请填写版本名",
                          maxlength: 40,
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.versionForm.versionName,
                          callback: function($$v) {
                            _vm.$set(_vm.versionForm, "versionName", $$v)
                          },
                          expression: "versionForm.versionName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.versionForm.platform === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "androidAddress" } },
                        [
                          _c("div", { staticClass: "file-upload-bg" }, [
                            _c("div", { staticClass: "upload-result-bg" }, [
                              _c("div", { staticClass: "file-txt" }, [
                                _vm._v(_vm._s(_vm.uploadAppState))
                              ]),
                              _vm._v(" "),
                              _vm.androidAddress
                                ? _c("i", {
                                    staticClass: "el-icon-download icon-down",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.downAppPackage($event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "file-upload-box" }, [
                              _c("input", {
                                ref: "appUpload",
                                staticClass: "file-upload",
                                attrs: {
                                  id: "uploadAPP",
                                  multiple: _vm.multipleSelect,
                                  disabled: _vm.uploadAppDisabled,
                                  type: "file"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.uploadApp($event)
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.androidAddress,
                                  expression: "androidAddress"
                                }
                              ],
                              attrs: { type: "hidden" },
                              domProps: { value: _vm.androidAddress },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.androidAddress = $event.target.value
                                }
                              }
                            })
                          ])
                        ]
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "iosAddress" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder:
                                " 请填写应用地址，以用于跳转至应用商店"
                            },
                            model: {
                              value: _vm.iosAddress,
                              callback: function($$v) {
                                _vm.iosAddress = $$v
                              },
                              expression: "iosAddress"
                            }
                          })
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "announcement" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "200",
                          placeholder: "请填写版本备注，限200字以内。"
                        },
                        model: {
                          value: _vm.versionForm.announcement,
                          callback: function($$v) {
                            _vm.$set(_vm.versionForm, "announcement", $$v)
                          },
                          expression: "versionForm.announcement"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "测试模式" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "active-value": 1,
                          "inactive-value": 0
                        },
                        model: {
                          value: _vm.versionForm.isTester,
                          callback: function($$v) {
                            _vm.$set(_vm.versionForm, "isTester", $$v)
                          },
                          expression: "versionForm.isTester"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialogFoot" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.versionDialogShow = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitBtnLoading },
                  on: { click: _vm.saveInfo }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }