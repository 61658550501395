"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringToBackRouter = stringToBackRouter;
exports.backRouterToString = backRouterToString;
exports.pillarFlowBack = pillarFlowBack;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

var _main = _interopRequireDefault(require("../../organizationLibs/typeIs/main"));

var _index = _interopRequireDefault(require("../../router/index"));

/**
 * 判断一个对象是否满足 backRouter
 *
 * @param {object|undefined} backRouter
 * @returns {boolean}
 */
function isBackRouter(backRouter) {
  try {
    return _main.default.isObject(backRouter) && _main.default.isString(backRouter.name) && !_main.default.isEmpty(backRouter.name);
  } catch (e) {
    return false;
  }
}
/**
 * 解析 string 到 backRouter
 *
 * @param {string|undefined} string
 * @returns {object|undefined}
 */


function stringToBackRouter(string) {
  try {
    if (isBackRouter(string)) {
      return string;
    }

    if (_main.default.isString(string) && !_main.default.isEmpty(string)) {
      return JSON.parse(string);
    }
  } catch (e) {
    autoLog("backRouter \u4E0D\u80FD\u7F16\u7801\u4E3A string ".concat(e.message));
  }

  return undefined;
}
/**
 * 把 backRouter 转化为 string
 *
 * @param {object|undefined} backRouter
 * @returns {string|undefined}
 */


function backRouterToString(backRouter) {
  try {
    if (_main.default.isString(backRouter) && !_main.default.isEmpty(backRouter)) {
      return backRouter;
    }

    if (isBackRouter(backRouter)) {
      return JSON.stringify(backRouter);
    }
  } catch (e) {
    autoLog("backRouter \u4E0D\u80FD\u7F16\u7801\u4E3A string ".concat(e.message));
  }

  return undefined;
}
/**
 * 后退到上一页
 */


function pillarFlowBack() {
  try {
    var _router$currentRoute$ = _index.default.currentRoute.query,
        backUrl = _router$currentRoute$.back_url,
        backRouterString = _router$currentRoute$.back_router; // 如果有 back_router 以 back_router 为基准

    var backRouter = stringToBackRouter(backRouterString);

    if (isBackRouter(backRouter)) {
      _index.default.replace(backRouter);
    } else if (_main.default.isString(backUrl) && !_main.default.isEmpty(backUrl)) {
      // 如果有 back_url 以 back_url 为基准
      window.location.href = backUrl;
    } else if (window.history.length > 1) {
      // 如果有历史记录，允许返回上一页面
      _index.default.back();
    } else {
      // 什么都没有时候，回到主页
      _index.default.push({
        name: 'Home'
      });
    }
  } catch (e) {
    autoLog("\u8DF3\u8F6C\u4E0A\u4E00\u9875\u7684\u51FD\u6570\u4E2D\u62A5\u9519 ".concat(e.message));
  }
}