var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ebook-attribute",
      attrs: {
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleCancel,
        width: "662px",
        title: "批量设置属性"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "attributeForm",
          attrs: {
            model: _vm.attributeForm,
            "label-width": "60",
            "label-position": "right"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "form-check-bg" },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "form-check",
                    attrs: { label: "1", name: "type" },
                    model: {
                      value: _vm.attributeForm.isChangeSync,
                      callback: function($$v) {
                        _vm.$set(_vm.attributeForm, "isChangeSync", $$v)
                      },
                      expression: "attributeForm.isChangeSync"
                    }
                  },
                  [_vm._v("\n          同步\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-content-bg" },
              [
                _c("sync-view", {
                  attrs: { "is-disabled": !_vm.attributeForm.isChangeSync },
                  model: {
                    value: _vm.attributeForm.tempCode,
                    callback: function($$v) {
                      _vm.$set(_vm.attributeForm, "tempCode", $$v)
                    },
                    expression: "attributeForm.tempCode"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "form-check-bg" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { label: "2" },
                    model: {
                      value: _vm.attributeForm.isChangeTypes,
                      callback: function($$v) {
                        _vm.$set(_vm.attributeForm, "isChangeTypes", $$v)
                      },
                      expression: "attributeForm.isChangeTypes"
                    }
                  },
                  [_vm._v("\n          分类\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-content-bg" },
              [
                _c("works", {
                  ref: "worksView",
                  attrs: {
                    "is-disabled": !_vm.attributeForm.isChangeTypes,
                    isebook: true,
                    isbookcase: _vm.typeName === "书橱",
                    "edit-hide": true
                  },
                  on: { "add-classtion": _vm.addClass }
                }),
                _vm._v(" "),
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  attrs: { value: _vm.attributeForm.types }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "form-check-bg" },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "form-check",
                    attrs: { label: "3", name: "type" },
                    model: {
                      value: _vm.attributeForm.isChangePeriod,
                      callback: function($$v) {
                        _vm.$set(_vm.attributeForm, "isChangePeriod", $$v)
                      },
                      expression: "attributeForm.isChangePeriod"
                    }
                  },
                  [_vm._v("\n          版次\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-content-bg" },
              [
                _c("editionbox", {
                  attrs: { "is-disabled": !_vm.attributeForm.isChangePeriod },
                  model: {
                    value: _vm.attributeForm.periodId,
                    callback: function($$v) {
                      _vm.$set(_vm.attributeForm, "periodId", $$v)
                    },
                    expression: "attributeForm.periodId"
                  }
                }),
                _vm._v(" "),
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  model: {
                    value: _vm.attributeForm.periodId,
                    callback: function($$v) {
                      _vm.$set(_vm.attributeForm, "periodId", $$v)
                    },
                    expression: "attributeForm.periodId"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "form-check-bg" },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "form-check",
                    attrs: { label: "4", name: "type" },
                    model: {
                      value: _vm.attributeForm.isChangeTags,
                      callback: function($$v) {
                        _vm.$set(_vm.attributeForm, "isChangeTags", $$v)
                      },
                      expression: "attributeForm.isChangeTags"
                    }
                  },
                  [_vm._v("\n          标签\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-content-bg" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: !_vm.attributeForm.isChangeTags,
                      multiple: "",
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "请输入或选择商品标签"
                    },
                    on: { change: _vm.changeTags },
                    model: {
                      value: _vm.tagValue,
                      callback: function($$v) {
                        _vm.tagValue = $$v
                      },
                      expression: "tagValue"
                    }
                  },
                  _vm._l(_vm.tagOptions, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  model: {
                    value: _vm.attributeForm.tagValue,
                    callback: function($$v) {
                      _vm.$set(_vm.attributeForm, "tagValue", $$v)
                    },
                    expression: "attributeForm.tagValue"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticStyle: { "margin-bottom": "0" }, attrs: { label: "" } },
            [
              _c(
                "div",
                { staticClass: "form-check-bg" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "form-check",
                      attrs: { label: "4", name: "type" },
                      model: {
                        value: _vm.attributeForm.isChangeArea,
                        callback: function($$v) {
                          _vm.$set(_vm.attributeForm, "isChangeArea", $$v)
                        },
                        expression: "attributeForm.isChangeArea"
                      }
                    },
                    [_vm._v("地区")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-content-bg" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: !_vm.attributeForm.isChangeArea,
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "请输入或选择地区"
                      },
                      on: { change: _vm.changeArea },
                      model: {
                        value: _vm.areaValue,
                        callback: function($$v) {
                          _vm.areaValue = $$v
                        },
                        expression: "areaValue"
                      }
                    },
                    _vm._l(_vm.areaList, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    model: {
                      value: _vm.attributeForm.areaValue,
                      callback: function($$v) {
                        _vm.$set(_vm.attributeForm, "areaValue", $$v)
                      },
                      expression: "attributeForm.areaValue"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("table.cancel")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.btnSetAttribute, type: "primary" },
              on: { click: _vm.setAttributes }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.btnSetAttribute ? "保存中..." : "确定") +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }