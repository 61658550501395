var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "public-top" },
    [
      _vm.tagsName === "awaitDeal"
        ? _c(
            "el-link",
            {
              staticClass: "common-icon",
              attrs: { icon: "el-icon-circle-plus-outline", underline: false },
              on: { click: _vm.addOrder }
            },
            [_c("span", { staticClass: "add-span" }, [_vm._v("新建工单")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tagsName === "createForMe"
        ? _c(
            "div",
            { staticClass: "select" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "创建时间降序",
                    clearable: ""
                  },
                  on: { change: _vm.handleSelect },
                  model: {
                    value: _vm.sortOrder,
                    callback: function($$v) {
                      _vm.sortOrder = $$v
                    },
                    expression: "sortOrder"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "优先级降序", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "优先级升序", value: 2 } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "创建时间降序", value: 3 }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "创建时间升序", value: 4 }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "refresh-search" },
        [
          _c("el-link", {
            staticClass: "common-icon refresh",
            attrs: { icon: "el-icon-refresh-right", underline: false },
            on: { click: _vm.handleRefresh }
          }),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              ref: "popoverSearch",
              attrs: {
                placement: "bottom-end",
                width: "260",
                trigger: "manual",
                "visible-arrow": "false"
              },
              model: {
                value: _vm.popoverVisible,
                callback: function($$v) {
                  _vm.popoverVisible = $$v
                },
                expression: "popoverVisible"
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "search-form",
                  attrs: { size: "small", model: _vm.query }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "flex-start"
                      },
                      attrs: { label: "用户" }
                    },
                    [
                      _c("div", { staticClass: "popover-search-user" }, [
                        _c(
                          "div",
                          { staticClass: "popover-search-user-input" },
                          [
                            _c("search-user-by-input", {
                              ref: "searchUserByInput",
                              on: { receiveuserid: _vm.receiveUserId }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "工单号" }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入工单号", clearable: "" },
                        model: {
                          value: _vm.searchOrderId,
                          callback: function($$v) {
                            _vm.searchOrderId = $$v
                          },
                          expression: "searchOrderId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "关键字" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入工单内容关键字",
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchVal,
                          callback: function($$v) {
                            _vm.searchVal = $$v
                          },
                          expression: "searchVal"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "工单分类" }
                    },
                    [
                      _c("el-cascader", {
                        staticClass: "cascade",
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.searchClassList,
                          clearable: "",
                          props: {
                            value: "id",
                            label: "classificationName",
                            multiple: false
                          }
                        },
                        on: { change: _vm.categoryChange },
                        model: {
                          value: _vm.classificationId,
                          callback: function($$v) {
                            _vm.classificationId = $$v
                          },
                          expression: "classificationId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "优先级别" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "search-common",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "全部", clearable: "" },
                          model: {
                            value: _vm.searchPriority,
                            callback: function($$v) {
                              _vm.searchPriority = $$v
                            },
                            expression: "searchPriority"
                          }
                        },
                        _vm._l(_vm.priorityOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "default-time": ["00:00:00", "23:59:59"],
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.timer,
                          callback: function($$v) {
                            _vm.timer = $$v
                          },
                          expression: "timer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right", margin: "0" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.popoverVisible = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.search } },
                    [_vm._v("搜索")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-link", {
                staticClass: "common-icon",
                attrs: {
                  slot: "reference",
                  icon: "el-icon-search",
                  underline: false
                },
                on: {
                  click: function($event) {
                    _vm.popoverVisible = true
                  }
                },
                slot: "reference"
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }