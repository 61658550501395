"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.to-string");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'QuestionCard',
  filters: {
    questionDegree: function questionDegree(value) {
      if (!value) return '暂无';
      var data = '';

      if (value.toString() === '0') {
        data = '暂无';
      }

      if (value.toString() === '1') {
        data = '很简单';
      }

      if (value.toString() === '2') {
        data = '简单';
      }

      if (value.toString() === '3') {
        data = '一般';
      }

      if (value.toString() === '4') {
        data = '困难';
      }

      if (value.toString() === '5') {
        data = '十分困难';
      }

      return data;
    }
  },
  props: {
    list: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      activeName: '试题1',
      item: {}
    };
  },
  created: function created() {
    var item = JSON.parse(JSON.stringify(this.list));

    if (item.optionText === '') {
      return;
    }

    item.optionText = JSON.parse(item.optionText); // 处理多选 答案按照 a-z 排序

    if (item.examQuestionTypeCode === 1002 && item.rightAnswerContent) {
      var right = item.rightAnswerContent.split(',');
      right = right.sort();
      item.rightAnswerContent = right.join(',');
    }

    if (item.examQuestionTypeCode === 1004) {
      // 定义一个数组保存连线题的选项
      item.optionList = [];
      item.rightAnswerContent = JSON.parse(item.rightAnswerContent); // 处理连线题选项

      for (var keys in item.optionText[1]) {
        item.optionList.push(item.optionText[1][keys]);
      }
    }

    if (item.examQuestionTypeCode === 1005) {
      item.answerList = [];
      item.rightAnswerContent = JSON.parse(item.rightAnswerContent);

      for (var _keys in item.rightAnswerContent) {
        var answer = item.rightAnswerContent[_keys] ? item.rightAnswerContent[_keys].join('/') : item.rightAnswerContent[_keys];
        item.answerList.push(answer);
      }
    } // 排序题


    if (item.examQuestionTypeCode === 1006) {
      var _right = JSON.parse(item.rightAnswerContent);

      _right = _right.length > 0 ? _right.join(',') : '';
      item.rightAnswerContent = _right;
    }

    if (item.examQuestionTypeCode === 1007) {
      item.rightAnswerContent = JSON.parse(item.rightAnswerContent);
      item.optionList = [];

      for (var _keys2 in item.optionText[0]) {
        item.optionList.push(item.optionText[0][_keys2]);
      }
    }

    if (item.examQuestionTypeCode === 1009) {
      var temArr = [];
      item.subQuestionList.forEach(function (n) {
        temArr.push(n.examQuestion);
      });
      item.subQuestionList = temArr;
      item.subQuestionList.forEach(function (i) {
        i.optionText = JSON.parse(i.optionText); // 处理多选 答案按照 a-z 排序

        if (i.examQuestionTypeCode === 1002 && i.rightAnswerContent) {
          var _right2 = i.rightAnswerContent.split(',');

          _right2 = _right2.sort();
          i.rightAnswerContent = _right2.join(',');
        }

        if (i.examQuestionTypeCode === 1004) {
          // 定义一个数组保存连线题的选项
          i.optionList = [];
          i.rightAnswerContent = JSON.parse(i.rightAnswerContent); // 处理连线题选项

          for (var _keys3 in i.optionText[1]) {
            i.optionList.push(i.optionText[1][_keys3]);
          }
        }

        if (i.examQuestionTypeCode === 1005) {
          i.answerList = [];
          i.rightAnswerContent = JSON.parse(i.rightAnswerContent);

          for (var _keys4 in i.rightAnswerContent) {
            i.answerList.push(i.rightAnswerContent[_keys4][0]);
          }
        } // 排序题


        if (i.examQuestionTypeCode === 1006) {
          var _right3 = JSON.parse(i.rightAnswerContent);

          _right3 = _right3.length > 0 ? _right3.join(',') : '--';
          i.rightAnswerContent = _right3;
        }

        if (i.examQuestionTypeCode === 1007) {
          i.rightAnswerContent = JSON.parse(i.rightAnswerContent);
          i.optionList = [];

          for (var _keys5 in i.optionText[0]) {
            i.optionList.push(i.optionText[0][_keys5]);
          }
        }
      });
    }

    this.item = _objectSpread({}, item);
  },
  methods: {
    handleCheck: function handleCheck(val, code) {
      if (code === 1009) {
        return;
      }

      this.$emit('handleCheck', val, !this.checked);
    },
    copy: function copy(data) {
      this.$emit('copy', data);
    },
    moveClass: function moveClass(data) {
      this.$emit('moveClass', data);
    },
    deleteQuestion: function deleteQuestion(data) {
      this.$emit('deleteQuestion', data);
    },
    emitType: function emitType(data, type) {
      this.$emit('emitType', data, type);
    }
  }
};
exports.default = _default2;