var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量修改价格",
        visible: _vm.dialogVisibleProxy,
        width: "30%",
        "modal-append-to-body": true,
        "append-to-body": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisibleProxy = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "modify-prices-in-batches" },
        _vm._l(_vm.valueName, function(i) {
          return _c("div", { key: i, staticClass: "line" }, [
            _c(
              "div",
              { staticClass: "my-switch" },
              [
                !_vm.onlyOnePrice
                  ? _c("el-switch", {
                      model: {
                        value: _vm.valueList[i].active,
                        callback: function($$v) {
                          _vm.$set(_vm.valueList[i], "active", $$v)
                        },
                        expression: "valueList[i].active"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "label" }, [_vm._v(_vm._s(i))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-line" },
              [
                _c("el-input-number", {
                  attrs: {
                    "controls-position": "right",
                    min: 0,
                    max: 10,
                    disabled: !_vm.valueList[i].active
                  },
                  model: {
                    value: _vm.valueList[i].value,
                    callback: function($$v) {
                      _vm.$set(_vm.valueList[i], "value", $$v)
                    },
                    expression: "valueList[i].value"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "modify" }, [_vm._v("折")])
              ],
              1
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisibleProxy = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }