var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "add-extended-dialog",
          attrs: {
            width: "800px",
            visible: _vm.visible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.title + "拓展栏目",
            "append-to-body": "",
            "before-close": _vm.cancel
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "form-data",
              attrs: {
                size: "small",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "90px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "栏目名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入栏目名称",
                      maxlength: "6",
                      clearable: "",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图标", prop: "cover" } },
                [
                  _c("cover-upload", {
                    attrs: {
                      path: "extendedLearning/",
                      type: "",
                      coverarr: ["84", "84"],
                      covertext: "建议尺寸1:1，JPG、PNG格式， 图片小于3M。"
                    },
                    on: { deletecover: _vm.deleteCoverFun },
                    model: {
                      value: _vm.form.cover,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cover", $$v)
                      },
                      expression: "form.cover"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "栏目简介" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入栏目简介", clearable: "" },
                    model: {
                      value: _vm.form.text,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "text", $$v)
                      },
                      expression: "form.text"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仅VIP可用" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.isVip,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "isVip", $$v)
                      },
                      expression: "form.isVip"
                    }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-info",
                    staticStyle: { color: "#409EFF", margin: "0 5px 0 15px" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "rgba(144, 147, 153, 1)",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v("开启后仅VIP可参与此栏目学习与使用 ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择内容" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addSelect }
                    },
                    [_vm._v("选择连接")]
                  ),
                  _vm._v(" "),
                  _vm.isWord && _vm.form.link.length > 0
                    ? _c(
                        "el-table",
                        {
                          staticClass: "link-table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            "header-cell-style": {
                              background: "#F5F7FA",
                              "text-align": "center"
                            },
                            data: _vm.form.link[0].key
                              ? _vm.form.link[0].key.list
                              : [],
                            border: "",
                            "row-key": "openId"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "内容" }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "110",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass:
                                            "common-link edit-margin",
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.replaceRow(
                                                scope.row,
                                                0
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("替换")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "common-link",
                                          attrs: {
                                            type: "danger",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.delRow(
                                                scope.$index,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("移除")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              299729469
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isWord && _vm.form.link.length > 0
                    ? _c(
                        "el-table",
                        {
                          staticClass: "link-table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            "header-cell-style": {
                              background: "#F5F7FA",
                              "text-align": "center"
                            },
                            data: _vm.form.link,
                            border: "",
                            "row-key": "id"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "内容" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.display.title) +
                                          "\n            "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1372751241
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "编号" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "common-flex" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "resource-encode" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.key.resourceEncode
                                                    ? scope.row.key
                                                        .resourceEncode
                                                    : "--"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-copy encode-copy-icon",
                                            on: {
                                              click: function($event) {
                                                return _vm.copyResourceEncode(
                                                  scope.row.key.resourceEncode,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4237930172
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "110",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "common-link",
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.previewResource(
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("预览")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          staticClass:
                                            "common-link edit-margin",
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.replaceRow(
                                                scope.row,
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("替换")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "common-link",
                                          attrs: {
                                            type: "danger",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.delRow(scope.$index)
                                            }
                                          }
                                        },
                                        [_vm._v("移除")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2579438798
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-link", {
        attrs: {
          value: _vm.linkData,
          "is-not-model": true,
          "is-replace": _vm.isReplace,
          "replace-index": _vm.replaceIndex,
          "multiple-check": true,
          visible: _vm.openSelect,
          "show-word": true,
          "props-active-name": _vm.propsActiveName,
          tool: _vm.tool,
          "is-extend": true
        },
        on: {
          "update:visible": function($event) {
            _vm.openSelect = $event
          },
          submitLink: _vm.submitLink
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isPreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isPreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }