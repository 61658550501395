var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "attribute-dialog",
      attrs: {
        title: "批量设置属性（试卷）",
        width: "500px",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.clearData
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c("el-form-item", { attrs: { prop: "isChangeSync" } }, [
            _c("div", { staticClass: "common-flex" }, [
              _c(
                "div",
                { staticClass: "common-label" },
                [
                  _c("el-checkbox", {
                    attrs: { name: "type" },
                    model: {
                      value: _vm.isChangeSync,
                      callback: function($$v) {
                        _vm.isChangeSync = $$v
                      },
                      expression: "isChangeSync"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "check-label" }, [_vm._v("同步")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sync-info" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        clearable: "",
                        disabled: !_vm.isChangeSync,
                        placeholder: "年级"
                      },
                      model: {
                        value: _vm.form.gradeCodes,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "gradeCodes", $$v)
                        },
                        expression: "form.gradeCodes"
                      }
                    },
                    _vm._l(_vm.syncObj.grade, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        clearable: "",
                        disabled: !_vm.isChangeSync,
                        placeholder: "阶段"
                      },
                      model: {
                        value: _vm.form.volumeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "volumeCode", $$v)
                        },
                        expression: "form.volumeCode"
                      }
                    },
                    _vm._l(_vm.syncObj.volume, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("select-param-sync-info", {
                    attrs: {
                      disabled: !_vm.isChangeSync,
                      "query-option": "FindSubjectList",
                      placeholder: "学科"
                    },
                    model: {
                      value: _vm.form.subjectCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "subjectCode", $$v)
                      },
                      expression: "form.subjectCode"
                    }
                  }),
                  _vm._v(" "),
                  _c("select-param-sync-info", {
                    attrs: {
                      disabled: !_vm.isChangeSync,
                      "query-option": "FindBBList",
                      placeholder: "版本"
                    },
                    model: {
                      value: _vm.form.editionCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "editionCode", $$v)
                      },
                      expression: "form.editionCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.errorText))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "category", attrs: { label: "" } },
            [
              _c(
                "div",
                { staticClass: "common-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "common-label" },
                    [
                      _c("el-checkbox", {
                        attrs: { name: "type" },
                        model: {
                          value: _vm.isChangeClass,
                          callback: function($$v) {
                            _vm.isChangeClass = $$v
                          },
                          expression: "isChangeClass"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "check-label" }, [
                        _vm._v("分类")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: !_vm.isChangeClass,
                      placeholder: "请选择分类",
                      options: _vm.questionClass,
                      props: _vm.classProps,
                      clearable: ""
                    },
                    model: {
                      value: _vm.form.categoryOpenId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "categoryOpenId", $$v)
                      },
                      expression: "form.categoryOpenId"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "common-flex" },
              [
                _c(
                  "div",
                  { staticClass: "common-label" },
                  [
                    _c("el-checkbox", {
                      attrs: { name: "type" },
                      model: {
                        value: _vm.isChangeArea,
                        callback: function($$v) {
                          _vm.isChangeArea = $$v
                        },
                        expression: "isChangeArea"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "check-label" }, [_vm._v("地区")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: !_vm.isChangeArea,
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "请输入或选择地区"
                    },
                    model: {
                      value: _vm.form.bookAreaId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "bookAreaId", $$v)
                      },
                      expression: "form.bookAreaId"
                    }
                  },
                  _vm._l(_vm.syncObj.area, function(item) {
                    return _c("el-option", {
                      key: item.code,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "common-flex" },
              [
                _c(
                  "div",
                  { staticClass: "common-label" },
                  [
                    _c("el-checkbox", {
                      attrs: { name: "type" },
                      model: {
                        value: _vm.isChangePeriod,
                        callback: function($$v) {
                          _vm.isChangePeriod = $$v
                        },
                        expression: "isChangePeriod"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "check-label" }, [_vm._v("版次")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: !_vm.isChangePeriod,
                    "query-option": "FindAll",
                    placeholder: "版次"
                  },
                  model: {
                    value: _vm.form.periodCode,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "periodCode", $$v)
                    },
                    expression: "form.periodCode"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "common-flex" },
              [
                _c(
                  "div",
                  { staticClass: "common-label" },
                  [
                    _c("el-checkbox", {
                      attrs: { name: "type" },
                      model: {
                        value: _vm.isChangeDifficult,
                        callback: function($$v) {
                          _vm.isChangeDifficult = $$v
                        },
                        expression: "isChangeDifficult"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "check-label" }, [_vm._v("难易")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      disabled: !_vm.isChangeDifficult,
                      placeholder: "请选择难易"
                    },
                    model: {
                      value: _vm.form.difficultDegree,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "difficultDegree", $$v)
                      },
                      expression: "form.difficultDegree"
                    }
                  },
                  _vm._l(_vm.difficultDegreeList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "common-flex" },
              [
                _c(
                  "div",
                  { staticClass: "common-label" },
                  [
                    _c("el-checkbox", {
                      attrs: { name: "type" },
                      model: {
                        value: _vm.isChangeTags,
                        callback: function($$v) {
                          _vm.isChangeTags = $$v
                        },
                        expression: "isChangeTags"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "check-label" }, [_vm._v("标签")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: !_vm.isChangeTags,
                      multiple: "",
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "请输入或选择试卷标签"
                    },
                    on: { change: _vm.changeTags },
                    model: {
                      value: _vm.tagsValue,
                      callback: function($$v) {
                        _vm.tagsValue = $$v
                      },
                      expression: "tagsValue"
                    }
                  },
                  _vm._l(_vm.tagOptions, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  model: {
                    value: _vm.form.tags,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "tags", $$v)
                    },
                    expression: "form.tags"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }