var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tz-price-input" },
    [
      _c("el-input-number", {
        staticClass: "m-input",
        attrs: {
          min: _vm.min,
          precision: _vm.precision,
          disabled: !_vm.valueProxy.enable,
          "controls-position": "right"
        },
        model: {
          value: _vm.valueProxy.price,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "price", $$v)
          },
          expression: "valueProxy.price"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "m-label" }, [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }