"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _main = require("../../organizationLibs/vueSsoSdk/main");

var _security = require("../../api/security");

var _user = require("../../api/user");

/* eslint-disable no-unused-vars */
var user = {
  state: {
    token: (0, _main.getAccessToken)(),
    info: undefined,
    roles: undefined,
    albumDisabled: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_INFO: function SET_INFO(state, info) {
      state.info = info;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_ALBUMDISABLED: function SET_ALBUMDISABLED(state, albumDisabled) {
      state.albumDisabled = albumDisabled;
    }
  },
  actions: {
    set_albumdisabled: function set_albumdisabled(_ref, data) {
      var commit = _ref.commit;
      commit('SET_ALBUMDISABLED', data);
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
          state = _ref2.state;
      return new Promise(function (resolve, reject) {
        (0, _security.getInfo)().then(function (response) {
          var data = response.data;
          commit('SET_INFO', data);
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户角色信息
    GetRoles: function GetRoles(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;
      return new Promise(function (resolve, reject) {
        (0, _user.getRoleNamesByUserOpenId)(state.info.openId, process.env.VUE_APP_SYSTEM_OPEN_ID).then(function (response) {
          var data = response.data;
          commit('SET_ROLES', data);
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit,
          state = _ref4.state;
      commit('SET_TOKEN', '');
      (0, _main.clearLoginStatus)();
    }
  }
};
var _default = user;
exports.default = _default;