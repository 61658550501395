var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions" },
    [
      _c("el-table-column", {
        attrs: { width: "160", fixed: "right" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c("header-with-batch-set", {
                  attrs: { label: "进货价", "def-val": 1 },
                  on: { update: _vm.updatePrice }
                })
              ]
            }
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("discount-setting-column", {
                  attrs: {
                    "orginal-price": scope.row.orginalPrice,
                    "no-price": _vm.showPrice(scope.row)
                  },
                  model: {
                    value: _vm.valueProxy[scope.row.productOpenId],
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, scope.row.productOpenId, $$v)
                    },
                    expression: "valueProxy[scope.row.productOpenId]"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      false
        ? _c("el-table-column", {
            attrs: { width: "200", fixed: "right" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function(scope) {
                    return [
                      _c("header-with-batch-set", {
                        attrs: { label: "其他中盘供货价", "def-val": 1 },
                        on: { update: _vm.updatePriceOtherSupplyPrice }
                      })
                    ]
                  }
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("discount-setting-column", {
                        attrs: {
                          "orginal-price": scope.row.orginalPrice,
                          "no-price": _vm.showPrice(scope.row)
                        },
                        model: {
                          value:
                            _vm.otherSupplyPriceProxy[scope.row.productOpenId],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.otherSupplyPriceProxy,
                              scope.row.productOpenId,
                              $$v
                            )
                          },
                          expression:
                            "otherSupplyPriceProxy[scope.row.productOpenId]"
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              3617168757
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }