var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "management-container" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { width: "100%" },
          on: { "tab-click": _vm.selectTabs },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "chinese" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v("语文 "),
                _c(
                  "span",
                  {
                    class:
                      _vm.activeName === "chinese"
                        ? "subjectNum"
                        : "noSubjectNum"
                  },
                  [_vm._v(_vm._s(_vm.chineseNum))]
                )
              ]),
              _vm._v(" "),
              _vm.table_name === "chinese"
                ? _c("mainTable", {
                    ref: "mainTable",
                    attrs: {
                      subJectCode: _vm.subJectCode,
                      resourceType: _vm.type
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { name: "english" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v("英语 "),
                _c(
                  "span",
                  {
                    class:
                      _vm.activeName === "english"
                        ? "subjectNum"
                        : "noSubjectNum"
                  },
                  [_vm._v(_vm._s(_vm.englishNum))]
                )
              ]),
              _vm._v(" "),
              _vm.table_name === "english"
                ? _c("mainTable", {
                    ref: "mainTable",
                    attrs: {
                      subJectCode: _vm.subJectCodeEnglish,
                      resourceType: _vm.type
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }