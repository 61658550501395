var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wraper" }, [
    _c("h3", { staticClass: "ratioTitle" }, [_vm._v("年级")]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.statisticsLoading,
            expression: "statisticsLoading"
          }
        ],
        staticStyle: { display: "flex", "justify-content": "space-between" }
      },
      [
        _c("div", { attrs: { id: "gradeContainer" } }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  "default-sort": { prop: "price", order: "descending" },
                  "summary-method": _vm.getSummaries,
                  "show-summary": ""
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "年级", width: "160" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "tableName" }, [
                            _c("span", {
                              staticClass: "titleIcon",
                              style: { background: scope.row.color }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.showVal(scope.row.title)) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "订单金额",
                    "header-align": "right",
                    align: "right",
                    width: "120",
                    sortable: "",
                    prop: "price"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            ¥" +
                              _vm._s(_vm.numFormat(scope.row.price / 100)) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }