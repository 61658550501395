var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.today.refreshLoading,
                    expression: "today.refreshLoading"
                  }
                ],
                staticClass: "grid-content"
              },
              [
                _c("div", { staticClass: "top-title" }, [
                  _vm._v("\n          今日累计提问 "),
                  _c("i", {
                    staticClass: "iconfont icon-refresh",
                    on: { click: _vm.refreshToday }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "top-content" }, [
                  _vm._v(_vm._s(_vm.today.questionNum))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "description-title" }, [
                    _vm._v("提问用户数")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-content" }, [
                    _vm._v(_vm._s(_vm.today.userNum))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.today.date))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.yesterday.refreshLoading,
                    expression: "yesterday.refreshLoading"
                  }
                ],
                staticClass: "grid-content"
              },
              [
                _c("div", { staticClass: "top-title" }, [
                  _vm._v("\n          昨天累计提问 "),
                  _c("i", {
                    staticClass: "iconfont icon-refresh",
                    on: {
                      click: function($event) {
                        return _vm.refreshYesterday()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "top-content" }, [
                  _vm._v(_vm._s(_vm.yesterday.questionNum))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "description-title" }, [
                    _vm._v("提问用户数")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-content" }, [
                    _vm._v(_vm._s(_vm.yesterday.userNum))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.yesterday.date))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.sevenDays.refreshLoading,
                    expression: "sevenDays.refreshLoading"
                  }
                ],
                staticClass: "grid-content"
              },
              [
                _c("div", { staticClass: "top-title" }, [
                  _vm._v("\n          近7日累计提问 "),
                  _c("i", {
                    staticClass: "iconfont icon-refresh",
                    on: {
                      click: function($event) {
                        return _vm.refreshSevenDays()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "top-content" }, [
                  _vm._v(_vm._s(_vm.sevenDays.questionNum))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "description-title" }, [
                    _vm._v("提问用户数")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-content" }, [
                    _vm._v(_vm._s(_vm.sevenDays.userNum))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.sevenDays.date))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.thisMonth.refreshLoading,
                    expression: "thisMonth.refreshLoading"
                  }
                ],
                staticClass: "grid-content"
              },
              [
                _c("div", { staticClass: "top-title" }, [
                  _vm._v("\n          近30日累计提问 "),
                  _c("i", {
                    staticClass: "iconfont icon-refresh",
                    on: {
                      click: function($event) {
                        return _vm.refreshMonth()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "top-content" }, [
                  _vm._v(_vm._s(_vm.thisMonth.questionNum))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "description-title" }, [
                    _vm._v("提问用户数")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-content" }, [
                    _vm._v(_vm._s(_vm.thisMonth.userNum))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.thisMonth.date))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("el-col", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.thisYear.refreshLoading,
                    expression: "thisYear.refreshLoading"
                  }
                ],
                staticClass: "grid-content"
              },
              [
                _c("div", { staticClass: "top-title" }, [
                  _vm._v("\n          今年累计提问 "),
                  _c("i", {
                    staticClass: "iconfont icon-refresh",
                    on: {
                      click: function($event) {
                        return _vm.refreshYear()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "top-content" }, [
                  _vm._v(_vm._s(_vm.thisYear.questionNum))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "description-title" }, [
                    _vm._v("提问用户数")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-content" }, [
                    _vm._v(_vm._s(_vm.thisYear.userNum))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.thisYear.date))
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }