var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-wrap" }, [
    _c("div", { staticClass: "item-header" }, [
      _c("span", [_vm._v("工单号：" + _vm._s(_vm.item.workOrderOpenId))]),
      _vm._v(" "),
      _c("span", [_vm._v("创建时间：" + _vm._s(_vm.item.createdAt))]),
      _vm._v(" "),
      _c("span", [
        _vm._v("创建类型：\n            "),
        _vm.appOpenId === _vm.item.appProductId
          ? _c("span", [_vm._v("平台")])
          : _c("span", [_vm._v("用户")])
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v("优先级："),
        _c("span", { staticStyle: { color: "#46a6ff" } }, [
          _vm._v(_vm._s(_vm.item.priority))
        ])
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v("状态：\n              "),
        _vm.item.status === 1 ? _c("span", [_vm._v("待处理")]) : _vm._e(),
        _vm._v(" "),
        _vm.item.status === 3 ? _c("span", [_vm._v("已处理")]) : _vm._e(),
        _vm._v(" "),
        _vm.item.status === 2 ? _c("span", [_vm._v("无需处理")]) : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item-title" }, [
      _vm._v("\n    " + _vm._s(_vm.item.workorderClassificationName) + "\n  ")
    ]),
    _vm._v(" "),
    _vm.item.workorderImgUrls
      ? _c(
          "div",
          { staticClass: "item-describe" },
          _vm._l(_vm.item.workorderImgUrls.split(","), function(
            itemImg,
            index
          ) {
            return _c("el-image", {
              key: index,
              staticStyle: {
                width: "72px",
                height: "72px",
                "margin-right": "10px"
              },
              attrs: { fit: "cover", src: _vm.getImgUrl(itemImg) }
            })
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "item-describe" }, [
      _vm._v("\n    " + _vm._s(_vm.item.workorderDescribe) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item-describe" }, [
      _vm._v("\n    联系电话：" + _vm._s(_vm.item.phone) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item-detail" }, [
      _c(
        "div",
        [
          _vm._v("\n      处理人：\n      "),
          _vm.item.cover
            ? _c("el-image", {
                staticClass: "cover",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(_vm.item.cover, _vm.smallthumbnailStyle),
                  alt: ""
                }
              })
            : _vm._e(),
          _vm._v("\n      " + _vm._s(_vm.item.realName) + "\n    ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.handleDetails(_vm.item.id)
                }
              }
            },
            [_vm._v("详情")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }