var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card-header card-header-class fontwe display-flex" },
        [
          _c(
            "span",
            { staticStyle: { cursor: "pointer" }, on: { click: _vm.shows } },
            [
              _c("i", { staticClass: "el-icon-circle-plus-outline newAdd" }),
              _vm._v(" "),
              _c("span", [_vm._v("新建")])
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "operation" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", content: "刷新", placement: "top" }
                },
                [
                  _c("svg-icon", {
                    attrs: { "icon-class": "upTree" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.upDataGenre()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("i", {
                staticClass: "iconfont noWeight",
                class: _vm.expandAll
                  ? "icon-category-collapse"
                  : "icon-category-expand",
                on: { click: _vm.isExpand }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "custom-tree-container tree-container" }, [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-tree", {
              ref: "tree",
              attrs: {
                "default-expand-all": _vm.isShow,
                "default-expanded-keys": _vm.expandArr,
                data: _vm.genreTreeList,
                "default-checked-keys": _vm.treeCode,
                "show-checkbox": "",
                "node-key": "typeCode",
                props: _vm.defaultProps,
                "expand-on-click-node": false
              },
              on: {
                "node-expand": _vm.nodeExpand,
                "node-collapse": _vm.nodeCollapse,
                check: _vm.getHalfCheckEvent
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c(
                      "div",
                      {
                        staticClass: "custom-tree-node",
                        staticStyle: { width: "100%" },
                        on: {
                          mouseenter: function($event) {
                            $event.stopPropagation()
                            return _vm.mouseoverFun(data)
                          },
                          mouseleave: function($event) {
                            $event.stopPropagation()
                            return _vm.mouseoutFun()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "treeName" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(node.label) +
                              "\n            "
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "el-dropdown",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.id === _vm.mouseid,
                                      expression: "data.id === mouseid"
                                    }
                                  ],
                                  staticStyle: {
                                    position: "absolute",
                                    right: "5px"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [_c("i", { staticClass: "el-icon-more" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      data.level < 2
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.append(data)
                                                }
                                              }
                                            },
                                            [_vm._v("添加子体裁")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.reName(data)
                                            }
                                          }
                                        },
                                        [_vm._v("重命名")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.remove(node, data)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitltText,
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm.dialogTitltText != "移动到"
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.addClass, "label-width": "80px" }
                },
                [
                  _vm.dialogTitltText === "添加子体裁"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "父类名称" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.addClass.fatherName,
                              callback: function($$v) {
                                _vm.$set(_vm.addClass, "fatherName", $$v)
                              },
                              expression: "addClass.fatherName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "体裁名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addClass.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addClass,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addClass.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submit(_vm.data)
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }