"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {},
  created: function created() {
    var back_url = this.$route.query.redirect;
    window.location.href = "https://sso.dev.taozhi.cn/?back_url=".concat(back_url, "&register_from=").concat(process.env.VUE_APP_SYSTEM_OPEN_ID);
  },
  methods: {}
};
exports.default = _default;