var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-lz-content card-editor-content editor-lz-box upload-video"
    },
    [
      _c("div", {
        staticClass: "toolbar-container",
        attrs: { id: _vm.toolbarContainer }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "editor", attrs: { id: _vm.editorDomId } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }