"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'multipleGoodsDetail',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    goodsList: {
      type: Array,
      default: [],
      required: true
    }
  },
  data: function data() {
    return {
      smallthumbnailStyle: 'style/width-limit-128',
      showVal: _common.showVal
    };
  },
  methods: {
    getGoodsCover: function getGoodsCover(item) {
      if (item.commodityType === 2 && item.resourceType === 5) {
        return 'https://open.oss.taozhi.online/develop/mp/orderCover/jinxinbiCover.png?x-oss-process=style%2Fthumbnail';
      }

      if (item.commodityType === 2 && item.resourceType === 6) {
        return 'https://open.oss.taozhi.online/develop/mp/orderCover/vipCover.png?x-oss-process=style%2Fthumbnail';
      }

      return this.getImgUrl(item.cover, this.thumbnailStyle);
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },

    /* 获取tupian */
    getImgUrl: function getImgUrl(cover, style) {
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    }
  }
};
exports.default = _default;