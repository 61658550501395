import { render, staticRenderFns } from "./testList.vue?vue&type=template&id=71423e6e&scoped=true&"
import script from "./testList.vue?vue&type=script&lang=js&"
export * from "./testList.vue?vue&type=script&lang=js&"
import style0 from "./testList.vue?vue&type=style&index=0&id=71423e6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71423e6e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71423e6e')) {
      api.createRecord('71423e6e', component.options)
    } else {
      api.reload('71423e6e', component.options)
    }
    module.hot.accept("./testList.vue?vue&type=template&id=71423e6e&scoped=true&", function () {
      api.rerender('71423e6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Question/testPaper/testList.vue"
export default component.exports