var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reply" },
    [
      _c(
        "div",
        { staticClass: "reply-input" },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "回复后，用户们将直接看到哦~",
              clearable: ""
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.multipleCheck
        ? _c("div", [
            _vm.linkData.length > 0 || _vm.linkReplyData.length > 0
              ? _c("div", { staticClass: "link" }, [
                  _vm.linkData.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.linkData, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "link-item link-item-padding"
                            },
                            [
                              _c("div", { staticClass: "link-flex" }, [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-gongdanlianjie show-icon-link"
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "link-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.display.title
                                        ? item.display.title
                                        : "--"
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete link-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeleteLink(index)
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.linkReplyData.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.linkReplyData, function(
                          replyItem,
                          replyIndex
                        ) {
                          return _c(
                            "div",
                            {
                              key: replyIndex,
                              staticClass: "link-item link-item-padding"
                            },
                            [
                              _c("div", { staticClass: "link-flex" }, [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-gongdanlianjie show-icon-link"
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "link-title" }, [
                                  _vm._v(
                                    _vm._s(replyItem.display.title) +
                                      ": " +
                                      _vm._s(replyItem.key.url)
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete link-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeleteReplyLink(replyIndex)
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _c("div", [
            _vm.linkData && JSON.stringify(_vm.linkData) !== "{}"
              ? _c("div", { staticClass: "link link-item" }, [
                  _c("div", { staticClass: "link-flex" }, [
                    _c("i", {
                      staticClass: "iconfont icon-gongdanlianjie show-icon-link"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "link-title" }, [
                      _vm._v(
                        _vm._s(_vm.linkData ? _vm.linkData.display.title : "--")
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-delete link-icon",
                    on: { click: _vm.handleDeleteLink }
                  })
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _vm.imageList.length > 0
        ? _c(
            "div",
            { staticClass: "upload-img-list" },
            _vm._l(_vm.imageList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "upload-img-item" },
                [
                  _c("el-image", {
                    staticClass: "item-img",
                    attrs: {
                      fit: "cover",
                      src: item.path,
                      "preview-src-list": [item.path]
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "deleted-icon" }, [
                    _c("i", {
                      staticClass: "el-icon-close icon",
                      on: {
                        click: function($event) {
                          return _vm.deletedImage(item)
                        }
                      }
                    })
                  ])
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "select-type" }, [
        _c("div", { staticClass: "select-options" }, [
          _vm.showCommonlyUsedReply
            ? _c("i", {
                class: [
                  _vm.currentOption === "commonlyUsedReply"
                    ? "active-icon"
                    : "select-icon",
                  "iconfont icon-gongdanxiaoxi"
                ],
                on: {
                  click: function($event) {
                    return _vm.handleOptions("commonlyUsedReply")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showEmoji
            ? _c("i", {
                class: [
                  _vm.currentOption === "emoji" ? "active-icon" : "select-icon",
                  "iconfont icon-gongdanbiaoqing"
                ],
                on: {
                  click: function($event) {
                    return _vm.handleOptions("emoji")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showUploadImage
            ? _c(
                "div",
                {
                  staticClass: "upload-img",
                  on: {
                    click: function($event) {
                      return _vm.handleOptions("uploadImage")
                    },
                    mouseenter: _vm.handelUploadIcon,
                    mouseleave: _vm.handelMouseout
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "file-upload" },
                    [
                      _c("upload-img", {
                        ref: "uploadImg",
                        attrs: {
                          type: "工单",
                          "image-list": _vm.imageList,
                          path: _vm.path
                        },
                        on: { getImage: _vm.getImage }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", {
                      class: [
                        _vm.activeIcon ? "active-icon" : "select-icon",
                        "iconfont icon-gongdantupian"
                      ]
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showLink
            ? _c("i", {
                class: [
                  _vm.currentOption === "link" ? "active-icon" : "select-icon",
                  "iconfont icon-gongdanlianjie",
                  "link-icon"
                ],
                on: {
                  click: function($event) {
                    return _vm.handleOptions("link")
                  }
                }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "send-btn",
                attrs: {
                  type: "primary",
                  size: _vm.sendBtn.size,
                  loading: _vm.submitLoading
                },
                on: { click: _vm.submit }
              },
              [_vm._v(_vm._s(_vm.sendBtn.sendTitle))]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.currentOption === "commonlyUsedReply" && _vm.showCommonlyUsedReply
        ? _c("div", { staticClass: "commonly-used-reply" }, [
            _c("div", { staticClass: "nav" }, [
              _c("div", [
                _c(
                  "span",
                  {
                    class: [
                      _vm.replyType === 0 ? "active-reply-title" : "",
                      "nav-title"
                    ],
                    on: {
                      click: function($event) {
                        _vm.replyType = 0
                      }
                    }
                  },
                  [_vm._v("回复语")]
                ),
                _vm._v(" "),
                _vm.businessType !== "comment"
                  ? _c(
                      "span",
                      {
                        class: [
                          _vm.replyType === 1 ? "active-reply-title" : "",
                          "nav-title"
                        ],
                        on: { click: _vm.handleReplyLink }
                      },
                      [_vm._v("链接")]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-s-tools nav-icon",
                on: {
                  click: function($event) {
                    _vm.replyDialog = true
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.loadScroll,
                    expression: "loadScroll"
                  }
                ],
                staticClass: "scroll",
                attrs: {
                  "infinite-scroll-immediate": "false",
                  "infinite-scroll-disabled": _vm.disabled
                }
              },
              [
                _vm.replyType === 0 && !_vm.showNoData
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.replyList, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "scroll-item",
                              on: {
                                click: function($event) {
                                  return _vm.rowClick(item.reply)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.reply))]
                          )
                        }),
                        _vm._v(" "),
                        _vm.scrollStatus
                          ? _c("div", { staticClass: "load-text" }, [
                              _vm._v("加载中...")
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.replyType === 1 && !_vm.showNoData
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.replyLinkList, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "scroll-item",
                              on: {
                                click: function($event) {
                                  return _vm.rowLinkClick(item.linkSetting)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "link-title" }, [
                                _vm._v(
                                  _vm._s(item.linkName) +
                                    ": " +
                                    _vm._s(item.linkSetting.key.url)
                                )
                              ])
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.scrollStatus
                          ? _c("div", { staticClass: "load-text" }, [
                              _vm._v("加载中...")
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showNoData
                  ? _c("div", { staticClass: "no-data" }, [_vm._v("暂无数据")])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentOption === "emoji"
        ? _c("emoji", { on: { selectEmoji: _vm.selectEmoji } })
        : _vm._e(),
      _vm._v(" "),
      _c("select-link", {
        attrs: {
          visible: _vm.openSelectLink,
          "multiple-check": _vm.multipleCheck
        },
        on: {
          "update:visible": function($event) {
            _vm.openSelectLink = $event
          },
          handleCloseSelect: _vm.handleCloseSelect
        },
        model: {
          value: _vm.linkData,
          callback: function($$v) {
            _vm.linkData = $$v
          },
          expression: "linkData"
        }
      }),
      _vm._v(" "),
      _c("common-reply-management", {
        attrs: {
          visible: _vm.replyDialog,
          "is-link": _vm.replyType,
          classification: "日常回复",
          "business-type": _vm.businessType
        },
        on: {
          "update:visible": function($event) {
            _vm.replyDialog = $event
          },
          handleRefreshReply: _vm.handleRefreshReply
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          "link-type": _vm.linkType,
          visible: _vm.openResourceShare,
          "share-obj": _vm.shareData
        },
        on: {
          "update:visible": function($event) {
            _vm.openResourceShare = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }