"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _OriginalImageWall = _interopRequireDefault(require("./OriginalImageWall"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CG_Image_ImageWall_index',
  components: {
    OriginalImageWall: _OriginalImageWall.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    coverArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    atlasArrText: {
      type: String,
      default: undefined
    },
    ossUploadFunction: {
      type: Function,
      default: undefined
    },
    path: {
      type: String,
      default: undefined
    },
    previewZIndex: {
      type: Number,
      default: 2000
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        try {
          var res = JSON.parse(this.value);

          if (!_main.default.isObject(res) || !_main.default.isArray(res)) {
            return [];
          }

          return res;
        } catch (e) {
          return [];
        }
      },
      set: function set(value) {
        this.$emit('input', JSON.stringify(value));
      }
    }
  }
};
exports.default = _default2;