var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "commodity-wrapper" }, [
    _c(
      "div",
      { staticClass: "commodity-info-bg" },
      [
        _vm.commodityInfo.resourceType === 5
          ? _c("el-image", {
              staticClass: "commodity-resource-cover",
              attrs: {
                fit: "cover",
                src: _vm.getImgUrl(
                  "mp/orderCover/jinxinbiCover.png",
                  _vm.thumbnailStyle
                )
              }
            })
          : _vm.commodityInfo.resourceType === 6
          ? _c("el-image", {
              staticClass: "commodity-resource-cover",
              attrs: {
                fit: "cover",
                src: _vm.getImgUrl(
                  "mp/orderCover/vipCover.png",
                  _vm.thumbnailStyle
                )
              }
            })
          : _c("el-image", {
              staticClass: "commodity-resource-cover",
              attrs: {
                fit: "cover",
                onerror: _vm.errorUserPhoto,
                src: _vm.getImgUrl(
                  _vm.commodityInfo.resourceInfo
                    ? _vm.commodityInfo.resourceInfo.cover
                    : "",
                  _vm.thumbnailStyle
                )
              }
            }),
        _vm._v(" "),
        _c("div", { staticClass: "commodity-info" }, [
          _c("div", { staticClass: "commodity-title-bg" }, [
            _c("div", { staticClass: "commodity-name" }, [
              _vm._v(_vm._s(_vm.commodityInfo.resourceTitle))
            ]),
            _vm._v(" "),
            _vm.commodityInfo.resourceInfo &&
            _vm.commodityInfo.resourceInfo.syncInfoText
              ? _c("div", { staticClass: "commodity-class" }, [
                  _vm._v(_vm._s(_vm.commodityInfo.resourceInfo.syncInfoText))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "commodity-tags" }, [
            _c(
              "div",
              { staticClass: "merchantInfo" },
              [
                _c("el-image", {
                  staticClass: "merchant-cover",
                  attrs: {
                    onerror: _vm.errorUserPhoto,
                    src: _vm.getImgUrl(
                      _vm.commodityInfo.partnerCover
                        ? _vm.commodityInfo.partnerCover
                        : "",
                      "style/thumbnail"
                    ),
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "merchant-name" }, [
                  _vm._v(_vm._s(_vm.commodityInfo.partnerName))
                ]),
                _vm._v(" "),
                _vm.commodityInfo.updatedAt
                  ? _c("span", { staticClass: "timer" }, [
                      _vm._v(
                        "创建时间：" + _vm._s(_vm.commodityInfo.updatedAt) + " "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "commodity-info-base" }, [
      _c("div", { staticClass: "commodity-base-l" }, [
        _c("div", { staticClass: "commodity-txt" }, [
          _vm._v(
            "原价：￥" +
              _vm._s(
                _vm.numFormat((_vm.commodityInfo.price / 100).toFixed(2))
              ) +
              "元"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "commodity-txt" }, [
          _vm._v(
            "商品折扣：" +
              _vm._s(_vm.toPercent(_vm.commodityInfo.discount)) +
              "，减免价：" +
              _vm._s(_vm.numFormat(_vm.deratePrice.toFixed(2))) +
              "元，折扣价：" +
              _vm._s(_vm.numFormat(_vm.discountPrice.toFixed(2))) +
              "元"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "commodity-txt" }, [
          _vm._v(
            "VIP折扣：" +
              _vm._s(_vm.toPercent(_vm.commodityInfo.vipDiscount)) +
              "，减免价：" +
              _vm._s(_vm.numFormat(_vm.vipDeratePrice.toFixed(2))) +
              "元，折扣价：" +
              _vm._s(_vm.numFormat(_vm.vipDiscountPrice.toFixed(2))) +
              "元"
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "commodity-base-r" }, [
        _c("div", { staticClass: "commodity-price" }, [
          _vm._v("现价：￥ "),
          _c("span", { staticClass: "txt-price" }, [
            _vm._v(_vm._s(_vm.numFormat(_vm.discountPrice.toFixed(2))))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "commodity-discounts" }, [
          _vm._v(
            "VIP享折上折：￥" +
              _vm._s(_vm.numFormat(_vm.vipDiscountPrice.toFixed(2))) +
              "元"
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "commodity-info-title" }, [
      _c("div", { staticClass: "commodity-title" }, [_vm._v("课程分销记录")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commodity-operation" },
        [
          _c("el-date-picker", {
            staticClass: "pay-date-bg",
            attrs: {
              type: "daterange",
              "default-time": ["00:00:00", "23:59:59"],
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "yyyy-MM-dd HH:mm:ss"
            },
            model: {
              value: _vm.payedTime,
              callback: function($$v) {
                _vm.payedTime = $$v
              },
              expression: "payedTime"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "search-state-bg",
              model: {
                value: _vm.searchQuery.status,
                callback: function($$v) {
                  _vm.$set(_vm.searchQuery, "status", $$v)
                },
                expression: "searchQuery.status"
              }
            },
            _vm._l(_vm.stateList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.title, value: item.value }
              })
            }),
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "commodity-table-bg" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.promotionRecordLoading,
                expression: "promotionRecordLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.recordList }
          },
          [
            _c("el-table-column", {
              attrs: { width: "140", label: "交易时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.payedAt
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("parseTime")(
                                  scope.row.payedAt,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                            )
                          ])
                        : _c("span", [_vm._v("--")])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "订单号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.orderId) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "购买人" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            scope.row.buyUserName ? scope.row.buyUserName : "--"
                          ) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "分销折扣" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.promotionDiscount / 10) +
                          "折\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "交易金额" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          ￥" +
                          _vm._s(
                            _vm.numFormat(
                              (scope.row.commodityPrice / 100).toFixed(2)
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "一级分销" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l(scope.row.amountRecordList, function(
                      record,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        record.commissionLevel === 1
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "user-info-bg" },
                                [
                                  _c("el-image", {
                                    staticClass: "user-cover-bg",
                                    attrs: {
                                      onerror: _vm.errorUserPhoto,
                                      src: _vm.getImgUrl(
                                        record.userCover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "user-info-name" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          record.userName
                                            ? record.userName
                                            : "--"
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "txt-commission-rate" },
                                      [
                                        _vm._v(
                                          "佣金比例" +
                                            _vm._s(
                                              record.commissionRate
                                                ? record.commissionRate
                                                : "--"
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "一级佣金" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l(scope.row.amountRecordList, function(
                      record,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        record.commissionLevel === 1
                          ? _c("div", [
                              _vm._v(
                                "\n              ￥" +
                                  _vm._s(
                                    _vm.numFormat(
                                      (record.changePrice / 100).toFixed(2)
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "二级分销" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l(scope.row.amountRecordList, function(
                      record,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        record.commissionLevel === 2
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "user-info-bg" },
                                [
                                  _c("el-image", {
                                    staticClass: "user-cover-bg",
                                    attrs: {
                                      onerror: _vm.errorUserPhoto,
                                      src: _vm.getImgUrl(
                                        record.userCover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "user-info-name" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          record.userName
                                            ? record.userName
                                            : "--"
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "txt-commission-rate" },
                                      [
                                        _vm._v(
                                          "佣金比例" +
                                            _vm._s(
                                              record.commissionRate
                                                ? record.commissionRate
                                                : "--"
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "二级佣金" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l(scope.row.amountRecordList, function(
                      record,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        record.commissionLevel === 2
                          ? _c("div", [
                              _vm._v(
                                "\n              ￥" +
                                  _vm._s(
                                    _vm.numFormat(
                                      (record.changePrice / 100).toFixed(2)
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "三级分销" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l(scope.row.amountRecordList, function(
                      record,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        record.commissionLevel === 3
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "user-info-bg" },
                                [
                                  _c("el-image", {
                                    staticClass: "user-cover-bg",
                                    attrs: {
                                      onerror: _vm.errorUserPhoto,
                                      src: _vm.getImgUrl(
                                        record.userCover,
                                        _vm.thumbnailStyle
                                      ),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "user-info-name" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          record.userName
                                            ? record.userName
                                            : "--"
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "txt-commission-rate" },
                                      [
                                        _vm._v(
                                          "佣金比例" +
                                            _vm._s(
                                              record.commissionRate
                                                ? record.commissionRate
                                                : "--"
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { "min-width": "40", label: "三级佣金" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l(scope.row.amountRecordList, function(
                      record,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        record.commissionLevel === 3
                          ? _c("div", [
                              _vm._v(
                                "\n              ￥" +
                                  _vm._s(
                                    _vm.numFormat(
                                      (record.changePrice / 100).toFixed(2)
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "80", fixed: "right", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "span",
                        { class: scope.row.status === 2 ? "refund" : "" },
                        [
                          _vm._v(
                            _vm._s(scope.row.status === 1 ? "已结算" : "已退款")
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-page-box" },
      [
        _c("Pagination", {
          attrs: {
            total: _vm.totalElements,
            page: _vm.searchQuery.page,
            limit: _vm.searchQuery.size,
            "current-page": _vm.searchQuery.page
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.searchQuery, "page", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.searchQuery, "size", $event)
            },
            pagination: _vm.getPromotionRecordList
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "commodity-info-title" }, [
      _c("div", { staticClass: "commodity-title" }, [_vm._v("销售信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }