var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-wrapper" },
    [
      _c("public-top", {
        attrs: {
          value: _vm.listQuery,
          "partner-open-id-echo": _vm.merchantId,
          typeName: _vm.typename
        },
        on: {
          "new-add": _vm.addLive,
          searchbtnevent: _vm.searchbtnFun,
          "merchant-id": _vm.merchantswitch
        }
      }),
      _vm._v(" "),
      _c(
        "multipane",
        {
          staticClass: "audio-box vertical-panes",
          attrs: { layout: "vertical" },
          on: { paneResize: _vm.changeMenuSize }
        },
        [
          _c(
            "div",
            {
              staticClass: "pane-menu",
              style: { minWidth: "130px", width: "240px" }
            },
            [
              _c(
                "div",
                { staticClass: "audio-box-menu" },
                [
                  _c("Label-page", {
                    attrs: {
                      state: _vm.state,
                      state_current: _vm.listQuery.state.toString(),
                      isWidthChange: _vm.isChangeWidth
                    },
                    on: { "state-currents": _vm.stateCurrents }
                  }),
                  _vm._v(" "),
                  _c("videoClass", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.merchantId,
                        expression: "merchantId"
                      }
                    ],
                    ref: "audioClass",
                    attrs: { routname: _vm.typename, type: _vm.resourceType },
                    on: { "check-event": _vm.searchClass }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("multipane-resizer", {
            staticClass: "resize-split-bg iconfont icon-tuozhuaidaxiao"
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pane", style: { flexGrow: 1, minWidth: "186px" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticClass: "resource-list-wrapper"
                },
                [
                  _c("table-list", {
                    ref: "tableList",
                    staticClass: "live-table-box",
                    attrs: {
                      list: _vm.list,
                      "img-height": 82,
                      "copy-btn-disabled": _vm.copyBtnDisabled,
                      reason: _vm.reason,
                      "list-query": _vm.listQuery,
                      "status-val-name": "state",
                      "resource-type-id": _vm.resourceType,
                      handleDel: _vm.liveDel,
                      passObj: _vm.passObj,
                      refuseObj: _vm.refuseObj,
                      objectRestore: _vm.handleRestore,
                      handleCancelHide: _vm.handelLiveCancelHidden,
                      handleHide: _vm.handleLiveHide,
                      handleHaltSale: _vm.handleHaltSale,
                      unPublish: _vm.batchUnpublish,
                      handleSale: _vm.handleSale,
                      setRecommend: _vm.setRecommend,
                      saveResourceEncode: _vm.updateLiveEncode,
                      cancelRecommend: _vm.cancelRecommend
                    },
                    on: {
                      showPage: _vm.addLive,
                      addObject: _vm.addLive,
                      selectObjs: _vm.selectObjs
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(live) {
                          return _c("div", { staticClass: "table-img" }, [
                            live.cover
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.getImgUrl(live.cover),
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("../../../assets/book_cover.png"),
                                    alt: ""
                                  }
                                })
                          ])
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-bottom-box" },
                    [
                      _c("review-btn", {
                        ref: "reviewBtn",
                        attrs: {
                          typename: _vm.typename,
                          merchantId: _vm.merchantId,
                          status: _vm.listQuery.state.toString(),
                          "handle-batch-stop-sale": _vm.batchStopSale,
                          reviewFunction: _vm.liveReviewPass,
                          "copy-function": _vm.batchCopy
                        },
                        on: {
                          batchUnpublish: _vm.batchUnpublish,
                          batchRefuse: function($event) {
                            return _vm.handlebatchRefuse(arguments)
                          },
                          batchSetAttribute: function($event) {
                            return _vm.batchSetAttributes(arguments)
                          },
                          batchRecovery: _vm.handleBatchRecovery,
                          batchSaveDraft: _vm.batchSaveLivetDraft,
                          batchSaveRecycle: _vm.batchSaveLiveRecycle,
                          batchHiddenOrStopSale: _vm.batchHiddenOrStopSale
                        }
                      }),
                      _vm._v(" "),
                      _vm.total > 0
                        ? _c("el-pagination", {
                            attrs: {
                              total: _vm.total,
                              "pager-count": 5,
                              "current-page": _vm.listQuery.page,
                              "page-sizes": _vm.pageSizes,
                              "page-size": _vm.listQuery.size,
                              layout: "total, sizes, prev, pager, next, jumper",
                              background: ""
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.currentchange
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }