var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "live-share-wrapper" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "live-share-dialog",
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "lock-scroll": false,
            visible: _vm.visibleProxy,
            width: "620px",
            title: "分享课程"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleProxy = $event
            },
            opened: _vm.initShareData
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "90px" } },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.changeIdentity },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _vm.liveObj.pushLive !== 2
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "老师", name: "teacher" } },
                        [
                          _vm.liveObj.pushLive !== 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "老师参加码" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c("div", { staticClass: "txt-input" }, [
                                        _vm._v(_vm._s(_vm.liveObj.teacherCode))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            "data-clipboard-text":
                                              _vm.liveObj.teacherCode,
                                            type: "primary"
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.liveObj.pushLive !== 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "客户端进入" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.teacherPhoneUrl,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-input" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.teacherPhoneUrl)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            "data-clipboard-text":
                                              _vm.teacherPhoneUrl,
                                            type: "primary"
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.liveObj.pushLive !== 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "网页进入" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.teacherPcUrl,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-input" },
                                            [_vm._v(_vm._s(_vm.teacherPcUrl))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            "data-clipboard-text":
                                              _vm.teacherPcUrl,
                                            type: "primary"
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.liveObj.pushLive !== 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "APP进入" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.teacherAppUrl,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-input-bg" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "txt-url" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.teacherAppUrl)
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    slot: "suffix",
                                                    "popper-class":
                                                      "code-popover-bg",
                                                    placement: "bottom-end",
                                                    width: "142",
                                                    trigger: "hover"
                                                  },
                                                  slot: "suffix"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "code-bg" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "code-img"
                                                        },
                                                        [
                                                          _c("vue-qr", {
                                                            staticClass:
                                                              "teacherAppCode",
                                                            attrs: {
                                                              text:
                                                                _vm.teacherAppUrl,
                                                              size: 116,
                                                              margin: 0
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass: "a-down",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.downCode(
                                                                "teacherAppCode"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("点击下载")]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont icon-black_antOutline-qrcode",
                                                    attrs: {
                                                      slot: "reference"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showCode()
                                                      }
                                                    },
                                                    slot: "reference"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            "data-clipboard-text":
                                              _vm.teacherAppUrl,
                                            type: "primary"
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.liveObj.pushLive === 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "推流地址" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.pushLiveUrl,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-input" },
                                            [_vm._v(_vm._s(_vm.pushLiveUrl))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            type: "primary",
                                            "data-clipboard-text":
                                              _vm.pushLiveUrl
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "学员", name: "student" } },
                    [
                      _c("el-form-item", { attrs: { label: "PC进入" } }, [
                        _c(
                          "div",
                          { staticClass: "txt-bg" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.studentPcUrl,
                                  target: "_blank"
                                }
                              },
                              [
                                _c("div", { staticClass: "txt-input" }, [
                                  _vm._v(_vm._s(_vm.studentPcUrl))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-copy tag-read",
                                attrs: {
                                  type: "primary",
                                  "data-clipboard-text": _vm.studentPcUrl
                                },
                                on: { click: _vm.copy }
                              },
                              [_vm._v("复制")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "手机端进入" } }, [
                        _c(
                          "div",
                          { staticClass: "txt-bg" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.studentPhoneUrl,
                                  target: "_blank"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "txt-input-bg" },
                                  [
                                    _c("span", { staticClass: "txt-url" }, [
                                      _vm._v(_vm._s(_vm.studentPhoneUrl))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          slot: "suffix",
                                          "popper-class": "code-popover-bg",
                                          placement: "bottom-end",
                                          width: "142",
                                          trigger: "hover"
                                        },
                                        slot: "suffix"
                                      },
                                      [
                                        _c("div", { staticClass: "code-bg" }, [
                                          _c(
                                            "div",
                                            { staticClass: "code-img" },
                                            [
                                              _c("vue-qr", {
                                                staticClass: "studentPhoneCode",
                                                attrs: {
                                                  text: _vm.studentPhoneUrl,
                                                  size: 116,
                                                  margin: 0
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "a-down",
                                              on: {
                                                click: function($event) {
                                                  return _vm.downCode(
                                                    "studentPhoneCode"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("点击下载")]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-black_antOutline-qrcode",
                                          attrs: { slot: "reference" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showCode()
                                            }
                                          },
                                          slot: "reference"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-copy tag-read",
                                attrs: {
                                  type: "primary",
                                  "data-clipboard-text": _vm.studentPhoneUrl
                                },
                                on: { click: _vm.copy }
                              },
                              [_vm._v("复制")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "助教", name: "assistant" } },
                    [
                      _c("el-form-item", { attrs: { label: "助教参加码" } }, [
                        _c(
                          "div",
                          { staticClass: "txt-bg" },
                          [
                            _c("div", { staticClass: "txt-input" }, [
                              _vm._v(_vm._s(_vm.liveObj.adminCode))
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-copy tag-read",
                                attrs: {
                                  "data-clipboard-text": _vm.liveObj.adminCode,
                                  type: "primary"
                                },
                                on: { click: _vm.copy }
                              },
                              [_vm._v("复制")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "PC进入" } }, [
                        _c(
                          "div",
                          { staticClass: "txt-bg" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.assistantPcUrl,
                                  target: "_blank"
                                }
                              },
                              [
                                _c("div", { staticClass: "txt-input" }, [
                                  _vm._v(_vm._s(_vm.assistantPcUrl))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-copy tag-read",
                                attrs: {
                                  "data-clipboard-text": _vm.assistantPcUrl,
                                  type: "primary"
                                },
                                on: { click: _vm.copy }
                              },
                              [_vm._v("复制")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.liveObj.pushLive !== 1
                        ? _c("el-form-item", { attrs: { label: "APP进入" } }, [
                            _c(
                              "div",
                              { staticClass: "txt-bg" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.assistantAppUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "txt-input-bg" },
                                      [
                                        _c("span", { staticClass: "txt-url" }, [
                                          _vm._v(_vm._s(_vm.assistantAppUrl))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              slot: "suffix",
                                              "popper-class": "code-popover-bg",
                                              placement: "bottom-end",
                                              width: "142",
                                              trigger: "hover"
                                            },
                                            slot: "suffix"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "code-bg" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "code-img" },
                                                  [
                                                    _c("vue-qr", {
                                                      staticClass:
                                                        "assistantAppCode",
                                                      attrs: {
                                                        text:
                                                          _vm.assistantAppUrl,
                                                        size: 116,
                                                        margin: 0
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "a-down",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.downCode(
                                                          "assistantAppCode"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("点击下载")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-black_antOutline-qrcode",
                                              attrs: { slot: "reference" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showCode()
                                                }
                                              },
                                              slot: "reference"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn-copy tag-read",
                                    attrs: {
                                      "data-clipboard-text":
                                        _vm.assistantAppUrl,
                                      type: "primary"
                                    },
                                    on: { click: _vm.copy }
                                  },
                                  [_vm._v("复制")]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.liveObj.pushLive !== 2
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "录制", name: "copy" } },
                        [
                          _c("el-form-item", { attrs: { label: "直播录制" } }, [
                            _c(
                              "div",
                              { staticClass: "txt-bg" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.liveRecordingPcUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "txt-input" }, [
                                      _vm._v(_vm._s(_vm.liveRecordingPcUrl))
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn-copy tag-read",
                                    attrs: {
                                      "data-clipboard-text":
                                        _vm.liveRecordingPcUrl,
                                      type: "primary"
                                    },
                                    on: { click: _vm.copy }
                                  },
                                  [_vm._v("复制")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.liveObj.longTerm !== 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "回放录制" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.playbackRecordingPcUrl,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-input" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.playbackRecordingPcUrl
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            "data-clipboard-text":
                                              _vm.playbackRecordingPcUrl,
                                            type: "primary"
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.liveObj.longTerm !== 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "官方回放" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "txt-bg" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.officialPlaybackPcUrl
                                              ? _vm.officialPlaybackPcUrl
                                              : "",
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "txt-input" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.officialPlaybackPcUrl
                                                    ? _vm.officialPlaybackPcUrl
                                                    : _vm.officialPlaybackPcUrlEmpty
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-copy tag-read",
                                          attrs: {
                                            "data-clipboard-text": _vm.officialPlaybackPcUrl
                                              ? _vm.officialPlaybackPcUrl
                                              : "",
                                            type: "primary"
                                          },
                                          on: { click: _vm.copy }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }