var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statistics-box" }, [
    _c("div", { staticClass: "tabs-box" }, [
      _c("div", { staticClass: "tab-item-box" }, [
        _c(
          "div",
          {
            staticClass: "tab-item",
            class: _vm.tabName === "menu" ? "active" : "",
            on: {
              click: function($event) {
                return _vm.handleChangeTab("menu")
              }
            }
          },
          [_vm._v("目录")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-item",
            class: _vm.tabName === "column" ? "active" : "",
            on: {
              click: function($event) {
                return _vm.handleChangeTab("column")
              }
            }
          },
          [_vm._v("栏目")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-box" }, [
      _vm.tabName === "menu"
        ? _c(
            "div",
            { staticClass: "menu-box" },
            [
              _c("el-tree", {
                ref: "catalogTree",
                attrs: {
                  data: _vm.menuList,
                  props: _vm.defaultProps,
                  draggable: "",
                  "node-key": "id",
                  "default-expand-all": true,
                  "expand-on-click-node": false
                },
                on: { "node-click": _vm.handleNodeClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c(
                          "div",
                          {
                            staticClass: "custom-tree-node",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  enterable: false,
                                  placement: "top-start",
                                  "open-delay": 500,
                                  effect: "light"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "directory-tooltip",
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(node.label)
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "treeName",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    },
                                    style:
                                      data.id === _vm.checkMenuID
                                        ? "color:#409EFF;font-weight: bold;"
                                        : "",
                                    attrs: { id: data.id }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "treeName",
                                      domProps: {
                                        innerHTML: _vm._s(node.label)
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  false,
                  3234231307
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tabName === "column"
        ? _c(
            "div",
            { staticClass: "menu-box" },
            _vm._l(_vm.queryExtData, function(item) {
              return _c(
                "div",
                {
                  staticClass: "label-list-item",
                  class:
                    item.extDataOpenId === _vm.extDataOpenId ? "active" : "",
                  on: {
                    click: function($event) {
                      return _vm.handleChangeExtData(item)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "label-name" }, [
                    _vm._v(_vm._s(item.labelName))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label-resource-number" }, [
                    _vm._v(_vm._s(item.resourceCount ? item.resourceCount : 0))
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "resource-box",
          style: _vm.tabName === "column" ? "width: 776px" : ""
        },
        [
          _vm.showList.length > 0
            ? _c(
                "div",
                _vm._l(_vm.showList, function(item, index) {
                  return _c(
                    "div",
                    { staticClass: "resource-item" },
                    [
                      _c("div", { staticClass: "resource-title" }, [
                        _vm.tabName === "menu"
                          ? _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(item.labelName))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tabName === "column"
                          ? _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(item.parentName))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "resource-number" }, [
                          _vm._v("资源数："),
                          _c("span", { staticClass: "number" }, [
                            _vm._v(_vm._s(item.fieldValue.length))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("default-content", {
                        attrs: {
                          type: item.inputType,
                          "default-value": item.fieldValue,
                          bookOpenId: _vm.bookInfo.bookOpenId,
                          bookMenuOpenId: _vm.bookInfo.bookMenuOpenId,
                          extDataOpenId: item.extDataOpenId,
                          index: index,
                          previewType: true,
                          htmlShowType: true
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : !_vm.loading
            ? _c("div", { staticClass: "noQueryData" }, [
                _vm._v("\n        暂无数据\n      ")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.tabName === "menu"
        ? _c(
            "div",
            { staticClass: "ext-list-box" },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleChangeLabel },
                  model: {
                    value: _vm.checkList,
                    callback: function($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList"
                  }
                },
                _vm._l(_vm.queryExtData, function(item) {
                  return _c(
                    "el-checkbox",
                    {
                      staticClass: "ext-item",
                      attrs: { label: item.fieldName }
                    },
                    [_vm._v(_vm._s(item.labelName))]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bottom-box" }, [
      _c("div", [
        _vm._v("总资源数："),
        _c("span", { staticClass: "resource-number" }, [
          _vm._v(_vm._s(_vm.resourceCount))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.toDirectory } },
            [_vm._v("编辑")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }