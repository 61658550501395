"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../utils/common");

var _main = require("@/organizationLibs/editor/main");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: function _default() {}
    },
    goods: {
      type: Object,
      default: function _default() {}
    },
    resourceType: {
      type: String,
      default: ''
    }
  },
  components: {
    Editor: _main.Editor
  },
  data: function data() {
    return {
      isShow: false,
      periodName: '',
      showDetail: false
    };
  },
  computed: {
    discount: function discount() {
      return parseFloat(this.goods.price / 100 * this.goods.discount).toFixed(2);
    },
    vipDiscount: function vipDiscount() {
      return parseFloat(this.discount * this.goods.vipDiscount).toFixed(2);
    },
    videoInfo: {
      get: function get() {
        return this.info;
      },
      set: function set(val) {
        console.log('videoInfo', val);
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler: function handler(val) {
        this.isShow = val;
      }
    }
  },
  methods: {
    imageUrl: function imageUrl(url, style) {
      return (0, _common.ossUploadUrl)(url, style);
    },
    close: function close() {
      this.isShow = false;
      this.$parent.$data.uploadVisible = false;
    },
    confirmFun: function confirmFun() {
      this.close();
      this.$parent.startUpload();
    }
  }
};
exports.default = _default2;