"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "get", {
  enumerable: true,
  get: function get() {
    return _read.get;
  }
});
Object.defineProperty(exports, "has", {
  enumerable: true,
  get: function get() {
    return _read.has;
  }
});
Object.defineProperty(exports, "increment", {
  enumerable: true,
  get: function get() {
    return _read.increment;
  }
});
Object.defineProperty(exports, "decrement", {
  enumerable: true,
  get: function get() {
    return _read.decrement;
  }
});
Object.defineProperty(exports, "remember", {
  enumerable: true,
  get: function get() {
    return _read.remember;
  }
});
Object.defineProperty(exports, "rememberForever", {
  enumerable: true,
  get: function get() {
    return _read.rememberForever;
  }
});
Object.defineProperty(exports, "rememberAsync", {
  enumerable: true,
  get: function get() {
    return _read.rememberAsync;
  }
});
Object.defineProperty(exports, "rememberForeverAsync", {
  enumerable: true,
  get: function get() {
    return _read.rememberForeverAsync;
  }
});
Object.defineProperty(exports, "pull", {
  enumerable: true,
  get: function get() {
    return _read.pull;
  }
});
Object.defineProperty(exports, "put", {
  enumerable: true,
  get: function get() {
    return _write.put;
  }
});
Object.defineProperty(exports, "add", {
  enumerable: true,
  get: function get() {
    return _write.add;
  }
});
Object.defineProperty(exports, "forever", {
  enumerable: true,
  get: function get() {
    return _write.forever;
  }
});
Object.defineProperty(exports, "forget", {
  enumerable: true,
  get: function get() {
    return _write.forget;
  }
});
Object.defineProperty(exports, "flush", {
  enumerable: true,
  get: function get() {
    return _write.flush;
  }
});

var _read = require("./read");

var _write = require("./write");