"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CoverDisplay = _interopRequireDefault(require("../../../../General/Image/CoverDisplay"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Ge_Content_CommodityCell_index',
  components: {
    CoverDisplay: _CoverDisplay.default
  },
  props: {
    commodity: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  }
};
exports.default = _default2;