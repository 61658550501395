"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      customizesearch: false
    };
  },
  methods: {
    downUphandler: function downUphandler() {
      this.customizesearch = !this.customizesearch;
      this.$emit('firstLoading', true);
    },
    searchContent: function searchContent() {
      this.$emit('searchContent');
    }
  }
};
exports.default = _default;