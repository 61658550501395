var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "newAddBox",
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": false,
        title: _vm.type === "add" ? "试题添加" : "编辑试题",
        visible: _vm.newQuestiondialogVisible,
        width: "1035px",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.newQuestiondialogVisible = $event
        }
      }
    },
    [
      _c("question-details", {
        attrs: {
          questionAdd: _vm.questionAdd,
          operationCode: _vm.operationCode,
          source: _vm.source,
          clickNodeOpenId: _vm.clickNodeOpenId,
          merchantOpenId: _vm.merchantOpenId,
          editOpenId: _vm.editOpenId,
          type: _vm.type
        },
        on: { directoryDialogChange: _vm.directoryDialogChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }