"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCompetent = addCompetent;
exports.updateCompetent = updateCompetent;
exports.getDetailById = getDetailById;
exports.getCompetentList = getCompetentList;
exports.addMicroPage = addMicroPage;
exports.getMicroPageList = getMicroPageList;
exports.batchDisableMicroPage = batchDisableMicroPage;
exports.batchUseMicroPage = batchUseMicroPage;
exports.batchDeleteMicroPage = batchDeleteMicroPage;
exports.batchPhysicalDeleteByIds = batchPhysicalDeleteByIds;
exports.existHomePage = existHomePage;
exports.getDetailPageById = getDetailPageById;
exports.getMaterialClassificationList = getMaterialClassificationList;
exports.getMaterialManageListByPartner = getMaterialManageListByPartner;
exports.addMaterialClassification = addMaterialClassification;
exports.updateMaterialClassification = updateMaterialClassification;
exports.getMaterialClassificationById = getMaterialClassificationById;
exports.deleteMaterialClassification = deleteMaterialClassification;
exports.moveMaterialClassification = moveMaterialClassification;
exports.updateStateById = updateStateById;
exports.getMaterialList = getMaterialList;
exports.addMaterial = addMaterial;
exports.updateMaterialName = updateMaterialName;
exports.batchDeleteMaterial = batchDeleteMaterial;
exports.getAllMaterialClassification = getAllMaterialClassification;
exports.queryAllListNotIncludeDefault = queryAllListNotIncludeDefault;
exports.addMicroClassification = addMicroClassification;
exports.getMicroClassificationList = getMicroClassificationList;
exports.updateMicroClassification = updateMicroClassification;
exports.deleteMicroClassification = deleteMicroClassification;
exports.moveMicroClassification = moveMicroClassification;
exports.batchMicroClassification = batchMicroClassification;
exports.blankingHomePage = blankingHomePage;
exports.updateMicroPage = updateMicroPage;
exports.batchSetCategory = batchSetCategory;
exports.getMerchantMicroPagelist = getMerchantMicroPagelist;
exports.batchRecoveryByIds = batchRecoveryByIds;
exports.copyByMicroPageOpenIds = copyByMicroPageOpenIds;
exports.updateSmallProgramCodeByOpenId = updateSmallProgramCodeByOpenId;
exports.removeMicroByPartner = removeMicroByPartner;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/micro-page/admin/"));
/**
 * 新增基础组件
 * @param {Object} data
 * @returns {Promise<*>}
 */

function addCompetent(data) {
  return request.post('competent/add', data);
}
/**
 * 编辑基础组件
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateCompetent(data) {
  return request.post('competent/update', data);
}
/**
 * 根据基础组件ID查看组件详情
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getDetailById(data) {
  return request.post('competent/view', data);
}
/**
 * 获取组件列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCompetentList(params) {
  return request.get('competent/list', {
    params: params
  });
}
/**
 * 保存微页
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addMicroPage(data) {
  return request.post('page/add', data);
}
/**
 * 获取微页列表 分页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMicroPageList(params) {
  return request.get('page/list', {
    params: params
  });
}
/**
 * 校验特定商户是否设置过主页
 * @param {Object} data
 * @returns {Promise<*>}
 */


function existHomePage(data) {
  return request.post('page/existHomePage', data);
}
/**
 * 批量禁用微页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchDisableMicroPage(params) {
  return request.delete('page/batchOfficiallyDisableByIds', {
    params: params
  });
}
/**
 * 批量启用微页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchUseMicroPage(params) {
  return request.delete('page/batchEnableByIds', {
    params: params
  });
}
/**
 * 批量删除微页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchDeleteMicroPage(params) {
  return request.delete('page/batchDeleteByIds', {
    params: params
  });
}
/**
 * 批量彻底删除微页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchPhysicalDeleteByIds(params) {
  return request.delete('page/batchPhysicalDeleteByIds', {
    params: params
  });
}
/**
 * 新增微页分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addMicroClassification(data) {
  return request.post('microPageClassification/add', data);
}
/**
 * 获取微页分类列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMicroClassificationList(params) {
  return request.get('microPageClassification/list', {
    params: params
  });
}
/**
 * 修改微页分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateMicroClassification(data) {
  return request.post('microPageClassification/update', data);
}
/**
 * 删除微页分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function deleteMicroClassification(data) {
  return request.post('microPageClassification/deleteById', data);
}
/**
 * 移动微页分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function moveMicroClassification(params) {
  return request.patch('microPageClassification/moveMicroPageClassification', undefined, {
    params: params
  });
}
/**
 * 批量修改分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function batchMicroClassification(data) {
  return request.patch('microPageClassification/batch', data);
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchSetCategory(params) {
  return request.patch('page/setCategory', undefined, {
    params: params
  });
}
/**
 * 置空特定商户设置过的主页
 * @param {Object} data
 * @returns {Promise<*>}
 */


function blankingHomePage(data) {
  return request.post('page/blankingHomePage', data);
}
/**
 * 编辑微页
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateMicroPage(data) {
  return request.post('page/update', data);
}
/**
 * 获取微页分类列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMerchantMicroPagelist(params) {
  return request.get('page/getMerchantMicroPagelist', {
    params: params
  });
}
/**
 * 批量恢复微页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchRecoveryByIds(params) {
  return request.delete('page/batchRecoveryByIds/', {
    params: params
  });
}
/**
 * 获取微页详情
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getDetailPageById(data) {
  return request.post('page/view', data);
}
/**
 * 获取素材分类列表 分页 (按照分组统计)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMaterialClassificationList(params) {
  return request.get('materialClassification/list', {
    params: params
  });
}
/**
 * 获取素材分类列表 全部 (按照分组统计)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllMaterialClassification(params) {
  return request.get('materialClassification/queryAllList', {
    params: params
  });
}
/**
 * 获取素材管理列表 分页 (按照商户统计)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMaterialManageListByPartner(params) {
  return request.get('materialClassification/partnerMaterialClassificationList', {
    params: params
  });
}
/**
 * 新增素材分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addMaterialClassification(data) {
  return request.post('materialClassification/add', data);
}
/**
 * 修改素材库分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateMaterialClassification(data) {
  return request.post('materialClassification/update', data);
}
/**
 * 根据openId素材分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getMaterialClassificationById(data) {
  return request.post('materialClassification/view', data);
}
/**
 * 删除素材分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function deleteMaterialClassification(data) {
  return request.post('materialClassification/deleteByMaterialClassificationOpenId', data);
}
/**
 * 移动素材分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function moveMaterialClassification(data) {
  return request.post('materialClassification/moveByOldId', data);
}
/**
 * 移动素材分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateStateById(data) {
  return request.post('materialClassification/updateStateById', data);
}
/**
 * 获取素材列表 分页
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMaterialList(params) {
  return request.get('material/list', {
    params: params
  });
}
/**
 * 新增素材
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addMaterial(data) {
  return request.post('material/add', data);
}
/**
 * 修改素材名称
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateMaterialName(data) {
  return request.post('material/updateMaterialName', data);
}
/**
 * 批量删除素材
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchDeleteMaterial(params) {
  return request.delete('material/batchDeleteByIds', {
    params: params
  });
}
/**
 * 获取系统素材不带默认分类(弹窗用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function queryAllListNotIncludeDefault(params) {
  return request.get('materialClassification/queryAllListNotIncludeDefault', {
    params: params
  });
}
/**
 * 新增素材分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function copyByMicroPageOpenIds(params) {
  return request.patch('page/copyByMicroPageOpenIds', undefined, {
    params: params
  });
}
/**
 * 更新微页小程序码
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSmallProgramCodeByOpenId(data) {
  return request.post('page/updateSmallProgramCodeByOpenId', data);
}
/**
 * 更新微页小程序码
 * @param {Object} data
 * @returns {Promise<*>}
 */


function removeMicroByPartner(params) {
  return request.patch('page/copyByMicroPageOpenIdsByPartnerOpenIdAndMicroPageClassificationOpenId', undefined, {
    params: params
  });
}