"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  '0100': '中国邮政储蓄银行',
  '0102': '工商银行',
  '0103': '农业银行',
  '0104': '中国银行',
  '0105': '建设银行',
  '0301': '交通银行',
  '0302': '中信银行',
  '0303': '光大银行',
  '0304': '华夏银行',
  '0305': '民生银行',
  '0306': '广发银行',
  '0308': '招商银行',
  '0309': '兴业银行',
  '0310': '浦发银行',
  '0318': '平安银行',
  '0403': '北京银行',
  '0408': '宁波银行',
  null: '吉林省农村信用社'
};
exports.default = _default;