"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVideoAddress = getVideoAddress;
exports.doGetUploadVideo = doGetUploadVideo;
exports.getVideoPlayAuth = getVideoPlayAuth;
exports.doGetRefreshVideo = doGetRefreshVideo;
exports.getPlayInfo = getPlayInfo;
exports.deleteVideo = deleteVideo;
exports.getSourceFile = getSourceFile;

var _main = _interopRequireDefault(require("../../utils/request/main"));

var thirdSdkRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/aliVod/common/"));
/**
 * 获取视频播放地址
 * @param {String} videoIds
 * @returns {Promise<*>}
 */

function getVideoAddress(videoIds) {
  return thirdSdkRequest.get('/mediaResource/getVideoInfos', {
    params: {
      videoIds: videoIds
    }
  });
}
/**
 * 获取视频上传凭证
 * @param {Object} params
 * @returns {Promise<*>}
 */


function doGetUploadVideo(params) {
  return thirdSdkRequest.get('/upload/createUploadVideo', {
    params: params
  });
}
/**
 * 刷新上传凭证
 * @param {Object} params
 * @returns {Promise<*>}
 */


function doGetRefreshVideo(params) {
  return thirdSdkRequest.get('/upload/refreshUploadVideo', {
    params: params
  });
}
/**
 * 获取视频播放凭证
 * @param {String} videoId
 * @returns {Promise<*>}
 */


function getVideoPlayAuth(videoId) {
  return thirdSdkRequest.get('/play/getVideoPlayAuth', {
    params: {
      videoId: videoId
    }
  });
}
/**
 * 获取视频播放地址
 * @param {String} videoId
 * @returns {Promise<*>}
 */


function getPlayInfo(videoId) {
  return thirdSdkRequest.get('/play/getPlayInfo', {
    params: {
      videoId: videoId
    }
  });
}
/**
 * 删除视频
 * @param {String} videoId
 * @returns {Promise<*>}
 */


function deleteVideo(videoId) {
  return thirdSdkRequest.get('/mediaResource/deleteVideo', {
    params: {
      videoId: videoId
    }
  });
}
/**
 * 获取源文件信息
 * @param {String} videoId
 * @returns {Promise<*>}
 */


function getSourceFile(videoId) {
  return thirdSdkRequest.get('/mediaResource/getMezzanineInfo', {
    params: {
      videoId: videoId
    }
  });
}