"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_DEFAULT_CACHE_KEY = exports.PAGE_DEFAULT_SORT = void 0;
var PAGE_DEFAULT_SORT = {
  resourceType: undefined,
  // 商品类型
  keyword: undefined,
  // 搜索
  sectionCode: undefined,
  // 学段
  subjectCode: undefined,
  // 学科
  editionCode: undefined,
  // 版本
  gradeCode: undefined,
  // 年级
  volumeCode: undefined,
  // 阶段
  electiveCode: undefined,
  // 选必修
  areaCode: undefined,
  // 地区
  periodCode: undefined,
  // 版次
  tags: undefined,
  // 商品标签
  // 类目信息
  regimentationIds: undefined,
  secondRegimentationIds: undefined,
  brandOpenId: undefined,
  // 品牌
  seriesCode: undefined,
  // 图书系列
  minInventory: undefined,
  // 最小库存
  maxInventory: undefined,
  // 最大库存
  state: '0',
  // 在售状态 - 全部
  specificJson: '0',
  // 有无规格 - 全部
  cover: '0',
  // 商品封面 - 全部
  showVideo: '0',
  // 主图视频 - 全部
  quota: '0',
  // 是否限购 - 全部
  teacherBuy: '0',
  // 教师可购 - 全部
  // 创建时间
  createdAtStart: undefined,
  createdAtEnd: undefined,
  sortBy: 'createdAt',
  // 排序条件 - 发布时间
  sortOrder: 'desc',
  // 排序方式 - 倒序
  size: 20,
  // 每页条数
  page: 1 // 第几页

};
exports.PAGE_DEFAULT_SORT = PAGE_DEFAULT_SORT;
var LIST_DEFAULT_CACHE_KEY = ['resourceType', // 商品类型
'keyword', // 搜索
'sectionCode', // 学段
'subjectCode', // 学科
'editionCode', // 版本
'gradeCode', // 年级
'volumeCode', // 阶段
'electiveCode', // 选必修
'areaCode', // 地区
'periodCode', // 版次
'tags', // 商品标签
'regimentationIds', 'secondRegimentationIds', // 类目信息
'brandOpenId', // 品牌
'seriesCode', // 图书系列
'state', // 有无规格
'specificJson', // 有无规格
'cover', // 商品封面
'showVideo', // 主图视频
'quota', // 是否限购
'teacherBuy', // 教师可购
'createdAtStart', 'createdAtEnd', // 创建时间
'sortBy', // 排序条件
'sortOrder', // 排序方式
'size' // 每页条数
];
exports.LIST_DEFAULT_CACHE_KEY = LIST_DEFAULT_CACHE_KEY;