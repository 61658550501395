var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboardContainer" }, [
    _c("div", { staticClass: "codeWraper" }, [
      _c("div", { staticClass: "useHead" }, [
        _c("h3", { staticClass: "wrapTitle" }, [
          _vm._v(_vm._s(_vm.$route.params.title))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("ordinaryuser", { on: { receiveuserid: _vm.receiveuserid } }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "searchInput",
              attrs: { placeholder: "请输入激活码" },
              model: {
                value: _vm.query.keyword,
                callback: function($$v) {
                  _vm.$set(_vm.query, "keyword", $$v)
                },
                expression: "query.keyword"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.initData } },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "useContent" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData }
            },
            [
              _c("el-table-column", {
                attrs: { label: "激活码", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "tableText" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(scope.row.cdKey) +
                              "\n            "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.user
                          ? _c("div", { staticClass: "tableUser" }, [
                              _c(
                                "div",
                                { staticClass: "userInfo" },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                        "border-radius": "50%",
                                        overflow: "hidden"
                                      },
                                      attrs: {
                                        src: _vm.getImgUrl(
                                          scope.row.user.cover
                                        ),
                                        fit: "cover"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "24px",
                                              height: "24px",
                                              "border-radius": "50%",
                                              border: "1px solid #ddd"
                                            },
                                            attrs: {
                                              src: require("../../../assets/book_cover.png")
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "userName",
                                        { isVip: scope.row.user.vipUser }
                                      ]
                                    },
                                    [_vm._v(_vm._s(scope.row.user.nickName))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "userId" }, [
                                _vm._v(
                                  "用户ID：" + _vm._s(scope.row.user.openId)
                                )
                              ])
                            ])
                          : _c("div", { staticClass: "tableUser" }, [
                              _c("div", { staticClass: "userInfo" }, [
                                _c("img", {
                                  staticStyle: {
                                    width: "24px",
                                    height: "24px",
                                    "border-radius": "50%",
                                    border: "1px solid #ddd"
                                  },
                                  attrs: {
                                    src: require("../../../assets/book_cover.png")
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "userName" }, [
                                  _vm._v("--")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "userId" }, [
                                _vm._v("用户ID：--")
                              ])
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "使用状态", "min-width": "10" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "tableText" }, [
                          scope.row.useUserOpenId
                            ? _c("span", [
                                _vm._v(
                                  "\n                已使用\n              "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                未使用\n              "
                                )
                              ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "使用时间", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "tableText" }, [
                          scope.row.usedAt
                            ? _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("parseTime")(
                                        scope.row.usedAt,
                                        "{y}-{m}-{d} {h}:{i}"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            : _c("span", [
                                _vm._v("\n                --\n              ")
                              ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "链接", "min-width": "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "tableLink" }, [
                          _c("div", { staticClass: "tableText" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.codeLink + scope.row.cdKey) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-copy-document",
                            on: {
                              click: function($event) {
                                return _vm.copyOpenId(
                                  _vm.codeLink + scope.row.cdKey,
                                  $event
                                )
                              }
                            }
                          })
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "useFoot" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.initData
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }