var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.expressLoading,
          expression: "expressLoading"
        }
      ],
      attrs: {
        title: "设置快递信息",
        visible: _vm.visible,
        width: "1192px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.disMissExpressDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "background-color": "#F5F7FA", padding: "10px" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "white",
                "border-radius": "4px",
                padding: "15px",
                height: "600px",
                overflow: "auto"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "info_title",
                  staticStyle: { "margin-bottom": "15px" }
                },
                [_vm._v("商品信息")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticClass: "info_row_margin",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.orderFormDetails,
                    "default-expand-all": "",
                    "row-key": "id",
                    "tree-props": {
                      children: "orderFormDetailLogisticList",
                      hasChildren: "hasChildren"
                    }
                  }
                },
                [
                  _vm._v(">\n          "),
                  _c("el-table-column", {
                    attrs: { label: "openId", prop: "productOpenId" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "商品" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "display_flex align_items" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "goodsCover_box" },
                                        [
                                          _c(
                                            "el-image",
                                            {
                                              staticClass: "goodsCover",
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  scope.row.cover,
                                                  _vm.smallthumbnailStyle64
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "image-slot",
                                                  attrs: { slot: "error" },
                                                  slot: "error"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "goodsCover",
                                                    attrs: {
                                                      src: require("@/assets/book_cover.png")
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "marginLeft_10" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(scope.row.title) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specificJson",
                      align: "center",
                      label: "规格"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.showVal(scope.row.specification))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "buyQuantity",
                      width: "100",
                      align: "center",
                      label: "数量"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.isLog
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "90px" },
                                      attrs: {
                                        "controls-position": "right",
                                        min: 1
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.buyQuantityChange(
                                            scope.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.buyQuantity,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "buyQuantity",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.buyQuantity"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.buyQuantity) +
                                      "\n              "
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "100", align: "center", label: "价格" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "¥" +
                                  _vm._s(
                                    _vm.showVal(
                                      Number(scope.row.payPrice / 100).toFixed(
                                        2
                                      )
                                    )
                                  )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "所属快递" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "display_flex align_items space_between "
                              },
                              [
                                scope.row.isLog ||
                                (!scope.row.isLog &&
                                  scope.row.orderFormDetailLogisticList.length <
                                    2)
                                  ? _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "150px" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: scope.row.expressAliasName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "expressAliasName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.expressAliasName"
                                        }
                                      },
                                      _vm._l(
                                        _vm.orderFormMaterialFlowRelationShipList,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.name,
                                            attrs: {
                                              label:
                                                item.name +
                                                ":" +
                                                (item.expressType === 1
                                                  ? item.trackingNumber
                                                  : "") +
                                                (item.expressType === 2
                                                  ? "自行配送"
                                                  : "") +
                                                (item.expressType === 3
                                                  ? "同城配送"
                                                  : ""),
                                              value: item.name
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.buyQuantity > 1 &&
                                !scope.row.isLog &&
                                scope.row.orderFormDetailLogisticList.length < 2
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.splitGoods(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  拆单\n                "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.isLog
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addGoods(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("增加\n                ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.isLog
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          underline: false
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteGoods(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("删除\n                ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "info_title",
                  staticStyle: { "margin-bottom": "15px", "margin-top": "15px" }
                },
                [_vm._v("快递")]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm._l(_vm.orderFormMaterialFlowRelationShipList, function(
                    item,
                    index
                  ) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { span: 8 }
                      },
                      [
                        _c("div", { staticClass: "address_item" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "address_title display_flex align_items space_between"
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "\n                  快递" +
                                    _vm._s(index + 1) +
                                    ":\n                  "
                                ),
                                item.expressType === 1
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.trackingNumber))
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.expressType === 2
                                  ? _c("span", [_vm._v("自行配送")])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                index !== 0
                                  ? _c("i", {
                                      staticClass: "el-icon-delete",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delExpress(item, index)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { padding: "15px" } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "expressForm",
                                  refInFor: true,
                                  attrs: { model: item, "label-width": "80px" }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "快递类型",
                                        prop: "postCompanyId"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.expressType,
                                            callback: function($$v) {
                                              _vm.$set(item, "expressType", $$v)
                                            },
                                            expression: "item.expressType"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("快递")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 2 } },
                                            [_vm._v("自行配送")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  item.expressType === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "快递公司",
                                            prop: "postCompanyId",
                                            rules: {
                                              required: true,
                                              message: "请选择快递公司",
                                              trigger: "blur"
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "235px" },
                                              model: {
                                                value: item.postCompanyId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "postCompanyId",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.postCompanyId"
                                              }
                                            },
                                            _vm._l(_vm.activeOption, function(
                                              activeOptionItem
                                            ) {
                                              return _c("el-option", {
                                                key: activeOptionItem.id,
                                                attrs: {
                                                  label:
                                                    activeOptionItem.expName,
                                                  value: activeOptionItem.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.expressType === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "运单号",
                                            prop: "trackingNumber",
                                            rules: {
                                              required: true,
                                              message: "请输入快递单号",
                                              trigger: "blur"
                                            }
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "235px" },
                                            model: {
                                              value: item.trackingNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "trackingNumber",
                                                  $$v
                                                )
                                              },
                                              expression: "item.trackingNumber"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.expressType === 2
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "联系人" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "235px" },
                                            model: {
                                              value: item.expressPerson,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "expressPerson",
                                                  $$v
                                                )
                                              },
                                              expression: "item.expressPerson"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.expressType === 2
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "联系电话" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "235px" },
                                            model: {
                                              value: item.expressPersonPhone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "expressPersonPhone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.expressPersonPhone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "备注" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "235px" },
                                        attrs: {
                                          type: "textarea",
                                          rows: "4",
                                          resize: "none"
                                        },
                                        model: {
                                          value: item.remarks,
                                          callback: function($$v) {
                                            _vm.$set(item, "remarks", $$v)
                                          },
                                          expression: "item.remarks"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "15px" },
                      attrs: { span: 8 }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "address_item display_flex align_items flex_direction",
                          staticStyle: {
                            color: "rgba(96, 98, 102, 1)",
                            "font-size": "14px",
                            "justify-content": "center",
                            cursor: "pointer"
                          },
                          on: {
                            click: function($event) {
                              return _vm.pushExpress(true)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "10px" } },
                            [_vm._v("添加新快递")]
                          )
                        ]
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.disMissExpressDialog } }, [
            _vm._v("取 消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitExpress } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }