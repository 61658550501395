"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ColumnDialogFilterPanel = _interopRequireDefault(require("./ColumnDialogFilterPanel"));

var _NewFilterPanel = _interopRequireDefault(require("./NewFilterPanel"));

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogFilterPanel',
  components: {
    NewFilterPanel: _NewFilterPanel.default,
    ColumnDialogFilterPanel: _ColumnDialogFilterPanel.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    typeName: {
      type: String,
      default: '点读书'
    },
    isShowArea: {
      type: Boolean,
      default: false
    },
    showVidSearch: {
      type: Boolean,
      default: false
    },
    showJsonData: {
      type: Boolean,
      default: false
    },
    // 视频列表的附件搜索
    isOldSearch: {
      type: Boolean,
      default: false
    },
    // 是否显示原来长款搜索样式
    showDownload: {
      type: Boolean,
      default: false
    },
    // 音频下载
    isebook: {
      type: Boolean,
      default: false
    },
    // 是否电子书
    showTestData: {
      type: Boolean,
      default: false
    },
    isbookcase: {
      type: Boolean,
      default: false
    },
    isShowRemember: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      checked: true
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    checkStore: function checkStore() {
      return _main.default.get('SELECT_RESOURCE_LIST_CHECK');
    }
  },
  methods: {
    handleRemember: function handleRemember(val) {
      _main.default.put('SELECT_RESOURCE_LIST_CHECK', val);
    }
  }
};
exports.default = _default2;