var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("product-grouping-selector", {
    attrs: {
      "regimentation-ids": _vm.valueProxy,
      "second-regimentation-ids": _vm.secondGroupIdsProxy,
      multiple: true,
      "middle-open-id": _vm.middleOpenId,
      "shop-open-id": _vm.partnerOpenId
    },
    on: {
      "update:regimentationIds": function($event) {
        _vm.valueProxy = $event
      },
      "update:regimentation-ids": function($event) {
        _vm.valueProxy = $event
      },
      "update:secondRegimentationIds": function($event) {
        _vm.secondGroupIdsProxy = $event
      },
      "update:second-regimentation-ids": function($event) {
        _vm.secondGroupIdsProxy = $event
      },
      change: function($event) {
        return _vm.$emit("change")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }