var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.operateUserOpenId === _vm.selectUserOpenId
          ? "select-user-background"
          : "admin-user-background",
        "common-inner"
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.operateUserOpenId === _vm.selectUserOpenId
              ? "select-user-inner"
              : "admin-user-inner"
          ]
        },
        [
          _vm.conversationContent
            ? _c("div", {
                staticClass: "conversation-text",
                domProps: { innerHTML: _vm._s(_vm.conversationContent) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.imgUrls && _vm.imgUrls.length > 0
            ? _c(
                "div",
                { staticClass: "conversation-images" },
                _vm._l(_vm.imgUrls, function(itemImage, indexC) {
                  return _c("el-image", {
                    key: indexC,
                    class: [
                      _vm.imgUrls.length === 1 && !_vm.conversationContent
                        ? "one-dialogue-img"
                        : "dialogue-img"
                    ],
                    attrs: {
                      fit: "cover",
                      src: _vm.getImgUrl(itemImage),
                      "preview-src-list": _vm.getImg(
                        _vm.imgUrls,
                        "style/width-limit-1000"
                      )
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }