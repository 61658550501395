"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAlbumList = getAlbumList;
exports.queryAlbumInfo = queryAlbumInfo;
exports.albumStateNum = albumStateNum;
exports.reviewAlbum = reviewAlbum;
exports.batchReviewAlbum = batchReviewAlbum;
exports.addAlbumInfo = addAlbumInfo;
exports.editAlbumInfo = editAlbumInfo;
exports.delAlbumInfo = delAlbumInfo;
exports.rejectAlbum = rejectAlbum;
exports.hideAlbum = hideAlbum;
exports.cancelHideAlbum = cancelHideAlbum;
exports.stopSaleAlbum = stopSaleAlbum;
exports.cancelStopSaleAlbum = cancelStopSaleAlbum;
exports.recoverAlbumDrafts = recoverAlbumDrafts;
exports.albumSetRecommendSequence = albumSetRecommendSequence;
exports.batchRecoverAlbumDrafts = batchRecoverAlbumDrafts;
exports.batchRejectAlbum = batchRejectAlbum;
exports.delAlbumDirectory = delAlbumDirectory;
exports.getAlbumDirectoryList = getAlbumDirectoryList;
exports.editAlbumDirectory = editAlbumDirectory;
exports.albumDirectorySequence = albumDirectorySequence;
exports.addAlbumDirectory = addAlbumDirectory;
exports.albumContentSetFreeView = albumContentSetFreeView;
exports.albumContentCancelFreeView = albumContentCancelFreeView;
exports.removeAlbumContent = removeAlbumContent;
exports.addProductionContent = addProductionContent;
exports.getAudioBookRequestList = getAudioBookRequestList;
exports.getVideoRequestList = getVideoRequestList;
exports.getAlbumContent = getAlbumContent;
exports.albumContentSequenceApi = albumContentSequenceApi;
exports.albumBatchSetAttribute = albumBatchSetAttribute;
exports.albumBatchSetPrice = albumBatchSetPrice;
exports.saveAlbumRelease = saveAlbumRelease;
exports.audioRequestList = audioRequestList;
exports.moveAlbumMenu = moveAlbumMenu;
exports.getAlbumTitleList = getAlbumTitleList;
exports.updateAlbumSequence = updateAlbumSequence;
exports.updateAlbumEncode = updateAlbumEncode;
exports.getCommodityAlbumList = getCommodityAlbumList;
exports.cleanAlbumContent = cleanAlbumContent;
exports.getResourceInfoByOpenId = getResourceInfoByOpenId;
exports.getResourceView = getResourceView;
exports.getAlbumByOpenIdsList = getAlbumByOpenIdsList;
exports.batchRecoverAlbumRecycle = batchRecoverAlbumRecycle;
exports.albumBatchRevoke = albumBatchRevoke;
exports.albumBatchHidden = albumBatchHidden;
exports.albumBatchCancelHidden = albumBatchCancelHidden;
exports.albumBatchStopSale = albumBatchStopSale;
exports.albumBatchCancelStopSale = albumBatchCancelStopSale;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/admin/"));
/**
 * 获取专辑列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getAlbumList(params) {
  return request.get('/album/request/list', {
    params: params
  });
}
/**
 * 查询专辑各状态数量
 * @param {Object}params
 * @returns {Promise<*>}
 */


function albumStateNum(params) {
  return request.get('/album/request/count/', {
    params: params
  });
}
/**
 * 审核专辑
 * @param {String} openId
 * @returns {Promise<*>}
 */


function reviewAlbum(openId) {
  return request.post("/album/request/".concat(openId, "/review"));
}
/**
 * 保存并发布专辑
 * @param {String} openId
 * @returns {Promise<*>}
 */


function saveAlbumRelease(data) {
  return request.post('/album/request/saveAndReview', data);
}
/**
 * 批量审核专辑
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchReviewAlbum(params) {
  return request.post('/album/request/batch/review', undefined, {
    params: params
  });
}
/**
 * 增加专辑
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addAlbumInfo(data) {
  return request.post('/album/request', data);
}
/**
 * 修改专辑
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editAlbumInfo(openId, data) {
  return request.patch("/album/request/".concat(openId), data);
}
/**
 * 查询专辑
 * @param {String} openId
 * @returns {Promise<*>}
 */


function queryAlbumInfo(openId) {
  return request.get("/album/request/".concat(openId));
}
/**
 * 删除专辑
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delAlbumInfo(openId) {
  return request.delete("/album/request/".concat(openId));
}
/**
 * 驳回专辑
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function rejectAlbum(openId, params) {
  return request.post("/album/request/refuse/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 批量驳回专辑
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchRejectAlbum(params) {
  return request.post('/album/request/refuse', undefined, {
    params: params
  });
}
/**
 * 隐藏专辑
 * @param {String} openId
 * @param {Number} type
 * @returns {Promise<*>}
 */


function hideAlbum(openId, params) {
  return request.post("/album/request/hide/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 取消隐藏专辑
 * @param {String} openId
 * @param {Number} type
 * @returns {Promise<*>}
 */


function cancelHideAlbum(openId, params) {
  return request.post("/album/request/hide/cancel/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 停售专辑
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function stopSaleAlbum(openId, params) {
  return request.post("/album/request/stopSell/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function cancelStopSaleAlbum(openId, params) {
  return request.post("/album/request/stopSell/cancel/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 恢复到草稿箱
 * @param {String} openId
 * @returns {Promise<*>}
 */


function recoverAlbumDrafts(openId) {
  return request.post("/album/request/toDraft/".concat(openId));
}
/**
 * 视频-设置推荐排序
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function albumSetRecommendSequence(openId, params) {
  return request.patch("/album/request/change/recommendSequence/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function batchRecoverAlbumDrafts(openIds) {
  return request.post('/album/request/batch/recovery', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function batchRecoverAlbumRecycle(openIds) {
  return request.post('/album/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除专辑目录
 * @param {Number} id
 * @returns {Promise<*>}
 */


function delAlbumDirectory(id) {
  return request.delete("/menu/request/".concat(id));
}
/**
 * 获取专辑目录列表
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getAlbumDirectoryList(openId) {
  return request.get("/menu/request/list/".concat(openId));
}
/**
 * 修改专辑目录
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editAlbumDirectory(id, data) {
  return request.patch("/menu/request/".concat(id), data);
}
/**
 * 专辑目录排序
 * @param {Object} data
 * @returns {Promise<*>}
 */


function albumDirectorySequence(data) {
  return request.patch('/menu/request/sequence', data);
}
/**
 * 新增专辑目录
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addAlbumDirectory(data) {
  return request.post('/menu/request', data);
}
/**
 * 批量添加作品内容
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addProductionContent(openId, data) {
  return request.post("/content/request/".concat(openId, "/batch/add"), data);
}
/**
 * 设为试看
 * @param {String} openId
 * @param {Number} id
 * @returns {Promise<*>}
 */


function albumContentSetFreeView(openId, id) {
  return request.post("/content/request/free/".concat(openId, "/").concat(id));
}
/**
 * 取消设为试看
 * @param {String} openId
 * @param {Number} id
 * @returns {Promise<*>}
 */


function albumContentCancelFreeView(openId, id) {
  return request.post("/content/request/free/cancel/".concat(openId, "/").concat(id));
}
/**
 * 删除专辑内容
 * @param {Number} id
 * @returns {Promise<*>}
 */


function removeAlbumContent(id) {
  return request.delete("/content/request/".concat(id));
}
/**
 * 查询资源点学书列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioBookRequestList(params) {
  return request.get('/content/request/resource/book/list', {
    params: params
  });
}
/**
 * 查询资源视频列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getVideoRequestList(params) {
  return request.get('/content/request/resource/video/list', {
    params: params
  });
}
/**
 * 查询资源视频列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function audioRequestList(params) {
  return request.get('/content/request/resource/audio/list', {
    params: params
  });
}
/**
 * 根据专辑openId和menuId查询内容
 * @param {String} id
 * @param {Number} menuId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumContent(id, menuId, params) {
  return request.get("/content/request/list/".concat(id, "/").concat(menuId), {
    params: params
  });
}
/**
 * 专辑内容排序
 * @param {Object} data
 * @returns {Promise<*>}
 */


function albumContentSequenceApi(data) {
  return request.patch('/content/request/sequence', data);
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function albumBatchSetAttribute(params) {
  return request.patch('/album/request/batch/attribute', undefined, {
    params: params
  });
}
/**
 * 批量设置价格
 * @param {Object} params
 * @returns {Promise<*>}
 */


function albumBatchSetPrice(params) {
  return request.patch('/album/request/batch/independentSale', undefined, {
    params: params
  });
}
/**
 * 移动专辑目录
 * @param {Object} params
 * @returns {Promise<*>}
 */


function moveAlbumMenu(params) {
  return request.patch('/menu/request/moveMenu', undefined, {
    params: params
  });
}
/**
 * 获取专辑标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumTitleList(params) {
  return request.get('/album/request/repeat/title', {
    params: params
  });
}
/**
 * 专辑-设置排序
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function updateAlbumSequence(openId, params) {
  return request.patch("/album/request/change/sequence/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateAlbumEncode(resourceOpenId, resourceEncode) {
  return request.patch("/album/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 分页获取专辑商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityAlbumList(params) {
  return request.get('/album/admin', {
    params: params
  });
}
/**
 * 清除专辑内容
 * @param {String} resourceOpenId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function cleanAlbumContent(resourceOpenId, data) {
  return request.patch("/content/clean/content/".concat(resourceOpenId), data);
}
/**
 * 根据openId 查询作品
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getResourceInfoByOpenId(params) {
  return request.get('album/by/openIds', {
    params: params
  });
}
/**
 * 获取浏览量销量
 * @param {String} resourceOpenId
 * @returns {Promise<*>}
 */


function getResourceView(resourceOpenId) {
  return request.get('/album/request/viewsAndSales', {
    params: {
      resourceOpenId: resourceOpenId
    }
  });
}
/**
 * 根据openIds获取作品信息
 * @returns {AxiosPromise<any>}
 */


function getAlbumByOpenIdsList(params) {
  return request.get('/album/by/openIds', {
    params: params
  });
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function albumBatchRevoke(openIds) {
  return request.post('/album/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function albumBatchHidden(openIds) {
  return request.patch('/album/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function albumBatchCancelHidden(openIds) {
  return request.patch('/album/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function albumBatchStopSale(params) {
  return request.patch('/album/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function albumBatchCancelStopSale(openIds) {
  return request.patch('/album/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}