import { render, staticRenderFns } from "./importDotData.vue?vue&type=template&id=4085b3b3&scoped=true&"
import script from "./importDotData.vue?vue&type=script&lang=js&"
export * from "./importDotData.vue?vue&type=script&lang=js&"
import style0 from "./importDotData.vue?vue&type=style&index=0&id=4085b3b3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4085b3b3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4085b3b3')) {
      api.createRecord('4085b3b3', component.options)
    } else {
      api.reload('4085b3b3', component.options)
    }
    module.hot.accept("./importDotData.vue?vue&type=template&id=4085b3b3&scoped=true&", function () {
      api.rerender('4085b3b3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/resource/video/importDotData.vue"
export default component.exports