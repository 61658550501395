"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBigAlbumList = getBigAlbumList;
exports.getBigAlbumResourceList = getBigAlbumResourceList;
exports.getBigAlbumDetail = getBigAlbumDetail;
exports.addBigAlbumInfo = addBigAlbumInfo;
exports.editBigAlbumInfo = editBigAlbumInfo;
exports.bigAlbumToDraft = bigAlbumToDraft;
exports.bigAlbumToDraftById = bigAlbumToDraftById;
exports.bigAlbumToSaveById = bigAlbumToSaveById;
exports.bigAlbumToReview = bigAlbumToReview;
exports.bigAlbumToReviewById = bigAlbumToReviewById;
exports.bigAlbumHidden = bigAlbumHidden;
exports.bigAlbumCancelHidden = bigAlbumCancelHidden;
exports.bigAlbumStopSale = bigAlbumStopSale;
exports.bigAlbumCancelStopSale = bigAlbumCancelStopSale;
exports.bigAlbumReview = bigAlbumReview;
exports.bigAlbumBatchReview = bigAlbumBatchReview;
exports.bigAlbumStateNum = bigAlbumStateNum;
exports.setBigAlbumSequence = setBigAlbumSequence;
exports.bigAlbumCancelSequence = bigAlbumCancelSequence;
exports.bigAlbumRecovery = bigAlbumRecovery;
exports.bigAlbumBatchRecovery = bigAlbumBatchRecovery;
exports.bigAlbumBatchRecycle = bigAlbumBatchRecycle;
exports.delBigAlbum = delBigAlbum;
exports.bigAlbumBatchSetAttribute = bigAlbumBatchSetAttribute;
exports.bigAlbumBatchIndependentSale = bigAlbumBatchIndependentSale;
exports.bigAlbumReviewPass = bigAlbumReviewPass;
exports.bigAlbumSavePublish = bigAlbumSavePublish;
exports.bigAlbumSaveReview = bigAlbumSaveReview;
exports.getBigAlbumContent = getBigAlbumContent;
exports.bigAlbumReject = bigAlbumReject;
exports.bigAlbumBatchRefuse = bigAlbumBatchRefuse;
exports.getBigAlbumTitleList = getBigAlbumTitleList;
exports.submitBigAlbums = submitBigAlbums;
exports.updateBigAlbumEncode = updateBigAlbumEncode;
exports.bigBatchRevoke = bigBatchRevoke;
exports.bigBatchCancelStopSale = bigBatchCancelStopSale;
exports.bigBatchStopSale = bigBatchStopSale;
exports.bigBatchCancelHidden = bigBatchCancelHidden;
exports.bigBatchHidden = bigBatchHidden;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/admin/"));
/**
 * 获取音频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getBigAlbumList(params) {
  return request.get('/big/request/list', {
    params: params
  });
}
/**
 * 获取音频列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBigAlbumResourceList(params) {
  return request.get('/big/list', {
    params: params
  });
}
/**
 * 获取音频详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getBigAlbumDetail(openId) {
  return request.get("/big/request/".concat(openId));
}
/**
 * 增加专辑
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addBigAlbumInfo(data) {
  return request.post('/big/request', data);
}
/**
 * 修改专辑
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editBigAlbumInfo(openId, data) {
  return request.patch("/big/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bigAlbumToDraft(data) {
  return request.post('/big/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bigAlbumToDraftById(openId, data) {
  return request.patch("/big/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bigAlbumToSaveById(openId, data) {
  return request.patch("/big/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function bigAlbumToReview(data) {
  return request.post('/big/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bigAlbumToReviewById(openId, data) {
  return request.patch("/big/request/".concat(openId, "/commit"), data);
}
/**
 * 音频隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumHidden(openId, params) {
  return request.post("/big/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 音频取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumCancelHidden(openId, params) {
  return request.post("/big/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 音频停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumStopSale(openId, params) {
  return request.post("/big/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 音频取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumCancelStopSale(openId, params) {
  return request.post("/big/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 音频-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function bigAlbumReview(openId) {
  return request.post("/big/request/".concat(openId, "/commit"));
}
/**
 * 音频-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function bigAlbumBatchReview(openIds) {
  return request.post('/big/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取不同状态下的音频数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumStateNum(params) {
  return request.get('/big/request/count', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setBigAlbumSequence(openId, params) {
  return request.patch("/big/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function bigAlbumCancelSequence(openId) {
  return request.patch("/big/request/".concat(openId, "/cancelSequence"));
}
/**
 * 音频-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function bigAlbumRecovery(openId) {
  return request.post("/big/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bigAlbumBatchRecovery(openIds) {
  return request.post('/big/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bigAlbumBatchRecycle(openIds) {
  return request.post('/big/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delBigAlbum(openId) {
  return request.delete("/big/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumBatchSetAttribute(params) {
  return request.patch('/big/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumBatchIndependentSale(params) {
  return request.patch('/big/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 音频-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function bigAlbumReviewPass(openId) {
  return request.post("/big/request/".concat(openId, "/review"));
}
/**
 * 音频-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bigAlbumSavePublish(data) {
  return request.post('/big/request/review', data);
}
/**
 * 音频-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function bigAlbumSaveReview(openId, data) {
  return request.patch("/big/request/".concat(openId, "/review"), data);
}
/**
 * 根据专辑openId和menuId查询内容
 * @param {String} id
 * @param {Number} menuId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBigAlbumContent(id, params) {
  return request.get("/content/request/list/".concat(id), {
    params: params
  });
}
/**
 * 音频-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumReject(openId, params) {
  return request.post("/big/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 音频-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigAlbumBatchRefuse(params) {
  return request.post('/big/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBigAlbumTitleList(params) {
  return request.get('/big/request/repeat/title', {
    params: params
  });
}
/**
 * 批量提交审核
 * @param {Object} params
 * @returns {Promise<*>}
 */


function submitBigAlbums(openIds) {
  return request.post('/big/request/commits', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateBigAlbumEncode(resourceOpenId, resourceEncode) {
  return request.patch("/big/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bigBatchRevoke(openIds) {
  return request.post('/big/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bigBatchHidden(openIds) {
  return request.patch('/big/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bigBatchCancelHidden(openIds) {
  return request.patch('/big/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bigBatchStopSale(params) {
  return request.patch('/big/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bigBatchCancelStopSale(openIds) {
  return request.patch('/big/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}