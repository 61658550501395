"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getYesterdayUserLearningData = getYesterdayUserLearningData;
exports.getTodayUserLearningData = getTodayUserLearningData;
exports.getNearlySevenDaysUserLearningData = getNearlySevenDaysUserLearningData;
exports.getMonthUserLearningData = getMonthUserLearningData;
exports.getYearUserLearningData = getYearUserLearningData;
exports.getLearningTotal = getLearningTotal;
exports.getProportionOfProductApplication = getProportionOfProductApplication;
exports.getPercentageOfSection = getPercentageOfSection;
exports.getPercentageOfResourceType = getPercentageOfResourceType;
exports.getProportionOfPartner = getProportionOfPartner;
exports.getStudyRecordStatistics = getStudyRecordStatistics;
exports.getStudyRecordStatisticsAll = getStudyRecordStatisticsAll;
exports.getStudyRecordDirectStatisticsByPartner = getStudyRecordDirectStatisticsByPartner;
exports.getStudyRecordDirectStatisticsByResource = getStudyRecordDirectStatisticsByResource;
exports.getStudyRecordDirectStatisticsByResourceCsv = getStudyRecordDirectStatisticsByResourceCsv;
exports.getStudyRecordDirectStatisticsByPartnerCsv = getStudyRecordDirectStatisticsByPartnerCsv;
exports.getStudyRecordStatisticsAllCsv = getStudyRecordStatisticsAllCsv;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/user-related/admin/"), {
  timeout: 120000
});
/**
 * 获取昨日学习统计数据
 * @returns {Promise<*>}
 */

function getYesterdayUserLearningData() {
  return request.get('/studyRecord/getYesterdayUserLearningData');
}
/**
 * 获取今日学习统计数据
 * @returns {Promise<*>}
 */


function getTodayUserLearningData() {
  return request.get('/studyRecord/getTodayUserLearningData');
}
/**
 * 获取近7日学习统计数据
 * @returns {Promise<*>}
 */


function getNearlySevenDaysUserLearningData() {
  return request.get('/studyRecord/getNearlySevenDaysUserLearningData');
}
/**
 * 获取近30日学习统计数据
 * @returns {Promise<*>}
 */


function getMonthUserLearningData() {
  return request.get('/studyRecord/getMonthUserLearningData');
}
/**
 * 获取近一年学习统计数据
 * @returns {Promise<*>}
 */


function getYearUserLearningData() {
  return request.get('/studyRecord/getYearUserLearningData');
}
/**
 * 获取总的学习统计
 * @returns {Promise<*>}
 */


function getLearningTotal() {
  return request.get('/studyRecord/getTotal');
}
/**
 * 获取产品应用占比
 * @returns {Promise<*>}
 */


function getProportionOfProductApplication(params) {
  return request.get('/studyRecord/getProportionOfProductApplication', {
    params: params
  });
}
/**
 * 获取学段占比
 * @returns {Promise<*>}
 */


function getPercentageOfSection(params) {
  return request.get('/studyRecord/getPercentageOfSection', {
    params: params
  });
}
/**
 * 获取学段占比
 * @returns {Promise<*>}
 */


function getPercentageOfResourceType(params) {
  return request.get('/studyRecord/getPercentageOfResourceType', {
    params: params
  });
}
/**
 * 获取商户占比
 * @returns {Promise<*>}
 */


function getProportionOfPartner(params) {
  return request.get('/studyRecord/getProportionOfPartner', {
    params: params
  });
}
/**
 * 学习记录统计分页
 * @returns {Promise<*>}
 */


function getStudyRecordStatistics(params) {
  return request.get('/studyRecord/getStudyRecordStatistics', {
    params: params
  });
}
/**
 * 学习记录统计折线图
 * @returns {Promise<*>}
 */


function getStudyRecordStatisticsAll(params) {
  return request.get('/studyRecord/getStudyRecordStatisticsAll', {
    params: params
  });
}
/**
 * 学习记录根据商户搜索
 * @returns {Promise<*>}
 */


function getStudyRecordDirectStatisticsByPartner(params) {
  return request.get('/studyRecord/getStudyRecordDirectStatisticsByPartner', {
    params: params
  });
}
/**
 * 学习记录根据作品搜索
 * @returns {Promise<*>}
 */


function getStudyRecordDirectStatisticsByResource(params) {
  return request.get('/studyRecord/getStudyRecordDirectStatisticsByResource', {
    params: params
  });
}
/**
 * d导出学习记录按照时间名称
 * @param params
 * @returns {*}
 */


function getStudyRecordStatisticsAllCsv(params) {
  return request.get('/studyRecord/getStudyRecordStatisticsAllCsv', {
    params: params
  });
}
/**
 * d导出学习记录按照作品名称
 * @param params
 * @returns {*}
 */


function getStudyRecordDirectStatisticsByResourceCsv(params) {
  return request.get('/studyRecord/getStudyRecordDirectStatisticsByResourceCsv', {
    params: params
  });
}
/**
 * d导出学习记录按照商户名称
 * @param params
 * @returns {*}
 */


function getStudyRecordDirectStatisticsByPartnerCsv(params) {
  return request.get('/studyRecord/getStudyRecordDirectStatisticsByPartnerCsv', {
    params: params
  });
}