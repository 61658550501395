var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "900px",
            visible: _vm.visible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": true,
            "before-close": _vm.handleClose,
            "z-index": 1000
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("span", [_vm._v("小程序详情")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table_box table_title_bg table_title_border_start"
                          },
                          [_vm._v("类型")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table_box table_title_bg table_title_border"
                          },
                          [_vm._v("测试版")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table_box table_title_bg table_title_border"
                          },
                          [_vm._v("审核版本")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table_box table_title_bg table_title_border"
                          },
                          [_vm._v("正式版")]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border_start" },
                          [_vm._v("线上版本")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" }, [
                          _vm._v(
                            _vm._s(_vm.showVal(_vm.miniAppInfo.expInfo.version))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: " table_border",
                            staticStyle: {
                              display: "flex",
                              padding: "15px",
                              height: "100%",
                              "flex-direction": "column",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.showVal(
                                      _vm.miniAppInfo.lastInfo.version
                                    )
                                  ) +
                                  "\n                  (\n                  "
                              ),
                              _vm.miniAppInfo.lastInfo.status === "0"
                                ? _c("span", [_vm._v("审核成功")])
                                : _vm.miniAppInfo.lastInfo.status === "1"
                                ? _c("span", [_vm._v("审核被拒绝")])
                                : _vm.miniAppInfo.lastInfo.status === "2"
                                ? _c("span", [_vm._v("审核中")])
                                : _vm.miniAppInfo.lastInfo.status === "3"
                                ? _c("span", [_vm._v("已撤回")])
                                : _vm.miniAppInfo.lastInfo.status === "4"
                                ? _c("span", [_vm._v("审核延后")])
                                : _c("span", [_vm._v("--")]),
                              _vm._v("\n                  )\n                ")
                            ]),
                            _vm._v(" "),
                            _vm.miniAppInfo.lastInfo.reason
                              ? _c("div", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.miniAppInfo.lastInfo.reason) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" }, [
                          _vm._v(
                            _vm._s(
                              _vm.showVal(_vm.miniAppInfo.releaseInfo.version)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border_start" },
                          [_vm._v("版本说明")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" }, [
                          _vm._v(
                            _vm._s(_vm.showVal(_vm.miniAppInfo.expInfo.detail))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" }, [
                          _vm._v(
                            _vm._s(_vm.showVal(_vm.miniAppInfo.lastInfo.detail))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" }, [
                          _vm._v(
                            _vm._s(
                              _vm.showVal(_vm.miniAppInfo.releaseInfo.detail)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border_start" },
                          [_vm._v("小程序码")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border" },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "qrcode_box",
                                staticStyle: { "margin-right": "8px" },
                                attrs: {
                                  fit: "cover",
                                  src: _vm.miniAppInfo.expInfo.getTrialQRCode
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "qrcode_box",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" })
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border" },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "qrcode_box",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    _vm.miniAppInfo.releaseInfo.ossQrcodeUrl
                                  )
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "qrcode_box",
                                      attrs: {
                                        src: require("@/assets/book_cover.png")
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border_start" },
                          [_vm._v("操作")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "table_box table_border" }, [
                          _c("div", [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.codeTemplate = true
                                      }
                                    }
                                  },
                                  [_vm._v("上传代码")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "marginLeft_10",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.submitAuditCode }
                                  },
                                  [_vm._v("提交审核")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.appletSettingVisible = true
                                      }
                                    }
                                  },
                                  [_vm._v("设置密钥")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "marginLeft_10",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.appletDomainWhiteListVisible = true
                                      }
                                    }
                                  },
                                  [_vm._v("设置跳转域名")]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border" },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.withdraw()
                                  }
                                }
                              },
                              [_vm._v("撤销审核")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-link",
                              {
                                staticClass: "marginLeft_10",
                                attrs: { type: "primary" },
                                on: { click: _vm.releaseCode }
                              },
                              [_vm._v("发布版本")]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "table_box table_border" },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.versionRollBack()
                                  }
                                }
                              },
                              [_vm._v("版本回退")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("确定")
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.codeTemplate
        ? _c("select-code-template-dialog", {
            attrs: {
              visible: _vm.codeTemplate,
              "partner-open-id": _vm.partnerOpenId
            },
            on: {
              "update:visible": function($event) {
                _vm.codeTemplate = $event
              },
              success: _vm.getMiniAppInfo
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appletDomainWhiteListVisible
        ? _c("applet-domain-white-list", {
            attrs: {
              visible: _vm.appletDomainWhiteListVisible,
              "partner-open-id": _vm.partnerOpenId
            },
            on: {
              "update:visible": function($event) {
                _vm.appletDomainWhiteListVisible = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appletSettingVisible
        ? _c("applet-setting", {
            attrs: {
              visible: _vm.appletSettingVisible,
              "partner-open-id": _vm.partnerOpenId
            },
            on: {
              "update:visible": function($event) {
                _vm.appletSettingVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }