var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "book-cover": true, "book-cover-a": _vm.item.coverType == 1 } },
    [
      _vm.item.type == 2
        ? _c("el-image", {
            class: {
              "cover-image": true,
              "cover-image-a": _vm.item.coverType == 1
            },
            attrs: {
              src: _vm.handleCoverDisplay(_vm.item, undefined, "cover"),
              fit: "cover"
            },
            on: {
              click: function($event) {
                return _vm.openBookCase(_vm.item)
              }
            }
          })
        : _c("el-image", {
            class: {
              "cover-image": true,
              "cover-image-a": _vm.item.coverType == 1
            },
            attrs: {
              src: _vm.handleCoverDisplay(_vm.item, undefined, "coverDisplay"),
              fit: "cover"
            }
          }),
      _vm._v(" "),
      _c("div", { class: { title: true, "title-2": _vm.item.type == 2 } }, [
        _vm._v(_vm._s(_vm.item.title))
      ]),
      _vm._v(" "),
      _vm.item.coverReferenceType === 2
        ? _c("div", { staticClass: "recommend-title" }, [
            _vm._v(_vm._s(_vm.item.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.directContentNum
        ? _c("div", { staticClass: "number" }, [
            _c("p", [_vm._v(_vm._s(_vm.item.directContentNum))]),
            _vm._v(" "),
            _c("p", [_vm._v("本")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticClass: "close-icon",
        attrs: { src: require("../../../assets/images/cha.png") },
        on: {
          click: function($event) {
            return _vm.deleteBookcase(_vm.item)
          }
        }
      }),
      _vm._v(" "),
      _vm.item.type == 2
        ? _c("div", { staticClass: "book-cover-show" }, [
            _c("div", { staticClass: "book-cover-btns" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn1",
                  on: {
                    click: function($event) {
                      return _vm.editBookcase(_vm.item)
                    }
                  }
                },
                [_vm._v("编辑书橱")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn2",
                  on: {
                    click: function($event) {
                      return _vm.openBookCase(_vm.item)
                    }
                  }
                },
                [_vm._v("进入书橱")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }