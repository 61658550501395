var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.visible,
        "before-close": _vm.close,
        width: "900px",
        title: "模板草稿箱",
        "z-index": 1000
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticStyle: { height: "496px" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                attrs: {
                  data: _vm.dataList,
                  border: "",
                  "header-cell-style": { background: "#F6F7FB" },
                  height: "100%"
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "draft_id", label: "模板ID", width: "100" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "user_version", label: "版本号", width: "100" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "user_desc", label: "版本描述" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addTemplate(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("添加至模板库")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteDraft(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.close }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }