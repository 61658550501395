"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DotDialog = _interopRequireDefault(require("./DotDialog.vue"));

var _common = require("../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'index',
  components: {
    VideoDot: _DotDialog.default
  },
  props: {
    videoMarkEnable: {
      type: Boolean,
      default: false
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    markData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    videoId: {
      type: String,
      default: ''
    },
    videourl: {
      type: String,
      default: ''
    },
    resourceTitle: {
      // 导出lrc 用到的文件名
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dotIsShow: false,
      arrayCatch: [],
      formatLongToTimeStr2: _common.formatLongToTimeStr2
    };
  },
  computed: {
    markList: {
      get: function get() {
        return this.markData;
      },
      set: function set(value) {
        console.log(value);
        this.$emit('update:markData', value);
      }
    }
  },
  watch: {
    markList: function markList(value) {
      this.$emit('getMarkList', value);
    }
  },
  methods: {
    addlistdate: function addlistdate(arr) {
      console.log('arr....');
      console.log(arr);
      this.markList = arr;
    },
    isshow: function isshow(val) {
      this.dotIsShow = val;
    },
    delAllMark: function delAllMark() {
      var _this = this;

      this.$confirm('确定全部移除？').then(function (_) {
        _this.markList = [];
        _this.arrayCatch = [];
      });
    }
  }
};
exports.default = _default2;