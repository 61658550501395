var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blank-audio-box" },
    [
      _c("div", { staticClass: "operation-box" }, [
        _c("div", { staticClass: "operation-tip" }, [
          _vm._v("\n      点学书启动音频\n      ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.audioVid,
                expression: "audioVid"
              }
            ],
            staticClass: "audio-vid",
            on: {
              mouseover: function($event) {
                _vm.hoverArea = "blankAudio"
              },
              mouseout: function($event) {
                _vm.hoverArea = "other"
              }
            }
          },
          [
            _vm._v("\n      " + _vm._s(_vm.audioVid) + "\n      "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hoverArea === "blankAudio" && _vm.canEdit,
                    expression: "hoverArea==='blankAudio'&&canEdit"
                  }
                ],
                staticClass: "operation-btn"
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "operation-icon",
                    attrs: { type: "text", size: "medium" },
                    on: { click: _vm.editBlankAudioVid }
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "option",
                      attrs: { "icon-class": "edit" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("svg-icon", {
                  staticClass: "operation-icon",
                  attrs: {
                    "icon-class": _vm.audioState
                      ? "pause"
                      : "play-circle-outline"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.audioPlay($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c("svg-icon", {
                  staticClass: "operation-icon",
                  attrs: { "icon-class": "file-download" },
                  on: {
                    click: function($event) {
                      return _vm.fileDownHandle(_vm.audioVid)
                    }
                  }
                }),
                _vm._v(" "),
                _c("el-button", {
                  staticClass: "operation-icon",
                  attrs: {
                    type: "text",
                    size: "medium",
                    icon: "el-icon-delete"
                  },
                  on: { click: _vm.deleteBlankAudioVid }
                }),
                _vm._v(" "),
                _c("video", {
                  ref: "audio",
                  attrs: {
                    id: "audio",
                    src: _vm.audioUrl,
                    controls: "controls",
                    hidden: "true"
                  }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.audioVid && _vm.canEdit,
                expression: "!audioVid&&canEdit"
              }
            ],
            staticClass: "add-audio-vid",
            on: { click: _vm.editBlankAudioVid }
          },
          [
            _c("div", { staticClass: "add-audio-but" }, [
              _vm._v("\n        添加音频\n      ")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("upload-audio", {
        ref: "upload_audio_dialog",
        attrs: { "audio-vid": _vm.audioVid, "audio-position": 1 },
        on: {
          "update:audioVid": function($event) {
            _vm.audioVid = $event
          },
          "update:audio-vid": function($event) {
            _vm.audioVid = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }