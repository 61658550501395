"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _page = _interopRequireDefault(require("@/components/BookPageEditor/page.vue"));

var _actions = _interopRequireDefault(require("@/components/BookPageEditor/actions.vue"));

//
//
//
//
//
//
//
var _default = {
  name: 'Editor',
  components: {
    Page: _page.default,
    Actions: _actions.default
  },
  computed: {
    pageId: function pageId() {
      return this.$store.state.book.page.page.id;
    },
    canEdit: function canEdit() {
      return this.$store.state.book.bookCanEdit;
    }
  }
};
exports.default = _default;