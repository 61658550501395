var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.cancelDialog,
            title: "上传发票",
            width: "654px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.initData
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "发票信息", "label-width": "80px" } },
                [
                  _c("div", { staticClass: "invoiceCon" }, [
                    _c("p", [
                      _c("span", { staticClass: "invoiceConTit" }, [
                        _vm._v("单位名称")
                      ]),
                      _c("span", [_vm._v("北京淘知在线教育科技有限公司")])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "invoiceConTit" }, [
                        _vm._v("地址")
                      ]),
                      _c("span", [_vm._v("北京市海淀区彩和坊路8号12层1217")])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "invoiceConTit" }, [
                        _vm._v("统一社会信用代码")
                      ]),
                      _c("span", [_vm._v("91110108MA01QBPA03")])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "invoiceConTit" }, [
                        _vm._v("开户行")
                      ]),
                      _c("span", [_vm._v("交通银行股份有限公司北京天通苑支行")])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "invoiceConTit" }, [
                        _vm._v("账号")
                      ]),
                      _c("span", [_vm._v("110060546018800045906")])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "invoiceConTit" }, [
                        _vm._v("电话")
                      ]),
                      _c("span", [_vm._v("010-61767880")])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "提供发票", "label-width": "80px" } },
                [
                  _c("div", { staticClass: "common-flex" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.selectedInvoice,
                              callback: function($$v) {
                                _vm.selectedInvoice = $$v
                              },
                              expression: "selectedInvoice"
                            }
                          },
                          [_vm._v("电子发票")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.selectedInvoice,
                              callback: function($$v) {
                                _vm.selectedInvoice = $$v
                              },
                              expression: "selectedInvoice"
                            }
                          },
                          [_vm._v("邮寄发票")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "inputInfoText",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.openInvoiceExample }
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon",
                          attrs: { "icon-class": "userInfo" }
                        }),
                        _c("span", { staticClass: "btn" }, [_vm._v("发票样例")])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.selectedInvoice === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "margin-bottom": "15px"
                              }
                            },
                            [
                              _c("invoiceimage", {
                                ref: "invoiceimage",
                                model: {
                                  value: _vm.fileListData,
                                  callback: function($$v) {
                                    _vm.fileListData = $$v
                                  },
                                  expression: "fileListData"
                                }
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: {
                                  color: "#409EFF",
                                  margin: "0 10px"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "可上传 jpg、png、pdf、ofd文件，且大小不超过3M。"
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.fileListData.queue, function(item, index) {
                            return _c("div", { key: index }, [
                              _c("div", { staticClass: "invoceList" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "106px",
                                        height: "60px"
                                      },
                                      attrs: {
                                        src: _vm.getImgUrl(item.filePath),
                                        fit: "cover"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.uploadStatus.SUCCEEDED ===
                                    item.uploadState
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "max-width": "180px",
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              "-webkit-line-clamp": "1",
                                              "white-space": "nowrap"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.fileTitle))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.uploadStatus.UPLOADING ===
                                    item.uploadState
                                      ? _c("el-progress", {
                                          staticStyle: { width: "180px" },
                                          attrs: {
                                            percentage:
                                              item.uploadProgress * 100,
                                            color: _vm.customColor
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.uploadStatus.SUCCEEDED ===
                                    item.uploadState
                                      ? _c("i", {
                                          staticClass: "el-icon-close",
                                          staticStyle: {
                                            display: "inline-block",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeImg(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedInvoice === 2
                    ? _c("div", [
                        _c("div", { staticClass: "invoiceCon" }, [
                          _c("p", [
                            _c("span", { staticClass: "invoiceConTit" }, [
                              _vm._v("邮寄地址")
                            ]),
                            _c("span", [
                              _vm._v("北京市昌平区天通苑东一区13号楼金星教育")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c("span", { staticClass: "invoiceConTit" }, [
                              _vm._v("收件人")
                            ]),
                            _c("span", [_vm._v("于洋")])
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c("span", { staticClass: "invoiceConTit" }, [
                              _vm._v("联系电话")
                            ]),
                            _c("span", [_vm._v("13810485667")])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                              height: "30px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "select-company",
                                    attrs: {
                                      placeholder: "请选择快递",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.invoicePostCompanyId,
                                      callback: function($$v) {
                                        _vm.invoicePostCompanyId = $$v
                                      },
                                      expression: "invoicePostCompanyId"
                                    }
                                  },
                                  _vm._l(_vm.postOptions, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.expName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                "prefix-icon": "el-icon-search",
                                placeholder: "请输入快递单号"
                              },
                              model: {
                                value: _vm.invoiceTrackingNumber,
                                callback: function($$v) {
                                  _vm.invoiceTrackingNumber = $$v
                                },
                                expression: "invoiceTrackingNumber"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancelDialog } },
                [_vm._v("\n        取 消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.fullscreenLoading,
                      expression: "fullscreenLoading",
                      modifiers: { fullscreen: true, lock: true }
                    }
                  ],
                  attrs: {
                    disabled: _vm.isDisable,
                    type: "primary",
                    size: "small"
                  },
                  on: { click: _vm.submitInvoice }
                },
                [_vm._v("\n        确 定\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.invoiceDialog, title: "发票样例" },
          on: {
            "update:visible": function($event) {
              _vm.invoiceDialog = $event
            }
          }
        },
        [
          _c("el-image", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.getFapiao(), fit: "cover" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "invoiceDialogTextBox" }, [
            _c("p", { staticClass: "invoiceDialogText" }, [_vm._v("注意")]),
            _vm._v(" "),
            _c("p", { staticClass: "invoiceDialogText" }, [
              _vm._v(
                "\n        根据《国家税务总局货物和劳务税司关于做好增值税发票使用宣传辅导有关工作的通知》（税总货便函〔2017〕127号）第十二规定，增值税专用发票应按下列要求开具：\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "invoiceDialogText" }, [
              _vm._v("(一) 项目齐全，与实际交易相符；")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "invoiceDialogText" }, [
              _vm._v("(二) 字迹清楚，不得压线、错格；")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "invoiceDialogText" }, [
              _vm._v("(三) 发票联和抵扣联加盖发票专用章；")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "invoiceDialogText" }, [
              _vm._v("(四) 按照增值税纳税义务的发生时间开具。")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "invoiceDialogText" }, [
              _vm._v("不符合上列要求的增值税专用发票，购买方有权拒收。")
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.invoicecashDialog,
            title: "提交发票",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.invoicecashDialog = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/success.png") }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "dialogStatus" }, [_vm._v("提交成功")]),
            _vm._v(" "),
            _c("p", { staticClass: "dialogText" }, [
              _vm._v("发票已提交，财务审核通过后将为您打款。")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialogInfo" },
              [
                _vm._v("\n        如遇问题，可\n        "),
                _c(
                  "el-link",
                  {
                    attrs: {
                      type: "primary",
                      target: "_blank",
                      href: "https://www.taozhi.cn/mq_chat.htm"
                    }
                  },
                  [_vm._v("\n          联系客服\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }