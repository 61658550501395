"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.is-integer");

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.is-nan");

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _anyToInteger = _interopRequireDefault(require("./anyToInteger"));

/**
 * 生成若干时间之后的 date。
 * 包含如下几种用法：
 * 1. 传入一个 date。将会直接返回这个 date。
 * 2. 传入一个数值，将会用当前时间减去这个数值，返回新的 date。
 * 3. 传入一个 undefined，将会返回 undefined。
 *
 * @param {any} time 一个 date 或者毫秒
 * @returns {Date|undefined} 结果
 */
function dateAfterTime(time) {
  try {
    switch ((0, _typeof2.default)(time)) {
      case 'undefined':
        return time;

      case 'function':
        // 计算出结果
        return dateAfterTime(time());

      case 'object':
        // Date
        if (time instanceof Date) {
          return time;
        }

        return dateAfterTime((0, _anyToInteger.default)(time));

      case 'number':
        // NaN 或者 Infinity
        if (Number.isNaN(time) || !Number.isFinite(time)) {
          return dateAfterTime((0, _anyToInteger.default)(time));
        } // float


        if (!Number.isInteger(time)) {
          return dateAfterTime((0, _anyToInteger.default)(time));
        }

        return new Date(new Date().getTime() + time);

      default:
        // 格式化数值
        return dateAfterTime((0, _anyToInteger.default)(time));
    }
  } catch (e) {
    console.warn(e && e.message);
    return undefined;
  }
}

var _default = dateAfterTime;
exports.default = _default;