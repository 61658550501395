var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "image-text-detail"
    },
    [
      _c(
        "div",
        { staticClass: "image-txt-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.realCommodity,
                rules: _vm.rules,
                "label-width": "80px",
                "status-icon": ""
              }
            },
            [
              _c("div", { staticClass: "content-form" }, [
                _c("div", { staticClass: "content-form-inner" }, [
                  _c("div", { staticClass: "title-text" }, [
                    _vm._v("\n            基本信息\n            "),
                    _vm.openId && _vm.realCommodity.state
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.realCommodity[
                                        _vm.statusList[0].dateName
                                      ]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.realCommodity[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.realCommodity.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.realCommodity.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-information" },
                    [
                      _vm.realCommodity.resourceOpenId
                        ? _c("el-form-item", { attrs: { label: "作品ID" } }, [
                            _vm.realCommodity.resourceOpenId
                              ? _c("div", { staticClass: "title-id" }, [
                                  _vm._v(
                                    _vm._s(_vm.realCommodity.resourceOpenId)
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称", prop: "title" } },
                        [
                          _c("el-autocomplete", {
                            staticClass: "title-input",
                            attrs: {
                              disabled: _vm.allowEdit,
                              "show-word-limit": "",
                              maxlength: 45,
                              debounce: 500,
                              placeholder: "请填写商品名称",
                              "fetch-suggestions": _vm.searchTitle
                            },
                            model: {
                              value: _vm.realCommodity.title,
                              callback: function($$v) {
                                _vm.$set(_vm.realCommodity, "title", $$v)
                              },
                              expression: "realCommodity.title"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品封面", prop: "cover" } },
                        [
                          _c("cover-upload", {
                            attrs: {
                              "partner-open-id": _vm.partnerOpenId,
                              disabledEdit: _vm.allowEdit,
                              path: _vm.path,
                              type: _vm.REAL_COMMONDITY_ID.toString(),
                              coverarr: _vm.coverArr,
                              covertext: _vm.coverText
                            },
                            on: { deletecover: _vm.deleteCoverFun },
                            model: {
                              value: _vm.realCommodity.cover,
                              callback: function($$v) {
                                _vm.$set(_vm.realCommodity, "cover", $$v)
                              },
                              expression: "realCommodity.cover"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            model: {
                              value: _vm.realCommodity.cover,
                              callback: function($$v) {
                                _vm.$set(_vm.realCommodity, "cover", $$v)
                              },
                              expression: "realCommodity.cover"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品描述" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              disabled: _vm.allowEdit,
                              placeholder: "请填写图文描述"
                            },
                            model: {
                              value: _vm.realCommodity.commodityDescribe,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.realCommodity,
                                  "commodityDescribe",
                                  $$v
                                )
                              },
                              expression: "realCommodity.commodityDescribe"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情" } },
                        [
                          _c("editor", {
                            attrs: { readonly: _vm.allowEdit },
                            model: {
                              value: _vm.realCommodity.detail,
                              callback: function($$v) {
                                _vm.$set(_vm.realCommodity, "detail", $$v)
                              },
                              expression: "realCommodity.detail"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品图集", prop: "imageList" } },
                        [
                          _c("photo-wall", {
                            attrs: {
                              path: "image/",
                              disabledEdit: _vm.allowEdit,
                              imageArr: _vm.imageList,
                              coverArr: _vm.coverArr,
                              atlasArrText: _vm.atlasArr,
                              isDisableUpload: _vm.imageList.length >= 5,
                              resourceType: _vm.REAL_COMMONDITY_ID.toString()
                            },
                            on: {
                              deletedImage: _vm.deletedImage,
                              replaceImage: _vm.replaceImage,
                              getImage: _vm.getImage
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-work" },
                    [
                      _c("div", { staticClass: "title-text" }, [
                        _vm._v("商品属性")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "分类", prop: "types" } },
                        [
                          _c("works", {
                            ref: "worksView",
                            attrs: { isDisabled: _vm.allowEdit },
                            on: { "add-classtion": _vm.addClass }
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: { value: _vm.realCommodity.types }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品编号" } },
                        [
                          _c("el-input", {
                            staticClass: "txt-width850",
                            attrs: {
                              disabled: _vm.allowEdit,
                              placeholder: "请输入商品编号",
                              maxlength: "50",
                              "show-word-limit": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.realCommodity.resourceEncode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.realCommodity,
                                  "resourceEncode",
                                  $$v
                                )
                              },
                              expression: "realCommodity.resourceEncode"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "content-work-tags",
                              attrs: {
                                disabled: _vm.allowEdit,
                                multiple: "",
                                filterable: "",
                                "allow-create": "",
                                "default-first-option": "",
                                placeholder: "请输入或选择商品标签"
                              },
                              on: { change: _vm.changeTags },
                              model: {
                                value: _vm.tagValue,
                                callback: function($$v) {
                                  _vm.tagValue = $$v
                                },
                                expression: "tagValue"
                              }
                            },
                            _vm._l(_vm.tagList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "property-box" },
                        [
                          _c("el-collapse-transition", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isShowProperty,
                                    expression: "isShowProperty"
                                  }
                                ],
                                staticClass: "property-item-bg"
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "同步" } },
                                  [
                                    _c("sync-view", {
                                      attrs: { isDisabled: _vm.allowEdit },
                                      model: {
                                        value: _vm.realCommodity.tempCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.realCommodity,
                                            "tempCode",
                                            $$v
                                          )
                                        },
                                        expression: "realCommodity.tempCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "类目",
                                      prop: "secondRegimentationIds"
                                    }
                                  },
                                  [
                                    _c("category-view", {
                                      attrs: { "is-disabled": _vm.allowEdit },
                                      on: {
                                        "category-change": _vm.categoryChange
                                      },
                                      model: {
                                        value:
                                          _vm.realCommodity
                                            .secondRegimentationIds,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.realCommodity,
                                            "secondRegimentationIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "realCommodity.secondRegimentationIds"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false"
                                        }
                                      ],
                                      model: {
                                        value:
                                          _vm.realCommodity
                                            .secondRegimentationIds,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.realCommodity,
                                            "secondRegimentationIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "realCommodity.secondRegimentationIds"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "版次" } },
                                  [
                                    _c("edition-box", {
                                      attrs: { isDisabled: _vm.allowEdit },
                                      model: {
                                        value: _vm.realCommodity.periodCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.realCommodity,
                                            "periodCode",
                                            $$v
                                          )
                                        },
                                        expression: "realCommodity.periodCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "地区" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "850px" },
                                        attrs: {
                                          disabled: _vm.allowEdit,
                                          multiple: "",
                                          filterable: "",
                                          "default-first-option": "",
                                          placeholder: "请输入或选择地区"
                                        },
                                        model: {
                                          value: _vm.areaValue,
                                          callback: function($$v) {
                                            _vm.areaValue = $$v
                                          },
                                          expression: "areaValue"
                                        }
                                      },
                                      _vm._l(_vm.areaList, function(item) {
                                        return _c("el-option", {
                                          key: item.code,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            class: [
                              "property-item-arrow",
                              _vm.isShowProperty
                                ? "el-icon-arrow-up"
                                : "el-icon-arrow-down"
                            ],
                            on: {
                              click: function($event) {
                                _vm.isShowProperty = !_vm.isShowProperty
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-commodity" },
                    [
                      _c("div", { staticClass: "title-text" }, [
                        _vm._v("商品设置")
                      ]),
                      _vm._v(" "),
                      _c("goods-set", {
                        ref: "commodity",
                        attrs: {
                          "type-name": _vm.typeName,
                          partnerOpenId: _vm.partnerOpenId,
                          disabledEdit: _vm.allowEdit,
                          "resource-open-id": _vm.openId,
                          goodsType: _vm.goodsType,
                          "independent-sale": _vm.realCommodity.independentSale
                        },
                        on: { "official-partner": _vm.getOfficialPartner }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content-footer" },
                [
                  _c("works-operation-button", {
                    attrs: {
                      openId: _vm.openId,
                      allowEdit: _vm.allowEdit,
                      operation: _vm.operation,
                      status: _vm.realCommodity.state,
                      passBtn: _vm.passBtn,
                      refuseBtn: _vm.refuseBtn,
                      restoreBtn: _vm.restoreBtn,
                      publishBtn: _vm.publishBtn,
                      saveBtn: _vm.saveBtn,
                      typeName: _vm.typeName,
                      saveDraftBtn: _vm.saveDraftBtn
                    },
                    on: {
                      closeView: _vm.closeView,
                      updateEdit: _vm.updateEdit,
                      passItem: _vm.passItem,
                      handelRefuse: _vm.handelRefuse,
                      handelSaveAndReview: _vm.handelSaveAndReview,
                      handleBookRestore: _vm.handleBookRestore,
                      saveData: _vm.saveData,
                      saveDraft: _vm.saveDraft
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }