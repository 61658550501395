var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "audio-wrapper"
    },
    [
      _c(
        "div",
        { staticClass: "audio-wrapper-bg" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.followBookInfo,
                rules: _vm.rules,
                "status-icon": "",
                "label-width": "80px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-audio-wrapper" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          基本信息\n          "),
                    _vm.openId && _vm.followBookInfo.state
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.followBookInfo[
                                        _vm.statusList[0].dateName
                                      ]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.followBookInfo[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.followBookInfo.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.followBookInfo.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.followBookInfo.resourceOpenId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "作品ID", prop: "" } },
                        [
                          _vm.followBookInfo.resourceOpenId
                            ? _c("div", { staticClass: "title-id" }, [
                                _vm._v(
                                  _vm._s(_vm.followBookInfo.resourceOpenId)
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会话名称", prop: "title" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "850px" },
                        attrs: {
                          disabled: _vm.allowEdit,
                          "show-word-limit": "",
                          maxlength: 100,
                          debounce: 500,
                          placeholder: "请输入英语会话作品名称",
                          "fetch-suggestions": _vm.searchTitle
                        },
                        model: {
                          value: _vm.followBookInfo.title,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "title", $$v)
                          },
                          expression: "followBookInfo.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品编号" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          placeholder: "请输入作品编号",
                          disabled: _vm.allowEdit,
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.followBookInfo.resourceEncode,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "resourceEncode", $$v)
                          },
                          expression: "followBookInfo.resourceEncode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会话封面" } },
                    [
                      _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: _vm.FOLLOW_BOOK_ID.toString(),
                          disabledEdit: _vm.allowEdit
                        },
                        on: { deletecover: _vm.deletecoverFun },
                        model: {
                          value: _vm.followBookInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "cover", $$v)
                          },
                          expression: "followBookInfo.cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.followBookInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "cover", $$v)
                          },
                          expression: "followBookInfo.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          会话设置\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeRadio },
                          model: {
                            value: _vm.radio,
                            callback: function($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.allowEdit, label: 0 } },
                            [_vm._v("无")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.allowEdit, label: 1 } },
                            [_vm._v("单人")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.allowEdit, label: 2 } },
                            [_vm._v("多人")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.radio > 0
                    ? _c("el-form-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                class: _vm.allowEdit
                                  ? "role-list btn-disabel"
                                  : "role-list",
                                on: {
                                  click: function($event) {
                                    return _vm.showRoleDialog()
                                  }
                                }
                              },
                              [
                                _vm.checkRole.length <= 0 ||
                                (_vm.checkRole.length === 2 &&
                                  _vm.checkRole[0].id === 9999 &&
                                    _vm.checkRole[1].id === 9998)
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "line-height": "28px",
                                          "margin-top": "4px"
                                        }
                                      },
                                      [_vm._v("请选择")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.checkRole, function(item, index) {
                                  return item.id !== 9999 && item.id !== 9998
                                    ? _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "role-item",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.roleName))
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            class: _vm.allowEdit
                                              ? "el-icon-circle-close btn-disabel"
                                              : "el-icon-circle-close",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.removeRole(index)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            !_vm.allowEdit
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "add-btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.showRoleDialog()
                                      }
                                    }
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.allowEdit
                              ? _c(
                                  "span",
                                  { staticClass: "add-btn add-btn-disabled" },
                                  [_vm._v("添加")]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "会话内容" } }, [
                    _c(
                      "div",
                      { staticClass: "txt-width850 follow-content" },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "table",
                            staticStyle: { width: "100%" },
                            attrs: {
                              "header-row-style": { height: "30px" },
                              "header-cell-style": { background: "#F5F7FA" },
                              data: _vm.attachmentList,
                              "row-style": { height: "44px" },
                              "row-class-name": _vm.tableRowClass,
                              border: ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "sequence",
                                label: "#",
                                align: "center",
                                width: "50"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "content",
                                label: "内容",
                                resizable: false,
                                "min-width": "180"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input", {
                                        staticClass: "content-ipt",
                                        attrs: {
                                          type: "textarea",
                                          resize: "none",
                                          autosize: { minRows: 1 },
                                          placeholder: "请输入会话内容",
                                          disabled: _vm.allowEdit
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.contentBlur(scope.$index)
                                          }
                                        },
                                        model: {
                                          value: scope.row.sentence,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "sentence", $$v)
                                          },
                                          expression: "scope.row.sentence"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "translate",
                                label: "内容翻译",
                                resizable: false,
                                "min-width": "180"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-input", {
                                        staticClass: "content-ipt",
                                        attrs: {
                                          type: "textarea",
                                          resize: "none",
                                          autosize: { minRows: 1 },
                                          placeholder: "请输入内容翻译",
                                          disabled: _vm.allowEdit
                                        },
                                        model: {
                                          value: scope.row.sentenceZhCn,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sentenceZhCn",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sentenceZhCn"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "role",
                                label: "发言人",
                                resizable: false,
                                width: "120"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "labek-select",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.allowEdit
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.selectChange(
                                                _vm.attachmentList[
                                                  scope.$index
                                                ],
                                                scope.$index
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.attachmentList[scope.$index]
                                                .roleId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.attachmentList[
                                                  scope.$index
                                                ],
                                                "roleId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "attachmentList[scope.$index].roleId"
                                          }
                                        },
                                        _vm._l(_vm.checkRole, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.roleName,
                                              value: item.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "audio",
                                label: "音频文件",
                                resizable: false,
                                width: "270"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "content-uploader" },
                                        [
                                          scope.row.pronunciation
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  _c("audio", {
                                                    ref: "audio",
                                                    staticStyle: {
                                                      width: "225px",
                                                      height: "30px",
                                                      "margin-right": "10px"
                                                    },
                                                    attrs: {
                                                      id: "audio",
                                                      src:
                                                        "https://v2.taozhi.online/" +
                                                        scope.row.pronunciation,
                                                      controls: "controls"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete delete-button",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteAudio(
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !scope.row.pronunciation &&
                                          !_vm.allowEdit
                                            ? _c(
                                                "div",
                                                {
                                                  class: scope.row.uploadState
                                                    ? "content-cell upload-ing"
                                                    : "content-cell"
                                                },
                                                [
                                                  _c("upload-file", {
                                                    ref: "uploadAudio",
                                                    attrs: {
                                                      type: _vm.audioType,
                                                      "audio-id":
                                                        scope.row.audioAliVid
                                                    },
                                                    on: {
                                                      startUpload: function(
                                                        $event
                                                      ) {
                                                        return _vm.startUpload(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      },
                                                      getFollowResult: function(
                                                        $event
                                                      ) {
                                                        return _vm.getUploader(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !scope.row.pronunciation &&
                                          _vm.allowEdit
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled: _vm.allowEdit
                                                  }
                                                },
                                                [_vm._v("点击上传")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                resizable: false,
                                width: "50"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            color: "rgb(245, 108, 108)"
                                          },
                                          attrs: {
                                            disabled: _vm.allowEdit,
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.delRow(scope.$index)
                                            }
                                          }
                                        },
                                        [_vm._v("移除")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addContentCell }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "场景图片" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.allowEdit
                          },
                          model: {
                            value: _vm.backgroundImageChecked,
                            callback: function($$v) {
                              _vm.backgroundImageChecked = $$v
                            },
                            expression: "backgroundImageChecked"
                          }
                        },
                        _vm._l(_vm.backgroundImage, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "preview-bgimg" }, [
                        _c(
                          "span",
                          {
                            staticClass: "add-btn",
                            on: {
                              mouseover: function($event) {
                                _vm.showPreview = true
                              },
                              mouseout: function($event) {
                                _vm.showPreview = false
                              }
                            }
                          },
                          [_vm._v("场景预览")]
                        ),
                        _vm._v(" "),
                        _vm.showPreview
                          ? _c("div", { staticClass: "preview-view" }, [
                              _c("img", {
                                attrs: { src: _vm.backgroundImageChecked }
                              })
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("作品属性")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同步" } },
                    [
                      _c("sync-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.followBookInfo.tempCode,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "tempCode", $$v)
                          },
                          expression: "followBookInfo.tempCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "types" } },
                    [
                      _c("works", {
                        ref: "worksView",
                        attrs: { isDisabled: _vm.allowEdit },
                        on: { "add-classtion": _vm.addClass }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { value: _vm.followBookInfo.types }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版次" } },
                    [
                      _c("edition-box", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.followBookInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "periodCode", $$v)
                          },
                          expression: "followBookInfo.periodCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.followBookInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.followBookInfo, "periodCode", $$v)
                          },
                          expression: "followBookInfo.periodCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "类目", prop: "secondRegimentationIds" }
                    },
                    [
                      _c("category-view", {
                        staticStyle: { width: "850px" },
                        attrs: { "is-disabled": _vm.allowEdit },
                        on: { "category-change": _vm.categoryChange },
                        model: {
                          value: _vm.followBookInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.followBookInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "followBookInfo.secondRegimentationIds"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.followBookInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.followBookInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "followBookInfo.secondRegimentationIds"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "property-box" },
                    [
                      _c("el-collapse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowProperty,
                                expression: "isShowProperty"
                              }
                            ],
                            staticClass: "property-item-bg"
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地区" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择地区"
                                    },
                                    model: {
                                      value: _vm.areaValue,
                                      callback: function($$v) {
                                        _vm.areaValue = $$v
                                      },
                                      expression: "areaValue"
                                    }
                                  },
                                  _vm._l(_vm.areaList, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择商品标签"
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.tagValue,
                                      callback: function($$v) {
                                        _vm.tagValue = $$v
                                      },
                                      expression: "tagValue"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        class: [
                          "property-item-arrow",
                          _vm.isShowProperty
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        ],
                        on: {
                          click: function($event) {
                            _vm.isShowProperty = !_vm.isShowProperty
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("商品设置")]),
                  _vm._v(" "),
                  _c("goods-set", {
                    ref: "commodity",
                    attrs: {
                      "type-name": _vm.typeName,
                      partnerOpenId: _vm.partnerOpenId,
                      "independent-sale": _vm.followBookInfo.independentSale,
                      disabledEdit: _vm.allowEdit,
                      "resource-open-id": _vm.openId
                    },
                    on: { "official-partner": _vm.getOfficialPartner }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("works-operation-buttton", {
                attrs: {
                  openId: _vm.openId,
                  allowEdit: _vm.allowEdit,
                  operation: _vm.operation,
                  status: _vm.followBookInfo.state,
                  passBtn: _vm.passBtn,
                  refuseBtn: _vm.refuseBtn,
                  restoreBtn: _vm.restoreBtn,
                  publishBtn: _vm.publishBtn,
                  saveBtn: _vm.saveBtn,
                  typeName: _vm.typeName,
                  saveDraftBtn: _vm.saveDraftBtn
                },
                on: {
                  closeView: _vm.closeView,
                  updateEdit: _vm.updateEdit,
                  passItem: _vm.passItem,
                  handelRefuse: _vm.handelRefuse,
                  handelSaveAndReview: _vm.handelSaveAndReview,
                  handleBookRestore: _vm.handleBookRestore,
                  saveData: _vm.saveData,
                  saveDraft: _vm.saveDraft
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("role-list-dialog", {
        attrs: {
          roleNum: _vm.radio,
          visible: _vm.roleListShow,
          partnerOpenId: _vm.partnerOpenId,
          checkRoleList: _vm.checkRole,
          list: _vm.attachmentList
        },
        on: {
          "update:visible": function($event) {
            _vm.roleListShow = $event
          },
          roleListDialogClose: _vm.roleListDialogClose,
          addRole: _vm.addRole,
          getCheckRole: _vm.getCheckRole,
          changeRole: _vm.changeRole
        }
      }),
      _vm._v(" "),
      _c("add-role-view", {
        attrs: {
          visible: _vm.addRoleShow,
          roleItem: _vm.roleItem,
          partnerOpenId: _vm.partnerOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.addRoleShow = $event
          },
          addRoleDialogClose: _vm.addRoleDialogClose
        }
      }),
      _vm._v(" "),
      _c("add-follow-book-content", {
        attrs: {
          visible: _vm.contentShow,
          contentItem: _vm.contentItem,
          contentIndex: _vm.contentIndex,
          roleList: _vm.checkRole
        },
        on: {
          "update:visible": function($event) {
            _vm.contentShow = $event
          },
          contentClose: _vm.contentClose,
          addContent: _vm.addContent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }