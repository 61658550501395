var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity" },
    [
      _c("cover-display", {
        staticClass: "cover",
        attrs: {
          disabledEdit: true,
          value: _vm.commodity.cover,
          type: "云零售商品",
          covertext: "",
          coverarr: ["36", "36"],
          texttips: "无封面",
          "preview-z-index": 3000
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "title", attrs: { title: _vm.commodity.title } }, [
        _vm._v(_vm._s(_vm.commodity.title))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }