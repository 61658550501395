"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _detailTeacher = _interopRequireDefault(require("./detailTeacher.vue"));

//
//
//
//
//
//
var _default = {
  name: 'AddTeacher',
  components: {
    detailTeacher: _detailTeacher.default
  }
};
exports.default = _default;