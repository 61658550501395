var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outermostLayer" },
    [
      _c("div", { staticClass: "contailer" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addCategory } },
              [_vm._v("添加")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  "row-key": "id",
                  lazy: "",
                  load: _vm.load,
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "类目名称", "min-width": "15" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "id", label: "ID", "min-width": "5" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "sectionName",
                    label: "所属学段",
                    "min-width": "10"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "gradeName",
                    label: "所属年级",
                    "min-width": "35"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "volumeName",
                    label: "所属阶段",
                    "min-width": "15"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "产品应用", "min-width": "15" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.showVal(
                                  _vm.showApppro(scope.row.appProductOpenIds)
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "排序", "min-width": "5" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.sequence))])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "15", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editCategory(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "removeBtn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.removeCategory(scope.row)
                                }
                              }
                            },
                            [_vm._v("移除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.addRegimentationDialog,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addRegimentationDialog = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialogContailer" }, [
            _c(
              "div",
              { staticClass: "dialogBody" },
              [
                _c(
                  "el-form",
                  {
                    ref: "categoryForm",
                    attrs: {
                      model: _vm.categoryForm,
                      "label-width": "80px",
                      rules: _vm.rules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "类目名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入类目名称" },
                          model: {
                            value: _vm.categoryForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.categoryForm, "name", $$v)
                            },
                            expression: "categoryForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "类目图片" } },
                      [
                        _c("coverupload", {
                          attrs: {
                            coverisshow: false,
                            covertext: _vm.covertextFeng,
                            coverarr: _vm.coverarr,
                            path: _vm.categoryManagePath,
                            texttips: _vm.texttipsDown,
                            type: "类目管理"
                          },
                          on: { deletecover: _vm.deletecoverFeng },
                          model: {
                            value: _vm.categoryForm.cover,
                            callback: function($$v) {
                              _vm.$set(_vm.categoryForm, "cover", $$v)
                            },
                            expression: "categoryForm.cover"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属类目" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择所属类目" },
                            model: {
                              value: _vm.categoryForm.parentId,
                              callback: function($$v) {
                                _vm.$set(_vm.categoryForm, "parentId", $$v)
                              },
                              expression: "categoryForm.parentId"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                disabled: _vm.haveSon,
                                label: "/",
                                value: null
                              }
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.tableData, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  disabled: _vm.haveSon,
                                  label: item.name,
                                  value: item.id
                                }
                              })
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "应用产品" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { multiple: "" },
                            model: {
                              value: _vm.categoryForm.appProductOpenIds,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.categoryForm,
                                  "appProductOpenIds",
                                  $$v
                                )
                              },
                              expression: "categoryForm.appProductOpenIds"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "auto",
                                  height: "200px"
                                }
                              },
                              _vm._l(_vm.appliedproductsOptions, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.openId
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属学段", prop: "sectionCodes" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.categoryForm.sectionCodes,
                              callback: function($$v) {
                                _vm.$set(_vm.categoryForm, "sectionCodes", $$v)
                              },
                              expression: "categoryForm.sectionCodes"
                            }
                          },
                          _vm._l(_vm.syncSectionInfo, function(item) {
                            return _c(
                              "el-checkbox",
                              {
                                key: item.code,
                                attrs: {
                                  disabled:
                                    _vm.parentInfoById.sectionCodes &&
                                    _vm.parentInfoById.sectionCodes.indexOf(
                                      item.code
                                    ) === -1,
                                  label: item.code,
                                  name: "sectionCodes"
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属年级", prop: "gradeCodes" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.categoryForm.gradeCodes,
                              callback: function($$v) {
                                _vm.$set(_vm.categoryForm, "gradeCodes", $$v)
                              },
                              expression: "categoryForm.gradeCodes"
                            }
                          },
                          _vm._l(_vm.syncGrade, function(item) {
                            return _c(
                              "el-checkbox",
                              {
                                key: item.code,
                                attrs: {
                                  disabled:
                                    _vm.parentInfoById.gradeCodes &&
                                    _vm.parentInfoById.gradeCodes.indexOf(
                                      item.code
                                    ) === -1,
                                  label: item.code,
                                  name: "gradeCodes"
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属阶段", prop: "volumeCodes" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.categoryForm.volumeCodes,
                              callback: function($$v) {
                                _vm.$set(_vm.categoryForm, "volumeCodes", $$v)
                              },
                              expression: "categoryForm.volumeCodes"
                            }
                          },
                          _vm._l(_vm.syncVolume, function(item) {
                            return _c(
                              "el-checkbox",
                              {
                                key: item.code,
                                attrs: {
                                  disabled:
                                    _vm.parentInfoById.volumeCodes &&
                                    _vm.parentInfoById.volumeCodes.indexOf(
                                      item.code
                                    ) === -1,
                                  label: item.code,
                                  name: "volumeCodes"
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "排序", prop: "volumeCodes" } },
                      [
                        _c("div", { staticClass: "dialogFoot" }, [
                          _c(
                            "div",
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "120px" },
                                attrs: { "controls-position": "right" },
                                model: {
                                  value: _vm.categoryForm.sequence,
                                  callback: function($$v) {
                                    _vm.$set(_vm.categoryForm, "sequence", $$v)
                                  },
                                  expression: "categoryForm.sequence"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "数字越大，排位越靠前",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "describeInfo" }, [
                                    _c("i", { staticClass: "el-icon-info" })
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.addRegimentationDialog = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.editRegimentationInfo }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }