var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "part" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "content"
      },
      [
        _c(
          "div",
          { staticClass: "nav" },
          [
            !_vm.onlyRead
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.createBrand = true
                      }
                    }
                  },
                  [_vm._v("添加")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.onlyRead ? _c("div") : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "filter-group" }, [
              _c(
                "div",
                { staticClass: "search-group" },
                [
                  _c("el-input", {
                    staticClass: "header-search",
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "请输入品牌方名称",
                      clearable: ""
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList($event)
                      }
                    },
                    model: {
                      value: _vm.query.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "keyword", $$v)
                      },
                      expression: "query.keyword"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "el-button el-button--primary",
                      attrs: { type: "button" },
                      on: { click: _vm.updateList }
                    },
                    [_c("span", [_vm._v("搜索")])]
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("brand-side-list", {
          ref: "brandSideList",
          attrs: { "only-read": _vm.onlyRead },
          on: {
            onUpdateBrand: _vm.onUpdateBrand,
            onListChanged: _vm.updateList
          },
          model: {
            value: _vm.list,
            callback: function($$v) {
              _vm.list = $$v
            },
            expression: "list"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footPage" },
          [
            _c("pagination", {
              attrs: {
                total: _vm.pagination.total,
                page: _vm.query.page,
                limit: _vm.query.size
              },
              on: {
                "update:page": function($event) {
                  return _vm.$set(_vm.query, "page", $event)
                },
                "update:limit": function($event) {
                  return _vm.$set(_vm.query, "size", $event)
                },
                pagination: _vm.onPageChanged
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialogs" },
      [
        _c("brand-side-create", {
          on: { onListChanged: _vm.updateList },
          model: {
            value: _vm.createBrand,
            callback: function($$v) {
              _vm.createBrand = $$v
            },
            expression: "createBrand"
          }
        }),
        _vm._v(" "),
        _c("brand-side-update", {
          on: { onListChanged: _vm.updateList },
          model: {
            value: _vm.updateBrand,
            callback: function($$v) {
              _vm.updateBrand = $$v
            },
            expression: "updateBrand"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }