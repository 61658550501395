var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: { fullscreen: true }
          }
        ],
        staticClass: "operation-wrapper-box",
        attrs: {
          "element-loading-text": "数据保存中...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 0.8)"
        }
      },
      [
        _c("div", { staticClass: "tag-wrapper" }, [
          _c(
            "div",
            { staticClass: "operation-wrapper-tag" },
            [
              _vm.resourceObj.resourceType === 2 && _vm.resourceObj.download
                ? _c(
                    "el-tag",
                    { staticClass: "audio-download-tag publishedExist-bg" },
                    [
                      _c("i", {
                        staticClass: "el-icon-download audio-download-icon"
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj.testData &&
              _vm.testDataType.includes(_vm.resourceObj.resourceType)
                ? _c(
                    "el-tag",
                    { staticClass: "tag-sequence publishedExist-bg" },
                    [_vm._v("拓展")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isShowJsonData
                ? _c(
                    "el-tag",
                    { staticClass: "tag-sequence publishedExist-bg" },
                    [_vm._v("附件")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj.recommendSequence
                ? _c(
                    "el-tag",
                    {
                      staticClass: "tag-sequence publishedExist-bg",
                      attrs: { type: "danger" }
                    },
                    [_vm._v("推荐")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj.publishedExist
                ? _c(
                    "div",
                    { staticClass: "publishedExist-bg" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "发布时间：" + _vm.resourceObj.publishedAt,
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-publishedExist",
                              attrs: { type: "success", plain: "" }
                            },
                            [_vm._v("存在已发布版本")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj.resourceType === _vm.ALBUM_ID
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-sequence",
                      attrs: { size: "mini" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleSetSequence()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.resourceObj.sequence))]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.resourceObj.resourceType === _vm.REAL_COMMONDITY_ID
            ? _c("div", { staticClass: "sales-bg" }, [
                _vm._v(
                  "销量：" +
                    _vm._s(_vm.resourceObj.sales ? _vm.resourceObj.sales : 0) +
                    "/" +
                    _vm._s(
                      _vm.resourceObj.inventory ? _vm.resourceObj.inventory : 0
                    )
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "operation-wrapper-btns" }, [
          _c(
            "div",
            { staticClass: "object-check-wrapper" },
            [
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] === _vm.RESOURCE_REVIEW_STATE
                ? _c(
                    "el-button",
                    {
                      staticClass: "txt-sucess data-operation-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.passObjFun(_vm.resourceObj.resourceOpenId)
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "通过",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-tongguo icon-pass"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] === _vm.RESOURCE_REVIEW_STATE
                ? _c(
                    "el-button",
                    {
                      staticClass: "txt-danger btn-reject data-operation-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleRefuseObj(
                            _vm.resourceObj.resourceOpenId
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "驳回",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-bohui icon-reject"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] === _vm.RESOURCE_DRAFT_STATE ||
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] === _vm.RESOURCE_REFUSED_STATE
                ? _c(
                    "el-button",
                    {
                      staticClass: "txt-sucess btn-publish data-operation-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.passObjFun(_vm.resourceObj.resourceOpenId)
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "发布",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-fabu" })]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "object-operation-bg" },
            [
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] !== _vm.RESOURCE_DELETED_STATE &&
              !_vm.resourceObj.recommendSequence
                ? _c(
                    "el-button",
                    {
                      staticClass: "data-operation-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleRecommend($event)
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "推荐",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-quxiaotuijian"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] !== _vm.RESOURCE_DELETED_STATE &&
              _vm.resourceObj.recommendSequence
                ? _c(
                    "el-button",
                    {
                      staticClass: "data-operation-btn btn-cancel-recommend",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.cancelRecommendFun(
                            _vm.resourceObj.resourceOpenId
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "取消推荐",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-tuijian" })]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "data-operation-btn btn-preview",
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.showSharePreview(_vm.resourceObj)
                    }
                  }
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: "预览",
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "iconfont icon-yulan" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              [_vm.SINGLE_FILE_ID, _vm.AUDIO_ID].includes(
                _vm.resourceObj.resourceType
              )
                ? _c(
                    "el-button",
                    {
                      staticClass: "download-operation-btn",
                      attrs: {
                        slot: "reference",
                        disabled:
                          _vm.isDownLoad ||
                          (_vm.resourceObj.resourceType === _vm.AUDIO_ID &&
                            !_vm.resourceObj.download),
                        type: "text"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleDownLoadFile(_vm.resourceObj)
                        }
                      },
                      slot: "reference"
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "下载",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-download download-icon"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "data-operation-btn btn-preview",
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.showOperationPreview(_vm.resourceObj)
                    }
                  }
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: "操作日志",
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "iconfont icon-caozuojilu" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "data-operation-btn",
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.showPage(_vm.resourceObj.resourceOpenId)
                    }
                  }
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: "编辑",
                        effect: "dark",
                        placement: "top"
                      }
                    },
                    [_c("i", { staticClass: "iconfont icon-xiangqing" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.resourceObj.resourceType === _vm.AUDIOBOOK_ID
                ? _c(
                    "el-button",
                    {
                      staticClass: "data-operation-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.toAudioBookContent(
                            _vm.resourceObj.resourceOpenId
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "点学书内容",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-reading",
                            staticStyle: { "font-size": "18px" }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj.resourceType === _vm.SINGLE_FILE_ID
                ? _c(
                    "el-dropdown",
                    { staticClass: "data-operation-btn icon-tihuan-bg" },
                    [
                      _c(
                        "span",
                        { staticClass: "el-dropdown-link" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "替换",
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-tihuan" })]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { staticClass: "btn-replace-bg" },
                            [
                              _vm._v(
                                "\n              仅替换文件\n              "
                              ),
                              _c("single-file-upload", {
                                ref: "singleFileUpload",
                                staticClass: "btn-replace",
                                attrs: { "is-upload-del": true },
                                on: {
                                  fileUploading: _vm.fileUploading,
                                  getResult: function($event) {
                                    return _vm.getUploader(
                                      $event,
                                      _vm.resourceObj.resourceOpenId
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.fileList,
                                  callback: function($$v) {
                                    _vm.fileList = $$v
                                  },
                                  expression: "fileList"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { staticClass: "btn-replace-bg" },
                            [
                              _vm._v(
                                "\n              替换并同步名称\n              "
                              ),
                              _c("single-file-upload", {
                                ref: "singleFileUpload",
                                staticClass: "btn-replace",
                                attrs: { "is-upload-del": true },
                                on: {
                                  fileUploading: _vm.fileUploading,
                                  getResult: function($event) {
                                    return _vm.getReplaceUploader(
                                      $event,
                                      _vm.resourceObj.resourceOpenId,
                                      true
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.fileList,
                                  callback: function($$v) {
                                    _vm.fileList = $$v
                                  },
                                  expression: "fileList"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] !== _vm.RESOURCE_DELETED_STATE
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "btn-operation-more",
                      on: { command: _vm.handleOperationMore }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "el-dropdown-link" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "更多",
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-gengduo" })]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _vm.resourceObj.publishedExist
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "unPublish",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("撤销发布")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.resourceType === _vm.LIVE_ID
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "share",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("分享")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj[
                            _vm.statusValName(_vm.resourceObj.resourceType)
                          ] !== _vm.RESOURCE_DELETED_STATE &&
                          _vm.resourceObj.resourceType === _vm.AUDIOBOOK_ID
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "copy",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj[
                            _vm.statusValName(_vm.resourceObj.resourceType)
                          ] !== _vm.RESOURCE_DELETED_STATE &&
                          _vm.resourceObj.resourceType === _vm.VEDIO_ID
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "getCover",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("更新封面")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.resourceType === _vm.LIVE_ID
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "liveStatistic",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("统计")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.resourceType === _vm.LIVE_ID
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "awardStatistic",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("中奖记录")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.resourceObj[
                            _vm.statusValName(_vm.resourceObj.resourceType)
                          ] === _vm.RESOURCE_REVIEW_STATE &&
                            !_vm.resourceObj.publishedExist) ||
                          (_vm.resourceObj[
                            _vm.statusValName(_vm.resourceObj.resourceType)
                          ] === _vm.RESOURCE_REFUSED_STATE &&
                            !_vm.resourceObj.publishedExist) ||
                          (_vm.resourceObj[
                            _vm.statusValName(_vm.resourceObj.resourceType)
                          ] === _vm.RESOURCE_DRAFT_STATE &&
                            !_vm.resourceObj.publishedExist)
                            ? _c(
                                "el-dropdown-item",
                                {
                                  staticClass: "select-del",
                                  attrs: {
                                    command: _vm.operateValue(
                                      "del",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("移除")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.publishedExist &&
                          _vm.resourceObj.hiddenByOfficialAt
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "show",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("官方显示")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.publishedExist &&
                          !_vm.resourceObj.hiddenByOfficialAt
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "hide",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("官方隐藏")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.publishedExist &&
                          _vm.resourceObj.stopSoldByOfficialAt
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "sale",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("官方售卖")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceObj.publishedExist &&
                          !_vm.resourceObj.stopSoldByOfficialAt
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: _vm.operateValue(
                                      "stopSale",
                                      _vm.resourceObj
                                    )
                                  }
                                },
                                [_vm._v("官方停售")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj[
                _vm.statusValName(_vm.resourceObj.resourceType)
              ] === _vm.RESOURCE_DELETED_STATE
                ? _c(
                    "el-button",
                    {
                      staticClass: "data-operation-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.objectRestoreFun(
                            _vm.resourceObj.resourceOpenId
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "恢复",
                            effect: "dark",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-huifu" })]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }