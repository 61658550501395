var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-layout" },
    [
      _c(
        "el-card",
        { staticClass: "view-layout-card" },
        [
          _c("product-list", {
            ref: "list",
            attrs: {
              loading: _vm.loading,
              query: _vm.query,
              data: _vm.data,
              total: _vm.total,
              "content-height": "calc(100vh - 244px)",
              select: _vm.select,
              "select-type": _vm.selectType,
              "middle-open-id": _vm.middleOpenId,
              "show-resource-type": true,
              "price-hidden": true
            },
            on: {
              "update:query": function($event) {
                _vm.query = $event
              },
              "update:select": function($event) {
                _vm.select = $event
              },
              "update:selectType": function($event) {
                _vm.selectType = $event
              },
              "update:select-type": function($event) {
                _vm.selectType = $event
              },
              "update-data": _vm.updateData
            },
            scopedSlots: _vm._u([
              {
                key: "header-left",
                fn: function() {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "medium" },
                        on: { click: _vm.onButtonClicked }
                      },
                      [_vm._v("添加商品")]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "content-action",
                fn: function() {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        align: "center",
                        width: "120",
                        label: "操作"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "actions" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-edit",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isActionDisabled(
                                          scope.row.productOpenId
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toEdit(
                                            scope.row.productOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("详情")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn-del",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isActionDisabled(
                                          scope.row.productOpenId
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toDelete(
                                            scope.row.productOpenId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "footer-left",
                fn: function() {
                  return [
                    _c("el-checkbox", {
                      staticClass: "pagination-check",
                      attrs: { indeterminate: _vm.selectType === "HALF" },
                      model: {
                        value: _vm.selectedAll,
                        callback: function($$v) {
                          _vm.selectedAll = $$v
                        },
                        expression: "selectedAll"
                      }
                    }),
                    _vm._v(" "),
                    _c("universal-dropdown", {
                      attrs: {
                        "option-list": _vm.universalDropdownOptionList,
                        "cache-name": "zhongpan-commodity-productManagement"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogs" },
        [
          _c("middle-add-product", {
            attrs: { "middle-open-id": _vm.middleOpenId },
            on: { "update-data": _vm.updateData },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          }),
          _vm._v(" "),
          _c("real-goods-info", {
            attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
            on: {
              "update:visible": function($event) {
                _vm.goodsDialogVisible = $event
              },
              "update:goodsId": function($event) {
                _vm.goodsId = $event
              },
              "update:goods-id": function($event) {
                _vm.goodsId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("modify-prices-in-batches", {
            attrs: {
              "dialog-visible": _vm.batchPriceDialogVisible,
              "value-name": _vm.batchPriceValueName
            },
            on: {
              "update:dialogVisible": function($event) {
                _vm.batchPriceDialogVisible = $event
              },
              "update:dialog-visible": function($event) {
                _vm.batchPriceDialogVisible = $event
              },
              confirm: _vm.batchPriceConfirm
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }