var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tab_box" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c("el-tab-pane", { attrs: { label: "商品库", name: "1" } }),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "品牌方", name: "2" } }),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "商品系列", name: "3" } })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "body_box" },
      [
        _vm.activeName === "1"
          ? _c("product-list", { attrs: { "only-read": true } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeName === "2"
          ? _c("p_brand-side", { attrs: { "only-read": true } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeName === "3"
          ? _c("p-m-s_product-set-management", { attrs: { "only-read": true } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }