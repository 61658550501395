var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    {
      attrs: { disabled: _vm.disabledEdit },
      on: { change: _vm.onChange },
      model: {
        value: _vm.valueProxy,
        callback: function($$v) {
          _vm.valueProxy = $$v
        },
        expression: "valueProxy"
      }
    },
    _vm._l(_vm.data, function(i) {
      return _c(
        "el-radio",
        { key: i.label, staticClass: "my-radio", attrs: { label: i.label } },
        [_vm._v(_vm._s(i.title))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }