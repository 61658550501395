"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_PriceInventory_PSE_Table_InputColumn',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    disableCell: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 2
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return (0, _priceParser.priceToYuan)(this.value);
      },
      set: function set(value) {
        this.$emit('input', (0, _priceParser.parsePriceFromYuan)(value));
      }
    }
  }
};
exports.default = _default;