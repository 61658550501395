"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPreAuthCode = getPreAuthCode;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/TWeChat/admin/"));
/**
 * 获取第三方绑定授权码
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getPreAuthCode(params) {
  return request.get('getPreAuthCode', {
    params: params
  });
}