"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogueLink',
  props: {
    isSelectUser: {
      type: Boolean,
      default: false
    },
    resourceInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    linkData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      defaultCover: 'https://open.oss.taozhi.online/production/assets/mp/resource/select-resource/book_cover.png?x-oss-process=style%2Fthumbnail'
    };
  },
  computed: {
    commonLinkTitle: function commonLinkTitle() {
      return !this.isSelectUser ? 'common-link-title-admin' : '';
    },
    isAudio: function isAudio() {
      return this.resourceInfo.resourceType === 2 || this.resourceInfo.resourceType === 8 || this.resourceInfo.resourceType === 10;
    }
  },
  methods: {
    handleLinkJump: function handleLinkJump() {
      this.$emit('handleLinkJump', this.linkData);
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      if (!cover) return this.defaultCover;
      if (cover.substring(0, 4) === 'http' || cover.substring(0, 5) === 'https') return cover;
      var imgAllUrl = (0, _common.imageUrlFun)(cover, 'style/width-limit-80');
      return imgAllUrl;
    }
  }
};
exports.default = _default2;