var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "类型" },
          model: {
            value: _vm.valueProxy,
            callback: function($$v) {
              _vm.valueProxy = $$v
            },
            expression: "valueProxy"
          }
        },
        _vm._l(_vm.typeOptions, function(type) {
          return _c("el-option", {
            key: type.value,
            attrs: { label: type.name, value: type.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }