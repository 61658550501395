"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audioBookRequestList = audioBookRequestList;
exports.bookList = bookList;
exports.delBook = delBook;
exports.createBook = createBook;
exports.updateBook = updateBook;
exports.audiobookSetRecommendSequence = audiobookSetRecommendSequence;
exports.updateAudioBookPatch = updateAudioBookPatch;
exports.bookShow = bookShow;
exports.bookCopy = bookCopy;
exports.bookRestore = bookRestore;
exports.hideBookItem = hideBookItem;
exports.cancelHideBookItem = cancelHideBookItem;
exports.saleStopItem = saleStopItem;
exports.cancelSaleStopItem = cancelSaleStopItem;
exports.passCheckItem = passCheckItem;
exports.notPassCheckItem = notPassCheckItem;
exports.bookBatchSetAttribute = bookBatchSetAttribute;
exports.bookBatchRecovery = bookBatchRecovery;
exports.bookBatchIndependentSale = bookBatchIndependentSale;
exports.bookBatchReview = bookBatchReview;
exports.bookBatchRefuse = bookBatchRefuse;
exports.getMenuList = getMenuList;
exports.getMenu = getMenu;
exports.addMenu = addMenu;
exports.updateMenu = updateMenu;
exports.deleteMenu = deleteMenu;
exports.updateBatchMenus = updateBatchMenus;
exports.getPageById = getPageById;
exports.getPageList = getPageList;
exports.addPage = addPage;
exports.updatePage = updatePage;
exports.deletePage = deletePage;
exports.addPages = addPages;
exports.updatePages = updatePages;
exports.addPagesByPdf = addPagesByPdf;
exports.getBookStateCount = getBookStateCount;
exports.sectionIndex = sectionIndex;
exports.deleteSection = deleteSection;
exports.updateSection = updateSection;
exports.replaceSection = replaceSection;
exports.addSection = addSection;
exports.saveSections = saveSections;
exports.getSysIcons = getSysIcons;
exports.getUserIcons = getUserIcons;
exports.addUserIcons = addUserIcons;
exports.saveAndReview = saveAndReview;
exports.updateBlankAudioVid = updateBlankAudioVid;
exports.updatePageGuideVid = updatePageGuideVid;
exports.getBookTitleList = getBookTitleList;
exports.hiddenPage = hiddenPage;
exports.cancelHiddenPage = cancelHiddenPage;
exports.onlineCovertLRC = onlineCovertLRC;
exports.updateAudioBookEncode = updateAudioBookEncode;
exports.getCommodityBookList = getCommodityBookList;
exports.getSectionViewCount = getSectionViewCount;
exports.getBatchSectionViewCount = getBatchSectionViewCount;
exports.bookBatchRecycle = bookBatchRecycle;
exports.audioBookBatchRevoke = audioBookBatchRevoke;
exports.getBookMenuList = getBookMenuList;
exports.audioBookBatchCancelStopSale = audioBookBatchCancelStopSale;
exports.audioBookBatchStopSale = audioBookBatchStopSale;
exports.audioBookBatchCancelHidden = audioBookBatchCancelHidden;
exports.audioBookBatchHidden = audioBookBatchHidden;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audiobook/admin/"));
var commonRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audiobook/common/"));
/**
 * 获取点学书列表
 * @param params
 * @returns {Promise<*>}
 */

function audioBookRequestList(params) {
  return request.get('/book/request/list', {
    params: params
  });
}
/**
 * 获取点学书列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookList(params) {
  return request.get('/book/request/list', {
    params: params
  });
}
/**
 * 删除点学书
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delBook(openId) {
  return request.delete("/book/request/".concat(openId));
}
/**
 * 创建点学书
 * @param {Object} data
 * @returns {Promise<*>}
 */


function createBook(data) {
  return request.post('/book/request', data);
}
/**
 * 修改点学书
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateBook(openId, data) {
  return request.put("/book/request/".concat(openId), data);
}
/**
 * 点学书-设置推荐排序
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audiobookSetRecommendSequence(openId, params) {
  return request.patch("/book/request/change/recommendSequence/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 修改点学书的单个值
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateAudioBookPatch(openId, data) {
  return request.patch("/book/request/".concat(openId), data);
}
/**
 * 查看点学书
 * @param {String} openId
 * @returns {Promise<*>}
 */


function bookShow(openId) {
  return request.get("/book/request/".concat(openId));
}
/**
 * 复制点学书
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookCopy(openId, params) {
  return request.post("/book/request/copy/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 恢复图书
 * @param {String} openId
 * @returns {Promise<*>}
 */


function bookRestore(openId) {
  return request.post("/book/request/restore/".concat(openId));
}
/**
 * 隐藏点学书
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function hideBookItem(openId, params) {
  return request.post("/book/request/hide/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 取消隐藏点学书
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function cancelHideBookItem(openId, params) {
  return request.post("/book/request/hide/cancel/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 停售点学书
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function saleStopItem(openId, params) {
  return request.post("/book/request/stopSell/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 取消停售点学书
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function cancelSaleStopItem(openId, params) {
  return request.post("/book/request/stopSell/cancel/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 通过审核点学书
 * @param {String} openId
 * @returns {Promise<*>}
 */


function passCheckItem(openId) {
  return request.post("/book/request/reviewed/".concat(openId));
}
/**
 * 点学书-驳回审核点学书
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function notPassCheckItem(openId, params) {
  return request.post("/book/request/refuse/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 点学书-批量修改属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookBatchSetAttribute(params) {
  return request.patch('/book/request/batch/attribute', undefined, {
    params: params
  });
}
/**
 * 点学书-批量恢复
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bookBatchRecovery(openIds) {
  return request.post('/book/request/batch/recovery', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 点学书-批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function bookBatchRecycle(openIds) {
  return request.post('/book/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 点学书-批量设置独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookBatchIndependentSale(params) {
  return request.patch('/book/request/batch/independentSale', undefined, {
    params: params
  });
}
/**
 * 点学书-批量审核通过
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookBatchReview(params) {
  return request.post('/book/request/batch/review', undefined, {
    params: params
  });
}
/**
 * 点学书-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookBatchRefuse(params) {
  return request.post('/book/request/batch/refuse', undefined, {
    params: params
  });
}
/**
 * 获取目录列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMenuList(params) {
  return request.get('/menu/request', {
    params: params
  });
}
/**
 * 查询一个目录
 * @param {Number} id
 * @returns {Promise<*>}
 */


function getMenu(id) {
  return request.get("/menu/request/".concat(id));
}
/**
 * 添加目录
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addMenu(data) {
  return request.post('/menu/request', data);
}
/**
 *
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateMenu(id, data) {
  return request.put("/menu/request/".concat(id), data);
}
/**
 * 删除目录
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deleteMenu(id) {
  return request.delete("/menu/request/".concat(id));
}
/**
 * 目录-批量修改
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateBatchMenus(data) {
  return request.put('/menu/request/batch/update', data);
}
/**
 * 获取指定书页
 * @param pageId
 * @returns {Promise<*>}
 */


function getPageById(pageId) {
  return request.get("/page/request/".concat(pageId));
}
/**
 * 获取书页列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPageList(params) {
  return request.get('/page/request/list', {
    params: params
  });
}
/**
 * 添加书页
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPage(data) {
  return request.post('/page/request', data);
}
/**
 * 修改书页
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePage(openId, data) {
  return request.put("/page/request/".concat(openId), data);
}
/**
 * 删除书页
 * @param {String} openId
 * @returns {Promise<*>}
 */


function deletePage(openId) {
  return request.delete("/page/request/".concat(openId));
}
/**
 * 书页-批量添加
 * @param pageId
 * @param data
 * @returns {AxiosPromise<any>}
 */


function addPages(pageId, data) {
  return request.post('/page/request/batch/add', data, {
    params: {
      pageId: pageId
    }
  });
}
/**
 * 书页-批量修改
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePages(data) {
  return request.put('/page/request/batch/update', data);
}
/**
 * 书页-通过pdf批量添加
 * @param {String} bookOpenId
 * @param {String} pdfUrl
 * @returns {Promise<*>}
 */


function addPagesByPdf(bookOpenId, pdfUrl) {
  return request.post('/page/request/batch/pdf', undefined, {
    params: {
      bookOpenId: bookOpenId,
      pdfUrl: pdfUrl
    },
    timeout: 1000 * 60 * 10
  });
}
/**
 * 隐藏指定的书页
 * @param pageId
 * @returns {AxiosPromise<any>}
 */


function hiddenPage(pageId) {
  return request.patch("/page/request/hidden/".concat(pageId));
}
/**
 * 显示指定的书页
 * @param pageId
 * @returns {AxiosPromise<any>}
 */


function cancelHiddenPage(pageId) {
  return request.patch("/page/request/cancel/hidden/".concat(pageId));
}
/**
 *  获取不同状态下的点学书数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBookStateCount(params) {
  return request.get('/book/request/count', {
    params: params
  });
}
/**
 * 根据页面Id获取页面选区
 * @param {Number} pageId
 * @returns {Promise<*>}
 */


function sectionIndex(pageId) {
  return request.get('/section/request/list', {
    params: {
      pageId: pageId
    }
  });
}
/**
 * 删除页面选区
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deleteSection(id) {
  return request.delete("/section/request/".concat(id));
}
/**
 * 修改页面选区
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSection(id, data) {
  return request.put("/section/request/".concat(id), data);
}
/**
 * 替换页面选区
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function replaceSection(id, data) {
  return request.put("/section/request/replace/".concat(id), data);
}
/**
 * 添加页面选区
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addSection(data) {
  return request.post('/section/request', data);
}
/**
 * 批量增加页面选区
 * @param {Number} pageId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveSections(pageId, data) {
  return request.post('/section/request/batch/adds', data, {
    params: {
      pageId: pageId
    }
  });
}
/**
 * 根据 id 获取选区的浏览量
 * @param sectionId
 * @returns {AxiosPromise<any>}
 */


function getSectionViewCount(sectionId) {
  return request.get('/section/views', {
    params: {
      id: sectionId
    }
  });
}
/**
 * 批量获取选区浏览量
 * @param sectionIds
 * @returns {AxiosPromise<any>}
 */


function getBatchSectionViewCount(sectionIds) {
  return request.get('/section/viewsMap', {
    params: {
      ids: sectionIds
    }
  });
}
/**
 * 获取系统图标
 * @returns {Promise<*>}
 */


function getSysIcons() {
  return commonRequest.get('/icon/system');
}
/**
 * 获取用户图标
 * @returns {Promise<*>}
 */


function getUserIcons() {
  return commonRequest.get('/icon/history');
}
/**
 * 添加用户图标
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addUserIcons(data) {
  return commonRequest.post('/icon/saves', data);
}
/**
 * 保存并提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function saveAndReview(openId, data) {
  return request.patch("/book/request/".concat(openId, "/toReview"), data);
}
/**
 * 点学书-修改空白音频vid
 * @param {String} openId
 * @param {String} vid
 * @returns {Promise<*>}
 */


function updateBlankAudioVid(openId, vid) {
  var params = {
    openId: openId,
    vid: vid
  };
  return request.patch('/book/request/blankVid', undefined, {
    params: params
  });
}
/**
 * 点学书-修改书页导播音频vid
 * @param {String} pageId
 * @param {String} vid
 * @returns {Promise<*>}
 */


function updatePageGuideVid(pageId, vid) {
  var params = {
    pageId: pageId,
    vid: vid
  };
  return request.patch('/page/request/guideVid', undefined, {
    params: params
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBookTitleList(params) {
  return request.get('/book/request/repeat/title', {
    params: params
  });
}
/**
 * 在线转换OSS上的LRC文件格式为UTF8
 * @param target
 * @returns {AxiosPromise<any>}
 */


function onlineCovertLRC(target) {
  return request.get('/section/request/lrc', {
    params: {
      target: target
    }
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateAudioBookEncode(resourceOpenId, resourceEncode) {
  return request.patch("/book/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 获取点学书商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityBookList(params) {
  return request.get('/book/admin', {
    params: params
  });
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBookBatchRevoke(openIds) {
  return request.post('book/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 图书目录页
 * @param {Object} bookOpenId
 * @returns {Promise<*>}
 */


function getBookMenuList(bookOpenId) {
  return request.get("page/menu/".concat(bookOpenId));
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBookBatchHidden(openIds) {
  return request.patch('/book/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBookBatchCancelHidden(openIds) {
  return request.patch('/book/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function audioBookBatchStopSale(params) {
  return request.patch('/book/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function audioBookBatchCancelStopSale(openIds) {
  return request.patch('/book/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}