"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var bookLibrary = {
  namespaced: true,
  state: {
    libraryParam: undefined
  },
  mutations: {
    MODIFY: function MODIFY(state) {
      state.libraryParam = new Date().getTime();
    }
  },
  actions: {
    set_state_sum: function set_state_sum(_ref) {
      var commit = _ref.commit;
      commit('MODIFY');
    }
  },
  getters: {
    libraryParam: function libraryParam(state) {
      return state.libraryParam;
    }
  }
};
var _default = bookLibrary;
exports.default = _default;