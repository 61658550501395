"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bjyUploadStatus = exports.uploadStatus = void 0;
var uploadStatus = {
  WAITING: 1,
  // 等待
  UPLOADING: 2,
  // 上传中
  CANCELED: 3,
  // 取消上传
  SUCCEEDED: 4,
  // 成功
  FAILED: 5 // 失败

};
exports.uploadStatus = uploadStatus;
var bjyUploadStatus = {
  SUCCEEDED: 100,
  // 转码成功
  UPLOADTIMEOUT: 32,
  // 上传超时
  TRANSCODINGTIMEOUT: 31,
  // 转码超时
  TRANSCODINGFAILED: 30,
  // 转码失败
  TRANSCODING: 20,
  // 转码中
  UPLOADING: 10 // 上传中

};
exports.bjyUploadStatus = bjyUploadStatus;