var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      ref: "table",
      staticClass: "my-table",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.valueProxy,
        "max-height": "500",
        border: "",
        "use-virtual": "",
        "row-class-name": _vm.tableRowClassName,
        "span-method": _vm.arraySpanMethod,
        "header-cell-class-name": "table-header",
        "cell-class-name": "table-cell"
      }
    },
    [
      _vm._l(_vm.name, function(v, k) {
        return _c("el-table-column", {
          key: k + "-" + v,
          attrs: { width: "120" },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(v))])]
                }
              },
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row[k]))])]
                }
              }
            ],
            null,
            true
          )
        })
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "name", label: "sku图片", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("image-edit-column", {
                  attrs: {
                    "disable-cell":
                      _vm.tableRowClassName(scope) === "disabled-row"
                  },
                  model: {
                    value: scope.row.cover,
                    callback: function($$v) {
                      _vm.$set(scope.row, "cover", $$v)
                    },
                    expression: "scope.row.cover"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm.showSkuType
        ? _c("el-table-column", {
            attrs: { prop: "name", label: "sku分类", width: "120" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled:
                              _vm.tableRowClassName(scope) === "disabled-row"
                          },
                          model: {
                            value: scope.row.skuType,
                            callback: function($$v) {
                              _vm.$set(scope.row, "skuType", $$v)
                            },
                            expression: "scope.row.skuType"
                          }
                        },
                        _vm._l(_vm.skuTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              623323718
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "ERP商品id", width: "340" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-input", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.importLoading[scope.$index],
                      expression: "importLoading[scope.$index]"
                    }
                  ],
                  attrs: {
                    disabled: _vm.tableRowClassName(scope) === "disabled-row"
                  },
                  on: {
                    blur: function($event) {
                      return _vm.syncDataFromErpDebounced(scope)
                    }
                  },
                  model: {
                    value: scope.row.productId,
                    callback: function($$v) {
                      _vm.$set(scope.row, "productId", $$v)
                    },
                    expression: "scope.row.productId"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm.showSkuCode
        ? _c("el-table-column", {
            attrs: { label: "sku编码", prop: "skuCode", width: "220" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "160" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c("header-with-batch-set", {
                  attrs: {
                    label: "原价",
                    "has-suffix": false,
                    "input-max": 10000000000,
                    "input-min": 0,
                    "parser-get": _vm.priceToYuan,
                    "parser-set": _vm.parsePriceFromYuan,
                    "input-width": "100px"
                  },
                  on: { update: _vm.updateLowPrice1 }
                })
              ]
            }
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("input-column", {
                  attrs: {
                    "disable-cell":
                      _vm.tableRowClassName(scope) === "disabled-row",
                    precision: 2
                  },
                  model: {
                    value: scope.row.orginalPrice,
                    callback: function($$v) {
                      _vm.$set(scope.row, "orginalPrice", $$v)
                    },
                    expression: "scope.row.orginalPrice"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "260" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c("header-with-batch-set", {
                  attrs: {
                    label: "库存",
                    "has-suffix": false,
                    "input-max": 10000000000,
                    "input-min": 0,
                    "parser-get": _vm.parsePrice,
                    "parser-set": _vm.parsePrice,
                    "input-width": "100px"
                  },
                  on: { update: _vm.updateLowPrice2 }
                })
              ]
            }
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("specification-inventory", {
                  attrs: {
                    "is-use-e-r-p": scope.row.isUseErp,
                    "disable-cell":
                      _vm.tableRowClassName(scope) === "disabled-row"
                  },
                  on: {
                    "update:isUseERP": function($event) {
                      return _vm.$set(scope.row, "isUseErp", $event)
                    },
                    "update:is-use-e-r-p": function($event) {
                      return _vm.$set(scope.row, "isUseErp", $event)
                    }
                  },
                  model: {
                    value: scope.row.inventory,
                    callback: function($$v) {
                      _vm.$set(scope.row, "inventory", $$v)
                    },
                    expression: "scope.row.inventory"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "200" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(scope) {
              return [
                _c("header-with-batch-set", {
                  attrs: { label: "全网最低售价" },
                  on: { update: _vm.updateLowPrice }
                })
              ]
            }
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("discount-setting-column", {
                  attrs: {
                    "orginal-price": scope.row.orginalPrice,
                    "disable-cell":
                      _vm.tableRowClassName(scope) === "disabled-row"
                  },
                  model: {
                    value: scope.row.lowPrice,
                    callback: function($$v) {
                      _vm.$set(scope.row, "lowPrice", $$v)
                    },
                    expression: "scope.row.lowPrice"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      false
        ? _c("el-table-column", {
            attrs: { width: "220" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function(scope) {
                    return [
                      _c("header-with-batch-set", {
                        attrs: { label: "售价", "input-width": "100px" },
                        on: { update: _vm.updatePrice }
                      })
                    ]
                  }
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("discount-setting-column", {
                        attrs: {
                          "orginal-price": scope.row.orginalPrice,
                          "disable-cell":
                            _vm.tableRowClassName(scope) === "disabled-row",
                          "input-width": "100px"
                        },
                        model: {
                          value: scope.row.price,
                          callback: function($$v) {
                            _vm.$set(scope.row, "price", $$v)
                          },
                          expression: "scope.row.price"
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              3961812026
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "条码", width: "160" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.tableRowClassName(scope) === "disabled-row"
                  },
                  model: {
                    value: scope.row.barCode,
                    callback: function($$v) {
                      _vm.$set(scope.row, "barCode", $$v)
                    },
                    expression: "scope.row.barCode"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { fixed: "right", label: "操作", width: "80" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "input-line" },
                  [
                    _c("el-switch", {
                      model: {
                        value: scope.row.isEnable,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isEnable", $$v)
                        },
                        expression: "scope.row.isEnable"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }