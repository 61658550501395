var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "900px",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        "before-close": _vm.handleClose,
        title: "设置密钥",
        "z-index": 1000
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "160px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称 :", prop: "nickName" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.nickName) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "partnerOpenId :", prop: "partnerOpenId" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.partnerOpenId) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "wechatBindOpenId :",
                        prop: "wechatBindOpenId"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.wechatBindOpenId) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "authorizerAppId :",
                        prop: "authorizerAppId"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.authorizerAppId) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "App Secret :",
                        prop: "authorizerAppSecret",
                        rules: [
                          { required: true, message: "App Secret 不能为空" }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.authorizerAppSecret,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "authorizerAppSecret", $$v)
                          },
                          expression: "form.authorizerAppSecret"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }