"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _assetAccount = _interopRequireDefault(require("@/components/Finance/assetAccount.vue"));

var _assetWithdraw = _interopRequireDefault(require("@/components/Finance/assetWithdraw.vue"));

var _cashWithdraw = _interopRequireDefault(require("@/components/Finance/cashWithdraw.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'index',
  components: {
    CashWithdraw: _cashWithdraw.default,
    AssetWithdraw: _assetWithdraw.default,
    AssetAccount: _assetAccount.default
  },
  data: function data() {
    return {
      openid: '1',
      tabName: '0',
      type: '0'
    };
  },
  created: function created() {
    if (this.$route.query.openId) {
      this.openid = this.$route.query.openId;
    }
  },
  methods: {
    changeTab: function changeTab() {
      this.type = this.tabName;
    },
    toWithdraw: function toWithdraw() {
      this.tabName = '1';
      this.type = '1';
    },
    toCash: function toCash() {
      this.type = '2';
    }
  }
};
exports.default = _default;