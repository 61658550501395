"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CPM_Ge_FilterOptions_TheUnderlyingColumn',
  props: {
    label: {
      type: String,
      default: '条件筛选'
    }
  }
};
exports.default = _default;