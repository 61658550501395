var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-table-column", {
        attrs: { width: "200", fixed: "right" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("header-with-batch-set", {
                  attrs: { label: "店铺进货价" },
                  on: { update: _vm.batchUpdateStockPrice }
                })
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("discount-setting-column", {
                  attrs: {
                    "orginal-price": scope.row.orginalPrice,
                    "no-price": scope.row.specificType !== 0
                  },
                  model: {
                    value: scope.row.stockPrice,
                    callback: function($$v) {
                      _vm.$set(scope.row, "stockPrice", $$v)
                    },
                    expression: "scope.row.stockPrice"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "200", fixed: "right" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("header-with-batch-set", {
                  attrs: { label: "店铺最低售价" },
                  on: { update: _vm.batchUpdateLowPrice }
                })
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("discount-setting-column", {
                  attrs: {
                    "orginal-price": scope.row.orginalPrice,
                    "no-price": scope.row.specificType !== 0
                  },
                  model: {
                    value: scope.row.lowPrice,
                    callback: function($$v) {
                      _vm.$set(scope.row, "lowPrice", $$v)
                    },
                    expression: "scope.row.lowPrice"
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "60", fixed: "right" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-edit",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.toEdit(scope.row.productOpenId)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }