var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "div",
        { staticClass: "playerWrap" },
        [
          _c("video-player", {
            ref: "videoPlayer",
            staticClass: "video-player vjs-custom-skin",
            attrs: { playsinline: true, options: _vm.playerOptions }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }