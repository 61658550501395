"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _index = _interopRequireDefault(require("../BaseNumberInput/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PriceInput',
  components: {
    BaseNumberInput: _index.default
  },
  props: {
    value: {
      type: Number,
      default: undefined
    },
    leftText: {
      type: String,
      default: undefined
    },
    rightText: {
      type: String,
      default: undefined
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    blur: function blur() {
      for (var _len = arguments.length, p = new Array(_len), _key = 0; _key < _len; _key++) {
        p[_key] = arguments[_key];
      }

      this.$emit.apply(this, ['blur'].concat(p));
    },
    change: function change() {
      for (var _len2 = arguments.length, p = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        p[_key2] = arguments[_key2];
      }

      this.$emit.apply(this, ['change'].concat(p));
    }
  }
};
exports.default = _default;