"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.set");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es7.object.entries");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.map");

var _main = _interopRequireDefault(require("../../../organizationLibs/typeIs/main"));

function noneToMap() {
  return new Map();
}

function valueToMap(value) {
  return new Map(Object.entries([value]));
}

function objectToMap(value) {
  return new Map(Object.entries(value));
}

function setToMap(value) {
  return new Map(Object.entries((0, _toConsumableArray2.default)(value)));
}

function anyToMap(value) {
  switch (_main.default.type(value)) {
    case _main.default.names.null:
      // none
      return noneToMap(value);

    case _main.default.names.undefined:
      // none
      return noneToMap(value);

    case _main.default.names.object:
      if (value instanceof Map) {
        // map
        return value;
      }

      if (value instanceof Set) {
        // set
        return setToMap(value);
      }

      return objectToMap(value);
    // object

    default:
      return valueToMap(value);
    // other
  }
}

var _default = anyToMap;
exports.default = _default;