var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-content-item" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group img-group", class: _vm.getItemClass("cover") },
      [
        _c("div", { staticClass: "name" }, [_vm._v("商品封面图")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sub" },
          [
            _c("el-image", {
              staticClass: "img-conc",
              attrs: { src: _vm.getImgUrlWl64(_vm.data.cover), fit: "cover" }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group", class: _vm.getItemClass("title") }, [
      _c("div", { staticClass: "name" }, [_vm._v("商品名称")]),
      _vm._v(" "),
      _c("div", { staticClass: "sub" }, [
        _vm._v(_vm._s(_vm.textParser(_vm.data.title)))
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group", class: _vm.getItemClass("productCode") },
      [
        _c("div", { staticClass: "name" }, [_vm._v("商品编号")]),
        _vm._v(" "),
        _c("div", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.productCode)))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group", class: _vm.getItemClass("atlasJson") }, [
      _c("div", { staticClass: "name" }, [_vm._v("商品图集")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sub with-img-conc" },
        _vm._l(_vm.imageList, function(i, k) {
          return _c("el-image", {
            key: k,
            staticClass: "img-conc",
            attrs: { src: _vm.getImgUrlWl64(i), fit: "cover" }
          })
        }),
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "group", class: _vm.getItemClass("showVideo") }, [
      _c("div", { staticClass: "name" }, [_vm._v("主图视频")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sub" },
        [
          _c("el-image", {
            staticClass: "vid-conc",
            attrs: { src: "/static/img/book_cover.79647519.png", fit: "cover" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "title-2" }, [_vm._v("商品属性")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("regimentation") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("类目")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.regimentationDisplay)))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group-2", class: _vm.getItemClass("barCode") }, [
      _c("span", { staticClass: "name" }, [_vm._v("条码")]),
      _vm._v(" "),
      _c("span", { staticClass: "sub" }, [
        _vm._v(_vm._s(_vm.textParser(_vm.data.barCode)))
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("brandText") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("品牌")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.brandText)))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("seriesText") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("图书系列")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.seriesText)))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group-2", class: _vm.getItemClass("section") }, [
      _c("span", { staticClass: "name" }, [_vm._v("同步")]),
      _vm._v(" "),
      _c("span", { staticClass: "sub" }, [
        _vm._v(
          _vm._s(_vm.data.sectionText) +
            "/" +
            _vm._s(_vm.data.subjectText) +
            "/" +
            _vm._s(_vm.data.editionText) +
            "/" +
            _vm._s(_vm.data.gradeText) +
            "/" +
            _vm._s(_vm.data.volumeText)
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("periodText") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("版次")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.periodText)))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group-2", class: _vm.getItemClass("areaText") }, [
      _c("span", { staticClass: "name" }, [_vm._v("地区")]),
      _vm._v(" "),
      _c("span", { staticClass: "sub" }, [
        _vm._v(_vm._s(_vm.textParser(_vm.data.areaText)))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "group-2", class: _vm.getItemClass("tags") }, [
      _c("span", { staticClass: "name" }, [_vm._v("标签")]),
      _vm._v(" "),
      _c("span", { staticClass: "sub" }, [
        _vm._v(_vm._s(_vm.textParser(_vm.data.tags)))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dividing-line" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("isSuitDisplay") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("是否套装")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.isSuitDisplay)))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("inventory") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("库存")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.inventory)))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("quotaDisplay") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("限购")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.quotaDisplay)))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("orginalPrice") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("原价")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(
            "¥" + _vm._s(_vm.textParser(_vm.priceToYuan(_vm.data.orginalPrice)))
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "group-2", class: _vm.getItemClass("price") }, [
      _c("span", { staticClass: "name" }, [_vm._v("售价")]),
      _vm._v(" "),
      _c("span", { staticClass: "sub" }, [
        _vm._v(
          "¥" +
            _vm._s(
              _vm.textParser(
                _vm.priceToYuan(_vm.data.orginalPrice * _vm.data.price)
              )
            )
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "group-2",
        class: _vm.getItemClass("paymentMethodDisplay")
      },
      [
        _c("span", { staticClass: "name" }, [_vm._v("交付方式")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.paymentMethodDisplay)))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group-2", class: _vm.getItemClass("pickupCode") },
      [
        _c("span", { staticClass: "name" }, [_vm._v("自提点")]),
        _vm._v(" "),
        _c("span", { staticClass: "sub" }, [
          _vm._v(_vm._s(_vm.textParser(_vm.data.pickupCode)))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "dividing-line" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "group img-group", class: _vm.getItemClass("detail") },
      [
        _c("div", { staticClass: "name" }, [_vm._v("商品介绍")]),
        _vm._v(" "),
        _c("div", { staticClass: "sub" }, [
          _c("div", {
            staticClass: "img-conc-big",
            domProps: { innerHTML: _vm._s(_vm.data.detail) }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }