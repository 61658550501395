"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.oneDay = exports.oneHour = exports.oneMinute = exports.oneSecond = exports.oneMillisecond = void 0;
var oneMillisecond = 1;
exports.oneMillisecond = oneMillisecond;
var oneSecond = 1000;
exports.oneSecond = oneSecond;
var oneMinute = 60000;
exports.oneMinute = oneMinute;
var oneHour = 3600000;
exports.oneHour = oneHour;
var oneDay = 86400000;
exports.oneDay = oneDay;