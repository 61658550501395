var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.operation === "update"
      ? _c("div", { staticClass: "bottom-button-block" }, [
          _vm.allowEdit
            ? _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.closeView } }, [
                    _vm._v("返回")
                  ]),
                  _vm._v(" "),
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.updateEdit()
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUnderReview
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.passBtn, type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.passItem()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.passBtn ? "通过..." : "通过"))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUnderReview
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.refuseBtn, type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.handelRefuse()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.refuseBtn ? "驳回..." : "驳回"))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.updateIsRelease
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.publishBtn, type: "success" },
                          on: { click: _vm.handelSaveAndReview }
                        },
                        [_vm._v(_vm._s(_vm.passBtn ? "发布..." : "发布"))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isResume
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.restoreBtn, type: "success" },
                          on: { click: _vm.handleBookRestore }
                        },
                        [_vm._v(_vm._s(_vm.restoreBtn ? "恢复..." : "恢复"))]
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.updateEdit()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.saveBtn, type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.saveData()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.saveBtn ? "保存..." : "保存"))]
                  ),
                  _vm._v(" "),
                  _vm.isRelease
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.publishBtn, type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.handelSaveAndReview()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.publishBtn ? "发布..." : "发布"))]
                      )
                    : _vm._e()
                ],
                1
              )
        ])
      : _c("div", { staticClass: "bottom-button-block" }, [
          _vm.openId || _vm.isVideo
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeView()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.saveDraftBtn, type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.saveDraft()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.saveDraftBtn ? "保存至草稿箱..." : "保存至草稿箱"
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isRelease
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.publishBtn, type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.handelSaveAndReview()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.publishBtn ? "发布..." : "发布"))]
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeView()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.nextBtn, type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.nextStep()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.nextBtn ? "下一步..." : "下一步"))]
                  )
                ],
                1
              )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }