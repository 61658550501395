var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination-box" },
    [
      _c(
        "div",
        { staticClass: "pagination-btns" },
        [
          _c("el-checkbox", {
            staticClass: "pagination-check",
            attrs: { indeterminate: _vm.isIndeterminate },
            on: { change: _vm.handleCheckAllChange },
            model: {
              value: _vm.selectedAll,
              callback: function($$v) {
                _vm.selectedAll = $$v
              },
              expression: "selectedAll"
            }
          }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.pageLoading,
                  expression: "pageLoading",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              staticClass: "batch-box",
              attrs: {
                "element-loading-text": "数据保存中...",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 0.2)",
                "split-button": "",
                type: "primary"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.batchDebounceClick($event)
                },
                command: _vm.handleBatchClick
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.batchName) + "\n      "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.reviewList, function(item) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: item.command,
                      attrs: { command: item.command, divided: item.divided }
                    },
                    [_vm._v(_vm._s(item.batch))]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "attribute-dialog-wrapper",
          attrs: {
            visible: _vm.dialogAttribute,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "批量设置属性"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAttribute = $event
            }
          }
        },
        [
          _vm.dialogAttribute
            ? _c(
                "el-form",
                {
                  ref: "attributeForm",
                  attrs: {
                    model: _vm.attributeForm,
                    "label-width": "60",
                    "label-position": "right"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "form-check-bg" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "form-check",
                            attrs: { label: "1", name: "type" },
                            model: {
                              value: _vm.attributeForm.isChangeSync,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeForm, "isChangeSync", $$v)
                              },
                              expression: "attributeForm.isChangeSync"
                            }
                          },
                          [_vm._v("同步")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-content-bg" },
                      [
                        _c("sync-view", {
                          attrs: {
                            "is-disabled": !_vm.attributeForm.isChangeSync
                          },
                          model: {
                            value: _vm.attributeForm.tempCode,
                            callback: function($$v) {
                              _vm.$set(_vm.attributeForm, "tempCode", $$v)
                            },
                            expression: "attributeForm.tempCode"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "form-check-bg" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { label: "2" },
                            model: {
                              value: _vm.attributeForm.isChangeTypes,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.attributeForm,
                                  "isChangeTypes",
                                  $$v
                                )
                              },
                              expression: "attributeForm.isChangeTypes"
                            }
                          },
                          [_vm._v("分类")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-content-bg" },
                      [
                        _c("works", {
                          ref: "worksView",
                          attrs: {
                            "is-disabled": !_vm.attributeForm.isChangeTypes,
                            "edit-hide": true
                          },
                          on: { "add-classtion": _vm.addclasstion }
                        }),
                        _vm._v(" "),
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          attrs: { value: _vm.attributeForm.types }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "form-check-bg" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "form-check",
                            attrs: { label: "3", name: "type" },
                            model: {
                              value: _vm.attributeForm.isChangePeriod,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.attributeForm,
                                  "isChangePeriod",
                                  $$v
                                )
                              },
                              expression: "attributeForm.isChangePeriod"
                            }
                          },
                          [_vm._v("版次")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-content-bg" },
                      [
                        _c("editionbox", {
                          attrs: {
                            "is-disabled": !_vm.attributeForm.isChangePeriod
                          },
                          model: {
                            value: _vm.attributeForm.periodId,
                            callback: function($$v) {
                              _vm.$set(_vm.attributeForm, "periodId", $$v)
                            },
                            expression: "attributeForm.periodId"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          model: {
                            value: _vm.attributeForm.periodId,
                            callback: function($$v) {
                              _vm.$set(_vm.attributeForm, "periodId", $$v)
                            },
                            expression: "attributeForm.periodId"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "form-check-bg" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "form-check",
                            attrs: { label: "4", name: "type" },
                            model: {
                              value: _vm.attributeForm.isChangeRegimentation,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.attributeForm,
                                  "isChangeRegimentation",
                                  $$v
                                )
                              },
                              expression: "attributeForm.isChangeRegimentation"
                            }
                          },
                          [_vm._v("类目")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-content-bg" },
                      [
                        _c("category-view", {
                          attrs: {
                            "is-disabled": !_vm.attributeForm
                              .isChangeRegimentation
                          },
                          on: { "category-change": _vm.categoryChange },
                          model: {
                            value: _vm.attributeForm.secondRegimentationIds,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.attributeForm,
                                "secondRegimentationIds",
                                $$v
                              )
                            },
                            expression: "attributeForm.secondRegimentationIds"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          model: {
                            value: _vm.attributeForm.secondRegimentationIds,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.attributeForm,
                                "secondRegimentationIds",
                                $$v
                              )
                            },
                            expression: "attributeForm.secondRegimentationIds"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "form-check-bg" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "form-check",
                            attrs: { label: "4", name: "type" },
                            model: {
                              value: _vm.attributeForm.isChangeTags,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeForm, "isChangeTags", $$v)
                              },
                              expression: "attributeForm.isChangeTags"
                            }
                          },
                          [_vm._v("标签")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-content-bg" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: !_vm.attributeForm.isChangeTags,
                              multiple: "",
                              filterable: "",
                              "allow-create": "",
                              "default-first-option": "",
                              placeholder: "请输入或选择商品标签"
                            },
                            on: { change: _vm.changeTags },
                            model: {
                              value: _vm.tagValue,
                              callback: function($$v) {
                                _vm.tagValue = $$v
                              },
                              expression: "tagValue"
                            }
                          },
                          _vm._l(_vm.tagOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          model: {
                            value: _vm.attributeForm.tagValue,
                            callback: function($$v) {
                              _vm.$set(_vm.attributeForm, "tagValue", $$v)
                            },
                            expression: "attributeForm.tagValue"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-check-bg" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "form-check",
                              attrs: { label: "4", name: "type" },
                              model: {
                                value: _vm.attributeForm.isChangeArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.attributeForm,
                                    "isChangeArea",
                                    $$v
                                  )
                                },
                                expression: "attributeForm.isChangeArea"
                              }
                            },
                            [_vm._v("地区")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-content-bg" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: !_vm.attributeForm.isChangeArea,
                                multiple: "",
                                filterable: "",
                                "allow-create": "",
                                "default-first-option": "",
                                placeholder: "请输入或选择地区"
                              },
                              on: { change: _vm.changeArea },
                              model: {
                                value: _vm.areaValue,
                                callback: function($$v) {
                                  _vm.areaValue = $$v
                                },
                                expression: "areaValue"
                              }
                            },
                            _vm._l(_vm.areaList, function(item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            model: {
                              value: _vm.attributeForm.areaValue,
                              callback: function($$v) {
                                _vm.$set(_vm.attributeForm, "areaValue", $$v)
                              },
                              expression: "attributeForm.areaValue"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogAttribute = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.pageLoading,
                      expression: "pageLoading",
                      modifiers: { fullscreen: true, lock: true }
                    }
                  ],
                  attrs: {
                    "element-loading-text": "数据保存中...",
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(255, 255, 255, 0.2)",
                    loading: _vm.btnSetAttribute,
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setAttributes()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.btnSetAttribute ? "保存中..." : "确定"))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuseWrapper",
          attrs: {
            visible: _vm.dialogRefuse,
            "close-on-press-escape": !_vm.dialogRefuse,
            "close-on-click-modal": !_vm.dialogRefuse,
            title: "驳回申请"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "batchRefuseForm",
              attrs: {
                model: _vm.batchRefuseForm,
                rules: _vm.batchRefuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.batchRefuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.batchRefuseForm, "reason", $$v)
                      },
                      expression: "batchRefuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObjects()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "audio-down-dialog",
          attrs: {
            visible: _vm.dialogDownloadAudio,
            "close-on-press-escape": !_vm.dialogDownloadAudio,
            "close-on-click-modal": !_vm.dialogDownloadAudio,
            title: "批量设置音频下载",
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDownloadAudio = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "audio-down" },
            [
              _vm._v("\n      允许下载\n      "),
              _c("el-switch", {
                staticClass: "download-switch",
                attrs: { "active-color": "#13ce66" },
                model: {
                  value: _vm.isDownLoadAudio,
                  callback: function($$v) {
                    _vm.isDownLoadAudio = $$v
                  },
                  expression: "isDownLoadAudio"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "tip" }, [_vm._v("开启则允许下载")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogDownloadAudio = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.pageLoading,
                      expression: "pageLoading",
                      modifiers: { fullscreen: true, lock: true }
                    }
                  ],
                  attrs: {
                    type: "primary",
                    size: "small",
                    "element-loading-text": "数据保存中...",
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(255, 255, 255, 0.2)",
                    loading: _vm.btnSetAttribute
                  },
                  on: { click: _vm.submitAudioDownLoad }
                },
                [_vm._v(_vm._s(_vm.btnSetAttribute ? "保存中..." : "保存"))]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showBatchAudit
        ? _c("batch-audit", {
            attrs: {
              showBatchAudit: _vm.showBatchAudit,
              reviewFunction: _vm.reviewFunction,
              auditArray: _vm.checkArr,
              operation: _vm.batch
            },
            on: {
              "update:showBatchAudit": function($event) {
                _vm.showBatchAudit = $event
              },
              "update:show-batch-audit": function($event) {
                _vm.showBatchAudit = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("batch-copy", {
        attrs: {
          showBatchCopy: _vm.showBatchCopy,
          batchCopyFunction: _vm.copyFunction,
          copyArray: _vm.copyList,
          typename: _vm.typename,
          "merchant-id": _vm.merchantId
        },
        on: {
          "update:showBatchCopy": function($event) {
            _vm.showBatchCopy = $event
          },
          "update:show-batch-copy": function($event) {
            _vm.showBatchCopy = $event
          }
        }
      }),
      _vm._v(" "),
      _c("resource-stop-sale", {
        attrs: {
          visible: _vm.dialogStopSale,
          openId: _vm.operationStr,
          "is-batch": true,
          handleBatchStopSale: _vm.handleBatchStopSale
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogStopSale = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }