"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ebookCaseDefault = _interopRequireDefault(require("@/assets/ebook-case-default.png"));

var _ebookDefault = _interopRequireDefault(require("@/assets/ebook-default.png"));

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'BookcaseContentItem',
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    handleCoverDisplay: function handleCoverDisplay(val) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-256';
      var type = arguments.length > 2 ? arguments[2] : undefined;

      if (!val[type]) {
        return val.coverType === 1 ? _ebookCaseDefault.default : _ebookDefault.default;
      }

      if (val[type].indexOf('http') !== -1) {
        return val[type];
      }

      return (0, _common.imageUrlFun)(val[type], style);
    },
    deleteBookcase: function deleteBookcase(item) {
      this.$emit('deleteBookcase', item);
    },
    openBookCase: function openBookCase(item) {
      this.$emit('openBookCase', item);
    },
    editBookcase: function editBookcase(item) {
      this.$emit('editBookcase', item);
    }
  }
};
exports.default = _default2;