var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-content",
    { attrs: { label: "基本信息", "full-width": true } },
    [
      _vm.valueProxy.productOpenId
        ? _c(
            "el-form-item",
            {
              staticClass: "width-limit",
              attrs: { label: "作品ID", prop: "productOpenId" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.valueProxy.productOpenId))])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "checkradio margibbottom width-limit",
          attrs: { label: "商品类型" }
        },
        [
          _c("resource-type-radio-group", {
            attrs: {
              data: _vm.resourceTypeCheckData,
              "disabled-edit": _vm.disabledResourceType
            },
            model: {
              value: _vm.valueProxy.resourceType,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "resourceType", $$v)
              },
              expression: "valueProxy.resourceType"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "hhind" }, [
            _vm._v("商品类型保存后将不可修改，请认真选择。")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.showIsSuit
        ? _c(
            "el-form-item",
            {
              staticClass: "checkradio margibbottom width-limit",
              attrs: { label: "是否套装" }
            },
            [
              _c("my-radio-group", {
                attrs: { data: _vm.checkData },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.valueProxy.isSuit,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "isSuit", $$v)
                  },
                  expression: "valueProxy.isSuit"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSpecificType
        ? _c(
            "el-form-item",
            {
              staticClass: "checkradio margibbottom width-limit",
              attrs: { label: "规格类型" }
            },
            [
              _c("my-radio-group", {
                attrs: { data: _vm.specificationTypeCheckData },
                model: {
                  value: _vm.valueProxy.specificType,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "specificType", $$v)
                  },
                  expression: "valueProxy.specificType"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showProductId
        ? _c(
            "el-form-item",
            {
              staticClass: "width-limit",
              attrs: { label: "ERP商品id", prop: "productId" }
            },
            [
              _c("input-with-button", {
                attrs: {
                  "disabled-edit": _vm.disabledEdit,
                  placeholder: "请填写ERP商品id编码",
                  "button-label": "导入",
                  "clear-validate": _vm.clearValidate
                },
                model: {
                  value: _vm.valueProxy,
                  callback: function($$v) {
                    _vm.valueProxy = $$v
                  },
                  expression: "valueProxy"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSkuCode
        ? _c(
            "el-form-item",
            {
              staticClass: "width-limit",
              attrs: { label: "sku编码", prop: "skuCode" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.valueProxy.skuCode))])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "width-limit",
          attrs: { label: "商品名称", prop: "title" }
        },
        [
          _c("el-input", {
            staticClass: "input-box",
            attrs: {
              disabled: _vm.disabledEdit,
              "show-word-limit": "",
              maxlength: 100,
              placeholder: "请填写商品名称",
              clearable: ""
            },
            model: {
              value: _vm.valueProxy.title,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "title", $$v)
              },
              expression: "valueProxy.title"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "width-limit", attrs: { label: "商品编号" } },
        [
          _c("el-input", {
            staticClass: "txt-width850",
            attrs: {
              disabled: _vm.disabledEdit,
              placeholder: "请输入自定义商品编号",
              maxlength: "100",
              "show-word-limit": "",
              clearable: ""
            },
            model: {
              value: _vm.valueProxy.productCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "productCode", $$v)
              },
              expression: "valueProxy.productCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showContentRequestList
        ? _c(
            "el-form-item",
            { attrs: { label: "商品内容" } },
            [
              _vm.valueProxy.resourceType === 21
                ? _c("edit-base-info-product-number-content", {
                    attrs: { "resource-type": _vm.valueProxy.resourceType },
                    model: {
                      value: _vm.valueProxy.contentRequestList,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "contentRequestList", $$v)
                      },
                      expression: "valueProxy.contentRequestList"
                    }
                  })
                : _c("edit-base-info-product-combination-content", {
                    attrs: { "resource-type": _vm.valueProxy.resourceType },
                    model: {
                      value: _vm.valueProxy.contentRequestList,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "contentRequestList", $$v)
                      },
                      expression: "valueProxy.contentRequestList"
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "width-limit", attrs: { label: "商品描述" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              disabled: _vm.disabledEdit,
              placeholder: "请输入商品描述"
            },
            model: {
              value: _vm.valueProxy.describeContent,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "describeContent", $$v)
              },
              expression: "valueProxy.describeContent"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "el-form-goodschli width-limit",
          attrs: { label: "商品重量" }
        },
        [
          _c("el-input-number", {
            attrs: {
              disabled: _vm.disabledEdit,
              precision: 3,
              step: 1,
              min: 0,
              max: 1000000,
              "controls-position": "right"
            },
            model: {
              value: _vm.valueProxy.weight,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "weight", $$v)
              },
              expression: "valueProxy.weight"
            }
          }),
          _vm._v(" "),
          _c("span", [_vm._v(" kg")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }