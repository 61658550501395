var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "e-wrap" },
    [
      _c(
        "multipane",
        { staticClass: "container-box", attrs: { layout: "vertical" } },
        [
          _c(
            "div",
            { class: { "pane-menu": true, "pane-menu-left": _vm.merchantId } },
            [
              _c("videoClass", {
                ref: "albumCatalog",
                attrs: { isebook: true, type: 20, routname: _vm.typename },
                on: {
                  "update-tree": _vm.getList,
                  "check-event": _vm.classChange
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.merchantId
            ? _c("multipane-resizer", {
                staticClass: "resize-split-bg iconfont icon-tuozhuaidaxiao"
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: { "e-body": true, "e-body-right": _vm.merchantId },
              style: { "flex-grow": _vm.merchantId ? 1 : 0 }
            },
            [
              _c(
                "div",
                { staticClass: "e-head" },
                [
                  _c("publictop", {
                    attrs: { typeName: _vm.typename, isebook: true },
                    on: {
                      "new-add": _vm.newPage,
                      searchbtnevent: _vm.searchBtnFun,
                      "merchant-id": _vm.searchMerchant
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "eBook",
                        fn: function() {
                          return [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "screen-botton",
                                model: {
                                  value: _vm.screenVal,
                                  callback: function($$v) {
                                    _vm.screenVal = $$v
                                  },
                                  expression: "screenVal"
                                }
                              },
                              [
                                _c("el-radio-button", {
                                  attrs: { label: "全部" }
                                }),
                                _vm._v(" "),
                                _c("el-radio-button", {
                                  attrs: { label: "已上架" }
                                }),
                                _vm._v(" "),
                                _c("el-radio-button", {
                                  attrs: { label: "未上架" }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticClass: "table-wrap",
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "calc(100% - 128px)",
                    data: _vm.tableData,
                    "empty-text": "暂无电子书",
                    "tooltip-effect": "dark"
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "电子书" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "content" }, [
                              _c(
                                "p",
                                { staticClass: "code" },
                                [
                                  _vm._v(
                                    "\n                作品编号:\n                "
                                  ),
                                  !scope.row.editCode
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.resourceEncode
                                              ? scope.row.resourceEncode
                                              : "--"
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.editCode
                                    ? _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { size: "small" },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.enterCode(scope.row)
                                          }
                                        },
                                        model: {
                                          value: _vm.resourceEncode,
                                          callback: function($$v) {
                                            _vm.resourceEncode = $$v
                                          },
                                          expression: "resourceEncode"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.editCode
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "edit-code-btn edit-code-btn-a",
                                          on: {
                                            click: function($event) {
                                              return _vm.enterCode(
                                                scope.row,
                                                "cancel"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("取消")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.editCode
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "edit-code-btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.enterCode(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("确定")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !scope.row.editCode
                                    ? _c("span", {
                                        staticClass: "iconfont icon-bianji3",
                                        on: {
                                          click: function($event) {
                                            return _vm.editRowCode(scope.row)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c("el-image", {
                                    class:
                                      scope.row.coverType == 1
                                        ? "cover-transverse"
                                        : "cover-vertical",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        scope.row.coverDisplay
                                      ),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "book-info" }, [
                                    _c("div", { staticClass: "common-flex" }, [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(scope.row.title))
                                      ]),
                                      _vm._v(" "),
                                      scope.row.containOpenIds
                                        ? _c(
                                            "div",
                                            { staticClass: "contain-tag" },
                                            [_vm._v("含夹册")]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "msg" }, [
                                      _vm._v(
                                        _vm._s(scope.row.syncInfoText) +
                                          " / " +
                                          _vm._s(scope.row.categoryInfoText)
                                      )
                                    ])
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "merchant" },
                                [
                                  _c("el-image", {
                                    staticClass: "mer-cover",
                                    attrs: {
                                      src: _vm.getImgUrl(scope.row.partnerLogo),
                                      fit: "cover"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.partnerName) +
                                      "\n                "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      "\n                  更新时间：" +
                                        _vm._s(scope.row.updatedAt) +
                                        "\n                "
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "操作", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "operation" }, [
                              _c(
                                "div",
                                { staticClass: "head_operation" },
                                [
                                  scope.row.ebookImageFolderPath
                                    ? _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { type: "success" }
                                        },
                                        [_vm._v("解析成功")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { type: "warning" }
                                        },
                                        [_vm._v("解析中")]
                                      ),
                                  _vm._v(" "),
                                  scope.row.status && scope.row.status == 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { type: "info" }
                                        },
                                        [_vm._v("未上架\n                ")]
                                      )
                                    : !scope.row.status || scope.row.status == 2
                                    ? _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: { type: "success" }
                                        },
                                        [_vm._v("已上架\n                ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              scope.row.tags
                                ? _c(
                                    "div",
                                    { staticClass: "tags" },
                                    _vm._l(scope.row.tags, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          staticClass: "tag",
                                          attrs: { size: "mini", type: "info" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _c("div", { staticClass: "tags" }, [
                                    _vm._v(
                                      "\n                 \n              "
                                    )
                                  ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "icons" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "预览",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-yulan",
                                        on: {
                                          click: function($event) {
                                            return _vm.showSharePreview(
                                              scope.row
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "编辑",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-bianji3",
                                        on: {
                                          click: function($event) {
                                            return _vm.editEBook(scope.row)
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !scope.row.folder
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "夹带书册",
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "iconfont icon-jiadaishuce",
                                            on: {
                                              click: function($event) {
                                                return _vm.folderEbook(
                                                  scope.row
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "图书内容设置",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont icon-tushuneirongshezhi",
                                        on: {
                                          click: function($event) {
                                            return _vm.toEbookContent(scope.row)
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown",
                                    {
                                      staticClass: "item",
                                      on: { command: _vm.itemOperation }
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: "更多",
                                            effect: "dark",
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-gengduo"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: {
                                                  type: "copy",
                                                  item: scope.row
                                                }
                                              }
                                            },
                                            [_vm._v("复制图书")]
                                          ),
                                          _vm._v(" "),
                                          scope.row.status &&
                                          scope.row.status == 1
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  attrs: {
                                                    command: {
                                                      type: "up",
                                                      item: scope.row
                                                    }
                                                  }
                                                },
                                                [_vm._v("图书上架")]
                                              )
                                            : !scope.row.status ||
                                              scope.row.status == 2
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  attrs: {
                                                    command: {
                                                      type: "down",
                                                      item: scope.row
                                                    }
                                                  }
                                                },
                                                [_vm._v("图书下架")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: {
                                                  type: "operation",
                                                  item: scope.row
                                                }
                                              }
                                            },
                                            [_vm._v("操作日志")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: {
                                                  type: "delete",
                                                  item: scope.row
                                                }
                                              }
                                            },
                                            [_vm._v("删除图书")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table-bottom-box" },
                [
                  _c(
                    "div",
                    [
                      _c("el-checkbox", {
                        staticClass: "pagination-check",
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.selectedAll,
                          callback: function($$v) {
                            _vm.selectedAll = $$v
                          },
                          expression: "selectedAll"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-dropdown",
                        {
                          attrs: { "split-button": "", type: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.listOperation($event)
                            },
                            command: _vm.handleCommand
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.batchName) +
                              "\n            "
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "up" } },
                                [_vm._v("批量上架")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "down" } },
                                [_vm._v("批量下架")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "delete" } },
                                [_vm._v("批量删除")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "copy" } },
                                [_vm._v("批量复制")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.total > 0
                    ? _c("el-pagination", {
                        attrs: {
                          total: _vm.total,
                          "pager-count": 5,
                          "current-page": _vm.listQuery.page,
                          "page-size": _vm.listQuery.size,
                          "page-sizes": _vm.pageSizes,
                          layout: "total, sizes, prev, pager, next, jumper",
                          background: ""
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.currentchange
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowOperationRecord
        ? _c("resource-operation-record", {
            attrs: {
              visible: _vm.isShowOperationRecord,
              isebook: true,
              resourceObj: _vm.operatoinResource
            },
            on: {
              "update:visible": function($event) {
                _vm.isShowOperationRecord = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确实删除本电子书吗？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteABook } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisibleAll, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleAll = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确实删除选中的电子书吗？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleAll = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteAllBook }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogCopyAll, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogCopyAll = $event
            }
          }
        },
        [
          _c("span", [
            _vm._v("确定要复制" + _vm._s(_vm.copyNumber) + "个作品吗？")
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogCopyAll = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.copyAllBook } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.eBookClassType == "add" ? "新增分类" : "编辑分类",
            visible: _vm.showEBookClass,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showEBookClass = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "classFormRef",
              attrs: { model: _vm.classFormInfo, "label-width": "80px" }
            },
            [
              _vm.eBookClassType == "add"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "父分类" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { disabled: "", placeholder: "/" },
                        model: {
                          value: _vm.classFormInfo.parentId,
                          callback: function($$v) {
                            _vm.$set(_vm.classFormInfo, "parentId", $$v)
                          },
                          expression: "classFormInfo.parentId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分类名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.classFormInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.classFormInfo, "name", $$v)
                      },
                      expression: "classFormInfo.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "50%" },
                    attrs: { "controls-position": "right" },
                    model: {
                      value: _vm.classFormInfo.sequence,
                      callback: function($$v) {
                        _vm.$set(_vm.classFormInfo, "sequence", $$v)
                      },
                      expression: "classFormInfo.sequence"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showEBookClass = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.eBookClass } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      }),
      _vm._v(" "),
      _c("ebook-attribute-dialog", {
        ref: "attribute",
        attrs: {
          visible: _vm.attributeDialog,
          "partner-open-id": _vm.merchantId,
          "operation-list": _vm.selectedOpenIds,
          "type-name": "电子书"
        },
        on: {
          "update:visible": function($event) {
            _vm.attributeDialog = $event
          },
          batchSetAttribute: _vm.batchSetAttribute
        }
      }),
      _vm._v(" "),
      _c("select-ebook-item-dialog", {
        ref: "folder",
        attrs: { visible: _vm.folderDialog },
        on: {
          "update:visible": function($event) {
            _vm.folderDialog = $event
          },
          onSubmitSelect: _vm.folderSubmit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }