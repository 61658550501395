var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true }
        }
      ],
      staticClass: "audio-book-wrapper",
      attrs: {
        "element-loading-text": "数据保存中...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)"
      }
    },
    [
      _c("publictop", {
        attrs: {
          value: _vm.listQuery,
          "partner-open-id-echo": _vm.merchantId,
          typeName: _vm.routname
        },
        on: {
          "new-add": _vm.showPage,
          searchbtnevent: _vm.searchBtnFun,
          "merchant-id": _vm.merchantswitch
        }
      }),
      _vm._v(" "),
      _c(
        "multipane",
        {
          staticClass: "container-wrapper vertical-panes",
          attrs: { layout: "vertical" },
          on: { paneResize: _vm.changeMenuSize }
        },
        [
          _c(
            "div",
            {
              staticClass: "pane-menu",
              style: { minWidth: "130px", width: "240px" }
            },
            [
              _c(
                "div",
                { staticClass: "left-content" },
                [
                  _c("Labelpage", {
                    attrs: {
                      state: _vm.state,
                      state_current: _vm.listQuery.status.toString(),
                      isWidthChange: _vm.isChangeWidth
                    },
                    on: { "state-currents": _vm.stateCurrents }
                  }),
                  _vm._v(" "),
                  _c("treeClass", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.merchantId,
                        expression: "merchantId"
                      }
                    ],
                    ref: "videoclass",
                    attrs: {
                      routname: _vm.routname,
                      type: _vm.resourceType,
                      "class-partner-id": _vm.classPartnerId
                    },
                    on: { "check-event": _vm.searchClass }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("multipane-resizer", {
            staticClass: "resize-split-bg iconfont icon-tuozhuaidaxiao"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pane", style: { flexGrow: 1 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                staticClass: "box-card-wrapper"
              },
              [
                _c("table-list", {
                  ref: "tableList",
                  staticClass: "audio-list-box",
                  attrs: {
                    list: _vm.lists,
                    "list-query": _vm.listQuery,
                    "copy-btn-disabled": _vm.copyBtnDisabled,
                    reason: _vm.reason,
                    "status-val-name": "status",
                    "resource-type-id": _vm.resourceType,
                    handleDel: _vm.bookDel,
                    passObj: _vm.passObj,
                    unPublish: _vm.batchUnpublish,
                    refuseObj: _vm.refuseObj,
                    objectRestore: _vm.handleBookRestore,
                    handleCancelHide: _vm.handleCancelBookHide,
                    handleHide: _vm.handleBookHide,
                    handleHaltSale: _vm.handleHaltSale,
                    handleSale: _vm.handleSale,
                    setRecommend: _vm.setRecommend,
                    cancelRecommend: _vm.cancelRecommend,
                    saveResourceEncode: _vm.updateAudioBookEncode
                  },
                  on: {
                    showPage: _vm.showPage,
                    handleCopy: _vm.handleBookCopy,
                    addObject: _vm.showPage,
                    selectObjs: _vm.selectObjs
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(book) {
                        return _c("div", { staticClass: "object-img-bg" }, [
                          book.cover
                            ? _c("img", {
                                staticClass: "object-cover-img",
                                attrs: {
                                  src: _vm.getImgUrl(book.cover),
                                  alt: ""
                                }
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("../../../assets/book_cover.png"),
                                  alt: ""
                                }
                              })
                        ])
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-bottom-box" },
                  [
                    _c("review-btn", {
                      ref: "reviewBtn",
                      attrs: {
                        typename: _vm.routname,
                        merchantId: _vm.merchantId,
                        status: _vm.listQuery.status.toString(),
                        "handle-batch-stop-sale": _vm.batchStopSale,
                        reviewFunction: _vm.passCheckItem
                      },
                      on: {
                        batchUnpublish: _vm.batchUnpublish,
                        batchRefuse: function($event) {
                          return _vm.handlebatchRefuse(arguments)
                        },
                        batchSetAttribute: function($event) {
                          return _vm.batchSetAttributes(arguments)
                        },
                        batchRecovery: _vm.handleBatchRecovery,
                        batchSaveDraft: _vm.batchSaveAudioBookDraft,
                        batchSaveRecycle: _vm.batchSaveAudioBookRecycle,
                        batchHiddenOrStopSale: _vm.batchHiddenOrStopSale
                      }
                    }),
                    _vm._v(" "),
                    _vm.total > 0
                      ? _c("el-pagination", {
                          attrs: {
                            total: _vm.total,
                            "current-page": _vm.listQuery.page,
                            "page-size": _vm.listQuery.size,
                            "page-sizes": _vm.pageSizes,
                            "pager-count": 5,
                            layout: "total, sizes, prev, pager, next, jumper",
                            background: ""
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.currentchange
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "copyBook",
          attrs: {
            visible: _vm.dialogCopyVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "复制点学书"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogCopyVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "copyForm",
              attrs: {
                rules: _vm.copyRules,
                model: _vm.bookCopy,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "图书名称：", prop: "title" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "book-titlebox-bg",
                    attrs: {
                      maxlength: 60,
                      debounce: 500,
                      "show-word-limit": "",
                      placeholder:
                        "请输入点学书名称（建议字数在30以内，不超过60个字）",
                      "fetch-suggestions": _vm.searchTitle
                    },
                    model: {
                      value: _vm.bookCopy.title,
                      callback: function($$v) {
                        _vm.$set(_vm.bookCopy, "title", $$v)
                      },
                      expression: "bookCopy.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联同复制：" } },
                [
                  _c("el-checkbox", {
                    attrs: { label: "目录" },
                    model: {
                      value: _vm.bookCopy.copyMenu,
                      callback: function($$v) {
                        _vm.$set(_vm.bookCopy, "copyMenu", $$v)
                      },
                      expression: "bookCopy.copyMenu"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-checkbox", {
                    attrs: { label: "正文" },
                    model: {
                      value: _vm.bookCopy.copyPage,
                      callback: function($$v) {
                        _vm.$set(_vm.bookCopy, "copyPage", $$v)
                      },
                      expression: "bookCopy.copyPage"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-checkbox", {
                    attrs: { label: "点学区及关联资源" },
                    model: {
                      value: _vm.bookCopy.copySection,
                      callback: function($$v) {
                        _vm.$set(_vm.bookCopy, "copySection", $$v)
                      },
                      expression: "bookCopy.copySection"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogCopyVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.copyBtn, type: "primary" },
                  on: { click: _vm.booksCopy }
                },
                [_vm._v(_vm._s(_vm.copyBtn ? "保存中..." : "复制"))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }