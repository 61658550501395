var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "questionCardBox" }, [
    _c(
      "div",
      {
        staticClass: "cardTop",
        on: {
          click: function($event) {
            return _vm.handleCheck(_vm.item.examQuestionOpenId)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "cardTopLeft" },
          [
            _c("el-checkbox", {
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.handleCheck(_vm.item.examQuestionOpenId)
                }
              },
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "leftText" }, [
              _vm._v("题型: " + _vm._s(_vm.item.questionTypeName))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "leftText" }, [
              _vm._v(
                "难易: " +
                  _vm._s(_vm._f("questionDegree")(_vm.item.difficultDegree))
              )
            ]),
            _vm._v(" "),
            _vm.item.tags.length >= 1 && _vm.item.tags[0] !== ""
              ? _c(
                  "span",
                  { staticClass: "leftText" },
                  [
                    _vm._v("\n        标签: "),
                    _vm._l(_vm.item.tags, function(tags, i) {
                      return _c("el-tag", { key: i, staticClass: "tags" }, [
                        _vm._v(_vm._s(tags))
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cardTopRight" },
          [
            _c(
              "span",
              {
                staticClass: "text",
                on: {
                  click: function($event) {
                    return _vm.emitType(_vm.item, "edit")
                  }
                }
              },
              [_vm._v("编辑")]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              { attrs: { trigger: "click" } },
              [
                _c(
                  "span",
                  { staticClass: "el-dropdown-link operationName text" },
                  [_vm._v("\n          更多\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.copy(_vm.item)
                          }
                        }
                      },
                      [_vm._v("\n            复制试题\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.moveClass(_vm.item)
                          }
                        }
                      },
                      [_vm._v("\n            转移分类\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      {
                        staticStyle: { color: "red" },
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.deleteQuestion(_vm.item)
                          }
                        }
                      },
                      [_vm._v("\n            删除试题\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "cardBody",
        on: {
          click: function($event) {
            return _vm.handleCheck(
              _vm.item.examQuestionOpenId,
              _vm.item.examQuestionTypeCode
            )
          }
        }
      },
      [
        _c("div", {
          staticClass: "stem",
          domProps: { innerHTML: _vm._s(_vm.item.stem) },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleCheck(_vm.item.examQuestionOpenId)
            }
          }
        }),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1001 ||
        _vm.item.examQuestionTypeCode === 1002
          ? _c(
              "div",
              [
                _vm._l(_vm.item.optionText[0], function(
                  question,
                  questionIndex
                ) {
                  return _c(
                    "div",
                    { key: questionIndex, staticClass: "item" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(questionIndex.toUpperCase()) +
                          ": "
                      ),
                      _c("span", { domProps: { innerHTML: _vm._s(question) } })
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "answer" }, [
                  _vm._v(
                    "\n        正确答案: " +
                      _vm._s(_vm.item.rightAnswerContent.toUpperCase()) +
                      "\n      "
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1003
          ? _c(
              "div",
              [
                _vm._l(_vm.item.optionText[0], function(
                  question,
                  questionIndex
                ) {
                  return _c(
                    "div",
                    {
                      key: questionIndex,
                      staticClass: "item connectionOptions"
                    },
                    [_c("div", { domProps: { innerHTML: _vm._s(question) } })]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "answer judge-flex" }, [
                  _c("span", { staticClass: "judge-title" }, [
                    _vm._v("正确答案:")
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.item.rightAnswerContent === "no"
                          ? _vm.item.optionText[0].b
                          : _vm.item.optionText[0].a
                      )
                    }
                  })
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1005
          ? _c("div", [
              _c("div", { staticClass: "answer write" }, [
                _vm._v("\n        正确答案:\n        "),
                _c("span", [_vm._v(_vm._s(_vm.item.answerList.toString()))])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1004
          ? _c("div", [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  _vm._l(_vm.item.optionText[0], function(
                    question,
                    questionIndex
                  ) {
                    return _c(
                      "div",
                      {
                        key: questionIndex,
                        staticClass: "item connectionOptions"
                      },
                      [
                        _c("div", { staticClass: "optionIndex" }, [
                          _c("div", [
                            _vm._v(_vm._s(questionIndex.toUpperCase()) + ":")
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            domProps: { innerHTML: _vm._s(question) }
                          })
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      color: "#909399"
                    }
                  },
                  _vm._l(_vm.item.optionList, function(option, optionIndex) {
                    return _c(
                      "div",
                      { key: optionIndex, staticClass: "option" },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex", margin: "5px" } },
                          [
                            _c("div", [_vm._v(_vm._s(optionIndex + 1))]),
                            _vm._v("."),
                            _c("div", {
                              domProps: { innerHTML: _vm._s(option) }
                            })
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "answer" },
                [
                  _c("div", [_vm._v("正确答案: ")]),
                  _vm._v(" "),
                  _vm._l(_vm.item.rightAnswerContent, function(
                    answer,
                    answerIndex
                  ) {
                    return _c("div", { key: answerIndex }, [
                      _c("div", { staticStyle: { margin: "5px" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(answerIndex.toUpperCase()) +
                            " -- " +
                            _vm._s(answer) +
                            "\n          "
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1006
          ? _c(
              "div",
              [
                _vm._l(_vm.item.optionText[0], function(option, optionIndex) {
                  return _c(
                    "div",
                    { key: optionIndex, staticClass: "item pxItem" },
                    [
                      _c("div", [_vm._v(_vm._s(optionIndex) + ":")]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "px",
                        domProps: { innerHTML: _vm._s(option) }
                      })
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "answer" }, [
                  _vm._v(
                    "\n        正确答案: " +
                      _vm._s(_vm.item.rightAnswerContent) +
                      "\n      "
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1007
          ? _c(
              "div",
              [
                _vm._l(_vm.item.optionList, function(option, optionIndex) {
                  return _c(
                    "div",
                    { key: optionIndex, staticClass: "item" },
                    [
                      _c("div", [_vm._v(_vm._s(optionIndex + 1) + ".")]),
                      _vm._v(" "),
                      _vm._l(option, function(secondItem, secondIndex) {
                        return _c("div", { key: secondIndex }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(secondIndex.toUpperCase()) +
                              ":" +
                              _vm._s(secondItem) +
                              "\n        "
                          )
                        ])
                      })
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "answer" },
                  [
                    _c("div", [_vm._v("正确答案: ")]),
                    _vm._v(" "),
                    _vm._l(_vm.item.rightAnswerContent[0], function(
                      answer,
                      answerIndex
                    ) {
                      return _c("div", { key: answerIndex }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(answerIndex) +
                            "." +
                            _vm._s(answer.toUpperCase()) +
                            "\n        "
                        )
                      ])
                    })
                  ],
                  2
                )
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1008
          ? _c("div", [
              _c("div", { staticClass: "answer" }, [
                _c("div", [
                  _vm._v("\n          正确答案:\n          "),
                  _c("div", { staticClass: "rightAnswer" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.item.rightAnswerContent) +
                        "\n          "
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1009
          ? _c("div", [
              _vm.item.subQuestionList && _vm.item.subQuestionList.length > 0
                ? _c(
                    "div",
                    { staticClass: "combination" },
                    [
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeName,
                            callback: function($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName"
                          }
                        },
                        _vm._l(_vm.item.subQuestionList, function(
                          subItem,
                          subIndex
                        ) {
                          return _c("el-tab-pane", {
                            key: subItem.examQuestionOpenId,
                            attrs: {
                              label: "试题" + (subIndex + 1),
                              name: "试题" + (subIndex + 1)
                            }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.item.subQuestionList, function(
                        subItem,
                        subIndex
                      ) {
                        return _c(
                          "div",
                          {
                            key: subIndex,
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleCheck(
                                  _vm.item.examQuestionOpenId
                                )
                              }
                            }
                          },
                          [
                            _vm.activeName === "试题" + (subIndex + 1)
                              ? _c("div", [
                                  _c("div", { staticClass: "title" }, [
                                    _c("div", {
                                      staticClass: "stem",
                                      domProps: {
                                        innerHTML: _vm._s(subItem.stem)
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  subItem.examQuestionTypeCode === 1001 ||
                                  subItem.examQuestionTypeCode === 1002
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(
                                            subItem.optionText[0],
                                            function(question, questionIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: questionIndex,
                                                  staticClass: "item"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(questionIndex) +
                                                      ": "
                                                  ),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        question
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "answer" }, [
                                            _vm._v(
                                              "\n                正确答案: " +
                                                _vm._s(
                                                  subItem.rightAnswerContent.toUpperCase()
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  subItem.examQuestionTypeCode === 1003
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(
                                            subItem.optionText[0],
                                            function(question, questionIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: questionIndex,
                                                  staticClass:
                                                    "item connectionOptions"
                                                },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        question
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "answer judge-flex"
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "judge-title" },
                                                [_vm._v("正确答案:")]
                                              ),
                                              _vm._v(" "),
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    subItem.rightAnswerContent ===
                                                      "no"
                                                      ? subItem.optionText[0].b
                                                      : subItem.optionText[0].a
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  subItem.examQuestionTypeCode === 1005
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "answer write" },
                                          [
                                            _vm._v(
                                              "\n                正确答案:\n                "
                                            ),
                                            _vm._l(
                                              subItem.rightAnswerContent[0],
                                              function(
                                                question,
                                                questionIndex
                                              ) {
                                                return _c(
                                                  "span",
                                                  { key: questionIndex },
                                                  [
                                                    _vm._v(
                                                      _vm._s(question[0]) + ","
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  subItem.examQuestionTypeCode === 1004
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              _vm._l(
                                                subItem.optionText[0],
                                                function(
                                                  question,
                                                  questionIndex
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: questionIndex,
                                                      staticClass:
                                                        "item connectionOptions"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "optionIndex"
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                questionIndex
                                                              ) + ":"
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                question
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "column"
                                                }
                                              },
                                              _vm._l(
                                                subItem.optionList,
                                                function(option, optionIndex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: optionIndex,
                                                      staticClass: "option"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            margin: "5px"
                                                          }
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                optionIndex + 1
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v("."),
                                                          _c("div", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                option
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "answer" },
                                          [
                                            _c("div", [_vm._v("正确答案: ")]),
                                            _vm._v(" "),
                                            _vm._l(
                                              subItem.rightAnswerContent,
                                              function(answer, answerIndex) {
                                                return _c(
                                                  "div",
                                                  { key: answerIndex },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          margin: "5px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              answerIndex
                                                            ) +
                                                            " -- " +
                                                            _vm._s(answer) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  subItem.examQuestionTypeCode === 1006
                                    ? _c(
                                        "div",
                                        [
                                          _vm._l(
                                            subItem.optionText[0],
                                            function(option, optionIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: optionIndex,
                                                  staticClass: "item"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(optionIndex) + ":"
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "px",
                                                    domProps: {
                                                      innerHTML: _vm._s(option)
                                                    }
                                                  })
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "answer" }, [
                                            _vm._v(
                                              "\n                正确答案: " +
                                                _vm._s(
                                                  subItem.rightAnswerContent
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  subItem.examQuestionTypeCode === 1008
                                    ? _c("div", [
                                        _c("div", { staticClass: "answer" }, [
                                          _c("div", [
                                            _vm._v(
                                              "\n                  正确答案:\n                  "
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "rightAnswer" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      subItem.rightAnswerContent
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.examQuestionTypeCode === 1010
          ? _c(
              "div",
              _vm._l(_vm.item.optionText, function(option, optionIndex) {
                return _c("div", { key: optionIndex, staticClass: "item" }, [
                  _c("div", [
                    _vm._v(
                      "\n          " + _vm._s(optionIndex + 1) + ".\n          "
                    ),
                    _c("div", [_vm._v("内容:" + _vm._s(option.content))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("内容翻译:" + _vm._s(option.translation))
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "音频文件:" +
                          _vm._s(
                            option.filePath === "" ? "暂无" : option.filePath
                          )
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }