var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品清单",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": true,
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            width: "1000px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "background-color": "#F5F7FA", padding: "10px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "#FFFFFF",
                    height: "600px",
                    overflow: "hidden",
                    width: "100%",
                    "overflow-y": "scroll"
                  }
                },
                _vm._l(_vm.goodsList, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "10px 12px",
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "goodsCover",
                                  attrs: {
                                    fit: "contain",
                                    src: _vm.getGoodsCover(item)
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "goodsCover",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                display: "flex",
                                "flex-direction": "column",
                                height: "70px",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "rgba(42, 83, 107, 1)",
                                    "font-size": "16px",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    display: "-webkit-box",
                                    "-webkit-line-clamp": "1",
                                    "line-clamp": "1",
                                    "word-break": "break-all",
                                    "-webkit-box-orient": "vertical"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " + _vm._s(item.title)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "rgba(102, 129, 144, 1)",
                                    "font-size": "12px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.showVal(item.specification))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              item.payPrice
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "rgba(235, 88, 59, 1)",
                                        "font-size": "14px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "¥ " +
                                          _vm._s(
                                            _vm.showVal(
                                              Number(
                                                item.payPrice / 100
                                              ).toFixed(2)
                                            )
                                          )
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "rgba(235, 88, 59, 1)",
                                        "font-size": "14px"
                                      }
                                    },
                                    [_vm._v("--")]
                                  )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-divider")
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }