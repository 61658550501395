"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  emojiList: [
  /* 新的 */
  [{
    url: '115.gif',
    alt: '[撇嘴]'
  }, {
    url: '116.gif',
    alt: '[色]'
  }, {
    url: '105.gif',
    alt: '[发呆]'
  }, {
    url: '104.gif',
    alt: '[得意]'
  }, {
    url: '103.gif',
    alt: '[大哭]'
  }, {
    url: '110.gif',
    alt: '[害羞]'
  }, {
    url: '101.gif',
    alt: '[闭嘴]'
  }, {
    url: '112.gif',
    alt: '[瞌睡]'
  }, {
    url: '107.gif',
    alt: '[生气]'
  }, {
    url: '122.gif',
    alt: '[惊讶]'
  }, {
    url: '114.gif',
    alt: '[难过]'
  }, {
    url: '100.gif',
    alt: '[白眼]'
  }, {
    url: '120.gif',
    alt: '[哼]'
  }, {
    url: '121.gif',
    alt: '[加油]'
  }, {
    url: '119.gif',
    alt: '[疑问]'
  }, {
    url: '123.gif',
    alt: '[晕]'
  }, {
    url: '124.gif',
    alt: '[再见]'
  }, {
    url: '109.gif',
    alt: '[鼓掌]'
  }, {
    url: '118.gif',
    alt: '[尴尬]'
  }, {
    url: '111.gif',
    alt: '[坏笑]'
  }, {
    url: '113.gif',
    alt: '[卖萌]'
  }, {
    url: '108.gif',
    alt: '[盯]'
  }, {
    url: '102.gif',
    alt: '[nice]'
  }, {
    url: '117.gif',
    alt: '[捂脸]'
  }, {
    url: '106.gif',
    alt: '[沉迷学习]'
  }],
  /* 下面是旧的 */
  [{
    url: '1.gif',
    alt: '[星妹拜托]'
  }, {
    url: '2.gif',
    alt: '[星妹鄙视]'
  }, {
    url: '3.gif',
    alt: '[星妹擦汗]'
  }, {
    url: '4.gif',
    alt: '[星妹大哭]'
  }, {
    url: '5.gif',
    alt: '[星妹大喜]'
  }, {
    url: '6.gif',
    alt: '[星妹点头]'
  }, {
    url: '7.gif',
    alt: '[星妹点赞]'
  }, {
    url: '8.gif',
    alt: '[星妹飞吻]'
  }, {
    url: '9.gif',
    alt: '[星妹鼓掌]'
  }, {
    url: '10.gif',
    alt: '[星妹害怕]'
  }, {
    url: '11.gif',
    alt: '[星妹哼]'
  }, {
    url: '12.gif',
    alt: '[星妹坏笑]'
  }, {
    url: '13.gif',
    alt: '[星妹惊讶]'
  }, {
    url: '14.gif',
    alt: '[星妹静]'
  }, {
    url: '15.gif',
    alt: '[星妹可怜]'
  }, {
    url: '16.gif',
    alt: '[星妹摸头]'
  }, {
    url: '17.gif',
    alt: '[星妹敲]'
  }, {
    url: '18.gif',
    alt: '[星妹窃喜]'
  }, {
    url: '19.gif',
    alt: '[星妹亲亲]'
  }, {
    url: '20.gif',
    alt: '[星妹吐]'
  }, {
    url: '21.gif',
    alt: '[星妹谢谢]'
  }, {
    url: '22.gif',
    alt: '[星妹欣喜]'
  }, {
    url: '23.gif',
    alt: '[星妹摇头]'
  }, {
    url: '24.gif',
    alt: '[星妹疑问]'
  }, {
    url: '25.gif',
    alt: '[星妹晕]'
  }, {
    url: '26.gif',
    alt: '[星妹再见]'
  }, {
    url: '27.gif',
    alt: '[星妹着急]'
  }, {
    url: '28.gif',
    alt: '[星妹住嘴]'
  }, {
    url: '29.gif',
    alt: '[星妹抓狂]'
  }, {
    url: '30.gif',
    alt: '[星妹遵命]'
  }, {
    url: '31.gif',
    alt: '[星仔OK]'
  }, {
    url: '32.gif',
    alt: '[星仔爱]'
  }, {
    url: '33.gif',
    alt: '[星仔比心]'
  }, {
    url: '34.gif',
    alt: '[星仔鄙视]'
  }, {
    url: '35.gif',
    alt: '[星仔不屑]'
  }, {
    url: '36.gif',
    alt: '[星仔擦汗]'
  }, {
    url: '37.gif',
    alt: '[星仔吃惊]'
  }, {
    url: '38.gif',
    alt: '[星仔大哭]'
  }, {
    url: '39.gif',
    alt: '[星仔大笑]'
  }, {
    url: '40.gif',
    alt: '[星仔鼓掌]'
  }, {
    url: '41.gif',
    alt: '[星仔害怕]'
  }, {
    url: '42.gif',
    alt: '[星仔坏笑]'
  }, {
    url: '43.gif',
    alt: '[星仔敬礼]'
  }, {
    url: '44.gif',
    alt: '[星仔酷]'
  }, {
    url: '45.gif',
    alt: '[星仔礼貌]'
  }, {
    url: '46.gif',
    alt: '[星仔摸头]'
  }, {
    url: '47.gif',
    alt: '[星仔难过]'
  }, {
    url: '48.gif',
    alt: '[星仔祈祷]'
  }, {
    url: '49.gif',
    alt: '[星仔碎]'
  }, {
    url: '50.gif',
    alt: '[星仔偷笑]'
  }, {
    url: '51.gif',
    alt: '[星仔吐]'
  }, {
    url: '52.gif',
    alt: '[星仔飞吻]'
  }, {
    url: '53.gif',
    alt: '[星仔嘘]'
  }, {
    url: '54.gif',
    alt: '[星仔摇头]'
  }, {
    url: '55.gif',
    alt: '[星仔疑问]'
  }, {
    url: '56.gif',
    alt: '[星仔晕]'
  }, {
    url: '57.gif',
    alt: '[星仔再见]'
  }, {
    url: '58.gif',
    alt: '[星仔赞]'
  }, {
    url: '59.gif',
    alt: '[星仔住嘴]'
  }, {
    url: '60.gif',
    alt: '[星仔抓狂]'
  }, {
    url: '61.gif',
    alt: '[酷]'
  }, {
    url: '62.gif',
    alt: '[流鼻涕]'
  }, {
    url: '63.gif',
    alt: '[坏笑]'
  }, {
    url: '64.gif',
    alt: '[笑哭]'
  }, {
    url: '65.gif',
    alt: '[色]'
  }, {
    url: '66.gif',
    alt: '[害羞]'
  }]]
};
exports.default = _default;