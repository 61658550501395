"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.is-nan");

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _collect = _interopRequireDefault(require("collect.js"));

var _priceParser = require("@taozhi/price-parser");

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _index = _interopRequireDefault(require("../../General/Content/CommodityCell/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPM_Gr_Content_index',
  components: {
    CommodityCell: _index.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      type: String,
      default: undefined
    },
    select: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectType: {
      type: String,
      default: undefined
    },
    priceHidden: {
      type: Boolean,
      default: false
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showLowPrice: {
      type: Boolean,
      default: true
    },
    showWholeLowPrice: {
      type: Boolean,
      default: false
    },
    showStoreLowPrice: {
      type: Boolean,
      default: false
    },
    allInfoSelect: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showSelection: {
      type: Boolean,
      default: true
    },
    showInventory: {
      type: Boolean,
      default: true
    },
    showLocalInventory: {
      type: Boolean,
      default: false
    },
    onlyRead: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectProxy: {
      get: function get() {
        return this.select;
      },
      set: function set(value) {
        this.$emit('update:select', value);
      }
    },
    selectTypeProxy: {
      get: function get() {
        return this.selectType;
      },
      set: function set(value) {
        this.$emit('update:selectType', value);
      }
    },
    excludeDataSeted: function excludeDataSeted() {
      // 设置过 excludeData
      return (0, _typeof2.default)(this.excludeData) === 'object' && this.excludeData !== null && Array.isArray(this.excludeData);
    },
    showSkuCode: function showSkuCode() {
      return false;
    },
    showIsSuit: function showIsSuit() {
      return false;
    },
    allInfoSelectProxy: {
      get: function get() {
        return this.allInfoSelect;
      },
      set: function set(value) {
        this.$emit('update:allInfoSelect', value);
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        var _this = this;

        this.$nextTick(function () {
          if (_this.$refs.table) {
            _this.$refs.table.doLayout();
          }
        });
      }
    }
  },
  methods: {
    rtIsDigitalGoods: function rtIsDigitalGoods(value) {
      return value === 21;
    },
    // 商品类型：是否是数字商品
    rtIsCombinationProducts: function rtIsCombinationProducts(value) {
      return value === 22;
    },
    // 商品类型：是否是组合商品
    rtIsPhysicalCommodity: function rtIsPhysicalCommodity(value) {
      return !this.rtIsDigitalGoods(value) && !this.rtIsCombinationProducts(value);
    },
    // 商品类型：是否是实物商品
    stIsSingleSpecification: function stIsSingleSpecification(value) {
      return value === 0;
    },
    // 规格类型：是否是单规格
    stIsMultipleSpecifications: function stIsMultipleSpecifications(value) {
      return !this.stIsSingleSpecification(value);
    },
    // 规格类型：是否是多规格
    doLayout: function doLayout() {
      this.$refs.table.doLayout();
    },
    toggleAllSelection: function toggleAllSelection(value) {
      this.$refs.table.toggleAllSelection(value);
    },
    numberFormatter: function numberFormatter(row, column, cellValue) {
      if (typeof cellValue !== 'number') {
        return 0;
      }

      if (Number.isNaN(cellValue)) {
        return 0;
      }

      if (!Number.isFinite(cellValue)) {
        return 0;
      }

      return cellValue;
    },
    emptyFormatter: function emptyFormatter(row, column, cellValue) {
      if (typeof cellValue === 'undefined') {
        return '--';
      }

      if ((0, _typeof2.default)(cellValue) === 'object' && cellValue === null) {
        return '--';
      }

      return cellValue;
    },
    hasFormatter: function hasFormatter(row, column, cellValue) {
      if (typeof cellValue === 'undefined') {
        return '--';
      }

      if ((0, _typeof2.default)(cellValue) === 'object' && cellValue === null) {
        return '--';
      }

      return cellValue ? '有' : '无';
    },
    booleanFormatter: function booleanFormatter(row, column, cellValue) {
      if (typeof cellValue === 'undefined') {
        return '--';
      }

      if ((0, _typeof2.default)(cellValue) === 'object' && cellValue === null) {
        return '--';
      }

      return cellValue ? '是' : '否';
    },
    quotaFormatter: function quotaFormatter(row, column, cellValue) {
      if (typeof cellValue === 'undefined') {
        return '--';
      }

      if ((0, _typeof2.default)(cellValue) === 'object' && cellValue === null) {
        return '--';
      }

      return cellValue === 0 ? '不限' : cellValue;
    },
    priceFormatter: function priceFormatter(row, column, cellValue) {
      if (this.rtIsPhysicalCommodity(row.resourceType)) {
        if (this.stIsSingleSpecification(row.specificType)) {
          return (0, _priceParser.priceToYuanFixed)(cellValue);
        }
      }

      return '--';
    },
    discountFormatter: function discountFormatter(row, column, cellValue) {
      var localization = "".concat((0, _priceParser.discountToRebate)(cellValue), " \u6298");

      if (this.rtIsPhysicalCommodity(row.resourceType)) {
        if (this.stIsSingleSpecification(row.specificType)) {
          var price = (0, _priceParser.calcPriceYuanFixedByDiscount)(row.orginalPrice, cellValue);
          return "".concat(localization, " / ").concat(price);
        }
      }

      return '--';
    },
    resourceTypeFormatter: function resourceTypeFormatter(row, column, cellValue) {
      if (typeof cellValue === 'undefined') {
        return '--';
      }

      if ((0, _typeof2.default)(cellValue) === 'object' && cellValue === null) {
        return '--';
      }

      return {
        20: '实物商品',
        21: '数字商品',
        22: '组合商品'
      }[cellValue];
    },
    specificTypeFormatter: function specificTypeFormatter(row, column, cellValue) {
      if (this.rtIsPhysicalCommodity(row.resourceType)) {
        return this.stIsMultipleSpecifications(cellValue) ? '有' : '无';
      }

      return '--';
    },
    inventoryFormatter: function inventoryFormatter(row, column, cellValue) {
      if (this.rtIsPhysicalCommodity(row.resourceType)) {
        if (this.stIsSingleSpecification(row.specificType)) {
          return this.numberFormatter(row, column, cellValue);
        }
      }

      return '--';
    },
    selectCall: function selectCall(row) {// this.$refs.table.toggleRowSelection(row);
    },
    handleSelectionChange: function handleSelectionChange(value) {
      this.allInfoSelectProxy = value;
      this.selectProxy = (0, _collect.default)(value).map(function (i) {
        return i.productOpenId;
      }).all();
      this.updateSelectType(value);
    },
    updateSelectType: function updateSelectType(value) {
      if (!_main.default.isObject(value) || !_main.default.isArray(value)) {
        this.selectTypeProxy = 'NO';
      }

      if (value.length < 1) {
        this.selectTypeProxy = 'NO';
      } else if (value.length === this.data.length) {
        this.selectTypeProxy = 'ALL';
      } else {
        this.selectTypeProxy = 'HALF';
      }
    },
    typeCellClassName: function typeCellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column;

      if (column.property === 'type') {
        return row.type === 1 ? 'type-distribution' : 'type-self-operated';
      }

      if (column.property === 'state') {
        return row.state === 3 ? 'state-in-stock' : 'state-not-sold';
      }

      if (column.property === 'resourceType') {
        return {
          20: 'resource-type-physical-goods',
          21: 'resource-type-digital-goods',
          22: 'resource-type-combination-goods'
        }[row.resourceType];
      }

      return undefined;
    },
    initTableSelect: function initTableSelect() {
      var _this2 = this;

      if (!this.selectProxy || this.selectProxy.length < 1) {
        return;
      }

      this.data.filter(function (i) {
        return _this2.selectProxy.includes(i.productOpenId);
      }).forEach(function (i) {
        _this2.$refs.table.toggleRowSelection(i);
      });
    },
    selectable: function selectable(row) {
      if (!this.excludeDataSeted) {
        return true;
      }

      return !(0, _collect.default)(this.excludeData).contains(function (i) {
        return i.resourceOpenId === row.productOpenId;
      });
    },
    tableRowClassName: function tableRowClassName(_ref2) {
      var row = _ref2.row;

      if (!this.excludeDataSeted) {
        return '';
      }

      return this.selectable(row) ? '' : 'disabled-row';
    }
  }
};
exports.default = _default2;