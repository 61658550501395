var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "learn-record-wrap" }, [
    _c(
      "div",
      { staticClass: "learn-record-header" },
      [
        _c(
          "el-card",
          { staticClass: "learn-time-item", attrs: { shadow: "hover" } },
          [
            _c("div", { staticClass: "lear-times" }, [
              _vm._v("今日学习时长(分钟)")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lear-times-mum" }, [
              _vm._v(_vm._s(Math.ceil(_vm.studyDetail.todayStudyDuration / 60)))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "learn-time-item", attrs: { shadow: "hover" } },
          [
            _c("div", { staticClass: "lear-times" }, [
              _vm._v("累计学习时长（分钟）")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lear-times-mum" }, [
              _vm._v(
                _vm._s(Math.ceil(_vm.studyDetail.cumulativeStudyDuration / 60))
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "learn-time-item", attrs: { shadow: "hover" } },
          [
            _c("div", { staticClass: "lear-times" }, [_vm._v("累计学习天数")]),
            _vm._v(" "),
            _c("div", { staticClass: "lear-times-mum" }, [
              _vm._v(_vm._s(_vm.studyDetail.cumulativeStudyDayNum))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "learn-time-item", attrs: { shadow: "hover" } },
          [
            _c("div", { staticClass: "lear-times" }, [
              _vm._v("累计学习课时数")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lear-times-mum" }, [
              _vm._v(_vm._s(_vm.studyDetail.cumulativeStudyLessonNum))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "learn-time-item", attrs: { shadow: "hover" } },
          [
            _c("div", { staticClass: "lear-times" }, [
              _vm._v("累计学习课程数")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lear-times-mum" }, [
              _vm._v(_vm._s(_vm.studyDetail.cumulativeStudyCourseNum))
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "learn-record-content" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "learn-record-border-card",
            attrs: { type: "border-card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "学习时长", name: "学习时长" } },
              [
                _vm.currentActiveName === "学习时长"
                  ? _c("learn-time", { attrs: { userOpenId: _vm.userOpenId } })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "学习记录", name: "学习记录" } },
              [
                _vm.currentActiveName === "学习记录"
                  ? _c("learn-record", {
                      attrs: { userOpenId: _vm.userOpenId }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }