var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions" },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "选择选区", placement: "left" }
        },
        [
          _c(
            "div",
            {
              staticClass: "action",
              class: { active: _vm.curAction === "drag" },
              on: { click: _vm.onDrag }
            },
            [_c("svg-icon", { attrs: { "icon-class": "cursor" } })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "新建选区", placement: "left" }
        },
        [
          _c(
            "div",
            {
              staticClass: "action",
              class: { active: _vm.curAction === "addSection" },
              on: { click: _vm.onAddSection }
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "photo-size-select-small" }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "新建图片选区", placement: "left" }
        },
        [
          _c(
            "div",
            {
              staticClass: "action",
              class: { active: _vm.curAction === "addIcon" },
              on: { click: _vm.selectIcons }
            },
            [_c("svg-icon", { attrs: { "icon-class": "image" } })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "新建遮罩选区", placement: "left" }
        },
        [
          _c(
            "div",
            {
              staticClass: "action",
              class: { active: _vm.curAction === "addMaskSection" },
              on: { click: _vm.onAddMaskSection }
            },
            [_c("svg-icon", { attrs: { "icon-class": "mask-section" } })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "选择动图选区", placement: "left" }
        },
        [
          _c(
            "div",
            {
              staticClass: "action",
              class: { active: _vm.curAction === "addGifSection" },
              on: { click: _vm.selectGifSection }
            },
            [_c("svg-icon", { attrs: { "icon-class": "gif-section" } })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "新建评论选区", placement: "left" }
        },
        [
          _c(
            "div",
            {
              staticClass: "action",
              class: { active: _vm.curAction === "addCommentSection" },
              on: { click: _vm.onAddCommentSection }
            },
            [_c("i", { staticClass: "el-icon-chat-line-square" })]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "放大", placement: "left" }
        },
        [
          _c("div", { staticClass: "action", on: { click: _vm.zoomInArea } }, [
            _c("i", { staticClass: "el-icon-zoom-in" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "缩小", placement: "left" }
        },
        [
          _c(
            "div",
            { staticClass: "action space", on: { click: _vm.zoomOutArea } },
            [_c("i", { staticClass: "el-icon-zoom-out" })]
          )
        ]
      ),
      _vm._v(" "),
      _c("icons", { ref: "icon_dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }