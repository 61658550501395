var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grading-layout" }, [
    _c("div", { staticClass: "grading-layout-left" }, [_vm._t("left")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grading-layout-right" },
      [
        _vm.showQueryType
          ? _c(
              "el-select",
              {
                staticClass: "resource-type-select1",
                attrs: { placeholder: "营销商品类型" },
                on: { change: _vm.searchChanged },
                model: {
                  value: _vm.valueProxy.type,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "type", $$v)
                  },
                  expression: "valueProxy.type"
                }
              },
              _vm._l(_vm.marketingProductTypes, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showResourceType
          ? _c(
              "el-select",
              {
                staticClass: "resource-type-select",
                attrs: { placeholder: "商品类型", clearable: "" },
                on: { change: _vm.searchChanged },
                model: {
                  value: _vm.valueProxy.resourceType,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "resourceType", $$v)
                  },
                  expression: "valueProxy.resourceType"
                }
              },
              _vm._l(_vm.resourceTypeOptions, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("search-group", {
              attrs: {
                placeholder: _vm.searchPlaceholder,
                "has-state": _vm.hasState,
                "show-inventory": _vm.showInventory,
                "show-local-inventory": _vm.showLocalInventory,
                "use-merchant-brand-series": _vm.useMerchantBrandSeries,
                "partner-open-id": _vm.partnerOpenId,
                "preset-partner-open-id": _vm.presetPartnerOpenId
              },
              on: { "search-changed": _vm.initiativeSearchChanged },
              model: {
                value: _vm.valueProxy,
                callback: function($$v) {
                  _vm.valueProxy = $$v
                },
                expression: "valueProxy"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }