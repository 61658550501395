var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "videoInfo",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.videoInfo,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _vm.resourceType === "10"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文件封面" } },
                    [
                      _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: _vm.SINGLE_FILE_ID.toString()
                        },
                        on: { deletecover: _vm.delCover },
                        model: {
                          value: _vm.videoInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "cover", $$v)
                          },
                          expression: "videoInfo.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "可预览页数" } }, [
                    _c(
                      "div",
                      { staticClass: "preview" },
                      [
                        _c("el-input-number", {
                          attrs: { "controls-position": "right", min: 0 },
                          model: {
                            value: _vm.videoInfo.previewLength,
                            callback: function($$v) {
                              _vm.$set(_vm.videoInfo, "previewLength", $$v)
                            },
                            expression: "videoInfo.previewLength"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "preview-checkbox" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.videoInfo.preview,
                                callback: function($$v) {
                                  _vm.$set(_vm.videoInfo, "preview", $$v)
                                },
                                expression: "videoInfo.preview"
                              }
                            }),
                            _c("span", { staticClass: "preview-margin" }, [
                              _vm._v("未购买不可预览")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "preview-checkbox" }, [
                          _c("i", {
                            staticClass: "el-icon-info",
                            staticStyle: {
                              color: "#409EFF",
                              "margin-right": "5px"
                            }
                          }),
                          _c("span", { staticClass: "preview-txt" }, [
                            _vm._v("默认为0，代表不限制预览页数")
                          ])
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "文件描述" } },
                    [
                      _c("editor-view", {
                        model: {
                          value: _vm.videoInfo.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.videoInfo, "detail", $$v)
                          },
                          expression: "videoInfo.detail"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("el-form-item", { attrs: { label: "预览时长" } }, [
                    _c("div", { staticClass: "card-lz-content upload-video" }, [
                      _c("div", { staticClass: "display-flex" }, [
                        _c(
                          "div",
                          { staticClass: "display-flex video-duration-text" },
                          [
                            _c(
                              "div",
                              { staticClass: "display-flex video-duration" },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    precision: 0,
                                    step: 1,
                                    min: 0,
                                    "controls-position": "right"
                                  },
                                  model: {
                                    value: _vm.videoInfo.previewLength,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.videoInfo,
                                        "previewLength",
                                        $$v
                                      )
                                    },
                                    expression: "videoInfo.previewLength"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "line" }, [
                                  _vm._v("秒")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "video-text" }, [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: { color: "#409EFF" }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                若视频时长少于预览时长，则将设置视频时长的前1/3为预览时长\n              "
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("作品属性")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "同步" } },
            [
              _c("sync-view", {
                staticStyle: { width: "850px" },
                on: { "sync-name": _vm.syncName },
                model: {
                  value: _vm.videoInfo.tempCode,
                  callback: function($$v) {
                    _vm.$set(_vm.videoInfo, "tempCode", $$v)
                  },
                  expression: "videoInfo.tempCode"
                }
              }),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: { value: _vm.videoInfo.resourceSyncId }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分类", prop: "types", required: "" } },
            [
              _c("works", {
                ref: "worksView",
                on: { "add-classtion": _vm.addclasstion }
              }),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: { value: _vm.videoInfo.types }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "版次" } },
            [
              _c("edition-box", {
                staticStyle: { width: "850px" },
                model: {
                  value: _vm.videoInfo.periodCode,
                  callback: function($$v) {
                    _vm.$set(_vm.videoInfo, "periodCode", $$v)
                  },
                  expression: "videoInfo.periodCode"
                }
              }),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                model: {
                  value: _vm.videoInfo.periodCode,
                  callback: function($$v) {
                    _vm.$set(_vm.videoInfo, "periodCode", $$v)
                  },
                  expression: "videoInfo.periodCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "类目", prop: "secondRegimentationIds" } },
            [
              _c("category-view", {
                staticStyle: { width: "850px" },
                on: {
                  "category-change": _vm.categoryChange,
                  "category-text": _vm.categoryValue
                },
                model: {
                  value: _vm.videoInfo.secondRegimentationIds,
                  callback: function($$v) {
                    _vm.$set(_vm.videoInfo, "secondRegimentationIds", $$v)
                  },
                  expression: "videoInfo.secondRegimentationIds"
                }
              }),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                model: {
                  value: _vm.videoInfo.secondRegimentationIds,
                  callback: function($$v) {
                    _vm.$set(_vm.videoInfo, "secondRegimentationIds", $$v)
                  },
                  expression: "videoInfo.secondRegimentationIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { transition: "all .4s", overflow: "hidden" },
              style: { height: _vm.showSelect ? "112px" : "0" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "地区" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "850px" },
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "default-first-option": "",
                        "value-key": "id",
                        placeholder: "请输入或选择地区"
                      },
                      model: {
                        value: _vm.areaValue,
                        callback: function($$v) {
                          _vm.areaValue = $$v
                        },
                        expression: "areaValue"
                      }
                    },
                    _vm._l(_vm.areaList, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "850px" },
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "请输入或选择商品标签"
                      },
                      on: { change: _vm.changeTags },
                      model: {
                        value: _vm.tagValue,
                        callback: function($$v) {
                          _vm.tagValue = $$v
                        },
                        expression: "tagValue"
                      }
                    },
                    _vm._l(_vm.tagList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "showSelect",
              on: {
                click: function($event) {
                  _vm.showSelect = !_vm.showSelect
                }
              }
            },
            [
              _c("span", {
                staticClass: "el-icon-arrow-up",
                staticStyle: { transition: "all .4s" },
                style: {
                  transform: _vm.showSelect ? "rotate(0)" : "rotate(-180deg)"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("商品设置")]),
          _vm._v(" "),
          _c("goods-set", {
            ref: "commodity",
            attrs: {
              "partner-open-id": _vm.partnerOpenId,
              disabledEdit: false,
              "independent-sale": _vm.independentSale,
              "resource-open-id": _vm.videoId,
              "type-name": _vm.getResourceName
            },
            on: { "gold-star": _vm.goldStar }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }