"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getXiaoTaoTotal = getXiaoTaoTotal;
exports.getTodayGptData = getTodayGptData;
exports.getYesterdayGptData = getYesterdayGptData;
exports.getNearlySevenDaysGptData = getNearlySevenDaysGptData;
exports.getMonthGptData = getMonthGptData;
exports.getYearGptData = getYearGptData;
exports.queryLineChart = queryLineChart;
exports.queryApiUsage = queryApiUsage;
exports.getGPTYuShe = getGPTYuShe;
exports.saveGPTYuShe = saveGPTYuShe;
exports.queryConversationList = queryConversationList;
exports.dialogList = dialogList;
exports.queryConversationId = queryConversationId;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/notification/admin/"), {
  timeout: 120000
});
/*小淘伴学统计*/

/**
 * 获取总列表统计
 * @returns {Promise<*>}
 */

function getXiaoTaoTotal() {
  return request.get('dialogueDetail/getTotal');
}
/**
 * 今日提问统计
 * @returns {Promise<*>}
 */


function getTodayGptData() {
  return request.get('dialogueDetail/getTodayGptData');
}
/**
 * 昨日提问统计
 * @returns {Promise<*>}
 */


function getYesterdayGptData() {
  return request.get('dialogueDetail/getYesterdayGptData');
}
/**
 * 近7日提问统计
 * @returns {Promise<*>}
 */


function getNearlySevenDaysGptData() {
  return request.get('dialogueDetail/getNearlySevenDaysGptData');
}
/**
 * 近30日提问统计
 * @returns {Promise<*>}
 */


function getMonthGptData() {
  return request.get('dialogueDetail/getMonthGptData');
}
/**
 * 近一年日提问统计
 * @returns {Promise<*>}
 */


function getYearGptData() {
  return request.get('dialogueDetail/getYearGptData');
}
/**
 * 按时间统计折线图
 * @returns {Promise<*>}
 */


function queryLineChart(params) {
  return request.get('dialogueDetail/queryLineChart', {
    params: params
  });
}
/**
 * 获取gpt接口信息
 * @returns {Promise<*>}
 */


function queryApiUsage() {
  return request.get('dialogueDetail/queryApiUsage');
}
/**
 * 获取预设信息
 * @returns {Promise<*>}
 */


function getGPTYuShe() {
  return request.get('dialogueDetailSet/view');
}
/**
 * 保存预设信息
 * @returns {Promise<*>}
 */


function saveGPTYuShe(data) {
  return request.post('dialogueDetailSet/save', data);
}
/**
 * 获取会话列表
 * @returns {Promise<*>}
 */


function queryConversationList(params) {
  return request.get('dialogueDetail/queryConversationList', {
    params: params
  });
}
/**
 * 获取对话信息
 * @returns {Promise<*>}
 */


function dialogList(params) {
  return request.get('dialogueDetail/dialogList', {
    params: params
  });
}
/**
 * 获取对话信息
 * @returns {Promise<*>}
 */


function queryConversationId(params) {
  return request.get('dialogueDetail/queryConversationId', {
    params: params
  });
}