var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("product-list", {
        ref: "list",
        attrs: {
          loading: _vm.loading,
          query: _vm.query,
          data: _vm.data,
          total: _vm.total,
          "content-height": "calc(100vh - 244px)",
          select: _vm.select,
          "select-type": _vm.selectType,
          "all-info-select": _vm.allInfoSelect,
          "show-resource-type": true,
          "price-hidden": true,
          "only-read": _vm.onlyRead
        },
        on: {
          "update:query": function($event) {
            _vm.query = $event
          },
          "update:select": function($event) {
            _vm.select = $event
          },
          "update:selectType": function($event) {
            _vm.selectType = $event
          },
          "update:select-type": function($event) {
            _vm.selectType = $event
          },
          "update:allInfoSelect": function($event) {
            _vm.allInfoSelect = $event
          },
          "update:all-info-select": function($event) {
            _vm.allInfoSelect = $event
          },
          "update-data": _vm.updateData
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header-left",
              fn: function() {
                return [
                  !_vm.onlyRead
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.onButtonClicked }
                        },
                        [_vm._v("新建商品")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "content-action",
              fn: function() {
                return [
                  !_vm.onlyRead
                    ? _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          align: "center",
                          width: "120",
                          label: "操作"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "actions" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-edit",
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.isActionDisabled(
                                              scope.row.productOpenId
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toEdit(
                                                scope.row.productOpenId
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown",
                                        { staticClass: "action-more" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [_vm._v("更多")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-dropdown-menu", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(dropdown) {
                                                    return [
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.commoditiesChangeSeasons(
                                                                scope.row
                                                                  .productOpenId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("商品换季")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.seasonalRecords(
                                                                scope.row
                                                                  .productOpenId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("换季记录")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.distributionRecords(
                                                                scope.row
                                                                  .productOpenId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("铺货记录")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.recordOfOperations(
                                                                scope.row
                                                                  .productOpenId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("操作记录")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toDelete(
                                                                scope.row
                                                                  .productOpenId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("移除商品")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          718491909
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.onlyRead
                    ? _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          align: "center",
                          width: "120",
                          label: "操作"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "actions" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn-edit",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showGoodsInfo(
                                                scope.row.productOpenId
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("详情")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1079449792
                        )
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            !_vm.onlyRead
              ? {
                  key: "footer-left",
                  fn: function() {
                    return [
                      _c("bulk-action-button", {
                        ref: "reviewBtn",
                        attrs: {
                          selectType: _vm.selectType,
                          status: "1",
                          "all-info-select-list": _vm.allInfoSelect,
                          "handle-batch-stop-sale": _vm.batchStopSale,
                          reviewFunction: _vm.audioReviewPass,
                          "copy-function": _vm.batchCopy,
                          "type-name": "商品"
                        },
                        on: {
                          "check-all": _vm.checkAll,
                          batchUnpublish: _vm.batchUnpublish,
                          batchRefuse: function($event) {
                            return _vm.handlebatchRefuse(arguments)
                          },
                          batchSetAttribute: _vm.updateData,
                          batchRecovery: _vm.handleBatchRecovery,
                          batchSaveDraft: _vm.batchSaveAudioDraft,
                          batchSaveRecycle: _vm.batchSaveAudioRecycle,
                          batchHiddenOrStopSale: _vm.batchHiddenOrStopSale,
                          bulkSetPrice: _vm.batchBulkSetPrice
                        }
                      }),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-delete" },
                          on: { click: _vm.recycleBin }
                        },
                        [_vm._v("回收站")]
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _vm.distributionRecordVisible
        ? _c("distribution-record", {
            attrs: {
              "product-open-id": _vm.productOpenId,
              visible: _vm.distributionRecordVisible
            },
            on: {
              "update:visible": function($event) {
                _vm.distributionRecordVisible = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogs" },
        [
          _c("batch-setting-prices-dialog", {
            on: { confirm: _vm.batchPriceConfirm },
            model: {
              value: _vm.batchPriceDialogVisible,
              callback: function($$v) {
                _vm.batchPriceDialogVisible = $$v
              },
              expression: "batchPriceDialogVisible"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.goodsDialogVisible
        ? _c("real-goods-info", {
            attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
            on: {
              "update:visible": function($event) {
                _vm.goodsDialogVisible = $event
              },
              "update:goodsId": function($event) {
                _vm.goodsId = $event
              },
              "update:goods-id": function($event) {
                _vm.goodsId = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }