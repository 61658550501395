"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCouponList = getCouponList;
exports.cancelCoupon = cancelCoupon;
exports.createCoupon = createCoupon;
exports.getCouponDetail = getCouponDetail;
exports.updateCouponInfo = updateCouponInfo;
exports.manualsIssued = manualsIssued;
exports.getCouponStatistics = getCouponStatistics;
exports.getCouponOperationList = getCouponOperationList;
exports.getCouponUserOrderList = getCouponUserOrderList;
exports.getCouponUsers = getCouponUsers;
exports.getAllCategory = getAllCategory;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/coupon/admin/"));
/**
 * 分页获取优惠券信息
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getCouponList(params) {
  return request.get('/coupon/getCouponList', {
    params: params
  });
}
/**
 * 取消发放
 * @param {String} id
 * @returns {Promise<*>}
 */


function cancelCoupon(id) {
  return request.patch("/coupon/cancelCoupon/".concat(id));
}
/**
 * 创建优惠券
 * @param {Object} data
 * @returns {Promise<*>}
 */


function createCoupon(data) {
  return request.post('/coupon/add', data);
}
/**
 * 获取优惠券详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCouponDetail(openId) {
  return request.get('/coupon/getCouponByOpenId', {
    params: {
      openId: openId
    }
  });
}
/**
 * 修改优惠券
 * @param {String} couponOpenId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateCouponInfo(couponOpenId, data) {
  return request.post("/coupon/updateCoupon/".concat(couponOpenId), data);
}
/**
 * 优惠券手动发放
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function manualsIssued(data) {
  return request.post('/coupon/addDistributionSettings', data);
}
/**
 * 获取优惠券详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCouponStatistics(couponOpenId) {
  return request.get('/coupon/getCouponStatisticsByOpenId', {
    params: {
      couponOpenId: couponOpenId
    }
  });
}
/**
 * 分页获取优惠券关系表(发送批次表)信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCouponOperationList(params) {
  return request.get('/couponUser/getCouponUserList', {
    params: params
  });
}
/**
 * 分页获取优惠券关系表(发送批次表)信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCouponUserOrderList(params) {
  return request.get('/couponUserOrder/getCouponUserOrderList', {
    params: params
  });
}
/**
 * 查看
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCouponUsers(params) {
  return request.get('/couponUserOrder/getCouponUserOrderListByCouponUserOpenId', {
    params: params
  });
}
/**
 * 获取所有分组
 * @returns {Promise<*>}
 */


function getAllCategory() {
  return request.get('/coupon/getAllCategory');
}