var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.value
      ? _c(
          "div",
          { staticClass: "select-partner" },
          [
            _c("span", { staticClass: "partner-text" }, [_vm._v("商户")]),
            _vm._v(" "),
            _c("search-partner", {
              ref: "selectPartner",
              on: { receivepartnerId: _vm.receivePartnerId }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "catalogue"
      },
      [
        _vm.systemList.length > 0 || _vm.query.partnerOpenId
          ? _c(
              "div",
              [
                _vm.systemList.length > 0
                  ? _c("el-tree", {
                      attrs: {
                        data: _vm.systemTree,
                        "node-key": "materialClassificationOpenId",
                        "default-expand-all": "",
                        "expand-on-click-node": false
                      },
                      on: { "node-click": _vm.handleNodeClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "div",
                                {
                                  staticClass: "common-tree",
                                  on: {
                                    mouseenter: function($event) {
                                      $event.stopPropagation()
                                      return _vm.mouseoverFun(data)
                                    },
                                    mouseleave: function($event) {
                                      $event.stopPropagation()
                                      return _vm.mouseoutFun()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item",
                                      class: [
                                        _vm.activeOpenId ===
                                        data.materialClassificationOpenId
                                          ? "active-item"
                                          : ""
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          data.materialClassificationOpenId !==
                                          -11
                                            ? _c("i", {
                                                class: [
                                                  "common-icon",
                                                  _vm.activeOpenId ===
                                                  data.materialClassificationOpenId
                                                    ? "el-icon-folder-opened"
                                                    : "el-icon-folder"
                                                ]
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "name-title" },
                                            [
                                              _vm._v(
                                                _vm._s(data.classificationName)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      data.materialClassificationOpenId !== -11
                                        ? _c("span", [
                                            _vm._v(_vm._s(data.fileNum))
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        560406913
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.query.partnerOpenId
                  ? _c("el-tree", {
                      attrs: {
                        data: _vm.personalTree,
                        "node-key": "materialClassificationOpenId",
                        "default-expand-all": "",
                        "expand-on-click-node": false
                      },
                      on: { "node-click": _vm.handlePersonalClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "div",
                                {
                                  staticClass: "common-tree",
                                  on: {
                                    mouseenter: function($event) {
                                      $event.stopPropagation()
                                      return _vm.mouseoverFun(data)
                                    },
                                    mouseleave: function($event) {
                                      $event.stopPropagation()
                                      return _vm.mouseoutFun()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item",
                                      class: [
                                        _vm.activeOpenId ===
                                        data.materialClassificationOpenId
                                          ? "active-item"
                                          : ""
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          data.materialClassificationOpenId !==
                                          -11
                                            ? _c("i", {
                                                class: [
                                                  "common-icon",
                                                  _vm.activeOpenId ===
                                                  data.materialClassificationOpenId
                                                    ? "el-icon-folder-opened"
                                                    : "el-icon-folder"
                                                ]
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "name-title" },
                                            [
                                              _vm._v(
                                                _vm._s(data.classificationName)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      data.materialClassificationOpenId !== -11
                                        ? _c("div", [
                                            _vm.mouseOpenId ===
                                              data.materialClassificationOpenId &&
                                            data.materialClassificationOpenId !==
                                              "-1"
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-edit common-icon delete-icon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleEdit(
                                                          data.materialClassificationOpenId
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete add-icon delete-icon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleDelete(
                                                          data,
                                                          true
                                                        )
                                                      }
                                                    }
                                                  })
                                                ])
                                              : _c("span", [
                                                  _vm._v(_vm._s(data.fileNum))
                                                ])
                                          ])
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-folder-add add-icon",
                                            on: { click: _vm.addSystemGroup }
                                          })
                                    ]
                                  )
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        2046022081
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          : _c("div", { staticClass: "no-data" }, [_vm._v("暂无数据")]),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            staticClass: "system-add-dialog",
            attrs: {
              "append-to-body": "",
              visible: _vm.systemAdd,
              title: _vm.systemTitle,
              "close-on-click-modal": false,
              "close-on-press-escape": false,
              width: "346px"
            },
            on: {
              "update:visible": function($event) {
                _vm.systemAdd = $event
              }
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "addSystemForm",
                attrs: {
                  model: _vm.addSystemForm,
                  "label-width": "80px",
                  rules: _vm.systemRules
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "分组名称", prop: "classificationName" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "", placeholder: "请填写分组名称" },
                      model: {
                        value: _vm.addSystemForm.classificationName,
                        callback: function($$v) {
                          _vm.$set(_vm.addSystemForm, "classificationName", $$v)
                        },
                        expression: "addSystemForm.classificationName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "分组排序" } },
                  [
                    _c("el-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "controls-position": "right",
                        placeholder: "请填写分组名称"
                      },
                      model: {
                        value: _vm.addSystemForm.sequence,
                        callback: function($$v) {
                          _vm.$set(_vm.addSystemForm, "sequence", $$v)
                        },
                        expression: "addSystemForm.sequence"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "footer-btn",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.systemAdd = false
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.addSystemLoading },
                    on: { click: _vm.addSystemSubmit }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("delete-material-dialog", {
          attrs: {
            "partner-open-id": _vm.query.partnerOpenId,
            "is-partner": _vm.deletePartner,
            visible: _vm.openDelete,
            data: _vm.deleteData
          },
          on: {
            "update:visible": function($event) {
              _vm.openDelete = $event
            },
            moveMaterial: _vm.moveMaterial
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }