var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "searchbtn",
        {
          on: {
            searchContent: _vm.searchContent,
            firstLoading: _vm.firstLoading
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.query, "label-width": "90px" } },
            [
              _c("div", { staticClass: "search_box" }, [
                _c("div", { staticClass: "search_content" }, [
                  _c("div", { staticClass: "search_head" }, [
                    _vm._v("根据商品查询")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content_top display_flex" }, [
                    _c(
                      "div",
                      [
                        _c("div", { staticClass: "search_title" }, [
                          _vm._v(
                            "\n                  条件筛选\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "同步信息" } }, [
                          _c(
                            "div",
                            { staticClass: "display_flex" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "search_input_120",
                                  attrs: { clearable: "", placeholder: "学段" },
                                  on: {
                                    change: function($event) {
                                      return _vm.queryChange("sectionCode")
                                    }
                                  },
                                  model: {
                                    value: _vm.query.sectionCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "sectionCode", $$v)
                                    },
                                    expression: "query.sectionCode"
                                  }
                                },
                                _vm._l(_vm.SyncSectionData, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "search_input_120 marginLeft_8",
                                  attrs: { clearable: "", placeholder: "学科" },
                                  on: {
                                    change: function($event) {
                                      return _vm.queryChange("subjectCode")
                                    }
                                  },
                                  model: {
                                    value: _vm.query.subjectCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "subjectCode", $$v)
                                    },
                                    expression: "query.subjectCode"
                                  }
                                },
                                _vm._l(_vm.subjectOption, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "display_flex marginTop_6" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "search_input_120",
                                  attrs: { clearable: "", placeholder: "版本" },
                                  on: {
                                    change: function($event) {
                                      return _vm.queryChange("editionCode")
                                    }
                                  },
                                  model: {
                                    value: _vm.query.editionCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "editionCode", $$v)
                                    },
                                    expression: "query.editionCode"
                                  }
                                },
                                _vm._l(_vm.editionOption, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "search_input_120 marginLeft_8",
                                  attrs: { clearable: "", placeholder: "年级" },
                                  on: {
                                    change: function($event) {
                                      return _vm.queryChange("gradeCode")
                                    }
                                  },
                                  model: {
                                    value: _vm.query.gradeCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "gradeCode", $$v)
                                    },
                                    expression: "query.gradeCode"
                                  }
                                },
                                _vm._l(_vm.allSyncGrade, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "display_flex marginTop_6" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "search_input_120",
                                  attrs: { clearable: "", placeholder: "阶段" },
                                  on: {
                                    change: function($event) {
                                      return _vm.queryChange("volumeCode")
                                    }
                                  },
                                  model: {
                                    value: _vm.query.volumeCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "volumeCode", $$v)
                                    },
                                    expression: "query.volumeCode"
                                  }
                                },
                                _vm._l(_vm.volumeCodetion, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "search_input_120 marginLeft_8",
                                  attrs: {
                                    clearable: "",
                                    placeholder: "选必修"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.queryChange("electiveCode")
                                    }
                                  },
                                  model: {
                                    value: _vm.query.electiveCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.query, "electiveCode", $$v)
                                    },
                                    expression: "query.electiveCode"
                                  }
                                },
                                _vm._l(_vm.electiveOption, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地区信息" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "地区信息"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("areaCode")
                                  }
                                },
                                model: {
                                  value: _vm.query.areaCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "areaCode", $$v)
                                  },
                                  expression: "query.areaCode"
                                }
                              },
                              _vm._l(_vm.areaList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "版次信息" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "版次信息"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("periodCode")
                                  }
                                },
                                model: {
                                  value: _vm.query.periodCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "periodCode", $$v)
                                  },
                                  expression: "query.periodCode"
                                }
                              },
                              _vm._l(_vm.periodOptions, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品标签" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "请输入或选择商品标签"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("tags")
                                  }
                                },
                                model: {
                                  value: _vm.query.tags,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "tags", $$v)
                                  },
                                  expression: "query.tags"
                                }
                              },
                              _vm._l(_vm.tagOptions, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "类目信息" } },
                          [
                            _c("el-cascader", {
                              staticClass: "search_input_width",
                              attrs: {
                                clearable: "",
                                placeholder: "类目信息",
                                options: _vm.categoryData,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: false
                                }
                              },
                              on: {
                                change: function($event) {
                                  return _vm.queryChange(
                                    "secondRegimentationIds"
                                  )
                                }
                              },
                              model: {
                                value: _vm.query.secondRegimentationId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.query,
                                    "secondRegimentationId",
                                    $$v
                                  )
                                },
                                expression: "query.secondRegimentationId"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "品牌" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: { clearable: "", placeholder: "品牌" },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("brandOpenId")
                                  }
                                },
                                model: {
                                  value: _vm.query.brandOpenId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "brandOpenId", $$v)
                                  },
                                  expression: "query.brandOpenId"
                                }
                              },
                              _vm._l(_vm.brandOption, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.brandOpenId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品系列" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "商品系列"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("seriesCode")
                                  }
                                },
                                model: {
                                  value: _vm.query.seriesCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "seriesCode", $$v)
                                  },
                                  expression: "query.seriesCode"
                                }
                              },
                              _vm._l(_vm.seriesOption, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.seriesOpenId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: {
                        margin: "0px 15px",
                        height: "490px",
                        border: "1px solid rgba(245, 247, 250, 1)"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("div", { staticClass: "search_title" }, [
                          _vm._v(
                            "\n                  条件筛选\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否套装" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("suitStatus")
                                  }
                                },
                                model: {
                                  value: _vm.query.suitStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "suitStatus", $$v)
                                  },
                                  expression: "query.suitStatus"
                                }
                              },
                              _vm._l(_vm.common_option, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "教辅单品" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("singleStatus")
                                  }
                                },
                                model: {
                                  value: _vm.query.singleStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "singleStatus", $$v)
                                  },
                                  expression: "query.singleStatus"
                                }
                              },
                              _vm._l(_vm.common_option, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "有无规格" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("specificJsonStatus")
                                  }
                                },
                                model: {
                                  value: _vm.query.specificJsonStatus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.query,
                                      "specificJsonStatus",
                                      $$v
                                    )
                                  },
                                  expression: "query.specificJsonStatus"
                                }
                              },
                              _vm._l(_vm.common_option, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "主图视频" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("showVideoStatus")
                                  }
                                },
                                model: {
                                  value: _vm.query.showVideoStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "showVideoStatus", $$v)
                                  },
                                  expression: "query.showVideoStatus"
                                }
                              },
                              _vm._l(_vm.common_option, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否限购" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("quotaStatus")
                                  }
                                },
                                model: {
                                  value: _vm.query.quotaStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "quotaStatus", $$v)
                                  },
                                  expression: "query.quotaStatus"
                                }
                              },
                              _vm._l(_vm.quota_options, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "教师可购" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("teacherBuyStatus")
                                  }
                                },
                                model: {
                                  value: _vm.query.teacherBuyStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "teacherBuyStatus", $$v)
                                  },
                                  expression: "query.teacherBuyStatus"
                                }
                              },
                              _vm._l(_vm.teacher_options, function(item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: {
                      margin: "0px 15px",
                      width: "100%",
                      border: "1px solid rgba(245, 247, 250, 1)"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "content_bottom display_flex" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass: "search_head",
                            staticStyle: { "margin-top": "20px" }
                          },
                          [_vm._v("根据订单查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "购买方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "购买方式"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("buyType")
                                  }
                                },
                                model: {
                                  value: _vm.query.buyType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "buyType", $$v)
                                  },
                                  expression: "query.buyType"
                                }
                              },
                              _vm._l(_vm.allForFront, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "支付渠道" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "支付渠道"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("payType")
                                  }
                                },
                                model: {
                                  value: _vm.query.payType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "payType", $$v)
                                  },
                                  expression: "query.payType"
                                }
                              },
                              _vm._l(_vm.payTypeData.payTypeData, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.ping_plus_plus_channel
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "购买渠道" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "购买渠道"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("channel")
                                  }
                                },
                                model: {
                                  value: _vm.query.channel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "channel", $$v)
                                  },
                                  expression: "query.channel"
                                }
                              },
                              _vm._l(_vm.purchsoptions, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: item.openId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "来源渠道" } },
                          [
                            _c("el-cascader", {
                              staticClass: "search_input_width",
                              attrs: {
                                clearable: "",
                                placeholder: "来源渠道",
                                options: _vm.sourceChannelOptions,
                                props: _vm.sourceProps
                              },
                              on: {
                                change: function($event) {
                                  return _vm.queryChange(
                                    "cooperationChannelOpenId"
                                  )
                                }
                              },
                              model: {
                                value: _vm.query.cooperationChannelOpenIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.query,
                                    "cooperationChannelOpenIds",
                                    $$v
                                  )
                                },
                                expression: "query.cooperationChannelOpenIds"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发货方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: { placeholder: "发货方式" },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("deliveryMethod")
                                  }
                                },
                                model: {
                                  value: _vm.query.deliveryMethod,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "deliveryMethod", $$v)
                                  },
                                  expression: "query.deliveryMethod"
                                }
                              },
                              _vm._l(_vm.deliveryMethodOption, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "物流公司" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "物流公司"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("postCompanyId")
                                  }
                                },
                                model: {
                                  value: _vm.query.postCompanyId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "postCompanyId", $$v)
                                  },
                                  expression: "query.postCompanyId"
                                }
                              },
                              _vm._l(_vm.activeOption, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.expName, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单标签" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入订单标签" },
                              on: {
                                change: function($event) {
                                  return _vm.queryChange("orderTags")
                                }
                              },
                              model: {
                                value: _vm.query.orderTags,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "orderTags", $$v)
                                },
                                expression: "query.orderTags"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品库" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  placeholder: "所属商品库"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("commodityLibrary")
                                  }
                                },
                                model: {
                                  value: _vm.query.commodityLibrary,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "commodityLibrary", $$v)
                                  },
                                  expression: "query.commodityLibrary"
                                }
                              },
                              _vm._l(_vm.commodityLibraryOption(), function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.label
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发货仓" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: { clearable: "", placeholder: "发货仓" },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("deliveryStashId")
                                  }
                                },
                                model: {
                                  value: _vm.query.deliveryStashId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "deliveryStashId", $$v)
                                  },
                                  expression: "query.deliveryStashId"
                                }
                              },
                              _vm._l(_vm.optionErpStoreRoom, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: {
                        margin: "20px 15px 0px 15px",
                        height: "470px",
                        border: "1px solid rgba(245, 247, 250, 1)"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "50px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "学段" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  multiple: "",
                                  placeholder: "学段"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("userSectionCode")
                                  }
                                },
                                model: {
                                  value: _vm.query.userSectionCodes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "userSectionCodes", $$v)
                                  },
                                  expression: "query.userSectionCodes"
                                }
                              },
                              _vm._l(_vm.SyncSectionData, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "年级" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  multiple: "",
                                  placeholder: "年级"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("userGradeCode")
                                  }
                                },
                                model: {
                                  value: _vm.query.userGradeCodes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "userGradeCodes", $$v)
                                  },
                                  expression: "query.userGradeCodes"
                                }
                              },
                              _vm._l(_vm.allSyncGrade, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "阶段" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: {
                                  clearable: "",
                                  multiple: "",
                                  placeholder: "阶段"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("userVolumeCode")
                                  }
                                },
                                model: {
                                  value: _vm.query.userVolumeCodes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "userVolumeCodes", $$v)
                                  },
                                  expression: "query.userVolumeCodes"
                                }
                              },
                              _vm._l(_vm.volumeCodetion, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "资源类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "search_input_width",
                                attrs: { clearable: "", placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("resourceType")
                                  }
                                },
                                model: {
                                  value: _vm.query.resourceType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.query, "resourceType", $$v)
                                  },
                                  expression: "query.resourceType"
                                }
                              },
                              _vm._l(_vm.resourceTypeOptions, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.title, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "ERP发货仓" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    return _vm.queryChange("deliveryStashFlag")
                                  }
                                },
                                model: {
                                  value: _vm.query.deliveryStashFlag,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.query,
                                      "deliveryStashFlag",
                                      $$v
                                    )
                                  },
                                  expression: "query.deliveryStashFlag"
                                }
                              },
                              _vm._l(_vm.deliveryStashFlag_options, function(
                                item
                              ) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "下单时间" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "380px" },
                              attrs: {
                                "picker-options": _vm.pickerOptions,
                                type: "datetimerange",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                align: "right"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.queryChange("orderCreated")
                                }
                              },
                              model: {
                                value: _vm.query.orderCreated,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "orderCreated", $$v)
                                },
                                expression: "query.orderCreated"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "付款时间" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "380px" },
                              attrs: {
                                "picker-options": _vm.pickerOptions,
                                type: "datetimerange",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                align: "right"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.queryChange("payedAtCreated")
                                }
                              },
                              model: {
                                value: _vm.query.payedAtCreated,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "payedAtCreated", $$v)
                                },
                                expression: "query.payedAtCreated"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "沙盒模式" } },
                          [
                            _c("el-switch", {
                              staticClass: "lineHeight_1 height36",
                              attrs: {
                                clearable: "",
                                "active-color": "#13ce66"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.queryChange("inIosSandbox")
                                }
                              },
                              model: {
                                value: _vm.query.inIosSandbox,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "inIosSandbox", $$v)
                                },
                                expression: "query.inIosSandbox"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }