"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));

var _beautifulTextFilter = _interopRequireDefault(require("../../../utils/filters/beautifulTextFilter"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'UserInfo',
  filters: {
    beautifulTextFilter: _beautifulTextFilter.default
  },
  props: {
    userInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isShowTooltip: {
      type: Boolean,
      default: true
    },
    newUserImg: {
      type: String,
      default: ''
    },
    newUserName: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    copyOpenId: function copyOpenId(text, event) {
      (0, _clipboard.default)(text, event);
    }
  }
};
exports.default = _default2;