"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CV_Product_Product_EditProduct_EditBaseInfo_PriceInputDiscount',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      discount: undefined
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return (0, _priceParser.parseDiscount)(this.value);
      },
      set: function set(value) {
        this.$emit('input', (0, _priceParser.parseDiscount)(value));
      }
    }
  },
  watch: {
    valueProxy: function valueProxy() {
      this.onDiscountUpdate();
    }
  },
  mounted: function mounted() {
    this.onDiscountUpdate();
  },
  methods: {
    onDiscountUpdate: function onDiscountUpdate() {
      this.discount = (0, _priceParser.discountToRebate)(this.valueProxy);
    },
    onInputUpdate: function onInputUpdate() {
      this.valueProxy = (0, _priceParser.parseDiscountFromRebate)(this.discount);
    }
  }
};
exports.default = _default;