"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dataExportingNotify = dataExportingNotify;

var _elementUi = require("element-ui");

function dataExportingNotify(_ref) {
  var h = _ref.h,
      click = _ref.click;
  (0, _elementUi.Notification)({
    title: '数据导出中',
    dangerouslyUseHTMLString: true,
    message: h('div', {
      class: 'message'
    }, [h('div', {
      class: 'btnList'
    }, [h('i', {
      class: 'el-icon-info',
      style: {
        color: '#409EFF'
      }
    }), h('span', null, '请在'), h('a', {
      style: {
        color: '#409EFF'
      },
      on: {
        click: click
      }
    }, '应用-数据导出'), h('span', null, '中查看')])])
  });
}