"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.crossSpecification = crossSpecification;
exports.updateSpecification = updateSpecification;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.string.starts-with");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.entries");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _toArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toArray"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

var _collect2 = _interopRequireDefault(require("collect.js"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 规格数据
 * @typedef {{name: string, value: string[]}} Specification
 */

/**
 * 规格表格数据
 * @typedef {{[p: string]: string, code: string, inventory: number, isUseErp: boolean, isEnable: boolean, orginalPrice: number, price: number, lowPrice: number, skuType: string}} SpecificationTableItem
 */

/**
 * 移除一个数组尾部的 0
 * @param {Collection<number>} code
 * @returns {Collection<number>}
 */
function crossSpecificationCode(code) {
  if (code.count() < 2) {
    return code;
  }

  if (code.last() === 0) {
    code.pop();
    crossSpecificationCode(code);
  }

  return code;
}
/**
 * 将一个多层级规格数据格式化为规格表数据
 * @param {[number, string][]} data 数组按顺序表示为多个规格，每个子元组中的第一个值为 number，表示这是这个规格值中的第几个，第二个值为 string，表示该规格值的值
 * @returns {SpecificationTableItem}
 */


function crossSpecificationItem(data) {
  var cl = (0, _collect2.default)(data);
  var code = cl.map(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        j = _ref2[0];

    return j;
  }).pipe(crossSpecificationCode).join('-');
  var name = cl.map(function (j) {
    return j[1];
  }).mapWithKeys(function (i, j) {
    return ["specification-".concat(j), i];
  }).all();
  return _objectSpread(_objectSpread({}, name), {}, {
    code: code,
    inventory: 0,
    isUseErp: true,
    isEnable: true,
    orginalPrice: 0,
    price: 1,
    lowPrice: 1,
    skuType: '单品'
  });
}
/**
 * 把填写的规格信息展开为表格所需的格式，以及一个独立的标题字段
 * @param {Specification[]} data 数组按顺序表示多个规格
 * @returns {{title: {[p: string]: string}, list: SpecificationTableItem[]}}
 */


function crossSpecification(data) {
  var cl = (0, _collect2.default)(data);
  var title = cl.pluck('name').mapWithKeys(function (i, j) {
    return ["specification-".concat(j), i];
  }).all(); //

  var list = cl.pluck('value').map(function (i) {
    return i.map(function (j, k) {
      return [k, j];
    });
  }).pipe(function (_ref3) {
    var _collect;

    var _ref4 = (0, _toArray2.default)(_ref3),
        first = _ref4[0],
        other = _ref4.slice(1);

    return (_collect = (0, _collect2.default)(first)).crossJoin.apply(_collect, (0, _toConsumableArray2.default)(other));
  }).map(crossSpecificationItem).all();
  return {
    title: title,
    list: list
  };
}
/**
 * 将一个规格表格数据拆分为两类，一类是关键 key，一类是用于初始化的数据
 * @param {SpecificationTableItem} data
 * @returns {{imp: {[p: string]: string, code: string}, def: SpecificationTableItem}}
 */


function updateSpecificationParse(data) {
  return (0, _collect2.default)(Object.entries(data)).groupBy(function (j) {
    return j[0].startsWith('specification-') || j[0] === 'code' ? 'imp' : 'def';
  }).map(function (j) {
    return Object.fromEntries(j.all());
  }).all();
}
/**
 * 用于合并两个规格表格数据
 * @param {SpecificationTableItem[]} origin
 * @param {SpecificationTableItem[]} data
 * @returns {SpecificationTableItem[]}
 */


function updateSpecification(origin, data) {
  return (0, _collect2.default)(data).map(function (i) {
    var match = origin.find(function (j) {
      return j.code === i.code;
    });

    var _updateSpecificationP = updateSpecificationParse(i),
        def = _updateSpecificationP.def,
        imp = _updateSpecificationP.imp;

    return _objectSpread(_objectSpread(_objectSpread({}, def), match), imp);
  }).all();
}