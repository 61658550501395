"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _SelectLink = _interopRequireDefault(require("../../PublicReply/SelectLink"));

var _selectTestPaper = _interopRequireDefault(require("../../Book/Question/selectTestPaper"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PoetryExtendStudy',
  components: {
    SelectTestPaper: _selectTestPaper.default,
    SelectLink: _SelectLink.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      openSelect: false,
      openPaper: false,
      showType: 'add',
      replaceIndex: undefined,
      checkList: []
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    },
    showPaper: function showPaper() {
      if (this.valueProxy.examPaperOpenIds) {
        return this.valueProxy.examPaperOpenIds.length > 0;
      }

      return false;
    }
  },
  methods: {
    replacePaper: function replacePaper(val, index) {
      this.showType = 'replace';
      this.replaceIndex = index;
      this.checkList = JSON.parse(JSON.stringify(this.valueProxy.examPaperOpenIds));
      this.openPaper = true;
    },
    handleRemove: function handleRemove(val, index) {
      this.valueProxy.examPaperOpenIds.splice(index, 1);
    },
    toOpenPaper: function toOpenPaper() {
      this.showType = 'add';
      this.openPaper = true;

      if (this.valueProxy.examPaperOpenIds) {
        this.checkList = JSON.parse(JSON.stringify(this.valueProxy.examPaperOpenIds));
      } else {
        this.checkList = [];
      }
    },
    deleteLink: function deleteLink() {
      this.valueProxy.learningLink = null;
    },
    // 添加试卷
    addSuccess: function addSuccess(val) {
      if (!this.valueProxy.examPaperOpenIds) {
        this.valueProxy.examPaperOpenIds = [];
      }

      if (this.showType === 'replace') {
        this.$set(this.valueProxy.examPaperOpenIds, this.replaceIndex, val[0]);
      } else {
        this.valueProxy.examPaperOpenIds = [].concat((0, _toConsumableArray2.default)(this.valueProxy.examPaperOpenIds), (0, _toConsumableArray2.default)(val));
      }

      this.openPaper = false;
    }
  }
};
exports.default = _default2;