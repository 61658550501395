"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wxStoreList = wxStoreList;
exports.wxStoreUpdate = wxStoreUpdate;
exports.wxStoreBalance = wxStoreBalance;
exports.wxStoreCreate = wxStoreCreate;
exports.wxStoreFlowList = wxStoreFlowList;
exports.wxStoreCharge = wxStoreCharge;
exports.wxStoreRefund = wxStoreRefund;
exports.orderCreate = orderCreate;
exports.orderCancel = orderCancel;
exports.orderDetail = orderDetail;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/mp/retail/intracityExpress/"));
/**
 * 获取商户微信门店信息
 * @param {String} params
 * @returns {Promise<*>}
 */

function wxStoreList(params) {
  return request.get('wxStore/list', {
    params: params
  });
}
/**
 * 更新微信门店
 * @returns {Promise<*>}
 * @param body
 */


function wxStoreUpdate(body) {
  return request.post('wxStore/update', body);
}
/**
 * 查询微信门店运费余额
 * @param {String} params
 * @returns {Promise<*>}
 */


function wxStoreBalance(params) {
  return request.get('wxStore/balance', {
    params: params
  });
}
/**
 * 创建微信门店
 * @returns {Promise<*>}
 * @param body
 */


function wxStoreCreate(body) {
  return request.post('wxStore/create', body);
}
/**
 * 门店运费流水查询
 * @returns {Promise<*>}
 * @param params
 */


function wxStoreFlowList(params) {
  return request.get('wxStore/flow/list', {
    params: params
  });
}
/**
 * 门店充值
 * @returns {Promise<*>}
 * @param body
 */


function wxStoreCharge(body) {
  return request.post('wxStore/charge', body);
}
/**
 * 微信退款
 * @returns {Promise<*>}
 * @param body
 */


function wxStoreRefund(body) {
  return request.post('wxStore/refund', body);
}
/**
 * 创建同城物流订单
 * @returns {Promise<*>}
 * @param body
 */


function orderCreate(body) {
  return request.post('order/create', body);
}
/**
 * 取消同城物流订单
 * @returns {Promise<*>}
 * @param body
 */


function orderCancel(body) {
  return request.post('order/cancel', body);
}
/**
 * 查询同城物流订单
 * @returns {Promise<*>}
 * @param params
 */


function orderDetail(params) {
  return request.get('order/detail', {
    params: params
  });
}