var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "operate" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "operate-dialog",
          attrs: {
            title: "处理记录",
            "append-to-body": "",
            visible: _vm.visible,
            width: "60%",
            "before-close": _vm.handleCancel,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "operate-nav" }, [
              _c("div", { staticClass: "classification-name" }, [
                _vm._v(
                  "工单分组:" +
                    _vm._s(_vm.detailData.workorderClassificationName)
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("工单号: " + _vm._s(_vm.detailData.workOrderOpenId))
              ])
            ]),
            _vm._v(" "),
            !_vm.isShowNoData
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.loadScroll,
                        expression: "loadScroll"
                      }
                    ],
                    staticClass: "operate-content",
                    attrs: {
                      "infinite-scroll-immediate": "false",
                      "infinite-scroll-disabled": "disabled"
                    }
                  },
                  _vm._l(_vm.operateList, function(item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "content operate-list" },
                      [
                        _c(
                          "div",
                          { staticClass: "content-title title-padding" },
                          [
                            _c("user-image-and-name", {
                              attrs: {
                                "user-info": item.operateUserInfo,
                                "is-show-tooltip": false
                              }
                            }),
                            _vm._v(" "),
                            item.status === 3 || item.status === 2
                              ? _c("span", { staticClass: "operate-status" }, [
                                  _vm._v("结束工单")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "content-time" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      item.createdAt,
                                      "{y}-{m}-{d}  {h}:{i}"
                                    )
                                  )
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.operateContent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "content-problem common-background-color"
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(item.operateContent))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "no-data operate-content" }, [
                  _vm._v("暂无数据")
                ])
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("关闭")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }