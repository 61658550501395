var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品详情",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.visible,
            "before-close": _vm.dismissGoodsDialog,
            "append-to-body": true,
            width: "1192px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.goodsLoading,
                  expression: "goodsLoading"
                }
              ]
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "10px",
                    "background-color": "#F5F7FA"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "display_flex",
                      staticStyle: { height: "600px", overflow: "auto" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "790px",
                            "border-radius": "4px",
                            "background-color": "#FFFFFF",
                            "margin-right": "6px",
                            overflow: "auto"
                          }
                        },
                        [
                          _c("div", { staticClass: "goods_dialog_title" }, [
                            _vm._v("\n              商品\n            ")
                          ]),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "display_flex align_items goods_detail_item"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "goodsDetailCover",
                                      attrs: {
                                        fit: "cover",
                                        src: _vm.getImgUrl(
                                          _vm.goodsInfo.cover,
                                          _vm.smallthumbnailStyle
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "goodsDetailCover",
                                            attrs: {
                                              src: require("@/assets/book_cover.png")
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "display_flex align_items space_between",
                                  staticStyle: {
                                    width: "100%",
                                    "margin-left": "12px"
                                  }
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "rgba(48, 49, 51, 1)",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.showVal(_vm.goodsInfo.title)
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "8px",
                                          color: "rgba(48, 49, 51, 1)",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.sectionText
                                              )
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.gradeText
                                              )
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.volumeText
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "display_flex flex_direction ",
                                      staticStyle: { "align-items": "flex-end" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "rgba(64, 158, 255, 1)",
                                            "font-size": "14px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    实物/" +
                                              _vm._s(
                                                _vm.goodsInfo.partnerOpenId
                                                  ? "自营"
                                                  : "分销"
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "rgba(96, 98, 102, 1)",
                                            "font-size": "14px",
                                            "margin-top": "5px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    ERP商品id:" +
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.goodsInfo.productId
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "rgba(96, 98, 102, 1)",
                                            "font-size": "14px",
                                            "margin-top": "5px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    商品编号:" +
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.goodsInfo.productOpenId
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.goodsInfo.resourceType === 22 ||
                          _vm.goodsInfo.resourceType === 21
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "goods_dialog_title" },
                                  [
                                    _vm._v(
                                      "\n                商品清单\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "10px",
                                      "background-color": "#F5F7FA",
                                      margin: "0px 15px 15px 15px"
                                    }
                                  },
                                  _vm._l(
                                    _vm.goodsInfo.contentRequestList,
                                    function(item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "display_flex align_items goods_detail_item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass:
                                                    "goodsDetailCover",
                                                  attrs: {
                                                    fit: "contain",
                                                    src: _vm.getResourceCover(
                                                      item
                                                    )
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "goodsDetailCover",
                                                        attrs: {
                                                          src: require("@/assets/book_cover.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "12px",
                                                height: "80px",
                                                display: "flex",
                                                "flex-direction": "column",
                                                "justify-content":
                                                  "space-around"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.resourceVo
                                                      ? item.resourceVo.title
                                                      : item.title
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              item.displayPrice
                                                ? _c("div", [
                                                    _vm._v(
                                                      "¥ " +
                                                        _vm._s(
                                                          _vm.showVal(
                                                            Number(
                                                              item.displayPrice /
                                                                100
                                                            ).toFixed(2)
                                                          )
                                                        )
                                                    )
                                                  ])
                                                : _c("div", [_vm._v("--")])
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _vm.picList
                            ? _c("div", { staticClass: "goods_dialog_title" }, [
                                _vm._v("\n              商品图集\n            ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.picList
                            ? _c(
                                "el-row",
                                { staticStyle: { padding: "0px 8px" } },
                                _vm._l(_vm.picList, function(item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 3 } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "display_flex align_items justify_center",
                                          staticStyle: {
                                            width: "100%",
                                            cursor: "pointer",
                                            margin: "5px 0px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onPreview(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "el-image",
                                            {
                                              staticClass: "goodsDetailCover",
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  item,
                                                  _vm.smallthumbnailStyle
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "image-slot",
                                                  attrs: { slot: "error" },
                                                  slot: "error"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "goodsDetailCover",
                                                    attrs: {
                                                      src: require("@/assets/book_cover.png")
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.videourl
                            ? _c("div", { staticClass: "goods_dialog_title" }, [
                                _vm._v("\n              主图视频\n            ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.videourl
                            ? _c(
                                "div",
                                { staticStyle: { margin: "0px 15px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "goods_detail_video",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: _vm.videoPreviewFun,
                                        mouseenter: _vm.handleVideoMouseEnter,
                                        mouseleave: _vm.handleVideoMouseLeave
                                      }
                                    },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "goods_detail_video",
                                          attrs: {
                                            fit: "cover",
                                            src: _vm.getImgUrl(
                                              _vm.goodsInfo.showVideoCover,
                                              _vm.smallthumbnailStyle
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error"
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "goods_detail_video",
                                                attrs: {
                                                  src: require("@/assets/book_cover.png")
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.videoMask,
                                            expression: "videoMask"
                                          }
                                        ],
                                        staticClass: "goods_detail_video",
                                        staticStyle: {
                                          "background-color": "#000000",
                                          opacity: "0.5",
                                          position: "relative",
                                          top: "-96px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "goods_dialog_title" }, [
                            _vm._v("\n              商品属性\n            ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "goods_detail_item" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c(
                                      "span",
                                      { staticClass: "goods_detail_key" },
                                      [_vm._v("类目")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "goods_detail_value" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showVal(
                                              _vm.goodsInfo.regimentationDisplay
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c(
                                      "span",
                                      { staticClass: "goods_detail_key" },
                                      [_vm._v("条码")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "goods_detail_value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.showVal(_vm.goodsInfo.barCode)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 12 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("品牌")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.brandText
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 12 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("商品系列")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.seriesText
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("同步")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_value" },
                                        [
                                          _vm._v(
                                            "\n                     " +
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.goodsInfo.sectionText
                                                )
                                              ) +
                                              "/" +
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.goodsInfo.gradeText
                                                )
                                              ) +
                                              "/" +
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.goodsInfo.volumeText
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 12 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("版次")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.periodText
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 12 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("地区")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.goodsInfo.areaText
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("标签")]
                                      ),
                                      _vm._v(" "),
                                      _vm.tags
                                        ? _c(
                                            "span",
                                            _vm._l(_vm.tags, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    "margin-left": "10px"
                                                  }
                                                },
                                                [_vm._v(_vm._s(item))]
                                              )
                                            }),
                                            1
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass: "goods_detail_value"
                                            },
                                            [_vm._v("--")]
                                          )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "goods_detail_item" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "span",
                                      { staticClass: "goods_detail_key" },
                                      [_vm._v("限购")]
                                    ),
                                    _vm._v(" "),
                                    _vm.goodsInfo.quota === 0
                                      ? _c(
                                          "span",
                                          { staticClass: "goods_detail_value" },
                                          [_vm._v("不限")]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "goods_detail_value" },
                                          [_vm._v("是")]
                                        )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass:
                                        "goods_detail_item_margin_top",
                                      attrs: { span: 12 }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_key" },
                                        [_vm._v("原价")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goods_detail_value" },
                                        [
                                          _vm._v(
                                            "¥ " +
                                              _vm._s(
                                                _vm.showVal(
                                                  Number(
                                                    _vm.goodsInfo.orginalPrice /
                                                      100
                                                  ).toFixed(2)
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "375px",
                            "margin-left": "6px",
                            "border-radius": "4px",
                            "background-color": "#FFFFFF",
                            overflow: "hidden",
                            "overflow-x": "scroll",
                            "overflow-y": "scroll"
                          }
                        },
                        [
                          _c("div", { staticClass: "goods_dialog_title" }, [
                            _vm._v("\n              商品介绍\n            ")
                          ]),
                          _vm._v(" "),
                          _c("el-divider", { staticStyle: { width: "100%" } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "goods_detail_item" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.goodsInfo.detail)
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "display_flex align_items flex_end",
                  staticStyle: {
                    padding: "15px 20px",
                    color: "rgba(48, 49, 51, 1)",
                    "font-size": "14px",
                    "font-weight": "bold"
                  }
                },
                [
                  _vm._v(
                    "\n        原价 (元)：¥ " +
                      _vm._s(
                        _vm.showVal(
                          Number(_vm.goodsInfo.orginalPrice / 100).toFixed(2)
                        )
                      ) +
                      "\n      "
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.imagePath
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.imagePath],
              "z-index": "9999"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoPreview,
            "before-close": _vm.videoPreviewClose,
            title: "视频预览"
          },
          on: {
            "update:visible": function($event) {
              _vm.videoPreview = $event
            }
          }
        },
        [
          _c("div", {
            staticClass: "video-start",
            attrs: { id: "videoBrowse" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }