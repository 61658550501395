var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", [
      _c("div", { staticClass: "merchantHeader" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("search", {
              staticClass: "selectPartner",
              on: { receivepartnerId: _vm.receivepartnerId }
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "marginRight_10",
              staticStyle: { "max-width": "250px", "margin-right": "10px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "搜索作品名称"
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getTableDate($event)
                }
              },
              model: {
                value: _vm.keyword,
                callback: function($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword"
              }
            }),
            _vm._v(" "),
            _c(
              "searchbtn",
              { on: { searchContent: _vm.getTableDate } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      id: "dialog-filter-panel-form",
                      model: _vm.valueProxy,
                      "label-width": "70px",
                      size: "mini"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "filter-sync-info",
                        attrs: { label: "同步信息" }
                      },
                      [
                        _c("select-param-sync-info", {
                          attrs: {
                            "query-option": "FindXDList",
                            placeholder: "学段"
                          },
                          model: {
                            value: _vm.valueProxy.xd,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "xd", $$v)
                            },
                            expression: "valueProxy.xd"
                          }
                        }),
                        _vm._v(" "),
                        _c("select-param-sync-info", {
                          attrs: {
                            "query-option": "FindSubjectList",
                            placeholder: "学科"
                          },
                          model: {
                            value: _vm.valueProxy.subject,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "subject", $$v)
                            },
                            expression: "valueProxy.subject"
                          }
                        }),
                        _vm._v(" "),
                        _c("select-param-sync-info", {
                          attrs: {
                            "query-option": "FindBBList",
                            placeholder: "版本"
                          },
                          model: {
                            value: _vm.valueProxy.bb,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "bb", $$v)
                            },
                            expression: "valueProxy.bb"
                          }
                        }),
                        _vm._v(" "),
                        _c("select-param-sync-info", {
                          attrs: {
                            "query-option": "FindGradeList",
                            placeholder: "年级"
                          },
                          model: {
                            value: _vm.valueProxy.grade,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "grade", $$v)
                            },
                            expression: "valueProxy.grade"
                          }
                        }),
                        _vm._v(" "),
                        _c("select-param-sync-info", {
                          attrs: {
                            "query-option": "FindJDList",
                            placeholder: "阶段"
                          },
                          model: {
                            value: _vm.valueProxy.jd,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "jd", $$v)
                            },
                            expression: "valueProxy.jd"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.exportReport }
              },
              [_vm._v("导出报表")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "merchantContent" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                "default-sort": { prop: "courseNum", order: "descending" },
                data: _vm.tableData
              },
              on: { "sort-change": _vm.sortChange }
            },
            [
              _c("el-table-column", {
                attrs: { label: "作品名称", "min-width": "20" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.showVal(scope.row.title)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "right",
                  align: "right",
                  "min-width": "20",
                  sortable: "custom",
                  property: "courseNum",
                  label: "累计观看课时数"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "textRight" }, [
                          _vm._v(_vm._s(scope.row.courseNum))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "right",
                  align: "right",
                  "min-width": "20",
                  sortable: "custom",
                  property: "courseFinishedNum",
                  label: "结课数"
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          [
                            _vm._v("结课数\n              "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "用户学习完成后的课程数",
                                  placement: "top"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "iconInfo el-icon-info"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "textRight" }, [
                          _vm._v(" " + _vm._s(scope.row.courseFinishedNum))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "right",
                  sortable: "custom",
                  align: "right",
                  "min-width": "20",
                  property: "courseFinishedRate",
                  label: "结课率"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "textRight" }, [
                          _vm._v(_vm._s(scope.row.courseFinishedRate))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  sortable: "custom",
                  "header-align": "right",
                  "min-width": "20",
                  property: "userNum",
                  label: "观看用户数"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(scope.row.userNum))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  sortable: "custom",
                  "header-align": "right",
                  "min-width": "20",
                  property: "grandBrowseDuration",
                  label: "累计学习时长（分钟）"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(scope.row.grandBrowseDuration))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  sortable: "custom",
                  "header-align": "right",
                  "min-width": "20",
                  property: "perBrowseDuration",
                  label: "人均学习时长（分钟）"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(scope.row.perBrowseDuration))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "elPagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalElements
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "merchantHeaderLeft" }, [
      _c("h3", { staticClass: "ratioTitle" }, [_vm._v("作品")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }