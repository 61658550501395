var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree-container" },
    [
      _c("el-tree", {
        ref: "tree",
        attrs: {
          "default-expand-all": _vm.isShow,
          data: _vm.dataList,
          "default-checked-keys": _vm.priceCode,
          "show-checkbox": "",
          "node-key": "commonCode",
          props: _vm.defaultProps,
          "expand-on-click-node": false
        },
        on: { check: _vm.getHalfCheckEvent },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var node = ref.node
              var data = ref.data
              return _c("span", { staticClass: "custom-tree-node" }, [
                _c("div", { staticClass: "nodeName" }, [
                  _vm._v("\n          " + _vm._s(node.label) + "\n        ")
                ])
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }