var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "update" },
    [
      _c(
        "el-row",
        { staticClass: "fill-height" },
        [
          _c(
            "el-col",
            { staticClass: "fill-height", attrs: { span: 7 } },
            [_c("book-menu", { ref: "bookMenu" })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "fill-height", attrs: { span: 11 } },
            [_c("editor-top-operation"), _vm._v(" "), _c("editor")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "fill-height", attrs: { span: 6 } },
            [_c("page-section")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }